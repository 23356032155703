import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { useAppDispatch } from 'util/useAppDispatch'

import { RootState } from '../../appReducer'
import { CustomFieldType } from '../../models/enums'
import { cleanZalgoText, convertDoubleStruckToRegular } from '../../modules/shared/unicodeCharsUtils'
import { fetchChatAlertTasksList } from '../amplify/actions'
import SurveyComponentV2 from '../chat-center/survey/SurveyComponentV2'
import { filterOutGoogleQueryParams } from '../chat-center/utils'
import { createPendingReferral } from '../referrals/v2actions'
import CustomField from '../shared/custom-fields/CustomField'
import InfoMessage from '../shared/info-message/info-message'

import './ChatTranscript.sass'

export type Props = {
    practice: Models.Practice
    selectedChatMetadata: ModelsV2.Practice.ChatMetadata
    transcript: string
    onCreate: () => void
}

const ChatTranscript = (props: Props) => {
    const { practice, selectedChatMetadata, onCreate } = props
    const { id, patient_name, amplify_web_code, ip, account, created, amplify_referral } = selectedChatMetadata
    const practiceId = practice?.id

    const dispatch = useAppDispatch()

    const tasks = useSelector((state: RootState) => state.amplify.tasks[id] || null)

    useEffect(() => {
        dispatch(fetchChatAlertTasksList(practiceId, id))
    }, [id, practiceId, dispatch])

    const onSave = async () => {
        await dispatch(createPendingReferral(id))
        onCreate()
    }

    const filteredTasks =
        amplify_web_code?.id && tasks
            ? tasks?.filter(task => task.websiteIds.indexOf(String(amplify_web_code?.id)) >= 0)
            : tasks

    const filterTranscript = (transcript: string) => {
        if (!transcript) {
            return ''
        }

        const transcriptArray = transcript.split('\n')

        const newTranscript = transcriptArray.map((line, index) => {
            return line.includes('Patient Started chat on') ? filterOutGoogleQueryParams(line) : line
        })

        return newTranscript.join('\n')
    }

    return (
        <div className={classNames('chat-transcript')}>
            <div className="practice-title">
                <div className="title">{practice.name}</div>
                <Grid container className="fields">
                    <Grid item xs={6} md={3} lg={2} className="field">
                        <InputLabel className="label">Patient Name</InputLabel>
                        <div className="field-data">
                            {(patient_name && convertDoubleStruckToRegular(patient_name)) || ip}
                        </div>
                    </Grid>
                    {account?.first_name && account?.last_name && (
                        <Grid item xs={6} md={3} lg={2} className="field">
                            <InputLabel className="label">Chatter</InputLabel>
                            <div className="field-data">
                                {`${account.first_name}
                                        ${account.last_name}`}
                            </div>
                        </Grid>
                    )}
                    {amplify_web_code.url && (
                        <Grid item xs={12} md={6} lg={5} className="field">
                            <InputLabel className="label">Website</InputLabel>
                            <div className="field-data">{amplify_web_code.url}</div>
                        </Grid>
                    )}
                    <Grid item xs={6} md={3} lg={1.5} className="field field--right">
                        <InputLabel className="label">Date</InputLabel>
                        <div className="field-data">
                            {moment(created)
                                .tz('America/New_York')
                                .format('MM/DD/YYYY')}
                        </div>
                    </Grid>
                    <Grid item xs={6} md={3} lg={1.5} className="field field--right">
                        <InputLabel className="label">Time</InputLabel>
                        <div className="field-data">
                            {moment(created)
                                .tz('America/New_York')
                                .format('h:mm A z')}
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="data-container">
                    <Grid item xs={12} md={4} className="transcript-container">
                        <div className="title">Transcript</div>
                        <CustomField
                            customFieldType={CustomFieldType.TEXTAREA}
                            rows={30}
                            value={
                                convertDoubleStruckToRegular(cleanZalgoText(filterTranscript(props.transcript))) || ''
                            }
                            readonly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} className="alerts-container">
                        <div className="title">Alerts</div>
                        <div className="alerts-list">
                            {!tasks && (
                                <div className="alerts-loader">
                                    <CircularProgress size={50} color="primary" variant="indeterminate" />
                                </div>
                            )}
                            {filteredTasks?.map(task => (
                                <div className="alert-wrapper">
                                    <i className="alert-icon material-icons">notifications</i>
                                    <div className="alert-text" key={task.id}>
                                        <label className="alert-label">
                                            <ReactMarkdown linkTarget="_blank">{task.alert}</ReactMarkdown>
                                        </label>
                                    </div>
                                </div>
                            ))}
                            {filteredTasks?.length === 0 && (
                                <div className={`alerts-no-records`}>
                                    <InfoMessage isShown={true}>
                                        {' No records found for selected criteria'}
                                    </InfoMessage>
                                </div>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} className="survey-container">
                        <div className="title">Survey</div>
                        <div className="survey-list">
                            <SurveyComponentV2 webCodeId={amplify_web_code?.id} practiceId={practiceId} />
                        </div>
                    </Grid>
                </Grid>
                <Grid container className="btn-container">
                    <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        disabled={Boolean(amplify_referral) ? true : false}
                        className="send-to-referral-center-button"
                        onClick={() => onSave()}
                    >
                        Send to request center
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default ChatTranscript
