import moment from 'moment'

export const transactionIdLabel = 'Charge Event/Transaction ID'

export const getDateOrdinalOfDate = (date: string): string => {
    return moment(date, 'YYYY-MM-DD').format('Do')
}

export const updateDayOfDate = (date: string, day: number): string => {
    return moment(date, 'YYYY-MM-DD')
        .set('date', day)
        .format('YYYY-MM-DD')
}
