import iassign from 'immutable-assign'
import _ from 'lodash'
import moment from 'moment'

import {
    ReceiveFormCreateAdditionalPatientsFinished,
    ReceiveFormDeleteAdditionalPatientsFinished,
    ReceiveFormEditAdditionalPatient,
    ReceiveFormEditAdditionalPatientFinished,
    ReceiveFormRemoveAdditionalPatient,
    ReceiveFormSectionMetaData,
    ReceiveFormValueChange,
    ReceivePrimaryPatientUpdateFinished,
    ResetReferralForm,
} from './referral-form/v2actions'
import { ReferralFormSections } from './shared/enums'
import {
    ReceiveErrors,
    ReceiveFormAtachments,
    ReceiveFormOtherPatientsData,
    ReceiveFormPrimaryPatientData,
    ReceiveFormReferralData,
    ReceiveReferral,
    ReceiveReferralActivityLog,
    ReceiveReferralAttachments,
    ReceiveReferralPatientFinancialData,
    ReceiveReferralsDashboard,
    ReceiveSchedulingAppointment,
    ReceiveUpdateReferralDashboardCard,
    SetDeleteReferralCard,
    SetNumberOfReferralsInReviewState,
} from './v2actions'

export type ReferralsState = {
    referralErrors: { [key: string]: string }
    unattendedInterval?: number
    numberOfReferralsInReview: number
    referralAttachments: { [key: string]: ModelsV2.Referrals.ReferralAttachment[] }
    referralActivityLog: { [key: string]: ModelsV2.Referrals.ActivityLog[] }
    patientsFinancialData: { [key: string]: ModelsV2.Referrals.PatientsFinancialData }
    schedulingAppointments: { [key: string]: ModelsV2.Referrals.SchedulingAppointment }
    connectAppointments: { [key: string]: ModelsV2.Referrals.ConnectAppointments[] }
    referralsDashboard: {
        unclaimed: ModelsV2.Referrals.ReferralsDashboardColumn
        in_progress: ModelsV2.Referrals.ReferralsDashboardColumn
        review: ModelsV2.Referrals.ReferralsDashboardColumn
        complete: ModelsV2.Referrals.ReferralsDashboardColumn
    }
    referralForm: ModelsV2.Referrals.ReferralForm
}

type ReferralsAction =
    | SetNumberOfReferralsInReviewState
    | ReceiveReferralAttachments
    | ReceiveReferral
    | ReceiveReferralActivityLog
    | ReceiveReferralPatientFinancialData
    | ReceiveErrors
    | ReceiveReferralsDashboard
    | ReceiveFormValueChange
    | ReceiveFormReferralData
    | ReceiveFormPrimaryPatientData
    | ReceiveFormAtachments
    | ReceiveFormOtherPatientsData
    | ResetReferralForm
    | ReceiveSchedulingAppointment
    | ReceiveFormRemoveAdditionalPatient
    | ReceiveFormEditAdditionalPatient
    | ReceiveFormEditAdditionalPatientFinished
    | ReceiveFormDeleteAdditionalPatientsFinished
    | ReceiveFormCreateAdditionalPatientsFinished
    | ReceivePrimaryPatientUpdateFinished
    | ReceiveFormSectionMetaData
    | ReceiveUpdateReferralDashboardCard
    | SetDeleteReferralCard

const initialReferralFormState = {
    referral: {
        errors: [],
        dirtyFields: [],
        value: {},
    },
    primaryPatient: {
        errors: [],
        dirtyFields: [],
        value: {},
    },
    attachments: {
        errors: [],
        dirtyFields: [],
        allAttachments: {
            value: {},
        },
        addedAttachments: {
            value: {},
        },
        removedAttachments: {
            value: {},
        },
    },
    otherPatients: {
        errors: [],
        dirtyFields: [],
        value: [],
        removedPatients: [],
        editedPatients: [],
    },
}
const initialState: ReferralsState = {
    numberOfReferralsInReview: 0,
    referralErrors: {},
    referralAttachments: {},
    referralActivityLog: {},
    patientsFinancialData: {},
    schedulingAppointments: {},
    connectAppointments: {},
    referralsDashboard: {
        unclaimed: {
            referrals: [],
        },
        in_progress: {
            referrals: [],
        },
        review: {
            referrals: [],
        },
        complete: {
            referrals: [],
        },
    },
    referralForm: _.cloneDeep(initialReferralFormState),
}

export function reducer(state: ReferralsState = initialState, action: ReferralsAction) {
    switch (action.type) {
        case 'RECEIVE_FORM_VALUE_CHANGE': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    if (
                        [ReferralFormSections.REFERRAL, ReferralFormSections.PRIMARY_PATIENT].includes(action.section)
                    ) {
                        next[action.section]['value'] = {
                            ...next[action.section]['value'],
                            [action.field]: action.value,
                        }
                        next[action.section]['dirtyFields'] = _.union(next[action.section]['dirtyFields'], [
                            action.field,
                        ])
                    }

                    // just to mark dirty other patients and attachment sections
                    if (
                        [
                            ReferralFormSections.OTHER_PATIENTS,
                            ReferralFormSections.ADDED_ATTACHMENTS,
                            ReferralFormSections.REMOVED_ATTACHMENTS,
                            ReferralFormSections.REFERRAL_ATTACHMENTS,
                        ].includes(action.section)
                    ) {
                        next[action.section]['dirtyFields'] = _.union(next[action.section]['dirtyFields'], [
                            action.field,
                        ])
                    }

                    return next
                },
            )
        }

        case 'RECEIVE_REFERRALS_DASHBOARD': {
            return iassign(
                state,
                next => next.referralsDashboard,
                next => {
                    const status = action.status
                    const newReferrals = action.referrals.map((referral: ModelsV2.Referrals.ReferralsDashboardCard) => {
                        return {
                            ...referral,
                            isUnattended: isReferralUnattended(referral),
                        }
                    })
                    next[status].pagination_info = action.pagination_info
                    next[status].current_page = action.page

                    if (action.page === 1) {
                        next[status].referrals = newReferrals
                    } else {
                        next[status].referrals = _.unionBy(next[status].referrals, newReferrals, 'id')
                    }
                    return next
                },
            )
        }

        case 'RECEIVE_UPDATE_REFERRAL_DASHBOARD_CARD': {
            return iassign(
                state,
                next => next.referralsDashboard,
                next => {
                    const newReferralCard = action.referralCard
                    const rid = newReferralCard.id
                    let referralWasInCompletedState = false

                    // remove updated referral if found in spefic column
                    if (next.unclaimed.referrals.find(referral => referral.id === rid)) {
                        next.unclaimed.referrals = next.unclaimed.referrals.filter(r => r.id !== rid)
                    } else if (next.in_progress.referrals.find(referral => referral.id === rid)) {
                        next.in_progress.referrals = next.in_progress.referrals.filter(r => r.id !== rid)
                    } else if (next.review.referrals.find(referral => referral.id === rid)) {
                        next.review.referrals = next.review.referrals.filter(r => r.id !== rid)
                    } else if (next.complete.referrals.find(referral => referral.id === rid)) {
                        referralWasInCompletedState = true
                        next.complete.referrals = next.complete.referrals.filter(r => r.id !== rid)
                    }

                    newReferralCard.isUnattended = isReferralUnattended(newReferralCard)

                    // re-add new referral to specific column
                    const columnName = ['unclaimed', 'in_progress', 'review'].includes(
                        newReferralCard.amplify_status.value,
                    )
                        ? newReferralCard.amplify_status.value
                        : 'complete'

                    const newReferralColumnState = [...next[columnName].referrals, newReferralCard]
                    next[columnName].referrals = newReferralColumnState

                    // increment completed referrals allRows count if new completed referall
                    if (
                        !referralWasInCompletedState &&
                        columnName === 'complete' &&
                        next.complete?.pagination_info?.allRows &&
                        next.complete.pagination_info.allRows > 0
                    ) {
                        next.complete.pagination_info.allRows = next.complete.pagination_info.allRows + 1
                    }

                    return next
                },
            )
        }

        case 'SET_DELETE_REFERRAL_CARD': {
            return iassign(
                state,
                next => next.referralsDashboard,
                next => {
                    const rid = action.referralId

                    // remove referral if found in specific column
                    if (next.unclaimed.referrals.find(referral => referral.id === rid)) {
                        next.unclaimed.referrals = next.unclaimed.referrals.filter(r => r.id !== rid)
                    } else if (next.in_progress.referrals.find(referral => referral.id === rid)) {
                        next.in_progress.referrals = next.in_progress.referrals.filter(r => r.id !== rid)
                    } else if (next.review.referrals.find(referral => referral.id === rid)) {
                        next.review.referrals = next.review.referrals.filter(r => r.id !== rid)
                    } else if (next.complete.referrals.find(referral => referral.id === rid)) {
                        next.complete.referrals = next.complete.referrals.filter(r => r.id !== rid)
                        // decrement completed referrals allRows count if we delete a referral
                        if (next.complete?.pagination_info?.allRows && next.complete.pagination_info.allRows > 0) {
                            next.complete.pagination_info.allRows = next.complete.pagination_info.allRows - 1
                        }
                    }

                    return next
                },
            )
        }

        case 'SET_NUMBER_OF_REFERRALS_IN_REVIEW_STATE': {
            return iassign(state, next => {
                next.numberOfReferralsInReview = action.numberOfReferralsInReview
                return next
            })
        }

        case 'RECEIVE_REFERRAL_ATTACHMENTS': {
            return iassign(
                state,
                next => next.referralAttachments,
                next => {
                    next[action.referralId] = action.attachments
                    return next
                },
            )
        }

        case 'RECEIVE_REFERRAL_ACTIVITY_LOG': {
            return iassign(
                state,
                next => next.referralActivityLog,
                next => {
                    next[action.referralId] = action.referralActivityLog
                    return next
                },
            )
        }

        case 'RECEIVE_REFERRAL_PATIENTS_FINANCIAL_DATA': {
            return iassign(
                state,
                next => next.patientsFinancialData,
                next => {
                    next[action.patientId] = action.patientsFinancialData
                    return next
                },
            )
        }

        case 'RECEIVE_REFERRAL_SCHEDULING_APPOINTMENT': {
            return iassign(
                state,
                next => next.schedulingAppointments,
                next => {
                    next[action.scheduling_appointment_id] = action.schedulingAppointment
                    return next
                },
            )
        }

        case 'RECEIVE_REFERRAL': {
            return iassign(
                state,
                next => next.referralForm.referral.value,
                next => {
                    next = action.referral
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_REFERRAL_DATA': {
            return iassign(
                state,
                next => next.referralForm.referral,
                next => {
                    next.value = {
                        ...next.value,
                        ...action.data,
                    }
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_PRIMARY_PATIENT_DATA': {
            return iassign(
                state,
                next => next.referralForm.primaryPatient,
                next => {
                    next.value = action.data
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_OTHER_PATIENTS_DATA': {
            return iassign(
                state,
                next => next.referralForm.otherPatients,
                next => {
                    next.value = action.data
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_ATTACHMENTS': {
            return iassign(
                state,
                next => next.referralForm.attachments,
                next => {
                    if (action.attachments.allAttachments) {
                        next.allAttachments.value = action.attachments.allAttachments
                    }
                    if (action.attachments.addedAttachments) {
                        next.addedAttachments.value = action.attachments.addedAttachments
                    }
                    if (action.attachments.removedAttachments) {
                        next.removedAttachments.value = action.attachments.removedAttachments
                    }
                    return next
                },
            )
        }

        case 'RECEIVE_ERRORS': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    const errors = action.errors.map(err => {
                        if (action.uiIndex !== undefined) {
                            err.uiIndex = action.uiIndex
                        }
                        return err
                    })

                    if (action.uiIndex) {
                        next[action.section]['errors'] = [...next[action.section]['errors'], ...errors]
                    } else {
                        next[action.section]['errors'] = errors
                    }
                    return next
                },
            )
        }

        case 'RESET_REFERRAL_FORM': {
            const nextState = iassign(
                state,
                next => next.referralForm,
                next => {
                    next = _.cloneDeep(initialReferralFormState)
                    return next
                },
            )
            return nextState
        }

        case 'RECEIVE_FORM_REMOVE_ADDITIONAL_PATIENT': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    next.otherPatients.removedPatients = _.unionBy(next.otherPatients.removedPatients, [
                        action.patientId,
                    ])

                    return next
                },
            )
        }

        case 'RECEIVE_FORM_EDIT_ADDITIONAL_PATIENT': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    const isEditedBefore = next.otherPatients.editedPatients.find(
                        patient => patient.patientId === action.patientId,
                    )
                    if (isEditedBefore) {
                        next.otherPatients.editedPatients.map(patient => {
                            if (patient.patientId === action.patientId) {
                                patient.dirtyFields = _.unionBy(patient.dirtyFields, [action.field])
                            }
                            return patient
                        })
                    } else {
                        next.otherPatients.editedPatients.push({
                            patientId: action.patientId,
                            dirtyFields: [action.field],
                        })
                    }

                    return next
                },
            )
        }

        case 'RECEIVE_FORM_EDIT_ADDITIONAL_PATIENT_FINISHED': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    next.otherPatients.editedPatients = next.otherPatients.editedPatients.filter(patient => {
                        return patient.patientId !== action.patientId
                    })
                    next.otherPatients.errors = next.otherPatients.errors.filter(err => {
                        return err.uiIndex !== action.uiIndex
                    })
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_DELETE_ADDITIONAL_PATIENTS_FINISHED': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    next.otherPatients.removedPatients = _.difference(
                        next.otherPatients.removedPatients,
                        action.deletedPatients.map(patient => patient.id),
                    )
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_CREATE_ADDITIONAL_PATIENT_FINISHED': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    next.otherPatients.value = next.otherPatients.value.map((patient, index) => {
                        if (index === action.uiIndex) {
                            return {
                                ...patient,
                                id: action.patientId,
                            }
                        }
                        next.otherPatients.errors = next.otherPatients.errors.filter(err => {
                            return err.uiIndex !== action.uiIndex
                        })
                        return patient
                    })
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_EDIT_PRIMARY_PATIENT_FINISHED': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    next.primaryPatient.dirtyFields = []
                    next.primaryPatient.errors = []
                    return next
                },
            )
        }

        case 'RECEIVE_FORM_SECTION_METADATA': {
            return iassign(
                state,
                next => next.referralForm,
                next => {
                    if (action.meta.dirtyFields) {
                        next[action.section]['dirtyFields'] = action.meta.dirtyFields
                    }
                    if (action.meta.errors) {
                        next[action.section]['errors'] = action.meta.errors
                    }
                    return next
                },
            )
        }

        default:
            return state
    }
}

function isReferralUnattended(referral: ModelsV2.Referrals.ReferralsDashboardCard) {
    const now = moment()
    const tenMinutesAfterLastUpdate = moment(referral.updated).add(10, 'minutes')
    const isUnattended =
        !['complete', 'new', 'scheduled', 'follow_up'].includes(referral.amplify_status.value) &&
        moment(tenMinutesAfterLastUpdate).isBefore(now)

    return isUnattended
}
