import * as React from 'react'
import Switch from '@mui/material/Switch'
import { SwitchClassKey } from '@mui/material/Switch'

import './SelfSchedulingAdditionalFeaturesAndFieldsSwitch.sass'

type Props = {
    title: string
    checked: boolean
    enableSwitch: (enabled: boolean) => void
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const moduleName = 'self-scheduling-additional-features-and-fields-switch'

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch-root`,
    checked: `${moduleName}__switch--checked`,
}

function SelfSchedulingAdditionalFeaturesAndFieldsSwitch({ title, checked, enableSwitch }: Props) {
    const handleEnableSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        enableSwitch(checked)
    }

    return (
        <div className={moduleName}>
            <h3 className={`${moduleName}__title`}>{title}</h3>
            <div className={`${moduleName}__switch`}>
                <Switch
                    classes={switchClassOverrides}
                    color="primary"
                    checked={checked}
                    onChange={handleEnableSwitch}
                />
            </div>
        </div>
    )
}

export default SelfSchedulingAdditionalFeaturesAndFieldsSwitch
