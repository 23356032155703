import * as React from 'react'

export default function LeftSocket() {
    // tslint:disable
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="400" height="73" viewBox="0 0 400 73">
            <g fill="none" fillRule="evenodd">
                <path fill="#B8E986" d="M0 43h313V31H0z" />
                <path fill="#2B7704" d="M315.068 42.981h-13.013c-2.537-4.392-1.975-8.196 0-12h13.013v12z" />
                <path fill="#3DA50C" d="M360.959 73c-28.37 0-51.37-16.342-51.37-36.5s23-36.5 51.37-36.5" />
                <path
                    fill="#B8E986"
                    d="M400 36.5c0 20.158-16.406 36.5-36.644 36.5s-36.644-16.342-36.644-36.5S343.118 0 363.356 0 400 16.342 400 36.5"
                />
                <path
                    fill="#000"
                    d="M345.205 35.477V25.243c0-2.627 2.158-4.776 4.795-4.776s4.795 2.15 4.795 4.776v10.234c0 2.626-2.158 4.775-4.795 4.775s-4.795-2.149-4.795-4.775M371.918 35.477V25.243c0-2.627 2.157-4.776 4.794-4.776 2.637 0 4.795 2.15 4.795 4.776v10.234c0 2.626-2.158 4.775-4.795 4.775s-4.794-2.149-4.794-4.775M360.93 50.486h6.222c2.245 0 4.08 1.83 4.08 4.064v1.423c0 2.235-1.835 4.064-4.08 4.064h-6.223c-2.244 0-4.08-1.829-4.08-4.064V54.55c0-2.235 1.836-4.064 4.08-4.064"
                />
            </g>
        </svg>
    )
}
