export function encode(str: string) {
    str = str.replace(/&/g, '&#38;')
    str = str.replace(/"/g, '&#34;')
    str = str.replace(/'/g, '&#39;')
    return str
}

export function decode(str: string) {
    return str.replace(/&amp;/gi, '&').replace(/(&#(\d+);)/g, (match, capture, charCode) => {
        return String.fromCharCode(charCode)
    })
}
