export const defaultUSTimezoneList: string[] = [
    'America/Adak',
    'America/Anchorage',
    'America/Boise',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/New_York',
    'America/Phoenix',
    'America/Puerto_Rico',
    'America/St_Thomas',
    'Pacific/Guam',
    'Pacific/Honolulu',
    'Pacific/Majuro',
    'Pacific/Palau',
    'Pacific/Saipan',
    'Pacific/Wake',
]

export const defaultTimezone = 'Europe/London' // UTC

export const terminalColors = {
    green: '#2D7B09',
    gold: '#BFA218',
    aqua: '#0db0bf',
    blue: '#346BC7',
    navy: '#2F3A77',
    pink: '#DE55C6',
    purple: '#8B19B8',
    red: '#C82323',
    orange: '#FB8532',
    brown: '#965A04',
    gray: '#737373',
}
