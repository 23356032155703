import * as React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import iassign from 'immutable-assign'
import validator from 'validator'

import { CustomFieldType } from '../../../models/enums'
import CustomField from '../../shared/custom-fields/CustomField'
import { FormFieldElement, validate } from '../../shared/form-validator/validator'

import './StaffFormNotificationEmailModal.sass'

interface StaffFormNotificationEmailModalProps {
    value?: string
}

export type StaffFormNotificationEmailModalDispatch = {
    onClose: () => void
    onSaveEmail: (email: string, oldEmail?: string) => void
    onRemoveEmail: (email: string) => void
}

interface FormElements {
    email: FormFieldElement
    repeatEmail: FormFieldElement
}

export type StaffFormNotificationEmailState = {
    formElements: FormElements
    isFormValid: boolean
    isFormDirty: boolean
    showFormErrors: boolean
}

type Props = StaffFormNotificationEmailModalProps & StaffFormNotificationEmailModalDispatch

class StaffFormNotificationEmail extends React.Component<Props, StaffFormNotificationEmailState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            formElements: {
                email: {
                    value: this.props.value || '',
                    validators: [
                        {
                            type: (value: string) => validator.isEmail(value),
                            errorMessage: 'Invalid email address',
                        },
                    ],
                    isValid: false,
                    isDirty: false,
                },
                repeatEmail: {
                    value: '',
                    validators: [
                        {
                            type: (value: string) => {
                                if (value) {
                                    return validator.isEmail(value)
                                }
                                return true
                            },
                            errorMessage: 'Invalid email address',
                        },
                        {
                            type: (value: string) => {
                                if (value) {
                                    return Boolean(value === this.state.formElements.email.value)
                                }
                                return true
                            },
                            errorMessage: 'Email addresses don’t match.',
                        },
                    ],
                    isValid: false,
                    isDirty: false,
                },
            },
            isFormValid: false,
            isFormDirty: false,
            showFormErrors: true,
        }
    }

    onClose = () => {
        this.props.onClose()
    }

    onSave = () => {
        this.setState(
            iassign(this.state, (next: StaffFormNotificationEmailState) => {
                return next
            }),
            () => {
                if (this.state.isFormValid) {
                    this.props.onSaveEmail(this.state.formElements.email.value, this.props.value)
                    this.onClose()
                    return
                }
            },
        )
    }

    onUpdate = (modifier: (changes: FormElements) => void) => {
        return this.setState(
            iassign(this.state, (next: StaffFormNotificationEmailState) => {
                modifier(next.formElements)
                next.isFormDirty = true
                return next
            }),
            this.validate,
        )
    }

    validate() {
        this.setState(
            iassign(this.state, (next: StaffFormNotificationEmailState) => {
                next.isFormValid = validate(this.state.formElements)
                return next
            }),
        )
    }

    render() {
        const { showFormErrors, formElements } = this.state

        return (
            <div>
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            this.onClose()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents staff-form-notification-email-modal">
                        <div className="close-modal-button" onClick={this.onClose}>
                            <i className="material-icons">close</i>
                        </div>
                        <div className="title">
                            {this.props.value ? 'Edit Email Address' : 'Add Additional Email Address'}
                        </div>
                        <div className="instruction">
                            Please enter a secondary email address. This address will receive the same email
                            notifications as primary email.
                        </div>
                        <CustomField
                            required={true}
                            error={showFormErrors && formElements.email.firstErrorMessage}
                            errorMessage={formElements.email.firstErrorMessage}
                            value={formElements.email.value}
                            customFieldType={CustomFieldType.INPUT}
                            showErrorText={true}
                            label="Email Address*"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                this.onUpdate(({ email: u }) => {
                                    u.value = event.target.value.trim()
                                    u.isDirty = true
                                })
                            }
                        />
                        <CustomField
                            required={true}
                            error={showFormErrors && formElements.repeatEmail.firstErrorMessage}
                            errorMessage={formElements.repeatEmail.firstErrorMessage}
                            value={formElements.repeatEmail.value}
                            customFieldType={CustomFieldType.INPUT}
                            label="Confirm Email Address*"
                            showErrorText={true}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                this.onUpdate(({ repeatEmail: u }) => {
                                    u.value = event.target.value.trim()
                                    u.isDirty = true
                                })
                            }
                        />
                        <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                            <Grid item={true}>
                                <Button color="primary" variant="outlined" onClick={this.onClose} className="primary">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item={true}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.onSave}
                                    disabled={
                                        !this.state.isFormDirty ||
                                        (this.state.showFormErrors && !this.state.isFormValid) ||
                                        !this.state.formElements.email.value ||
                                        !this.state.formElements.repeatEmail.value
                                    }
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default StaffFormNotificationEmail
