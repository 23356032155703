import { SurveyDoctorRecord } from '../../../models/PracticeSurvey'
import { doctorField } from '../../../modules/practices/locations/edit-survey/shared/factories/doctor'

export const mapDoctors = (doctors: ApiV2.Survey.Doctor[]): SurveyDoctorRecord[] =>
    doctors.map(doctor =>
        doctorField({
            id: {
                value: doctor.id,
                isRequired: true,
                isValid: true,
                isDirty: false,
            },
            firstName: {
                value: doctor.first_name,
                isValid: true,
            },
            lastName: {
                value: doctor.last_name,
                isValid: true,
            },
            suffix: {
                isRequired: false,
                value: doctor.suffix,
                isValid: true,
            },
        }),
    )
