import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import MuiSwitch from '@mui/material/Switch'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import {
    LocationInformation,
    OtherOption,
    QuestionOption,
    SurveySection,
    SurveySectionQuestion,
    ValueType,
} from '../../../../../models/PracticeSurvey'
import { setSectionPracticeLocationIds, updateSurveyQuestion } from '../actions'
import LocationMultiselect from '../shared/location-multiselect/LocationMultiselect'
import { validator } from '../shared/validator'

import InputField from './input-field/InputField'
import MultiValueInput from './input-field/MultiValueInput'
import SelectMulti from './select-multi/SelectMulti'
import SelectOne from './select-one/SelectOne'
import { getExcludeLocationIdsBySpecialty, isQuestionRequired, isQuestionVisible } from './utils'

import './SurveyRendered.sass'

const Switch = withStyles({
    checked: {
        '&:hover': {
            backgroundColor: 'rgba(52, 107, 199, 0.1) !important',
        },
    },
})(MuiSwitch as React.ComponentType<any>)

type Props = {
    location: Models.PracticeLocation
}

const moduleName = 'survey-surveys'

const SurveyRenderedEdit = ({ location }: Props) => {
    const {
        surveys,
        countryCode,
        practiceLocations,
        locationInformation,
    }: {
        surveys: SurveySection[]
        countryCode: string
        practiceLocations: Models.PracticeLocation[]
        locationInformation: LocationInformation
    } = useSelector((state: any) => ({
        surveys: state.practiceSurvey[location.id]?.surveys ?? [],
        countryCode: state.practiceSurvey[location.id]?.locationInformation?.country_code_iso_alpha_2?.value,
        practiceLocations: state.practices.practices[location.practiceId].locations,
        locationInformation: state.practiceSurvey[location.id]?.locationInformation ?? {},
    }))

    const isUSLocation = Boolean(locationInformation.address?.country_code_iso_alpha_2.value === 'US')

    const noPaddingBottomQuestions = ['major-holidays1']
    const noPaddingTopQuestions = ['major-holidays2']

    const dispatch = useDispatch()

    const changeList = ({
        survey,
        question,
        options,
        other_option,
    }: {
        survey: SurveySection
        question: SurveySectionQuestion
        options: QuestionOption[]
        other_option?: OtherOption
    }) => {
        const value = other_option?.selected ? other_option.id : options.find(option => option.selected)?.id
        const nextQuestion: SurveySectionQuestion = {
            ...question,
            errorMessage: '',
            isDirty: true,
            answer_schema: {
                ...question.answer_schema,
                options,
                other_option,
            },
        }
        if (question.isRequired && !value) {
            nextQuestion.errorMessage = 'Required'
        }
        if (other_option?.selected && !other_option.input_field.value) {
            nextQuestion.errorMessage = 'Please enter a value'
        }
        nextQuestion.isValid = !nextQuestion.errorMessage
        dispatch(updateSurveyQuestion(location.id, survey, nextQuestion))
    }

    const renderSectionSubHeading = (question: SurveySectionQuestion) => {
        if (question.section_sub_heading) {
            return (
                <Grid item xs={12} md={12} sm={12} className="survey-section__subheading-wrapper">
                    <div className="survey-section__subheading">{question.section_sub_heading}</div>
                </Grid>
            )
        }
        return ''
    }

    const handleChangeInput = (survey: SurveySection, question: SurveySectionQuestion) => (value: ValueType) => {
        const errorMessage = (Array.isArray(value) ? value : [value]).reduce((message, value) => {
            if (message) {
                return message
            }
            return validator(question, value)
        }, '')
        const nextQuestion: SurveySectionQuestion = { ...question }
        if (!nextQuestion.answer_schema) {
            nextQuestion.answer_schema = {}
        }
        nextQuestion.answer_schema.value = value
        nextQuestion.errorMessage = errorMessage || ''
        nextQuestion.isValid = !errorMessage
        nextQuestion.isDirty = true
        dispatch(updateSurveyQuestion(location.id, survey, nextQuestion))
    }

    const handleChangeList = (survey: SurveySection, question: SurveySectionQuestion) => (
        options: QuestionOption[],
        other_option?: OtherOption,
    ) => {
        changeList({ survey, question, options, other_option })
    }

    const onSelectElement = (survey: SurveySection, practiceLocationIds: string[]) => {
        dispatch(setSectionPracticeLocationIds(location.id, survey, practiceLocationIds))
    }

    const renderQuestion = (survey: SurveySection, question: SurveySectionQuestion) => {
        const key = `${question.question_type}_${question.id}`
        switch (question.question_type) {
            case 'input_field':
                return (
                    <InputField
                        question={question}
                        onChange={handleChangeInput(survey, question)}
                        key={key}
                        countryCode={countryCode}
                    />
                )
            case 'input_field_multi_values':
                return (
                    <MultiValueInput
                        question={question}
                        minValuesNumber={1}
                        onChange={handleChangeInput(survey, question)}
                        key={key}
                    />
                )
            case 'select_one':
            case 'select_one_with_other':
                return <SelectOne question={question} onChange={handleChangeList(survey, question)} key={key} />
            case 'select_multiple':
            case 'select_multi_with_other':
                return (
                    <SelectMulti
                        survey={survey}
                        question={question}
                        location={location}
                        onChange={handleChangeList(survey, question)}
                        key={key}
                    />
                )
            default:
                return <></>
        }
    }

    const renderLocationMultiselect = (survey: SurveySection, question?: SurveySectionQuestion) => {
        const excludeIds = getExcludeLocationIdsBySpecialty({
            location,
            practiceLocations,
            survey,
            question,
        })

        return (
            <LocationMultiselect
                practiceId={location.practiceId}
                onSelectElement={practiceLocationIds => {
                    onSelectElement(survey, practiceLocationIds)
                }}
                selectedItems={survey.practiceLocationIds || []}
                excludeIds={excludeIds}
            />
        )
    }

    const generateClassNames = (survey: SurveySection, question: SurveySectionQuestion, index: number) => {
        let classNames =
            survey.firstLabelSize === 'big' && index === 0
                ? `survey-question__label survey-question__label--big`
                : `survey-question__label`

        if (!question.isValid) {
            classNames += ' survey-question__label-invalid'
        }
        return classNames
    }

    const handleToggleAll = (toggleAll: boolean, survey: SurveySection, question: SurveySectionQuestion) => {
        const allOptions = question.answer_schema?.options
        if (!allOptions) {
            return
        }

        const options: QuestionOption[] = [...allOptions]
        options.map(option => {
            option.selected = toggleAll
            return option
        })
        changeList({ survey, question, options })
    }

    const renderSelectAll = (survey: SurveySection, question: SurveySectionQuestion) => {
        const allSelected = question.answer_schema?.options?.every(option => option.selected)
        return (
            <div className="survey-question__switch">
                <Switch
                    checked={allSelected}
                    onClick={() => handleToggleAll(!allSelected, survey, question)}
                    color="primary"
                    inputProps={{
                        'aria-label': 'primary checkbox',
                    }}
                />
                <span className="survey-question__label-optional">Select All</span>
            </div>
        )
    }

    return (
        <div className={`${moduleName}`}>
            {surveys.map(survey => {
                return (
                    <div key={`survey_section_${survey.id}`} className="survey-section survey-section--edit">
                        <Grid
                            container
                            spacing={1}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            className={`grid-container ${
                                survey.sectionHeader === 'big' ? `${moduleName}__header-underline` : ``
                            }`}
                        >
                            {survey.sectionHeader !== 'none' && (
                                <Grid item>
                                    <h4
                                        className={`${
                                            survey.sectionHeader === 'big'
                                                ? `${moduleName}__section-header--big`
                                                : `${moduleName}__section-header`
                                        }`}
                                    >
                                        {survey.display_name}
                                        {survey.isRequired && survey.sectionHeader !== 'big' && '*'}
                                    </h4>
                                </Grid>
                            )}
                            {survey.showPracticeLocationsSelect && (
                                <Grid item xs={12} sm={7} md={5}>
                                    {renderLocationMultiselect(survey)}
                                </Grid>
                            )}
                        </Grid>
                        <div className="survey-section__wrapper">
                            <Grid container spacing={4}>
                                {survey.questions.map((question, index) => {
                                    if (!isQuestionVisible(surveys, question, isUSLocation)) {
                                        return <Fragment key={`id_${survey.id}_${index}`}></Fragment>
                                    }
                                    const surveyQuestionIdentifier = `${survey.name}${question.order_no}`
                                    const questionRequired = isQuestionRequired(surveys, question)
                                    return (
                                        <Fragment key={`id_${survey.id}_${index}`}>
                                            {renderSectionSubHeading(question)}
                                            <Grid
                                                className={classNames({
                                                    [`survey-surveys__no-padding-top`]: noPaddingTopQuestions.includes(
                                                        surveyQuestionIdentifier,
                                                    ),
                                                    [`survey-surveys__no-padding-bottom`]: noPaddingBottomQuestions.includes(
                                                        surveyQuestionIdentifier,
                                                    ),
                                                })}
                                                item
                                                {...question.size}
                                            >
                                                <div
                                                    className={`survey-question survey-question--${question.question_type}`}
                                                >
                                                    <div
                                                        id={question.id}
                                                        className={generateClassNames(survey, question, index)}
                                                    >
                                                        {question.question_text}
                                                        {questionRequired && '*'}
                                                        {!questionRequired &&
                                                            !question.variants?.includes('hide-optional') && (
                                                                <span className="survey-question__label-optional">
                                                                    (optional)
                                                                </span>
                                                            )}
                                                        {question.showPracticeLocationsSelect && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={7}
                                                                md={5}
                                                                className="survey-question__location-select"
                                                            >
                                                                {renderLocationMultiselect(survey, question)}
                                                            </Grid>
                                                        )}
                                                        {question.variants?.includes('toggle-all') && (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={7}
                                                                md={5}
                                                                className="survey-question__location-select"
                                                            >
                                                                {renderSelectAll(survey, question)}
                                                            </Grid>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={`survey-question__fields survey-question--${question.question_type}`}
                                                    >
                                                        {renderQuestion(survey, question)}
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Fragment>
                                    )
                                })}
                            </Grid>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SurveyRenderedEdit
