import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import Switch, { SwitchClassKey } from '@mui/material/Switch'
import merge from 'lodash/merge'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import { ConnectBookingFormElements, ConnectPaymentStatus, PatientType } from '../../../models/Connect'
import { CustomFieldType, SimplifeyePayments } from '../../../models/enums'
import PubNubService from '../../../services/PubNubService'
import { newObjectId } from '../../../util/objectId'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchLocationConnectPaymentInfoData } from '../../practices/actions'
import CustomField from '../../shared/custom-fields/CustomField'
import CustomPhoneInput from '../../shared/custom-fields/CustomPhoneInput'
import MaskedInlineDatepicker from '../../shared/custom-fields/MaskedInlineDatepicker'
import WebcodeLocationMultiselectContainer from '../../shared/custom-fields/webcode-location-multiselect/WebcodeLocationMultiselectContainer'
import { usePrevious, useResponsiveClasses } from '../../shared/custom-hooks'
import { isDobDate } from '../../shared/form-validator/isDateValid'
import { FormFieldElement } from '../../shared/form-validator/validator'
import { veryLightGreen } from '../../shared/styles/colors'
import { ConnectIcon } from '../../shared/svgIcons'
import { PaymentCanceled, PaymentProgress, PaymentVerified } from '../../shared/svgIcons'
import { updateConnectPayerId } from '../actions'
import { OnSendMessageParams } from '../chat-tiles-container-hooks/useMessaging'

import {
    bookTeleConnect,
    createPatient,
    createTelehealthSession,
    getPaymentClientSecret,
    initConnect,
    shareTeleConnect,
    updateConnect,
    updateConnectPaymentStatus,
    updateFormFields,
} from './actions'

import './ConnectBookingForm.sass'

const moduleName = 'connect-booking'
const DATE_FORMAT = 'MM/DD/YYYY'
const DATE_MASK = '99/99/9999'
const CONNECT_TELEHEALTH_HOST = process.env.REACT_APP_CONNECT_TELEHEALTH_HOST

enum paymentOptions {
    Optional = 1,
    Required = 2,
    Never = 3,
}

type ErrorDetailsType = 'duplicate_email' | 'doxy_url_missing'
interface ErrorDetails {
    type: ErrorDetailsType
    email?: string
    patientName?: string
    showMessage?: boolean
}

export type ConnectBookingFormDispatch = {}

export type Props = {
    chat: Models.ChatCenterSelectedChat
    webCode: Models.WebCode
    onSendMessage: (sendMessageParams: OnSendMessageParams) => void
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch-base`,
}

const Loading = () => (
    <div className={`${moduleName}__pending`}>
        <ReactLoading type="spin" color={veryLightGreen} height={120} width={120} />
    </div>
)

const ConnectBookingForm = ({ webCode, chat, onSendMessage }: Props) => {
    const practice = useSelector((state: RootState) => state.practices.practices[chat.practice.id])
    const connectBooking = useSelector((state: RootState) => state.bookings.connect[chat.id])
    const [errorDetails, setErrorDetails] = useState<ErrorDetails | undefined>()
    const dispatch = useAppDispatch()
    const { connect } = webCode
    const isSubmitting = connectBooking?.isPending ?? false
    const acceptablePatientType = connect?.patient_type.value
    const isInsuranceRequired = connect?.insurance_information_status.value === 'required'
    const formElements = connectBooking?.formElements
    const doxyURLIndex = formElements?.doxyURLIndex.value === '' ? null : Number(formElements?.doxyURLIndex.value)

    const { ref } = useResponsiveClasses({
        breakpoints: {
            width: {
                [`${moduleName}--small`]: 0,
                [`${moduleName}--default`]: 380,
                [`${moduleName}--large`]: 800,
            },
        },
    })

    const locationId = formElements?.practiceLocation.value

    const connectBookingFormElementsDateOfBirthValue = connectBooking?.formElements.dateOfBirth.value
    const connectBookingFormElementsEmailValue = connectBooking?.formElements.email.value
    const connectBookingFormElementsFirstNameValue = connectBooking?.formElements.firstName.value
    const connectBookingFormElementsLastNameValue = connectBooking?.formElements.lastName.value
    const connectBookingFormElementsMobilePhoneValue = connectBooking?.formElements.mobilePhone.value
    const connectBookingPaymentClientSecret = connectBooking?.paymentClientSecret

    const practiceLocation = useMemo(() => {
        if (locationId) {
            return practice.locations.find(location => location.id === locationId)
        }
        return
    }, [practice, locationId])

    const isPaymentProductActivated = useMemo(
        () => practice.products.find(product => product.value === SimplifeyePayments.value)?.active === true,
        [practice.products],
    )

    const isPaymentAvailable =
        isPaymentProductActivated &&
        practiceLocation?.connectPaymentInfo !== undefined &&
        practiceLocation?.connectPaymentInfo !== null &&
        [paymentOptions.Required, paymentOptions.Optional].includes(
            practiceLocation?.connectPaymentInfo?.paymentInformationStatusId,
        )

    useEffect(() => {
        if (!connectBooking) {
            const initPatientType =
                acceptablePatientType === PatientType.EXISTING ? PatientType.EXISTING : PatientType.NEW

            dispatch(initConnect(chat, isInsuranceRequired, initPatientType, isPaymentProductActivated))
        }
    }, [chat, connectBooking, isInsuranceRequired, acceptablePatientType, isPaymentProductActivated, dispatch])

    useEffect(() => {
        if (practiceLocation && practiceLocation.connectPaymentInfo === undefined) {
            dispatch(fetchLocationConnectPaymentInfoData(practice.id, practiceLocation.id))
        }
    }, [dispatch, practice, practiceLocation])

    const updateConnectDetails = useCallback(
        (isInsuranceRequired, nextPatientType, isPolicyHolder) => {
            dispatch(updateConnect(chat, isInsuranceRequired, nextPatientType, isPolicyHolder))
        },
        [chat, dispatch],
    )

    useEffect(() => {
        if (connectBooking?.patientType && acceptablePatientType) {
            const nextPatientType =
                acceptablePatientType === ('both' || acceptablePatientType === connectBooking.patientType)
                    ? connectBooking.patientType
                    : acceptablePatientType
            if (
                connectBooking.patientType !== nextPatientType ||
                isInsuranceRequired !== connectBooking.isInsuranceRequired
            ) {
                updateConnectDetails(isInsuranceRequired, nextPatientType as PatientType, connectBooking.isPolicyHolder)
            }
        }
    }, [acceptablePatientType, connectBooking, isInsuranceRequired, updateConnectDetails])

    useEffect(() => {
        if (practice.locations.length === 1 && formElements && !formElements.practiceLocation.value) {
            dispatch(
                updateFormFields(
                    chat,
                    merge(formElements, {
                        practiceLocation: {
                            value: practice.locations[0].id,
                            isValid: true,
                        },
                    }),
                ),
            )
        }
    }, [chat, dispatch, formElements, practice.locations])

    useEffect(() => {
        if (webCode.connect?.doxy_urls.length === 1 && formElements && !formElements.doxyURLIndex.value) {
            dispatch(
                updateFormFields(
                    chat,
                    merge(formElements, {
                        doxyURLIndex: {
                            value: '0',
                            isValid: true,
                        },
                    }),
                ),
            )
        }
    }, [chat, dispatch, formElements, webCode.connect])

    const handleRequestPayment = useCallback(async () => {
        const amount = parseFloat(practiceLocation?.connectPaymentInfo?.amount ?? '0')
        if (amount > 0) {
            if (connectBookingPaymentClientSecret) {
                sendOpenPaymentDetailsForm(chat, amount, connectBookingPaymentClientSecret)
                return
            }
            const { payerId, patientId, firstName } = await dispatch(
                createPatient(chat, {
                    patient: {
                        firstName: connectBookingFormElementsFirstNameValue.trim(),
                        lastName: connectBookingFormElementsLastNameValue.trim(),
                        dob: moment(connectBookingFormElementsDateOfBirthValue, DATE_FORMAT).format('YYYY-MM-DD'),
                        phoneNumber: connectBookingFormElementsMobilePhoneValue,
                        email: connectBookingFormElementsEmailValue,
                    },
                }),
            )
            if (payerId && patientId) {
                dispatch(updateConnectPayerId(chat, payerId))
                const clientSecret = await dispatch(getPaymentClientSecret(payerId))
                if (clientSecret) {
                    sendOpenPaymentDetailsForm(chat, amount, clientSecret)
                    dispatch(updateConnectPaymentStatus(chat, ConnectPaymentStatus.Pending))
                } else {
                    alert('Client secret is not valid')
                }
            } else {
                setErrorDetails({
                    type: 'duplicate_email',
                    email: connectBookingFormElementsEmailValue,
                    patientName: firstName || 'another patient',
                    showMessage: true,
                })
            }
        } else {
            alert('Payment amount is not valid')
        }
    }, [
        chat,
        connectBookingFormElementsDateOfBirthValue,
        connectBookingFormElementsEmailValue,
        connectBookingFormElementsFirstNameValue,
        connectBookingFormElementsLastNameValue,
        connectBookingFormElementsMobilePhoneValue,
        connectBookingPaymentClientSecret,
        dispatch,
        practiceLocation,
    ])

    const prevPatientRejoinedChatCounter = usePrevious(chat.patientRejoinedChatCounter) || 0
    useEffect(() => {
        const isPaymentStatusPending = connectBooking?.paymentStatus === ConnectPaymentStatus.Pending
        if (chat.patientRejoinedChatCounter > prevPatientRejoinedChatCounter && isPaymentStatusPending) {
            handleRequestPayment()
        }
    }, [chat.patientRejoinedChatCounter, handleRequestPayment, connectBooking, prevPatientRejoinedChatCounter])

    if (!connectBooking) {
        return <Loading />
    }

    const onUpdate = (modifier: (changes: ConnectBookingFormElements) => ConnectBookingFormElements) => {
        dispatch(updateFormFields(chat, modifier(formElements)))
    }

    const hasError = (field: FormFieldElement) => field.isDirty && !field.isValid

    const isBookingAvailable =
        practiceLocation?.connectPaymentInfo === undefined ||
        practiceLocation?.connectPaymentInfo === null ||
        [paymentOptions.Optional, paymentOptions.Never].includes(
            practiceLocation?.connectPaymentInfo?.paymentInformationStatusId,
        ) ||
        !isPaymentProductActivated

    const setPatientType = (patientType: PatientType) => () => {
        dispatch(updateConnect(chat, isInsuranceRequired, patientType, connectBooking.isPolicyHolder))
    }

    const setIsPolicyHolder = (isPolicyHolder: boolean) => {
        dispatch(updateConnect(chat, isInsuranceRequired, connectBooking.patientType, isPolicyHolder))
    }

    const handleSubmit = async () => {
        const url = doxyURLIndex !== null && webCode.connect?.doxy_urls[doxyURLIndex].url

        if (!url) {
            setErrorDetails({
                type: 'doxy_url_missing',
                showMessage: true,
            })

            return
        }

        await dispatch(createTelehealthSession(chat, { url, practiceUrl: webCode.url }))
        dispatch(bookTeleConnect(practice, webCode, connectBooking))
    }

    const handleCloseError = () => {
        setErrorDetails(undefined)
    }

    const telehealthUri = connectBooking?.connectTelehealthSessionId
        ? `${CONNECT_TELEHEALTH_HOST}/${connectBooking?.connectTelehealthSessionId}`
        : undefined

    const handleShare = () => {
        if (telehealthUri) {
            if (doxyURLIndex === null) {
                setErrorDetails({
                    type: 'doxy_url_missing',
                    showMessage: true,
                })

                return
            }

            const password = webCode.connect?.doxy_urls[doxyURLIndex].password
            const message = `You can join your virtual appointment by visiting ${telehealthUri}${
                password ? ` and entering the password: ${password}` : ''
            }`
            onSendMessage({
                message,
                chatMetadata: chat,
                channel: chat.channelName,
            })
            dispatch(shareTeleConnect(connectBooking.chat))
        } else {
            alert('Telehealth session is not created')
        }
    }

    const sendOpenPaymentDetailsForm = (chat: Models.ChatMetadata, amount: number, clientSecret: string) => {
        PubNubService.send(chat.channelName, {
            id: newObjectId(32),
            text: 'Sent request to open connect payment form in chat widget.',
            is_patient: false,
            sender_id: PubNubService.getChatterId(),
            type: 'system:connect-payment-form-open',
            meta_data: {
                connect_payment_amount: amount,
                payment_client_secret: clientSecret,
            },
        })
    }

    const showPaymentProcess = [
        ConnectPaymentStatus.Pending,
        ConnectPaymentStatus.Canceled,
        ConnectPaymentStatus.Verified,
    ].includes(connectBooking.paymentStatus)

    const isEmailAddressDuplicated =
        formElements.email.value && formElements.email.value.toLowerCase() === errorDetails?.email?.toLowerCase()

    return (
        <Fragment>
            {errorDetails?.showMessage && (
                <div className={`${moduleName}__backdrop`}>
                    <div className={`${moduleName}__error-modal`}>
                        <i className={`material-icons ${moduleName}__close-error`} onClick={handleCloseError}>
                            close
                        </i>
                        <div className={`${moduleName}__error-icon`}>
                            <Icon>error</Icon>
                        </div>

                        {errorDetails.type === 'doxy_url_missing' ? (
                            <p>
                                <strong>The connect survey is missing a url.</strong>
                                &nbsp;Please fill up the connect survey with all the necessary fields.
                            </p>
                        ) : (
                            <p>
                                <strong>
                                    Email address {` ${errorDetails.email} `}
                                    is already associated with {` ${errorDetails.patientName}`}.
                                </strong>
                                &nbsp;Please use a different address or use the correct patient name.
                            </p>
                        )}
                    </div>
                </div>
            )}

            <div
                ref={ref as React.RefObject<any>}
                className={`${moduleName} ${errorDetails?.showMessage && `${moduleName}--has-error`}`}
            >
                {connectBooking.isBooked && (
                    <div className={`${moduleName}__booked`}>
                        <div className={`${moduleName}__booked-icon`}>
                            <ConnectIcon />
                        </div>
                        <h3 className={`${moduleName}__booked-title`}>Tele-doc Session Booked!</h3>
                        <dl>
                            <dt>Enter the Patient Lounge</dt>
                            <dd>
                                {telehealthUri !== undefined && (
                                    <a href={telehealthUri}>{telehealthUri.replace(/^https?:\/\//i, '')}</a>
                                )}
                            </dd>
                            {doxyURLIndex !== null && webCode.connect?.doxy_urls[doxyURLIndex].password && (
                                <Fragment>
                                    <dt>Password</dt>
                                    <dd>{webCode.connect?.doxy_urls[doxyURLIndex].password}</dd>
                                </Fragment>
                            )}
                        </dl>
                        <div className={`${moduleName}__submit`}>
                            <Button
                                disabled={connectBooking.isShared}
                                className={`${moduleName}__submit-button`}
                                onClick={handleShare}
                            >
                                {connectBooking.isShared ? '✓ SHARED IN CHAT' : 'SHARE DETAILS IN CHAT'}
                            </Button>
                        </div>
                    </div>
                )}

                {!connectBooking.isBooked && (
                    <div>
                        {connectBooking.isPending && <Loading />}

                        {showPaymentProcess ? (
                            <div className={`${moduleName}__payment-process`}>
                                {connectBooking.paymentStatus === ConnectPaymentStatus.Pending && (
                                    <Fragment>
                                        <div className={`${moduleName}__payment-process-icon`}>
                                            <PaymentProgress />
                                        </div>
                                        <div className={`${moduleName}__payment-process-label`}>
                                            Patient is filling out payment information
                                        </div>
                                        <Button
                                            className={`${moduleName}__submit-button`}
                                            onClick={handleRequestPayment}
                                        >
                                            Request payment info
                                        </Button>
                                    </Fragment>
                                )}
                                {connectBooking.paymentStatus === ConnectPaymentStatus.Verified && (
                                    <Fragment>
                                        <div className={`${moduleName}__payment-process-icon`}>
                                            <PaymentVerified />
                                        </div>
                                        <div className={`${moduleName}__payment-process-label`}>
                                            Patient has completed payment information.
                                        </div>
                                        <Button
                                            disabled={isSubmitting}
                                            className={`${moduleName}__submit-button`}
                                            onClick={handleSubmit}
                                        >
                                            BOOK TELE-APPOINTMENT
                                        </Button>
                                    </Fragment>
                                )}
                                {connectBooking.paymentStatus === ConnectPaymentStatus.Canceled && (
                                    <Fragment>
                                        <div className={`${moduleName}__payment-process-icon`}>
                                            <PaymentCanceled />
                                        </div>
                                        <div className={`${moduleName}__payment-process-label`}>
                                            Patient has cancelled payment info.
                                        </div>
                                        <Button
                                            className={`${moduleName}__submit-button`}
                                            onClick={handleRequestPayment}
                                        >
                                            Request payment info
                                        </Button>
                                    </Fragment>
                                )}
                            </div>
                        ) : (
                            <Fragment>
                                {acceptablePatientType === 'both' && (
                                    <div className={`${moduleName}__tabs`}>
                                        <button
                                            disabled={isSubmitting}
                                            className={`${moduleName}__tab
                                        ${
                                            connectBooking.patientType === PatientType.NEW
                                                ? `${moduleName}__tab--selected`
                                                : ''
                                        }`}
                                            onClick={setPatientType(PatientType.NEW)}
                                        >
                                            New Patient
                                        </button>
                                        <button
                                            disabled={isSubmitting}
                                            className={`${moduleName}__tab ${connectBooking.patientType ===
                                                PatientType.EXISTING && `${moduleName}__tab--selected`}`}
                                            onClick={setPatientType(PatientType.EXISTING)}
                                        >
                                            Existing Patient
                                        </button>
                                    </div>
                                )}

                                {acceptablePatientType && acceptablePatientType !== 'both' && (
                                    <div className={`${moduleName}__restricted-patient-type`}>
                                        Accepting {connect?.patient_type.display} Patients Only
                                    </div>
                                )}

                                <h3 className={`${moduleName}__section-title`}>Patient Info</h3>

                                <div className={`${moduleName}__field-columns`}>
                                    <div className={`${moduleName}__field`}>
                                        <CustomField
                                            disabled={isSubmitting}
                                            required={true}
                                            value={formElements.firstName.value}
                                            customFieldType={CustomFieldType.INPUT}
                                            label="First Name*"
                                            error={hasError(formElements.firstName)}
                                            errorMessage="This field is required"
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                onUpdate(elements => {
                                                    elements.firstName.value = event.target.value
                                                    elements.firstName.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>

                                    <div className={`${moduleName}__field`}>
                                        <CustomField
                                            disabled={isSubmitting}
                                            required={true}
                                            value={formElements.lastName.value}
                                            customFieldType={CustomFieldType.INPUT}
                                            label="Last Name*"
                                            error={hasError(formElements.lastName)}
                                            errorMessage="This field is required"
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                onUpdate(elements => {
                                                    elements.lastName.value = event.target.value
                                                    elements.lastName.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={`${moduleName}__field-columns`}>
                                    <div className={`${moduleName}__field ${moduleName}__field--datepicker`}>
                                        <MaskedInlineDatepicker
                                            dateMask={DATE_MASK}
                                            dateFormat={DATE_FORMAT}
                                            label="Date of Birth*"
                                            value={formElements.dateOfBirth.value}
                                            disabled={isSubmitting}
                                            error={hasError(formElements.dateOfBirth)}
                                            errorMessage="Invalid birthday date value"
                                            onBlur={() => {
                                                onUpdate(elements => {
                                                    elements.dateOfBirth.isDirty = true
                                                    return elements
                                                })
                                            }}
                                            onInputChange={targetValue => {
                                                onUpdate(elements => {
                                                    elements.dateOfBirth.value = targetValue
                                                    return elements
                                                })
                                            }}
                                            onChange={value => {
                                                onUpdate(elements => {
                                                    elements.dateOfBirth.value = value
                                                    elements.dateOfBirth.isDirty = true
                                                    return elements
                                                })
                                            }}
                                            validator={isDobDate}
                                        />
                                    </div>
                                    <div className={`${moduleName}__field ${moduleName}__field`}>
                                        <CustomPhoneInput
                                            disabled={isSubmitting}
                                            value={formElements.mobilePhone.value}
                                            label="Mobile Phone*"
                                            error={hasError(formElements.mobilePhone)}
                                            errorMessage="Invalid phone number"
                                            country={'US'}
                                            onChange={(value: string) =>
                                                onUpdate(elements => {
                                                    elements.mobilePhone.value = value
                                                    elements.mobilePhone.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={`${moduleName}__field-columns`}>
                                    <div className={`${moduleName}__field`}>
                                        <CustomField
                                            disabled={isSubmitting}
                                            required={isPaymentAvailable}
                                            value={formElements.email.value}
                                            customFieldType={CustomFieldType.INPUT}
                                            label="Email*"
                                            error={hasError(formElements.email) || isEmailAddressDuplicated}
                                            errorMessage={
                                                isEmailAddressDuplicated ? 'Duplicated email address' : 'Invalid email'
                                            }
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                onUpdate(elements => {
                                                    elements.email.value = event.target.value.trim().toLowerCase()
                                                    return elements
                                                })
                                            }
                                            onBlur={() => {
                                                onUpdate(elements => {
                                                    elements.email.isDirty = true
                                                    return elements
                                                })
                                            }}
                                        />
                                    </div>

                                    {practice.locations.length > 1 && (
                                        <div className={`${moduleName}__field`}>
                                            <WebcodeLocationMultiselectContainer
                                                practice={practice}
                                                webCode={webCode}
                                                label={'Practice Location*'}
                                                onlyEnabledWebCodeLocations={true}
                                                selectedItems={[formElements.practiceLocation.value]}
                                                error={hasError(formElements.practiceLocation)}
                                                onSelectElement={(values: string[]) => {
                                                    onUpdate(elements => {
                                                        elements.practiceLocation.value = values[0]
                                                        elements.practiceLocation.isDirty = true
                                                        return elements
                                                    })
                                                }}
                                            />
                                        </div>
                                    )}

                                    {webCode.connect?.doxy_urls && webCode.connect.doxy_urls.length > 1 && (
                                        <div className={`${moduleName}__field`}>
                                            <CustomField
                                                disabled={isSubmitting}
                                                required={true}
                                                customFieldType={CustomFieldType.SELECT}
                                                label="Doxy URL"
                                                error={hasError(formElements.doxyURLIndex)}
                                                errorMessage="Please select a URL"
                                                value={formElements.doxyURLIndex.value}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                                    onUpdate(elements => {
                                                        elements.doxyURLIndex.value = event.target.value
                                                        elements.doxyURLIndex.isDirty = true
                                                        return elements
                                                    })
                                                }}
                                            >
                                                <option value="">Select Doxy URL</option>
                                                {webCode.connect.doxy_urls.map(({ url }, index) => (
                                                    <option key={url} value={index}>
                                                        {url}
                                                    </option>
                                                ))}
                                            </CustomField>
                                        </div>
                                    )}
                                </div>

                                <h3 className={`${moduleName}__section-title`}>
                                    <span className={`${moduleName}__title`}>
                                        Insurance
                                        {isInsuranceRequired && (
                                            <span className={`${moduleName}__required-red`}>*</span>
                                        )}
                                    </span>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={isSubmitting}
                                                color="primary"
                                                checked={connectBooking.isPolicyHolder}
                                                onChange={event => setIsPolicyHolder(event.target.checked)}
                                                classes={switchClassOverrides}
                                            />
                                        }
                                        label="Patient is policyholder"
                                    />
                                </h3>

                                <div className={`${moduleName}__field-columns`}>
                                    <div className={`${moduleName}__field`}>
                                        <CustomField
                                            disabled={isSubmitting}
                                            required={isInsuranceRequired}
                                            value={formElements.insurance.value}
                                            customFieldType={CustomFieldType.INPUT}
                                            label={`Insurance${isInsuranceRequired ? '*' : ''}`}
                                            error={hasError(formElements.insurance)}
                                            errorMessage="This field is required"
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                onUpdate(elements => {
                                                    elements.insurance.value = event.target.value
                                                    elements.insurance.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>

                                    <div className={`${moduleName}__field`}>
                                        <CustomPhoneInput
                                            disabled={isSubmitting}
                                            value={formElements.insurancePhone.value}
                                            label={`Insurance Phone #${isInsuranceRequired ? '*' : ''}`}
                                            error={hasError(formElements.insurancePhone)}
                                            errorMessage="This field is required"
                                            country={'US'}
                                            onChange={(value: string) =>
                                                onUpdate(elements => {
                                                    elements.insurancePhone.value = value
                                                    elements.insurancePhone.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>

                                    <div className={`${moduleName}__field`}>
                                        <CustomField
                                            disabled={isSubmitting}
                                            required={isInsuranceRequired}
                                            value={formElements.insuranceGroup.value}
                                            customFieldType={CustomFieldType.INPUT}
                                            label={`Insurance Group #${isInsuranceRequired ? '*' : ''}`}
                                            error={hasError(formElements.insuranceGroup)}
                                            errorMessage="This field is required"
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                onUpdate(elements => {
                                                    elements.insuranceGroup.value = event.target.value
                                                    elements.insuranceGroup.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>

                                    <div className={`${moduleName}__field`}>
                                        <CustomField
                                            disabled={isSubmitting}
                                            required={isInsuranceRequired}
                                            value={formElements.insuranceMemberId.value}
                                            customFieldType={CustomFieldType.INPUT}
                                            label={`Insurance Member ID/SSN${isInsuranceRequired ? '*' : ''}`}
                                            error={hasError(formElements.insuranceMemberId)}
                                            errorMessage="This field is required"
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                onUpdate(elements => {
                                                    elements.insuranceMemberId.value = event.target.value
                                                    elements.insuranceMemberId.isDirty = true
                                                    return elements
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                {!connectBooking.isPolicyHolder && (
                                    <div className={`${moduleName}__field-columns`}>
                                        <div className={`${moduleName}__field`}>
                                            <CustomField
                                                disabled={isSubmitting}
                                                required={isInsuranceRequired}
                                                value={formElements.policyHolderFirstName.value}
                                                customFieldType={CustomFieldType.INPUT}
                                                label={`Policyholder First Name${isInsuranceRequired ? '*' : ''}`}
                                                error={hasError(formElements.policyHolderFirstName)}
                                                errorMessage="This field is required"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                    onUpdate(elements => {
                                                        elements.policyHolderFirstName.value = event.target.value
                                                        elements.policyHolderFirstName.isDirty = true
                                                        return elements
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className={`${moduleName}__field`}>
                                            <CustomField
                                                disabled={isSubmitting}
                                                required={isInsuranceRequired}
                                                value={formElements.policyHolderLastName.value}
                                                customFieldType={CustomFieldType.INPUT}
                                                label={`Policyholder Last Name${isInsuranceRequired ? '*' : ''}`}
                                                error={hasError(formElements.policyHolderLastName)}
                                                errorMessage="This field is required"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                    onUpdate(elements => {
                                                        elements.policyHolderLastName.value = event.target.value
                                                        elements.policyHolderLastName.isDirty = true
                                                        return elements
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className={`${moduleName}__field ${moduleName}__field--datepicker`}>
                                            <MaskedInlineDatepicker
                                                dateMask={DATE_MASK}
                                                dateFormat={DATE_FORMAT}
                                                value={formElements.policyHolderDateOfBirth.value}
                                                disabled={isSubmitting}
                                                label={`Date of Birth${isInsuranceRequired ? '*' : ''}`}
                                                error={hasError(formElements.policyHolderDateOfBirth)}
                                                errorMessage="Invalid birthday date value"
                                                onBlur={() => {
                                                    onUpdate(elements => {
                                                        elements.policyHolderDateOfBirth.isDirty = true
                                                        return elements
                                                    })
                                                }}
                                                onInputChange={targetValue => {
                                                    onUpdate(elements => {
                                                        elements.policyHolderDateOfBirth.value = targetValue
                                                        return elements
                                                    })
                                                }}
                                                onChange={value => {
                                                    onUpdate(elements => {
                                                        elements.policyHolderDateOfBirth.value = value
                                                        elements.policyHolderDateOfBirth.isDirty = true
                                                        return elements
                                                    })
                                                }}
                                                validator={isDobDate}
                                            />
                                        </div>
                                    </div>
                                )}

                                {connectBooking.hasError && (
                                    <div className={`${moduleName}__error`}>
                                        {connectBooking.errorMessage || 'Booking Error'}
                                    </div>
                                )}

                                {isPaymentAvailable && (
                                    <div className={`${moduleName}__submit`}>
                                        <Button
                                            disabled={!connectBooking.isFormValid}
                                            className={`${moduleName}__submit-button`}
                                            onClick={handleRequestPayment}
                                        >
                                            Request payment info
                                        </Button>
                                    </div>
                                )}

                                {isBookingAvailable && (
                                    <div className={`${moduleName}__submit`}>
                                        <Button
                                            disabled={!connectBooking.isFormValid || isSubmitting}
                                            className={`${moduleName}__submit-button`}
                                            onClick={handleSubmit}
                                        >
                                            BOOK TELE-APPOINTMENT
                                        </Button>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export default ConnectBookingForm
