// TO-DO-V2: completely remove all from this file and fn calls app wide
// when we rewrite list referrals in diagnostics section
import moment from 'moment-timezone'

import { mapPractice } from '../../../modules/practices/reducer'

export const mapReferralHistory = (
    history: ApiV2.Referrals.ReferralStatusHistory,
): ModelsV2.Referrals.ReferralStatusHistory => {
    return { ...history, created: new Date(history.created) }
}

export function mapOtherPatients(
    patient: ApiV2.Referrals.AdditionalPatientData,
): ModelsV2.Referrals.AdditionalPatientData {
    return {
        patientName: patient.patient_name,
        firstName: patient.first_name,
        lastName: patient.last_name,
        dateOfBirth: patient.date_of_birth,
        reason: patient.reason,
        operatoryName: patient.operatory_name,
        procedure: patient.procedure,
        providerName: patient.provider_name,
        appointmentTime: patient.appointment_time,
        schedulingAppointmentId: patient.scheduling_appointment_id,
        jarvisPatientId: patient.jarvis_patient_id,
        financialData: patient.financial_data,
    }
}

export const mapReferral = (referral: ApiV2.Referrals.Referral): ModelsV2.Referrals.Referral => {
    const timezone = 'America/New_York'

    return {
        id: referral.id,
        created: new Date(referral.created),
        updated: new Date(referral.updated),
        practice: referral.practice && mapPractice(referral.practice),
        location: referral.location,
        date: moment(referral.date)
            .tz(timezone)
            .format('MM/DD/YYYY'),
        time: moment(referral.time, 'HH:mm:ss')
            .tz(timezone)
            .format('h:mm A z'),
        status: referral.status,
        history: referral.history && referral.history.map(mapReferralHistory),
        referralCreatorId: referral.referral_creator_id,
        inboundHandlerId: referral.inbound_handler_id,
        referral_writer: referral.referral_writer,
        chatter: referral.chatter,
        legacyMessageId: referral.legacy_message_id,
        legacyFileId: referral.legacy_file_id,
        amplify_referral_patients: referral.amplify_referral_patients,
        patientName: undefined,
        patientData: undefined,
        attachments: referral.attachments,
        transcript: referral.transcript,
        archived: referral.archived,
        isTest: referral.is_test,
        chat: referral.chat_data && {
            surveyId: referral.chat_data.survey_id,
            practiceSpecialty: referral.chat_data.practice_specialty,
            webCodeKey: referral.chat_data.web_code_key,
        },
        directScheduled: referral.direct_scheduled,
        tags: referral.tags,
    }
}

export const mapReferralV2 = (referral: ApiV2.Amplify.ReferralV2): ModelsV2.Referrals.Referral => {
    const timezone = 'America/New_York'

    return {
        id: referral.id,
        created: new Date(referral.created),
        updated: new Date(referral.updated),
        date: moment(referral.date)
            .tz(timezone)
            .format('MM/DD/YYYY'),
        time: moment(referral.time, 'HH:mm:ss')
            .tz(timezone)
            .format('h:mm A z'),
        status: referral.amplify_status,
        referral_writer: referral.referral_creator,
        referralCreatorId: referral.referral_creator_id,
        inboundHandlerId: referral.inbound_handler_id,
        inboundHandler: referral.inbound_handler,
        legacyMessageId: referral.legacy_message_id,
        legacyFileId: referral.legacy_file_id,
        amplify_referral_patients: referral.amplify_referral_patient,
        patientName: undefined,
        patientData: undefined,
        transcript: referral.transcript,
        archived: referral.archived,
        isTest: referral.is_test,
        //chat: referral.amplify_chat_metadata,
        directScheduled: referral.direct_scheduled,
        tags: referral.tags,
        location: { id: referral.location_id },
        attachments: [],
    }
}
