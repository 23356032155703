import _ from 'lodash'
import { AvailableHours } from 'models/Connect'
import moment from 'moment'

import {
    LocationInformation,
    OperationDay,
    QuestionOption,
    SurveyAnswerSchema,
    SurveyDoctorRecord,
    SurveySection,
    SurveySectionQuestion,
    SurveySectionValidationType,
} from '../../../models/PracticeSurvey'
import {
    RENDER_TIME_FORMAT,
    SHADOW_TIME_FORMAT,
    weekDays,
} from '../../../modules/practices/locations/edit-survey/operating-hours/const'
import { formatUSPhoneNumbers } from '../../../modules/shared/phone-number-formatter/formatPhoneNumber'

export const surveyFormatConnectHours = (
    connectStatus: 'enabled' | 'disabled' | undefined,
    connectHours: AvailableHours[] | undefined,
    timezone: string | undefined,
): Models.SurveyResponse[] => {
    if (!connectHours || !connectHours.length || connectStatus !== 'enabled') {
        return []
    }

    const timezoneAbbr = timezone ? ` (${moment.tz(timezone).format('z')})` : ``

    const answers = connectHours.map(day => {
        if (!day.available) {
            return `${weekDays[day.dayOfWeek]} - Closed`
        }
        return `${weekDays[day.dayOfWeek]} - ${moment(day.startTime, SHADOW_TIME_FORMAT).format(
            RENDER_TIME_FORMAT,
        )} - ${moment(day.endTime, SHADOW_TIME_FORMAT).format(RENDER_TIME_FORMAT)}${timezoneAbbr}`
    })

    return [
        {
            id: _.uniqueId('id_'),
            question: `Connect Hours`,
            answers: answers,
        },
    ]
}

// maping survey response for chat/referrals
export const surveyFormatDoctors = (doctors: SurveyDoctorRecord[] | undefined): Models.SurveyResponse[] => {
    if (!doctors || !doctors.length) {
        return []
    }

    const answers = doctors.map(doctor => {
        if (doctor.suffix.value && doctor.suffix.value !== '') {
            return `${doctor.firstName.value} ${doctor.lastName.value}, ${doctor.suffix.value}`
        }
        return `${doctor.firstName.value} ${doctor.lastName.value}`
    })

    return [
        {
            id: _.uniqueId('id_'),
            question: 'Doctor(s)',
            answers: answers,
        },
    ]
}
export const surveyFormatLocationInformation = (
    locationInformation: LocationInformation | undefined,
): Models.SurveyResponse[] => {
    if (!locationInformation || !locationInformation.address) {
        return []
    }
    const address = locationInformation.address
    const unit = address.unit?.value
    const street = address.street?.value + (unit ? ` ${unit}` : '')
    const city = address.city?.value
    const state = address.state?.value
    const zip = address.zip?.value
    const countryCode = address.country_code_iso_alpha_2?.value

    let addressAnswer = []
    street && addressAnswer.push(street)
    city && addressAnswer.push(city)
    state && addressAnswer.push(state)
    zip && addressAnswer.push(zip)
    countryCode && addressAnswer.push(countryCode)
    const answers = [addressAnswer.join(', ')]

    return [
        {
            id: _.uniqueId('id_'),
            question: 'Address(es):',
            answers: answers,
        },
    ]
}
export const surveyFormatOperatingHours = (operatingHours: OperationDay[] | undefined): Models.SurveyResponse[] => {
    if (!operatingHours || !operatingHours.length) {
        return []
    }

    const answers = operatingHours.map(day => {
        if (!day.close && !day.open) return ''

        if (!day.enabled) return `${weekDays[day.dayOfWeek - 1]} CLOSED`

        return `${weekDays[day.dayOfWeek - 1]} ${moment(day.open, SHADOW_TIME_FORMAT).format(
            RENDER_TIME_FORMAT,
        )} to ${moment(day.close, SHADOW_TIME_FORMAT).format(RENDER_TIME_FORMAT)}`
    })
    if (answers.filter(day => day !== '').length < 1) {
        return []
    }

    return [
        {
            id: _.uniqueId('id_'),
            question: 'Hours of operation?',
            answers: answers,
        },
    ]
}
export const surveyFormatSurveySections = (surveys: SurveySection[] | undefined): Models.SurveyResponse[] => {
    if (!surveys) {
        return []
    }

    let answers: Models.SurveyResponse[] = []
    surveys?.forEach(survey => {
        survey.questions &&
            survey.questions.forEach(question => {
                const answersList = formatAnswerByType(question, question.question_type)
                const formattedAnswersList = formatAnswerByValidationRules(answersList, question.validation_rules)

                if (formattedAnswersList.length) {
                    answers.push({
                        id: question.id,
                        question: question.question_text,
                        answers: formattedAnswersList,
                    })
                }
            })
    })

    return answers
}

const formatAnswerByType = (question: SurveySectionQuestion, answerType: string) => {
    const answerSchema: SurveyAnswerSchema | any = question.answer_schema

    switch (answerType) {
        case 'select_one':
        case 'select_multiple':
            return answerSchema?.options
                ?.filter((option: { selected: boolean }) => option?.selected === true)
                .map((option: { display_name: string; metadata?: { date?: string } }) => {
                    if (
                        question.variants?.includes('capitalize_first') &&
                        ['yes', 'no'].includes(option.display_name.toLowerCase())
                    ) {
                        return _.capitalize(option.display_name)
                    } else if (
                        question.variants?.includes('capitalize') &&
                        ['yes', 'no'].includes(option.display_name.toLowerCase())
                    ) {
                        return option.display_name.toUpperCase()
                    } else if (option.metadata?.date) {
                        return `${option.display_name} - ${moment(option.metadata.date).format('ddd, MM/DD/YYYY')}`
                    }

                    return option.display_name
                })

        case 'select_one_with_other':
        case 'select_multi_with_other':
            const options = answerSchema?.options
                ?.filter((option: { selected: boolean }) => option?.selected === true)
                .map((option: { display_name: string }) => {
                    if (
                        question.variants?.includes('capitalize_first') &&
                        ['yes', 'no'].includes(option.display_name.toLowerCase())
                    ) {
                        return _.capitalize(option.display_name)
                    } else if (
                        question.variants?.includes('capitalize') &&
                        ['yes', 'no'].includes(option.display_name.toLowerCase())
                    ) {
                        return option.display_name.toUpperCase()
                    }

                    return option.display_name
                })
                .sort((a: QuestionOption, b: QuestionOption) => a.display_name?.localeCompare(b.display_name))

            if (answerSchema?.other_option?.selected && answerSchema?.other_option?.input_field?.value) {
                const displayName = answerSchema?.other_option?.display_name
                const displayValue = answerSchema?.other_option?.input_field?.value

                const display =
                    displayName?.toLowerCase() === 'only for'
                        ? `${_.capitalize(displayName)}: ${displayValue}`
                        : displayValue

                options.push(display)
            }
            return options

        case 'input_field':
            return answerSchema?.value ? [answerSchema?.value] : []
        case 'input_field_multi_values':
            return answerSchema?.value.map((value: { value: string }) => value)

        default:
            return []
    }
}

const formatAnswerByValidationRules = (
    answersList: string[],
    validationRules: SurveySectionValidationType[] | undefined,
) => {
    if (validationRules) {
        if (validationRules.includes('IsPhoneNumber')) {
            return answersList.map(answer => {
                return formatUSPhoneNumbers(answer)
            })
        }
    }

    return answersList
}
