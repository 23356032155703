import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'

import { RootState } from '../../../appReducer'
import { PracticeReschedulingConfiguration } from '../../../models/v2/Practice'
import CustomMultiselectFieldV2 from '../../../modules/shared/custom-fields/custom-multiselect-field-v2/CustomMultiselectFieldV2'

import AllowSidebookingCheckboxGlobal from './AllowSidebookingCheckboxGlobal'
import { CANCELLATIONS_VALUES, CONCURRENCY_NUM_ARRAY, DAYS_PRIOR_VALUES, RESCHEDULING_VALUES } from './constants'

import './GlobalSettingsModal.sass'

type Props = {
    practiceId: string
    practiceSettings: PracticeReschedulingConfiguration
    saveGlobalSettings: (configuration: PracticeReschedulingConfiguration) => void
    closeModal: () => void
}

const moduleName = 'global-settings-modal'

const GlobalSettingsModal = ({ practiceId, practiceSettings, saveGlobalSettings, closeModal }: Props) => {
    const [cancelAppointmentValue, setCancelAppointmentValue] = useState(practiceSettings?.cancel_appointment || '')
    const [rescheduleAppointmentValue, setRescheduleAppointmentValue] = useState(
        practiceSettings?.reschedule_appointment || '',
    )
    const [daysPriorValue, setDaysPriorValue] = useState<number>(practiceSettings?.days_prior || 0)
    const [concurrencyNumValue, setConcurrencyNumValue] = useState<number>(practiceSettings?.concurrency_num || 0)
    const [isTouched, setIsTouched] = useState(false)

    const practiceLocationsAgentModules = useSelector(
        (state: RootState) => state.practices.practiceLocationsAgentModules[practiceId] || [],
    )

    const showCheckboxForAllowSidebooking = practiceLocationsAgentModules.some(
        module => module.heartbeat_agent_module?.type_id === 1 || module.heartbeat_agent_module?.type_id === 5,
    )

    const isValid =
        Boolean(concurrencyNumValue) &&
        Boolean(daysPriorValue) &&
        Boolean(cancelAppointmentValue) &&
        Boolean(rescheduleAppointmentValue)

    useEffect(() => {
        if (showCheckboxForAllowSidebooking && concurrencyNumValue === 0) {
            setConcurrencyNumValue(1)
        }
    }, [showCheckboxForAllowSidebooking, concurrencyNumValue])

    const handleSaveGlobalSettings = () => {
        saveGlobalSettings({
            practice_id: practiceId,
            cancel_appointment: cancelAppointmentValue,
            reschedule_appointment: rescheduleAppointmentValue,
            days_prior: daysPriorValue,
            concurrency_num: concurrencyNumValue,
        })
    }

    return (
        <Modal
            open={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    closeModal()
                }
            }}
            disableEscapeKeyDown={true}
        >
            <div className={`${moduleName}`}>
                <div className={`${moduleName}__close-modal-button`} onClick={closeModal}>
                    <i className="material-icons">close</i>
                </div>
                <div className={`${moduleName}__title`}>Global Cancel/Reschedule Settings</div>
                <div className={`${moduleName}__contents`}>
                    <div className={`${moduleName}__subtitle`}>Settings selected here apply to all procedures.</div>
                    <div className={`${moduleName}__subtitle-2`}>
                        <span className={`${moduleName}--bold`}>Note:</span> Edits made to individual locations will
                        override global settings.
                    </div>

                    <Grid container className={`${moduleName}__list-row`}>
                        <Grid
                            item={true}
                            xs={12}
                            sm={6}
                            md={3}
                            className={`${moduleName}__table-item details  ${moduleName}__size30`}
                        >
                            <div className={`${moduleName}__role-picker`}>
                                <CustomMultiselectFieldV2
                                    items={CANCELLATIONS_VALUES}
                                    maxSelected={1}
                                    selectedItems={[cancelAppointmentValue]}
                                    keyProperty="value"
                                    displayProperty="display"
                                    placeholder=""
                                    label="Cancellations"
                                    onSelectElement={(values: string[]) => {
                                        setCancelAppointmentValue(values[0])
                                        setIsTouched(true)
                                    }}
                                    dontSetTop={true}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            sm={6}
                            md={3}
                            className={`${moduleName}__table-item details  ${moduleName}__size30`}
                        >
                            <div className={`${moduleName}__role-picker`}>
                                <CustomMultiselectFieldV2
                                    items={RESCHEDULING_VALUES}
                                    maxSelected={1}
                                    selectedItems={[rescheduleAppointmentValue]}
                                    keyProperty="value"
                                    displayProperty="display"
                                    placeholder=""
                                    label="Reschedules"
                                    onSelectElement={(values: string[]) => {
                                        setRescheduleAppointmentValue(values[0])
                                        setIsTouched(true)
                                    }}
                                    dontSetTop={true}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                            sm={6}
                            md={3}
                            className={`${moduleName}__table-item details ${moduleName}__size${
                                showCheckboxForAllowSidebooking ? `22` : `20`
                            }`}
                        >
                            <div className={`${moduleName}__role-picker`}>
                                <CustomMultiselectFieldV2
                                    items={DAYS_PRIOR_VALUES}
                                    maxSelected={1}
                                    selectedItems={[daysPriorValue.toString()]}
                                    keyProperty="value"
                                    displayProperty="display"
                                    placeholder=""
                                    label="Allow Changes"
                                    onSelectElement={(values: string[]) => {
                                        setDaysPriorValue(parseInt(values[0]))
                                        setIsTouched(true)
                                    }}
                                    dontSetTop={true}
                                />
                            </div>
                        </Grid>
                        {showCheckboxForAllowSidebooking ? (
                            <AllowSidebookingCheckboxGlobal
                                setConcurrencyNumValue={setConcurrencyNumValue}
                                concurrencyNumValue={concurrencyNumValue}
                                setIsTouched={setIsTouched}
                            />
                        ) : (
                            <Grid
                                item={true}
                                xs={12}
                                sm={6}
                                md={2}
                                className={`${moduleName}__table-item details  ${moduleName}__size18`}
                            >
                                <div className={`${moduleName}__sidebooking-picker`}>
                                    <CustomMultiselectFieldV2
                                        items={CONCURRENCY_NUM_ARRAY.map(value => ({
                                            value: value.toString(),
                                            display: `${value}`,
                                        }))}
                                        maxSelected={1}
                                        selectedItems={[concurrencyNumValue.toString() || '']}
                                        keyProperty="value"
                                        displayProperty="display"
                                        placeholder=""
                                        label="Allow Sidebooking"
                                        onSelectElement={(values: string[]) => {
                                            setConcurrencyNumValue(parseInt(values[0]))
                                            setIsTouched(true)
                                        }}
                                        dontSetTop={true}
                                    />
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <div className={`${moduleName}__footer`}>
                    <Button
                        variant="contained"
                        className={`${moduleName}__save-button`}
                        onClick={handleSaveGlobalSettings}
                        disabled={!isValid || !isTouched}
                    >
                        Save settings
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default GlobalSettingsModal
