import { connect } from 'react-redux'

import { RootState } from '../../appReducer'

import PaymentsTab from './PaymentsTab'

const mapStateToProps = (state: RootState, props: any) => ({
    practice: props.practice,
})

const DexVoiceContainer = connect(mapStateToProps)(PaymentsTab)

export default DexVoiceContainer
