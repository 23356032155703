import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import moment from 'moment'

import { RootState } from '../../../../appReducer'
import { PracticePatientSort } from '../../../../models/v2/Practice'
import { convertNumCentsToDollars } from '../../../../util/formatCurrency'
import Paginator from '../../../shared/Paginator'
import { ArrowDropDown } from '../../../shared/svgIcons'
import { patientFinancialDataSelectPatient } from '../v2actions'

import './PatientsTable.sass'

interface Props {
    practiceId: string
    practiceLocationNames: { [key: string]: string }
    numOfPracticeLocations: number
    searchSort: PracticePatientSort | undefined
    searchOrder: 'asc' | 'desc' | undefined
    searchPage: number
    setSearchSort: (sort: PracticePatientSort) => void
    setSearchOrder: (order: 'asc' | 'desc') => void
    setSearchPage: (page: number) => void
}

const PatientsTable = ({
    practiceId,
    practiceLocationNames,
    numOfPracticeLocations,
    searchOrder,
    searchSort,
    searchPage,
    setSearchPage,
    setSearchOrder,
    setSearchSort,
}: Props) => {
    const list = useSelector((state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.list || [])
    const pagination = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.pagination || null,
    )
    const loading = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.loading || false,
    )

    const dispatch = useDispatch()

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    const getSortingDirectionOfProperty = (sortByProperty: string) => {
        if (sortByProperty === searchSort) {
            return searchOrder === 'desc' ? 'asc' : 'desc'
        }

        return 'desc'
    }
    const sortByProperty = (sort: PracticePatientSort) => {
        const order = getSortingDirectionOfProperty(sort)
        setSearchSort(sort)
        setSearchOrder(order)
    }

    const renderRows = () => {
        return (
            <>
                {list.map(patient => (
                    <TableRow
                        key={patient.id}
                        className="table-row table-row-clickable"
                        onClick={() => dispatch(patientFinancialDataSelectPatient(practiceId, patient))}
                    >
                        <TableCell className="table-item table-first-item" align="left">
                            {patient.last_name}
                        </TableCell>
                        <TableCell className="table-item">{patient.first_name}</TableCell>
                        <TableCell className="table-item">
                            {patient.date_of_birth && moment(patient.date_of_birth).format('MM/DD/YYYY')}
                        </TableCell>
                        {numOfPracticeLocations > 1 && (
                            <TableCell className="table-item">
                                {patient.practice_location_id &&
                                    (practiceLocationNames[patient.practice_location_id] ||
                                        patient.practice_location_id)}
                            </TableCell>
                        )}
                        <TableCell className="table-item">
                            {patient.last_visit && moment(patient.last_visit).format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell className="table-item">
                            {convertNumCentsToDollars(Number(patient.lifetime_value) * 100, true, '$')}
                        </TableCell>
                    </TableRow>
                ))}
            </>
        )
    }

    if (list.length === 0) {
        return null
    }

    return (
        <div className="patients-table">
            <Table>
                <TableHead>
                    <TableRow className="table-row">
                        <TableCell className="table-item table-first-item table-header-item" align="left">
                            <TableSortLabel
                                active={searchSort === 'last_name'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('last_name')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Last Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'first_name'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('first_name')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                First Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'date_of_birth'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('date_of_birth')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                DOB
                            </TableSortLabel>
                        </TableCell>
                        {numOfPracticeLocations > 1 && (
                            <TableCell className="table-item table-header-item">
                                <TableSortLabel
                                    active={searchSort === 'location_name'}
                                    direction={searchOrder}
                                    onClick={() => {
                                        sortByProperty('location_name')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Practice Location
                                </TableSortLabel>
                            </TableCell>
                        )}
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'last_visit'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('last_visit')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Last Visit
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'lifetime_value'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('lifetime_value')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Lifetime Value
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderRows()}</TableBody>
            </Table>
            {pagination && (
                <div className="table-pagination">
                    <Paginator currentPage={searchPage} paginationInfo={pagination} selectPage={handlePageChange} />
                </div>
            )}
            {loading && (
                <div className="circular-progress-loader">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
        </div>
    )
}

export default PatientsTable
