import React from 'react'
import { Icon, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import './ExpandablePanel.sass'

export type ExpandablePanelProps = {
    title: React.ReactNode | string
    delayedContent?: boolean
    isExpanded?: boolean
    children?: React.ReactNode
}

export type ExpandablePanelDispatch = {
    onToggle?: (toggle: boolean) => void
}

type Props = ExpandablePanelProps & ExpandablePanelDispatch

const ExpandablePanel = ({ title, children, isExpanded, delayedContent, onToggle }: Props) => {
    const handleChange = (event: React.ChangeEvent<{}>, nextExpanded: boolean) => {
        if (onToggle) {
            onToggle(!isExpanded)
        }
    }

    const renderContent = () => {
        if (!delayedContent || isExpanded) {
            return children
        }

        return null
    }

    return (
        <div className={`expandable-panel ${isExpanded ? 'expanded' : ''}`}>
            <Accordion expanded={isExpanded} className="expandable-panel__wrapper" onChange={handleChange}>
                <AccordionSummary
                    className="expandable-panel__header"
                    expandIcon={<Icon>{isExpanded ? 'expand_less' : 'chevron_right'}</Icon>}
                >
                    <Typography className="expandable-panel__title">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails className="expandable-panel__details">{renderContent()}</AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ExpandablePanel
