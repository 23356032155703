import { useCallback, useReducer, useRef } from 'react'

import { useSafeDispatch } from './useSafeDispatch'

export type AsyncStatus = 'idle' | 'pending' | 'resolved' | 'rejected' | 'reset'

interface AsyncState {
    status: AsyncStatus
    data: any
    error: Error | null
}

interface AsyncActionPending {
    type: 'pending'
    data: null
    error: null
}

interface AsyncActionResolved {
    type: 'resolved'
    data: any
    error: null
}

interface AsyncActionRejected {
    type: 'rejected'
    data: null
    error: Error
}

interface AsyncActionReset {
    type: 'reset'
    initialState: AsyncState
}

type AsyncAction = AsyncActionPending | AsyncActionResolved | AsyncActionRejected | AsyncActionReset

const defaultInitialState: AsyncState = { status: 'idle', data: null, error: null }

function asyncReducer(state: AsyncState, action: AsyncAction): AsyncState {
    switch (action.type) {
        case 'pending': {
            return { status: 'pending', data: null, error: null }
        }
        case 'resolved': {
            return { status: 'resolved', data: action.data, error: null }
        }
        case 'rejected': {
            return { status: 'rejected', data: null, error: action.error }
        }
        case 'reset': {
            return action.initialState ?? defaultInitialState
        }
    }
}

interface UseAsyncReturn {
    isIdle: boolean
    isLoading: boolean
    isError: boolean
    isSuccess: boolean
    error: Error | null
    status: AsyncStatus
    data: any
    run: (promise: any) => void
    setData: (data: any) => any
    setError: (error: Error | null) => any
    reset: () => any
}

function useAsync(initialState?: Partial<AsyncState>): UseAsyncReturn {
    const initialStateRef = useRef({
        ...defaultInitialState,
        ...initialState,
    })

    const [{ data, error, status }, unsafeDispatch] = useReducer(asyncReducer, initialStateRef.current)

    const dispatch = useSafeDispatch(unsafeDispatch)

    const run = useCallback(
        promise => {
            if (!promise || !promise.then) {
                throw new Error(
                    `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
                )
            }

            dispatch({ type: 'pending' })
            promise
                .then((data: any) => {
                    dispatch({ type: 'resolved', data })
                    return data
                })
                .catch((error: Error) => {
                    dispatch({ type: 'rejected', error })
                    return error
                })
        },
        [dispatch],
    )

    const setData = useCallback(data => dispatch({ type: 'resolved', data }), [dispatch])
    const setError = useCallback(error => dispatch({ type: 'rejected', error }), [dispatch])
    const reset = useCallback(() => dispatch({ type: 'reset', initialState: initialStateRef.current }), [dispatch])

    return {
        isIdle: status === 'idle',
        isLoading: status === 'pending',
        isError: status === 'rejected',
        isSuccess: status === 'resolved',
        error,
        status,
        data,
        run,
        setData,
        setError,
        reset,
    }
}

export { useAsync }
