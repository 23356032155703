import React from 'react'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import { withStyles } from '@mui/styles'

import { grayd7, verySoftBlack } from '../../../../shared/styles/colors'

const cellStyles = {
    root: {
        paddingTop: 11,
        paddingRight: 24,
        paddingBottom: 9,
        paddingLeft: 0,
        fontWeight: 'normal',
        fontSize: 16,
        verticalAlign: 'top',
    },
}

export const Cell = withStyles(cellStyles as any)(({ children, ...props }: TableCellProps) => (
    <TableCell {...props}>{children}</TableCell>
))

export const ResponsiveCell = Cell

const headerCellStyles = {
    root: {
        color: verySoftBlack,
        fontWeight: 600,
        fontSize: 14,
    },
}

export const HeaderCell = withStyles(headerCellStyles as any)(({ children, ...props }: TableCellProps) => (
    <Cell {...props}>{children}</Cell>
))

const headerRowStyles = {
    root: {
        height: 20,
        borderBottom: `1px solid ${grayd7}`,
    },
}

export const HeaderRow = withStyles(headerRowStyles as any)(({ children, ...props }: TableRowProps) => (
    <TableRow {...props}>{children}</TableRow>
))
