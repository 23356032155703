import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import Select from '@mui/material/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import classNames from 'classnames'
import _ from 'lodash'

import { IntegrationTypes } from '../../../models/enums'
import { savePractice } from '../actions'

import AgentContainer from './AgentContainer'
import UnboundAgents from './UnboundAgents'

import './ManageAgents.sass'

export type ManageAgentsProps = {
    practice: Models.Practice
    agents: Array<Models.PracticeAgent>
    onBack: () => void
    bindPracticeAgent: (practice: Models.Practice, agentId: string, updates: Api.PracticeAgentUpdate) => any
    savePractice: typeof savePractice
}

export type ManageAgentsState = {
    showBindAgentModal: boolean
    integrationType: string
    isDirty: boolean
}

type Props = ManageAgentsProps

class ManageAgents extends React.Component<Props, ManageAgentsState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            showBindAgentModal: false,
            integrationType: props.practice.integrationType.name,
            isDirty: false,
        }
    }

    onOpenBindAgentModal = () => {
        this.setState({ showBindAgentModal: true })
    }

    onCloseBindAgentModal = () => {
        this.setState({ showBindAgentModal: false })
    }

    onBindPracticeAgent = (agentId: string, updates: Api.PracticeAgentUpdate) => {
        return this.props.bindPracticeAgent(this.props.practice, agentId, updates)
    }

    onChangeIntegrationType(integrationType: string) {
        this.setState({
            integrationType,
            isDirty: true,
        })
    }

    onCancelChangeIntegration = () => {
        this.setState({
            integrationType: this.props.practice.integrationType.name,
            isDirty: false,
        })
    }

    onSubmitChangeIntegration = () => {
        const integrationType = IntegrationTypes.find(i => i.name === this.state.integrationType)
        const integrationTypeId = integrationType && integrationType.id

        if (this.state.isDirty && integrationTypeId) {
            this.props.savePractice(this.props.practice, { integration_type_id: integrationTypeId })
        }
        this.setState({
            isDirty: false,
        })
    }

    render() {
        return (
            <div className={classNames('manage-agents')}>
                <div className="actions">
                    <button className="back-button" onClick={this.props.onBack}>
                        <i className="material-icons">arrow_back</i>
                    </button>
                    <div className="integration-types">
                        <span className="integration-types-label">Integration Type: </span>
                        <Select
                            className="select-integration-type"
                            value={this.state.integrationType || ''}
                            onChange={e => this.onChangeIntegrationType(e.target.value as string)}
                            renderValue={(integration: string) => {
                                const integrationType = IntegrationTypes.find(t => t.name === integration)
                                return integrationType ? integrationType.displayName : ''
                            }}
                            variant="standard"
                        >
                            {IntegrationTypes.map(i => (
                                <MenuItem key={i.name} value={i.name}>
                                    {this.props.practice.integrationType.name === i.name ? (
                                        <i className="material-icons" style={{ paddingRight: '10px' }}>
                                            check
                                        </i>
                                    ) : (
                                        <i className="material-icons" style={{ color: 'transparent' }}>
                                            check
                                        </i>
                                    )}
                                    {i.displayName}
                                </MenuItem>
                            ))}
                        </Select>
                        {this.state.isDirty && (
                            <div>
                                <button className="confirm-button" onClick={this.onSubmitChangeIntegration}>
                                    <i className="material-icons">check</i>
                                </button>
                                <button className="cancel-button" onClick={this.onCancelChangeIntegration}>
                                    <i className="material-icons">close</i>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="header id">id</TableCell>
                            <TableCell className="header name">name</TableCell>
                            <TableCell className="header">created</TableCell>
                            <TableCell className="header">updated</TableCell>
                            <TableCell className="header location">location</TableCell>
                            <TableCell className="header data">data</TableCell>
                            <TableCell className="header">days</TableCell>
                            <TableCell className="header" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.sortBy(this.props.agents, agent => agent.name && agent.name.toLowerCase()).map(agent => (
                            <AgentContainer key={agent.id} practice={this.props.practice} agent={agent} />
                        ))}
                    </TableBody>
                </Table>
                <div className="footer" onClick={this.onOpenBindAgentModal}>
                    <div className="button">
                        <i className="material-icons add-icon">add</i>
                        bind agent
                    </div>
                </div>
                {this.state.showBindAgentModal && (
                    <Modal
                        className="pst-modal"
                        open={this.state.showBindAgentModal}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.onCloseBindAgentModal()
                            }
                        }}
                        disableEscapeKeyDown={true}
                    >
                        <div className="contents unbound-agent-modal">
                            <UnboundAgents
                                practice={this.props.practice}
                                onSave={this.onBindPracticeAgent}
                                onClose={this.onCloseBindAgentModal}
                            />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

export default ManageAgents
