import * as React from 'react'

import './ErrorNotificationCard.sass'

export type Props = {
    onClose: () => void
}

const moduleName = 'error-notification-card'

const ErrorNotificationCard = ({ onClose }: Props) => {
    return (
        <div>
            <div className={`${moduleName}__container`}>
                <div className={`${moduleName}__header`}>
                    <span className={`${moduleName}__close-button`} onClick={onClose}>
                        <i className="material-icons">close</i>
                    </span>
                </div>
                <div className={`${moduleName}__content`}>
                    <div className={`${moduleName}__icon`}>
                        <i className="material-icons">info</i>
                    </div>
                    <div className={`${moduleName}__inner-content`}>
                        <div className={`${moduleName}__notification`}>
                            Please select at least one practice to associate with this website.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorNotificationCard
