import * as React from 'react'
import Switch from '@mui/material/Switch'
import { SwitchClassKey } from '@mui/material/Switch'

import { CustomFieldType } from '../../../../models/enums'
import CustomField from '../../../shared/custom-fields/CustomField'

import './SelfSchedulingEnableCustomConfirmationPage.sass'

type Props = {
    title: string
    checked: boolean
    url: string
    enableSwitch: (enabled: boolean) => void
    updateCustomConfirmationPageURL: (url: string) => void
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const moduleName = 'self-scheduling-enable-custom-confirmation-page'

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch-root`,
    checked: `${moduleName}__switch--checked`,
}

function SelfSchedulingEnableCustomConfirmationPage({
    title,
    checked,
    url,
    enableSwitch,
    updateCustomConfirmationPageURL,
}: Props) {
    const handleEnableSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        enableSwitch(checked)
    }

    const onUrlChange = (url: string) => {
        updateCustomConfirmationPageURL(url)
    }

    return (
        <div className={moduleName}>
            <h3 className={`${moduleName}__title`}>{title}</h3>
            <div className={`${moduleName}__switch-and-input`}>
                <div className={`${moduleName}__switch`}>
                    <Switch
                        classes={switchClassOverrides}
                        color="primary"
                        checked={checked}
                        onChange={handleEnableSwitch}
                    />
                </div>
                <div className={`${moduleName}__url-wrapper`}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        inputType="text"
                        value={url || ''}
                        placeholder="Enter URL"
                        label=""
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onUrlChange(event.target.value)}
                        disabled={!checked}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingEnableCustomConfirmationPage
