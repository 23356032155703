import React, { useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Icon, IconButton } from '@mui/material'

import { CustomFieldType } from '../../../../../../models/enums'
import { SurveySectionQuestion, ValueType } from '../../../../../../models/PracticeSurvey'
import CustomPhoneInput from '../../../../../../modules/shared/custom-fields/CustomPhoneInput'
import ConfirmModal from '../../../../../shared/confirm-modal/ConfirmModal'
import CustomField from '../../../../../shared/custom-fields/CustomField'

import './MultiValueInput.sass'

const arrayFrom = (value: ValueType) => {
    if (Array.isArray(value)) {
        return value
    }
    if (value) {
        return [value]
    }
    return ['']
}

interface Props {
    question: SurveySectionQuestion
    onChange: (values: string[]) => void
    maxValuesNumber?: number
    minValuesNumber?: number
    countryCode?: string
}

const MultiValueInput = ({
    question: {
        variants,
        isRequired,
        isDirty,
        isValid,
        errorMessage,
        error,
        answer_schema,
        add_label,
        component_type,
        component_size,
        show_empty_field,
    },
    maxValuesNumber = Infinity,
    minValuesNumber = 1,
    onChange,
    countryCode,
}: Props) => {
    const isMultiValue = maxValuesNumber > 1
    const answerSchemaValues = arrayFrom(answer_schema?.value || '')
    const values = show_empty_field && answerSchemaValues.length === 0 ? [''] : answerSchemaValues
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null)

    const handleChange = (valueIndex: number, nextValue: string) => {
        if (values) {
            onChange(values.map((value, index) => (index === valueIndex ? nextValue : value)))
        }
    }

    const handleDelete = (itemIndex: number) => () => {
        setDeleteIndex(itemIndex)
    }

    const deleteItem = () => {
        onChange(values.filter((value, index) => index !== deleteIndex))
        setDeleteIndex(null)
    }

    const handleAdd = () => {
        if (values) {
            onChange([...values, ''])
        }
    }

    const hasError = (isDirty && !isValid) || error

    const extraClasses = variants?.map(variant => `multi-value-input--${variant}`).join(' ') ?? ''

    const customFieldProps =
        component_type === 'text-area'
            ? { customFieldType: CustomFieldType.TEXTAREA, rows: component_size ?? 3 }
            : { customFieldType: CustomFieldType.INPUT }

    return (
        <div className={`multi-value-input ${extraClasses}`}>
            {values &&
                values.map((value, index) => {
                    // initial validation with libphonenumber
                    let hasErr = hasError
                    let errMessage = errorMessage
                    if (value !== '' && !isDirty && variants?.includes('phone-number')) {
                        hasErr = !isValidPhoneNumber(value)
                        errMessage = 'Invalid Phone # format'
                    }

                    return (
                        <div className="multi-value-input__value" key={index}>
                            {variants?.includes('phone-number') ? (
                                <CustomPhoneInput
                                    {...customFieldProps}
                                    value={value}
                                    {...(!isMultiValue
                                        ? {
                                              error: hasErr,
                                              errMessage,
                                          }
                                        : {})}
                                    country={countryCode ?? 'US'}
                                    onChange={(value: string) => {
                                        if (value) {
                                            handleChange(index, value)
                                        }
                                    }}
                                />
                            ) : (
                                <CustomField
                                    {...customFieldProps}
                                    value={value}
                                    required={isRequired}
                                    {...(!isMultiValue
                                        ? {
                                              error: hasErr,
                                              errMessage,
                                          }
                                        : {})}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChange(index, event.target.value)
                                    }
                                />
                            )}
                            {values.length > minValuesNumber && (
                                <div className="multi-value-input__delete">
                                    <IconButton disableRipple size="large" onClick={handleDelete(index)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    )
                })}
            {isMultiValue && hasError && <div className="multi-value-input__error">{errorMessage}</div>}
            {values && values.length < maxValuesNumber && (
                <div className="multi-value-input__add">
                    <span className="multi-value-input__add-link" onClick={handleAdd}>
                        {add_label || '+ Add another'}
                    </span>
                </div>
            )}

            <ConfirmModal
                title="Are you sure you want to delete this item?"
                subtitle=""
                discardText="CANCEL"
                confirmText="YES, DELETE ITEM"
                open={deleteIndex !== null}
                onClose={() => setDeleteIndex(null)}
                onDiscard={() => setDeleteIndex(null)}
                onConfirm={deleteItem}
            />
        </div>
    )
}

export default MultiValueInput
