import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { isNumber } from 'lodash'

import { LocationInformation } from '../../../../../models/PracticeSurvey'

import './LocationInformation.sass'

interface Props {
    location: Models.PracticeLocation
}

const LocationInformationView = ({ location }: Props) => {
    const { locationInformation }: { locationInformation: LocationInformation } = useSelector((state: any) => ({
        locationInformation: state.practiceSurvey[location.id]?.locationInformation,
    }))

    const address = locationInformation?.address
    const addressLatValue = address?.lat.value
    const addressLngValue = address?.lng.value

    const latHelper = useMemo(() => {
        const lat = parseFloat(addressLatValue ?? '')
        if (!isNaN(lat) && isNumber(lat)) {
            return lat < 0 ? 'S' : 'N'
        }
        return ' '
    }, [addressLatValue])

    const lngHelper = useMemo(() => {
        const lng = parseFloat(addressLngValue ?? '')
        if (!isNaN(lng)) {
            return lng < 0 ? 'W' : 'E'
        }
        return ' '
    }, [addressLngValue])

    return (
        <div className="survey-section survey-section--view survey-location-information">
            <h4 className="survey-section__header">Location Information</h4>

            {address && (
                <div className="survey-section__wrapper">
                    <Grid container spacing={3} className={'grid-container'}>
                        <Grid item xs={12}>
                            <h4>Address</h4>
                            {address.street.value && <div>{address.street.value}</div>}
                            {address.unit.value && <div>{address.unit.value}</div>}
                            <div>
                                {address.city.value && <span>{address.city.value}, </span>}
                                {address.state.value && <span>{address.state.value} </span>}
                                {address.zip.value && <span>{address.zip.value}</span>}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={'grid-container location-information-country'}>
                        {address.country_code_iso_alpha_2.value && (
                            <Grid item xs={12} md={4} sm={6}>
                                <h4>Country</h4>
                                <p>{address.country_code_iso_alpha_2.value}</p>
                            </Grid>
                        )}
                        {address.timezone.value && (
                            <Grid item xs={12} md={4} sm={6}>
                                <h4>Time Zone</h4>
                                <p>{address.timezone.value}</p>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container spacing={3} className={'grid-container'}>
                        {address.lng.value && (
                            <Grid item xs={12} md={4} sm={6}>
                                <h4>Longitude</h4>
                                <p>{`${address.lng.value} ${lngHelper}`}</p>
                            </Grid>
                        )}
                        {address.lat.value && (
                            <Grid item xs={12} md={4} sm={6}>
                                <h4>Latitude</h4>
                                <p>{`${address.lat.value} ${latHelper}`}</p>
                            </Grid>
                        )}
                    </Grid>
                </div>
            )}
        </div>
    )
}

export default LocationInformationView
