export enum AccountTypes {
    SuperAdmin = 1,
    SimplifeyeStaff = 2,
    Customer = 3,
    Referral = 4,
    PartnerAdmin = 5,
    PattersonStaff = 7,
}

export enum Products {
    DexVoice = 3,
}

export type SortingDirection = 'asc' | 'desc'

export enum CustomFieldType {
    INPUT,
    TEXTAREA,
    SELECT,
}

export enum ChatCenterTab {
    Survey,
    Scheduling,
    Connect,
}

export enum QuickviewTab {
    DexVoice = 'dexvoice',
    Staff = 'staff',
    Locations = 'locations',
    Amplify = 'amplify',
    Payments = 'payments',
    Flow = 'flow',
    Logo = 'logo',
    DirectScheduling = 'direct_scheduling',
    Integrations = 'integrations',
    Heartbeat = 'heartbeat',
    Patients = 'patients',
    Chairfill = 'chairfill',
}

export enum PatientType {
    NEW_PATIENT = 'newPatient',
    EXISTING_PATIENT = 'existingPatient',
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export interface Enum<T> {
    id: number
    value: T
    display: string
}

export interface ReferralStatusEnum extends Enum<Api.Amplify.ReferralStatusValue> {}
export interface PendingReferralStatusEnum extends Enum<Api.Amplify.PendingReferralStatusValue> {}

export const NewReferralStatus: ReferralStatusEnum = {
    id: 1,
    value: 'new',
    display: 'Referral received',
}

export const FollowUpReferralStatus: ReferralStatusEnum = {
    id: 2,
    value: 'follow_up',
    display: 'Follow up required',
}

export const ScheduledReferralStatus: ReferralStatusEnum = {
    id: 3,
    value: 'scheduled',
    display: 'Scheduled',
}

export const UnsuccessfulReferralStatus: ReferralStatusEnum = {
    id: 4,
    value: 'new',
    display: ' Unsuccessful referral',
}

export const InactiveReferralStatus: ReferralStatusEnum = {
    id: 6,
    value: 'inactive',
    display: 'Inactive',
}

export const UnclaimedPendingReferralStatus: PendingReferralStatusEnum = {
    id: 1,
    value: 'unclaimed',
    display: 'Unclaimed',
}

export const InProgressPendingReferralStatus: PendingReferralStatusEnum = {
    id: 2,
    value: 'in_progress',
    display: 'In Progress',
}

export const ReviewPendingReferralStatus: PendingReferralStatusEnum = {
    id: 3,
    value: 'review',
    display: 'Review',
}

export const CompletePendingReferralStatus: PendingReferralStatusEnum = {
    id: 4,
    value: 'complete',
    display: 'Completed',
}

export interface SelectedPractice {
    id: string
    selectedTab?: QuickviewTab
}

export interface IntegrationType {
    id: number
    name: Api.IntegrationTypeValue
    displayName: string
}

export const Dentrix: IntegrationType = {
    id: 1,
    name: 'Dentrix',
    displayName: 'Dentrix',
}

export const MicroMD: IntegrationType = {
    id: 2,
    name: 'micro_md',
    displayName: 'MicroMD',
}

export const Vision: IntegrationType = {
    id: 3,
    name: 'vision',
    displayName: 'Vision',
}

export const Eaglesoft: IntegrationType = {
    id: 4,
    name: 'eaglesoft',
    displayName: 'Eaglesoft',
}

export const IntegrationTypes = [Dentrix, Eaglesoft, MicroMD, Vision]

export interface ProductType {
    id: number
    value: Api.ProductType
    displayName: string
}

export const Flow: ProductType = {
    id: 1,
    value: 'flow',
    displayName: 'Flow',
}
export const Exchange: ProductType = {
    id: 2,
    value: 'exchange',
    displayName: 'Exchange',
}
export const DexVoice: ProductType = {
    id: 3,
    value: 'dexvoice',
    displayName: 'DEXvoice',
}
export const Amplify: ProductType = {
    id: 4,
    value: 'amplify',
    displayName: 'Live Webchat',
}
export const Chat: ProductType = {
    id: 5,
    value: 'chat',
    displayName: 'Chat',
}
export const Verify: ProductType = {
    id: 6,
    value: 'verify',
    displayName: 'Verify',
}
export const SimplifeyePayments: ProductType = {
    id: 7,
    value: 'payments',
    displayName: 'Simplifeye Payments',
}
export const DirectScheduling: ProductType = {
    id: 8,
    value: 'direct_scheduling',
    displayName: 'Online Scheduling',
}
export const Heartbeat: ProductType = {
    id: 9,
    value: 'heartbeat',
    displayName: 'Heartbeat',
}
export const Chairfill: ProductType = {
    id: 10,
    value: 'chairfill',
    displayName: 'Campaigns',
}
export const VerifyPracticePal: ProductType = {
    id: 11,
    value: 'verify_practice_pal',
    displayName: 'Verify - Practice Pal',
}
export const Yext: ProductType = {
    id: 12,
    value: 'yext',
    displayName: 'Yext',
}

export const Reviews: ProductType = {
    id: 13,
    value: 'reviews',
    displayName: 'Reviews',
}

// sort by displayName
export const ProductTypes = [
    Chairfill,
    Chat,
    DexVoice,
    Exchange,
    Flow,
    Heartbeat,
    Amplify,
    DirectScheduling,
    Reviews,
    SimplifeyePayments,
    Verify,
    VerifyPracticePal,
    Yext,
]

export enum CustomerAccountType {
    Customer = 'Customer',
    Referral = 'Referral',
}

export interface AccountType {
    id: number
    value: string
    displayName: string
}

export const SuperAdmin: AccountType = {
    id: 1,
    value: 'simplifeye_admin',
    displayName: 'Simplifeye Admin',
}

export const SimplifeyeStaff: AccountType = {
    id: 2,
    value: 'simplifeye_staff',
    displayName: 'Simplifeye Staff',
}

export const PartnerAdmin: AccountType = {
    id: 5,
    value: 'partner_admin',
    displayName: 'Partner Admin',
}

export const PattersonStaff: AccountType = {
    id: 7,
    value: 'patterson_staff',
    displayName: 'Patterson Staff',
}

export const ManageAmplify: Models.Permission = {
    id: 14,
    type: 'manage_amplify',
    displayName: 'Manage Amplify Practices',
    isPublic: true,
}

export const ManagePayments: Models.Permission = {
    id: 23,
    type: 'manage_payments',
    displayName: 'Manage Payments Practices',
    isPublic: true,
}

export const ManageDexVoice: Models.Permission = {
    id: 13,
    type: 'manage_dexvoice',
    displayName: 'Manage DEXvoice Practices',
    isPublic: true,
}

export const ManageFlow: Models.Permission = {
    id: 11,
    type: 'manage_flow',
    displayName: 'Manage Flow Practices',
    isPublic: true,
}

export const ManageDirectScheduling: Models.Permission = {
    id: 22,
    type: 'manage_direct_scheduling',
    displayName: 'Manage Online Scheduling Practices',
    isPublic: true,
}

export const ManageIntegrations: Models.Permission = {
    id: 24,
    type: 'manage_integrations',
    displayName: 'Manage Practices Integrations',
    isPublic: true,
}

export const ManageAllPractices: Models.Permission = {
    id: 10,
    type: 'manage_all_practices',
    displayName: 'Manage All Practices',
    isPublic: true,
}

export const GenerateAmplifyWebCode: Models.Permission = {
    id: 17,
    type: 'generate_amplify_web_code',
    displayName: 'Generate Amplify Web Code',
    isPublic: true,
}

export const AccessChatCenter: Models.Permission = {
    id: 18,
    type: 'access_chat_center',
    displayName: 'Access Chat Center',
    isPublic: true,
}

export const AccessChats: Models.Permission = {
    id: 24,
    type: 'access_chats',
    displayName: 'Access Chats',
    isPublic: true,
}

export const AccessReferralActivityLog: Models.Permission = {
    id: 26,
    type: 'access_referral_activity_log',
    displayName: 'Access Referral Activity Log',
    isPublic: true,
}

export const AccessHeartbeatConfiguration: Models.Permission = {
    id: 27,
    type: 'edit_heartbeat_configuration',
    displayName: 'Edit Heartbeat Configuration',
    isPublic: true,
}

export const AccessAmplifySurveys: Models.Permission = {
    id: 28,
    type: 'access_amplify_surveys',
    displayName: 'Access Amplify Surveys',
    isPublic: true,
}

export const AccessYext: Models.Permission = {
    id: 29,
    type: 'access_yext',
    displayName: 'Access Yext',
    isPublic: true,
}

export const ViewDirectSchedulingLogs: Models.Permission = {
    id: 30,
    type: 'view_direct_scheduling_logs',
    displayName: 'View Online Scheduling Logs',
    isPublic: true,
}

export const AccessAgentDashboard: Models.Permission = {
    id: 31,
    type: 'access_agent_dashboard',
    displayName: 'Access Agent Dashboard',
    isPublic: true,
}

export const UpdateCancellationAndRescheduleSettings: Models.Permission = {
    id: 32,
    type: 'update_cancel_reschedule_settings',
    displayName: 'Update Cancel/Reschedule Settings',
    isPublic: true,
}

export const UpdatePSTFacilityId: Models.Permission = {
    id: 33,
    type: 'update_pst_facility_id',
    displayName: 'Edit Facility IDs',
    isPublic: true,
}

export const AccessChatList: Models.Permission = {
    id: 34,
    type: 'access_chat_list',
    displayName: 'Access Chat List',
    isPublic: true,
}

export const AccessReferralsList: Models.Permission = {
    id: 35,
    type: 'access_referrals_list',
    displayName: 'Access Referrals List',
    isPublic: true,
}

export const AccessPatientsTab: Models.Permission = {
    id: 36,
    type: 'access_patients_tab',
    displayName: 'Access Patients Tab',
    isPublic: true,
}

export const AccessPaymentsTab: Models.Permission = {
    id: 37,
    type: 'access_payments_tab',
    displayName: 'Access Payments Tab',
    isPublic: true,
}

export interface PracticeSpecialtyTypeEnum extends Enum<Api.PracticeSpecialtyTypeValue> {}

export const DentalSpecialtyType: PracticeSpecialtyTypeEnum = {
    id: 1,
    value: 'dental',
    display: 'Dental',
}

export const OptometrySpecialtyType: PracticeSpecialtyTypeEnum = {
    id: 2,
    value: 'optometry',
    display: 'Optometry',
}

export const MedicalSpecialtyType: PracticeSpecialtyTypeEnum = {
    id: 3,
    value: 'medical',
    display: 'Medical',
}

export const AlternativeVerticalsSpecialtyType: PracticeSpecialtyTypeEnum = {
    id: 4,
    value: 'alternative_verticals',
    display: 'Alternate Vertical',
}

export const PracticeSpecialtyTypes = [
    DentalSpecialtyType,
    OptometrySpecialtyType,
    MedicalSpecialtyType,
    AlternativeVerticalsSpecialtyType,
]
