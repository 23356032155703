import * as React from 'react'
import IconButton from '@mui/material/IconButton'

import Shortcut from './Shortcut'

import './ShortcutList.sass'

type Props = {
    closeShortcuts: () => void
    selectShortcut: (text: string) => void
    filteredShortcuts: Models.Shortcut[]
    selectedShortcut: number
}

export default class Shortcuts extends React.Component<Props> {
    render() {
        return (
            <div className="shortcut-container">
                <div className="shortcut-title">
                    <p className="shortcut-title-text">Shortcuts</p>
                    <button className="shortcut-title-icon" onClick={this.props.closeShortcuts}>
                        <i className="material-icons icon">close</i>
                    </button>
                </div>

                <div className="shortcuts">
                    {this.props.filteredShortcuts.map((shortcut: Models.Shortcut, index: number) => {
                        return (
                            <Shortcut
                                key={shortcut.name}
                                active={index === this.props.selectedShortcut}
                                shortcut={shortcut}
                                selectShortcut={this.props.selectShortcut}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}
