import iassign from 'immutable-assign'

import { Heartbeat } from '../../models/v2/Heartbeat'

import { HeartbeatActionTypes, SetHeartbeatDownloadMonthlyCheckupCsv, SetHeartbeatProviders } from './v2actions'

type HeartbeatStateAction = SetHeartbeatDownloadMonthlyCheckupCsv | SetHeartbeatProviders

const initialState = (): Heartbeat => ({
    download_monthly_checkup: {
        status: 'idle',
    },
    heartbeatProviders: [],
})

function practiceHeartbeatReducer(state: Heartbeat = initialState(), action: HeartbeatStateAction): Heartbeat {
    switch (action.type) {
        case HeartbeatActionTypes.EARTBEAT_DOWNLOAD_MONTHLY_CHECKUP_CSV: {
            return iassign(state, next => {
                next.download_monthly_checkup.status = action.status
                return next
            })
        }

        case HeartbeatActionTypes.HEARTBEAT_RECEIVE_PROVIDERS: {
            return iassign(state, next => {
                next.heartbeatProviders = action.heartbeatProviders
                return next
            })
        }

        default:
            return state
    }
}

export default practiceHeartbeatReducer
