export const mapAccountsV2ToV1 = (accounts: ApiV2.Admin.Account[]): Api.Account[] => {
    return accounts.map(mapAccountV2ToV1)
}

export const mapAccountV2ToV1 = (account: ApiV2.Admin.Account): Api.Account => {
    return {
        id: account.id,
        username: account.username,
        first_name: account.first_name,
        last_name: account.last_name,
        type: {
            id: account.account_type.id,
            value: account.account_type.value,
            display_name: account.account_type.display_name,
        },
        permissions: account.permission,
        created: account.created,
        accepted: true,
        active: account.active,
        medical_role: '',
        role: undefined,
        account_status: account.account_status,
        phone_numbers: [],
        emails: [],
        yext_user_id: account.yext_user_id,
        locked_until: account.locked_until,
        locked: account.locked,
        is_vyne_created: account.is_vyne_created,
    }
}

export const mapAccountPracticeLocation = (location: ModelsV2.Practice.AccountPracticeLocation) => {
    return {
        ...location,
        permissions: {
            email_notifications: location.permissions?.email_notifications || location.permissions?.emailNotifications,
            sms_notifications: location.permissions?.sms_notifications || location.permissions?.smsNotifications,
        },
    }
}

export const mapAccountPracticeLocations = (locations: ModelsV2.Practice.AccountPracticeLocation[]) => {
    return locations.map(mapAccountPracticeLocation)
}
