import React from 'react'
import Notifications from 'react-notification-system-redux'
import { useSelector } from 'react-redux'

import { RootState } from '../../appReducer'

const NotificationAlerts = () => {
    const notifications = useSelector((state: RootState) => state.notifications)
    return <Notifications notifications={notifications} />
}

export default NotificationAlerts
