import React, { ChangeEvent, useEffect, useState } from 'react'
// import { SFSelect } from '@simplifeye/component-library'
import { useDispatch } from 'react-redux'
import { CircularProgress, Icon } from '@mui/material'
import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import moment from 'moment'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { downloadMonthlyStatement, getMonthlyStatementAccountsAndYears, getMonthlyStatementMonths } from './actions'

import './MonthlyStatements.sass'

interface Props {
    practiceId: string
    practiceName: string
    onClose: () => void
}

export default function MonthlyStatements(props: Props) {
    type AppDispatch = ThunkDispatch<any, any, AnyAction>

    const dispatch: AppDispatch = useDispatch()

    const [locationsAndYears, updateLocationsAndYears] = useState<Api.PaymentMonthlyStatementAccountsAndYearsResponse>(
        [],
    )
    const [monthsAvailable, udpateMonthsAvailable] = useState<string[]>([])
    const [selectedConnectedAccountId, udpateSelectedConnectedAccountId] = useState('')
    const [selectedYear, updateSelectedYear] = useState('')
    const [isLoadingAccounts, updateIsLoadingAccounts] = useState(true)
    const [isLoadingMonths, updateIsLoadingMonths] = useState(false)
    const [errorText, setErrorText] = useState('')

    useEffect(() => {
        const loadMonthlyStatementData = async () => {
            updateIsLoadingAccounts(true)
            // await new Promise(resolve => setTimeout(resolve, 500000))
            try {
                const locationsAndYearsData: Api.PaymentMonthlyStatementAccountsAndYearsResponse = await dispatch(
                    getMonthlyStatementAccountsAndYears(props.practiceId),
                )
                updateLocationsAndYears(locationsAndYearsData || [])
            } catch (err) {
                setErrorText(err.message || 'Error loading location and years data.')
            }
            updateIsLoadingAccounts(false)
        }
        loadMonthlyStatementData()
        return () => {}
    }, [dispatch, props.practiceId])

    useEffect(() => {
        if (selectedConnectedAccountId === '' || selectedYear === '') {
            return
        }

        const loadStatementMonths = async () => {
            updateIsLoadingMonths(true)
            try {
                const statementMonthsData: Api.PaymentMonthlyStatementMonthsResponse = await dispatch(
                    getMonthlyStatementMonths(selectedConnectedAccountId, selectedYear, props.practiceId),
                )
                udpateMonthsAvailable(statementMonthsData.monthsAvailable)
            } catch (err) {
                setErrorText(err.message || 'Error loading statement months data.')
            }

            updateIsLoadingMonths(false)
        }
        loadStatementMonths()
    }, [dispatch, props.practiceId, selectedConnectedAccountId, selectedYear])

    if (isLoadingAccounts) {
        return (
            <div className="payments-monthly-statements">
                <div className="payments-monthly-statements-content">
                    <div className="payments-monthly-statements-loading">
                        <CircularProgress className="loading" thickness={2} style={{ height: '80px', width: '80px' }} />
                    </div>
                </div>
            </div>
        )
    }

    const selectedAccountIndex = locationsAndYears.findIndex(l => l.connectedAccountId === selectedConnectedAccountId)

    return (
        <div className="payments-monthly-statements">
            <div className="payments-monthly-statements-content">
                {!!errorText && (
                    <div className="monthly-statement-error">
                        <Icon className="info-payment-icon">close</Icon>
                        {errorText}
                    </div>
                )}
                <button className="payments-back-button" onClick={props.onClose}>
                    <Icon className="info-payment-icon">chevron_left</Icon>
                    Back
                </button>
                <h2>Monthly Statements</h2>
                <div className="monthly-statement-filters">
                    {locationsAndYears.length === 0 && (
                        <div className="monthly-statement-empty">No monthly statements available yet.</div>
                    )}
                    {locationsAndYears.length > 0 && (
                        <div className="monthly-statement-dropdown">
                            <CustomField
                                customFieldType={CustomFieldType.SELECT}
                                value={selectedConnectedAccountId || ''}
                                label="Choose Practice Location"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    updateSelectedYear('')
                                    udpateSelectedConnectedAccountId(e.target.value)
                                }}
                            >
                                <option value="">Choose Practice Location</option>
                                {locationsAndYears.map((l, index) => {
                                    let locationText = l.locationNames[0]
                                    if (l.locationNames.length > 1) {
                                        locationText = l.locationNames.slice(0, 3).reduce((acc, current) => {
                                            let thisLocation = current
                                            if (thisLocation.length > 12) {
                                                thisLocation = `${thisLocation.substr(0, 12)}...`
                                            }
                                            if (acc.length > 0) {
                                                thisLocation = `, ${thisLocation}`
                                            }
                                            return `${acc}${thisLocation}`
                                        }, '')
                                        if (l.locationNames.length > 3) {
                                            locationText = `${locationText} and ${l.locationNames.length -
                                                3} more location${l.locationNames.length === 4 ? '' : 's'}`
                                        }
                                    }
                                    const extraFields: { disabled?: boolean } = {}
                                    if (isLoadingMonths) {
                                        extraFields.disabled = true
                                    }

                                    return (
                                        <option key={index} value={l.connectedAccountId} {...extraFields}>
                                            {locationText}
                                        </option>
                                    )
                                })}
                            </CustomField>
                        </div>
                    )}
                    {selectedAccountIndex > -1 && (
                        <div className="monthly-statement-dropdown">
                            <CustomField
                                customFieldType={CustomFieldType.SELECT}
                                value={selectedYear || ''}
                                label="Choose Statement Year"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    updateSelectedYear(e.target.value)
                                }}
                            >
                                <option value="">Choose Statement Year</option>
                                {locationsAndYears[selectedAccountIndex].yearsAvailable.map((y, index) => {
                                    const extraFields: { disabled?: boolean } = {}
                                    if (isLoadingMonths) {
                                        extraFields.disabled = true
                                    }
                                    return (
                                        <option key={index} value={String(y)} {...extraFields}>
                                            {String(y)}
                                        </option>
                                    )
                                })}
                            </CustomField>
                        </div>
                    )}
                </div>
                {isLoadingMonths && (
                    <CircularProgress
                        className="loading-months"
                        thickness={2}
                        style={{ height: '60px', width: '60px' }}
                    />
                )}

                {!isLoadingMonths && monthsAvailable.length > 0 && (
                    <ul className="monthly-statements-list">
                        {monthsAvailable.map(m => {
                            return (
                                <li key={m}>
                                    <button
                                        className="monthly-statement-link"
                                        onClick={() =>
                                            dispatch(
                                                downloadMonthlyStatement(
                                                    selectedConnectedAccountId,
                                                    `${selectedYear}-${m}-01`,
                                                    locationsAndYears[selectedAccountIndex].locationNames,
                                                    props.practiceName,
                                                    props.practiceId,
                                                ),
                                            )
                                        }
                                    >
                                        {moment(m, 'MM').format('MMMM')} {selectedYear}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        </div>
    )
}
