import * as React from 'react'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import countries from 'countries-list'
import iassign from 'immutable-assign'
import _ from 'lodash'

import { CustomFieldType, IntegrationTypes, PracticeSpecialtyTypes, ProductTypes } from '../../models/enums'
import CustomMultiselectField from '../../modules/shared/custom-fields/CustomMultiselectField'
import CustomPhoneInput from '../../modules/shared/custom-fields/CustomPhoneInput'
import CustomField from '../shared/custom-fields/CustomField'
import ErrorMessage from '../shared/error-message/error-message'
import {
    FormFieldElement,
    FormSelectElement,
    isCoordinate,
    isEmail,
    isNotEmpty,
    isRequired,
    isValidPhoneNumberCheck,
    validate,
} from '../shared/form-validator/validator'

import { GeocodingResult } from './locations/SearchLocationInput'
import SearchLocationInput, { AddressObject } from './locations/SearchLocationInput'
import { getTimeZonesForCountry, getTimeZonesWithAbbreviations } from './locations/utils'

import './CreatePracticeForm.sass'

export type CreatePracticeFormProps = {
    readonly name: string
}

export type CreatePracticeFormDispatch = {
    readonly onClose: () => void
    readonly onSave: (practice: Api.CreatePractice, locations?: Array<ApiV2.Practice.CreatePracticeLocation>) => void
    readonly getZipCodeTimezone: (zipCode: string) => any
}

interface LocationFormElement {
    name?: FormFieldElement
    practiceSpecialtyType?: FormFieldElement
    timezone: FormFieldElement
    timezoneListForCountry: FormSelectElement
    street: FormFieldElement
    city: FormFieldElement
    state: FormFieldElement
    zip: FormFieldElement
    unit: FormFieldElement
    countryCodeIsoAlpha2: FormFieldElement
    lat: FormFieldElement
    lng: FormFieldElement
    products: FormSelectElement
}

export interface FormElements {
    practiceSpecialtyType: FormFieldElement
    practiceName: FormFieldElement
    username: FormFieldElement
    phone: FormFieldElement
    firstName: FormFieldElement
    lastName: FormFieldElement
    locations: LocationFormElement[]
    integrationType: FormFieldElement
}

export type CreatePracticeFormState = {
    formElements: FormElements
    practice: Api.CreatePractice
    locations: Api.CreateLocation[]
    isFormValid: boolean
    isFormDirty: boolean
    showFormErrors: boolean
    geolocationFailed: boolean[]
}

type CountryData = {
    code: string
    name: string
    phone: string
}

type Props = CreatePracticeFormProps & CreatePracticeFormDispatch

class CreatePracticeForm extends React.Component<Props, CreatePracticeFormState> {
    nameField: HTMLInputElement | null
    scrollContainer: HTMLDivElement | null
    scrollTarget: HTMLDivElement | null

    get initialState(): CreatePracticeFormState {
        return {
            formElements: {
                practiceSpecialtyType: {
                    value: '',
                    validators: [isRequired(), isNotEmpty()],
                    isValid: false,
                    isDirty: false,
                },
                practiceName: {
                    value: this.props.name || '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                username: {
                    value: '',
                    validators: [isEmail()],
                    isValid: false,
                    isDirty: false,
                },
                phone: {
                    value: '',
                    validators: [isRequired(), isValidPhoneNumberCheck({ countryCode: 'US' })],
                    isValid: false,
                    isDirty: false,
                },
                firstName: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                lastName: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                integrationType: {
                    value: '',
                    isValid: true,
                    isDirty: true,
                },
                locations: [
                    {
                        ...this.primaryLocationValidators(),
                    },
                ],
            },
            practice: {
                practice_specialty_type: '',
                name: this.props.name,
                phone: '',
                subscribed_users: 1,
                billing_address: {
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                },
                primary_user: {
                    username: '',
                    first_name: '',
                    last_name: '',
                    type: 'customer',
                },
                timezone: 'US',
                timezoneListForCountry: getTimeZonesForCountry('US'),
                integration_type: '',
                products: [],
            },
            locations: [],
            geolocationFailed: [],
            isFormValid: false,
            isFormDirty: false,
            showFormErrors: false,
        }
    }

    constructor(props: Props) {
        super(props)
        this.state = this.initialState
    }

    componentDidMount() {
        if (this.nameField) {
            this.nameField.select()
            this.nameField.selectionStart = this.nameField.selectionEnd = this.nameField.value.length
        }
    }

    componentDidUpdate(prevProps: never, prevState: CreatePracticeFormState) {
        if (this.state.locations == null || this.scrollContainer == null || this.scrollTarget == null) {
            return
        }

        if (prevState.locations == null || prevState.locations.length < this.state.locations.length) {
            if (this.scrollContainer.scrollTo) {
                this.scrollContainer.scrollTo({
                    top: this.scrollTarget.offsetTop + this.scrollTarget.offsetHeight,
                })
            }
        }
    }

    primaryLocationValidators = () => ({
        street: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
        },
        city: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
        },
        state: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
        },
        zip: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
        },
        unit: {
            value: '',
            isValid: true,
            isDirty: true,
        },
        countryCodeIsoAlpha2: {
            value: 'US',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
        },
        timezone: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
        },
        timezoneListForCountry: {
            value: getTimeZonesForCountry('US'),
            validators: [],
            isValid: false,
            isDirty: false,
        },
        lat: {
            value: '',
            validators: [isRequired(), isCoordinate({ min: -85, max: 85 })],
            isValid: false,
            isDirty: false,
        },
        lng: {
            value: '',
            validators: [isRequired(), isCoordinate({ min: -180, max: 180 })],
            isValid: false,
            isDirty: false,
        },
        products: {
            value: [],
            validators: [isNotEmpty()],
            isValid: true,
            isDirty: true,
        },
    })

    nextLocationValidators = () => ({
        name: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
            isNested: true,
        },
        practiceSpecialtyType: {
            value: '',
            validators: [isRequired()],
            isValid: false,
            isDirty: false,
            isNested: true,
        },
        ...this.primaryLocationValidators(),
    })

    getPrimaryLocationCountryCode = () => {
        const { formElements } = this.state
        if (formElements.locations && formElements.locations[0]) {
            return formElements.locations[0].countryCodeIsoAlpha2.value
        }
        return 'US'
    }

    validateZipCode = async ({
        locationIndex,
        zipCode,
        countryCode,
    }: {
        locationIndex: number
        zipCode?: string
        countryCode?: string
    }) => {
        const { formElements } = this.state
        const location = formElements.locations && formElements.locations[locationIndex]
        const zipCodeValue = zipCode ? zipCode : location?.zip.value
        const countryCodeValue = countryCode ? countryCode : location?.countryCodeIsoAlpha2.value
        let timezone = ''
        let isZipCodeValid = false

        if (location && zipCodeValue && countryCodeValue !== 'US') {
            isZipCodeValid = Boolean(zipCodeValue.length > 0)
        }

        if (location && zipCodeValue && countryCodeValue === 'US') {
            if (zipCodeValue.length < 5) {
                isZipCodeValid = false
            }
            if (zipCodeValue.length >= 5) {
                try {
                    const result = await this.props.getZipCodeTimezone(zipCodeValue.trim())

                    if (result) {
                        isZipCodeValid = true
                        timezone = result.iana_tz
                    }
                } catch (err) {
                    isZipCodeValid = false
                }
            }
        }

        this.onUpdate(formElements => {
            const locationZip = formElements.locations && formElements.locations[locationIndex].zip
            const locationTimezone = formElements.locations && formElements.locations[locationIndex].timezone

            if (locationZip) {
                locationZip.isValid = isZipCodeValid
                locationZip.firstErrorMessage = isZipCodeValid ? '' : 'Invalid Zip Code'
                locationZip.validators = isZipCodeValid
                    ? [isRequired()]
                    : [
                          isRequired(),
                          {
                              errorMessage: 'Invalid Zip Code',
                              type: () => {
                                  return false
                              },
                          },
                      ]
            }

            if (locationTimezone && timezone) {
                locationTimezone.value = timezone
            }
        })
    }

    onUpdate = (modifier: (changes: FormElements) => void) => {
        return this.setState(
            iassign(this.state, (next: CreatePracticeFormState) => {
                modifier(next.formElements)
                next.isFormDirty = true
                return next
            }),
            this.validate,
        )
    }

    onUpdateAddress = (locationIndex: number) => () => {
        const address = this.state.formElements.locations[locationIndex]
        if (!address) {
            return
        }

        const street = address.street.value
        const city = address.city.value
        const state = address.state.value
        const countryCodeIsoAlpha2 = address.countryCodeIsoAlpha2.value
        const zip = address.zip.value

        if (street && city && state && countryCodeIsoAlpha2 && zip) {
            const service = new window.google.maps.Geocoder()
            service.geocode(
                {
                    address: `${street ?? ''}, ${city ?? ''}, ${state ?? ''},
                        ${countryCodeIsoAlpha2 ?? ''} ${zip ?? ''}`,
                },
                (results: GeocodingResult[], status: string) => {
                    const result =
                        results?.find(
                            (geometryResult: GeocodingResult) => geometryResult.geometry?.location_type === 'ROOFTOP',
                        )?.geometry || null
                    const lat = result?.location?.lat()
                    const lng = result?.location?.lng()
                    if (status === 'OK' && lat && lng) {
                        this.setState(
                            iassign(this.state, (next: CreatePracticeFormState) => {
                                next.geolocationFailed[locationIndex] = false
                                next.formElements.locations[locationIndex].lat.value = lat.toString()
                                next.formElements.locations[locationIndex].lat.isDirty = true
                                next.formElements.locations[locationIndex].lng.value = lng.toString()
                                next.formElements.locations[locationIndex].lng.isDirty = true
                                return next
                            }),
                        )
                    } else {
                        this.setState(
                            iassign(this.state, (next: CreatePracticeFormState) => {
                                next.geolocationFailed[locationIndex] = true
                                next.formElements.locations[locationIndex].lat.value = ''
                                next.formElements.locations[locationIndex].lng.value = ''
                                return next
                            }),
                        )
                    }
                },
            )
        }
    }

    onUpdateLocation = (locationIndex: number, formElementName: string, value: string | string[]) => {
        this.onUpdate(formElements => {
            const location = formElements.locations?.[locationIndex][formElementName]
            if (location) {
                location.value = value
                location.isDirty = true
            }
        })
    }

    onChangeCountry = (locationIndex: number, countryCode: string) => {
        if (!countryCode || !this.isNewCountryDifferentThanPrevious(locationIndex, countryCode)) {
            return
        }
        this.onUpdate(formElements => {
            const location = formElements.locations?.[locationIndex]

            if (location) {
                const { state, countryCodeIsoAlpha2, timezone, timezoneListForCountry } = location
                timezoneListForCountry.value = getTimeZonesForCountry(countryCode)

                state.validators = countryCode === 'US' ? [isRequired()] : []
                state.isDirty = true

                const hasTimezones = (timezoneListForCountry.value?.length ?? 0) > 0
                timezone.validators = countryCode !== 'US' && hasTimezones ? [isRequired()] : []
                timezone.isDirty = true

                if (locationIndex === 0) {
                    formElements.phone.validators = [isRequired(), isValidPhoneNumberCheck({ countryCode })]
                    formElements.phone.isDirty = true
                }

                countryCodeIsoAlpha2.value = countryCode
                countryCodeIsoAlpha2.isDirty = true
            }
        })

        this.validateZipCode({ locationIndex })
        this.onUpdateAddress(locationIndex)()
    }

    isNewCountryDifferentThanPrevious = (locationIndex: number, countryCode: string) => {
        const location = this.state.formElements.locations?.[locationIndex]
        return location && location.countryCodeIsoAlpha2?.value && location.countryCodeIsoAlpha2?.value !== countryCode
    }

    onChangePhoneCountry = (countryCode: string | undefined) => {
        if (countryCode) {
            this.onChangeCountry(0, countryCode)
        }
    }

    onAddLocation = () => {
        this.setState(
            iassign(
                this.state,
                next => next.formElements.locations,
                next => {
                    if (next) {
                        next.push({
                            ...this.nextLocationValidators(),
                        })
                    }
                    return next
                },
            ),
        )
    }

    onChangeBound(key: string, value: string | number) {
        this.setState(
            iassign(
                this.state,
                next => _.get(next, key),
                () => value,
            ),
        )
    }

    onChangeStreet = (extraLocationIdx: number) => (inputValue: string) => {
        this.onUpdateLocation(extraLocationIdx, 'street', inputValue)
    }

    onPlaceSelect = (extraLocationIdx: number, addressObject: AddressObject) => {
        if (addressObject.street) {
            const lat: string = typeof addressObject.lat === 'number' ? addressObject?.lat.toString() : ''
            const lng: string = typeof addressObject.lng === 'number' ? addressObject?.lng.toString() : ''
            this.onUpdateLocation(extraLocationIdx, 'lat', lat || '')
            this.onUpdateLocation(extraLocationIdx, 'lng', lng || '')

            this.onUpdateLocation(extraLocationIdx, 'street', addressObject.street || '')
            this.onUpdateLocation(extraLocationIdx, 'city', addressObject.city || '')
            this.onUpdateLocation(extraLocationIdx, 'unit', addressObject.unit || '')
            this.onUpdateLocation(extraLocationIdx, 'zip', addressObject.zip || '')
            this.onUpdateLocation(extraLocationIdx, 'state', addressObject.state || '')
            this.onChangeCountry(extraLocationIdx, addressObject.country || 'US')
            this.validateZipCode({
                locationIndex: extraLocationIdx,
                zipCode: addressObject.zip ? addressObject.zip : '',
                countryCode: addressObject.country || 'US',
            })
        }

        this.setState(
            iassign(this.state, (next: CreatePracticeFormState) => {
                next.geolocationFailed[extraLocationIdx] = !addressObject.lat || !addressObject.lng
                return next
            }),
        )
    }

    validate() {
        this.setState(
            iassign(this.state, (next: CreatePracticeFormState) => {
                next.isFormValid = validate(this.state.formElements)
                return next
            }),
        )
    }

    createReqPayload = (): {
        practice: Api.CreatePractice
        locations?: Array<ApiV2.Practice.CreatePracticeLocation>
    } => {
        const {
            practiceSpecialtyType,
            practiceName,
            phone,
            username,
            firstName,
            lastName,
            integrationType,
            locations,
        } = this.state.formElements
        const primaryLocation = locations && (locations[0] as LocationFormElement)

        const otherLocations = locations?.slice(1).map(location => {
            const {
                name,
                practiceSpecialtyType,
                street,
                state,
                city,
                zip,
                unit,
                timezone,
                countryCodeIsoAlpha2,
                lat,
                lng,
                products,
            } = location

            const addressPayload: Api.CreateAddress = {
                street: street.value,
                city: city.value,
                state: state.value,
                zip: zip.value,
                lat: parseFloat(lat.value),
                lng: parseFloat(lng.value),
                country_code_iso_alpha_2: countryCodeIsoAlpha2.value,
            }

            if (unit.value) {
                addressPayload.unit = unit.value
            }

            return {
                name: name?.value,
                practice_specialty_type: { value: practiceSpecialtyType?.value as Api.PracticeSpecialtyTypeValue },
                address: addressPayload,
                timezone: timezone?.value,
                description: '',
                products: products?.value.map(product => {
                    const productId = ProductTypes.find(p => p.value === product)?.id || 0
                    return {
                        product_id: productId,
                        active: true,
                    }
                }),
            }
        })

        const createPractice: Api.CreatePractice = {
            name: practiceName.value,
            phone: phone.value,
            subscribed_users: 1,
            billing_address: {
                street: primaryLocation && primaryLocation.street.value,
                city: primaryLocation && primaryLocation.city.value,
                state: primaryLocation && primaryLocation.state.value,
                zip: primaryLocation && primaryLocation.zip.value,
                country_code_iso_alpha_2: primaryLocation && primaryLocation.countryCodeIsoAlpha2.value,
                lat: primaryLocation && parseFloat(primaryLocation.lat.value),
                lng: primaryLocation && parseFloat(primaryLocation.lng.value),
            },
            timezone: primaryLocation && primaryLocation.timezone.value,
            primary_user: {
                username: username.value,
                first_name: firstName.value,
                last_name: lastName.value,
                type: 'customer' as Api.PracticePrimaryUserType,
            },
            integration_type: integrationType.value,
            products: primaryLocation.products.value as Api.ProductType[],
            practice_specialty_type: practiceSpecialtyType.value as Api.PracticeSpecialtyTypeValue,
        }

        if (primaryLocation && primaryLocation.unit.value) {
            createPractice.billing_address.unit = primaryLocation.unit.value
        }

        return { practice: createPractice, locations: otherLocations }
    }

    onSave = () => {
        this.setState(
            iassign(this.state, (next: CreatePracticeFormState) => {
                next.showFormErrors = true
                return next
            }),
            () => {
                if (this.state.isFormValid) {
                    const { practice, locations } = this.createReqPayload()
                    this.props.onSave(practice, locations)
                    this.props.onClose()
                }
            },
        )
    }

    onRemoveAddress(idx: number) {
        this.setState(
            iassign(this.state, (next: CreatePracticeFormState) => {
                next?.formElements?.locations?.splice(idx, 1)
                next?.geolocationFailed?.splice(idx, 1)
                return next
            }),
        )
    }

    addressFields(extraLocationIdx: number): React.ReactNode {
        const { showFormErrors, formElements } = this.state
        const isNew = extraLocationIdx > 0
        const formElement = formElements.locations && formElements.locations[extraLocationIdx]
        const sortedCountriesList: CountryData[] = _.orderBy(
            Object.keys(countries.countries).map(code => {
                return {
                    code,
                    name: countries.countries[code].name,
                    phone: countries.countries[code].phone,
                }
            }),
            'name',
        )

        const latHelper = () => {
            const lat = parseFloat(formElement?.lat?.value ?? '')
            if (!isNaN(lat)) {
                return lat < 0 ? 'S' : 'N'
            }
            return ' '
        }

        const lngHelper = () => {
            const lng = parseFloat(formElement?.lng?.value ?? '')
            if (!isNaN(lng)) {
                return lng < 0 ? 'W' : 'E'
            }
            return ' '
        }

        return (
            <div className="address" key={extraLocationIdx}>
                {isNew && (
                    <div className="row">
                        <div className="address-title">
                            <span>{`Location ${extraLocationIdx + 1}`}</span>
                            <Button onClick={() => this.onRemoveAddress(extraLocationIdx)}>Remove</Button>
                        </div>
                    </div>
                )}
                {isNew && formElement && formElement.name && formElement.practiceSpecialtyType && (
                    <React.Fragment>
                        <div className="row">
                            <div className="practice-specialty-type">
                                <CustomMultiselectField
                                    items={PracticeSpecialtyTypes}
                                    selectedItems={[formElement.practiceSpecialtyType.value || '']}
                                    maxSelected={1}
                                    keyProperty="value"
                                    displayProperty="display"
                                    placeholder="Select Specialty"
                                    label="Location Specialty*"
                                    error={showFormErrors && formElement.practiceSpecialtyType.firstErrorMessage}
                                    errorMessage={formElement.practiceSpecialtyType.firstErrorMessage}
                                    onSelectElement={(values: string[]) =>
                                        this.onUpdateLocation(
                                            extraLocationIdx,
                                            'practiceSpecialtyType',
                                            values[0] || '',
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="address-name">
                                <CustomField
                                    required={true}
                                    error={showFormErrors && formElement.name.firstErrorMessage}
                                    errorMessage={formElement.name.firstErrorMessage}
                                    value={formElement.name && formElement.name.value}
                                    customFieldType={CustomFieldType.INPUT}
                                    label="Location Name*"
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.onUpdateLocation(extraLocationIdx, 'name', event.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {formElement && (
                    <div>
                        <div className="row">
                            <div className="address-street">
                                <SearchLocationInput
                                    required={true}
                                    error={showFormErrors && formElement.street.firstErrorMessage}
                                    errorMessage={formElement.street.firstErrorMessage}
                                    value={formElement.street.value}
                                    label="Street Address*"
                                    onChange={this.onChangeStreet(extraLocationIdx)}
                                    onBlur={this.onUpdateAddress(extraLocationIdx)}
                                    onPlaceSelect={addressObject => this.onPlaceSelect(extraLocationIdx, addressObject)}
                                />
                            </div>
                            <div className="address-suite">
                                <CustomField
                                    required={true}
                                    value={formElement.unit.value}
                                    customFieldType={CustomFieldType.INPUT}
                                    label="Suite"
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.onUpdateLocation(extraLocationIdx, 'unit', event.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="address-city">
                                <CustomField
                                    required={true}
                                    error={showFormErrors && formElement.city.firstErrorMessage}
                                    errorMessage={formElement.city.firstErrorMessage}
                                    value={formElement.city.value}
                                    customFieldType={CustomFieldType.INPUT}
                                    label="City*"
                                    onBlur={this.onUpdateAddress(extraLocationIdx)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.onUpdateLocation(extraLocationIdx, 'city', event.target.value)
                                    }
                                />
                            </div>
                            <div className="address-state">
                                <CustomField
                                    required={true}
                                    error={showFormErrors && formElement.state.firstErrorMessage}
                                    errorMessage={formElement.state.firstErrorMessage}
                                    value={formElement.state.value}
                                    customFieldType={CustomFieldType.INPUT}
                                    label={`State${formElement.countryCodeIsoAlpha2.value === 'US' ? `*` : ``}`}
                                    onBlur={this.onUpdateAddress(extraLocationIdx)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.onUpdateLocation(extraLocationIdx, 'state', event.target.value)
                                    }}
                                />
                            </div>
                            <div className="address-zip">
                                <CustomField
                                    required={true}
                                    error={showFormErrors && formElement.zip.firstErrorMessage}
                                    errorMessage={formElement.zip.firstErrorMessage}
                                    value={formElement.zip.value}
                                    customFieldType={CustomFieldType.INPUT}
                                    label="ZIP Code*"
                                    onBlur={this.onUpdateAddress(extraLocationIdx)}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.onUpdateLocation(extraLocationIdx, 'zip', event.target.value)
                                        this.validateZipCode({
                                            locationIndex: extraLocationIdx,
                                            zipCode: event.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="address-country">
                                <CustomMultiselectField
                                    search
                                    items={sortedCountriesList}
                                    selectedItems={[formElement.countryCodeIsoAlpha2.value || '']}
                                    maxSelected={1}
                                    keyProperty="code"
                                    displayProperty="name"
                                    placeholder="Select Country"
                                    label="Country*"
                                    searchPlaceholder="Search Countries"
                                    error={showFormErrors && formElement.countryCodeIsoAlpha2.firstErrorMessage}
                                    errorMessage={formElement.countryCodeIsoAlpha2.firstErrorMessage}
                                    onSelectElement={(values: string[]) =>
                                        this.onChangeCountry(extraLocationIdx, values[0] || '')
                                    }
                                />
                            </div>
                        </div>
                        {formElement.timezoneListForCountry?.value && (
                            <div className="row">
                                <div className="address-timezone">
                                    <CustomMultiselectField
                                        items={
                                            formElement.timezoneListForCountry.value
                                                ? getTimeZonesWithAbbreviations(
                                                      formElement.timezoneListForCountry.value,
                                                  )
                                                : []
                                        }
                                        search={formElement.timezoneListForCountry?.value?.length > 8}
                                        searchPlaceholder="Search Timezones"
                                        selectedItems={[formElement.timezone?.value || '']}
                                        maxSelected={1}
                                        keyProperty="timezoneIanaTZ"
                                        displayProperty="timezoneString"
                                        placeholder="Select Time Zone"
                                        label="Time Zone"
                                        error={showFormErrors && formElement.timezone?.firstErrorMessage}
                                        errorMessage={formElement.timezone?.firstErrorMessage}
                                        onSelectElement={(values: string[]) =>
                                            this.onUpdateLocation(extraLocationIdx, 'timezone', values[0] || '')
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {this.state.geolocationFailed[extraLocationIdx] && (
                            <div className="row">
                                <ErrorMessage type="error">
                                    Geocoding has failed. Please input the geographic coordinates.
                                </ErrorMessage>
                            </div>
                        )}

                        <div className="row">
                            <div className="field field--position field--lat">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    label="Latitude*"
                                    value={formElement.lat?.value || ''}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.onUpdateLocation(extraLocationIdx, 'lat', event.target.value)
                                    }}
                                    error={showFormErrors && formElement.lat.firstErrorMessage}
                                    errorMessage={formElement.lat.firstErrorMessage}
                                />
                                <span className="field-position__helper">{latHelper()}</span>
                            </div>

                            <div className="field field--position field--lng">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    label="Longitude*"
                                    value={formElement.lng?.value || ''}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.onUpdateLocation(extraLocationIdx, 'lng', event.target.value)
                                    }}
                                    error={showFormErrors && formElement.lng.firstErrorMessage}
                                    errorMessage={formElement.lng.firstErrorMessage}
                                />
                                <span className="field-position__helper">{lngHelper()}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="products">
                                <CustomMultiselectField
                                    items={ProductTypes}
                                    keyProperty="value"
                                    displayProperty="displayName"
                                    placeholder="Select"
                                    label="Products*"
                                    selectAllLabel="All Products"
                                    error={showFormErrors && Boolean(formElement.products.firstErrorMessage)}
                                    errorMessage={formElement.products.firstErrorMessage}
                                    onSelectElement={(values: string[]) =>
                                        this.onUpdateLocation(extraLocationIdx, 'products', values)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    render() {
        const { showFormErrors, formElements } = this.state
        const primaryLocationCountryCode = this.getPrimaryLocationCountryCode()

        return (
            <div className={classNames('create-practice')}>
                <div
                    className="content"
                    ref={ref => {
                        this.scrollContainer = ref
                    }}
                >
                    <div className="row">
                        <div className="practice-specialty-type">
                            <CustomMultiselectField
                                items={PracticeSpecialtyTypes}
                                selectedItems={[formElements.practiceSpecialtyType.value || '']}
                                maxSelected={1}
                                keyProperty="value"
                                displayProperty="display"
                                placeholder="Select Specialty"
                                label="Location Specialty*"
                                error={showFormErrors && formElements.practiceSpecialtyType.firstErrorMessage}
                                errorMessage={formElements.practiceSpecialtyType.firstErrorMessage}
                                onSelectElement={(values: string[]) =>
                                    this.onUpdate(({ practiceSpecialtyType: u }) => {
                                        u.value = values[0] || ''
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="practice-name">
                            <CustomField
                                required={true}
                                error={showFormErrors && formElements.practiceName.firstErrorMessage}
                                errorMessage={formElements.practiceName.firstErrorMessage}
                                value={formElements.practiceName.value}
                                customFieldType={CustomFieldType.INPUT}
                                label="Practice Name*"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                    this.onUpdate(({ practiceName: u }) => {
                                        u.value = event.target.value
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="admin-name">
                            <CustomField
                                required={true}
                                error={showFormErrors && formElements.firstName.firstErrorMessage}
                                errorMessage={formElements.firstName.firstErrorMessage}
                                value={formElements.firstName.value}
                                customFieldType={CustomFieldType.INPUT}
                                label="Owner First Name*"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                    this.onUpdate(({ firstName: u }) => {
                                        u.value = event.target.value
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>
                        <div className="admin-name">
                            <CustomField
                                required={true}
                                error={showFormErrors && formElements.lastName.firstErrorMessage}
                                errorMessage={formElements.lastName.firstErrorMessage}
                                value={formElements.lastName.value}
                                customFieldType={CustomFieldType.INPUT}
                                label="Owner Last Name*"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                    this.onUpdate(({ lastName: u }) => {
                                        u.value = event.target.value
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="admin-email">
                            <CustomField
                                required={true}
                                error={showFormErrors && formElements.username.firstErrorMessage}
                                errorMessage={formElements.username.firstErrorMessage}
                                value={formElements.username.value}
                                customFieldType={CustomFieldType.INPUT}
                                label="Owner Email Address*"
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                    this.onUpdate(({ username: u }) => {
                                        u.value = event.target.value
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>
                        <div className="phone-number">
                            <CustomPhoneInput
                                error={showFormErrors && formElements.phone.firstErrorMessage}
                                errorMessage={formElements.phone.firstErrorMessage}
                                value={formElements.phone.value}
                                label="Phone Number*"
                                key={primaryLocationCountryCode}
                                country={primaryLocationCountryCode}
                                onCountryChange={this.onChangePhoneCountry}
                                onChange={(value: string) => {
                                    this.onUpdate(({ phone: u }) => {
                                        u.value = value
                                        u.isDirty = true
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="integration-types">
                            <CustomMultiselectField
                                items={IntegrationTypes}
                                selectedItems={[formElements.integrationType.value || '']}
                                maxSelected={1}
                                keyProperty="name"
                                displayProperty="displayName"
                                placeholder="Select Integration Type"
                                label="Integration Type"
                                onSelectElement={(values: string[]) =>
                                    this.onUpdate(({ integrationType: u }) => {
                                        u.value = values[0] || ''
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="address-title">Primary Location</div>
                    </div>
                    {this.state.formElements.locations &&
                        this.state.formElements.locations.map((_1, i) => this.addressFields(i))}
                    <div className="row">
                        <div
                            className="multi-locations"
                            ref={ref => {
                                this.scrollTarget = ref
                            }}
                        >
                            <Button onClick={this.onAddLocation}>Add Another Location</Button>
                        </div>
                    </div>
                </div>
                <button
                    className={classNames('save', {
                        active: this.state.isFormDirty,
                        'can-save': this.state.isFormDirty,
                    })}
                    onClick={this.onSave}
                    disabled={!this.state.isFormDirty}
                    type="button"
                    tabIndex={0}
                >
                    Register Practice
                </button>
            </div>
        )
    }
}

export default CreatePracticeForm
