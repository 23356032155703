import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { TableCellClassKey } from '@mui/material/TableCell'
import { createStyles, withStyles } from '@mui/styles'
import _ from 'lodash'

import { RootState } from '../../appReducer'
import { useAppDispatch } from '../../util/useAppDispatch'
import { addShortcut, deleteShortcut, editShortcut, fetchShortcuts } from '../chat-center/actions'
import { SearchBar } from '../shared/custom-fields/SearchBar'

import ShortcutModalBody from './ShortcutModalBody'
import ShortcutSuccessModalBody from './ShortcutSuccessModalBody'

import './AdminShortcutList.sass'

const styles = createStyles({
    root: {
        height: '10px',
    },
})

type TableCellClassOverrides = {
    readonly [key in TableCellClassKey]?: string
}

type Props = any

export enum ShortcutModalType {
    None,
    AddShortcut,
    EditShortcut,
    DeleteShortcut,
}

export interface ShortcutModalData {
    open: boolean
    type: ShortcutModalType
    id?: string
    name?: string
    message?: string
}

export interface SuccessModalData {
    open: boolean
    type: ShortcutModalType
    success?: boolean
    name?: string
}

const tableCellClassOverrides: TableCellClassOverrides = {
    root: `shortcut-list-table-cell`,
}

const AdminShortcutList = (props: Props) => {
    const { classes } = props
    const [shortcutModal, setShortcutModal] = useState<ShortcutModalData>({ open: false, type: ShortcutModalType.None })
    const [successModal, setSuccessModal] = useState<SuccessModalData>({ open: false, type: ShortcutModalType.None })
    const [searchTerms, setSearchTerms] = useState<Api.Amplify.ShortcutSearchTerms>({})
    const { searchKey } = searchTerms
    const { open: shortcutModalOpen, type: shortcutModalType, id, name: shortcutName, message } = shortcutModal
    const { open: successModalOpen, type: successModalType, name: successName, success } = successModal

    const dispatch = useAppDispatch()

    const shortcuts = useSelector((state: RootState) => state.chat.shortcuts)

    const throttledFetchShortcuts = useCallback(
        _.throttle((searchTerms: Api.Amplify.ShortcutSearchTerms) => {
            dispatch(fetchShortcuts(searchTerms))
        }, 250),
        [],
    )
    useEffect(() => {
        throttledFetchShortcuts(searchTerms)
    }, [dispatch, searchTerms, throttledFetchShortcuts])

    const searchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = e.target && encodeURIComponent(e.target.value)
        setSearchTermsState(searchKey)
    }
    const clearSearch = () => {
        const searchKey = ''
        setSearchTermsState(searchKey)
    }
    const setSearchTermsState = (searchKey: string) => {
        setSearchTerms({
            searchKey,
        })
    }

    const onOpenShortcutModal = (type: ShortcutModalType, id = '', name = '', message = '') => {
        onCloseSuccessModal()
        setShortcutModal({
            open: true,
            type,
            id,
            name: name.replace('/', ''),
            message,
        })
    }

    const onCloseShortcutModal = () => {
        setShortcutModal({ open: false, type: ShortcutModalType.None })
    }

    const onOpenSuccessModal = (type: ShortcutModalType, success = true, name = '') => {
        onCloseShortcutModal()
        setSuccessModal({ open: true, success, type, name })
    }

    const onCloseSuccessModal = () => {
        setSuccessModal({ open: false, type: ShortcutModalType.None, name: '' })
    }

    const onAddShortcut = (name: string, message: string) => {
        const foundShortcut = shortcuts.find(shortcut => shortcut.name === name)

        if (foundShortcut) {
            onOpenSuccessModal(ShortcutModalType.AddShortcut, false, name)
            return
        }

        dispatch(addShortcut({ name, message }))
        onOpenSuccessModal(ShortcutModalType.AddShortcut, true, name)
    }

    const onEditShortcut = async (id: string, name: string, message: string) => {
        await dispatch(editShortcut({ id, name, message }))
        onOpenSuccessModal(ShortcutModalType.EditShortcut, true, name)
    }

    const onDeleteShortcut = async (id: string, name: string) => {
        await dispatch(deleteShortcut(id))
        onOpenSuccessModal(ShortcutModalType.DeleteShortcut, true, name)
    }

    return (
        <React.Fragment>
            <div className="shortcut-list-header-container">
                <div className="shortcut-list-header">
                    <p className="shortcut-list-header-title">Shortcuts</p>
                    <div className="shortcut-list-header-controls">
                        <div className="shortcut-list-search-box">
                            <SearchBar
                                value={searchKey || ''}
                                onChange={searchByKeyword}
                                onClear={clearSearch}
                                placeholder="Search by Shortcut Name"
                            />
                        </div>
                        <Button
                            className="create-new-shortcut-button"
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={() => onOpenShortcutModal(ShortcutModalType.AddShortcut)}
                        >
                            Create new shortcut
                        </Button>
                    </div>
                </div>
            </div>
            <div className="shortcut-list-body">
                <Table>
                    <TableHead>
                        <TableRow classes={classes}>
                            <TableCell classes={tableCellClassOverrides}>Shortcut Name</TableCell>
                            <TableCell classes={tableCellClassOverrides}>Message</TableCell>
                            <TableCell classes={tableCellClassOverrides} />
                            <TableCell classes={tableCellClassOverrides} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shortcuts.map(shortcut => (
                            <TableRow key={shortcut.id}>
                                <TableCell>/{shortcut.name}</TableCell>
                                <TableCell>{shortcut.message}</TableCell>
                                <TableCell>
                                    <div
                                        className="button"
                                        onClick={() => {
                                            return onOpenShortcutModal(
                                                ShortcutModalType.EditShortcut,
                                                shortcut.id,
                                                shortcut.name,
                                                shortcut.message,
                                            )
                                        }}
                                    >
                                        <i className="material-icons">edit</i>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div
                                        className="button"
                                        onClick={() => {
                                            return onOpenShortcutModal(
                                                ShortcutModalType.DeleteShortcut,
                                                shortcut.id,
                                                shortcut.name,
                                            )
                                        }}
                                    >
                                        <i className="material-icons">delete</i>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {shortcutModalOpen && (
                <Modal
                    open={shortcutModalOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseShortcutModal()
                        }
                    }}
                >
                    <ShortcutModalBody
                        type={shortcutModalType}
                        onCloseShortcutModal={onCloseShortcutModal}
                        onAddShortcut={onAddShortcut}
                        onEditShortcut={onEditShortcut}
                        onDeleteShortcut={onDeleteShortcut}
                        id={id}
                        name={shortcutName}
                        message={message}
                    />
                </Modal>
            )}
            {successModalOpen && (
                <Modal
                    className="success-modal"
                    open={successModalOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseSuccessModal()
                        }
                    }}
                >
                    <ShortcutSuccessModalBody
                        type={successModalType}
                        success={success}
                        name={successName}
                        onCloseSuccessModal={onCloseSuccessModal}
                        onOpenShortcutModal={onOpenShortcutModal}
                    />
                </Modal>
            )}
        </React.Fragment>
    )
}

export default withStyles(styles)(AdminShortcutList)
