import { HeartbeatAgentLocation } from '../../models/Integrations'

export function mapHeartbeatAgentLocation(location: Api.Integrations.HeartbeatAgentLocation): HeartbeatAgentLocation {
    return {
        externalLocationId: location.external_location_id,
        practiceLocation: location.practice_location,
        hasHeartbeat: location.has_heartbeat,
        hasDirectScheduling: location.has_direct_scheduling,
        hasChairfill: location.has_chairfill,
        hasPayments: location.has_payments,
        hasReviews: location.has_reviews,
        chairfillSelfSchedulingWebCode: location.chairfill_self_scheduling_web_code,
    }
}

export function mapHeartbeatAgentLocations(
    heartbeatAgents: Api.Integrations.HeartbeatAgentLocation[],
): HeartbeatAgentLocation[] {
    return heartbeatAgents.map(mapHeartbeatAgentLocation)
}
