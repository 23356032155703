import React from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@mui/material'

import { RootState } from '../../../../appReducer'
import { useAppDispatch } from '../../../../util/useAppDispatch'

import {
    closeStripeAccountIdModal,
    updateStripeAccountIdAllLocations,
    updateStripeAccountIdModalData,
    updateStripeAccountIdSingleLocation,
} from './actions'
import StripeAccountIdForm from './StripeAccountIdForm'

interface Props {
    practiceId: Models.Practice['id']
}

const StripeAccountIdModal = ({ practiceId }: Props) => {
    const dispatch = useAppDispatch()
    const { isOpen, locationName, stripeAccountId, applyToAll, isLoading, errorMessage, locationId } = useSelector(
        (state: RootState) => state.practiceLocations[practiceId].modals.stripeAccountId,
    )

    const handleCloseStripeAccountIdModal = (): void => {
        if (isLoading) return

        dispatch(closeStripeAccountIdModal(practiceId))
    }

    const handleUpdateStripeAccountId = (): void => {
        if (isLoading && !stripeAccountId) return

        dispatch(updateStripeAccountIdModalData({ isLoading: true, errorMessage: '' }, practiceId))

        if (applyToAll === true) {
            const confirmAll = window.confirm(
                'Are you sure you want to apply this Stripe ID to all locations for this practice?',
            )
            if (!confirmAll) return

            dispatch(updateStripeAccountIdAllLocations(practiceId, stripeAccountId || ''))
        } else {
            dispatch(updateStripeAccountIdSingleLocation(practiceId, locationId, stripeAccountId || ''))
        }
    }

    const handleApplyToAllLocationsToggle = (updatedToggle: boolean): void => {
        if (isLoading) return

        dispatch(updateStripeAccountIdModalData({ applyToAll: updatedToggle }, practiceId))
    }

    const handleStripeIdChange = (updatedAccountId: string): void => {
        if (isLoading) return

        dispatch(updateStripeAccountIdModalData({ stripeAccountId: updatedAccountId }, practiceId))
    }

    if (!isOpen) {
        return null
    }

    return (
        <Modal
            open={isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleCloseStripeAccountIdModal()
                }
            }}
            disableEscapeKeyDown
        >
            <StripeAccountIdForm
                locationName={locationName}
                accountId={stripeAccountId || ''}
                shouldApplyToAllLocations={applyToAll}
                isSubmiting={isLoading}
                errorMessage={errorMessage}
                onClose={handleCloseStripeAccountIdModal}
                onSubmit={handleUpdateStripeAccountId}
                onApplyToAllLocationsChange={handleApplyToAllLocationsToggle}
                onStripeIdChange={handleStripeIdChange}
            />
        </Modal>
    )
}

export default StripeAccountIdModal
