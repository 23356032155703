export interface ApiProcedure {
    id: string
    name: string
    changeable: boolean
    enabled: boolean
    duration?: number
    patient_type: Models.PatientTypeValue
}

export function mapProcedure(procedure: ApiProcedure, practiceId: string): Models.Procedure {
    return {
        id: procedure.id,
        name: procedure.name,
        changeable: procedure.changeable,
        enabled: procedure.enabled,
        practiceId: practiceId,
        duration: procedure.duration,
        patientType: procedure.patient_type,
        availability: {},
    }
}

export function mapProcedures(procedures: ApiProcedure[], practiceId: string): Models.Procedure[] {
    if (!Array.isArray(procedures)) {
        return []
    }
    return procedures.map((procedure: ApiProcedure) => mapProcedure(procedure, practiceId))
}
