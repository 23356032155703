import React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import SelfSchedulingModel from '../../../../models/SelfScheduling'

import './SelfSchedulingButtonStyle.sass'

export type SelfSchedulingButtonStyleProps = {
    style: Models.SelfSchedulingButtonStyle
    updateStyle: (style: Models.SelfSchedulingButtonStyle) => void
}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

const radioClassOverrides: RadioClassOverrides = {
    root: 'radio-root',
}

const moduleName = 'self-scheduling-button-style'

function SelfSchedulingButtonStyle(props: SelfSchedulingButtonStyleProps) {
    const { style = SelfSchedulingModel.defaultButtonStyle } = props

    function updateStyle(e: React.ChangeEvent<HTMLInputElement>) {
        props.updateStyle(e.target.value as Models.SelfSchedulingButtonStyle)
    }

    return (
        <div className={moduleName}>
            <h3 className="webcode-section-title">Style:</h3>
            <div className={`${moduleName}__wrapper`}>
                <Radio
                    id="button-style-default"
                    color="primary"
                    checked={style === 'default_position'}
                    onChange={updateStyle}
                    name="radio-widget-style"
                    value="default_position"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__label-wrapper`}>
                    <label htmlFor="button-style-default">
                        <span className={`${moduleName}__label-text`}>Default Position</span>
                    </label>
                </div>
            </div>
            <div className={`${moduleName}__wrapper`}>
                <Radio
                    id="button-style-custom"
                    color="primary"
                    checked={style === 'custom_position'}
                    onChange={updateStyle}
                    name="radio-widget-style"
                    value="custom_position"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__label-wrapper`}>
                    <label htmlFor="button-style-custom">
                        <span className={`${moduleName}__label-text`}>Custom Position</span>
                    </label>
                </div>
            </div>
            <div className={`${moduleName}__wrapper`}>
                <Radio
                    id="button-style-client"
                    color="primary"
                    checked={style === 'client_button'}
                    onChange={updateStyle}
                    name="radio-widget-style"
                    value="client_button"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__label-wrapper`}>
                    <label htmlFor="button-style-client">
                        <span className={`${moduleName}__label-text`}>Client Button</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingButtonStyle
