import { mapRSVPConnectedLocationsToModel } from '../../Api/mappers/rsvp/locations'
import ApiService from '../service'

export default class RSVPService extends ApiService {
    readonly ModulePath = 'api/admin/rsvp/v1'

    async putConnectLocation(
        connectLocationData: Api.RSVP.ConnectLocationData[],
    ): Promise<Models.RSVPConnectedLocation[]> {
        const { data: connectedLocations } = await this.fetch(`rsvp-practices/locations`, {
            method: 'PUT',
            body: JSON.stringify(connectLocationData),
        })
        return mapRSVPConnectedLocationsToModel(connectedLocations)
    }
}
