import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, Icon, TextField } from '@material-ui/core'
import { Modal } from '@mui/material'
import classNames from 'classnames'

import { RootState } from '../../../../appReducer'
import { useAppDispatch } from '../../../../util/useAppDispatch'

import {
    closeStatementDescriptorModal,
    updateStatementDescriptorAllLocations,
    updateStatementDescriptorModalData,
    updateStatementDescriptorSingleLocation,
} from './actions'

interface Props {
    practiceId: Models.Practice['id']
}

const StatementDescriptorModal = ({ practiceId }: Props) => {
    const dispatch = useAppDispatch()
    const { isOpen, statementDescriptor, applyToAll, isLoading, errorMessage, locationId } = useSelector(
        (state: RootState) => state.practiceLocations[practiceId].modals.statementDescriptor,
    )

    const [successMessage, setSuccessMessage] = useState('')

    const handleCloseStatementDescriptorModal = (): void => {
        if (isLoading) return

        dispatch(closeStatementDescriptorModal(practiceId))
    }

    const handleUpdateStatementDescriptor = async () => {
        if (isLoading || !statementDescriptor) return

        setSuccessMessage('')

        const stateLen = statementDescriptor.length
        const charRegex = /^(?=[A-z])[A-z0-9-&@ ]*$/

        if (stateLen < 5 || stateLen > 22) {
            dispatch(
                updateStatementDescriptorModalData(
                    {
                        errorMessage: `Descriptor must be 5-22 characters long.`,
                    },
                    practiceId,
                ),
            )
            return
        }
        if (!charRegex.test(statementDescriptor)) {
            dispatch(
                updateStatementDescriptorModalData(
                    {
                        errorMessage: `Descriptor is invalid.`,
                    },
                    practiceId,
                ),
            )
            return
        }

        dispatch(updateStatementDescriptorModalData({ isLoading: true, errorMessage: '' }, practiceId))

        try {
            if (applyToAll === true) {
                const confirmAll = window.confirm(
                    'Are you sure you want to apply this Statement Descriptor to all locations for this practice?',
                )
                if (!confirmAll) return

                await dispatch(updateStatementDescriptorAllLocations(practiceId, statementDescriptor || ''))
            } else {
                await dispatch(
                    updateStatementDescriptorSingleLocation(practiceId, locationId, statementDescriptor || ''),
                )
            }

            dispatch(updateStatementDescriptorModalData({ isLoading: false, errorMessage: '' }, practiceId))
            setSuccessMessage('Changes saved')
        } catch (err) {
            dispatch(updateStatementDescriptorModalData({ isLoading: false }, practiceId))
        }
    }

    const handleApplyToAllLocationsToggle = (updatedToggle: boolean): void => {
        if (isLoading) return

        dispatch(updateStatementDescriptorModalData({ applyToAll: updatedToggle }, practiceId))
    }

    const handleStatementDescriptorChange = (updatedStatementDescriptor: string): void => {
        if (isLoading) return

        dispatch(updateStatementDescriptorModalData({ statementDescriptor: updatedStatementDescriptor }, practiceId))
    }

    if (!isOpen) {
        return null
    }

    return (
        <Modal
            open={isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleCloseStatementDescriptorModal()
                }
            }}
            disableEscapeKeyDown
        >
            <div className="location-modal">
                <div>
                    <i className="material-icons close-modal" onClick={handleCloseStatementDescriptorModal}>
                        close
                    </i>
                    <div className="stripe-modal-wrapper">
                        {errorMessage !== '' && (
                            <div className="payments-stripe-modal-error">
                                <Icon className="info-payment-icon">close</Icon>
                                <span>{errorMessage}</span>
                            </div>
                        )}
                        {successMessage !== '' && (
                            <div className="payments-stripe-modal-success">
                                <Icon className="info-payment-icon">check_circle</Icon>
                                <span>{successMessage}</span>
                            </div>
                        )}
                        <h1 className="name-header">Statement Descriptor</h1>
                        <div>
                            <div className="stripe-modal-content">
                                <p>
                                    This is the name that will appear on any credit card charges associated with this
                                    location.
                                </p>
                                <label className="stripe-input-label">Statement Descriptor (22 characters max)</label>
                                <TextField
                                    required
                                    className="field terminal-nickname"
                                    value={statementDescriptor}
                                    placeholder="Statement Descriptor"
                                    onChange={event => handleStatementDescriptorChange(event.target.value.trim())}
                                    variant="outlined"
                                />
                                <FormControlLabel
                                    className="location-stripe-apply-all"
                                    control={
                                        <Checkbox
                                            checked={applyToAll}
                                            className="location-stripe-apply-all-checkbox"
                                            onChange={event => handleApplyToAllLocationsToggle(event.target.checked)}
                                            value="all-websites"
                                            color="primary"
                                        />
                                    }
                                    label="Apply to all locations"
                                />
                            </div>
                            <button
                                className={classNames('update-button', {
                                    'can-save': statementDescriptor !== '',
                                })}
                                onClick={handleUpdateStatementDescriptor}
                                disabled={!(statementDescriptor !== '') || isLoading}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default StatementDescriptorModal
