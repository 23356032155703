import * as React from 'react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import moment from 'moment'

import './SchedulingErrorMessage.sass'

export type SchedulingErrorMessageWithShareAppointmentTimeButtonProps = {
    message: string
    shareAppointment: (datetime: moment.Moment, postponed: boolean) => () => void
    datetime: moment.Moment
    selectedDatetime?: moment.Moment | undefined
    type?: 'error' | 'warning'
}

const moduleName = 'scheduling-error-message'

const SchedulingErrorMessageWithShareAppointmentTimeButton = (
    props: SchedulingErrorMessageWithShareAppointmentTimeButtonProps,
) => {
    const { message, selectedDatetime, type, datetime, shareAppointment } = props
    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__wrapper ${type && `${moduleName}__wrapper--${type}`}`}>
                <Icon>warning</Icon>
                <div className={`${moduleName}__message`}>{message}</div>
            </div>

            <div className={`${moduleName}__submit`}>
                <Button
                    disabled={!selectedDatetime}
                    className={`${moduleName}__share-appointment-button`}
                    onClick={shareAppointment(datetime, true)}
                >
                    SHARE APPOINTMENT TIME IN CHAT
                </Button>
            </div>
        </div>
    )
}

export default SchedulingErrorMessageWithShareAppointmentTimeButton
