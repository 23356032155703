import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { HeartbeatAgentLocation } from '../../../models/Integrations'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { SearchBar } from '../../shared/custom-fields/SearchBar'
import InfoMessage from '../../shared/info-message/info-message'
import Paginator from '../../shared/Paginator'

import ErrorNotificationCard from './customization/ErrorNotificationCard'
import SelfSchedulingWebCodeCustomizationForm from './customization/SelfSchedulingWebCodeCustomizationForm'
import * as selfSchedulingActions from './actions'
import SelfSchedulingWebCodeCard from './SelfSchedulingWebCodeCard'
import SelfSchedulingWebCodeForm from './SelfSchedulingWebCodeForm'

import './SelfSchedulingWebCodeManager.sass'

export type Props = {
    account: AdminAccount
    practice: Models.Practice
    onBack: () => void
}

const moduleName = 'self-scheduling-web-code-manager'

function SelfSchedulingWebCodeManager(props: Props) {
    const { account, practice, onBack } = props

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [customizeModalOpen, setCustomizeModalOpen] = useState<boolean>(false)
    const [errorNotificationCardOpen, setErrorNotificationCardOpen] = useState<boolean>(false)
    const [selectedWebCode, setSelectedWebCode] = useState<Models.SelfSchedulingWebCode | undefined>()
    const [searchValue, setSearchValue] = useState('')
    const [isListLoading, setIsListLoading] = useState<boolean>(true)
    const [firstListLoadDone, setFirstListLoadDone] = useState<boolean>(false)
    const [hasSearch, setHasSearch] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [paginationInfo, setPaginationInfo] = useState<Models.PaginationInfo>({
        allPages: 1,
        allRows: 0,
    })

    const webCodes = useSelector((state: RootState) => state.selfScheduling.webCodes[practice.id])
    const rsvpEnabledLocations = useSelector(
        (state: RootState) => state.selfScheduling.rsvpEnabledLocations[practice.id],
    )
    const dispatch = useAppDispatch()

    const modalRef = useRef<HTMLDivElement>()
    const initLoadComplete = useRef(false)

    const onFetchWebCodes = useCallback(
        async (nextPage: number, search: string) => {
            try {
                setCurrentPage(nextPage)
                setIsListLoading(true)
                const metadata = await dispatch(
                    selfSchedulingActions.fetchSelfSchedulingWebCodeList(practice.id, { search, page: nextPage }),
                )
                setPaginationInfo({
                    allPages: metadata?.pagination_info?.allPages || nextPage,
                    allRows: metadata?.pagination_info?.allRows || 0,
                })
                setIsListLoading(false)
                initLoadComplete.current = true
            } catch (_) {
                setIsListLoading(false)
            }
        },
        [dispatch, practice],
    )

    const handleDebouncedSearch = useMemo(() => throttle(onFetchWebCodes, 500), [onFetchWebCodes])

    useEffect(() => {
        if (!initLoadComplete.current) {
            onFetchWebCodes(1, '')
        }
    }, [onFetchWebCodes])

    useEffect(() => {
        if (initLoadComplete.current) {
            handleDebouncedSearch(1, searchValue)
        }
    }, [handleDebouncedSearch, searchValue])

    useEffect(() => {
        dispatch(selfSchedulingActions.getRSVPEnabledLocations(practice.id))
    }, [dispatch, practice.id])

    useEffect(() => {
        if ((paginationInfo.allPages > 1 || searchValue.trim() !== '') && !firstListLoadDone) {
            setHasSearch(true)
            setFirstListLoadDone(true)
        }
    }, [paginationInfo, searchValue, firstListLoadDone])

    function onShowModal() {
        setModalOpen(true)
    }

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const clearSearch = () => {
        setSearchValue('')
    }

    function onCloseErrorNotification() {
        setErrorNotificationCardOpen(false)
    }

    async function onCloseModal() {
        if (selectedWebCode?.id && customizeModalOpen) {
            const numberOfEnabledLocations = await dispatch(
                selfSchedulingActions.fetchNumberOfEnabledSelfSchedulingWebCodeLocations(selectedWebCode.id),
            )
            if (numberOfEnabledLocations === 0) {
                setErrorNotificationCardOpen(true)
                return
            }
        }

        setModalOpen(false)
        setCustomizeModalOpen(false)
        setSelectedWebCode(undefined)
    }

    function onEditWebCode(webCode: Models.SelfSchedulingWebCode) {
        setModalOpen(true)
        setSelectedWebCode(webCode)
    }

    function onCustomizeWebCode(webCode: Models.SelfSchedulingWebCode) {
        setCustomizeModalOpen(true)
        setSelectedWebCode(webCode)
    }

    function onToggleActive(webCode: Models.SelfSchedulingWebCode) {
        const updates = {
            id: webCode.id,
            active: !webCode.active,
        }
        onSaveWebCode(updates)

        if (webCode.active) {
            disableAllRSVPConnectionsForWebcode(webCode.id)
        }
    }

    function onGenerateSelfSchedulingWebCode(url: string) {
        if (selectedWebCode) {
            return dispatch(selfSchedulingActions.saveSelfSchedulingWebCode({ id: selectedWebCode.id, url }))
        }

        return dispatch(
            selfSchedulingActions.createSelfSchedulingWebCode({
                practice_id: practice.id,
                account_id: account.id,
                url,
                active: true,
            }),
        )
    }

    async function onSaveWebCode(updates: Api.SelfScheduling.WebCodeUpdate) {
        await dispatch(selfSchedulingActions.saveSelfSchedulingWebCode(updates))
        onCloseModal()
    }

    function isLocationRSVPConnectedOnWebcode(location: HeartbeatAgentLocation, webCodeId: string) {
        const connectedWebCodeId = location.chairfillSelfSchedulingWebCode?.id
        return Boolean(connectedWebCodeId) && connectedWebCodeId === webCodeId
    }

    async function disableAllRSVPConnectionsForWebcode(webCodeId: string) {
        const connectLocationData = rsvpEnabledLocations
            .filter(loc => isLocationRSVPConnectedOnWebcode(loc, webCodeId))
            .map(loc => {
                return {
                    location_id: loc.practiceLocation.id,
                    self_scheduling_web_code_id: webCodeId,
                    active: false,
                }
            })

        if (connectLocationData.length === 0) {
            return
        }

        await dispatch(
            selfSchedulingActions.updateLocationRSVPConnection({
                active: false,
                practiceId: practice.id,
                webCodeId,
                connectLocationData,
            }),
        )

        dispatch(selfSchedulingActions.setAllRSVPsEnabled(webCodeId, false))
    }

    const onPageChange = (newPage: number) => {
        onFetchWebCodes(newPage, searchValue)
    }

    if (currentPage === 1 && !firstListLoadDone && !webCodes) {
        return (
            <div className={moduleName}>
                <div className="loader loader--global">
                    <CircularProgress className="loader-spinner" size={50} color="primary" variant="indeterminate" />
                </div>
            </div>
        )
    }

    const noRecordsFound = !isListLoading && searchValue === '' && webCodes.length === 0

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__back-button-wrapper`}>
                <button className={`${moduleName}__back-button`} onClick={onBack}>
                    <i className="material-icons">arrow_back</i>
                </button>
                <div className="search-container">
                    {hasSearch ? (
                        <SearchBar
                            value={searchValue}
                            onChange={onSearch}
                            onClear={clearSearch}
                            isMessageShown={searchValue.length > 0 && !webCodes?.length}
                            placeholder="Search by URL or Web Code Key"
                        />
                    ) : null}
                    {noRecordsFound && <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>}
                </div>
            </div>
            <div className={`${moduleName}__card-container`}>
                {webCodes?.map(webCode => (
                    <SelfSchedulingWebCodeCard
                        key={webCode.id}
                        practice={practice}
                        webCode={webCode}
                        onToggleActive={onToggleActive}
                        onEditWebCode={onEditWebCode}
                        onCustomizeWebCode={onCustomizeWebCode}
                    />
                ))}
                {isListLoading && (
                    <div className="loader loader--list">
                        <CircularProgress
                            className="loader-spinner"
                            size={50}
                            color="primary"
                            variant="indeterminate"
                        />
                    </div>
                )}
            </div>
            {!noRecordsFound && (
                <div className={`${moduleName}__paginator-wrapper`}>
                    <Paginator currentPage={currentPage} paginationInfo={paginationInfo} selectPage={onPageChange} />
                </div>
            )}

            <div className={`${moduleName}__footer`} onClick={onShowModal}>
                <div className="button">
                    <i className="material-icons add-icon">add</i>
                    Add Web Code
                </div>
            </div>

            {modalOpen && (
                <Modal
                    className="pst-modal"
                    open={modalOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className={`contents ${moduleName}__code-modal`}>
                        <div className="close-modal-button" onClick={onCloseModal}>
                            <i className="material-icons">close</i>
                        </div>
                        <SelfSchedulingWebCodeForm
                            practice={practice}
                            webCode={selectedWebCode}
                            onCloseModal={onCloseModal}
                            onSaveWebCode={onSaveWebCode}
                            onGenerateSelfSchedulingWebCode={onGenerateSelfSchedulingWebCode}
                        />
                    </div>
                </Modal>
            )}

            {customizeModalOpen && selectedWebCode && (
                <Modal
                    className="pst-modal"
                    open={customizeModalOpen}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className={`contents ${moduleName}__code-modal`} ref={modalRef as React.RefObject<any>}>
                        <div className="close-modal-button" onClick={onCloseModal}>
                            <i className="material-icons">close</i>
                        </div>
                        <SelfSchedulingWebCodeCustomizationForm
                            account={account}
                            practice={practice}
                            webCode={selectedWebCode}
                            onSaveWebCode={onSaveWebCode}
                            modalRef={modalRef}
                        />
                        {errorNotificationCardOpen && <ErrorNotificationCard onClose={onCloseErrorNotification} />}
                        <div id="sf-portal-root"></div>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default SelfSchedulingWebCodeManager
