import { setLocationSurveyStatusToCompleted } from './modules/practices/actions'
import { updateReferralDashboardCard } from './modules/referrals/v2actions'
import { ReceiveToken, setSocketConnectionStatus } from './appActions'
import SimplifeyeSocket from './SimplifeyeSocket'

type AppAction = ReceiveToken

class DataEventsService {
    simplifeyeSocket: any
    store: any
    connected: boolean

    constructor() {
        this.simplifeyeSocket = new SimplifeyeSocket()
    }

    storeListener() {
        return (next: any) => (action: AppAction) => {
            const nextValue = next(action)

            switch (action.type) {
                case 'RECEIVE_TOKEN': {
                    this.connect(action.token)
                    break
                }
                default:
                    break
            }

            return nextValue
        }
    }

    registerStore(store: any) {
        if (this.store) {
            throw new Error('Store already registered with DataEvent instance')
        }
        this.store = store
    }

    connect(token: string) {
        this.simplifeyeSocket.connect({
            token,
            onReconnectCallback: this.onReconnect.bind(this),
            onDisconnectCallback: this.onDisconnect.bind(this),
        })

        this.simplifeyeSocket.register('amplify_referral', {
            dispatch: this.amplifyReferralEvent.bind(this),
            fallbackInterval: 1000,
            fallbackIntervalId: 3,
            persistentIntervalId: 4,
            skipFirst: true,
        })

        this.simplifeyeSocket.register('practice_location_survey', {
            dispatch: this.practiceLocationSurveyEvent.bind(this),
            fallbackInterval: 1000,
            fallbackIntervalId: 3,
            persistentIntervalId: 4,
            skipFirst: true,
        })
    }

    onReconnect() {
        this.connected = true
        this.store.dispatch(setSocketConnectionStatus(this.connected))
    }

    onDisconnect() {
        this.connected = false
        this.store.dispatch(setSocketConnectionStatus(this.connected))
    }

    disconnect() {
        this.simplifeyeSocket.disconnect()
        this.onDisconnect()
    }

    async amplifyReferralEvent(data: any) {
        if (data == null) {
            return
        }

        this.store.dispatch(updateReferralDashboardCard(data))
    }

    async practiceLocationSurveyEvent(data: any) {
        if (data == null || !data?.practice_id || !data?.practice_location_id) {
            return
        }

        this.store.dispatch(setLocationSurveyStatusToCompleted(data.practice_id, data.practice_location_id))
    }
}

const DataEvents = new DataEventsService()

export default DataEvents
