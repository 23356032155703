import React from 'react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'

import { errorRed, successGreen, textBlue } from '../styles/colors'

import './ConfirmModal.sass'

const moduleName = 'confirm-modal-one-button'

type ModalTheme = {
    name: string
    icon: string
    color: string
}

export const InformationTheme: ModalTheme = {
    name: 'info',
    icon: 'info',
    color: textBlue,
}

export const AlertTheme: ModalTheme = {
    name: 'alert',
    icon: 'error',
    color: errorRed,
}

export const SuccessTheme: ModalTheme = {
    name: 'success',
    icon: 'check_circle',
    color: successGreen,
}

type ConfirmModalProps = {
    isModalOpen: boolean
    closeModal: () => void
    onConfirm: () => void
    confirmLabel?: string
    title?: string
    children?: React.ReactNode
    theme?: ModalTheme
}

const ConfirmModal = ({
    isModalOpen,
    closeModal,
    title,
    children,
    confirmLabel = 'CONFIRM',
    onConfirm,
    theme = InformationTheme,
}: ConfirmModalProps) => {
    return (
        <Modal open={isModalOpen} onClose={closeModal}>
            <Paper className={moduleName}>
                <div className={`${moduleName}__close`}>
                    <IconButton size="large" onClick={closeModal}>
                        <i className={`material-icons ${moduleName}__close-icon`}>close</i>
                    </IconButton>
                </div>

                {title && <div className={`${moduleName}__header`}>{title}</div>}

                <div className={`${moduleName}__content`}>
                    {theme.icon && <Icon style={{ color: theme.color }}>{theme.icon}</Icon>}
                    <div className={`${moduleName}__children`}>{children}</div>
                </div>

                <div className={`${moduleName}__footer`}>
                    <Button
                        className={`${moduleName}__button ${moduleName}__button--${theme.name}`}
                        color="primary"
                        variant="outlined"
                        onClick={onConfirm}
                    >
                        {confirmLabel}
                    </Button>
                </div>
            </Paper>
        </Modal>
    )
}

export default ConfirmModal
