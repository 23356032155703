import moment from 'moment'

import { mapWebCodeLocationsToModel, mapWebCodeLocationToModel } from '../mappers'
import ApiService from '../service'

const pubnubRetentionPeriod = Number(process.env.REACT_APP_PUBNUB_RETENTION_PERIOD)

export default class AmplifyService extends ApiService {
    readonly ModulePath: string = 'api/admin/amplify/v1'
    private readonly PAGE_SIZE = 25

    async listReferrals(practice: Models.Practice, searchTerms: Api.Amplify.ReferralsSearchTerms = {}) {
        const {
            limit = this.PAGE_SIZE,
            page = 1,
            locationId,
            startDate,
            searchKey,
            sortByProperty = 'sortByCreated',
            sortingDirection = 'desc',
        } = searchTerms
        const queryParams: any = {
            limit,
            page,
            [sortByProperty]: sortingDirection.toUpperCase(),
        }
        if (locationId) {
            queryParams.locationId = locationId
        }
        if (startDate) {
            queryParams.startDate = startDate
        }
        if (searchKey) {
            queryParams.searchKey = searchKey
        }
        const data = (await this.fetch(
            `practices/${practice.id}/referrals`,
            {},
            queryParams,
        )) as Api.StructuredResponse<{
            referrals: Api.Amplify.Referral[]
            sum_production_values: Api.Amplify.PatientFinancialData
        }>

        return data
    }

    async deleteReferral(practiceId: string, referralId: string) {
        await this.fetch(`practices/${practiceId}/referrals/${referralId}`, {
            method: 'DELETE',
        })
    }

    async getSubscriber(practice: Models.Practice, subscriberId: string) {
        const { data: subscriber } = (await this.fetch(
            `practices/${practice.id}/subscribers/${subscriberId}`,
        )) as Api.StructuredResponse<Api.Amplify.Subscriber>

        return subscriber
    }

    async listSubscribers(practice: Models.Practice) {
        const { data: subscribers } = (await this.fetch(
            `practices/${practice.id}/subscribers`,
        )) as Api.StructuredResponse<Array<Api.Amplify.Subscriber>>

        return subscribers
    }

    async postCreateSubscriber(practice: Models.Practice, subscriber: Api.Amplify.CreateSubscriber) {
        const { data: newSubscriber } = (await this.fetch(`practices/${practice.id}/subscribers`, {
            method: 'POST',
            body: JSON.stringify(subscriber),
        })) as Api.StructuredResponse<Api.Amplify.Subscriber>

        return newSubscriber
    }

    async putUpdateSubscriber(
        practice: Models.Practice,
        subscriber: Models.Subscriber,
        updates: Api.Amplify.UpdateSubscriber,
    ) {
        const { data: updatedSubscriber } = (await this.fetch(`practices/${practice.id}/subscribers/${subscriber.id}`, {
            method: 'PUT',
            body: JSON.stringify(updates),
        })) as Api.StructuredResponse<Api.Amplify.Subscriber>

        return updatedSubscriber
    }

    async deleteSubscriber(practice: Models.Practice, subscriber: Models.Subscriber) {
        await this.fetch(`practices/${practice.id}/subscribers/${subscriber.id}`, {
            method: 'DELETE',
        })
    }

    async listWebCodes(practiceId: string) {
        const { data: webCodes } = (await this.fetch(`practices/${practiceId}/web-codes`)) as Api.StructuredResponse<
            Array<Api.WebCode>
        >

        return webCodes
    }

    async postCreateWebCode(practice: Models.Practice, webCode: Api.WebCodeCreate) {
        const { data: createdWebCode } = (await this.fetch(`practices/${practice.id}/web-codes`, {
            method: 'POST',
            body: JSON.stringify(webCode),
        })) as Api.StructuredResponse<Api.WebCode>

        return createdWebCode
    }

    async putUpdateWebCode(practice: Models.Practice, webCode: Models.WebCode, updates: Api.WebCodeUpdate) {
        const { data: updated } = (await this.fetch(`practices/${practice.id}/web-codes/${webCode.id}`, {
            method: 'PUT',
            body: JSON.stringify(updates),
        })) as Api.StructuredResponse<Api.WebCode>

        return updated
    }

    async listChatAlerts(practiceId: string) {
        const { data: alerts } = (await this.fetch(`practices/${practiceId}/alerts`)) as Api.StructuredResponse<
            Api.Amplify.ChatAlert[]
        >

        return alerts
    }

    async listChatAlertTasks(practiceId: string, chatId: string) {
        const { data: alertTasks } = (await this.fetch(
            `practices/${practiceId}/alerts/tasks`,
            {},
            {
                chatId,
            },
        )) as Api.StructuredResponse<Api.Amplify.ChatAlertTask[]>

        return alertTasks
    }

    async updateChatAlertTask(practiceId: string, changes: Api.Amplify.ChatAlertTaskUpdate) {
        const { data: alertTask } = (await this.fetch(`practices/${practiceId}/alerts/tasks`, {
            method: 'PUT',
            body: JSON.stringify(changes),
        })) as Api.StructuredResponse<Api.Amplify.ChatAlertTask>

        return alertTask
    }

    async postChatAlertCreate(practice: Models.Practice, createChatAlert: Api.Amplify.ChatAlertCreate) {
        const { data: chatAlert } = (await this.fetch(`practices/${practice.id}/alerts`, {
            method: 'POST',
            body: JSON.stringify(createChatAlert),
        })) as Api.StructuredResponse<Api.Amplify.ChatAlert>

        return chatAlert
    }

    async putChatAlertUpdate(
        practice: Models.Practice,
        chatAlert: Models.ChatAlert,
        changes: Api.Amplify.ChatAlertUpdate,
    ) {
        const { data: updated } = (await this.fetch(`practices/${practice.id}/alerts/${chatAlert.id}`, {
            method: 'PUT',
            body: JSON.stringify(changes),
        })) as Api.StructuredResponse<Api.Amplify.ChatAlert>

        return updated
    }

    async putChatAlertListUpdate(practice: Models.Practice, alerts: Api.Amplify.ChatAlertListUpdate[]) {
        const { data: updated } = (await this.fetch(`practices/${practice.id}/alerts`, {
            method: 'PUT',
            body: JSON.stringify({ alerts }),
        })) as Api.StructuredResponse<Api.Amplify.ChatAlert[]>

        return updated
    }

    async deleteChatAlert(practice: Models.Practice, chatAlert: Models.ChatAlert) {
        const { data: deleted } = (await this.fetch(`practices/${practice.id}/alerts/${chatAlert.id}`, {
            method: 'DELETE',
        })) as Api.StructuredResponse<Api.Amplify.ChatAlert>

        return deleted
    }

    async loadArchivedChatTranscript(practice: Models.Practice, chatId: string) {
        const { data: transcript } = (await this.fetch(
            `practices/${practice.id}/archive/${chatId}/transcript`,
        )) as Api.StructuredResponse<Api.Amplify.ChatTranscript>

        return transcript
    }

    async listArchivedChats(
        practice: Models.Practice,
        searchTerms: Api.Amplify.ChatArchiveSearchTerms = {},
    ): Promise<Api.StructuredResponse<Api.ChatMetadata[]>> {
        const {
            limit = this.PAGE_SIZE,
            page = 1,
            webCodeUrl,
            startDate,
            searchKey,
            sortByProperty = 'sortByCreated',
            sortingDirection = 'desc',
        } = searchTerms
        const structuredResponse = (await this.fetch(
            `practices/${practice.id}/archive/search`,
            {},
            {
                limit,
                page,
                webCodeUrl,
                startDate,
                searchKey,
                [sortByProperty]: sortingDirection.toUpperCase(),
            },
        )) as Api.StructuredResponse<Api.ChatMetadata[]>

        return structuredResponse
    }

    async listLatestChats() {
        try {
            const chatMetadataResponse = (await this.fetch(
                'chats',
                {},
                {
                    start_time: moment().subtract(pubnubRetentionPeriod, 'days'),
                    end_time: Date.now(),
                    limit: 100,
                },
            )) as Api.StructuredResponse<Api.ChatMetadata[]>

            return chatMetadataResponse?.data
        } catch (error) {
            return error?.data ?? []
        }
    }

    async listUnclaimedChats() {
        const unclaimedChatsData = (await this.fetch(
            'chats/unclaimed',
            {},
            { before: Date.now(), limit: 100 },
        )) as Api.StructuredResponse<Api.ChatMetadata[]>

        return unclaimedChatsData?.data || []
    }

    async listClaimedChats() {
        const { data: chatMetadata } = (await this.fetch(
            'chats/user',
            {},
            {
                start_time: moment().subtract(pubnubRetentionPeriod, 'days'),
                end_time: Date.now(),
                limit: 100,
            },
        )) as Api.StructuredResponse<Api.ChatMetadata[]>
        return chatMetadata
    }

    async getChatMetadata(chat: Models.ChatMetadata) {
        const { data: chatMetadata } = (await this.fetch(`chats/${chat.id}`)) as Api.StructuredResponse<
            Api.ChatMetadata
        >
        return chatMetadata
    }

    async putChatMetadataUpdate(chat: Models.ChatMetadata, updates: Api.ChatMetadataUpdate) {
        const { data: updated } = (await this.fetch(`chats/${chat.id}`, {
            method: 'PUT',
            body: JSON.stringify(updates),
        })) as Api.StructuredResponse<Api.ChatMetadata>
        return updated
    }

    async putCloseChatMetadata(chat: Models.ChatMetadata, close: Api.ChatMetadataClose) {
        try {
            const { data: updated } = (await this.fetch(`chats/${chat.id}/close`, {
                method: 'PUT',
                body: JSON.stringify(close),
            })) as Api.StructuredResponse<Api.ChatMetadata>

            return updated
        } catch (err) {
            throw err
        }
    }

    async fetchAuthKey() {
        const { data: auth } = (await this.fetch('auth')) as Api.StructuredResponse<Api.Amplify.AuthKey>
        return auth.authKey
    }

    async getBrowsingCount() {
        try {
            const getBrowsingCountData = (await this.fetch('chats/browsing')) as Api.StructuredResponse<{
                count: number
            }>
            return getBrowsingCountData?.data?.count
        } catch (error) {
            return error?.data ?? 0
        }
    }

    async listShortcuts(searchTerms: Api.Amplify.ShortcutSearchTerms = {}) {
        const { searchKey } = searchTerms
        const { data: shortcuts } = (await this.fetch(
            `shortcuts`,
            {},
            {
                searchKey,
            },
        )) as Api.StructuredResponse<Api.Amplify.Shortcut[]>

        return shortcuts
    }

    async postShortcut(shortcut: Models.Shortcut) {
        const { data: newShortcut } = (await this.fetch('shortcuts', {
            method: 'POST',
            body: JSON.stringify(shortcut),
        })) as Api.StructuredResponse<Api.Amplify.Shortcut>

        return newShortcut
    }

    async putShortcut(shortcut: Models.Shortcut) {
        const { data: updatedShortcut } = (await this.fetch(`shortcuts/${shortcut.id}`, {
            method: 'PUT',
            body: JSON.stringify(shortcut),
        })) as Api.StructuredResponse<Api.Amplify.Shortcut>

        return updatedShortcut
    }

    async deleteShortcut(shortcutId: string) {
        const { data: deletedShortcut } = (await this.fetch(`shortcuts/${shortcutId}`, {
            method: 'DELETE',
        })) as Api.StructuredResponse<Api.Amplify.Shortcut>

        return deletedShortcut
    }

    async getSearchLocationsByName(
        searchLocationsByName: Api.Amplify.WebCodeLocationSearch,
    ): Promise<Api.StructuredResponse<Models.AmplifyWebCodeLocation[]>> {
        const { practiceId, webCodeId, searchTerms } = searchLocationsByName
        const { limit = this.PAGE_SIZE, page, searchKey = '', onlyEnabled = false } = searchTerms
        const { data: locations, paginationInfo } = (await this.fetch(
            `practices/${practiceId}/web-codes/${webCodeId}/location/search`,
            {},
            {
                limit,
                page,
                searchKey,
                onlyEnabled,
            },
        )) as Api.StructuredResponse<Api.Amplify.WebCodeLocation[]>

        return {
            paginationInfo,
            data: mapWebCodeLocationsToModel(locations),
        }
    }

    async putUpdateLocation(updateLocation: Api.Amplify.WebCodeLocationUpdate): Promise<Models.AmplifyWebCodeLocation> {
        const { practiceId, webCodeId, locationId } = updateLocation
        const { data: location } = await this.fetch(
            `practices/${practiceId}/web-codes/${webCodeId}/locations/${locationId}`,
            {
                method: 'PUT',
                body: JSON.stringify(updateLocation),
            },
        )
        return mapWebCodeLocationToModel(location)
    }

    async postToggleEnableAllLocations(
        locationToggleEnableAll: Api.Amplify.WebCodeLocationToggleEnableAll,
    ): Promise<void> {
        const { practiceId, enabled } = locationToggleEnableAll
        await this.fetch(
            `practices/${practiceId}/web-codes/${locationToggleEnableAll.webCodeId}/location/toggle-enable-all`,
            {
                method: 'POST',
                body: JSON.stringify({
                    practiceId,
                    enabled,
                }),
            },
        )
    }
}
