import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid, Paper } from '@mui/material'
import _, { forEach } from 'lodash'

import Api from '../../../Api'
import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { PartnerAdmin } from '../../../models/enums'
import { AccessReferralActivityLog } from '../../../models/enums'
import * as V2PracticeActions from '../../../modules/practices/v2actions'
import * as Factory from '../../../modules/referrals/referral-form/factory'
import {
    resetReferralForm,
    saveNewManualReferral,
    saveUpdateReferral,
} from '../../../modules/referrals/referral-form/v2actions'
import {
    fetchReferralV2,
    receiveFormOtherPatientData,
    receiveFormPrimaryPatientData,
    receiveFormReferralData,
    updateReferral,
    updateReferralDashboardCard,
} from '../../../modules/referrals/v2actions'
import ErrorMessage from '../../../modules/shared/error-message/error-message'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { useAsync } from '../../../util/useAsync'
import ChatCenterAlertsList from '../../chat-center/chat-center-alert/ChatCenterAlertsList'
import SurveyComponentV2 from '../../chat-center/survey/SurveyComponentV2'
import { isDobDate } from '../../shared/form-validator/isDateValid'

import ReferralActivityLog from './ReferralActivityLog'
import ReferralAdditionalPatients from './ReferralAdditionalPatients'
import ReferralAttachment from './ReferralAttachment'
import ReferralHeader from './ReferralHeader'
import ReferralNotes from './ReferralNotes'
import ReferralPatientInfo from './ReferralPatientInfo'
import ReferralPracticeInfo from './ReferralPracticeInfo'
import ReferralProductionValues from './ReferralProductionValues'
import ReferralReviewFlag from './ReferralReviewFlag'
import ReferralTranscript from './ReferralTranscript'

import './ReferralForm.sass'

type Props = {
    referralId?: string
    pid?: string
    actions?: React.ReactNode
    testReferral?: boolean
    manualReferral?: boolean
    testReferralType?: Models.PracticeSpecialtyValue
    account?: AdminAccount
    onCreate?: () => void
    onUpdate?: (
        currentStatus: ModelsV2.Referrals.StatusValue,
        nextStatus: ModelsV2.Referrals.StatusValue | null,
        referralForm: ModelsV2.Referrals.ReferralForm,
    ) => void
    setLoading: (loading: boolean) => void
}

export const dateFormat = 'MM/DD/YYYY'
export const timeFormat = 'h:mm A z'

const ReferralForm = (props: Props) => {
    const { actions, testReferral, manualReferral, testReferralType, pid, onUpdate, setLoading, onCreate } = props
    const dispatch = useAppDispatch()

    const isButtonClicked = useRef(false)

    const referralId = props.referralId
    const isTestReferral = Boolean(testReferral)
    const isNewReferral = Boolean(manualReferral)
    const isUpdatingReferral = !isTestReferral && !isNewReferral && Boolean(referralId)

    const [practiceId, setPracticeId] = useState<string | undefined>(pid ? pid : undefined)
    const [dependencyLoaded, setDependencyLoaded] = useState(false)
    const [failedSave, setFailedSave] = useState(false)
    const [selectedLocation, setSelectedLocation] = useState<string>('')
    const [practiceLocationsError, setPracticeLocationsError] = useState('')
    const [inboundHandlerAccounts, setInboundHandlerAccounts] = useState<Api.Account[]>([])

    // FORM DATA
    const referralForm = useSelector((state: RootState) => state.v2.referrals.referralForm)
    const complete = useSelector((state: RootState) => state.v2.referrals.referralsDashboard.complete.referrals)

    const referral = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.referral.value,
    ) as ModelsV2.Referrals.ReferralV2

    const primaryPatient = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.primaryPatient.value,
    ) as ModelsV2.Referrals.ReferralPatient

    const otherPatients = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.otherPatients.value,
    ) as ModelsV2.Referrals.ReferralPatient[]

    const practice = useSelector(
        (state: RootState) => practiceId && state.v2.practices.practicesV2[practiceId],
    ) as ModelsV2.Practice.PracticeV2

    const practiceLocations = useSelector(
        (state: RootState) => practiceId && state.v2.practices.practiceLocations[practiceId],
    ) as ModelsV2.Practice.PracticeLocationsV2[]

    const locationId = referral.location_id
    const { run: fetchReferral, status: statusFetchReferral } = useAsync()
    const { run: fetchPractice } = useAsync()

    // LOADING TEST DATA TO FORM
    const loadChatters = useCallback(() => {
        Api.Admin.list('amplify-chatters', { limit: 0 }).then(({ data: handlers }) => {
            const inboundHandlerAccounts = handlers.filter(h => {
                return h.type.id === PartnerAdmin.id
            })
            setInboundHandlerAccounts(inboundHandlerAccounts)
        })
    }, [])

    const loadTestFactoryData = useCallback(() => {
        if (isTestReferral) {
            if (testReferralType === 'dental') {
                dispatch(receiveFormReferralData(Factory.DentalTestFactory))
                dispatch(receiveFormPrimaryPatientData(Factory.DentalTestFactory.amplify_referral_patient[0]))
            } else if (testReferralType === 'optometry') {
                dispatch(receiveFormReferralData(Factory.OptometryTestFactory))
                dispatch(receiveFormPrimaryPatientData(Factory.OptometryTestFactory.amplify_referral_patient[0]))
            }
        }
    }, [dispatch, isTestReferral, testReferralType])

    const loadFactoryData = useCallback(() => {
        dispatch(receiveFormReferralData(Factory.ReferralFactory))
        dispatch(receiveFormPrimaryPatientData(Factory.ReferralFactory.amplify_referral_patient[0]))
    }, [dispatch])

    const getTimezone = () => {
        if (referral.location_id && practiceLocations) {
            return practiceLocations.find(location => location.id === referral.location_id)?.timezone
        }
        return ''
    }
    // LOADING LOGIC
    useEffect(() => {
        loadChatters()
        if (isUpdatingReferral && referralId) {
            setLoading(true)
            fetchReferral(dispatch(fetchReferralV2(referralId)))
        } else if (isTestReferral) {
            loadTestFactoryData()
        } else if (isNewReferral) {
            loadFactoryData()
        }
    }, [
        referralId,
        dispatch,
        isTestReferral,
        fetchReferral,
        isNewReferral,
        loadTestFactoryData,
        loadFactoryData,
        setLoading,
        isUpdatingReferral,
        loadChatters,
    ])

    const fetchAllPracticeLocations = useCallback(
        async (practiceId, page) => {
            try {
                const response = await dispatch(V2PracticeActions.fetchPracticeLocationV2s(practiceId, page))
                if (response?.pagination_info && response.pagination_info.allPages > page) {
                    fetchAllPracticeLocations(practiceId, page + 1)
                }
            } catch (error) {
                setPracticeLocationsError('Problem with loading practice locations')
            }
        },
        [dispatch],
    )

    useEffect(() => {
        return () => {
            practiceId && dispatch(V2PracticeActions.setResetLocationsState(practiceId))
        }
    }, [dispatch, practiceId])

    useEffect(() => {
        if (practiceId) {
            fetchPractice(dispatch(V2PracticeActions.fetchPractice(practiceId)))
            //recursively fetching locations
            fetchAllPracticeLocations(practiceId, 1)
        }
    }, [dispatch, fetchAllPracticeLocations, fetchPractice, practiceId])

    // LOADING STATE DATA TO FORM DATA
    useEffect(() => {
        if (statusFetchReferral === 'resolved' && dependencyLoaded === false) {
            if (referral) {
                dispatch(receiveFormReferralData(referral))
            }
            setPracticeId(referral?.practice_id)
            if (referral?.amplify_referral_patient?.length) {
                const primaryPatientData = referral?.amplify_referral_patient?.find(
                    patient => patient.is_primary === true,
                )
                const otherPatients = referral?.amplify_referral_patient?.filter(
                    patient => patient.is_primary === false,
                )
                if (primaryPatientData) {
                    dispatch(receiveFormPrimaryPatientData(primaryPatientData))
                }
                if (otherPatients) {
                    dispatch(receiveFormOtherPatientData(otherPatients))
                }
            }
            setLoading(false)
            setDependencyLoaded(true)
        }
    }, [dependencyLoaded, dispatch, referral, setLoading, statusFetchReferral])

    useEffect(() => {
        return () => {
            dispatch(resetReferralForm())
        }
    }, [dispatch])

    useEffect(() => {
        if (locationId) {
            setSelectedLocation(locationId)
        }
    }, [locationId])
    const webCode = referral?.amplify_web_code
    const allowReferralActivityLog = props.account && referralId && props.account.hasAccess(AccessReferralActivityLog)

    const referralStatus = referral?.amplify_status
    const isReferralInProgress = referralStatus?.value === 'in_progress'
    const isReferralInReview = referralStatus?.value === 'review'
    const isReferralPending = isReferralInProgress || isReferralInReview
    const timezone = getTimezone()

    const errMsg = (status: string, msg: string): React.ReactNode => {
        if (isUpdatingReferral && status === 'rejected') {
            return <ErrorMessage>{msg}</ErrorMessage>
        }
        return <></>
    }
    const updateCompletedReferralDashboardCard = (formState: ModelsV2.Referrals.ReferralForm) => {
        const activeCard = complete.find(referral => referral.id === referralId)

        if (activeCard) {
            const referralWritter = inboundHandlerAccounts.find(
                inbound => inbound.id === formState.referral.value.inbound_handler_id,
            )
            const updatedState: ModelsV2.Referrals.ReferralsDashboardCard = {
                ...activeCard,
                amplify_status: {
                    ...activeCard.amplify_status,
                    // added this in order the proper column could be foud in the reducer
                    // because our "complete" column contains referral with status "new", "follow-up" etc
                    value: 'complete',
                },
                updated: `${new Date()}`,
                patient_first_name: formState.primaryPatient.value.first_name as string,
                patient_last_name: formState.primaryPatient.value.last_name as string,
                ...(referralWritter
                    ? {
                          referral_writer_first_name: referralWritter.first_name,
                          referral_writer_last_name: referralWritter.last_name,
                      }
                    : {}),
            }
            dispatch(updateReferralDashboardCard(updatedState))
        }
    }
    const onAfterReferralSuccessfullUpdate = async (formState: ModelsV2.Referrals.ReferralForm) => {
        const currentStatus = referral.amplify_status.value === 'new' ? 'complete' : referral.amplify_status.value
        let nextStatus: ModelsV2.Referrals.StatusValue | null = null

        /* 
            - Special exception for chaning referral status
            -- when user removes the FLAG from the referral status: 
               "review" - referral becomes status: "complete"
            or 
            -- when the referral is in status: "in_progress" 
               and any change occurs (except the flag) - referral becomes status: "complete"
        */

        if (['review', 'in_progress'].includes(currentStatus) && Boolean(referral.flag) === false) {
            nextStatus = 'complete'
            await dispatch(
                updateReferral(referral.id, {
                    amplify_status: {
                        value: nextStatus,
                    },
                }),
            ).then(response => {
                setLoading(false)
                if (response !== null) {
                    onUpdate && onUpdate(currentStatus, nextStatus, formState)
                }
            })
            return
        }

        // BE changes referral status to "review" automatically if user adds the flag property
        if (currentStatus === 'in_progress' && Boolean(referral.flag) === true) {
            nextStatus = 'review'
        }

        if (currentStatus === 'complete') {
            updateCompletedReferralDashboardCard(formState)
        }

        setLoading(false)
        onUpdate && onUpdate(currentStatus, nextStatus, formState)
    }

    const onAfterReferralSuccessfullManualCreate = async () => {
        const referralId = referralForm.referral.value.id
        if (referralId) {
            await dispatch(
                updateReferral(referralId, {
                    amplify_status: {
                        value: 'complete',
                    },
                }),
            ).then(response => {
                if (response !== null) {
                    onCreate && onCreate()
                }
            })
        }
        setLoading(false)
    }

    const trimField = (fieldValue: string | number | boolean | null | undefined | any) => {
        if (fieldValue && typeof fieldValue === 'string') {
            return fieldValue.trim()
        }
        return fieldValue
    }

    const trimFieldValues = (referralForm: ModelsV2.Referrals.ReferralForm): ModelsV2.Referrals.ReferralForm => {
        const formState = _.cloneDeep(referralForm)

        const referralParts = ['primaryPatient', 'referral', 'otherPatients']

        referralParts.forEach((referralPart: string) => {
            forEach(formState[referralPart].value, (fieldValue, index) => {
                if (Array.isArray(formState[referralPart].value)) {
                    formState[referralPart].value.forEach((_: any, patientIndex: string) => {
                        forEach(formState[referralPart].value[patientIndex], (fieldValue, index) => {
                            formState[referralPart].value[patientIndex][index] = trimField(fieldValue)
                        })
                    })
                } else {
                    formState[referralPart].value[index] = trimField(fieldValue)
                }
            })
        })

        return formState
    }

    const onSave = () => {
        if (!isButtonClicked.current) {
            isButtonClicked.current = true
            setTimeout(() => {
                isButtonClicked.current = false
            }, 1000)
            setLoading(true)
            const formState = trimFieldValues(referralForm)
            if (!referralForm.referral.value.id) {
                dispatch(saveNewManualReferral(isTestReferral, formState)).then(success => {
                    if (success) {
                        onAfterReferralSuccessfullManualCreate()
                    } else {
                        setFailedSave(true)
                        setLoading(false)
                    }
                })
            } else {
                dispatch(saveUpdateReferral(formState)).then(success => {
                    if (success) {
                        onAfterReferralSuccessfullUpdate(formState)
                    } else {
                        setFailedSave(true)
                        setLoading(false)
                    }
                })
            }
        }
    }
    const isInboundHandlerSelected = () => {
        const inbound_handler_id = referral?.inbound_handler_id
        const isInboundInList = Boolean(inboundHandlerAccounts.find(inbound => inbound.id === inbound_handler_id))
        // sometimes inbound_handler_id is present but not contained in inboundHandlerAccounts
        if (!isInboundInList && !referralForm.referral.dirtyFields.includes('inbound_handler_id')) {
            return false
        }
        return true
    }
    const isFormValid = () => {
        if (
            !isInboundHandlerSelected() ||
            _.isEmpty(referral?.location_id) ||
            _.isEmpty(primaryPatient?.first_name) ||
            _.isEmpty(primaryPatient?.last_name) ||
            _.isEmpty(primaryPatient?.reason)
        ) {
            return false
        }

        if (Boolean(primaryPatient.date_of_birth) && !isDobDate(primaryPatient.date_of_birth)) {
            return false
        }

        if (
            primaryPatient.patient_is_policy_holder === false &&
            Boolean(primaryPatient.policy_holder_date_of_birth) &&
            !isDobDate(primaryPatient.policy_holder_date_of_birth)
        ) {
            return false
        }

        if (otherPatients.length > 0) {
            const otherPatientsWithBadDate = otherPatients.filter(patient => {
                return Boolean(patient.date_of_birth) && !isDobDate(patient.date_of_birth)
            })
            const otherPatientsWithBadName = otherPatients.filter(patient => {
                return patient.first_name.trim() === '' || patient.last_name.trim() === ''
            })
            if (otherPatientsWithBadDate.length > 0 || otherPatientsWithBadName.length > 0) {
                return false
            }
        }

        if (isNewReferral && _.isEmpty(referral?.transcript)) {
            return false
        }

        return true
    }

    const saveEnabled = isFormValid()

    return (
        <div className="referral-form">
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <ReferralHeader
                        actions={actions}
                        practiceName={practice?.name}
                        referral={referral}
                        referralStatus={referralStatus}
                    />
                </Grid>
                <Grid item={true} xs={4} className="patient-info-container">
                    {errMsg(statusFetchReferral, 'Problem with loading referral')}
                    {practiceLocationsError && <ErrorMessage>{practiceLocationsError}</ErrorMessage>}
                    <ReferralPracticeInfo
                        practiceId={practiceId}
                        webCode={webCode}
                        isReferralPending={isReferralPending}
                        isTestReferral={isTestReferral}
                        inboundHandlerAccounts={inboundHandlerAccounts}
                        isUpdatingReferral={Boolean(referralId && isUpdatingReferral)}
                    />
                    <ReferralPatientInfo
                        referral={referral}
                        patient_data={primaryPatient}
                        testReferral={testReferral}
                        failedSave={failedSave}
                        dateFormat={dateFormat}
                        practiceLocations={practiceLocations}
                    />
                    <ReferralNotes patient_data={primaryPatient} />
                </Grid>
                <Grid item={true} xs={4} className="transcript-container">
                    <ReferralTranscript failedSave={failedSave} account={props.account} patient_data={primaryPatient} />
                </Grid>
                <Grid item={true} xs={4}>
                    <div className="referral-form-alerts__container">
                        <Paper elevation={4} square={true} className="referral-form-alerts">
                            <div className="referral-form-alerts__header-container">
                                <div className="referral-form-alerts__header">
                                    <div className="title">Alerts</div>
                                </div>
                            </div>
                            <div className="referral-form-alerts__body-container">
                                {practiceId && <ChatCenterAlertsList practiceId={practiceId} webCode={webCode} />}
                            </div>
                        </Paper>
                    </div>

                    <div className="referral-form-survey__container">
                        {practice?.id && (
                            <Paper elevation={4} square={true} className="referral-form-alerts">
                                <div className="referral-form-alerts__header-container">
                                    <div className="referral-form-alerts__header">
                                        <div className="title">Survey Results</div>
                                    </div>
                                </div>
                                <SurveyComponentV2
                                    isReferralModal={true}
                                    practiceId={practice.id}
                                    webCodeId={webCode?.id}
                                    locationId={selectedLocation}
                                    referralStatus={referralStatus?.value}
                                />
                            </Paper>
                        )}
                    </div>
                </Grid>
                {primaryPatient && (
                    <Grid item={true} xs={12} className="referral-production-values-container">
                        <ReferralProductionValues
                            patientName={`${primaryPatient.first_name} ${primaryPatient.last_name}`}
                            patientId={primaryPatient.id}
                        />
                    </Grid>
                )}
                {isReferralPending && (
                    <Grid item={true} xs={12} className="referral-review-flag-container">
                        <ReferralReviewFlag referral={referral} failedSave={failedSave} />
                    </Grid>
                )}
                <Grid item={true} xs={12} className="referral-attachment-container">
                    <ReferralAttachment referralId={referralId} />
                </Grid>
                <Grid item={true} xs={12}>
                    <ReferralAdditionalPatients failedSave={failedSave} timezone={timezone} />
                </Grid>

                {allowReferralActivityLog && referral && (
                    <Grid item={true} xs={12}>
                        <div className="activity-log">
                            <ReferralActivityLog referral={referral} timezone={timezone} />
                        </div>
                    </Grid>
                )}
                <Grid item={true} xs={12}>
                    <div className="button-container">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onSave}
                            disabled={!saveEnabled || isButtonClicked.current}
                        >
                            {isTestReferral || isNewReferral ? 'Send Request' : 'Save'}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default ReferralForm
