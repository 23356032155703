import React from 'react'
import { Icon } from '@mui/material'

import './info-message.sass'

type Props = {
    isShown: boolean
    children: React.ReactNode
}

const InfoMessage = ({ children, isShown }: Props) => {
    return (
        <div className={`info-message info-message--${isShown ? 'shown' : 'hidden'}`}>
            <Icon>info</Icon>
            <span>{children}</span>
        </div>
    )
}

export default InfoMessage
