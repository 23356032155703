import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton } from '@material-ui/core'
import moment from 'moment'

import { RootState } from '../../../../appReducer'
import { convertNumCentsToDollars } from '../../../../util/formatCurrency'
import { getPatientFinancialData, patientFinancialDataSelectPatient } from '../v2actions'

import './PatientDetails.sass'

export type Props = {
    practiceId: string
    practiceLocationNames: { [key: string]: string }
    numOfPracticeLocations: number
}

const PatientDetails = ({ practiceId, practiceLocationNames, numOfPracticeLocations }: Props) => {
    const data = useSelector((state: RootState) => state.v2.practicePatients.patientFinancialData[practiceId]?.data)

    const selectedPatient = useSelector(
        (state: RootState) => state.v2.practicePatients.patientFinancialData[practiceId]?.selectedPatient,
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedPatient.id) {
            dispatch(getPatientFinancialData(practiceId, selectedPatient.id))
        }
    }, [dispatch, selectedPatient, practiceId])

    const handleBackToList = () => {
        dispatch(patientFinancialDataSelectPatient(practiceId, null))
    }

    if (!selectedPatient) {
        return null
    }

    return (
        <div className="patient-details">
            <div className="patient-details__header">
                <IconButton className="btn-back" onClick={handleBackToList}>
                    <Icon>arrow_back</Icon>
                </IconButton>
                <div className="patient-details__header-text">
                    <div>
                        <h2 className="patient-name">{`${selectedPatient.first_name} ${selectedPatient.last_name}`}</h2>
                    </div>
                    {numOfPracticeLocations > 1 && (
                        <div className="patient-practice-location-name">
                            {practiceLocationNames[selectedPatient.practice_location_id] || ''}
                        </div>
                    )}
                    <div className="patient-details__dob">
                        DOB:&nbsp;
                        {selectedPatient?.date_of_birth
                            ? moment(selectedPatient?.date_of_birth).format('MM/DD/YYYY')
                            : '...'}
                    </div>
                </div>
            </div>
            <div className="patient-details__table">
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">First Visit</div>
                    <div className="patient-details__cell-value">
                        {data?.first_visit_date ? `${moment(data?.first_visit_date).format('MM/DD/YYYY')}` : '...'}
                    </div>
                    <div className="patient-details__cell-title">Last Visit</div>
                    <div className="patient-details__cell-value-bottom">
                        {data?.last_visit_date ? `${moment(data?.last_visit_date).format('MM/DD/YYYY')}` : '...'}
                    </div>
                </div>
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">First Visit Value</div>
                    <div className="patient-details__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_visit_value) * 100, true, '$')}
                    </div>
                    <div className="patient-details__cell-title">Last Visit Value</div>
                    <div className="patient-details__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_visit_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">First 30 Days</div>
                    <div className="patient-details__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_30_days_value) * 100, true, '$')}
                    </div>
                    <div className="patient-details__cell-title">Last 30 Days</div>
                    <div className="patient-details__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_30_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">First 60 Days</div>
                    <div className="patient-details__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_60_days_value) * 100, true, '$')}
                    </div>
                    <div className="patient-details__cell-title">Last 60 Days</div>
                    <div className="patient-details__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_60_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">First 90 Days</div>
                    <div className="patient-details__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_90_days_value) * 100, true, '$')}
                    </div>
                    <div className="patient-details__cell-title">Last 90 Days</div>
                    <div className="patient-details__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_90_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">First 360 Days</div>
                    <div className="patient-details__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_360_days_value) * 100, true, '$')}
                    </div>
                    <div className="patient-details__cell-title">Last 360 Days</div>
                    <div className="patient-details__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_360_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="patient-details__cell">
                    <div className="patient-details__cell-title">Lifetime Value</div>
                    <div className="patient-details__cell-value patient-details--bold">
                        {convertNumCentsToDollars(Number(data?.lifetime_value) * 100, true, '$')}
                    </div>
                    <div className="patient-details__cell-title"></div>
                    <div className="patient-details__cell-value-bottom"></div>
                </div>
            </div>
        </div>
    )
}

export default PatientDetails
