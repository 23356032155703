import React, { useState } from 'react'

import { Dashboards } from '../../ApiV2/services/DataService'
import AdminAccount from '../../models/AdminAccount'
import MetabaseData from '../amplify/data/MetabaseData'
import InfoMessage from '../shared/info-message/info-message'

import SelfSchedulingWebCodeManager from './self-scheduling/SelfSchedulingWebCodeManager'
import DirectSchedulingViewsList from './DirectSchedulingViewsList'

type DirectSchedulingTabProps = {
    practice: Models.Practice
    account: AdminAccount
}

export type DirectSchedulingViews = 'main' | 'web-codes'

function DirectSchedulingTab({ account, practice }: DirectSchedulingTabProps) {
    const [view, setView] = useState<DirectSchedulingViews>('main')

    function onSelectView(selectedView: DirectSchedulingViews) {
        setView(selectedView)
    }

    return (
        <>
            {view === 'main' && (
                <>
                    <DirectSchedulingViewsList practice={practice} account={account} selectView={onSelectView} />

                    {process.env.REACT_APP_ENV === 'production' ? (
                        <MetabaseData practice={practice} source={Dashboards.DIRECT_SCHEDULING} />
                    ) : (
                        <InfoMessage isShown={true}>Metabase is only available in production.</InfoMessage>
                    )}
                </>
            )}
            {view === 'web-codes' && (
                <SelfSchedulingWebCodeManager
                    account={account}
                    practice={practice}
                    onBack={() => onSelectView('main')}
                />
            )}
        </>
    )
}

export default DirectSchedulingTab
