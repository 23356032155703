import { connect } from 'react-redux'

import { RootState } from '../../../../appReducer'
import { searchAmplifyWebCodesLocations } from '../../../amplify/actions'

import WebcodeLocationMultiselect, { Props } from './WebcodeLocationMultiselect'

const mapStateToProps = (
    state: RootState,
    props: Omit<Props, 'webCodeLocations' | 'searchAmplifyWebCodesLocations'>,
) => ({
    webCodeLocations: state.amplify.webCodesLocations?.[props.webCode?.id] ?? {
        locations: [],
        isFetching: false,
        currentPage: 0,
    },
    ...props,
})

const WebcodeLocationMultiselectContainer = connect(mapStateToProps, {
    searchAmplifyWebCodesLocations,
})(WebcodeLocationMultiselect)

export default WebcodeLocationMultiselectContainer
