import { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'

import { useAppDispatch } from '../../../util/useAppDispatch'
import { createAccount } from '../actions'

import StaffForm from './StaffForm'

export type Props = {
    practice: Models.Practice
    practiceUserRoles: Models.PracticeUserRole[]
    locationsLoaded: boolean
    refreshStaffList: () => void
}

const StaffAddMember = ({ practiceUserRoles, practice, locationsLoaded, refreshStaffList }: Props) => {
    const [showModal, setShowModal] = useState(false)

    const dispatch = useAppDispatch()

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onShowModal = () => {
        if (!locationsLoaded) {
            return
        }
        setShowModal(true)
    }

    const onCreateNewAccount = async (newAccount: ApiV2.Practice.AccountCreate) => {
        await dispatch(createAccount(practice, newAccount))
        refreshStaffList()
        onCloseModal()
    }

    return (
        <div>
            <div
                className={classNames(`staff-footer`, { ['footer-disabled']: !locationsLoaded })}
                onClick={onShowModal}
            >
                {!locationsLoaded ? (
                    <div className="loader">
                        <CircularProgress size={25} thickness={5} />
                    </div>
                ) : (
                    <div className="button">
                        <i className="material-icons add-icon">add</i>
                        Add User
                    </div>
                )}
            </div>
            {showModal && (
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <StaffForm
                        onCreateNewAccount={onCreateNewAccount}
                        onClose={onCloseModal}
                        practiceUserRoles={practiceUserRoles}
                        practice={practice}
                    />
                </Modal>
            )}
        </div>
    )
}

export default StaffAddMember
