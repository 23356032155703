import React from 'react'
import Grid from '@mui/material/Grid'

import CustomCheckbox from './CustomCheckbox'

const moduleName = 'global-settings-modal'

type AllowSidebookngCheckboxProps = {
    concurrencyNumValue: number
    setIsTouched: (value: boolean) => void
    setConcurrencyNumValue: (value: number) => void
}

const AllowSidebookingCheckboxGlobal = ({
    concurrencyNumValue,
    setIsTouched,
    setConcurrencyNumValue,
}: AllowSidebookngCheckboxProps) => {
    return (
        <Grid
            xs={12}
            sm={6}
            md={2}
            className={`${moduleName}__table-item details  ${moduleName}__checkbox  ${moduleName}__size15`}
        >
            <CustomCheckbox
                className={`${moduleName}__checkbox-input`}
                checked={concurrencyNumValue === 2}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                        setConcurrencyNumValue(2)
                    } else {
                        setConcurrencyNumValue(1)
                    }
                    setIsTouched(true)
                }}
                label={`Allow Sidebooking`}
            />
        </Grid>
    )
}

export default AllowSidebookingCheckboxGlobal
