import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from 'classnames'
import moment from 'moment'

import CustomTimeContainer, { Period } from './CustomTimeContainer'

export enum TimeType {
    AM = 'AM',
    PM = 'PM',
}

export interface Props {
    className?: string
    time?: moment.Moment
    label?: string
    error?: boolean
    renderTrigger: () => JSX.Element
    handleTimeChange: (time: moment.Moment) => void
    handleCancel: () => void
    setOpen: (open: boolean) => void
}

const moduleName = 'picker'

const TimePicker = (props: Props) => {
    const { className, label, error, renderTrigger, setOpen } = props
    const [show, setShow] = useState<boolean>(false)

    const triggerRef = useRef<HTMLDivElement>()
    const contentsRef = useRef<HTMLDivElement>()

    const hideContents = useCallback(() => {
        setOpen(false)
        setShow(false)
    }, [setOpen, setShow])

    useEffect(() => {
        const handleClickEvent = (event: any) => {
            if (!triggerRef?.current?.contains(event.target)) {
                hideContents()
            }
        }

        window.addEventListener('click', handleClickEvent)

        return () => {
            window.removeEventListener('click', handleClickEvent)
        }
    }, [triggerRef, hideContents])

    const preventPropagation = (event: any) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
    }

    const toggleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(!show)
        setShow(!show)
    }

    const renderTime = (): JSX.Element | null => {
        const time = props.time || moment()
        const timeString = time.format('h mm A')
        const timeParts = timeString.split(' ')
        return (
            <CustomTimeContainer
                hour={Number(timeParts[0])}
                minute={Number(timeParts[1])}
                period={timeParts[2] as Period}
                onChange={props.handleTimeChange}
                onCancel={props.handleCancel}
                hideContents={hideContents}
                preventProp={preventPropagation}
            />
        )
    }

    return (
        <div className={moduleName}>
            {label && (
                <label
                    className={classes(`${moduleName}__label`, {
                        [`${moduleName}__label--error`]: error,
                        [`${moduleName}__label--selected`]: show,
                    })}
                >
                    {label}
                </label>
            )}
            <div className={`${moduleName}__trigger`} onClick={toggleDropdown} ref={triggerRef as React.RefObject<any>}>
                {renderTrigger()}
            </div>
            {show && (
                <div
                    className={classes([`${moduleName}__container`], className)}
                    role="dialog"
                    aria-modal="true"
                    ref={contentsRef as React.RefObject<any>}
                >
                    <div className={`${moduleName}__container__timeonly`}>{renderTime()}</div>
                </div>
            )}
        </div>
    )
}

export default TimePicker
