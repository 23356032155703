import React, { Fragment, useEffect, useState } from 'react'
import Button, { ButtonClassKey } from '@mui/material/Button'

import { CustomFieldType } from '../../models/enums'
import CustomField from '../shared/custom-fields/CustomField'

import { ShortcutModalType } from './AdminShortcutList'

import './ShortcutModalBody.sass'

type Props = {
    type: ShortcutModalType
    id?: string
    name?: string
    message?: string
    onCloseShortcutModal: () => void
    onAddShortcut: (name: string, message: string) => void
    onEditShortcut: (id: string, name: string, message: string) => void
    onDeleteShortcut: (id: string, name: string) => void
}

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const moduleName = 'shortcut-modal-body'

const buttonClassOverrides: ButtonClassOverrides = {
    outlined: `${moduleName}__cancel_btn`,
    contained: `${moduleName}__yes_btn`,
}

const ShortcutModalBody = (props: Props) => {
    const { type, id, onCloseShortcutModal, onAddShortcut, onEditShortcut, onDeleteShortcut } = props

    const [title, setTitle] = useState<string>('')
    const [name, setName] = useState<string>(props.name || '')
    const [message, setMessage] = useState<string>(props.message || '')
    const [isDirty, setIsDirty] = useState<boolean>(false)

    useEffect(() => {
        if (type === ShortcutModalType.AddShortcut) {
            setTitle('Add Shortcut')
        }

        if (type === ShortcutModalType.EditShortcut) {
            if (!id) {
                window.alert(`There has been a problem loading the shortcut. Please refresh the page and try again.`)
                return
            }
            setTitle('Edit Shortcut')
        }
    }, [id, type])

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const shortcutName = event.target.value
        setName(shortcutName.replace('/', ''))
        setIsDirty(true)
    }

    const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(event.target.value)
        setIsDirty(true)
    }

    const onCancel = () => {
        onCloseShortcutModal()
        setIsDirty(false)
    }

    const onSaveShortcut = () => {
        if (type === ShortcutModalType.AddShortcut) {
            onAddShortcut(name.trim().toLowerCase(), message.trim())
        }

        if (type === ShortcutModalType.EditShortcut && props.id) {
            onEditShortcut(props.id, name.trim().toLowerCase(), message.trim())
        }
        setIsDirty(false)
        onCloseShortcutModal()
    }

    const handleDeleteShortcut = () => {
        if (props.id && props.name) {
            onDeleteShortcut(props.id, props.name.trim())
        }

        onCloseShortcutModal()
    }

    const saveEditModal = () => (
        <Fragment>
            <div className={`${moduleName}__content-edit`}>
                <div className={`${moduleName}__main-title`}>{title}</div>
                <CustomField
                    customFieldType={CustomFieldType.INPUT}
                    inputType="text"
                    value={name || ''}
                    placeholder="Holidays"
                    label="Shortcut Name"
                    helperText="Do NOT add '/' to shortcut name"
                    onChange={onNameChange}
                />
                <CustomField
                    customFieldType={CustomFieldType.TEXTAREA}
                    value={message || ''}
                    rows={4}
                    label="Message"
                    placeholder="We observe all U.S. Government holidays."
                    onChange={onMessageChange}
                />
            </div>
            <div className={`${moduleName}__footer-edit`}>
                <Button color="primary" variant="outlined" classes={buttonClassOverrides} onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    classes={buttonClassOverrides}
                    onClick={onSaveShortcut}
                    disabled={!name.trim() || !message.trim() || !isDirty}
                >
                    Save Shortcut
                </Button>
            </div>
        </Fragment>
    )

    const deleteModal = () => (
        <Fragment>
            <div className={`${moduleName}__content`}>
                <div className={`${moduleName}__icon`}>
                    <i className="material-icons">info</i>
                </div>
                <div className={`${moduleName}__inner-content`}>
                    <div className={`${moduleName}__title`}>
                        Are you sure you want to delete shortcut "{props.name}"?
                    </div>
                </div>
            </div>
            <div className={`${moduleName}__footer`}>
                <Button color="primary" variant="outlined" classes={buttonClassOverrides} onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" type="reset" classes={buttonClassOverrides} onClick={handleDeleteShortcut}>
                    Delete Shortcut
                </Button>
            </div>
        </Fragment>
    )

    return (
        <div className="shortcut-modal-body">
            <div className={`${moduleName}__container`}>
                <div className={`${moduleName}__header`}>
                    <span className={`${moduleName}__close-button`} onClick={onCloseShortcutModal}>
                        <i className="material-icons">close</i>
                    </span>
                </div>
                {type === ShortcutModalType.DeleteShortcut ? deleteModal() : saveEditModal()}
            </div>
        </div>
    )
}

export default ShortcutModalBody
