import React from 'react'
import throttle from 'lodash/throttle'

import ChatCard from './ChatCard'

import './ClaimedChats.sass'

const INCOMING_MESSAGE_SOUND_FREQUENCY = 1000

export type ClaimedChatsProps = {
    selectedChatsIds: string[]
    claimedChats: Models.ChatMetadata[]
}

export type ClaimedChatsDispatch = {
    onSelectChat: (selectedChatId: string) => void
}

type Props = ClaimedChatsProps & ClaimedChatsDispatch

const ClaimedChats = ({ selectedChatsIds, claimedChats, onSelectChat }: Props) => {
    const playIncomingMessageSound = () => {
        const incomingMessageSound = new Audio(`${process.env.PUBLIC_URL}/sounds/agent-alert-dig.mp3`)
        incomingMessageSound.play()
    }

    const throttledPlayIncomingMessageSound = throttle(playIncomingMessageSound, INCOMING_MESSAGE_SOUND_FREQUENCY, {
        trailing: false,
    })

    const renderChat = (chat: Models.ChatMetadata) => {
        const selected = selectedChatsIds.includes(chat.id)

        return (
            <ChatCard
                key={chat.id}
                chat={chat}
                selected={selected}
                onSelect={onSelectChat}
                throttledPlayIncomingMessageSound={throttledPlayIncomingMessageSound}
            />
        )
    }

    return <div className="claimed-chats">{claimedChats.map(renderChat)}</div>
}

export default ClaimedChats
