import { useCallback, useEffect, useState } from 'react'
import Slider from 'react-slick'

import { turnChatsPage } from '../actions'

import useChatSliderNavigation from './useChatSliderNavigation'

interface UseChatSliderProps {
    slider: React.MutableRefObject<Slider | undefined>
    numChatsOnScreen: number
    selectedChats: Models.ChatCenterSelectedChat[]
    paginator: Models.ChatCenterPaginator
    turnChatsPage: typeof turnChatsPage
}

interface UseChatSliderReturn {
    activeSlide: number
    focusedChatIndex: number
    setActiveCarouselSlide: (nextActiveSlide?: number) => void
    focusChat: (index: number) => void
}

const useChatSlider = (props: UseChatSliderProps): UseChatSliderReturn => {
    const { slider, selectedChats, numChatsOnScreen, paginator, turnChatsPage } = props

    const [activeSlide, setActiveSlide] = useState<number>(0)
    const [focusedChatIndex, setFocusedChat] = useState<number>(0)

    useEffect(() => {
        const maxSlide = Math.max(0, selectedChats.length - numChatsOnScreen)

        if (!slider.current || !selectedChats[focusedChatIndex]) {
            return
        }

        const isFocusedChatVisible = selectedChats[focusedChatIndex].visible

        if (!isFocusedChatVisible) {
            const slideToScroll = Math.min(focusedChatIndex, maxSlide)
            slider.current.slickGoTo(slideToScroll)
        }
    }, [slider, focusedChatIndex, selectedChats, numChatsOnScreen])

    const focusChat = useCallback((chatIndex: number) => {
        setFocusedChat(chatIndex)
    }, [])

    const setActiveCarouselSlide = useCallback(
        (nextActiveSlide: number) => {
            if (nextActiveSlide !== activeSlide) {
                setActiveSlide(nextActiveSlide)
            }
        },
        [activeSlide],
    )

    useChatSliderNavigation({
        slider,
        focusedChatIndex,
        paginator,
        selectedChats,
        turnChatsPage,
        focusChat,
    })

    return {
        activeSlide,
        focusedChatIndex,
        setActiveCarouselSlide,
        focusChat,
    }
}

export default useChatSlider
