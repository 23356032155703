export enum PaymentRatesModalActionTypes {
    OPEN_PAYMENT_RATES_MODAL = '@LOCATIONS_TAB_PAYMENT_RATES/OPEN_PAYMENT_RATES_MODAL',
    CLOSE_PAYMENT_RATES_MODAL = '@LOCATIONS_TAB_PAYMENT_RATES/CLOSE_PAYMENT_RATES_MODAL',
}

export type OpenPaymentRatesModal = {
    type: PaymentRatesModalActionTypes.OPEN_PAYMENT_RATES_MODAL
    locationId: Models.PracticeLocation['id']
    locationName: Models.PracticeLocation['name']
    practiceId: string
}

export type ClosePaymentRatesModal = {
    type: PaymentRatesModalActionTypes.CLOSE_PAYMENT_RATES_MODAL
    practiceId: string
}

export function openPaymentRatesModal(
    locationId: Models.PracticeLocation['id'],
    locationName: Models.PracticeLocation['name'],
    practiceId: string,
): OpenPaymentRatesModal {
    return {
        type: PaymentRatesModalActionTypes.OPEN_PAYMENT_RATES_MODAL,
        locationId,
        locationName,
        practiceId,
    }
}

export function closePaymentRatesModal(practiceId: string): ClosePaymentRatesModal {
    return {
        type: PaymentRatesModalActionTypes.CLOSE_PAYMENT_RATES_MODAL,
        practiceId,
    }
}
