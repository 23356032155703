import React, { useMemo, useState } from 'react'
import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import uniqBy from 'lodash/uniqBy'

import { ChatStatus } from '../../../models/ChatStatus'
import CustomMultiselectField from '../../shared/custom-fields/CustomMultiselectField'

import ChatCard from './ChatCard'

import './AllChats.sass'

const moduleName = 'all-claimed-chats'
const statusList = Object.values(pick(ChatStatus, ['Claimed', 'Idle', 'Inactive', 'PatientClosed']))

export type AllChatsProps = {
    allClaimedChats: Models.ChatMetadata[]
    selectedChatsIds: string[]
}

export type AllChatsDispatch = {
    onSelectChat: (selectedChatId: string) => void
}

export type AllChatsState = {}

type Props = AllChatsProps & AllChatsDispatch

const AllChats = ({ allClaimedChats, selectedChatsIds, onSelectChat }: Props) => {
    const [selectedClaimees, setSelectedClaimees] = useState<string[]>([])
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])

    const renderChat = (chat: Models.ChatMetadata) => {
        const selected = selectedChatsIds.includes(chat.id)

        return <ChatCard key={chat.id} chat={chat} onSelect={onSelectChat} selected={selected} />
    }

    const claimeeList = useMemo(
        () =>
            orderBy(
                uniqBy(
                    allClaimedChats
                        .filter(chat => Boolean(chat.claimee))
                        .map(chat => ({
                            ...chat.claimee,
                            fullName: `${chat.claimee?.firstName} ${chat.claimee?.lastName}`,
                        })),
                    'id',
                ),
                ['fullName'],
                ['asc'],
            ),
        [allClaimedChats],
    )

    const filteredChats = useMemo(() => {
        const filteredByClaimee = selectedClaimees?.length
            ? allClaimedChats.filter(chat => chat?.claimee?.id && selectedClaimees.includes(chat.claimee.id))
            : allClaimedChats
        const filterByStatus = selectedStatuses?.length
            ? filteredByClaimee.filter(chat => selectedStatuses.includes(chat.status.value))
            : filteredByClaimee
        return filterByStatus
    }, [allClaimedChats, selectedClaimees, selectedStatuses])

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__filter`}>
                <CustomMultiselectField
                    items={claimeeList}
                    selectedItems={selectedClaimees}
                    keyProperty={'id'}
                    displayProperty={'fullName'}
                    placeholder={'Select Chatter'}
                    selectAllLabel={'All Chatters'}
                    searchPlaceholder="Search by chatter name"
                    search={true}
                    onSelectElement={setSelectedClaimees}
                />
                <CustomMultiselectField
                    items={statusList}
                    selectedItems={selectedStatuses}
                    keyProperty={'value'}
                    displayProperty={'display'}
                    placeholder={'Select Status'}
                    selectAllLabel={'All Statuses'}
                    onSelectElement={setSelectedStatuses}
                />
            </div>
            <div className={`${moduleName}__list`}>{filteredChats.map(renderChat)}</div>
        </div>
    )
}

export default AllChats
