import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal } from '@mui/material'
import classNames from 'classnames'

import { RootState } from '../../appReducer'
import {
    CompletePendingReferralStatus,
    InProgressPendingReferralStatus,
    ReviewPendingReferralStatus,
    UnclaimedPendingReferralStatus,
} from '../../models/enums'
import { ReferralsDashboardCard } from '../../models/v2/Referrals'
import { deleteReferral } from '../../modules/amplify/actions'
import ConfirmModalWindow from '../../modules/shared/confirm-modal/ConfirmModal'
import ConfirmModal, { InformationTheme } from '../../modules/shared/confirm-modal-one-button/ConfirmModal'
import { useAppDispatch } from '../../util/useAppDispatch'

import CompletedReferralsDashboardColumn from './CompletedReferralsDashboardColumn'
import ReferralModal from './ReferralModal'
import ReferralsDashboardColumn from './ReferralsDashboardColumn'
import ReferralStatusModal from './ReferralStatusModal'
import { deleteReferralCard, fetchReferralsDashboard, resetReferralsDashboardColumn, updateReferral } from './v2actions'

import './ReferralsDashboard.sass'

const ReferralsDashboard = () => {
    const dispatch = useAppDispatch()

    const [activeReferralCard, setActiveReferralCard] = useState<ModelsV2.Referrals.ReferralsDashboardCard | null>()
    const [referralId, setReferralId] = useState<string>()

    const [isPendingReferralAlreadyClaimedModalOpen, setIsPendingReferralAlreadyClaimedModalOpen] = useState(false)
    const [isReferralModalOpen, setIsReferralModalOpen] = useState(false)
    const [isReferralStatusModalOpen, setIsReferralStatusModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [dashboardLoading, setDashboardLoading] = useState(false)
    const [referralFormLoading, setReferralFormLoading] = useState(false)
    const [fromStatus, setFromStatus] = useState('')
    const [toStatus, setToStatus] = useState('')

    const reloadColumns = useCallback(
        async (statuses: ModelsV2.Referrals.StatusValue[]) => {
            setDashboardLoading(true)
            resetReferralsDashboardColumn('complete')
            const requests: Promise<ReferralsDashboardCard[] | null>[] = []
            statuses.forEach((status: ModelsV2.Referrals.StatusValue) => {
                requests.push(
                    dispatch(
                        fetchReferralsDashboard({
                            filter: {
                                status: status,
                            },
                        }),
                    ),
                )
            })

            return await Promise.all(requests).then(responses => {
                setDashboardLoading(false)
            })
        },
        [dispatch],
    )

    useEffect(() => {
        reloadColumns(['unclaimed', 'in_progress', 'review', 'complete'])
    }, [dispatch, reloadColumns])

    const account = useSelector((state: RootState) => state?.app?.self?.account)
    const unclaimed = useSelector((state: RootState) => state.v2.referrals.referralsDashboard.unclaimed.referrals)
    const in_progress = useSelector((state: RootState) => state.v2.referrals.referralsDashboard.in_progress.referrals)
    const review = useSelector((state: RootState) => state.v2.referrals.referralsDashboard.review.referrals)
    const complete = useSelector((state: RootState) => state.v2.referrals.referralsDashboard.complete.referrals)

    const claimReferral = async (referral: ModelsV2.Referrals.ReferralsDashboardCard) => {
        const response = await dispatch(
            updateReferral(referral.id, {
                amplify_status: {
                    value: 'in_progress',
                },
            }),
        )

        if (response && response.id) {
            await openReferralForm(referral)
        } else {
            setIsPendingReferralAlreadyClaimedModalOpen(true)
        }
    }

    const closeReferralForm = () => {
        setIsReferralModalOpen(false)
        setReferralId('')
        setActiveReferralCard(null)
    }

    const openReferralForm = (referral: ModelsV2.Referrals.ReferralsDashboardCard) => {
        setActiveReferralCard(referral)
        setReferralId(referral.id)
        setIsReferralModalOpen(true)
    }

    const onDeleteReferral = async () => {
        if (activeReferralCard?.practice.id) {
            setDeleteModalOpen(false)
            setReferralFormLoading(true)
            await dispatch(deleteReferral(activeReferralCard?.practice.id, activeReferralCard.id))
            await dispatch(deleteReferralCard(activeReferralCard.id))
            setReferralFormLoading(false)
            closeReferralForm()
        }
    }
    const onUpdate = (
        currentStatus: ModelsV2.Referrals.StatusValue,
        nextStatus: ModelsV2.Referrals.StatusValue | null,
    ) => {
        closeReferralForm()
        const columns = [currentStatus]
        if (nextStatus !== null && currentStatus !== nextStatus) {
            columns.push(nextStatus)
            setIsReferralStatusModalOpen(true)
            setFromStatus(currentStatus)
            setToStatus(nextStatus)
        }
    }

    const actions = () => {
        const actions = []
        if (activeReferralCard && activeReferralCard.amplify_status.display === 'complete') {
            actions.push(<></>)
            return actions
        }

        const hasDeletePermission =
            account && account.permissions.find(permission => permission.type === 'can_delete_pending_referral')

        if (hasDeletePermission) {
            actions.push(
                <div key="delete-pending-referral" className="referral-actions">
                    <Button
                        className="delete"
                        onClick={() => {
                            setDeleteModalOpen(true)
                        }}
                    >
                        Delete
                    </Button>
                </div>,
            )
        }
        return actions
    }

    const getPatientName = (): string => {
        if (activeReferralCard?.patient_first_name && !activeReferralCard?.patient_last_name) {
            return activeReferralCard?.patient_first_name
        }
        if (activeReferralCard?.patient_first_name && activeReferralCard?.patient_last_name) {
            return `${activeReferralCard?.patient_first_name} ${activeReferralCard?.patient_first_name}`
        }
        return ''
    }

    const reloadReferralsDashboard = () => {
        reloadColumns(['unclaimed', 'in_progress', 'review', 'complete'])
    }

    return (
        <>
            <div className="referrals-dashboard-container">
                <div className="referrals-dashboard-header">
                    <h2 className="referrals-dashboard-title">Requests</h2>
                    <Button
                        onClick={reloadReferralsDashboard}
                        className="referrals-dashboard-refresh"
                        size="small"
                        disabled={dashboardLoading}
                    >
                        REFRESH PAGE <i className="material-icons refresh">refresh</i>
                    </Button>
                </div>
                <div
                    className={classNames(`referrals-dashboard-columns`, {
                        'referrals-dashboard-columns-loading': dashboardLoading,
                    })}
                >
                    <ReferralsDashboardColumn
                        referrals={unclaimed}
                        status={UnclaimedPendingReferralStatus}
                        openReferral={openReferralForm}
                        claimReferral={claimReferral}
                    />
                    <ReferralsDashboardColumn
                        referrals={in_progress}
                        status={InProgressPendingReferralStatus}
                        openReferral={openReferralForm}
                    />
                    <ReferralsDashboardColumn
                        referrals={review}
                        status={ReviewPendingReferralStatus}
                        openReferral={openReferralForm}
                    />

                    <CompletedReferralsDashboardColumn
                        status={CompletePendingReferralStatus}
                        referrals={complete}
                        openReferral={openReferralForm}
                    />
                </div>
            </div>

            {isReferralModalOpen && referralId && (
                <ReferralModal
                    newTestReferral={false}
                    newManualReferral={false}
                    onClose={closeReferralForm}
                    pid={activeReferralCard?.practice.id}
                    referralId={referralId}
                    onUpdate={onUpdate}
                    actions={actions()}
                    account={account}
                    loading={referralFormLoading}
                />
            )}

            {isPendingReferralAlreadyClaimedModalOpen && (
                <ConfirmModal
                    title="Request already claimed"
                    isModalOpen={isPendingReferralAlreadyClaimedModalOpen}
                    closeModal={() => {
                        setIsPendingReferralAlreadyClaimedModalOpen(false)
                    }}
                    onConfirm={() => {
                        setIsPendingReferralAlreadyClaimedModalOpen(false)
                    }}
                    confirmLabel="RETURN TO REQUEST CENTER"
                    theme={InformationTheme}
                >
                    This request has been claimed by another Request Writer.
                </ConfirmModal>
            )}

            {deleteModalOpen && (
                <ConfirmModalWindow
                    title={`Are you sure you want to delete this request?`}
                    subtitle=""
                    discardText="Cancel"
                    confirmText="Confirm"
                    open={deleteModalOpen}
                    onClose={() => {
                        setDeleteModalOpen(false)
                    }}
                    onConfirm={onDeleteReferral}
                    onDiscard={() => {
                        setDeleteModalOpen(false)
                    }}
                />
            )}

            {isReferralStatusModalOpen && (
                <Modal
                    className="referral-status-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            setIsReferralStatusModalOpen(false)
                        }
                    }}
                    key="referral-status-modal"
                >
                    <div className="modal-contents">
                        <div
                            className="close-modal-button"
                            onClick={() => {
                                setIsReferralStatusModalOpen(false)
                            }}
                        >
                            <i className="material-icons">close</i>
                        </div>
                        <ReferralStatusModal
                            patientName={getPatientName()}
                            fromStatus={fromStatus}
                            toStatus={toStatus}
                            onCloseSuccessModal={() => {
                                setIsReferralStatusModalOpen(false)
                            }}
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}

export default ReferralsDashboard
