import React from 'react'
import { Modal } from '@mui/material'
import Button, { ButtonClassKey } from '@mui/material/Button'

import './AlertModal.sass'

type Props = {
    open: boolean
    title: string
    subtitle: string
    confirmText?: string
    onConfirm: () => void
    onClose: () => void
}

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const moduleName = 'alert-modal'

const buttonClassOverrides: ButtonClassOverrides = {
    contained: `${moduleName}__yes_btn`,
}

const AlertModal = ({ title, subtitle, confirmText, onClose, onConfirm, open }: Props) => {
    return (
        <Modal open={open} onClose={onClose}>
            <div className={`${moduleName}__container`}>
                <div className={`${moduleName}__header`}>
                    <span className={`${moduleName}__close-button`} onClick={onClose}>
                        <i className="material-icons">close</i>
                    </span>
                </div>
                <div className={`${moduleName}__content`}>
                    <div className={`${moduleName}__icon`}>
                        <i className="material-icons">info</i>
                    </div>
                    <div className={`${moduleName}__inner-content`}>
                        <div className={`${moduleName}__title`}>{title}</div>
                        <div className={`${moduleName}__notification`}>{subtitle}</div>
                    </div>
                </div>
                <div className={`${moduleName}__footer`}>
                    <Button variant="contained" type="reset" classes={buttonClassOverrides} onClick={onConfirm}>
                        {confirmText || 'CONFIRM'}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default AlertModal
