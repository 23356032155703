import { BookingStep } from './models/BookingAppointment'
import { PatientType } from './models/enums'
import { setAppointmentBookingStep, setBookingError } from './modules/chat-center/direct-scheduling/actions'
import {
    AppointmentSubscribeToChannel,
    AppointmentUnsubscribeFromChannel,
    ConnectToAppointmentSocket,
    DisconnectFromAppointmentSocket,
    setPending,
} from './modules/chat-center/direct-scheduling/actions'
import { ReceiveToken, setSocketConnectionStatus } from './appActions'
import AppointmentSocket from './AppointmentSocket'
import SessionManager from './SessionManager'

type AppAction =
    | ReceiveToken
    | ConnectToAppointmentSocket
    | DisconnectFromAppointmentSocket
    | AppointmentSubscribeToChannel
    | AppointmentUnsubscribeFromChannel

class AppointmentDataEventsService {
    appointmentSocket: any
    store: any
    transactionId: string | null = null
    chat: Models.ChatMetadata
    tab: PatientType
    connected: boolean = false

    constructor() {
        this.appointmentSocket = new AppointmentSocket()
    }

    storeListener() {
        return (next: any) => async (action: AppAction) => {
            const nextValue = next(action)

            switch (action.type) {
                case 'RECEIVE_TOKEN': {
                    this.authenticate(action.token)
                    break
                }
                case 'APPOINTMENT_SOCKET_CONNECT': {
                    this.connect(SessionManager.session)
                    break
                }
                case 'APPOINTMENT_SOCKET_DISCONNECT': {
                    this.disconnect()
                    break
                }
                case 'APPOINTMENT_CHANNEL_SUBSCRIBE': {
                    await this.subscribe(action.transactionId, action.chat, action.tab)
                    break
                }
                case 'APPOINTMENT_CHANNEL_UNSUBSCRIBE': {
                    this.unsubscribe()
                    break
                }
                default:
                    break
            }

            return nextValue
        }
    }

    registerStore(store: any) {
        if (this.store) {
            throw new Error('Store already registered with DataEvent instance')
        }
        this.store = store
    }

    authenticate(token: string) {
        this.appointmentSocket.setToken(token)
        this.store.dispatch(setSocketConnectionStatus(true))
    }

    connect(token: string | undefined) {
        this.appointmentSocket.connect({
            token,
            onReconnectCallback: this.onReconnect.bind(this),
            onDisconnectCallback: this.onDisconnect.bind(this),
        })

        this.appointmentSocket.register('appointment_confirmation', {
            dispatch: this.appointmentConfirmationEvent.bind(this),
            fallbackInterval: null,
            skipFirst: true,
        })
    }

    onReconnect() {
        this.connected = true
        this.store.dispatch(setSocketConnectionStatus(this.connected))
    }

    onDisconnect() {
        this.connected = false
        this.store.dispatch(setSocketConnectionStatus(this.connected))
    }

    disconnect() {
        this.appointmentSocket.disconnect()
        this.onDisconnect()
    }

    async subscribe(id: string, chat: Models.ChatMetadata, tab: PatientType) {
        if (this.transactionId) {
            this.unsubscribe()
        }
        this.transactionId = id
        this.chat = chat
        this.tab = tab

        await this.appointmentSocket.subscribe(id)
    }

    unsubscribe() {
        this.transactionId = null
        this.appointmentSocket.unsubscribe()
    }

    async appointmentConfirmationEvent(data: { success: boolean }) {
        try {
            if (data == null) {
                return
            }

            if (data?.success) {
                this.store.dispatch(setAppointmentBookingStep(this.chat, this.tab, BookingStep.BOOKED))
                this.store.dispatch(setBookingError(this.chat, this.tab, undefined))
                this.store.dispatch(setPending(this.chat, this.tab, false))
            } else {
                this.store.dispatch(setAppointmentBookingStep(this.chat, this.tab, BookingStep.ERROR))
                this.store.dispatch(setPending(this.chat, this.tab, false))
            }
        } catch (e) {
            console.error(e)
        }
    }
}

const AppointmentDataEvents = new AppointmentDataEventsService()

export default AppointmentDataEvents
