import * as React from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import Api from '../../../Api'

import './AgentMetrics.sass'

export type AgentMetricsProps = {
    practice: Models.Practice
    agents: Array<Models.PracticeAgent>
    onClose: () => void
}

export type AgentMetricsState = {
    vitals?: Api.PracticeFlowVitals
}

type Props = AgentMetricsProps

class AgentMetrics extends React.Component<AgentMetricsProps, AgentMetricsState> {
    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    UNSAFE_componentWillMount() {
        Api.Practices.getAgentVitals(this.props.practice).then(vitals => {
            this.setState({ vitals })
        })
    }

    render() {
        const { agents } = this.props
        const { vitals } = this.state

        if (!vitals) {
            return (
                <div className="loading-vitals">
                    <div>
                        <Button className="manage" onClick={this.props.onClose}>
                            Manage Agents
                        </Button>
                    </div>
                    <div>
                        <CircularProgress /> Loading vitals
                    </div>
                </div>
            )
        }

        const { latest_diagnostics, appointments_today, first_and_last_today } = vitals

        const fiveMinutesAgo = moment().subtract(5, 'minutes')
        const [healthyAgents, unhealthyAgents] = _.partition(agents, agent => {
            const diagnostic = latest_diagnostics.find((d: any) => d.agent_id === agent.id)
            if (!diagnostic) {
                return false
            }

            return fiveMinutesAgo < moment(diagnostic.created)
        })

        return (
            <div className={classNames('agent-metrics')}>
                <div className="column">
                    <div className="metrics">
                        <div className="heading">Appointments</div>
                        <div className="range-select">Today</div>
                        <div className={classNames('appointment-count', { empty: appointments_today == null })}>
                            {appointments_today != null ? appointments_today : 'No Appointments from agents'}
                        </div>
                        {first_and_last_today && (
                            <React.Fragment>
                                <div className="appointment-date">
                                    <div className="title">Most Recent</div>
                                    <div className="date">
                                        {moment(first_and_last_today.first_appointment_date).format('L [at] hh:mmA')}
                                    </div>
                                </div>
                                <div className="appointment-date">
                                    <div className="title">Furthest Scheduled</div>
                                    <div className="date">
                                        {moment(first_and_last_today.last_appointment_date).format('L [at] hh:mmA')}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className="column">
                    <div className="metrics">
                        <Button className="manage" onClick={this.props.onClose}>
                            manage
                        </Button>
                        <div className="heading">
                            <div className="agent-count">{agents.length} Agents</div>
                        </div>
                        <div className="graphs">
                            <div className="healthy">
                                <div
                                    className="bar"
                                    style={{ width: `${4 + (100 * healthyAgents.length) / agents.length}px` }}
                                />
                                <div className="label">Healthy</div>
                                <div className="count">{healthyAgents.length}</div>
                            </div>
                            <div className="unhealthy">
                                <div
                                    className="bar"
                                    style={{ width: `${4 + (100 * unhealthyAgents.length) / agents.length}px` }}
                                />
                                <div className="label">Unhealthy</div>
                                <div className="count">{unhealthyAgents.length}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AgentMetrics
