import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'

import { CustomFieldType } from '../../../models/enums'
import { receiveFormValueChange } from '../../../modules/referrals/referral-form/v2actions'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CustomField from '../../shared/custom-fields/CustomField'
import { ReferralFormSections } from '../shared/enums'

import './ReferralReviewFlag.sass'

type Props = {
    referral?: ModelsV2.Referrals.ReferralV2
    failedSave: boolean
}

const moduleName = 'referral-review-flag'
const ReferralReviewFlag = (props: Props) => {
    const { referral } = props
    const [isFlaggedForReview, setIsFlaggedForReview] = useState(Boolean(props.referral?.flag))

    const dispatch = useAppDispatch()

    const flagForReview = () => {
        setIsFlaggedForReview(true)
        onChange('flag', '')
    }

    const cancelReview = () => {
        setIsFlaggedForReview(false)
        onChange('flag', '')
    }

    const onChange = (field: string, value: string | undefined) => {
        dispatch(receiveFormValueChange(ReferralFormSections.REFERRAL, field, value))
    }

    return (
        <Grid container={true} spacing={5} className={`${moduleName}__container`}>
            <Grid item={true} xs={4}>
                <div className={moduleName}>
                    {isFlaggedForReview ? (
                        <div className={`${moduleName}__field`}>
                            <CustomField
                                customFieldType={CustomFieldType.TEXTAREA}
                                label="Reason for Flag*"
                                value={referral?.flag || ''}
                                rows={2}
                                autoSize={true}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    const value = event.target.value
                                    onChange('flag', value)
                                }}
                            />
                            <Button className={`${moduleName}__cancel-review`} onClick={cancelReview}>
                                CLEAR
                            </Button>
                        </div>
                    ) : (
                        <Button
                            className={`${moduleName}__flag-button`}
                            color="secondary"
                            variant="contained"
                            onClick={flagForReview}
                        >
                            <i className="material-icons flag-icon">flag</i> FLAG FOR REVIEW
                        </Button>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

export default ReferralReviewFlag
