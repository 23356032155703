import React, { Fragment, useEffect, useRef, useState } from 'react'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'

import { RootState } from '../../../appReducer'
import { IntegrationsAgent, IntegrationsAgentModule, IntegrationsSoftware } from '../../../models/v2/Integrations'
import { useAppDispatch } from '../../../util/useAppDispatch'
import ErrorMessage from '../../shared/error-message/error-message'
import { mainBlue } from '../../shared/styles/colors'

import { loadAgent } from './actions'
import { IntegrationsCreateAgentForm } from './IntegrationsCreateAgentForm'
import { IntegrationsDownloadAgent } from './IntegrationsDownloadAgent'
import { IntegrationsLocationPairingForm } from './IntegrationsLocationPairingForm'

import './Integrations.sass'

const moduleName = 'integrations'

export const SOFTWARE_TYPES = {
    DENTRIX: 'DENTRIX',
    DENTRIX_ENTERPRISE: 'DENTRIX_ENTERPRISE',
    EAGLESOFT: 'EAGLESOFT',
    OPENDENTAL: 'OPENDENTAL',
    QUICKBOOKS: 'QUICKBOOKS',
}

interface Props {
    practice: Models.Practice
    isModalOpened: boolean
    externalAgentId?: number
    agentId?: string
    software: IntegrationsSoftware[]
    setSavedAgentMessage: (agent: IntegrationsAgent) => void
    onClose: () => void
}

export const IntegrationsForm = ({
    practice,
    onClose,
    isModalOpened,
    externalAgentId,
    setSavedAgentMessage,
    software,
    agentId,
}: Props) => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const selectedAgent = useSelector((state: RootState) => state.v2.integrations.selectedAgent)
    const createdAgentId = useRef(agentId)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const fetchAgent = async () => {
            if (externalAgentId) {
                try {
                    setLoading(true)
                    await dispatch(loadAgent(externalAgentId))
                } catch (error) {
                    setError(error.message)
                } finally {
                    setLoading(false)
                }
            }
        }

        fetchAgent()
    }, [dispatch, externalAgentId])

    const hasExternalLocations = (agent: IntegrationsAgent) =>
        agent.agentModules && agent.agentModules.some((module: IntegrationsAgentModule) => module.agentLocations.length)

    return (
        <>
            <Modal
                open={isModalOpened}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose()
                    }
                }}
                disableEscapeKeyDown={true}
            >
                <div className={classNames('integrations-location-modal', `${moduleName}__modal`)}>
                    <>
                        <div>
                            <i className="material-icons close-modal" onClick={onClose}>
                                close
                            </i>
                        </div>
                        {loading ? (
                            <div className={`${moduleName}__loading-overlay`}>
                                <ReactLoading type="spin" color={mainBlue} height={40} width={40} />
                            </div>
                        ) : (
                            <div className={`${moduleName}__modal-body`}>
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                                {selectedAgent && (
                                    <Fragment>
                                        {selectedAgent.agentModules.length > 0 &&
                                            !hasExternalLocations(selectedAgent) && (
                                                <IntegrationsDownloadAgent
                                                    selectedAgent={selectedAgent}
                                                    practice={practice}
                                                />
                                            )}
                                        {selectedAgent.agentModules.length > 0 &&
                                            hasExternalLocations(selectedAgent) && (
                                                <IntegrationsLocationPairingForm
                                                    selectedAgent={selectedAgent}
                                                    practice={practice}
                                                    software={software}
                                                    setSavedAgentMessage={setSavedAgentMessage}
                                                    onClose={onClose}
                                                    createdAgentId={createdAgentId}
                                                />
                                            )}
                                    </Fragment>
                                )}

                                {(!selectedAgent || !selectedAgent.agentModules.length) && (
                                    <IntegrationsCreateAgentForm
                                        practice={practice}
                                        software={software}
                                        agentId={agentId}
                                        createdAgentId={createdAgentId}
                                    />
                                )}
                            </div>
                        )}
                    </>
                </div>
            </Modal>
        </>
    )
}
