import * as React from 'react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import './SchedulingErrorMessage.sass'

export type SchedulingErrorMessageProps = {
    message: string
    onButtonClick?: Models.VoidFunction
    type?: 'error' | 'warning'
}

const moduleName = 'scheduling-error-message'

const SchedulingErrorMessage = (props: SchedulingErrorMessageProps) => {
    const { message, onButtonClick, type } = props
    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__wrapper ${type && `${moduleName}__wrapper--${type}`}`}>
                <Icon>warning</Icon>
                <div className={`${moduleName}__message`}>{message}</div>
            </div>
            {onButtonClick !== undefined && (
                <div className={`${moduleName}__submit`}>
                    <Button className={`${moduleName}__submit-button`} onClick={onButtonClick}>
                        TRY AGAIN
                        <Icon>refresh</Icon>
                    </Button>
                </div>
            )}
        </div>
    )
}

export default SchedulingErrorMessage
