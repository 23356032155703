import React, { ChangeEvent, FocusEvent } from 'react'
import countries from 'countries-list'
import _ from 'lodash'

import { CustomFieldType } from '../../../../models/enums'
import { useAppDispatch } from '../../../../util/useAppDispatch'
import CustomField from '../../../shared/custom-fields/CustomField'
import CustomMultiselectField from '../../../shared/custom-fields/CustomMultiselectField'
import ErrorMessage from '../../../shared/error-message/error-message'
import { validatePhoneNumber } from '../../../shared/phone-number-formatter/formatPhoneNumber'
import { getZipCodeTimezone } from '../../actions'
import { defaultTimezone } from '../constants'
import SearchLocationInput, { AddressObject, GeocodingResult } from '../SearchLocationInput'
import { getTimeZonesForCountry, getTimeZonesWithAbbreviations } from '../utils'

import { FormFieldValidationModel, LocationFormDataModel } from './hooks/useLocationForm'

type CountryData = {
    code: string
    name: string
    phone: string
    locale: string
}

type AddressFormDataModel = Pick<
    LocationFormDataModel,
    | 'street'
    | 'unit'
    | 'city'
    | 'state'
    | 'zip'
    | 'countryCodeIsoAlpha2'
    | 'timezone'
    | 'timezoneListForCountry'
    | 'lat'
    | 'lng'
    | 'phone'
>

interface Props {
    isFormEditing: boolean
    addressFields: AddressFormDataModel
    onSingleFieldChange: <T>(
        key: keyof AddressFormDataModel,
        value: T,
        meta?: Partial<FormFieldValidationModel>,
        makeDirty?: boolean,
    ) => void
    onMultipleFieldsChange: (fields: Partial<AddressFormDataModel>, makeDirty?: boolean) => void
}

const sortedCountriesList: CountryData[] = _.orderBy(
    Object.keys(countries.countries).map(code => {
        return {
            code,
            name: countries.countries[code].name,
            phone: countries.countries[code].phone,
            locale: countries.countries[code].locale,
        }
    }),
    'name',
)

const LocationAddressInformationForm = ({
    addressFields,
    isFormEditing,
    onSingleFieldChange,
    onMultipleFieldsChange,
}: Props) => {
    const dispatch = useAppDispatch()

    const getGeolocationFail = () =>
        addressFields.lat.isTouched &&
        addressFields.lng.isTouched &&
        !Boolean(
            addressFields.lat.isValid &&
                addressFields.lng.isValid &&
                addressFields.lng.value &&
                addressFields.lat.value,
        )

    const getLatLngHelperText = (key: 'lat' | 'lng') => {
        const dirKeys = key === 'lat' ? ['S', 'N'] : ['W', 'E']
        const parsed = parseFloat(addressFields[key].value ?? '')
        if (!isNaN(parsed)) {
            return parsed < 0 ? dirKeys[0] : dirKeys[1]
        }
        return ''
    }

    const generateCountryChanges = (countryCode: string): Partial<AddressFormDataModel> => {
        const timezoneListForCountry = getTimeZonesForCountry(countryCode)
        const newState: Partial<AddressFormDataModel> = {
            countryCodeIsoAlpha2: {
                value: countryCode,
                isTouched: true,
                isValid: true,
            },
            timezoneListForCountry: {
                value: timezoneListForCountry,
                isTouched: true,
                isValid: true,
            },
            timezone: {
                value: !timezoneListForCountry ? defaultTimezone : '',
                isTouched: true,
                isValid: true,
            },
        }

        if (addressFields.phone.isTouched && addressFields.phone.value) {
            return {
                ...newState,
                phone: {
                    ...addressFields.phone,
                    isTouched: true,
                    isValid: validatePhoneNumber(addressFields.phone.value, countryCode),
                },
            }
        } else {
            return {
                ...newState,
                phone: {
                    ...addressFields.phone,
                    value: '',
                    isTouched: true,
                    isValid: false,
                },
            }
        }

        return { ...newState }
    }

    const validateZipCodeChange = async (
        zipCode: string,
        passedValues: AddressFormDataModel,
        autocompleted: boolean = false,
    ) => {
        const zipField = { ...passedValues.zip, value: zipCode }
        if (!passedValues.zip.isTouched && !autocompleted) {
            return { ...passedValues, zip: zipField }
        }

        if (passedValues.countryCodeIsoAlpha2?.value !== 'US') {
            return { ...passedValues, zip: { ...zipField, isValid: Boolean(zipCode.length > 0) } }
        }

        if (zipCode.length < 5) {
            return { ...passedValues, zip: { ...zipField, isValid: false } }
        }

        const timezoneResult = await dispatch(getZipCodeTimezone(zipCode.trim()))

        if (timezoneResult) {
            return {
                ...passedValues,
                zip: { ...zipField, isValid: true },
                timezone: { ...passedValues.timezone, value: timezoneResult.iana_tz, isValid: true },
            }
        }

        return { ...passedValues, zip: { ...zipField, isValid: false } }
    }

    const handleValidateAddressChange = (passedFormValues: AddressFormDataModel) => {
        let localFormValues = { ...passedFormValues }
        const { street, city, state, countryCodeIsoAlpha2, zip } = localFormValues
        if (street.value && city.value && state.value && countryCodeIsoAlpha2?.value && zip.value) {
            const service = new window.google.maps.Geocoder()
            service.geocode(
                {
                    address: `${street.value ?? ''}, ${city.value ?? ''}, ${state.value ?? ''},
                        ${countryCodeIsoAlpha2.value ?? ''} ${zip.value ?? ''}`,
                },
                (results: GeocodingResult[], status: string) => {
                    const result =
                        results?.find(
                            (geometryResult: GeocodingResult) => geometryResult.geometry?.location_type === 'ROOFTOP',
                        )?.geometry || null
                    const lat = result?.location?.lat()
                    const lng = result?.location?.lng()
                    if (status === 'OK' && lat && lng) {
                        localFormValues = {
                            ...localFormValues,
                            lat: {
                                value: lat.toString(),
                                isTouched: true,
                                isValid: true,
                            },
                            lng: {
                                value: lng.toString(),
                                isTouched: true,
                                isValid: true,
                            },
                        }
                    } else {
                        localFormValues = {
                            ...localFormValues,
                            lat: {
                                value: '',
                                isTouched: true,
                                isValid: false,
                            },
                            lng: {
                                value: '',
                                isTouched: true,
                                isValid: false,
                            },
                        }
                    }
                },
            )
        }
        return localFormValues
    }

    const validateCountryChange = async (countryCode: string) => {
        const updatedFields = handleValidateAddressChange({
            ...addressFields,
            ...generateCountryChanges(countryCode),
        })

        const data = await validateZipCodeChange(updatedFields.zip.value || '', updatedFields)

        onMultipleFieldsChange({ ...addressFields, ...data })
    }

    const handleInputChange = (
        key: keyof AddressFormDataModel,
        meta: Partial<FormFieldValidationModel> | undefined = {},
    ) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => onSingleFieldChange(key, value, meta)

    const handleStreetChange = (inputValue: string | undefined) => onSingleFieldChange('street', inputValue)

    const handleTimeZoneChange = (values: string[]) => onSingleFieldChange('timezone', values[0] || '')

    const handleZipCodeChange = async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const changeData = await validateZipCodeChange(value, addressFields)
        onMultipleFieldsChange({ ...addressFields, ...changeData })
    }

    const handleCountryChange = (values: string[]) => {
        const value = values[0]
        if (value && value !== addressFields.countryCodeIsoAlpha2?.value) {
            validateCountryChange(value)
        }
    }

    const handleAddressUpdate = () =>
        onMultipleFieldsChange({ ...addressFields, ...handleValidateAddressChange(addressFields) })

    const handlePlaceSelect = async (addressObject: AddressObject) => {
        if (addressObject.street) {
            const shouldChangeCountry = addressObject.country !== addressFields.countryCodeIsoAlpha2?.value
            const lat: string = typeof addressObject.lat === 'number' ? addressObject.lat.toString() : ''
            const lng: string = typeof addressObject.lng === 'number' ? addressObject.lng.toString() : ''

            let bulkFields: AddressFormDataModel = {
                ...addressFields,
                street: {
                    value: addressObject.street,
                    isValid: true,
                    isTouched: true,
                },
                unit: {
                    value: addressObject.unit,
                    isValid: true,
                    isTouched: true,
                },
                city: {
                    value: addressObject.city || '',
                    isValid: true,
                    isTouched: true,
                },
                zip: {
                    value: addressObject.zip || '',
                    isValid: true,
                    isTouched: true,
                },
                state: {
                    value: addressObject.state || '',
                    isValid: true,
                    isTouched: true,
                },
                countryCodeIsoAlpha2: {
                    value: addressObject.country || '',
                    isValid: true,
                    isTouched: true,
                },
                timezoneListForCountry: {
                    value: getTimeZonesForCountry(addressObject.country ?? 'US'),
                    isValid: true,
                    isTouched: true,
                },
                lat: {
                    value: lat ?? addressFields.lat.value,
                    isValid: lat ? true : addressFields.lat.isValid,
                    isTouched: lat ? true : addressFields.lat.isTouched,
                },
                lng: {
                    value: lng ?? addressFields.lng.value,
                    isValid: lng ? true : addressFields.lng.isValid,
                    isTouched: lng ? true : addressFields.lng.isTouched,
                },
            }

            if (addressObject.country && shouldChangeCountry) {
                bulkFields = handleValidateAddressChange({
                    ...bulkFields,
                    ...generateCountryChanges(addressObject.country),
                })
            }

            const data = await validateZipCodeChange(addressObject.zip ? addressObject.zip : '', bulkFields, true)

            onMultipleFieldsChange({ ...addressFields, ...data })
        }
    }

    const handleZipcodeBlur = async ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
        const changeData = await validateZipCodeChange(value, addressFields)
        onMultipleFieldsChange({ ...addressFields, ...handleValidateAddressChange(changeData) })
    }

    return (
        <>
            <div className="address-heading">Location Info</div>
            <div className="content">
                <div className="field street">
                    <SearchLocationInput
                        value={addressFields.street.value || ''}
                        label="Street Address*"
                        onChange={handleStreetChange}
                        onPlaceSelect={handlePlaceSelect}
                        onBlur={handleAddressUpdate}
                    />
                </div>
                <div className="field unit">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        value={addressFields.unit?.value || ''}
                        label="Suite"
                        onChange={handleInputChange('unit')}
                    />
                </div>
                <div className="field city">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        value={addressFields.city.value || ''}
                        label="City*"
                        onChange={handleInputChange('city')}
                        onBlur={handleAddressUpdate}
                    />
                </div>
                <div className="field state">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        value={addressFields.state.value || ''}
                        label={`State${addressFields.countryCodeIsoAlpha2?.value === 'US' ? `*` : ``}`}
                        onChange={handleInputChange('state')}
                        onBlur={handleAddressUpdate}
                    />
                </div>
                <div className="field zip">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Zip Code*"
                        value={addressFields.zip.value || ''}
                        onChange={handleZipCodeChange}
                        onBlur={handleZipcodeBlur}
                        error={addressFields.zip.isTouched && !addressFields.zip.isValid}
                    />
                </div>
                <div className="field country">
                    <CustomMultiselectField
                        search
                        items={sortedCountriesList}
                        maxSelected={1}
                        selectedItems={[addressFields.countryCodeIsoAlpha2?.value || 'US']}
                        keyProperty="code"
                        displayProperty="name"
                        placeholder="Select Country"
                        label={`Country*`}
                        searchPlaceholder="Search Countries"
                        error={addressFields.timezone.isTouched && !addressFields.timezone.isValid}
                        onSelectElement={handleCountryChange}
                    />
                </div>
                {(isFormEditing || addressFields.timezoneListForCountry.value) && (
                    <div className="field country">
                        <CustomMultiselectField
                            items={
                                addressFields.timezoneListForCountry.value
                                    ? getTimeZonesWithAbbreviations(addressFields.timezoneListForCountry.value)
                                    : []
                            }
                            search={addressFields.timezoneListForCountry.value?.length > 8}
                            searchPlaceholder="Search Timezones"
                            maxSelected={1}
                            selectedItems={[addressFields.timezone.value || '']}
                            keyProperty="timezoneIanaTZ"
                            displayProperty="timezoneString"
                            placeholder="Time Zone"
                            label={`Time Zone*`}
                            error={addressFields.timezone.isTouched && !addressFields.timezone.isValid}
                            onSelectElement={handleTimeZoneChange}
                        />
                    </div>
                )}
                {getGeolocationFail() && (
                    <div className="error-wrapper">
                        <ErrorMessage type="error">
                            Geocoding has failed. Please input the geographic coordinates.
                        </ErrorMessage>
                    </div>
                )}
                <div className="field field--position field--lat">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Latitude*"
                        value={addressFields.lat.value || ''}
                        onChange={handleInputChange('lat')}
                        error={addressFields.lat.isTouched && !addressFields.lat.isValid}
                    />
                    <span className="field-position__helper">{getLatLngHelperText('lat')}</span>
                </div>

                <div className="field field--position field--lng">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Longitude*"
                        value={addressFields.lng.value || ''}
                        onChange={handleInputChange('lng')}
                        error={addressFields.lng.isTouched && !addressFields.lng.isValid}
                    />
                    <span className="field-position__helper">{getLatLngHelperText('lng')}</span>
                </div>
            </div>
        </>
    )
}

export default LocationAddressInformationForm
