import iassign from 'immutable-assign'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

import { IMappedAgentsWithLocations, IntegrationsAgent, IntegrationsSoftware } from '../../../models/v2/Integrations'

import {
    ReceiveHeartbeatAgents,
    ReceiveHeartbeatSoftware,
    ReceivePracticeLocations,
    ResetSelectedAgent,
    SetAgentsErrorMessage,
    SetAgentsLoading,
    SetAgentsPending,
    SetSelectedAgent,
    UpdateSelectedAgent,
} from './actions'
import { IntegrationsActionTypes } from './actions'

export type IntegrationsState = {
    heartbeat: {
        [key: string /* practice id */]: {
            agents: IMappedAgentsWithLocations[]
            isPending: boolean
            errorMessage: string
            query: string
            page?: number
            pagination?: Models.PaginationInfo | null
        }
    }
    software: IntegrationsSoftware[]
    selectedAgent?: IntegrationsAgent
    loadingAgents: {
        [key: string /* practice id */]: boolean
    }
    practiceLocations: { [key: string /* practice id */]: ModelsV2.Practice.PracticeLocationsV2[] }
}

const initialState: IntegrationsState = {
    heartbeat: {},
    software: [],
    selectedAgent: undefined,
    loadingAgents: {},
    practiceLocations: {},
}

type IntegrationsAction =
    | ReceiveHeartbeatAgents
    | ReceiveHeartbeatSoftware
    | UpdateSelectedAgent
    | SetAgentsPending
    | SetSelectedAgent
    | SetAgentsErrorMessage
    | ResetSelectedAgent
    | SetAgentsLoading
    | ReceivePracticeLocations

export const reducer = (state: IntegrationsState = initialState, action: IntegrationsAction): IntegrationsState => {
    switch (action.type) {
        case IntegrationsActionTypes.GET_HEARTBEAT_AGENTS_WITH_LOCATIONS: {
            return iassign(
                state,
                next => next.heartbeat,
                nextHeartbeat => {
                    if (!nextHeartbeat[action.practiceId]) {
                        nextHeartbeat[action.practiceId] = {
                            query: '',
                            agents: [],
                            page: 1,
                            pagination: null,
                            isPending: false,
                            errorMessage: '',
                        }
                    }
                    nextHeartbeat[action.practiceId].agents = action.heartbeatAgents
                    nextHeartbeat[action.practiceId].query = action.query
                    nextHeartbeat[action.practiceId].page = action.page
                    nextHeartbeat[action.practiceId].pagination = action.paginationInfo
                    nextHeartbeat[action.practiceId].isPending = false
                    return nextHeartbeat
                },
            )
        }

        case IntegrationsActionTypes.SET_AGENTS_PENDING: {
            return iassign(
                state,
                next => next.heartbeat,
                nextHeartbeat => {
                    if (!nextHeartbeat[action.practiceId]) {
                        nextHeartbeat[action.practiceId] = {
                            query: '',
                            agents: [],
                            isPending: action.state,
                            errorMessage: '',
                        }
                    } else {
                        nextHeartbeat[action.practiceId].isPending = action.state
                    }
                    return nextHeartbeat
                },
            )
        }
        case IntegrationsActionTypes.RECEIVE_HEARTBEAT_SOFTWARE: {
            return iassign(
                state,
                next => next.software,
                () => sortBy(action.software, 'moduleTypeName'),
            )
        }
        case IntegrationsActionTypes.SET_SELECTED_AGENT: {
            return iassign(
                state,
                next => next.selectedAgent,
                nextSelectedAgent => {
                    nextSelectedAgent = action.agent
                    return nextSelectedAgent
                },
            )
        }
        case IntegrationsActionTypes.RESET_SELECTED_AGENT: {
            return iassign(
                state,
                next => next.selectedAgent,
                nextSelectedAgent => {
                    nextSelectedAgent = initialState.selectedAgent
                    return nextSelectedAgent
                },
            )
        }

        case IntegrationsActionTypes.UPDATE_SELECTED_AGENT: {
            return iassign(
                state,
                next => next.selectedAgent,
                nextAgents => {
                    if (!nextAgents) {
                        return nextAgents
                    }

                    nextAgents?.agentModules.map(aModule => {
                        const foundLocation = aModule.agentLocations.find(location => {
                            return location.id === action.externalLocationId
                        })

                        if (foundLocation) {
                            foundLocation.directScheduling = action.directScheduling
                            foundLocation.heartbeat = action.heartbeat
                            foundLocation.chairfill = action.chairfill
                            foundLocation.payments = action.payments
                            foundLocation.reviews = action.reviews

                            if (foundLocation.practiceLocation?.heartbeatPracticeLocationId !== action.value) {
                                foundLocation.practiceLocation = {
                                    heartbeatPracticeLocationId: action.value,
                                    updated: true,
                                }
                            }
                        }
                    })
                    return nextAgents
                },
            )
        }

        case IntegrationsActionTypes.SET_AGENTS_LOADING: {
            return iassign(
                state,
                next => next.loadingAgents,
                nextLoadingAgents => {
                    if (!nextLoadingAgents[action.practiceId]) {
                        nextLoadingAgents[action.practiceId] = action.isLoading
                    } else {
                        nextLoadingAgents[action.practiceId] = action.isLoading
                    }
                    return nextLoadingAgents
                },
            )
        }
        case IntegrationsActionTypes.RECEIVE_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.practiceLocations[action.practiceId],
                nextLocations => {
                    if (nextLocations) {
                        nextLocations = uniqBy([...nextLocations, ...action.locations], 'id')
                        return nextLocations
                    }
                    nextLocations = action.locations
                    return nextLocations
                },
            )
        }
        case IntegrationsActionTypes.SET_AGENTS_ERROR_MESSAGE: {
            return iassign(
                state,
                next => next.heartbeat,
                nextHeartbeat => {
                    if (!nextHeartbeat[action.practiceId]) {
                        nextHeartbeat[action.practiceId] = {
                            query: '',
                            agents: [],
                            isPending: false,
                            errorMessage: action.errorMessage,
                        }
                    } else {
                        nextHeartbeat[action.practiceId].errorMessage = action.errorMessage
                    }
                    return nextHeartbeat
                },
            )
        }
        default:
            return state
    }
}
