import { useState } from 'react'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { useAppDispatch } from 'util/useAppDispatch'
import { cancelPaymentPlan, loadPaymentPlan } from './actions'
import { convertNumCentsToDollars } from 'util/formatCurrency'
import { mapOldStatusToNewStatusPossiblities, mapTransactionStatusToCopy } from './ChangePaymentStatusModal'

import './AdminToolsShared.sass'
import { transactionIdLabel } from './utils'

interface Props {
    onClose: () => void
    practiceId: string
}

const DEFAULT_ERROR_MESSAGE = ''

const CancelPaymentPlanModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [paymentPlanId, setPaymentPlanId] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [screenIndex, setScreenIndex] = useState(0)
    const [isLoadingPaymentPlan, setIsLoadingPaymentPlan] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        paymentPlanId: string
    }>({
        paymentPlanId: '',
    })
    const [isCancelingPaymentPlan, setIsCancelingPaymentPlan] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE)

    const goBackScreen = () => {
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadPaymentPlan = async () => {
        if (isLoadingPaymentPlan) {
            return
        }

        setIsLoadingPaymentPlan(true)
        try {
            const loadedPaymentPlanId = await dispatch(loadPaymentPlan(paymentPlanId, transactionId))
            setLoadedData({
                paymentPlanId: loadedPaymentPlanId,
            })
            goForwardScreen()
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Error loading payment plan.')
            }
        }
        setIsLoadingPaymentPlan(false)
    }

    const onSubmitCancelPaymentPlan = async () => {
        if (isCancelingPaymentPlan) {
            return
        }
        setIsCancelingPaymentPlan(true)
        try {
            const cancelPlan = await dispatch(cancelPaymentPlan(loadedData.paymentPlanId))
            if (cancelPlan === true) {
                goForwardScreen()
                setSuccessMessage(`Payment plan ${loadedData.paymentPlanId} has been successfully canceled.`)
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsCancelingPaymentPlan(false)
    }

    return (
        <DefaultModal
            title="Cancel Payment Plan"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={'Payment Plan ID'}
                                    inputType="text"
                                    value={paymentPlanId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPaymentPlanId(e.target.value)
                                    }
                                />
                                <span className="admin-tools-or-text">Or</span>
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={transactionIdLabel}
                                    inputType="text"
                                    value={transactionId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingPaymentPlan,
                        disabled: paymentPlanId === '' && transactionId === '',
                        onClick: onLoadPaymentPlan,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                You are about to cancel this payment plan. This action cannot be undone. Please confirm
                                the cancellation below.
                            </p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Payment Plan ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.paymentPlanId}</div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isCancelingPaymentPlan,
                        disabled: errorMessage !== DEFAULT_ERROR_MESSAGE,
                        onClick: onSubmitCancelPaymentPlan,
                        text: 'Cancel Payment Plan',
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default CancelPaymentPlanModal
