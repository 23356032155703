import { connect } from 'react-redux'

import { RootState } from '../../appReducer'

import { resetSearchPracticePatients } from './patients/v2actions'
import {
    createLocation,
    createPractice,
    fetchAvailablePracticeUserRoles,
    fetchPracticeList,
    fetchPracticeLocations,
    fetchPracticeLocationsPaginated,
    getZipCodeTimezone,
    savePractice,
    saveSelectedPractice,
    setCreateStep,
    setPracticeSearchTerms,
} from './actions'
import PracticeList, { PracticeListProps } from './PracticeList'

const mapStateToProps = (state: RootState): PracticeListProps => ({
    account: state.app.self && state.app.self.account,
    featureFlags: state.app.featureFlags,
    practices: state.practices.practices,
    practiceList: state.navigation.practices.list,
    createState: state.practices.createState,
    paginationInfo: state.practices.practicesPaginationInfo,
    selectedPractice: state.practices.selectedPractice,
    searchTerms: state.practices.searchTerms,
    practiceLocationTabData: state.practiceLocations,
})

const PracticeListContainer = connect(mapStateToProps, {
    fetchPracticeList,
    createPractice,
    createLocation,
    resetSearchPracticePatients,
    setCreateStep,
    savePractice,
    saveSelectedPractice,
    setPracticeSearchTerms,
    fetchAvailablePracticeUserRoles,
    getZipCodeTimezone,
    fetchPracticeLocations,
    fetchPracticeLocationsPaginated,
})(PracticeList as any)

export default PracticeListContainer
