import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Toolbar from '@mui/material/Toolbar'
import Fuse from 'fuse.js'

import { RootState } from '../../../appReducer'
import { useAsync } from '../../../util/useAsync'
import { SearchBar } from '../../shared/custom-fields/SearchBar'
import { fetchSurvey } from '../actions'

import Alert from './Alert'
import SurveyResponse from './SurveyResponse'

import './SurveyMonkeyComponent.sass'

export type SurveyComponentProps = {
    surveyId?: string
    practiceSpecialtyValue?: Models.PracticeSpecialtyValue
    webCodeKey?: string
    webCodeConnectStatusValue?: string
    wrapped?: boolean
}

const FUSE_OPTIONS = {
    threshold: 0.6,
    maxPatternLength: 24,
    minMatchCharLength: 1,
    keys: ['question'],
}

const moduleName = 'survey-component'

function SurveyMonkeyComponent({
    wrapped = false,
    surveyId,
    practiceSpecialtyValue,
    webCodeKey,
    webCodeConnectStatusValue,
}: SurveyComponentProps) {
    const [searchVal, setSearchVal] = React.useState('')
    const responses = useSelector((state: RootState) => webCodeKey && state.chat.surveys[webCodeKey])

    const dispatch = useDispatch()
    const { run, status, error } = useAsync({ status: responses ? 'resolved' : 'idle' })
    const shouldShowSearch = status === 'resolved' || responses

    React.useEffect(() => {
        if (surveyId && practiceSpecialtyValue && webCodeKey && !error && !responses) {
            const fetchSurveyParams: Models.GetSurveyResponse = {
                id: surveyId,
                practiceSpecialtyValue: practiceSpecialtyValue,
                webCodeKey: webCodeKey,
            }
            if (webCodeConnectStatusValue && webCodeConnectStatusValue === 'enabled') {
                fetchSurveyParams.includeConnectHours = true
            }

            run(dispatch(fetchSurvey(fetchSurveyParams)))
        }
    }, [
        run,
        dispatch,
        status,
        surveyId,
        responses,
        error,
        webCodeKey,
        practiceSpecialtyValue,
        webCodeConnectStatusValue,
    ])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const searchValue = e.target && e.target.value
        setSearchVal(searchValue)
    }

    const clearSearch = () => {
        const searchValue = ''
        setSearchVal(searchValue)
    }

    function renderResponses() {
        if (!surveyId) {
            return <Alert message="Survey has not been completed" />
        }

        if (status === 'pending') {
            return (
                <div className={`${moduleName}__loading`}>
                    <CircularProgress size={70} color="primary" variant="indeterminate" />
                </div>
            )
        } else if (status === 'rejected') {
            return (
                <Alert
                    message={
                        error?.message ?? 'Something went wrong while loading the survey. Please refresh the page.'
                    }
                />
            )
        } else {
            if (!responses) {
                return null
            }

            const fuse = new Fuse(responses, FUSE_OPTIONS)
            const results = searchVal && fuse ? fuse.search(searchVal) : responses

            return results.map((surveyResponse: Models.SurveyResponse, index: number) => (
                <Grid item={true} key={surveyResponse.id} className={`${moduleName}__item`}>
                    <SurveyResponse response={surveyResponse} darkerCard={index % 2 === 0} />
                </Grid>
            ))
        }
    }

    if (wrapped) {
        return (
            <Paper className={`${moduleName}__survey-wrapped`} square={true}>
                <Toolbar className={`${moduleName}__header ${moduleName}--wrapped`}>
                    {shouldShowSearch && (
                        <div className={`${moduleName}__search-bar`}>
                            <SearchBar value={searchVal || ''} onChange={handleChange} onClear={clearSearch} />
                        </div>
                    )}
                </Toolbar>
                <Grid container={true} className={`${moduleName}__content-wrapped`} spacing={0}>
                    {renderResponses()}
                </Grid>
            </Paper>
        )
    } else {
        return (
            <div className={`${moduleName}`}>
                <div className={`${moduleName}__header`}>
                    {status === 'resolved' && (
                        <div className={`${moduleName}__search-bar`}>
                            <SearchBar value={searchVal || ''} onChange={handleChange} onClear={clearSearch} />
                        </div>
                    )}
                </div>
                <div className={`${moduleName}__content`}>{renderResponses()}</div>
            </div>
        )
    }
}

export default SurveyMonkeyComponent
