import React from 'react'

import SelfSchedulingModalBackgroundColorStyle from './SelfSchedulingModalBackgroundColorStyle'
import SelfSchedulingModalColors from './SelfSchedulingModalColors'
import { CustomizationObject } from './types'

import './SelfSchedulingModalCustomizationOptions.sass'

const moduleName = 'self-scheduling-modal-customization-options'

type Props = {
    schedulingModal: Models.SelfSchedulingModal
    resetColors: (event: React.MouseEvent<HTMLElement>, customizationObject: CustomizationObject) => void
    updateBackgroundStyle: (
        style: Models.SelfSchedulingModalBackgroundColorStyle,
        customizationObject: CustomizationObject,
    ) => void
    updateCustomColor: (
        colorKey: Models.SelfSchedulingModalColorTypes,
        colorValue: string,
        type: CustomizationObject,
    ) => void
}

function SelfSchedulingModalCustomizationOptions(props: Props) {
    const { schedulingModal, resetColors, updateBackgroundStyle } = props

    const updateCustomColor = (colorKey: Models.SelfSchedulingModalColorTypes, colorValue: string) => {
        props.updateCustomColor(colorKey, colorValue, 'schedulingModal')
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__left`}>
                <SelfSchedulingModalBackgroundColorStyle
                    style={schedulingModal?.backgroundColorStyle}
                    updateStyle={updateBackgroundStyle}
                />
                <SelfSchedulingModalColors
                    colors={schedulingModal?.colors}
                    updateCustomColor={updateCustomColor}
                    resetColors={resetColors}
                    style={schedulingModal?.backgroundColorStyle}
                />
            </div>
        </div>
    )
}

export default SelfSchedulingModalCustomizationOptions
