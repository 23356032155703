import * as React from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import iassign from 'immutable-assign'
import _ from 'lodash'
import moment from 'moment'

import Api from '../../../Api'
import { MicroMD, Vision } from '../../../models/enums'
import CopyText from '../../shared/CopyText'

import './UnboundAgents.sass'

export type UnboundAgent = {
    name?: string
    id: string
    practiceId: string | null
    locationId: string | null
    createdAt: string
    updatedAt: string
    sendData: boolean
    futureDays: number
}

export type UnboundAgentsProps = {
    practice: Models.Practice
    onSave: (agentId: string, updates: Api.PracticeAgentUpdate) => Promise<void>
    onClose: () => void
}
export type UnboundAgentsState = {
    agents?: Array<UnboundAgent>
}

type Props = UnboundAgentsProps

class UnboundAgents extends React.Component<Props, UnboundAgentsState> {
    static mapAgent(agent: Api.PracticeAgent): UnboundAgent {
        const isDefaultState = agent.sendData === false && agent.futureDays === 0

        return {
            id: agent.id,
            name: agent.name,
            practiceId: agent.practiceId || null,
            locationId: agent.locationId || null,
            sendData: isDefaultState ? true : agent.sendData,
            futureDays: isDefaultState ? 14 : agent.futureDays,
            createdAt: agent.createdAt,
            updatedAt: agent.updatedAt,
        }
    }

    constructor(props: Props) {
        super(props)
        this.state = {}
    }

    async UNSAFE_componentWillMount() {
        const agents = await Api.Practices.listUnboundPracticeAgents()

        if (agents) {
            const sortedAgents = _.sortBy(agents.map(UnboundAgents.mapAgent), a => a.createdAt).reverse()
            this.setState({ agents: sortedAgents })
        }
    }

    onChangeValue(agentId: string, modifier: (agent: UnboundAgent) => void) {
        this.setState(
            iassign(
                this.state,
                next => next.agents,
                agents => {
                    if (!agents) {
                        return
                    }
                    const i = agents.findIndex(a => a.id === agentId)
                    if (i === -1) {
                        return
                    }
                    modifier(agents[i])
                    return agents
                },
            ),
        )
    }

    onBindAgent = (agentId: string) => {
        const selectedAgent = this.state.agents && this.state.agents.find(agent => agent.id === agentId)
        if (!selectedAgent) {
            return
        }
        if (window.confirm(`Are you sure you want to bind and update '${selectedAgent.name}'`)) {
            const updates = {
                id: selectedAgent.id,
                locationId: selectedAgent.locationId,
                sendData: selectedAgent.sendData,
                practiceId: this.props.practice.id,
                futureDays: selectedAgent.futureDays,
            }
            this.props.onSave(agentId, updates).then(this.props.onClose)
        }
    }

    render() {
        const { practice } = this.props
        const { agents } = this.state
        if (!agents) {
            return <div>Loading...</div>
        }
        return (
            <div>
                <div className="close-modal-button" onClick={this.props.onClose}>
                    <i className="material-icons">close</i>
                </div>
                <div className="modal-header">
                    <h2 className="header">All Unbound Agents</h2>
                </div>
                <Table className="table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="header id">id</TableCell>
                            <TableCell className="header name">name</TableCell>
                            <TableCell className="header">created</TableCell>
                            <TableCell className="header">updated</TableCell>
                            <TableCell className="header location">location</TableCell>
                            <TableCell className="header data">data</TableCell>
                            <TableCell className="header">days</TableCell>
                            <TableCell className="header" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {agents.map(agent => {
                            return (
                                <TableRow key={agent.id} className="agent">
                                    <TableCell className="cell id">
                                        <div>
                                            <CopyText tooltip={true} text={agent.id} />
                                        </div>
                                    </TableCell>
                                    <TableCell className="cell name">{agent.name}</TableCell>
                                    <TableCell className="cell">{moment(agent.createdAt).format('L')}</TableCell>
                                    <TableCell className="cell">{moment(agent.updatedAt).format('L')}</TableCell>
                                    <TableCell className="cell location">
                                        <Select
                                            fullWidth={true}
                                            value={agent.locationId || ''}
                                            onChange={e =>
                                                this.onChangeValue(
                                                    agent.id,
                                                    a => (a.locationId = e.target.value as string),
                                                )
                                            }
                                            disabled={
                                                practice.integrationType.name === MicroMD.name ||
                                                practice.integrationType.name === Vision.name
                                            }
                                            variant="standard"
                                        >
                                            {_.sortBy(practice.locations, l => l.name.toLowerCase()).map(location => (
                                                <MenuItem key={location.id} value={location.id}>
                                                    {location.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </TableCell>
                                    <TableCell className="cell">
                                        <div className="data">
                                            <Switch
                                                color="primary"
                                                checked={agent.sendData}
                                                onChange={e =>
                                                    this.onChangeValue(agent.id, a => (a.sendData = e.target.checked))
                                                }
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell className="cell">
                                        <div className="future-days">
                                            <TextField
                                                className="input"
                                                type="number"
                                                value={agent.futureDays}
                                                onChange={e =>
                                                    this.onChangeValue(agent.id, a => {
                                                        const days = parseInt(e.target.value, 10)
                                                        if (days < 0) {
                                                            return a
                                                        }
                                                        return (a.futureDays = days)
                                                    })
                                                }
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => this.onBindAgent(agent.id)}>Select</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        )
    }
}

export default UnboundAgents
