import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon, Modal } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { cloneDeep, isEqualWith, omit } from 'lodash'

import {
    LocationInformation,
    PracticeSurvey,
    SurveySection,
    SurveySectionQuestion,
} from '../../../../models/PracticeSurvey'
import { useAsync } from '../../../../util/useAsync'
import ErrorMessage from '../../../shared/error-message/error-message'
import ErrorMessageDetailed from '../../../shared/error-message/error-message-detailed'
import SuccessMessage from '../../../shared/success-message/success-message'

import { clearQuestionValues, isQuestionVisible } from './survey-rendered/utils'
import * as actions from './actions'
import SurveyEdit from './SurveyEdit'
import SurveyView from './SurveyView'
import { fetchSurveyActivityLogs } from './v2actions'

import './LocationSurveyModal.sass'

interface Props {
    practice: Models.Practice
    location: Models.PracticeLocation
    onClose: () => void
}

const EditSurvey = ({ location, practice, onClose }: Props) => {
    const [isEditing, setEditing] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<string>('')
    const dispatch = useDispatch()

    const { run: runDoctors, status: statusDoctors } = useAsync()
    const { run: runHours, status: statusHours } = useAsync()
    const { run: runLocationInformation, status: statusLocationInformation } = useAsync()
    const { run: runSurveys, status: statusSurveys } = useAsync()
    const { run: runSurveyActivityLogs } = useAsync()
    const { run: runSaving, status: statusSaving } = useAsync()

    const {
        locationSurvey,
        locationInformation,
    }: { locationSurvey: PracticeSurvey; locationInformation: LocationInformation } = useSelector((state: any) => ({
        locationSurvey: state.practiceSurvey?.[location.id],
        locationInformation: state.practiceSurvey[location.id]?.locationInformation ?? {},
    }))

    const [surveyCopy, setSurveyCopy] = useState<null | PracticeSurvey>(null)

    const isUSLocation = Boolean(locationInformation.address?.country_code_iso_alpha_2.value === 'US')

    const locationSurveyState = locationSurvey?.state

    const modalTopMessagesRef = useRef<HTMLDivElement>(null)

    const hasInvalidSections = () => {
        let isInvalid = false

        if (locationSurvey?.errorMessages) {
            Object.keys(locationSurvey?.errorMessages).forEach(section => {
                if (locationSurvey?.errorMessages[section].length > 0) {
                    isInvalid = true
                }
            })
        }

        return isInvalid
    }

    const isPending = [statusSaving, statusDoctors, statusHours, statusLocationInformation, statusSurveys].includes(
        'pending',
    )

    useEffect(() => {
        if (!surveyCopy && isEditing && !isPending) {
            setSurveyCopy(cloneDeep(locationSurvey))
        }
    }, [isEditing, isPending, locationSurvey, surveyCopy])

    const emptyInvisibleQuestionsValues = (locationSurvey: PracticeSurvey) => {
        locationSurvey.surveys.forEach(section => {
            section.questions.forEach(question => {
                if (!isQuestionVisible(locationSurvey.surveys, question, isUSLocation)) {
                    dispatch(actions.updateSurveyQuestion(location.id, section, clearQuestionValues(question)))
                }
            })
        })
    }

    useEffect(() => {
        if (location.survey?.id) {
            dispatch(actions.setLocationState(location.id, 'pending'))
            Promise.all([
                runDoctors(dispatch(actions.fetchLocationSurveyDoctors(location.id))),
                runLocationInformation(
                    dispatch(actions.fetchLocationSurveyLocationInformation(location, location.addressId)),
                ),
                runHours(dispatch(actions.fetchLocationSurveyHours(location.id))),
                runSurveys(dispatch(actions.fetchLocationSurveys(location.id, location.survey.id))),
                runSurveyActivityLogs(dispatch(fetchSurveyActivityLogs(location.survey.id, { page: 1 }))),
            ])
                .then(() => {
                    dispatch(actions.setLocationState(location.id, 'success'))
                })
                .catch(() => {
                    dispatch(actions.setLocationState(location.id, 'error'))
                })
        }
    }, [runDoctors, runHours, runLocationInformation, runSurveys, runSurveyActivityLogs, dispatch, location])

    useEffect(() => {
        if (statusSaving === 'resolved') {
            dispatch(actions.setEditing(location.id, false))
            setSuccessMessage('Updates have been saved.')
        }
        if (statusSaving === 'rejected') {
            setErrorMessage('There are errors while saving updates.')
        }
    }, [statusSaving, dispatch, location.id])

    useEffect(() => {
        if (statusSaving === 'resolved' && locationSurveyState === 'submitted') {
            modalTopMessagesRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            })
        }
    }, [statusSaving, locationSurveyState])

    const clearEmptyAdditionalFields = async () => {
        await dispatch(
            actions.updateDoctorsSection(
                location.id,
                locationSurvey.doctors.list.filter(
                    doc => Boolean(doc.firstName.value) || Boolean(doc.lastName.value) || Boolean(doc.suffix.value),
                ),
            ),
        )
        locationSurvey.surveys.forEach((section: SurveySection) => {
            section.questions.forEach((question: SurveySectionQuestion) => {
                if (
                    question.question_type === 'input_field_multi_values' &&
                    Array.isArray(question.answer_schema?.value) &&
                    question.answer_schema &&
                    question.answer_schema?.value?.length > 1 &&
                    question.answer_schema?.value?.indexOf('') > -1
                ) {
                    const nextQuestion: SurveySectionQuestion = { ...question }
                    if (nextQuestion.answer_schema) {
                        nextQuestion.answer_schema.value = question.answer_schema.value.filter(q => q !== '')
                        dispatch(actions.updateSurveyQuestion(location.id, section, nextQuestion))
                    }
                }
            })
        })
    }

    const handleSave = async (event: React.MouseEvent) => {
        setSuccessMessage('')
        setErrorMessage('')
        emptyInvisibleQuestionsValues(locationSurvey)
        await clearEmptyAdditionalFields()
        runSaving(dispatch(actions.saveLocationUpdates(practice, location, locationSurvey)))

        event.stopPropagation()
    }

    const handleClose = () => {
        onClose()
    }

    const onEditInfo = () => {
        setEditing(true)
        dispatch(actions.clearSurveyErrors(location.id))
    }

    const areSurveysValid = locationSurvey?.surveys.every(survey =>
        survey.questions.every(question => question.isValid),
    )
    const isValid =
        areSurveysValid &&
        ['doctors', 'operatingHours', 'locationInformation'].every(section => locationSurvey?.[section].isValid)

    const isEqual = () => {
        if (!surveyCopy) return true
        const survey = omit(surveyCopy, ['errorMessages', 'state'])
        const originalSurvey = omit(locationSurvey, ['errorMessages', 'state'])

        return isEqualWith(survey, originalSurvey)
    }

    const isUpdated = locationSurvey?.state === 'updated'
    const isDisabled = isPending || !isUpdated || isEqual() // TODO: should be disabled if it is invalid once submitted valid
    const isLoading = !locationSurvey?.state || locationSurvey?.state === 'pending' || locationSurvey?.state === 'none'

    return (
        <Modal
            open={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleClose()
                }
            }}
            disableEscapeKeyDown={true}
        >
            <div className="survey-modal">
                <div className="survey-modal__header">
                    <div className="survey-modal__title">
                        <span className="survey-modal__back" onClick={handleClose}>
                            <Icon>arrow_back</Icon>
                        </span>
                        {location.name}
                        {!isEditing && (
                            <Button
                                variant="outlined"
                                size="large"
                                className="survey-modal__edit-button"
                                onClick={() => onEditInfo()}
                            >
                                Edit Info <Icon className="survey-modal__edit-icon">create</Icon>
                            </Button>
                        )}
                    </div>
                    {isEditing && (
                        <div className="survey-modal__actions">
                            <Fragment>
                                <Button variant="outlined" size="large" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    className="survey-modal__save"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={isDisabled}
                                    onClick={handleSave}
                                >
                                    Save updates <Icon className="survey-modal__edit-icon">create</Icon>
                                </Button>
                            </Fragment>
                        </div>
                    )}
                </div>
                <div className={`survey-modal__body survey-modal__body--${isValid ? 'valid' : 'invalid'}`}>
                    {isLoading && (
                        <div className={`survey-modal__loading-overlay`}>
                            <CircularProgress size={100} className={'survey__await-spinner survey-modal__loading'} />
                        </div>
                    )}
                    {locationSurvey?.state === 'error' && (
                        <ErrorMessage>Error loading location survey data</ErrorMessage>
                    )}
                    {isEditing && errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                    <div ref={modalTopMessagesRef}>
                        {hasInvalidSections() && (
                            <ErrorMessageDetailed
                                title={'Some required fields are incorrect or missing information'}
                                description={
                                    'Please review highlighted fields below and complete the required information with the required format.'
                                }
                                errorList={locationSurvey.errorMessages}
                            />
                        )}
                        {isEditing && successMessage && (
                            <SuccessMessage isShown={Boolean(successMessage)}>{successMessage}</SuccessMessage>
                        )}
                    </div>
                    {isEditing && <SurveyEdit location={location} />}
                    {!isEditing && <SurveyView location={location} />}
                </div>
            </div>
        </Modal>
    )
}

export default EditSurvey
