import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Modal, TableCell, TableRow } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { RootState } from '../../appReducer'
import { AccountTypes } from '../../models/enums'
import { useAppDispatch } from '../../util/useAppDispatch'
import CopyText from '../shared/CopyText'

import { saveAdmin, sendPasswordReset, unlockAccount } from './actions'
import ManageAdmin from './ManageAdmin'

import './AdminItem.sass'

type Props = {
    admin: Models.Account
}

const AdminItem = (props: Props) => {
    const { admin } = props
    const [showModal, setShowModal] = useState(false)
    const dispatch = useAppDispatch()

    const adminName = `${admin.lastName} ${admin.firstName}`.trim()

    const permissions = useSelector((state: RootState) => state.admin.permissions)

    useEffect(() => {
        if (showModal) {
            document.body.style.overflowY = 'hidden'
            return
        }
        document.body.style.overflowY = 'initial'
    }, [showModal])

    const onShowModal = () => {
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)
    }

    return (
        <TableRow className={classNames('admin-list-row')} title={admin.id} key={admin.id}>
            {showModal && (
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents manage-admin-modal">
                        <div className="close-modal-button" onClick={onCloseModal}>
                            <i className="material-icons">close</i>
                        </div>
                        <ManageAdmin
                            admin={admin}
                            permissionList={permissions}
                            submitButtonText="Update"
                            onClose={onCloseModal}
                            onSave={(changes: Api.AccountUpdate) => {
                                dispatch(saveAdmin(admin, changes)).then(() => {
                                    onCloseModal()
                                })
                            }}
                            onSendPasswordReset={() => dispatch(sendPasswordReset(admin))}
                            onUnlockAccount={() => dispatch(unlockAccount(admin))}
                        />
                    </div>
                </Modal>
            )}

            <TableCell className="cell name">{adminName}</TableCell>
            <TableCell className="cell username">
                &nbsp;
                <CopyText text={admin.username}>{admin.username}</CopyText>
            </TableCell>
            <TableCell className="cell type">{admin.type.displayName.replace('staff', 'Staff')}</TableCell>
            <TableCell className="cell permissions" title={`${admin.type.displayName} (${admin.type.id})`}>
                {_.sortBy(admin.permissions, ['displayName']).map(p => (
                    <div className="permission" key={p.type}>
                        {p.displayName}
                    </div>
                ))}
            </TableCell>
            <TableCell className="cell created">{moment(admin.created).format('MM/DD/YYYY')}</TableCell>
            <TableCell className="cell created">{admin.locked ? 'Locked' : admin.accountStatus?.display}</TableCell>
            <TableCell className="cell manage-admin">
                {admin.type.id !== AccountTypes.SuperAdmin && <Button onClick={onShowModal}>Manage</Button>}
            </TableCell>
        </TableRow>
    )
}

export default AdminItem
