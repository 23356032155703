import * as React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { AccessChatCenter, AccessHeartbeatConfiguration, ViewDirectSchedulingLogs } from './models/enums'
import AdminList from './modules/admin/AdminList'
import AdminShortcutList from './modules/admin/AdminShortcutList'
import ChatCenter from './modules/chat-center/ChatCenter'
import PubNubEventManagerContainer from './modules/chat-center/PubNubEventManagerContainer'
import PracticeDiagnosticsContainer from './modules/diagnostics/PracticeDiagnosticsContainer'
import Availability from './modules/direct-scheduling/availability/Availability'
import CancelRescheduleSettings from './modules/direct-scheduling/cancel-reschedule-settings/CancelRescheduleSettings'
import ConfigLogs from './modules/direct-scheduling/config-logs/ConfigLogs'
import HeartbeatMenu from './modules/heartbeat/Heartbeat'
import Login from './modules/login/Login'
import PasswordReset from './modules/login/PasswordReset'
import NavToolbar from './modules/navigation/NavToolbar'
import NotificationAlerts from './modules/notifications/Notifications'
import PracticeListContainer from './modules/practices/PracticeListContainer'
import ReferralsDashboard from './modules/referrals/ReferralsDashboard'
import { setRedirect } from './appActions'
import { AppState, RootState, Self } from './appReducer'
import SessionManager from './SessionManager'
import Theme from './Theme'

import './App.sass'

interface AuthorizedRouteProps extends RouteProps {}

interface AccessLockedRouteProps extends RouteProps {
    self: Self | undefined
    permission: Models.Permission
}

const cache = createCache({
    key: 'css',
    prepend: true,
})

function AuthorizedRoute({ ...rest }: AuthorizedRouteProps) {
    const isLoggedIn = SessionManager.isLoggedIn
    if (isLoggedIn) {
        return <Route {...rest} />
    }
    return (
        <Redirect
            push={true}
            to={{
                pathname: '/login',
                state: { from: rest.location },
            }}
        />
    )
}

function AccessLockedRoute({ self, permission, ...rest }: AccessLockedRouteProps) {
    if (self != null && self.account.hasAccess(permission)) {
        return <Route {...rest} />
    }
    return <div />
}

class Redirector extends React.PureComponent<AppState & { setRedirect: typeof setRedirect }> {
    componentDidMount() {
        this.props.setRedirect('')
    }

    render() {
        return (
            <div>
                <Redirect push={true} to={this.props.redirectTo || '/'} />
            </div>
        )
    }
}

const AppRedirector = connect((state: RootState) => state.app, { setRedirect })(Redirector)

class AppRoot extends React.PureComponent<AppState> {
    render() {
        return (
            <CacheProvider value={cache}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={Theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Router>
                                <div>
                                    <NotificationAlerts />

                                    {this.props.redirectTo && <AppRedirector />}

                                    {this.props.self && <Route component={NavToolbar} />}

                                    <AccessLockedRoute
                                        component={PubNubEventManagerContainer}
                                        self={this.props.self}
                                        permission={AccessChatCenter}
                                    />

                                    <div className="app-root">
                                        <Switch>
                                            <AuthorizedRoute path="/practices" component={PracticeListContainer} />
                                            <AccessLockedRoute
                                                path="/chat-center"
                                                component={ChatCenter}
                                                self={this.props.self}
                                                permission={AccessChatCenter}
                                            />
                                            <AccessLockedRoute
                                                path="/heartbeat"
                                                component={HeartbeatMenu}
                                                self={this.props.self}
                                                permission={AccessHeartbeatConfiguration}
                                            />
                                            <AuthorizedRoute
                                                path="/diagnostics/:practiceId"
                                                component={PracticeDiagnosticsContainer}
                                            />
                                            <AuthorizedRoute path="/admins/shortcuts" component={AdminShortcutList} />
                                            <AuthorizedRoute path="/admins" component={AdminList} />
                                            <AuthorizedRoute path="/referrals" component={ReferralsDashboard} />
                                            <AuthorizedRoute
                                                path="/availability/:practiceId"
                                                component={Availability}
                                            />
                                            <AccessLockedRoute
                                                path="/config-logs/:practiceId"
                                                component={ConfigLogs}
                                                self={this.props.self}
                                                permission={ViewDirectSchedulingLogs}
                                            />
                                            <AccessLockedRoute
                                                path="/cancel-reschedule-settings/:practiceId"
                                                component={CancelRescheduleSettings}
                                                self={this.props.self}
                                                permission={ViewDirectSchedulingLogs}
                                            />
                                            <Route path="/login" component={Login} />
                                            <Route path="/reset-password/:code" component={PasswordReset} />
                                            <Redirect push={true} to="/practices" />
                                        </Switch>
                                    </div>
                                </div>
                            </Router>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CacheProvider>
        )
    }
}

const mapStateToProps = (state: RootState): AppState => state.app

const App = connect(mapStateToProps, {})(AppRoot)

export default App
