import React, { useState } from 'react'
import Grid from '@mui/material/Grid'

import ConfirmModal from '../../shared/confirm-modal/ConfirmModal'
import { formatPhoneNumberNtl } from '../../shared/phone-number-formatter/formatPhoneNumber'

import { StaffConfirmationBoxParams } from './StaffConfirmationBox'
import StaffFormNotificationEmailModal from './StaffFormNotificationEmailModal'
import StaffFormNotificationPhoneNumberModal from './StaffFormNotificationPhoneNumberModal'

import './StaffFormNotificationItem.sass'

interface StaffFormNotificationItemProps {
    label: string
    type: 'email' | 'phoneNumber'
}

export type StaffFormNotificationItemDispatch = {
    onShowConfirmationBox: (params: StaffConfirmationBoxParams) => void
    onCloseConfirmationBox: () => void
    onSaveEmail?: (newEmail: string, oldEmail?: string) => void
    onRemoveEmail?: (email: string) => void
    onSavePhoneNumber?: (phoneNumber: string) => void
    onRemovePhoneNumber?: (phoneNumber: string) => void
    validatePhoneNumber: (phoneNumber: string) => Promise<{ isValid: boolean; message: string }>
}

export type StaffFormNotificationItemState = {
    showNotificationEmailModal: boolean
    showNotificationPhoneNumberModal: boolean
    showRemovePhoneNumberModal: boolean
}

type Props = StaffFormNotificationItemProps & StaffFormNotificationItemDispatch

const StaffFormNotificationEmail = ({
    type,
    label,
    onSaveEmail,
    onRemoveEmail,
    onSavePhoneNumber,
    onRemovePhoneNumber,
    validatePhoneNumber,
}: Props) => {
    const [showNotificationEmailModal, setShowNotificationEmailModal] = useState(false)
    const [showNotificationPhoneNumberModal, setShowNotificationPhoneNumberModal] = useState(false)
    const [showRemovePhoneNumberModal, setShowRemovePhoneNumberModal] = useState(false)

    const onClose = () => {
        if (type === 'email') {
            setShowNotificationEmailModal(false)
        }
        if (type === 'phoneNumber') {
            setShowNotificationPhoneNumberModal(false)
            setShowRemovePhoneNumberModal(false)
        }
    }

    const onShowNotificationModal = () => {
        if (type === 'email') {
            setShowNotificationEmailModal(true)
        }
        if (type === 'phoneNumber') {
            setShowNotificationPhoneNumberModal(true)
        }
    }

    const onDelete = () => {
        if (type === 'email' && onRemoveEmail) {
            onRemoveEmail(label)
        }

        if (type === 'phoneNumber') {
            setShowRemovePhoneNumberModal(true)
        }
    }

    const onConfirmDelete = () => {
        onRemovePhoneNumber && onRemovePhoneNumber(label)
        onClose()
    }

    return (
        <div>
            {showNotificationEmailModal && onSaveEmail && onRemoveEmail && (
                <StaffFormNotificationEmailModal
                    onClose={onClose}
                    value={label}
                    onSaveEmail={onSaveEmail}
                    onRemoveEmail={onRemoveEmail}
                />
            )}
            {showNotificationPhoneNumberModal && onSavePhoneNumber && (
                <StaffFormNotificationPhoneNumberModal
                    onClose={onClose}
                    value={label}
                    onSavePhoneNumber={onSavePhoneNumber}
                    validatePhoneNumber={validatePhoneNumber}
                />
            )}

            <ConfirmModal
                title={`Are you sure you want to delete mobile phone 
                "${formatPhoneNumberNtl(label)}"?`}
                subtitle=""
                discardText="Cancel"
                confirmText="Delete #"
                open={showRemovePhoneNumberModal}
                onClose={onClose}
                onConfirm={onConfirmDelete}
                onDiscard={onClose}
            />

            <Grid
                container={true}
                className="staff-form-notification-item"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item={true} className="label" sm={6}>
                    {type === 'phoneNumber' ? formatPhoneNumberNtl(label) : label}
                </Grid>
                <Grid item={true} sm={6}>
                    <Grid container={true} spacing={2} justifyContent="flex-end">
                        {type === 'email' && (
                            <Grid item={true} className="button" onClick={onShowNotificationModal}>
                                <i className="material-icons">edit</i>
                            </Grid>
                        )}

                        <Grid item={true} className="button" onClick={onDelete}>
                            <i className="material-icons">delete</i>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default StaffFormNotificationEmail
