import React, { Fragment } from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { TableCellClassKey } from '@mui/material/TableCell'
import { TableHeadClassKey } from '@mui/material/TableHead'

import './EnhancedTableHead.sass'

export type ColumnLabel = {
    id: string
    label: string
    sortable?: boolean
}

type Props = {
    order: 'asc' | 'desc'
    orderBy: string
    onRequestSort: (property: string) => void
    columnLabels: ColumnLabel[]
}

type TableHeadClassOverrides = {
    readonly [key in TableHeadClassKey]?: string
}

type TableRowClassOverrides = {
    readonly [key in TableCellClassKey]?: string
}

type TableCellClassOverrides = {
    readonly [key in TableCellClassKey]?: string
}

const moduleName = 'enhanced-table-head'

function EnhancedTableHead(props: Props) {
    const { order, orderBy, onRequestSort, columnLabels } = props

    const tableHeadClassOverrides: TableHeadClassOverrides = {
        root: `${moduleName}`,
    }

    const tableRowClassOverrides: TableRowClassOverrides = {
        head: `${moduleName}__table-head`,
    }

    const tableCellClassOverrides: TableCellClassOverrides = {
        root: `${moduleName}__table-cell`,
    }

    const createSortHandler = (property: any) => () => {
        onRequestSort(property)
    }

    return (
        <TableHead classes={tableHeadClassOverrides}>
            <TableRow classes={tableRowClassOverrides}>
                {columnLabels.map(({ id, label, sortable = false }) => (
                    <TableCell
                        classes={tableCellClassOverrides}
                        key={id}
                        align={'left'}
                        sortDirection={orderBy === id ? order : false}
                    >
                        {sortable ? (
                            <TableSortLabel active={orderBy === id} direction={order} onClick={createSortHandler(id)}>
                                {label}
                            </TableSortLabel>
                        ) : (
                            <Fragment>{label}</Fragment>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead
