import React, { useEffect, useState } from 'react'
import moment from 'moment'

import TimePicker from './TimePicker'
import TimePickerInput from './TimePickerInput'

import './CustomTimePicker.sass'

export const formatTime = (time: moment.Moment | undefined, format: string) => {
    if (time === undefined) return ''
    return moment(time).format(format)
}

export const TimePickerDefaults = {
    timeFormat: 'h:mm A',
}

interface TimePickerProps {
    timeFormat?: string
    initialTime?: moment.Moment | string
    value?: moment.Moment | string
    label?: string
    error?: boolean
    errorMessage?: string
    timezone?: string
    className?: string
    placeholder?: string
    onChange: (rawValue: string) => void
    onOpen: () => void
    onCancel: () => void
}

export type Props = TimePickerProps

const CustomTimePicker = (props: Props) => {
    const { value, timeFormat, label, className, error, errorMessage, timezone, onChange, onCancel, onOpen } = props
    const selectedTimeFormat = timeFormat || TimePickerDefaults.timeFormat

    const [time, setTime] = useState<moment.Moment>(moment(value, selectedTimeFormat))
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const placeholder = props.placeholder ?? ''

    useEffect(() => {
        setTime(moment(value, selectedTimeFormat))
    }, [value, selectedTimeFormat])

    const handleTimeChange = (selectedTime: moment.Moment) => {
        const newInputValue = selectedTime.format(selectedTimeFormat)
        setTime(selectedTime)
        onChange(newInputValue)
    }

    const handleIsOpen = (open: boolean) => {
        if (open) {
            onOpen()
        }
        setIsOpen(open)
    }

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value)
    }

    const renderInputComponent = (): JSX.Element => {
        const inputValue = value ? time.format(TimePickerDefaults.timeFormat) : placeholder
        const inputProps = {
            selected: isOpen,
            onChange: handleInputChange,
            value: inputValue,
            error,
            errorMessage,
            timezone,
        }
        return <TimePickerInput {...inputProps} />
    }

    return (
        <TimePicker
            renderTrigger={renderInputComponent}
            handleTimeChange={handleTimeChange}
            handleCancel={onCancel}
            className={className}
            label={label}
            error={error}
            setOpen={handleIsOpen}
            time={time}
        />
    )
}

export default CustomTimePicker
