import ApiService from '../service'

export default class DexisService extends ApiService {
    readonly ModulePath: string = 'api/voice/dexis/v1/admin'

    async list(practice: Models.Practice): Promise<Array<Api.DexisAgent>> {
        const { data: agents } = await this.fetch(`practices/${practice.id}/agents`)
        return agents
    }

    async listCommands(practice: Models.Practice, start: Date, end: Date): Promise<Array<Api.DexisAgentCommand>> {
        const { data: commands } = await this.fetch(
            `practices/${practice.id}/agents/commands`,
            {},
            {
                start_time: start.toISOString(),
                end_time: end.toISOString(),
            },
        )
        return commands
    }

    async listErrors(practice: Models.Practice, start: Date, end: Date): Promise<Array<Api.DexisAgentError>> {
        const { data: errors } = await this.fetch(
            `practices/${practice.id}/agents/errors`,
            {},
            {
                start_time: start.toISOString(),
                end_time: end.toISOString(),
            },
        )
        return errors
    }

    async listLicenseAgreements(practice: Models.Practice): Promise<Array<Api.DexisLicense>> {
        const { data: licenses } = await this.fetch(`practices/${practice.id}/licenses`)
        return licenses
    }
}
