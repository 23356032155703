import ApiV2 from '../../../ApiV2'
import { AppDispatch, AppThunk } from '../../../appStore'

export enum PracticePatientsActionTypes {
    GET_PRACTICE_PATIENTS_FINANCIAL_DATA_REQUEST = '@V2/GET_PRACTICE_PATIENTS_FINANCIAL_DATA_REQUEST',
    GET_PRACTICE_PATIENTS_FINANCIAL_DATA_SUCCESS = '@V2/GET_PRACTICE_PATIENTS_FINANCIAL_DATA_SUCCESS',
    GET_PATIENT_FINANCIAL_DATA_REQUEST = '@V2/GET_PATIENT_FINANCIAL_DATA_REQUEST',
    GET_PATIENT_FINANCIAL_DATA_SUCCESS = '@V2/GET_PATIENT_FINANCIAL_DATA_SUCCESS',
    PATIENT_FINANCIAL_DATA_SELECT_PATIENT = '@V2/PATIENT_FINANCIAL_DATA_SELECT_PATIENT',
    SEARCH_PRACTICE_PATIENTS_REQUEST = '@V2/SEARCH_PRACTICE_PATIENTS_REQUEST',
    SEARCH_PRACTICE_PATIENTS_SUCCESS = '@V2/SEARCH_PRACTICE_PATIENTS_SUCCESS',
    RESET_SEARCH_PRACTICE_PATIENTS = '@V2/RESET_SEARCH_PRACTICE_PATIENTS',
}

export type PracticePatientsFinancialDataRequest = {
    type: PracticePatientsActionTypes.GET_PRACTICE_PATIENTS_FINANCIAL_DATA_REQUEST
    practiceId: string
}

export type PracticePatientsFinancialDataSuccess = {
    type: PracticePatientsActionTypes.GET_PRACTICE_PATIENTS_FINANCIAL_DATA_SUCCESS
    practiceId: string
    practiceProductionValue: ModelsV2.Practice.PracticePatientsFinancialData
}

export type PatientFinancialDataRequest = {
    type: PracticePatientsActionTypes.GET_PATIENT_FINANCIAL_DATA_REQUEST
    practiceId: string
}

export type PatientFinancialDataSuccess = {
    type: PracticePatientsActionTypes.GET_PATIENT_FINANCIAL_DATA_SUCCESS
    practiceId: string
    patientFinancialData: ModelsV2.Referrals.PatientsFinancialData
}

export type PatientFinancialDataSelectPatient = {
    type: PracticePatientsActionTypes.PATIENT_FINANCIAL_DATA_SELECT_PATIENT
    practiceId: string
    patient: any
}

export type SearchPracticePatientsRequest = {
    type: PracticePatientsActionTypes.SEARCH_PRACTICE_PATIENTS_REQUEST
    practiceId: string
    searchTerms: ModelsV2.Practice.PracticePatientSearchTerms
}

export type SearchPracticePatientsSuccess = {
    type: PracticePatientsActionTypes.SEARCH_PRACTICE_PATIENTS_SUCCESS
    practiceId: string
    patients: ModelsV2.Practice.PracticePatientData[]
    pagination: Models.PaginationInfo | undefined
}

export type ResetSearchPracticePatients = {
    type: PracticePatientsActionTypes.RESET_SEARCH_PRACTICE_PATIENTS
    practiceId: string
}

export function practicePatientsFinancialDataRequest(practiceId: string): PracticePatientsFinancialDataRequest {
    return {
        type: PracticePatientsActionTypes.GET_PRACTICE_PATIENTS_FINANCIAL_DATA_REQUEST,
        practiceId,
    }
}

export function practicePatientsFinancialDataSuccess(
    practiceProductionValue: ModelsV2.Practice.PracticePatientsFinancialData,
    practiceId: string,
): PracticePatientsFinancialDataSuccess {
    return {
        type: PracticePatientsActionTypes.GET_PRACTICE_PATIENTS_FINANCIAL_DATA_SUCCESS,
        practiceId,
        practiceProductionValue,
    }
}

export function getPracticePatientsFinancialData(
    practiceId: string,
): AppThunk<Promise<ModelsV2.Practice.PracticePatientsFinancialData>> {
    return async (dispatch: AppDispatch) => {
        dispatch(practicePatientsFinancialDataRequest(practiceId))
        const data = await ApiV2.Practice.getPracticePatientsFinancialData(practiceId)
        await dispatch(practicePatientsFinancialDataSuccess(data, practiceId))
        return data
    }
}

export function patientFinancialDataRequest(practiceId: string): PatientFinancialDataRequest {
    return {
        type: PracticePatientsActionTypes.GET_PATIENT_FINANCIAL_DATA_REQUEST,
        practiceId,
    }
}

export function patientFinancialDataSelectPatient(practiceId: string, patient: any): PatientFinancialDataSelectPatient {
    return {
        type: PracticePatientsActionTypes.PATIENT_FINANCIAL_DATA_SELECT_PATIENT,
        practiceId,
        patient,
    }
}

export function patientFinancialDataSuccess(
    practiceId: string,
    patientFinancialData: ModelsV2.Referrals.PatientsFinancialData,
): PatientFinancialDataSuccess {
    return {
        type: PracticePatientsActionTypes.GET_PATIENT_FINANCIAL_DATA_SUCCESS,
        practiceId,
        patientFinancialData,
    }
}

export function getPatientFinancialData(
    practiceId: string,
    referralPatientId: string,
): AppThunk<Promise<ModelsV2.Referrals.PatientsFinancialData | null>> {
    return async (dispatch: AppDispatch) => {
        dispatch(patientFinancialDataRequest(practiceId))
        const data = await ApiV2.Referrals.getReferralFinancialData(referralPatientId)
        await dispatch(patientFinancialDataSuccess(practiceId, data))
        return data
    }
}

export function searchPracticePatientsRequest(
    practiceId: string,
    searchTerms: ModelsV2.Practice.PracticePatientSearchTerms,
): SearchPracticePatientsRequest {
    return {
        type: PracticePatientsActionTypes.SEARCH_PRACTICE_PATIENTS_REQUEST,
        practiceId,
        searchTerms,
    }
}

export function searchPracticePatientsSuccess(
    practiceId: string,
    patients: ModelsV2.Practice.PracticePatientData[],
    pagination: Models.PaginationInfo | undefined,
): SearchPracticePatientsSuccess {
    return {
        type: PracticePatientsActionTypes.SEARCH_PRACTICE_PATIENTS_SUCCESS,
        practiceId,
        patients,
        pagination,
    }
}

export function resetSearchPracticePatients(practiceId: string): ResetSearchPracticePatients {
    return {
        type: PracticePatientsActionTypes.RESET_SEARCH_PRACTICE_PATIENTS,
        practiceId,
    }
}

export function searchPracticePatients(
    practiceId: string,
    searchTerms: ModelsV2.Practice.PracticePatientSearchTerms,
): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        dispatch(searchPracticePatientsRequest(practiceId, searchTerms))
        const { data: patients, paginationInfo } = await ApiV2.Practice.searchPracticePatients(practiceId, searchTerms)
        await dispatch(searchPracticePatientsSuccess(practiceId, patients, paginationInfo))
        return patients
    }
}
