import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, Icon } from '@mui/material'
import { CheckboxClassKey } from '@mui/material/Checkbox'
import { FormControlLabelClassKey } from '@mui/material/FormControlLabel'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { union } from 'lodash'

import { sortLocationByNameAndMoveDefaultLocationToTop } from '../../../modules/shared/sortUtils'
import { extendMuiTheme } from '../../../modules/shared/styles/utils'
import { CheckboxTheme } from '../../../Theme'
import { textBlue } from '../../shared/styles/colors'

import './LocationsFlatList.sass'

export type Props = {
    onSelectElement: (values: string[]) => void
    practiceId: string
    selectedItems: string[]
}

const moduleName = 'locations-list'
const itemModuleName = 'web-code-location'
const DISABLED_PROPERTY = 'disabled'

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

type CheckboxClassOverrides = {
    readonly [key in CheckboxClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    root: `${moduleName}__form-control-root`,
    label: `${moduleName}__form-control-label`,
}

const checkboxClassOverrides: CheckboxClassOverrides = {
    root: `${moduleName}__checkbox-root`,
}

const LocationsTheme = extendMuiTheme(CheckboxTheme, {
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 3px 3px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    maxWidth: 24,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: textBlue,
                },
            },
        },
    },
})

const Locations = ({ practiceId, selectedItems, onSelectElement }: Props) => {
    const { locations }: { locations: any } = useSelector((state: any) => ({
        locations: state.practices.practices[practiceId].locations ?? [],
    }))

    const [searchKeyword, setSearchKeyword] = useState<string>('')
    const [localItemsKeys, setLocalItemsKeys] = useState<string[]>(selectedItems ?? [])
    const isAll = localItemsKeys.length === locations.length && localItemsKeys.length > 0

    const filteredItems = useMemo(() => {
        if (!searchKeyword) {
            return sortLocationByNameAndMoveDefaultLocationToTop(locations)
        }
        return sortLocationByNameAndMoveDefaultLocationToTop(
            locations.filter((item: any) => item['name'].toLowerCase().includes(searchKeyword.toLowerCase())),
        )
    }, [locations, searchKeyword])

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(event.target.value)
    }

    const handleSearchClear = () => {
        setSearchKeyword('')
    }

    const updateSelectedElements = (newItemsKeys: string[]) => {
        setLocalItemsKeys(newItemsKeys)
        onSelectElement(newItemsKeys)
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemIndex = localItemsKeys.indexOf(event.target.value)

        if (itemIndex < 0) {
            const newItemsKeys = [...localItemsKeys, event.target.value]
            updateSelectedElements(newItemsKeys)
        } else {
            const newItemsKeys = localItemsKeys.filter((key, index) => index !== itemIndex)
            updateSelectedElements(newItemsKeys)
        }
    }
    const toggleAllItems = () => {
        const newItemsKeys = isAll
            ? []
            : locations.filter((item: any) => !item[DISABLED_PROPERTY]).map((item: any) => item['id'])
        updateSelectedElements(newItemsKeys)
    }

    useEffect(() => {
        if (!selectedItems?.length) {
            setLocalItemsKeys([])
            return
        }
        setLocalItemsKeys(prevSelectedItems => union(prevSelectedItems, selectedItems).filter(item => Boolean(item)))
    }, [selectedItems])

    return (
        <div className={moduleName}>
            {(searchKeyword || filteredItems.length > 8) && (
                <div className={`${moduleName}__search`}>
                    <Icon className={`${moduleName}__search-icon`}>search</Icon>
                    <input
                        className={`${moduleName}__search-field`}
                        placeholder="Search Location"
                        value={searchKeyword}
                        onChange={handleChangeSearch}
                    />
                    {searchKeyword && (
                        <span className={`${moduleName}__search-clear`} onClick={handleSearchClear}>
                            <Icon className={`${moduleName}__search-icon`}>clear</Icon>
                        </span>
                    )}
                </div>
            )}
            <div className={`${moduleName}__locations-container`}>
                <div className={`${itemModuleName}`} key={'all'}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={LocationsTheme}>
                            <FormControlLabel
                                classes={formControlLabelClassOverrides}
                                control={
                                    <Checkbox
                                        disableRipple={true}
                                        classes={checkboxClassOverrides}
                                        checked={isAll}
                                        onChange={toggleAllItems}
                                    />
                                }
                                label={''}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                    <label className={`${itemModuleName}__label`}>
                        <div className={`${itemModuleName}__text ${itemModuleName}--text-bold`}>
                            ALL PRACTICE LOCATIONS
                        </div>
                    </label>
                </div>
                {filteredItems.map((location: any) => {
                    return (
                        <div className={`${itemModuleName}`} key={location.id}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={LocationsTheme}>
                                    <FormControlLabel
                                        classes={formControlLabelClassOverrides}
                                        control={
                                            <Checkbox
                                                disableRipple={true}
                                                classes={checkboxClassOverrides}
                                                checked={localItemsKeys.indexOf(location['id']) > -1}
                                                onChange={onChange}
                                                value={location.id}
                                            />
                                        }
                                        label={''}
                                    />
                                </ThemeProvider>
                            </StyledEngineProvider>
                            <label className={`${itemModuleName}__label`}>
                                <div className={`${itemModuleName}__text`}>{location.name}</div>
                            </label>
                        </div>
                    )
                })}

                {locations && locations.length === 0 && (
                    <div className={`${moduleName}__not-found`}>
                        No practices found with the search “{searchKeyword}”
                    </div>
                )}
            </div>
        </div>
    )
}

export default Locations
