import ApiV2 from '../../ApiV2'
import { AppDispatch, AppThunk } from '../../appStore'

export enum YextActionTypes {
    RECEIVE_YEXT_LOCATIONS = '@V2/RECEIVE_YEXT_LOCATIONS',
}

export type ReceiveYextLocations = {
    type: YextActionTypes.RECEIVE_YEXT_LOCATIONS
    yextLocations: ApiV2.Yext.GetYextLocationsResponse
}

export function receiveYextLocations(yextLocations: ApiV2.Yext.GetYextLocationsResponse): ReceiveYextLocations {
    return {
        type: YextActionTypes.RECEIVE_YEXT_LOCATIONS,
        yextLocations,
    }
}

export function fetchYextLocations(): AppThunk<Promise<ApiV2.Yext.GetYextLocationsResponse | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const { data: yextLocations } = await ApiV2.Yext.getYextLocations()
            dispatch(receiveYextLocations(yextLocations))
            return yextLocations
        } catch (err) {
            throw err
        }
    }
}
