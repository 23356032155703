import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import { convertNumCentsToDollars } from '../../../util/formatCurrency'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchReferralFinancialData } from '../v2actions'

import './ReferralProductionValues.sass'

type Props = {
    patientId: string
    patientName?: string
}

const moduleName = 'referral-production-values'

const ReferralProductionValues = (props: Props) => {
    const { patientId, patientName } = props
    const dispatch = useAppDispatch()
    const financialData = useSelector((state: RootState) => state.v2.referrals.patientsFinancialData[patientId])

    useEffect(() => {
        if (patientId) {
            dispatch(fetchReferralFinancialData(patientId))
        }
    }, [dispatch, patientId])
    if (!financialData) {
        return <></>
    }
    return (
        <div className={moduleName}>
            <div className={`${moduleName}__title`}>{patientName} Production Values</div>

            <div className={`${moduleName}__table`}>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>First Visit</div>
                    <div className={`${moduleName}__cell-value`}>
                        {financialData?.first_visit_date
                            ? `${moment(financialData?.first_visit_date).format('MM/DD/YYYY')}`
                            : '...'}
                    </div>
                    <div className={`${moduleName}__cell-title`}>Last Visit</div>
                    <div className={`${moduleName}__cell-value-bottom`}>
                        {financialData?.last_visit_date
                            ? `${moment(financialData?.last_visit_date).format('MM/DD/YYYY')}`
                            : '...'}
                    </div>
                </div>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>First Visit Value</div>
                    <div className={`${moduleName}__cell-value`}>
                        {convertNumCentsToDollars(Number(financialData?.first_visit_value) * 100, true, '$')}
                    </div>
                    <div className={`${moduleName}__cell-title`}>Last Visit Value</div>
                    <div className={`${moduleName}__cell-value-bottom`}>
                        {convertNumCentsToDollars(Number(financialData?.last_visit_value) * 100, true, '$')}
                    </div>
                </div>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>First 30 Days</div>
                    <div className={`${moduleName}__cell-value`}>
                        {convertNumCentsToDollars(Number(financialData?.first_30_days_value) * 100, true, '$')}
                    </div>
                    <div className={`${moduleName}__cell-title`}>Last 30 Days</div>
                    <div className={`${moduleName}__cell-value-bottom`}>
                        {convertNumCentsToDollars(Number(financialData?.last_30_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>First 60 Days</div>
                    <div className={`${moduleName}__cell-value`}>
                        {convertNumCentsToDollars(Number(financialData?.first_60_days_value) * 100, true, '$')}
                    </div>
                    <div className={`${moduleName}__cell-title`}>Last 60 Days</div>
                    <div className={`${moduleName}__cell-value-bottom`}>
                        {convertNumCentsToDollars(Number(financialData?.last_60_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>First 90 Days</div>
                    <div className={`${moduleName}__cell-value`}>
                        {convertNumCentsToDollars(Number(financialData?.first_90_days_value) * 100, true, '$')}
                    </div>
                    <div className={`${moduleName}__cell-title`}>Last 90 Days</div>
                    <div className={`${moduleName}__cell-value-bottom`}>
                        {convertNumCentsToDollars(Number(financialData?.last_90_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>First 360 Days</div>
                    <div className={`${moduleName}__cell-value`}>
                        {convertNumCentsToDollars(Number(financialData?.first_360_days_value) * 100, true, '$')}
                    </div>
                    <div className={`${moduleName}__cell-title`}>Last 360 Days</div>
                    <div className={`${moduleName}__cell-value-bottom`}>
                        {convertNumCentsToDollars(Number(financialData?.last_360_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className={`${moduleName}__cell`}>
                    <div className={`${moduleName}__cell-title`}>Lifetime Value</div>
                    <div className={`${moduleName}__cell-value ${moduleName}--bold`}>
                        {convertNumCentsToDollars(Number(financialData?.lifetime_value) * 100, true, '$')}
                    </div>
                    <div className={`${moduleName}__cell-title`}></div>
                    <div className={`${moduleName}__cell-value-bottom`}></div>
                </div>
            </div>
        </div>
    )
}

export default ReferralProductionValues
