import * as React from 'react'
import _ from 'lodash'

import './Paginator.sass'

export type Props = {
    currentPage: number
    paginationInfo?: Models.PaginationInfo
    selectPage: (page: number) => void
}

export default class Paginator extends React.Component<Props> {
    generatePageNumbers = (numberOfPages: number, currentPage: number) => {
        if (numberOfPages <= 5) {
            return _.range(1, numberOfPages + 1)
        } else {
            let from = currentPage - 2
            let to = currentPage + 2

            if (from < 1) {
                to = to - from + 1
                from = 1
            }

            if (to > numberOfPages) {
                from = from - to + numberOfPages
                to = numberOfPages
            }

            return _.range(from, to + 1)
        }
    }

    getPrevPage = (page?: number) => {
        if (page) {
            return page <= 1 ? 1 : page - 1
        }

        return 1
    }

    getNextPage = (numberOfPages: number, page?: number) => {
        if (page) {
            return page >= numberOfPages ? numberOfPages : page + 1
        }

        return 1
    }

    goToFirstPage = () => {
        this.props.selectPage(1)
    }

    goToPrevPage = () => {
        const { currentPage } = this.props
        const prevPage = this.getPrevPage(currentPage)
        this.props.selectPage(prevPage)
    }

    goToNextPage = (numberOfPages: number) => {
        const { currentPage } = this.props
        const nextPage = this.getNextPage(numberOfPages, currentPage)
        this.props.selectPage(nextPage)
    }

    goToLastPage = (numberOfPages: number) => {
        this.props.selectPage(numberOfPages)
    }

    render() {
        const { currentPage, paginationInfo: { allPages: numberOfPages } = { allPages: 0 }, selectPage } = this.props

        return (
            <div className="paginator">
                {numberOfPages > 1 && (
                    <ul>
                        <li className={currentPage === 1 ? 'disabled' : ''} onClick={this.goToFirstPage}>
                            <i className="material-icons">first_page</i>
                        </li>
                        <li className={currentPage === 1 ? 'disabled' : ''} onClick={this.goToPrevPage}>
                            <i className="material-icons">keyboard_arrow_left</i>
                        </li>
                        {this.generatePageNumbers(numberOfPages, currentPage).map((n: number) => {
                            return (
                                <li
                                    key={n}
                                    className={currentPage === n ? 'selected' : ''}
                                    onClick={() => selectPage(n)}
                                >
                                    {n}
                                </li>
                            )
                        })}
                        <li
                            className={currentPage === numberOfPages ? 'disabled' : ''}
                            onClick={() => this.goToNextPage(numberOfPages)}
                        >
                            <i className="material-icons">keyboard_arrow_right</i>
                        </li>
                        <li
                            className={currentPage === numberOfPages ? 'disabled' : ''}
                            onClick={() => this.goToLastPage(numberOfPages)}
                        >
                            <i className="material-icons">last_page</i>
                        </li>
                    </ul>
                )}
            </div>
        )
    }
}
