import { Fragment } from 'react'
import Button, { ButtonClassKey } from '@mui/material/Button'

import { ShortcutModalType } from './AdminShortcutList'

import './ShortcutSuccessModalBody.sass'

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const moduleName = 'shortcut-success-modal-body'

const buttonClassOverrides: ButtonClassOverrides = {
    outlined: `${moduleName}__cancel_btn`,
    contained: `${moduleName}__yes_btn`,
}

type Props = {
    type: ShortcutModalType
    success?: boolean
    name?: string
    onCloseSuccessModal: () => void
    onOpenShortcutModal: (type: ShortcutModalType) => void
}

const ShortcutSuccessModalBody = (props: Props) => {
    const renderSuccessMessage = () => {
        switch (props.type) {
            case ShortcutModalType.AddShortcut:
                if (props.success) {
                    return (
                        <Fragment>
                            <div className={`${moduleName}__icon-success`}>
                                <i className="material-icons">check_circle</i>
                            </div>
                            <div className={`${moduleName}__inner-content`}>
                                <div className={`${moduleName}__title`}>
                                    New Shortcut "{props.name}" has been created.
                                </div>
                            </div>
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment>
                            <div className={`${moduleName}__icon-error`}>
                                <i className="material-icons">error</i>
                            </div>
                            <div className={`${moduleName}__inner-content`}>
                                <div className={`${moduleName}__title`}>Shortcut "{props.name}" already exists.</div>
                            </div>
                        </Fragment>
                    )
                }
            case ShortcutModalType.EditShortcut:
                return (
                    <Fragment>
                        <div className={`${moduleName}__icon-success`}>
                            <i className="material-icons">check_circle</i>
                        </div>
                        <div className={`${moduleName}__inner-content`}>
                            <div className={`${moduleName}__title`}>Shortcut "{props.name}" has been edited.</div>
                        </div>
                    </Fragment>
                )
            case ShortcutModalType.DeleteShortcut:
                return (
                    <Fragment>
                        <div className={`${moduleName}__icon-success`}>
                            <i className="material-icons">check_circle</i>
                        </div>
                        <div className={`${moduleName}__inner-content`}>
                            <div className={`${moduleName}__title`}>Shortcut "{props.name}" has been deleted.</div>
                        </div>
                    </Fragment>
                )
            default:
                return null
        }
    }

    const onClose = () => {
        props.onCloseSuccessModal()
    }

    const onCreateAnotherShortcut = () => {
        props.onOpenShortcutModal(ShortcutModalType.AddShortcut)
    }

    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__container`}>
                <div className={`${moduleName}__header`}>
                    <span className={`${moduleName}__close-button`} onClick={onClose}>
                        <i className="material-icons">close</i>
                    </span>
                </div>
                <div className={`${moduleName}__content`}>{renderSuccessMessage()}</div>
                <div className={`${moduleName}__footer`}>
                    <Button variant="outlined" classes={buttonClassOverrides} onClick={onClose}>
                        Close
                    </Button>
                    {props.type === ShortcutModalType.AddShortcut && (
                        <Button
                            variant="contained"
                            color={props.success ? 'primary' : 'secondary'}
                            onClick={onCreateAnotherShortcut}
                        >
                            Create another shortcut
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ShortcutSuccessModalBody
