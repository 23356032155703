import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import ErrorMessage from 'modules/shared/error-message/error-message'
import moment from 'moment'

import ApiV2 from '../../ApiV2'
import { RootState } from '../../appReducer'
import Confirmation from '../../modules/shared/success-message/success-message'

import { fetchHeartbeatDownloadMonthlyCheckupCsv } from './v2actions'

import './Heartbeat.sass'

export type HeartbeatProps = {}

const moduleName = 'heartbeat'

const HeartbeatMenu = (props: HeartbeatProps) => {
    const heartbeat = useSelector((state: RootState) => state.v2.heartbeat)
    const account = useSelector((state: RootState) => state.app?.self?.account)
    const [generatePandLZipStarted, setGeneratePandLZipStarted] = useState(false)
    const [generatePandLZipFinished, setGeneratePandLZipFinished] = useState(false)
    const dispatch = useDispatch()

    const startDateObject: moment.Moment = moment()
        .subtract(1, 'months')
        .startOf('month')

    const startDate = startDateObject.format('MM/DD/YYYY')

    const endDate = moment()
        .subtract(1, 'months')
        .endOf('month')
        .format('MM/DD/YYYY')

    const downloadCsv = () => {
        const monthIndex = startDateObject.month()
        const month = monthIndex + 1
        const year = startDateObject.year()
        dispatch(fetchHeartbeatDownloadMonthlyCheckupCsv(year, month))
    }
    const onGeneratePandLZip = async () => {
        setGeneratePandLZipStarted(true)
        try {
            await ApiV2.Heartbeat.generatePandLZip('bofa-biz-dsc')
            setGeneratePandLZipStarted(false)
            setGeneratePandLZipFinished(true)
        } catch (e) {
            setGeneratePandLZipStarted(false)
        }
    }

    const csvDownloadInProgress = heartbeat.download_monthly_checkup.status === 'downloading'

    return (
        <div className={`${moduleName}`}>
            <h2>Download Heartbeat CSVs</h2>
            <div>
                Click below to download files for {startDate} - {endDate}.
            </div>

            <div className={`${moduleName}__container`}>
                {generatePandLZipFinished && (
                    <Confirmation isShown={true}>
                        The P&L Zip will be sent to {account?.username} email in 15-30 minutes.
                    </Confirmation>
                )}
                <p>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={csvDownloadInProgress}
                        onClick={downloadCsv}
                        className={`${moduleName}__download-css`}
                    >
                        DOWNLOAD MONTHLY CHECKUP CSV
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={generatePandLZipStarted}
                        onClick={onGeneratePandLZip}
                        className={`${moduleName}__generate-zip`}
                    >
                        GENERATE P&L ZIP
                    </Button>
                </p>
            </div>
            {csvDownloadInProgress && <p>Downloading monthly-checkup.csv...</p>}

            {heartbeat.download_monthly_checkup.status === 'error' && (
                <div className="row">
                    <ErrorMessage type="error">Something went wrong, please try again later.</ErrorMessage>
                </div>
            )}
        </div>
    )
}

export default HeartbeatMenu
