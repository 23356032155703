import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import './InlineEditableText.sass'

export type InlineEditableTextProps = {
    className?: string
    textClassName?: string
    text: string
    editable: boolean
    isIconButtonPrimary?: boolean
    onSave: (text: string) => Promise<any>
}

const InlineEditableText = (props: InlineEditableTextProps) => {
    const { className, textClassName, text, editable, isIconButtonPrimary = true, onSave } = props

    const [editing, setEditing] = useState<boolean>(false)
    const [update, setUpdate] = useState<string | undefined>()
    const editableDiv = useRef<HTMLDivElement>(null)

    const onClickCancel = () => {
        setEditing(false)
        setUpdate(undefined)
    }

    const onClickEdit = () => {
        setEditing(true)
        setUpdate(text)
    }

    useEffect(() => {
        if (editing) {
            editableDiv?.current?.focus()
            document.execCommand('selectAll', false)
        }
    }, [editing])

    const onDoubleClick = () => {
        if (!editing) {
            onClickEdit()
        }
    }

    const onClickSave = async () => {
        if (!update) {
            return
        }

        await onSave(update)
        onClickCancel()
    }

    const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        const text = event.target && event.target.innerText

        if (text) {
            setUpdate(text.trim())
        }
    }

    const onEnter = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        const text = event.currentTarget && event.currentTarget.innerText

        if (event.key === 'Enter' && text) {
            event.preventDefault()
            event.stopPropagation()
            await onSave(text.trim())
            onClickCancel()
        }
    }

    return (
        <div className={classNames('editable-inline-text', className)}>
            <div
                ref={editableDiv}
                className={classNames('editable-inline-text__text-input', { editing }, textClassName)}
                contentEditable={editing}
                suppressContentEditableWarning={editing}
                onBlur={onBlur}
                onKeyDown={onEnter}
                onDoubleClick={onDoubleClick}
            >
                {editing ? update : text}
            </div>
            {editable && !editing && (
                <div className="icon-wrapper" onClick={onClickEdit}>
                    <i
                        className={classNames('material-icons', 'icon-button', {
                            'icon-button--primary': isIconButtonPrimary,
                        })}
                    >
                        edit
                    </i>
                </div>
            )}
            {editable && editing && (
                <div className="icon-wrapper" onClick={onClickSave}>
                    <i className="material-icons icon-button">check</i>
                </div>
            )}
            {editable && editing && (
                <div className="icon-wrapper" onClick={onClickCancel}>
                    <i className="material-icons icon-button">close</i>
                </div>
            )}
        </div>
    )
}

export default InlineEditableText
