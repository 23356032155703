import Api from './Api'
import ApiV2 from './ApiV2'
import SessionManager from './SessionManager'

export type ReceiveSelf = {
    type: 'RECEIVE_SELF'
    account: Api.Account
}

export type ReceiveFeatureFlags = {
    type: 'RECEIVE_FEATURE_FLAGS'
    featureFlags: ApiV2.FeatureFlags.FeatureFlags
}

export type SetRedirect = {
    type: 'SET_REDIRECT'
    path: string
}

export type SetSocketConnectionStatus = {
    type: 'SET_SOCKET_CONNECTION_STATUS'
    connected: boolean
}

export type ReceiveToken = {
    type: 'RECEIVE_TOKEN'
    token: string
}

export type ReceiveCredentials = {
    type: 'RECEIVE_CREDENTIALS'
    credentials: Api.Credentials
}

export function receiveSelf(account: Api.Account): ReceiveSelf {
    return {
        type: 'RECEIVE_SELF',
        account,
    }
}

export function receiveFeatureFlags(featureFlags: ApiV2.FeatureFlags.FeatureFlags): ReceiveFeatureFlags {
    return {
        type: 'RECEIVE_FEATURE_FLAGS',
        featureFlags,
    }
}

export function tryLogin(email: string, password: string): any {
    return async (dispatch: any) => {
        const credentials = await Api.login(email, password)
        window.sessionStorage.setItem('session', credentials.token)
        window.sessionStorage.removeItem('PSTUnauthorizedError')
        SessionManager.session = credentials.token
        Api.token = credentials.token
        ApiV2.token = credentials.token
        await dispatch(receiveToken(credentials.token))
        await dispatch(fetchSelf())
        await dispatch(fetchFeatureFlags())
    }
}

export function logout() {
    SessionManager.update(undefined)
    window.location.assign('/logout')
}

export function setRedirect(path: string): SetRedirect {
    return {
        type: 'SET_REDIRECT',
        path,
    }
}

export function setSocketConnectionStatus(connected: boolean): SetSocketConnectionStatus {
    return {
        type: 'SET_SOCKET_CONNECTION_STATUS',
        connected,
    }
}

export function receiveCredentials(credentials: Api.Credentials): ReceiveCredentials {
    return {
        type: 'RECEIVE_CREDENTIALS',
        credentials,
    }
}

export function receiveToken(token: string): ReceiveToken {
    return {
        type: 'RECEIVE_TOKEN',
        token,
    }
}

export function fetchSelf(): any {
    return async (dispatch: any) => {
        const self = await Api.App.getSelf()
        await dispatch(receiveSelf(self))
    }
}

export function fetchFeatureFlags(): any {
    return async (dispatch: any) => {
        const { data: featureFlags } = await ApiV2.FeatureFlags.getFeatureFlags()
        await dispatch(receiveFeatureFlags(featureFlags))
    }
}

export function tryCompletePasswordReset(code: string, password: string, confirmed: string): any {
    return async (dispatch: any) => {
        if (password !== confirmed) {
            throw new Error('Passwords do not match.')
        }
        return Api.postCompletePasswordReset(code, password)
    }
}
