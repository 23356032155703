import { useEffect } from 'react'

const KEYS_LEFT = ['ArrowLeft']
const KEYS_RIGHT = ['ArrowRight']

type useKeyNavigationProps = {
    prevChatTile: () => void
    nextChatTile: () => void
}

const useKeyNavigation = (props: useKeyNavigationProps) => {
    const { prevChatTile, nextChatTile } = props

    useEffect(() => {
        const onKeyUp = ({ key, shiftKey }: KeyboardEvent) => {
            if (KEYS_LEFT.includes(key) && shiftKey) {
                prevChatTile()
            }

            if (KEYS_RIGHT.includes(key) && shiftKey) {
                nextChatTile()
            }
        }

        document.addEventListener('keyup', onKeyUp)

        return () => {
            document.removeEventListener('keyup', onKeyUp)
        }
    }, [prevChatTile, nextChatTile])
}

export default useKeyNavigation
