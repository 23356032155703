import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Switch as MuiSwitch, Table, TableBody, TableHead } from '@mui/material'
import { withStyles } from '@mui/styles'
import moment from 'moment'

import { DayOfWeek, OperationDay } from '../../../../../models/PracticeSurvey'
import CustomTimePicker from '../../../../shared/custom-fields/custom-time-picker-v2/CustomTimePicker'
import { setSectionIsValid, updateOperatingHour, updateOperatingHourLocationIds } from '../actions'
import LocationMultiselect from '../shared/location-multiselect/LocationMultiselect'
import { Cell, HeaderCell, HeaderRow } from '../shared/Table'

import { EDIT_TIME_FORMAT, SHADOW_TIME_FORMAT, weekDays } from './const'

import './OperatingHoursView.sass'

interface Props {
    location: Models.PracticeLocation
}

const Switch = withStyles({
    root: {
        marginRight: 10,
    },
    checked: {
        '&:hover': {
            backgroundColor: 'rgba(52, 107, 199, 0.1) !important',
        },
    },
})(MuiSwitch as React.ComponentType<any>)

const OperatingHoursEdit = ({ location }: Props) => {
    const { hours, practiceLocationIds }: { hours: OperationDay[]; practiceLocationIds: string[] } = useSelector(
        (state: any) => ({
            hours: state.practiceSurvey[location.id]?.operatingHours?.days ?? [],
            practiceLocationIds: state.practiceSurvey[location.id]?.operatingHours?.practiceLocationIds ?? [],
        }),
    )
    const [previousOpenTime, setPreviousOpenTime] = useState<string[]>([])
    const [previousCloseTime, setPreviousCloseTime] = useState<string[]>([])
    const [initialHours, setInitialHours] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
        const sectionErrors = hours.filter(day => getOpenCloseError(day.open, day.close))
        dispatch(setSectionIsValid(location.id, 'operatingHours', !sectionErrors.length))
    }, [hours, dispatch, location.id])

    useEffect(() => {
        if (!initialHours && hours.length > 0 && !previousOpenTime.length && !previousCloseTime.length) {
            setPreviousOpenTime(hours.map(dayOfWeek => dayOfWeek.open || '09:00'))
            setPreviousCloseTime(hours.map(dayOfWeek => dayOfWeek.close || '17:00'))
            setInitialHours(true)
        }
    }, [initialHours, previousOpenTime, previousCloseTime, hours])

    const handleChangeOpenTime = (dayOfWeek: DayOfWeek) => (value: string) => {
        dispatch(
            updateOperatingHour(location.id, dayOfWeek, {
                open: moment(value, EDIT_TIME_FORMAT).format(SHADOW_TIME_FORMAT),
            }),
        )
    }

    const handleChangeCloseTime = (dayOfWeek: DayOfWeek) => (value: string) => {
        dispatch(
            updateOperatingHour(location.id, dayOfWeek, {
                close: moment(value, EDIT_TIME_FORMAT).format(SHADOW_TIME_FORMAT),
            }),
        )
    }

    const handleOpenOpenTimeTimePicker = (dayOfWeek: DayOfWeek) => () => {
        const dayIndex = dayOfWeek - 1
        const newOpenTime = [...previousOpenTime]
        newOpenTime[dayIndex] = hours[dayIndex].open || '09:00'
        setPreviousOpenTime(newOpenTime)
    }

    const handleOpenCloseTimeTimePicker = (dayOfWeek: DayOfWeek) => () => {
        const dayIndex = dayOfWeek - 1
        const newCloseTime = [...previousCloseTime]
        newCloseTime[dayIndex] = hours[dayIndex].close || '17:00'
        setPreviousCloseTime(newCloseTime)
    }

    const handleCancelOpenTime = (dayOfWeek: DayOfWeek) => () => {
        dispatch(
            updateOperatingHour(location.id, dayOfWeek, {
                open: moment(previousOpenTime[dayOfWeek - 1], EDIT_TIME_FORMAT).format(SHADOW_TIME_FORMAT),
            }),
        )
    }

    const handleCancelCloseTime = (dayOfWeek: DayOfWeek) => () => {
        dispatch(
            updateOperatingHour(location.id, dayOfWeek, {
                close: moment(previousCloseTime[dayOfWeek - 1], EDIT_TIME_FORMAT).format(SHADOW_TIME_FORMAT),
            }),
        )
    }

    const handleToggleWorkingHours = (dayOfWeek: DayOfWeek) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation()
        dispatch(
            updateOperatingHour(location.id, dayOfWeek, {
                enabled: event.target.checked,
            }),
        )
    }

    const onSelectElement = (practiceLocationIds: string[]) => {
        dispatch(updateOperatingHourLocationIds(location.id, practiceLocationIds))
    }

    const getOpenCloseError = (openTime?: string, closeTime?: string) => {
        if (!openTime && !closeTime) return ''

        if (!openTime) {
            return 'Please define the open time'
        }
        if (!closeTime) {
            return 'Please define the close time'
        }
        const beginningTime = moment(openTime, EDIT_TIME_FORMAT)
        const endTime = moment(closeTime, EDIT_TIME_FORMAT)
        if (endTime.isBefore(beginningTime)) {
            return 'Open time is higher than close time'
        } else if (beginningTime.isSame(endTime)) {
            return 'Open and close times are the same'
        }
        return ''
    }

    const hoursErrorClass = () => {
        const sectionErrors = hours.filter(day => getOpenCloseError(day.open, day.close))
        return sectionErrors.length ? 'survey-section__header-error' : ''
    }

    const renderEnableSwitch = (day: OperationDay) => {
        return (
            <Switch
                checked={day.enabled}
                onChange={handleToggleWorkingHours(day.dayOfWeek as DayOfWeek)}
                disableRipple={false}
                inputProps={{
                    'aria-label': 'primary checkbox',
                }}
            />
        )
    }

    return (
        <div className="survey-section survey-section--view survey-operation-hours">
            <Grid
                container
                spacing={3}
                justifyContent={'flex-start'}
                alignItems={'center'}
                className={'grid-container'}
            >
                <Grid item>
                    <h4 className={`survey-section__header ${hoursErrorClass()}`} id="hours">
                        Hours of Operation*
                    </h4>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <LocationMultiselect
                        onSelectElement={onSelectElement}
                        selectedItems={practiceLocationIds}
                        practiceId={location.practiceId}
                        excludeIds={[location.id]}
                    />
                </Grid>
            </Grid>
            <div className="survey-section__wrapper">
                <Table className="survey-operation-hours__table" aria-labelledby="Hours of Operation">
                    <TableHead>
                        <HeaderRow>
                            <HeaderCell className="survey-operation-hours__switch switch-cell"></HeaderCell>
                            <HeaderCell className="survey-operation-hours__day">Day</HeaderCell>
                            <HeaderCell className="survey-operation-hours__open">Open</HeaderCell>
                            <HeaderCell className="survey-operation-hours__close">Close</HeaderCell>
                        </HeaderRow>
                    </TableHead>
                    <TableBody>
                        {hours.map(day => {
                            const errorMessage = getOpenCloseError(day.open, day.close)

                            return (
                                <HeaderRow key={day.dayOfWeek}>
                                    <Cell align={'left'} className={`big-screens switch-cell`}>
                                        {renderEnableSwitch(day)}
                                    </Cell>
                                    <Cell align={'left'} sx={{ verticalAlign: 'middle !important' }}>
                                        {weekDays[day.dayOfWeek - 1]}
                                    </Cell>
                                    <Cell align={'left'}>
                                        <div className="survey-operation-hours__time-picker">
                                            <CustomTimePicker
                                                value={day.open}
                                                onOpen={handleOpenOpenTimeTimePicker(day.dayOfWeek as DayOfWeek)}
                                                onCancel={handleCancelOpenTime(day.dayOfWeek as DayOfWeek)}
                                                onChange={handleChangeOpenTime(day.dayOfWeek as DayOfWeek)}
                                                placeholder="Open Time"
                                                timeFormat={EDIT_TIME_FORMAT}
                                                error={Boolean(errorMessage)}
                                                errorMessage={errorMessage}
                                            />
                                        </div>
                                    </Cell>
                                    <Cell align={'left'}>
                                        <div className="survey-operation-hours__time-picker">
                                            <CustomTimePicker
                                                value={day.close}
                                                onOpen={handleOpenCloseTimeTimePicker(day.dayOfWeek as DayOfWeek)}
                                                onCancel={handleCancelCloseTime(day.dayOfWeek as DayOfWeek)}
                                                onChange={handleChangeCloseTime(day.dayOfWeek as DayOfWeek)}
                                                placeholder="Close Time"
                                                timeFormat={EDIT_TIME_FORMAT}
                                                error={Boolean(errorMessage)}
                                                className="survey-operation-hours__time-picker"
                                            />
                                        </div>
                                    </Cell>
                                </HeaderRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default OperatingHoursEdit
