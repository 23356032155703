import React, { useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@mui/styles'

import './CustomTooltipV2.sass'

interface TooltipProps {
    tooltipText: NonNullable<React.ReactNode>
    type?: 'click' | 'hover'
    iconType?: 'warning' | 'info' | 'question'
}

export const moduleName = 'simplifeye-custom-tooltip'

const HtmlTooltip = withStyles(theme => ({
    arrow: {
        '&::before': {
            border: `1px solid #cfdae9`,
        },
        color: 'white',
        backgroundColor: 'white',
    },
    tooltip: {
        backgroundColor: 'white',
        color: '#4a4a4a',
        maxWidth: 260,
        fontSize: '16px',
        fontWeight: 400,
        border: `1px solid #cfdae9`,
        borderRadius: '4px',
        padding: '12px',
    },
}))(Tooltip)

const SFCustomTooltip = ({ type, tooltipText, iconType = 'info' }: TooltipProps) => {
    const [open, setOpen] = useState(false)

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const toggleTooltip = () => {
        setOpen(!open)
    }

    const renderIcon = () => {
        if (iconType === 'question') {
            return <i className={`material-icons ${moduleName}__question-icon`}>help</i>
        }

        return (
            <div className={`${moduleName}__info-icon`}>
                <i className="material-icons">info</i>
            </div>
        )
    }

    return (
        <div className={`${moduleName}`}>
            {type === 'click' ? (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <HtmlTooltip
                            PopperProps={{
                                disablePortal: false,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <>
                                    <Icon
                                        className={`${moduleName}__close-icon`}
                                        sx={{ float: 'right' }}
                                        onClick={handleTooltipClose}
                                    >
                                        close
                                    </Icon>

                                    {tooltipText}
                                </>
                            }
                            arrow
                            placement="top"
                        >
                            <IconButton onClick={toggleTooltip} className={`${moduleName}__icon-wrapper`}>
                                {renderIcon()}
                            </IconButton>
                        </HtmlTooltip>
                    </div>
                </ClickAwayListener>
            ) : (
                <div>
                    <HtmlTooltip
                        PopperProps={{
                            disablePortal: false,
                        }}
                        title={tooltipText}
                        arrow
                        placement="top"
                    >
                        <IconButton className={`${moduleName}__icon-wrapper`}>{renderIcon()}</IconButton>
                    </HtmlTooltip>
                </div>
            )}
        </div>
    )
}

export default SFCustomTooltip
