import * as React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import './WidgetButtonStyle.sass'

export type WidgetButtonStyleProps = {
    customValue?: Api.widgetButtonStyle | boolean
    updateWidgetButtonStyle: (widgetButtonStyle: Api.widgetButtonStyle) => void
}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

const moduleName = 'widget-button-style'

const WidgetButtonStyle = (props: WidgetButtonStyleProps) => {
    const updateWidgetButtonStyle = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.updateWidgetButtonStyle(e.target.value as Api.widgetButtonStyle)
    }

    const { customValue } = props
    const radioValue = customValue || 'default'
    const radioClassOverrides: RadioClassOverrides = {
        root: `${moduleName}__radio-root`,
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__title`}>Style:</div>
            <div className={`${moduleName}__radio-wrapper`}>
                <Radio
                    checked={radioValue === 'default'}
                    onChange={updateWidgetButtonStyle}
                    name="radio-widget-button-style"
                    value="default"
                    id="widget-button-style-default"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__wrapper`}>
                    <label htmlFor="widget-button-style-default">
                        <span className={`${moduleName}__text`}>Default</span>
                    </label>
                </div>
            </div>
            <div className={`${moduleName}__radio-wrapper`}>
                <Radio
                    checked={radioValue === 'client_button'}
                    onChange={updateWidgetButtonStyle}
                    name="radio-widget-button-style"
                    value="client_button"
                    id="widget-button-style-client-button"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__wrapper`}>
                    <label htmlFor="widget-button-style-client-button">
                        <span className={`${moduleName}__text`}>Client Button</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default WidgetButtonStyle
