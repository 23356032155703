import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import Api from '../../../Api'
import ApiV2 from '../../../ApiV2'
import { mergeAgentLocations } from '../../../ApiV2/mappers/v2/integrations'
import { RootState } from '../../../appReducer'
import { AppDispatch, AppThunk } from '../../../appStore'
import {
    HeartbeatPracticeIntegrationsAgent,
    IMappedAgentsWithLocations,
    IntegrationsAgent,
    IntegrationsLocationPair,
    IntegrationsSoftware,
} from '../../../models/v2/Integrations'
import { PracticeLocationsV2 } from '../../../models/v2/Practice'

export enum IntegrationsActionTypes {
    GET_HEARTBEAT_AGENTS_WITH_LOCATIONS = '@V2/@INTEGRATIONS/GET_HEARTBEAT_AGENTS_WITH_LOCATIONS',
    RECEIVE_HEARTBEAT_AGENT = '@V2@INTEGRATIONS/RECEIVE_HEARTBEAT_AGENT',
    RECEIVE_HEARTBEAT_SOFTWARE = '@V2@INTEGRATIONS/RECEIVE_HEARTBEAT_SOFTWARE',
    UPDATE_SELECTED_AGENT = '@V2@INTEGRATIONS/UPDATE_SELECTED_AGENT',
    SET_AGENTS_PENDING = '@V2@INTEGRATIONS/SET_AGENTS_PENDING',
    SET_AGENTS_ERROR_MESSAGE = '@V2@INTEGRATIONS/SET_AGENTS_ERROR_MESSAGE',
    SET_SELECTED_AGENT = '@V2@INTEGRATIONS/SET_SELECTED_AGENT',
    RESET_SELECTED_AGENT = '@V2@INTEGRATIONS/RESET_SELECTED_AGENT',
    SET_AGENTS_LOADING = '@V2@INTEGRATIONS/SET_AGENTS_LOADING',
    RECEIVE_PRACTICE_LOCATIONS = '@V2@INTEGRATIONS/RECEIVE_PRACTICE_LOCATIONS',
}

export type ReceiveHeartbeatAgents = {
    type: IntegrationsActionTypes.GET_HEARTBEAT_AGENTS_WITH_LOCATIONS
    heartbeatAgents: IMappedAgentsWithLocations[]
    practiceId: string
    query: string
    page: number
    paginationInfo: ApiV2.PaginationInfo | undefined
}

export type ReceiveHeartbeatSoftware = {
    type: IntegrationsActionTypes.RECEIVE_HEARTBEAT_SOFTWARE
    software: IntegrationsSoftware[]
}

export type SetSelectedAgent = {
    type: IntegrationsActionTypes.SET_SELECTED_AGENT
    agent: IntegrationsAgent
}

export type ResetSelectedAgent = {
    type: IntegrationsActionTypes.RESET_SELECTED_AGENT
}

export type UpdateSelectedAgent = {
    type: IntegrationsActionTypes.UPDATE_SELECTED_AGENT
    externalLocationId: number
    value: string
    directScheduling: boolean
    heartbeat: boolean
    chairfill: boolean
    payments: boolean
    reviews: boolean
}

export type SetAgentsPending = {
    type: IntegrationsActionTypes.SET_AGENTS_PENDING
    practiceId: string
    state: boolean
}

export type SetAgentsErrorMessage = {
    type: IntegrationsActionTypes.SET_AGENTS_ERROR_MESSAGE
    practiceId: string
    errorMessage: string
}

export type SetAgentsLoading = {
    type: IntegrationsActionTypes.SET_AGENTS_LOADING
    practiceId: string
    isLoading: boolean
}

export type ReceivePracticeLocations = {
    type: IntegrationsActionTypes.RECEIVE_PRACTICE_LOCATIONS
    practiceId: string
    locations: ModelsV2.Practice.PracticeLocationsV2[]
}

export function getHeartbeatAgentsWithLocations(
    heartbeatAgents: IMappedAgentsWithLocations[],
    practiceId: string,
    query: string,
    page: number,
    paginationInfo: ApiV2.PaginationInfo | undefined,
): ReceiveHeartbeatAgents {
    return {
        type: IntegrationsActionTypes.GET_HEARTBEAT_AGENTS_WITH_LOCATIONS,
        heartbeatAgents,
        practiceId,
        query,
        page,
        paginationInfo,
    }
}

export function receiveHeartbeatSoftware(software: IntegrationsSoftware[]): ReceiveHeartbeatSoftware {
    return {
        type: IntegrationsActionTypes.RECEIVE_HEARTBEAT_SOFTWARE,
        software,
    }
}

export function setSelectedAgentAction(agent?: IntegrationsAgent) {
    return {
        type: IntegrationsActionTypes.SET_SELECTED_AGENT,
        agent,
    }
}

export function resetSelectedAgent() {
    return {
        type: IntegrationsActionTypes.RESET_SELECTED_AGENT,
    }
}

export function updateLocationAgent(
    externalLocationId: number,
    directScheduling: boolean,
    heartbeat: boolean,
    chairfill: boolean,
    payments: boolean,
    reviews: boolean,
    value: string,
): UpdateSelectedAgent {
    return {
        type: IntegrationsActionTypes.UPDATE_SELECTED_AGENT,
        externalLocationId,
        directScheduling,
        heartbeat,
        chairfill,
        payments,
        reviews,
        value,
    }
}

export function setAgentsPending(practiceId: string, state: boolean): SetAgentsPending {
    return {
        type: IntegrationsActionTypes.SET_AGENTS_PENDING,
        practiceId,
        state,
    }
}

export function setAgentsErrorMessage(practiceId: string, errorMessage: string): SetAgentsErrorMessage {
    return {
        type: IntegrationsActionTypes.SET_AGENTS_ERROR_MESSAGE,
        practiceId,
        errorMessage,
    }
}

export function setAgentsLoading(practiceId: string, isLoading: boolean): SetAgentsLoading {
    return {
        type: IntegrationsActionTypes.SET_AGENTS_LOADING,
        practiceId,
        isLoading,
    }
}

export function receivePracticeLocations(
    practiceId: string,
    locations: ModelsV2.Practice.PracticeLocationsV2[],
): ReceivePracticeLocations {
    return {
        type: IntegrationsActionTypes.RECEIVE_PRACTICE_LOCATIONS,
        practiceId,
        locations,
    }
}

export const loadAgent = (externalAgentId: number): AppThunk<Promise<IntegrationsAgent>> => {
    return async (dispatch: AppDispatch) => {
        try {
            const heartbeatAgent = await ApiV2.Integrations.getAgent(externalAgentId)
            dispatch(setSelectedAgent(heartbeatAgent))
            return heartbeatAgent
        } catch (err) {
            throw err
        }
    }
}

export const deleteHeartbeatAgent = (practiceId: string, agentId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            await Api.Heartbeat.deleteHeartbeatAgent(practiceId, agentId)
        } catch (err) {
            throw err
        }
    }
}

export const loadHeartbeatSoftwares = () => {
    return async (dispatch: AppDispatch) => {
        const softwares = await ApiV2.Integrations.loadIntegrationSoftwares()
        dispatch(receiveHeartbeatSoftware(softwares))
    }
}

export const setSelectedAgent = (agent?: IntegrationsAgent) => {
    return async (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
        dispatch(setSelectedAgentAction(agent))
    }
}

export function saveLocationPairing(practiceId: string, agentId: string, pairs: IntegrationsLocationPair[]): any {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setAgentsErrorMessage(practiceId, ''))
            dispatch(setAgentsPending(practiceId, true))
            const locationPair = await Api.Heartbeat.saveLocationPairing(practiceId, agentId, pairs)
            dispatch(setAgentsPending(practiceId, false))

            return locationPair
        } catch (error) {
            let errorMessage = error?.message
            if (error.errors) {
                errorMessage = error.errors.map((e: any) => e.message || e.details).join('; ')
            }
            dispatch(setAgentsPending(practiceId, false))
            dispatch(setAgentsErrorMessage(practiceId, errorMessage))
            throw error
        }
    }
}

export function saveAgentName(practiceId: string, agentId: string, name: string): any {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setAgentsPending(practiceId, true))
            const updatedAgent = await ApiV2.Integrations.putHeartbeatAgentsUpdate(agentId, name)
            dispatch(setAgentsPending(practiceId, false))
            return updatedAgent
        } catch (error) {
            dispatch(setAgentsPending(practiceId, false))
            throw error
        }
    }
}

export function fetchPracticeLocations(
    practiceId: string,
    page: number,
): AppThunk<Promise<{ pagination_info?: ApiV2.PaginationInfo | undefined } | undefined>> {
    return async (dispatch: AppDispatch) => {
        const { data: locations, metadata } = await ApiV2.Integrations.loadPracticeLocations(practiceId, page)
        await dispatch(receivePracticeLocations(practiceId, locations))
        return metadata
    }
}

export const loadAgentsPaginated = (
    practiceId: string,
    query: string,
    page: number,
    practiceLocations: PracticeLocationsV2[],
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(setAgentsLoading(practiceId, true))
        const { data: agents, paginationInfo } = await ApiV2.Integrations.loadAgentsByPracticeIdPaginated(practiceId, {
            query,
            page,
        })
        dispatch(setAgentsLoading(practiceId, false))

        const mergedLocations = mergeAgentLocations(agents, practiceLocations)
        dispatch(getHeartbeatAgentsWithLocations(mergedLocations, practiceId, query, page, paginationInfo))

        return agents
    }
}

export const getPaymentsWritebackEnabled = (locationId: string) => {
    return async (dispatch: AppDispatch): Promise<boolean | null> => {
        try {
            const writebackData = await Api.Payments.getPaymentsWritebackEnabled(locationId)

            return writebackData.data.enableWriteback
        } catch (err) {
            return null
        }
    }
}

export const savePaymentsAllowWriteback = (locationId: string, allowWriteback: boolean) => {
    return async (dispatch: AppDispatch, getState: () => RootState): Promise<boolean | null> => {
        try {
            const accountId = getState().app.self?.account.id || 'none'
            const saveAllowWriteback = await Api.Payments.savePaymentsAllowWriteback(
                locationId,
                allowWriteback,
                accountId,
            )

            return saveAllowWriteback.data
        } catch (err) {
            return null
        }
    }
}
