import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../appReducer'
import { PatientType } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import {
    fetchLocationsSchedulingData,
    fetchPracticeLocationProcedures,
    fetchPracticeWithLocations,
    fetchProcedureProviders,
} from '../../practices/actions'
import { OnSendMessageParams } from '../chat-tiles-container-hooks/useMessaging'

import {
    appointmentSubscribeToChannel,
    connectToAppointmentSocket,
    createAppointment,
    disconnectFromAppointmentSocket,
    resetForm,
    resetSearchResults,
    searchExistingPatients,
    selectExistingPatient,
    setAppointmentBookingStep,
    setAppointmentDatetime,
    setAppointmentPickerError,
    setBookingError,
    setPending,
    switchDirectSchedulingTab,
    updateFormFields,
    updateSearchFormFields,
} from './actions'
import { DirectSchedulingContextConsumer, DirectSchedulingContextProvider } from './DirectSchedulingContext'
import ExistingPatientTab from './ExistingPatientTab'
import NewPatientTab from './NewPatientTab'

import './DirectScheduling.sass'

export type Props = {
    chat: Models.ChatCenterSelectedChat
    open: boolean
    webCode: Models.WebCode
    onSendMessage: (sendMessageParams: OnSendMessageParams) => void
}

const moduleName = 'direct-scheduling-appointment'

const DirectScheduling = (props: Props) => {
    const { webCode, open, chat } = props
    const practice = useSelector((state: RootState) => state.practices.practices[props.chat.practice.id])
    const booking = useSelector((state: RootState) => state.bookings.directScheduling[props.chat.id])
    const chatConversation = useSelector((state: RootState) => state.chat.conversations[props.chat.channelName])

    const chatHasScheduledAppointment = chatConversation?.messages.some(
        message => message.text.includes('Wonderful! I have booked an appointment for') && !message.is_patient,
    )

    const [locationsSchedulingDataLoaded, setLocationsSchedulingDataLoaded] = useState(false)

    const directSchedulingRef = useRef<HTMLDivElement>(null)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(connectToAppointmentSocket(chat))

        return () => {
            dispatch(disconnectFromAppointmentSocket(chat))
        }
    }, [dispatch])

    useEffect(() => {
        async function fetchLocations() {
            const loadedPractice = await dispatch(fetchPracticeWithLocations(chat.practice.id))

            if (!loadedPractice) {
                return
            }

            await dispatch(fetchLocationsSchedulingData(loadedPractice.id))
            setLocationsSchedulingDataLoaded(true)
        }

        if (chat.practice.id) {
            fetchLocations()
        }
    }, [chat.practice.id, dispatch])

    const handleSwitchDirectSchedulingTab = (chat: Models.ChatMetadata, patientType: PatientType) => () => {
        dispatch(switchDirectSchedulingTab(chat, patientType))
    }

    if (!booking) {
        return <div>Loading...</div>
    }

    const handleSendMessage = (message: string) => {
        props.onSendMessage({
            message,
            chatMetadata: chat,
            channel: chat.channelName,
        })
    }

    const handleAddPatient = () => {
        dispatch(resetForm(chat, true))
    }

    return (
        <div className={moduleName} ref={directSchedulingRef}>
            {booking.isAdditionalPatientBooking && <div className={`${moduleName}__heading`}>Additional Patient</div>}

            <div className={`${moduleName}__tabs`}>
                <div
                    className={`${moduleName}__tab
                            ${booking.selectedTab === PatientType.NEW_PATIENT ? `${moduleName}__tab--selected` : ''}`}
                    onClick={handleSwitchDirectSchedulingTab(booking.chat, PatientType.NEW_PATIENT)}
                >
                    New
                </div>
                <div
                    className={`${moduleName}__tab ${booking.selectedTab === PatientType.EXISTING_PATIENT &&
                        `${moduleName}__tab--selected`}`}
                    onClick={handleSwitchDirectSchedulingTab(booking.chat, PatientType.EXISTING_PATIENT)}
                >
                    Existing
                </div>
            </div>

            <DirectSchedulingContextProvider
                value={{
                    chat,
                    webCode,
                    createAppointment: createAppointment,
                    fetchPracticeLocationProcedures: fetchPracticeLocationProcedures,
                    fetchProcedureProviders: fetchProcedureProviders,
                    onSendMessage: handleSendMessage,
                    practice,
                    resetForm: resetForm,
                    searchExistingPatients: searchExistingPatients,
                    selectExistingPatient: selectExistingPatient,
                    setAppointmentBookingStep: setAppointmentBookingStep,
                    setAppointmentDatetime: setAppointmentDatetime,
                    setAppointmentPickerError: setAppointmentPickerError,
                    appointmentSubscribeToChannel: appointmentSubscribeToChannel,
                    setPending: setPending,
                    tab: booking.selectedTab,
                    updateFormFields: updateFormFields,
                    updateSearchFormFields: updateSearchFormFields,
                    handleAddPatient,
                    setBookingError: setBookingError,
                }}
            >
                <DirectSchedulingContextConsumer>
                    {context =>
                        context && (
                            <React.Fragment>
                                <NewPatientTab
                                    {...context}
                                    isModalOpened={open}
                                    open={booking.selectedTab === PatientType.NEW_PATIENT}
                                    form={booking.newPatient}
                                    appointment={booking.appointment}
                                    isAdditionalPatientBooking={booking.isAdditionalPatientBooking}
                                    chatHasScheduledAppointment={chatHasScheduledAppointment}
                                    locationsSchedulingDataLoaded={locationsSchedulingDataLoaded}
                                    directSchedulingRef={directSchedulingRef}
                                />

                                <ExistingPatientTab
                                    {...context}
                                    isModalOpened={open}
                                    open={booking.selectedTab === PatientType.EXISTING_PATIENT}
                                    form={booking.existingPatient}
                                    appointment={booking.appointment}
                                    searchPatients={booking.searchPatients}
                                    resetSearchResults={resetSearchResults}
                                    isAdditionalPatientBooking={booking.isAdditionalPatientBooking}
                                    chatHasScheduledAppointment={chatHasScheduledAppointment}
                                    locationsSchedulingDataLoaded={locationsSchedulingDataLoaded}
                                    directSchedulingRef={directSchedulingRef}
                                />
                            </React.Fragment>
                        )
                    }
                </DirectSchedulingContextConsumer>
            </DirectSchedulingContextProvider>
        </div>
    )
}

export default DirectScheduling
