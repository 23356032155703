import React from 'react'
import { Modal } from '@mui/material'
import Button, { ButtonClassKey } from '@mui/material/Button'

import './ConfirmModal.sass'

type Props = {
    open: boolean
    title: string
    subtitle: string
    discardText?: string
    confirmText?: string
    onDiscard: () => void
    onConfirm: () => void
    onClose: () => void
}

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const moduleName = 'confirm-modal-v2'

const buttonClassOverrides: ButtonClassOverrides = {
    outlined: `${moduleName}__cancel_btn`,
    contained: `${moduleName}__yes_btn`,
}

const ConfirmModal = ({ title, subtitle, discardText, confirmText, onClose, onDiscard, onConfirm, open }: Props) => {
    return (
        <Modal open={open} onClose={onClose}>
            <div className={`${moduleName}__container`}>
                <div className={`${moduleName}__header`}>
                    <span className={`${moduleName}__close-button`} onClick={onClose}>
                        <i className="material-icons">close</i>
                    </span>
                </div>
                <div className={`${moduleName}__content`}>
                    <div className={`${moduleName}__icon`}>
                        <i className="material-icons">info</i>
                    </div>
                    <div className={`${moduleName}__inner-content`}>
                        <div className={`${moduleName}__title`}>{title}</div>
                        <div className={`${moduleName}__notification`}>{subtitle}</div>
                    </div>
                </div>
                <div className={`${moduleName}__footer`}>
                    <Button variant="outlined" classes={buttonClassOverrides} onClick={onDiscard}>
                        {discardText || 'DISCARD CHANGES'}
                    </Button>
                    <Button variant="contained" type="reset" classes={buttonClassOverrides} onClick={onConfirm}>
                        {confirmText || ' SAVE CHANGES'}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmModal
