import uniqBy from 'lodash/uniqBy'

import { QuestionOption, SurveySectionQuestion } from '../../../../../models/PracticeSurvey'

export const groupProceduresBySpecialty = (question: SurveySectionQuestion) => {
    let proceduresBySpecialty: { [specialty: string]: QuestionOption[] } = {}
    proceduresBySpecialty['all'] = []
    let allProcedures: QuestionOption[] = []
    const sortedOptions = question.answer_schema?.options?.sort((a, b) => a.display_name.localeCompare(b.display_name))
    sortedOptions?.forEach(option => {
        const optionSpecialties = option.metadata?.categories || []
        optionSpecialties.forEach(specialty => {
            if (!proceduresBySpecialty[specialty]) {
                proceduresBySpecialty[specialty] = []
            }
            proceduresBySpecialty[specialty].push(option)
            allProcedures.push(option)
        })
    })
    proceduresBySpecialty['all'] = uniqBy(allProcedures, 'id')

    return proceduresBySpecialty
}

export const capitalizeSpecialty = (str: string) => {
    const strArr = str.split(' ')
    const capitalized = strArr.map(str => str.charAt(0).toUpperCase() + str.slice(1))
    return capitalized.join(' ')
}

export const formatSpecialtyName = (specialty: string) => {
    return capitalizeSpecialty(specialty.replace('-', ' '))
}
