import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import classNames from 'classnames'
import moment from 'moment'

import { logout, setRedirect } from '../../appActions'
import { RootState } from '../../appReducer'
import { AccessChatCenter, AccessHeartbeatConfiguration, AccessYext } from '../../models/enums'
import { useAppDispatch } from '../../util/useAppDispatch'
import { useAsync } from '../../util/useAsync'
import { fetchNumberOfReferralsInReview } from '../referrals/v2actions'
import { fetchYextLocations } from '../yext/v2actions'

import NavChatColumnsController from './NavChatColumnsController'
import NavSessionTimer from './NavSessionTimer'
import { SelectedList } from './reducer'

import './NavToolbar.sass'

const moduleName = 'nav-toolbar'

const NavToolbar = () => {
    const fetchNumberOfReferralsInReviewIntervalIdRef = useRef<number | null>(null)
    const [menuButton, setMenuButton] = React.useState<undefined | HTMLElement>(undefined)
    const [menuOpen, setMenuOpen] = useState<boolean>(false)
    const [loader, setLoader] = useState(false)

    const self = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const hasYextUserId = useSelector((state: RootState) => state.app.self?.account.yextUserId)
    const yextUrl = useSelector((state: RootState) => state.v2.yext?.yextUrl)
    const selected = useSelector((state: RootState) => state.navigation.selectedList)
    const numberOfReferralsInReview = useSelector((state: RootState) => state.v2.referrals.numberOfReferralsInReview)
    const numChatsOnScreen = useSelector((state: RootState) => state.chat.numChatsOnScreen)
    const paginator = useSelector((state: RootState) => state.chat.paginator)

    const dispatch = useAppDispatch()

    const location = useLocation()

    const { run: runFetchYextLocations, status: statusYextLocations } = useAsync()

    const hasAccessToYext = self?.hasAccess(AccessYext) && hasYextUserId

    useEffect(() => {
        const fetchReferralsInReview = () => {
            dispatch(fetchNumberOfReferralsInReview())
        }
        let fetchNumberOfReferralsInReviewIntervalId = fetchNumberOfReferralsInReviewIntervalIdRef.current
        fetchReferralsInReview()
        fetchNumberOfReferralsInReviewIntervalId = window.setInterval(
            fetchReferralsInReview,
            moment.duration(1, 'minute').asMilliseconds(),
        )
        return () => {
            fetchNumberOfReferralsInReviewIntervalId && window.clearInterval(fetchNumberOfReferralsInReviewIntervalId)
        }
    }, [dispatch])

    useEffect(() => {
        if (hasAccessToYext) dispatch(fetchYextLocations())
    }, [hasAccessToYext, dispatch])

    useEffect(() => {
        if (statusYextLocations === 'resolved') {
            setLoader(false)
            window.open(yextUrl, '_blank', 'noopener,noreferrer')
        }
    }, [statusYextLocations, yextUrl])

    const isSelected = (list: SelectedList) => {
        return selected === list
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const isChatCenter = () => {
        return location.pathname.startsWith('/chat-center')
    }

    const onClickYextLink = () => {
        runFetchYextLocations(dispatch(fetchYextLocations()))
        setLoader(true)
    }

    const renderMenu = () => {
        const formattedNumberOfReferralsInReview = numberOfReferralsInReview <= 9 ? numberOfReferralsInReview : '9+'

        if (!self) {
            return <div />
        }

        const selectedClass = `${moduleName}__page-link--selected`

        return (
            <React.Fragment>
                <div
                    className={classNames(`${moduleName}__page-link`, {
                        [selectedClass]: isSelected('practices'),
                    })}
                    onClick={() => dispatch(setRedirect('/practices'))}
                >
                    Practices
                </div>
                <div
                    className={classNames(`${moduleName}__page-link`, {
                        [selectedClass]: isSelected('diagnostics'),
                    })}
                    style={{ cursor: 'not-allowed' }}
                >
                    Diagnostics
                </div>
                {self.isSuperAdmin && (
                    <div
                        className={classNames(`${moduleName}__page-link`, {
                            [selectedClass]: isSelected('admins'),
                        })}
                        onClick={() => dispatch(setRedirect('/admins'))}
                    >
                        Admin Users
                    </div>
                )}
                {self.hasAccess(AccessChatCenter) && (
                    <div
                        className={classNames(`${moduleName}__page-link`, {
                            [selectedClass]: isSelected('chatCenter'),
                        })}
                        onClick={() => dispatch(setRedirect('/chat-center'))}
                    >
                        Chat Center
                    </div>
                )}
                {self.isPartnerAdmin && (
                    <div
                        className={classNames(`${moduleName}__page-link`, {
                            [selectedClass]: isSelected('referrals'),
                        })}
                        onClick={() => dispatch(setRedirect('/referrals'))}
                    >
                        Requests
                        {numberOfReferralsInReview !== 0 && (
                            <div className={`${moduleName}__badge`}>{formattedNumberOfReferralsInReview}</div>
                        )}
                    </div>
                )}
                {self.hasAccess(AccessHeartbeatConfiguration) && (
                    <div
                        className={classNames(`${moduleName}__page-link`, {
                            [selectedClass]: isSelected('heartbeat'),
                        })}
                        onClick={() => dispatch(setRedirect('/heartbeat'))}
                    >
                        Heartbeat
                    </div>
                )}
                {self.hasAccess(AccessYext) && hasYextUserId && yextUrl && (
                    <div
                        className={classNames(`${moduleName}__page-link-yext`, {
                            [selectedClass]: isSelected('yext'),
                        })}
                        onClick={onClickYextLink}
                    >
                        Yext Info<i className={`${moduleName}__launch-icon material-icons `}>launch</i>
                        {loader && <CircularProgress size={5} color="primary" variant="indeterminate" />}
                    </div>
                )}
                <NavSessionTimer />
            </React.Fragment>
        )
    }

    if (!self) {
        return <div />
    }

    return (
        <div className={moduleName} title={self.username}>
            <div className={`${moduleName}__navigation`}>
                <div
                    className={`${moduleName}__quick-menu`}
                    ref={input => (input ? setMenuButton(input) : setMenuButton(undefined))}
                >
                    <Button style={{ padding: '6px 8px' }} onClick={toggleMenu}>
                        <Icon className={`${moduleName}__menu-icon`}>menu</Icon>
                    </Button>
                    <Menu
                        className={`${moduleName}__menu-list`}
                        anchorEl={menuButton}
                        open={menuOpen}
                        onClose={toggleMenu}
                    >
                        <MenuItem className={`${moduleName}__menu-item`} disabled={true}>
                            {self.username}
                        </MenuItem>
                        <div className={`${moduleName}__mobile-menu`}>{renderMenu()}</div>
                        <MenuItem className={`${moduleName}__menu-item`} onClick={() => dispatch(logout)}>
                            Log Out
                        </MenuItem>
                    </Menu>
                </div>
                <div className={`${moduleName}__desktop-menu`}>{renderMenu()}</div>
                {isChatCenter() && (
                    <NavChatColumnsController numChatsOnScreen={numChatsOnScreen} paginator={paginator} />
                )}
            </div>
        </div>
    )
}

export default NavToolbar
