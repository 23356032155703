import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { oneLine } from 'common-tags'

import 'react-phone-number-input/style.css'

import './CustomPhoneInput.sass'

type EventProps = {
    onChange?: (value: string) => void
    onCountryChange?: (countryCode: string) => void
}
type PhoneFieldProps = {
    error?: boolean | string
    errorMessage?: string
    warning?: boolean | string
    warningMessage?: string
    placeholder?: string
    country?: string
    label?: string
    value?: string
    disabled?: boolean
    helperText?: string
    rules?: { required: boolean }
}
type Props = EventProps & PhoneFieldProps

export const CustomPhoneInput = ({
    placeholder,
    value,
    country,
    label,
    error,
    errorMessage,
    warning = false,
    warningMessage = '',
    disabled,
    helperText,
    onChange,
    onCountryChange,
}: Props) => {
    const [active, setActive] = useState(false)

    const onChangeState = (value: string) => onChange && onChange(value)
    const onCountryChangeState = (countryCode: string) => onCountryChange && onCountryChange(countryCode)

    const onFocus = () => {
        setActive(true)
    }
    const onBlur = () => {
        setActive(false)
    }

    return (
        <div className="phone-custom-field__container">
            <div
                className={oneLine`
                        phone-custom-field
                        ${error ? 'phone-custom-field--error' : ''}
                        ${warning && !error ? 'phone-custom-field--warning' : ''}
                        ${disabled ? 'phone-custom-field--disabled' : ''}
                        ${active ? 'phone-custom-field--active' : ''}
                    `}
            >
                {label && (
                    <label htmlFor="phoneId" className="phone-custom-field__label">
                        {label}
                    </label>
                )}

                <PhoneInput
                    id="phoneId"
                    placeholder={placeholder}
                    initialValueFormat="national"
                    value={value}
                    defaultCountry={country}
                    country={country}
                    onChange={onChangeState}
                    onCountryChange={onCountryChangeState}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
                {error ? (
                    <span className="phone-custom-field__helper-text">{errorMessage}</span>
                ) : warning ? (
                    <span className="phone-custom-field__helper-text">{warningMessage}</span>
                ) : (
                    helperText && <span className="phone-custom-field__helper-text">{helperText}</span>
                )}
            </div>
        </div>
    )
}

export default CustomPhoneInput
