import * as React from 'react'

import EnableProviderSelectionToggle from './EnableProviderSelectionToggle'

import './WebCodeCustomizationDirectScheduling.sass'

type Props = {
    customization: Api.WebCodeCustomization
    updateEnableProviderSelection: (enabled: boolean) => void
}

const moduleName = 'customize-direct-scheduling'

const WebCodeCustomizationDirectScheduling = (props: Props) => {
    const { customization, updateEnableProviderSelection } = props
    const directSchedulingCustomization = customization.direct_scheduling

    return (
        <div className={moduleName}>
            <EnableProviderSelectionToggle
                enabled={directSchedulingCustomization && directSchedulingCustomization.enable_provider_selection}
                updateEnableProviderSelection={updateEnableProviderSelection}
            />
        </div>
    )
}

export default WebCodeCustomizationDirectScheduling
