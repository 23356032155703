import { DayOfWeek, OperationDay } from '../../../models/PracticeSurvey'

const days: DayOfWeek[] = [1, 2, 3, 4, 5, 6, 7]

export const mapHours = (hours: ApiV2.Survey.OperatingHours[]): OperationDay[] => {
    const mappedHours = hours.reduce(
        (acc, day) => ({
            ...acc,
            [day.day_of_week]: {
                open: day.open_at,
                close: day.close_at,
                enabled: day.enabled,
            },
        }),
        {},
    )

    const result: OperationDay[] = []

    days.forEach(dayOfWeek => {
        if (!mappedHours[dayOfWeek]) {
            result.push({
                dayOfWeek,
                open: '09:00',
                close: '17:00',
                enabled: true,
            })
        } else {
            result.push({
                dayOfWeek,
                ...mappedHours[dayOfWeek],
            })
        }
    })

    return result
}
