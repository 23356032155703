import { mapConnectWebCodesV2 } from '../../Api/mappers/connect'
import { mapAmplifyWebcodeConnectSurveysV2 } from '../../ApiV2/mappers/survey/webcodes'
import ApiService from '../../ApiV2/service'

export default class SelfSchedulingService extends ApiService {
    async getConnectSurveys(practiceId: string, params: { search: string; page: number; sort?: string }) {
        const { data, metadata } = (await this.fetch(
            `practices/${practiceId}/amplify-web-codes`,
            {
                method: 'GET',
            },
            { ...params, limit: 10 },
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.Amplify.WebCode>>
        return { data: mapConnectWebCodesV2(data), metadata }
    }

    async getConnectSurveyById(amplifyWebcodeId: string, connectSurveyId: string) {
        const { data: connectSurveyData } = (await this.fetch(
            `amplify-web-codes/${amplifyWebcodeId}/connect-surveys/${connectSurveyId}?include[]=connect_available_hours&include[]=connect_insurance_information_status&include[]=connect_patient_type&include[]=connect_status`,
            {
                method: 'GET',
            },
        )) as ApiV2.StructuredResponse<ApiV2.Connect.ConnectSurveyFullV2>

        return mapAmplifyWebcodeConnectSurveysV2(connectSurveyData)
    }

    async getConnectSurveyStatusById(amplifyWebcodeId: string, connectSurveyId: string) {
        const { data: connectSurveyData } = (await this.fetch(
            `amplify-web-codes/${amplifyWebcodeId}/connect-surveys/${connectSurveyId}?include[]=connect_status`,
            {
                method: 'GET',
            },
        )) as ApiV2.StructuredResponse<ApiV2.Connect.ConnectSurveyV2>

        return mapAmplifyWebcodeConnectSurveysV2(connectSurveyData)
    }
}
