import { useCallback } from 'react'
import Slider from 'react-slick'

import { turnChatsPage } from '../actions'

import useKeyNavigation from './useKeyNavigation'
import usePaginationNavigation from './usePaginationNavigation'

type useChatSliderNavigationProps = {
    slider: React.MutableRefObject<Slider | undefined>
    selectedChats: Models.ChatCenterSelectedChat[]
    paginator: Models.ChatCenterPaginator
    focusedChatIndex: number
    turnChatsPage: typeof turnChatsPage
    focusChat: (chatIndex: number) => void
}

const useChatSliderNavigation = (props: useChatSliderNavigationProps) => {
    const { paginator, slider, turnChatsPage, focusedChatIndex, selectedChats, focusChat } = props

    const prevChatTile = useCallback(() => {
        if (focusedChatIndex <= 0) {
            return
        }

        focusChat(focusedChatIndex - 1)
    }, [focusedChatIndex, focusChat])

    const nextChatTile = useCallback(() => {
        if (focusedChatIndex >= selectedChats.length - 1) {
            return
        }

        const nextSlideIndex = focusedChatIndex + 1
        const isNextSlideVisible = selectedChats[nextSlideIndex]?.visible

        if (isNextSlideVisible) {
            focusChat(nextSlideIndex)
        } else {
            slider.current?.slickNext()
            focusChat(nextSlideIndex)
        }
    }, [slider, selectedChats, focusedChatIndex, focusChat])

    useKeyNavigation({ prevChatTile, nextChatTile })
    usePaginationNavigation({
        focusedChatIndex,
        paginator,
        selectedChats,
        turnChatsPage,
        prevChatTile,
        nextChatTile,
    })
}

export default useChatSliderNavigation
