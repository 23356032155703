import React, { Fragment, useEffect, useState } from 'react'
import { Button, Switch } from '@mui/material'
import moment from 'moment'

import { DayOfWeek } from '../../../models/Connect'
import CustomTimePicker from '../../shared/custom-fields/custom-time-picker/CustomTimePicker'

import './EditableAvailableHours.sass'

const moduleName = 'editable-available-hours'

export type HourUpdate = {
    startTime: string
    endTime: string
    dayIndex: DayOfWeek
    available: boolean
}

export type EditableAvailableHoursProps = {
    dayName: string
    dayIndex: DayOfWeek
    startTime: string
    endTime: string
    editButtons?: boolean
    className?: string
    available: boolean
    onChange: (hourUpdate: HourUpdate) => void
    onSave: (hourUpdate: HourUpdate) => void
}

type Props = EditableAvailableHoursProps

const minDuration = 5

const EditableAvailableHours = (props: Props) => {
    const [edit, setEdit] = useState<boolean>(false)
    const [showButtons, setShowButtons] = useState<boolean>(false)
    const [startTime, setStartTime] = useState<any>(props.startTime || '')
    const [endTime, setEndTime] = useState<any>(props.endTime || '')
    const [available, setAvailable] = useState<boolean>(Boolean(props.available))
    const [prevStartTime, setPrevStartTime] = useState<any>(props.startTime || '')
    const [prevEndTime, setPrevEndTime] = useState<any>(props.endTime || '')

    const { editButtons } = props

    useEffect(() => {
        if (editButtons) {
            setShowButtons(true)
        }
    }, [editButtons])

    useEffect(() => {
        setEdit(false)
    }, [available])

    const openEditView = () => {
        setEdit(true)
    }

    const handleStartTimeChange = (time: string) => {
        const timeCalc = moment(time, 'HH:mm')
            .add(minDuration, 'minutes')
            .format('HH:mm')
        if (endTime < timeCalc) {
            setEndTime(timeCalc)
        }
        setStartTime(time)
        if (!showButtons) {
            props.onChange({ startTime: time, endTime, dayIndex: props.dayIndex, available })
        }
    }

    const handleEndTimeChange = (time: string) => {
        const timeCalc = moment(time, 'HH:mm')
            .subtract(minDuration, 'minutes')
            .format('HH:mm')
        if (startTime > timeCalc) {
            setStartTime(timeCalc)
        }
        setEndTime(time)
        if (!showButtons) {
            props.onChange({ startTime, endTime: time, dayIndex: props.dayIndex, available })
        }
    }

    const handleChangeTimeAvailability = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        setAvailable(checked)
        if (editButtons) {
            props.onSave({
                startTime: props.startTime,
                endTime: props.endTime,
                dayIndex: props.dayIndex,
                available: checked,
            })
        } else {
            props.onChange({
                startTime: props.startTime,
                endTime: props.endTime,
                dayIndex: props.dayIndex,
                available: checked,
            })
        }
    }

    const onCancel = () => {
        setEdit(false)
        if (prevStartTime) {
            setStartTime(prevStartTime)
        }
        if (prevEndTime) {
            setEndTime(prevEndTime)
        }
    }

    const onSave = () => {
        setPrevStartTime(startTime)
        setPrevEndTime(endTime)
        props.onSave({ startTime, endTime, dayIndex: props.dayIndex, available })
        setEdit(false)
    }

    let showInput = showButtons ? edit : !edit
    let showEditPen = showButtons && !edit
    let showSaveCancel = showButtons && edit

    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__field-input-container`}>
                <div className={props.className ? props.className : `${moduleName}__field-input`}>
                    <div>
                        <Switch color="primary" checked={available} onChange={handleChangeTimeAvailability} />
                    </div>
                    <div className={`${moduleName}__day-name`}>{props.dayName}</div>
                    {!available ? (
                        <span className={`${moduleName}__closed`}>Closed</span>
                    ) : (
                        <Fragment>
                            {showInput ? (
                                <React.Fragment>
                                    <div className={`${moduleName}__start-time`}>
                                        <div className={`${moduleName}__time-picker-field`}>
                                            <CustomTimePicker
                                                value={startTime}
                                                onChange={handleStartTimeChange}
                                                timeFormat="HH:mm"
                                            />
                                        </div>
                                    </div>
                                    <div className={`${moduleName}__end-time`}>
                                        <div className={`${moduleName}__time-picker-field`}>
                                            <CustomTimePicker
                                                value={endTime}
                                                onChange={handleEndTimeChange}
                                                timeFormat="HH:mm"
                                            />
                                        </div>
                                    </div>
                                    <div className={`${moduleName}__timezone`}></div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className={`${moduleName}__start-time ${moduleName}--line-height`}>
                                        {moment(props.startTime, 'HH:mm:ss').format('h:mm a')}
                                    </div>
                                    <div className={`${moduleName}__end-time ${moduleName}--line-height`}>
                                        {moment(props.endTime, 'HH:mm:ss').format('h:mm a')}
                                    </div>
                                    <div className={`${moduleName}__timezone`}></div>
                                </React.Fragment>
                            )}
                            <div className={`${moduleName}__action-buttons-container`}>
                                {showEditPen && (
                                    <div className={`${moduleName}__icon-wrapper`} onClick={openEditView}>
                                        <i className={`material-icons ${moduleName}__icon`}>edit</i>
                                    </div>
                                )}
                                {showSaveCancel && (
                                    <div className={`${moduleName}__action-buttons`}>
                                        <div className={`${moduleName}__cancel-button`}>
                                            <Button className={`${moduleName}__save-button`} onClick={onSave}>
                                                Save
                                            </Button>
                                        </div>
                                        <div className={`${moduleName}__cancel-button`}>
                                            <Button color="primary" onClick={onCancel}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EditableAvailableHours
