import { createTheme } from '@mui/material/styles'

import { errorRed, labelGray, mainBlue, offWhite, white } from './modules/shared/styles/colors'

export const Theme = createTheme({
    breakpoints: {
        values: {
            xs: 480,
            sm: 767,
            md: 992,
            lg: 1200,
            xl: 1400,
        },
    },
    palette: {
        primary: {
            main: mainBlue,
            contrastText: white,
        },
        secondary: {
            main: errorRed,
            contrastText: offWhite,
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '0.875rem',
                    letterSpacing: 'normal',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `2px solid ${mainBlue} !important`,
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: labelGray,
                },
            },
        },
    },
})

export const CheckboxTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: mainBlue,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    height: 'auto',
                    padding: '3px',
                    width: 'auto',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    alignItems: 'center',
                    marginLeft: 0,
                    width: '100%',
                },
                label: {
                    fontSize: '0.875rem',
                },
            },
        },
    },
})

export default Theme
