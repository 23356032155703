import iassign from 'immutable-assign'

import { ConnectWebCode } from '../../../models/Connect'

export type ConnectSurveyState = {
    connectSurveys: { [key: string]: Array<ConnectWebCode> }
    connectSurveyStatuses: { [key: string]: Array<{ id: string; status: 'enabled' | 'disabled' }> }
}

import {
    ReceiveConnectSurvey,
    ReceiveConnectSurveysList,
    ReceiveConnectSurveyStatus,
    UpdateFormFields,
} from './actions'

type ConnectAction = ReceiveConnectSurvey | ReceiveConnectSurveyStatus | UpdateFormFields | ReceiveConnectSurveysList
const initialState: ConnectSurveyState = {
    connectSurveys: {},
    connectSurveyStatuses: {},
}

export function reducer(state: ConnectSurveyState = initialState, action: ConnectAction) {
    switch (action.type) {
        case 'RECEIVE_CONNECT_SURVEY': {
            return iassign(
                state,
                next => next.connectSurveys[action.practice.id],
                nextConnectWebCodes => {
                    const index = nextConnectWebCodes.findIndex(
                        connectWebCode => connectWebCode.id === action.webCodeId,
                    )
                    nextConnectWebCodes[index].connectSurvey = action.survey
                    return nextConnectWebCodes
                },
            )
        }
        case 'RECEIVE_CONNECT_SURVEY_STATUS': {
            return iassign(
                state,
                next => next.connectSurveyStatuses[action.practiceId],
                nextConnectWebCodesSurveyStatuses => {
                    const index = nextConnectWebCodesSurveyStatuses.findIndex(
                        connectWebCode => connectWebCode.id === action.webCodeId,
                    )
                    nextConnectWebCodesSurveyStatuses[index] = {
                        id: action.webCodeId,
                        status: action.survey.status.value,
                    }
                    return nextConnectWebCodesSurveyStatuses
                },
            )
        }
        case 'RECEIVE_CONNECT_SURVEYS_LIST': {
            const state1 = iassign(
                state,
                next => next.connectSurveys[action.practice.id],
                nextConnectWebCodes => {
                    nextConnectWebCodes = action.connectWebCodes
                    return nextConnectWebCodes
                },
            )

            return iassign(
                state1,
                next => next.connectSurveyStatuses[action.practice.id],
                nextConnectWebCodesSurveyStatuses => {
                    nextConnectWebCodesSurveyStatuses = action.connectWebCodes.map(connectWebCode => ({
                        id: connectWebCode.id,
                        status: 'disabled',
                    }))
                    return nextConnectWebCodesSurveyStatuses
                },
            )
        }

        default:
            return state
    }
}
