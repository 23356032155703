import * as React from 'react'
import { connect } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'
import moment from 'moment'

import { RootState } from '../../appReducer'
import AdminAccount from '../../models/AdminAccount'
import { savePractice } from '../practices/actions'

import CopyText from './CopyText'
import InlineEditableText from './InlineEditableText'
import ManageSalesforceType from './ManageSalesforceType'
import { SimplifeyeLogoIconSmall, VyneLogoIconSmall } from './svgIcons'

import './PracticeHeader.sass'

export type PracticeHeaderProps = {
    account?: AdminAccount
    practice: Models.Practice
    canEdit?: boolean
    className?: string
}

export type PracticeHeaderDispatch = {
    savePractice: typeof savePractice
}

export type PracticeHeaderState = {
    showSettingsModal: boolean
}

type Props = PracticeHeaderProps & PracticeHeaderDispatch

class PracticeHeaderView extends React.Component<Props, PracticeHeaderState> {
    get initialState() {
        return {
            showSettingsModal: false,
        }
    }

    constructor(props: Props) {
        super(props)

        this.state = this.initialState
    }

    onCloseModal = () => {
        this.setState(this.initialState)
    }

    onShowModal = () => {
        this.setState({ showSettingsModal: true })
    }

    onSavePracticeName = (name: string) => {
        return this.props.savePractice(this.props.practice, { name })
    }

    render() {
        const { account, practice, className } = this.props

        if (!account) {
            return (
                <div className="circular-progress-loader">
                    <CircularProgress size={70} color="primary" variant="indeterminate" />
                </div>
            )
        }

        const isInactive = practice.products.every(p => !p.active) || !practice.active

        const { canEdit } = this.props

        const isVyneCreated = practice.isVyneCreated
        const isVyneSalesForce = practice.salesforceType === 'vyne'

        return (
            <div className={classNames('practice-header', className)}>
                <div className="info">
                    {isVyneCreated ? (
                        <div className="practice-header__non-editable-text">{practice.name}</div>
                    ) : (
                        <InlineEditableText
                            className="title"
                            textClassName="practice-header__text"
                            editable={canEdit ?? true}
                            text={practice.name}
                            onSave={this.onSavePracticeName}
                        />
                    )}
                    <div className="db-id">
                        Practice ID:&nbsp;
                        <CopyText text={practice.id}>{practice.id} &nbsp;</CopyText>
                    </div>
                    <div className="created">Registered On: {moment(practice.created).format('MM/DD/YYYY')}</div>
                </div>
                <div className="status">
                    <div className="company-logo-icon" onClick={this.onShowModal}>
                        {isVyneCreated || isVyneSalesForce ? <VyneLogoIconSmall /> : <SimplifeyeLogoIconSmall />}
                    </div>
                    <div className={classNames('dot', { inactive: isInactive })} />
                    <div className="label">{isInactive ? 'Disabled' : 'Active'} Account</div>
                </div>
                {this.state.showSettingsModal && (
                    <Modal
                        className="pst-modal"
                        open={true}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.onCloseModal()
                            }
                        }}
                        disableEscapeKeyDown={true}
                    >
                        <div className="contents manage-products-modal">
                            <div className="close-modal-button" onClick={this.onCloseModal}>
                                <i className="material-icons">close</i>
                            </div>
                            <ManageSalesforceType practice={practice} onClose={this.onCloseModal} />
                        </div>
                    </Modal>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState, props: PracticeHeaderProps) => ({
    account: state.app.self && state.app.self.account,
    practice: props.practice,
})

const PracticeHeader = connect(mapStateToProps, {
    savePractice,
})(PracticeHeaderView as any)

export default PracticeHeader
