import { alpha, styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'

import { clearGreen } from '../styles/colors'

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: clearGreen,
        '&:hover': {
            backgroundColor: alpha(clearGreen, theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: clearGreen,
    },
}))

export default GreenSwitch
