import React from 'react'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { RootState } from 'appReducer'

import * as Factory from '../../../modules/referrals/referral-form/factory'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { receiveFormOtherPatientData } from '../v2actions'

import ReferralAdditionalPatient from './ReferralAdditionalPatient'

import './ReferralAdditionalPatient.sass'

type Props = {
    failedSave: boolean
    timezone?: string
}

const ReferralAdditionalPatients = (props: Props) => {
    const dispatch = useAppDispatch()

    const { failedSave, timezone } = props

    const otherPatients = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.otherPatients.value,
    ) as ModelsV2.Referrals.ReferralPatient[]

    const onAdditionalPatient = () => {
        if (otherPatients?.length) {
            dispatch(receiveFormOtherPatientData([...otherPatients, Factory.ReferralPatientFactory]))
        } else {
            dispatch(receiveFormOtherPatientData([Factory.ReferralPatientFactory]))
        }
    }

    return (
        <>
            <Grid item={true} xs={12}>
                {otherPatients &&
                    otherPatients.map((patient, index) => (
                        <div className="add-patient-container" key={index}>
                            <p>{index + 1}. Patient</p>
                            <ReferralAdditionalPatient
                                patient={patient}
                                directScheduled={Boolean(patient.scheduling_appointment_id)}
                                index={index}
                                failedSave={failedSave}
                                timezone={timezone}
                            />
                        </div>
                    ))}
            </Grid>
            <Grid item={true} xs={12}>
                <div className="add-patient">
                    <div className="text-between-lines">
                        <span className="add-patient-button" onClick={onAdditionalPatient}>
                            <i className="material-icons person-add-icon">person_add</i> ADD ANOTHER PATIENT
                        </span>
                    </div>
                </div>
            </Grid>
        </>
    )
}

export default ReferralAdditionalPatients
