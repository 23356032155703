import ApiService from '../service'

export default class SurveyService extends ApiService {
    readonly ModulePath: string = 'api/admin/survey/v1'

    async getResponses(surveyParams: Models.GetSurveyResponse) {
        const { id, practiceSpecialtyValue, includeConnectHours, webCodeKey } = surveyParams
        const queryParams: Models.GetSurveyResponse = { id, practiceSpecialtyValue, webCodeKey }

        if (includeConnectHours) {
            queryParams.includeConnectHours = includeConnectHours
        }

        try {
            const { data } = (await this.fetch('response', {}, queryParams)) as Api.StructuredResponse<
                Array<Api.Survey.Response>
            >
            return data
        } catch (error) {
            return error
        }
    }
}
