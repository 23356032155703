import * as React from 'react'
import { Grid } from '@mui/material'

import { CustomFieldType } from '../../../models/enums'
import { receiveFormValueChange } from '../../../modules/referrals/referral-form/v2actions'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CustomField from '../../shared/custom-fields/CustomField'
import { ReferralFormSections } from '../shared/enums'

import './ReferralNotes.sass'

type Props = {
    patient_data?: ModelsV2.Referrals.ReferralPatient
}

const ReferralAdditionalInfo = (props: Props) => {
    const { patient_data } = props

    const dispatch = useAppDispatch()

    const onChangeValue = (field: string, value: string | boolean) => {
        dispatch(receiveFormValueChange(ReferralFormSections.PRIMARY_PATIENT, field, value))
    }
    return (
        <div className="referral-notes">
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <CustomField
                        customFieldType={CustomFieldType.TEXTAREA}
                        label="Additional Notes"
                        rows={2}
                        autoSize={true}
                        value={patient_data?.notes || ''}
                        autosizeOnUpdate={true}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            const value = event.target.value
                            onChangeValue('notes', value)
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12}>
                    <CustomField
                        customFieldType={CustomFieldType.TEXTAREA}
                        label="How did they hear about the office?"
                        rows={1}
                        autoSize={true}
                        autosizeOnUpdate={true}
                        value={patient_data?.inbound_source || ''}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            const value = event.target.value
                            onChangeValue('inbound_source', value)
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default ReferralAdditionalInfo
