import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce, sortBy } from 'lodash'

import { RootState } from '../../../appReducer'
import { ConnectWebCode } from '../../../models/Connect'
import { SearchBar } from '../../shared/custom-fields/SearchBar'
import ExpandablePanel from '../../shared/expandable-panel/ExpandablePanelControlled'
import InfoMessage from '../../shared/info-message/info-message'
import Paginator from '../../shared/Paginator'

import { fetchConnectSurvey, fetchPaginatedConnectSurveyList } from './actions'
import { WebCodeSurveyDirty } from './SurveyForm'
import SurveyForm from './SurveyForm'

import './WebCodesAccordion.sass'

export type Props = {
    practice: Models.Practice
    onBack: () => void
}

const DEFAULT_SORT = 'url'

const moduleName = 'webcodes-accordion'

const WebCodesAccordion = ({ practice, onBack }: Props) => {
    const accordionRef = useRef<HTMLDivElement>(null)
    const initLoadComplete = useRef<boolean>(false)

    const dispatch = useDispatch()

    const [isLeavePageModalOpen, setIsLeavePageModalOpen] = useState<boolean>(false)
    const [clickedAction, setClickedAction] = useState<HTMLElement | undefined>()
    const [isAccordionDirty, setIsAccordionDirty] = useState<WebCodeSurveyDirty[]>([])
    const [expandedWebCodeId, setExpandedWebcodeId] = useState<string>('')
    const [searchValue, setSearchValue] = useState<string>('')
    const [isListLoading, setIsListLoading] = useState<boolean>(true)
    const [firstListLoadDone, setFirstListLoadDone] = useState<boolean>(false)
    const [hasSearch, setHasSearch] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [paginationInfo, setPaginationInfo] = useState<Models.PaginationInfo>({
        allPages: 1,
        allRows: 0,
    })

    const connectWebCodes = useSelector((state: RootState) => state.connectSurveys?.connectSurveys?.[practice.id])
    const connectWebCodesSurveyStatuses = useSelector(
        (state: RootState) => state.connectSurveys?.connectSurveyStatuses?.[practice.id],
    )

    const noRecordsFound = !isListLoading && searchValue === '' && connectWebCodes?.length === 0

    const onFetchConnectSurveyList = useCallback(
        async (nextPage: number, search: string) => {
            try {
                setCurrentPage(nextPage)
                setIsListLoading(true)
                const { data, metadata } = await dispatch(
                    fetchPaginatedConnectSurveyList(practice, { search, page: nextPage, sort: DEFAULT_SORT }),
                )
                setIsAccordionDirty(
                    data.map((webCode: ConnectWebCode) => {
                        return { webCodeId: webCode.id, isDirty: false }
                    }),
                )
                setPaginationInfo({
                    allPages: metadata?.pagination_info?.allPages || nextPage,
                    allRows: metadata?.pagination_info?.allRows || 0,
                })
                setIsListLoading(false)
                initLoadComplete.current = true
            } catch (_) {
                setIsListLoading(false)
            }
        },
        [dispatch, practice],
    )

    const handleDebouncedSearch = useMemo(() => debounce(onFetchConnectSurveyList, 300), [onFetchConnectSurveyList])

    useEffect(() => {
        if (!initLoadComplete.current) {
            onFetchConnectSurveyList(1, '')
        }
    }, [onFetchConnectSurveyList])

    useEffect(() => {
        if (initLoadComplete.current) {
            handleDebouncedSearch(1, searchValue)
        }
    }, [handleDebouncedSearch, searchValue])

    useEffect(() => {
        if ((paginationInfo.allPages > 1 || searchValue.trim() !== '') && !firstListLoadDone) {
            setHasSearch(true)
            setFirstListLoadDone(true)
        }
    }, [paginationInfo, searchValue, firstListLoadDone])

    const openLeavePageModal = useCallback(() => {
        setIsLeavePageModalOpen(true)
    }, [setIsLeavePageModalOpen])

    const getParentElementWithClass = useCallback((element: HTMLElement, classname: string): HTMLElement | null => {
        if (typeof element.className === 'string' && element.className.split(' ').indexOf(classname) >= 0) {
            return element
        }

        return element.parentElement && getParentElementWithClass(element.parentElement, classname)
    }, [])

    const isWebcodeExpanded = (webcodeId: string) => {
        return Boolean(webcodeId === expandedWebCodeId)
    }

    const setExpandedPanel = (webcodeId: string, connectSurveyId?: string) => async (toggle: boolean) => {
        if (connectSurveyId) {
            await dispatch(fetchConnectSurvey(practice, webcodeId, connectSurveyId))
        }

        setExpandedWebcodeId(toggle ? webcodeId || '' : '')
    }

    const isLeavePageClick = useCallback(
        (element: HTMLElement) => {
            const leavePagePracticeClasses = ['amplify-tab__back-button', 'tab']
            const leavePageOutsideClasses = ['nav-toolbar__page-link', 'with-adornment--right', 'paginator-wrapper']
            const practiceElement = getParentElementWithClass(element, 'practice-quickview')
            const hasParentPracticeLeavePageClass = leavePagePracticeClasses.filter(leavePageClass => {
                return Boolean(getParentElementWithClass(element, leavePageClass))
            })

            const hasParentOutsideLeavePageClass = leavePageOutsideClasses.filter(leavePageClass => {
                return Boolean(getParentElementWithClass(element, leavePageClass))
            })

            if (hasParentOutsideLeavePageClass.length > 0) {
                return true
            }

            if (practiceElement && hasParentPracticeLeavePageClass.length > 0) {
                const practiceId = practiceElement.id.split('-')[2]
                return practiceId === practice.id
            }
            return false
        },
        [getParentElementWithClass, practice.id],
    )

    const handleClick = useCallback(
        (event: any) => {
            const hasDirty = isAccordionDirty.filter(wc => wc.isDirty === true)
            const openModalConditions = [
                isLeavePageClick(event.target),
                !isLeavePageModalOpen,
                !Boolean(clickedAction),
                hasDirty.length > 0,
            ]

            if (!openModalConditions.includes(false)) {
                setClickedAction(event.target)
                openLeavePageModal()
            }
        },
        [openLeavePageModal, isLeavePageClick, setClickedAction, clickedAction, isLeavePageModalOpen, isAccordionDirty],
    )

    const setSurveyIsDirty = (surveyIsDirty: WebCodeSurveyDirty) => {
        const updated = isAccordionDirty.map(survey =>
            survey.webCodeId === surveyIsDirty.webCodeId ? { ...survey, isDirty: surveyIsDirty.isDirty } : survey,
        )
        if (updated.length > 0) {
            setIsAccordionDirty(updated)
        }
    }

    const onCloseLeavePageModal = () => {
        setIsLeavePageModalOpen(false)
        setClickedAction(undefined)
    }

    const onLeavePage = () => {
        setIsLeavePageModalOpen(false)
        if (clickedAction) {
            clickedAction.click()
            setClickedAction(undefined)
        }
    }

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
        setExpandedWebcodeId('')
    }

    const clearSearch = () => {
        setSearchValue('')
        setExpandedWebcodeId('')
    }

    const onPageChange = (newPage: number) => {
        onFetchConnectSurveyList(newPage, searchValue)
        setExpandedWebcodeId('')
    }

    useLayoutEffect(() => {
        document.addEventListener('mousedown', handleClick)

        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [handleClick])

    const renderCodes = (validConnectWebcodes: ConnectWebCode[]) => {
        const websites = sortBy(validConnectWebcodes, webcode => webcode.url)

        return (
            <React.Fragment>
                {websites.map(connectWebCode => {
                    const enabled =
                        connectWebCodesSurveyStatuses.find(c => c.id === connectWebCode.id)?.status === 'enabled'
                    const title = (
                        <div className={`${moduleName}__${enabled ? `blue-title` : `gray-title`}`}>
                            {connectWebCode.url}
                        </div>
                    )
                    return (
                        <ExpandablePanel
                            key={connectWebCode.id}
                            isExpanded={isWebcodeExpanded(connectWebCode.id)}
                            title={title}
                            onToggle={setExpandedPanel(connectWebCode.id, connectWebCode.connectSurveyId)}
                        >
                            <SurveyForm
                                webCodeId={connectWebCode.id}
                                practice={practice}
                                connectSurvey={connectWebCode.connectSurvey}
                                connectSurveyId={connectWebCode.connectSurveyId}
                                setIsDirty={setSurveyIsDirty}
                            />
                        </ExpandablePanel>
                    )
                })}
            </React.Fragment>
        )
    }

    const renderContent = () => {
        const codesForRender = Array.isArray(connectWebCodes) ? connectWebCodes : []
        if (codesForRender.length > 0) {
            return renderCodes(codesForRender)
        }

        return false
    }

    if (currentPage === 1 && !firstListLoadDone && !connectWebCodes) {
        return (
            <div className={moduleName}>
                <div className="loader loader--global">
                    <CircularProgress className="loader-spinner" size={50} color="primary" variant="indeterminate" />
                </div>
            </div>
        )
    }

    return (
        <div className={moduleName} ref={accordionRef as React.RefObject<any>}>
            <div className="back-button-wrapper">
                <button className="back-button" onClick={onBack}>
                    <i className="material-icons">arrow_back</i>
                </button>
                <div className="search-container">
                    {hasSearch && (
                        <SearchBar
                            value={searchValue}
                            onChange={onSearch}
                            onClear={clearSearch}
                            isMessageShown={searchValue.length > 0 && !connectWebCodes?.length}
                            placeholder="Search by URL"
                        />
                    )}
                    {noRecordsFound && <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>}
                </div>
            </div>
            <ReactModal
                className={`${moduleName}__leave-page-modal`}
                contentLabel="Leave"
                isOpen={isLeavePageModalOpen}
                onRequestClose={onCloseLeavePageModal}
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={true}
                ariaHideApp={false}
                overlayClassName={`${moduleName}__leave-page-modal-overlay`}
            >
                <i className={`${moduleName}__close-button material-icons`} onClick={onCloseLeavePageModal}>
                    close
                </i>

                <div className={`${moduleName}__modal-content`}>
                    <div className={`${moduleName}__modal-icon`}>
                        <i className={`material-icons`} onClick={onCloseLeavePageModal}>
                            error
                        </i>
                    </div>
                    <div className={`${moduleName}__modal-header-text`}>You have unsaved changes.</div>
                    <div className={`${moduleName}__modal-text`}>
                        If you leave this page, any unsaved changes will be lost.
                    </div>
                </div>
                <button className={`${moduleName}__leave-button`} onClick={onLeavePage} type="button" tabIndex={0}>
                    Leave page
                </button>
            </ReactModal>
            {renderContent()}
            {isListLoading && (
                <div className="loader loader--list">
                    <CircularProgress className="loader-spinner" size={50} color="primary" variant="indeterminate" />
                </div>
            )}
            {!noRecordsFound && (
                <div className={`${moduleName}__paginator-wrapper`}>
                    <Paginator currentPage={currentPage} paginationInfo={paginationInfo} selectPage={onPageChange} />
                </div>
            )}
        </div>
    )
}

export default WebCodesAccordion
