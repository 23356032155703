import _ from 'lodash'
import Push from 'push.js'

type SoundId = 'new-message' | 'user-message'

class NotificationService {
    static defaultOptions: Push.PushNotificationParams = {
        timeout: 5000,
    }

    accepted: boolean

    constructor() {
        this.accepted = Push.Permission.has()
    }

    playSound(soundId: SoundId = 'new-message') {
        const soundEl = document.getElementById(`simplifeye-sound-${soundId}`)
        const sound = soundEl && (soundEl as HTMLAudioElement)
        const promise = sound && sound.play().catch(() => true)

        return promise
    }

    notify(title: string, options?: Push.PushNotificationParams) {
        Push.create(title, _.assign({}, NotificationService.defaultOptions, options))
        return this.playSound()
    }

    request(callback?: Function) {
        Push.Permission.request(
            () => {
                this.accepted = true
                return callback && callback(this.accepted)
            },
            () => {
                this.accepted = false
                return callback && callback(this.accepted)
            },
        )
    }
}

export default new NotificationService()
