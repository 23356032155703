import { RootState } from 'appReducer'

import Api from '../../../../Api'
import { setErrorMessage, setSuccessMessage } from '../actions'
import { StatementDescriptorModalState } from '../reducer'

export enum StatementDescriptorModalActionTypes {
    OPEN_STATEMENT_DESCRIPTOR_MODAL = '@LOCATIONS_TAB_STATEMENT_DESCRIPTOR/OPEN_STATEMENT_DESCRIPTOR_MODAL',
    UPDATE_STATEMENT_DESCRIPTOR_MODAL_DATA = '@LOCATIONS_TAB_STATEMENT_DESCRIPTOR/UPDATE_STATEMENT_DESCRIPTOR_MODAL',
    CLOSE_STATEMENT_DESCRIPTOR_MODAL = '@LOCATIONS_TAB_STATEMENT_DESCRIPTOR/CLOSE_STATEMENT_DESCRIPTOR_MODAL',
}

export type OpenStatementDescriptorModal = {
    type: StatementDescriptorModalActionTypes.OPEN_STATEMENT_DESCRIPTOR_MODAL
    practiceId: Models.PracticeLocation['practiceId']
    locationId: Models.PracticeLocation['id']
    statementDescriptor: Models.PracticeLocation['statementDescriptor']
}

export type CloseStatementDescriptorModal = {
    type: StatementDescriptorModalActionTypes.CLOSE_STATEMENT_DESCRIPTOR_MODAL
    practiceId: Models.PracticeLocation['practiceId']
}

export type UpdateStatementDescriptorModalData = {
    type: StatementDescriptorModalActionTypes.UPDATE_STATEMENT_DESCRIPTOR_MODAL_DATA
    data: Partial<StatementDescriptorModalState>
    practiceId: string
}

export function openStatementDescriptorModal(
    locationId: Models.PracticeLocation['id'],
    statementDescriptor: Models.PracticeLocation['statementDescriptor'],
    practiceId: Models.PracticeLocation['practiceId'],
): OpenStatementDescriptorModal {
    return {
        type: StatementDescriptorModalActionTypes.OPEN_STATEMENT_DESCRIPTOR_MODAL,
        practiceId,
        locationId,
        statementDescriptor,
    }
}

export function closeStatementDescriptorModal(practiceId: string): CloseStatementDescriptorModal {
    return {
        type: StatementDescriptorModalActionTypes.CLOSE_STATEMENT_DESCRIPTOR_MODAL,
        practiceId,
    }
}

export function updateStatementDescriptorModalData(
    data: Partial<StatementDescriptorModalState>,
    practiceId: string,
): UpdateStatementDescriptorModalData {
    return {
        type: StatementDescriptorModalActionTypes.UPDATE_STATEMENT_DESCRIPTOR_MODAL_DATA,
        data,
        practiceId,
    }
}

export function updateStatementDescriptorAllLocations(practiceId: string, statementDescriptor: string) {
    return async (dispatch: any, getState: () => RootState) => {
        try {
            const accountId = getState().app.self?.account.id || 'none'
            const updatedPaymentsSettings = await Api.Payments.updateLocationPaymentsSettings(
                accountId,
                { practiceId },
                {
                    statementDescriptor,
                },
            )
            // await dispatch(receiveAccount(practice, paymentsSettings))
            dispatch(setSuccessMessage(`Statement Descriptor for all locations updated successfully.`, practiceId))

            return updatedPaymentsSettings
        } catch (err) {
            dispatch(
                updateStatementDescriptorModalData(
                    {
                        errorMessage: `Error saving Statement Descriptors.`,
                        isLoading: false,
                    },
                    practiceId,
                ),
            )

            dispatch(setErrorMessage(`Error saving Statement Descriptors.`, practiceId))

            throw err
        }
    }
}

export function updateStatementDescriptorSingleLocation(
    practiceId: string,
    locationId: string,
    statementDescriptor: string,
) {
    return async (dispatch: any, getState: () => RootState) => {
        try {
            const accountId = getState().app.self?.account.id || 'none'
            const updatedPaymentsSettings = await Api.Payments.updateLocationPaymentsSettings(
                accountId,
                { locationId },
                {
                    statementDescriptor,
                },
            )
            // await dispatch(receiveAccount(practice, paymentsSettings))
            dispatch(setSuccessMessage(`Statement Descriptor for "${locationId}" updated successfully.`, practiceId))

            return updatedPaymentsSettings
        } catch (err) {
            dispatch(
                updateStatementDescriptorModalData(
                    {
                        errorMessage: `Error saving Statement Descriptor.`,
                        isLoading: false,
                    },
                    practiceId,
                ),
            )

            dispatch(setErrorMessage(`Error saving Statement Descriptor.`, practiceId))

            throw err
        }
    }
}
