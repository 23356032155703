import * as React from 'react'
import classNames from 'classnames'

import CopyText from '../shared/CopyText'

import './DexVoiceAgent.sass'

export type DexVoiceAgentListProps = {
    agent: Models.DexisAgent
}

export type DexVoiceAgentListDispatch = {}

export type DexVoiceAgentListState = {}

type Props = DexVoiceAgentListProps & DexVoiceAgentListDispatch

class DexVoiceAgentList extends React.Component<Props, DexVoiceAgentListState> {
    render() {
        const { agent } = this.props

        return (
            <div className={classNames('dexvoice-agent-list-item')}>
                <div className="info">
                    <div className="room-name">{agent.roomName}</div>
                    <div className="agent-id">
                        Agent ID: &nbsp;
                        <CopyText text={agent.id}>{agent.id} &nbsp;</CopyText>
                    </div>
                </div>
                {!agent.failing && (
                    <div className="status">
                        <i className="material-icons ok">check_circle</i>
                    </div>
                )}
                {agent.failing && (
                    <div className="status">
                        <i className="material-icons error">warning</i>
                    </div>
                )}
            </div>
        )
    }
}

export default DexVoiceAgentList
