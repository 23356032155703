import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import countries from 'countries-list'
import _, { isNumber } from 'lodash'

import { CustomFieldType } from '../../../../../models/enums'
import { LocationInformation } from '../../../../../models/PracticeSurvey'
import CustomField from '../../../../shared/custom-fields/CustomField'
import CustomMultiselectField from '../../../../shared/custom-fields/CustomMultiselectField'
import { getTimeZonesForCountry, getTimeZonesWithAbbreviations } from '../../utils'
import { updateLocationInformation } from '../actions'
import { validator } from '../shared/validator'

import './LocationInformation.sass'

interface Props {
    location: Models.PracticeLocation
}

type CountryData = {
    code: string
    name: string
    phone: string
    locale: string
}

const LocationInformationEdit = ({ location }: Props) => {
    const [timezoneListForCountry, setTimezoneListForCountry] = useState<string[]>([])
    const { locationInformation }: { locationInformation: LocationInformation } = useSelector((state: any) => ({
        locationInformation: state.practiceSurvey[location.id]?.locationInformation,
    }))

    const address = locationInformation.address
    const addressCountryCode = address?.country_code_iso_alpha_2.value
    const addressLatValue = address?.lat.value
    const addressLngValue = address?.lng.value

    useEffect(() => {
        if (addressCountryCode) {
            setTimezoneListForCountry(getTimeZonesForCountry(addressCountryCode))
        }
    }, [addressCountryCode])

    const dispatch = useDispatch()

    const handleChange = async (fieldName: string, value?: string) => {
        const errorMessage = validator(address?.[fieldName], value)
        const nextAddress = { ...address }
        nextAddress[fieldName] = {
            ...nextAddress[fieldName],
            value,
            errorMessage,
            isValid: !errorMessage,
            isDirty: true,
        }
        dispatch(updateLocationInformation(location.id, fieldName, nextAddress[fieldName]))
    }

    const latHelper = useMemo(() => {
        const lat = parseFloat(addressLatValue ?? '')
        if (!isNaN(lat) && isNumber(lat)) {
            return lat < 0 ? 'S' : 'N'
        }
        return ' '
    }, [addressLatValue])

    const lngHelper = useMemo(() => {
        const lng = parseFloat(addressLngValue ?? '')
        if (!isNaN(lng)) {
            return lng < 0 ? 'W' : 'E'
        }
        return ' '
    }, [addressLngValue])

    const sortedCountriesList: CountryData[] = useMemo(
        () =>
            _.orderBy(
                Object.keys(countries.countries).map(code => ({
                    code,
                    name: countries.countries[code].name,
                    phone: countries.countries[code].phone,
                    locale: countries.countries[code].locale,
                })),
                'name',
            ),
        [],
    )

    const locationErrorClass = () => {
        return !locationInformation.isValid ? 'survey-section__header-error' : ''
    }

    return (
        <div className="survey-section survey-section--view survey-location-information">
            {address && (
                <div className="survey-section__wrapper">
                    <h4 className={`survey-section__header ${locationErrorClass()}`} id="location">
                        Location Information*
                    </h4>
                    <Grid container spacing={4} className={'grid-container'}>
                        <Grid item xs={12} md={5} sm={5}>
                            <CustomField
                                label="Address 1*"
                                customFieldType={CustomFieldType.INPUT}
                                value={address.street.value}
                                required={address.street.isRequired}
                                error={address.street.isDirty && !address.street.isValid}
                                errorMessage={address.street.errorMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange('street', event.target.value)
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={2} sm={2}>
                            <CustomField
                                label="Suite"
                                customFieldType={CustomFieldType.INPUT}
                                value={address.unit.value}
                                required={address.unit.isRequired}
                                error={address.unit.isDirty && !address.unit.isValid}
                                errorMessage={address.unit.errorMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange('unit', event.target.value)
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} className={'grid-container'}>
                        <Grid item xs={6} md={3} sm={3}>
                            <CustomField
                                label="City*"
                                customFieldType={CustomFieldType.INPUT}
                                value={address.city.value}
                                required={address.city.isRequired}
                                error={address.city.isDirty && !address.city.isValid}
                                errorMessage={address.city.errorMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange('city', event.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={6} md={1} sm={1}>
                            <CustomField
                                label="State*"
                                customFieldType={CustomFieldType.INPUT}
                                value={address.state.value}
                                required={address.state.isRequired}
                                error={address.state.isDirty && !address.state.isValid}
                                errorMessage={address.state.errorMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange('state', event.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={6} md={2} sm={2}>
                            <CustomField
                                label="ZIP Code*"
                                customFieldType={CustomFieldType.INPUT}
                                value={address.zip.value}
                                required={address.zip.isRequired}
                                error={address.zip.isDirty && !address.zip.isValid}
                                errorMessage={address.zip.errorMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    handleChange('zip', event.target.value)
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} className={'grid-container'}>
                        <Grid item xs={12} md={4} sm={4}>
                            <CustomMultiselectField
                                search
                                items={sortedCountriesList}
                                maxSelected={1}
                                selectedItems={[address.country_code_iso_alpha_2.value || 'US']}
                                keyProperty="code"
                                displayProperty="name"
                                placeholder="Select Country"
                                label={`Country*`}
                                searchPlaceholder="Search Countries"
                                onSelectElement={(values: string[]) =>
                                    handleChange('country_code_iso_alpha_2', values[0] || '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <CustomMultiselectField
                                items={
                                    timezoneListForCountry?.length
                                        ? getTimeZonesWithAbbreviations(timezoneListForCountry)
                                        : []
                                }
                                search={Boolean(timezoneListForCountry?.length > 8)}
                                searchPlaceholder="Search Timezones"
                                maxSelected={1}
                                selectedItems={[address.timezone.value || '']}
                                keyProperty="timezoneIanaTZ"
                                displayProperty="timezoneString"
                                placeholder="Time Zone"
                                label="Time Zone*"
                                onSelectElement={(values: string[]) => handleChange('timezone', values[0] || '')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} className={'grid-container'}>
                        <Grid item xs={12} md={4} sm={4}>
                            <div className="survey-location-information__position">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    label="Latitude*"
                                    value={address.lat.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChange('lat', event.target.value)
                                    }
                                    error={!!address.lat.value && !address.lat.isValid}
                                    errorMessage={address.lat.errorMessage}
                                />
                                <span className="survey-location-information__position-helper">{latHelper}</span>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <div className="survey-location-information__position">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    label="Longitude*"
                                    value={address.lng.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChange('lng', event.target.value)
                                    }
                                    error={!!address.lng.value && !address.lng.isValid}
                                    errorMessage={address.lng.errorMessage}
                                />
                                <span className="survey-location-information__position-helper">{lngHelper}</span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}

export default LocationInformationEdit
