export function mapWebCodeLocationToModel(
    location: Api.SelfScheduling.WebCodeLocation,
): Models.SelfSchedulingWebCodeLocation {
    return {
        name: location.name,
        selfSchedulingWebCodeId: location.self_scheduling_web_code_id,
        practiceLocationId: location.practice_location_id,
        enabled: location.enabled,
        allLocationsEnabled: location.all_locations_enabled,
    }
}

export function mapWebCodeLocationToApi(
    location: Models.SelfSchedulingWebCodeLocation,
): Api.SelfScheduling.WebCodeLocation {
    return {
        name: location.name,
        self_scheduling_web_code_id: location.selfSchedulingWebCodeId,
        practice_location_id: location.practiceLocationId,
        enabled: location.enabled,
        all_locations_enabled: location.allLocationsEnabled,
    }
}

export function mapWebCodeLocationsToApi(
    locations: Models.SelfSchedulingWebCodeLocation[],
): Api.SelfScheduling.WebCodeLocation[] {
    if (!Array.isArray(locations)) {
        return []
    }
    return locations.map((location: Models.SelfSchedulingWebCodeLocation) => mapWebCodeLocationToApi(location))
}

export function mapWebCodeLocationsToModel(
    locations: Api.SelfScheduling.WebCodeLocation[],
): Models.SelfSchedulingWebCodeLocation[] {
    if (!Array.isArray(locations)) {
        return []
    }
    return locations.map((location: Api.SelfScheduling.WebCodeLocation) => mapWebCodeLocationToModel(location))
}
