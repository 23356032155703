import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { setRedirect } from '../../../appActions'
import { RootState } from '../../../appReducer'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchPractice } from '../../practices/actions'
import AvailabilityPracticeHeader from '../../shared/AvailabilityPracticeHeader'

import AvailabilityFilters from './AvailabilityFilters'
import AvailabilityWeeklyView from './AvailabilityWeeklyView'

import './Availability.sass'

export type AvailabilityRouteParams = RouteComponentProps<{
    practiceId: string
}>

type Props = AvailabilityRouteParams

const moduleName = 'availability'

const Availability = (props: Props) => {
    const practiceId = props.match.params.practiceId

    const [loading, setLoading] = useState(false)

    const practice = useSelector((state: RootState) => state.practices.practices[practiceId])
    const selectedLocationId = useSelector((state: RootState) => state.v2.availability.selectedLocationId[practiceId])
    const availabilities = useSelector((state: RootState) => state.v2.availability.availabilities?.[selectedLocationId])

    const practiceFetched = practice?.id

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!practiceFetched) {
            dispatch(fetchPractice(practiceId))
        }
    }, [practiceFetched, practiceId, dispatch])

    const onReturnToPractices = () => {
        dispatch(setRedirect(`/practices`))
    }

    if (!practice) {
        return <div>Loading...</div>
    }

    return (
        <div className={moduleName}>
            <div className="detached-button" onClick={onReturnToPractices}>
                <i className="material-icons">arrow_back</i>
            </div>
            <AvailabilityPracticeHeader practice={practice} />
            <div className={`${moduleName}__title`}>Availability</div>
            <div className={`${moduleName}__subtitle-wrapper`}>
                <div className={`${moduleName}__subtitle`}>View the corresponding schedule.</div>
            </div>
            <AvailabilityFilters practiceId={practiceId} setLoading={setLoading} />
            <AvailabilityWeeklyView data={availabilities} isPending={loading} />
        </div>
    )
}

export default Availability
