import { isValidPhoneNumber } from 'react-phone-number-input'
import validators from 'validator'

import { SurveyFormField, SurveySectionQuestion } from '../../../../../models/PracticeSurvey'

export enum ValidatorPreset {
    FIRST_NAME,
    NAME,
    STRING,
    EMAIL_ADDRESS,
    PHONE_NUMBER,
    LATITUDE,
    LONGITUDE,
    URL,
    SUFFIX,
}

const FIRST_NAME_ALLOWED_CHARS = /^([a-z]+[-'’.]{0,1}[\s]{0,1})*[a-z]+([.]{0,1})$/i
const NAME_ALLOWED_CHARS = /^([a-z]+[-'’.]{0,1}[\s]{0,1})*[a-z]+$/i
const SUFFIX_ALLOWED_CHARS = /^([a-z]+[-'’.]{0,1}[\s]{0,1})*[a-z]+$/i
const FLOAT_REGEXP = /^[+-]?\d+(.\d+)?$/

export const validator = (fieldData: SurveyFormField | SurveySectionQuestion, value?: any): string | undefined => {
    if (fieldData.isRequired && (!value || value.length === 0)) {
        return 'Required'
    }

    if (fieldData.validators) {
        for (const preset of fieldData.validators) {
            switch (preset) {
                case ValidatorPreset.FIRST_NAME:
                    if (!FIRST_NAME_ALLOWED_CHARS.test(value.trim())) {
                        return 'Invalid name'
                    }
                    return
                case ValidatorPreset.NAME:
                    if (!NAME_ALLOWED_CHARS.test(value.trim())) {
                        return 'Invalid name'
                    }
                    return
                case ValidatorPreset.EMAIL_ADDRESS:
                    if (!validators.isEmail(value)) {
                        return 'Invalid Email value'
                    }
                    return
                case ValidatorPreset.PHONE_NUMBER:
                    const phoneNumber = value as string
                    if (!isValidPhoneNumber(phoneNumber)) {
                        return 'Invalid Phone # format'
                    }

                    return
                case ValidatorPreset.LATITUDE:
                    let isLatValid = Boolean(value.match(FLOAT_REGEXP))
                    if (isLatValid) {
                        const lat = parseFloat(value)
                        isLatValid = !isNaN(lat) && lat >= -85 && lat <= 85
                    }
                    if (!isLatValid) {
                        return 'Latitude should be between -85 and 85'
                    }
                    break
                case ValidatorPreset.LONGITUDE:
                    let isLngValid = Boolean(value.match(FLOAT_REGEXP))
                    if (isLngValid) {
                        const lng = parseFloat(value)
                        isLngValid = !isNaN(lng) && lng >= -180 && lng <= 180
                    }
                    if (!isLngValid) {
                        return 'Longitude should be between -180 and 180'
                    }
                    break
                case ValidatorPreset.URL:
                    const url = value as string
                    if (!validators.isURL(url, { require_protocol: true })) {
                        return 'Invalid URL. Example: https://domain.com'
                    }
                    break
                case ValidatorPreset.SUFFIX:
                    if (!SUFFIX_ALLOWED_CHARS.test(value.trim())) {
                        return 'Invalid suffix'
                    }
                    return
                default:
                    return
            }
        }
    }

    return
}
