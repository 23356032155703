import * as React from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'

import { errorRed, fieldBorderGray, labelGray, mainBlue, white } from '../../styles/colors'

type EventProps = {
    onChange: (value: moment.Moment) => void
    onInput?: (event: any) => void
}

type DateFieldProps = {
    required?: boolean
    dateFormat: string
    className?: string
    value: Date | null
    placeholder?: string
    label?: React.ReactNode
    invalidLabel?: string
    minDateMessage?: string
    maxDateMessage?: string
    InputProps?: any
    disablePast?: boolean
    disableFuture?: boolean
    error?: boolean
    keyboard?: boolean
    mask?: (RegExp | string)[]
    autoOk?: boolean
}

type Props = EventProps & DateFieldProps

const materialTheme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
            contrastText: white,
        },
    },
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%',
                'margin-top': '18px',
                'font-size': '12px',
            },
        },
        MuiIconButton: {
            root: {
                width: '32px',
                '&:hover': {
                    'background-color': 'transparent',
                },
            },
        },
        MuiTouchRipple: {
            root: {
                display: 'none',
            },
        },
        MuiInput: {
            root: {
                'background-color': white,
                border: `1px solid ${fieldBorderGray}`,
                'border-radius': '6px',
                height: '38px',
                '&$error': {
                    'border-color': errorRed,
                },
                '&:hover': {
                    'border-color': mainBlue,
                },
            },
            formControl: {
                display: 'flex',
                'align-items': 'center !important',
                'padding-left': '10px',
                'label + &': {
                    'margin-top': '1px',
                },
            },
            underline: {
                '&:after': {
                    display: 'none',
                },
                '&:before': {
                    display: 'none',
                },
            },
        },
        MuiInputLabel: {
            root: {
                color: labelGray,
                'margin-top': '-15px',
                'margin-left': '5px',
            },
            formControl: {
                transform: 'none',
                'font-size': '12px',
            },
            shrink: {
                transform: 'none',
            },
        },
        MuiFormLabel: {
            root: {
                '&$focused': {
                    color: labelGray,
                    'font-size': '12px',
                },
                '&$error': {
                    color: errorRed,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                color: errorRed,
                'margin-top': '5px',
                'margin-left': '5px',
                '&$error': {
                    color: errorRed,
                },
            },
        },
        MuiInputAdornment: {
            positionEnd: {
                'margin-left': 0,
            },
        },
    },
})

export default function CustomInlineDatepicker(props: Props) {
    const onChange = (value: moment.Moment) => props.onChange && props.onChange(value)
    const onInput = (event: React.FormEvent<HTMLDivElement>) => props.onInput && props.onInput(event)

    const {
        required,
        value,
        dateFormat,
        placeholder,
        label,
        className,
        invalidLabel,
        disablePast,
        disableFuture,
        minDateMessage,
        maxDateMessage,
        InputProps,
        error,
        autoOk = true,
    } = props

    return (
        <MuiThemeProvider theme={materialTheme}>
            <KeyboardDatePicker
                autoOk={autoOk}
                color="primary"
                variant="inline"
                label={label}
                className={className}
                invalidLabel={invalidLabel}
                format={dateFormat}
                placeholder={placeholder}
                value={value}
                disablePast={disablePast}
                disableFuture={disableFuture}
                minDateMessage={minDateMessage}
                maxDateMessage={maxDateMessage}
                InputProps={InputProps}
                required={required}
                error={error}
                onInput={onInput}
                onChange={onChange}
                KeyboardButtonProps={{
                    color: 'primary',
                }}
            />
        </MuiThemeProvider>
    )
}
