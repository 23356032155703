import * as React from 'react'
import { Checkbox, FormControlLabel, Modal } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import Radio, { RadioClassKey } from '@mui/material/Radio'
import classNames from 'classnames'
import _ from 'lodash'
import CopyText from 'modules/shared/CopyText'

import { CustomFieldType } from '../../../../models/enums'
import CustomField from '../../../shared/custom-fields/CustomField'

import './LocationPaymentsRates.sass'

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

export interface LocationPaymentsRatesProps {
    locationId: string
    locationName: string
    practice: Models.Practice
    // practiceFeeData: Models.DisplayPracticeFee
    closeLocationPaymentsModal: () => void
    fetchPaymentProcessingData: (practiceId: string, locationId: string) => any
    savePaymentProcessingData: (
        practiceId: string,
        locationId: string,
        merchantFee: number,
        merchantProcessingRate: number,
        terminalFee: number,
        terminalProcessingRate: number,
        debitFee: number,
        debitProcessingRate: number,
        achFee: number,
        achProcessingRate: number,
        insuranceFee: number,
        insuranceProcessingRate: number,
        membershipPlanRate: number,
        monthlyPlatformFee: number,
        monthlyTerminalFee: number,
        prepaidProcessingRate: number,
        prepaidFlatFee: number,
        type: Models.PaymentProcessingType,
        applyToAll: boolean,
    ) => Promise<any>
}

interface LocationPaymentsRatesState {
    applyToAll: boolean
    blockingError: boolean
    buttonDisabled: boolean
    errorMessage: string
    fee: string
    hasLoadedInitial: boolean
    hasUpdated: boolean
    isSaving: boolean
    monthlyPlatformFee: string
    monthlyTerminalFee: string
    percent: string
    radioValue: '' | Models.PaymentProcessingType
    terminalFee: string
    terminalPercent: string
    debitFee: string
    debitPercent: string
    insuranceFee: string
    insurancePercent: string
    achFee: string
    achPercent: string
    membershipPlanPercent: string
    prepaidProcessingRate: string
    prepaidFlatFee: string
}

export default class LocationPaymentsRates extends React.Component<
    LocationPaymentsRatesProps,
    LocationPaymentsRatesState
> {
    paymentsModalWrapper: HTMLDivElement | null = null
    state = {
        applyToAll: false,
        blockingError: false,
        buttonDisabled: true,
        errorMessage: '',
        fee: '0.00',
        hasLoadedInitial: false,
        hasUpdated: false,
        isSaving: false,
        monthlyPlatformFee: '0.00',
        monthlyTerminalFee: '0.00',
        percent: '0.00',
        radioValue: '',
        terminalFee: '0.00',
        terminalPercent: '0.00',
        debitFee: '0.00',
        debitPercent: '0.00',
        insuranceFee: '0.00',
        insurancePercent: '0.00',
        achFee: '0.00',
        achPercent: '0.00',
        membershipPlanPercent: '0.00',
        prepaidProcessingRate: '0.00',
        prepaidFlatFee: '0.00',
    } as LocationPaymentsRatesState

    componentDidMount() {
        const loadProcessingData = async () => {
            try {
                const processingResult: Api.PaymentProcessingResponse = await this.props.fetchPaymentProcessingData(
                    this.props.practice.id,
                    this.props.locationId,
                )

                const {
                    flatFee,
                    processingRate,
                    terminalFlatFee,
                    terminalProcessingRate,
                    debitFlatFee,
                    debitProcessingRate,
                    insuranceFlatFee,
                    insuranceProcessingRate,
                    achFlatFee,
                    achProcessingRate,
                    membershipPlanProcessingRate,
                    accountType,
                    monthlyPlatformFee,
                    monthlyTerminalFee,
                    prepaidProcessingRate,
                    prepaidFlatFee,
                } = processingResult

                if (Object.keys(processingResult).length === 0) {
                    this.setState({
                        hasLoadedInitial: true,
                    })
                    return
                }

                this.setState(state => ({
                    ...state,
                    hasLoadedInitial: true,

                    // card not present fees
                    fee: String(_.round(flatFee / 100, 2)),
                    percent: String(_.round(processingRate * 100, 2)),

                    // card present fees
                    terminalFee: terminalFlatFee ? String(_.round(terminalFlatFee / 100, 2)) : state.terminalFee,
                    terminalPercent: terminalProcessingRate
                        ? String(_.round(terminalProcessingRate * 100, 2))
                        : state.terminalPercent,

                    // debit card fees
                    debitFee: debitFlatFee ? String(_.round(debitFlatFee / 100, 2)) : state.debitFee,
                    debitPercent: debitProcessingRate
                        ? String(_.round(debitProcessingRate * 100, 2))
                        : state.debitPercent,

                    // insurance fees
                    insuranceFee: insuranceFlatFee ? String(_.round(insuranceFlatFee / 100, 2)) : state.insuranceFee,
                    insurancePercent: insuranceProcessingRate
                        ? String(_.round(insuranceProcessingRate * 100, 2))
                        : state.insurancePercent,

                    // ach fees
                    achFee: achFlatFee ? String(_.round(achFlatFee / 100, 2)) : state.achFee,
                    achPercent: achProcessingRate ? String(_.round(achProcessingRate * 100, 2)) : state.achPercent,

                    // membership plan fees
                    membershipPlanPercent: membershipPlanProcessingRate
                        ? String(_.round(membershipPlanProcessingRate * 100, 2))
                        : state.membershipPlanPercent,

                    // monthly fees
                    monthlyPlatformFee: monthlyPlatformFee
                        ? String(_.round(monthlyPlatformFee / 100, 2))
                        : state.monthlyPlatformFee,
                    monthlyTerminalFee: monthlyTerminalFee
                        ? String(_.round(monthlyTerminalFee / 100, 2))
                        : state.monthlyTerminalFee,

                    // prepaid processing fees
                    prepaidProcessingRate: prepaidProcessingRate
                        ? String(_.round(prepaidProcessingRate * 100, 2))
                        : state.prepaidProcessingRate,
                    prepaidFlatFee: prepaidFlatFee ? String(_.round(prepaidFlatFee / 100, 2)) : state.prepaidFlatFee,

                    radioValue: accountType,
                }))
            } catch (err) {
                this.setState({
                    hasLoadedInitial: true,
                    errorMessage: 'Error loading payments data.  Contact engineering if problem persists.',
                    blockingError: true,
                })
            }
        }
        loadProcessingData()
    }

    updateFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fee: e.target.value }, this.checkDisabled)
    }
    updateMonthlyPlatformFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ monthlyPlatformFee: e.target.value }, this.checkDisabled)
    }
    updateMonthlyTerminalFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ monthlyTerminalFee: e.target.value }, this.checkDisabled)
    }
    updatePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ percent: e.target.value }, this.checkDisabled)
    }
    updateTerminalFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ terminalFee: e.target.value }, this.checkDisabled)
    }
    updateTerminalPercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ terminalPercent: e.target.value }, this.checkDisabled)
    }
    updateDebitFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ debitFee: e.target.value }, this.checkDisabled)
    }
    updateDebitPercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ debitPercent: e.target.value }, this.checkDisabled)
    }
    updateInsuranceFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ insuranceFee: e.target.value }, this.checkDisabled)
    }
    updateInsurancePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ insurancePercent: e.target.value }, this.checkDisabled)
    }
    updateAchFee = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ achFee: e.target.value }, this.checkDisabled)
    }
    updateAchPercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ achPercent: e.target.value }, this.checkDisabled)
    }
    updateMembershipPlanPercent = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ membershipPlanPercent: e.target.value }, this.checkDisabled)
    }
    updatePrepaidProcessingRate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ prepaidProcessingRate: e.target.value }, this.checkDisabled)
    }
    updatePrepaidFlatFees = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ prepaidFlatFee: e.target.value }, this.checkDisabled)
    }
    updateProcessingType = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ radioValue: e.target.value as LocationPaymentsRatesState['radioValue'] }, this.checkDisabled)
    }

    validateForm = (): string | boolean => {
        const {
            fee,
            monthlyPlatformFee,
            monthlyTerminalFee,
            percent,
            radioValue,
            terminalFee,
            terminalPercent,
            debitFee,
            debitPercent,
            insuranceFee,
            insurancePercent,
            achFee,
            achPercent,
            membershipPlanPercent,
            prepaidProcessingRate,
            prepaidFlatFee,
        } = this.state

        const parsedPercent = parseFloat(percent)
        const parsedFee = parseFloat(fee)

        const parsedMonthlyPlatformFee = parseFloat(monthlyPlatformFee)
        const parsedMontlyTerminalFee = parseFloat(monthlyTerminalFee)

        const parsedTerminalPercent = parseFloat(terminalPercent)
        const parsedTerminalFee = parseFloat(terminalFee)

        const parsedDebitPercent = parseFloat(debitPercent)
        const parsedDebitFee = parseFloat(debitFee)

        const parsedInsurancePercent = parseFloat(insurancePercent)
        const parsedInsuranceFee = parseFloat(insuranceFee)

        const parsedAchPercent = parseFloat(achPercent)
        const parsedAchFee = parseFloat(achFee)

        const parsedPrepaidProcessingRate = parseFloat(prepaidProcessingRate)
        const parsedPrepaidFlatFee = parseFloat(prepaidFlatFee)

        const parsedMembershipPlanPercent = parseFloat(membershipPlanPercent)

        if (percent === '') {
            return '"Card not present" percent is required.'
        }
        if (parsedPercent < 0) {
            return '"Card not present" percent cannot be negative.'
        }
        if (fee === '') {
            return '"Card not present" fixed fee is required.'
        }
        if (parsedFee < 0) {
            return '"Card not present" fixed fee cannot be negative.'
        }
        if (monthlyPlatformFee === '') {
            return 'Monthly Platform Fee is required.'
        }
        if (parsedMonthlyPlatformFee < 0) {
            return 'Monthly Platform Fee cannot be negative.'
        }
        if (monthlyTerminalFee === '') {
            return 'Monthly TerminalFee is required.'
        }
        if (parsedMontlyTerminalFee < 0) {
            return 'Monthly Terminal Fee cannot be negative.'
        }
        if (terminalPercent === '') {
            return '"Card present" percent is required.'
        }
        if (parsedTerminalPercent < 0) {
            return '"Card present" percent cannot be negative.'
        }
        if (terminalFee === '') {
            return '"Card present" fixed fee is required.'
        }
        if (parsedTerminalFee < 0) {
            return '"Card present" fixed fee cannot be negative.'
        }
        if (debitPercent === '') {
            return 'Debit percent is required.'
        }
        if (parsedDebitPercent < 0) {
            return 'Debit percent cannot be negative.'
        }
        if (debitFee === '') {
            return 'Debit fee is required.'
        }
        if (parsedDebitFee < 0) {
            return 'Debit fee cannot be negative.'
        }
        if (insurancePercent === '') {
            return 'Insurance percent is required.'
        }
        if (parsedInsurancePercent < 0) {
            return 'Insurance percent cannot be negative.'
        }
        if (insuranceFee === '') {
            return 'Insurance fee is required.'
        }
        if (parsedInsuranceFee < 0) {
            return 'Insurance fee cannot be negative.'
        }
        if (achPercent === '') {
            return 'ACH percent is required.'
        }
        if (parsedAchPercent < 0) {
            return 'ACH percent cannot be negative.'
        }
        if (achFee === '') {
            return 'ACH fee is required.'
        }
        if (parsedAchFee < 0) {
            return 'ACH fee cannot be negative.'
        }
        if (prepaidProcessingRate === '') {
            return 'Prepaid processing rate is required.'
        }
        if (parsedPrepaidProcessingRate < 0) {
            return 'Prepaid processing rate cannot be negative.'
        }
        if (prepaidFlatFee === '') {
            return 'Prepaid fee is required.'
        }
        if (parsedPrepaidFlatFee < 0) {
            return 'Prepaid fee cannot be negative.'
        }
        if (radioValue === '') {
            return 'Type of Processing is required.'
        }
        if (membershipPlanPercent === '') {
            return 'Membership Plan percent is required.'
        }
        if (parsedMembershipPlanPercent < 0) {
            return 'Membership Plan percent cannot be negative.'
        }

        const percentParts = percent.split('.')
        if (percentParts.length === 2 && percentParts[1].length > 2) {
            return 'Only a precision of 2 is allowed (2 decimals) for percent fee.'
        }

        const feeParts = fee.split('.')
        if (feeParts.length === 2 && feeParts[1].length > 2) {
            return 'Only a precision of 2 is allowed (2 decimals) for fixed fee.'
        }

        const monthlyPlatformFeeParts = monthlyPlatformFee.split('.')
        if (monthlyPlatformFeeParts.length === 2 && monthlyPlatformFeeParts[1].length > 2) {
            return 'Only a precision of 2 is allowed (2 decimals) for monthly platform fee.'
        }

        const monthlyTerminalFeeParts = monthlyTerminalFee.split('.')
        if (monthlyTerminalFeeParts.length === 2 && monthlyTerminalFeeParts[1].length > 2) {
            return 'Only a precision of 2 is allowed (2 decimals) for monthly terminal fee.'
        }

        const terminalPercentParts = terminalPercent.split('.')
        if (terminalPercentParts.length === 2 && terminalPercentParts[1].length > 2) {
            return 'Terminal - only a precision of 2 is allowed (2 decimals) for percent fee.'
        }

        const terminalParts = terminalFee.split('.')
        if (terminalParts.length === 2 && terminalParts[1].length > 2) {
            return 'Terminal - only a precision of 2 is allowed (2 decimals) for fixed fee.'
        }

        const debitPercentParts = debitPercent.split('.')
        if (debitPercentParts.length === 2 && debitPercentParts[1].length > 2) {
            return 'Debit - only a precision of 2 is allowed (2 decimals) for percent fee.'
        }

        const debitFeeParts = debitFee.split('.')
        if (debitFeeParts.length === 2 && debitFeeParts[1].length > 2) {
            return 'Debit - only a precision of 2 is allowed (2 decimals) for fixed fee.'
        }

        const insurancePercentParts = insurancePercent.split('.')
        if (insurancePercentParts.length === 2 && insurancePercentParts[1].length > 2) {
            return 'Insurance - only a precision of 2 is allowed (2 decimals) for percent fee.'
        }

        const insuranceFeeParts = insuranceFee.split('.')
        if (insuranceFeeParts.length === 2 && insuranceFeeParts[1].length > 2) {
            return 'Insurance - only a precision of 2 is allowed (2 decimals) for fixed fee.'
        }

        const achPercentParts = achPercent.split('.')
        if (achPercentParts.length === 2 && achPercentParts[1].length > 2) {
            return 'ACH - only a precision of 2 is allowed (2 decimals) for percent fee.'
        }

        const achFeeParts = achFee.split('.')
        if (achFeeParts.length === 2 && achFeeParts[1].length > 2) {
            return 'ACH - only a precision of 2 is allowed (2 decimals) for fixed fee.'
        }

        const prepaidProcessingRateParts = prepaidProcessingRate.split('.')
        if (prepaidProcessingRateParts.length === 2 && prepaidProcessingRateParts[1].length > 2) {
            return 'Prepaid - only a precision of 2 is allowed (2 decimals) for processing rate.'
        }

        const prepaidFlatFeeParts = achFee.split('.')
        if (prepaidFlatFeeParts.length === 2 && prepaidFlatFeeParts[1].length > 2) {
            return 'Prepaid - only a precision of 2 is allowed (2 decimals) for fixed fee.'
        }

        const membershipPlanPercentParts = achPercent.split('.')
        if (membershipPlanPercentParts.length === 2 && membershipPlanPercentParts[1].length > 2) {
            return 'Membership Plan - only a precision of 2 is allowed (2 decimals) for percent fee.'
        }

        return false
    }

    checkDisabled = () => {
        this.setState({ buttonDisabled: this.validateForm() !== false })
    }

    saveChanges = () => {
        if (this.state.isSaving) {
            return
        }
        const {
            applyToAll,
            fee,
            percent,
            terminalFee,
            terminalPercent,
            debitFee,
            debitPercent,
            achFee,
            achPercent,
            insuranceFee,
            insurancePercent,
            membershipPlanPercent,
            radioValue,
            monthlyPlatformFee,
            monthlyTerminalFee,
            prepaidProcessingRate,
            prepaidFlatFee,
        } = this.state

        // card not present fees
        const parsedMerchantPercent = parseFloat(percent)
        const parsedMerchantFee = parseFloat(fee)

        // card present fees
        const parsedTerminalPercent = parseFloat(terminalPercent)
        const parsedTerminalFee = parseFloat(terminalFee)

        // debit card fees
        const parsedDebitPercent = parseFloat(debitPercent)
        const parsedDebitFee = parseFloat(debitFee)

        // insurance fees
        const parsedInsurancePercent = parseFloat(insurancePercent)
        const parsedInsuranceFee = parseFloat(insuranceFee)

        // ach fees
        const parsedAchPercent = parseFloat(achPercent)
        const parsedAchFee = parseFloat(achFee)

        // membership plan fees
        const parsedMembershipPlanPercent = parseFloat(membershipPlanPercent)

        // monthly fees
        const parsedMonthlyPlatformFee = parseFloat(monthlyPlatformFee)
        const parsedMonthlyTerminalFee = parseFloat(monthlyTerminalFee)

        // prepaid fees
        const parsedPrepaidProcessingRate = parseFloat(prepaidProcessingRate)
        const parsedPrepaidFlatFee = parseFloat(prepaidFlatFee)

        this.setState({ errorMessage: '', hasUpdated: false })

        const errorMessage = this.validateForm()

        if (errorMessage !== false) {
            this.setState({ errorMessage: errorMessage as string })
            return
        }

        const lT = 'lower'
        const hT = 'higher'

        // card not present confirmation when values are irregular
        const shouldContinueMerchantPercent = () =>
            window.confirm(
                `"Card not present" percent is ${percent}% which is ${
                    parsedMerchantPercent === 0 ? lT : hT
                } than normal. Continue?`,
            )
        const shouldContinueMerchantFee = () =>
            window.confirm(`"Card not present" fixed fee is $${fee} which is higher than normal. Continue?`)

        // card present confirmation when values are irregular
        const shouldContinueTerminalPercent = () =>
            window.confirm(
                `"Card present" percent is ${terminalPercent}% which is ${
                    parsedTerminalPercent === 0 ? lT : hT
                } than normal. Continue?`,
            )
        const shouldContinueTerminalFee = () =>
            window.confirm(`"Card present" fixed fee is $${terminalFee} which is higher than normal. Continue?`)

        // debit card confirmation when values are irregular
        const shouldContinueDebitPercent = () =>
            window.confirm(
                `Debit percent is ${debitPercent}% which is ${
                    parsedDebitPercent === 0 ? lT : hT
                } than normal. Continue?`,
            )
        const shouldContinueDebitFee = () =>
            window.confirm(`Debit fixed fee is $${debitFee} which is higher than normal. Continue?`)

        // insurance confirmation when values are irregular
        const shouldContinueInsurancePercent = () =>
            window.confirm(
                `Insurance percent is ${insurancePercent}% which is ${
                    parsedInsurancePercent === 0 ? lT : hT
                } than normal. Continue?`,
            )
        const shouldContinueInsuranceFee = () =>
            window.confirm(`Insurance fixed fee is $${insuranceFee} which is higher than normal. Continue?`)

        // ach confirmation when values are irregular
        const shouldContinueAchPercent = () =>
            window.confirm(
                `ACH percent is ${achPercent}% which is ${parsedAchPercent === 0 ? lT : hT} than normal. Continue?`,
            )
        const shouldContinueAchFee = () =>
            window.confirm(`ACH fixed fee is $${achFee} which is higher than normal. Continue?`)

        const shouldContinueMembershipPlanPercent = () =>
            window.confirm(
                `Membership Plan percent is ${membershipPlanPercent}% which is ${
                    parsedMembershipPlanPercent === 0 ? lT : hT
                } than normal. Continue?`,
            )

        // monthly fee confirmation when values are irregular
        const shouldContinueMonthlyPlatformFee = () =>
            window.confirm(`Monthly Platform Fee is $${monthlyPlatformFee} which is higher than normal. Continue?`)
        const shouldContinueMonthlyTerminalFee = () =>
            window.confirm(`Monthly Terminal Fee is $${monthlyTerminalFee} which is higher than normal. Continue?`)

        // prepaid fee confirmation when values are irregular
        const shouldContinuePrepaidProcessingRate = () =>
            window.confirm(
                `Prepaid processing percent is $${prepaidProcessingRate} which is higher than normal. Continue?`,
            )
        const shouldContinuePrepaidFlatFee = () =>
            window.confirm(`Prepaid fixed fee is $${prepaidFlatFee} which is higher than normal. Continue?`)

        if (
            // card not present validation
            ((parsedMerchantPercent > 4 || parsedMerchantPercent === 0) && !shouldContinueMerchantPercent()) ||
            (parsedMerchantFee > 1 && !shouldContinueMerchantFee()) ||
            // card present validation
            ((parsedTerminalPercent > 4 || parsedTerminalPercent === 0) && !shouldContinueTerminalPercent()) ||
            (parsedTerminalFee > 1 && !shouldContinueTerminalFee()) ||
            // debit card validation
            ((parsedDebitPercent > 4 || parsedDebitPercent === 0) && !shouldContinueDebitPercent()) ||
            (parsedDebitFee > 1 && !shouldContinueDebitFee()) ||
            // insurance validation
            ((parsedInsurancePercent > 4 || parsedInsurancePercent === 0) && !shouldContinueInsurancePercent()) ||
            (parsedInsuranceFee > 1 && !shouldContinueInsuranceFee()) ||
            // ach validation
            ((parsedAchPercent > 4 || parsedAchPercent === 0) && !shouldContinueAchPercent()) ||
            (parsedAchFee > 1 && !shouldContinueAchFee()) ||
            // membership plan validation
            ((parsedMembershipPlanPercent > 50 || parsedMembershipPlanPercent === 0) &&
                !shouldContinueMembershipPlanPercent()) ||
            // monthly fees validation
            (parsedMonthlyPlatformFee > 100 && !shouldContinueMonthlyPlatformFee()) ||
            (parsedMonthlyTerminalFee > 100 && !shouldContinueMonthlyTerminalFee()) ||
            // prepaid fees validation
            ((parsedPrepaidProcessingRate > 4 || parsedPrepaidProcessingRate === 0) &&
                !shouldContinuePrepaidProcessingRate()) ||
            (parsedPrepaidFlatFee > 100 && !shouldContinuePrepaidFlatFee())
        ) {
            return
        }

        if (applyToAll) {
            const confirmAll = window.confirm(
                'Are you sure you want to apply these Rates and Processing Fees to all locations for this practice?',
            )
            if (!confirmAll) {
                return
            }
        }

        // convert to decimal from percent to send to backend
        // final card not present fees
        const finalMerchantFlatFee = _.round(parseFloat(fee) * 100, 0)
        const finalMerchantProcessingRate = _.round(parseFloat(percent) / 100, 4)

        // final card present fees
        const finalTerminalFlatFee = _.round(parseFloat(terminalFee) * 100, 0)
        const finalTerminalProcessingRate = _.round(parseFloat(terminalPercent) / 100, 4)

        // final debit fees
        const finalDebitFlatFee = _.round(parseFloat(debitFee) * 100, 0)
        const finalDebitProcessingRate = _.round(parseFloat(debitPercent) / 100, 4)

        // final insurance fees
        const finalInsuranceFlatFee = _.round(parseFloat(insuranceFee) * 100, 0)
        const finalInsuranceProcessingRate = _.round(parseFloat(insurancePercent) / 100, 4)

        // final ach fees
        const finalAchFlatFee = _.round(parseFloat(achFee) * 100, 0)
        const finalAchProcessingRate = _.round(parseFloat(achPercent) / 100, 4)

        // final membership plan fees
        const finalMembershipPlanPercent = _.round(parseFloat(membershipPlanPercent) / 100, 4)

        // final monthly fees
        const finalMonthlyPlatformFee = _.round(parseFloat(monthlyPlatformFee) * 100, 0)
        const finalMonthlyTerminalFee = _.round(parseFloat(monthlyTerminalFee) * 100, 0)

        // final prepaid fees
        const finalPrepaidProcessingRate = _.round(parseFloat(prepaidProcessingRate) / 100, 4)
        const finalPrepaidFlatFee = _.round(parseFloat(prepaidFlatFee) * 100, 0)

        this.setState({ isSaving: true })
        this.props
            .savePaymentProcessingData(
                this.props.practice.id,
                this.props.locationId,
                finalMerchantFlatFee,
                finalMerchantProcessingRate,
                finalTerminalFlatFee,
                finalTerminalProcessingRate,
                finalDebitFlatFee,
                finalDebitProcessingRate,
                finalInsuranceFlatFee,
                finalInsuranceProcessingRate,
                finalAchFlatFee,
                finalAchProcessingRate,
                finalMembershipPlanPercent,
                finalMonthlyPlatformFee,
                finalMonthlyTerminalFee,
                finalPrepaidProcessingRate,
                finalPrepaidFlatFee,
                radioValue as Models.PaymentProcessingType,
                applyToAll,
            )
            .then((response: Api.PaymentsResponse<Api.PaymentProcessingUpdateResponse>): any => {
                const updatedFees = response.data

                const confirmChangeSuccessful = (
                    practiceFee: Api.PaymentProcessingUpdateContents,
                    isAll: boolean = false,
                ): Promise<any> => {
                    if (
                        !!practiceFee &&
                        practiceFee.accountType === radioValue &&
                        practiceFee.flatFee === finalMerchantFlatFee &&
                        practiceFee.processingRate === finalMerchantProcessingRate &&
                        practiceFee.terminalFlatFee === finalTerminalFlatFee &&
                        practiceFee.terminalProcessingRate === finalTerminalProcessingRate &&
                        practiceFee.insuranceFlatFee === finalInsuranceFlatFee &&
                        practiceFee.insuranceProcessingRate === finalInsuranceProcessingRate &&
                        practiceFee.achFlatFee === finalAchFlatFee &&
                        practiceFee.achProcessingRate === finalAchProcessingRate &&
                        practiceFee.monthlyPlatformFee === finalMonthlyPlatformFee &&
                        practiceFee.monthlyTerminalFee === finalMonthlyTerminalFee &&
                        practiceFee.prepaidProcessingRate === finalPrepaidProcessingRate &&
                        practiceFee.prepaidFlatFee === finalPrepaidFlatFee
                    ) {
                        return Promise.resolve()
                        // document.querySelector('.payments-modal-wrapper').scrollTo({
                        //     top: 0,
                        //     left: 0,
                        //     behavior: 'smooth'
                        //   });
                    } else {
                        return Promise.reject(
                            response.errors && response.errors.length > 0
                                ? response.errors[0]
                                : {
                                      message: !isAll
                                          ? `Mismatched data. Contact engineering.`
                                          : 'Mismatched data in some locations. Contact engineering.',
                                  },
                        )
                    }
                }

                if (!applyToAll) {
                    return confirmChangeSuccessful(updatedFees as Api.PaymentProcessingUpdateContents).then(() => {
                        this.setState({ hasUpdated: true, isSaving: false }, () => {
                            if (this.paymentsModalWrapper) {
                                this.paymentsModalWrapper.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }
                        })
                    })
                } else if (updatedFees instanceof Array) {
                    const changesList: Promise<any>[] = []

                    updatedFees.forEach((updatedLocationFees: Api.PaymentProcessingUpdateContents) => {
                        changesList.push(
                            confirmChangeSuccessful(updatedLocationFees as Api.PaymentProcessingUpdateContents, true),
                        )
                    })
                    return Promise.all(changesList).then(() => {
                        this.setState({ hasUpdated: true, isSaving: false }, () => {
                            if (this.paymentsModalWrapper) {
                                this.paymentsModalWrapper.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }
                        })
                    })
                }
            })
            .catch(err => {
                this.setState({ errorMessage: err.message || 'Server error.', isSaving: false }, () => {
                    if (this.paymentsModalWrapper) {
                        this.paymentsModalWrapper.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }
                })
            })
    }

    render() {
        const {
            blockingError,
            buttonDisabled,
            errorMessage,
            fee,
            hasLoadedInitial,
            hasUpdated,
            isSaving,
            monthlyPlatformFee,
            monthlyTerminalFee,
            percent,
            radioValue,
            terminalFee,
            terminalPercent,
            debitFee,
            debitPercent,
            insuranceFee,
            insurancePercent,
            achFee,
            achPercent,
            membershipPlanPercent,
            prepaidProcessingRate,
            prepaidFlatFee,
        } = this.state
        const practiceId = this.props.practice.id
        const radioClassOverrides: RadioClassOverrides = {
            root: 'radio-root',
        }

        return (
            <Modal
                open={true}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        this.props.closeLocationPaymentsModal()
                    }
                }}
                disableEscapeKeyDown={true}
            >
                <div className="location-payments-modal">
                    {!hasLoadedInitial && (
                        <div className="payments-modal-wrapper payments-modal-wrapper-loading">
                            <i className="material-icons close-modal" onClick={this.props.closeLocationPaymentsModal}>
                                close
                            </i>
                            <CircularProgress />
                        </div>
                    )}
                    {hasLoadedInitial && blockingError && errorMessage && (
                        <div className="payments-modal-wrapper">
                            <div className="payments-main-inner">
                                <i
                                    className="material-icons close-modal"
                                    onClick={this.props.closeLocationPaymentsModal}
                                >
                                    close
                                </i>
                                <div className="payments-processing-error">
                                    <Icon className="info-payment-icon">close</Icon>
                                    <span>{errorMessage}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {hasLoadedInitial && !(blockingError && errorMessage) && (
                        <div
                            className="payments-modal-wrapper"
                            ref={paymentsModalWrapper => (this.paymentsModalWrapper = paymentsModalWrapper)}
                        >
                            <i className="material-icons close-modal" onClick={this.props.closeLocationPaymentsModal}>
                                close
                            </i>
                            <div className="payments-main-inner">
                                {hasUpdated && (
                                    <div className="payments-updated-successfully">
                                        <Icon className="info-payment-icon">check_circle</Icon>
                                        <span>Updates to rates and fees have been saved.</span>
                                    </div>
                                )}
                                {errorMessage !== '' && (
                                    <div className="payments-processing-error">
                                        <Icon className="info-payment-icon">close</Icon>
                                        <span>{errorMessage}</span>
                                    </div>
                                )}
                                <div className="account-id">
                                    Location ID: {this.props.locationId}
                                    <CopyText text={this.props.locationId} />
                                </div>
                                <h2 className="address-heading">{this.props.locationName}</h2>
                                <div className="payments-section-wrapper payments-section-wrapper--multiple">
                                    <div className="payments-section-item">
                                        <h3>Card Not Present Transaction Fee</h3>
                                        <div className="payments-rate-wrapper">
                                            <div className="payments-processing-input">
                                                <CustomField
                                                    customFieldType={CustomFieldType.INPUT}
                                                    autoFocus={false}
                                                    autoSize={true}
                                                    inputProps={{ step: '.01' }}
                                                    inputType="number"
                                                    value={percent}
                                                    rows={1}
                                                    onChange={this.updatePercent}
                                                />
                                                <label className="pay-process-icon">%</label>
                                            </div>
                                            <div className="payments-processing-add">+</div>
                                            <div className="payments-processing-input">
                                                <label className="pay-process-icon pay-process-icon--start">$</label>
                                                <CustomField
                                                    customFieldType={CustomFieldType.INPUT}
                                                    autoFocus={false}
                                                    autoSize={true}
                                                    inputProps={{ step: '.01' }}
                                                    inputType="number"
                                                    value={fee}
                                                    rows={1}
                                                    onChange={this.updateFee}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="payments-section-item">
                                        <h3>Monthly Terminal Fee</h3>
                                        <div className="payments-processing-input">
                                            <label className="pay-process-icon pay-process-icon--start">$</label>
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={monthlyTerminalFee}
                                                rows={1}
                                                onChange={this.updateMonthlyTerminalFee}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper payments-section-wrapper--multiple">
                                    <div className="payments-section-item">
                                        <h3>Card Present Transaction Fee</h3>
                                        <div className="payments-rate-wrapper">
                                            <div className="payments-processing-input">
                                                <CustomField
                                                    customFieldType={CustomFieldType.INPUT}
                                                    autoFocus={false}
                                                    autoSize={true}
                                                    inputProps={{ step: '.01' }}
                                                    inputType="number"
                                                    value={terminalPercent}
                                                    rows={1}
                                                    onChange={this.updateTerminalPercent}
                                                />
                                                <label className="pay-process-icon">%</label>
                                            </div>
                                            <div className="payments-processing-add">+</div>
                                            <div className="payments-processing-input">
                                                <label className="pay-process-icon pay-process-icon--start">$</label>
                                                <CustomField
                                                    customFieldType={CustomFieldType.INPUT}
                                                    autoFocus={false}
                                                    autoSize={true}
                                                    inputProps={{ step: '.01' }}
                                                    inputType="number"
                                                    value={terminalFee}
                                                    rows={1}
                                                    onChange={this.updateTerminalFee}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="payments-section-item">
                                        <h3>Monthly Platform Fee</h3>
                                        <div className="payments-processing-input">
                                            <label className="pay-process-icon pay-process-icon--start">$</label>
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={monthlyPlatformFee}
                                                rows={1}
                                                onChange={this.updateMonthlyPlatformFee}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper">
                                    <h3>Debit Card Processing Rate</h3>
                                    <div className="payments-rate-wrapper">
                                        <div className="payments-processing-input">
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={debitPercent}
                                                rows={1}
                                                onChange={this.updateDebitPercent}
                                            />
                                            <label className="pay-process-icon">%</label>
                                        </div>
                                        <div className="payments-processing-add">+</div>
                                        <div className="payments-processing-input">
                                            <label className="pay-process-icon pay-process-icon--start">$</label>
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={debitFee}
                                                rows={1}
                                                onChange={this.updateDebitFee}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper">
                                    <h3>Insurance Processing Fee</h3>
                                    <div className="payments-rate-wrapper">
                                        <div className="payments-processing-input">
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={insurancePercent}
                                                rows={1}
                                                onChange={this.updateInsurancePercent}
                                            />
                                            <label className="pay-process-icon">%</label>
                                        </div>
                                        <div className="payments-processing-add">+</div>
                                        <div className="payments-processing-input">
                                            <label className="pay-process-icon pay-process-icon--start">$</label>
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={insuranceFee}
                                                rows={1}
                                                onChange={this.updateInsuranceFee}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper">
                                    <h3>Prepaid Processing Fee</h3>
                                    <div className="payments-rate-wrapper">
                                        <div className="payments-processing-input">
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={prepaidProcessingRate}
                                                rows={1}
                                                onChange={this.updatePrepaidProcessingRate}
                                            />
                                            <label className="pay-process-icon">%</label>
                                        </div>
                                        <div className="payments-processing-add">+</div>
                                        <div className="payments-processing-input">
                                            <label className="pay-process-icon pay-process-icon--start">$</label>
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={prepaidFlatFee}
                                                rows={1}
                                                onChange={this.updatePrepaidFlatFees}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper">
                                    <h3>ACH Processing Fee</h3>
                                    <div className="payments-rate-wrapper">
                                        <div className="payments-processing-input">
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={achPercent}
                                                rows={1}
                                                onChange={this.updateAchPercent}
                                            />
                                            <label className="pay-process-icon">%</label>
                                        </div>
                                        <div className="payments-processing-add">+</div>
                                        <div className="payments-processing-input">
                                            <label className="pay-process-icon pay-process-icon--start">$</label>
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={achFee}
                                                rows={1}
                                                onChange={this.updateAchFee}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper">
                                    <h3>Membership Plan Rate</h3>
                                    <div className="payments-rate-wrapper">
                                        <div className="payments-processing-input">
                                            <CustomField
                                                customFieldType={CustomFieldType.INPUT}
                                                autoFocus={false}
                                                autoSize={true}
                                                inputProps={{ step: '.01' }}
                                                inputType="number"
                                                value={membershipPlanPercent}
                                                rows={1}
                                                onChange={this.updateMembershipPlanPercent}
                                            />
                                            <label className="pay-process-icon">%</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="payments-section-wrapper">
                                    <h3>Type of Processing</h3>
                                    <div className="payments-processing-wrapper">
                                        <Radio
                                            checked={radioValue === 'simplyzero'}
                                            onChange={this.updateProcessingType}
                                            name="radio-payments-processing"
                                            value="simplyzero"
                                            id={`payments-radio-simplyzero-${practiceId}`}
                                            classes={radioClassOverrides}
                                        />
                                        <div className="payments-processing-label-wrapper">
                                            <label htmlFor={`payments-radio-simplyzero-${practiceId}`}>
                                                <span className="payments-processing-text">SimplyZero</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="payments-processing-wrapper">
                                        <Radio
                                            checked={radioValue === 'mixed'}
                                            onChange={this.updateProcessingType}
                                            name="radio-payments-processing"
                                            value="mixed"
                                            id={`payments-radio-mixed-${practiceId}`}
                                            classes={radioClassOverrides}
                                        />
                                        <div className="payments-processing-label-wrapper">
                                            <label htmlFor={`payments-radio-mixed-${practiceId}`}>
                                                <span className="payments-processing-text">Mixed</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="payments-processing-wrapper">
                                        <Radio
                                            checked={radioValue === 'standard'}
                                            onChange={this.updateProcessingType}
                                            name="radio-payments-processing"
                                            value="standard"
                                            id={`payments-radio-standard-${practiceId}`}
                                            classes={radioClassOverrides}
                                        />
                                        <div className="payments-processing-label-wrapper">
                                            <label htmlFor={`payments-radio-standard-${practiceId}`}>
                                                <span className="payments-processing-text">Standard</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="location-payments-apply-all-wrapper">
                                    <FormControlLabel
                                        className="location-payments-apply-all"
                                        control={
                                            <Checkbox
                                                checked={this.state.applyToAll}
                                                className="location-payments-apply-all-checkbox"
                                                onChange={() =>
                                                    this.setState(state => ({
                                                        applyToAll: !state.applyToAll,
                                                    }))
                                                }
                                                value="all-locations"
                                            />
                                        }
                                        label="Apply to all locations"
                                    />
                                </div>
                            </div>
                            <button
                                className={classNames('update-button', {
                                    'can-save': !buttonDisabled && !isSaving,
                                })}
                                onClick={this.saveChanges}
                                disabled={buttonDisabled || isSaving}
                            >
                                Save Changes
                            </button>
                        </div>
                    )}
                </div>
            </Modal>
        )
    }
}
