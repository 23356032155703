import download from 'downloadjs'

import ApiV2 from '../../ApiV2'
import { downloadStatus, HeartbeatProvider } from '../../models/v2/Heartbeat'

export enum HeartbeatActionTypes {
    EARTBEAT_DOWNLOAD_MONTHLY_CHECKUP_CSV = '@V2/HEARTBEAT_DOWNLOAD_MONTHLY_CHECKUP_CSV',
    HEARTBEAT_RECEIVE_PROVIDERS = '@V2/HEARTBEAT_RECEIVE_PROVIDERS',
}

export type SetHeartbeatDownloadMonthlyCheckupCsv = {
    type: HeartbeatActionTypes.EARTBEAT_DOWNLOAD_MONTHLY_CHECKUP_CSV
    status: downloadStatus
}

export type SetHeartbeatProviders = {
    type: HeartbeatActionTypes.HEARTBEAT_RECEIVE_PROVIDERS
    heartbeatProviders: HeartbeatProvider[]
}

export function receiveMonthlyCheckupCsv(status: downloadStatus): SetHeartbeatDownloadMonthlyCheckupCsv {
    return {
        type: HeartbeatActionTypes.EARTBEAT_DOWNLOAD_MONTHLY_CHECKUP_CSV,
        status,
    }
}

export function receiveHeartbeatProviders(heartbeatProviders: HeartbeatProvider[]): SetHeartbeatProviders {
    return {
        type: HeartbeatActionTypes.HEARTBEAT_RECEIVE_PROVIDERS,
        heartbeatProviders,
    }
}

export function fetchHeartbeatDownloadMonthlyCheckupCsv(year: number, month: number): any {
    return async (dispatch: any) => {
        await dispatch(receiveMonthlyCheckupCsv('downloading'))

        try {
            const { blob } = await ApiV2.Heartbeat.getHeartbeatDownloadMonthlyCheckupCsv(year, month)
            const fileName = `monthly-checkup-${year}-${month}.csv`
            download(blob, fileName, 'text/csv')

            return await dispatch(receiveMonthlyCheckupCsv('idle'))
        } catch (e) {
            return await dispatch(receiveMonthlyCheckupCsv('error'))
        }
    }
}

export function fetchHeartbeatProviders(): any {
    return async (dispatch: any) => {
        const { data: heartbeatProviders } = await ApiV2.Heartbeat.getHeartbeatProviders()
        await dispatch(receiveHeartbeatProviders(heartbeatProviders))
        return heartbeatProviders
    }
}

export function registerAgentLocationProvider(externalLocationId: number, providerId: string, payload: any): any {
    return async (dispatch: any) => {
        return await ApiV2.Heartbeat.postAgentLocationProviders(externalLocationId, providerId, payload)
    }
}

export function updateAgentLocationProvider(externalLocationId: number, providerId: string, payload: any): any {
    return async (dispatch: any) => {
        return await ApiV2.Heartbeat.patchAgentLocationProviders(externalLocationId, providerId, payload)
    }
}
