import * as React from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import classNames from 'classnames'
import moment from 'moment'

import { Products } from '../../models/enums'
import { downloadLicense, fetchAgents, fetchDexVoiceLicenses } from '../dexvoice/actions'
import { toggleDexVoice } from '../practices/actions'

import DexVoiceDiagnosticsCommand from './DexVoiceDiagnosticsCommand'

import './DexVoiceDiagnostics.sass'

export type DexVoiceDiagnosticsProps = {
    readonly practice: Models.Practice
    readonly agents?: Array<Models.DexisAgent>
    readonly licenses?: Array<Models.DexisLicense>
}

export type DexVoiceDiagnosticsDispatch = {
    readonly fetchAgents: typeof fetchAgents
    readonly fetchDexVoiceLicenses: typeof fetchDexVoiceLicenses
    readonly toggleDexVoice: typeof toggleDexVoice
    readonly downloadLicense: typeof downloadLicense
}

export type DexVoiceDiagnosticsState = {
    readonly isLoading: boolean
    readonly selectedAgentId: string | null
    readonly selectedRangeId: number
}

type Props = DexVoiceDiagnosticsProps & DexVoiceDiagnosticsDispatch

type Range = {
    text: string
    count: number
    unit: moment.DurationInputArg2
}

class DexVoiceDiagnostics extends React.Component<Props, DexVoiceDiagnosticsState> {
    static get ranges(): Array<Range> {
        return [
            {
                text: 'Today',
                count: 1,
                unit: 'day',
            },
            {
                text: 'Last Week',
                count: 7,
                unit: 'days',
            },
            {
                text: 'Last Month',
                count: 1,
                unit: 'month',
            },
        ]
    }

    constructor(props: Props) {
        super(props)
        this.state = {
            isLoading: true,
            selectedAgentId: null,
            selectedRangeId: 1,
        }
    }

    UNSAFE_componentWillMount() {
        const range = DexVoiceDiagnostics.ranges[this.state.selectedRangeId]
        this.fetchCommandsAndErrors(range)
    }

    componentDidUpdate() {
        if (this.state.selectedAgentId == null && this.props.agents && this.props.agents.length > 0) {
            this.setState({
                selectedAgentId: this.props.agents[0].id,
            })
        }
    }

    async fetchCommandsAndErrors(range: Range) {
        const start = moment()
            .subtract(range.count, range.unit)
            .toDate()
        const end = moment().toDate()
        await this.props.fetchAgents(this.props.practice, start, end)
        await this.props.fetchDexVoiceLicenses(this.props.practice)
        this.setState({
            isLoading: false,
        })
    }

    onChangeRoom(key: number) {
        this.setState({
            selectedAgentId: this.props.agents ? this.props.agents[key].id : null,
        })
    }

    async onChangeRange(key: number) {
        this.setState({ isLoading: true })
        const range = DexVoiceDiagnostics.ranges[this.state.selectedRangeId]
        await this.fetchCommandsAndErrors(range)
        this.setState({ isLoading: false, selectedRangeId: key })
    }

    onDownloadLicense(practice: Models.Practice, license: Models.DexisLicense) {
        this.props.downloadLicense(practice, license)
    }

    onToggleDexVoice(product: Models.Product) {
        const text = product.active ? 'disable' : 'enable'
        const message = `Are you sure you want to ${text} DEXvoice for ${this.props.practice.name}`
        if (window.confirm(message)) {
            this.props.toggleDexVoice(this.props.practice).then(() => {
                window.alert(`You have ${text}d DEXvoice for ${this.props.practice.name}`)
            })
        }
    }

    render() {
        const { practice, agents, licenses } = this.props
        const { isLoading, selectedAgentId, selectedRangeId } = this.state

        const product = this.props.practice.products.find(p => p.id === Products.DexVoice)

        if (!agents || !product || !licenses) {
            return <div>Loading...</div>
        }

        if (isLoading) {
            return <div>Loading...</div> // TODO spinner
        }

        const selectedAgent = agents.find(agent => agent.id === selectedAgentId)

        if (selectedAgentId == null || selectedAgent == null) {
            return <div className={classNames('dexvoice-diagnostics')}>No DEXvoice agents at this practice</div>
        }

        const commands = selectedAgent.commands || []
        const errors = selectedAgent.errors || []

        return (
            <div className={classNames('dexvoice-diagnostics')}>
                <div className="selection-header">
                    <div className="select device">
                        <Select
                            className="dropdown"
                            fullWidth={true}
                            onChange={event => this.onChangeRoom(parseInt(event.target.value as string, 10))}
                            value={selectedAgent.id}
                            renderValue={() => selectedAgent.roomName}
                            variant="standard"
                        >
                            {agents.map((agent, i) => (
                                <MenuItem key={agent.id} value={i}>
                                    {agent.roomName}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="select range">
                        <Select
                            className="dropdown"
                            onChange={event => this.onChangeRange(parseInt(event.target.value as string, 10))}
                            value={selectedRangeId}
                            variant="standard"
                        >
                            {DexVoiceDiagnostics.ranges.map((range, i) => (
                                <MenuItem key={range.text} value={i}>
                                    {range.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className="statistics-subheader">
                    <div className="statistic commands-info">
                        <div className="label">Commands</div>
                        <div className="count">{commands.length}</div>
                    </div>
                    <div className="statistic errors-info">
                        <div className="label">Errors</div>
                        <div className="count">{errors.length}</div>
                    </div>
                    <div className="toggle-product">
                        <Button
                            variant="contained"
                            onClick={() => this.onToggleDexVoice(product)}
                            color={!product ? 'primary' : 'inherit'}
                            className={classNames({ enabled: product.active }, { disabled: !product.active })}
                        >
                            {`${!product.active ? 'Enable' : 'Disable'} DEXvoice`}
                        </Button>
                    </div>
                    <div className="download-license">
                        <Button
                            variant="contained"
                            disabled={licenses.length < 1}
                            onClick={() => this.onDownloadLicense(practice, licenses[0])}
                        >
                            Download License Agreement
                        </Button>
                    </div>
                </div>
                <div className="command-list">
                    {commands.map(command => (
                        <DexVoiceDiagnosticsCommand key={command.id} command={command} />
                    ))}
                </div>
            </div>
        )
    }
}

export default DexVoiceDiagnostics
