import { error } from 'react-notification-system-redux'
import _ from 'lodash'

import SessionManager from '../SessionManager'

const API_HOST_V2 = `${process.env.REACT_APP_API_HOST}/v2`

const client = 'pst'

interface SilentFetchArguments {
    endpoint: string
    requestOptions?: RequestInit
    queryParams?: object
    customHost?: string
    includeClient?: boolean
}

export default abstract class ApiService {
    host?: string

    private readonly token: string

    constructor(token: string) {
        this.token = token
    }

    get headers() {
        return new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${this.token}`,
        })
    }

    async fetch(
        endpoint: string,
        requestOptions: RequestInit = {},
        queryParams: object = {},
        customHost?: string,
        skipErrorAlert: boolean = false,
        includes: string[] = [],
        customUrl?: string,
        separateFilterQueryParams: boolean = false,
        customClient?: string,
    ) {
        requestOptions.mode = 'cors'
        requestOptions.headers = requestOptions.headers
            ? { ...requestOptions.headers, authorization: `Bearer ${this.token}` }
            : this.headers

        const url = `${customHost || this.host || API_HOST_V2}/api/${customClient ? customClient : client}/${endpoint}`

        const query = _.map({ ...queryParams }, (val, prop) => {
            if (separateFilterQueryParams && prop.includes(`filter[`) && Array.isArray(val)) {
                const values = [...val]
                return values.map((v: any) => `${prop}=${encodeURIComponent(v)}`).join('&')
            }
            if (prop && val) {
                return `${prop}=${encodeURIComponent(val)}`
            }
            return ''
        }).join('&')

        const includeRelationships = includes.length > 0 ? `include[]=${includes.join('&include[]=')}&` : ``

        const finalEndpoint = query ? `${customUrl ? customUrl : url}?${includeRelationships}${query}` : `${url}`

        const response = await fetch(finalEndpoint, requestOptions)
        if (!response.ok) {
            const err = await response.json()

            if (err.statusCode === 401 || err.code === 401 || err.status === 401) {
                SessionManager.update(undefined)
                window.sessionStorage.setItem('PSTUnauthorizedError', err.message)
                return null
            }

            // TODO handle errors better, more granularly
            // avoid spamming error on cancelUpload
            if (err.code === 104 && err.message && err.message.includes('cancelUpload')) {
                throw err
            }

            if (!skipErrorAlert) {
                const errorMessage = err.errors
                    ? err.errors.map((e: any) => e.message || e.details).join('; ')
                    : err.message || err.details
                try {
                    globalThis['store'].dispatch(
                        error({
                            message: errorMessage,
                            position: 'tr',
                            autoDismiss: 7,
                        }),
                    )
                } catch (err) {
                    alert(errorMessage)
                }
            }

            throw err
        }

        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.includes('pdf')) {
            const blob = await response.blob()

            let filename = null
            const disposition = response.headers.get('Content-Disposition')
            if (disposition) {
                filename = (disposition as any).match(/filename=(.+)\s?;/i)[0]
            }

            return {
                blob,
                filename,
            }
        }

        if (contentType && contentType.includes('csv')) {
            const blob = await response.blob()

            let filename = null
            const disposition = response.headers.get('Content-Disposition')
            if (disposition) {
                filename = (disposition as any).match(/filename=(.+)\s?;/i)[0]
            }

            return {
                blob,
                filename,
            }
        }

        // eslint-disable-next-line
        if (response.status == 204) {
            return
        }

        return response.json()
    }

    // Custom fetch that does not show an alert box with an error message.
    async silentFetch({ endpoint, requestOptions = {}, queryParams = {}, customHost }: SilentFetchArguments) {
        return this.fetch(endpoint, requestOptions, queryParams, customHost, true)
    }
}
