import React, { useMemo, useState } from 'react'
import { Button, LinearProgress, Modal } from '@mui/material'
import classNames from 'classnames'

import { CustomFieldType, PracticeSpecialtyTypes } from '../../../../models/enums'
import { ProductTypes } from '../../../../models/enums'
import CopyText from '../../../shared/CopyText'
import CustomField from '../../../shared/custom-fields/CustomField'
import CustomMultiselectField from '../../../shared/custom-fields/CustomMultiselectField'
import CustomPhoneInput from '../../../shared/custom-fields/CustomPhoneInput'
import { validatePhoneNumber } from '../../../shared/phone-number-formatter/formatPhoneNumber'
import LocationConfirmationBox from '../confirmation-modals/LocationConfirmationBox'
import LocationsLogo from '../LocationsLogo'

import { LocationFormDataModel, useLocationForm } from './hooks/useLocationForm'
import AddressInformationForm from './LocationAddressInformationForm'
import PaymentInformationForm from './LocationPaymentInformationForm'
import { validateFacilityId } from './utils'

interface Props {
    practiceId: string
    practiceName: string
    locationData: Models.PracticeLocation | null
    hasEhrIntegration: boolean
    hasPayments: boolean
    isEdit: boolean
    isLoading: boolean
    onClose: () => void
    onDeleteLocation: () => void
    onSubmit: (
        data: LocationFormDataModel,
        hasEhrIntegrationFields: boolean,
        hasDirectSchedulingFields: boolean,
    ) => void
}

const LocationForm = ({
    practiceId,
    practiceName,
    locationData,
    hasPayments,
    isEdit,
    isLoading,
    onClose,
    onDeleteLocation,
    onSubmit,
}: Props) => {
    const isLocationSurveyCompleted = Boolean(locationData?.survey?.status === 'completed')
    const hasStripeAccount = Boolean(locationData?.stripeConnectedAccountId)

    const [showFacilityIdModal, setShowFacilityIdModal] = useState(false)

    const {
        formValues,
        isValid,
        isDirty,
        hasEditFacilityIdPermission,
        hasEhrIntegrationFields,
        hasDirectSchedulingFields,
        handleFormFieldChange,
        handleFormFieldsBulkChange,
        handleInputChange,
    } = useLocationForm(locationData, hasPayments, hasStripeAccount, isEdit, isLoading)

    const handlePhoneNumberChange = (value: string) =>
        handleFormFieldChange('phone', value, {
            isValid: validatePhoneNumber(value, formValues.countryCodeIsoAlpha2?.value || 'US'),
            isTouched: true,
        })

    const handleFacilityIdChange = (e: any) => {
        const value = e.target.value
        handleFormFieldChange('facilityId', value, {
            isValid: validateFacilityId(value, hasEditFacilityIdPermission),
            isTouched: true,
        })
    }

    // const handleSoftwareChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    //     const software = value ? { value, isValid: true, isTouched: true } : undefined
    //     const version = software ? formValues.version : undefined
    //     const ipAddress = software ? formValues.ipAddress : undefined
    //     handleFormFieldsBulkChange({ software, version, ipAddress })
    // }

    const handlePaymentInformationStatusChange = (value: string) =>
        handleFormFieldChange('paymentInformationStatus', value, { isValid: true })

    const handleLogoChange = (
        updatedLogo: Partial<{
            bgColor: string | null
            data: any | null
            shouldDelete: boolean
        }>,
    ) => {
        const shouldMakeDirty =
            updatedLogo.bgColor !== null || updatedLogo.data !== null || updatedLogo.shouldDelete !== false

        handleFormFieldChange(
            'logo',
            {
                ...formValues.logo,
                ...updatedLogo,
            },
            shouldMakeDirty ? { isTouched: true, isValid: true } : { isTouched: false },
            shouldMakeDirty,
        )
    }

    const handlePracticeSpecialityChange = (values: string[]) => {
        const value = values[0] || ''
        handleFormFieldChange('practiceSpecialtyType', value, {
            isValid: Boolean(value),
            isTouched: true,
        })
    }

    const handleProductsChange = (values: string[]) => {
        const value = values
        handleFormFieldChange('products', value, {
            isValid: Boolean(value),
            isTouched: true,
        })
    }

    const handleSubmit = () => {
        if (!isValid) return

        if (isEdit && formValues.facilityId?.value === '' && locationData?.facilityId !== '') {
            setShowFacilityIdModal(true)
            return
        }

        onSubmit(formValues, hasEhrIntegrationFields, hasDirectSchedulingFields)
    }

    const onCancelConfirmationBox = () => {
        handleFormFieldChange('facilityId', locationData?.facilityId, {
            isValid: true,
            isTouched: true,
        })
        setShowFacilityIdModal(false)
    }

    const onCloseConfirmationBox = () => {
        setShowFacilityIdModal(false)
    }

    const onConfirmSave = async () => {
        await onSubmit(formValues, hasEhrIntegrationFields, hasDirectSchedulingFields)
    }

    const generateAddressFields = useMemo(
        () => ({
            street: formValues.street,
            unit: formValues.unit,
            city: formValues.city,
            state: formValues.state,
            zip: formValues.zip,
            countryCodeIsoAlpha2: formValues.countryCodeIsoAlpha2,
            timezone: formValues.timezone,
            timezoneListForCountry: formValues.timezoneListForCountry,
            lat: formValues.lat,
            lng: formValues.lng,
            phone: formValues.phone,
        }),
        [
            formValues.street,
            formValues.unit,
            formValues.city,
            formValues.state,
            formValues.zip,
            formValues.countryCodeIsoAlpha2,
            formValues.timezone,
            formValues.timezoneListForCountry,
            formValues.lat,
            formValues.lng,
            formValues.phone,
        ],
    )

    return (
        <>
            <div className="location-modal">
                <div className="location-modal__content">
                    <div>
                        <i className="material-icons close-modal" onClick={onClose}>
                            close
                        </i>
                        <div>
                            <div className="account-id">
                                ID: {formValues.locationId?.value}
                                <CopyText text={formValues.locationId?.value ?? ''} />
                            </div>
                            <h1 className="name-header">{practiceName}</h1>
                            {isEdit && (
                                <Button className="upper-delete-button" onClick={onDeleteLocation}>
                                    Delete
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="content">
                        <CustomMultiselectField
                            items={PracticeSpecialtyTypes}
                            maxSelected={1}
                            selectedItems={[formValues.practiceSpecialtyType?.value || '']}
                            keyProperty="value"
                            displayProperty="display"
                            placeholder="Select Specialty"
                            label="Location Specialty*"
                            onSelectElement={handlePracticeSpecialityChange}
                            disabled={isLocationSurveyCompleted}
                        />
                    </div>
                    {hasEditFacilityIdPermission && (
                        <div className="content">
                            <div className="field facility-id">
                                <CustomField
                                    error={isDirty && formValues.facilityId?.value && !formValues.facilityId?.isValid}
                                    customFieldType={CustomFieldType.INPUT}
                                    value={formValues.facilityId?.value || ''}
                                    label="Facility ID (ex: AA123456)"
                                    onChange={handleFacilityIdChange}
                                    showErrorText={true}
                                    errorMessage="Facility ID must be two uppercase letters followed by six numbers"
                                />
                            </div>
                        </div>
                    )}
                    <div className="content">
                        <CustomField
                            customFieldType={CustomFieldType.INPUT}
                            value={formValues.name.value || ''}
                            label="Location Name*"
                            onChange={handleInputChange('name')}
                        />
                    </div>
                    {!isEdit && (
                        <div className="content">
                            <CustomMultiselectField
                                items={ProductTypes}
                                keyProperty="value"
                                displayProperty="displayName"
                                placeholder="Select Products"
                                label="Products"
                                selectAllLabel="All Products"
                                onSelectElement={handleProductsChange}
                            />
                        </div>
                    )}
                    <AddressInformationForm
                        addressFields={generateAddressFields}
                        isFormEditing={isEdit}
                        onSingleFieldChange={handleFormFieldChange}
                        onMultipleFieldsChange={handleFormFieldsBulkChange}
                    />
                    <div className="content">
                        <div className="field phone-number">
                            <CustomPhoneInput
                                error={isDirty && formValues.phone.value && !formValues.phone.isValid}
                                errorMessage="Phone number is invalid."
                                value={formValues.phone.value}
                                label="Phone Number*"
                                key={formValues.countryCodeIsoAlpha2?.value || ''}
                                country={formValues.countryCodeIsoAlpha2?.value || ''}
                                onChange={handlePhoneNumberChange}
                            />
                        </div>
                    </div>

                    <LocationsLogo
                        practiceId={practiceId}
                        locationId={formValues.locationId?.value || ''}
                        logo={formValues.logo.value}
                        updateLogoData={handleLogoChange}
                    />

                    {hasPayments && (
                        <PaymentInformationForm
                            hasStripeAccount={hasStripeAccount}
                            paymentInformationStatus={formValues.paymentInformationStatus?.value || ''}
                            onPaymentInformationStatusChange={handlePaymentInformationStatusChange}
                            hasDirectSchedulingFields={hasDirectSchedulingFields}
                            paymentAmount={formValues.paymentAmount?.value || ''}
                            onPaymentAmountChange={handleInputChange('paymentAmount', { isValid: true })}
                        />
                    )}
                </div>
                <button
                    className={classNames('update-button', {
                        'can-save': isValid && isDirty,
                    })}
                    onClick={handleSubmit}
                    disabled={!isValid}
                >
                    {isEdit ? 'Save Changes' : 'Add Location'}
                    {isLoading && <LinearProgress />}
                </button>
            </div>
            {showFacilityIdModal && (
                <Modal className="pst-modal" open={true} disableEscapeKeyDown={true}>
                    <LocationConfirmationBox
                        confirmFn={onConfirmSave}
                        cancelFn={onCancelConfirmationBox}
                        closeFn={onCloseConfirmationBox}
                        type={`deleteFacilityId`}
                    />
                </Modal>
            )}
        </>
    )
}

export default LocationForm
