import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import classNames from 'classnames'

import { CustomFieldType } from '../../../models/enums'
import CustomField from '../../shared/custom-fields/CustomField'
import { isRequired, ValidatorObj } from '../../shared/form-validator/validator'

import './EditableField.sass'

type FieldData = {
    value: string
    isValid: boolean
    firstErrorMessage?: string
    validators?: ValidatorObj[]
}

export type Option = {
    display: string
    value: string
}

const moduleName = 'editable-field'
interface Props {
    fieldData: FieldData
    customFieldType: CustomFieldType
    inputType?: string
    className?: string
    editButtons?: boolean
    label?: string
    error?: boolean | string
    forceOpenEdit?: boolean
    placeholder?: string
    options?: Option[]
    noBorder?: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onSave: (value: string) => void
    onCancel: (value: string) => void
}

const EditableField = (props: Props) => {
    const {
        fieldData: { value: propsValue, isValid, firstErrorMessage, validators },
        label,
        customFieldType,
        inputType,
        editButtons,
        noBorder,
        options,
        placeholder,
        forceOpenEdit,
    } = props

    const [edit, setEdit] = useState<boolean>(Boolean(forceOpenEdit && editButtons))
    const [showButtons, setShowButtons] = useState<boolean>(false)
    const [value, setValue] = useState<string>(propsValue || '')
    const [prevValue, setPrevValue] = useState<string>(propsValue || '')
    const isFieldRequired = Boolean(validators?.find(validator => validator.errorMessage === isRequired().errorMessage))

    useEffect(() => {
        if (editButtons) {
            setShowButtons(true)
        }
    }, [editButtons])

    const openEditView = () => {
        setEdit(true)
    }

    const renderOptions = () => {
        return props.options?.map((option: Option) => {
            return (
                <option key={option.value} value={option.value}>
                    {option.display}
                </option>
            )
        })
    }

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.target.value
        if (inputType === 'phoneNumber') {
            newValue = event.target.value
        }
        setValue(newValue)
        if (!showButtons) {
            setPrevValue(event.target.value)
        }
        props.onChange(event)
    }

    const onCancel = () => {
        setEdit(false)
        if (prevValue) {
            setValue(prevValue)
            props.onCancel(prevValue)
        }
    }

    const onSave = () => {
        setPrevValue(value)
        props.onSave(value)
        setEdit(false)
    }

    const getDisplayValue = () => {
        if (customFieldType === CustomFieldType.SELECT && options) {
            return options.find(option => option.value === propsValue)?.display
        }

        return propsValue
    }

    let showInput = showButtons ? edit : !edit
    let showEditPen = showButtons && !edit
    let showSaveCancel = showButtons && edit

    return (
        <div
            className={classNames(`${moduleName}`, {
                [`${moduleName}--narrow`]: Boolean(noBorder),
            })}
        >
            {Boolean(label) && (
                <div className={`${moduleName}__field-label`}>
                    <div className={`${moduleName}__field-label-text`}>{label}</div>
                </div>
            )}
            <div
                className={classNames(`${moduleName}__field-input-container`, {
                    [`${moduleName}--no-border`]: Boolean(noBorder),
                })}
            >
                <div
                    className={classNames(`${moduleName}__field-input`, {
                        [`${moduleName}--min-width`]: Boolean(showEditPen && noBorder),
                    })}
                >
                    {showInput ? (
                        <CustomField
                            customFieldType={customFieldType}
                            inputType={inputType}
                            value={value}
                            error={props.error}
                            errorMessage={firstErrorMessage}
                            onChange={onChangeValue}
                            placeholder={placeholder}
                        >
                            {customFieldType === CustomFieldType.SELECT && renderOptions()}
                        </CustomField>
                    ) : (
                        <div className={`${moduleName}__field-value`}>{getDisplayValue()}</div>
                    )}
                </div>
                <div>
                    {showEditPen && (
                        <div className={`${moduleName}__icon-wrapper`} onClick={openEditView}>
                            <i className={`material-icons ${moduleName}__icon`}>edit</i>
                        </div>
                    )}
                    {showSaveCancel && (
                        <div className={`${moduleName}__action-buttons`}>
                            <div className={`${moduleName}__cancel-button`}>
                                <Button
                                    className={classNames(`${moduleName}__save-button`, {
                                        [`${moduleName}--disabled`]: (!value && isFieldRequired) || !isValid,
                                    })}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </div>
                            {(prevValue || !isFieldRequired) && (
                                <div className={`${moduleName}__cancel-button`}>
                                    <Button color="primary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EditableField
