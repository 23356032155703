// TO-DO-V2 - refactor heartbeat agent module to use practice V2 and delete this
import { HeartbeatAgentModuleLocation, PracticeLocation } from '../../../models/v2/Practice'

// mapping practice location response
export function mapPracticeLocations(
    locations: ApiV2.StructuredResponse<Array<ApiV2.Practice.PracticeLocation>>,
): ApiV2.StructuredResponse<Array<PracticeLocation>> {
    return {
        ...locations,
        data: locations.data.map(mapPracticeLocation),
    }
}

export function mapPracticeLocation(location: ApiV2.Practice.PracticeLocation): PracticeLocation {
    return {
        id: location.id,
        name: location.name,
        heartbeatAgentModuleLocation: location.heartbeat_agent_module_location.map(mapHeartbeatAgentModuleLocation),
    }
}

export function mapHeartbeatAgentModuleLocation(
    heartbeatAgentModuleLocation: ApiV2.Practice.HeartbeatAgentModuleLocation,
): HeartbeatAgentModuleLocation {
    return {
        externalLocationId: heartbeatAgentModuleLocation.external_location_id,
        hasHeartbeat: heartbeatAgentModuleLocation.has_heartbeat,
    }
}
