import { connect } from 'react-redux'

import { RootState } from '../../appReducer'

import PaymentsMain, { PaymentsMainProps } from './PaymentsMain'

const mapStateToProps = (state: RootState, props: PaymentsMainProps) => ({
    practice: props.practice,
})

export default connect(mapStateToProps, {})(PaymentsMain)
