export const setCheckedLocation = (
    location: Models.PracticeLocation,
    index: number,
    employee: ModelsV2.Practice.PracticeAccount,
) => {
    if (!employee && index === 0) {
        return true
    }
    return Boolean(
        employee &&
            employee.practice_locations &&
            employee.practice_locations.find(
                employePracticeLocation =>
                    employePracticeLocation.practice_location_id === location.id &&
                    employePracticeLocation.status === 2,
            ),
    )
}

export const setCheckedLocationEmailPermission = (
    location: Models.PracticeLocation,
    index: number,
    employee: ModelsV2.Practice.PracticeAccount,
) => {
    if (!employee && index === 0) {
        return true
    }
    return Boolean(
        employee &&
            employee.practice_locations &&
            employee.practice_locations.find(
                employePracticeLocation =>
                    employePracticeLocation.practice_location_id === location.id &&
                    employePracticeLocation.permissions?.email_notifications,
            ),
    )
}

export const setCheckedLocationSmsPermission = (
    location: Models.PracticeLocation,
    index: number,
    employee: ModelsV2.Practice.PracticeAccount,
) => {
    if (!employee && index === 0) {
        return false
    }
    return Boolean(
        employee?.practice_locations?.find(
            employePracticeLocation =>
                employePracticeLocation.practice_location_id === location.id &&
                employePracticeLocation.permissions.sms_notifications,
        ),
    )
}
