import React from 'react'

import { SurveySectionQuestion } from '../../../../../../models/PracticeSurvey'

import SelectMultiViewDefault from './SelectMultiViewDefault'
import SelectMultiViewTabs from './SelectMultiViewTabs'

import './SelectMulti.sass'
interface Props {
    question: SurveySectionQuestion
}

const SelectMultiView = ({ question }: Props) => {
    if (question.component_type === 'tabs') {
        return <SelectMultiViewTabs question={question} />
    }

    return <SelectMultiViewDefault question={question} />
}

export default SelectMultiView
