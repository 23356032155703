import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import _ from 'lodash'

import { ChatStatus } from '../../../models/ChatStatus'
import PubNubService from '../../../services/PubNubService'
import { newObjectId } from '../../../util/objectId'
import { mainBlue } from '../../shared/styles/colors'
import useMessaging, { OnSendMessageParams } from '../chat-tiles-container-hooks/useMessaging'
import ShortcutList from '../ShortcutList'

import './ChatTextField.sass'

enum Mode {
    Chat = 'chat',
    Whisper = 'whisper',
}

type Props = {
    selectedChat: Models.ChatCenterSelectedChat
    isBanned: boolean | undefined
    shortcuts: Models.Shortcut[]
    refreshConversationInProgress: boolean
    focused?: boolean
    setPendingMessage: (message: Models.Message) => void
    refreshConversation: () => void
}

type InitializeOnStartTyping = () => void

const ChatTextFieldTheme = createTheme({
    overrides: {
        MuiInput: {
            underline: {
                '&:after': {
                    display: 'none',
                },
                '&:before': {
                    display: 'none',
                },
            },
        },
        MuiCircularProgress: {
            root: { color: mainBlue },
            colorPrimary: { color: mainBlue },
        },
    },
})

const ChatTextField = (props: Props) => {
    const { selectedChat, shortcuts, isBanned, focused, refreshConversationInProgress, refreshConversation } = props

    const [text, setText] = useState<string>('')
    const [mode, setMode] = useState<Mode>(Mode.Chat)
    const [filteredShortcuts, setFilteredShortcuts] = useState<Models.Shortcut[]>([])
    const [selectedShortcut, setSelectedShortcut] = useState<number>(0)

    const selectedChatChannelName = selectedChat.channelName
    const initializeOnStartTypingRef = useRef<InitializeOnStartTyping | null>(null)
    const messageInputTextField = useRef<HTMLInputElement>()

    const { onSendMessage } = useMessaging()

    useEffect(() => {
        if (focused) {
            setTimeout(() => messageInputTextField.current?.focus(), 500)
        }
    }, [focused])

    const resizeTextArea = useCallback(() => {
        resetTextAreaHeight()
        const textarea: any = messageInputTextField?.current
        const scrollHeight = textarea.scrollHeight
        const minHeight = 61
        const maxHeight = 241

        let newHeight
        if (scrollHeight >= minHeight) {
            newHeight = scrollHeight <= maxHeight ? scrollHeight : maxHeight
        } else {
            newHeight = minHeight
        }

        textarea.style.height = `${newHeight}px`
    }, [])

    useEffect(() => {
        resizeTextArea()
    }, [text, resizeTextArea])

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const key = event.key

        if (filteredShortcuts.length > 0) {
            if (key === 'ArrowUp') {
                event.preventDefault()
                const shortcutIndex = selectedShortcut <= 0 ? filteredShortcuts.length - 1 : selectedShortcut - 1

                setText(`/${filteredShortcuts[shortcutIndex].name}`)
                setSelectedShortcut(shortcutIndex)
            }

            if (key === 'ArrowDown') {
                event.preventDefault()
                const shortcutIndex = selectedShortcut >= filteredShortcuts.length - 1 ? 0 : selectedShortcut + 1

                setText(`/${filteredShortcuts[shortcutIndex].name}`)
                setSelectedShortcut(shortcutIndex)
            }
        }

        if (key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
        }
    }

    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { selectedChat } = props
        const key = event.key
        const textTrimmed = text.trim()

        if (!textTrimmed) {
            closeShortcuts()
            onStopTyping()
            return
        }

        if (mode !== Mode.Whisper && key !== 'Backspace' && key !== 'Delete' && initializeOnStartTypingRef.current) {
            initializeOnStartTypingRef.current()
        }

        if (key === 'Enter' && !event.shiftKey) {
            const channel = mode === Mode.Whisper ? `whisper_${selectedChat.channelName}` : selectedChat.channelName

            // If the shortcut list is still open don't send the text on 'Enter', but select the correct shortcut
            if (filteredShortcuts.length > 0) {
                insertShortcut(text)
                return
            }

            const messageId = newObjectId(32)

            if (mode !== Mode.Whisper) {
                setPendingMessage(messageId, text)
            }

            sendMessage({ channel, message: text, chatMetadata: selectedChat, messageId })
            setText('')
            resetTextAreaHeight()
        }
    }

    const setPendingMessage = (messageId: string, text: string) => {
        props.setPendingMessage({
            id: messageId,
            text: text,
            is_patient: false,
            sender_id: PubNubService.getChatterId(),
            type: 'text',
            status: 'pending',
            timetoken: (Date.now() * 10000).toString(),
        })
    }

    const onStartTyping = useCallback(() => {
        PubNubService.setState(selectedChatChannelName, { is_typing: true })
    }, [selectedChatChannelName])

    const onStopTyping = async () => {
        initializeOnStartTypingRef.current = _.once(onStartTyping)
        return PubNubService.setState(selectedChat.channelName, { is_typing: false })
    }

    useEffect(() => {
        initializeOnStartTypingRef.current = _.once(onStartTyping)
    }, [onStartTyping])

    const sendMessage = async (sendMessageParams: OnSendMessageParams) => {
        await onStopTyping()
        const { channel, message, chatMetadata, messageId } = sendMessageParams
        onSendMessage({ channel, message, chatMetadata, messageId })
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value

        if (newText.charAt(0) === '/') {
            filterShortcuts(newText.substr(1)) // removes the '/' character
        }

        setText(newText)
    }
    const resetTextAreaHeight = () => {
        const textarea: any = messageInputTextField?.current
        textarea.style.height = '61px'
    }

    const filterShortcuts = (key: string) => {
        let newList: Models.Shortcut[] = []

        newList = shortcuts.filter((shortcut: Models.Shortcut) => {
            const lowercaseShortcut = shortcut.name.toLowerCase()
            const lowercaseKey = key.toLowerCase()

            return lowercaseShortcut.indexOf(lowercaseKey) === 0
        })

        setFilteredShortcuts(newList)
    }

    const insertShortcut = (text: string) => {
        const shortcutIndex =
            filteredShortcuts.length > selectedShortcut ? selectedShortcut : filteredShortcuts.length - 1
        const shortcut = filteredShortcuts[shortcutIndex]
        text = (shortcut && shortcut.message) || text

        selectShortcut(text)
    }

    const selectShortcut = (text: string) => {
        setText(text)
        closeShortcuts()
        messageInputTextField?.current?.focus()
    }

    const closeShortcuts = () => {
        setSelectedShortcut(0)
        setFilteredShortcuts([])
    }

    const isChatEnded = () => {
        const { value } = selectedChat.status
        return value === ChatStatus.PatientClosed.value
    }

    const opacityStyle = isBanned ? { opacity: 0.5 } : { opacity: 1 }

    return (
        <MuiThemeProvider theme={ChatTextFieldTheme}>
            <div className="chat-text-field">
                <div className="chat-text-field__message-input" style={opacityStyle}>
                    {filteredShortcuts.length > 0 && (
                        <ShortcutList
                            filteredShortcuts={filteredShortcuts}
                            selectedShortcut={selectedShortcut}
                            selectShortcut={selectShortcut}
                            closeShortcuts={closeShortcuts}
                        />
                    )}

                    <div className="chat-buttons">
                        <button
                            className={classNames('button', { selected: mode === Mode.Chat })}
                            onClick={() => setMode(Mode.Chat)}
                        >
                            Chat
                        </button>
                        <button
                            className={classNames('button', { selected: mode === Mode.Whisper })}
                            onClick={() => setMode(Mode.Whisper)}
                        >
                            Whisper
                        </button>
                        <span onClick={refreshConversation} className="refresh-button">
                            {!refreshConversationInProgress ? (
                                <i className="material-icons refresh-icon">refresh</i>
                            ) : (
                                <span className={`refresh-button-loader`}>
                                    <CircularProgress thickness={5} size={17} color="primary" variant="indeterminate" />
                                </span>
                            )}
                        </span>
                    </div>
                    <TextField
                        inputRef={messageInputTextField}
                        className="input-field"
                        placeholder="Type your message here"
                        fullWidth={true}
                        multiline={true}
                        disabled={isBanned || isChatEnded()}
                        rows={5}
                        value={text}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onKeyUp={onKeyUp}
                    />
                </div>

                {isBanned && (
                    <div className="centered-ban-message-red message-red">
                        {selectedChat.ip}
                        <br />
                        Banned
                    </div>
                )}
            </div>
        </MuiThemeProvider>
    )
}

export default ChatTextField
