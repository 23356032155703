import * as React from 'react'
import Button, { ButtonClassKey } from '@mui/material/Button'
import Modal from '@mui/material/Modal'

import './StaffFormUnstopSmsNotificationModal.sass'

interface StaffFormUnstopSmsNotificationModalProps {
    phoneNumberToUnstop: string | null
}

export type StaffFormUnstopSmsNotificationModalDispatch = {
    onClose: () => void
}

type Props = StaffFormUnstopSmsNotificationModalProps & StaffFormUnstopSmsNotificationModalDispatch

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const moduleName = 'staff-form-unstop-sms-notifications-modal'
const buttonClassOverrides: ButtonClassOverrides = {
    outlined: `${moduleName}__cancel_btn`,
}

const StaffFormUnstopSmsNotificationModal = ({ onClose, phoneNumberToUnstop }: Props) => {
    return (
        <div>
            <Modal
                className="pst-modal"
                open={true}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose()
                    }
                }}
                disableEscapeKeyDown={true}
            >
                <div className={`${moduleName}__container`}>
                    <div className={`${moduleName}__header`}>
                        <span className={`${moduleName}__close-button`} onClick={onClose}>
                            <i className="material-icons">close</i>
                        </span>
                    </div>
                    <div className={`${moduleName}__content`}>
                        <div className={`${moduleName}__icon`}>
                            <i className="material-icons">info</i>
                        </div>
                        <div className={`${moduleName}__inner-content`}>
                            <div
                                className={`${moduleName}__title`}
                            >{`${phoneNumberToUnstop} has previously opted out of SMS communications.`}</div>
                            <div
                                className={`${moduleName}__notification`}
                            >{`To re-enable this feature, ask the user to send "UNSTOP" to ${process.env.REACT_APP_TWILIO_SMS_SENDER_PHONE_NUMBER}.`}</div>
                        </div>
                    </div>
                    <div className={`${moduleName}__footer`}>
                        <Button variant="outlined" classes={buttonClassOverrides} onClick={onClose}>
                            CANCEL
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default StaffFormUnstopSmsNotificationModal
