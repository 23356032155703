import moment from 'moment'

const DATE_FORMAT = 'MM/DD/YYYY'

export const isDateValid = (value: string) => {
    const formattedValue = formatDateOfBirth(value)
    return formattedValue.replace(/\D/g, '').length === 8
}

export const isDobDate = (value: string) => {
    if (!isDateValid(value)) {
        return false
    }
    const date = moment(formatDateOfBirth(value), DATE_FORMAT)
    const maxDate = moment()
    const minDate = moment('01/01/1900', DATE_FORMAT)
    return date.isAfter(minDate) && date.isBefore(maxDate)
}

const formatDateOfBirth = (dob: string) => {
    const formatType1 = 'YYYY-MM-DD'
    const formatType2 = 'MM/DD/YYYY'

    const isFormatType1 = dob.includes('-')

    if (isFormatType1) {
        return moment(dob, formatType1).format(formatType2)
    }

    return dob
}
