import ApiService from '../service'

export default class YextService extends ApiService {
    async getYextLocations(): Promise<ApiV2.StructuredResponse<ApiV2.Yext.GetYextLocationsResponse>> {
        const structuredResponse = (await this.fetch(`yext/locations`)) as ApiV2.StructuredResponse<
            ApiV2.Yext.GetYextLocationsResponse
        >

        return structuredResponse
    }
}
