/* tslint:disable:max-line-length */
const MEDICAL_QUESTION_LIST: Array<Models.DefaultSurveyQuestion> = [
    {
        id: 'connectHours',
        question: '<b>Connect hours:</b>',
        htmlClass: 'big',
    },
    {
        id: '631011603',
        question: 'What is/are the name(s) of your <b>practice(s)?</b>',
        htmlClass: 'big',
    },
    {
        id: '631011604',
        question: '<b>What is/are the name(s) of the doctor(s)?</b>',
        htmlClass: 'small',
    },
    {
        id: '631011608',
        question: 'What is/are the practice website URL/address(es)?',
        htmlClass: 'small',
    },
    {
        id: '631011609',
        question: 'What are the addresses of ALL practice locations?',
        htmlClass: 'small',
    },
    {
        id: '631011610',
        question: 'What are the phone numbers of ALL practice locations?',
        htmlClass: 'small',
    },
    {
        id: '631011611',
        question: 'At what email address(es) would you like to receive Amplify referrals?',
        htmlClass: 'small',
    },
    {
        id: '631011612',
        question: 'What is your office emergency number?',
        htmlClass: 'small',
    },
    {
        id: '631011613',
        question: 'Do you allow non-existing patients to contact your emergency number?',
        htmlClass: 'small',
    },
    {
        id: '631011614',
        question: 'What is your fax number?',
        htmlClass: 'small',
    },
    {
        id: '631011630',
        question: 'Please provide the office email address that patients may use.',
        htmlClass: 'big',
    },
    {
        id: '631011615',
        question:
            'What are your hours of operation? If you have more than one office, please specify what days and hours you are available at each location.',
        htmlClass: 'small',
    },
    {
        id: '631011616',
        question: 'Please select the major holidays that the office closes for:',
        htmlClass: 'small',
    },
    {
        id: '631014063',
        question: 'What treatments does your practice provide?',
        htmlClass: 'small',
    },
    {
        id: '631011617',
        question: 'Do you have a complimentary consultation?',
        htmlClass: 'small',
    },
    {
        id: '631011631',
        question: 'What is included in the complimentary consultation?',
        htmlClass: 'big',
    },
    {
        id: '631011618',
        question: 'What payment options does your practice offer?',
        htmlClass: 'small',
    },
    {
        id: '631011620',
        question: 'Do you accept Medicaid?',
        htmlClass: 'small',
    },
    {
        id: '631011619',
        question: 'What is the name of your state sponsored Medicaid?',
        htmlClass: 'small',
    },
    {
        id: '631011621',
        question:
            'Please provide a list of insurances that you accept at your practice AND/OR let us know how we may respond to the common questions like "Do you accept insurance?"',
        htmlClass: 'big',
    },
    {
        id: '631011622',
        question: "If you do not accept a patient's specific insurance, how would you like us to reply to them?",
        htmlClass: 'small',
    },
    {
        id: '631011623',
        question: 'What age range of patients do you accept?',
        htmlClass: 'small',
    },
    {
        id: '631011629',
        question: 'Please provide the link to your online Patient Forms.',
        htmlClass: 'small',
    },
    {
        id: '631011625',
        question: 'What can we say to draw patients to your amazing practice?',
        htmlClass: 'small',
    },
    {
        id: '631011624',
        question: 'What differentiates your practice from your competitors?',
        htmlClass: 'big',
    },
    {
        id: '631011626',
        question:
            'For the purpose of composing customized scripting, what can we say about your doctor(s), your team, or your practice?',
        htmlClass: 'small',
    },
    {
        id: '631011627',
        question: 'Is there anything else we should know about your practice?',
        htmlClass: 'small',
    },
    {
        id: '631011605',
        question: 'Who (or what business) is in charge of your website?',
        htmlClass: 'small',
    },
    {
        id: '631011606',
        question: `What is the web developer's email address?`,
        htmlClass: 'small',
    },
    {
        id: '631011607',
        question: `What is the web developer's phone number?`,
        htmlClass: 'small',
    },
].map(surveyItem => ({
    // Create raw (lowercase, no formatting) version for search
    questionRaw: surveyItem.question.toLowerCase().replace(/<b>|<\/b>/i, ''),
    ...surveyItem,
}))

export default MEDICAL_QUESTION_LIST
