import ApiService from '../service'

export default class AdminService extends ApiService {
    readonly ModulePath: string = 'api/admin/administration/v1'
    private readonly PAGE_SIZE = 5

    async list(
        list: Api.AdminListType,
        searchTerms: Api.Amplify.AdminUsersSearchTerms = {},
    ): Promise<Api.StructuredResponse<Api.Account[]>> {
        const {
            limit = this.PAGE_SIZE,
            page = 1,
            permissionTypes,
            searchKey,
            sort = 'created',
            order = 'desc',
        } = searchTerms

        const structuredResponse = (await this.fetch(
            'admins',
            {},
            {
                limit,
                page,
                list,
                permissionTypes,
                searchKey,
                sort,
                order,
            },
        )) as Api.StructuredResponse<Api.Account[]>

        return structuredResponse
    }

    async permissionList(): Promise<Api.Permission[]> {
        const { data: permissions } = await this.fetch('admin-permissions')
        return permissions
    }

    async postAdminCreate(values: Api.AccountCreate): Promise<Api.Account> {
        const { data: created } = await this.fetch(`admins`, {
            method: 'post',
            body: JSON.stringify(values),
        })

        return created
    }

    async putAdminUpdate(admin: Models.Account, changes: Api.AccountUpdate): Promise<Api.Account> {
        const { data: updated } = await this.fetch(`admins/${admin.id}`, {
            method: 'put',
            body: JSON.stringify(changes),
        })

        return updated
    }

    async postPasswordReset(admin: Models.Account): Promise<void> {
        await this.fetch(`password-reset`, {
            method: 'post',
            body: JSON.stringify({
                admin_id: admin.id,
            }),
        })
    }

    async unlockAccount(admin: Models.Account): Promise<Api.Account> {
        const { data: unlockedAccount } = await this.silentFetch({
            endpoint: `admins/${admin.id}/unlock`,
            requestOptions: {
                method: 'put',
            },
        })

        return unlockedAccount
    }
}
