import React from 'react'

import SelfSchedulingModel from '../../../../models/SelfScheduling'
import ColorPickerButton from '../../../shared/ColorPickerButton'

import { CustomizationObject } from './types'

import './SelfSchedulingModalColors.sass'

const moduleName = 'self-scheduling-modal-colors'

export type SelfSchedulingModalColorsProps = {
    colors: Models.SelfSchedulingModalColors
    style: Models.SelfSchedulingModalBackgroundColorStyle
    updateCustomColor: (colorKey: Models.SelfSchedulingModalColorTypes, colorValue: string) => void
    resetColors: (event: React.MouseEvent<HTMLElement>, customizationObject: CustomizationObject) => void
}

function SelfSchedulingModalColors(props: SelfSchedulingModalColorsProps) {
    const { updateCustomColor, resetColors, style } = props

    const colors = {
        ...SelfSchedulingModel.defaultModalColors,
        ...props.colors,
    }

    function updateColor(type: Models.SelfSchedulingModalColorTypes) {
        return (color: string) => {
            updateCustomColor(type, color)
        }
    }

    function handleResetColors(event: React.MouseEvent<HTMLElement>) {
        resetColors(event, 'schedulingModal')
    }

    return (
        <div>
            <h3 className="webcode-section-title">Background Color{style === 'gradient' && 's'}:</h3>
            <div className={`${moduleName}__background-colors-wrapper`}>
                <ColorPickerButton
                    id="background-color-1"
                    key="background-color-1"
                    color={colors.backgroundColor1}
                    label={`Color${style === 'gradient' ? ` 1` : ``}`}
                    updateCustomColor={updateColor('backgroundColor1')}
                />
                {style === 'gradient' && (
                    <ColorPickerButton
                        id="background-color-2"
                        key="background-color-2"
                        color={colors.backgroundColor2}
                        label="Color 2"
                        updateCustomColor={updateColor('backgroundColor2')}
                    />
                )}
            </div>
            <h3 className="webcode-section-title">Text Color (appearing on background only)</h3>
            <div className={`${moduleName}__background-colors-wrapper`}>
                <ColorPickerButton
                    id="text-color"
                    key="text-color"
                    color={colors.text}
                    label="Color"
                    updateCustomColor={updateColor('text')}
                />
            </div>
            <div className={`${moduleName}__reset-colors-wrapper`}>
                <a onClick={handleResetColors} className={`${moduleName}__reset-colors`} href="/#">
                    Reset colors to default
                </a>
            </div>
        </div>
    )
}

export default SelfSchedulingModalColors
