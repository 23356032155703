import React, { Fragment, useState } from 'react'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'

import ApiV2 from '../../../ApiV2'
import { RootState } from '../../../appReducer'
import { CustomFieldType } from '../../../models/enums'
import { IntegrationsSoftware } from '../../../models/v2/Integrations'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CustomMultiselectFieldV2 from '../../shared/custom-fields/custom-multiselect-field-v2/CustomMultiselectFieldV2'
import CustomField from '../../shared/custom-fields/CustomField'
import ErrorMessage from '../../shared/error-message/error-message'
import { mainBlue } from '../../shared/styles/colors'

import { loadAgent, setAgentsPending } from './actions'

import './Integrations.sass'

const moduleName = 'integrations'

export const SOFTWARE_TYPES = {
    DENTRIX: 'DENTRIX',
    DENTRIX_ENTERPRISE: 'DENTRIX_ENTERPRISE',
    EAGLESOFT: 'EAGLESOFT',
    OPENDENTAL: 'OPENDENTAL',
    QUICKBOOKS: 'QUICKBOOKS',
    VYNE_SYNC: 'VYNE_SYNC',
}

interface Props {
    practice: Models.Practice
    agentId?: string
    software: IntegrationsSoftware[]
    createdAgentId: React.MutableRefObject<string | undefined>
}

export const IntegrationsCreateAgentForm = ({ practice, software, createdAgentId }: Props) => {
    const softwareFiltered = software.filter(soft => soft.moduleTypeName !== SOFTWARE_TYPES.VYNE_SYNC)
    const [agentName, setAgentName] = useState('')
    const [selectedModules, setSelectedModules] = useState([softwareFiltered[0].id])
    const [error, setError] = useState<string | null>(null)
    const selectedAgent = useSelector((state: RootState) => state.v2.integrations.selectedAgent)

    const isPending = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.isPending ?? false,
    )

    const dispatch = useAppDispatch()

    const handleAddModule = () => {
        if (selectedModules.length < softwareFiltered.length) {
            const nextModule = softwareFiltered.find(soft => !selectedModules.includes(soft.id))
            if (nextModule) {
                setSelectedModules([...selectedModules, nextModule.id])
            }
        }
    }

    const handleRemoveModule = (moduleIndex: number) => {
        const newModulesArray = [...selectedModules]
        newModulesArray.splice(moduleIndex, 1)
        setSelectedModules(newModulesArray)
    }

    const updateAgent = (id: string) => {
        createdAgentId.current = id
    }

    const handleOnSubmit = (agentId?: string, externalAgentId?: number) => {
        setError(null)
        dispatch(setAgentsPending(practice.id, true))
        agentId && externalAgentId ? handleModuleCreate(agentId, externalAgentId) : handleAgentCreate()
    }

    const handleAgentCreate = async () => {
        try {
            const agent = await ApiV2.Integrations.postIntegrationsAgent(practice.id, agentName)
            await dispatch(loadAgent(agent.external_agent_id))
            updateAgent(agent.id)
            handleModuleCreate(agent.id, agent.external_agent_id)
        } catch (error) {
            setError('Cannot create an agent')
            dispatch(setAgentsPending(practice.id, false))
        }
    }

    const handleModuleCreate = async (agentId: string, externalAgentId: number) => {
        try {
            await ApiV2.Integrations.postIntegrationsAgentModule(agentId, selectedModules[0])
            await dispatch(loadAgent(externalAgentId))
            dispatch(setAgentsPending(practice.id, false))
        } catch (error) {
            setError('Cannot add an agent module')
            dispatch(setAgentsPending(practice.id, false))
        }
    }

    const generateSoftwareItems = (parentId: number) =>
        softwareFiltered.map(soft => ({
            id: soft.id.toString(),
            name: `${soft.moduleTypeName} ${
                soft.version && soft.version.toLowerCase() !== 'null' ? ` (version ${soft.version})` : ''
            }`,
            disabled: selectedModules.includes(soft.id) && parentId !== soft.id,
        }))

    return (
        <Fragment>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isPending ? (
                <div className={`${moduleName}__loading-overlay`}>
                    <ReactLoading type="spin" color={mainBlue} height={40} width={40} />
                </div>
            ) : (
                <>
                    <h1 className={`name-header ${moduleName}__name-header`}>{selectedAgent?.name}</h1>
                    <h1 className={`name-header ${moduleName}__name-header`}>Add New Agent</h1>
                    <div className={`${moduleName}__field`}>
                        <CustomField
                            value={selectedAgent?.name || agentName}
                            customFieldType={CustomFieldType.INPUT}
                            disabled={!!selectedAgent?.name}
                            label="Agent Name"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setAgentName(event.target.value)}
                        />
                    </div>
                    <div className={`${moduleName}__modules`}>
                        {selectedModules.map((softId, index) => (
                            <div key={`${softId}-wrapper`} className={`${moduleName}__field-wrapper`}>
                                <div key={softId} className={`${moduleName}__field`}>
                                    <CustomMultiselectFieldV2
                                        items={generateSoftwareItems(softId)}
                                        maxSelected={1}
                                        selectedItems={[softId.toString()]}
                                        keyProperty="id"
                                        displayProperty="name"
                                        placeholder="Select Module"
                                        label="Module"
                                        onSelectElement={(values: string[]) => {
                                            const updatedModuleList = [...selectedModules]
                                            updatedModuleList[index] = parseInt(values[0], 10)
                                            setSelectedModules(updatedModuleList)
                                        }}
                                    />
                                </div>
                                {index !== 0 && (
                                    <div className={`material-icons ${moduleName}`}>
                                        <i
                                            className={`material-icons ${moduleName}__action-icon`}
                                            title="Delete Module"
                                            onClick={() => handleRemoveModule(index)}
                                        >
                                            delete
                                        </i>
                                    </div>
                                )}
                            </div>
                        ))}
                        <span onClick={handleAddModule} className={`${moduleName}__add`}>
                            + Add another module
                        </span>
                    </div>
                    <div className={`${moduleName}__footer`}>
                        <Button
                            disabled={!(selectedAgent?.name || agentName) || isPending}
                            className={`${moduleName}__submit-button`}
                            onClick={() => handleOnSubmit(createdAgentId.current, selectedAgent?.id)}
                        >
                            GET DOWNLOAD LINK AND KEYS
                        </Button>
                    </div>
                </>
            )}
        </Fragment>
    )
}
