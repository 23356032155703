import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import moment from 'moment-timezone'

import { RootState } from '../../../appReducer'
import { moveDefaultLocationToTop } from '../../../modules/shared/sortUtils'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CustomDateRange, { DATE_FORMAT } from '../../shared/custom-fields/custom-date-range/CustomDateRange'
import CustomMultiselectField from '../../shared/custom-fields/CustomMultiselectField'

import {
    fetchAllPracticeStaffUsers,
    fetchConnectedPracticeLocations,
    fetchSchedulingConfigurationLogs,
    setDateFrom,
    setDateTo,
    setSelectedLocation,
    setSelectedUsers,
} from './v2actions'

import './ConfigLogsFilters.sass'

type Props = {
    practiceId: string
    setCsvError: (error: boolean) => void
    setLoading: Dispatch<SetStateAction<boolean>>
}

export const DATE_ERROR_MSG = {
    dateFrom: 'Date from must be same or before date to.',
    dateTo: 'Date to must be same or after date from.',
}

const moduleName = 'config-logs-filters'

export const dateFromInitial = {
    value: moment()
        .subtract(1, 'weeks')
        .startOf('day')
        .format(DATE_FORMAT),
    isValid: true,
    errorMessage: '',
}

export const dateToInitial = {
    value: moment()
        .endOf('day')
        .format(DATE_FORMAT),
    isValid: true,
    errorMessage: '',
}

const ConfigLogsList = (props: Props) => {
    const { practiceId, setLoading } = props

    const connectedPracticeLocations = useSelector(
        (state: RootState) => state.v2.configLogs.connectedPracticeLocations?.[practiceId] || [],
    )
    const selectedLocationId = useSelector((state: RootState) => state.v2.configLogs.selectedLocationId?.[practiceId])
    const allPracticeUsers = useSelector((state: RootState) => state.v2.configLogs.allUsers?.[practiceId] || [])
    const selectedUsers = useSelector(
        (state: RootState) => state.v2.configLogs.selectedUserIds?.[practiceId] || ['all'],
    )
    const dateFrom = useSelector((state: RootState) => state.v2.configLogs.dateFrom?.[practiceId] || dateFromInitial)
    const dateTo = useSelector((state: RootState) => state.v2.configLogs.dateTo?.[practiceId] || dateToInitial)

    const applyFilterButtonDisabled = !selectedLocationId || !dateFrom || !dateTo

    const allPracticeUsersMapped = allPracticeUsers.map(user => ({
        id: user.id,
        name: user.name,
    }))

    const allPracticeUsersWithSelectAll = [{ id: 'all', name: 'Select All Users' }, ...allPracticeUsersMapped]

    const sortedConnectedPracticeLocations = connectedPracticeLocations.sort(moveDefaultLocationToTop)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchConnectedPracticeLocations(practiceId))
        dispatch(fetchAllPracticeStaffUsers(practiceId))
    }, [practiceId, dispatch])

    const selectPracticeLocation = (values: string[]) => {
        dispatch(setSelectedLocation(practiceId, values[0] || ''))
    }

    const selectUser = (userIds: string[]) => {
        dispatch(setSelectedUsers(practiceId, userIds))
    }

    const handleDateFromChange = (dateObj: any) => {
        let errorMessage = '' //validator(queryFilters[fieldName], dateObj)

        if (errorMessage) {
            dispatch(setDateFrom(practiceId, dateObj, Boolean(errorMessage), errorMessage))
            return
        }
        const isDatesRelationshipOK = moment(dateObj, DATE_FORMAT).isSameOrBefore(dateTo.value)
        const isValidDateInputted = dateFrom.isValid || dateFrom.errorMessage === DATE_ERROR_MSG['dateFrom']

        errorMessage = isValidDateInputted && !isDatesRelationshipOK ? DATE_ERROR_MSG['dateFrom'] : ''

        dispatch(setDateFrom(practiceId, dateObj, Boolean(errorMessage), errorMessage))
    }

    const handleDateToChange = (dateObj: any) => {
        let errorMessage = '' //validator(queryFilters[fieldName], dateObj)

        if (errorMessage) {
            dispatch(setDateTo(practiceId, dateObj, Boolean(errorMessage), errorMessage))
            return
        }
        const isDatesRelationshipOK = moment(dateObj, DATE_FORMAT).isSameOrAfter(dateFrom.value)
        const isValidDateInputted = dateTo.isValid || dateTo.errorMessage === DATE_ERROR_MSG['dateTo']

        errorMessage = isValidDateInputted && !isDatesRelationshipOK ? DATE_ERROR_MSG['dateTo'] : ''

        dispatch(setDateTo(practiceId, dateObj, Boolean(errorMessage), errorMessage))
    }

    const applyFilter = async () => {
        props.setCsvError(false)
        setLoading(true)
        await dispatch(
            fetchSchedulingConfigurationLogs(selectedLocationId, {
                user_ids: selectedUsers,
                start_date: moment(dateFrom.value, DATE_FORMAT)
                    .startOf('day')
                    .toISOString(),
                end_date: moment(dateTo.value, DATE_FORMAT)
                    .endOf('day')
                    .toISOString(),
            }),
        )
        setLoading(false)
    }

    return (
        <div className={moduleName}>
            <Grid container={true} spacing={3} className={moduleName}>
                <Grid item={true} sm={4} xs={6}>
                    <CustomMultiselectField
                        items={sortedConnectedPracticeLocations}
                        maxSelected={1}
                        selectedItems={selectedLocationId ? [selectedLocationId] : ['']}
                        keyProperty="id"
                        search={connectedPracticeLocations.length > 8}
                        placeholder="Select Practice Location"
                        label="Select Practice Location"
                        displayProperty="name"
                        onSelectElement={selectPracticeLocation}
                    />
                </Grid>
                <Grid item={true} sm={4} xs={6}>
                    <CustomMultiselectField
                        items={allPracticeUsersWithSelectAll}
                        maxSelected={1}
                        selectAllLabel={'Select All Users'}
                        selectedItems={selectedUsers}
                        keyProperty={'id'}
                        search={allPracticeUsers.length > 8}
                        placeholder={'Select User'}
                        displayProperty={'name'}
                        onSelectElement={value => {
                            selectUser(value)
                        }}
                        label="Select User(s)"
                    ></CustomMultiselectField>
                </Grid>
                <Grid item={true} sm={4} xs={6}>
                    <CustomDateRange
                        dateFrom={{
                            ...dateFrom,
                        }}
                        dateTo={{
                            ...dateTo,
                        }}
                        labelFrom="From"
                        labelTo="To"
                        onChangeDateFrom={handleDateFromChange}
                        onChangeDateTo={handleDateToChange}
                    />
                </Grid>
                <Grid item={true} xs={12} className={`${moduleName}__submit-button-wrapper`}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={applyFilter}
                        disabled={applyFilterButtonDisabled}
                    >
                        Get Logs
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default ConfigLogsList
