import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import { RootState } from 'appReducer'
import classNames from 'classnames'
import moment from 'moment'

import { CustomFieldType } from '../../../models/enums'
import CustomField from '../../../modules/shared/custom-fields/CustomField'
import MaskedInlineDatepicker from '../../../modules/shared/custom-fields/MaskedInlineDatepicker'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { isDobDate } from '../../shared/form-validator/isDateValid'
import { ReferralFormSections } from '../shared/enums'
import { fetchSchedulingAppointments, receiveFormOtherPatientData } from '../v2actions'

import ReferralProductionValues from './ReferralProductionValues'
import { editAdditionalPatient, receiveFormValueChange, removeAdditionalPatient } from './v2actions'

import './ReferralAdditionalPatient.sass'

type Props = {
    patient: ModelsV2.Referrals.ReferralPatient
    financial_data?: ModelsV2.Referrals.PatientFinancialData
    directScheduled?: boolean
    index: number
    failedSave: boolean
    timezone?: string
}

const moduleName = 'referral-additional-patient'

const TELE_APPOINTMENT = 'TELE-APPOINTMENT'

const appointmentTime = (time?: string, timezone?: string) => {
    if (time === TELE_APPOINTMENT) {
        return TELE_APPOINTMENT
    }
    if (!time) {
        return ''
    }
    if (!timezone) {
        return time
    }

    return moment.tz(new Date(time), timezone).format('MM/DD/YYYY h:mm A (z)')
}

const ReferralAdditionalPatient = (props: Props) => {
    const dispatch = useAppDispatch()
    const dateFormat = 'MM/DD/YYYY'
    const { index, patient, directScheduled, failedSave, timezone } = props
    const { first_name, last_name, date_of_birth, reason } = patient
    const scheduling_appointment_id = patient?.scheduling_appointment_id

    const otherPatients = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.otherPatients.value,
    ) as ModelsV2.Referrals.ReferralPatient[]

    useEffect(() => {
        if (scheduling_appointment_id) {
            dispatch(fetchSchedulingAppointments(scheduling_appointment_id))
        }
    }, [dispatch, scheduling_appointment_id])

    const schedulingAppointment = useSelector(
        (state: RootState) =>
            scheduling_appointment_id && state.v2.referrals.schedulingAppointments[scheduling_appointment_id],
    ) as ModelsV2.Referrals.SchedulingAppointment

    const appointment_time = patient?.desired_appointment_time
    const procedure = schedulingAppointment?.scheduling_procedure?.name
    const operatory_name =
        schedulingAppointment?.scheduling_operatory?.name || schedulingAppointment?.scheduling_operatory?.num
    const provider_name = `${schedulingAppointment?.scheduling_provider?.first_name} ${schedulingAppointment?.scheduling_provider?.last_name}`

    const onDeletePatient = (index: number) => {
        if (otherPatients?.length) {
            const otherPatientsClone = [...otherPatients]
            otherPatientsClone.splice(index, 1)

            dispatch(receiveFormOtherPatientData(otherPatientsClone))
            dispatch(removeAdditionalPatient(otherPatients[index].id))
        }
    }
    const onChangeValue = (field: string, value: string, index: number) => {
        if (otherPatients?.length) {
            const otherPatientsClone = [...otherPatients]
            const editedPatient = { ...otherPatientsClone[index] }

            editedPatient[field] = value
            otherPatientsClone.splice(index, 1, editedPatient)
            dispatch(receiveFormOtherPatientData(otherPatientsClone))
            dispatch(receiveFormValueChange(ReferralFormSections.OTHER_PATIENTS, field, value))
            dispatch(editAdditionalPatient(editedPatient.id, field))
        }
    }

    const formatDateOfBirth = (dob: string | undefined) => {
        if (!dob) {
            return
        }
        const formatType1 = 'YYYY-MM-DD'
        const formatType2 = 'MM/DD/YYYY'

        const isFormatType1 = dob.includes('-')

        if (isFormatType1) {
            return moment(dob, formatType1).format(formatType2)
        }

        return dob
    }

    const fieldErrors = useSelector((state: RootState) => state.v2.referrals.referralForm?.otherPatients.errors)

    const errorMsg = (fieldName: string, index: number): string => {
        if (!failedSave) {
            return ''
        }
        const err = fieldErrors?.find(err => {
            if (err.uiIndex !== undefined && err.uiIndex === index && err.metadata?.propertyName === fieldName) {
                return true
            }
            return false
        })
        if (err) {
            return err.details
        }
        return ''
    }
    const hasError = (field: string, index: number): boolean => {
        return errorMsg(field, index) !== ''
    }
    const dob = formatDateOfBirth(date_of_birth) || ''
    return (
        <div className={`${moduleName}__form`}>
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={2}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        required={true}
                        error={hasError('first_name', index)}
                        errorMessage={errorMsg('first_name', index)}
                        errorMessageInTooltip={true}
                        label="First Name*"
                        value={first_name || ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            onChangeValue('first_name', value, index)
                        }}
                    />
                </Grid>
                <Grid item={true} xs={2}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        required={true}
                        error={hasError('last_name', index)}
                        errorMessage={errorMsg('last_name', index)}
                        errorMessageInTooltip={true}
                        label="Last Name*"
                        value={last_name || ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            onChangeValue('last_name', value, index)
                        }}
                    />
                </Grid>
                <Grid item={true} xs={2}>
                    <div className={`${moduleName}__datepicker-wrapper`}>
                        <MaskedInlineDatepicker
                            dateMask={'99/99/9999'}
                            dateFormat={dateFormat}
                            label="Patient DOB"
                            value={dob}
                            error={(Boolean(dob) && !isDobDate(dob)) || hasError('date_of_birth', index)}
                            errorMessage={(Boolean(dob) && 'Invalid Date') || errorMsg('date_of_birth', index)}
                            onInputChange={value => {
                                onChangeValue('date_of_birth', value, index)
                            }}
                            onChange={value => {
                                onChangeValue('date_of_birth', value, index)
                            }}
                            validator={isDobDate}
                        />
                    </div>
                </Grid>
                <Grid item={true} xs={5}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Reason for Appointment"
                        value={reason || ''}
                        error={hasError('reason', index)}
                        errorMessage={errorMsg('reason', index)}
                        errorMessageInTooltip={true}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            onChangeValue('reason', value, index)
                        }}
                    />
                </Grid>
                <Grid item={true} xs={1}>
                    <Button
                        className={`${moduleName}__delete-button`}
                        onClick={() => {
                            onDeletePatient(index)
                        }}
                        disabled={directScheduled}
                    >
                        <span
                            className={classNames(`${moduleName}__delete-button-text`, {
                                [`${moduleName}__delete-button-text--disabled`]: directScheduled,
                            })}
                        >
                            <i className={`material-icons ${moduleName}__delete-icon`}>delete</i> DELETE
                        </span>
                    </Button>
                </Grid>
                {directScheduled && schedulingAppointment && (
                    <React.Fragment>
                        {appointment_time && (
                            <Grid item={true} xs={3} className={`${moduleName}__field-static`}>
                                <div className={`${moduleName}__field-label`}>Appointment Date and Time</div>
                                <div className={`${moduleName}__field-text`}>
                                    {appointmentTime(appointment_time, timezone)}
                                </div>
                            </Grid>
                        )}
                        {procedure && (
                            <Grid item={true} xs={3} className={`${moduleName}__field-static`}>
                                <div className={`${moduleName}__field-label`}>Procedure</div>
                                <div className={`${moduleName}__field-text`}>{procedure}</div>
                            </Grid>
                        )}
                        {operatory_name && (
                            <Grid item={true} xs={3} className={`${moduleName}__field-static`}>
                                <div className={`${moduleName}__field-label`}>Operatory Name</div>
                                <div className={`${moduleName}__field-text`}>{operatory_name}</div>
                            </Grid>
                        )}
                        {provider_name && (
                            <Grid item={true} xs={3} className={`${moduleName}__field-static`}>
                                <div className={`${moduleName}__field-label`}>Provider Name</div>
                                <div className={`${moduleName}__field-text`}>{provider_name}</div>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                <Grid item={true} xs={12}>
                    <ReferralProductionValues patientId={patient.id} patientName={[first_name, last_name].join(' ')} />
                </Grid>
            </Grid>
        </div>
    )
}

export default ReferralAdditionalPatient
