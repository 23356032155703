import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import { SearchBar } from '../../shared/custom-fields/SearchBar'

interface Props {
    practiceId: string
    locationQuery: string
    loading: boolean
    setLocationQuery: (value: string) => void
    setSearchPage: (value: number) => void
}

const HeartbeatSearchBar = ({ practiceId, locationQuery, loading, setLocationQuery, setSearchPage }: Props) => {
    const list = useSelector((state: RootState) => state.v2.practices.practices?.[practiceId]?.locations)

    const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setLocationQuery(value)
        setSearchPage(1)
    }

    const handleClearSearch = () => {
        setLocationQuery('')
        setSearchPage(1)
    }

    const throttledHandleSearch = throttle(handleSearch, 1000, {
        trailing: false,
    })

    return (
        <div className={`heartbeat-search-bar-wrapper`}>
            <SearchBar
                value={locationQuery}
                onClear={handleClearSearch}
                onChange={throttledHandleSearch}
                placeholder="Search by Location Name or Location ID or Facility ID"
                isMessageShown={locationQuery.length > 0 && !loading && list?.length === 0}
            />
        </div>
    )
}

export type HeartbeatSearchBarProps = Props
export default HeartbeatSearchBar
