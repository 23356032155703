import * as React from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import moment from 'moment'

import { BookingStep, ExistingPatientBookingForm, NewPatientBookingForm } from '../../../models/BookingAppointment'
import { ScheduleIcon } from '../../shared/svgIcons'

import AppointmentPicker from './AppointmentPicker'
import { DirectSchedulingContextConsumer } from './DirectSchedulingContext'
import SchedulingErrorMessage from './SchedulingErrorMessage'
import SchedulingErrorMessageWithShareAppointmentTimeButton from './SchedulingErrorMessageWithShareAppointmentTimeButton'
import { APP_ERROR_MESSAGE_TIMEOUT } from './shared'

import './NewPatientTab.sass'

type AppointmentPickerSectionProps = {
    form: NewPatientBookingForm | ExistingPatientBookingForm
    patientName: string
    directSchedulingRef: React.RefObject<HTMLDivElement>
    appointment?: Models.Appointment
    practice: Models.Practice
    isNewPatient?: boolean
}

type AppointmentPickerSectionDispatch = {
    handleSetAppointmentBookingStep: (bookingStep: BookingStep) => () => void
    shareAppointment: (datetime: moment.Moment) => () => void
    bookAppointment: () => void
    addPatient: () => void
}

type Props = AppointmentPickerSectionProps & AppointmentPickerSectionDispatch

const moduleName = 'scheduling-new-patient-form'

const AppointmentPickerSection = ({
    practice,
    form,
    appointment,
    patientName,
    directSchedulingRef,
    isNewPatient = false,
    handleSetAppointmentBookingStep,
    shareAppointment,
    bookAppointment,
    addPatient,
}: Props) => {
    const getLocation = () => {
        const {
            formElements: {
                location: { value: locationId },
            },
        } = form

        if (!practice || !practice.locations || !locationId) {
            return undefined
        }

        return practice.locations.find(locationItem => locationItem.id === locationId)
    }

    const getProcedure = () => {
        const location = getLocation()

        if (!practice?.locations || !location?.id) {
            return undefined
        }

        const locationIndex = practice.locations.findIndex(el => el.id === location.id)
        const locationProcedures = practice.locations[locationIndex]?.procedures

        if (!locationProcedures || !locationProcedures[form.formElements.procedure.value]) {
            return undefined
        }

        return locationProcedures[form.formElements.procedure.value]
    }

    const getIsBooked = () => {
        return [BookingStep.BOOKED, BookingStep.SHARED].includes(form.bookingStep)
    }

    const {
        isFormValid,
        bookingStep,
        isPending,
        errorMessage,
        appointmentPicker: { selectedDatetime },
    } = form

    const procedure = getProcedure()
    const location = getLocation()
    const isCheckScheduleButtonEnabled = isNewPatient
        ? form.formElements.location.isValid && form.formElements.procedure.isValid
        : isFormValid
    const shouldCheckSchedule =
        !isCheckScheduleButtonEnabled || bookingStep === BookingStep.NONE || !procedure || !location
    const practiceTimezone = location?.timezone || location?.schedulingDetails?.timezone // TODO: To investigate why we need two timezones.

    if (location && !practiceTimezone) {
        return <SchedulingErrorMessage type="error" message="Timezone for this location is not specified!" />
    }

    if (shouldCheckSchedule) {
        return (
            <div className={`${moduleName}__submit`}>
                <Button
                    disabled={!isCheckScheduleButtonEnabled}
                    className={`${moduleName}__submit-button`}
                    onClick={handleSetAppointmentBookingStep(BookingStep.PICKER)}
                >
                    Check Schedule
                </Button>
            </div>
        )
    }

    if (isPending) {
        return (
            <div className={`${moduleName}__progress`}>
                <CircularProgress size={100} color="primary" variant="indeterminate" />
            </div>
        )
    }

    if (bookingStep === BookingStep.ERROR) {
        if (errorMessage === APP_ERROR_MESSAGE_TIMEOUT) {
            const datetime = moment(appointment?.startDate).tz(practiceTimezone || 'America/New_York')
            return (
                <SchedulingErrorMessageWithShareAppointmentTimeButton
                    message={errorMessage as string}
                    shareAppointment={shareAppointment}
                    selectedDatetime={selectedDatetime}
                    datetime={datetime}
                />
            )
        }
        return (
            <SchedulingErrorMessage
                message={errorMessage as string}
                onButtonClick={handleSetAppointmentBookingStep(BookingStep.PICKER)}
            />
        )
    }

    if (getIsBooked() && appointment && practiceTimezone) {
        const datetime = moment(appointment.startDate).tz(practiceTimezone)

        return (
            <div className={`${moduleName}__appointment`}>
                <div className={`${moduleName}__booked`}>Appointment Booked!</div>
                <div className={`${moduleName}__calendar`}>
                    <ScheduleIcon fontSize="inherit" />
                </div>
                <div className={`${moduleName}__datetime`}>
                    {datetime.format('dddd')}
                    <br />
                    {datetime.format('MMMM D')}
                    <br />
                    {datetime.format('h:mm a')}
                </div>

                <div className={`${moduleName}__submit`}>
                    {bookingStep === BookingStep.SHARED ? (
                        <div className={`${moduleName}__shared`}>
                            <div className={`${moduleName}__shared-label`}>
                                <Icon>check_circle</Icon>
                                Appointment Shared
                            </div>
                            <Button className={`${moduleName}__submit-button`} onClick={addPatient}>
                                BOOK ADDITIONAL PATIENT
                            </Button>
                        </div>
                    ) : (
                        <Button
                            disabled={!selectedDatetime}
                            className={`${moduleName}__submit-button`}
                            onClick={shareAppointment(datetime)}
                        >
                            SHARE APPOINTMENT TIME IN CHAT
                        </Button>
                    )}
                </div>
            </div>
        )
    }

    return (
        <DirectSchedulingContextConsumer>
            {context =>
                context &&
                location &&
                procedure && (
                    <React.Fragment>
                        <AppointmentPicker
                            chat={context.chat}
                            tab={context.tab}
                            patientName={patientName}
                            {...form.appointmentPicker}
                            location={location}
                            procedure={procedure}
                            practice={practice}
                            providerId={form.formElements.provider.value}
                            onBookAppointment={bookAppointment}
                            isFormValid={isFormValid}
                            directSchedulingRef={directSchedulingRef}
                        />
                    </React.Fragment>
                )
            }
        </DirectSchedulingContextConsumer>
    )
}

export default AppointmentPickerSection
