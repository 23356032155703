import { useState } from 'react'
import moment from 'moment'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { useAppDispatch } from 'util/useAppDispatch'
import { loadEmailOrPhoneData, saveNewEmailOrPhone } from './actions'
import CustomMultiselectField from 'modules/shared/custom-fields/CustomMultiselectField'

import './AdminToolsShared.sass'

interface Props {
    onClose: () => void
    practiceId: string
}

const UpdatePhoneModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [screenIndex, setScreenIndex] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [currentEmail, setTheCurrentEmail] = useState('')
    const [currentPhone, setTheCurrentPhone] = useState('')
    const [isLoadingPhoneData, setIsLoadingPhoneData] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        practiceId: string
        portalUserId: string
        payerId: string
        firstName: string
        lastName: string
        email: string
        phone: string
    }>({
        practiceId: '',
        portalUserId: '',
        payerId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })
    const [newPhone, setTheNewPhone] = useState('')
    const [isSavingPhone, setIsSavingPhone] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const goBackScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadPhoneData = async () => {
        if (isLoadingPhoneData) {
            return
        }

        setIsLoadingPhoneData(true)
        try {
            const emailData = await dispatch(
                loadEmailOrPhoneData(
                    props.practiceId,
                    firstName,
                    lastName,
                    currentEmail || undefined,
                    currentPhone || undefined,
                ),
            )
            setLoadedData({
                practiceId: emailData[0].practiceId,
                portalUserId: emailData[0].portalUserId,
                payerId: emailData[0].payerId,
                firstName: emailData[0].firstName,
                lastName: emailData[0].lastName,
                email: currentEmail,
                phone: currentPhone,
            })
            goForwardScreen()
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsLoadingPhoneData(false)
    }

    const onSavePhone = async () => {
        if (isSavingPhone) {
            return
        }
        setIsSavingPhone(true)
        try {
            const phoneData = await dispatch(
                saveNewEmailOrPhone(loadedData.portalUserId, loadedData.payerId, undefined, newPhone),
            )
            if (phoneData === true) {
                goForwardScreen()
                setSuccessMessage(`Phone number successfully updated to ${newPhone}.`)
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsSavingPhone(false)
    }

    return (
        <DefaultModal
            title="Update Phone Number"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="First Name"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                />
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Last Name"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="admin-tools-row">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Current Email"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={currentEmail}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTheCurrentEmail(e.target.value)
                                    }
                                />
                                <span className="admin-tools-or-text">Or</span>
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Current Phone #"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={currentPhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTheCurrentPhone(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingPhoneData,
                        disabled: firstName === '' || lastName === '' || (currentEmail === '' && currentPhone === ''),
                        onClick: onLoadPhoneData,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Practice ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.practiceId}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">First Name:</div>
                                    <div className="admin-tools-details-value">{loadedData.firstName}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Last Name:</div>
                                    <div className="admin-tools-details-value">{loadedData.lastName}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Current Email:</div>
                                    <div className="admin-tools-details-value">{loadedData.email}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Current Phone:</div>
                                    <div className="admin-tools-details-value">{loadedData.phone}</div>
                                </div>
                            </div>
                            <div className="admin-tools-select-wrapper">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Change phone to:"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={newPhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTheNewPhone(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isSavingPhone,
                        disabled: newPhone === '' || newPhone.length < 10,
                        onClick: goForwardScreen,
                        text: 'Review Changes',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">Please confirm the following phone number update:</p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Current Phone:</div>
                                    <div className="admin-tools-details-value">{loadedData.phone}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">New Phone:</div>
                                    <div className="admin-tools-details-value">{newPhone}</div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isSavingPhone,
                        onClick: onSavePhone,
                        text: 'Submit',
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default UpdatePhoneModal
