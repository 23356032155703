declare var dataLayer: Array<{ event: string; account_id?: string }>

import { Middleware, Store } from 'redux'

import { RootState } from '../appReducer'

type ActionType = {
    type: String
}

const analytics: Middleware = (store: Store<RootState, ActionType>) => (next: (action: ActionType) => any) => {
    return (action: any) => {
        const state = store.getState()
        // dataLayer = dataLayer || []
        // dataLayer.push({
        //     event: 'pst-' + action.type,
        //     account_id: state.app.self && state.app.self.account.id,
        // })

        return next(action)
    }
}

export default analytics
