const imageProcess = (src: string) => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
    })
}

export const imageData = async (file: any): Promise<any> => {
    if (Boolean(file)) {
        try {
            const image = (await imageProcess(file.preview)) as Models.ImagePreview

            return {
                width: image.width,
                height: image.height,
                size: file.size,
                preview: file.preview,
            }
        } catch (err) {
            return
        }
    }

    return
}
