import { useState } from 'react'
import moment from 'moment'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { dateFormat } from 'modules/referrals/referral-form/ReferralForm'
import CustomDatepicker from 'modules/shared/custom-fields/CustomInlineDatepicker'
import { useAppDispatch } from 'util/useAppDispatch'
import { formatDobForApi } from 'modules/shared/dateUtils'
import {
    loadEmailOrPhoneData,
    loadPlanData,
    loadSwapTransactionData,
    savePlanChargeDate,
    saveNewEmailOrPhone,
    saveSwapTransaction,
} from './actions'
import CustomMultiselectField from 'modules/shared/custom-fields/CustomMultiselectField'

import './AdminToolsShared.sass'
import { getDateOrdinalOfDate, updateDayOfDate } from './utils'

interface Props {
    onClose: () => void
    practiceId: string
}

const emailRegex = /.+\@.+\..+/

const daysList = new Array(31).fill(0).map((_, index) => index + 1)

const ChangeChargeDateModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [screenIndex, setScreenIndex] = useState(0)
    // const [transactionId, setTransactionId] = useState('')
    const [paymentPlanId, setPaymentPlanId] = useState('')
    const [newPaymentDayOfMonth, setNewPaymentDayOfMonth] = useState(0)
    const [paymentDayStr, setPaymentDayStr] = useState('')
    const [isLoadingPlanData, setIsLoadingPlanData] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        practiceId: string
        // transactionId: string
        paymentPlanId: string
        currentPaymentDateOfMonth: string
        planStartDate: string
    }>({
        practiceId: '',
        // transactionId: '',
        paymentPlanId: '',
        currentPaymentDateOfMonth: '',
        planStartDate: '',
    })
    const [isSavingChargeDate, setIsSavingChargeDate] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const goBackScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadPlanData = async () => {
        if (isLoadingPlanData) {
            return
        }

        setIsLoadingPlanData(true)
        try {
            const planData = await dispatch(loadPlanData(paymentPlanId))
            setLoadedData({
                practiceId: planData.practiceId,
                // transactionId: planData.id,
                paymentPlanId: planData.paymentPlanId,
                currentPaymentDateOfMonth: getDateOrdinalOfDate(planData.startDate),
                planStartDate: planData.startDate,
            })
            goForwardScreen()
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsLoadingPlanData(false)
    }

    const onSaveChargeData = async () => {
        if (isSavingChargeDate) {
            return
        }
        setIsSavingChargeDate(true)
        try {
            const emailData = await dispatch(
                savePlanChargeDate(
                    loadedData.paymentPlanId,
                    updateDayOfDate(loadedData.planStartDate, newPaymentDayOfMonth),
                ),
            )
            if (emailData === true) {
                goForwardScreen()
                setSuccessMessage(
                    `Payment Plan ID ${loadedData.paymentPlanId} charge date successfully changed to the ${paymentDayStr} of the month.`,
                )
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsSavingChargeDate(false)
    }

    return (
        <DefaultModal
            title="Change Charge Date for Payment Plan"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            {/* <div className="admin-tools-row">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={transactionIdLabel}
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={transactionId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </div> */}
                            <div className="admin-tools-row admin-tools-row--narrow">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Payment Plan Id"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={paymentPlanId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPaymentPlanId(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingPlanData,
                        disabled: paymentPlanId === '', // transactionId === '' ||
                        onClick: onLoadPlanData,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <div className="admin-tools-details">
                                {/* <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Transaction ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.transactionId}</div>
                                </div> */}
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Payment Plan Id:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {loadedData.paymentPlanId}
                                    </div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Current Payment Day of Month:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {loadedData.currentPaymentDateOfMonth}
                                    </div>
                                </div>
                            </div>
                            <div className="admin-tools-select-wrapper">
                                <CustomField
                                    customFieldType={CustomFieldType.SELECT}
                                    autoFocus={false}
                                    label="Change Payment Date to:"
                                    // inputProps={{ step: '.01' }}
                                    // inputType="text"
                                    value={newPaymentDayOfMonth}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const newDay = parseInt(e.target.value)
                                        setNewPaymentDayOfMonth(newDay)
                                        setPaymentDayStr(
                                            getDateOrdinalOfDate(updateDayOfDate(loadedData.planStartDate, newDay)),
                                        )
                                    }}
                                >
                                    <option value="">Select Day</option>
                                    {daysList.map((day, index) => (
                                        <option key={day} value={day}>
                                            {day}
                                        </option>
                                    ))}
                                </CustomField>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isSavingChargeDate,
                        disabled: newPaymentDayOfMonth <= 0,
                        onClick: goForwardScreen,
                        text: 'Review Changes',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <div className="admin-tools-details">
                                {/* <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Transaction ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.transactionId}</div>
                                </div> */}
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Payment Plan Id:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {loadedData.paymentPlanId}
                                    </div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Current Payment Day of Month:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {loadedData.currentPaymentDateOfMonth}
                                    </div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Change Payment Day to:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {paymentDayStr}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isSavingChargeDate,
                        onClick: onSaveChargeData,
                        text: 'Submit',
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default ChangeChargeDateModal
