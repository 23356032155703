import * as React from 'react'
import Button from '@mui/material/Button'
import { oneLine } from 'common-tags'

import './ButtonGroupSelector.sass'

type ButtonGroupSelectorDispatch = {
    onSelect: (values: string) => void
}

type ButtonGroupSelectorProps = {
    items: object[]
    value: string
    keyProperty: string
    valueProperty: string
    required?: boolean
    label?: string
    helperText?: string
    error?: boolean
    errorMessage?: string
    disabled?: boolean
    className?: string
}

type Props = ButtonGroupSelectorProps & ButtonGroupSelectorDispatch

export default class ButtonGroupSelector extends React.PureComponent<Props> {
    handleSelectItem = (value: string) => () => {
        this.props.onSelect(value)
    }

    renderButtons = () => {
        const { disabled, items, keyProperty, valueProperty, value } = this.props

        return (
            <>
                {items.map(item => {
                    return (
                        <Button
                            key={item[keyProperty]}
                            disabled={disabled}
                            className={oneLine`
                                buttongroup-selector__button
                                ${disabled ? 'buttongroup-selector__button--disabled' : ''}
                                ${item[keyProperty] === value ? 'buttongroup-selector__button--active' : ''}
                            `}
                            onClick={this.handleSelectItem(item[keyProperty])}
                        >
                            {item[valueProperty]}
                        </Button>
                    )
                })}
            </>
        )
    }

    render() {
        const { label, helperText, error, errorMessage, disabled, required, className } = this.props

        return (
            <div
                className={oneLine`
                    buttongroup-selector
                    ${error ? 'buttongroup-selector--error' : ''}
                    ${disabled ? 'buttongroup-selector--disabled' : ''}
                    ${required ? 'buttongroup-selector--required' : ''}
                    ${className ? className : ''}
                `}
            >
                <div className="buttongroup-selector__container">
                    {Boolean(label) && <label className="buttongroup-selector__label">{label}</label>}
                    <div className="buttongroup-selector__list">{this.renderButtons()}</div>
                    {Boolean(error) ? (
                        <span className="buttongroup-selector__helper-text buttongroup-selector__helper-text--error">
                            {errorMessage}
                        </span>
                    ) : (
                        helperText && <span className="buttongroup-selector__helper-text">{helperText}</span>
                    )}
                </div>
            </div>
        )
    }
}
