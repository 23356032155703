export const ReferralPatientFactory: ModelsV2.Referrals.ReferralPatient = {
    id: '',
    created: '',
    updated: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    desired_appointment_time: '',
    email: '',
    inbound_source: '',
    phone_number: '',
    reason: '',
    existing_patient: false,
    is_primary: false,
    notes: '',
    patient_is_policy_holder: true,
    insurance_provider: '',
    insurance_phone: '',
    insurance_group: '',
    insurance_member_id_ssn: '',
    policy_holder_first_name: '',
    policy_holder_last_name: '',
    policy_holder_date_of_birth: '',
    employer_name: '',
    scheduling_appointment_id: '',
    has_appointments_in_last_7_days: false,
}

export const ReferralFactory: ModelsV2.Referrals.ReferralV2 = {
    id: '',
    location_id: '',
    inbound_handler_id: '',
    date: '',
    time: '',
    is_test: false,
    created: '',
    updated: '',
    practice_id: '',
    status_id: 0,
    referral_creator_id: '',
    legacy_message_id: '',
    legacy_file_id: '',
    transcript: '',
    archived: false,
    status_notes: '',
    practice_staff_read: false,
    direct_scheduled: false,
    tags: [],
    payment_data: {
        payer_id: '',
        patient_id: '',
        card_is_verified: false,
        card_is_charged: false,
    },
    amplify_referral_patient: [ReferralPatientFactory],
    flag: '',
    amplify_status: {
        id: 0,
        value: 'unclaimed',
        display: '',
        display_long: '',
    },
    amplify_web_code: {
        id: '',
        url: '',
        amplify_script: '',
        active: false,
        survey: {
            id: '',
            status: '',
        },
    },
}
