import React from 'react'
import MaskedInput from 'react-input-mask'
import Icon from '@mui/material/Icon'
import moment from 'moment'

import CustomInlineDatepicker from './CustomInlineDatepicker'

import './MaskedDatepicker.sass'

const moduleName = 'masked-datepicker'

type Props = {
    dateMask: string
    label?: string
    dateFormat: string
    value: string
    disabled?: boolean
    error?: boolean
    errorMessage?: string
    onBlur?: () => void
    onInputChange: (value: string) => void
    onChange: (value: string) => void
    validator?: (value: string) => boolean
}

const MaskedInlineDatepicker = ({
    dateMask,
    dateFormat,
    value,
    disabled,
    onBlur = () => {},
    onInputChange,
    onChange,
    validator,
    label = '',
    error = false,
    errorMessage = 'Invalid date value',
}: Props) => {
    const isValid = (value: string) => {
        if (validator !== undefined) {
            return validator(value)
        }
        return true
    }

    return (
        <div className={moduleName}>
            <MaskedInput
                mask={dateMask}
                value={value}
                disabled={Boolean(disabled)}
                onBlur={onBlur}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value: targetValue } = event.target as HTMLInputElement
                    onInputChange(targetValue)
                }}
            >
                {() => (
                    <input
                        value={value}
                        disabled={Boolean(disabled)}
                        placeholder={dateFormat}
                        className={`${moduleName}__masked-input ${error && `${moduleName}__masked-input--error`}`}
                    />
                )}
            </MaskedInput>
            <Icon
                className={`${moduleName}__calendar-icon ${disabled ? `${moduleName}__calendar-icon--disabled` : ''}`}
            >
                event
            </Icon>
            <CustomInlineDatepicker
                value={value && isValid(value) ? moment(value, dateFormat).toDate() : null}
                label={label}
                invalidLabel={dateFormat}
                placeholder={dateFormat}
                dateFormat={dateFormat}
                error={error}
                disableFuture={true}
                maxDateMessage="Future dates not supported"
                onChange={(value: moment.Moment) => {
                    onChange(value?.format(dateFormat))
                }}
                autoOk={true}
            />
            {error === true && <div className={`${moduleName}__error`}>{errorMessage}</div>}
        </div>
    )
}

export default MaskedInlineDatepicker
