import { useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import './LocationConfirmationBox.sass'

export type LocationConfirmationBoxType = 'deleteFacilityId'

export type StaffConfrimationBoxProps = {
    type?: LocationConfirmationBoxType
    title?: string
}

export type LocationConfirmationBoxDispatch = {
    confirmFn?: () => void
    cancelFn?: () => void
    closeFn: () => void
}

export interface LocationConfirmationBoxParams {
    type: LocationConfirmationBoxType
    callback: () => void
    title?: string
}

type Props = StaffConfrimationBoxProps & LocationConfirmationBoxDispatch

const LocationConfirmationBox = ({ title, type, confirmFn, cancelFn, closeFn }: Props) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const buildText = () => {
        switch (type) {
            case 'deleteFacilityId':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                            style={{ paddingTop: '4px' }}
                        >
                            <Grid item={true}>
                                <i className="material-icons red">info</i>
                            </Grid>
                            <Grid item={true} className="text" style={{ paddingTop: '12px' }}>
                                Are you sure you want to delete this Facility ID?
                            </Grid>
                        </Grid>
                    </>
                )

            default:
                return null
        }
    }

    const buildConfirmBtn = () => {
        const onConfirm = async () => {
            setIsButtonDisabled(true)
            confirmFn && (await confirmFn())
            setIsButtonDisabled(false)
            closeFn()
        }
        switch (type) {
            case 'deleteFacilityId':
                return (
                    <Button
                        disabled={isButtonDisabled}
                        color="primary"
                        variant="contained"
                        onClick={onConfirm}
                        className="confirm-btn red"
                        style={{ textTransform: 'none' }}
                    >
                        Confirm
                    </Button>
                )

            default:
                return null
        }
    }

    return (
        <div className="location-confirmation-container">
            <div className="confirmation-inner-container">
                {buildText()}
                <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                    <Grid item={true}>
                        <Button
                            variant="outlined"
                            style={{ textTransform: 'none' }}
                            className="cancel-button"
                            onClick={cancelFn}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true}>{buildConfirmBtn()}</Grid>
                    {isButtonDisabled && (
                        <div className="circular-progress-loader">
                            <CircularProgress size={48} />
                        </div>
                    )}
                </Grid>
            </div>
        </div>
    )
}

export default LocationConfirmationBox
