import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import classNames from 'classnames'

import { listItemHoverBackground } from '../../shared/styles/colors'

const moduleName = 'chatter-alert-manager'

const DragDropChatterAlert = (props: {
    localAlerts: Models.ChatAlert[]
    editedChatAlert?: Models.ChatAlert
    children: any
}) => {
    const { localAlerts, editedChatAlert } = props

    function getItemStyle(isDragging: boolean, draggableStyle: any) {
        return {
            backgroundColor: isDragging ? listItemHoverBackground : 'transparent',
            boxShadow: isDragging && '0 0 15px 0 rgba(0, 0, 0, 0.5)',
            ...draggableStyle,
        }
    }

    return (
        <Droppable droppableId="droppable">
            {providedDrop => (
                <div
                    {...providedDrop.droppableProps}
                    className={`${moduleName}__table-body`}
                    ref={providedDrop.innerRef}
                >
                    {localAlerts.map((chatAlert, index) => (
                        <Draggable key={chatAlert.id} draggableId={chatAlert.id} index={index}>
                            {(providedDrag, snapshot) => (
                                <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    style={getItemStyle(snapshot.isDragging, providedDrag.draggableProps.style)}
                                    className={classNames(`${moduleName}__table-row`, {
                                        [`${moduleName}__row--edited`]:
                                            editedChatAlert && chatAlert.id === editedChatAlert.id,
                                    })}
                                >
                                    {chatAlert && props.children(chatAlert, providedDrag)}
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {providedDrop.placeholder}
                </div>
            )}
        </Droppable>
    )
}

export default DragDropChatterAlert
