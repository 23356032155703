import moment from 'moment'

const API_DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT = 'MM/DD/YYYY'

export const formatDateOfBirth = (dob: string) => {
    const isApiFormatType = dob.includes('-')

    if (isApiFormatType) {
        return moment(dob, API_DATE_FORMAT).format(DATE_FORMAT)
    }

    return dob
}

export const formatDobForApi = (dob: string) => {
    const isApiFormatType = dob.includes('/')

    if (isApiFormatType) {
        return moment(dob, DATE_FORMAT).format(API_DATE_FORMAT)
    }

    return dob
}
