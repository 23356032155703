import { connect } from 'react-redux'

import { RootState } from '../../../appReducer'
import { bindPracticeAgent, fetchPracticeAgents, savePractice } from '../actions'

import FlowTab, { FlowTabProps } from './FlowTab'

const mapStateToProps = (state: RootState, props: FlowTabProps) => ({})

const FlowTabContainer = connect(mapStateToProps, {
    fetchPracticeAgents,
    bindPracticeAgent,
    savePractice,
})(FlowTab as any)

export default FlowTabContainer
