import React, { useState } from 'react'

import SessionManager from '../../SessionManager'
import { useInterval } from '../shared/custom-hooks'

import './NavSessionTimer.sass'

const NavSessionTimer = () => {
    const [timeLeft, setTimeLeft] = useState<string | null>(null)

    useInterval(() => {
        const countDown = SessionManager.logoutCountDownInMinSec
        if (!countDown) {
            return
        }

        setTimeLeft(countDown)
    }, 1000)

    return (
        <div>
            {timeLeft && (
                <div className="nav-session-timer">
                    <div className="nav-session-timer__container">
                        <i className="material-icons">query_builder</i>
                        <div className="nav-session-timer__msg">{timeLeft} before logout</div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default NavSessionTimer
