import { HeartbeatProviderPropertyValidationRules } from 'models/v2/Heartbeat'

const NUMBER_REGEXP = /^\d+$/

export const isValid = (validationList: HeartbeatProviderPropertyValidationRules[], value: string) => {
    if (validationList.includes('IsNotEmpty') && value.trim() === '') {
        return 'Please enter value'
    }

    if (validationList.includes('IsNumber') && !Boolean(value.match(NUMBER_REGEXP))) {
        return 'Value must a number'
    }

    if (validationList.includes('IsString') && Boolean(value.match(NUMBER_REGEXP))) {
        return 'Value must a string'
    }

    if (validationList.includes('MaxLength_9') && value.length > 9) {
        return 'You can enter max 9 digits'
    }

    if (validationList.includes('MaxLength_50') && value.length > 50) {
        return 'You can enter max 50 digits'
    }

    return ''
}
