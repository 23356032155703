import React, { useCallback, useEffect, useRef, useState } from 'react'
import Icon from '@material-ui/core/Icon'

import Portal from '../custom-portal/Portal'

import './CustomTooltipWithPortal.sass'

type Position = 'top' | 'bottom' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left'
interface TooltipProps {
    text: React.ReactNode
    type?: 'click'
    timeout?: number
    children?: React.ReactNode
    overlay?: boolean
    parentRect?: any
    scrollableWindow: React.RefObject<any>
    topOffset?: number
    leftOffset?: number
    style?: object
    position?: Position
    forceClose?: boolean
    onTooltipClick?: () => void
}

export const moduleName = 'custom-tooltip-with-portal'

const CustomTooltip = (props: TooltipProps) => {
    const {
        text,
        type = 'click',
        children,
        overlay = true,
        parentRect,
        scrollableWindow,
        leftOffset = 0,
        topOffset = 0,
        forceClose = false,
        onTooltipClick,
    } = props

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [parentX, setParentX] = useState<number>(0)
    const [parentY, setParentY] = useState<number>(0)
    const [position, setPosition] = useState<Position>(props.position || 'top')
    const [coords, setCoords] = useState({ left: 0, top: 0 })
    const textLength = text?.toString().length || 0

    const popoverRef = useRef<HTMLDivElement>()
    const childrenRef = useRef<HTMLDivElement>()

    const checkIfClickedInside = (event: MouseEvent, element: HTMLDivElement) => {
        return element?.contains(event.target as HTMLElement) || element === event.target
    }

    useEffect(() => {
        if (forceClose && isOpen) {
            setIsOpen(false)
        }
    }, [forceClose, isOpen])

    useEffect(() => {
        if (!overlay && parentRect) {
            setParentX(parentRect.left)
            setParentY(parentRect.top)
        }
    }, [overlay, parentRect])

    const closeOnClickOutOfPopover = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            const isInsidePopover = checkIfClickedInside(event, popoverRef.current as HTMLDivElement)
            const isInsideChildrenElements = checkIfClickedInside(event, childrenRef.current as HTMLDivElement)
            if (!isInsidePopover && !isInsideChildrenElements) {
                setIsOpen(false)
            }
        },
        [setIsOpen],
    )

    const toggleTooltip = (open: boolean) => (e: any) => {
        if (!overlay) {
            const positionX = parentX + 100
            const positionY = parentY + textLength * 2

            if (e.clientX >= positionX && e.clientY <= positionY) {
                setPosition('bottom-left')
            }
            if (e.clientX <= positionX && e.clientY <= positionY) {
                setPosition('bottom-right')
            }
            if (e.clientX <= positionX && e.clientY >= positionY) {
                setPosition('top-right')
            }
            if (e.clientX >= positionX && e.clientY >= positionY) {
                setPosition('top-left')
            }
        }
        const rect = e.target.getBoundingClientRect()

        setCoords({
            left: rect.x - parentRect.x - leftOffset + rect.width / 2,
            top: rect.y - topOffset + rect.height + scrollableWindow.current.scrollTop,
        })
        setIsOpen(open)

        if (onTooltipClick) {
            onTooltipClick()
        }
    }

    useEffect(() => {
        if (type === 'click') {
            if (isOpen) {
                document.addEventListener('click', closeOnClickOutOfPopover, true)
                return
            }
            document.removeEventListener('click', closeOnClickOutOfPopover, true)
        }
    }, [type, isOpen, closeOnClickOutOfPopover])

    const renderChildren = () => {
        return (
            <div
                className={`${moduleName}__children`}
                ref={childrenRef as React.RefObject<any>}
                onClick={toggleTooltip(true)}
            >
                {children}
            </div>
        )
    }

    const style = props.style || {}

    return (
        <div className={`${moduleName}`}>
            {isOpen && (
                <Portal>
                    <div
                        className={`${moduleName}__popover ${moduleName}__popover--${position}`}
                        style={{ ...style, top: `${coords.top}px`, left: `${coords.left}px` }}
                        ref={popoverRef as React.RefObject<any>}
                    >
                        {type === 'click' && (
                            <Icon onClick={toggleTooltip(false)} className={`${moduleName}__popover-close-icon`}>
                                close
                            </Icon>
                        )}
                        {text}
                    </div>
                </Portal>
            )}
            {renderChildren()}
        </div>
    )
}

export default CustomTooltip
