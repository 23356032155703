import iassign from 'immutable-assign'

import {
    ConnectedPracticeLocation,
    PracticeLocationAvailabilities,
    PracticeLocationSchedulingOperatory,
    PracticeLocationSchedulingProcedure,
    PracticeLocationSchedulingProvider,
} from '../../../models/v2/Practice'

import {
    AvailabilityActionTypes,
    ReceiveConnectedPracticeLocations,
    ReceivePracticeLocationSchedulingAvailabilities,
    ReceivePracticeLocationSchedulingOperatories,
    ReceivePracticeLocationSchedulingProcedures,
    ReceivePracticeLocationSchedulingProviders,
    SetSelectedLocation,
    SetSelectedOperatories,
    SetSelectedProcedure,
    SetSelectedProviders,
} from './v2actions'

export type AvailabilityState = {
    connectedPracticeLocations: { [key: string]: ConnectedPracticeLocation[] }
    practiceLocationSchedulingProcedures: { [key: string]: PracticeLocationSchedulingProcedure[] }
    practiceLocationSchedulingOperatories: { [key: string]: PracticeLocationSchedulingOperatory[] }
    practiceLocationSchedulingProviders: { [key: string]: PracticeLocationSchedulingProvider[] }
    selectedLocationId: { [key: string]: string }
    selectedProcedureId: { [key: string]: string }
    selectedOperatoryIds: { [key: string]: string[] }
    selectedProviderIds: { [key: string]: string[] }
    availabilities: { [key: string]: PracticeLocationAvailabilities }
}

const initialState = (): AvailabilityState => ({
    connectedPracticeLocations: {},
    practiceLocationSchedulingProcedures: {},
    practiceLocationSchedulingOperatories: {},
    practiceLocationSchedulingProviders: {},
    selectedLocationId: {},
    selectedProcedureId: {},
    selectedOperatoryIds: {},
    selectedProviderIds: {},
    availabilities: {},
})

type AvailabilityAction =
    | ReceiveConnectedPracticeLocations
    | ReceivePracticeLocationSchedulingAvailabilities
    | ReceivePracticeLocationSchedulingProcedures
    | ReceivePracticeLocationSchedulingOperatories
    | ReceivePracticeLocationSchedulingProviders
    | SetSelectedLocation
    | SetSelectedProcedure
    | SetSelectedOperatories
    | SetSelectedProviders

export function reducer(state: AvailabilityState = initialState(), action: AvailabilityAction): AvailabilityState {
    switch (action.type) {
        case AvailabilityActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.connectedPracticeLocations[action.practiceId],
                nextConnectedLocations => {
                    if (!nextConnectedLocations) {
                        nextConnectedLocations = [...action.connectedLocations]
                        return nextConnectedLocations.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        })
                    }
                    nextConnectedLocations = action.connectedLocations.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        })
                    })
                    return nextConnectedLocations
                },
            )
        }

        case AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_PROCEDURES: {
            return iassign(
                state,
                next => next.practiceLocationSchedulingProcedures[action.locationId],
                practiceLocationSchedulingProcedures => {
                    practiceLocationSchedulingProcedures = action.schedulingProcedures.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
                    })
                    return practiceLocationSchedulingProcedures
                },
            )
        }

        case AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_OPERATORIES: {
            return iassign(
                state,
                next => next.practiceLocationSchedulingOperatories[action.locationId],
                nextPracticeLocationSchedulingOperatories => {
                    nextPracticeLocationSchedulingOperatories = action.schedulingOperatories.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
                    })
                    return nextPracticeLocationSchedulingOperatories
                },
            )
        }

        case AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_SCHEDULING_PROVIDERS: {
            return iassign(
                state,
                next => next.practiceLocationSchedulingProviders[action.locationId],
                nextPracticeLocationSchedulingProviders => {
                    nextPracticeLocationSchedulingProviders = action.schedulingProviders
                        .map(p => {
                            return {
                                ...p,
                                full_name: `${p.first_name} ${p.last_name}`,
                            }
                        })
                        .sort((a, b) => {
                            return a.full_name.localeCompare(b.full_name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        })
                    return nextPracticeLocationSchedulingProviders
                },
            )
        }

        case AvailabilityActionTypes.SET_SELECTED_LOCATION: {
            return iassign(
                state,
                next => next.selectedLocationId[action.practiceId],
                () => action.locationId,
            )
        }
        case AvailabilityActionTypes.SET_SELECTED_PROCEDURE: {
            return iassign(
                state,
                next => next.selectedProcedureId[action.practiceId],
                () => action.procedureId,
            )
        }

        case AvailabilityActionTypes.SET_SELECTED_OPERATORIES: {
            return iassign(
                state,
                next => next.selectedOperatoryIds[action.practiceId],
                () => action.operatoryIds,
            )
        }

        case AvailabilityActionTypes.SET_SELECTED_PROVIDERS: {
            return iassign(
                state,
                next => next.selectedProviderIds[action.practiceId],
                () => action.providerIds,
            )
        }

        case AvailabilityActionTypes.RECEIVE_PRACTICE_LOCATION_AVAILABILITIES: {
            return iassign(
                state,
                next => next.availabilities[action.locationId],
                () => action.availabilities,
            )
        }

        default:
            return state
    }
}
