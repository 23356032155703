import React, { useState } from 'react'
import Checkbox, { CheckboxClassKey } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelClassKey } from '@mui/material/FormControlLabel'
import Switch, { SwitchClassKey } from '@mui/material/Switch'

import CustomTooltip from '../../../shared/custom-tooltip/CustomTooltip'

import './SelfSchedulingWebCodeLocation.sass'

const moduleName = 'self-scheduling-web-code-location'

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

type CheckboxClassOverrides = {
    readonly [key in CheckboxClassKey]?: string
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    root: `${moduleName}__form-control-root`,
    label: `${moduleName}__form-control-label`,
}

const checkboxClassOverrides: CheckboxClassOverrides = {
    root: `${moduleName}__checkbox-root`,
}

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch`,
    checked: `${moduleName}__switch--checked`,
}

const RSVP_ALL_INFO = `Locations must have Campaigns enabled on a paired agent and only enabled on one web code at a time.`

export type Props = {
    allLocationsSelected: boolean
    allRSVPSSelected: boolean
    showRSVPAll: boolean
    isRSVPAlldisabled: boolean
    selectAllLocations: (checked: boolean) => void
    selectAllRSVPs: (checked: boolean) => void
}

const SelfSchedulingWebCodeSelectAllLocations = ({
    allLocationsSelected,
    allRSVPSSelected,
    showRSVPAll,
    isRSVPAlldisabled,
    selectAllLocations,
    selectAllRSVPs,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleOnChange = async () => {
        if (!allLocationsSelected) {
            setLoading(true)
            await selectAllLocations(true)
            setLoading(false)
        } else {
            await selectAllLocations(false)
            if (showRSVPAll) {
                await selectAllRSVPs(false)
            }
        }
    }

    const handleOnChangeRSVP = async () => {
        setLoading(true)
        await selectAllRSVPs(!allRSVPSSelected)
        setLoading(false)
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__location-section`}>
                <FormControlLabel
                    classes={formControlLabelClassOverrides}
                    control={
                        <Checkbox
                            disabled={loading}
                            classes={checkboxClassOverrides}
                            checked={allLocationsSelected}
                            onChange={handleOnChange}
                            color="primary"
                        />
                    }
                    label={''}
                />

                <label className={`${moduleName}__label ${moduleName}__label--checkbox`}>
                    <div className={`${moduleName}__text ${moduleName}--text-bold`}>ALL PRACTICE LOCATIONS</div>
                </label>
            </div>
            {showRSVPAll && (
                <div className={`${moduleName}__rsvp-all-section`}>
                    <CustomTooltip position="top-left" text={RSVP_ALL_INFO}>
                        <i className={`${moduleName}__rsvp-info-icon material-icons`}>info</i>
                    </CustomTooltip>
                    <FormControlLabel
                        classes={formControlLabelClassOverrides}
                        control={
                            <Switch
                                disabled={loading || isRSVPAlldisabled}
                                classes={switchClassOverrides}
                                checked={Boolean(allRSVPSSelected)}
                                color="primary"
                                onChange={handleOnChangeRSVP}
                            />
                        }
                        label={''}
                    />
                    <label className={`${moduleName}__label`}>
                        <div className={`${moduleName}__text ${moduleName}__rsvp-all-text ${moduleName}--text-bold`}>
                            CAMPAIGNS ALL
                        </div>
                    </label>
                </div>
            )}
        </div>
    )
}

export default SelfSchedulingWebCodeSelectAllLocations
