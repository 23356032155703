import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import { DatePicker } from '@material-ui/pickers'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { createTheme } from '@mui/material/styles'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import classNames from 'classnames'
import _, { debounce } from 'lodash'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import { CustomFieldType } from '../../../models/enums'
import {
    createChatAlert,
    deleteChatAlert,
    fetchChatAlertList,
    fetchWebCodes,
    saveChatAlert,
    saveChatAlertList,
} from '../../../modules/amplify/actions'
import ConfirmModal from '../../../modules/shared/confirm-modal/ConfirmModal'
import CopyText from '../../../modules/shared/CopyText'
import CustomField from '../../../modules/shared/custom-fields/CustomField'
import CustomMultiselectField from '../../../modules/shared/custom-fields/CustomMultiselectField'
import { textBlue } from '../../../modules/shared/styles/colors'
import { useAppDispatch } from '../../../util/useAppDispatch'
import InfoMessage from '../../shared/info-message/info-message'

import ChatterAlertExpiryDateNotification from './ChatterAlertExpiryDateNotification'
import ChatterAlertUpdateNotification from './ChatterAlertUpdateNotification'
import DragDropChatterAlert from './DragDropChatterAlert'

import './ChatterAlertManager.sass'

const timezone = 'America/New_York'

export type Props = {
    practice: Models.Practice
}

export const ChatterAlertsTheme = createTheme({
    palette: {
        primary: {
            main: textBlue,
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: textBlue,
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    opacity: 0,
                    position: 'absolute',
                    marginTop: '3px',
                    marginLeft: '3px',
                    width: '20px',
                    '&:hover': {
                        opacity: 0.25,
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    color: 'white',
                    cursor: 'pointer',
                    padding: 0,
                },
                underline: {
                    '&:before': {
                        display: 'none',
                    },
                    '&:after': {
                        display: 'none',
                    },
                },
            },
        },
    },
})

const moduleName = 'chatter-alert-manager'

const ChatterAlertManager = (props: Props) => {
    const { practice } = props

    const practiceId = practice?.id

    const alerts = useSelector((state: RootState) => state.amplify.alerts[practiceId])
    const webCodes = useSelector((state: RootState) => state.amplify.webCodes[props.practice.id])
    const websites = _.sortBy(webCodes, webcode => webcode.url)?.map(({ id, url }) => ({ id, url })) ?? []

    const [firstLoadDone, setFirstLoadDone] = useState(false)
    const [dateExpires, setDateExpires] = useState<moment.Moment | undefined>()
    const [showUpdateExpiryNotification, setShowUpdateExpiryNotification] = useState(false)
    const [showUpdateNotification, setShowUpdateNotification] = useState(false)
    const [localAlerts, setLocalAlerts] = useState<Models.ChatAlert[] | undefined>(undefined)
    const [collapsedChatAlerts, setCollapsedChatAlerts] = useState(false)
    const [alertWebsiteIds, setAlertWebsiteIds] = useState<{ [alertId: string]: string[] }>({})
    const [showDeleteChatAlertModal, setShowDeleteChatAlertModal] = useState(false)
    const [newChatAlert, setNewChatAlert] = useState<Api.Amplify.ChatAlertCreate | undefined>(undefined)
    const [editedChatAlert, setEditedChatAlert] = useState<Models.ChatAlert | undefined>(undefined)
    const [chatAlertToDelete, setChatAlertToDelete] = useState<Models.ChatAlert>()
    const canSaveNewChatAlert = newChatAlert && newChatAlert.alert.length > 0

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!firstLoadDone) {
            dispatch(fetchChatAlertList(practiceId))
            dispatch(fetchWebCodes(practiceId))
            setFirstLoadDone(true)
        }
    }, [alerts, dispatch, firstLoadDone, practiceId])

    useEffect(() => {
        if (alerts && alerts.length >= 0) {
            setLocalAlerts(alerts)
            const alertWebsiteIdsData =
                alerts?.reduce((agg, alert) => ({ ...agg, [alert.id]: alert.websiteIds }), {}) ?? {}
            setAlertWebsiteIds(alertWebsiteIdsData)
        }
    }, [alerts])

    const dispatchSaveChatAlert = useCallback(
        (practice: Models.Practice, chatAlert: Models.ChatAlert, updates: string[]) => {
            dispatch(saveChatAlert(practice, chatAlert, { website_ids: updates }))
        },
        [dispatch],
    )

    const onSaveWebsitesDebounce = useMemo(() => debounce(dispatchSaveChatAlert, 500, { trailing: true }), [
        dispatchSaveChatAlert,
    ])

    const reorder = (alerts: Models.ChatAlert[], startIndex: number, endIndex: number) => {
        const result = Array.from(alerts)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    const onBeforeDragStart = () => {
        collapseChatAlerts()
    }

    const onDragEnd = (result: any) => {
        if (!result.destination || !localAlerts) {
            return
        }

        const reorderedAlerts = reorder(localAlerts, result.source.index, result.destination.index)
        setLocalAlerts(reorderedAlerts)
        setCollapsedChatAlerts(false)

        const alerts = reorderedAlerts.map((alert, index) => ({
            id: alert.id,
            alert: alert.alert,
            expiration_date: alert.expirationDate
                ? moment(alert.expirationDate)
                      .tz(timezone)
                      .toDate()
                : undefined,
            status: alert.status.value,
            order_number: index,
            website_ids: alertWebsiteIds[alert.id] ?? [],
        }))

        dispatch(saveChatAlertList(practice, alerts))
    }

    const onAddNewChatAlert = () => {
        setNewChatAlert({
            alert: '',
        })
    }

    const onCancelNewChatAlert = () => {
        setNewChatAlert(undefined)
    }

    const onCancelEditedChatAlert = () => {
        setEditedChatAlert(undefined)
    }

    const onChangeNewChatAlert = (alert: string) => {
        setNewChatAlert({
            alert: alert,
        })
    }

    const onChangeEditedChatAlert = (alert: string) => {
        if (editedChatAlert) {
            setEditedChatAlert({
                ...editedChatAlert,
                alert: alert,
            })
        }
    }

    const onSaveNewChatAlert = () => {
        if (newChatAlert == null) {
            return
        }

        dispatch(createChatAlert(practice, newChatAlert)).then((alert: Models.ChatAlert) => {
            const alertWebsiteIdsClone = _.cloneDeep(alertWebsiteIds)
            alertWebsiteIdsClone[alert.id] = webCodes?.map(({ id }) => id) ?? []
            setAlertWebsiteIds(alertWebsiteIdsClone)
            onCancelNewChatAlert()
        })
    }

    const onSelectWebsite = (alert: Models.ChatAlert, values: string[]) => {
        const alertWebsiteIdsClone = _.cloneDeep(alertWebsiteIds)
        alertWebsiteIdsClone[alert.id] = values
        setAlertWebsiteIds(alertWebsiteIdsClone)
        onSaveWebsitesDebounce(practice, alert, values)
    }

    const onSaveEditedAlert = () => {
        if (editedChatAlert) {
            const updates: Api.Amplify.ChatAlertUpdate = {
                alert: editedChatAlert.alert,
            }

            dispatch(saveChatAlert(practice, editedChatAlert, updates))
                .then((res: any) => {
                    onCancelEditedChatAlert()
                    setShowUpdateNotification(true)
                    setTimeout(() => {
                        setShowUpdateNotification(false)
                    }, 1000)
                })
                .catch((err: Error) => {
                    setShowUpdateNotification(false)
                })
        }
    }

    const onChangeExpiryDate = (chatAlert: Models.ChatAlert, expirationDate: moment.Moment | null) => {
        const updates: Api.Amplify.ChatAlertUpdate = {
            expiration_date: expirationDate ? expirationDate.tz(timezone).toDate() : null,
            status: 'pending_expiration',
        }

        dispatch(saveChatAlert(practice, chatAlert, updates))
            .then((res: any) => {
                if (res.statusCode !== 200) {
                    setDateExpires(expirationDate ? expirationDate.tz(timezone) : undefined)
                    setShowUpdateExpiryNotification(true)
                    setTimeout(() => {
                        setShowUpdateExpiryNotification(false)
                    }, 1000)
                }
            })
            .catch((err: Error) => {
                setDateExpires(undefined)
            })
    }

    const onDeleteChatAlert = () => {
        if (chatAlertToDelete) {
            dispatch(deleteChatAlert(practice, chatAlertToDelete))
        }
        setShowDeleteChatAlertModal(false)
        setChatAlertToDelete(undefined)
    }

    const openDeleteChatAlertModal = (chatAlert: Models.ChatAlert) => {
        setShowDeleteChatAlertModal(true)
        setChatAlertToDelete(chatAlert)
    }

    const closeDeleteChatAlertModal = () => {
        setShowDeleteChatAlertModal(false)
        setChatAlertToDelete(undefined)
    }

    const openEditChatAlert = (chatAlert: Models.ChatAlert) => {
        setEditedChatAlert(chatAlert)
    }

    const expandChatAlerts = () => {
        setCollapsedChatAlerts(false)
    }

    const collapseChatAlerts = () => {
        setEditedChatAlert(undefined)
        setCollapsedChatAlerts(true)
    }

    const renderExpirationDate = (chatAlert: Models.ChatAlert) => {
        const expirationDate = chatAlert.expirationDate as string
        return (
            <div className={classNames(`${moduleName}__render-exp-date`)}>
                <div
                    className={classNames(`${moduleName}__date hoverable`, {
                        [`${moduleName}__date--red`]: isExpiringWithin24hrs(expirationDate),
                        [`${moduleName}__date--blue`]: !isExpiringWithin24hrs(expirationDate),
                    })}
                >
                    <div className={`${moduleName}__date-wrapper`}>
                        {isExpiringWithin24hrs(expirationDate) && (
                            <i className={`material-icons ${moduleName}__icon--red`}>alarm_add</i>
                        )}
                        {moment(expirationDate)
                            .tz(timezone)
                            .format('MM/DD/YYYY')}
                    </div>
                    <i
                        onClick={() => onChangeExpiryDate(chatAlert, null)}
                        className={classNames(`${moduleName}__remove-icon material-icons`)}
                    >
                        close
                    </i>
                </div>
            </div>
        )
    }

    const renderChatAlert = (chatAlert: Models.ChatAlert) => {
        return (
            <ReactMarkdown
                className={classNames(`${moduleName}__alert-text`, {
                    [`${moduleName}__alert-text--collapsed`]: collapsedChatAlerts,
                })}
                linkTarget="_blank"
            >
                {chatAlert.alert}
            </ReactMarkdown>
        )
    }

    const renderEditChatAlert = () => {
        const alert = editedChatAlert ? editedChatAlert.alert : ''

        return (
            <div className={`${moduleName}__edit-alert`}>
                <div className={`${moduleName}__edit-alert-input-container`}>
                    <CustomField
                        customFieldType={CustomFieldType.TEXTAREA}
                        autoFocus={true}
                        autoSize={true}
                        value={alert}
                        rows={1}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            const value = e.target.value
                            onChangeEditedChatAlert(value)
                        }}
                    />
                </div>
                <div className={`${moduleName}__edit-buttons-container`}>
                    <Button
                        color="primary"
                        className={`${moduleName}__action-button ${moduleName}__cancel-button`}
                        onClick={onCancelEditedChatAlert}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        className={`${moduleName}__action-button ${moduleName}__save-button`}
                        onClick={onSaveEditedAlert}
                    >
                        Save
                    </Button>
                </div>
            </div>
        )
    }

    const renderAddNewChatAlert = () => {
        return (
            <div className={`${moduleName}__row--added`}>
                <div
                    className={classNames(
                        `${moduleName}__table-body-cell`,
                        `${moduleName}__table-body-cell--large`,
                        `${moduleName}__add-alert-cell`,
                    )}
                >
                    <CustomField
                        customFieldType={CustomFieldType.TEXTAREA}
                        autoFocus={true}
                        autoSize={true}
                        value={newChatAlert ? newChatAlert.alert : ''}
                        rows={1}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                            const value = e.target.value
                            onChangeNewChatAlert(value)
                        }}
                    />
                </div>
                <div className={classNames(`${moduleName}__table-body-cell`, `${moduleName}__buttons-cell`)}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={`${moduleName}__action-button ${moduleName}__save-button`}
                        disabled={!canSaveNewChatAlert}
                        onClick={onSaveNewChatAlert}
                    >
                        Add
                    </Button>
                    <Button
                        color="primary"
                        className={`${moduleName}__action-button ${moduleName}__cancel-button`}
                        onClick={onCancelNewChatAlert}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }

    const renderEditIcon = (chatAlert: Models.ChatAlert) => {
        return (
            <i
                className={classNames('material-icons', `${moduleName}__action-icon`, {
                    [`${moduleName}--inactive`]: editedChatAlert && chatAlert.id === editedChatAlert.id,
                })}
                title="Edit chat alert"
                onClick={() => openEditChatAlert(chatAlert)}
            >
                edit
            </i>
        )
    }

    const isExpiringWithin24hrs = (expirationDate: string) => {
        if (!expirationDate) {
            return false
        }

        if (
            moment()
                .tz(timezone)
                .diff(moment(expirationDate).tz(timezone), 'days') === 0
        ) {
            return true
        }

        return false
    }

    if (!localAlerts) {
        return (
            <div className={`${moduleName}__circular-progress-loader`}>
                <CircularProgress size={50} color="primary" variant="indeterminate" />
            </div>
        )
    }

    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={ChatterAlertsTheme}>
                    <div className={classNames(moduleName)}>
                        <div className={`${moduleName}__header`}>
                            <div className={`${moduleName}__title`}>Chatter Alerts</div>
                            <div>
                                <Button
                                    className={`${moduleName}__add-button`}
                                    color="primary"
                                    variant="contained"
                                    onClick={onAddNewChatAlert}
                                >
                                    <i className={`material-icons ${moduleName}__add-icon`}>add</i>ADD CHATTER ALERT
                                </Button>
                            </div>
                        </div>
                        <ChatterAlertExpiryDateNotification
                            expirationDate={dateExpires ? dateExpires.format('MM/DD/YYYY') : null}
                            show={showUpdateExpiryNotification}
                        />
                        <ChatterAlertUpdateNotification show={showUpdateNotification} />
                        <div className={`${moduleName}__table`}>
                            <div className={`${moduleName}__table-header`}>
                                <div className={`${moduleName}__table-header-cell`} />
                                <div
                                    className={classNames(
                                        `${moduleName}__table-header-cell`,
                                        `${moduleName}__table-header-cell--center`,
                                    )}
                                >
                                    id
                                </div>
                                <div
                                    className={classNames(
                                        `${moduleName}__table-header-cell`,
                                        `${moduleName}__table-header-cell--large`,
                                    )}
                                >
                                    alert
                                </div>
                                <div
                                    className={classNames(
                                        `${moduleName}__table-header-cell`,
                                        `${moduleName}__table-header-cell--exp-date-cell`,
                                    )}
                                >
                                    expires
                                </div>
                                <div
                                    className={classNames(
                                        `${moduleName}__table-header-cell`,
                                        `${moduleName}__table-header-cell--center`,
                                    )}
                                >
                                    edit
                                </div>
                                <div
                                    className={classNames(
                                        `${moduleName}__table-header-cell`,
                                        `${moduleName}__table-header-cell--center`,
                                    )}
                                >
                                    delete
                                </div>
                            </div>
                            {newChatAlert && renderAddNewChatAlert()}
                            {typeof alerts !== 'undefined' && localAlerts.length === 0 && (
                                <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>
                            )}
                            <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={onDragEnd}>
                                <DragDropChatterAlert localAlerts={localAlerts} editedChatAlert={editedChatAlert}>
                                    {(chatAlert: Models.ChatAlert, providedDrag: any) => (
                                        <>
                                            <div
                                                className={classNames(
                                                    `${moduleName}__table-body-cell`,
                                                    `${moduleName}__table-body-cell--center`,
                                                )}
                                                {...providedDrag.dragHandleProps}
                                                onMouseDownCapture={collapseChatAlerts}
                                                onMouseUp={expandChatAlerts}
                                            >
                                                <i className={`material-icons`}>drag_handle</i>
                                            </div>
                                            <div
                                                className={classNames(
                                                    `${moduleName}__table-body-cell`,
                                                    `${moduleName}__table-body-cell--center`,
                                                )}
                                            >
                                                <CopyText text={chatAlert.id} />
                                            </div>
                                            <div
                                                className={classNames(
                                                    `${moduleName}__table-body-cell`,
                                                    `${moduleName}__table-body-cell--large`,
                                                )}
                                            >
                                                {editedChatAlert && chatAlert.id === editedChatAlert.id
                                                    ? renderEditChatAlert()
                                                    : renderChatAlert(chatAlert)}
                                            </div>
                                            <div
                                                className={classNames(
                                                    `${moduleName}__table-body-cell`,
                                                    `${moduleName}__table-body-cell--exp-date-cell`,
                                                )}
                                            >
                                                <DatePicker
                                                    autoOk={true}
                                                    color="primary"
                                                    className={classNames(`${moduleName}__datepicker`, {
                                                        [`${moduleName}__datepicker-set`]: Boolean(
                                                            chatAlert.expirationDate,
                                                        ),
                                                    })}
                                                    variant="inline"
                                                    minDate={moment.now()}
                                                    value={
                                                        chatAlert.expirationDate
                                                            ? moment(chatAlert.expirationDate).tz(timezone)
                                                            : moment().tz(timezone)
                                                    }
                                                    onChange={(value: moment.Moment) =>
                                                        onChangeExpiryDate(chatAlert, value)
                                                    }
                                                    inputProps={{ style: { cursor: 'pointer' } }}
                                                />
                                                {!chatAlert.expirationDate ? (
                                                    <i
                                                        className={`material-icons ${moduleName}__expire-icon hoverable`}
                                                    >
                                                        alarm_add
                                                    </i>
                                                ) : (
                                                    renderExpirationDate(chatAlert)
                                                )}
                                            </div>
                                            <div
                                                className={classNames(
                                                    `${moduleName}__table-body-cell`,
                                                    `${moduleName}__table-body-cell--center`,
                                                )}
                                            >
                                                {renderEditIcon(chatAlert)}
                                            </div>
                                            <div
                                                className={classNames(
                                                    `${moduleName}__table-body-cell`,
                                                    `${moduleName}__table-body-cell--center`,
                                                )}
                                            >
                                                <i
                                                    className={`material-icons ${moduleName}__action-icon`}
                                                    title="Delete chat alert"
                                                    onClick={() => openDeleteChatAlertModal(chatAlert)}
                                                >
                                                    delete
                                                </i>
                                            </div>
                                            <div className={`${moduleName}__table-body-cell--websites`}>
                                                <CustomMultiselectField
                                                    items={websites}
                                                    selectedItems={alertWebsiteIds[chatAlert.id]}
                                                    search={websites.length > 8}
                                                    searchPlaceholder="Search Website"
                                                    keyProperty="id"
                                                    displayProperty="url"
                                                    placeholder="Select website(s)"
                                                    selectAllLabel="ALL WEBSITES"
                                                    onSelectElement={values => onSelectWebsite(chatAlert, values)}
                                                    withTooltip={true}
                                                />
                                            </div>
                                        </>
                                    )}
                                </DragDropChatterAlert>
                            </DragDropContext>
                        </div>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
            <ConfirmModal
                title={`Are you sure you want to delete this Chatter Alert?`}
                subtitle=""
                discardText="CANCEL"
                confirmText="DELETE ALERT"
                open={showDeleteChatAlertModal}
                onClose={closeDeleteChatAlertModal}
                onDiscard={closeDeleteChatAlertModal}
                onConfirm={onDeleteChatAlert}
            />
        </>
    )
}

export default ChatterAlertManager
