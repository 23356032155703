import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { CustomFieldType } from '../../../../../../models/enums'
import { OtherOption, QuestionOption, SurveySectionQuestion } from '../../../../../../models/PracticeSurvey'
import AtomToggle from '../../../../../shared/custom-atom-toggle/CustomAtomToggle'
import CustomField from '../../../../../shared/custom-fields/CustomField'
import CustomMultiselectField from '../../../../../shared/custom-fields/CustomMultiselectField'

import './SelectOne.sass'

interface Props {
    question: SurveySectionQuestion
    onChange: (options: QuestionOption[], other_option?: OtherOption) => void
}

const SelectOne = ({
    question: {
        id,
        question_text,
        variants,
        add_label,
        answer_schema,
        component_type,
        other_component_type,
        other_label,
        isRequired,
        isDirty,
        isValid,
        errorMessage,
        error,
    },
    onChange,
}: Props) => {
    const options = answer_schema?.options || []
    const other_option = answer_schema?.other_option

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const nextOptions = options.map((option: QuestionOption) => ({
            ...option,
            selected: event.target.value === option.id,
        }))
        const nextOther = other_option && {
            ...other_option,
            selected: event.target.value === other_option.id,
        }
        onChange(nextOptions, nextOther)
    }

    const handleChangeAtomToggle = (value: string) => {
        const nextOptions = options.map((option: QuestionOption) => ({
            ...option,
            selected: value === option.id,
        }))
        const nextOther = other_option && {
            ...other_option,
            selected: value === other_option.id,
        }
        onChange(nextOptions, nextOther)
    }

    const handleChangeDropdown = (value: any) => {
        const nextOptions = options.map(option => ({
            ...option,
            selected: value[0] === option.id,
        }))

        const nextOther = other_option && {
            ...other_option,
            selected: value[0] === other_option.id,
        }

        onChange(nextOptions, nextOther)
    }

    const handleChangeOtherInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (other_option) {
            const nextOther = { ...other_option }
            nextOther.input_field.value = event.target.value
            onChange(options, nextOther)
        }
    }

    const renderSelectOneComponent = () => {
        switch (component_type) {
            case 'atom-toggle':
                const renderOptions = other_option ? [...options, other_option] : options
                return (
                    <AtomToggle
                        value={value}
                        error={false}
                        errorMessage={''}
                        onClick={value => handleChangeAtomToggle(value)}
                        options={renderOptions.map((option: QuestionOption) => {
                            return { name: option.display_name, value: option.id }
                        })}
                    />
                )
            case 'dropdown':
                const renderOptionsDropdown = other_option ? [...options, other_option] : options
                return (
                    <CustomMultiselectField
                        items={renderOptionsDropdown}
                        selectAllLabel={''}
                        selectedItems={[value]}
                        keyProperty={'id'}
                        search={renderOptionsDropdown.length > 8}
                        maxSelected={1}
                        placeholder={add_label}
                        displayProperty={'display_name'}
                        onSelectElement={handleChangeDropdown}
                    ></CustomMultiselectField>
                )
            default:
                return (
                    <RadioGroup
                        row={variants?.includes('row')}
                        aria-label={question_text}
                        name={id}
                        value={value}
                        onChange={handleChange}
                    >
                        {options.map((option: QuestionOption) => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio color="primary" />}
                                label={option.display_name}
                            />
                        ))}
                        {other_option && (
                            <FormControlLabel
                                value={other_option.id}
                                control={<Radio color="primary" />}
                                label={other_option.display_name}
                            />
                        )}
                    </RadioGroup>
                )
        }
    }

    const renderOther = () => {
        return (
            <CustomField
                customFieldType={
                    other_component_type === 'text-area' ? CustomFieldType.TEXTAREA : CustomFieldType.INPUT
                }
                value={other_option?.input_field.value ?? ''}
                required={isRequired}
                error={hasError}
                onChange={handleChangeOtherInput}
            />
        )
    }

    const hasError = (isDirty && !isValid) || error

    const value =
        (other_option?.selected ? other_option.id : options.find((option: QuestionOption) => option.selected)?.id) || ''
    const extraClasses = variants?.map((variant: string) => `question-select-one--${variant}`).join(' ') ?? ''
    const inLineWithOther = variants?.includes('inline-with-other')

    if (inLineWithOther) {
        return (
            <div className={`question-select-one__wrapper`}>
                <div className={`question-select-one ${extraClasses}`}>
                    <div className="question-select-one__options">{renderSelectOneComponent()}</div>
                </div>
                <div className={`question-select-one ${extraClasses} question-select-one--right`}>
                    {other_label && other_option?.selected && (
                        <div className="question-select-one__other-label">{other_label}</div>
                    )}
                    <div className="question-select-one__options">
                        {other_option?.selected && other_option?.input_field && inLineWithOther && renderOther()}
                    </div>
                    {hasError && <div className="question-select-one__error">{errorMessage}</div>}
                </div>
            </div>
        )
    }

    return (
        <div className={`question-select-one ${extraClasses}`}>
            <div className="question-select-one__options">{renderSelectOneComponent()}</div>
            {other_option?.selected && other_option?.input_field && (
                <div className="question-select-one__other">{renderOther()}</div>
            )}
            {hasError && <div className="question-select-one__error">{errorMessage}</div>}
        </div>
    )
}

export default SelectOne
