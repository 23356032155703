import React, { ChangeEvent } from 'react'
import { Checkbox, FormControlLabel, Icon, TextField } from '@mui/material'
import classNames from 'classnames'

interface Props {
    locationName: string
    accountId: string
    shouldApplyToAllLocations: boolean
    isSubmiting: boolean
    errorMessage?: string
    onStripeIdChange: (newId: string) => void
    onApplyToAllLocationsChange: (toggle: boolean) => void
    onClose: () => void
    onSubmit: () => void
}

const StripeAccountIdForm = ({
    locationName,
    accountId,
    shouldApplyToAllLocations,
    isSubmiting,
    errorMessage = '',
    onStripeIdChange,
    onApplyToAllLocationsChange,
    onClose,
    onSubmit,
}: Props) => {
    const handleChangeStripeAccountId = (event: ChangeEvent<HTMLInputElement>) => {
        onStripeIdChange(event.target.value.trim())
    }

    const handleChangeApplyToAllLocationsChange = () => onApplyToAllLocationsChange(!shouldApplyToAllLocations)

    return (
        <div className="location-modal">
            <div>
                <i className="material-icons close-modal" onClick={onClose}>
                    close
                </i>
                <div className="stripe-modal-wrapper">
                    {errorMessage !== '' && (
                        <div className="payments-stripe-modal-error">
                            <Icon className="info-payment-icon">close</Icon>
                            <span>{errorMessage}</span>
                        </div>
                    )}
                    <h1 className="name-header">{locationName}</h1>
                    <div>
                        <div className="stripe-modal-content">
                            <div className="address-heading">STRIPE Account ID</div>
                            <TextField
                                required
                                className="field terminal-nickname"
                                value={accountId}
                                placeholder="STRIPE Account ID"
                                onChange={handleChangeStripeAccountId}
                            />
                            <FormControlLabel
                                className="location-stripe-apply-all"
                                control={
                                    <Checkbox
                                        checked={shouldApplyToAllLocations}
                                        className="location-stripe-apply-all-checkbox"
                                        onChange={handleChangeApplyToAllLocationsChange}
                                        value="all-websites"
                                    />
                                }
                                label="Apply to all locations"
                            />
                        </div>
                        <button
                            className={classNames('update-button', {
                                'can-save': accountId !== '',
                            })}
                            onClick={onSubmit}
                            disabled={!(accountId !== '') || isSubmiting}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export type StripeAccountIdFormProps = Props
export default StripeAccountIdForm
