import React, { useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import TimeSelector from './TimeSelector'

export type Period = 'AM' | 'PM'

interface Props {
    hour: number
    minute: number
    period: Period
    onChange: (time: moment.Moment) => void
    hideContents: () => void
    preventProp?: (event: any) => void
}

const min = { hour: 1, minute: 0 }
const max = { hour: 12, minute: 55 }
const reduce = { hour: 1, minute: 5 }
const add = { hour: 1, minute: 5 }

const moduleName = 'time-container'

const TimeContainer = (props: Props) => {
    const [hour, setHour] = useState<number>(props.hour || 0)
    const [minute, setMinute] = useState<number>(props.minute || 0)
    const [period, setPeriod] = useState<Period>(props.period || 'AM')

    const handleHourUp = () => {
        let newHour = Math.min(hour + add.hour, max.hour)

        if (hour === max.hour && period === 'AM') {
            newHour = Math.min(min.hour, max.hour)
            const newPeriod = period === 'AM' && minute !== max.minute ? 'PM' : 'AM'
            setPeriod(newPeriod)
        }

        setHour(newHour)
    }

    const handleHourDown = () => {
        let newHour = Math.max(hour - reduce.hour, min.hour)

        if (hour === min.hour) {
            newHour = Math.max(max.hour, min.hour)
            const newPeriod = period === 'PM' && minute !== min.minute ? 'AM' : 'PM'
            setPeriod(newPeriod)
        }

        setHour(newHour)
    }

    const handleMinuteUp = () => {
        let newMinute = Math.min(minute + add.minute, max.minute)

        if (minute === max.minute) {
            const newHour = hour === max.hour && period === 'AM' ? min.hour : Math.min(hour + add.hour, max.hour)
            newMinute = Math.min(min.minute, max.minute)

            if (hour === max.hour && period === 'AM') {
                setPeriod('PM')
            }

            setHour(newHour)
        }

        setMinute(newMinute)
    }

    const handleMinuteDown = () => {
        let newMinute = Math.max(minute - reduce.minute, min.minute)

        if (minute === min.minute) {
            const newHour = hour === min.hour && period === 'PM' ? max.hour : Math.max(hour - reduce.hour, min.hour)
            newMinute = Math.max(max.minute, min.minute)

            if (hour === min.hour && period === 'PM') {
                setPeriod('AM')
            }

            setHour(newHour)
        }

        setMinute(newMinute)
    }

    const invokeOnChange = () => {
        const time = moment(`${hour}:${minute} ${period}`, 'h:mm A')
        props.onChange(time)
        props.hideContents()
    }

    return (
        <div className={moduleName} onClick={props.preventProp}>
            <div className={`${moduleName}__time-div`}>
                <TimeSelector onUp={handleHourUp} onDown={handleHourDown} value={hour.toString()} />
                <div className={`${moduleName}__colon`}>:</div>
                <TimeSelector
                    onUp={handleMinuteUp}
                    onDown={handleMinuteDown}
                    value={minute < 10 ? `0${minute.toString()}` : minute.toString()}
                />
                <div className={`${moduleName}__am-pm`}>
                    <div
                        className={classnames(`${moduleName}__am-pm__button`, {
                            [`${moduleName}__am-pm--selected`]: period === 'AM',
                        })}
                        onClick={() => setPeriod('AM')}
                    >
                        AM
                    </div>
                    <div
                        className={classnames(`${moduleName}__am-pm__button`, {
                            [`${moduleName}__am-pm--selected`]: period === 'PM',
                        })}
                        onClick={() => setPeriod('PM')}
                    >
                        PM
                    </div>
                </div>
            </div>
            <div className={`${moduleName}__buttons-div`}>
                <div className={`${moduleName}__buttons-div__button-left`} onClick={props.hideContents}>
                    CANCEL
                </div>
                <div className={`${moduleName}__buttons-div__button-right`} onClick={invokeOnChange}>
                    OK
                </div>
            </div>
        </div>
    )
}

export default TimeContainer
