import React, { Fragment, useRef } from 'react'
import classNames from 'classnames'

export interface Props {
    value?: string
    icon?: string
    timezone?: string
    onChange: (e: React.FormEvent<HTMLInputElement>) => void
    onClick?: () => void
    className?: string
    selected?: boolean
    error?: boolean
    errorMessage?: string
}

const moduleName = 'picker-input'

const TimePickerInput = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>()

    const { icon = 'schedule', selected, value = '', error, errorMessage, timezone, onChange, onClick } = props

    return (
        <Fragment>
            <div className={`${moduleName}__container`}>
                <div
                    className={classNames(moduleName, {
                        [`${moduleName}__with-timezone`]: Boolean(timezone),
                        [`${moduleName}--error`]: error,
                        [`${moduleName}--selected`]: selected,
                    })}
                >
                    {
                        <input
                            ref={inputRef as React.RefObject<any>}
                            className={`${moduleName}__text`}
                            type="text"
                            value={value}
                            disabled={true}
                            onChange={onChange}
                            onClick={onClick}
                        />
                    }
                    {<i className={`material-icons ${moduleName}__icon`}>{icon}</i>}
                </div>
                {timezone && <div className={`${moduleName}__timezone`}>{timezone}</div>}
            </div>
            {error && <div className={`${moduleName}__error-message`}>{errorMessage}</div>}
        </Fragment>
    )
}

export default TimePickerInput
