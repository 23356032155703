import React from 'react'
import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import { setRedirect } from '../../appActions'
import AdminAccount from '../../models/AdminAccount'
import {
    AccessReferralsList,
    DirectScheduling,
    GenerateAmplifyWebCode,
    UpdateCancellationAndRescheduleSettings,
    ViewDirectSchedulingLogs,
} from '../../models/enums'
import { useAppDispatch } from '../../util/useAppDispatch'
import { WebCodeIcon } from '../amplify/WebCodeIcon'
import { grayDa, softBlack, verySoftBlack, white } from '../shared/styles/colors'

import { DirectSchedulingViews } from './DirectSchedulingTab'

type Props = {
    practice: Models.Practice
    account: AdminAccount
    selectView: (selectedView: DirectSchedulingViews) => void
    classes: any
}

const styles: any = () => ({
    container: {
        marginBottom: '20px',
    },
    button: {
        background: softBlack,
        borderRadius: '2px',
        color: white,
        fontSize: '14px',
        letterSpacing: '0.5px',
        margin: '0 2px',
        padding: '4px 12px',
        textAlign: 'center',
        textTransform: 'uppercase',
        '&:hover': {
            background: verySoftBlack,
        },
    },
    icon: {
        color: white,
        fontSize: '18px',
        paddingRight: '8px',
        height: '24px',
    },
    iconAvailability: {
        color: white,
        fontSize: '18px',
        height: '24px',
        paddingRight: '8px',
    },
    iconCancelReschedule: {
        color: white,
        fontSize: '18px',
        height: '24px',
        paddingRight: '8px',
    },
    iconReferrals: {
        color: white,
        fontSize: '18px',
        height: '24px',
        paddingRight: '8px',
    },
    inactive: {
        backgroundColor: grayDa,
        '&:hover': {
            background: grayDa,
        },
    },
})

function DirectSchedulingViewsList({ account, practice, selectView, classes }: Props) {
    const dispatch = useAppDispatch()

    function onSelectView(selectedView: DirectSchedulingViews) {
        selectView(selectedView)
    }

    function onViewDetails() {
        dispatch(setRedirect(`/availability/${practice.id}`))
    }

    function onViewConfigLogs() {
        dispatch(setRedirect(`/config-logs/${practice.id}`))
    }

    function onViewCancelReschedule() {
        dispatch(setRedirect(`/cancel-reschedule-settings/${practice.id}`))
    }

    function onViewReferrals() {
        dispatch(setRedirect(`/diagnostics/${practice.id}/amplify`))
    }

    return (
        <div className={classes.container}>
            {account.hasAccess(GenerateAmplifyWebCode) && (
                <Button
                    className={classNames(classes.button, {
                        [classes.inactive]: !practice.products.some(
                            p => p.value === DirectScheduling.value && p.active,
                        ),
                    })}
                    onClick={() => onSelectView('web-codes')}
                    variant="text"
                    color="primary"
                >
                    <span className={classes.icon}>
                        <WebCodeIcon />
                    </span>
                    web codes
                </Button>
            )}
            <Button
                className={classNames(classes.button, {
                    [classes.inactive]: !practice.products.some(p => p.value === DirectScheduling.value && p.active),
                })}
                onClick={onViewDetails}
                variant="text"
                color="primary"
            >
                <span className={classes.iconAvailability}>
                    <i className="material-icons">today</i>
                </span>
                Availability
            </Button>
            {account.hasAccess(UpdateCancellationAndRescheduleSettings) && (
                <Button
                    className={classNames(classes.button, {
                        [classes.inactive]: !practice.products.some(
                            p => p.value === DirectScheduling.value && p.active,
                        ),
                    })}
                    onClick={onViewCancelReschedule}
                    variant="text"
                    color="primary"
                >
                    <span className={classes.iconCancelReschedule}>
                        <i className="material-icons">update</i>
                    </span>
                    Cancel/Reschedule
                </Button>
            )}
            {account.hasAccess(ViewDirectSchedulingLogs) && (
                <Button
                    className={classNames(classes.button, {
                        [classes.inactive]: !practice.products.some(
                            p => p.value === DirectScheduling.value && p.active,
                        ),
                    })}
                    onClick={onViewConfigLogs}
                    variant="text"
                    color="primary"
                >
                    <span className={classes.iconAvailability}>
                        <i className="material-icons">toc</i>
                    </span>
                    Config Logs
                </Button>
            )}
            {account.hasAccess(AccessReferralsList) && (
                <Button
                    className={classNames(classes.button, {
                        [classes.inactive]: !practice.products.some(
                            p => p.value === DirectScheduling.value && p.active,
                        ),
                    })}
                    onClick={onViewReferrals}
                    variant="text"
                    color="primary"
                >
                    <span className={classes.iconReferrals}>
                        <i className="material-icons">device_hub</i>
                    </span>
                    Requests
                </Button>
            )}
        </div>
    )
}

export default withStyles(styles)(DirectSchedulingViewsList)
