import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { IconButton, InputAdornment, TextField } from '@mui/material'

import { RootState } from '../../../appReducer'

import './ChatListNavBar.sass'

export type ChatListProps = {
    search: string
    browsing: number
    claimedChats: { [channel: string]: Models.ChatMetadata }
    setSearch: (searchKey: string) => void
}

const moduleName = 'chat-list-navbar'

const ChatListNavBar = ({ search, claimedChats, browsing, setSearch }: ChatListProps) => {
    const conversations = useSelector((state: RootState) => state.chat.conversations)

    const needsResponseCount = Object.keys(claimedChats).filter(chatId => {
        const conversation = conversations[claimedChats[chatId].channelName]
        const lastMessage = conversation?.messages[conversation.messages.length - 1]
        return lastMessage && lastMessage.is_patient
    }).length

    return (
        <div className={`${moduleName}__nav-top`}>
            <div className={`${moduleName}__search`}>
                <TextField
                    placeholder="Search"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <i className="material-icons icon">search</i>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {search && (
                                    <IconButton onClick={() => setSearch('')}>
                                        <i className="material-icons icon">close</i>
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </div>

            {!search && (
                <Fragment>
                    <div className={`${moduleName}__infolabel`}>Site Visitors: {browsing}</div>

                    {needsResponseCount > 0 && (
                        <div className={`${moduleName}__unread-count`} title="Your claimed chats that need a response">
                            <i className={`material-icons ${moduleName}__notifications`}>notifications</i>
                            <label>{needsResponseCount < 10 ? needsResponseCount : '9+'}</label>
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    )
}

export default ChatListNavBar
