import iassign from 'immutable-assign'
import orderBy from 'lodash/orderBy'

import { PracticeSurvey, PracticeSurveyMap as PracticeSurveyState } from '../../../../models/PracticeSurvey'

import {
    ClearSurveyErrors,
    PatchDoctors,
    SetAdditionalHoursInformationLocationIds,
    SetEditing,
    SetLocationState,
    SetLocationSurveyDoctors,
    SetLocationSurveyLocationInformation,
    SetLocationSurveyOperatingHours,
    SetLocationSurveys,
    SetOperatingHour,
    SetOperatingHourLocationIds,
    SetSectionIsValid,
    SetSectionPracticeLocationIds,
    SetSurveyErrors,
    SetSurveyQuestion,
    SURVEY_CLEAR_ERROR_DETAILS,
    SURVEY_ERROR_DETAILS,
    SURVEY_PATCH_DOCTORS,
    SURVEY_SET_ADDITIONAL_HOURS_INFORMATION_LOCATION_IDS,
    SURVEY_SET_DOCTORS,
    SURVEY_SET_EDITING,
    SURVEY_SET_LOCATION_INFORMATION,
    SURVEY_SET_LOCATION_STATE,
    SURVEY_SET_OPERATING_HOUR,
    SURVEY_SET_OPERATING_HOUR_LOCATION_IDS,
    SURVEY_SET_OPERATING_HOURS,
    SURVEY_SET_SECTION_IS_VALID,
    SURVEY_SET_SECTION_LOCATION_IDS,
    SURVEY_SET_SURVEY_QUESTION,
    SURVEY_SET_SURVEYS,
    SURVEY_UPDATE_LOCATION_INFORMATION,
    UpdateLocationInformation,
} from './actions'

type PracticeSurveyStateAction =
    | SetLocationState
    | SetEditing
    | SetSectionIsValid
    | SetSectionPracticeLocationIds
    | SetLocationSurveyDoctors
    | SetLocationSurveys
    | SetSurveyQuestion
    | SetLocationSurveyOperatingHours
    | SetOperatingHour
    | SetOperatingHourLocationIds
    | SetAdditionalHoursInformationLocationIds
    | SetLocationSurveyLocationInformation
    | UpdateLocationInformation
    | SetSurveyErrors
    | ClearSurveyErrors
    | PatchDoctors

const initialState = (): PracticeSurveyState => ({})

const initialLocationData = (): PracticeSurvey =>
    ({
        state: 'none',
        isEditing: false,
        wasSubmitted: false,
        doctors: {
            isValid: true,
            list: [],
        },
        operatingHours: {
            isValid: true,
            practiceLocationIds: [],
            days: [],
            list: [{}],
        },
        procedures: {
            isValid: true,
            practiceLocationIds: [],
        },
        locationInformation: {
            isValid: true,
            address: undefined,
        },
        surveys: [],
        errorMessages: {},
    } as PracticeSurvey)

function practiceSurveyReducer(
    state: PracticeSurveyState = initialState(),
    action: PracticeSurveyStateAction,
): PracticeSurveyState {
    switch (action.type) {
        case SURVEY_SET_LOCATION_STATE: {
            return iassign(state, next => {
                if (!next[action.locationId]) {
                    next[action.locationId] = initialLocationData()
                }
                next[action.locationId].state = action.state
                if (action.state === 'submitted') {
                    next[action.locationId].wasSubmitted = true
                }
                return next
            })
        }

        case SURVEY_SET_EDITING: {
            return iassign(state, next => {
                if (!next[action.locationId]) {
                    next[action.locationId] = initialLocationData()
                }
                next[action.locationId].isEditing = action.isEditing
                return next
            })
        }

        case SURVEY_SET_SECTION_IS_VALID: {
            return iassign(
                state,
                next => next[action.locationId][action.section].isValid,
                () => action.isValid,
            )
        }

        case SURVEY_SET_SECTION_LOCATION_IDS: {
            const nextState = iassign(
                state,
                next => next[action.locationId].surveys,
                nextSurveys => {
                    const surveyIndex = nextSurveys.findIndex(survey => survey.id === action.survey.id)

                    if (surveyIndex === -1) {
                        return nextSurveys
                    }

                    nextSurveys[surveyIndex].practiceLocationIds = action.practiceLocationIds

                    return nextSurveys
                },
            )

            return iassign(
                nextState,
                next => next[action.locationId].state,
                () => 'updated',
            )
        }

        case SURVEY_SET_DOCTORS: {
            const nextState = iassign(
                state,
                next => next[action.locationId].doctors,
                () => ({
                    list: action.doctors,
                    isValid: action.doctors.every(doctor => doctor.firstName.isValid && doctor.lastName.isValid),
                }),
            )

            const isDirty = Boolean(
                action.doctors.some(
                    doctor => doctor.firstName.isDirty || doctor.lastName.isDirty || doctor.suffix.isDirty,
                ),
            )

            return iassign(
                nextState,
                next => next[action.locationId].state,
                nextState => (isDirty ? 'updated' : nextState),
            )
        }

        case SURVEY_PATCH_DOCTORS: {
            return iassign(
                state,
                next => next[action.locationId].doctors.list,
                nextDoctorsList => {
                    action.doctorsIds.forEach((id, index) => {
                        if (id) {
                            nextDoctorsList[index].id.value = id
                        }
                    })
                    return nextDoctorsList.sort((a, b) => a.lastName?.value?.localeCompare(b.lastName?.value))
                },
            )
        }

        case SURVEY_SET_SURVEYS: {
            return iassign(
                state,
                next => next[action.locationId].surveys,
                () => orderBy(action.surveys, 'order'),
            )
        }

        case SURVEY_SET_SURVEY_QUESTION: {
            const nextState = iassign(
                state,
                next => next[action.locationId].surveys,
                nextSurveys => {
                    const surveyIndex = nextSurveys.findIndex(survey => survey.id === action.survey.id)
                    if (surveyIndex === -1) {
                        return nextSurveys
                    }

                    const questionIndex = nextSurveys[surveyIndex].questions.findIndex(
                        question => question.id === action.question.id,
                    )
                    if (questionIndex === -1) {
                        return nextSurveys
                    }

                    nextSurveys[surveyIndex].questions[questionIndex] = action.question
                    nextSurveys[surveyIndex].isValid = nextSurveys[surveyIndex].questions.every(
                        question => question.isValid,
                    )

                    return nextSurveys
                },
            )

            return iassign(
                nextState,
                next => next[action.locationId].state,
                () => 'updated',
            )
        }

        case SURVEY_SET_ADDITIONAL_HOURS_INFORMATION_LOCATION_IDS: {
            const nextState = iassign(
                state,
                next => next[action.locationId].surveys,
                nextSurveys => {
                    const surveyIndex = nextSurveys.findIndex(survey => survey.name === 'additional-hours-info')

                    if (surveyIndex === -1) {
                        return nextSurveys
                    }

                    nextSurveys[surveyIndex].practiceLocationIds = action.practiceLocationIds

                    return nextSurveys
                },
            )

            return iassign(
                nextState,
                next => next[action.locationId].state,
                () => 'updated',
            )
        }

        case SURVEY_SET_OPERATING_HOUR_LOCATION_IDS: {
            const nextState = iassign(
                state,
                next => next[action.locationId].operatingHours.practiceLocationIds,
                () => action.practiceLocationIds,
            )

            return iassign(
                nextState,
                next => next[action.locationId].state,
                () => 'updated',
            )
        }

        case SURVEY_SET_OPERATING_HOURS: {
            return iassign(
                state,
                next => next[action.locationId].operatingHours.days,
                () => action.hours,
            )
        }

        case SURVEY_SET_OPERATING_HOUR: {
            const nextState = iassign(
                state,
                next => next[action.locationId].operatingHours.days,
                nextDays => {
                    const dayIndex = nextDays.findIndex(day => day.dayOfWeek === action.dayOfWeek)
                    if (dayIndex === -1) {
                        return nextDays
                    }
                    nextDays[dayIndex] = {
                        ...nextDays[dayIndex],
                        ...action.patch,
                    }
                    return nextDays
                },
            )

            return iassign(
                nextState,
                next => next[action.locationId].state,
                () => 'updated',
            )
        }

        case SURVEY_SET_LOCATION_INFORMATION: {
            return iassign(
                state,
                next => next[action.locationId].locationInformation,
                () => action.locationInformation,
            )
        }

        case SURVEY_UPDATE_LOCATION_INFORMATION: {
            const nextState = iassign(
                state,
                next => next[action.locationId].locationInformation?.address?.[action.fieldName],
                nextField => {
                    if (!nextField) {
                        return nextField
                    }
                    return action.nextLocationInformationField
                },
            )

            const nextStateLocation = iassign(
                nextState,
                next => next[action.locationId].locationInformation,
                nextLocationInformation => {
                    if (!nextLocationInformation) {
                        return nextLocationInformation
                    }
                    nextLocationInformation.isValid = Object.values(nextLocationInformation?.address ?? {}).every(
                        field => field === null || typeof field !== 'object' || field?.isValid,
                    )
                    return nextLocationInformation
                },
            )

            return iassign(
                nextStateLocation,
                next => next[action.locationId].state,
                () => 'updated',
            )
        }

        case SURVEY_ERROR_DETAILS: {
            const errorMessages = {}

            if (!state[action.locationId].doctors.isValid) {
                errorMessages['Doctors'] = [
                    {
                        questionId: 'doctors',
                        error: 'Please enter proper values',
                    },
                ]
            }

            if (!state[action.locationId].operatingHours.isValid) {
                errorMessages['Hours of Operation'] = [
                    {
                        questionId: 'hours',
                        error: 'Please enter proper values',
                    },
                ]
            }

            if (!state[action.locationId].locationInformation?.isValid) {
                errorMessages['Location Information'] = [
                    {
                        questionId: 'location',
                        error: 'Please enter proper values',
                    },
                ]
            }

            state[action.locationId].surveys.forEach(survey => {
                const errors = survey.questions
                    .map(question => {
                        const err = action.errorDetails[question.id]
                            ? `"${question.question_text}": ${action.errorDetails[question.id].details}`
                            : ''
                        return err ? { questionId: question.id, error: err } : null
                    })
                    .filter(error => !!error)

                if (errors.length) {
                    errorMessages[survey.display_name] = [...errors]
                }

                errorMessages[survey.display_name] = [...errors]
            })

            const doctors = iassign(
                state,
                next => next[action.locationId]['doctors'],
                nextSurveys => {
                    return {
                        ...nextSurveys,
                        isValid: !Boolean(action.errorDetails['doctors']),
                    }
                },
            )

            const locationInformation = iassign(
                doctors,
                next => next[action.locationId]['locationInformation'],
                nextSurveys => {
                    return {
                        ...nextSurveys,
                        isValid: !Boolean(action.errorDetails['locationInformation']),
                    }
                },
            )

            const operatingHours = iassign(
                locationInformation,
                next => next[action.locationId]['operatingHours'],
                nextSurveys => {
                    return {
                        ...nextSurveys,
                        isValid: !Boolean(action.errorDetails['operatingHours']),
                    }
                },
            )

            const surveyList = iassign(
                operatingHours,
                next => next[action.locationId].surveys,
                nextSurveys => {
                    return nextSurveys.map(survey => {
                        return {
                            ...survey,
                            questions: survey.questions.map(question => ({
                                ...question,
                                isValid: !Boolean(action.errorDetails[question.id]),
                                error: Boolean(action.errorDetails[question.id]),
                                errorMessage: action.errorDetails[question.id]
                                    ? action.errorDetails[question.id].details
                                    : '',
                            })),
                        }
                    })
                },
            )

            return iassign(
                surveyList,
                next => next[action.locationId].errorMessages,
                () => errorMessages,
            )
        }

        case SURVEY_CLEAR_ERROR_DETAILS: {
            return iassign(
                state,
                next => next[action.locationId].errorMessages,
                () => {},
            )
        }

        default:
            return state
    }
}

export default practiceSurveyReducer
