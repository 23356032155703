import React from 'react'
import { show } from 'react-notification-system-redux'
import { CircularProgress } from '@mui/material'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'

import CopyText from '../../../../shared/CopyText'
import { AddTerminalRadio } from '../../terminal-form/TerminalForm'

interface Props {
    practiceId: Models.Practice['id']
    locationId: Models.PracticeLocation['id']
    locationName: Models.PracticeLocation['name']
    paymentsInfo: Models.PracticeLocation['paymentsInfo']
    isPaymentsCardLoading: boolean
    isPaymentsCardExpanded: boolean
    stripeConnectedAccountId: Models.PracticeLocation['stripeConnectedAccountId']
    stripeLocationId: Models.PracticeLocation['stripeLocationId']
    statementDescriptor: Models.PracticeLocation['statementDescriptor']
    onOpenPaymentsRatesModal: (
        locationId: Models.PracticeLocation['id'],
        locationName: Models.PracticeLocation['name'],
    ) => void
    onOpenStripeAccountIdModal: (
        locationId: Models.PracticeLocation['id'],
        locationName: Models.PracticeLocation['name'],
        stripeAccountId: string,
        applyToAll: boolean,
    ) => void
    onOpenPaymentsDescriptorModal: (
        locationId: Models.PracticeLocation['id'],
        statementDescriptor: Models.PracticeLocation['statementDescriptor'],
    ) => void
    onTogglePaymentsExpand: (locationId?: Models.PracticeLocation['id']) => void
    onOpenTerminalModal: (
        locationId: Models.PracticeLocation['id'],
        terminalId: Models.LocationPaymentsTerminalInfo['id'],
        nickname: Models.LocationPaymentsTerminalInfo['nickname'],
        readerSerial: Models.LocationPaymentsTerminalInfo['readerSerial'],
        pairingCode: Models.LocationPaymentsTerminalInfo['pairingCode'],
        s700Code: Models.LocationPaymentsTerminalInfo['s700Code'],
        uuid: Models.LocationPaymentsTerminalInfo['uuid'],
        addTerminalRadio: AddTerminalRadio,
        isView: boolean,
    ) => void
    allFieldsCompleted: Models.PracticeLocation['allFieldsCompleted']
    stripeIDStatus: boolean | undefined
    paymentsRatesStatus: boolean | undefined
}

const LocationCardPayment = ({
    practiceId,
    locationId,
    locationName,
    paymentsInfo,
    isPaymentsCardLoading,
    isPaymentsCardExpanded,
    stripeConnectedAccountId,
    stripeLocationId,
    statementDescriptor,
    onOpenPaymentsRatesModal,
    onOpenStripeAccountIdModal,
    onOpenPaymentsDescriptorModal,
    onTogglePaymentsExpand,
    onOpenTerminalModal,
    allFieldsCompleted,
    stripeIDStatus,
    paymentsRatesStatus,
}: Props) => {
    const handleTogglePaymentsDetails = () => onTogglePaymentsExpand(isPaymentsCardExpanded ? undefined : locationId)

    const handleOpenTerminalModal = (locationPaymentsInfo?: Models.LocationPaymentsTerminalInfo) => () => {
        if (!locationPaymentsInfo) {
            onOpenTerminalModal(locationId, '', '', '', undefined, undefined, undefined, 'useSmile', false)
            return
        }
        onOpenTerminalModal(
            locationId,
            locationPaymentsInfo.id,
            locationPaymentsInfo.nickname,
            locationPaymentsInfo.readerSerial,
            locationPaymentsInfo.pairingCode,
            locationPaymentsInfo.s700Code,
            locationPaymentsInfo.uuid,
            'useSmile',
            true,
        )
    }

    const handleOpenPaymentsRatesModal = () => onOpenPaymentsRatesModal(locationId, locationName)

    const handleOpenStripeAccountId = () => {
        onOpenStripeAccountIdModal(locationId, locationName, stripeConnectedAccountId || '', false)
    }

    const handleOpenPaymentsDescriptorModal = () => {
        onOpenPaymentsDescriptorModal(locationId, statementDescriptor || '')
    }

    const renderLocationPaymentsInfo = (): JSX.Element | null => {
        if (paymentsInfo && paymentsInfo.length > 0) {
            return (
                <>
                    {paymentsInfo.map(pi => {
                        return (
                            <dl key={`${pi.id}-${pi.nickname}`} className="card-payments-info__row-terminal">
                                <dt>
                                    <button className="card-payments-info__link" onClick={handleOpenTerminalModal(pi)}>
                                        {pi.nickname}
                                    </button>
                                </dt>
                            </dl>
                        )
                    })}
                </>
            )
        }
        return null
    }

    return (
        <div className={classNames('card-scheduling')}>
            <span
                className={classNames('card-scheduling__link', 'card-scheduling__link--payments')}
                onClick={handleTogglePaymentsDetails}
            >
                <span className={classNames('card-scheduling__link-title')}>
                    {allFieldsCompleted ? '' : '! '}Simplifeye Payments Info
                </span>
                <Icon>{isPaymentsCardExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
            </span>
            {!isPaymentsCardLoading && isPaymentsCardExpanded && (
                <div className={classNames('card-scheduling__data', 'card-payments-info')}>
                    <span className="card-payments-info__stripe-title">Terminals</span>
                    {renderLocationPaymentsInfo()}
                    <dl className="card-payments-info__row-add">
                        <dt>
                            <button className="card-payments-info__link" onClick={handleOpenTerminalModal()}>
                                <span>ADD TERMINAL</span>
                                <Icon>add_circle</Icon>
                            </button>
                        </dt>
                    </dl>

                    <span className="card-payments-info__stripe-title">
                        {stripeIDStatus ? '' : '! '}STRIPE Location ID
                    </span>
                    <span
                        className={classNames('card-payments-info__stripe-location-id', {
                            'card-payments-info__stripe-location-id--missing': !stripeLocationId,
                        })}
                    >
                        {!!stripeLocationId ? stripeLocationId : 'No stripe location id'}
                    </span>
                    <span className="card-payments-info__stripe-title">
                        {stripeIDStatus ? '' : '! '}STRIPE Account ID
                    </span>
                    <button className="card-payments-info__stripe-link" onClick={handleOpenStripeAccountId}>
                        {!stripeConnectedAccountId && (
                            <>
                                <span>Add STRIPE ID</span>
                                <Icon>add_circle</Icon>
                            </>
                        )}
                        {!!stripeConnectedAccountId && (
                            <>
                                <span>{stripeConnectedAccountId}</span>
                                <Icon>edit</Icon>
                            </>
                        )}
                    </button>

                    <span className="card-payments-info__stripe-title">
                        {paymentsRatesStatus ? '' : '! '}Rates and Processing
                    </span>
                    <button className="card-payments-info__stripe-link" onClick={handleOpenPaymentsRatesModal}>
                        <span>Edit rates/processing</span>
                        <Icon>edit</Icon>
                    </button>

                    <span className="card-payments-info__stripe-title">External Invoices</span>
                    <div className="card-payments-info__external-link">
                        <CopyText
                            text={`${process.env.REACT_APP_PORTAL_HOST}/login/external-invoice?practiceId=${practiceId}&locationId=${locationId}`}
                        >
                            <span>{`${process.env.REACT_APP_PORTAL_HOST}/login/external-invoice?practiceId=${practiceId}&locationId=${locationId}`}</span>
                        </CopyText>
                    </div>

                    <span className="card-payments-info__stripe-title">Statement Descriptor</span>
                    <button className="card-payments-info__stripe-link" onClick={handleOpenPaymentsDescriptorModal}>
                        <span>{statementDescriptor}</span>
                        <Icon>edit</Icon>
                    </button>
                </div>
            )}
            {isPaymentsCardLoading && isPaymentsCardExpanded && (
                <div
                    className={classNames('card-scheduling__data', 'card-payments-info', 'card-payments-info__loading')}
                >
                    <CircularProgress className="card-payments-loading" style={{ height: '25px', width: '25px' }} />
                </div>
            )}
        </div>
    )
}

export type LocationCardPaymentProps = Props
export default LocationCardPayment
