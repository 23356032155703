import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import CircularProgress from '@mui/material/CircularProgress'
import throttle from 'lodash/throttle'

import { setRedirect } from '../../../appActions'
import { RootState } from '../../../appReducer'
import { PracticeReschedulingConfiguration } from '../../../models/v2/Practice'
import { SearchBar } from '../../../modules/shared/custom-fields/SearchBar'
import { fetchPractice } from '../../practices/actions'
import CancelRescheduleSettingsPracticeHeader from '../../shared/CancelRescheduleSettingsPracticeHeader'
import SuccessMessage from '../../shared/success-message/success-message'

import CancelRescheduleSettingsLocationsList from './CancelRescheduleSettingsLocationsList'
import GlobalSettingsModal from './GlobalSettingsModal'
import {
    createPracticeReschedulingConfiguration,
    fetchConnectedPracticeLocations,
    fetchPracticeLocationReschedulingConfiguration,
    fetchPracticeLocations,
    fetchPracticeReschedulingConfiguration,
    searchPracticeLocationsRequest,
} from './v2actions'

import './CancelRescheduleSettings.sass'

export type CancelRescheduleSettingsRouteParams = RouteComponentProps<{
    practiceId: string
}>

type Props = CancelRescheduleSettingsRouteParams

const moduleName = 'cancel-reschedule-settings'

const CancelRescheduleSettings = (props: Props) => {
    const practiceId = props.match.params.practiceId

    const page = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceLocationsData?.[practiceId]?.page || 1,
    )
    const practiceSettings = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceSettingsData?.[practiceId],
    )

    const connectedPracticeLocations = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.connectedPracticeLocations?.[practiceId] || [],
    )

    const hasConnectedPracticeLocations = connectedPracticeLocations?.length > 0

    const [successMessage, setSuccessMessage] = useState<string>('')
    const [searchPage, setSearchPage] = useState<number>(page)
    const [openGlobalSettingsModal, setOpenGlobalSettingsModal] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    const [firstListLoadDone, setFirstListLoadDone] = useState<boolean>(false)
    const [searchKeyword, setSearchKeyword] = useState<string>('')
    const [hasSearch, setHasSearch] = useState<boolean>(false)

    const practice = useSelector((state: RootState) => state.practices.practices[practiceId])
    const allPages = useSelector(
        (state: RootState) =>
            state.v2.reschedulingConfiguration.practiceLocationsData?.[practiceId]?.pagination?.allPages || 0,
    )
    const list = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceLocationsData?.[practiceId]?.list,
    )

    const practiceFetched = practice?.id

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            clearSearch()
            dispatch(searchPracticeLocationsRequest(practiceId, 1, false))
        }
    }, [dispatch, practiceId])

    useEffect(() => {
        if (!practiceFetched) {
            dispatch(fetchPractice(practiceId))
        }
    }, [practiceFetched, practiceId, dispatch])

    useEffect(() => {
        dispatch(fetchPracticeLocationReschedulingConfiguration(practiceId))
        dispatch(fetchConnectedPracticeLocations(practiceId))
        dispatch(fetchPracticeReschedulingConfiguration(practiceId))
    }, [practiceFetched, practiceId, dispatch])

    const onReturnToPractices = () => {
        dispatch(setRedirect(`/practices`))
    }

    const searchForPracticeLocations = useCallback(
        (searchKeyword: string, searchPage: number) => {
            dispatch(fetchPracticeLocations(practiceId, searchKeyword, searchPage))
        },
        [dispatch, practiceId],
    )

    const throttledSearchForPracticeLocations = useMemo(() => throttle(searchForPracticeLocations, 300), [
        searchForPracticeLocations,
    ])

    useEffect(() => {
        throttledSearchForPracticeLocations(searchKeyword.trim().toLocaleLowerCase(), searchPage)
    }, [searchKeyword, searchPage, throttledSearchForPracticeLocations])

    useEffect(() => {
        if ((allPages > 1 || searchKeyword.trim() !== '') && !firstListLoadDone) {
            setHasSearch(true)
            setFirstListLoadDone(true)
        }
    }, [allPages, searchKeyword, firstListLoadDone])

    useEffect(() => {
        if (successMessage) {
            setShowSuccessMessage(true)
            setTimeout(() => {
                setShowSuccessMessage(false)
            }, 5000)
        }
    }, [successMessage])

    const onOpenGlobalSettingsModal = () => {
        setSuccessMessage('')
        setOpenGlobalSettingsModal(true)
    }

    const onCloseGlobalSettingsModal = () => {
        setOpenGlobalSettingsModal(false)
    }

    const saveGlobalSettings = async (configuration: PracticeReschedulingConfiguration) => {
        const globalSettings = await dispatch(createPracticeReschedulingConfiguration(practiceId, configuration))

        if (globalSettings) {
            setSuccessMessage('Global settings saved successfully.')
            setOpenGlobalSettingsModal(false)
        }
    }

    const searchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value || '')
        setSearchPage(1)
    }
    const clearSearch = () => {
        setSearchKeyword('')
    }

    if (!practice) {
        return (
            <div className="circular-progress-loader">
                <CircularProgress size={100} color="primary" variant="indeterminate" />
            </div>
        )
    }

    return (
        <div className={moduleName}>
            <div className="detached-button" onClick={onReturnToPractices}>
                <i className="material-icons">arrow_back</i>
            </div>
            <CancelRescheduleSettingsPracticeHeader practice={practice} />
            <div className={`${moduleName}__title`}>Cancel/Reschedule Settings</div>
            <div className={`${moduleName}__subtitle-wrapper`}>
                <div className={`${moduleName}__subtitle`}>
                    Control what appointments patients can reschedule or cancel.
                </div>
                {hasConnectedPracticeLocations && (
                    <div className={`${moduleName}__global-settings-button`} onClick={onOpenGlobalSettingsModal}>
                        <div className={`${moduleName}__global-settings-button-text`}>Global Settings</div>
                        <i className={`material-icons ${moduleName}__global-settings-icon`}>settings</i>
                    </div>
                )}
            </div>
            {hasSearch && (
                <div className="search-bar-container">
                    <SearchBar
                        value={searchKeyword}
                        onChange={searchByKeyword}
                        onClear={clearSearch}
                        isMessageShown={searchKeyword.length > 0 && !list?.length}
                        noResultsMessage="No records found for given name or ID of Facility ID"
                        placeholder="Search by Location Name or Location ID or Facility ID"
                    />
                </div>
            )}
            <CSSTransition
                in={showSuccessMessage}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={{ enter: 500, exit: 500 }}
            >
                <SuccessMessage isShown={Boolean(successMessage)}>{successMessage}</SuccessMessage>
            </CSSTransition>
            <CancelRescheduleSettingsLocationsList
                searchPage={searchPage}
                setSearchPage={setSearchPage}
                practiceId={practiceId}
                isPending={true}
            />
            {openGlobalSettingsModal && (
                <GlobalSettingsModal
                    practiceId={practiceId}
                    practiceSettings={practiceSettings}
                    saveGlobalSettings={saveGlobalSettings}
                    closeModal={onCloseGlobalSettingsModal}
                />
            )}
        </div>
    )
}

export default CancelRescheduleSettings
