import download from 'downloadjs'

import ApiV2 from '../../../ApiV2'
import { AppThunk } from '../../../appStore'
import {
    ConnectedPracticeLocation,
    PracticeLocationSchedulingConfigurationLogs as ConfigLog,
} from '../../../models/v2/Practice'

export enum ConfigLogsActionTypes {
    RECEIVE_ALL_PRACTICE_LOCATIONS = '@V2/RECEIVE_ALL_PRACTICE_LOCATIONS',
    SET_SELECTED_LOCATION = '@V2/SET_SELECTED_LOCATION',
    SET_SELECTED_USERS = '@V2/SET_SELECTED_USERS',
    SET_DATE_FROM = '@V2/SET_DATE_FROM',
    SET_DATE_TO = '@V2/SET_DATE_TO',
    SEARCH_CONFIG_LOGS_REQUEST = '@v2/SEARCH_CONFIG_LOGS_REQUEST',
    RECEIVE_CONFIG_LOGS = '@V2/RECEIVE_CONFIG_LOGS',
    RECEIVE_USERS = '@V2/RECEIVE_USERS',
    RECEIVE_CONNECTED_PRACTICE_LOCATIONS = '@V2/RECEIVE_CONNECTED_PRACTICE_LOCATIONS',
    RECEIVE_SCHEDULING_CONFIGURATION_LOGS_CSV = '@V2/RECEIVE_SCHEDULING_CONFIGURATION_LOGS_CSV',
}

export type ReceiveAllPracticeLocations = {
    type: ConfigLogsActionTypes.RECEIVE_ALL_PRACTICE_LOCATIONS
    practiceId: string
    practiceLocations: ApiV2.Practice.PracticeLocationFull[]
}

export type SetSelectedLocation = {
    type: ConfigLogsActionTypes.SET_SELECTED_LOCATION
    practiceId: string
    locationId: string
}

export type SetSelectedUsers = {
    type: ConfigLogsActionTypes.SET_SELECTED_USERS
    practiceId: string
    userIds: string[]
}

export type SetDateFrom = {
    type: ConfigLogsActionTypes.SET_DATE_FROM
    practiceId: string
    value: string
    isValid: boolean
    errorMessage: string
}

export type SetDateTo = {
    type: ConfigLogsActionTypes.SET_DATE_TO
    practiceId: string
    value: string
    isValid: boolean
    errorMessage: string
}

export type ReceiveUsers = {
    type: ConfigLogsActionTypes.RECEIVE_USERS
    practiceId: string
    users: ModelsV2.Practice.PracticeStaffDataExtended[]
}

export type SearchConfigLogsRequest = {
    type: ConfigLogsActionTypes.SEARCH_CONFIG_LOGS_REQUEST
    locationId: string
    searchParams: ModelsV2.Practice.PracticeLocationSchedulingConfigurationSearchParams
    loading: boolean
}

export type ReceiveConfigLogs = {
    type: ConfigLogsActionTypes.RECEIVE_CONFIG_LOGS
    locationId: string
    configLogs: ConfigLog[]
    pagination: Models.PaginationInfo | undefined
}

export type ReceiveConnectedPracticeLocations = {
    type: ConfigLogsActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS
    practiceId: string
    connectedLocations: ConnectedPracticeLocation[]
}

export type ReceiveSchedulingConfigurationLogsCsv = {
    type: ConfigLogsActionTypes.RECEIVE_SCHEDULING_CONFIGURATION_LOGS_CSV
    locationId: string
    status: 'idle' | 'downloading' | 'success' | 'error'
}

export function receiveAllPracticeLocations(
    practiceId: string,
    practiceLocations: ApiV2.Practice.PracticeLocationFull[],
): ReceiveAllPracticeLocations {
    return {
        type: ConfigLogsActionTypes.RECEIVE_ALL_PRACTICE_LOCATIONS,
        practiceId,
        practiceLocations,
    }
}

export function receiveUsers(practiceId: string, users: ModelsV2.Practice.PracticeStaffDataExtended[]): ReceiveUsers {
    return {
        type: ConfigLogsActionTypes.RECEIVE_USERS,
        practiceId,
        users,
    }
}

export function setDateFrom(
    practiceId: string,
    value: string,
    isValid: boolean,
    errorMessage: string = '',
): SetDateFrom {
    return {
        type: ConfigLogsActionTypes.SET_DATE_FROM,
        practiceId,
        value,
        isValid,
        errorMessage,
    }
}

export function setDateTo(practiceId: string, value: string, isValid: boolean, errorMessage: string = ''): SetDateTo {
    return {
        type: ConfigLogsActionTypes.SET_DATE_TO,
        practiceId,
        value,
        isValid,
        errorMessage,
    }
}

export function searchConfigLogsRequest(
    locationId: string,
    searchParams: ModelsV2.Practice.PracticeLocationSchedulingConfigurationSearchParams,
    loading: boolean,
): SearchConfigLogsRequest {
    return {
        type: ConfigLogsActionTypes.SEARCH_CONFIG_LOGS_REQUEST,
        locationId,
        searchParams,
        loading,
    }
}

export function receiveConfigLogs(
    locationId: string,
    configLogs: ConfigLog[],
    pagination: Models.PaginationInfo | undefined,
): ReceiveConfigLogs {
    return {
        type: ConfigLogsActionTypes.RECEIVE_CONFIG_LOGS,
        locationId,
        configLogs,
        pagination,
    }
}

export function receiveSchedulingConfigurationLogsCsv(
    locationId: string,
    status: 'idle' | 'downloading' | 'success' | 'error',
): ReceiveSchedulingConfigurationLogsCsv {
    return {
        type: ConfigLogsActionTypes.RECEIVE_SCHEDULING_CONFIGURATION_LOGS_CSV,
        locationId,
        status,
    }
}

export function setSelectedLocation(practiceId: string, locationId: string): SetSelectedLocation {
    return {
        type: ConfigLogsActionTypes.SET_SELECTED_LOCATION,
        practiceId,
        locationId,
    }
}

export function setSelectedUsers(practiceId: string, userIds: string[]): SetSelectedUsers {
    return {
        type: ConfigLogsActionTypes.SET_SELECTED_USERS,
        practiceId,
        userIds,
    }
}

export function receiveConnectedPracticeLocations(
    practiceId: string,
    connectedLocations: ConnectedPracticeLocation[],
): ReceiveConnectedPracticeLocations {
    return {
        type: ConfigLogsActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS,
        practiceId,
        connectedLocations,
    }
}

export function fetchAllPracticeLocations(practiceId: string): any {
    return async (dispatch: any) => {
        const locations = await ApiV2.Practice.getAllPracticeLocations(practiceId)
        await dispatch(receiveAllPracticeLocations(practiceId, locations))
        return locations
    }
}

export function fetchSchedulingConfigurationLogs(
    locationId: string,
    searchParams: ModelsV2.Practice.PracticeLocationSchedulingConfigurationSearchParams,
): AppThunk<Promise<ConfigLog[]>> {
    return async (dispatch: any) => {
        await dispatch(searchConfigLogsRequest(locationId, searchParams, true))
        const { data: configLogs, paginationInfo } = await ApiV2.Practice.getSchedulingConfigurationLogs(
            locationId,
            searchParams,
        )
        await dispatch(receiveConfigLogs(locationId, configLogs, paginationInfo))
        return configLogs
    }
}

export function fetchSchedulingConfigurationLogsCsv(
    locationId: string,
    searchParams: ModelsV2.Practice.PracticeLocationSchedulingConfigurationSearchParams,
): any {
    return async (dispatch: any) => {
        await dispatch(receiveSchedulingConfigurationLogsCsv(locationId, 'downloading'))
        try {
            const { blob } = await ApiV2.Practice.getSchedulingConfigurationLogsCsv(locationId, searchParams)
            const fileName = `scheduling-configuration-logs-${locationId}-${searchParams.start_date}-${searchParams.end_date}.csv`
            download(blob, fileName, 'text/csv')

            return await dispatch(receiveSchedulingConfigurationLogsCsv(locationId, 'idle'))
        } catch (e) {
            return await dispatch(receiveSchedulingConfigurationLogsCsv(locationId, 'error'))
        }
    }
}

export function fetchAllPracticeStaffUsers(
    practiceId: string,
): AppThunk<Promise<ModelsV2.Practice.PracticeStaffData[]>> {
    return async (dispatch: any) => {
        const users = await ApiV2.Practice.getAllPracticeStaffUsers(practiceId)
        const usersMapped = users.map((user: ModelsV2.Practice.PracticeStaffData) => {
            return {
                ...user,
                name: `${user.last_name} ${user.first_name}`.trim(),
            }
        })
        await dispatch(receiveUsers(practiceId, usersMapped))
        return users
    }
}

export function fetchConnectedPracticeLocations(
    practiceId: string,
    page: number = 1,
): AppThunk<Promise<ConnectedPracticeLocation[]>> {
    return async (dispatch: any) => {
        const connectedLocations = await ApiV2.Practice.getAllConnectedPracticeLocations(practiceId, page)
        await dispatch(receiveConnectedPracticeLocations(practiceId, connectedLocations))
        return connectedLocations
    }
}
