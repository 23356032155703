import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'
import { getAllLocationsPaymentsStatus } from 'modules/practices/actions'

import { RootState } from '../../../appReducer'
import { ManageAllPractices, ManageDirectScheduling, ManagePayments, QuickviewTab } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import ConfirmModal from '../../shared/confirm-modal/ConfirmModal'
import {
    fetchLocationsSchedulingData,
    fetchLocationVyneProducts,
    fetchPracticeLocationsPaginated,
    receivePracticeLocations,
} from '../actions'

import LocationSurveyModal from './edit-survey/LocationSurveyModal'
import { deleteLocation } from './location-forms/actions'
import { updateLocationAddEditData } from './location-forms/actions'
import LocationFormModal from './location-forms/LocationFormModal'
import LocationList from './locations-list/LocationList'
import { closePaymentRatesModal } from './payment-rates/actions'
import LocationPaymentsRatesContainer from './payment-rates/LocationPaymentsRatesContainer'
import StatementDescriptorModal from './statement-descriptor/StatementDescriptorModal'
import StripeAccountIdModal from './stripe-account-id/StripeAccountIdModal'
import TerminalModal from './terminal-form/TerminalModal'
import { closeEditSurveyModal } from './actions'

import './LocationsTab.sass'

interface Props {
    practice: Models.Practice
    hasPayments: boolean
    practiceSearchTerms: Models.SearchTerms
}

const LocationsTab = ({ practice, practiceSearchTerms, hasPayments }: Props) => {
    const isInitRequest = React.useRef<boolean>(true)

    const dispatch = useAppDispatch()

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const practicesList = useSelector((state: RootState) => state.navigation.practices.list)
    const loadedPractices = practicesList.join(',')
    const { modals, successMessage, errorMessage } = useSelector(
        (state: RootState) => state.practiceLocations[practice.id],
    )
    const { page, search } = useSelector((state: RootState) => state.practiceLocations[practice.id].metadata)
    const isFetchingAllPracticeLocations = useSelector(
        (state: RootState) => state.practices.isFetchingAllPracticeLocations[practice.id],
    )

    const [isListLoading, setIsListLoading] = useState<boolean>(true)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    const { locations, products } = practice

    const locationsLength = locations ? locations.length : 0

    const fetchLocationsListData = useCallback(async () => {
        setIsListLoading(true)
        const locationsReponse = await dispatch(fetchPracticeLocationsPaginated(practice, { page, search }))
        dispatch(fetchLocationsSchedulingData(practice.id))
        dispatch(getAllLocationsPaymentsStatus(practice.id))
        setIsListLoading(false)
        if (locationsReponse?.length > 0) {
            locationsReponse.forEach((location: Api.PracticeLocation) => {
                if (location.facility_id && location.is_vyne_controlled)
                    dispatch(fetchLocationVyneProducts(practice.id, location.id))
            })
        }
    }, [dispatch, page, search, loadedPractices])

    useEffect(() => {
        fetchLocationsListData()
    }, [fetchLocationsListData])

    useEffect(() => {
        if (locationsLength > 8 && !isFetchingAllPracticeLocations) {
            fetchLocationsListData()
        }
    }, [isFetchingAllPracticeLocations, fetchLocationsListData, locationsLength])

    useEffect(() => {
        if (successMessage) {
            setShowSuccessMessage(true)
            setTimeout(() => {
                setShowSuccessMessage(false)
            }, 5000)
        }
    }, [successMessage])

    useEffect(() => {
        if (isInitRequest.current) {
            isInitRequest.current = false
            dispatch(receivePracticeLocations(practice.id, []))
        }
    }, [dispatch, practice])

    const getHasDirectScheduling = (): boolean =>
        Boolean(
            products.find(p => p.value === QuickviewTab.DirectScheduling) &&
                [ManageDirectScheduling, ManageAllPractices].some(account?.hasAccess.bind(account)),
        )

    const getHasEhrIntegration = () =>
        Boolean(
            getHasDirectScheduling() ||
                (products.find(p => p.value === QuickviewTab.Payments) &&
                    [ManagePayments, ManageAllPractices].some(account?.hasAccess.bind(account))),
        )

    const handleCloseSurveyLocationModal = (): void => {
        dispatch(closeEditSurveyModal(practice.id))
        fetchLocationsListData()
    }

    const handleClosePaymentRatesModal = (): void => {
        dispatch(closePaymentRatesModal(practice.id))
    }

    const handleCloseDeleteLocationModal = (): void => {
        if (modals.locationAddEdit.isLoading) return
        dispatch(updateLocationAddEditData({ showDeleteConfirmation: false }, practice.id))
    }

    const handleDeleteLocation = (): void => {
        if (!modals.locationAddEdit.location) return
        dispatch(deleteLocation(practice, modals.locationAddEdit.location))
    }

    if (!locations) {
        return <div>Loading</div>
    }

    return (
        <div className={classNames('locations-tab')}>
            <CSSTransition
                in={showSuccessMessage}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={{ enter: 500, exit: 500 }}
                className={classNames(`payments-updated-successfully`)}
            >
                <div>
                    <Icon className="info-payment-icon">check_circle</Icon>
                    <span>{successMessage}</span>
                </div>
            </CSSTransition>

            {errorMessage !== '' && (
                <div className="payments-processing-error">
                    <Icon className="info-payment-icon">close</Icon>
                    <span>{errorMessage}</span>
                </div>
            )}
            <LocationList
                products={products}
                practiceId={practice.id}
                locations={locations}
                hasPayments={hasPayments}
                hasDirectScheduling={getHasDirectScheduling()}
                isListLoading={isListLoading}
                practiceSearchTerms={practiceSearchTerms}
            />
            {modals.editSurvey.isOpen && modals.editSurvey.location !== null ? (
                <LocationSurveyModal
                    practice={practice}
                    location={modals.editSurvey.location}
                    onClose={handleCloseSurveyLocationModal}
                />
            ) : null}
            {modals.locationAddEdit.isOpen && (
                <LocationFormModal
                    practice={practice}
                    hasEhrIntegration={getHasEhrIntegration()}
                    hasPayments={hasPayments}
                />
            )}
            {modals.terminal.isOpen && <TerminalModal practiceId={practice.id} />}
            {modals.stripeAccountId.isOpen && <StripeAccountIdModal practiceId={practice.id} />}
            {modals.paymentRates.isOpen && (
                <LocationPaymentsRatesContainer
                    closeLocationPaymentsModal={handleClosePaymentRatesModal}
                    locationId={modals.paymentRates.locationId}
                    locationName={modals.paymentRates.locationName}
                    practice={practice}
                />
            )}
            {modals.locationAddEdit.showDeleteConfirmation && (
                <ConfirmModal
                    open
                    title={`Are you sure you want to delete "${modals.locationAddEdit?.location?.name || ''}"?`}
                    subtitle=""
                    discardText="CANCEL"
                    confirmText="DELETE LOCATION"
                    onClose={handleCloseDeleteLocationModal}
                    onDiscard={handleCloseDeleteLocationModal}
                    onConfirm={handleDeleteLocation}
                />
            )}
            {modals.statementDescriptor.isOpen && <StatementDescriptorModal practiceId={practice.id} />}
        </div>
    )
}

export type LocationsTabProps = Props
export default LocationsTab
