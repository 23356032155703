import React from 'react'

export type SpecialtyTabProps = {
    children: React.ReactNode | React.ReactNode[]
    name: string
}

const moduleName = 'specialty-tab'

const SpecialtyTab = (props: SpecialtyTabProps) => {
    return <div className={moduleName}>{props.children}</div>
}

export default SpecialtyTab
