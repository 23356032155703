import moment from 'moment'

const sessionKey = 'session'

export default class SessionManager {
    public static sessionExpireAt: moment.Moment
    public static logoutCountDownInMinSec: string

    private static token?: string = undefined

    static get session() {
        return this.token
    }

    static set session(token: string | undefined) {
        this.token = token
        if (this.token) {
            SessionManager.parseSessionExpirationTime(this.token)
            SessionManager.forceLogoutCountDown()
        }
    }

    static update(token: string | undefined) {
        this.session = token
        if (token) {
            window.sessionStorage.setItem(sessionKey, token)
        } else {
            window.sessionStorage.removeItem(sessionKey)
        }
    }

    static get isLoggedIn() {
        return Boolean(this.token)
    }

    static forceLogout() {
        this.update(undefined)
        window.sessionStorage.setItem('PSTUnauthorizedError', 'Your session has expired. Please log in again.')
        window.location.assign('/login')
    }

    private static forceLogoutCountDown() {
        let intervalID: any = null

        const callbackFn = () => {
            const expiresAtSubtracted = this.sessionExpireAt.clone().subtract(15, 'minutes')
            const currentTime = moment()
            if (currentTime.isAfter(expiresAtSubtracted)) {
                const diffInSec = this.sessionExpireAt.diff(currentTime, 'seconds')

                if (diffInSec <= 0) {
                    this.forceLogout()
                    return
                }

                const diffInMin = Math.floor(diffInSec / 60)

                const newDate = moment({
                    minute: diffInMin,
                    seconds: diffInSec % 60,
                }).subtract(1, 'second')

                this.logoutCountDownInMinSec = newDate.format('mm:ss')
            }
        }

        intervalID = setInterval(callbackFn, 1000)

        // In order to preserve computer resources when browser tab is not active
        // we clear the interval for the browser inactive time.
        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                clearInterval(intervalID)
            } else {
                intervalID = setInterval(callbackFn, 1000)
            }
        })
    }

    private static parseSessionExpirationTime(token: string) {
        const jwtPayload = token.split('.')[1]
        try {
            const { expires_at } = jwtPayload && JSON.parse(window.atob(jwtPayload))
            this.sessionExpireAt = moment(expires_at)
        } catch (error) {
            console.error(`Couldn't parse session expiration time.`)
        }
    }
}

try {
    const session = window.sessionStorage.getItem(sessionKey) || ''
    SessionManager.session = session
} catch (err) {
    SessionManager.session = undefined
}
