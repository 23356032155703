import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import { fetchPermissionList, fetchPracticeLocations } from '../actions'
import { searchPracticeStaffUsers } from '../v2actions'

import StaffAddMember from './StaffAddMember'
import StaffSearchBar from './StaffSearchBar'
import StaffTabTableBody from './StaffTabTableBody'

import './StaffTab.sass'

export type Props = {
    practice: Models.Practice
}

const StaffTab = (props: Props) => {
    const { practice } = props
    const practiceId = practice.id

    const [firstLoadDone, setFirstLoadDone] = useState(false)
    const [firstListLoadDone, setFirstListLoadDone] = useState(false)
    const [hasSearchBar, setHasSearchBar] = useState(false)

    const practiceUserRoles = useSelector((state: RootState) => state.practices.userRoles)

    const loadedPractices = useSelector((state: RootState) => state.practices.practices)

    const list = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.list || [])
    const allRows = useSelector(
        (state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.pagination?.allRows || 0,
    )

    const query = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practice.id]?.query || '')
    const page = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practice.id]?.page || 1)
    const sort = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practice.id]?.sort || 'last_name')
    const order = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practice.id]?.order)

    const [staffQuery, setStaffQuery] = useState<string>(query)
    const [searchPage, setSearchPage] = useState<number>(page)
    const [searchSort, setSearchSort] = useState<ModelsV2.Practice.StaffSort | undefined>(sort)
    const [searchOrder, setSearchOrder] = useState<'asc' | 'desc' | undefined>(order)

    const selectedPracticeIsLoaded = Boolean(loadedPractices[practice.id].id)

    const dispatch = useDispatch()

    const searchForStaff = useCallback(
        (
            practiceId: string,
            searchKey: string,
            searchPage: number,
            sort: ModelsV2.Practice.StaffSort | undefined,
            order: 'asc' | 'desc' | undefined,
        ) => {
            const searchTerms: ModelsV2.Practice.PracticeStaffSearchTerms = { query: searchKey, page: searchPage }
            if (sort) {
                searchTerms.sort = sort
                searchTerms.order = order
            }

            dispatch(searchPracticeStaffUsers(practiceId, searchTerms))
        },
        [dispatch],
    )

    const refreshStaffList = () => {
        dispatch(
            searchPracticeStaffUsers(
                practiceId,
                {
                    query: staffQuery,
                    page: searchPage,
                    sort: searchSort,
                    order: searchOrder,
                },
                false,
            ),
        )
    }

    const throttledSearchStaff = useMemo(() => throttle(searchForStaff, 300), [searchForStaff])

    useEffect(() => {
        if ((allRows > 8 || query !== '') && !firstListLoadDone) {
            setHasSearchBar(true)
            setFirstListLoadDone(true)
        }
    }, [allRows, firstListLoadDone, query])

    useEffect(() => {
        throttledSearchStaff(practiceId, staffQuery.trim(), searchPage, searchSort, searchOrder)
    }, [practiceId, staffQuery, searchPage, searchSort, searchOrder, throttledSearchStaff])

    useEffect(() => {
        if (!firstLoadDone && selectedPracticeIsLoaded) {
            const fetchLocations = async () => {
                await dispatch(fetchPracticeLocations(practice.id))
            }

            fetchLocations().then(() => {
                setFirstLoadDone(true)
            })
            dispatch(fetchPermissionList())
        }
    }, [dispatch, selectedPracticeIsLoaded, firstLoadDone, practice])

    if (list == null) {
        return (
            <div className={classNames('staff-table')}>
                <StaffTabTableBody
                    practiceId={practiceId}
                    practice={practice}
                    searchSort={searchSort}
                    setSearchSort={setSearchSort}
                    searchOrder={searchOrder}
                    setSearchOrder={setSearchOrder}
                    searchPage={searchPage}
                    setSearchPage={setSearchPage}
                    refreshStaffList={refreshStaffList}
                />
            </div>
        )
    }

    return (
        <div className={classNames('staff-table')}>
            {hasSearchBar && (
                <StaffSearchBar
                    practiceId={practiceId}
                    setStaffQuery={setStaffQuery}
                    setSearchPage={setSearchPage}
                    staffQuery={staffQuery}
                />
            )}
            {list.length > 0 && (
                <StaffTabTableBody
                    practiceId={practiceId}
                    practice={practice}
                    searchSort={searchSort}
                    setSearchSort={setSearchSort}
                    searchOrder={searchOrder}
                    setSearchOrder={setSearchOrder}
                    searchPage={searchPage}
                    setSearchPage={setSearchPage}
                    refreshStaffList={refreshStaffList}
                />
            )}

            <StaffAddMember
                practice={practice}
                practiceUserRoles={practiceUserRoles}
                refreshStaffList={refreshStaffList}
                locationsLoaded={firstLoadDone}
            />
        </div>
    )
}

export default StaffTab
