import * as React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'

import { mainBlue } from '../../shared/styles/colors'

import './CustomDatepicker.sass'

type EventProps = {
    onChange: (date: moment.Moment, value?: string) => void
    onInput?: (event: any) => void
}

type DateFieldProps = {
    required?: boolean
    dateFormat: string
    value: Date | null
    placeholder?: string
    label?: string
    invalidLabel?: string
    minDateMessage?: string
    maxDateMessage?: string
    disablePast?: boolean
    disableFuture?: boolean
    error?: boolean
    mask?: string
    autoOk?: boolean
}

type Props = EventProps & DateFieldProps

export default class CustomDatepicker extends React.PureComponent<Props> {
    onChange = (value: moment.Moment) => this.props.onChange && this.props.onChange(value)
    onInput = (event: React.FormEvent<HTMLDivElement>) => this.props.onInput && this.props.onInput(event)

    render() {
        const {
            required,
            value,
            dateFormat,
            placeholder,
            label,
            invalidLabel,
            disablePast,
            disableFuture,
            minDateMessage,
            maxDateMessage,
            error,
            mask,
            autoOk = true,
        } = this.props

        return (
            <div className="custom-datepicker">
                <KeyboardDatePicker
                    autoOk={autoOk}
                    color="primary"
                    style={{ color: mainBlue }}
                    variant="inline"
                    label={label}
                    invalidLabel={invalidLabel}
                    format={dateFormat}
                    placeholder={placeholder}
                    value={value}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    minDateMessage={minDateMessage}
                    maxDateMessage={maxDateMessage}
                    allowKeyboardControl={true}
                    required={required}
                    error={error}
                    mask={mask}
                    onInput={this.onInput}
                    onChange={this.onChange}
                    KeyboardButtonProps={{
                        color: 'primary',
                    }}
                />
            </div>
        )
    }
}
