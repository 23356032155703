import React from 'react'

import SurveyActivityLogView from './activity-logs/SurveyActivityLogList'
import DoctorView from './doctors/DoctorsView'
import LocationInformationView from './location-information/LocationInformationView'
import OperatingHoursView from './operating-hours/OperatingHoursView'
import SurveyRenderedView from './survey-rendered/SurveyRenderedView'

interface Props {
    location: Models.PracticeLocation
}

const SurveyView = ({ location }: Props) => {
    return (
        <div>
            <h2 className="survey-modal__main-title survey-modal__main-title--underlined">Practice Info</h2>
            <DoctorView location={location} />
            <LocationInformationView location={location} />
            <OperatingHoursView location={location} />
            <SurveyRenderedView location={location} />
            <SurveyActivityLogView location={location} />
        </div>
    )
}

export default SurveyView
