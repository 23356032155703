import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import _ from 'lodash'

import { RootState } from '../../appReducer'
import { SortingDirection } from '../../models/enums'
import { useAppDispatch } from '../../util/useAppDispatch'

import ReferralCard from './ReferralCard'
import { fetchReferralsDashboard } from './v2actions'

import './ReferralsDashboardColumn.sass'
import './CompletedReferralsDashboardColumn.sass'

type Props = {
    status: ModelsV2.Referrals.PendingReferralStatus
    referrals: ModelsV2.Referrals.ReferralsDashboardCard[]
    openReferral: (referral: ModelsV2.Referrals.ReferralsDashboardCard) => void
}

const CompletedReferralsDashboardColumn = (props: Props) => {
    const { referrals, status, openReferral } = props
    const [sortedReferrals, setSortedReferrals] = useState<ModelsV2.Referrals.ReferralsDashboardCard[]>([])
    const [sortingDirection, setSortingDirection] = useState<SortingDirection>()
    const [isSortingIconDisabled, setIsSortingIconDisabled] = useState(false)
    const [loadMoreDisabled, setLoadMoreDisabled] = useState(false)

    const dispatch = useAppDispatch()
    const currentPage = useSelector((state: RootState) => state.v2.referrals.referralsDashboard.complete.current_page)
    const allPages = useSelector(
        (state: RootState) => state.v2.referrals.referralsDashboard.complete.pagination_info?.allPages,
    )
    const allRows = useSelector(
        (state: RootState) => state.v2.referrals.referralsDashboard.complete.pagination_info?.allRows,
    )

    const changeReferralsSortingDirection = async () => {
        const newSortingDirection = sortingDirection === 'asc' ? 'desc' : 'asc'
        setSortingIconDisabledState(true)
        setSortedReferrals(sortCompletedReferrals(newSortingDirection))
        setSortingDirection(newSortingDirection)
        setSortingIconDisabledState(false)
    }
    const sortCompletedReferrals = useCallback(
        (direction: SortingDirection) => {
            return _.orderBy(referrals, 'created', direction)
        },
        [referrals],
    )

    const setSortingIconDisabledState = (isDisabled: boolean) => {
        setIsSortingIconDisabled(isDisabled)
    }
    const loadMore = async () => {
        if (currentPage && allPages && currentPage < allPages) {
            setLoadMoreDisabled(true)
            await dispatch(
                fetchReferralsDashboard({
                    page: currentPage + 1,
                    filter: {
                        status: 'complete',
                    },
                }),
            )
            setLoadMoreDisabled(false)
        }
    }

    useEffect(() => {
        setSortedReferrals(sortCompletedReferrals('desc'))
    }, [referrals, sortCompletedReferrals])

    return (
        <div className="referrals-dashboard-column">
            <div className="referrals-dashboard-column-header">
                <div className="referrals-dashboard-column-title">
                    <strong>{allRows}</strong> {status.display}
                </div>
                <div className="referrals-dashboard-column-sort-load">
                    <Button
                        className="referrals-dashboard-column-sort-load-button"
                        onClick={changeReferralsSortingDirection}
                        variant="text"
                        disabled={isSortingIconDisabled}
                    >
                        Created
                        {sortingDirection === 'asc' ? (
                            <i className="material-icons">arrow_drop_up</i>
                        ) : (
                            <i className="material-icons">arrow_drop_down</i>
                        )}
                    </Button>
                </div>
            </div>
            <div className="referrals-dashboard-column-body">
                {sortedReferrals.map(referral => (
                    <ReferralCard
                        key={referral.id}
                        statusValue={status.value}
                        referral={referral}
                        openReferral={openReferral}
                    />
                ))}

                {sortedReferrals.length > 0 && (
                    <div className="load-completed-button">
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={
                                loadMoreDisabled || sortedReferrals.length < 50 || sortedReferrals.length === allRows
                            }
                            onClick={loadMore}
                        >
                            Load More
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CompletedReferralsDashboardColumn
