import iassign from 'immutable-assign'

import { ReceiveYextLocations, YextActionTypes } from './v2actions'

export type YextState = {
    yextUrl: string
}

type YextAction = ReceiveYextLocations

const initialState: YextState = {
    yextUrl: '',
}

export function reducer(state: YextState = initialState, action: YextAction) {
    switch (action.type) {
        case YextActionTypes.RECEIVE_YEXT_LOCATIONS: {
            return iassign(state, next => {
                next.yextUrl = action.yextLocations.url
                return next
            })
        }

        default:
            return state
    }
}
