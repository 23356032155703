import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Grid } from '@mui/material'
import sortBy from 'lodash/sortBy'

import { RootState } from '../../../appReducer'
import { CustomFieldType } from '../../../models/enums'
import { HeartbeatProviderPropertyInput } from '../../../models/v2/Heartbeat'
import { Practice, PracticeLocation } from '../../../models/v2/Practice'
import { registerAgentLocationProvider, updateAgentLocationProvider } from '../../../modules/heartbeat/v2actions'
import CustomField from '../../../modules/shared/custom-fields/CustomField'
import CustomMultiselectField from '../../../modules/shared/custom-fields/CustomMultiselectField'
import ErrorMessage from '../../../modules/shared/error-message/error-message'
import { fetchHeartbeatPracticeLocationProviders } from '../v2actions'

import { isValid } from './shared/validator'

import './Location.sass'

export type LocationProps = {
    practice: Practice
    location: PracticeLocation
}

const moduleName = 'heartbeat-tab-location'

const Location = (props: LocationProps) => {
    const { practice, location } = props
    const heartbeatProviders = useSelector((state: RootState) => state.v2.heartbeat.heartbeatProviders)
    const heartbeatLocation = location?.heartbeatAgentModuleLocation?.find(location => location.hasHeartbeat === true)
    const connectedLocation = practice.locationProviders?.find(
        locationProvider => locationProvider.agentLocationId === heartbeatLocation?.externalLocationId,
    )

    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)
    const [selectedPovider, setSelectedPovider] = useState<any>([])
    const [inputsState, setInputsState] = useState<HeartbeatProviderPropertyInput[]>([])
    const isFormValid = inputsState.length
        ? inputsState.every(item => {
              return item.isDirty === true && item.error === ''
          })
        : false
    const dispatch = useDispatch()

    useEffect(() => {
        if (connectedLocation === undefined) {
            setEdit(true)
        } else {
            setSelectedPovider([connectedLocation.provider.providerId])
        }
    }, [connectedLocation])

    const onInputPropertyChange = (value: string, property: HeartbeatProviderPropertyInput, propertyIndex: number) => {
        setInputsState(
            inputsState.map((input: HeartbeatProviderPropertyInput, index) => {
                if (propertyIndex === index) {
                    const error = isValid(property.validationRules, value)
                    return {
                        ...input,
                        isDirty: true,
                        error: error,
                        value: value,
                    }
                }
                return { ...input }
            }),
        )
    }

    const onSave = async (patch: boolean) => {
        const inputData = {
            properties: inputsState.map(input => {
                return {
                    name: input.name,
                    value: input.value,
                }
            }),
        }

        if (heartbeatLocation?.externalLocationId) {
            setIsSaving(true)
            if (patch) {
                await dispatch(
                    updateAgentLocationProvider(heartbeatLocation.externalLocationId, selectedPovider[0], inputData),
                )
            } else {
                await dispatch(
                    registerAgentLocationProvider(heartbeatLocation.externalLocationId, selectedPovider[0], inputData),
                )
            }
            await dispatch(fetchHeartbeatPracticeLocationProviders(practice))
            setEdit(false)
            setIsSaving(false)
        }
    }

    const onEdit = () => {
        setEdit(true)
    }

    /*
        This part sets inputs to be rendered  from connectedLocation.properties if there are connected locations
        or renders inputs from heartbeatProviders.properties if location is not connected yet
    */
    useEffect(() => {
        let inputs: HeartbeatProviderPropertyInput[] = []
        if (heartbeatLocation && !connectedLocation) {
            if (selectedPovider.length) {
                inputs = heartbeatProviders.find(provider => provider.id === selectedPovider[0])
                    ?.properties as HeartbeatProviderPropertyInput[]
                setInputsState(
                    sortBy(inputs, input => input.displayName)
                        .map((input: HeartbeatProviderPropertyInput) => {
                            return {
                                ...input,
                                isDirty: false,
                                error: '',
                                value: '',
                            }
                        })
                        .reverse(),
                )
            }
        } else if (heartbeatLocation && connectedLocation) {
            inputs = connectedLocation.provider?.properties
            setInputsState(
                sortBy(inputs, input => input.displayName)
                    .map((input: HeartbeatProviderPropertyInput) => {
                        return {
                            ...input,
                            isDirty: true,
                            error: isValid(input.validationRules, input.value as string),
                            value: input.value,
                        }
                    })
                    .reverse(),
            )
        }
    }, [heartbeatLocation, connectedLocation, selectedPovider, heartbeatProviders])

    const renderErrorMsg = () => {
        return (
            <div className="row">
                <ErrorMessage type="error">
                    The location has not been paired with an agent or Heartbeat product is not enabled on the agent, so
                    we are unable to collect data from the PMS.
                </ErrorMessage>
            </div>
        )
    }

    const renderEdit = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <label className={`${moduleName}__label`}>Send data to</label>
                    <CustomMultiselectField
                        items={heartbeatProviders}
                        selectAllLabel={''}
                        selectedItems={selectedPovider}
                        keyProperty={'id'}
                        search={false}
                        maxSelected={1}
                        placeholder={'Send data to'}
                        displayProperty={'displayName'}
                        onSelectElement={value => {
                            setSelectedPovider(value)
                        }}
                    ></CustomMultiselectField>
                </Grid>

                {inputsState.map((property: HeartbeatProviderPropertyInput, index: number) => {
                    const inputProp = inputsState[index]
                    return (
                        <Grid item xs={12} sm={6} md={3} key={`id_${index}`}>
                            <label className={`${moduleName}__label`}>{property.displayName}</label>
                            <CustomField
                                customFieldType={CustomFieldType.INPUT}
                                inputType={inputProp.fieldType || 'text'}
                                placeholder={inputProp.metadata?.placeholder || '...'}
                                value={inputProp?.value || ''}
                                error={inputProp?.error !== ''}
                                errorMessage={inputProp?.error}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    onInputPropertyChange(e.target.value, inputProp, index)
                                }}
                            />
                        </Grid>
                    )
                })}

                <Grid item xs={12} sm={6} md={3} className={`${moduleName}__buttons  ${moduleName}__buttons-save`}>
                    <Button
                        className="button"
                        type="button"
                        color="primary"
                        variant="contained"
                        disabled={!isFormValid}
                        onClick={() => {
                            const patch = heartbeatLocation && connectedLocation ? true : false
                            onSave(patch)
                        }}
                    >
                        SAVE
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const renderView = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <label className={`${moduleName}__label`}>Send data to</label>
                    <div>{connectedLocation?.provider.displayName}</div>
                </Grid>

                {inputsState.map((property: any, index: number) => {
                    const inputProp = inputsState[index]
                    return (
                        <Grid item xs={12} sm={6} md={3} key={`id_${index}`}>
                            <label className={`${moduleName}__label`}>{property.displayName}</label>
                            <div>{inputProp?.value}</div>
                        </Grid>
                    )
                })}

                <Grid item xs={12} sm={6} md={3} className={`${moduleName}__buttons ${moduleName}__buttons-edit`}>
                    <Button className="button" type="button" color="primary" variant="contained" onClick={onEdit}>
                        EDIT
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const renderMexicanScenario = () => {
        if (!heartbeatLocation) {
            return renderErrorMsg()
        }
        if (heartbeatLocation && connectedLocation === undefined) {
            return renderEdit()
        }
        if (heartbeatLocation && connectedLocation !== undefined && edit) {
            return renderEdit()
        }
        if (heartbeatLocation && connectedLocation !== undefined && !edit) {
            return renderView()
        }
        return ''
    }

    return (
        <div className={`${moduleName}`}>
            {isSaving && (
                <div className={`${moduleName}__loading-overlay`}>
                    <CircularProgress size={50} className={`${moduleName}__spinner`} />
                </div>
            )}
            <div className={`${moduleName}__location-name`}>{location.name}</div>
            {renderMexicanScenario()}
        </div>
    )
}

export default Location
