import * as React from 'react'
import classNames from 'classnames'

import { bindPracticeAgent, fetchPracticeAgents, savePractice } from '../actions'

import AgentMetrics from './AgentMetrics'
import ManageAgents from './ManageAgents'

import './FlowTab.sass'

export type FlowTabProps = {
    practice: Models.Practice
}

export type FlowTabDispatch = {
    fetchPracticeAgents: typeof fetchPracticeAgents
    bindPracticeAgent: typeof bindPracticeAgent
    savePractice: typeof savePractice
}

export type FlowTabState = {
    showManageAgents: boolean
}

type Props = FlowTabProps & FlowTabDispatch

class FlowTab extends React.Component<Props, FlowTabState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            showManageAgents: false,
        }
    }

    UNSAFE_componentWillMount() {
        this.props.fetchPracticeAgents(this.props.practice)
    }

    onToggleManageAgents = () => {
        this.setState({ showManageAgents: !this.state.showManageAgents })
    }

    render() {
        const { agents } = this.props.practice
        if (agents == null) {
            return <div>Loading...</div>
        }

        return (
            <div className={classNames('flow-tab')}>
                {!this.state.showManageAgents && (
                    <AgentMetrics practice={this.props.practice} agents={agents} onClose={this.onToggleManageAgents} />
                )}
                {this.state.showManageAgents && (
                    <ManageAgents
                        agents={agents}
                        practice={this.props.practice}
                        bindPracticeAgent={this.props.bindPracticeAgent}
                        onBack={this.onToggleManageAgents}
                        savePractice={this.props.savePractice}
                    />
                )}
            </div>
        )
    }
}

export default FlowTab
