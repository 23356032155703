export const mapWebCode = (webCode: ApiV2.Amplify.WebCode): Models.WebCode => {
    return {
        id: webCode.id,
        key: webCode.key,
        url: webCode.url,
        amplifyScript: webCode.amplify_script,
        active: webCode.active,
        customization: {
            welcome_messages: webCode.customization.welcome_messages,
            widget: webCode.customization.widget || {},
            widget_button: webCode.customization.widget_button || {},
            banned_ip_addresses: webCode.customization.banned_ip_addresses,
            direct_scheduling: webCode.customization.direct_scheduling || {},
        },
        practiceLocationIds: webCode.amplify_web_code_practice_location?.map((location: any) => {
            return {
                practiceLocationId: location.practice_location_id,
                enabled: location.enabled,
            }
        }),
        survey: webCode.survey,
        connect: null,
        connectSurveyId: webCode?.connect_survey_id,
        surveyMonkeyResponseId: webCode?.survey_monkey_response_id,
        practiceSpecialityType: webCode.practice_specialty_type,
    }
}

export const mapChatAlert = (chatAlert: Api.Amplify.ChatAlert): Models.ChatAlert => {
    return {
        id: chatAlert.id,
        created: chatAlert.created,
        updated: chatAlert.updated,
        practiceId: chatAlert.practice_id,
        creatorId: chatAlert.creator_id,
        alert: chatAlert.alert,
        status: chatAlert.status,
        expirationDate: chatAlert.expiration_date,
        orderNumber: chatAlert.order_number || 0,
        practiceLocationClosureId: chatAlert.practice_location_closure_id,
        websiteIds: chatAlert.website_ids,
    }
}

export const mapChatAlertTask = (chatAlertTask: Api.Amplify.ChatAlertTask): Models.ChatAlertTask => {
    return {
        ...mapChatAlert(chatAlertTask),
        marked: chatAlertTask.marked,
    }
}

export const mapRestoredAccount = (account: Api.Account): Api.Account => {
    if (account.account_status.value === 'deleted') {
        return {
            ...account,
            account_status: { value: 'active', id: 2, display: 'Active' },
        }
    }

    return account
}
