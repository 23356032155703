import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import ApiV2 from '../../../ApiV2'
import { Dashboards } from '../../../ApiV2/services/DataService'

import './MetabaseData.sass'

export type Props = {
    practice: Models.Practice
    source?: Dashboards
}

export type MetabaseDataState = {
    metabaseUrl?: string
}

const MetabaseData = ({ practice, source = Dashboards.AMPLIFY }: Props) => {
    const [metabaseUrl, setMetabaseUrl] = useState<string>('')

    useEffect(() => {
        ApiV2.Data.getDashboardUrl(practice.id, source).then((metabaseUrl: string) => {
            setMetabaseUrl(metabaseUrl)
        })
    }, [practice, source])

    if (!metabaseUrl) {
        return (
            <div className="analytics-loader">
                <CircularProgress size={50} />
            </div>
        )
    }

    return (
        <div>
            <iframe className="analytics-frame" title="Metabase URL" src={metabaseUrl} />
        </div>
    )
}

export default MetabaseData
