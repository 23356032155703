import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Switch, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import classNames from 'classnames'
import sortBy from 'lodash/sortBy'
import throttle from 'lodash/throttle'
import uniq from 'lodash/uniq'

import { RootState } from '../../appReducer'
import { CustomFieldType } from '../../models/enums'
import { moveDefaultLocationToTop } from '../../modules/shared/sortUtils'
import { fetchPracticeHasEmailNotificationSubscribers } from '../practices/actions'
import {
    fetchPracticeLocationAccounts,
    fetchPracticeLocationSubscribers,
    setNotificationsSelectedLocationId,
} from '../practices/v2actions'
import ConfirmModal from '../shared/confirm-modal/ConfirmModal'
import CopyText from '../shared/CopyText'
import CustomField from '../shared/custom-fields/CustomField'
import CustomMultiselectField from '../shared/custom-fields/CustomMultiselectField'
import InfoMessage from '../shared/info-message/info-message'
import Paginator from '../shared/Paginator'

import { createSubscriber, deleteSubscriber, saveSubscriber } from './actions'

import './NotificationManager.sass'

type NotificationManagerProps = {
    practice: Models.Practice
}

const NotificationManager = (props: NotificationManagerProps) => {
    const { practice } = props

    const practiceId = practice.id

    const sortedLocations = sortBy(practice.locations, l => l.name.toLowerCase()).sort(moveDefaultLocationToTop)

    const notificationsSelectedLocationId = useSelector(
        (state: RootState) => state.v2.practices.notificationsSelectedLocationId[practiceId],
    )

    const notificationsSelectedLocationIdExists =
        notificationsSelectedLocationId && Boolean(sortedLocations.find(l => l.id === notificationsSelectedLocationId))

    const [newSubscriber, setNewSubscriber] = useState<Api.Amplify.CreateSubscriber | undefined>(undefined)
    const [selectedLocationId, setSelectedLocationId] = useState<string>(
        notificationsSelectedLocationIdExists ? notificationsSelectedLocationId : sortedLocations[0].id,
    )

    const subscribers = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationSubscriber[selectedLocationId]?.list,
    )

    const subscribersFromStaff = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationAccounts[selectedLocationId]?.list,
    )
    const pagination = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationAccounts[selectedLocationId]?.pagination,
    )
    const loading = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationAccounts[selectedLocationId]?.loading || false,
    )
    const page = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationAccounts[selectedLocationId]?.page || 1,
    )

    const [searchPage, setSearchPage] = useState<number>(page)
    const [showDeleteSubscriberModal, setShowDeleteSubscriberModal] = useState<boolean>(false)
    const [subscriberToDelete, setSubscriberToDelete] = useState<Models.Subscriber | undefined>(undefined)
    const [initialFetch, setInitialFetch] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const dispatch = useDispatch()

    const searchForSubscribersFromStaff = useCallback(
        (practiceId: string, locationId: string, searchPage: number) => {
            dispatch(fetchPracticeLocationAccounts(practiceId, locationId, searchPage))
        },
        [dispatch],
    )

    const throttledSearchSubscribersFromStaff = useMemo(() => throttle(searchForSubscribersFromStaff, 300), [
        searchForSubscribersFromStaff,
    ])

    useEffect(() => {
        throttledSearchSubscribersFromStaff(practiceId, selectedLocationId, searchPage)
    }, [practiceId, selectedLocationId, searchPage, throttledSearchSubscribersFromStaff])

    useEffect(() => {
        dispatch(setNotificationsSelectedLocationId(practiceId, selectedLocationId))
    }, [selectedLocationId, practiceId, dispatch])

    useEffect(() => {
        if (!initialFetch) {
            dispatch(fetchPracticeLocationSubscribers(practice.id, { locationId: selectedLocationId, page: 1 }))
            dispatch(fetchPracticeLocationAccounts(practice.id, selectedLocationId, 1))
            setInitialFetch(true)
        }
    }, [initialFetch, practice, selectedLocationId, dispatch])

    useEffect(() => {
        if (selectedLocationId && initialFetch) {
            setIsLoading(true)
            dispatch(fetchPracticeLocationSubscribers(practice.id, { locationId: selectedLocationId, page: 1 }))
            dispatch(fetchPracticeLocationAccounts(practice.id, selectedLocationId, 1))
            setIsLoading(false)
        }
    }, [practice, selectedLocationId, initialFetch, dispatch])

    const canSaveNewSubscriber = () => {
        if (!newSubscriber) {
            return false
        }
        return newSubscriber.email.match(/.+@.+\..+/i) != null
    }

    const subscribersFromStaffFiltered = () => {
        if (subscribersFromStaff) {
            let emails: string[] = []
            subscribersFromStaff.forEach(user => {
                emails = uniq([...emails, user.username, ...user.emails])
            })
            return emails
        }
        return []
    }

    const onAddNewSubscriber = () => {
        setNewSubscriber({ location_id: '', email: '', active: true })
    }

    const onCancelNewSubscriber = () => {
        setNewSubscriber(undefined)
    }

    const onChangeSubscriberEmail = (email: string) => {
        if (newSubscriber) {
            setNewSubscriber({ ...newSubscriber, email })
        }
    }

    const onChangeSubscriberActive = (active: boolean) => {
        if (newSubscriber) {
            setNewSubscriber({ ...newSubscriber, active })
        }
    }

    const onSaveNewSubscriber = async () => {
        if (newSubscriber == null) {
            return
        }

        newSubscriber.location_id = selectedLocationId
        await dispatch(createSubscriber(practice, newSubscriber))
        dispatch(fetchPracticeHasEmailNotificationSubscribers(practice))
        setNewSubscriber(undefined)
    }

    const onSelectLocation = (locationId: string) => {
        setSelectedLocationId(locationId)
    }

    const onToggleSubscriber = async (subscriber: Models.Subscriber) => {
        await dispatch(saveSubscriber(practice, subscriber, { active: !subscriber.active }))
        dispatch(fetchPracticeHasEmailNotificationSubscribers(practice))
    }

    const onDeleteSubscriber = async () => {
        if (subscriberToDelete) {
            await dispatch(deleteSubscriber(practice, subscriberToDelete))
            dispatch(fetchPracticeHasEmailNotificationSubscribers(practice))
        }
        setShowDeleteSubscriberModal(false)
        setSubscriberToDelete(undefined)
    }

    const openDeleteSubscriberModal = (subscriber: Models.Subscriber) => {
        setShowDeleteSubscriberModal(true)
        setSubscriberToDelete(subscriber)
    }

    const closeDeleteSubscriberModal = () => {
        setShowDeleteSubscriberModal(false)
        setSubscriberToDelete(undefined)
    }

    const sortedSubscribers = () => {
        return subscribersFromStaffFiltered().sort((a, b) => a.localeCompare(b))
    }

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    return (
        <div className={classNames('notification-manager')}>
            <div className="header">
                <div className="title">Request Subscribers</div>
                <div className="locations">
                    <CustomMultiselectField
                        items={sortedLocations}
                        maxSelected={1}
                        selectedItems={[selectedLocationId]}
                        keyProperty="id"
                        displayProperty="name"
                        placeholder="Select Location"
                        label="Select Practice Location"
                        onSelectElement={(values: string[]) => onSelectLocation(values[0])}
                        search={sortedLocations?.length > 8}
                        searchPlaceholder="Search Location"
                    />
                </div>
            </div>
            <Table className="subscriber-list">
                <TableHead>
                    <TableRow className="header">
                        <TableCell className="id title">ID</TableCell>
                        <TableCell className="email title">Email</TableCell>
                        <TableCell className="active title" padding="none">
                            Notify?
                        </TableCell>
                        <TableCell className="actions" padding="none" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subscribers ? (
                        subscribers.filter(s => s.location_id === selectedLocationId).length === 0 ? (
                            <TableRow className="subscriber">
                                <TableCell colSpan={4}>
                                    <InfoMessage isShown={true}>
                                        There are no added request subscribers for this practice location.
                                    </InfoMessage>
                                </TableCell>
                            </TableRow>
                        ) : (
                            subscribers
                                .filter(s => s.location_id === selectedLocationId)
                                .map(subscriber => (
                                    <TableRow key={subscriber.id} className="subscriber">
                                        <TableCell className="id">
                                            <CopyText text={subscriber.id} />
                                        </TableCell>
                                        <TableCell className="email">{subscriber.email}</TableCell>
                                        <TableCell className="active">
                                            <Switch
                                                className="switch"
                                                checked={subscriber.active}
                                                value="switch"
                                                color="primary"
                                                onChange={() => onToggleSubscriber(subscriber)}
                                            />
                                        </TableCell>
                                        <TableCell className="actions">
                                            <Button
                                                className="delete"
                                                variant="text"
                                                onClick={() => openDeleteSubscriberModal(subscriber)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                        )
                    ) : (
                        <div className="circular-progress-loader">
                            <CircularProgress size={70} color="primary" variant="indeterminate" />
                        </div>
                    )}
                    {newSubscriber && (
                        <TableRow className="subscriber">
                            <TableCell className="id" />
                            <TableCell className="email">
                                <CustomField
                                    customFieldType={CustomFieldType.TEXTAREA}
                                    value={newSubscriber.email}
                                    rows={1}
                                    autoFocus={true}
                                    autoSize={true}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        onChangeSubscriberEmail(e.target.value)
                                    }}
                                />
                            </TableCell>
                            <TableCell className="active" padding="none">
                                <Switch
                                    className="switch"
                                    checked={newSubscriber.active}
                                    value="switch"
                                    color="primary"
                                    onChange={() => onChangeSubscriberActive(!newSubscriber.active)}
                                />
                            </TableCell>
                            <TableCell className="actions" padding="none">
                                <Button
                                    className="add"
                                    variant="text"
                                    disabled={!canSaveNewSubscriber()}
                                    onClick={onSaveNewSubscriber}
                                >
                                    Add
                                </Button>
                                <Button className="cancel" variant="text" onClick={onCancelNewSubscriber}>
                                    Cancel
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    {subscribersFromStaffFiltered().length > 0 && (
                        <TableRow key="subscribersFromStaff" className="subscriber">
                            <TableCell className="title" colSpan={4}>
                                Subscribers from practice staff{' '}
                                {isLoading && (
                                    <div className="circular-progress-loader">
                                        <CircularProgress size={70} color="primary" variant="indeterminate" />
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {sortedSubscribers().map((email, index) => (
                        <TableRow key={index} className="subscriber-list-row">
                            <TableCell className="list-cell id">&nbsp;</TableCell>
                            <TableCell className="list-cell email">{email}</TableCell>
                            <TableCell className="list-cell active">&nbsp;</TableCell>
                            <TableCell className="list-cell actions">&nbsp;</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {pagination?.allRows && pagination?.allRows > 8 && (
                <div className="notifications-paginator-wrapper">
                    <Paginator currentPage={searchPage} paginationInfo={pagination} selectPage={handlePageChange} />
                </div>
            )}
            {loading && (
                <div className="circular-progress-loader-body">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
            <ConfirmModal
                title={`Are you sure you want to delete this Subscriber?`}
                subtitle=""
                discardText="CANCEL"
                confirmText="DELETE SUBSCRIBER"
                open={showDeleteSubscriberModal}
                onClose={closeDeleteSubscriberModal}
                onDiscard={closeDeleteSubscriberModal}
                onConfirm={onDeleteSubscriber}
            />
            <div className="notifications-footer" onClick={onAddNewSubscriber}>
                <div className={classNames('button', { disabled: Boolean(newSubscriber) })}>
                    <i className="material-icons add-icon">add</i> Add Subscriber
                </div>
            </div>
        </div>
    )
}

export default NotificationManager
