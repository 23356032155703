import React, { useEffect, useRef, useState } from 'react'
import Grid, { GridClassKey } from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'

import { QuestionOption } from '../../../../../models/PracticeSurvey'

import { useHeaderDimensions } from './useHeaderDimensions'

import './ProceduresTabs.sass'

type ProceduresTabsProps = {
    children: React.ReactNode | React.ReactNode[]
    edit: boolean
    onChange?: (optionId: string) => void
}

const moduleName = 'survey-procedures-tabs'

type GridClassOverrides = {
    readonly [key in GridClassKey]?: string
}

const gridClassOverrides: GridClassOverrides = {
    item: `${moduleName}__grid-item`,
}

const ProceduresTabs = (props: ProceduresTabsProps) => {
    const { children, edit } = props
    const [tabHeader, setTabHeader] = useState<string[]>([])
    const [visibleHeader, setVisibleHeader] = useState<string[]>([])
    const [childContent, setChildContent] = useState<any>({})
    const [active, setActive] = useState<string>('')
    const [numberOfSlides, setNumberOfSlides] = useState<number>(0)
    const [slideNum, setSlideNum] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(0)

    const headerRef = useRef<HTMLHeadingElement>()
    const { width: headerWidth } = useHeaderDimensions(headerRef)

    useEffect(() => {
        const headers: string[] = []
        const childCnt = {}
        React.Children.forEach(children, element => {
            if (!React.isValidElement(element)) return
            const { name } = element.props
            headers.push(name)
            childCnt[name] = element.props.children
        })
        setTabHeader(headers)
        setVisibleHeader(headers)
        if (active === '') {
            setActive(headers[0])
        }
        setChildContent({ ...childCnt })
    }, [props, children, active])

    useEffect(() => {
        const optionsLength = tabHeader.reduce((pr, curr) => {
            return Number(pr + curr.length)
        }, 0)
        const headerLength = tabHeader.length
        const optionsTotalWidth = headerLength * 12 + optionsLength * 8
        const ratio = optionsTotalWidth / headerWidth
        const numOfSlidesCalc = Math.floor(ratio)
        const leftOver = ratio / tabHeader.length
        const pageSize = Math.round(1 / leftOver) < headerLength ? Math.round(1 / leftOver) : headerLength
        const numOfSlides = pageSize * numOfSlidesCalc >= headerLength ? numOfSlidesCalc - 1 : numOfSlidesCalc
        setNumberOfSlides(numOfSlides)
        setPageSize(pageSize)
        setSlideNum(0)
    }, [headerWidth, tabHeader])

    useEffect(() => {
        if (tabHeader && pageSize > 0) {
            const start = pageSize * slideNum
            const lastIndex = tabHeader.length - 1
            const end = pageSize * (slideNum + 1) - 1 < tabHeader.length ? pageSize * (slideNum + 1) : lastIndex + 1
            setVisibleHeader(tabHeader.slice(start, end))
        }
    }, [slideNum, numberOfSlides, tabHeader, pageSize])

    const changeTab = (name: string) => {
        setActive(name)
    }

    const leftClick = () => {
        setSlideNum(slideNum > 0 ? slideNum - 1 : 0)
    }

    const rightClick = () => {
        setSlideNum(slideNum < numberOfSlides ? slideNum + 1 : numberOfSlides - 1)
    }

    const handleChangeOption = (optionId: string) => {
        if (props.onChange) {
            props.onChange(optionId)
        }
    }

    return (
        <div className={`${moduleName}`}>
            {numberOfSlides !== 0 && slideNum !== 0 && (
                <button className={`${moduleName}__slide-btn ${moduleName}__slide-btn--left`} onClick={leftClick}>
                    <Icon>keyboard_arrow_left</Icon>
                </button>
            )}
            <div className={`${moduleName}__tab-header`} ref={headerRef as React.RefObject<any>}>
                {visibleHeader.map(item => (
                    <div onClick={() => changeTab(item)} key={item} className={item === active ? 'active' : ''}>
                        {item}
                    </div>
                ))}
            </div>
            {numberOfSlides !== 0 && slideNum < numberOfSlides && (
                <button
                    className={`${moduleName}__slide-btn ${moduleName}__slide-btn--right ${moduleName}__slide-btn--${slideNum}`}
                    onClick={rightClick}
                >
                    <Icon>keyboard_arrow_right</Icon>
                </button>
            )}
            <div className={`${moduleName}__tab-content`}>
                {Object.keys(childContent).map(tabName => {
                    if (tabName === active) {
                        return (
                            <Grid container={true} key={tabName} spacing={1.5} className={moduleName}>
                                {childContent[tabName].map((option: QuestionOption) => (
                                    <Grid item={true} key={option.id} md={2} sm={3} xs={6} classes={gridClassOverrides}>
                                        {edit ? (
                                            <div
                                                className={classNames(`${moduleName}__tile ${moduleName}--clickable`, {
                                                    [`${moduleName}--selected`]: option.selected,
                                                })}
                                                onClick={() => handleChangeOption(option.id)}
                                            >
                                                {option.display_name}
                                            </div>
                                        ) : (
                                            <div
                                                className={classNames(`${moduleName}__tile`, {
                                                    [`${moduleName}--selected`]: option.selected,
                                                })}
                                            >
                                                {option.display_name}
                                            </div>
                                        )}
                                    </Grid>
                                ))}
                            </Grid>
                        )
                    } else {
                        return null
                    }
                })}
            </div>
        </div>
    )
}

export default ProceduresTabs
