import { connect } from 'react-redux'

import { RootState } from '../../appReducer'
import { downloadLicense, fetchAgents, fetchDexVoiceLicenses } from '../dexvoice/actions'
import { toggleDexVoice } from '../practices/actions'

import DexVoiceDiagnostics, { DexVoiceDiagnosticsProps } from './DexVoiceDiagnostics'
import { PracticeDiagnosticsParams } from './PracticeDiagnostics'

const mapStateToProps = (state: RootState, props: PracticeDiagnosticsParams): DexVoiceDiagnosticsProps => ({
    practice: state.practices.practices[props.match.params.practiceId],
    agents: state.dexVoice.agents[props.match.params.practiceId],
    licenses: state.dexVoice.licenses[props.match.params.practiceId],
})

const DexVoiceDiagnosticsContainer = connect(mapStateToProps, {
    fetchAgents,
    fetchDexVoiceLicenses,
    toggleDexVoice,
    downloadLicense,
})(DexVoiceDiagnostics)

export default DexVoiceDiagnosticsContainer
