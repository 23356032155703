import React, { Fragment } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Switch as MuiSwitch } from '@mui/material'
import { withStyles } from '@mui/styles'

import { CustomFieldType } from '../../../../../../models/enums'
import { OtherOption, QuestionOption, SurveySectionQuestion } from '../../../../../../models/PracticeSurvey'
import CustomField from '../../../../../shared/custom-fields/CustomField'
import { formatDate } from '../../shared/utils'

import './SelectMulti.sass'

export type SelectMultiComponentType = 'default' | 'tabs'

const Switch = withStyles({
    root: {
        marginRight: 8,
    },
    checked: {
        '&:hover': {
            backgroundColor: 'rgba(52, 107, 199, 0.1) !important',
        },
    },
})(MuiSwitch as React.ComponentType<any>)

interface Props {
    question: SurveySectionQuestion
    onChange: (options: QuestionOption[], other_option?: OtherOption) => void
}

const SelectMultiDefault = ({
    question: { question_text, variants, answer_schema, isRequired, isDirty, isValid, error, errorMessage },
    onChange,
}: Props) => {
    const options = answer_schema?.options || []
    const other_option = answer_schema?.other_option
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const optionId = event.target.value
        const nextOptions = options.map((option: QuestionOption) => {
            if (optionId === option.id) {
                return {
                    ...option,
                    selected: checked,
                }
            }
            return option
        })

        const nextOther = other_option ? { ...other_option } : undefined
        if (nextOther && optionId === nextOther?.id) {
            nextOther.selected = checked
        }
        onChange(nextOptions, nextOther)
    }

    const handleChangeOtherInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (other_option) {
            const nextOther = { ...other_option }
            nextOther.input_field.value = event.target.value
            onChange(options, nextOther)
        }
    }

    const hasError = (isDirty && !isValid) || error

    const extraClasses = variants?.map(variant => `question-select-multi--${variant}`).join(' ') ?? ''

    const CustomCheckbox = ({ checked, name, label }: { checked: boolean; name: string; label: string }) => {
        if (variants?.includes('toggle')) {
            return (
                <div className="question-select-multi__checkbox">
                    <Switch checked={checked} onChange={handleChange} value={name} color="primary" />
                    <span className="question-select-multi__toggle-label">{label}</span>
                </div>
            )
        }
        return (
            <FormControlLabel
                className="question-select-multi__checkbox"
                control={
                    <Checkbox color="primary" checked={checked} onChange={handleChange} name={name} value={name} />
                }
                label={label}
            />
        )
    }

    return (
        <div className={`question-select-multi ${extraClasses}`}>
            <FormGroup
                row={variants?.includes('row')}
                aria-label={question_text}
                className="question-select-multi__options"
            >
                {options.map((option: QuestionOption) => {
                    const optionLabel = option.metadata?.date
                        ? `${option.display_name} - ${formatDate(option.metadata?.date)}`
                        : option.display_name
                    return (
                        <CustomCheckbox
                            key={option.id}
                            checked={option.selected ?? false}
                            name={option.id}
                            label={optionLabel}
                        />
                    )
                })}
            </FormGroup>
            {other_option && (
                <Fragment>
                    <CustomCheckbox
                        checked={other_option.selected ?? false}
                        name={other_option.id}
                        label={other_option.display_name}
                    />
                </Fragment>
            )}

            {other_option?.selected && other_option?.input_field && (
                <div className="question-select-multi__other">
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        value={other_option.input_field.value ?? ''}
                        required={isRequired}
                        error={hasError}
                        onChange={handleChangeOtherInput as (event: React.ChangeEvent<Element>) => void}
                    />
                </div>
            )}
            {hasError && <div className="question-select-multi__error">{errorMessage}</div>}
        </div>
    )
}

export default SelectMultiDefault
