import React, { useEffect, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { LocationDescriptor } from 'history'

import Api from '../../Api'
import { tryCompletePasswordReset } from '../../appActions'
import { useAppDispatch } from '../../util/useAppDispatch'
import CopyText from '../shared/CopyText'
import { SimplifeyeVyneLogo } from '../shared/svgIcons'

import LinkExpired from './LinkExpired'

import './PasswordReset.sass'

const VERSION = `${process.env.REACT_APP_GIT_HASH}`

type CompletePasswordResetState = {
    password: string
    confirm: string
    errorMessage?: string
    redirect: boolean
    isLoading: boolean
    isResetCodeValid: boolean
    isVerifying?: boolean
}

type CompletePasswordResetDispatch = {
    tryCompletePasswordReset: typeof tryCompletePasswordReset
}
interface MatchParams {
    code: string
}

type Props = CompletePasswordResetState & CompletePasswordResetDispatch & RouteComponentProps<MatchParams>

type Location = {
    state: {
        from: string
    }
}

const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{12,}$/

const PasswordReset = (props: Props) => {
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [redirect, setRedirect] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [isResetCodeValid, setisResetCodeValid] = useState(false)
    const [isVerifying, setisVerifying] = useState(false)
    const location = props.location as Location

    const dispatch = useAppDispatch()

    const {
        match: {
            params: { code },
        },
    } = props

    useEffect(() => {
        const checkForExpiredResetCode = async () => {
            const { isValid } = await Api.checkForExpiredResetCode(code)
            return isValid
        }

        setisVerifying(true)
        const isValid = checkForExpiredResetCode()
        setisResetCodeValid(Boolean(isValid))
        setisVerifying(false)
    }, [code])

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const matches = password === confirm
        if (!matches) {
            setErrorMessage('Passwords don’t match.')
            return
        }

        const isStrongEnough = PASSWORD_REGEX.test(password)

        if (!isStrongEnough) {
            setErrorMessage(
                'Password must be at least 12 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
            )
            return
        }

        if (isStrongEnough && matches) {
            setErrorMessage('')
        }

        setisLoading(true)

        dispatch(tryCompletePasswordReset(code, password, confirm))
            .then(() => {
                setRedirect(true)
            })
            .catch((err: Error) => {
                if (err.message.includes('cannot be the same as the current password')) {
                    setErrorMessage('New password cannot be the same as the current password.')
                } else {
                    window.alert(err.message || err)
                }

                setisLoading(false)
            })
    }

    if (redirect) {
        const to: LocationDescriptor = (location && location.state && location.state.from) || {
            pathname: '/login',
        }

        return <Redirect to={to} />
    }

    if (isVerifying) {
        return <div />
    }

    if (!isResetCodeValid) {
        return <LinkExpired />
    }

    return (
        <div className={classNames('pst-password-reset')}>
            <div className="container">
                <div className="content">
                    <div className="banner">
                        <SimplifeyeVyneLogo />
                    </div>
                    <div className="error">{errorMessage}</div>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="header">
                            <span className="text">Set Your Password</span>
                        </div>
                        <div className="fields">
                            <div className="field">
                                <span className="text">Please set your password for the Product Support Tool</span>
                            </div>
                            <div className="field">
                                <TextField
                                    autoFocus={true}
                                    fullWidth={true}
                                    label="Please enter your password"
                                    type="password"
                                    value={password}
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </div>
                            <div className="field">
                                <TextField
                                    fullWidth={true}
                                    label="Please confirm your password"
                                    type="password"
                                    value={confirm}
                                    onChange={event => setConfirm(event.target.value)}
                                />
                            </div>
                            <div className="submit-field">
                                {isLoading && <CircularProgress />}
                                {!isLoading && (
                                    <button className="submit-button" type="submit" disabled={!password || !confirm}>
                                        <div className="text">Submit Password</div>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="version-text">
                <CopyText text={VERSION}>{VERSION}</CopyText>
            </div>
        </div>
    )
}

export default PasswordReset
