import * as React from 'react'
import { Grid } from '@mui/material'
import moment from 'moment-timezone'

import './ReferralHeader.sass'

export type Props = {
    practiceName?: string
    referral?: ModelsV2.Referrals.ReferralV2
    actions: React.ReactNode
    referralStatus?: ModelsV2.Referrals.ReferralV2Status
}

function formatDateTime(date: string): string {
    const timezone = moment.tz.guess()
    return moment(date)
        .tz(timezone)
        .format('MM/DD/YYYY h:mm A z')
}

export default function ReferralHeader(props: Props) {
    const { actions, practiceName, referral, referralStatus } = props

    return (
        <Grid container={true} spacing={0} className="referral-header">
            <Grid item={true} xs={10} className="title-wrapper">
                <div className="practice-title">
                    <div className="title">{practiceName}</div>
                    {referral?.is_test && <div className="test-referral"> [TEST]</div>}
                </div>
                <div className="datetime-status">
                    {referral?.created && (
                        <div className="referral-date">Created: {formatDateTime(referral?.created)}</div>
                    )}
                </div>
                <div className="datetime-status">
                    {referral?.updated && (
                        <div className="referral-date">Last Edit: {formatDateTime(referral?.updated)}</div>
                    )}
                    {referralStatus?.display && (
                        <div className="referral-status">
                            <strong>Request Status: </strong>
                            {referralStatus.display}
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item={true} xs={2}>
                {actions}
            </Grid>
        </Grid>
    )
}
