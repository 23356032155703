import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'

import { RootState } from '../../../../appReducer'
import { SearchBar } from '../../../shared/custom-fields/SearchBar'

interface Props {
    practiceId: string
    patientQuery: string
    setPatientQuery: (value: string) => void
    setSearchPage: (value: number) => void
}

const PatientSearchBar = ({ practiceId, patientQuery, setPatientQuery, setSearchPage }: Props) => {
    const list = useSelector((state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.list || [])
    const loading = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.loading || false,
    )

    const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setPatientQuery(value)
        setSearchPage(1)
    }

    const handleClearSearch = () => {
        setPatientQuery('')
        setSearchPage(1)
    }

    const throttledHandleSearch = throttle(handleSearch, 1000, {
        trailing: false,
    })

    return (
        <SearchBar
            value={patientQuery}
            onClear={handleClearSearch}
            onChange={throttledHandleSearch}
            placeholder="Search by Patient Name"
            isMessageShown={patientQuery.length > 0 && !loading && list.length === 0}
        />
    )
}

export type PatientSearchBarProps = Props
export default PatientSearchBar
