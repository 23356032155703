import Api from 'Api'
import ApiV2 from 'ApiV2'

import { mapAccountsV2ToV1 } from '../../ApiV2/mappers/v2/accounts'

export type ReceiveAdmin = {
    type: 'RECEIVE_ADMIN'
    admin: Api.Account
}

export type ReceiveAllAdmins = {
    type: 'RECEIVE_ALL_ADMINS'
    admins: Array<Api.Account>
}

export type ReceiveAdmins = {
    type: 'RECEIVE_ADMINS'
    admins: Array<Api.Account>
    paginationInfo?: Api.PaginationInfo
}

export type ReceiveAdminPermissions = {
    type: 'RECEIVE_ADMIN_PERMISSIONS'
    permissions: Array<Api.Permission>
}

export function receiveAdmin(admin: Api.Account): ReceiveAdmin {
    return {
        type: 'RECEIVE_ADMIN',
        admin,
    }
}

export function receiveAllAdmins(admins: Api.Account[]): ReceiveAllAdmins {
    return {
        type: 'RECEIVE_ALL_ADMINS',
        admins,
    }
}

export function receiveAdmins(admins: Api.Account[], paginationInfo?: Api.PaginationInfo): ReceiveAdmins {
    return {
        type: 'RECEIVE_ADMINS',
        admins,
        paginationInfo,
    }
}

export function receiveAdminPermissions(permissions: Array<Api.Permission>): ReceiveAdminPermissions {
    return {
        type: 'RECEIVE_ADMIN_PERMISSIONS',
        permissions,
    }
}

export function fetchAdmins(list: Api.AdminListType, searchTerms: Api.Amplify.AdminUsersSearchTerms = {}): any {
    return async (dispatch: any) => {
        const { data: admins, paginationInfo } = await Api.Admin.list(list, searchTerms)
        await dispatch(receiveAdmins(admins, paginationInfo))
    }
}

export function fetchAdminsV2(list: Api.AdminListType, searchTerms: ApiV2.Admin.AdminUsersSearchTerms = {}): any {
    return async (dispatch: any) => {
        const { data: admins, metadata } = await ApiV2.Admin.list(list, searchTerms)
        await dispatch(receiveAdmins(mapAccountsV2ToV1(admins), metadata?.pagination_info))
    }
}

export function fetchAllAdminsV2(list: Api.AdminListType): any {
    return async (dispatch: any) => {
        const { data: admins } = await ApiV2.Admin.listAll(list, 1)
        await dispatch(receiveAllAdmins(mapAccountsV2ToV1(admins)))
    }
}

export function fetchAdminPermissions(): any {
    return async (dispatch: any) => {
        const permissions = await Api.Admin.permissionList()
        await dispatch(receiveAdminPermissions(permissions))
    }
}

export function createAdmin(values: Api.AccountCreate): any {
    return async (dispatch: any) => {
        const admin = await Api.Admin.postAdminCreate(values)
        await dispatch(receiveAdmin(admin))
    }
}

export function saveAdmin(admin: Models.Account, updates: Api.AccountUpdate): any {
    return async (dispatch: any) => {
        const updated = await Api.Admin.putAdminUpdate(admin, updates)
        await dispatch(receiveAdmin(updated))
    }
}

export function sendPasswordReset(admin: Models.Account): any {
    return async (dispatch: any) => {
        await Api.Admin.postPasswordReset(admin)
    }
}

export function unlockAccount(admin: Models.Account): any {
    return async (dispatch: any) => {
        try {
            const unlockedAccount = await Api.Admin.unlockAccount(admin)
            await dispatch(receiveAdmin(unlockedAccount))
        } catch (e) {
            return e
        }
    }
}
