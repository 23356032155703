import React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import SelfSchedulingModel from '../../../../models/SelfScheduling'

import './SelfSchedulingAdditionalComments.sass'

export type SelfSchedulingAdditionalCommentsProps = {
    additionalComments: Models.SelfSchedulingAdditionalComments
    updateAdditionalComments: (additionalComments: Models.SelfSchedulingAdditionalComments) => void
}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

const radioClassOverrides: RadioClassOverrides = {
    root: 'radio-root',
}

const moduleName = 'self-scheduling-additional-comments'

function SelfSchedulingAdditionalComments(props: SelfSchedulingAdditionalCommentsProps) {
    const { additionalComments = SelfSchedulingModel.defaultAdditionalComments } = props

    function updateAdditionalComments(e: React.ChangeEvent<HTMLInputElement>) {
        props.updateAdditionalComments(e.target.value as Models.SelfSchedulingAdditionalComments)
    }

    return (
        <div className={moduleName}>
            <h3 className={`${moduleName}__title`}>Additional Information</h3>
            <div className={`${moduleName}__radio-buttons-wrapper`}>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="additional-info-required"
                        color="primary"
                        checked={additionalComments === 'required'}
                        onChange={updateAdditionalComments}
                        name="radio-widget-style"
                        value="required"
                        classes={radioClassOverrides}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="additional-info-required">
                            <span className={`${moduleName}__label-text`}>Required</span>
                        </label>
                    </div>
                </div>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="additional-info-optional"
                        color="primary"
                        checked={additionalComments === 'optional'}
                        onChange={updateAdditionalComments}
                        name="radio-widget-style"
                        value="optional"
                        classes={radioClassOverrides}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="additional-info-optional">
                            <span className={`${moduleName}__label-text`}>Optional</span>
                        </label>
                    </div>
                </div>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="additional-info-none"
                        color="primary"
                        checked={additionalComments === 'none'}
                        onChange={updateAdditionalComments}
                        name="radio-widget-style"
                        value="none"
                        classes={radioClassOverrides}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="additional-info-none">
                            <span className={`${moduleName}__label-text`}>None</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingAdditionalComments
