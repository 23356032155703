import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import CustomToggle from 'modules/shared/custom-fields/CustomToggle'

interface Props {
    hasStripeAccount: boolean
    paymentInformationStatus: string
    onPaymentInformationStatusChange: (value: string) => void
    hasDirectSchedulingFields: boolean
    paymentAmount: string
    onPaymentAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const paymentInformationOptions: Array<{ name: string; value: string }> = [
    { name: 'Never', value: '3' },
    { name: 'Optional', value: '1' },
    { name: 'Required', value: '2' },
]

const LocationPaymentInformationForm = ({
    hasStripeAccount,
    paymentInformationStatus,
    onPaymentInformationStatusChange,
    hasDirectSchedulingFields,
    paymentAmount,
    onPaymentAmountChange,
}: Props) => (
    <>
        <div className="connect-info-heading">Connect Info</div>
        {hasStripeAccount ? (
            <>
                <div className="connect-info-question-heading">
                    Capture payment information before allowing a patient into the virtual waiting room?
                </div>
                <div className="connect-info-question">
                    <div className="payment-information-toggle">
                        <CustomToggle
                            options={paymentInformationOptions.map(pio => ({
                                text: pio.name,
                                onClick: () => onPaymentInformationStatusChange(pio.value),
                                selected: paymentInformationStatus === pio.value,
                            }))}
                        />
                    </div>
                    <div className="payment-amount">
                        <TextField
                            className="payment-amount-field"
                            required={hasDirectSchedulingFields}
                            value={paymentAmount}
                            disabled={!Boolean(paymentInformationStatus)}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            placeholder="Payment Amount"
                            onChange={onPaymentAmountChange}
                            variant="standard"
                        />
                    </div>
                </div>{' '}
            </>
        ) : (
            <div className="connect-info-question-heading">No Stripe Account detected</div>
        )}
    </>
)

export default LocationPaymentInformationForm
