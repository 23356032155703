import React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import SelfSchedulingModel from '../../../../models/SelfScheduling'

import './SelfSchedulingButtonSize.sass'

export type SelfSchedulingButtonSizeProps = {
    size: Models.SelfSchedulingButtonSize
    updateSize: (size: Models.SelfSchedulingButtonSize) => void
}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

const radioClassOverrides: RadioClassOverrides = {
    root: 'radio-root',
}

const moduleName = 'self-scheduling-button-size'

function SelfSchedulingButtonSize(props: SelfSchedulingButtonSizeProps) {
    const { size = SelfSchedulingModel.defaultButtonSize } = props

    function updateSize(e: React.ChangeEvent<HTMLInputElement>) {
        props.updateSize(e.target.value as Models.SelfSchedulingButtonSize)
    }

    return (
        <div className={moduleName}>
            <h3 className="webcode-section-title">Size:</h3>
            <div className={`${moduleName}__wrapper`}>
                <Radio
                    id="button-size-large"
                    color="primary"
                    checked={size === 'large'}
                    onChange={updateSize}
                    name="radio-widget-style"
                    value="large"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__label-wrapper`}>
                    <label htmlFor="button-size-large">
                        <span className={`${moduleName}__label-text`}>Large (min width = 277)</span>
                    </label>
                </div>
            </div>
            <div className={`${moduleName}__wrapper`}>
                <Radio
                    id="button-size-medium"
                    color="primary"
                    checked={size === 'medium'}
                    onChange={updateSize}
                    name="radio-widget-style"
                    value="medium"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__label-wrapper`}>
                    <label htmlFor="button-size-medium">
                        <span className={`${moduleName}__label-text`}>Medium (min width = 236)</span>
                    </label>
                </div>
            </div>
            <div className={`${moduleName}__wrapper`}>
                <Radio
                    id="button-size-small"
                    color="primary"
                    checked={size === 'small'}
                    onChange={updateSize}
                    name="radio-widget-style"
                    value="small"
                    classes={radioClassOverrides}
                />
                <div className={`${moduleName}__label-wrapper`}>
                    <label htmlFor="button-size-small">
                        <span className={`${moduleName}__label-text`}>Small (min width = 209)</span>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingButtonSize
