import React, { useMemo, useState } from 'react'
import Tab, { TabClassKey } from '@mui/material/Tab'
import Tabs, { TabsClassKey } from '@mui/material/Tabs'
import Fuse from 'fuse.js'
import _ from 'lodash'

import AllChats from './AllChats'
import ChatCard from './ChatCard'
import ClaimedChats from './ClaimedChats'

import './ClaimedChatsList.sass'

export type ClaimedChatsListProps = {
    list: string[]
    chats: { [id: string]: Models.ChatMetadata }
    claimedChats: { [channel: string]: Models.ChatMetadata }
    myClaimedChats: Models.ChatMetadata[]
    selectedChatsIds: string[]
    search: string
    claimChat: (chat: Models.ChatMetadata) => void
    scrollToChat: (chatIndex: number) => void
    attachChats: (chat: Models.ChatMetadata[]) => Models.ChatCenterSelectedChat[]
}

const moduleName = 'claimed-chats-list'

type TabClassOverrides = {
    readonly [key in TabClassKey]?: string
}
type TabsClassOverrides = {
    readonly [key in TabsClassKey]?: string
}
const tabClassOverrides: TabClassOverrides = {
    root: `${moduleName}__tab`,
    // labelContainer: `${moduleName}__tab--label-container`,
    selected: `${moduleName}__tab--selected`,
}
const tabsClassOverrides: TabsClassOverrides = {
    root: `${moduleName}__tab-wrapper`,
}

const FUSE_OPTIONS = {
    caseSensitive: false,
    shouldSort: true,
    threshold: 0.25,
    maxPatternLength: 12,
    minMatchCharLength: 3,
    keys: ['patientName', 'practice.name', 'ip', 'claimee.firstName', 'claimee.lastName'],
}
enum ChatTab {
    AllClaimed = 'AllClaimed',
    MyClaimed = 'MyClaimed',
}

const ClaimedChatsList = ({
    list,
    chats,
    claimedChats,
    myClaimedChats,
    selectedChatsIds,
    search,
    claimChat,
    scrollToChat,
    attachChats,
}: ClaimedChatsListProps) => {
    const [tab, setTab] = useState<ChatTab>(ChatTab.MyClaimed)

    const allClaimedChats = useMemo(() => {
        const allowedStatuses: Models.ChatStatusValue[] = ['inactive', 'idle', 'claimed', 'patient_closed']
        const allChatsIds = list.filter(c => allowedStatuses.includes(chats[c].status.value))
        const filteredChatsByStatuses = _.filter(chats, c => allChatsIds.includes(c.id))
        const filteredChats = filteredChatsByStatuses.filter(c => c.claimee)

        return _.orderBy(filteredChats, ['created'], ['desc'])
    }, [list, chats])

    const fuse = useMemo(() => {
        const allChats = _.unionBy(Object.values(chats), Object.values(claimedChats), c => c.id).filter(chat =>
            Boolean(chat.claimee),
        )

        return new Fuse(allChats, FUSE_OPTIONS)
    }, [chats, claimedChats])

    const onChangeTab = (tab: ChatTab) => {
        setTab(tab)
    }
    const onClaimChat = (chat: Models.ChatMetadata) => {
        claimChat(chat)
    }
    const onSelectChat = (selectedChatId?: string) => {
        const chat = chats[`${selectedChatId}`]
        const selectedChats = attachChats([chat])

        const chatIndex = selectedChats.findIndex(({ id }) => id === selectedChatId)

        if (chatIndex >= 0) {
            scrollToChat(chatIndex)
        }
    }

    const renderChats = () => {
        if (search) {
            const fuseResults: Models.ChatMetadata[] = fuse.search(search)

            if (fuseResults.length === 0) {
                return <div className={`${moduleName}__no-results`}>No results</div>
            }

            return (
                <div className="claimed-chats">
                    {fuseResults.map((chat: Models.ChatMetadata) => {
                        return (
                            <ChatCard
                                key={chat.id}
                                chat={chat}
                                onSelect={onSelectChat}
                                onButton={chat.claimee ? undefined : onClaimChat}
                            />
                        )
                    })}
                </div>
            )
        }

        switch (tab) {
            case ChatTab.MyClaimed: {
                return (
                    <ClaimedChats
                        claimedChats={myClaimedChats}
                        onSelectChat={onSelectChat}
                        selectedChatsIds={selectedChatsIds}
                    />
                )
            }
            case ChatTab.AllClaimed: {
                return (
                    <AllChats
                        allClaimedChats={allClaimedChats}
                        onSelectChat={onSelectChat}
                        selectedChatsIds={selectedChatsIds}
                    />
                )
            }
            default: {
                return <div />
            }
        }
    }

    return (
        <div className={moduleName}>
            {!search && (
                <Tabs
                    value={tab}
                    onChange={(e, value) => onChangeTab(value)}
                    centered={true}
                    classes={tabsClassOverrides}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="inherit"
                >
                    <Tab value={ChatTab.MyClaimed} label={<span>My Claimed</span>} classes={tabClassOverrides} />
                    <Tab value={ChatTab.AllClaimed} label={<span>All Claimed</span>} classes={tabClassOverrides} />
                </Tabs>
            )}
            {renderChats()}
        </div>
    )
}

export default ClaimedChatsList
