import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'

import { RootState } from '../../../../appReducer'
import { convertNumCentsToDollars } from '../../../../util/formatCurrency'
import { getPracticePatientsFinancialData } from '../v2actions'

import './ProductionValue.sass'

interface Props {
    practiceId: string
}

const ProductionValue = ({ practiceId }: Props) => {
    const data = useSelector(
        (state: RootState) => state.v2.practicePatients.practicePatientsFinancialData?.[practiceId]?.data || null,
    )
    const loading = useSelector(
        (state: RootState) => state.v2.practicePatients.practicePatientsFinancialData?.[practiceId]?.loading || false,
    )

    const loadingPatientsTable = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.loading || false,
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticePatientsFinancialData(practiceId))
        }
    }, [dispatch, practiceId])

    return (
        <div className="production-values">
            <h2 className="production-values__title">Total Production Values</h2>
            <div className="production-values__table">
                <div className="production-values__cell">
                    <div className="production-values__cell-title">First 30 Days</div>
                    <div className="production-values__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_30_days_value) * 100, true, '$')}
                    </div>
                    <div className="production-values__cell-title">Last 30 Days</div>
                    <div className="production-values__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_30_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="production-values__cell">
                    <div className="production-values__cell-title">First 60 Days</div>
                    <div className="production-values__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_60_days_value) * 100, true, '$')}
                    </div>
                    <div className="production-values__cell-title">Last 60 Days</div>
                    <div className="production-values__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_60_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="production-values__cell">
                    <div className="production-values__cell-title">First 90 Days</div>
                    <div className="production-values__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_90_days_value) * 100, true, '$')}
                    </div>
                    <div className="production-values__cell-title">Last 90 Days</div>
                    <div className="production-values__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_90_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="production-values__cell">
                    <div className="production-values__cell-title">First 360 Days</div>
                    <div className="production-values__cell-value">
                        {convertNumCentsToDollars(Number(data?.first_360_days_value) * 100, true, '$')}
                    </div>
                    <div className="production-values__cell-title">Last 360 Days</div>
                    <div className="production-values__cell-value-bottom">
                        {convertNumCentsToDollars(Number(data?.last_360_days_value) * 100, true, '$')}
                    </div>
                </div>
                <div className="production-values__cell">
                    <div className="production-values__cell-title">Lifetime Value</div>
                    <div className="production-values__cell-value production-values--bold">
                        {convertNumCentsToDollars(Number(data?.lifetime_value) * 100, true, '$')}
                    </div>
                    <div className="production-values__cell-title"></div>
                    <div className="production-values__cell-value-bottom"></div>
                </div>
            </div>
            {loading && !loadingPatientsTable && (
                <div className="circular-progress-loader">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
        </div>
    )
}

export default ProductionValue
