import iassign from 'immutable-assign'

import {
    ConnectedPracticeLocation,
    PracticeLocationReschedulingConfiguration,
    PracticeReschedulingConfiguration,
} from '../../../models/v2/Practice'

import {
    CancelRescheduleSettingsActionTypes,
    ReceiveConnectedPracticeLocations,
    ReceivePracticeCancelRescheduleSettings,
    ReceivePracticeLocationCancelRescheduleSetting,
    ReceivePracticeLocationCancelRescheduleSettings,
    ReceivePracticeLocations,
    SearchPracticeLocationReschedulingConfigurationRequest,
    SearchPracticeLocationsRequest,
} from './v2actions'

export interface DateFilterField {
    isValid: boolean
    errorMessage?: string
    validators?: any
    value: string
}

export type PracticeLocationsState = {
    list?: ApiV2.Practice.PracticeLocationFull[]
    pagination: Models.PaginationInfo | null
    loading: boolean
    page?: number
}

export type ReschedulingConfigurationState = {
    connectedPracticeLocations: { [key: string]: ConnectedPracticeLocation[] }
    practiceLocationsData: { [key: string]: PracticeLocationsState }
    settings: { [key: string]: PracticeLocationReschedulingConfiguration[] }
    practiceSettingsData: { [key: string]: PracticeReschedulingConfiguration }
}

const initialState = (): ReschedulingConfigurationState => ({
    settings: {},
    connectedPracticeLocations: {},
    practiceLocationsData: {},
    practiceSettingsData: {},
})

export const initialPracticeLocationsState = (): PracticeLocationsState => ({
    page: 1,
    loading: false,
    list: undefined,
    pagination: null,
})

type ConfigLogsAction =
    | ReceivePracticeLocations
    | ReceiveConnectedPracticeLocations
    | SearchPracticeLocationReschedulingConfigurationRequest
    | ReceivePracticeLocationCancelRescheduleSetting
    | ReceivePracticeLocationCancelRescheduleSettings
    | ReceivePracticeCancelRescheduleSettings
    | SearchPracticeLocationsRequest

export function reducer(
    state: ReschedulingConfigurationState = initialState(),
    action: ConfigLogsAction,
): ReschedulingConfigurationState {
    switch (action.type) {
        case CancelRescheduleSettingsActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.connectedPracticeLocations[action.practiceId],
                nextConnectedLocations => {
                    if (!nextConnectedLocations) {
                        nextConnectedLocations = [...action.connectedLocations]
                        return nextConnectedLocations.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        })
                    }
                    nextConnectedLocations = action.connectedLocations.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        })
                    })
                    return nextConnectedLocations
                },
            )
        }

        case CancelRescheduleSettingsActionTypes.SEARCH_PRACTICE_LOCATIONS_REQUEST: {
            return iassign(
                state,
                next => next.practiceLocationsData[action.practiceId],
                nextPracticeLocationsData => {
                    if (!nextPracticeLocationsData) {
                        nextPracticeLocationsData = initialPracticeLocationsState()
                    }

                    nextPracticeLocationsData.page = action.page || 1
                    nextPracticeLocationsData.loading = action.loading
                    return nextPracticeLocationsData
                },
            )
        }

        case CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.practiceLocationsData[action.practiceId],
                nextPracticeLocations => {
                    if (!nextPracticeLocations) {
                        nextPracticeLocations = initialPracticeLocationsState()
                    }
                    nextPracticeLocations.list = action.practiceLocations
                    nextPracticeLocations.loading = false
                    nextPracticeLocations.pagination = action.pagination || { allPages: 1, allRows: 1 }

                    return nextPracticeLocations
                },
            )
        }

        case CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTINGS: {
            return iassign(
                state,
                next => next.settings[action.practiceId],
                nextSettings => {
                    nextSettings = action.settings
                    return nextSettings
                },
            )
        }

        case CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTING: {
            return iassign(
                state,
                next => next.settings[action.practiceId],
                nextSettingsData => {
                    if (nextSettingsData) {
                        if (!action.isCreate) {
                            const index = nextSettingsData.findIndex(
                                l => l.practice_location_id === action.setting[0].practice_location_id,
                            )

                            if (index !== -1) {
                                nextSettingsData[index] = action.setting[0]
                            }
                        } else {
                            nextSettingsData.push(action.setting[0])
                        }
                    }

                    return nextSettingsData
                },
            )
        }

        case CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_SETTINGS: {
            return iassign(
                state,
                next => next.practiceSettingsData,
                nextSettingsData => {
                    nextSettingsData[action.practiceId] = action.settings
                    return nextSettingsData
                },
            )
        }

        default:
            return state
    }
}
