import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import _ from 'lodash'

import Api from '../../Api'
import ConfirmModal from '../shared/confirm-modal/ConfirmModal'

import { mapPractice } from './reducer'

import './CreatePracticeSearch.sass'

export type CreatePracticeSearchDispatch = {
    onClose: () => void
    onSave: (name: string) => void
    searchByKeyword: (searchTerms: Models.SearchTerms) => void
}

export type CreatePracticeSearchState = {
    practices: Models.Practice[]
    name: string
}

type Props = CreatePracticeSearchDispatch

const CreatePracticeSearch = (props: Props) => {
    const searchByKeyword = async (searchTerms: Models.SearchTerms) => {
        const { data: apiPractices } = await Api.Practices.list(searchTerms)

        setPractices(apiPractices.map(mapPractice))
    }

    const [name, setName] = useState('')
    const [practices, setPractices] = useState<Models.Practice[]>([])
    const [showDuplicateModal, setShowDuplicateModal] = useState(false)

    const canSave = () => {
        return name.length > 1
    }

    const delayedQuery = useCallback(
        _.throttle((q: Models.SearchTerms) => searchByKeyword(q), 750),
        [],
    )

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = e.target && e.target.value
        setNameState(searchKey)

        if (searchKey) {
            const searchTerms = { searchKey: encodeURIComponent(searchKey), limit: 0 }
            delayedQuery(searchTerms)
        } else {
            emptyPracticesList()
        }
    }

    const emptyPracticesList = () => {
        setPractices([])
    }
    const onViewPractice = (practice: Models.Practice) => {
        props.searchByKeyword({ searchKey: practice.id })
        props.onClose()
    }
    const setNameState = (name: string = '') => {
        setName(name)
    }

    const onSave = () => {
        if (!canSave()) {
            return
        }

        if (practices.length > 2) {
            setShowDuplicateModal(true)
        } else {
            props.onSave(name)
        }
    }

    const onConfirmDuplicateModal = () => {
        props.onSave(name)
        setShowDuplicateModal(false)
    }

    const closeDuplicateModal = () => {
        setShowDuplicateModal(false)
    }

    return (
        <div className={classNames('create-practice')}>
            <div className="content">
                <div className="create-search">
                    <TextField
                        label="Enter Practice Name"
                        value={name}
                        onChange={onSearch}
                        fullWidth={true}
                        autoFocus={true}
                        variant="standard"
                    />
                </div>
                <div className="search-results">
                    {practices.map(practice => {
                        if (practice) {
                            return (
                                <div className="potential-dupe" key={practice.id}>
                                    <div className="name">{practice.name}</div>
                                    <div className="view-practice">
                                        <Button
                                            className="view-practice-button"
                                            variant="contained"
                                            onClick={() => onViewPractice(practice)}
                                        >
                                            View
                                        </Button>
                                    </div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })}
                </div>
            </div>
            <button
                className={classNames('save', { active: canSave(), 'can-save': canSave() })}
                onClick={onSave}
                type="button"
                disabled={!canSave}
                tabIndex={0}
            >
                Next
            </button>

            <ConfirmModal
                title={`Are you sure that "${name}" is not a duplicate practice?`}
                subtitle=""
                discardText="Cancel"
                confirmText="Confirm"
                open={showDuplicateModal}
                onClose={closeDuplicateModal}
                onConfirm={onConfirmDuplicateModal}
                onDiscard={closeDuplicateModal}
            />
        </div>
    )
}

export default CreatePracticeSearch
