import React from 'react'
import Icon from '@mui/material/Icon'

import './error-message.sass'

export type ErrorMessageProps = {
    title: string
    description?: string
    errorList?: any
}

const moduleName = 'general-error-message'

const scrollToElement = (element: string) => {
    const section = document.getElementById(element)

    if (section)
        section.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
}

const ErrorMessageDetailed = ({ title, description, errorList }: ErrorMessageProps) => {
    const sublist = (list: any) => {
        return (
            <ul>
                {list.map((error: any, i: any) => (
                    <li key={`id_${i}`}>
                        <button className={`${moduleName}__link`} onClick={() => scrollToElement(error.questionId)}>
                            {error.error}
                        </button>
                    </li>
                ))}
            </ul>
        )
    }

    const sectionsList = Object.keys(errorList)
        .filter(sectionName => errorList[sectionName].length > 0)
        .map((sectionName, i) => {
            if (sectionName === 'locationInformation') {
                return (
                    <li key={i}>
                        {`Location information`} {sublist(errorList[sectionName])}
                    </li>
                )
            }
            return (
                <li key={i}>
                    {sectionName} {sublist(errorList[sectionName])}
                </li>
            )
        })

    return (
        <div className={`${moduleName}__wrapper-vertical`}>
            <div className={`${moduleName}__title`}>
                <Icon>warning</Icon>
                <div className={`${moduleName}__message`}>{title}</div>
            </div>
            {description && <div className={`${moduleName}__description`}>{description}</div>}
            {errorList && <ul className={`${moduleName}__list`}>{sectionsList}</ul>}
        </div>
    )
}

export default ErrorMessageDetailed
