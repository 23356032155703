import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import throttle from 'lodash/throttle'

import Api from '../../../../Api'
import { RootState } from '../../../../appReducer'
import { AccessAmplifySurveys, AccountTypes, Amplify } from '../../../../models/enums'
import { SearchBar } from '../../../../modules/shared/custom-fields/SearchBar'
import ManageLocationProducts from '../../../../modules/shared/ManageLocationProducts'
import { useAppDispatch } from '../../../../util/useAppDispatch'
import Paginator from '../../../shared/Paginator'
import {
    fetchLocationConnectPaymentInfoData,
    // fetchLocationStatementDescriptor,
    fetchLocationStripeAccountId,
    loadLocationPaymentsInfo,
} from '../../actions'
import {
    fetchPracticeProductsByPracticeId,
    savePracticeLocationProducts,
    savingPracticeLocationProducts,
} from '../../actions'
import { getLocationPaymentsSettings, openEditSurveyModal, setLocationListMeta } from '../actions'
import { openLocationAddEditModal } from '../location-forms/actions'
import { openPaymentRatesModal } from '../payment-rates/actions'
import { openStatementDescriptorModal } from '../statement-descriptor/actions'
import { openStripeAccountIdModal } from '../stripe-account-id/actions'
import { openTerminalModal } from '../terminal-form/actions'
import { AddTerminalRadio } from '../terminal-form/TerminalForm'

import LocationCard from './location-card/LocationCard'

interface Props {
    practiceId: Models.Practice['id']
    locations: Models.PracticeLocation[]
    products: Models.Product[]
    hasPayments: boolean
    hasDirectScheduling: boolean
    isListLoading: boolean
    practiceSearchTerms: Models.SearchTerms
}

const LocationList = ({ practiceId, locations, products, hasPayments, hasDirectScheduling, isListLoading }: Props) => {
    const dispatch = useAppDispatch()
    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const practice = useSelector((state: RootState) => state.practices.practices[practiceId])
    const enablePstPracticeCreation = useSelector(
        (state: RootState) => state.app.featureFlags?.enablePstPracticeCreation,
    )
    const isFetchingAllPracticeLocations = useSelector(
        (state: RootState) => state.practices.isFetchingAllPracticeLocations[practice.id],
    )
    const isSavingPracticeLocationProducts = useSelector(
        (state: RootState) => state.practices.isSavingPracticeLocationProducts,
    )
    const { page, allPages, allRows } = useSelector((state: RootState) => state.practiceLocations[practiceId].metadata)

    const isCreatePracticeLocationEnabled = enablePstPracticeCreation !== false

    const [expandedPayments, setExpandedPayments] = useState<{
        id: Models.PracticeLocation['id']
        isLoading: boolean
        errorMessage: string
    } | null>(null)
    const [firstListLoadDone, setFirstListLoadDone] = useState<boolean>(false)
    const [searchKeyword, setSearchKeyword] = useState<string>('')
    const [hasSearch, setHasSearch] = useState<boolean>(false)
    const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false)
    const [locationIdForProducts, setLocationIdForProducts] = useState<string>('')

    const onCloseSettingsModal = () => {
        setShowSettingsModal(false)
        setLocationIdForProducts('')
    }

    const searchForLocations = useCallback(
        (searchKey: string) => {
            dispatch(setLocationListMeta(practiceId, { search: searchKey, page: 1 }))
        },
        [dispatch, practiceId],
    )

    const throttledSearchLocations = useMemo(() => throttle(searchForLocations, 1000), [searchForLocations])

    useEffect(() => {
        throttledSearchLocations(searchKeyword.trim().toLocaleLowerCase())
    }, [searchKeyword, throttledSearchLocations])

    useEffect(() => {
        if ((allPages > 1 || searchKeyword.trim() !== '') && !firstListLoadDone) {
            setHasSearch(true)
            setFirstListLoadDone(true)
        }
    }, [allPages, searchKeyword, firstListLoadDone])

    const getSelectedLocation = (locationId: string): Models.PracticeLocation | undefined => {
        return locations.find(loc => loc.id === locationId)
    }

    const getCanEdit = (): boolean =>
        Boolean(
            account &&
                (account.type.id === AccountTypes.SimplifeyeStaff ||
                    account.type.id === AccountTypes.SuperAdmin ||
                    account.type.id === AccountTypes.PattersonStaff),
        )

    const getCanManageSurvey = (): boolean =>
        Boolean(
            products.some(product => product.value === Amplify.value && product.active === true) &&
                account?.hasAccess(AccessAmplifySurveys),
        )

    const searchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.target.value || '')
    }
    const clearSearch = () => {
        setSearchKeyword('')
    }

    const handlePageChange = (newPage: number) => {
        dispatch(setLocationListMeta(practiceId, { page: newPage }))
    }

    const handleOpenAddLocationModal = (): void => {
        dispatch(openLocationAddEditModal(null, practiceId))
    }

    const handleOpenEditLocationModal = async (selectedLocation: Models.PracticeLocation) => {
        if (hasPayments) {
            await dispatch(fetchLocationStripeAccountId(practiceId, selectedLocation.id))
            await dispatch(fetchLocationConnectPaymentInfoData(practiceId, selectedLocation.id))
        }

        dispatch(openLocationAddEditModal(selectedLocation, practiceId))
    }

    const handleOpenEditSurveyModal = (selectedLocation: Models.PracticeLocation): void => {
        dispatch(openEditSurveyModal(selectedLocation))
    }

    const handleOpenPaymentsRatesModal = (
        locationId: Models.PracticeLocation['id'],
        locationName: Models.PracticeLocation['name'],
    ): void => {
        dispatch(openPaymentRatesModal(locationId, locationName, practiceId))
    }

    const handleOpenStripeAccountIdModal = (
        locationId: Models.PracticeLocation['id'],
        locationName: Models.PracticeLocation['name'],
        stripeAccountId: string,
        applyToAll: boolean,
    ): void => {
        dispatch(openStripeAccountIdModal(locationId, locationName, practiceId, stripeAccountId, applyToAll))
    }

    const handleOpenPaymentsDescriptorModal = (
        locationId: Models.PracticeLocation['id'],
        statementDescriptor: Models.PracticeLocation['statementDescriptor'],
    ) => {
        dispatch(openStatementDescriptorModal(locationId, statementDescriptor, practiceId))
    }

    const handleOpenTerminalModal = (
        locationId: Models.PracticeLocation['id'],
        terminalId: Models.LocationPaymentsTerminalInfo['id'],
        nickname: Models.LocationPaymentsTerminalInfo['nickname'],
        readerSerial: Models.LocationPaymentsTerminalInfo['readerSerial'],
        pairingCode: Models.LocationPaymentsTerminalInfo['pairingCode'],
        s700Code: Models.LocationPaymentsTerminalInfo['s700Code'],
        uuid: Models.LocationPaymentsTerminalInfo['uuid'],
        addTerminalRadio: AddTerminalRadio,
        isView: boolean,
    ): void => {
        dispatch(
            openTerminalModal(
                locationId,
                practiceId,
                terminalId,
                nickname,
                readerSerial,
                pairingCode,
                s700Code,
                uuid,
                addTerminalRadio,
                isView,
            ),
        )
    }

    const handleExpandPayments = (locationId?: string) => {
        if (!locationId) {
            setExpandedPayments(null)
            return
        }
        const isOpening = expandedPayments?.id !== locationId

        setExpandedPayments(
            isOpening
                ? {
                      id: locationId,
                      isLoading: true,
                      errorMessage: '',
                  }
                : null,
        )

        const selected = getSelectedLocation(locationId)
        if (isOpening) {
            Promise.all([
                dispatch(loadLocationPaymentsInfo(practiceId, locationId)),
                dispatch(fetchLocationStripeAccountId(practiceId, locationId)),
                dispatch(getLocationPaymentsSettings(practiceId, locationId)),
                // dispatch(fetchLocationStatementDescriptor(practiceId, locationId)),
            ])
                .then(() => {
                    setExpandedPayments({
                        id: locationId,
                        isLoading: false,
                        errorMessage: ``,
                    })
                })
                .catch(() => {
                    setExpandedPayments({
                        id: locationId,
                        isLoading: false,
                        errorMessage: `Failed loading payments data for "${selected?.name || locationId}".`,
                    })
                })
        }
    }

    const handleSyncLocationSchedulingData = async (selectedLocation: Models.PracticeLocation) => {
        if (!selectedLocation || !selectedLocation.address) return

        if (hasDirectScheduling) {
            return Api.Practices.syncLocationSchedulingData(selectedLocation)
        }
    }

    const handleOpenLocationProductsModal = (locationId: string) => {
        setLocationIdForProducts(locationId)
        setShowSettingsModal(true)
    }

    const handleSaveLocationProducts = async (products: Array<Api.SaveProduct>) => {
        dispatch(savingPracticeLocationProducts(true))
        await dispatch(savePracticeLocationProducts(practiceId, locationIdForProducts, products))
        dispatch(savingPracticeLocationProducts(false))
        onCloseSettingsModal()
        dispatch(fetchPracticeProductsByPracticeId(practice.id))
    }

    if ((page === 1 && !firstListLoadDone && !locations) || locations?.length > 8 || isFetchingAllPracticeLocations) {
        return (
            <div className="card-container">
                <div className="locations-tab__loader-container">
                    <div className="loader loader--global">
                        <CircularProgress
                            className="loader-spinner"
                            size={50}
                            color="primary"
                            variant="indeterminate"
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {hasSearch && (
                <div className="search-bar-container">
                    <SearchBar
                        value={searchKeyword}
                        onChange={searchByKeyword}
                        onClear={clearSearch}
                        isMessageShown={searchKeyword.length > 0 && !locations.length}
                        noResultsMessage="No records found for given name or ID or Facility ID"
                        placeholder="Search by Location Name or Location ID or Facility ID"
                    />
                </div>
            )}
            <div className="card-container">
                {locations.map((location: Models.PracticeLocation) => (
                    <LocationCard
                        key={`${location.name}-${location.id}`}
                        location={location}
                        practiceId={practiceId}
                        hasPayments={hasPayments}
                        isPaymentsCardExpanded={expandedPayments?.id === location.id}
                        isPaymentsCardLoading={Boolean(expandedPayments?.isLoading)}
                        isLocationEditable={getCanEdit()}
                        canManageSurvey={getCanManageSurvey()}
                        hasDirectScheduling={hasDirectScheduling}
                        onTogglePaymentsExpand={handleExpandPayments}
                        onOpenTerminalModal={handleOpenTerminalModal}
                        onOpenStripeAccountIdModal={handleOpenStripeAccountIdModal}
                        onOpenPaymentsDescriptorModal={handleOpenPaymentsDescriptorModal}
                        onOpenPaymentsRatesModal={handleOpenPaymentsRatesModal}
                        onSyncLocationSchedulingData={handleSyncLocationSchedulingData}
                        onEditSurvey={handleOpenEditSurveyModal}
                        onEditLocation={handleOpenEditLocationModal}
                        onOpenLocationProductsModal={handleOpenLocationProductsModal}
                    />
                ))}
                {isListLoading && (
                    <div className="locations-tab__loader-container">
                        <div className="loader loader--list">
                            <CircularProgress
                                className="loader-spinner"
                                size={50}
                                color="primary"
                                variant="indeterminate"
                            />
                        </div>
                    </div>
                )}
                {showSettingsModal && (
                    <Modal
                        className="pst-modal"
                        open={true}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                onCloseSettingsModal()
                            }
                        }}
                        disableEscapeKeyDown={true}
                    >
                        <div className="contents manage-products-modal">
                            <div className="close-modal-button" onClick={onCloseSettingsModal}>
                                <i className="material-icons">close</i>
                            </div>
                            <ManageLocationProducts
                                account={account}
                                practice={practice}
                                location={getSelectedLocation(locationIdForProducts)}
                                onSave={handleSaveLocationProducts}
                                onClose={onCloseSettingsModal}
                                isSavingPracticeProducts={isSavingPracticeLocationProducts}
                            />
                        </div>
                    </Modal>
                )}
            </div>

            <div className="locations-tab__paginator-wrapper">
                <Paginator currentPage={page} paginationInfo={{ allRows, allPages }} selectPage={handlePageChange} />
            </div>
            {isCreatePracticeLocationEnabled && (
                <div className="locations-tab__footer" onClick={handleOpenAddLocationModal}>
                    <div className="button">
                        <i className="material-icons add-icon">add</i>
                        Add Location
                    </div>
                </div>
            )}
        </>
    )
}

export default LocationList
