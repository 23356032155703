import { connect } from 'react-redux'

import { RootState } from '../../../appReducer'
import { cancelPendingUpload, startFileUpload } from '../../uploads/actions'
import {
    confirmUploadedLogo,
    deletePracticeLogo,
    fetchPracticeLogo,
    fetchPracticeLogoBgColor,
    updateLogoBgColor,
} from '../actions'

import PracticeLogoModal, { PracticeLogoModalProps } from './PracticeLogoModal'

const mapStateToProps = (state: RootState, props: Omit<PracticeLogoModalProps, 'pendingUploads'>) => ({
    account: state.app.self && state.app.self.account,
    pendingUploads: state.uploads.pendingUploads,
})

const PracticeLogoContainer = connect(mapStateToProps, {
    fetchPracticeLogo,
    confirmUploadedLogo,
    deletePracticeLogo,
    startFileUpload,
    cancelPendingUpload,
    fetchPracticeLogoBgColor,
    updateLogoBgColor,
})(PracticeLogoModal as any)

export default PracticeLogoContainer
