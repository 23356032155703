export const softBlack = '#303030'
export const verySoftBlack = '#4a4a4a'
export const brandTeal = '#47e0cd'
export const mainBlue = '#346bc7'
export const alertBlue = '#448aff'
export const textBlue = '#346bc7'
export const errorRed = '#D0021B'
export const offWhite = '#F0EFED'
export const labelGray = '#737373'
export const fieldBorderGray = '#cfdae9'
export const softerGray = '#a9a9a9'
export const gray97 = '#979797'
export const grayd7 = '#D7D7D7'
export const white = '#FFFFFF'
export const black = '#000000'
export const listItemHoverBackground = '#f5f7fa'
export const veryLightGreen = '#b8e986'
export const successGreen = '#64dd17'
export const clearGreen = '#3da50c'
export const grayDa = '#DADADA'
