import merge from 'lodash/fp/merge'

import { SurveyDoctorRecord } from '../../../../../../models/PracticeSurvey'
import { ValidatorPreset } from '../validator'

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>
}

export const doctorField = (doctorRecord?: DeepPartial<SurveyDoctorRecord>): SurveyDoctorRecord => {
    const defaultRecord = {
        id: {
            value: '',
            isRequired: false,
            isValid: true,
            isDirty: false,
            validators: [],
        },
        firstName: {
            value: '',
            isRequired: true,
            isValid: false,
            isDirty: false,
            validators: [ValidatorPreset.FIRST_NAME],
        },
        lastName: {
            value: '',
            isRequired: true,
            isValid: false,
            isDirty: false,
            validators: [ValidatorPreset.NAME],
        },
        suffix: {
            value: '',
            isRequired: false,
            isValid: false,
            isDirty: false,
            validators: [ValidatorPreset.SUFFIX],
        },
    }

    return doctorRecord ? merge(defaultRecord, doctorRecord) : defaultRecord
}
