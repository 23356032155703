import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Modal from '@mui/material/Modal'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import classNames from 'classnames'

import { RootState } from '../../../appReducer'
import { AccountTypes } from '../../../models/enums'
import { SimplifeyeLogoIconSmall, VyneLogoIconSmall } from '../../../modules/shared/svgIcons'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CopyText from '../../shared/CopyText'
import { impersonateUser, resendInvitation, resendInvitationV2, resetPassword, saveAccount } from '../actions'
import { restoreAccount } from '../v2actions'
import { fetchPracticeAccount, fetchPracticeAccountLocations } from '../v2actions'

import StaffConfirmationBox from './StaffConfirmationBox'
import { StaffConfirmationBoxType } from './StaffConfirmationBox'
import StaffForm from './StaffForm'
import StaffSuccessBox, { StaffSuccessBoxType } from './StaffSuccessBox'

import './StaffMember.sass'

export type Props = {
    practice: Models.Practice
    employee: ModelsV2.Practice.PracticeStaffData
    refreshStaffList: () => void
}

const StaffMember = (props: Props) => {
    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const practiceUserRoles = useSelector((state: RootState) => state.practices.userRoles)

    const [successBox, setSuccessBox] = useState<StaffSuccessBoxType | ''>('')
    const [showModal, setShowModal] = useState(false)
    const [showActions, setShowActions] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [actionsAnchor, setActionsAnchor] = useState<HTMLElement | undefined>(undefined)
    const [showConfirmationBox, setShowConfirmationBox] = useState(false)
    const [confirmationBoxCallbackFn, setConfirmationBoxCallbackFn] = useState<(() => void) | undefined>(undefined)
    const [confirmationBoxType, setConfirmationBoxType] = useState<StaffConfirmationBoxType | undefined>(undefined)
    const dispatch = useAppDispatch()
    const invitedClass = () =>
        classNames('cell', {
            invited: hasStatus('invited'),
        })

    const deletedDeactivatedClass = () =>
        classNames('cell', {
            deleteddeactivated: hasStatus('permanently_deleted') || hasStatus('deleted') || hasStatus('deactivated'),
        })

    const onCloseModal = () => {
        props.refreshStaffList()
        setShowModal(false)
    }

    const onShowModal = async () => {
        setModalLoading(true)
        await dispatch(fetchPracticeAccount(props.practice.id, props.employee.id))
        await dispatch(fetchPracticeAccountLocations(props.practice.id, props.employee.id, 1))
        setModalLoading(false)
        setShowModal(true)
    }

    const onOpenActionsMenu = async (event: any) => {
        setShowActions(true)
        setActionsAnchor(event.currentTarget)
        await dispatch(fetchPracticeAccount(props.practice.id, props.employee.id))
    }

    const onCloseActionsMenu = () => {
        setShowActions(false)
        setActionsAnchor(undefined)
    }

    const onShowCloseConfirmationBox = (type: StaffConfirmationBoxType, callback: () => void) => {
        setConfirmationBoxType(type)
        setConfirmationBoxCallbackFn(() => callback)
        setShowConfirmationBox(true)
    }

    const onCloseConfirmationBox = () => {
        setConfirmationBoxType(undefined)
        setConfirmationBoxCallbackFn(() => undefined)
        setShowConfirmationBox(false)
    }

    const createReqPayload = (status: string) => {
        const requestPayload = {
            firstName: employee.first_name,
            lastName: employee.last_name,
            email: employee.username,
            permissions: employee.permission?.map(p => p.type) || [],
            role: employee.simplifeye_role.value,
            locations: [],
            emails: (employee as ModelsV2.Practice.PracticeAccount).emails,
            phoneNumbers: (employee as ModelsV2.Practice.PracticeAccount).phone_numbers,
            accountStatus: status,
        }

        if ((employee as ModelsV2.Practice.PracticeAccount).yext_user_id) {
            requestPayload['yextUserId'] = (employee as ModelsV2.Practice.PracticeAccount).yext_user_id
        }

        return requestPayload
    }

    const onImpersonate = () => {
        const { employee } = props
        onCloseActionsMenu()

        const callback = () => {
            dispatch(impersonateUser(employee))
        }

        onShowCloseConfirmationBox('impersonateUser', callback)
    }

    const onToggleAccountStatus = () => {
        const { employee } = props

        const employeeStatusValue = employee.account_status?.value

        const shouldDeactivateAccount = employeeStatusValue === 'active' || employeeStatusValue === 'invited'

        const callback = async () => {
            const changes = createReqPayload(shouldDeactivateAccount ? 'deactivated' : 'active')

            await dispatch(saveAccount(props.practice, employee.id, changes))
            props.refreshStaffList()
        }

        const confirmationType = shouldDeactivateAccount ? 'deactivateAccount' : 'reactivateAccount'
        onShowCloseConfirmationBox(confirmationType, callback)
        onCloseActionsMenu()
    }

    const onDeleteAccount = () => {
        const callback = async () => {
            const changes = createReqPayload('deleted')
            await dispatch(saveAccount(props.practice, props.employee.id, changes))
            props.refreshStaffList()
        }

        onShowCloseConfirmationBox('deleteAccount', callback)
        onCloseActionsMenu()
    }

    const onRestoreAccount = () => {
        const callback = async () => {
            await dispatch(restoreAccount(props.practice, props.employee))
            props.refreshStaffList()
        }

        onShowCloseConfirmationBox('restoreAccount', callback)
        onCloseActionsMenu()
    }

    const onInviteResend = () => {
        const callback = () => {
            const { employee, practice } = props
            if (employee) {
                const changes: Api.InviteResend = {
                    username: employee.username,
                    practice_id: practice.id,
                }
                const changesV2: ApiV2.Practice.InviteResend = {
                    email: employee.username,
                    practiceId: practice.id,
                }

                dispatch(employee?.is_sso ? resendInvitationV2(changesV2) : resendInvitation(changes))
            }
        }
        onShowCloseConfirmationBox('resendInvitation', callback)
        onCloseActionsMenu()
    }

    const onResetPassword = () => {
        const callback = async () => {
            let success = false

            const { employee } = props
            const changes: ApiV2.Practice.ResetPassword = {
                email: employee.username,
            }

            const resetResponse = await dispatch(resetPassword(changes))

            if (resetResponse.success) {
                success = true
            }
            if (success) {
                onShowSuccessBox('resetPassword')
            }
        }
        onShowCloseConfirmationBox('resetPassword', callback)
        onCloseActionsMenu()
    }

    const onCloseSuccessBox = () => {
        setSuccessBox('')
    }

    const onShowSuccessBox = (type: StaffSuccessBoxType) => {
        setSuccessBox(type)
    }

    const onSave = (changes: ApiV2.Practice.AccountUpdate) => {
        dispatch(saveAccount(props.practice, props.employee.id, changes)).then(onCloseModal)
    }

    const onRestore = () => {
        dispatch(restoreAccount(props.practice, props.employee)).then(onCloseModal)
    }

    const hasStatus = (status: string) => {
        const { employee } = props
        return employee && employee.account_status?.value === status
    }

    const { employee, practice } = props

    const isSSO = Boolean(employee?.is_sso)

    const isAdmin =
        (account && account.type.id === AccountTypes.SimplifeyeStaff) ||
        (account && account.type.id === AccountTypes.SuperAdmin) ||
        (account && account.type.id === AccountTypes.PattersonStaff)

    const noDeleteOption = isSSO || hasStatus('deleted')
    const showResetPassword = !hasStatus('deactivated') && !hasStatus('invited') && !hasStatus('deleted')
    const showDeactivate = isSSO ? hasStatus('active') || hasStatus('invited') : hasStatus('active')
    const showReactivate = hasStatus('deactivated')

    const menuId = `actions-menu-${account && account.id}`

    return (
        <TableRow className={classNames('staff-tab-list-member')}>
            <TableCell className="resend-invitation-btn-container">
                {props.employee?.is_vyne_created ? (
                    <VyneLogoIconSmall />
                ) : (
                    <SimplifeyeLogoIconSmall className="simplifeye-icon" />
                )}
            </TableCell>
            <TableCell className="narrower-table-item">
                <div className="cell">
                    <CopyText tooltip={true} text={employee.id} />
                </div>
            </TableCell>
            <TableCell className="table-item">
                <div className={`${invitedClass()} ${deletedDeactivatedClass()} employee-first-name-wrapper`}>
                    <div className="employee-name">{employee.last_name}</div>
                </div>
            </TableCell>
            <TableCell className="table-item">
                <div className={`${invitedClass()} ${deletedDeactivatedClass()} employee-last-name-wrapper`}>
                    <div className="employee-name">{employee.first_name}</div>
                </div>
            </TableCell>
            <TableCell className="username-table-item">
                <div className={`${invitedClass()} ${deletedDeactivatedClass()} employee-username-wrapper`}>
                    <div className="employee-username">{employee.username}</div>
                </div>
            </TableCell>
            <TableCell className="table-item">
                <div className={`${invitedClass()} ${deletedDeactivatedClass()}`}>
                    {employee.simplifeye_role && employee.simplifeye_role.display}
                </div>
            </TableCell>
            <TableCell className="table-item">
                <div className={`${invitedClass()} ${deletedDeactivatedClass()}`}>
                    {employee.account_status?.display.replace('Permanently ', '')}
                </div>
            </TableCell>
            <TableCell className="narrower-table-item">
                <div className="edit-btn" onClick={onShowModal}>
                    {modalLoading ? (
                        <CircularProgress className="circular-progress-spinner" size={20} thickness={6} />
                    ) : (
                        <i className="material-icons settings-icon">settings</i>
                    )}
                </div>
                {showModal && (
                    <Modal
                        className="pst-modal"
                        open={true}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                onCloseModal()
                            }
                        }}
                        disableEscapeKeyDown={true}
                    >
                        <StaffForm
                            employee={employee}
                            onSave={onSave}
                            onRestore={onRestore}
                            onClose={onCloseModal}
                            practiceUserRoles={practiceUserRoles}
                            practice={practice}
                        />
                    </Modal>
                )}
            </TableCell>
            <TableCell className="table-item table-last-item">
                <div>
                    <IconButton
                        size="large"
                        aria-owns={actionsAnchor ? menuId : undefined}
                        aria-haspopup="true"
                        onClick={onOpenActionsMenu}
                    >
                        <i className="material-icons more-icon">more_horiz</i>
                    </IconButton>
                    <Menu
                        id={menuId}
                        className="staff-member-actions-menu"
                        anchorEl={actionsAnchor}
                        open={showActions}
                        onClose={onCloseActionsMenu}
                        anchorOrigin={{
                            horizontal: 'left',
                            vertical: 'bottom',
                        }}
                    >
                        {isAdmin && !hasStatus('invited') && (
                            <MenuItem className="staff-member-menu-item" key="impersonate" onClick={onImpersonate}>
                                Impersonate
                            </MenuItem>
                        )}
                        {hasStatus('invited') && (
                            <MenuItem
                                className="staff-member-menu-item"
                                key="resendInvitation"
                                onClick={onInviteResend}
                            >
                                Resend Invite
                            </MenuItem>
                        )}
                        {showResetPassword && (
                            <MenuItem className="staff-member-menu-item" key="resetPassword" onClick={onResetPassword}>
                                Reset Password
                            </MenuItem>
                        )}
                        {(showDeactivate || showReactivate) && (
                            <MenuItem
                                key="activate"
                                className={classNames('active-button', 'staff-member-menu-item', {
                                    active: hasStatus('active') || hasStatus('invited'),
                                })}
                                onClick={onToggleAccountStatus}
                            >
                                {showDeactivate ? 'Deactivate User' : 'Reactivate User'}
                            </MenuItem>
                        )}
                        {!hasStatus('invited') && hasStatus('deleted') && (
                            <MenuItem
                                key="activate"
                                className={classNames('active-button', 'staff-member-menu-item', {
                                    active: hasStatus('active'),
                                })}
                                onClick={onRestoreAccount}
                            >
                                Restore User
                            </MenuItem>
                        )}
                        {!noDeleteOption && (
                            <MenuItem
                                key="delete"
                                className={classNames('active-button', 'staff-member-menu-item', {
                                    active: !hasStatus('deleted'),
                                })}
                                onClick={onDeleteAccount}
                            >
                                Delete User
                            </MenuItem>
                        )}
                    </Menu>
                    {showConfirmationBox && (
                        <Modal
                            open={true}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick') {
                                    onCloseConfirmationBox()
                                }
                            }}
                            disableEscapeKeyDown={true}
                        >
                            <div className="staff-member-confirmation-modal">
                                <div className="close-modal-button" onClick={onCloseConfirmationBox}>
                                    <i className="material-icons">close</i>
                                </div>
                                <StaffConfirmationBox
                                    employee={employee}
                                    confirmFn={confirmationBoxCallbackFn}
                                    closeFn={onCloseConfirmationBox}
                                    type={confirmationBoxType}
                                />
                            </div>
                        </Modal>
                    )}
                    {successBox && (
                        <Modal
                            open={true}
                            onClose={(event, reason) => {
                                if (reason !== 'backdropClick') {
                                    onCloseConfirmationBox()
                                }
                            }}
                            disableEscapeKeyDown={true}
                        >
                            <div className="staff-member-confirmation-modal">
                                <div className="close-modal-button" onClick={onCloseSuccessBox}>
                                    <i className="material-icons">close</i>
                                </div>
                                <StaffSuccessBox employee={employee} closeFn={onCloseSuccessBox} type={successBox} />
                            </div>
                        </Modal>
                    )}
                </div>
            </TableCell>
        </TableRow>
    )
}

export default StaffMember
