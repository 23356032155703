import * as React from 'react'
import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import moment from 'moment'

import './WebCodeCustomizationBannedIPAddresses.sass'

type Props = {
    cancelNewIPAddress: () => void
    customization: Api.WebCodeCustomization
    deleteIPAddress: (index: number) => void
    editIPAddress: (index: number, ipString: string | undefined, banReason: string | undefined) => void
    newIPAddress?: string
    saveNewIPAddress: () => void
    updateNewIPAddress: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type State = {
    addNewIpAddressOpen: boolean
    isEditOpen: boolean
    editIndex: number
    editedBanReason: string | undefined
    editedIPString: string | undefined
}

export default class WebCodeCustomizationBannedIPAddresses extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            addNewIpAddressOpen: false,
            isEditOpen: false,
            editIndex: -1,
            editedBanReason: '',
            editedIPString: '',
        }
    }

    onEditIPAddress = () => {
        this.props.editIPAddress(this.state.editIndex, this.state.editedIPString, this.state.editedBanReason)
        this.setState({ isEditOpen: false, editIndex: -1, editedIPString: '', editedBanReason: '' })
    }

    openAddNewIpAddress = () => {
        this.setState({
            addNewIpAddressOpen: true,
        })
    }

    onChangeIPString = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editedIPString: e.target.value })
    }

    onChangeBanReason = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editedBanReason: e.target.value })
    }

    openEdit = (index: number, ipString: string | undefined, banReason: string | undefined) => {
        this.setState({ isEditOpen: true, editIndex: index, editedIPString: ipString, editedBanReason: banReason })
    }

    closeEdit = () => {
        this.setState({ isEditOpen: false, editIndex: -1 })
    }

    closeAddNewIpAddress = () => {
        this.props.cancelNewIPAddress()
        this.setState({
            addNewIpAddressOpen: false,
        })
    }

    render() {
        const { customization, deleteIPAddress, newIPAddress, saveNewIPAddress, updateNewIPAddress } = this.props

        const newIPAddressValue = newIPAddress || ''
        const inputStyle = { style: { fontSize: '13px', padding: '2px' } }

        return (
            <div className="banned-ip-addresses-table">
                {customization.banned_ip_addresses && customization.banned_ip_addresses.length > 0 && (
                    <Table>
                        {customization.banned_ip_addresses && (
                            <TableHead style={{ padding: '0px' }}>
                                <TableRow className="table-head-row">
                                    <TableCell className="table-head-cell">Date</TableCell>
                                    <TableCell className="table-head-cell">IP Address</TableCell>
                                    <TableCell className="table-head-cell">Location</TableCell>
                                    <TableCell className="table-head-cell">Ban Reason</TableCell>
                                    <TableCell className="table-head-cell" style={{ paddingRight: '4px' }} />
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {customization.banned_ip_addresses &&
                                customization.banned_ip_addresses.map((wm, index) => (
                                    <TableRow key={index.toString()}>
                                        <TableCell className="table-cell">
                                            {moment(wm.date).format('MM/DD/YYYY')}
                                        </TableCell>
                                        {this.state.isEditOpen && this.state.editIndex === index ? (
                                            <TableCell className="table-cell">
                                                <TextField
                                                    inputProps={inputStyle}
                                                    value={this.state.editedIPString}
                                                    onChange={this.onChangeIPString}
                                                    variant="standard"
                                                />
                                            </TableCell>
                                        ) : (
                                            <TableCell className="table-cell">{wm.ip_address}</TableCell>
                                        )}
                                        <TableCell className="table-cell">{wm.location}</TableCell>
                                        {this.state.isEditOpen && this.state.editIndex === index ? (
                                            <TableCell className="table-cell">
                                                <TextField
                                                    multiline={true}
                                                    inputProps={inputStyle}
                                                    maxRows={4}
                                                    value={this.state.editedBanReason}
                                                    onChange={this.onChangeBanReason}
                                                    variant="standard"
                                                />
                                            </TableCell>
                                        ) : (
                                            <TableCell className="table-cell">
                                                {wm.ban_reason}
                                                <div className="banned-by-div">By {wm.banned_by}</div>
                                            </TableCell>
                                        )}
                                        {this.state.isEditOpen && this.state.editIndex === index ? (
                                            <TableCell className="table-cell-action" style={{ paddingRight: '4px' }}>
                                                <div>
                                                    <Button
                                                        className="action-button"
                                                        variant="text"
                                                        onClick={this.onEditIPAddress}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </div>
                                                <div>
                                                    <Button
                                                        className="action-button"
                                                        variant="text"
                                                        onClick={this.closeEdit}
                                                    >
                                                        CANCEL
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        ) : (
                                            <TableCell className="table-cell-action" style={{ paddingRight: '4px' }}>
                                                <div className="action-buttons-wrapper">
                                                    <div
                                                        className="button icon-button"
                                                        onClick={() =>
                                                            this.openEdit(index, wm.ip_address, wm.ban_reason)
                                                        }
                                                    >
                                                        <i className="material-icons icon-action">edit</i>
                                                    </div>

                                                    <div
                                                        className="button icon-button"
                                                        onClick={() => deleteIPAddress(index)}
                                                    >
                                                        <i className="material-icons icon-action">delete</i>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                )}
                {this.state.addNewIpAddressOpen ? (
                    <div className="table-cell">
                        <TextField
                            className="field"
                            autoFocus={true}
                            placeholder="IP Address"
                            value={newIPAddressValue}
                            onChange={updateNewIPAddress}
                            variant="standard"
                        />
                        <Button
                            className="action-button"
                            variant="text"
                            disabled={!newIPAddress}
                            onClick={saveNewIPAddress}
                        >
                            Add
                        </Button>
                        <Button className="action-button" variant="text" onClick={this.closeAddNewIpAddress}>
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div onClick={this.openAddNewIpAddress} className="add-new-ip-address-div">
                        <div className="icon-wrapper icon-add">
                            <i className="material-icons icon-button">add_circle</i>
                        </div>
                        <div className="add-ip-address-text">Add IP address</div>
                    </div>
                )}
            </div>
        )
    }
}
