import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

import { gray97, listItemHoverBackground, verySoftBlack } from '../styles/colors'

const CustomHoverBox = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: listItemHoverBackground,
        borderRadius: '0',
        color: verySoftBlack,
        fontSize: '14px',
        fontWeight: 400,
        border: `1px solid ${gray97}`,
        lineHeight: '40px',
        height: '38px',
        paddingTop: 0,
        paddingBottom: 0,
        maxWidth: 'none',
    },
}))

export default CustomHoverBox
