import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { claimChat } from '../actions'

import ChatListNavBar from './ChatListNavBar'
import ClaimedChatsList from './ClaimedChatsList'
import UnclaimedChatsList from './UnclaimedChatsList'

import './ChatList.sass'

export type Props = {
    myClaimedChats: Models.ChatMetadata[]
    scrollToChat: (chatIndex: number) => void
    attachChats: (chat: Models.ChatMetadata[]) => Models.ChatCenterSelectedChat[]
}

const moduleName = 'chat-list'

const ChatList = ({
    myClaimedChats,

    scrollToChat,
    attachChats,
}: Props) => {
    const [search, setSearch] = useState<string>('')
    const list = useSelector((state: RootState) => state.chat.list)
    const chats = useSelector((state: RootState) => state.chat.chats)
    const account = useSelector((state: RootState) => state.app.self && state.app.self.account) as AdminAccount
    const selectedChats = useSelector((state: RootState) => state.chat.selectedChats)
    const claimedChats = useSelector((state: RootState) => state.chat.claimedChats)
    const browsing = useSelector((state: RootState) => state.chat.browsing)
    const { unclaimed } = _.groupBy(list, c => chats[c].status.value)
    const dispatch = useAppDispatch()

    const onSetSearch = (searchKey: string) => {
        setSearch(searchKey)
    }

    const onClaimChat = (chat: Models.ChatMetadata) => {
        dispatch(claimChat(account, chat))
    }
    const selectedChatsIds = selectedChats.map(chat => chat.id)

    return (
        <div className={`${moduleName}__container`}>
            <div className={`${moduleName}__unclaimed-section`}>
                <ChatListNavBar
                    search={search}
                    browsing={browsing}
                    claimedChats={claimedChats}
                    setSearch={onSetSearch}
                />

                {!search && <UnclaimedChatsList unclaimed={unclaimed} chats={chats} claimChat={onClaimChat} />}
            </div>

            <ClaimedChatsList
                list={list}
                chats={chats}
                claimedChats={claimedChats}
                myClaimedChats={myClaimedChats}
                selectedChatsIds={selectedChatsIds}
                search={search}
                claimChat={onClaimChat}
                scrollToChat={scrollToChat}
                attachChats={attachChats}
            />
        </div>
    )
}

export default ChatList
