import { mapWebCodeToModel } from 'Api/mappers/self-scheduling/webcodes'

import ApiService from '../../ApiV2/service'

export default class SelfSchedulingService extends ApiService {
    async getSelfSchedulingWebCodes(practiceId: string, params: { search: string; page: number }) {
        const { data, metadata } = (await this.fetch(
            `practices/${practiceId}/self-scheduling-web-codes`,
            {
                method: 'GET',
            },
            { ...params, limit: 8 },
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.SelfScheduling.WebCode>>
        return { webCodes: data.map(mapWebCodeToModel), metadata }
    }
}
