import React, { useCallback, useEffect, useRef, useState } from 'react'

import { usePrevious } from '../../modules/shared/custom-hooks'
import NotificationService from '../../services/NotificationService'

import ReferralCard from './ReferralCard'

import './ReferralsDashboardColumn.sass'

type Props = {
    status: ModelsV2.Referrals.PendingReferralStatus
    referrals: ModelsV2.Referrals.ReferralsDashboardCard[]
    openReferral: (referral: ModelsV2.Referrals.ReferralsDashboardCard) => void
    claimReferral?: (referral: ModelsV2.Referrals.ReferralsDashboardCard) => void
}

const ReferralsDashboardColumn = (props: Props) => {
    const timer = useRef<number | undefined>(undefined)
    const [soundPlayed, setSoundPlayed] = useState(false)
    const { status, referrals, claimReferral, openReferral } = props
    const prevReferrals = usePrevious<ModelsV2.Referrals.ReferralsDashboardCard[]>(referrals) || []

    const checkIfRefferalArrived = useCallback(
        (
            prevReferrals: ModelsV2.Referrals.ReferralsDashboardCard[],
            newReferrals: ModelsV2.Referrals.ReferralsDashboardCard[],
        ) => {
            let hasNewReferral = false
            const oldRefferalIds = prevReferrals.map(r => r.id)
            newReferrals.forEach(ref => {
                if (!oldRefferalIds.includes(ref.id)) {
                    hasNewReferral = true
                }
            })

            return hasNewReferral
        },
        [],
    )

    const playSound = useCallback((numberOfTimes: number) => {
        let timesRun = 0
        if (!timer.current) {
            const interval = window.setInterval(() => {
                timesRun += 1
                if (timesRun === numberOfTimes) {
                    stopSound()
                }
                NotificationService.playSound()
            }, 1000)
            setSoundPlayed(true)
            timer.current = interval
        }
    }, [])

    const stopSound = () => {
        clearInterval(timer.current)
        timer.current = undefined
    }
    useEffect(() => {
        if (status.value === 'unclaimed' && referrals.length > 0) {
            const ifArrived = checkIfRefferalArrived(prevReferrals, referrals)
            if (!soundPlayed || ifArrived) {
                playSound(3)
            }
        }
    }, [status, referrals, soundPlayed, checkIfRefferalArrived, playSound, prevReferrals])

    return (
        <div className="referrals-dashboard-column">
            <div className="referrals-dashboard-column-header">
                <div className="referrals-dashboard-column-title">
                    <strong>{referrals.length}</strong> {status.display}
                </div>
            </div>
            <div className="referrals-dashboard-column-body">
                {referrals.map(referral => (
                    <ReferralCard
                        key={referral.id}
                        statusValue={status.value}
                        referral={referral}
                        openReferral={openReferral}
                        claimReferral={claimReferral}
                    />
                ))}
            </div>
        </div>
    )
}

export default ReferralsDashboardColumn
