import { FormFieldElement } from '../modules/shared/form-validator/validator'

export enum PatientType {
    NEW = 'new',
    EXISTING = 'existing',
}

export enum ConnectPaymentStatus {
    NotSubmitted,
    Pending,
    Canceled,
    Verified,
}

export interface Enum<T> {
    id: number
    value: T
    display: string
}

export interface ConnectBookingFormElements {
    firstName: FormFieldElement
    lastName: FormFieldElement
    dateOfBirth: FormFieldElement
    mobilePhone: FormFieldElement
    email: FormFieldElement
    practiceLocation: FormFieldElement
    doxyURLIndex: FormFieldElement
    insurance: FormFieldElement
    insurancePhone: FormFieldElement
    insuranceGroup: FormFieldElement
    insuranceMemberId: FormFieldElement
    policyHolderFirstName: FormFieldElement
    policyHolderLastName: FormFieldElement
    policyHolderDateOfBirth: FormFieldElement
}

export interface ConnectPaymentDetails {
    payerId: string
    patientId?: string
}

export interface ConnectBooking {
    chat: Models.ChatMetadata
    patientType: PatientType
    isInsuranceRequired: boolean
    isPaymentAvailable: boolean
    isPolicyHolder: boolean
    paymentStatus: ConnectPaymentStatus
    formElements: ConnectBookingFormElements
    isFormValid: boolean
    isFormDirty: boolean
    isPending: boolean
    isBooked: boolean
    isShared: boolean
    hasError: boolean
    errorMessage?: string
    paymentPatientId?: string
    paymentPayerId?: string
    paymentClientSecret?: string
    connectTelehealthSessionId?: string
}

export interface DoxyUrlFormElement {
    url: FormFieldElement
    password: FormFieldElement
    uniqueKey: string
}

export interface ConnectSurveyFormElements {
    status: FormFieldElement
    doxy_urls: DoxyUrlFormElement[]
    patientType: FormFieldElement
    insuranceInformationStatus: FormFieldElement
    emails: FormFieldElement[]
    phoneNumber: FormFieldElement
    timeZone: FormFieldElement
    availableHours: AvailableHours[]
}

export interface ConnectSurveyForm {
    formElements: ConnectSurveyFormElements
    isFormValid: boolean
    isFormDirty: boolean
    errorMessage?: string
}

export type StatusValues = 'enabled' | 'disabled'
export interface Status extends Enum<StatusValues> {}

export type PatientTypeValues = 'new' | 'existing' | 'both'
export interface ConnectPatientType extends Enum<PatientTypeValues> {}

export type InsuranceInformationStatusValues = 'required' | 'optional'
export interface InsuranceInformationStatus extends Enum<InsuranceInformationStatusValues> {}

export type PaymentInformationStatusValues = 'optional' | 'required' | 'never'
export interface PaymentInformationStatus extends Enum<PaymentInformationStatusValues> {}

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6
export interface AvailableHours {
    available: boolean
    dayOfWeek: DayOfWeek
    startTime: string
    endTime: string
}

export interface DoxyURL {
    url: string
    password: string
}

export interface ConnectSurvey {
    id: string
    status: Status
    doxy_urls: DoxyURL[]
    patientType: ConnectPatientType
    insuranceInformationStatus: InsuranceInformationStatus
    emails: string[]
    phoneNumber: string
    timeZone: string
    availableHours: AvailableHours[]
}

export interface UpdateConnectSurvey {
    statusId?: number
    doxy_urls?: DoxyURL[]
    patientTypeId?: number
    insuranceInformationStatusId?: number
    emails?: string[]
    phoneNumber?: string
    timeZone?: string
    availableHours?: AvailableHours
}

export interface ConnectWebCode {
    id: string
    url: string
    connectSurvey?: ConnectSurvey
    connectSurveyId?: string
}
