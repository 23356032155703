export const mapCloseChatMetadataToChatMetadata = (chat: ApiV2.Amplify.CloseChatMetadata): Api.ChatMetadata => {
    return {
        id: chat.id,
        created: chat.created,
        updated: chat.updated,
        ip: chat.ip,
        channel_name: chat.channel_name,
        patient_name: chat.patient_name,
        property_id: chat.property_id,
        practice: {
            id: chat.practice.id,
            name: chat.practice.name,
        },
        meta: chat.meta,
        claimee: {
            id: chat.claimee_id,
            first_name: '',
            last_name: '',
        },
        status: chat.amplify_chat_status,
        last_message_timestamp: chat.last_message_timestamp,
        last_message_uuid: '',
        survey_id: '',
        reasons: chat.amplify_chat_close_reason.map(r => ({
            id: Number(r.reason_id),
            message: r.message,
            value: r.amplify_inbound_reason.value,
            created: r.created,
            display: r.amplify_inbound_reason.display,
        })),
        practice_specialty: {
            id: 1,
            value: 'dental',
            display: 'Dental',
        },
        first_response_time: chat.first_response_time,
    }
}
