import ApiService from '../service'

export default class AppService extends ApiService {
    // sort of a catch all service
    readonly ModulePath = 'api'

    async getSelf() {
        try {
            const getAccountResponse = (await this.fetch('admin/v1/self')) as Api.StructuredResponse<Api.Account>
            return getAccountResponse?.data
        } catch (error) {
            return error?.data ?? {}
        }
    }

    async getCountryCodeFromIp(ip: string) {
        const { data } = (await this.fetch(`admin/services/v1/ipstack/${ip}/countryCode`)) as Api.StructuredResponse<
            Api.IpstackCountryCode
        >
        return data
    }

    async getZipCodeTimezone(zipCode: string): Promise<Api.ZipCodeDetails | null> {
        try {
            const { data: zipCodeDetails } = (await this.silentFetch({
                endpoint: `admin/services/v1/zip-code/${zipCode}`,
            })) as Api.StructuredResponse<Api.ZipCodeDetails>
            return zipCodeDetails
        } catch (err) {
            throw err
        }
    }
}
