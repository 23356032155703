import * as React from 'react'
import classNames from 'classnames'

import AdminAccount from '../../models/AdminAccount'

import PaymentsMainContainer from './PaymentsMainContainer'

type PaymentsTabProps = {
    practice: Models.Practice
    account: AdminAccount
}

class PaymentsTab extends React.Component<PaymentsTabProps> {
    render() {
        return (
            <div className={classNames('payments-tab')}>
                <PaymentsMainContainer practice={this.props.practice} />
            </div>
        )
    }
}

export default PaymentsTab
