import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../../../../appReducer'
import { UpdatePSTFacilityId } from '../../../../../models/enums'
import { getTimeZonesForCountry } from '../../utils'
import { validateForm } from '../utils'

export type FormFieldErrorModel = {
    isValid: boolean
    errorMessage?: string
}

export type FormFieldValidationModel = FormFieldErrorModel & {
    isTouched: boolean
}

export type FormField<T> = FormFieldValidationModel & { value: T }

export type LocationDataModel = {
    street: string
    unit?: string
    city: string
    state: string
    zip: string
    countryCodeIsoAlpha2?: string
    timezone: string
    timezoneListForCountry: string[]
    lat: string
    lng: string
    phone: string
    locationId?: string
    facilityId?: string
    practiceSpecialtyType?: Models.PracticeSpecialtyValue
    products?: string[]
    name: string
    logo: {
        id: string | null
        bgColor: string | null
        data: any | null
        shouldDelete: boolean
    }
    jarvisEnabled: boolean
    software?: string
    version?: string
    ipAddress?: string
    paymentInformationStatus?: string
    paymentAmount?: string
    description?: string
}

export type LocationFormDataModel = { [key in keyof LocationDataModel]: FormField<LocationDataModel[key]> }

export interface LocationFormHookResult {
    formValues: LocationFormDataModel
    isDirty: boolean
    isValid: boolean
    hasEditFacilityIdPermission: boolean
    hasEhrIntegrationFields: boolean
    hasDirectSchedulingFields: boolean
    handleFormFieldChange: <T>(
        key: keyof LocationFormDataModel,
        value: T,
        meta?: Partial<FormFieldValidationModel>,
        makeDirty?: boolean,
    ) => void
    handleFormFieldsBulkChange: (fields: Partial<LocationFormDataModel>, makeDirty?: boolean) => void
    handleInputChange: (
        key: keyof LocationFormDataModel,
        meta?: Partial<FormFieldValidationModel>,
    ) => (event: ChangeEvent<HTMLInputElement>) => void
    handleSwitchChange: (
        key: keyof LocationFormDataModel,
        meta?: Partial<FormFieldValidationModel>,
    ) => (event: ChangeEvent<HTMLInputElement>) => void
}

const defaultFieldValidationData: FormFieldValidationModel = {
    isTouched: false,
    isValid: true,
}

const generateFormField = <T>(value: T): FormField<T> => ({
    ...defaultFieldValidationData,
    value,
})

const defaultLocationFormData: LocationFormDataModel = {
    jarvisEnabled: { ...generateFormField(false) },
    practiceSpecialtyType: { ...generateFormField(undefined) },
    name: { ...generateFormField('') },
    street: { ...generateFormField('') },
    unit: { ...generateFormField('') },
    city: { ...generateFormField('') },
    state: { ...generateFormField('') },
    zip: { ...generateFormField('') },
    countryCodeIsoAlpha2: { ...generateFormField('US') },
    timezone: { ...generateFormField('') },
    timezoneListForCountry: { ...generateFormField(getTimeZonesForCountry('US')) },
    lat: { ...generateFormField('') },
    lng: { ...generateFormField('') },
    phone: { ...generateFormField('') },
    logo: {
        ...generateFormField({
            id: null,
            bgColor: null,
            data: null,
            shouldDelete: false,
        }),
    },
}

const useLocationForm = (
    locationFormData: Models.PracticeLocation | null,
    hasPayments: boolean,
    hasStripeAccount: boolean,
    isEdit: boolean = false,
    isLoading: boolean = false,
): LocationFormHookResult => {
    const [isDirty, setIsDirty] = useState<boolean>(false)

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)

    const hasEditFacilityIdPermission = Boolean(account?.hasAccess(UpdatePSTFacilityId))

    const canEditForm = locationFormData !== null && Boolean(locationFormData?.id)

    const generateEditFormData = useCallback((): LocationFormDataModel => {
        return {
            ...defaultLocationFormData,
            ...(locationFormData?.address
                ? {
                      street: generateFormField(locationFormData.address.street || ''),
                      unit: generateFormField(locationFormData.address.unit || ''),
                      city: generateFormField(locationFormData.address.city || ''),
                      state: generateFormField(locationFormData.address.state || ''),
                      zip: generateFormField(locationFormData.address.zip || ''),
                      countryCodeIsoAlpha2: generateFormField(locationFormData.address.countryCodeIsoAlpha2 || 'US'),
                      lng: generateFormField(locationFormData.address?.lng?.toString() ?? ''),
                      lat: generateFormField(locationFormData.address?.lat?.toString() ?? ''),
                  }
                : {}),
            ...(locationFormData?.practiceSpecialtyType?.value
                ? {
                      practiceSpecialtyType: generateFormField(locationFormData.practiceSpecialtyType.value),
                  }
                : {}),
            ...(locationFormData?.logo
                ? {
                      logo: generateFormField({
                          id: locationFormData.logo.id || null,
                          bgColor: locationFormData.logo.bgColor || null,
                          data: locationFormData.logo.url || null,
                          shouldDelete: false,
                      }),
                  }
                : {}),
            timezone: generateFormField(locationFormData?.timezone ?? ''),
            timezoneListForCountry: generateFormField(
                getTimeZonesForCountry(locationFormData?.address?.countryCodeIsoAlpha2 ?? 'US'),
            ),
            jarvisEnabled: generateFormField(locationFormData?.jarvisEnabled ?? false),
            software: generateFormField(locationFormData?.schedulingDetails?.software ?? ''),
            version: generateFormField(locationFormData?.schedulingDetails?.version ?? ''),
            ipAddress: generateFormField(locationFormData?.schedulingDetails?.ipAddress ?? ''),
            phone: generateFormField(locationFormData?.phoneNumber ?? ''),
            locationId: generateFormField(locationFormData?.id ?? ''),
            facilityId: generateFormField(locationFormData?.facilityId ?? ''),
            name: generateFormField(locationFormData?.name ?? ''),
            paymentAmount: generateFormField(locationFormData?.connectPaymentInfo?.amount ?? undefined),
            paymentInformationStatus: generateFormField(
                locationFormData?.connectPaymentInfo?.paymentInformationStatusId?.toString() ?? undefined,
            ),
        }
    }, [locationFormData])

    const [formValues, setFormValues] = useState<LocationFormDataModel>(
        canEditForm ? generateEditFormData() : { ...defaultLocationFormData },
    )

    useEffect(() => {
        if (canEditForm) {
            const editData = generateEditFormData()
            setFormValues({ ...editData })
        } else {
            setFormValues({ ...defaultLocationFormData })
        }
    }, [canEditForm, generateEditFormData])

    const handleFormFieldChange = <T>(
        key: keyof LocationFormDataModel,
        value: T,
        meta: Partial<FormFieldValidationModel> | undefined = {},
        makeDirty: boolean | undefined = true,
    ) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [key]: {
                ...prevValues[key],
                value,
                isTouched: true,
                ...meta,
            },
        }))
        makeDirty && setIsDirty(true)
    }

    const handleFormFieldsBulkChange = (
        fields: Partial<LocationFormDataModel>,
        makeDirty: boolean | undefined = true,
    ) => {
        setFormValues(prevValues => ({
            ...prevValues,
            ...fields,
        }))
        makeDirty && setIsDirty(true)
    }

    const handleInputChange = (
        key: keyof LocationFormDataModel,
        meta: Partial<FormFieldValidationModel> | undefined = {},
    ) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) => handleFormFieldChange(key, value, meta)

    const handleSwitchChange = (
        key: keyof LocationFormDataModel,
        meta: Partial<FormFieldValidationModel> | undefined = {},
    ) => ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        if (key === 'jarvisEnabled') {
            if (checked) {
                handleFormFieldChange('software', 'dentrix')
            } else {
                handleFormFieldsBulkChange({
                    software: generateFormField(undefined),
                    ipAddress: generateFormField(undefined),
                    version: generateFormField(undefined),
                })
            }
        }
        handleFormFieldChange(key, checked, meta)
    }

    const hasEhrIntegrationFields = () => {
        return Boolean(formValues.software?.value || formValues.version?.value || formValues.timezone.value)
    }

    const hasDirectSchedulingFields = () => {
        return Boolean(formValues.software?.value || formValues.version?.value || formValues.ipAddress?.value)
    }

    return {
        formValues,
        isDirty,
        hasEditFacilityIdPermission,
        isValid: validateForm(
            formValues,
            hasPayments,
            hasStripeAccount,
            isEdit,
            hasEditFacilityIdPermission,
            isLoading,
        ),
        hasEhrIntegrationFields: hasEhrIntegrationFields(),
        hasDirectSchedulingFields: hasDirectSchedulingFields(),
        handleFormFieldChange,
        handleFormFieldsBulkChange,
        handleInputChange,
        handleSwitchChange,
    }
}
export { useLocationForm }
