import ApiService from '../../ApiV2/service'

export default class AdminService extends ApiService {
    private readonly PAGE_SIZE = 5

    async list(
        list: Api.AdminListType,
        searchTerms: ApiV2.Admin.AdminUsersSearchTerms = {},
    ): Promise<ApiV2.StructuredResponse<ApiV2.Admin.Account[]>> {
        const { limit = this.PAGE_SIZE, page = 1, search, sort = 'created', order = 'desc' } = searchTerms

        const structuredResponse = (await this.fetch(
            `accounts/admins`,
            {},
            {
                limit,
                page,
                list,
                search,
                sort,
                order,
            },
            undefined,
            undefined,
            ['permission', 'account_type', 'account_status'],
        )) as ApiV2.StructuredResponse<ApiV2.Admin.Account[]>

        return structuredResponse
    }

    async listAll(list: Api.AdminListType, page: number = 1): Promise<ApiV2.StructuredResponse<ApiV2.Admin.Account[]>> {
        const limit = 50

        const admins = (await this.fetch(
            `accounts/admins`,
            {},
            {
                limit,
                page,
                list,
            },
            undefined,
            undefined,
            ['permission', 'account_type', 'account_status'],
        )) as ApiV2.StructuredResponse<ApiV2.Admin.Account[]>

        const allPages = Number(admins.metadata?.pagination_info?.allPages)
        const adminData = admins.data

        if (allPages > page) {
            return {
                data: adminData.concat((await this.listAll(list, page + 1)).data),
                metadata: admins.metadata,
            }
        } else {
            return { data: admins.data, metadata: admins.metadata }
        }
    }
}
