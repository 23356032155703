/* tslint:disable:max-line-length */
const DENTAL_QUESTION_LIST: Array<Models.DefaultSurveyQuestion> = [
    {
        id: 'connectHours',
        question: '<b>Connect hours:</b>',
        htmlClass: 'big',
    },
    {
        id: '95264869',
        question: 'What is/are the name(s) of your <b>practice(s)?</b>',
        htmlClass: 'big',
    },
    {
        id: '95264870',
        question: '<b>Doctor(s):</b>',
        htmlClass: 'small',
    },
    {
        id: '95264888',
        question: '<b>Address(es):</b>',
        htmlClass: 'small',
    },
    {
        id: '95264889',
        question: '<b>Phone</b> number(s):',
        htmlClass: 'small',
    },
    {
        id: '95264892',
        question: '<b>Emergency</b> number:',
        htmlClass: 'small',
    },
    {
        id: '95264903',
        question: 'Do you allow <b>non-existing patients</b> to contact your emergency number?',
        htmlClass: 'small',
    },
    {
        id: '95264905',
        question: '<b>Fax</b> number?',
        htmlClass: 'small',
    },
    {
        id: '95264906',
        question: '<b>Hours</b> of operation?',
        htmlClass: 'small',
    },
    {
        id: '95264908',
        question: 'What <b>holidays</b> does the office close for?',
        htmlClass: 'small',
    },
    {
        id: '95264928',
        question: 'What <b>payment options</b> does your practice offer?',
        htmlClass: 'big',
    },
    {
        id: '95264933',
        question: 'Do you accept <b>Medicaid</b>?',
        htmlClass: 'small',
    },
    {
        id: '95264931',
        question: 'State sponsored <b>Medicaid</b>:',
        htmlClass: 'small',
    },
    {
        id: '95264937',
        question: '<b>Insurances</b> accepted:',
        htmlClass: 'small',
    },
    {
        id: '95264965',
        question: "If you do not accept a patient's specific <b>insurance</b>, how would you like us to reply to them?",
        htmlClass: 'small',
    },
    {
        id: '95264967',
        question: 'What <b>treatments</b> does your practice provide?',
        htmlClass: 'big',
    },
    {
        id: '95264909',
        question: 'Do you have a <b>complimentary consultation</b>?',
        htmlClass: 'small',
    },
    {
        id: '95264927',
        question: 'What is <b>included in the consultation</b>?',
        htmlClass: 'small',
    },
    {
        id: '95264966',
        question: 'What <b>age range</b> of patients do you accept?',
        htmlClass: 'small',
    },
    {
        id: '95264872',
        question: 'Who (or what business) is in charge of your <b>website</b>?',
        htmlClass: 'big',
    },
    {
        id: '95264873',
        question: "<b>Web developer's email address:</b>",
        htmlClass: 'small',
    },
    {
        id: '95264874',
        question: "<b>Web developer's phone number:</b>",
        htmlClass: 'small',
    },
    {
        id: '95264875',
        question: 'What is/are the practice website <b>URL</b> / address(es)?',
        htmlClass: 'small',
    },
    {
        id: '95264890',
        question: 'At what email address(es) would you like to receive Amplify <b>referrals</b>?',
        htmlClass: 'small',
    },
    {
        id: '95264994',
        question:
            'For the purpose of composing customized scripting, <b>what can we say about your doctor(s), your team, or your practice</b>?',
        htmlClass: 'big',
    },
    {
        id: '95264991',
        question: 'What can we say to <b>draw patients</b> to your practice?',
        htmlClass: 'small',
    },
    {
        id: '95264980',
        question: 'What <b>differentiates</b> your practice from your competitors?',
        htmlClass: 'small',
    },
    {
        id: '95264996',
        question: 'Is there <b>anything else</b> we should know about your practice?',
        htmlClass: 'small',
    },
    {
        id: '157851533',
        question: 'Please provide the office email address that patients may use.',
        htmlClass: 'small',
    },
    {
        id: '157768813',
        question: 'Please provide the link to your online Patient Forms.',
        htmlClass: 'small',
    },
].map(surveyItem => ({
    // Create raw (lowercase, no formatting) version for search
    questionRaw: surveyItem.question.toLowerCase().replace(/<b>|<\/b>/i, ''),
    ...surveyItem,
}))

export default DENTAL_QUESTION_LIST
