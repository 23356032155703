import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@mui/material'

import { RootState } from '../../../../appReducer'
import { useAppDispatch } from '../../../../util/useAppDispatch'
import { terminalColors } from '../constants'

import { closeTerminalModal, createTerminal, deleteTerminal, updateTerminalModalData } from './actions'
import TerminalForm, { AddTerminalRadio } from './TerminalForm'
import { validateTerminal } from './utils'

interface Props {
    practiceId: Models.Practice['id']
}

const TerminalModal = ({ practiceId }: Props) => {
    const dispatch = useAppDispatch()
    const {
        locationId,
        terminalId,
        isView,
        isLoading,
        isOpen,
        nickname,
        readerSerial,
        pairingCode,
        s700Code,
        uuid,
        addTerminalRadio,
        stripeLocationId,
    } = useSelector((state: RootState) => {
        return {
            ...state.practiceLocations[practiceId].modals.terminal,
            stripeLocationId: state.practices.practices[practiceId].locations.find(
                l => l.id === state.practiceLocations[practiceId].modals.terminal.locationId,
            )?.stripeLocationId,
        }
    })

    const handleTerminalColorChange = (nickname: string): void => {
        if (isLoading) return

        dispatch(updateTerminalModalData({ nickname }, practiceId))
    }

    const handleTerminalModeChange = (mode: AddTerminalRadio): void => {
        if (isLoading) return

        dispatch(
            updateTerminalModalData(
                {
                    addTerminalRadio: mode,
                    uuid: undefined,
                    pairingCode: undefined,
                    s700Code: undefined,
                },
                practiceId,
            ),
        )
    }

    const handleTerminalReaderChange = (updatedSerial: string): void => {
        if (isLoading) return

        dispatch(
            updateTerminalModalData(
                {
                    readerSerial: updatedSerial,
                },
                practiceId,
            ),
        )
    }

    const handleTerminalPairingCodeChange = (pairingCode: string): void => {
        if (isLoading) return

        dispatch(
            updateTerminalModalData(
                {
                    pairingCode,
                    uuid: undefined,
                },
                practiceId,
            ),
        )
    }

    const handleTerminalUUIDChange = (updatedUuid: string): void => {
        if (isLoading) return

        dispatch(
            updateTerminalModalData(
                {
                    uuid: updatedUuid,
                    pairingCode: undefined,
                },
                practiceId,
            ),
        )
    }

    const handleS700CodeChange = (s700Code: string): void => {
        if (isLoading) return

        dispatch(
            updateTerminalModalData(
                {
                    s700Code,
                    readerSerial: undefined,
                    uuid: undefined,
                    pairingCode: undefined,
                },
                practiceId,
            ),
        )
    }

    const handleDeleteTerminalModal = (): void => {
        const confirmDelete = window.confirm(`Are you sure you want to delete "${nickname}"?`)

        if (!confirmDelete) return

        dispatch(deleteTerminal(practiceId, locationId, terminalId, nickname))
    }

    const handleCreateTerminal = (): void => {
        if (!validateTerminal(nickname, readerSerial, pairingCode, s700Code, uuid)) return

        const newTerminal = {
            id: '',
            nickname,
            backgroundColor: terminalColors[nickname.toLowerCase()],
            readerSerial,
            pairingCode,
            s700Code,
            uuid,
        }
        dispatch(createTerminal(practiceId, locationId, newTerminal, stripeLocationId))
    }

    const handleCloseModal = (): void => {
        dispatch(closeTerminalModal(practiceId))
    }

    if (!isOpen) {
        return null
    }

    return (
        <Modal
            open={isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleCloseModal()
                }
            }}
            disableEscapeKeyDown
        >
            <TerminalForm
                isView={isView}
                isOpen={isOpen}
                isLoading={isLoading}
                terminalNickname={nickname}
                addTerminalMode={addTerminalRadio}
                readerSerial={readerSerial}
                wisePairingCode={pairingCode}
                stripeLocationId={stripeLocationId}
                terminalUUID={uuid}
                onTerminalColorChange={handleTerminalColorChange}
                onTerminalModeChange={handleTerminalModeChange}
                onTerminalReaderSerialChange={handleTerminalReaderChange}
                onTerminalReaderPairingCodeChange={handleTerminalPairingCodeChange}
                onTerminalUUIDChange={handleTerminalUUIDChange}
                onClose={handleCloseModal}
                onSubmit={handleCreateTerminal}
                onDeleteTerminal={handleDeleteTerminalModal}
                s700RegistrationCode={s700Code}
                onTerminalReaderS700CodeChange={handleS700CodeChange}
            />
        </Modal>
    )
}

export type TerminalModalProps = Props
export default TerminalModal
