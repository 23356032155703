import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Table, { TableClassKey } from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import _ from 'lodash'
import moment from 'moment'

import { SortingDirection } from '../../models/enums'
import { moveDefaultLocationToTop } from '../../modules/shared/sortUtils'
import { fetchAdmins } from '../admin/actions'
import { fetchReferralList } from '../amplify/actions'
import CustomMultiselectField from '../shared/custom-fields/CustomMultiselectField'
import { SearchBar } from '../shared/custom-fields/SearchBar'
import InfoMessage from '../shared/info-message/info-message'
import Paginator from '../shared/Paginator'
import { ArrowDropDown } from '../shared/svgIcons'

import AmplifyReferral from './AmplifyReferral'

import './AmplifyManagement.sass'

export type AmplifyManagementProps = Readonly<{
    practice: Models.Practice
    referrals?: Array<Models.Referral>
    admins: { [key: string]: Models.Account }
    paginationInfo?: Models.PaginationInfo
}>

export type AmplifyManagementDispatch = Readonly<{
    fetchReferralList: typeof fetchReferralList
    fetchAdmins: typeof fetchAdmins
}>

export type AmplifyManagementState = {
    days: number | 'all'
    searchTerms: ApiV2.Amplify.ReferralSearchTerms
}

type Props = AmplifyManagementProps & AmplifyManagementDispatch

type TableClassOverrides = {
    readonly [key in TableClassKey]?: string
}

const pickerDaysRanges = [
    { id: 'all', name: 'All Time' },
    { id: '0', name: 'Today' },
    { id: '7', name: 'Last 7 Days' },
    { id: '30', name: 'Last 30 Days' },
    { id: '90', name: 'Last 90 Days' },
]

class AmplifyManagement extends React.Component<Props, AmplifyManagementState> {
    throttledFetchReferralList: () => void

    constructor(props: Props) {
        super(props)
        this.state = {
            days: 'all',
            searchTerms: {
                page: 1,
                location_ids: undefined,
                sort: 'updated',
                order: 'desc',
            },
        }

        this.throttledFetchReferralList = _.throttle(this.fetchReferralList, 750)
    }

    UNSAFE_componentWillMount() {
        this.props.fetchReferralList(this.props.practice, {})
        this.props.fetchAdmins('amplify-chatters', { limit: 0 })
    }

    getSortingDirectionOfProperty = (sortByProperty: ApiV2.Amplify.AmplifyReferralSort) => {
        if (sortByProperty === this.state.searchTerms.sort) {
            return this.state.searchTerms.order === 'desc' ? 'asc' : 'desc'
        }

        return 'desc'
    }
    sortByProperty = (sortByProperty: ApiV2.Amplify.AmplifyReferralSort) => {
        const sortingDirection: SortingDirection = this.getSortingDirectionOfProperty(sortByProperty)
        this.setSearchTermsState({ sort: sortByProperty, order: sortingDirection })
    }

    selectDays = (values: string[]) => {
        const value = values[0]
        const days = value === 'all' ? value : parseInt(value, 10)
        this.setState({ days }, () =>
            this.setSearchTermsState({
                start_date:
                    value !== 'all'
                        ? moment()
                              .startOf('day')
                              .subtract(value, 'days')
                              .toISOString()
                        : undefined,
            }),
        )
    }
    selectLocation = (values: string[]) => {
        const value = values[0] ?? 'all'
        const locationId = value !== 'all' ? value : undefined
        this.setSearchTermsState({ location_ids: locationId })
    }
    selectPage = (page: number) => {
        this.setSearchTermsState({ page })
    }
    searchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = e.target && e.target.value
        this.setSearchTermsState({ search: searchKey, page: 1 })
    }
    clearSearch = () => {
        const searchKey = ''
        this.setSearchTermsState({ search: searchKey, page: 1 })
    }
    setSearchTermsState = (searchTerms: ApiV2.Amplify.ReferralSearchTerms) => {
        this.setState(
            iassign(
                this.state,
                next => next.searchTerms,
                next => {
                    return {
                        ...next,
                        ...searchTerms,
                    }
                },
            ),
            () => this.throttledFetchReferralList(),
        )
    }
    fetchReferralList = () => {
        this.props.fetchReferralList(this.props.practice, { ...this.state.searchTerms })
    }

    render() {
        const { practice, referrals, admins, paginationInfo } = this.props
        const {
            days,
            searchTerms: { sort, order, location_ids = 'all', page = 1, search = '' },
        } = this.state

        const sortedLocations = _.sortBy([...practice.locations], l => l.name.toLowerCase()).sort(
            moveDefaultLocationToTop,
        )

        const storedLocationsWithAll = [{ id: 'all', name: 'All Locations' }, ...sortedLocations]

        const tableClassOverrides: TableClassOverrides = {
            root: 'amplify-management-table',
        }

        if (!referrals) {
            return (
                <div className="circular-progress-loader">
                    <CircularProgress size={70} color="primary" variant="indeterminate" />
                </div>
            )
        }

        return (
            <div className={classNames('amplify-management')}>
                <div className="filter-select">
                    <div className="amplify-management-select-location">
                        <CustomMultiselectField
                            items={storedLocationsWithAll}
                            maxSelected={1}
                            selectedItems={[location_ids]}
                            keyProperty="id"
                            displayProperty="name"
                            placeholder="Select location"
                            onSelectElement={this.selectLocation}
                            search={sortedLocations?.length > 8}
                            searchPlaceholder="Search Location"
                        />
                    </div>
                    <div className="amplify-management-select-days">
                        <CustomMultiselectField
                            items={pickerDaysRanges}
                            maxSelected={1}
                            selectedItems={[days.toString()]}
                            keyProperty="id"
                            displayProperty="name"
                            placeholder="Select time"
                            onSelectElement={this.selectDays}
                        />
                    </div>
                    <div className="amplify-management-search-box">
                        <SearchBar
                            value={search}
                            onChange={this.searchByKeyword}
                            onClear={this.clearSearch}
                            placeholder="Search by Request Name or Request ID"
                        />
                    </div>
                </div>
                <div className="amplify-management__production-values">
                    <div className="amplify-management__production-value">
                        <div className="amplify-management__production-value-label">Requests</div>
                        <div className="amplify-management__production-value-sum">{paginationInfo?.allRows ?? 0}</div>
                    </div>
                </div>
                {referrals.length ? (
                    <Table classes={tableClassOverrides}>
                        <colgroup>
                            <col style={{ width: '6%' }} />
                            <col style={{ width: '8%' }} />'
                            <col style={{ width: '9%' }} />'
                            <col style={{ width: '14%' }} />
                            <col style={{ width: '14%' }} />
                            <col style={{ width: '16%' }} />
                            <col style={{ width: '16%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '7%' }} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell className="table-head-cell">ID</TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'amplify_referral_patient.last_name'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('amplify_referral_patient.last_name')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        Last Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'amplify_referral_patient.first_name'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('amplify_referral_patient.first_name')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        First Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'created'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('created')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        Created
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'updated'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('updated')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        Last Edit
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'referral_creator.last_name'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('referral_creator.last_name')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        Request Writer
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'inbound_handler.last_name'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('inbound_handler.last_name')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        Chatter
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell">
                                    <TableSortLabel
                                        active={sort === 'amplify_status.value'}
                                        direction={order}
                                        onClick={() => {
                                            this.sortByProperty('amplify_status.value')
                                        }}
                                        IconComponent={ArrowDropDown}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="table-head-cell" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {referrals.map(referral => (
                                <AmplifyReferral
                                    key={referral.id}
                                    v2referral={referral}
                                    referralId={referral.id}
                                    practice={practice}
                                    referralCreator={admins[referral.referralCreatorId]}
                                    inboundHandler={admins[referral.inboundHandlerId]}
                                />
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>
                )}
                <div className="paginator-wrapper">
                    <Paginator currentPage={page} paginationInfo={paginationInfo} selectPage={this.selectPage} />
                </div>
            </div>
        )
    }
}

export default AmplifyManagement
