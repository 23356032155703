import React, { useState } from 'react'
import Checkbox, { CheckboxClassKey } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelClassKey } from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import Switch, { SwitchClassKey } from '@mui/material/Switch'

import CustomTooltip from '../../../shared/custom-tooltip/CustomTooltipWithPortal'
import { mainBlue } from '../../../shared/styles/colors'

import './SelfSchedulingWebCodeLocation.sass'

const moduleName = 'self-scheduling-web-code-location'

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

type CheckboxClassOverrides = {
    readonly [key in CheckboxClassKey]?: string
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    root: `${moduleName}__form-control-root`,
    label: `${moduleName}__form-control-label`,
}

const checkboxClassOverrides: CheckboxClassOverrides = {
    root: `${moduleName}__checkbox-root`,
}

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch`,
    checked: `${moduleName}__switch--checked`,
}

export type Props = {
    location: Models.SelfSchedulingWebCodeLocation
    showRSVPSwitches: boolean
    hasRSVPOption: boolean
    rsvpEnabled: boolean
    rsvpInfoMessage?: string
    parentRect: any
    modalRef: React.RefObject<any>
    handleTooltipClick: () => void
    forceCloseTooltip: boolean
    updateLocation: (locationId: string, enabled: boolean) => void
    updateRSVP: (locationId: string, enabled: boolean) => void
}

const SelfSchedulingWebCodeLocation = ({
    location,
    showRSVPSwitches,
    hasRSVPOption,
    rsvpEnabled,
    rsvpInfoMessage,
    parentRect,
    modalRef,
    handleTooltipClick,
    forceCloseTooltip,
    updateLocation,
    updateRSVP,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleOnChange = async () => {
        setLoading(true)
        await updateLocation(location.practiceLocationId, !location.enabled)
        if (location.enabled && showRSVPSwitches && rsvpEnabled) {
            await updateRSVP(location.practiceLocationId, false)
        }
        setLoading(false)
    }

    const handleOnChangeRSVP = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        setLoading(true)
        await updateRSVP(location.practiceLocationId, checked)
        setLoading(false)
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__location-section`}>
                <FormControlLabel
                    classes={formControlLabelClassOverrides}
                    control={
                        <Checkbox
                            disabled={loading}
                            classes={checkboxClassOverrides}
                            checked={location.enabled}
                            onChange={handleOnChange}
                            value={location.practiceLocationId}
                        />
                    }
                    label={''}
                />

                <label className={`${moduleName}__label ${moduleName}__label--checkbox`}>
                    <div className={`${moduleName}__text`}>{location.name}</div>
                </label>
            </div>
            {showRSVPSwitches && (
                <div className={`${moduleName}__rsvp-section`}>
                    {rsvpInfoMessage && location.enabled && (
                        <CustomTooltip
                            position="top-left"
                            text={rsvpInfoMessage}
                            parentRect={parentRect}
                            scrollableWindow={modalRef}
                            topOffset={165}
                            leftOffset={60}
                            onTooltipClick={handleTooltipClick}
                            forceClose={forceCloseTooltip}
                        >
                            <Icon style={{ position: 'absolute', fontSize: '21px', color: mainBlue }}>info</Icon>
                        </CustomTooltip>
                    )}
                    <FormControlLabel
                        classes={formControlLabelClassOverrides}
                        control={
                            <Switch
                                disabled={loading || !hasRSVPOption || !location.enabled}
                                checked={rsvpEnabled}
                                classes={switchClassOverrides}
                                onChange={handleOnChangeRSVP}
                                value={location.practiceLocationId}
                                color="primary"
                            />
                        }
                        label={''}
                    />
                    <label className={`${moduleName}__label`}>
                        <div className={`${moduleName}__text`}>Campaigns</div>
                    </label>
                </div>
            )}
        </div>
    )
}

export default SelfSchedulingWebCodeLocation
