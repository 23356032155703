import iassign from 'immutable-assign'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'

import { SurveyActivityLogData } from '../../../../models/v2/PracticeSurvey'

import { ReceiveLocationSurveyActivityLogs, SURVEY_RECEIVE_ACTIVITY_LOGS } from './v2actions'

export type PracticeSurveyState = {
    activityLogsData: { [key: string]: SurveyActivityLogData }
}

const initialState = (): PracticeSurveyState => ({
    activityLogsData: {},
})

type PracticeSurveyAction = ReceiveLocationSurveyActivityLogs

export function reducer(
    state: PracticeSurveyState = initialState(),
    action: PracticeSurveyAction,
): PracticeSurveyState {
    switch (action.type) {
        case SURVEY_RECEIVE_ACTIVITY_LOGS: {
            const { surveyId, activityLogs, paginationInfo, page } = action.receiveSurveyActivityLogs
            return iassign(
                state,
                next => next.activityLogsData[surveyId],
                nextActivityLogsData => {
                    if (!nextActivityLogsData || page === 1) {
                        const newActivityLogsData = {
                            activityLogs: sortBy(activityLogs, 'created'),
                            paginationInfo,
                            currentPage: page,
                        }
                        nextActivityLogsData = newActivityLogsData
                    } else {
                        nextActivityLogsData.activityLogs = sortBy(
                            unionBy(activityLogs, nextActivityLogsData.activityLogs, 'id'),
                            'created',
                        )
                        nextActivityLogsData.paginationInfo = paginationInfo
                        nextActivityLogsData.currentPage = page
                    }

                    return nextActivityLogsData
                },
            )
        }

        default:
            return state
    }
}
