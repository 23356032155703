import { useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import './StaffConfirmationBox.sass'

export type StaffConfirmationBoxType =
    | 'resendInvitation'
    | 'deleteAccount'
    | 'resetPassword'
    | 'deactivateAccount'
    | 'reactivateAccount'
    | 'restoreAccount'
    | 'deleteEmail'
    | 'deletePhoneNumber'
    | 'impersonateUser'

export type StaffConfrimationBoxProps = {
    type?: StaffConfirmationBoxType
    employee?: ModelsV2.Practice.PracticeStaffData
    title?: string
}

export type StaffConfrimationBoxDispatch = {
    confirmFn?: () => void
    closeFn: () => void
}

export interface StaffConfirmationBoxParams {
    type: StaffConfirmationBoxType
    callback: () => void
    title?: string
}

type Props = StaffConfrimationBoxProps & StaffConfrimationBoxDispatch

const StaffConfirmationBox = ({ employee, title, type, confirmFn, closeFn }: Props) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const buildText = () => {
        const employeeName = `${employee?.first_name} ${employee?.last_name}`
        switch (type) {
            case 'resendInvitation':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons green">email</i>
                            </Grid>
                            <Grid item={true} className="text">
                                Are you sure you want to resend an invitation to "{employee && employeeName}"?
                            </Grid>
                        </Grid>
                        <div>This user will receive an email with link to create their Vyne Trellis account.</div>
                    </>
                )
            case 'impersonateUser':
                return (
                    <Grid
                        container={true}
                        spacing={2}
                        className="title"
                        wrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item={true}>
                            <i className="material-icons red">warning</i>
                        </Grid>
                        <Grid item={true} className="text">
                            Are you sure you want to impersonate user "{employee && employeeName}"?
                        </Grid>
                    </Grid>
                )
            case 'deleteAccount':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">remove_circle</i>
                            </Grid>
                            <Grid item={true} className="text">
                                Are you sure you want to delete user "{employee && employeeName}"?
                            </Grid>
                        </Grid>
                        <div>
                            This user account will no longer be able to login to access the Dashboard or receive
                            notifications.
                            <br />
                            <br />
                            You may restore this user account within the next 30 days, after which the user account will
                            be permanently deleted and can not be restored.
                        </div>
                    </>
                )
            case 'deactivateAccount':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">flash_off</i>
                            </Grid>
                            <Grid item={true} className="text">
                                Are you sure you want to deactivate account access for "{employee && employeeName}"?
                            </Grid>
                        </Grid>
                        <div>
                            This user account will no longer be able to login to access the Dashboard or receive
                            notifications.
                            <br />
                            <br />
                            You may reactivate this user account at any time.
                        </div>
                    </>
                )
            case 'reactivateAccount':
                return (
                    <Grid
                        container={true}
                        spacing={2}
                        className="title"
                        wrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item={true}>
                            <i className="material-icons green">flash_on</i>
                        </Grid>
                        <Grid item={true} className="text">
                            Are you sure you want to reactivate account access for "{employee && employeeName}
                            "?
                        </Grid>
                    </Grid>
                )
            case 'restoreAccount':
                return (
                    <Grid
                        container={true}
                        spacing={2}
                        className="title"
                        wrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item={true}>
                            <i className="material-icons green">cached</i>
                        </Grid>
                        <Grid item={true} className="text">
                            Are you sure you want to restore account for user "{employee && employeeName}"?
                        </Grid>
                    </Grid>
                )
            case 'resetPassword':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons green">refresh</i>
                            </Grid>
                            <Grid className="text">
                                Are you sure you want to send a password reset to{' '}
                                <span className="staff-member-name">"{employee && employeeName}"</span>?
                            </Grid>
                        </Grid>
                        <div>This user will receive an email with a link to reset their password.</div>
                    </>
                )
            case 'deleteEmail':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">email</i>
                            </Grid>
                            <Grid item={true} className="text">
                                {title}
                            </Grid>
                        </Grid>
                        <div>
                            Deleting this email will remove it from this account. The User will no longer receive
                            notifications at this email address.
                        </div>
                    </>
                )
            case 'deletePhoneNumber':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">warning</i>
                            </Grid>
                            <Grid item={true} className="text">
                                {title}
                            </Grid>
                        </Grid>
                        <div>
                            Deleting this mobile phone will remove it from this account. The User will no longer receive
                            notifications at this number.
                        </div>
                    </>
                )
            default:
                return null
        }
    }

    const buildConfirmBtn = () => {
        const onConfirm = async () => {
            setIsButtonDisabled(true)
            confirmFn && (await confirmFn())
            setIsButtonDisabled(false)
            closeFn()
        }
        switch (type) {
            case 'resendInvitation':
                return (
                    <Button color="primary" variant="contained" onClick={onConfirm}>
                        Resend invitation
                    </Button>
                )
            case 'impersonateUser':
                return (
                    <Button color="primary" variant="contained" onClick={onConfirm}>
                        Impersonate User
                    </Button>
                )
            case 'deleteAccount':
                return (
                    <Button
                        disabled={isButtonDisabled}
                        color="primary"
                        variant="contained"
                        onClick={onConfirm}
                        className="confirm-btn red"
                    >
                        Delete User
                    </Button>
                )
            case 'deactivateAccount':
                return (
                    <Button
                        disabled={isButtonDisabled}
                        color="primary"
                        variant="contained"
                        onClick={onConfirm}
                        className="confirm-btn red"
                    >
                        Deactivate User
                    </Button>
                )
            case 'reactivateAccount':
                return (
                    <Button
                        disabled={isButtonDisabled}
                        color="primary"
                        variant="contained"
                        onClick={onConfirm}
                        className="confirm-btn green"
                    >
                        Reactivate User
                    </Button>
                )
            case 'restoreAccount':
                return (
                    <Button
                        disabled={isButtonDisabled}
                        color="primary"
                        variant="contained"
                        onClick={onConfirm}
                        className="confirm-btn green"
                    >
                        Restore User
                    </Button>
                )
            case 'resetPassword':
                return (
                    <Button color="primary" variant="contained" onClick={onConfirm} className="confirm-btn">
                        Send Password Reset
                    </Button>
                )
            case 'deleteEmail':
                return (
                    <Button color="primary" variant="contained" onClick={onConfirm} className="confirm-btn red">
                        Delete Email
                    </Button>
                )
            case 'deletePhoneNumber':
                return (
                    <Button color="primary" variant="contained" onClick={onConfirm} className="confirm-btn red">
                        Delete Mobile Phone
                    </Button>
                )
            default:
                return null
        }
    }

    return (
        <div className="confirmation-container">
            <div className="confirmation-inner-container">
                {buildText()}
                <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                    <Grid item={true}>
                        <Button variant="outlined" onClick={closeFn}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true}>{buildConfirmBtn()}</Grid>
                    {isButtonDisabled && (
                        <div className="circular-progress-loader">
                            <CircularProgress size={48} />
                        </div>
                    )}
                </Grid>
            </div>
        </div>
    )
}

export default StaffConfirmationBox
