import React from 'react'

import SelfSchedulingModel from '../../../../models/SelfScheduling'
import CustomAtomToggle, {
    CustomAtomToggleOption,
} from '../../../../modules/shared/custom-atom-toggle/CustomAtomToggle'

import { CustomizationObject } from './types'

import './SelfSchedulingModalBackgroundColorStyle.sass'

export type SelfSchedulingModalBackgroundColorStyleProps = {
    style: Models.SelfSchedulingModalBackgroundColorStyle
    updateStyle: (
        style: Models.SelfSchedulingModalBackgroundColorStyle,
        customizationObject: CustomizationObject,
    ) => void
}

const backgroundColorStyleOptions: CustomAtomToggleOption[] = [
    { name: 'Gradient', value: 'gradient' },
    { name: 'Solid', value: 'solid' },
]

const moduleName = 'self-scheduling-modal-background-color-style'

function SelfSchedulingModalBackgroundColorStyle(props: SelfSchedulingModalBackgroundColorStyleProps) {
    const { style = SelfSchedulingModel.defaultModalBackgroundColorStyle } = props

    function updateStyle(value: Models.SelfSchedulingModalBackgroundColorStyle) {
        props.updateStyle(value, 'schedulingModal')
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__wrapper`}>
                <div className={`${moduleName}__toggle`}>
                    <CustomAtomToggle
                        value={style}
                        onClick={value => updateStyle(value)}
                        options={backgroundColorStyleOptions}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingModalBackgroundColorStyle
