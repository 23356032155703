import { connect } from 'react-redux'

import { RootState } from '../../../appReducer'
import { unbindPracticeAgent, updatePracticeAgent } from '../actions'

import Agent from './Agent'

const mapStateToProps = (state: RootState) => ({})

const AgentContainer = connect(mapStateToProps, {
    updatePracticeAgent,
    unbindPracticeAgent,
})(Agent)

export default AgentContainer
