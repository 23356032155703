import iassign from 'immutable-assign'

import { ConnectedPracticeLocation } from '../../../models/v2/Practice'

import {
    ConfigLogsActionTypes,
    ReceiveAllPracticeLocations,
    ReceiveConfigLogs,
    ReceiveConnectedPracticeLocations,
    ReceiveSchedulingConfigurationLogsCsv,
    ReceiveUsers,
    SearchConfigLogsRequest,
    SetDateFrom,
    SetDateTo,
    SetSelectedLocation,
    SetSelectedUsers,
} from './v2actions'

export interface DateFilterField {
    isValid: boolean
    errorMessage?: string
    validators?: any
    value: string
}

export type PracticeLocationConfigurationLogsState = {
    list?: ModelsV2.Practice.PracticeLocationSchedulingConfigurationLogs[]
    pagination: Models.PaginationInfo | null
    loading: boolean
    page?: number
}

export type ConfigLogsState = {
    connectedPracticeLocations: { [key: string]: ConnectedPracticeLocation[] }
    practiceLocations: { [key: string]: ApiV2.Practice.PracticeLocationFull[] }
    allUsers: { [key: string]: ModelsV2.Practice.PracticeStaffDataExtended[] }
    selectedLocationId: { [key: string]: string }
    selectedUserIds: { [key: string]: string[] }
    dateFrom: { [key: string]: DateFilterField }
    dateTo: { [key: string]: DateFilterField }
    configLogsData: { [key: string]: PracticeLocationConfigurationLogsState }
    csvDownloadStatus: { [key: string]: 'idle' | 'downloading' | 'success' | 'error' }
}

const initialState = (): ConfigLogsState => ({
    connectedPracticeLocations: {},
    practiceLocations: {},
    allUsers: {},
    selectedLocationId: {},
    selectedUserIds: {},
    dateFrom: {},
    dateTo: {},
    configLogsData: {},
    csvDownloadStatus: {},
})

export const initialPracticeLocationConfigurationLogsState = (): PracticeLocationConfigurationLogsState => ({
    page: 1,
    loading: false,
    list: undefined,
    pagination: null,
})

type ConfigLogsAction =
    | ReceiveAllPracticeLocations
    | ReceiveConnectedPracticeLocations
    | SearchConfigLogsRequest
    | ReceiveConfigLogs
    | SetSelectedLocation
    | SetSelectedUsers
    | ReceiveUsers
    | SetDateFrom
    | SetDateTo
    | ReceiveSchedulingConfigurationLogsCsv

export function reducer(state: ConfigLogsState = initialState(), action: ConfigLogsAction): ConfigLogsState {
    switch (action.type) {
        case ConfigLogsActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.connectedPracticeLocations[action.practiceId],
                nextConnectedLocations => {
                    if (!nextConnectedLocations) {
                        nextConnectedLocations = [...action.connectedLocations]
                        return nextConnectedLocations.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        })
                    }
                    nextConnectedLocations = action.connectedLocations.sort((a, b) => {
                        return a.name.localeCompare(b.name, undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        })
                    })
                    return nextConnectedLocations
                },
            )
        }
        case ConfigLogsActionTypes.RECEIVE_ALL_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.practiceLocations[action.practiceId],
                nextPracticeLocations => {
                    if (!nextPracticeLocations) {
                        nextPracticeLocations = [...action.practiceLocations]
                        return nextPracticeLocations.sort((a, b) => {
                            return a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        })
                    }
                    nextPracticeLocations = action.practiceLocations.sort(
                        (a: ApiV2.Practice.PracticeLocationFull, b: ApiV2.Practice.PracticeLocationFull) => {
                            return a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        },
                    )
                    return nextPracticeLocations
                },
            )
        }

        case ConfigLogsActionTypes.RECEIVE_USERS: {
            return iassign(
                state,
                next => next.allUsers[action.practiceId],
                nextAllUsers => {
                    nextAllUsers = action.users.sort((a: any, b: any) => {
                        return a.name.trim().localeCompare(b.name.trim(), undefined, {
                            numeric: true,
                            sensitivity: 'base',
                        })
                    })
                    return nextAllUsers
                },
            )
        }

        case ConfigLogsActionTypes.SET_SELECTED_LOCATION: {
            return iassign(
                state,
                next => next.selectedLocationId[action.practiceId],
                () => action.locationId,
            )
        }

        case ConfigLogsActionTypes.SET_SELECTED_USERS: {
            return iassign(
                state,
                next => next.selectedUserIds[action.practiceId],
                () => action.userIds,
            )
        }

        case ConfigLogsActionTypes.SEARCH_CONFIG_LOGS_REQUEST: {
            return iassign(
                state,
                next => next.configLogsData[action.locationId],
                nextPracticeLocationConfigurationLogs => {
                    if (!nextPracticeLocationConfigurationLogs) {
                        nextPracticeLocationConfigurationLogs = initialPracticeLocationConfigurationLogsState()
                    }

                    nextPracticeLocationConfigurationLogs.page = action.searchParams.page || 1
                    nextPracticeLocationConfigurationLogs.loading = action.loading
                    return nextPracticeLocationConfigurationLogs
                },
            )
        }

        case ConfigLogsActionTypes.RECEIVE_CONFIG_LOGS: {
            return iassign(
                state,
                next => next.configLogsData[action.locationId],
                nextPracticeLocationConfigurationLogs => {
                    if (!nextPracticeLocationConfigurationLogs) {
                        nextPracticeLocationConfigurationLogs = initialPracticeLocationConfigurationLogsState()
                    }
                    nextPracticeLocationConfigurationLogs.list = action.configLogs
                    nextPracticeLocationConfigurationLogs.loading = false
                    nextPracticeLocationConfigurationLogs.pagination = action.pagination || { allPages: 1, allRows: 1 }

                    return nextPracticeLocationConfigurationLogs
                },
            )
        }

        case ConfigLogsActionTypes.SET_DATE_FROM: {
            return iassign(
                state,
                next => next.dateFrom[action.practiceId],
                nextDateFrom => {
                    nextDateFrom = { value: action.value, isValid: true, errorMessage: action.errorMessage }
                    return nextDateFrom
                },
            )
        }

        case ConfigLogsActionTypes.SET_DATE_TO: {
            return iassign(
                state,
                next => next.dateTo[action.practiceId],
                nextDateTo => {
                    nextDateTo = { value: action.value, isValid: true, errorMessage: action.errorMessage }
                    return nextDateTo
                },
            )
        }

        case ConfigLogsActionTypes.RECEIVE_SCHEDULING_CONFIGURATION_LOGS_CSV: {
            return iassign(
                state,
                next => next.csvDownloadStatus[action.locationId],
                nextCsvDownloadStatus => {
                    nextCsvDownloadStatus = action.status
                    return nextCsvDownloadStatus
                },
            )
        }

        default:
            return state
    }
}
