import * as React from 'react'

import { ArrowDropDown, ArrowDropUp } from '../../../shared/svgIcons'

export interface Props {
    onUp: () => void
    onDown: () => void
    value: string
}

const TimeSelector: React.FunctionComponent<Props> = ({ onUp, onDown, value }) => {
    return (
        <div className="time-input">
            <div className="time-input__text">{value}</div>
            <div>
                <div className="time-input__up">
                    <ArrowDropUp
                        className="time-input__icon time-input--up"
                        onClick={onUp}
                        viewBox="0 -7 24 24"
                        size="24"
                    />
                </div>
                <div className="time-input__down">
                    <ArrowDropDown
                        className="time-input__icon time-input--down"
                        onClick={onDown}
                        viewBox="0 7 24 24"
                        size="24"
                    />
                </div>
            </div>
        </div>
    )
}

TimeSelector.defaultProps = {
    value: '0',
}

export default TimeSelector
