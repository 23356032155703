import React, { useEffect, useState } from 'react'
import { Redirect, RouteProps } from 'react-router-dom'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import { LocationDescriptor } from 'history'

import { tryLogin } from '../../appActions'
import { useAppDispatch } from '../../util/useAppDispatch'
import CopyText from '../shared/CopyText'
import { SimplifeyeVyneLogo } from '../shared/svgIcons'

import './Login.sass'

type Props = RouteProps

type Location = {
    state: {
        from: string
    }
}

const version = process.env.REACT_APP_GIT_HASH || 'devmode'

function Login(props: Props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [redirect, setRedirect] = useState(false)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState(window.sessionStorage.getItem('PSTUnauthorizedError') || '')

    const canSubmit = !email || !password || !email.match(/.+@.+\..+/i)
    const location = props.location as Location

    const dispatch = useAppDispatch()

    useRemoveErrorOnUnload()

    const onChangeEmail = (email: string) => {
        setEmail(email)
    }

    const onChangePassword = (password: string) => {
        setPassword(password)
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (canSubmit) {
            return
        }

        setIsLoggingIn(true)

        dispatch(tryLogin(email, password))
            .then(() => {
                setPassword('')
                setIsLoggingIn(false)
                setRedirect(true)
            })
            .catch((err: { message: string }) => {
                setPassword('')
                setIsLoggingIn(false)
                setErrorMessage(window.sessionStorage.getItem('PSTUnauthorizedError') || '')
            })
    }

    if (redirect) {
        const to: LocationDescriptor = (location && location.state && location.state.from) || {
            pathname: '/',
        }

        return <Redirect to={to} />
    }

    return (
        <div className={classNames('pst-login')}>
            <form
                className="login-form"
                onSubmit={e => {
                    onSubmit(e)
                }}
            >
                <div className="logo">
                    <SimplifeyeVyneLogo />
                </div>
                <div className="title">Product Support Tool</div>

                <div className="login__error-wrapper">
                    {errorMessage && <ErrorNotification errorMessage={errorMessage} />}
                </div>

                <div className="email">
                    <TextField
                        autoFocus={true}
                        fullWidth={true}
                        label="Email"
                        name="username"
                        onChange={event => onChangeEmail(event.target.value)}
                        value={email}
                        type="email"
                        variant="standard"
                    />
                </div>
                <div className="password">
                    <TextField
                        label="Password"
                        name="password"
                        fullWidth={true}
                        onChange={event => onChangePassword(event.target.value)}
                        type="password"
                        variant="standard"
                        value={password}
                    />
                </div>
                <div className="submit">
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={canSubmit || isLoggingIn}
                        color="secondary"
                        className={classNames('button')}
                    >
                        {isLoggingIn ? (
                            <CircularProgress thickness={5} color="inherit" size={20} variant="indeterminate" />
                        ) : (
                            'Sign In'
                        )}
                    </Button>
                </div>
            </form>
            <div className="version-text">
                <CopyText text={version}>{version}</CopyText>
            </div>
        </div>
    )
}

function useRemoveErrorOnUnload() {
    useEffect(() => {
        function onBeforeUnload() {
            sessionStorage.removeItem('PSTUnauthorizedError')
        }

        window.addEventListener('beforeunload', onBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload)
        }
    }, [])
}

function ErrorNotification({ errorMessage }: { errorMessage: string }) {
    return (
        <div className="login__error-notification">
            <div className="material-icons-container">
                <i className="material-icons">lock_outlined</i>
            </div>
            <span className="login__error-message">{errorMessage}</span>
        </div>
    )
}

export default Login
