import React from 'react'

import {
    OtherOption,
    QuestionOption,
    SurveySection,
    SurveySectionQuestion,
} from '../../../../../../models/PracticeSurvey'

import SelectMultiDefault from './SelectMultiDefault'
import SelectMultiTabs from './SelectMultiTabs'

import './SelectMulti.sass'

export type SelectMultiComponentType = 'default' | 'tabs'

interface Props {
    survey: SurveySection
    question: SurveySectionQuestion
    location: Models.PracticeLocation
    onChange: (options: QuestionOption[], other_option?: OtherOption) => void
}

const SelectMulti = ({ survey, question, location, onChange }: Props) => {
    if (question.component_type === 'tabs') {
        return <SelectMultiTabs survey={survey} question={question} location={location} onChange={onChange} />
    }

    return <SelectMultiDefault question={question} onChange={onChange} />
}

export default SelectMulti
