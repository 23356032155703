import moment from 'moment-timezone'

import { AtomToggleOption } from './AtomToggle'

export enum PatientType {
    NEW_PATIENT = 1,
    EXISTING_PATIENT = 2,
    BOTH = 3,
}

export enum StatusValue {
    ENABLED = 1,
    DISABLED = 2,
}

export enum InsuranceInformationType {
    REQUIRED = 1,
    OPTIONAL = 2,
}

export const patientOptions: AtomToggleOption[] = [
    { name: 'New', value: PatientType.NEW_PATIENT.toString() },
    { name: 'Existing', value: PatientType.EXISTING_PATIENT.toString() },
    { name: 'Both', value: PatientType.BOTH.toString() },
]

export const insuranceInformationOptions: AtomToggleOption[] = [
    { name: 'Required', value: InsuranceInformationType.REQUIRED.toString() },
    { name: 'Optional', value: InsuranceInformationType.OPTIONAL.toString() },
]

export const defaultStartTime = '09:00'
export const defaultEndTime = '17:00'

export const daysOfTheWeek = [
    { name: 'Monday', index: 0 },
    { name: 'Tuesday', index: 1 },
    { name: 'Wednesday', index: 2 },
    { name: 'Thursday', index: 3 },
    { name: 'Friday', index: 4 },
    { name: 'Saturday', index: 5 },
    { name: 'Sunday', index: 6 },
]

const currentTime = Date.now()

export const timeZones = [
    { display: moment.tz.zone('Canada/Atlantic')?.abbr(currentTime), value: 'Canada/Atlantic' },
    { display: moment.tz.zone('America/New_York')?.abbr(currentTime), value: 'America/New_York' },
    { display: moment.tz.zone('America/Chicago')?.abbr(currentTime), value: 'America/Chicago' },
    { display: `${moment.tz.zone('America/Denver')?.abbr(currentTime)}(Denver)`, value: 'America/Denver' },
    { display: `${moment.tz.zone('America/Phoenix')?.abbr(currentTime)}(Phoenix)`, value: 'America/Phoenix' },
    { display: moment.tz.zone('America/Los_Angeles')?.abbr(currentTime), value: 'America/Los_Angeles' },
    { display: moment.tz.zone('America/Anchorage')?.abbr(currentTime), value: 'America/Anchorage' },
    { display: moment.tz.zone('Pacific/Honolulu')?.abbr(currentTime), value: 'Pacific/Honolulu' },
]
