import * as React from 'react'

import { PatientType } from '../../../models/enums'
import { fetchPracticeLocationProcedures, fetchProcedureProviders } from '../../practices/actions'

import {
    appointmentSubscribeToChannel,
    createAppointment,
    resetForm,
    searchExistingPatients,
    selectExistingPatient,
    setAppointmentBookingStep,
    setAppointmentDatetime,
    setAppointmentPickerError,
    setBookingError,
    setPending,
    updateFormFields,
    updateSearchFormFields,
} from './actions'

export type DirectSchedulingContextInterface = {
    practice: Models.Practice
    chat: Models.ChatMetadata
    webCode: Models.WebCode
    tab: PatientType
    updateFormFields: typeof updateFormFields
    updateSearchFormFields: typeof updateSearchFormFields
    fetchPracticeLocationProcedures: typeof fetchPracticeLocationProcedures
    fetchProcedureProviders: typeof fetchProcedureProviders
    setAppointmentBookingStep: typeof setAppointmentBookingStep
    setAppointmentPickerError: typeof setAppointmentPickerError
    setPending: typeof setPending
    setAppointmentDatetime: typeof setAppointmentDatetime
    createAppointment: typeof createAppointment
    resetForm: typeof resetForm
    searchExistingPatients: typeof searchExistingPatients
    selectExistingPatient: typeof selectExistingPatient
    appointmentSubscribeToChannel: typeof appointmentSubscribeToChannel
    setBookingError: typeof setBookingError
    onSendMessage: (message: string) => void
    handleAddPatient: () => void
}

const DirectSchedulingContext = React.createContext<DirectSchedulingContextInterface | null>(null)

export const DirectSchedulingContextProvider = DirectSchedulingContext.Provider

export const DirectSchedulingContextConsumer = DirectSchedulingContext.Consumer
