import React, { useEffect } from 'react'
import ReactLoading from 'react-loading'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import {
    BookingStep,
    ExistingPatientBookingForm,
    ExistingPatientFormElements,
    PatientSearchCriteria,
    SearchPatientForm,
    SearchPatientFormElements,
} from '../../../models/BookingAppointment'
import { PatientType } from '../../../models/enums'
import { CustomFieldType } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import {
    fetchPracticeLocationProcedures as fetchPracticeLocationProceduresAction,
    fetchProcedureProviders as fetchProcedureProvidersAction,
} from '../../practices/actions'
import CustomField from '../../shared/custom-fields/CustomField'
import CustomPhoneInput from '../../shared/custom-fields/CustomPhoneInput'
import WebcodeLocationMultiselectContainer from '../../shared/custom-fields/webcode-location-multiselect/WebcodeLocationMultiselectContainer'
import { usePrevious } from '../../shared/custom-hooks'
import { FormFieldElement, isBasicPhoneNumberWithWarning } from '../../shared/form-validator/validator'
import { gray97 } from '../../shared/styles/colors'

import { appointmentSubscribeToChannel } from './actions'
import {
    createAppointment,
    resetSearchResults,
    searchExistingPatients,
    selectExistingPatient,
    setAppointmentBookingStep,
    setAppointmentPickerError,
    setBookingError,
    setPending,
    updateFormFields,
    updateSearchFormFields,
} from './actions'
import AppointmentPickerSection from './AppointmentPickerSection'
import SearchExistingPatient from './SearchExistingPatient'
import SearchResults from './SearchResults'
import { formatDateOfBirth } from './shared'
import { APP_ERROR_MESSAGE_TIMEOUT } from './shared'

import './ExistingPatientTab.sass'

export type ExistingPatientTabProps = {
    open: boolean
    isModalOpened: boolean
    practice: Models.Practice
    chat: Models.ChatMetadata
    webCode: Models.WebCode
    tab: PatientType
    directSchedulingRef: React.RefObject<HTMLDivElement>
    locationsSchedulingDataLoaded: boolean
    appointment?: Models.Appointment
    form: ExistingPatientBookingForm
    chatHasScheduledAppointment: boolean
    searchPatients: SearchPatientForm
    isAdditionalPatientBooking?: boolean
}

export type ExistingPatientTabDispatch = {
    fetchPracticeLocationProcedures: typeof fetchPracticeLocationProceduresAction
    fetchProcedureProviders: typeof fetchProcedureProvidersAction
    updateFormFields: typeof updateFormFields
    updateSearchFormFields: typeof updateSearchFormFields
    searchExistingPatients: typeof searchExistingPatients
    selectExistingPatient: typeof selectExistingPatient
    resetSearchResults: typeof resetSearchResults
    setAppointmentBookingStep: typeof setAppointmentBookingStep
    createAppointment: typeof createAppointment
    setPending: typeof setPending
    appointmentSubscribeToChannel: typeof appointmentSubscribeToChannel
    setAppointmentPickerError: typeof setAppointmentPickerError
    setBookingError: typeof setBookingError
    onSendMessage: (message: string) => void
    handleAddPatient: () => void
}

type Props = ExistingPatientTabProps & ExistingPatientTabDispatch

const moduleName = 'scheduling-new-patient-form'

const ExistingPatientTab = (props: Props) => {
    const { practice, fetchPracticeLocationProcedures, webCode, fetchProcedureProviders } = props
    const prevSelectedLocation = usePrevious(props.form.formElements.location.value)
    const selectedLocation = props.form.formElements.location.value
    const selectedProcedure = props.form.formElements.procedure.value
    const enabledProviderSelection = Boolean(webCode?.customization?.direct_scheduling?.enable_provider_selection)

    const {
        chat,
        tab,
        form: { isPending },
    } = props

    const hasError = (field: FormFieldElement) => !field.isValid
    const hasWarning = (field: FormFieldElement) => field.isDirty && !field.isPossiblyInvalid

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (selectedLocation && practice?.id) {
            const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
            const locationProcedures = practice.locations[locationIndex]?.procedures
            const shouldFetchProcedures = (selectedProcedure || selectedLocation) && !locationProcedures
            if (prevSelectedLocation !== selectedLocation || shouldFetchProcedures) {
                dispatch(fetchPracticeLocationProcedures(selectedLocation, practice))
            }
        }
    }, [selectedLocation, practice, prevSelectedLocation, selectedProcedure, fetchPracticeLocationProcedures, dispatch])

    useEffect(() => {
        if (isPending) {
            const timer = setTimeout(() => {
                if (isPending) {
                    dispatch(props.setPending(chat, tab, false))
                    dispatch(props.setBookingError(chat, tab, APP_ERROR_MESSAGE_TIMEOUT))
                    dispatch(props.setAppointmentBookingStep(chat, tab, BookingStep.ERROR))
                }
            }, 20000)

            return () => clearTimeout(timer)
        }
        return
    }, [isPending, dispatch])

    const handleSubmitSearch = (patientSearchCriteria: PatientSearchCriteria) => {
        dispatch(props.searchExistingPatients(props.chat, props.tab, props.practice, patientSearchCriteria))
    }

    const handleSelectPatient = (patient: Models.Patient) => {
        dispatch(props.selectExistingPatient(props.chat, patient))
    }

    const handleResetSearchResults = () => {
        dispatch(props.resetSearchResults(props.chat))
    }

    const handleConfirmPatient = () => {
        dispatch(
            props.updateFormFields(props.chat, props.tab, {
                ...props.form.formElements,
                location: {
                    ...props.form.formElements.location,
                    value: props.searchPatients.formElements.location.value,
                    isDirty: true,
                },
            }),
        )
        dispatch(props.setAppointmentBookingStep(props.chat, props.tab, BookingStep.NONE))
    }

    const getProcedure = () => {
        const { practice, form } = props

        if (!practice?.locations) {
            return undefined
        }

        const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
        const locationProcedures = practice.locations[locationIndex]?.procedures

        if (!locationProcedures || !locationProcedures[form.formElements.procedure.value]) {
            return undefined
        }

        return locationProcedures[form.formElements.procedure.value]
    }

    const getLocation = () => {
        const {
            practice,
            form: {
                formElements: {
                    location: { value: locationId },
                },
            },
        } = props

        if (!practice || !practice.locations || !locationId) {
            return undefined
        }

        return practice.locations.find(location => location.id === locationId)
    }

    const isLocationDisabled = (locationId: string): boolean => {
        const location = practice?.locations.find(location => location.id === locationId)
        return !Boolean(location?.hasDirectScheduling && location?.agentLocationActive)
    }

    const locationSchedulingStatusReason = (locationId: string): string => {
        const location = practice?.locations.find(location => location.id === locationId)
        if (location?.hasDirectScheduling == null && location?.agentLocationActive == null) {
            return ' - not paired to agent'
        }
        if (location?.agentLocationActive === false) {
            if (selectedLocation === locationId) {
                dispatch(
                    props.updateFormFields(props.chat, props.tab, {
                        ...props.form.formElements,
                        location: { ...props.form.formElements.location, value: '' },
                    }),
                )
            }
            return ' - offline agent'
        }
        if (location?.hasDirectScheduling === false) {
            return ' - DS not enabled'
        }

        return ''
    }

    const bookAppointment = async () => {
        const {
            chat,
            tab,
            form: {
                formElements,
                isFormValid,
                appointmentPicker: { selectedDatetime },
            },
            searchPatients: { selectedPatient },
        } = props

        if (!selectedPatient) {
            dispatch(props.setAppointmentPickerError(chat, tab, 'Patient is not selected'))
            return
        }

        const location = getLocation()
        const procedure = getProcedure()

        if (!isFormValid || !selectedDatetime || !location || !procedure) {
            dispatch(props.setAppointmentPickerError(chat, tab, 'Undefined Error'))
            return
        }

        const transactionId = uuidv4()

        dispatch(props.appointmentSubscribeToChannel(chat, tab, transactionId))

        dispatch(props.setPending(chat, tab, true))

        dispatch(
            props.createAppointment(chat, tab, location, procedure, {
                datetime: selectedDatetime.toDate(),
                patient: {
                    id: selectedPatient.id,
                    mobile_phone: formElements.mobilePhone.value,
                    email_address: formElements.email.value,
                },
                chat_id: chat.id,
                transactionId: transactionId,
                ...(formElements.provider.value ? { providerId: formElements.provider.value } : {}),
            }),
        )
            .catch((err: Error) => {
                dispatch(props.setAppointmentPickerError(chat, tab, err.message))
            })
            .finally(() => {})
    }

    const shareAppointment = (datetime: moment.Moment, postponed: boolean = false) => () => {
        const message = postponed
            ? `I have requested an appointment for ${props.searchPatients.selectedPatient?.firstName} ${
                  props.searchPatients.selectedPatient?.lastName
              } on ${datetime.format('dddd, MMMM D [at] h:mm a')}. I'll send you an email once it's scheduled.`
            : `${
                  !props.chatHasScheduledAppointment ? `Wonderful! ` : ``
              }I have booked an appointment for ${props.searchPatients.selectedPatient?.firstName.trim()} ${props.searchPatients.selectedPatient?.lastName.trim()} on ${datetime.format(
                  'dddd, MMMM D [at] h:mm a',
              )}. I'll send you an email once it's confirmed.`

        props.onSendMessage(message)

        dispatch(props.setAppointmentBookingStep(props.chat, props.tab, BookingStep.SHARED))
    }

    const handleSetAppointmentBookingStep = (step: BookingStep) => () => {
        const { chat, tab } = props
        dispatch(props.setAppointmentBookingStep(chat, tab, step))
    }

    const getIsBooked = () => {
        return [BookingStep.BOOKED, BookingStep.SHARED].includes(props.form.bookingStep)
    }

    const onUpdate = (modifier: (changes: ExistingPatientFormElements) => ExistingPatientFormElements) => {
        dispatch(props.updateFormFields(props.chat, props.tab, modifier(props.form.formElements)))
    }

    const renderProceduresOptions = () => {
        const { practice } = props

        if (!practice?.locations) {
            return null
        }

        const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
        const locationProcedures = practice.locations[locationIndex]?.procedures

        if (!locationProcedures) {
            return null
        }

        const procedureIds = Object.keys(locationProcedures)
        const filteredProcedureIds = procedureIds.filter(
            id =>
                locationProcedures[id].enabled === true &&
                ['both', 'existing'].includes(locationProcedures[id].patientType),
        )

        return (
            <React.Fragment>
                {filteredProcedureIds.map((id: string) => (
                    <option key={id} value={id}>
                        {locationProcedures[id].name}
                    </option>
                ))}
            </React.Fragment>
        )
    }

    const renderProviders = () => {
        const { practice, form } = props

        if (!practice?.locations || !form.formElements.procedure.value) {
            return null
        }

        const locationIndex = practice.locations.findIndex(el => el.id === selectedLocation)
        const locationProcedures = practice.locations[locationIndex]?.procedures
        const selectedProcedure = locationProcedures?.[form.formElements.procedure.value]

        if (!selectedProcedure) {
            return null
        }

        return (
            <React.Fragment>
                {selectedProcedure.providers?.map((provider: Models.Provider) => (
                    <option key={provider.id} value={provider.id}>
                        {[provider.suffix, provider.first_name, provider.middle_initial, provider.last_name]
                            .filter(Boolean)
                            .join(' ')}
                    </option>
                ))}
            </React.Fragment>
        )
    }

    const renderBookingForm = () => {
        const {
            chat,
            tab,
            form: { formElements },
            searchPatients: { selectedPatient },
        } = props
        const disabled = getIsBooked()

        return (
            <div className={`${moduleName}__existing-form`}>
                {selectedPatient && (
                    <div className={`${moduleName}__field-columns`}>
                        <div className={`${moduleName}__field`}>
                            <div className={`${moduleName}__label`}>Patient Name</div>
                            <div className={`${moduleName}__value`}>
                                {selectedPatient.firstName} {selectedPatient.lastName}
                            </div>
                        </div>

                        <div className={`${moduleName}__field`}>
                            <div className={`${moduleName}__label`}>Date of Birth</div>
                            <div className={`${moduleName}__value`}>{formatDateOfBirth(selectedPatient.birthDate)}</div>
                        </div>
                    </div>
                )}

                <div className={`${moduleName}__field`}>
                    <CustomPhoneInput
                        disabled={disabled}
                        value={formElements.mobilePhone.value || ''}
                        label="Mobile Phone*"
                        error={hasError(formElements.mobilePhone)}
                        errorMessage={formElements.mobilePhone?.firstErrorMessage || 'Invalid phone number'}
                        warning={hasWarning(formElements.mobilePhone)}
                        warningMessage={'Phone # might be invalid'}
                        country={'US'}
                        onChange={(value: string) =>
                            onUpdate(elements => {
                                elements.mobilePhone.value = value
                                elements.mobilePhone.isDirty = true
                                return elements
                            })
                        }
                        onCountryChange={(countryCode: string) => {
                            onUpdate(elements => {
                                elements.mobilePhone.validators = [isBasicPhoneNumberWithWarning({ countryCode })]

                                return elements
                            })
                        }}
                    />
                </div>

                <div className={`${moduleName}__field`}>
                    <CustomField
                        disabled={disabled}
                        required={true}
                        value={formElements.email.value}
                        customFieldType={CustomFieldType.INPUT}
                        label="Email*"
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            onUpdate(elements => {
                                elements.email.value = event.target.value.trim()
                                elements.email.isDirty = true
                                return elements
                            })
                        }
                    />
                </div>

                <div className={`${moduleName}__field`}>
                    <WebcodeLocationMultiselectContainer
                        disabled={true}
                        label="Practice Location*"
                        practice={practice}
                        placeholder="Choose a Location"
                        webCode={webCode}
                        onlyEnabledWebCodeLocations={true}
                        selectedItems={formElements.location.value ? [formElements.location.value] : []}
                        errorMessage={formElements.location?.firstErrorMessage || 'This field is required'}
                        isOptionDisabled={isLocationDisabled}
                        locationSchedulingStatusReason={locationSchedulingStatusReason}
                        onSelectElement={(values: string[]) => {
                            onUpdate(elements => {
                                elements.location.value = values[0]
                                elements.location.isDirty = true
                                return elements
                            })
                            dispatch(props.setAppointmentBookingStep(chat, tab, BookingStep.NONE))
                        }}
                        loading={!props.locationsSchedulingDataLoaded}
                    />
                </div>

                <div className={`${moduleName}__field`}>
                    <CustomField
                        disabled={disabled || !Boolean(selectedLocation)}
                        customFieldType={CustomFieldType.SELECT}
                        label="Reason for Appointment*"
                        value={formElements.procedure.value}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            onUpdate(elements => {
                                elements.procedure.value = event.target.value
                                elements.procedure.isDirty = true
                                return elements
                            })
                            dispatch(props.setAppointmentBookingStep(chat, tab, BookingStep.NONE))
                            if (formElements.location.value && formElements.procedure.value) {
                                dispatch(
                                    fetchProcedureProviders(
                                        formElements.location.value,
                                        practice,
                                        practice.locations.find(el => el.id === formElements.location.value)
                                            ?.procedures?.[event.target.value] as Models.Procedure,
                                    ),
                                )
                            }
                        }}
                    >
                        <option value={''}>Choose Reason</option>
                        {renderProceduresOptions()}
                    </CustomField>
                </div>

                {enabledProviderSelection && (
                    <div className={`${moduleName}__field`}>
                        <CustomField
                            disabled={disabled || !Boolean(selectedLocation) || !formElements.procedure.value}
                            customFieldType={CustomFieldType.SELECT}
                            label="Provider"
                            value={formElements.provider.value}
                            errorMessage={formElements.provider?.firstErrorMessage || 'This field is required'}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                onUpdate(elements => {
                                    elements.provider.value = event.target.value
                                    elements.provider.isDirty = true
                                    return elements
                                })
                                dispatch(props.setAppointmentBookingStep(chat, tab, BookingStep.NONE))
                            }}
                        >
                            <option value={''} selected={formElements.provider.value === ''}>
                                Any Provider
                            </option>
                            {renderProviders()}
                        </CustomField>
                    </div>
                )}

                <AppointmentPickerSection
                    patientName={`${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
                    form={props.form}
                    appointment={props.appointment}
                    practice={props.practice}
                    handleSetAppointmentBookingStep={handleSetAppointmentBookingStep}
                    shareAppointment={shareAppointment}
                    bookAppointment={bookAppointment}
                    addPatient={props.handleAddPatient}
                    directSchedulingRef={props.directSchedulingRef}
                />
            </div>
        )
    }

    const {
        open,
        form: { bookingStep },
        searchPatients: { patients },
        isAdditionalPatientBooking,
    } = props

    return (
        <div className={`${moduleName} ${!open && `${moduleName}--hidden`}`}>
            {[BookingStep.SEARCH, BookingStep.SEARCH_NO_RESULTS].includes(bookingStep) && (
                <>
                    {isPending ? (
                        <div className={`${moduleName}__progress ${moduleName}__progress--large`}>
                            <ReactLoading type="spin" color={gray97} height={145} width={145} />
                        </div>
                    ) : (
                        <>
                            <SearchExistingPatient
                                form={props.searchPatients}
                                practice={props.practice}
                                webCode={props.webCode}
                                updateFormFields={(changes: SearchPatientFormElements) => {
                                    dispatch(props.updateSearchFormFields(props.chat, changes))
                                }}
                                onSubmitSearch={handleSubmitSearch}
                                showNoResultsMessage={bookingStep === BookingStep.SEARCH_NO_RESULTS}
                                isLocationDisabled={isLocationDisabled}
                                locationSchedulingStatusReason={locationSchedulingStatusReason}
                                isAdditionalPatientBooking={isAdditionalPatientBooking}
                            />
                        </>
                    )}
                </>
            )}

            {bookingStep === BookingStep.SEARCH_RESULTS && (
                <SearchResults
                    onSelectPatient={handleSelectPatient}
                    onResetSearchResults={handleResetSearchResults}
                    onConfirmPatient={handleConfirmPatient}
                    selectedPatient={props.searchPatients.selectedPatient}
                    patients={patients}
                />
            )}

            {bookingStep >= BookingStep.NONE && renderBookingForm()}
        </div>
    )
}

export default ExistingPatientTab
