import { connect } from 'react-redux'

import { setRedirect } from '../../appActions'
import { RootState } from '../../appReducer'
// import * as actions from './actions'
import { fetchPractice, savePractice } from '../practices/actions'

import PracticeDiagnostics, { PracticeDiagnosticsParams, PracticeDiagnosticsProps } from './PracticeDiagnostics'

const mapStateToProps = (state: RootState, props: PracticeDiagnosticsParams): PracticeDiagnosticsProps => ({
    account: state.app.self && state.app.self.account,
    practice: state.practices.practices[props.match.params.practiceId],
})

const PracticeDiagnosticsContainer = connect(mapStateToProps, {
    fetchPractice,
    setRedirect,
    savePractice,
})(PracticeDiagnostics)

export default PracticeDiagnosticsContainer
