import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import classNames from 'classnames'

import { CustomFieldType } from '../../../models/enums'
import CustomPhoneInput from '../../shared/custom-fields/CustomPhoneInput'
import { isRequired, ValidatorObj } from '../../shared/form-validator/validator'
import { formatPhoneNumberNtl } from '../../shared/phone-number-formatter/formatPhoneNumber'

import './EditableField.sass'

type FieldData = {
    value: string
    isValid: boolean
    firstErrorMessage?: string
    validators?: ValidatorObj[]
}

export type Option = {
    display: string
    value: string
}

const moduleName = 'editable-field'
interface Props {
    fieldData: FieldData
    customFieldType: CustomFieldType
    inputType?: string
    className?: string
    editButtons?: boolean
    label?: string
    error?: boolean | string
    forceOpenEdit?: boolean
    placeholder?: string
    options?: Option[]
    noBorder?: boolean
    countryCodeIsoAlpha2?: string
    onChange: (value: string) => void
    onSave: (value: string) => void
    onCancel: (value: string) => void
}

const EditablePhoneNumberField = (props: Props) => {
    const {
        fieldData: { value: propsValue, isValid, firstErrorMessage, validators },
        label,
        customFieldType,
        editButtons,
        noBorder,
        options,
        forceOpenEdit,
        countryCodeIsoAlpha2 = 'US',
    } = props

    const [edit, setEdit] = useState<boolean>(Boolean(forceOpenEdit && editButtons))
    const [showButtons, setShowButtons] = useState<boolean>(false)
    const [value, setValue] = useState<string>(propsValue || '')
    const [prevValue, setPrevValue] = useState<string>(propsValue || '')
    const isFieldRequired = Boolean(validators?.find(validator => validator.errorMessage === isRequired().errorMessage))

    useEffect(() => {
        if (editButtons) {
            setShowButtons(true)
        }
    }, [editButtons])

    useEffect(() => {
        if (value === '' && prevValue === '' && propsValue !== '') {
            setPrevValue(propsValue)
            setValue(propsValue)
        }
    }, [prevValue, value, propsValue])

    const openEditView = () => {
        setEdit(true)
    }

    const onChangeValue = (value: string) => {
        let newValue = value

        setValue(newValue)
        if (!showButtons) {
            setPrevValue(value)
        }
        props.onChange(value)
    }

    const onCancel = () => {
        setEdit(false)
        if (prevValue) {
            setValue(prevValue)
            props.onCancel(prevValue)
        }
    }

    const onSave = () => {
        setPrevValue(value)
        props.onSave(value)
        setEdit(false)
    }

    const getDisplayValue = () => {
        if (customFieldType === CustomFieldType.SELECT && options) {
            return options.find(option => option.value === propsValue)?.display
        }

        return propsValue
    }

    let showInput = showButtons ? edit : !edit
    let showEditPen = showButtons && !edit
    let showSaveCancel = showButtons && edit

    return (
        <div
            className={classNames(`${moduleName}`, {
                [`${moduleName}--narrow`]: Boolean(noBorder),
            })}
        >
            {Boolean(label) && (
                <div className={`${moduleName}__field-label`}>
                    <div className={`${moduleName}__field-label-text`}>{label}</div>
                </div>
            )}
            <div
                className={classNames(`${moduleName}__field-input-container`, {
                    [`${moduleName}--no-border`]: Boolean(noBorder),
                })}
            >
                <div
                    className={classNames(`${moduleName}__field-input`, {
                        [`${moduleName}--min-width`]: Boolean(showEditPen && noBorder),
                    })}
                >
                    {showInput ? (
                        <CustomPhoneInput
                            error={props.error}
                            errorMessage={firstErrorMessage}
                            value={value}
                            label={label}
                            key={countryCodeIsoAlpha2 || ''}
                            country={countryCodeIsoAlpha2 || ''}
                            onChange={onChangeValue}
                        />
                    ) : (
                        <div className={`${moduleName}__field-value`}>
                            {formatPhoneNumberNtl(getDisplayValue() || '')}
                        </div>
                    )}
                </div>
                <div>
                    {showEditPen && (
                        <div className={`${moduleName}__icon-wrapper`} onClick={openEditView}>
                            <i className={`material-icons ${moduleName}__icon`}>edit</i>
                        </div>
                    )}
                    {showSaveCancel && (
                        <div className={`${moduleName}__action-buttons`}>
                            <div className={`${moduleName}__cancel-button`}>
                                <Button
                                    className={classNames(`${moduleName}__save-button`, {
                                        [`${moduleName}--disabled`]: (!value && isFieldRequired) || !isValid,
                                    })}
                                    onClick={onSave}
                                >
                                    Save
                                </Button>
                            </div>
                            {(prevValue || !isFieldRequired) && (
                                <div className={`${moduleName}__cancel-button`}>
                                    <Button color="primary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EditablePhoneNumberField
