import { AnyAction, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createLogger } from 'redux-logger'
import { persistStore } from 'redux-persist'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'

import analytics from './util/analytics'
import AppointmentDataEvents from './AppointmentDataEvents'
import appReducer, { RootState } from './appReducer'
import DataEvents from './DataEvents'

const middleware = [
    thunkMiddleware,
    DataEvents.storeListener.bind(DataEvents),
    AppointmentDataEvents.storeListener.bind(AppointmentDataEvents),
    analytics,
]

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>
export type AppDispatch = ThunkDispatch<RootState, undefined, AnyAction>

if (process.env.REACT_APP_REDUX_LOGGER === 'true') {
    middleware.push(createLogger())
}

const store = createStore(appReducer, composeWithDevTools(applyMiddleware(...middleware)))
DataEvents.registerStore(store)
AppointmentDataEvents.registerStore(store)
persistStore(store)

export default store
