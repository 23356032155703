import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button, { ButtonClassKey } from '@mui/material/Button'
import TableCell, { TableCellClassKey } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { RootState } from 'appReducer'
import classNames from 'classnames'
import moment from 'moment'

import Api from '../../Api'
import { PartnerAdmin } from '../../models/enums'
import ReferralModal from '../../modules/referrals/ReferralModal'
import { downloadReferral, fetchReferralV2 } from '../../modules/referrals/v2actions'
import { convertDoubleStruckToRegular } from '../../modules/shared/unicodeCharsUtils'
import { useAppDispatch } from '../../util/useAppDispatch'
import { deleteReferral } from '../amplify/actions'
import ConfirmModal from '../shared/confirm-modal/ConfirmModal'
import CopyText from '../shared/CopyText'

import './AmplifyReferral.sass'

export type AmplifyReferralProps = {
    referralId: string
    v2referral: ModelsV2.Referrals.Referral
    practice: Models.Practice
    referralCreator?: Models.Account
    inboundHandler?: Models.Account
}

type TableCellClassOverrides = {
    readonly [key in TableCellClassKey]?: string
}

const tableCellClassOverrides: TableCellClassOverrides = {
    root: 'amplify-referral-table-cell',
    body: 'amplify-referral-table-cell-body',
}

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const buttonClassOverrides: ButtonClassOverrides = {
    root: `amplify-referral__download-btn`,
}

type Props = AmplifyReferralProps

const AmplifyReferral = (props: Props) => {
    const { referralId, v2referral, practice, referralCreator, inboundHandler } = props
    const now = moment()
    const referral = useSelector((state: RootState) => state.v2.referrals.referralForm.referral.value)
    const account = useSelector((state: RootState) => state?.app?.self?.account)
    const primaryPatient = referral?.amplify_referral_patient?.find(patient => patient.is_primary)
    const v2PrimaryPatientFound = v2referral?.amplify_referral_patients?.find(patient => patient.is_primary)
    const v2PrimaryPatient = v2PrimaryPatientFound || v2referral?.amplify_referral_patients[0]
    const isLegacy = Boolean(referral?.legacy_message_id)
    const patientFullName = `${primaryPatient?.last_name} ${primaryPatient?.first_name}`
    const v2patientFullName = [v2PrimaryPatient?.last_name, v2PrimaryPatient?.first_name].join(' ')
    const v2ReferralWriter = v2referral.referral_writer
        ? `${v2referral.referral_writer?.first_name} ${v2referral.referral_writer?.last_name}`
        : v2referral?.referralCreatorId
    const referralWriter = referralCreator ? referralCreator.name : v2ReferralWriter
    const v2Chatter = v2referral.chatter
        ? `${v2referral.chatter.first_name} ${v2referral.chatter.last_name}`
        : v2referral.inboundHandler
        ? `${v2referral.inboundHandler.first_name} ${v2referral.inboundHandler.last_name}`
        : v2referral.inboundHandlerId
    const chatterInfo = inboundHandler ? inboundHandler.name : v2Chatter
    const [chatter, setChatter] = useState<string>()
    const [patientName, setPatientName] = useState<string>()
    const [updated, setUpdated] = useState<Date>()
    const [created, setCreated] = useState<Date>()
    const [showModal, setShowModal] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    useEffect(() => {
        if (chatterInfo) {
            setChatter(chatterInfo)
        }
    }, [chatterInfo])
    useEffect(() => {
        if (v2referral.updated) {
            setUpdated(v2referral.updated)
        }
    }, [v2referral.updated])
    useEffect(() => {
        if (v2referral.created) {
            setCreated(v2referral.created)
        }
    }, [v2referral.created])

    useEffect(() => {
        if (v2patientFullName) {
            setPatientName(v2patientFullName)
        }
    }, [v2patientFullName])

    const dispatch = useAppDispatch()

    const onCloseModal = () => {
        setShowModal(false)
    }

    const onDownloadReferral = () => {
        if (referral?.id) {
            dispatch(downloadReferral(referral?.id))
        }
    }

    const onShowModal = async () => {
        setShowModal(true)
        loadReferral()
    }

    const onDeleteReferral = () => {
        setShowDelete(true)
    }

    const onRemoveDiscard = () => {
        setShowDelete(false)
    }

    const loadReferral = async () => {
        await dispatch(fetchReferralV2(referralId))
    }

    const formatStatusValue = (status: string) => {
        const formattedStatusValue = status.charAt(0).toUpperCase() + status.slice(1)
        return formattedStatusValue.replace('_', ' ')
    }

    const patchReferralsTable = (referralData: ModelsV2.Referrals.ReferralForm) => {
        if (referralData.referral.dirtyFields.length === 0 && referralData.primaryPatient.dirtyFields.length === 0) {
            return
        }

        if (referralData.referral.dirtyFields.includes('inbound_handler_id')) {
            Api.Admin.list('amplify-chatters', { limit: 0 })
                .then(({ data: handlers }) => {
                    const inboundHandlerAccounts = handlers.filter(h => {
                        return h.type.id === PartnerAdmin.id
                    })
                    return inboundHandlerAccounts
                })
                .then(chatters => {
                    const chatter = chatters.find(
                        chatter => chatter.id === referralData.referral.value.inbound_handler_id,
                    )
                    if (chatter) {
                        setChatter(`${chatter.first_name} ${chatter.last_name}`)
                    }
                })
        }

        if (referralData.primaryPatient.dirtyFields.some(val => ['last_name', 'first_name'].includes(val))) {
            const patient = referralData.primaryPatient.value
            setPatientName(`${patient.last_name} ${patient.first_name}`)
        }
        setUpdated(new Date())
    }

    return (
        <>
            <TableRow className={classNames('amplify-referral-item legacy')}>
                <TableCell className="data" classes={tableCellClassOverrides}>
                    <span className="id">
                        <CopyText text={v2referral?.id as string}>ID</CopyText>
                    </span>
                </TableCell>
                <TableCell className="data" classes={tableCellClassOverrides}>
                    <div className="patient-name">
                        {convertDoubleStruckToRegular(v2PrimaryPatient?.last_name || '')}
                        {v2referral?.isTest && <span className="test-referral"> [TEST]</span>}
                    </div>
                </TableCell>
                <TableCell className="data" classes={tableCellClassOverrides}>
                    <div className="patient-name">
                        {convertDoubleStruckToRegular(v2PrimaryPatient?.first_name || '')}
                        {v2referral?.isTest && <span className="test-referral"> [TEST]</span>}
                    </div>
                </TableCell>
                <TableCell className="last-edit" classes={tableCellClassOverrides}>
                    {moment(created).format('MM-DD-YYYY hh:mm a')}
                    <div className="time-since">{now.to(v2referral?.created)}</div>
                </TableCell>
                <TableCell className="last-edit" classes={tableCellClassOverrides}>
                    {moment(updated).format('MM-DD-YYYY hh:mm a')}
                </TableCell>
                {isLegacy && (
                    <React.Fragment>
                        <TableCell className="referral-creator legacy" classes={tableCellClassOverrides}>
                            {referralCreator ? referralCreator.name : 'Chad Simplifeye'}
                        </TableCell>
                        <TableCell className="inbound-handler legacy" classes={tableCellClassOverrides}>
                            {inboundHandler ? inboundHandler.name : 'Chad Simplifeye'}
                        </TableCell>
                    </React.Fragment>
                )}
                {!isLegacy && (
                    <React.Fragment>
                        <TableCell
                            className={classNames('referral-creator', {
                                missing: !referralCreator && !v2referral.referral_writer,
                            })}
                            classes={tableCellClassOverrides}
                        >
                            {referralWriter}
                        </TableCell>
                        <TableCell
                            className={classNames('inbound-handler', {
                                missing: !inboundHandler && !v2referral.chatter,
                            })}
                            classes={tableCellClassOverrides}
                        >
                            {chatter}
                        </TableCell>
                    </React.Fragment>
                )}

                <TableCell className="data" classes={tableCellClassOverrides}>
                    {v2referral?.status && formatStatusValue(v2referral.status.value)}
                </TableCell>

                <TableCell className="actions" padding="none">
                    <Button className="view" onClick={onShowModal}>
                        View
                    </Button>
                    {showModal && (
                        <ReferralModal
                            newTestReferral={false}
                            newManualReferral={false}
                            onClose={onCloseModal}
                            referralId={v2referral?.id}
                            onUpdate={(currentStatus, nextStatus, referral) => {
                                patchReferralsTable(referral)
                                onCloseModal()
                            }}
                            practice={practice}
                            account={account}
                            actions={
                                <div className="referral-actions">
                                    <Button
                                        className="download"
                                        classes={buttonClassOverrides}
                                        onClick={onDownloadReferral}
                                    >
                                        <i className="material-icons">get_app</i>
                                    </Button>
                                    <Button
                                        className="delete"
                                        classes={buttonClassOverrides}
                                        onClick={onDeleteReferral}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            }
                        />
                    )}
                </TableCell>
            </TableRow>
            <ConfirmModal
                title={`Are you sure you want to remove the request for "${patientFullName}"?`}
                subtitle=""
                discardText="Cancel"
                confirmText="Remove"
                open={showDelete}
                onClose={onRemoveDiscard}
                onConfirm={() => {
                    dispatch(deleteReferral(practice.id, v2referral.id)).then(() => {
                        window.alert(`Request for '${patientFullName}' was removed.`)
                    })
                }}
                onDiscard={onRemoveDiscard}
            />
        </>
    )
}

export default AmplifyReferral
