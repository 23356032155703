import * as React from 'react'

export default function UpwardLightning() {
    // tslint:disable
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="45" viewBox="0 0 19 45">
            <path
                fill="#B8E986"
                fillRule="evenodd"
                d="M0 6.53l1.612 23.024 7.717-3.731L9.946 45 19 12.707l-8.51 4.331L13.506 0z"
            />
        </svg>
    )
}
