import PubNub from 'pubnub'

const {
    REACT_APP_PUBNUB_PUBLISH_KEY,
    REACT_APP_PUBNUB_SUBSCRIBE_KEY,
    REACT_APP_SMILE_SPECIALIST_CHANNEL,
    REACT_APP_PUBNUB_LOGGER,
} = process.env

class PubNubService {
    pubnub: PubNub

    constructor() {
        this.pubnub = new PubNub({
            publishKey: REACT_APP_PUBNUB_PUBLISH_KEY as string,
            subscribeKey: REACT_APP_PUBNUB_SUBSCRIBE_KEY as string,
            logVerbosity: REACT_APP_PUBNUB_LOGGER === 'true',
            ssl: true,
        })
    }

    setChatterId(chatterId: string) {
        this.pubnub.setUUID(chatterId)
    }

    getChatterId() {
        return this.pubnub.getUUID()
    }

    setAuthKey(authKey: string) {
        this.pubnub.setAuthKey(authKey)
    }

    addListener(listener: PubNub.ListenerParameters<Services.PubNub.MessageEvent, Services.PubNub.Presence>) {
        this.pubnub.addListener(listener)
    }

    removeListener(listener: PubNub.ListenerParameters<Services.PubNub.MessageEvent, Services.PubNub.Presence>) {
        this.pubnub.removeListener(listener)
    }

    async getAllChatHistory(
        iteration: number,
        channelName: string,
        start?: string,
        end?: string,
    ): Promise<PubNub.HistoryResponse<Services.PubNub.Message>> {
        const history = await this.getChatHistory(channelName, end, undefined)
        const historyMessages = history.messages
        if (
            history.messages.length > 0 &&
            history.startTimeToken !== '0' &&
            history.startTimeToken !== history.endTimeToken &&
            iteration < 10
        ) {
            return {
                messages: [...historyMessages].concat(
                    await (await this.getAllChatHistory(++iteration, channelName, undefined, history.startTimeToken))
                        .messages,
                ),
                startTimeToken: history.startTimeToken,
                endTimeToken: history.endTimeToken,
            }
        } else {
            return history
        }
    }

    async getChatHistory(channelName: string, start?: string, end?: string) {
        return this.pubnub.history<Services.PubNub.Message>({
            channel: channelName,
            count: 100,
            start,
            end,
        })
    }

    async hereNow(channelName: string) {
        return this.pubnub.hereNow({
            channels: [channelName],
            includeUUIDs: true,
        })
    }

    batchHistory(channels: string[], count: number = 1) {
        return this.pubnub.fetchMessages<Services.PubNub.Message>({
            channels,
            count,
        })
    }

    async send(channel: string, message: Services.PubNub.Message, metaData: Services.PubNub.MessageMetadata = {}) {
        return this.pubnub.publish({
            message,
            channel,
            storeInHistory: true,
            meta: metaData,
        })
    }

    async fireEvent(type: Services.PubNub.EventType, payload: any) {
        await this.pubnub.publish({
            message: { type, payload },
            channel: REACT_APP_SMILE_SPECIALIST_CHANNEL as string,
        })
    }

    async setState(channel: string, state: any) {
        const response = await this.pubnub.setState({
            channels: [channel],
            state,
        })

        return response
    }

    subscribe(subscribedChannels: string | string[], withPresence: boolean = true) {
        // From PubNub docs: "Specifies timetoken from which to start returning any available cached messages.
        // Message retrieval with timetoken is not guaranteed and should only be considered a best-effort service."
        // We're gettting all chat history through PubNub REST API, so there is no need to get cached messages.
        // Removing timetoken causes getting onPresence state-changed history which makes chat-center components to re-render.
        const timetoken = new Date().getTime() * 10000
        const channels = typeof subscribedChannels === 'string' ? [subscribedChannels] : subscribedChannels
        this.pubnub.subscribe({
            channels,
            withPresence,
            timetoken,
        })
    }

    unsubscribe(channels: Array<Models.ChatMetadata>) {
        const channelNames = channels.map(c => c.channelName)
        this.pubnub.unsubscribe({ channels: channelNames })
    }

    unsubscribeAll() {
        this.pubnub.unsubscribeAll()
    }
}

export default new PubNubService()
