import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'
import throttle from 'lodash/throttle'

import { RootState } from '../../appReducer'
import AdminAccount from '../../models/AdminAccount'
import { SearchBar } from '../shared/custom-fields/SearchBar'
import InfoMessage from '../shared/info-message/info-message'
import Paginator from '../shared/Paginator'
import SuccessMessage from '../shared/success-message/success-message'

import { fetchPaginatedWebCodes, saveWebCode } from './actions'
import WebCodeCard from './WebCodeCard'
import WebCodeCustomizationForm from './WebCodeCustomizationForm'
import WebCodeForm from './WebCodeForm'

import './WebCodeManager.sass'

export type Props = {
    account: AdminAccount
    practice: Models.Practice
    onBack: () => void
}

enum ModalTypesEnum {
    CRUD = 'CRUD',
    CUSOTMIZE = 'CUSTOMIZE',
}

const WebCodeManager = ({ practice, account, onBack }: Props) => {
    const initLoadComplete = useRef(false)

    const dispatch = useDispatch()

    const webCodes = useSelector((state: RootState) => state.amplify.webCodes[practice.id])

    const [activeModal, setActiveModal] = useState<ModalTypesEnum | null>(null)
    const [webCode, setWebCode] = useState<Models.WebCode | undefined>()
    const [closeMsg, setCloseMessage] = useState<string>('')
    const [isListLoading, setIsListLoading] = useState<boolean>(true)
    const [firstListLoadDone, setFirstListLoadDone] = useState<boolean>(false)
    const [hasSearch, setHasSearch] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchValue, setSearchValue] = useState<string>('')
    const [paginationInfo, setPaginationInfo] = useState<Models.PaginationInfo>({
        allPages: 1,
        allRows: 0,
    })

    const practiceId = practice?.id

    const noRecordsFound = !isListLoading && searchValue === '' && webCodes.length === 0

    const handleFetchWebCodes = useCallback(
        async (nextPage: number, search: string) => {
            try {
                setCurrentPage(nextPage)
                setIsListLoading(true)
                const metadata = await dispatch(fetchPaginatedWebCodes(practiceId, { search, page: nextPage }))
                setPaginationInfo({
                    allPages: metadata?.pagination_info?.allPages || nextPage,
                    allRows: metadata?.pagination_info?.allRows || 0,
                })
                setIsListLoading(false)
                initLoadComplete.current = true
            } catch (_) {
                setIsListLoading(false)
            }
        },
        [dispatch, practiceId],
    )

    const handleDebouncedSearch = useMemo(() => throttle(handleFetchWebCodes, 500), [handleFetchWebCodes])

    useEffect(() => {
        if (!initLoadComplete.current) {
            handleFetchWebCodes(1, '')
        }
    }, [handleFetchWebCodes])

    useEffect(() => {
        if (initLoadComplete.current) {
            handleDebouncedSearch(1, searchValue)
        }
    }, [handleDebouncedSearch, searchValue])

    useEffect(() => {
        if ((paginationInfo.allPages > 1 || searchValue.trim() !== '') && !firstListLoadDone) {
            setHasSearch(true)
            setFirstListLoadDone(true)
        }
    }, [paginationInfo, searchValue, firstListLoadDone])

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const clearSearch = () => {
        setSearchValue('')
    }

    const onPageChange = (newPage: number) => {
        handleFetchWebCodes(newPage, searchValue)
    }

    const onCloseModal = (closeMsg?: string | undefined) => {
        setActiveModal(null)
        setWebCode(undefined)

        if (closeMsg) {
            setCloseMessage(closeMsg)
            setTimeout(() => {
                setCloseMessage('')
            }, 2500)
        }
        return
    }

    const onShowModal = () => {
        setActiveModal(ModalTypesEnum.CRUD)
    }

    const onEditWebCode = (webCode: Models.WebCode) => {
        setActiveModal(ModalTypesEnum.CRUD)
        setWebCode(webCode)
    }

    const onCustomizeWebCode = (webCode: Models.WebCode) => {
        setActiveModal(ModalTypesEnum.CUSOTMIZE)
        setWebCode(webCode)
    }

    const onSaveWebCode = (webCode: Models.WebCode, updates: Api.WebCodeUpdate) => {
        return dispatch(saveWebCode(practice, webCode, updates)).then(() => onCloseModal())
    }

    const onToggleActive = (webCode: Models.WebCode) => {
        const updates = {
            id: webCode.id,
            active: !webCode.active,
        }
        onSaveWebCode(webCode, updates)
    }

    if (currentPage === 1 && !firstListLoadDone && !webCodes) {
        return (
            <div className={classNames('web-code-manager')}>
                <div className="loader loader--global">
                    <CircularProgress className="loader-spinner" size={50} color="primary" variant="indeterminate" />
                </div>
            </div>
        )
    }

    return (
        <div className={classNames('web-code-manager')}>
            {closeMsg && <SuccessMessage isShown={Boolean(closeMsg)}>{closeMsg}</SuccessMessage>}
            <div className={`back-button-wrapper`}>
                <button className="back-button" onClick={onBack}>
                    <i className="material-icons">arrow_back</i>
                </button>
                <div className="search-container">
                    {hasSearch && (
                        <SearchBar
                            value={searchValue}
                            onChange={onSearch}
                            onClear={clearSearch}
                            isMessageShown={searchValue.length > 0 && !webCodes?.length}
                            placeholder="Search by URL or Web Code Key"
                        />
                    )}
                    {noRecordsFound && <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>}
                </div>
            </div>
            <div>
                <div className="card-container">
                    {webCodes?.map(webCode => (
                        <WebCodeCard
                            key={webCode.id}
                            webCode={webCode}
                            practice={practice}
                            onToggleActive={onToggleActive}
                            onEditWebCode={onEditWebCode}
                            onCustomizeWebCode={onCustomizeWebCode}
                        />
                    ))}
                    {isListLoading && (
                        <div className="loader loader--list">
                            <CircularProgress
                                className="loader-spinner"
                                size={50}
                                color="primary"
                                variant="indeterminate"
                            />
                        </div>
                    )}
                </div>
                {!noRecordsFound && (
                    <div className="web-code-manager__paginator-wrapper">
                        <Paginator
                            currentPage={currentPage}
                            paginationInfo={paginationInfo}
                            selectPage={onPageChange}
                        />
                    </div>
                )}
            </div>

            {activeModal === ModalTypesEnum.CRUD && (
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(_, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <WebCodeForm
                        practice={practice}
                        webCode={webCode}
                        onClose={saved => {
                            onCloseModal(saved)
                        }}
                    />
                </Modal>
            )}
            {activeModal === ModalTypesEnum.CUSOTMIZE && webCode && (
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(_, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents code-modal">
                        <div className="close-modal-button" onClick={() => onCloseModal()}>
                            <i className="material-icons">close</i>
                        </div>
                        <WebCodeCustomizationForm
                            practice={practice}
                            webCode={webCode}
                            onSave={onSaveWebCode}
                            account={account}
                        />
                    </div>
                </Modal>
            )}
            <div className="web-code-manager__footer" onClick={onShowModal}>
                <div className="button">
                    <i className="material-icons add-icon">add</i>
                    Add Web Code
                </div>
            </div>
        </div>
    )
}

export default WebCodeManager
