import React from 'react'
import classNames from 'classnames'

import './CustomAtomToggle.sass'

export interface CustomAtomToggleOption {
    name: string
    value: string
}

type Props = {
    options: CustomAtomToggleOption[]
    value: string | undefined
    error?: boolean | string
    errorMessage?: string
    onClick: (option: any) => void
}

const moduleName = 'custom-atom-toggle'

const CustomAtomToggle = (props: Props) => {
    const renderToggle = (): React.ReactNode => {
        return props.options.map(option => {
            return (
                <div
                    className={classNames(`${moduleName}__tab`, {
                        [`${moduleName}__tab--selected`]: props.value === option.value,
                        [`${moduleName}__tab--error`]: Boolean(props.error),
                    })}
                    onClick={() => props.onClick(option.value)}
                    key={option.name}
                >
                    {option.name}
                </div>
            )
        })
    }

    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__tabs`}>{renderToggle()}</div>
            {props.error && <div className={`${moduleName}__error-message`}>{props.errorMessage}</div>}
        </div>
    )
}

export default CustomAtomToggle
