import React from 'react'
import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import { setRedirect } from '../../appActions'
import AdminAccount from '../../models/AdminAccount'
import { AccessReferralsList } from '../../models/enums'
import { Chairfill } from '../../models/enums'
import { useAppDispatch } from '../../util/useAppDispatch'
import { grayDa, softBlack, verySoftBlack, white } from '../shared/styles/colors'

type Props = {
    practice: Models.Practice
    account: AdminAccount
    classes: any
}

const styles: any = () => ({
    container: {
        marginBottom: '20px',
    },
    button: {
        background: softBlack,
        borderRadius: '2px',
        color: white,
        fontSize: '14px',
        letterSpacing: '0.5px',
        margin: '0 2px',
        padding: '4px 12px',
        textAlign: 'center',
        textTransform: 'uppercase',
        '&:hover': {
            background: verySoftBlack,
        },
    },
    icon: {
        color: white,
        fontSize: '18px',
        paddingRight: '8px',
        height: '24px',
    },
    iconAvailability: {
        color: white,
        fontSize: '18px',
        height: '24px',
        paddingRight: '8px',
    },
    iconCancelReschedule: {
        color: white,
        fontSize: '18px',
        height: '24px',
        paddingRight: '8px',
    },
    iconReferrals: {
        color: white,
        fontSize: '18px',
        height: '24px',
        paddingRight: '8px',
    },
    inactive: {
        backgroundColor: grayDa,
        '&:hover': {
            background: grayDa,
        },
    },
})

function ChairfillViewsList({ account, practice, classes }: Props) {
    const dispatch = useAppDispatch()

    function onViewReferrals() {
        dispatch(setRedirect(`/diagnostics/${practice.id}/amplify`))
    }

    return (
        <div className={classes.container}>
            {account.hasAccess(AccessReferralsList) && (
                <Button
                    className={classNames(classes.button, {
                        [classes.inactive]: !practice.products.some(p => p.value === Chairfill.value && p.active),
                    })}
                    onClick={onViewReferrals}
                    variant="text"
                    color="primary"
                >
                    <span className={classes.iconReferrals}>
                        <i className="material-icons">device_hub</i>
                    </span>
                    Requests
                </Button>
            )}
        </div>
    )
}

export default withStyles(styles)(ChairfillViewsList)
