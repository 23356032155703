import React, { ChangeEvent, Fragment, useMemo, useState } from 'react'
import classNames from 'classnames'
import validators from 'validator'

import { CustomFieldType } from '../../../models/enums'
import { VALID_URL_PROTOCOL_REGEX } from '../../../modules/amplify/shared/validator'
import CopyText from '../../../modules/shared/CopyText'
import CustomInput from '../../shared/custom-fields/CustomField'

import './SelfSchedulingWebCodeForm.sass'

export type SelfSchedulingWebCodeFormProps = {
    practice: Models.Practice
    webCode?: Models.SelfSchedulingWebCode
    onSaveWebCode: (updates: Api.SelfScheduling.WebCodeUpdate) => Promise<void>
    onGenerateSelfSchedulingWebCode: (url: string) => Promise<Models.SelfSchedulingWebCode>
    onCloseModal: () => void
}

const moduleName = 'self-scheduling-web-code-form'

function SelfSchedulingWebCodeForm(props: SelfSchedulingWebCodeFormProps) {
    const { webCode, practice } = props
    const directLink = `${process.env.REACT_APP_SELF_SCHEDULING_DIRECT_LINK_HOST}/#key=${webCode?.key}&gaID=${webCode?.googleAnalyticsId}`

    const [webCodeURL, setWebCodeURL] = useState<string | undefined>(webCode?.url)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [hasSaved, setHasSaved] = useState<boolean>(false)
    const [savedEmbedCode, setSavedEmbedCode] = useState<string>()
    const [savedDirectLink, setSavedDirectLink] = useState<string>()
    const [urlErr, setUrlErr] = useState<boolean>(false)

    const isValidUrl =
        validators.isURL(webCodeURL || '', {
            protocols: ['https', 'http'],
            require_protocol: true,
            require_valid_protocol: true,
        }) && !urlErr

    const canSave = useMemo(
        () => Boolean(isDirty) && Boolean(webCodeURL) && webCodeURL !== webCode?.url && isValidUrl,
        [isDirty, webCodeURL, webCode, isValidUrl],
    )

    function handleURLChange(event: ChangeEvent<HTMLInputElement>) {
        setWebCodeURL(event.target.value)
        setIsDirty(true)
    }

    function renderWebsiteInput() {
        const urlErrorMsg = () => {
            if (!VALID_URL_PROTOCOL_REGEX.test(webCodeURL || '') && isDirty) {
                return 'Please enter protocol (ex https://)'
            }
            if (
                (!validators.isURL(webCodeURL || '', {
                    protocols: ['https', 'http'],
                    require_protocol: true,
                    require_valid_protocol: true,
                }) &&
                    isDirty) ||
                urlErr
            ) {
                return 'URL is invalid.'
            }

            return ''
        }
        const urlError = urlErrorMsg()
        return (
            <>
                <CustomInput
                    customFieldType={CustomFieldType.INPUT}
                    placeholder={' https://vynedental.com/simplifeye'}
                    value={webCodeURL || ''}
                    error={urlError !== ''}
                    errorMessage={urlError}
                    onChange={handleURLChange}
                />
                {urlError && <span className={`${moduleName}__error`}>{urlError}</span>}
            </>
        )
    }

    async function onGenerateSelfSchedulingWebCode() {
        if (!webCodeURL) {
            return
        }

        const generatedWebCode = await props.onGenerateSelfSchedulingWebCode(webCodeURL)

        setHasSaved(true)
        setSavedEmbedCode(generatedWebCode.embedCode)
        setSavedDirectLink(`${process.env.REACT_APP_SELF_SCHEDULING_DIRECT_LINK_HOST}/#key=${generatedWebCode.key}`)
        setIsDirty(false)
    }

    async function onSave() {
        if (!webCode || !isDirty) {
            props.onCloseModal()
            return
        }

        props.onSaveWebCode({
            id: webCode.id,
            url: webCodeURL,
        })
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__container`}>
                {webCode && (
                    <div className={`${moduleName}__key`}>
                        <CopyText text={webCode.key}>Web Code Key</CopyText>
                    </div>
                )}
                <h1 className={`${moduleName}__header`}>{practice.name}</h1>
                {renderWebsiteInput()}
                <div className={`${moduleName}__copy-code-wrapper`}>
                    {(webCode || savedEmbedCode) && (
                        <Fragment>
                            <span className={`${moduleName}__code-header`}> Self-Scheduling Code</span>
                            <CopyText text={webCode ? webCode.embedCode : savedEmbedCode || ''}>
                                <span
                                    className={classNames(`${moduleName}__copy-code-button`, {
                                        [`${moduleName}__copy-code-button--active`]: hasSaved,
                                    })}
                                >
                                    copy code
                                </span>
                            </CopyText>
                        </Fragment>
                    )}
                </div>
                <div className={`${moduleName}__embed-code-wrapper`}>
                    <div className={`${moduleName}__embed-code`}>
                        <pre>{webCode ? webCode.embedCode : savedEmbedCode}</pre>
                    </div>
                </div>
                <div className={`${moduleName}__copy-code-wrapper`}>
                    {(webCode || savedEmbedCode) && (
                        <Fragment>
                            <span className={`${moduleName}__code-header`}>Self-Scheduling Direct Link</span>
                            <CopyText text={webCode ? directLink : savedDirectLink || ''}>
                                <span
                                    className={classNames(`${moduleName}__copy-code-button`, {
                                        [`${moduleName}__copy-code-button--active`]: hasSaved,
                                    })}
                                >
                                    copy link
                                </span>
                            </CopyText>
                        </Fragment>
                    )}
                </div>
                <div className={`${moduleName}__embed-code-wrapper`}>
                    <div className={`${moduleName}__embed-link`}>
                        <pre>{webCode ? directLink : savedDirectLink}</pre>
                    </div>
                </div>
            </div>
            <div>
                {webCode ? (
                    <button
                        className={classNames(`bottom-save-button`, { 'can-save': canSave })}
                        onClick={onSave}
                        disabled={!canSave}
                    >
                        save
                    </button>
                ) : (
                    <button
                        className={classNames(`bottom-save-button`, { 'can-save': canSave })}
                        onClick={onGenerateSelfSchedulingWebCode}
                        disabled={!canSave}
                    >
                        Generate Code and Direct Link
                    </button>
                )}
            </div>
        </div>
    )
}

export default SelfSchedulingWebCodeForm
