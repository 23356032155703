import last from 'lodash/last'
import moment from 'moment'

export const momentByTime = (hours: number, minutes: number = 0): moment.Moment =>
    moment()
        .set('hour', hours)
        .set('minutes', minutes)

export const minuteSlots = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]

export const START_HOUR = 8
export const END_HOUR = 17

export enum TimeSlotState {
    AVAILABLE = 'available', // green
    UNAVAILABLE = 'unavailable', // white
}

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6

export const hourSlots = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
export const timeSlots = (isStartTime: boolean = true) =>
    [...hourSlots, 24].reduce((list, hour, index, source) => {
        if (isStartTime && index === source.length - 1) {
            return list
        }

        const times = minuteSlots.reduce((minutesList, minute, minuteIndex) => {
            if (!isStartTime && index === 0 && minuteIndex === 0) {
                return minutesList
            }
            if (!isStartTime && index === source.length - 1 && minuteIndex > 0) {
                return minutesList
            }
            return [...minutesList, momentByTime(hour, minute)]
        }, [])

        return [...list, ...times]
    }, [])
