import iassign from 'immutable-assign'
import _ from 'lodash'

import { mapChatAlert, mapChatAlertTask, mapWebCode } from '../../Api/mappers/amplify'
import { mapReferralV2 } from '../../ApiV2/mappers/referrals/referral'
import { AmplifyWebCodeSurveySection } from '../../models/AmplifyWebCodeSurveys'

import {
    AmplifyClearSurveyErrors,
    AmplifySetActiveWebCode,
    AmplifySetSurveyErrors,
    AmplifySetWeSurveyQuestion,
    ReceiveAmplifyWebCodeLocation,
    ReceiveAmplifyWebCodeLocationsList,
    ReceiveChatAlert,
    ReceiveChatAlertList,
    ReceiveChatAlertTask,
    ReceiveChatAlertTasksList,
    ReceiveReferralList,
    ReceiveSubscriber,
    ReceiveSubscriberList,
    ReceiveWebCode,
    ReceiveWebCodes,
    ReceiveWebCodesV1,
    RecieveAmplifyWebcodeConnectSurvey,
    RecieveAmplifyWebCodeSurvey,
    RemoveChatAlert,
    RemoveReferral,
    RemoveSubscriber,
    SetAvailableLocationsFetching,
} from './actions'

export type AmplifyState = {
    referrals: { [key: string]: Array<Models.Referral> }
    subscribers: { [key: string]: Array<Models.Subscriber> }
    activeWebCode: string
    webCodes: { [key: string]: Array<Models.WebCode> }
    webCodeSurveys: { [key: string]: Array<AmplifyWebCodeSurveySection> }
    alerts: { [key: string]: Array<Models.ChatAlert> }
    tasks: { [key: string]: Array<Models.ChatAlertTask> }
    referralsPaginationInfo?: Models.PaginationInfo
    practiceStaff: Array<Models.Account>
    webCodesLocations: { [webCodeId: string]: Models.AmplifyWebCodeLocationsData }
}

export type AmplifyAction =
    | ReceiveReferralList
    | RemoveReferral
    | ReceiveSubscriberList
    | ReceiveSubscriber
    | RemoveSubscriber
    | ReceiveWebCodes
    | ReceiveWebCode
    | ReceiveChatAlertList
    | ReceiveChatAlert
    | RemoveChatAlert
    | ReceiveChatAlertTask
    | ReceiveChatAlertTasksList
    | ReceiveAmplifyWebCodeLocation
    | ReceiveAmplifyWebCodeLocationsList
    | SetAvailableLocationsFetching
    | RecieveAmplifyWebCodeSurvey
    | RecieveAmplifyWebcodeConnectSurvey
    | AmplifySetActiveWebCode
    | AmplifySetWeSurveyQuestion
    | AmplifySetSurveyErrors
    | ReceiveWebCodesV1
    | AmplifyClearSurveyErrors

const initialState: AmplifyState = {
    referrals: {},
    referralsPaginationInfo: {
        allPages: 1,
        allRows: 0,
    },
    subscribers: {},
    activeWebCode: '',
    webCodes: {},
    webCodeSurveys: {},
    alerts: {},
    tasks: {},
    practiceStaff: [],
    webCodesLocations: {},
}

export function reducer(state: AmplifyState = initialState, action: AmplifyAction) {
    switch (action.type) {
        case 'AMPLIFY_RECEIVE_REFERRAL_LIST': {
            return iassign(state, next => {
                next.referrals[action.practice.id] = action.referrals?.map(mapReferralV2) ?? []
                next.referralsPaginationInfo = action.paginationInfo

                return next
            })
        }

        case 'AMPLIFY_REMOVE_REFERRAL': {
            return iassign(
                state,
                next => next.referrals[action.practiceId],
                next => {
                    return _.filter(next, r => r.id !== action.referralId)
                },
            )
        }
        case 'AMPLIFY_RECEIVE_SUBSCRIBER_LIST': {
            return iassign(state, next => {
                next.subscribers[action.practice.id] = _.sortBy(action.subscribers, 'created').reverse()
                return next
            })
        }
        case 'AMPLIFY_RECEIVE_SUBSCRIBER': {
            return iassign(
                state,
                n => n.subscribers[action.practice.id],
                next => {
                    if (next == null) {
                        next = []
                    }
                    next.unshift(action.subscriber)
                    return _.sortBy(_.uniqBy(next, 'id'), 'created').reverse()
                },
            )
        }
        case 'AMPLIFY_REMOVE_SUBSCRIBER': {
            return iassign(
                state,
                next => next.subscribers[action.practice.id],
                next => {
                    return _.filter(next, r => r.id !== action.subscriber.id)
                },
            )
        }
        case 'AMPLIFY_RECEIVE_WEB_CODES': {
            return iassign(
                state,
                next => next.webCodes,
                next => {
                    next[action.practiceId] = action.webCodes.map(mapWebCode).map(newWebCode => {
                        const previousConnectValues = next[action.practiceId]?.find(w => w.id === newWebCode.id)
                            ?.connect
                        if (previousConnectValues) {
                            return { ...newWebCode, connect: { ...previousConnectValues } }
                        }
                        return newWebCode
                    })
                    return next
                },
            )
        }

        case 'AMPLIFY_RECEIVE_WEB_CODES_V1': {
            return iassign(
                state,
                next => next.webCodes,
                next => {
                    next[action.practiceId] = next[action.practiceId].map(webcode => {
                        const webCodeV1 = action.webCodes.find(webcodeV1 => webcodeV1.id === webcode.id)

                        if (webCodeV1) {
                            return {
                                ...webcode,
                                connect: webCodeV1.connect,
                            }
                        }

                        return webcode
                    })
                    return next
                },
            )
        }

        case 'AMPLIFY_RECEIVE_WEB_CODE': {
            return iassign(
                state,
                next => next.webCodes[action.practice.id],
                next => {
                    const i = next.findIndex(c => c.id === action.webCode.id)
                    if (i === -1) {
                        next.push(mapWebCode(action.webCode))
                        return next
                    }
                    next[i] = mapWebCode(action.webCode)
                    return next
                },
            )
        }

        case 'AMPLIFY_SET_ACTIVE_WEB_CODE': {
            return iassign(
                state,
                next => next.activeWebCode,
                next => {
                    next = action.webCodeId
                    return next
                },
            )
        }

        case 'RECEIVE_AMPLIFY_WEB_CODE_SURVEY': {
            return iassign(
                state,
                next => next.webCodeSurveys,
                nextSurveys => {
                    nextSurveys[action.webCodeId] = action.surveys

                    return nextSurveys
                },
            )
        }

        case 'RECEIVE_AMPLIFY_WEB_CODE_CONNECT_SURVEY': {
            return iassign(
                state,
                next => next.webCodes[action.practiceId],
                next => {
                    const i = next.findIndex(c => c.id === action.amplifyWebcodeId)
                    if (i === -1) {
                        return next
                    }
                    next[i] = { ...next[i], connectSurvey: action.connectSurvey }
                    return next
                },
            )
        }

        case 'AMPLIFY_SET_WEB_CODE_SURVEY_QUESTION': {
            return iassign(
                state,
                next => next.webCodeSurveys[action.webCodeId],
                nextSurveys => {
                    const surveyIndex = nextSurveys.findIndex(survey => survey.id === action.survey.id)
                    if (surveyIndex === -1) {
                        return nextSurveys
                    }

                    const questionIndex = nextSurveys[surveyIndex].questions.findIndex(
                        question => question.id === action.question.id,
                    )
                    if (questionIndex === -1) {
                        return nextSurveys
                    }

                    nextSurveys[surveyIndex].questions[questionIndex] = action.question
                    nextSurveys[surveyIndex].isValid = nextSurveys[surveyIndex].questions.every(
                        question => question.isValid,
                    )

                    return nextSurveys
                },
            )
        }

        case 'AMPLIFY_SET_SURVEY_ERROR_DETAILS': {
            return iassign(
                state,
                next => next.webCodeSurveys[action.webCodeId],
                nextSurveys => {
                    return nextSurveys.map(survey => {
                        return {
                            ...survey,
                            questions: survey.questions.map(question => ({
                                ...question,
                                isValid: !Boolean(action.errorDetails[question.id]),
                                error: Boolean(action.errorDetails[question.id]),
                                errorMessage: action.errorDetails[question.id]
                                    ? action.errorDetails[question.id].details
                                    : '',
                            })),
                        }
                    })
                },
            )
        }

        case 'AMPLIFY_CLEAR_SURVEY_ERROR_DETAILS': {
            return iassign(
                state,
                next => next.webCodeSurveys[action.webCodeId],
                nextSurveys => {
                    return nextSurveys.map(survey => {
                        return {
                            ...survey,
                            questions: survey.questions.map(question => ({
                                ...question,
                                isValid: true,
                                error: false,
                                errorMessage: '',
                            })),
                        }
                    })
                },
            )
        }

        case 'AMPLIFY_RECEIVE_CHAT_ALERT_LIST': {
            return iassign(
                state,
                next => next.alerts[action.practiceId],
                nextAlerts => {
                    nextAlerts = _.orderBy(action.alerts.map(mapChatAlert), ['orderNumber', 'created'], ['asc', 'desc'])
                    return nextAlerts
                },
            )
        }
        case 'AMPLIFY_RECEIVE_CHAT_ALERT_TASK': {
            return iassign(
                state,
                next => next.tasks[action.chatId],
                nextChatAlertTasks => {
                    const foundTaskIndex = nextChatAlertTasks.findIndex(task => task.id === action.task.id)

                    if (foundTaskIndex === -1) {
                        nextChatAlertTasks.push(mapChatAlertTask(action.task))
                    } else {
                        nextChatAlertTasks[foundTaskIndex] = mapChatAlertTask(action.task)
                    }

                    return _.orderBy(nextChatAlertTasks, ['orderNumber', 'created'], ['asc', 'desc'])
                },
            )
        }
        case 'AMPLIFY_RECEIVE_CHAT_ALERT_TASKS_LIST': {
            return iassign(
                state,
                next => next.tasks[action.chatId],
                nextTasks => {
                    nextTasks = _.orderBy(
                        action.tasks.map(mapChatAlertTask),
                        ['orderNumber', 'created'],
                        ['asc', 'desc'],
                    )
                    return nextTasks
                },
            )
        }
        case 'AMPLIFY_RECEIVE_CHAT_ALERT': {
            return iassign(
                state,
                next => next.alerts[action.practice.id],
                next => {
                    if (next == null) {
                        next = []
                    }
                    next.unshift(mapChatAlert(action.alert))
                    return _.orderBy(_.uniqBy(next, 'id'), ['orderNumber', 'created'], ['asc', 'desc'])
                },
            )
        }
        case 'AMPLIFY_REMOVE_CHAT_ALERT': {
            return iassign(
                state,
                next => next.alerts[action.practice.id],
                next => {
                    return _.filter(next, a => a.id !== action.alert.id)
                },
            )
        }
        case 'SET_AMPLIFY_WEB_CODE_LOCATION_FETCHING': {
            return iassign(
                state,
                next => next.webCodesLocations[action.webCodeId],
                nextWebCodeLocations => {
                    if (!nextWebCodeLocations) {
                        nextWebCodeLocations = {
                            locations: [],
                            isFetching: action.isFetching,
                            currentPage: 0,
                        }
                    } else {
                        nextWebCodeLocations.isFetching = action.isFetching
                    }
                    return nextWebCodeLocations
                },
            )
        }
        case 'RECEIVE_AMPLIFY_WEB_CODE_LOCATION': {
            return iassign(
                state,
                next => next.webCodesLocations[action.location.amplifyWebCodeId].locations,
                nextWebCodesLocations => {
                    const idx = nextWebCodesLocations.findIndex(
                        webCodeLocation => webCodeLocation.practiceLocationId === action.location.practiceLocationId,
                    )

                    if (idx === -1) {
                        nextWebCodesLocations.push(action.location)
                        return nextWebCodesLocations
                    }

                    nextWebCodesLocations[idx] = action.location
                    return nextWebCodesLocations
                },
            )
        }
        case 'RECEIVE_AMPLIFY_WEB_CODE_LOCATIONS_LIST': {
            const { webCodeId, page, paginationInfo, locations } = action.receiveWebCodeLocationsList
            return iassign(
                state,
                next => next.webCodesLocations[webCodeId],
                nextWebCodeLocations => {
                    if (!nextWebCodeLocations || page === 1) {
                        const newLocationsData = {
                            locations: _.sortBy(locations, [location => location.name.toLowerCase()]),
                            paginationInfo,
                            currentPage: page,
                        }
                        nextWebCodeLocations = newLocationsData
                    } else {
                        nextWebCodeLocations.locations = _.sortBy(
                            _.unionBy(locations, nextWebCodeLocations.locations, 'practiceLocationId'),
                            [location => location.name.toLowerCase()],
                        )
                        nextWebCodeLocations.paginationInfo = paginationInfo
                        nextWebCodeLocations.currentPage = page
                    }
                    nextWebCodeLocations.isFetching = false

                    return nextWebCodeLocations
                },
            )
        }
        default:
            return state
    }
}
