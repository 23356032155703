import React, { useState } from 'react'
import { Icon } from '@mui/material'

import SdrAndAe from './SdrAndAe/SdrAndAe'
import MonthlyStatements from './MonthlyStatements'

import SwapTransactionsModal from './modals/SwapTransactionsModal'
import ChangePaymentStatusModal from './modals/ChangePaymentStatusModal'
import UpdateEmailModal from './modals/UpdateEmailModal'

import './PaymentsMain.sass'
import UpdatePhoneModal from './modals/UpdatePhoneModal'
import CancelInvoiceModal from './modals/CancelInvoiceModal'
import ChangeChargeDateModal from './modals/ChangeChargeDateModal'
import RemovePaymentMethod from './modals/RemovePaymentMethod'
import CancelPaymentPlanModal from './modals/CancelPaymentPlanModal'
import RemoveChargeFromPlanModal from './modals/RemoveChargeFromPlanModal'

type PaymentModalTypes =
    | ''
    | 'payment-status'
    | 'charge-date'
    | 'swap-transactions'
    | 'update-email'
    | 'update-phone'
    | 'cancel-invoice'
    | 'remove-payment-method'
    | 'cancel-payment-plan'
    | 'remove-plan-charge'

export interface PaymentsMainProps {
    practice: Models.Practice
}

export default function PaymentsMain(props: PaymentsMainProps) {
    const [showMonthlyStatements, setShowMonthlyStatements] = useState(false)
    const [openModal, setOpenModal] = useState<PaymentModalTypes>('')

    return (
        <div className="payments-main-wrapper">
            {!showMonthlyStatements && (
                <>
                    <button className="payments-action-btn" onClick={() => setOpenModal('payment-status')}>
                        Change status of payment
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('charge-date')}>
                        Change charge date for Payment Plan
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('remove-plan-charge')}>
                        Remove a charge for a Payment Plan
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('swap-transactions')}>
                        Swap Transaction
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('cancel-invoice')}>
                        Cancel Sent Invoice
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('update-email')}>
                        Update Email
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('update-phone')}>
                        Update Phone Number
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('remove-payment-method')}>
                        Remove Payment Method
                    </button>
                    <button className="payments-action-btn" onClick={() => setOpenModal('cancel-payment-plan')}>
                        Cancel Payment Plan
                    </button>
                    <button className="payments-action-btn" onClick={() => setShowMonthlyStatements(true)}>
                        Monthly Statements
                    </button>
                </>
            )}
            {openModal === 'payment-status' && <ChangePaymentStatusModal onClose={() => setOpenModal('')} />}
            {openModal === 'cancel-payment-plan' && (
                <CancelPaymentPlanModal onClose={() => setOpenModal('')} practiceId={props.practice.id} />
            )}
            {openModal === 'charge-date' && (
                <ChangeChargeDateModal practiceId={props.practice.id} onClose={() => setOpenModal('')} />
            )}
            {openModal === 'swap-transactions' && <SwapTransactionsModal onClose={() => setOpenModal('')} />}
            {openModal === 'cancel-invoice' && (
                <CancelInvoiceModal onClose={() => setOpenModal('')} practiceId={props.practice.id} />
            )}
            {openModal === 'update-email' && (
                <UpdateEmailModal onClose={() => setOpenModal('')} practiceId={props.practice.id} />
            )}
            {openModal === 'update-phone' && (
                <UpdatePhoneModal onClose={() => setOpenModal('')} practiceId={props.practice.id} />
            )}
            {openModal === 'remove-payment-method' && (
                <RemovePaymentMethod onClose={() => setOpenModal('')} practiceId={props.practice.id} />
            )}
            {openModal === 'remove-plan-charge' && (
                <RemoveChargeFromPlanModal onClose={() => setOpenModal('')} practiceId={props.practice.id} />
            )}
            {showMonthlyStatements && (
                <MonthlyStatements
                    practiceId={props.practice.id}
                    practiceName={props.practice.name}
                    onClose={() => setShowMonthlyStatements(false)}
                />
            )}
            {false && (
                <>
                    <div className="payments-notice-info">
                        <Icon className="info-payment-icon">info_circle</Icon>
                        <span>
                            To view and edit terminals, STRIPE info, and rates/processing, please click on the Locations
                            tab.
                        </span>
                    </div>
                    <SdrAndAe practiceId={props.practice.id} />
                    <button></button>
                </>
            )}
        </div>
    )
}
