import { Button, Card, CardActions, CardContent, Switch } from '@mui/material'
import { CardContentClassKey } from '@mui/material/CardContent'
import { SwitchClassKey } from '@mui/material/Switch'
import classNames from 'classnames'

import { DirectScheduling } from '../../../models/enums'
import CopyText from '../../../modules/shared/CopyText'

import './SelfSchedulingWebCodeCard.sass'

type Props = {
    webCode: Models.SelfSchedulingWebCode
    practice: Models.Practice
    onToggleActive: (webCode: Models.SelfSchedulingWebCode) => void
    onEditWebCode: (webCode: Models.SelfSchedulingWebCode) => void
    onCustomizeWebCode: (webCode: Models.SelfSchedulingWebCode) => void
}

const moduleName = 'self-scheduling-web-code-card'

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch`,
    checked: `${moduleName}__switch--checked`,
}

type CardContentClassOverrides = {
    readonly [key in CardContentClassKey]?: string
}

const cardContentClassOverrides: CardContentClassOverrides = {
    root: `${moduleName}__card-content`,
}

function SelfSchedulingWebCodeCard(props: Props) {
    const { webCode, onToggleActive, onCustomizeWebCode, onEditWebCode } = props
    const directLink = `${process.env.REACT_APP_SELF_SCHEDULING_DIRECT_LINK_HOST}/#key=${webCode.key}&gaID=${webCode.googleAnalyticsId}`

    const directSchedulingProductDisabled = !props.practice.products.some(
        p => p.value === DirectScheduling.value && p.active,
    )

    return (
        <Card
            className={classNames(moduleName, {
                [`${moduleName}--code-inactive`]: !webCode.active,
            })}
        >
            <CardContent classes={cardContentClassOverrides}>
                <div className={`${moduleName}__wrapper`}>
                    <div className={`${moduleName}__header`}>
                        <div
                            className={classNames(`${moduleName}__url`, {
                                [`${moduleName}__url--active`]: webCode.active,
                            })}
                        >
                            {webCode.url}
                        </div>
                    </div>

                    <div className={`${moduleName}__container`}>
                        <div className={`${moduleName}__heading`}>
                            <div>
                                Self-Scheduling Code
                                <CopyText text={webCode.embedCode} />
                            </div>
                            {directSchedulingProductDisabled ? (
                                <Switch
                                    classes={switchClassOverrides}
                                    disabled={true}
                                    color="primary"
                                    checked={false}
                                />
                            ) : (
                                <Switch
                                    classes={switchClassOverrides}
                                    color="primary"
                                    checked={webCode.active}
                                    onClick={() => onToggleActive(webCode)}
                                />
                            )}
                        </div>
                    </div>
                    <div className={`${moduleName}__container`}>
                        <div className={`${moduleName}__ds-heading`}>
                            <div>
                                Direct Link
                                <CopyText text={directLink} />
                            </div>
                        </div>
                    </div>
                    <div className={`${moduleName}__container`}>
                        <div className={`${moduleName}__web-code-heading`}>Web Code Key</div>
                        <div className={`${moduleName}__web-code-key`}>
                            {webCode.key}
                            <CopyText text={webCode.key} />
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardActions className={`${moduleName}__actions`}>
                <div className={`${moduleName}__buttons ${moduleName}__buttons-space-between`}>
                    <Button className={`button`} size="small" onClick={() => onCustomizeWebCode(webCode)}>
                        Customize
                    </Button>
                    <Button className={`button`} size="small" onClick={() => onEditWebCode(webCode)}>
                        Edit
                    </Button>
                </div>
            </CardActions>
        </Card>
    )
}

export default SelfSchedulingWebCodeCard
