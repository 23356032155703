import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, IconButton, Table, TableBody, TableHead } from '@mui/material'
import classNames from 'classnames'

import { RootState } from '../../../../../appReducer'
import { CustomFieldType } from '../../../../../models/enums'
import { PracticeSurvey, PracticeSurveyState, SurveyDoctorRecord } from '../../../../../models/PracticeSurvey'
import ConfirmModal from '../../../../shared/confirm-modal/ConfirmModal'
import CustomField from '../../../../shared/custom-fields/CustomField'
import { deleteDoctor, updateDoctorsSection } from '../actions'
import { doctorField } from '../shared/factories/doctor'
import { Cell, HeaderCell, HeaderRow } from '../shared/Table'
import { validator } from '../shared/validator'

import './Doctors.sass'

interface Props {
    location: Models.PracticeLocation
}

interface DoctorErrorMessage {
    error: string
    questionId: string
}

const DoctorsEdit = ({ location }: Props) => {
    const {
        practiceSurvey,
        doctors,
        errorMessages,
    }: {
        practiceSurvey: PracticeSurvey
        doctors: SurveyDoctorRecord[]
        errorMessages: { [key: string]: string[] }
    } = useSelector((state: RootState) => ({
        practiceSurvey: state.practiceSurvey[location.id] ?? {},
        doctors: state.practiceSurvey[location.id]?.doctors?.list ?? [],
        errorMessages: state.practiceSurvey[location.id].errorMessages,
    }))
    const practiceSurveyState: PracticeSurveyState = practiceSurvey.state
    const [deleteDoctorIndex, setDeleteDoctorIndex] = useState<number | null>(null)

    const dispatch = useDispatch()

    const handleChange = async (index: number, fieldName: string, value?: string) => {
        const errorMessage = validator(doctors[index][fieldName], value)
        const nextDoctors = [...doctors]
        nextDoctors[index][fieldName] = {
            ...nextDoctors[index][fieldName],
            value,
            errorMessage,
            isValid: !errorMessage,
            isDirty: true,
        }
        dispatch(updateDoctorsSection(location.id, nextDoctors))
    }

    const deleteDoctorById = () => {
        const nextDoctors = doctors.filter((doctor, index) => index !== deleteDoctorIndex)

        dispatch(updateDoctorsSection(location.id, nextDoctors))

        const nextDoctor = doctors.filter((doctor, index) => index === deleteDoctorIndex)
        if (nextDoctor.length) {
            dispatch(deleteDoctor(location.id, nextDoctor[0]))
            setDeleteDoctorIndex(null)
        }
    }

    const handleDeleteDoctor = (deleteDoctorIndex: number) => {
        const nextDoctor = doctors.filter((doctor, index) => index === deleteDoctorIndex)
        if (nextDoctor.length && nextDoctor[0].id.value !== '') {
            setDeleteDoctorIndex(deleteDoctorIndex)
        } else {
            const nextDoctors = doctors.filter((doctor, index) => index !== deleteDoctorIndex)
            dispatch(updateDoctorsSection(location.id, nextDoctors))
            setDeleteDoctorIndex(null)
        }
    }
    const handleAdd = () => {
        dispatch(updateDoctorsSection(location.id, [...doctors, doctorField()]))
    }

    useEffect(() => {
        if (doctors?.length === 0) {
            dispatch(updateDoctorsSection(location.id, [...doctors, doctorField()]))
        }
    }, [doctors, location.id, dispatch])

    const doctorsErrorClass = () => {
        let hasError = false
        doctors.forEach(doctor => {
            if (practiceSurveyState === 'submitted' && (!doctor.firstName.isValid || !doctor.lastName.isValid)) {
                hasError = true
            }
        })
        return hasError ? 'survey-section__header-error' : ''
    }

    const getDoctorsErrorMessage = () => {
        const errorMessages = practiceSurvey.errorMessages?.['Doctors']
        const message = errorMessages?.length ? errorMessages[0] : ''

        return (message as unknown) as DoctorErrorMessage
    }

    const doctorsSectionInvalid = errorMessages && Boolean(errorMessages['Doctors'])

    return (
        <div className="survey-section survey-section--edit survey-doctors">
            <h4 className={`survey-section__header survey-doctors__header ${doctorsErrorClass()}`} id="doctors">
                Doctors
            </h4>

            <div className="survey-section__wrapper">
                <Table className="survey-doctors__table" aria-labelledby="Doctors">
                    <TableHead>
                        <HeaderRow>
                            <HeaderCell
                                className={classNames('survey-doctors__first-name', {
                                    'survey-section__header-error': doctorsSectionInvalid,
                                })}
                            >
                                First Name*
                            </HeaderCell>
                            <HeaderCell
                                className={classNames('survey-doctors__last-name', {
                                    'survey-section__header-error': doctorsSectionInvalid,
                                })}
                            >
                                Last Name*
                            </HeaderCell>
                            <HeaderCell className="survey-doctors__suffix-name">
                                Suffix <span className="survey-doctors__suffix-name-optional">(optional)</span>
                            </HeaderCell>
                            <HeaderCell className="survey-doctors__delete"> </HeaderCell>
                        </HeaderRow>
                    </TableHead>
                    <TableBody>
                        {doctors.map((doctor, index) => (
                            <HeaderRow key={`id_${index}`}>
                                <Cell align={'left'} className="survey-doctors__first-name">
                                    <CustomField
                                        customFieldType={CustomFieldType.INPUT}
                                        value={doctor.firstName.value}
                                        required={doctor.firstName.isRequired}
                                        error={
                                            (doctor.firstName.isDirty && !doctor.firstName.isValid) ||
                                            (doctorsSectionInvalid && !doctor.firstName.isValid)
                                        }
                                        errorMessage={doctor.firstName.errorMessage}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange(index, 'firstName', event.target.value)
                                        }
                                    />
                                </Cell>
                                <Cell align={'left'} className="survey-doctors__last-name">
                                    <CustomField
                                        customFieldType={CustomFieldType.INPUT}
                                        value={doctor.lastName.value}
                                        required={doctor.lastName.isRequired}
                                        error={
                                            (doctor.lastName.isDirty && !doctor.lastName.isValid) ||
                                            (doctorsSectionInvalid && !doctor.lastName.isValid)
                                        }
                                        errorMessage={doctor.lastName.errorMessage}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange(index, 'lastName', event.target.value)
                                        }
                                    />
                                </Cell>
                                <Cell align={'left'} className="survey-doctors__suffix">
                                    <CustomField
                                        customFieldType={CustomFieldType.INPUT}
                                        value={doctor.suffix.value}
                                        required={doctor.suffix.isRequired}
                                        error={doctor.suffix.isDirty && !doctor.suffix.isValid}
                                        errorMessage={doctor.suffix.errorMessage}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleChange(index, 'suffix', event.target.value)
                                        }
                                    />
                                </Cell>
                                <Cell align={'left'} className="survey-doctors__delete">
                                    {doctors.length > 1 && (
                                        <IconButton
                                            disableRipple
                                            size="large"
                                            onClick={() => handleDeleteDoctor(index)}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    )}
                                </Cell>
                            </HeaderRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            {getDoctorsErrorMessage() && <div className="survey-doctors__error">{getDoctorsErrorMessage().error}</div>}
            <div className="survey-doctors__add-wrapper">
                <span onClick={handleAdd} className="survey-doctors__add">
                    + Add another Doctor
                </span>
            </div>

            <ConfirmModal
                title="Are you sure you want to delete this item?"
                subtitle="This action cannot be undone."
                discardText="CANCEL"
                confirmText="YES, DELETE ITEM"
                open={deleteDoctorIndex !== null}
                onClose={() => setDeleteDoctorIndex(null)}
                onDiscard={() => setDeleteDoctorIndex(null)}
                onConfirm={deleteDoctorById}
            />
        </div>
    )
}

export default DoctorsEdit
