import ApiV2 from '../../../../ApiV2'
import { ReceiveSurveyActivityLogs } from '../../../../models/v2/PracticeSurvey'

export const SURVEY_RECEIVE_ACTIVITY_LOGS = '@V2/SURVEY_RECEIVE_ACTIVITY_LOGS'

export type ReceiveLocationSurveyActivityLogs = {
    type: '@V2/SURVEY_RECEIVE_ACTIVITY_LOGS'
    receiveSurveyActivityLogs: ReceiveSurveyActivityLogs
}

export function receiveSurveyActivityLogs(
    receiveSurveyActivityLogs: ReceiveSurveyActivityLogs,
): ReceiveLocationSurveyActivityLogs {
    return {
        type: SURVEY_RECEIVE_ACTIVITY_LOGS,
        receiveSurveyActivityLogs,
    }
}

export function fetchSurveyActivityLogs(surveyId: string, searchTerms: ApiV2.Survey.ActivityLogsSearchTerms): any {
    return async (dispatch: any) => {
        try {
            const { page = 1 } = searchTerms
            const { data: activityLogs, paginationInfo } = await ApiV2.Survey.getSurveyActivityLogs(
                surveyId,
                searchTerms,
            )
            return await dispatch(receiveSurveyActivityLogs({ surveyId, activityLogs, paginationInfo, page }))
        } catch (e) {}
    }
}
