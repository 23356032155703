import * as React from 'react'

export default function UpwardLightning() {
    // tslint:disable
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="45" viewBox="0 0 19 45">
            <path
                fill="#B8E986"
                fillRule="evenodd"
                d="M19 38.47l-1.612-23.024-7.717 3.731L9.054 0 0 32.293l8.51-4.331L5.494 45z"
            />
        </svg>
    )
}
