import React, { useMemo, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import Button from '@mui/material/Button'
import Switch from '@mui/material/Switch'
import classNames from 'classnames'
import _ from 'lodash'

import AdminAccount from '../../models/AdminAccount'
import { ProductTypes, Verify } from '../../models/enums'
import CustomMultiselectField from '../shared/custom-fields/CustomMultiselectField'

import './ManageProducts.sass'

export type ManageLocationProductsProps = {
    account?: AdminAccount
    practice: Models.Practice
    location?: Models.PracticeLocation
    isSavingPracticeProducts?: boolean
    onSave: (products: Array<Api.SaveProduct>) => void
    onClose: () => void
}

const ManageLocationProducts: React.FC<ManageLocationProductsProps> = ({
    account,
    practice,
    location,
    isSavingPracticeProducts,
    onSave,
    onClose,
}) => {
    const salesforceType = useMemo(() => (practice.isVyneCreated ? 'vyne' : practice.salesforceType || 'simplifeye'), [
        practice.isVyneCreated,
        practice.salesforceType,
    ])
    const [selected, setSelected] = useState<string>()
    const [products, setProducts] = useState<Array<Models.PracticeLocationProduct>>(
        location?.products ? location.products?.map(p => ({ ...p })) : [],
    )

    const [pendingProducts, setPendingProducts] = useState<Array<Models.PracticeLocationProduct>>([])

    const [isDirty, setIsDirty] = useState<boolean>(false)

    const onAddProduct = () => {
        const product = ProductTypes.find(p => p.value === selected)
        if (product) {
            setPendingProducts(prevPendingProducts => [
                ...prevPendingProducts,
                {
                    product_id: product.id,
                    practice_location_id: location?.id || '',
                    active: true,
                    product: {
                        product_id: product.id,
                        display_name: product.displayName,
                        value: product.value,
                        id: product.id,
                        default_paid_modules: [],
                    },
                },
            ])
            setSelected(undefined)
            setIsDirty(true)
        }
    }

    const onSelectProduct = (value: string) => {
        setSelected(value)
    }

    const onSaveProducts = async () => {
        if (pendingProducts.length === 0) {
            onClose()
            return
        }

        await onSave(
            productsList.map(p => ({
                product_id: p.product.id,
                active: p.active,
            })),
        )
    }

    const onToggleActive = (product: Models.PracticeLocationProduct, active: boolean) => {
        pendingProducts.unshift({
            ...product,
            active,
        })

        setProducts(prevProducts => prevProducts.filter(p => p.product.value !== product.product.value))
        setPendingProducts(_.uniqBy(pendingProducts, 'product.value'))
        setIsDirty(true)
    }

    const pendingProductsRenamed = pendingProducts.map(product => ({
        ...product,
        product: {
            ...product.product,
            display_name:
                ProductTypes.find(p => p.value === product.product.value)?.displayName || product.product.display_name,
        },
    }))

    const productsRenamed = products.map(product => ({
        ...product,
        product: {
            ...product.product,
            display_name:
                ProductTypes.find(p => p.value === product.product.value)?.displayName || product.product.display_name,
        },
    }))

    const productsList = _.sortBy(productsRenamed.concat(pendingProductsRenamed), 'product.display_name')

    const options = ProductTypes.filter(
        type => productsList.find(product => product.product.value === type.value) == null,
    ).filter(type => account?.isSuperAdmin || type.id !== Verify.id)

    return (
        <div className="manage-products">
            <div className="form">
                <div className="title">Product Manager</div>
                <div className="selector">
                    {options.length > 0 && (
                        <CustomMultiselectField
                            items={options}
                            maxSelected={1}
                            selectedItems={[selected || '']}
                            keyProperty="value"
                            displayProperty="displayName"
                            placeholder="Select Product"
                            label="Select Product"
                            disabled={practice.isVyneCreated}
                            onSelectElement={(values: string[]) => onSelectProduct(values[0])}
                        />
                    )}

                    {options.length > 0 && (
                        <Button
                            className={classNames('add-product', {
                                'can-add': selected != null && salesforceType === 'simplifeye',
                            })}
                            color="primary"
                            variant="contained"
                            onClick={onAddProduct}
                            disabled={selected == null || isSavingPracticeProducts || practice.isVyneCreated}
                        >
                            Add
                        </Button>
                    )}
                    {options.length === 0 && <div>Practice location has all products.</div>}
                </div>
                <div className="products">
                    {productsList.map(product => (
                        <div key={product.product.value} className="product">
                            <Switch
                                className="active"
                                checked={product.active}
                                color="primary"
                                onChange={(_e, checked) => onToggleActive(product, checked)}
                                disabled={isSavingPracticeProducts || practice.isVyneCreated}
                            />
                            <div className="name">{product.product.display_name}</div>
                        </div>
                    ))}
                </div>
            </div>
            <button
                className={classNames('bottom-save-button', {
                    'can-save': isDirty,
                })}
                disabled={!isDirty}
                onClick={onSaveProducts}
            >
                Save Changes
            </button>
            {Boolean(isSavingPracticeProducts) && (
                <div className="circular-progress-loader">
                    <CircularProgress className="circular-progress-spinner" size={70} variant="indeterminate" />
                </div>
            )}
        </div>
    )
}

export default ManageLocationProducts
