import {
    AvailableHours,
    ConnectBooking,
    ConnectPaymentStatus,
    ConnectSurvey,
    ConnectWebCode,
    PatientType,
    UpdateConnectSurvey,
} from '../../models/Connect'

export function mapConnectSurvey(survey: Api.Connect.ConnectSurvey): ConnectSurvey {
    return {
        id: survey.id,
        status: survey.status,
        doxy_urls: survey.doxy_urls,
        patientType: survey.patient_type,
        insuranceInformationStatus: survey.insurance_information_status,
        emails: survey.emails,
        phoneNumber: survey.phone_number,
        timeZone: survey.timezone,
        availableHours: mapAvailableHours(survey.available_hours),
    }
}

export function mapAvailableHours(available_hours: Array<Api.Connect.AvailableHours>): AvailableHours[] {
    if (!Array.isArray(available_hours)) {
        return []
    }
    return available_hours.map((available_hour: Api.Connect.AvailableHours, index: number) => {
        return {
            available: available_hour.available,
            startTime: available_hour.start_time,
            endTime: available_hour.end_time,
            dayOfWeek: available_hour.day_of_week,
        }
    })
}

export function mapReverseAllAvailableHours(availableHours: AvailableHours[]): Array<Api.Connect.AvailableHours> {
    if (!Array.isArray(availableHours)) {
        return []
    }
    return availableHours.map((availableHour: AvailableHours) => ({
        start_time: availableHour.startTime,
        end_time: availableHour.endTime,
        day_of_week: availableHour.dayOfWeek,
        available: availableHour.available,
    }))
}

export function mapReverseAvailableHours(availableHours: AvailableHours): Api.Connect.AvailableHours {
    return {
        start_time: availableHours.startTime,
        end_time: availableHours.endTime,
        day_of_week: availableHours.dayOfWeek,
        available: availableHours.available,
    }
}

export function mapReverseUpdateConnectSurvey(
    updateConnectSurvey: UpdateConnectSurvey,
): Api.Connect.UpdateConnectSurvey {
    const update: Api.Connect.UpdateConnectSurvey = {
        status_id: updateConnectSurvey.statusId,
        doxy_urls: updateConnectSurvey.doxy_urls,
        patient_type_id: updateConnectSurvey.patientTypeId,
        insurance_information_status_id: updateConnectSurvey.insuranceInformationStatusId,
        emails: updateConnectSurvey.emails,
        phone_number: updateConnectSurvey.phoneNumber,
        timezone: updateConnectSurvey.timeZone,
        available_hours: updateConnectSurvey.availableHours
            ? mapReverseAvailableHours(updateConnectSurvey.availableHours)
            : undefined,
    }
    Object.keys(update).forEach(
        key => (update[key] == null || typeof update[key] === 'undefined') && delete update[key],
    )
    return update
}

export function mapConnectSurveys(surveys: Api.Connect.ConnectSurvey[]): ConnectSurvey[] {
    if (!Array.isArray(surveys)) {
        return []
    }
    return surveys.map((survey: Api.Connect.ConnectSurvey) => mapConnectSurvey(survey))
}

export function mapConnectWebCodes(connectWebCodes: Api.Connect.ConnectWebCode[]): ConnectWebCode[] {
    if (!Array.isArray(connectWebCodes)) {
        return []
    }
    return connectWebCodes.map((connectWebCode: Api.Connect.ConnectWebCode) => {
        return {
            id: connectWebCode.id,
            url: connectWebCode.url,
            connectSurvey: connectWebCode.connect_survey ? mapConnectSurvey(connectWebCode.connect_survey) : undefined,
        }
    })
}

export function mapConnectWebCodesV2(amplifyWebCodes: ApiV2.Amplify.WebCode[]): ConnectWebCode[] {
    if (!Array.isArray(amplifyWebCodes)) {
        return []
    }
    return amplifyWebCodes.map((amplifyWebCode: ApiV2.Amplify.WebCode) => {
        return {
            id: amplifyWebCode.id,
            url: amplifyWebCode.url,
            connectSurveyId: amplifyWebCode.connect_survey_id,
        }
    })
}

export function mapReverseBookingDetails(
    practice: Models.Practice,
    webCode: Models.WebCode,
    connectBooking: ConnectBooking,
): Api.Connect.ConnectBooking {
    const { formElements } = connectBooking
    return {
        practice_id: practice.id,
        web_code_id: webCode.key,
        first_name: formElements.firstName.value,
        last_name: formElements.lastName.value,
        practice_location_id: formElements.practiceLocation.value,
        patient_data: {
            date_of_birth: formElements.dateOfBirth.value,
            phone_number: formElements.mobilePhone.value,
            existing_patient: connectBooking.patientType === PatientType.EXISTING,
            email: formElements.email.value,
            insurance: formElements.insurance.value,
            insurance_phone: formElements.insurancePhone.value,
            insurance_group: formElements.insuranceGroup.value,
            insurance_member_id_ssn: formElements.insuranceMemberId.value,
            patient_is_policy_holder: connectBooking.isPolicyHolder,
            ...(!connectBooking.isPolicyHolder
                ? {
                      policy_holder_first_name: formElements.policyHolderFirstName.value,
                      policy_holder_last_name: formElements.policyHolderLastName.value,
                      policy_holder_date_of_birth: formElements.policyHolderDateOfBirth.value,
                  }
                : {}),
        },
        /*
            If connectBooking.paymentPayerId is present then connectBooking.paymentPatientId
            is present too since both values are coming together from Payment API.
        */
        ...(connectBooking.paymentPayerId
            ? {
                  payment_data: {
                      payer_id: connectBooking.paymentPayerId,
                      patient_id: connectBooking.paymentPatientId,
                      card_is_verified: connectBooking.paymentStatus === ConnectPaymentStatus.Verified,
                  },
              }
            : undefined),
    }
}

export function mapLocationConnectPaymentInfo(
    locationConnectPaymentInfo: Api.LocationConnectPaymentInfo,
): Models.LocationConnectPaymentInfo {
    return {
        paymentInformationStatusId: locationConnectPaymentInfo.payment_information_status?.id,
        amount: locationConnectPaymentInfo.amount,
    }
}
