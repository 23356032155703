const GOOGLE_ANALYTICS_ID_PARAM = 'google_analytics_id='
const GOOGLE_TAG_MANAGER_ID_PARAM = 'google_tag_manager_id='

export const filterOutGoogleQueryParams = (text: string) => {
    let textToReturn = text || ''

    if (textToReturn.includes(`?${GOOGLE_ANALYTICS_ID_PARAM}`)) {
        textToReturn.replace(`?${GOOGLE_ANALYTICS_ID_PARAM}`, `&${GOOGLE_ANALYTICS_ID_PARAM}`)
    }

    if (textToReturn.includes(`?${GOOGLE_TAG_MANAGER_ID_PARAM}`)) {
        textToReturn.replace(`?${GOOGLE_TAG_MANAGER_ID_PARAM}`, `&${GOOGLE_TAG_MANAGER_ID_PARAM}`)
    }

    if (
        textToReturn.includes(`&${GOOGLE_ANALYTICS_ID_PARAM}`) ||
        textToReturn.includes(`&${GOOGLE_TAG_MANAGER_ID_PARAM}`)
    ) {
        const textToReturnArray = textToReturn
            .split('&')
            .map(param => {
                if (
                    param.includes(`${GOOGLE_ANALYTICS_ID_PARAM}`) ||
                    param.includes(`${GOOGLE_TAG_MANAGER_ID_PARAM}`)
                ) {
                    return ''
                } else {
                    return param
                }
            })
            .filter(p => p !== '')

        if (textToReturnArray.length > 1) {
            const firstPart = `${textToReturnArray[0]}?${textToReturnArray[1]}`
            const secondPart = textToReturnArray.slice(2).join('&')
            textToReturn = [firstPart, secondPart].join('&')
        } else {
            textToReturn = textToReturnArray[0]
        }
    }
    return textToReturn
}
