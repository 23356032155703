import { getTimezonesForCountry } from 'countries-and-timezones'
import _ from 'lodash'
import moment from 'moment-timezone'

import { defaultUSTimezoneList } from './constants'

export const getTimeZoneData = (timeZoneName: string) => {
    const currentTimestamp = moment().unix()
    return {
        name: timeZoneName,
        abbr: moment.tz.zone(timeZoneName)?.abbr(currentTimestamp),
        offset: moment.tz.zone(timeZoneName)?.utcOffset(currentTimestamp),
    }
}

export const getTimeZonesWithAbbreviations = (timeZonesNames: string[]) => {
    const formattedTimezones = timeZonesNames.map(timeZoneName => {
        const utcOffset = moment.tz(timeZoneName).utcOffset()
        const offset = utcOffset ? utcOffset / 60 : 0
        const absOffset = Math.abs(offset)
        const formattedOffset = Math.sign(offset) !== -1 ? `UTC +${absOffset}` : `UTC -${absOffset}`
        const abbreviation = moment.tz(timeZoneName).format('z')

        return { timeZoneName, abbreviation, formattedOffset, offset }
    })

    const sortedTimeZones = _.sortBy(formattedTimezones, ['offset', 'timeZoneName'])

    return sortedTimeZones.map(timezone => {
        const timezoneAbbreviation = !/\d/.test(timezone.abbreviation) ? timezone.abbreviation : undefined
        return {
            timezoneIanaTZ: timezone.timeZoneName,
            timezoneString: [
                timezone.timeZoneName.replaceAll('_', ' '),
                timezoneAbbreviation,
                timezone.formattedOffset,
            ].join(' '),
        }
    })
}

export const getAllTimeZones = () => {
    return moment.tz.names()
}

export const getTimeZonesForCountry = (countryCodeIsoAlpha2: string) => {
    if (!countryCodeIsoAlpha2 || countryCodeIsoAlpha2 === 'US') {
        return defaultUSTimezoneList
    }
    const momentTimezones = moment.tz.zonesForCountry(countryCodeIsoAlpha2)
    const countryTimezones = getTimezonesForCountry(countryCodeIsoAlpha2)?.map((tz: any) => tz.name)
    return momentTimezones.filter(timezone => countryTimezones?.includes(timezone))
}
