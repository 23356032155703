import React, { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import './SearchLocationInput.sass'

export type AddressObject = {
    street?: string
    city?: string
    state?: string
    zip?: string
    unit?: string
    country?: string
    lat?: number
    lng?: number
}

export type Props = {
    value: string
    label: string
    required?: boolean
    helperText?: string
    error?: boolean | string
    errorMessage?: string
    placeholder?: string
    onChange: (inputValue: string | undefined) => void
    onBlur?: () => void
    onPlaceSelect: (addressObject: AddressObject) => void
}
export interface GeocodingResult {
    geometry: {
        location: {
            lat: () => number
            lng: () => number
        }
        location_type: 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE'
    }
}
const addressComponentsMap = {
    street_number: 'short_name',
    route: 'long_name',
    room: 'long_name',
    floor: 'long_name',
    locality: 'long_name',
    postal_town: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'short_name',
    postal_code: 'short_name',
    postal_code_prefix: 'short_name',
}

const cityAddressTypesByPreferenceByCountry = {
    ca: ['postal_town', 'sublocality_level_1', 'locality', 'administrative_area_level_3'],
    world: ['postal_town', 'locality', 'sublocality_level_1', 'administrative_area_level_3'],
}
const zipAddressTypesByPreference = ['postal_code', 'postal_code_prefix']

const NYC_LAT = 40.76357762579798
const NYC_LNG = -73.56110432470035

const centerPosition = { lat: NYC_LAT, lng: NYC_LNG }

const defaultBounds = {
    north: centerPosition.lat + 0.1,
    south: centerPosition.lat - 0.1,
    east: centerPosition.lng + 0.1,
    west: centerPosition.lng - 0.1,
}

const moduleName = 'search-location-input'

const SearchLocationInput = ({
    value,
    label,
    helperText,
    error,
    errorMessage,
    placeholder,
    onChange,
    onPlaceSelect,
    onBlur,
}: Props) => {
    const [isScriptLoaded, setScriptLoaded] = useState<boolean>(false)
    const autoCompleteInputRef = useRef(null)
    const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

    const handlePlaceSelect = useCallback(async () => {
        if (autoCompleteRef.current) {
            const place = autoCompleteRef.current.getPlace()
            const addressComponents = place?.address_components || []
            const addressObject: AddressObject = {
                street: '',
                zip: getAddressValueByPreference(addressComponents, zipAddressTypesByPreference),
            }

            for (const component of addressComponents) {
                const addressType = component.types[0]
                const addressComponentName = addressComponentsMap[addressType]
                const geocoderAddressComponent = component[addressComponentName]

                switch (addressType) {
                    case 'administrative_area_level_1':
                        addressObject.state = geocoderAddressComponent
                        break
                    case 'street_number':
                    case 'route':
                        addressObject.street = setStreet(geocoderAddressComponent, addressObject.street)
                        break
                    case 'floor':
                        addressObject.unit = setUnit(geocoderAddressComponent, addressObject.unit, 'floor')
                        break
                    case 'room':
                        addressObject.unit = setUnit(geocoderAddressComponent, addressObject.unit, 'room')
                        break
                    case 'country':
                        addressObject.country = geocoderAddressComponent ?? ''
                        break
                    default:
                        break
                }
            }

            addressObject.city = getAddressValueByPreference(
                addressComponents,
                getCityAddressType(addressObject.country),
            )

            const service = new window.google.maps.Geocoder()
            service.geocode(
                {
                    address: `${addressObject.street ?? ''}, ${addressObject.city ?? ''}, ${addressObject.state ?? ''},
                        ${addressObject.country ?? ''} ${addressObject.zip ?? ''}`,
                },
                (results: GeocodingResult[], status: string) => {
                    if (status === 'OK' && results[0]) {
                        addressObject.lat = results[0].geometry.location?.lat()
                        addressObject.lng = results[0].geometry.location?.lng()
                    }
                    onPlaceSelect(addressObject)
                },
            )
        }
    }, [onPlaceSelect])

    const getCityAddressType = (countryCode: string | undefined) => {
        const country = countryCode?.toLowerCase()
        return country && cityAddressTypesByPreferenceByCountry[country]
            ? cityAddressTypesByPreferenceByCountry[country]
            : cityAddressTypesByPreferenceByCountry.world
    }

    const setStreet = (streetComponent: string, street: string | undefined) => {
        return street !== '' ? `${street} ${streetComponent}` : streetComponent
    }

    const setUnit = (unitComponent: string, unit: string | undefined, unitPart: 'floor' | 'room') => {
        if (unitPart === 'floor') {
            return unit !== '' ? `${unitComponent} ${unit}` : unitComponent
        }
        return unit !== '' ? `${unit} ${unitComponent}` : unitComponent
    }

    const getAddressValueByPreference = (
        geocoderAddressComponents: google.maps.GeocoderAddressComponent[],
        preferenceOrder: string[],
    ) => {
        const addressComponentValues = preferenceOrder
            .map(type => {
                const found = geocoderAddressComponents.find(component => component.types[0] === type)
                const addressComponentsName = addressComponentsMap[type]
                return found && found[addressComponentsName]
            })
            .filter(Boolean)

        return addressComponentValues[0] || ''
    }

    const loadScript = (url: string, callback: any) => {
        if (!window.google) {
            const script = document.createElement('script')
            script.type = 'text/javascript'
            script.onload = () => callback()
            script.src = url
            document.getElementsByTagName('head')[0].appendChild(script)
            return
        }
        callback()
    }

    const handleClick = (inputRef: React.MutableRefObject<any>) => {
        if (inputRef.current && inputRef.current.hasAttribute('disabled')) {
            inputRef.current.removeAttribute('disabled')
            inputRef.current.setAttribute('placeholder', 'Enter a location')
        }
    }

    useEffect(() => {
        const handleScriptLoad = (inputFieldRef: React.RefObject<any>) => {
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputFieldRef.current, {
                types: ['address'],
            })
            autoCompleteRef.current.setFields(['address_component'])
            autoCompleteRef.current.setBounds(defaultBounds)
            autoCompleteRef.current.addListener('place_changed', () => handlePlaceSelect())
            setScriptLoaded(true)
        }

        if (!isScriptLoaded) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
                () => handleScriptLoad(autoCompleteInputRef),
            )
        }
    }, [handlePlaceSelect, autoCompleteInputRef, isScriptLoaded])

    return (
        <div
            className={cx(`${moduleName}`, {
                [`${moduleName}--error`]: Boolean(error),
            })}
        >
            <label className={`${moduleName}__label`}>{label}</label>
            <input
                ref={autoCompleteInputRef as React.RefObject<any>}
                className={`${moduleName}__field`}
                type="text"
                onChange={event => onChange(event.target.value)}
                onClick={() => handleClick(autoCompleteInputRef)}
                placeholder={placeholder}
                value={value}
                onBlur={onBlur}
            />
            {error ? (
                <span className={`${moduleName}__helper-text`}>{errorMessage}</span>
            ) : (
                helperText && <span className={`${moduleName}__helper-text`}>{helperText}</span>
            )}
        </div>
    )
}

export default SearchLocationInput
