import iassign from 'immutable-assign'
import _ from 'lodash'

import { ReceiveAgents, ReceiveCommands, ReceiveErrors, ReceiveLicenses } from './actions'

export type DexVoiceState = {
    agents: { [key: string]: Array<Models.DexisAgent> }
    licenses: { [key: string]: Array<Models.DexisLicense> }
}

const initialState = (): DexVoiceState => ({
    agents: {},
    licenses: {},
})

type DexVoiceAction = ReceiveAgents | ReceiveCommands | ReceiveErrors | ReceiveLicenses

function isFailing(agent: Models.DexisAgent) {
    if (!agent.commands || !agent.errors) {
        return false
    }

    if (agent.commands.length === 0) {
        return false
    }

    return agent.errors.length / agent.commands.length >= 0.1
}

export function reducer(state: DexVoiceState = initialState(), action: DexVoiceAction): DexVoiceState {
    switch (action.type) {
        case 'RECEIVE_PRACTICE_DEXVOICE_AGENTS': {
            return iassign(state, next => {
                next.agents[action.practice.id] = _.sortBy(
                    action.agents.map(a => ({
                        id: a.id,
                        roomName: a.room_name,
                        deviceId: a.device_id,
                        linkWord: a.link_word,
                        failing: false,
                    })),
                    a => a.roomName.toUpperCase(),
                )
                return next
            })
        }
        case 'RECEIVE_PRACTICE_DEXVOICE_AGENTS_COMMANDS': {
            return iassign(
                state,
                next => next.agents[action.practice.id],
                agents => {
                    for (const agent of agents) {
                        const commands = action.commands.filter(c => c.agent && c.agent.id === agent.id)
                        agent.commands = commands.map(
                            (command): Models.DexisAgentCommand => ({
                                id: command.id,
                                device_id: command.device_id,
                                created: new Date(command.created),
                                details: command.details && {
                                    exitCode: command.details.exit_code,
                                },
                                success: command.success,
                                parameters: command.parameters && {
                                    action: command.parameters.action,
                                    bitewing: command.parameters.action,
                                    imageType: command.parameters.image_type,
                                    tooth: command.parameters.tooth,
                                },
                                intent: command.intent,
                            }),
                        )
                        agent.failing = isFailing(agent)
                    }
                    return agents
                },
            )
        }
        case 'RECEIVE_PRACTICE_DEXVOICE_AGENTS_ERRORS': {
            return iassign(
                state,
                next => next.agents[action.practice.id],
                agents => {
                    for (const agent of agents) {
                        agent.errors = action.errors
                            .filter(c => c.agent_id === agent.id)
                            .map(error => ({
                                ...error,
                                created: new Date(error.timestamp),
                            }))
                        agent.failing = isFailing(agent)
                    }
                    return agents
                },
            )
        }
        case 'RECEIVE_PRACTICE_DEXVOICE_LICENSES': {
            return iassign(state, next => {
                next.licenses[action.practice.id] = _.sortBy(
                    action.licenses.map(license => ({
                        ...license,
                        agreementTime: new Date(license.agreement_time),
                    })),
                    'agreementTime',
                )
                return next
            })
        }
        default:
            return state
    }
}
