// @ts-nocheck
import SessionManager from '../SessionManager'

import AdminService from './services/AdminService'
import AmplifyService from './services/AmplifyService'
import AppService from './services/AppService'
import ConnectService from './services/ConnectService'
import DexisService from './services/DexisService'
import DexVoiceDownloadService from './services/DexVoiceDownloadService'
import HeartbeatService from './services/HeartbeatService'
import PatientsService from './services/PatientsService'
import PaymentsService from './services/PaymentsService'
import PendingReferralsService from './services/PendingReferralsService'
import PracticesService from './services/PracticesService'
import RSVPService from './services/RSVPService'
import SelfSchedulingService from './services/SelfSchedulingService'
import SurveyService from './services/SurveyService'
import UploadsService from './services/UploadsService'

const API_HOST = process.env.REACT_APP_API_HOST

class ApiManager {
    Admin: AdminService
    Amplify: AmplifyService
    App: AppService
    Dexis: DexisService
    DexVoiceDownload: DexVoiceDownloadService
    Payments: PaymentsService
    Patients: PatientsService
    PendingReferrals: PendingReferralsService
    Practices: PracticesService
    Survey: SurveyService
    Uploads: UploadsService
    SelfScheduling: SelfSchedulingService
    Connect: ConnectService
    Heartbeat: HeartbeatService
    RSVP: RSVPService

    constructor() {
        this.token = SessionManager.session
    }

    set token(token: string | undefined) {
        if (token) {
            this.Admin = new AdminService(token)
            this.Amplify = new AmplifyService(token)
            this.App = new AppService(token)
            this.Dexis = new DexisService(token)
            this.DexVoiceDownload = new DexVoiceDownloadService(token)
            this.Patients = new PatientsService(token)
            this.Payments = new PaymentsService(token)
            this.PendingReferrals = new PendingReferralsService(token)
            this.Practices = new PracticesService(token)
            this.Survey = new SurveyService(token)
            this.Uploads = new UploadsService(token)
            this.SelfScheduling = new SelfSchedulingService(token)
            this.Connect = new ConnectService(token)
            this.Heartbeat = new HeartbeatService(token)
            this.RSVP = new RSVPService(token)
        } else {
            delete this.Admin
            delete this.Amplify
            delete this.App
            delete this.Dexis
            delete this.DexVoiceDownload
            delete this.Patients
            delete this.Payments
            delete this.PendingReferrals
            delete this.Practices
            delete this.Survey
            delete this.Uploads
            delete this.SelfScheduling
            delete this.Connect
            delete this.Heartbeat
            delete this.RSVP
        }
    }

    get headers() {
        return new Headers({
            'content-type': 'application/json',
        })
    }

    async login(username: string, password: string): Promise<Api.Credentials> {
        const response = await fetch(`${API_HOST}/api/1/login/admin`, {
            method: 'post',
            mode: 'cors',
            headers: this.headers,
            body: JSON.stringify({ username, password }),
        })

        if (!response.ok) {
            const err = await response.json()

            if (err.statusCode === 401 || err.code === 401 || err.status === 401) {
                SessionManager.update(undefined)
                window.sessionStorage.setItem('PSTUnauthorizedError', err.message)
            }

            if (err.errors) {
                throw new Error(err.errors.map((e: any) => e.message).join('; '))
            } else {
                throw err
            }
        }

        return response.json()
    }

    async postCompletePasswordReset(code: string, password: string): Promise<object> {
        const response = await fetch(`${API_HOST}/api/1/login/reset_password/${encodeURIComponent(code)}`, {
            method: 'put',
            mode: 'cors',
            headers: this.headers,
            body: JSON.stringify({
                password,
            }),
        })
        if (!response.ok) {
            const err = await response.json()
            throw err
        }

        return response.json()
    }

    async checkForExpiredResetCode(code: string) {
        const response = await fetch(`${API_HOST}/api/1/login/verify-reset-code/${encodeURIComponent(code)}`)

        if (!response.ok) {
            const err = await response.json()

            if (err.errors) {
                throw new Error(err.errors.map((e: any) => e.message).join('; '))
            } else {
                throw err
            }
        }

        return response.json()
    }
}

const Api = new ApiManager()

export default Api
