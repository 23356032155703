import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import moment from 'moment'

import { PatientSearchCriteria, SearchPatientForm, SearchPatientFormElements } from '../../../models/BookingAppointment'
import { CustomFieldType } from '../../../models/enums'
import CustomField from '../../shared/custom-fields/CustomField'
import CustomInlineDatepicker from '../../shared/custom-fields/CustomInlineDatepicker'
import WebcodeLocationMultiselectContainer from '../../shared/custom-fields/webcode-location-multiselect/WebcodeLocationMultiselectContainer'
import { FormFieldElement } from '../../shared/form-validator/validator'

import './SearchExistingPatient.sass'

const DATE_FORMAT = 'MM/DD/YYYY'
const MIN_DATE = '01/01/1900'
// const DATE_MASK = [/[0-1]{1}/, /\d/, '/', /[0-3]{1}/, /\d/, '/', /[1-2]{1}/, /[0,9]{1}/, /\d/, /\d/]
const DATE_MASK = '__/__/____'

export type SearchExistingPatientProps = {
    form: SearchPatientForm
    showNoResultsMessage: boolean
    practice: Models.Practice
    webCode: Models.WebCode
    isLocationDisabled: (id: string) => boolean
    locationSchedulingStatusReason: (id: string) => string
    isAdditionalPatientBooking?: boolean
}

export type SearchExistingPatientDispatch = {
    updateFormFields: (changes: SearchPatientFormElements) => void
    onSubmitSearch: (patientSearchCriteria: PatientSearchCriteria) => void
}

type Props = SearchExistingPatientProps & SearchExistingPatientDispatch

const moduleName = 'scheduling-search-existing-patient'

const SearchExistingPatient = (props: Props) => {
    const [dateOfBirthValid, setDateOfBirthValid] = useState<Boolean>(false)

    useEffect(() => {
        const { value } = props.form.formElements.dateOfBirth

        if (value) {
            const cleanedDate = cleanDate(value)
            if (cleanedDate) {
                checkBirthDateIsValid(cleanedDate)
            }
        }
    }, [props.form.formElements.dateOfBirth])

    const onUpdate = (modifier: (changes: SearchPatientFormElements) => SearchPatientFormElements) => {
        props.updateFormFields(modifier(props.form.formElements))
    }

    const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const cleanedDate = cleanDate(event.target.value)

        if (cleanedDate && checkBirthDateIsValid(cleanedDate)) {
            onUpdate(elements => {
                elements.dateOfBirth.value = cleanedDate.format(DATE_FORMAT)
                elements.dateOfBirth.isDirty = true
                return elements
            })
        }
    }

    const checkBirthDateIsValid = (date: moment.Moment): boolean => {
        const today = moment()
        const dateOfBirthNotAfterMaxDate = !date.isAfter(today)
        const dateOfBirthNotBeforeMinDate = !date.isBefore(MIN_DATE)
        const dateOfBirthValidVal = dateOfBirthNotAfterMaxDate && dateOfBirthNotBeforeMinDate

        setDateOfBirthValid(dateOfBirthValidVal)
        return dateOfBirthValidVal
    }

    const cleanDate = (date: string): moment.Moment | false => {
        const cleanedDate = ('' + date).replace(/\D/g, '')

        if (cleanedDate.length === 8) {
            return moment(cleanedDate, 'MMDDYYYY')
        }

        setDateOfBirthValid(false)
        return false
    }

    const searchPatients = () => {
        const { firstName, lastName, dateOfBirth, location } = props.form.formElements
        props.onSubmitSearch({
            firstName: firstName.value.trim(),
            lastName: lastName.value.trim(),
            dateOfBirth: dateOfBirth.value,
            locationId: location.value,
        })
    }

    const hasError = (field: FormFieldElement) => field.isDirty && !field.isValid

    const {
        form: { formElements, isFormValid, isFormDirty },
        practice,
        webCode,
        isLocationDisabled,
        locationSchedulingStatusReason,
    } = props

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__field-columns`}>
                <div className={`${moduleName}__field`}>
                    <CustomField
                        required={true}
                        value={formElements.firstName.value}
                        customFieldType={CustomFieldType.INPUT}
                        label="First Name*"
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            onUpdate(elements => {
                                elements.firstName.value = event.target.value
                                elements.firstName.isDirty = true
                                return elements
                            })
                        }
                    />
                </div>

                <div className={`${moduleName}__field`}>
                    <CustomField
                        required={true}
                        value={formElements.lastName.value}
                        customFieldType={CustomFieldType.INPUT}
                        label="Last Name*"
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                            onUpdate(elements => {
                                elements.lastName.value = event.target.value
                                elements.lastName.isDirty = true
                                return elements
                            })
                        }
                    />
                </div>
            </div>

            <div className={`${moduleName}__field ${moduleName}__field--datepicker`}>
                <CustomInlineDatepicker
                    value={
                        formElements.dateOfBirth.value
                            ? moment(formElements.dateOfBirth.value, DATE_FORMAT).toDate()
                            : null
                    }
                    label="Date of Birth*"
                    mask={DATE_MASK}
                    invalidLabel={DATE_FORMAT}
                    placeholder={DATE_FORMAT}
                    dateFormat={DATE_FORMAT}
                    disableFuture={true}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => onInput(event)}
                    onChange={(date: moment.Moment, value: string) => {
                        if (date && date?.format(DATE_FORMAT) !== 'Invalid date') {
                            checkBirthDateIsValid(date)
                            onUpdate(elements => {
                                elements.dateOfBirth.value = date.format(DATE_FORMAT)
                                elements.dateOfBirth.isDirty = true
                                return elements
                            })
                        }
                    }}
                />
            </div>

            <div className={`${moduleName}__field`}>
                <WebcodeLocationMultiselectContainer
                    disabled={props.isAdditionalPatientBooking}
                    label="Practice Location*"
                    practice={practice}
                    placeholder="Choose a Location"
                    webCode={webCode}
                    onlyEnabledWebCodeLocations={true}
                    selectedItems={[formElements.location.value]}
                    error={hasError(formElements.location)}
                    errorMessage={formElements.location?.firstErrorMessage || 'This field is required'}
                    isOptionDisabled={isLocationDisabled}
                    locationSchedulingStatusReason={locationSchedulingStatusReason}
                    onSelectElement={(values: string[]) => {
                        onUpdate(elements => {
                            elements.location.value = values[0]
                            elements.location.isDirty = true
                            return elements
                        })
                    }}
                />
            </div>

            {props.showNoResultsMessage && !isFormDirty && (
                <div className={`${moduleName}__error`}>
                    <Icon>warning</Icon>
                    <div className={`${moduleName}__message`}>
                        {`Your search didn't return any results.`}
                        {` `}
                        Please check the name and birthdate and try again.
                    </div>
                </div>
            )}

            <div className={`${moduleName}__submit`}>
                <Button
                    disabled={!isFormValid || !isFormDirty || !dateOfBirthValid}
                    className={`${moduleName}__submit-button`}
                    onClick={searchPatients}
                >
                    Search for Patient
                </Button>
            </div>
        </div>
    )
}

export default SearchExistingPatient
