import React, { Fragment, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchWebCodes } from '../../amplify/actions'
import { fetchPracticeWithLocations } from '../../practices/actions'
import { useInterval, usePrevious } from '../../shared/custom-hooks'
import { mainBlue } from '../../shared/styles/colors'
import { closeChat, fetchAllNewerChatMsgs, saveChat, updateSelectedChat } from '../actions'
import ChatCenterAlertManager from '../chat-center-alert/ChatCenterAlert'

import ChatConversation from './ChatConversation'
import ChatSurveyAndDirectScheduling, { checkConnectAvailability } from './ChatSurveyAndDirectScheduling'
import ChatTextField from './ChatTextField'
import ChatTileHeader from './ChatTileHeader'

import './ChatTile.sass'

const moduleName = 'chat-tile'
const CONNECT_AVAILABILITY_TIMEOUT = 20000

export type Props = {
    isVisible?: boolean
    isPatientOnline: boolean
    selectedChat: Models.ChatCenterSelectedChat
    webCode: Models.WebCode
    focused?: boolean
    onLeaveChat: (chatId: string) => void
}

const BAN_DAYS = 7

const ChatTile = ({ selectedChat, isPatientOnline, isVisible, webCode, focused, onLeaveChat }: Props) => {
    const [isConnectAvailable, setConnectAvailable] = useState<boolean>(false)
    const [pendingMessages, setPendingMessages] = useState<Models.Message[]>([])
    const [refreshConversationInProgress, setRefreshConversationInProgress] = useState<boolean>(false)

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account) as AdminAccount
    const bookings = useSelector((state: RootState) => state.bookings.directScheduling)
    const practice = useSelector((state: RootState) => state.practices.practices[selectedChat.practice.id])
    const shortcuts = useSelector((state: RootState) => state.chat.shortcuts)
    const connectSurvey = useSelector(
        (state: RootState) =>
            state?.connectSurveys?.connectSurveys?.[selectedChat.practice.id]?.find(
                connectWebCode => connectWebCode.id === webCode?.id,
            )?.connectSurvey,
    )
    const conversation = useSelector((state: RootState) => state.chat.conversations[selectedChat.channelName])
    const whispers = useSelector((state: RootState) => state.chat.conversations[`whisper_${selectedChat.channelName}`])

    const connectSurveyId = connectSurvey?.id
    const connectSurveyAvailableHours = connectSurvey?.availableHours
    const connectSurveyTimeZone = connectSurvey?.timeZone

    const dispatch = useAppDispatch()

    const prevPractice = usePrevious(practice) || null

    useInterval(() => {
        setConnectAvailable(
            checkConnectAvailability(connectSurveyId, connectSurvey?.availableHours, connectSurveyTimeZone),
        )
    }, CONNECT_AVAILABILITY_TIMEOUT)

    useEffect(() => {
        setConnectAvailable(
            checkConnectAvailability(connectSurveyId, connectSurveyAvailableHours, connectSurveyTimeZone),
        )
    }, [connectSurveyId, connectSurveyTimeZone, connectSurveyAvailableHours])

    useEffect(() => {
        if (!selectedChat.initialized && selectedChat.visible) {
            const updatedChat = { id: selectedChat.id, initialized: true }
            dispatch(updateSelectedChat(updatedChat))
        }
    }, [dispatch, selectedChat])

    useEffect(() => {
        if (!practice) {
            fetchPracticeWithLocations(selectedChat.practice.id)
        }
    }, [practice, selectedChat.practice.id])

    useEffect(() => {
        ;(async function fetch() {
            if (practice && prevPractice?.id !== practice.id) {
                await dispatch(fetchWebCodes(practice.id))
            }
        })()
    }, [dispatch, practice, prevPractice])

    const refreshConversation = async () => {
        setRefreshConversationInProgress(true)
        await dispatch(fetchAllNewerChatMsgs(selectedChat.channelName))
        await dispatch(fetchAllNewerChatMsgs(`whisper_${selectedChat.channelName}`))
        setRefreshConversationInProgress(false)
    }

    useInterval(refreshConversation, moment.duration(30, 's').asMilliseconds())

    const onEditName = async (text: string) => {
        await dispatch(saveChat(selectedChat, { patient_name: text }))
        dispatch(updateSelectedChat({ id: selectedChat.id, patientName: text }))
    }

    const onCloseChat = async (close: Models.ChatClose) => {
        await dispatch(closeChat(selectedChat, close))
    }

    const isChatBanned = (chat: Models.ChatMetadata) => {
        if (!chat) {
            return
        }

        return webCode?.customization.banned_ip_addresses
            ? checkIfValidBanExists(webCode.customization.banned_ip_addresses, chat.ip)
            : false
    }

    const checkIfValidBanExists = (bannedIpAddresses: Models.BannedIPAddress[], ip: string) => {
        let banExists = false

        bannedIpAddresses.forEach(ipAddress => {
            if (ipAddress.ip_address === ip && checkIfBanExpired(ipAddress.date)) {
                banExists = true
            }
        })

        return banExists
    }

    const checkIfBanExpired = (date: Date | undefined) => {
        const banDate = moment(date)
        return moment()
            .subtract(BAN_DAYS, 'days')
            .isBefore(banDate)
    }

    const isBanned = selectedChat?.isConversationBanned ?? isChatBanned(selectedChat)

    const setPendingMessage = (message: Models.Message) => {
        setPendingMessages(messages => [...messages, message])
    }

    const removePendingMessage = (message: Models.Message) => {
        setPendingMessages(messages => messages.filter(pendingMessage => pendingMessage.id !== message.id))
    }

    return (
        <div
            className={classNames(moduleName, isBanned && `${moduleName}--banned`, focused && `${moduleName}--focused`)}
        >
            <div className={`${moduleName}__header`}>
                <ChatTileHeader
                    chat={selectedChat}
                    webCode={webCode}
                    isBanned={isBanned}
                    onEditName={onEditName}
                    onCloseChat={onCloseChat}
                    account={account}
                    bookings={bookings}
                    onLeaveChat={onLeaveChat}
                    isVisible={isVisible}
                    isPatientOnline={isPatientOnline}
                />
            </div>

            {isVisible ? (
                <Fragment>
                    <div className={`${moduleName}__alerts`}>
                        <ChatCenterAlertManager
                            account={account}
                            selectedChat={selectedChat}
                            practice={practice}
                            webCodeId={webCode?.id}
                            webCodeConnect={webCode?.connect}
                            isConnectAvailable={isConnectAvailable}
                        />
                    </div>
                    <div className={`${moduleName}__chat-conversation`}>
                        <ChatConversation
                            selectedChat={selectedChat}
                            isBanned={isBanned}
                            pendingMessages={pendingMessages}
                            removePendingMessage={removePendingMessage}
                        />
                    </div>

                    <div className={`${moduleName}__survey-ds-connect`}>
                        <ChatSurveyAndDirectScheduling
                            selectedChat={selectedChat}
                            webCode={webCode}
                            isConnectAvailable={isConnectAvailable}
                        />
                    </div>
                    <div className={`${moduleName}__chat-text-field`}>
                        <ChatTextField
                            isBanned={isBanned}
                            selectedChat={selectedChat}
                            refreshConversation={refreshConversation}
                            refreshConversationInProgress={refreshConversationInProgress}
                            focused={focused}
                            shortcuts={shortcuts}
                            setPendingMessage={setPendingMessage}
                        />
                    </div>
                </Fragment>
            ) : (
                <div className={`${moduleName}__loading`}>
                    <ReactLoading type="spin" color={mainBlue} height={96} width={96} />
                </div>
            )}
        </div>
    )
}

export default ChatTile
