const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export function newObjectId(length: number) {
    if (length < 1) {
        throw new Error('Must have length greater than 0')
    }

    let objectId = ''
    const randInts = new Uint32Array(length)
    window.crypto.getRandomValues(randInts)

    randInts.forEach(i => {
        objectId += CHARS[i % CHARS.length]
    })

    return objectId
}
