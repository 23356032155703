import { useCallback, useEffect } from 'react'

import { useAppDispatch } from '../../../util/useAppDispatch'
import { turnChatsPage } from '../actions'

type usePaginationNavigationProps = {
    selectedChats: Models.ChatCenterSelectedChat[]
    paginator: Models.ChatCenterPaginator
    focusedChatIndex: number
    turnChatsPage: typeof turnChatsPage
    prevChatTile: () => void
    nextChatTile: () => void
}

const usePaginationNavigation = (props: usePaginationNavigationProps) => {
    const { paginator, turnChatsPage, focusedChatIndex, selectedChats, prevChatTile, nextChatTile } = props
    const dispatch = useAppDispatch()

    const turnPageListener = useCallback(() => {
        if (paginator.direction === 'prev') {
            dispatch(turnChatsPage({ direction: 'current' }))
            prevChatTile()
        }

        if (paginator.direction === 'next') {
            dispatch(turnChatsPage({ direction: 'current' }))
            nextChatTile()
        }
    }, [paginator.direction, dispatch, turnChatsPage, prevChatTile, nextChatTile])

    const checkIfArrowsAreEnabled = useCallback(() => {
        if (focusedChatIndex === 0 && paginator.prevEnabled) {
            dispatch(turnChatsPage({ prevEnabled: false }))
        }

        if (focusedChatIndex > 0 && !paginator.prevEnabled) {
            dispatch(turnChatsPage({ prevEnabled: true }))
        }

        if (focusedChatIndex === selectedChats.length - 1 && paginator.nextEnabled) {
            dispatch(turnChatsPage({ nextEnabled: false }))
        }

        if (focusedChatIndex < selectedChats.length - 1 && !paginator.nextEnabled) {
            dispatch(turnChatsPage({ nextEnabled: true }))
        }
    }, [focusedChatIndex, paginator.prevEnabled, paginator.nextEnabled, selectedChats.length, dispatch, turnChatsPage])

    useEffect(() => {
        turnPageListener()
        checkIfArrowsAreEnabled()
    }, [turnPageListener, checkIfArrowsAreEnabled])
}

export default usePaginationNavigation
