import * as React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { setRedirect as setRedirectFn } from '../../appActions'

import { fetchAgents } from './actions'
import DexVoiceAgent from './DexVoiceAgent'
import DexVoiceCommands from './DexVoiceCommands'
import DexVoiceHealth from './DexVoiceHealth'

import './DexVoice.sass'

export type DexVoiceProps = {
    readonly practice: Models.Practice
    readonly agents?: Array<Models.DexisAgent>
}

export type DexVoiceDispatch = {
    readonly fetchAgents: typeof fetchAgents
    readonly setRedirect: typeof setRedirectFn
}

export type DexVoiceState = {}

type Props = DexVoiceProps & DexVoiceDispatch

class DexVoice extends React.PureComponent<Props, DexVoiceState> {
    UNSAFE_componentWillMount() {
        this.props.fetchAgents(
            this.props.practice,
            moment()
                .subtract(7, 'days')
                .toDate(),
            moment().toDate(),
        )
    }

    render() {
        const { practice, agents, setRedirect } = this.props

        if (agents == null) {
            return <div>Loading ...</div>
        }

        const commands: Array<Models.DexisAgentCommand> = []
        for (const agent of agents) {
            if (agent.commands) {
                commands.push(...agent.commands)
            }
        }

        return (
            <div className={classNames('practice-quickview-dexvoice-tab')}>
                <div className="agent-metrics">
                    <DexVoiceHealth agents={agents} />
                    <DexVoiceCommands practice={practice} commands={commands} setRedirect={setRedirect} />
                </div>
                <div className="agent-list">
                    <div className="subheader">Rooms</div>
                    {agents.map(agent => (
                        <DexVoiceAgent key={agent.id} agent={agent} />
                    ))}
                </div>
            </div>
        )
    }
}

export default DexVoice
