import iassign from 'immutable-assign'
import _ from 'lodash'

import { HeartbeatAgentLocation } from '../../../models/Integrations'

import {
    ReceiveRSVPEnabledLocations,
    ReceiveSelfSchedulingWebCode,
    ReceiveSelfSchedulingWebCodeList,
    ReceiveSelfSchedulingWebCodeLocation,
    ReceiveSelfSchedulingWebCodeLocationsList,
    SetAllRSVPsEnabled,
    SetNumberOfEnabledSelfSchedulingWebCodeLocations,
    SetWebCodeLocationsRSVPState,
} from './actions'

export type SelfSchedulingState = {
    webCodes: { [practiceId: string]: Models.SelfSchedulingWebCode[] }
    webCodesLocations: { [webCodeId: string]: Models.SelfSchedulingWebCodeLocationsData }
    numberOfEnabledLocations: { [webCodeId: string]: number }
    rsvpEnabledLocations: { [practiceId: string]: HeartbeatAgentLocation[] }
    allRSVPsEnabled: { [webCodeId: string]: boolean }
}

type SelfSchedulingAction =
    | ReceiveSelfSchedulingWebCodeList
    | ReceiveSelfSchedulingWebCode
    | ReceiveSelfSchedulingWebCodeLocation
    | ReceiveSelfSchedulingWebCodeLocationsList
    | SetNumberOfEnabledSelfSchedulingWebCodeLocations
    | SetWebCodeLocationsRSVPState
    | ReceiveRSVPEnabledLocations
    | SetAllRSVPsEnabled

const initialState: SelfSchedulingState = {
    webCodes: {},
    webCodesLocations: {},
    numberOfEnabledLocations: {},
    rsvpEnabledLocations: {},
    allRSVPsEnabled: {},
}

export function reducer(state: SelfSchedulingState = initialState, action: SelfSchedulingAction) {
    switch (action.type) {
        case 'RECEIVE_SELF_SCHEDULING_WEB_CODE': {
            return iassign(
                state,
                next => next.webCodes[action.webCode.practiceId],
                nextWebCodes => {
                    const idx = nextWebCodes.findIndex(webCode => webCode.id === action.webCode.id)

                    if (idx === -1) {
                        nextWebCodes.push(action.webCode)
                        return nextWebCodes
                    }

                    nextWebCodes[idx] = action.webCode
                    return nextWebCodes
                },
            )
        }
        case 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LIST': {
            return iassign(
                state,
                next => next.webCodes,
                nextWebCodes => {
                    nextWebCodes[action.practiceId] = _.sortBy(action.webCodes, 'url')
                    return nextWebCodes
                },
            )
        }
        case 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LOCATION': {
            return iassign(
                state,
                next => next.webCodesLocations[action.location.selfSchedulingWebCodeId].locations,
                nextWebCodesLocations => {
                    const idx = nextWebCodesLocations.findIndex(
                        webCodeLocation => webCodeLocation.practiceLocationId === action.location.practiceLocationId,
                    )

                    if (idx === -1) {
                        nextWebCodesLocations.push(action.location)
                        return nextWebCodesLocations
                    }

                    nextWebCodesLocations[idx] = action.location
                    return nextWebCodesLocations
                },
            )
        }
        case 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LOCATIONS_LIST': {
            const { webCodeId, page, paginationInfo, locations } = action.receiveWebCodeLocationsList
            return iassign(
                state,
                next => next.webCodesLocations[webCodeId],
                nextWebCodeLocations => {
                    if (!nextWebCodeLocations || page === 1) {
                        const newLocationsData = {
                            locations: _.sortBy(locations, [location => location.name.toLowerCase()]),
                            paginationInfo,
                            currentPage: page,
                        }
                        nextWebCodeLocations = newLocationsData
                    } else {
                        nextWebCodeLocations.locations = _.sortBy(
                            _.unionBy(locations, nextWebCodeLocations.locations, 'practiceLocationId'),
                            [location => location.name.toLowerCase()],
                        )
                        nextWebCodeLocations.paginationInfo = paginationInfo
                        nextWebCodeLocations.currentPage = page
                    }

                    return nextWebCodeLocations
                },
            )
        }
        case 'SET_NUMBER_OF_ENABLED_SELF_SCHEDULING_WEB_CODE_LOCATIONS': {
            return iassign(
                state,
                next => next.numberOfEnabledLocations,
                nextNumberOfEnabledLocations => {
                    nextNumberOfEnabledLocations[action.selfSchedulingWebCodeId] = action.numberOfEnabledLocations

                    return nextNumberOfEnabledLocations
                },
            )
        }
        case 'RECEIVE_RSVP_ENABLED_LOCATIONS': {
            return iassign(
                state,
                next => next.rsvpEnabledLocations[action.practiceId],
                nextRsvpEnabledLocations => {
                    nextRsvpEnabledLocations = action.enabledLocations

                    return nextRsvpEnabledLocations
                },
            )
        }
        case 'SET_WEB_CODE_LOCATIONS_RSVP_STATE': {
            return iassign(
                state,
                next => next.rsvpEnabledLocations[action.practiceId],
                nextRsvpEnabledLocations => {
                    const enabledLocations = action.updates.map(update => update.location.id)
                    nextRsvpEnabledLocations = nextRsvpEnabledLocations.map(loc => {
                        if (enabledLocations.includes(loc.practiceLocation.id)) {
                            const chairfillSelfSchedulingWebCode: any = { id: action.webCodeId }

                            return { ...loc, chairfillSelfSchedulingWebCode: chairfillSelfSchedulingWebCode }
                        }
                        if (loc.chairfillSelfSchedulingWebCode?.id === action.webCodeId) {
                            return { ...loc, chairfillSelfSchedulingWebCode: undefined }
                        }
                        return { ...loc }
                    })
                    return nextRsvpEnabledLocations
                },
            )
        }
        case 'SET_ALL_RSVPS_ENABLED': {
            return iassign(
                state,
                next => next.allRSVPsEnabled[action.webCodeId],
                nextAllRSVPsEnabled => {
                    nextAllRSVPsEnabled = action.enabled
                    return nextAllRSVPsEnabled
                },
            )
        }
        default:
            return state
    }
}
