import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import Checkbox, { CheckboxClassKey } from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelClassKey } from '@mui/material/FormControlLabel'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import classNames from 'classnames'

import { CheckboxTheme } from '../../../Theme'
import { extendMuiTheme } from '../../shared/styles/utils'

import './ChatCenterAlertTask.sass'

const moduleName = 'chat-alert-task'

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

type CheckboxClassOverrides = {
    readonly [key in CheckboxClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    root: `${moduleName}__form-control-root`,
    label: `${moduleName}__form-control-label`,
}

const checkboxClassOverrides: CheckboxClassOverrides = {
    root: `${moduleName}__checkbox-root`,
}

const TaskCheckboxTheme = extendMuiTheme(CheckboxTheme, {
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 3px 3px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    maxWidth: 24,
                },
            },
        },
    },
})

export type Props = {
    task: Models.ChatAlertTask
    updateAlertTask: (alertId: string, marked: boolean) => void
}

const ChatCenterAlertTask = ({ task, updateAlertTask }: Props) => {
    const [loading, setLoading] = useState<boolean>(false)

    const handleOnChange = async () => {
        setLoading(true)
        await updateAlertTask(task.id, !task.marked)
        setLoading(false)
    }

    return (
        <div className={moduleName}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={TaskCheckboxTheme}>
                    <FormControlLabel
                        classes={formControlLabelClassOverrides}
                        control={
                            <Checkbox
                                disabled={loading}
                                disableRipple={true}
                                classes={checkboxClassOverrides}
                                checked={task.marked}
                                onChange={handleOnChange}
                                value={task.id}
                            />
                        }
                        label={''}
                    />
                </ThemeProvider>
            </StyledEngineProvider>
            <label className={`${moduleName}__label`}>
                <ReactMarkdown
                    className={classNames(`${moduleName}__text`, {
                        [`${moduleName}__text--marked`]: task.marked,
                    })}
                    linkTarget="_blank"
                >
                    {task.alert}
                </ReactMarkdown>
            </label>
        </div>
    )
}

export default ChatCenterAlertTask
