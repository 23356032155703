import React from 'react'
import MaskedInput from 'react-input-mask'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'

import CustomInlineDatepicker from './CustomInlineDatepicker'

import './MaskedInlineDatepicker.sass'

const moduleName = 'masked-inline-datepicker'

type Props = {
    dateMask: string
    label?: string | React.ReactElement<any>
    dateFormat: string
    placeholder?: string
    value: string
    disabled?: boolean
    error?: boolean
    errorMessage?: string
    className?: string
    InputProps?: any
    disableFuture?: boolean
    disablePast?: boolean
    keyboard?: boolean
    onBlur?: () => void
    onInputChange: (value: string) => void
    onChange: (value: string) => void
    validator?: (value: string) => boolean
}

const MaskedInlineDatepicker = ({
    dateMask,
    dateFormat,
    placeholder,
    value,
    disabled,
    InputProps,
    disableFuture,
    disablePast,
    className,
    keyboard,
    onBlur = () => {},
    onInputChange,
    onChange,
    validator,
    label = '',
    error = false,
    errorMessage = 'Invalid date value',
}: Props) => {
    const isValid = (value: string) => {
        if (validator !== undefined) {
            return validator(value)
        }
        return true
    }

    return (
        <div className={moduleName}>
            <MaskedInput
                mask={dateMask}
                value={value}
                disabled={Boolean(disabled)}
                onBlur={onBlur}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value: targetValue } = event.target as HTMLInputElement
                    onInputChange(targetValue)
                }}
            >
                {() => (
                    <input
                        value={value}
                        disabled={Boolean(disabled)}
                        placeholder={placeholder ? placeholder : dateFormat}
                        className={`${moduleName}__masked-input ${error && `${moduleName}__masked-input--error`}`}
                    />
                )}
            </MaskedInput>
            <Icon
                className={`${moduleName}__calendar-icon ${disabled ? `${moduleName}__calendar-icon--disabled` : ''}`}
            >
                date_range
            </Icon>
            <CustomInlineDatepicker
                value={value && isValid(value) ? moment(value, dateFormat).toDate() : null}
                label={label}
                className={className}
                invalidLabel={dateFormat}
                placeholder={dateFormat}
                dateFormat={dateFormat}
                InputProps={InputProps}
                error={error}
                disableFuture={disableFuture}
                disablePast={disablePast}
                keyboard={keyboard}
                maxDateMessage="Future dates not supported"
                onChange={(value: moment.Moment) => {
                    onChange(value?.format(dateFormat))
                }}
            />
            {error === true && <div className={`${moduleName}__error`}>{errorMessage}</div>}
        </div>
    )
}

export default MaskedInlineDatepicker
