import ApiService from '../service'

const API_UPLOAD_HOST = process.env.REACT_APP_API_UPLOAD_HOST

export default class DexVoiceDownloadService extends ApiService {
    readonly ModulePath: string = 'api/voice/dexis/v1/admin'

    constructor(token: string) {
        super(token)
        this.host = API_UPLOAD_HOST
    }

    async downloadLicense(practice: Models.Practice, licenseId: string) {
        return await this.fetch(`practices/${practice.id}/licenses/${licenseId}/download`)
    }
}
