import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { TableClassKey } from '@mui/material/Table'
import { TableCellClassKey } from '@mui/material/TableCell'
import { sortBy } from 'lodash'
import moment from 'moment-timezone'

import { RootState } from '../../../appReducer'
import { ActivityLog } from '../../../models/v2/Referrals'
import ErrorMessage from '../../../modules/shared/error-message/error-message'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { useAsync } from '../../../util/useAsync'
import EnhancedTableHead from '../../shared/enhanced-table-head/EnhancedTableHead'
import { fetchReferralActivityLog } from '../v2actions'

import './ReferralActivityLog.sass'

type Order = 'asc' | 'desc'

type Props = {
    referral?: ModelsV2.Referrals.ReferralV2
    timezone?: string
}

const ACTIVITY_DATE_FORMAT = 'MM/DD/YYYY h:mm A (z)'

const columnLabels = [
    { id: 'created', label: 'Date', sortable: true },
    { id: 'name', label: 'Name' },
    { id: 'details', label: 'Details' },
    { id: 'additionalData', label: '' },
]

type TableClassOverrides = {
    readonly [key in TableClassKey]?: string
}

type TableCellClassOverrides = {
    readonly [key in TableCellClassKey]?: string
}

const moduleName = 'referral-activity-log'

const ReferralActivityLog = (props: Props) => {
    const { referral } = props
    const timezone = props.timezone ?? moment.tz.guess()
    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState('')
    const referralId = referral && referral.id
    const dispatch = useAppDispatch()
    const { run: fetchAsyncReferralAcitivityLog, status: statusFetchAsyncReferralAcitivityLog } = useAsync()

    useEffect(() => {
        if (referralId) {
            fetchAsyncReferralAcitivityLog(dispatch(fetchReferralActivityLog(referralId)))
        }
    }, [dispatch, fetchAsyncReferralAcitivityLog, referralId])

    const referralActivityLogs = useSelector(
        (state: RootState) => referralId && state.v2.referrals.referralActivityLog[referralId],
    )

    const tableClassOverrides: TableClassOverrides = {
        root: `${moduleName}__table`,
    }

    const tableCellClassOverrides: TableCellClassOverrides = {
        root: `${moduleName}__table-cell`,
    }

    const handleRequestSort = (property: string) => {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }

    const sortActivites = (activities: ActivityLog[], order: Order, orderBy: string) => {
        const sorted = order === 'desc' ? sortBy(activities, orderBy).reverse() : sortBy(activities, orderBy)
        return sorted
    }

    if (statusFetchAsyncReferralAcitivityLog === 'rejected') {
        return <ErrorMessage>Problem with loading referral activity</ErrorMessage>
    }

    return (
        <Grid container={true} spacing={0} className={`${moduleName}`}>
            <Grid item={true} xs={12}>
                <div className={`${moduleName}__title`}>Activity Log</div>
            </Grid>
            <Grid item={true} xs={12}>
                <div className={`${moduleName}__table-view`}>
                    <Table classes={tableClassOverrides}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            columnLabels={columnLabels}
                        />
                        <TableBody>
                            {referralActivityLogs &&
                                sortActivites(referralActivityLogs, order, 'created').map(activity => (
                                    <TableRow id={activity.id} key={activity.id}>
                                        <TableCell
                                            classes={tableCellClassOverrides}
                                            className={`${moduleName}__table-cell ${moduleName}__no-wrap-cell`}
                                        >
                                            {activity.created &&
                                                timezone &&
                                                moment(activity.created)
                                                    .tz(timezone)
                                                    .format(ACTIVITY_DATE_FORMAT)}
                                        </TableCell>
                                        <TableCell
                                            classes={tableCellClassOverrides}
                                            className={`${moduleName}__table-cell ${moduleName}__no-wrap-cell`}
                                        >
                                            {activity.author}
                                        </TableCell>
                                        <TableCell
                                            classes={tableCellClassOverrides}
                                            className={`${moduleName}__table-cell ${moduleName}__mark-down-cell`}
                                        >
                                            <ReactMarkdown className={`${moduleName}__activity-details`}>
                                                {activity.details}
                                            </ReactMarkdown>
                                        </TableCell>

                                        <TableCell
                                            classes={tableCellClassOverrides}
                                            className={`${moduleName}__table-cell ${moduleName}__no-wrap-cell`}
                                        ></TableCell>
                                    </TableRow>
                                ))}
                            <></>
                        </TableBody>
                    </Table>
                </div>
            </Grid>
        </Grid>
    )
}

export default ReferralActivityLog
