import { Enum } from '../../models/enums'

export type ApiAppointmentStatusTypeValues = 'sync_pending' | 'syncing' | 'sync_successful' | 'sync_unsuccessful'

export interface ApiAppointment {
    id: string
    created: string
    start_date: string
    end_date: string
    operatory_id: string
    patient_id: string
    procedure_id: string
    provider_id: string
    status: Enum<ApiAppointmentStatusTypeValues>
}

export function mapAppointment(appointment: ApiAppointment): Models.Appointment {
    return {
        id: appointment.id,
        operatoryId: appointment.operatory_id,
        providerId: appointment.provider_id,
        procedureId: appointment.procedure_id,
        patientId: appointment.patient_id,
        startDate: new Date(appointment.start_date),
        endDate: new Date(appointment.end_date),
        created: new Date(appointment.created),
        status: appointment.status,
    }
}
