import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'

import ApiV2 from '../../../ApiV2'
import { RootState } from '../../../appReducer'
import { IntegrationsAgent, IntegrationsAgentModule } from '../../../models/v2/Integrations'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CopyText from '../../shared/CopyText'
import ErrorMessage from '../../shared/error-message/error-message'

import { setAgentsPending, setSelectedAgent } from './actions'

import './Integrations.sass'

const moduleName = 'integrations'
const ERROR_NO_AGENT = 'We could not detect the installed agent. Please install agent prior to pairing locations.'

export const SOFTWARE_TYPES = {
    DENTRIX: 'DENTRIX',
    DENTRIX_ENTERPRISE: 'DENTRIX_ENTERPRISE',
    EAGLESOFT: 'EAGLESOFT',
    OPENDENTAL: 'OPENDENTAL',
    QUICKBOOKS: 'QUICKBOOKS',
}

interface Props {
    selectedAgent: IntegrationsAgent
    practice: Models.Practice
}

const ellipsis = (text: string, maxLength: number = 100) => {
    if (text.length < maxLength) {
        return text
    }

    return `${text.slice(0, maxLength)}...`
}

export const IntegrationsDownloadAgent = ({ selectedAgent, practice }: Props) => {
    const [error, setError] = useState('')
    const isPending = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.isPending ?? false,
    )

    const dispatch = useAppDispatch()

    const hasExternalLocations = (agent: IntegrationsAgent) =>
        agent.agentModules && agent.agentModules.some((module: IntegrationsAgentModule) => module.agentLocations.length)

    const handlePairLocations = async () => {
        dispatch(setAgentsPending(practice.id, true))
        const agent = await ApiV2.Integrations.getAgent(selectedAgent.id)

        if (!agent) {
            setError(ERROR_NO_AGENT)
            dispatch(setAgentsPending(practice.id, false))
            return
        }
        if (!agent || !agent.agentModules || !hasExternalLocations(agent)) {
            setError(ERROR_NO_AGENT)
            dispatch(setAgentsPending(practice.id, false))
            return
        }

        if (agent && agent.agentModules && hasExternalLocations(agent)) {
            dispatch(setSelectedAgent(agent))
            dispatch(setAgentsPending(practice.id, false))
            setError('')
        }

        dispatch(setAgentsPending(practice.id, false))
    }

    return (
        <>
            <Fragment>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <h1 className={`name-header ${moduleName}__name-header`}>{selectedAgent?.name}</h1>
                <p>
                    Please download and install modules automatically using this agent. When you have successfully
                    installed the agent, please click ‘pair locations’ to complete this agent.
                </p>
                <div className={`${moduleName}__download`}>
                    <div>
                        <a download target="_blank" rel="noopener noreferrer" href={selectedAgent.downloadUrl}>
                            Download agent
                        </a>
                        <CopyText
                            text={selectedAgent.downloadUrl}
                            tooltipText={ellipsis(selectedAgent.downloadUrl)}
                        ></CopyText>
                    </div>
                    <div>
                        <CopyText text={selectedAgent.accessKey}>
                            <strong>Access Key:</strong> {selectedAgent.accessKey}
                        </CopyText>
                    </div>
                    <div>
                        <CopyText text={selectedAgent.secretKey}>
                            <strong>Secret Key:</strong> {selectedAgent.secretKey}
                        </CopyText>
                    </div>
                </div>
                <div className={`${moduleName}__footer`}>
                    <Button
                        className={`${moduleName}__submit-button`}
                        onClick={handlePairLocations}
                        disabled={isPending}
                    >
                        PAIR LOCATIONS
                    </Button>
                </div>
            </Fragment>
        </>
    )
}
