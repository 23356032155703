import React, { useMemo, useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { debounce } from 'lodash'

import { CustomFieldType } from '../../../../../../models/enums'
import { SurveySectionQuestion } from '../../../../../../models/PracticeSurvey'
import CustomPhoneInput from '../../../../../../modules/shared/custom-fields/CustomPhoneInput'
import CustomField from '../../../../../shared/custom-fields/CustomField'

import './InputField.sass'

interface Props {
    question: SurveySectionQuestion
    onChange: (value: string) => void
    countryCode?: string
    showErrorText?: boolean
}

const InputField = ({
    question: {
        id,
        variants,
        isRequired,
        isDirty,
        isValid,
        errorMessage,
        error,
        answer_schema,
        component_type,
        component_size,
    },
    onChange,
    countryCode,
    showErrorText = false,
}: Props) => {
    const onChangeDebounce = useMemo(() => debounce(onChange, 500), [onChange])
    const handleChange = (nextValue: string) => {
        setInputValue(nextValue)
        onChangeDebounce(nextValue)
    }

    const hasError = (isDirty && !isValid) || error
    const isAutoSize = Boolean(variants?.includes('auto-size'))
    const value = (answer_schema?.value as string) || ''
    const [inputValue, setInputValue] = useState<string>(value)
    const extraClasses = variants?.map(variant => `input-field--${variant}`).join(' ') ?? ''

    const customFieldProps =
        component_type === 'text-area'
            ? { customFieldType: CustomFieldType.TEXTAREA, rows: component_size ?? 3, autoSize: isAutoSize }
            : { customFieldType: CustomFieldType.INPUT }
    const customField = () => {
        if (variants?.includes('phone-number')) {
            // initial validation with libphonenumber
            let phoneHasError = hasError
            let phoneHasErrorMessage = errorMessage
            if (value !== '' && !isDirty) {
                phoneHasError = !isValidPhoneNumber(value)
                phoneHasErrorMessage = 'Invalid Phone # format'
            }

            return (
                <CustomPhoneInput
                    {...customFieldProps}
                    value={inputValue}
                    errorMessage={phoneHasErrorMessage}
                    error={phoneHasError}
                    key={id}
                    country={countryCode ?? 'US'}
                    onChange={(value: string) => {
                        handleChange(value ?? '')
                    }}
                />
            )
        }
        return (
            <div className="input-field__wrapper">
                <CustomField
                    {...customFieldProps}
                    value={inputValue}
                    required={isRequired}
                    error={hasError}
                    key={id}
                    errorMessage={errorMessage}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(event.target.value)
                    }}
                />
                {hasError && showErrorText && <span className="input-field__error">{errorMessage}</span>}
            </div>
        )
    }
    return (
        <div className={`input-field ${extraClasses}`}>
            <div className="input-field__value">{customField()}</div>
        </div>
    )
}

export default InputField
