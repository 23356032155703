import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import ChatCenterAlertTask from './ChatCenterAlertTask'

import './ChatCenterAlertTasksList.sass'

export type Props = {
    tasks?: Models.ChatAlertTask[]
    updateAlertTask: (alertId: string, marked: boolean) => void
}

const moduleName = 'chat-center-alert-tasks-list'

const ChatCenterAlertTasksList = (props: Props) => {
    const { tasks, updateAlertTask } = props

    if (!tasks) {
        return (
            <div className={`${moduleName}__loader`}>
                <CircularProgress size={70} color="primary" variant="indeterminate" />
            </div>
        )
    }

    return (
        <ul className={`${moduleName}__list`}>
            {tasks.map(task => (
                <li key={task.id}>
                    <ChatCenterAlertTask task={task} updateAlertTask={updateAlertTask} />
                </li>
            ))}
        </ul>
    )
}

export default ChatCenterAlertTasksList
