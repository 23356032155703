import React, { useState } from 'react'
import { Button } from '@mui/material'
import Modal from '@mui/material/Modal'

import { CustomFieldType } from '../../../models/enums'
import PubNubService from '../../../services/PubNubService'
import { newObjectId } from '../../../util/objectId'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { saveWebCode } from '../../amplify/actions'
import CustomField from '../../shared/custom-fields/CustomField'
import { updateSelectedChat } from '../actions'

import './BanUserModal.sass'

export type Props = {
    isModalOpen: boolean
    bannedBy?: string
    chat: Models.ChatCenterSelectedChat
    date?: string
    country?: string
    webCode: Models.WebCode
    closeBanUserModal: () => void
    closeBanUserModalAndEndChat: (data: Models.ChatClose) => void
}

const moduleName = 'ban-user-modal'

const BanUserModal = (props: Props) => {
    const [reasonForBan, setReasonForBan] = useState<string>('')
    const { bannedBy, date, country, chat, webCode } = props

    const onReasonForBanChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setReasonForBan(event.target.value)
    }

    const onCancel = () => {
        props.closeBanUserModal()
    }

    const dispatch = useAppDispatch()

    const onBanUser = () => {
        const bannedBy = props.bannedBy || ''
        const location = props.country || ''
        const dateNow = new Date()
        const customization = webCode.customization

        if (!customization.banned_ip_addresses) {
            customization.banned_ip_addresses = []
        }

        customization.banned_ip_addresses.push({
            ip_address: chat.ip,
            date: dateNow,
            ban_reason: reasonForBan.trim(),
            banned_by: bannedBy,
            location: location,
        })

        const updates = {
            customization,
        }

        dispatch(saveWebCode(chat.practice as Models.Practice, webCode, updates))
        PubNubService.send(chat.channelName, {
            id: newObjectId(32),
            text: `Ban:${chat.ip}`,
            is_patient: false,
            sender_id: PubNubService.getChatterId(),
            type: 'system:ban',
        })

        const updatedChat = { id: chat.id, isConversationBanned: true }
        dispatch(updateSelectedChat(updatedChat))

        props.closeBanUserModalAndEndChat({
            reasons: ['other'],
            message: reasonForBan.trim() || 'banned',
            success: false,
        })
    }

    const cancelButton = () => (
        <Button variant="outlined" color="primary" className={`${moduleName}__cancel-button`} onClick={onCancel}>
            Cancel
        </Button>
    )

    return (
        <Modal
            className={`${moduleName}`}
            open={props.isModalOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.closeBanUserModal()
                }
            }}
        >
            <div className={`${moduleName}__modal-contents`}>
                <div className={`${moduleName}__ip-address-title`}>Ban {props.chat.ip}</div>
                <div className={`${moduleName}__close-modal-button`} onClick={props.closeBanUserModal}>
                    <i className={`${moduleName}__icon material-icons`}>close</i>
                </div>
                <div className={`${moduleName}__body`}>
                    <React.Fragment>
                        <div className={`${moduleName}__ban-info`}>
                            <div className={`${moduleName}__info-field`}>
                                Date:
                                <span className={`${moduleName}__info-span`}> {date}</span>
                            </div>
                            <div className={`${moduleName}__info-field`}>
                                Location:
                                <span className={`${moduleName}__info-span`}> {country}</span>
                            </div>
                            <div className={`${moduleName}__info-field`}>
                                Banned by:
                                <span className={`${moduleName}__info-span`}> {bannedBy}</span>
                            </div>
                        </div>
                        <CustomField
                            customFieldType={CustomFieldType.TEXTAREA}
                            value={reasonForBan || ''}
                            rows={4}
                            label="Reason for ban*"
                            onChange={onReasonForBanChange}
                        />
                        <div className={`${moduleName}__ban-chatter-button-container`}>
                            {cancelButton()}
                            <Button
                                variant="contained"
                                color="secondary"
                                className={`${moduleName}__ban-chatter-button`}
                                onClick={onBanUser}
                                disabled={!reasonForBan.trim()}
                            >
                                BAN CHATTER
                            </Button>
                        </div>
                    </React.Fragment>
                </div>
            </div>
        </Modal>
    )
}

export default BanUserModal
