import * as React from 'react'
import ReactLoading from 'react-loading'
import Button from '@mui/material/Button'

import { mainBlue } from '../../shared/styles/colors'

import './SyncSchedBtn.sass'

type Props = {
    locationId: string
    syncLocationSchedulingData: (locationId: string) => void
}

type State = {
    isSyncing: boolean
}

class SyncSchedBtn extends React.PureComponent<Props, State> {
    state: State = {
        isSyncing: false,
    }

    startSync = async () => {
        this.setState({ isSyncing: true }, this.syncLocationScheduling)
    }

    syncLocationScheduling = async () => {
        const { locationId, syncLocationSchedulingData } = this.props

        await syncLocationSchedulingData(locationId)
        this.setState({ isSyncing: false })
    }

    render() {
        return (
            <Button
                className="button sync-sched-btn"
                size="small"
                onClick={this.startSync}
                disabled={this.state.isSyncing}
                title="Sync data from Jarvis"
            >
                {this.state.isSyncing && (
                    <ReactLoading className="btn-sync-spinner" type="spin" color={mainBlue} height={15} width={15} />
                )}
                <span>SYNC</span>
            </Button>
        )
    }
}

export default SyncSchedBtn
