import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Icon } from '@mui/material'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import { PracticeLocation } from '../../../models/v2/Practice'
import { fetchHeartbeatProviders } from '../../../modules/heartbeat/v2actions'
import { fetchPractice } from '../../../modules/practices/actions'
import Paginator from '../../shared/Paginator'
import { fetchHeartbeatPracticeLocationProviders, fetchPracticeLocationsPaginated, setPractice } from '../v2actions'

import HeartbeatSearchBar from './HeartbeatSearchBar'
import Location from './Location'

import './HeartbeatTab.sass'

export type HeartbeatTabProps = {
    practice: Models.Practice
}

const LOCATION_PAGE_SIZE = 5

const moduleName = 'heartbeat-tab'

const HeartbeatTab = (props: HeartbeatTabProps) => {
    const { practice } = props
    const practiceId = practice?.id
    const practiceName = practice?.name
    const externalHeartbeatPracticeId = practice?.externalHeartbeatPracticeId

    const practiceState = useSelector((state: RootState) => state.v2.practices.practices?.[practice.id])
    const locations = useSelector((state: RootState) => state.v2.practices.practices?.[practice.id]?.locations)
    const heartbeatProviders = useSelector((state: RootState) => state.v2.heartbeat.heartbeatProviders)
    const v2PracticeId = practiceState?.id

    const requiredDataLoaded = v2PracticeId && heartbeatProviders && practiceState?.locationProviders

    const [loading, setLoading] = useState(false)
    const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)
    const [locationQuery, setLocationQuery] = useState<string>(practiceState?.locationQuery || '')
    const [hasSearchBar, setHasSearchBar] = useState(false)
    const [searchPage, setSearchPage] = useState<number>(practiceState?.currentLocationPage || 0)

    const locationsUndefined = Boolean(typeof locations === 'undefined')

    const allRows = practiceState?.locationPaginationInfo?.allRows || 0

    const dispatch = useDispatch()

    const searchForLocations = useCallback(
        async (searchKey: string, searchPage: number, practiceData) => {
            const searchTerms: ApiV2.Practice.PracticeLocationSearchTerms = {
                query: searchKey,
                limit: LOCATION_PAGE_SIZE,
                page: searchPage,
            }
            setLoading(true)
            await dispatch(fetchPracticeLocationsPaginated(searchTerms, practiceData))
            setLoading(false)
        },
        [dispatch],
    )

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    const refreshProviders = useCallback(() => {
        dispatch(
            fetchHeartbeatPracticeLocationProviders({
                id: practiceId,
                name: practiceName,
                externalHeartbeatPracticeId: externalHeartbeatPracticeId,
            }),
        )
        dispatch(fetchHeartbeatProviders())
    }, [dispatch, externalHeartbeatPracticeId, practiceName, practiceId])

    const throttledSearchLocations = useMemo(() => throttle(searchForLocations, 300), [searchForLocations])

    useEffect(() => {
        if (searchPage && practiceId && practiceName) {
            throttledSearchLocations(locationQuery.trim(), searchPage, {
                id: practiceId,
                name: practiceName,
            })
        }
    }, [throttledSearchLocations, locationQuery, searchPage, practiceId, practiceName, locationsUndefined])

    useEffect(() => {
        if (allRows > 10 || locationQuery !== '') {
            setHasSearchBar(true)
        }
    }, [allRows, locationQuery])

    const fetchFirstLoadData = useCallback(async () => {
        setLoading(true)
        const data = await dispatch(fetchPractice(practiceId))
        const v2Practice = {
            id: data.id,
            name: data.name,
            externalHeartbeatPracticeId: data.external_heartbeat_practice_id,
        }
        await dispatch(setPractice(v2Practice))
        await dispatch(fetchHeartbeatPracticeLocationProviders(v2Practice))
        await dispatch(fetchHeartbeatProviders())
        handlePageChange(1)
        setIsFirstLoadDone(true)
        setLoading(false)
    }, [dispatch, practiceId])

    useEffect(() => {
        if (!requiredDataLoaded) {
            fetchFirstLoadData()
        }
    }, [fetchFirstLoadData, requiredDataLoaded])

    useEffect(() => {
        if (!isFirstLoadDone) {
            refreshProviders()
        }
    }, [isFirstLoadDone, refreshProviders])

    const renderNoLocationsMessage = () => {
        return (
            <div className={`${moduleName}__empty-locations`}>
                <div className={`${moduleName}__error-icon`}>
                    <Icon>error</Icon>
                </div>

                <div className={`${moduleName}__text-bold`}>
                    No locations have been paired with the agent yet, so we are unable to collect data from the PMS.
                </div>
                <div>Please install the agent and enable the "Heartbeat" product.</div>
            </div>
        )
    }

    if (locationsUndefined || typeof practiceState === 'undefined') {
        return (
            <div className={`${moduleName}__loading-container`}>
                <div className="spinner-overlay">
                    <CircularProgress className="spinner-circural-bar" size={50} />
                </div>
            </div>
        )
    }

    const noLocations = !loading && (locations === null || !locations?.length)
    if (locationQuery === '' && (noLocations || !practiceState?.externalHeartbeatPracticeId)) {
        return <div className={`${moduleName}`}>{renderNoLocationsMessage()}</div>
    }

    return (
        <div className={`${moduleName}`}>
            {hasSearchBar && (
                <HeartbeatSearchBar
                    practiceId={practiceId}
                    setLocationQuery={setLocationQuery}
                    setSearchPage={setSearchPage}
                    locationQuery={locationQuery}
                    loading={loading}
                />
            )}
            {locations?.map((location: PracticeLocation, index) => {
                return <Location location={location} practice={practiceState} key={`key_${index}`} />
            })}
            <div className={`${moduleName}__paginator-wrapper`}>
                {requiredDataLoaded && practiceState.locationPaginationInfo && (
                    <Paginator
                        currentPage={searchPage}
                        paginationInfo={practiceState.locationPaginationInfo}
                        selectPage={handlePageChange}
                    />
                )}
            </div>
            {loading && (
                <div className="circular-progress-loader-body">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
        </div>
    )
}

export default HeartbeatTab
