import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import { SearchBar } from '../../shared/custom-fields/SearchBar'

interface Props {
    practiceId: string
    staffQuery: string
    setStaffQuery: (value: string) => void
    setSearchPage: (value: number) => void
}

const StaffSearchBar = ({ practiceId, staffQuery, setStaffQuery, setSearchPage }: Props) => {
    const list = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.list || [])
    const loading = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.loading || false)

    const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setStaffQuery(value)
        setSearchPage(1)
    }

    const handleClearSearch = () => {
        setStaffQuery('')
        setSearchPage(1)
    }

    const throttledHandleSearch = throttle(handleSearch, 1000, {
        trailing: false,
    })

    return (
        <div className={`staff-search-bar-wrapper`}>
            <SearchBar
                value={staffQuery}
                onClear={handleClearSearch}
                onChange={throttledHandleSearch}
                placeholder="Search by Name or Email Address"
                isMessageShown={staffQuery.length > 0 && !loading && list.length === 0}
            />
        </div>
    )
}

export type StaffSearchBarProps = Props
export default StaffSearchBar
