import React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import SelfSchedulingModel from '../../../../models/SelfScheduling'

import './SelfSchedulingInsurance.sass'

export type SelfSchedulingInsuranceProps = {
    insurance: Models.SelfSchedulingInsurance
    updateInsurance: (insurance: Models.SelfSchedulingInsurance) => void
}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

const radioClassOverrides: RadioClassOverrides = {
    root: 'radio-root',
}

const moduleName = 'self-scheduling-insurance'

function SelfSchedulingInsurance(props: SelfSchedulingInsuranceProps) {
    const { insurance = SelfSchedulingModel.defaultInsurance } = props

    function updateInsurance(e: React.ChangeEvent<HTMLInputElement>) {
        props.updateInsurance(e.target.value as Models.SelfSchedulingInsurance)
    }

    return (
        <div className={moduleName}>
            <h3 className={`${moduleName}__title`}>Insurance</h3>
            <div className={`${moduleName}__radio-buttons-wrapper`}>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="insurance-required"
                        color="primary"
                        checked={insurance === 'required'}
                        onChange={updateInsurance}
                        name="radio-widget-style"
                        value="required"
                        classes={radioClassOverrides}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="insurance-required">
                            <span className={`${moduleName}__label-text`}>Required</span>
                        </label>
                    </div>
                </div>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="insurance-optional"
                        color="primary"
                        checked={insurance === 'optional'}
                        onChange={updateInsurance}
                        name="radio-widget-style"
                        value="optional"
                        classes={radioClassOverrides}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="insurance-optional">
                            <span className={`${moduleName}__label-text`}>Optional</span>
                        </label>
                    </div>
                </div>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="insurance-none"
                        color="primary"
                        checked={insurance === 'none'}
                        onChange={updateInsurance}
                        name="radio-widget-style"
                        value="none"
                        classes={radioClassOverrides}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="insurance-none">
                            <span className={`${moduleName}__label-text`}>None</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingInsurance
