import React, { useEffect } from 'react'
import { useState } from 'react'
import { CircularProgress, Modal } from '@mui/material'

import AdminAccount from '../../models/AdminAccount'
import TestReferralTypeSelector from '../../modules/amplify/TestReferralTypeSelector'

import ReferralForm from './referral-form/ReferralForm'

import './ReferralModal.sass'

export type Props = {
    newTestReferral: boolean
    newManualReferral: boolean
    practice?: Models.Practice
    referralId?: string
    pid?: string
    actions?: React.ReactNode
    account?: AdminAccount
    loading?: boolean
    onClose?: () => void
    onCreate?: () => void
    onUpdate?: (
        currentStatus: ModelsV2.Referrals.StatusValue,
        nextStatus: ModelsV2.Referrals.StatusValue | null,
        referralForm: ModelsV2.Referrals.ReferralForm,
    ) => void
}

const ReferralModal = (props: Props) => {
    const {
        onUpdate,
        onClose,
        onCreate,
        actions,
        referralId,
        pid,
        account,
        newTestReferral,
        newManualReferral,
        practice,
        loading,
    } = props
    const [referralFormLoading, setReferralFormLoading] = useState(false)
    const [testReferralType, setTestReferralType] = useState<Models.PracticeSpecialtyValue>()
    const [showTestReferralTypeSelector, setShowTestReferralTypeSelector] = useState(newTestReferral === true)
    const [showReferralForm, setShowReferralForm] = useState(newTestReferral !== true)

    const onSelectedTestReferralType = (type: Models.PracticeSpecialtyValue) => {
        setTestReferralType(type)
        setShowTestReferralTypeSelector(false)
        setShowReferralForm(true)
    }
    useEffect(() => {
        if (loading !== undefined) {
            setReferralFormLoading(loading)
        }
    }, [loading])
    return (
        <Modal
            className="pst-modal"
            open={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && onClose) {
                    onClose()
                }
            }}
            disableEscapeKeyDown={true}
        >
            <>
                {referralFormLoading && (
                    <div className={`loading-overlay`}>
                        <CircularProgress size={100} className={'loading'} />
                    </div>
                )}
                <div className="contents referral__modal">
                    <div className="close-modal-button" onClick={onClose}>
                        <i className="material-icons">close</i>
                    </div>
                    {showTestReferralTypeSelector && practice && (
                        <TestReferralTypeSelector
                            practiceName={practice.name}
                            onSelectedTestReferralType={onSelectedTestReferralType}
                        />
                    )}
                    {showReferralForm && (
                        <ReferralForm
                            testReferral={newTestReferral}
                            manualReferral={newManualReferral}
                            testReferralType={testReferralType}
                            setLoading={setReferralFormLoading}
                            pid={pid}
                            referralId={referralId}
                            onUpdate={onUpdate}
                            onCreate={onCreate}
                            actions={actions}
                            account={account}
                        />
                    )}
                </div>
            </>
        </Modal>
    )
}

export default ReferralModal
