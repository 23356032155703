import React from 'react'

import {
    OtherOption,
    QuestionOption,
    SurveySection,
    SurveySectionQuestion,
} from '../../../../../../models/PracticeSurvey'
import ProceduresEdit from '../../procedures/ProceduresEdit'

import './SelectMulti.sass'

interface Props {
    survey: SurveySection
    question: SurveySectionQuestion
    location: Models.PracticeLocation
    onChange: (options: QuestionOption[], other_option?: OtherOption) => void
}

const SelectMultiTabs = ({ survey, question, location, onChange }: Props) => {
    const handleChangeTabs = () => (options: QuestionOption[], other_option?: OtherOption) => {
        onChange(options, other_option)
    }

    return <ProceduresEdit survey={survey} question={question} location={location} onChange={handleChangeTabs()} />
}

export default SelectMultiTabs
