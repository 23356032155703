import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import { RootState } from '../../../appReducer'
import Paginator from '../../shared/Paginator'
import { ArrowDropDown } from '../../shared/svgIcons'

import StaffMember from './StaffMember'

import './StaffTabTableBody.sass'

export type StaffTabTableBodyProps = {
    practiceId: string
    practice: Models.Practice
    searchSort: ModelsV2.Practice.StaffSort | undefined
    searchOrder: 'asc' | 'desc' | undefined
    searchPage: number
    setSearchSort: (sort: ModelsV2.Practice.StaffSort) => void
    setSearchOrder: (order: 'asc' | 'desc') => void
    setSearchPage: (page: number) => void
    refreshStaffList: () => void
}

type Props = StaffTabTableBodyProps

const StaffTabTableBody = ({
    practiceId,
    practice,
    searchOrder,
    searchSort,
    searchPage,
    setSearchPage,
    setSearchOrder,
    setSearchSort,
    refreshStaffList,
}: Props) => {
    const list = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.list || [])
    const pagination = useSelector(
        (state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.pagination || null,
    )

    const loading = useSelector((state: RootState) => state.v2.practices.practiceStaff?.[practiceId]?.loading || false)

    const getSortingDirectionOfProperty = (sortByProperty: string) => {
        if (sortByProperty === searchSort) {
            return searchOrder === 'desc' ? 'asc' : 'desc'
        }

        return 'desc'
    }
    const sortByProperty = (sort: ModelsV2.Practice.StaffSort) => {
        const order = getSortingDirectionOfProperty(sort)
        setSearchSort(sort)
        setSearchOrder(order)
    }

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    const renderRows = () => {
        return (
            <>
                {list &&
                    list.map((employee: any) => (
                        <StaffMember
                            key={employee.id}
                            practice={practice}
                            employee={employee}
                            refreshStaffList={refreshStaffList}
                        />
                    ))}
            </>
        )
    }

    if (list == null) {
        return (
            <div className="circular-progress-loader-background">
                <div className="circular-progress-loader">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="staff-table-body">
            <Table>
                <TableHead>
                    <TableRow className="table-row">
                        <TableCell className="table-item table-first-item table-header-item" align="left" />
                        <TableCell className="narrower-table-item table-header-item">ID</TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'last_name'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('last_name')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Last Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'first_name'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('first_name')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                First Name
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'username'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('username')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Email Address
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'simplifeye_role.value'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('simplifeye_role.value')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Role
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="table-item table-header-item">
                            <TableSortLabel
                                active={searchSort === 'account_status.value'}
                                direction={searchOrder}
                                onClick={() => {
                                    sortByProperty('account_status.value')
                                }}
                                IconComponent={ArrowDropDown}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className="narrower-table-item table-header-item">Edit</TableCell>
                        <TableCell className="table-item table-last-item table-header-item" />
                    </TableRow>
                </TableHead>
                <TableBody>{renderRows()}</TableBody>
            </Table>
            {pagination?.allRows && pagination?.allRows > 8 && (
                <div className="staff-paginator-wrapper">
                    <Paginator currentPage={searchPage} paginationInfo={pagination} selectPage={handlePageChange} />
                </div>
            )}
            {loading && (
                <div className="circular-progress-loader-body">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
        </div>
    )
}

export default StaffTabTableBody
