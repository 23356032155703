import * as React from 'react'

import './Shortcut.sass'

interface Props {
    shortcut: Models.Shortcut
    active: boolean
    selectShortcut: (text: string) => void
}

const Shortcuts = ({ shortcut, active, selectShortcut }: Props) => {
    const handleClick = () => selectShortcut(shortcut.message)

    return (
        <div key={shortcut.name} className={'shortcut ' + (active ? 'active' : '')} onClick={handleClick}>
            <span>/{shortcut.name}</span>
            <p>{shortcut.message}</p>
        </div>
    )
}

export default Shortcuts
