import * as React from 'react'
import _ from 'lodash'

import ColorPickerButton from '../shared/ColorPickerButton'

export type WidgetCustomColorsProps = {
    customization: Api.WebCodeCustomization
    resetColors: (e: React.MouseEvent<HTMLElement>) => void
    updateCustomColor: (groupKey: string, colorKey: string, newColor: string) => void
}

export type WidgetCustomColorsState = {}

export default class WidgetCustomColors extends React.Component<WidgetCustomColorsProps, WidgetCustomColorsState> {
    render() {
        const { customization, resetColors, updateCustomColor } = this.props
        const widgetCustomization = customization.widget
        const widgetColors = widgetCustomization && widgetCustomization.colors
        const headerBg = widgetColors && widgetColors.header_background
        const headerText = widgetColors && widgetColors.header_text
        const agentBg = widgetColors && widgetColors.agent_background
        const agentText = widgetColors && widgetColors.agent_text
        const visitorBg = widgetColors && widgetColors.visitor_background
        const visitorText = widgetColors && widgetColors.visitor_text

        const customizationOptions = {
            widgetColors: {
                header: {
                    background: {
                        value: 'Header & Footer Background',
                        color: headerBg || '#303030',
                    },
                    text: {
                        value: 'Header & Footer Text',
                        color: headerText || '#ffffff',
                    },
                },
                agent: {
                    background: {
                        value: 'Agent Message Background',
                        color: agentBg || '#174a8e',
                    },
                    text: {
                        value: 'Agent Message Text',
                        color: agentText || '#ffffff',
                    },
                },
                visitor: {
                    background: {
                        value: 'Visitor Message Background',
                        color: visitorBg || '#ffffff',
                    },
                    text: {
                        value: 'Visitor Message Text',
                        color: visitorText || '#4a4a4a',
                    },
                },
            },
        }

        const colorButtons = _.flatten(
            Object.keys(customizationOptions.widgetColors).map(groupKey => {
                return Object.keys(customizationOptions.widgetColors[groupKey]).map(colorKey => {
                    const colorReference = customizationOptions.widgetColors[groupKey][colorKey]
                    return { groupKey, colorKey, color: colorReference.color, value: colorReference.value }
                })
            }),
        )

        return (
            <div className="widget-colors">
                <h3>Colors:</h3>
                {colorButtons.map(customButton => {
                    const id = `custom-${customButton.groupKey}-${customButton.colorKey}`
                    const bindUpdate = updateCustomColor.bind(this, customButton.groupKey, customButton.colorKey)
                    return (
                        <ColorPickerButton
                            key={id}
                            color={customButton.color}
                            id={id}
                            label={customButton.value}
                            updateCustomColor={bindUpdate}
                        />
                    )
                })}
                <a onClick={resetColors} className="customize-widget__reset-colors" href="/#">
                    Reset colors to default
                </a>
            </div>
        )
    }
}
