import iassign from 'immutable-assign'
import _ from 'lodash'

import PendingReferralsService from '../../Api/services/PendingReferralsService'
import { LOCAL_STORAGE_KEY_CHATS_ON_SCREEN } from '../../Constants'
import { ChatCenterTab } from '../../models/enums'

import ALTERNATIVE_VERTICALS_QUESTION_LIST from './survey/AlternativeVerticalsQuestionList'
import DENTAL_QUESTION_LIST from './survey/DentalQuestionList'
import MEDICAL_QUESTION_LIST from './survey/MedicalQuestionList'
import OPTOMETRY_QUESTION_LIST from './survey/OptometryQuestionList'
import {
    ClearIdleTimer,
    ClearInactiveTimer,
    ClosePatientNameRequiredAlert,
    CreateShortcut,
    DecrementCount,
    IncrementCount,
    PatientRejoinedChat,
    ReceiveAmplifyChatMetadataSchedulingAppointments,
    ReceiveAuthKey,
    ReceiveBrowsingCount,
    ReceiveChat,
    ReceiveChatHistory,
    ReceiveChatMessage,
    ReceiveChatsOnScreen,
    ReceiveClaimedChat,
    ReceiveClaimedChats,
    ReceiveClosedChat,
    ReceiveHistoryBatch,
    ReceiveLatestChats,
    ReceiveMessagePreview,
    ReceiveShortcuts,
    ReceiveSurvey,
    ReceiveUnclaimedChats,
    RemoveShortcut,
    SetIdleTimer,
    SetInactiveTimer,
    SetPatientOnlineStatus,
    SetSelectedChat,
    SetSelectedChats,
    SetSelectedChatTab,
    ShowPatientNameRequiredAlert,
    TurnChatsPage,
    UpdateConnectPayerId,
    UpdateNetworkState,
    UpdateSelectedChat,
    UpdateShortcut,
} from './actions'

export type ChatState = {
    authKey: string | null
    browsing: number
    list: string[]
    chats: { [id: string]: Models.ChatMetadata }
    claimedChats: { [channel: string]: Models.ChatMetadata }
    conversations: { [channel: string]: Models.Chat }
    surveys: { [id: string]: Models.SurveyResponse[] }
    selectedChat: Models.ChatMetadata | null
    selectedChats: Models.ChatCenterSelectedChat[]
    selectedChatTabs: { [id: string]: ChatCenterTab }
    shortcuts: Models.Shortcut[]
    isNetworkUp: boolean
    numChatsOnScreen: number
    paginator: Models.ChatCenterPaginator
    showPatientNameRequiredAlert: { [chatId: string]: boolean }
    scheduledAppointments: { [chatId: string]: ApiV2.Amplify.SchedulingAppointment[] }
}

type ChatAction =
    | ClearIdleTimer
    | ReceiveLatestChats
    | ReceiveClaimedChats
    | ReceiveChatHistory
    | ReceiveChatMessage
    | ReceiveAuthKey
    | ReceiveMessagePreview
    | ReceiveChat
    | ReceiveClaimedChat
    | ReceiveSurvey
    | ReceiveHistoryBatch
    | SetIdleTimer
    | ReceiveClosedChat
    | ReceiveBrowsingCount
    | IncrementCount
    | DecrementCount
    | SetSelectedChat
    | SetSelectedChats
    | SetSelectedChatTab
    | UpdateSelectedChat
    | ReceiveShortcuts
    | CreateShortcut
    | UpdateShortcut
    | RemoveShortcut
    | ClearInactiveTimer
    | RemoveShortcut
    | ClearInactiveTimer
    | SetInactiveTimer
    | UpdateNetworkState
    | ReceiveUnclaimedChats
    | ReceiveChatsOnScreen
    | TurnChatsPage
    | UpdateConnectPayerId
    | PatientRejoinedChat
    | ShowPatientNameRequiredAlert
    | ClosePatientNameRequiredAlert
    | SetPatientOnlineStatus
    | ReceiveAmplifyChatMetadataSchedulingAppointments

const getNumOfChatsOnScreen = () => {
    const numOfChatsOnScreen = window.localStorage.getItem(LOCAL_STORAGE_KEY_CHATS_ON_SCREEN)
    return numOfChatsOnScreen ? Number(numOfChatsOnScreen) : 1
}

const initialState: ChatState = {
    authKey: null,
    browsing: 0,
    list: [],
    chats: {},
    claimedChats: {},
    conversations: {},
    surveys: {},
    selectedChat: null,
    selectedChats: [],
    selectedChatTabs: {},
    shortcuts: [],
    isNetworkUp: true,
    numChatsOnScreen: getNumOfChatsOnScreen(),
    paginator: {
        direction: 'current',
        prevEnabled: true,
        nextEnabled: true,
    },
    showPatientNameRequiredAlert: {},
    scheduledAppointments: {},
}

export const NO_RESPONSE_PLACEHOLDER = '<NO RESPONSE PROVIDED>'

export function mapChatMetadata(chat: Api.ChatMetadata): Models.ChatMetadata {
    return {
        id: chat.id,
        created: new Date(chat.created),
        updated: new Date(chat.updated),
        ip: chat.ip,
        channelName: chat.channel_name,
        patientName: chat.patient_name || '',
        propertyId: chat.property_id,
        practice: chat.practice,
        meta: chat.meta || {},
        claimee: chat.claimee && {
            id: chat.claimee.id,
            firstName: chat.claimee.first_name,
            lastName: chat.claimee.last_name,
        },
        status: chat.status,
        lastMessageTimestamp: new Date(chat.last_message_timestamp),
        surveyId: chat.survey_id,
        pendingReferral: chat.pending_referral
            ? PendingReferralsService.mapPendingReferral(chat.pending_referral)
            : undefined,
        webCodeUrl: chat.web_code ? chat.web_code.url : undefined,
        practiceSpecialty: chat.practice_specialty,
        firstResponseTime: chat.first_response_time,
    }
}

export function reducer(state: ChatState = initialState, action: ChatAction) {
    switch (action.type) {
        case 'RECEIVE_AUTH_KEY': {
            return iassign(
                state,
                next => next.authKey,
                next => action.authKey,
            )
        }
        case 'RECEIVE_BROWSING_COUNT': {
            return iassign(
                state,
                next => next.browsing,
                next => action.count,
            )
        }
        case 'RECEIVE_CHAT': {
            return iassign(state, next => {
                next.chats[action.chat.id] = mapChatMetadata(action.chat)
                next.list.unshift(action.chat.id)
                next.list = _.uniq(next.list)
                return next
            })
        }
        case 'RECEIVE_LATEST_CHATS': {
            return iassign(state, next => {
                if (action.chats) {
                    next.chats = _.keyBy(action.chats.map(mapChatMetadata), 'id')
                    next.list = _.map(_.orderBy(action.chats, ['lastMessageTimestamp'], ['desc']), 'id')
                }
                return next
            })
        }
        case 'RECEIVE_CLAIMED_CHAT': {
            return iassign(state, next => {
                const channel = action.chat.channel_name
                const chat = mapChatMetadata(action.chat)
                next.claimedChats = iassign(
                    next.claimedChats,
                    n => n[channel],
                    () => chat,
                )
                next.conversations = iassign(next.conversations, n => {
                    const whisper = `whisper_${channel}`
                    n[channel] = n[channel] || { messages: [] }
                    n[whisper] = n[whisper] || { messages: [] }
                    return n
                })
                return next
            })
        }
        case 'RECEIVE_AMPLIFY_CHAT_METADATA_SCHEDULING_APPOINTMENTS': {
            return iassign(
                state,
                next => next.chats,
                nextChats => {
                    if (nextChats[action.chatId]) {
                        nextChats[action.chatId].schedulingAppointments = action.appointments
                    }

                    return nextChats
                },
            )
        }
        case 'RECEIVE_CLAIMED_CHATS': {
            return iassign(state, next => {
                const chats = _.chain(action.chats.map(mapChatMetadata))
                    .orderBy(['lastMessageTimestamp'], ['desc'])
                    .keyBy('channelName')
                    .value()

                next.claimedChats = chats
                next.conversations = _.fromPairs(
                    _.flatMap(chats, chat => [
                        [chat.channelName, { messages: [] }],
                        [`whisper_${chat.channelName}`, { messages: [] }],
                    ]),
                )
                return next
            })
        }
        case 'RECEIVE_UNCLAIMED_CHATS': {
            return iassign(state, next => {
                action.chats.forEach(chat => {
                    next.chats[chat.id] = mapChatMetadata(chat)
                    next.list.unshift(chat.id)
                })
                next.list = _.uniq(next.list)

                return next
            })
        }
        case 'RECEIVE_CLOSED_CHAT': {
            return iassign(
                state,
                next => next.claimedChats,
                next => {
                    delete next[action.chatChannelName]
                    return next
                },
            )
        }
        case 'RECEIVE_CHAT_HISTORY': {
            return iassign(
                state,
                next => next.conversations[action.channelName],
                next => {
                    const history = action.history.messages.map(
                        m =>
                            ({
                                ...m.entry,
                                timetoken: m.timetoken,
                            } as Models.Message),
                    )
                    if (next) {
                        return {
                            ...next,
                            messages: _([...history, ...next.messages])
                                .uniqBy('id')
                                .sortBy('timetoken')
                                .value(),
                        }
                    }

                    return { messages: history }
                },
            )
        }
        case 'RECEIVE_CHAT_MESSAGE': {
            return iassign(
                state,
                n => n.conversations[action.channel],
                next => {
                    if (next) {
                        return {
                            ...next,
                            messages: next.messages.concat(action.message),
                        }
                    }

                    return {
                        messages: [action.message],
                    }
                },
            )
        }
        case 'RECEIVE_HISTORY_BATCH': {
            return iassign(
                state,
                next => next.conversations,
                next => {
                    return _.mergeWith(next, action.history.channels, (channel, history) => {
                        if (_.isArray(history)) {
                            const messages = history.map(
                                h =>
                                    ({
                                        ...h.message,
                                        timetoken: h.timetoken,
                                    } as Models.Message),
                            )
                            return { ...channel, messages }
                        }
                    })
                },
            )
        }
        case 'RECEIVE_MESSAGE_PREVIEW': {
            return iassign(
                state,
                n => n.conversations[action.chat],
                next => {
                    if (next) {
                        return {
                            ...next,
                            preview: action.preview,
                        }
                    }
                    return {
                        messages: [],
                        preview: action.preview,
                    }
                },
            )
        }
        case 'RECEIVE_SURVEY': {
            return iassign(
                state,
                next => next.surveys[action.webCodeKey],
                next => {
                    const QUESTION_LIST = {
                        dental: DENTAL_QUESTION_LIST,
                        optometry: OPTOMETRY_QUESTION_LIST,
                        medical: MEDICAL_QUESTION_LIST,
                        alternative_verticals: ALTERNATIVE_VERTICALS_QUESTION_LIST,
                    }

                    const responses = _.keyBy(action.responses, 'id')
                    const questionList = QUESTION_LIST[action.practiceSpecialtyValue].map(q => {
                        const answers = _.has(responses, q.id) ? responses[q.id].answers : [NO_RESPONSE_PLACEHOLDER]
                        return {
                            id: q.id,
                            question: q.question,
                            htmlClass: q.htmlClass,
                            rawQuestionAnswer: `${q.questionRaw} ${answers.join(' ')}`,
                            answers,
                        }
                    })

                    return questionList.filter(question => {
                        if (question.id === 'connectHours' && question.answers[0] === NO_RESPONSE_PLACEHOLDER) {
                            return false
                        }
                        return true
                    })
                },
            )
        }
        case 'RECEIVE_SHORTCUTS': {
            return iassign(
                state,
                next => next.shortcuts,
                () => _.sortBy(action.shortcuts, 'name'),
            )
        }
        case 'CREATE_SHORTCUT': {
            return iassign(
                state,
                next => next.shortcuts,
                next => {
                    const { shortcut } = action
                    next.push(shortcut)

                    return _.sortBy(next, 'name')
                },
            )
        }
        case 'UPDATE_SHORTCUT': {
            return iassign(
                state,
                next => next.shortcuts,
                next => {
                    const { shortcut } = action
                    const idx = next.findIndex(c => c.id === shortcut.id)
                    next[idx] = shortcut

                    return _.sortBy(next, 'name')
                },
            )
        }
        case 'REMOVE_SHORTCUT': {
            return iassign(
                state,
                next => next.shortcuts,
                next => {
                    return _.filter(next, r => r.id !== action.shortcut.id)
                },
            )
        }
        case 'SET_IDLE_TIMER': {
            return iassign(
                state,
                next => next.conversations[action.chat.channelName],
                next => {
                    if (!next) {
                        return next
                    }

                    if (next.idleTimer) {
                        window.clearTimeout(next.idleTimer)
                    }

                    next.idleTimer = action.idleTimer
                    return next
                },
            )
        }
        case 'SET_INACTIVE_TIMER': {
            return iassign(
                state,
                next => next.conversations[action.chat.channelName],
                next => {
                    if (!next) {
                        return next
                    }

                    if (next.inactiveTimer) {
                        window.clearTimeout(next.inactiveTimer)
                    }

                    next.inactiveTimer = action.inactiveTimer
                    return next
                },
            )
        }
        case 'CLEAR_IDLE_TIMER': {
            return iassign(
                state,
                next => next.conversations[action.channel],
                next => {
                    if (!next) {
                        return next
                    }

                    if (next.idleTimer) {
                        window.clearTimeout(next.idleTimer)
                        delete next.idleTimer
                    }

                    return next
                },
            )
        }
        case 'CLEAR_INACTIVE_TIMER': {
            return iassign(
                state,
                next => next.conversations[action.channel],
                next => {
                    if (!next) {
                        return next
                    }

                    if (next.inactiveTimer) {
                        window.clearTimeout(next.inactiveTimer)
                        delete next.inactiveTimer
                    }

                    return next
                },
            )
        }
        case 'INCREMENT_BROWSING_COUNT': {
            return iassign(
                state,
                next => next.browsing,
                next => next + 1,
            )
        }
        case 'DECREMENT_BROWSING_COUNT': {
            return iassign(
                state,
                next => next.browsing,
                next => next - 1,
            )
        }
        case 'SET_SELECTED_CHAT': {
            return iassign(state, next => {
                next.selectedChat = action.chat
                return next
            })
        }
        case 'SET_SELECTED_CHATS': {
            return iassign(state, next => {
                next.selectedChats = action.chats
                return next
            })
        }
        case 'UPDATE_SELECTED_CHAT': {
            return iassign(
                state,
                next => next.selectedChats,
                nextSelectedChats => {
                    const idx = nextSelectedChats.findIndex(({ id }) => id === action.chatUpdate.id)

                    if (idx !== -1) {
                        nextSelectedChats[idx] = { ...nextSelectedChats[idx], ...action.chatUpdate }
                    }

                    return nextSelectedChats
                },
            )
        }
        case 'SET_PATIENT_ONLINE_STATUS': {
            return iassign(
                state,
                next => next.selectedChats,
                nextSelectedChats => {
                    const chat = nextSelectedChats.find(chat => chat.id === action.chat.id)

                    if (chat) {
                        chat.isPatientOnline = action.isOnline
                    }

                    return nextSelectedChats
                },
            )
        }
        case 'PATIENT_REJOINED_CHAT': {
            return iassign(
                state,
                next => next.selectedChats,
                nextSelectedChats => {
                    const chat = nextSelectedChats.find(chat => chat.id === action.chat.id)

                    if (chat) {
                        chat.patientRejoinedChatCounter++
                    }

                    return nextSelectedChats
                },
            )
        }

        case 'UPDATE_NETWORK_STATE': {
            return iassign(state, next => {
                next.isNetworkUp = action.isNetworkUp
                return next
            })
        }
        case 'RECEIVE_CHATS_ON_SCREEN': {
            return iassign(
                state,
                next => next.numChatsOnScreen,
                next => {
                    next = action.numChatsOnScreen
                    return next
                },
            )
        }
        case 'TURN_CHATS_PAGE': {
            return iassign(
                state,
                next => next.paginator,
                nextPaginator => {
                    nextPaginator = { ...nextPaginator, ...action.paginator }
                    return nextPaginator
                },
            )
        }
        case 'UPDATE_CHAT_CONNECT_PAYER_ID': {
            const updatedChatsState = iassign(
                state,
                next => next.chats,
                nextChats => {
                    if (nextChats[action.chat.id]) {
                        nextChats[action.chat.id].connectPayerId = action.payerId
                    }
                    return nextChats
                },
            )

            return iassign(
                updatedChatsState,
                next => next.claimedChats,
                nextClaimedChats => {
                    if (nextClaimedChats[action.chat.channelName]) {
                        nextClaimedChats[action.chat.channelName].connectPayerId = action.payerId
                    }
                    return nextClaimedChats
                },
            )
        }
        case 'SET_SELECTED_CHAT_TAB': {
            return iassign(
                state,
                next => next.selectedChatTabs,
                nextSelectedChatTabs => {
                    nextSelectedChatTabs[action.chatId] = action.tab

                    return nextSelectedChatTabs
                },
            )
        }
        case 'SHOW_PATIENT_NAME_REQUIRED_ALERT': {
            return iassign(
                state,
                next => next.showPatientNameRequiredAlert,
                nextShowPatientNameRequiredAlert => {
                    nextShowPatientNameRequiredAlert[action.chatId] = true
                    return nextShowPatientNameRequiredAlert
                },
            )
        }
        case 'CLOSE_PATIENT_NAME_REQUIRED_ALERT': {
            return iassign(
                state,
                next => next.showPatientNameRequiredAlert,
                nextShowPatientNameRequiredAlert => {
                    nextShowPatientNameRequiredAlert[action.chatId] = false
                    return nextShowPatientNameRequiredAlert
                },
            )
        }
        default:
            return state
    }
}
