import * as React from 'react'

export default function rightSocket() {
    // tslint:disable
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="399" height="73" viewBox="0 0 399 73">
            <g fill="none" fillRule="evenodd">
                <path fill="#3DA50C" d="M92 43h307V31H92z" />
                <path fill="#2B7704" d="M95.31 30.981h12.982c2.53 4.393 1.97 8.196 0 12H95.31v-12z" />
                <path fill="#3DA50C" d="M45.776 73c28.3 0 51.243-16.342 51.243-36.5S74.076 0 45.776 0" />
                <path
                    fill="#B8E986"
                    d="M6.832 36.5c0 20.158 16.366 36.5 36.553 36.5 20.187 0 36.553-16.342 36.553-36.5S63.572 0 43.385 0C23.198 0 6.832 16.342 6.832 36.5"
                />
                <path
                    fill="#000"
                    d="M30.81 18.42H4.377C1.97 18.42 0 20.388 0 22.792v.81c0 2.404 1.97 4.371 4.377 4.371H30.81c2.407 0 4.376-1.967 4.376-4.37v-.811c0-2.404-1.97-4.37-4.376-4.37M30.81 45.028H4.377C1.97 45.028 0 46.994 0 49.398v.811c0 2.404 1.97 4.37 4.377 4.37H30.81c2.407 0 4.376-1.966 4.376-4.37v-.81c0-2.405-1.97-4.371-4.376-4.371M52.98 32.065h-6.208c-2.238 0-4.07 1.83-4.07 4.065v1.423c0 2.235 1.832 4.064 4.07 4.064h6.208c2.238 0 4.07-1.83 4.07-4.064V36.13c0-2.235-1.832-4.065-4.07-4.065"
                />
            </g>
        </svg>
    )
}
