import * as React from 'react'
import { ColorChangeHandler, CustomPicker } from 'react-color'
import { EditableInput, Hue, Saturation } from 'react-color/lib/components/common'
import Button from '@mui/material/Button'

import './ColorPicker.sass'

export type ColorPickerProps = {
    cancelColorPicker: () => void
    hex?: string
    onChange: ColorChangeHandler
    rgb?: any
    updateColor: () => void
}

export type ColorPickerState = {}

interface RGBColors {
    b?: string
    g?: string
    r?: string
}

export type CustomSwatchStyles = {
    background?: string
}

class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    handleRGBChange = (RGBColor: RGBColors) => {
        const { onChange, rgb } = this.props
        const RGBKey = Object.keys(RGBColor)[0]
        const RGBInputValue = parseInt(RGBColor[RGBKey], 10)
        const isValidRGB = RGBInputValue >= 0 && RGBInputValue <= 255

        if (!isValidRGB) {
            return
        }

        const newColor = { ...rgb }
        newColor[RGBKey] = String(RGBInputValue)

        onChange(newColor)
    }

    render() {
        const { cancelColorPicker, hex, onChange, rgb, updateColor } = this.props

        const swatchStyles: CustomSwatchStyles = {
            background: hex,
        }

        return (
            <div className="color-picker">
                <div className="saturation-picker">
                    <Saturation {...this.props} onChange={onChange} />
                </div>
                <div className="hue-picker">
                    <Hue {...this.props} onChange={onChange} />
                </div>
                <div className="hex-field">
                    <EditableInput value={hex} onChange={onChange} />
                </div>
                <div className="rgb-fields">
                    <EditableInput
                        label="r"
                        value={rgb.r}
                        onChange={RGBColor => {
                            this.handleRGBChange(RGBColor as RGBColors)
                        }}
                    />
                    <EditableInput
                        label="g"
                        value={rgb.g}
                        onChange={RGBColor => {
                            this.handleRGBChange(RGBColor as RGBColors)
                        }}
                    />
                    <EditableInput
                        label="b"
                        value={rgb.b}
                        onChange={RGBColor => {
                            this.handleRGBChange(RGBColor as RGBColors)
                        }}
                    />
                </div>
                <div className="picker-actions">
                    <Button color="primary" className="update" variant="outlined" onClick={updateColor}>
                        Update
                    </Button>
                    <Button className="cancel" variant="text" onClick={cancelColorPicker}>
                        Cancel
                    </Button>
                </div>
                <div style={swatchStyles} />
            </div>
        )
    }
}

export default CustomPicker(ColorPicker)
