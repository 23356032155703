import React from 'react'
import classNames from 'classnames'

import './CustomToggle.sass'

interface Props {
    className?: string
    options: Array<{
        disabled?: boolean
        onClick: (event: React.MouseEvent<HTMLElement>) => void
        selected: boolean
        text: string
    }>
    useTextInClassNames?: boolean
}

export default function CustomToggle(props: Props) {
    return (
        <div className={classNames('custom-toggle-wrapper', props.className)}>
            {props.options.map((o, index) => (
                <button
                    key={index}
                    className={classNames('custom-toggle', {
                        'custom-toggle-selected': o.selected,
                        [`custom-toggle-${o.text.toLowerCase()}`]: props.useTextInClassNames === true,
                    })}
                    onClick={o.onClick}
                    disabled={o.disabled === true}
                >
                    {o.text}
                </button>
            ))}
        </div>
    )
}

/**
 * USAGE EXAMPLE:
 *      <CustomToggle
            options={[
                {
                    text: 'One',
                    onClick: () => {},
                    selected: true,
                },
                {
                    text: 'Two',
                    onClick: () => {},
                    selected: false,
                },
            ]}
        />
 */
