import * as React from 'react'

import './Alert.sass'

type Props = {
    message: string
}

const moduleName = 'survey-alert-container'

const Alert = ({ message }: Props) => {
    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__alert`}>
                <i className={`material-icons ${moduleName}__icon-red`}>error</i>
                <span className={`${moduleName}__message`}>{message}</span>
            </div>
        </div>
    )
}

export default Alert
