import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import './ChatterAlertExpiryDateNotification.sass'

type ChatterAlertExpiryDateNotificationProps = {
    expirationDate: string | null
    show: boolean | undefined
}

type Props = ChatterAlertExpiryDateNotificationProps

const moduleName = 'chatter-alert-expiry-date-notification'

class ChatterAlertExpiryDateNotification extends React.PureComponent<Props> {
    render() {
        const { expirationDate, show } = this.props

        return (
            <CSSTransition
                in={show}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={3000}
                classNames={classNames(`${moduleName}__transition-container`)}
            >
                <div>
                    <div
                        className={classNames(
                            `${moduleName}__container${!expirationDate ? ` ${moduleName}--red-container` : ``}`,
                        )}
                    >
                        {expirationDate ? (
                            <>
                                <i className={classNames('material-icons', `${moduleName}__check-mark-icon`)}>
                                    check_circle
                                </i>
                                <div>
                                    Expiration for your chatter alert has been saved. This alert will expire on{' '}
                                    {expirationDate} at 11:59 PM.
                                </div>
                            </>
                        ) : (
                            <>
                                <i className={classNames('material-icons', `${moduleName}__remove-icon`)}>
                                    remove_circle
                                </i>
                                <div>
                                    Expiration for your chatter alert has been saved. Expiration date has been removed.
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

export default ChatterAlertExpiryDateNotification
