import * as React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import WidgetPlacementSVGIcon from './WidgetPlacementSVGIcon'

import './WidgetIconPlacement.sass'

export type WidgetIconPlacementProps = {
    customValue?: Api.widgetIconPosition | boolean
    updateIconPlacement: (iconPlacement: Api.widgetIconPosition) => void
}

export type WidgetIconPlacementState = {}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

export default class WidgetIconPlacement extends React.Component<WidgetIconPlacementProps, WidgetIconPlacementState> {
    updateIconPlacement = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateIconPlacement(e.target.value as Api.widgetIconPosition)
    }

    render() {
        const { customValue } = this.props
        const radioValue = customValue || 'lower-right'
        const radioClassOverrides: RadioClassOverrides = {
            root: 'radio-root',
        }

        return (
            <div className="widget-icon-placement">
                <h3>Placement:</h3>
                <div className="radio-wrapper">
                    <Radio
                        checked={radioValue === 'lower-right'}
                        onChange={this.updateIconPlacement}
                        name="radio-widget-placement"
                        value="lower-right"
                        id="icon-lower-right"
                        classes={radioClassOverrides}
                    />
                    <div className="widget-icon-wrapper widget-lower-right">
                        <label htmlFor="icon-lower-right">
                            <span className="icon-text">Lower Right</span>
                            <WidgetPlacementSVGIcon />
                        </label>
                    </div>
                </div>
                <div className="radio-wrapper">
                    <Radio
                        checked={radioValue === 'lower-left'}
                        onChange={this.updateIconPlacement}
                        name="radio-widget-placement"
                        value="lower-left"
                        id="icon-lower-left"
                        classes={radioClassOverrides}
                    />
                    <div className="widget-icon-wrapper widget-lower-left">
                        <label htmlFor="icon-lower-left">
                            <span className="icon-text">Lower Left</span>
                            <WidgetPlacementSVGIcon />
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}
