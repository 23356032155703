import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import TimePicker from './TimePicker'
import TimePickerInput from './TimePickerInput'

import './CustomTimePicker.sass'

export const formatTime = (time: moment.Moment | undefined, format: string) => {
    if (time === undefined) return ''
    return moment(time).format(format)
}

export const TimePickerDefaults = {
    timeFormat: 'h:mm A',
}

interface TimePickerProps {
    timeFormat?: string
    value?: moment.Moment | string
    label?: string
    error?: boolean
    errorMessage?: string
    className?: string
    placeholder?: string
    onChange: (rawValue: string) => void
}

export interface State {
    time?: moment.Moment
    inputValue: string
    selected: moment.Moment[]
    isOpen: boolean
}

export type Props = TimePickerProps

const CustomTimePicker = ({
    value,
    timeFormat,
    placeholder,
    onChange,
    errorMessage,
    error,
    label,
    className,
}: Props) => {
    const getTimeFormat = useCallback(() => {
        if (!timeFormat) {
            return TimePickerDefaults.timeFormat
        }
        return timeFormat
    }, [timeFormat])

    const initialTime = value ? moment(value, getTimeFormat()) : moment()

    const [time, setTime] = useState<moment.Moment>(initialTime)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        setTime(moment(value, getTimeFormat()))
    }, [setTime, getTimeFormat, value])

    const handleTimeChange = (selectedTime: moment.Moment) => {
        setTime(selectedTime)
        const newInputValue = selectedTime.format(getTimeFormat())
        onChange(newInputValue)
    }

    const handleIsOpen = (open: boolean) => {
        setIsOpen(open)
    }

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value
        onChange(newValue)
    }

    const renderInputComponent = (): JSX.Element => {
        const inputValue = value ? time.format(TimePickerDefaults.timeFormat) : placeholder

        const inputProps = {
            selected: isOpen,
            onChange: handleInputChange,
            value: inputValue,
            error: error,
            errorMessage: errorMessage,
        }
        return <TimePickerInput {...inputProps} />
    }

    return (
        <TimePicker
            renderTrigger={renderInputComponent}
            handleTimeChange={handleTimeChange}
            className={className}
            label={label}
            error={error}
            setOpen={handleIsOpen}
            time={time}
            placeholder={placeholder}
        />
    )
}

export default CustomTimePicker
