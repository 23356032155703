import moment from 'moment'

import { FormFieldElement } from '../modules/shared/form-validator/validator'

import { PatientType } from './enums'

export enum BookingStep {
    SEARCH = 100,
    SEARCH_NO_RESULTS = 110,
    SEARCH_RESULTS = 120,
    NONE = 150,
    PICKER = 200,
    ERROR = 210,
    BOOKED = 220,
    SHARED = 230,
}

export enum Gender {
    MALE = 'M',
    FEMALE = 'F',
}

export interface NewPatientFormElements {
    firstName: FormFieldElement
    lastName: FormFieldElement
    dateOfBirth: FormFieldElement
    mobilePhone: FormFieldElement
    gender: FormFieldElement
    email: FormFieldElement
    location: FormFieldElement
    procedure: FormFieldElement
    provider: FormFieldElement
}

export interface NewPatientBookingForm {
    formElements: NewPatientFormElements
    isFormValid: boolean
    isFormDirty: boolean
    isPending: boolean
    bookingStep: BookingStep
    errorMessage?: string
    appointmentPicker: AppointmentPicker
}

export interface SearchPatientFormElements {
    firstName: FormFieldElement
    lastName: FormFieldElement
    dateOfBirth: FormFieldElement
    location: FormFieldElement
}

export interface SearchPatientForm {
    formElements: SearchPatientFormElements
    isFormValid: boolean
    isFormDirty: boolean
    isPending: boolean
    patients: Models.Patient[]
    selectedPatient?: Models.Patient
}

export interface ExistingPatientFormElements {
    mobilePhone: FormFieldElement
    email: FormFieldElement
    procedure: FormFieldElement
    location: FormFieldElement
    provider: FormFieldElement
}

export interface ExistingPatientBookingForm {
    formElements: ExistingPatientFormElements
    patientId?: string
    isFormValid: boolean
    isFormDirty: boolean
    isPending: boolean
    bookingStep: BookingStep
    errorMessage?: string
    appointmentPicker: AppointmentPicker
}

export interface AppointmentPicker {
    selectedDate: moment.Moment
    selectedDatetime?: moment.Moment
    errorMessage?: string
}

export interface Booking {
    chat: Models.ChatMetadata
    isAdditionalPatientBooking: boolean
    open: boolean
    selectedTab: PatientType
    newPatient: NewPatientBookingForm
    socketConnected: boolean
    appointment?: Models.Appointment
    existingPatient: ExistingPatientBookingForm
    searchPatients: SearchPatientForm
    channelId?: string
}

export type BookingMap = Record<string /* chatId */, Booking>

export type PatientSearchCriteria = {
    firstName: string
    lastName: string
    dateOfBirth: string
    locationId: string
}

export interface PatientDraft {
    first_name: string
    last_name: string
    birth_date: string
    middie_initial?: string
    mobile_phone: string
    email_address: string
    gender?: Gender
    home_phone?: string
}

export interface PatientUpdate {
    id: string
    mobile_phone: string
    email_address: string
}

export interface NewPatientAppointmentBooking {
    datetime: Date
    patient: PatientDraft
    chat_id: string
    transactionId: string
    providerId?: string
}

export interface ExistingPatientAppointmentBooking {
    datetime: Date
    patient: PatientUpdate
    chat_id: string
    transactionId: string
    providerId?: string
}
