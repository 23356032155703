import * as scclient from 'socketcluster-client'

interface ConnectParams {
    token: string
    onReconnectCallback: () => void
    onDisconnectCallback: () => void
}

type EventType = 'amplify_referral'

interface EventConfig {
    dispatch(): void
    fallbackInterval: number
    skipFirst: boolean
    fallbackIntervalId: number
    persistentIntervalId: number
}

export default class SimplifeyeSocket {
    socket: any = null
    token: string = ''
    eventConfigs = {}
    usePolling = false
    channel: any = null
    onReconnectCallback = () => {}
    onDisconnectCallback = () => {}
    numOfConnectEvents = 0

    connect({ token, onReconnectCallback, onDisconnectCallback }: ConnectParams) {
        this.token = token
        this.onReconnectCallback = onReconnectCallback
        this.onDisconnectCallback = onDisconnectCallback

        this.socket = scclient.create({
            host: process.env.REACT_APP_WEBSOCKET_DOMAIN,
            secure: process.env.REACT_APP_SECURE_WEBSOCKET_DOMAIN === 'true',
            autoConnect: false,
            autoReconnect: true,
            autoReconnectOptions: {
                initialDelay: 3000, //milliseconds
                randomness: 1000, //milliseconds
                multiplier: 1.5, //decimal
                maxDelay: 10000, //milliseconds
            },
        })

        this.onConnecting()
        this.onConnect()
        this.onDisconnect()
        this.onKickOut()
        this.onConnectAbort()
        this.onError()
        this.onAuthenticate()
        this.onSubscribe()

        this.socket.connect()
    }

    async auth() {
        try {
            if (this.socket.authState === this.socket.UNAUTHENTICATED) {
                await this.socket.authenticate(this.token)
            }
            await this.subscribe()
        } catch (error) {
            console.error(error)
            this.usePolling = true
        }
    }

    // invokes dispatcher on first registration
    register(type: EventType, config: EventConfig) {
        const alreadyRegistered = this.eventConfigs[type] != null

        if (alreadyRegistered) {
            return
        }

        this.eventConfigs[type] = {
            dispatch: config.dispatch,
            fallbackInterval: config.fallbackInterval,
            persistentInterval: null,
        }

        if (!config.skipFirst) {
            config.dispatch()
        }
        this.fallbackPolling(type)
        this.persistentPolling(type)
    }

    // on logout, clear out everything and kill the socket
    disconnect() {
        if (!this.socket) {
            return
        }

        this.channel.kill()

        this.socket.deauthenticate()

        this.socket.disconnect()

        this.eventConfigs = {}
        this.socket = null
        this.token = ''
        this.usePolling = false
    }

    handleManifest(data: any) {
        const polling = data.polling

        if (!polling) {
            return
        }

        this.usePolling = polling.force_polling

        for (let i = 0; i < polling.intervals.length; i++) {
            const type = polling.intervals[i].type
            const eventConfig = this.eventConfigs[type]

            if (!eventConfig) {
                continue
            }

            const previousFallbackInterval = eventConfig.fallbackInterval
            const previousPersistentInterval = eventConfig.persistentInterval

            eventConfig.fallbackInterval = polling.intervals[i].fallback
            eventConfig.persistentInterval = polling.intervals[i].persistent

            if (previousFallbackInterval !== polling.intervals[i].fallback) {
                console.log(
                    'Fallback polling cleared and restarted',
                    previousFallbackInterval,
                    polling.intervals[i].fallback,
                )
                clearTimeout(eventConfig.fallbackIntervalId)
                this.fallbackPolling(type)
            }
            if (previousPersistentInterval !== polling.intervals[i].persistent) {
                console.log(
                    'Persistent polling cleared and restarted',
                    previousPersistentInterval,
                    polling.intervals[i].persistent,
                )
                clearTimeout(eventConfig.persistentIntervalId)
                this.persistentPolling(type)
            }
        }
    }

    private async onConnect() {
        for await (let event of this.socket.listener('connect')) {
            console.log(`Socket ${event.id} is connected`)
            this.auth()
            this.usePolling = false
            this.numOfConnectEvents++
            if (this.numOfConnectEvents > 1) {
                this.onReconnectCallback()
            }
        }
    }

    private async onConnecting() {
        for await (let event of this.socket.listener('connecting')) {
            console.log(`Websocket client is connecting`)
        }
    }

    private async onDisconnect() {
        for await (let { code, reason } of this.socket.listener('disconnect')) {
            console.log(`Socket is disconnected with code ${code} and reason ${reason}.`)
            this.usePolling = true
            this.channel.kill()
            this.onDisconnectCallback()
        }
    }

    private async onConnectAbort() {
        for await (let { code, reason } of this.socket.listener('connectAbort')) {
            console.log(`Socket connection is aborted with code ${code} and reason ${reason}.`)
            this.usePolling = true
            this.channel?.kill()
            this.onDisconnectCallback()
        }
    }

    private async onError() {
        for await (let { error } of this.socket.listener('error')) {
            console.error(`Webscoket Socket error ${error.message}.`)
            this.usePolling = true
        }
    }

    private async onAuthenticate() {
        for await (let event of this.socket.listener('authenticate')) {
            console.log('Socket is authenticated')
        }
    }

    private async onSubscribe() {
        for await (let { channel } of this.socket.listener('subscribe')) {
            console.log(`Websocket client is subscribed to the channel ${channel}.`)
            this.usePolling = false
        }
    }

    private async onKickOut() {
        for await (let { channel, message } of this.socket.listener('kickOut')) {
            console.log(`Websocket client is was kicked-out from the channel ${channel} with message ${message}.`)
            this.usePolling = false
        }
    }

    private async subscribe() {
        const channelName = `pst`
        this.channel = this.socket.subscribe(channelName)
        await this.channel.listener('subscribe').once()
        this.setMessageConsumer()
    }

    private setMessageConsumer() {
        return (async () => {
            for await (let data of this.channel) {
                this.parseMessage(data)
            }
        })()
    }

    private parseMessage(payload: any) {
        if (process.env.NODE_ENV === 'development') {
            console.log('dispatching payload', payload)
        }

        const eventType = payload.type
        const data = payload.data

        const eventConfig = this.eventConfigs[eventType]
        if (eventConfig) {
            eventConfig.dispatch(data)
        } else {
            console.log('No dispatch found for event type ' + eventType)
        }
    }

    // on each interval, check if it's active because either
    // the manifest states it should be or the socket server is dead
    // if active, dispatch the registered function, else skip
    // and re-queue another cancellable invocation
    private fallbackPolling(type: EventType) {
        const eventConfig = this.eventConfigs[type]

        if (!eventConfig) {
            return
        }

        eventConfig.fallbackIntervalId = setTimeout(() => {
            if (eventConfig.fallbackInterval && this.usePolling) {
                eventConfig.dispatch()
                console.log('Fallback pooling', type, eventConfig.fallbackInterval)
            }
            this.fallbackPolling(type)
        }, eventConfig.fallbackInterval || 60 * 1000)
    }

    private persistentPolling(type: EventType) {
        const eventConfig = this.eventConfigs[type]

        if (!eventConfig) {
            return
        }

        eventConfig.persistentIntervalId = setTimeout(() => {
            if (eventConfig.persistentInterval) {
                eventConfig.dispatch()
            }
            this.persistentPolling(type)
        }, eventConfig.persistentInterval || 60 * 1000)
    }
}
