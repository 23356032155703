import moment from 'moment'

import Api from '../../../Api'
import {
    BookingStep,
    ExistingPatientAppointmentBooking,
    ExistingPatientFormElements,
    NewPatientAppointmentBooking,
    NewPatientFormElements,
    PatientSearchCriteria,
    SearchPatientFormElements,
} from '../../../models/BookingAppointment'
import { PatientType } from '../../../models/enums'

export type SetBookingError = {
    type: 'SET_BOOKING_ERROR'
    chat: Models.ChatMetadata
    tab: PatientType
    message: string | undefined
}

export type ReceiveAppointment = {
    type: 'RECEIVE_APPOINTMENT'
    chat: Models.ChatMetadata
    tab: PatientType
    appointment: Models.Appointment
}

export type ToggleDirectSchedulingModal = {
    type: 'TOGGLE_DIRECT_SCHEDULING_MODAL'
    chat: Models.ChatMetadata
    isOpened: boolean
}

export type SwitchDirectSchedulingTab = {
    type: 'SWITCH_DIRECT_SCHEDULING_TAB'
    chat: Models.ChatMetadata
    tab: PatientType
}

export type UpdateFormFields = {
    type: 'UPDATE_FORM_FIELDS'
    chat: Models.ChatMetadata
    tab: PatientType
    formElements: NewPatientFormElements | ExistingPatientFormElements
}

export type SetAppointmentBookingStep = {
    type: 'SET_APPOINTMENT_BOOKING_STEP'
    chat: Models.ChatMetadata
    tab: PatientType
    bookingStep: BookingStep
}

export type SetPending = {
    type: 'SET_BOOKING_PENDING'
    chat: Models.ChatMetadata
    tab: PatientType
    isPending: boolean
}

export type SetAppointmentDatetime = {
    type: 'SET_APPOINTMENT_DATETIME'
    chat: Models.ChatMetadata
    tab: PatientType
    datetime?: moment.Moment
}

export type ResetAppointmentForm = {
    type: 'RESET_APPOINTMENT_FORM'
    chat: Models.ChatMetadata
    isAdditionalPatientBooking: boolean
}

export type SelectAppointmentDay = {
    type: 'SELECT_APPOINTMENT_DAY'
    chat: Models.ChatMetadata
    tab: PatientType
    day: moment.Moment
}

export type SetAppointmentPickerError = {
    type: 'SET_APPOINTMENT_PICKER_ERROR'
    chat: Models.ChatMetadata
    tab: PatientType
    errorMessage?: string
}

export type UpdateSearchFormFields = {
    type: 'UPDATE_SEARCH_FORM_FIELDS'
    chat: Models.ChatMetadata
    formElements: SearchPatientFormElements
}

export type ReceiveExistingPatients = {
    type: 'RECEIVE_EXISTING_PATIENTS'
    chat: Models.ChatMetadata
    patients: Models.Patient[]
}

export type SetSearchExistingPatientsPending = {
    type: 'SET_SEARCH_EXISTING_PATIENTS_PENDING'
    chat: Models.ChatMetadata
    isPending: boolean
}

export type SelectExistingPatient = {
    type: 'SELECT_EXISTING_PATIENT'
    chat: Models.ChatMetadata
    patient: Models.Patient
}

export type ResetSearchResults = {
    type: 'RESET_SEARCH_RESULTS'
    chat: Models.ChatMetadata
}

export type ConnectToAppointmentSocket = {
    type: 'APPOINTMENT_SOCKET_CONNECT'
    chat: Models.ChatMetadata
}

export type DisconnectFromAppointmentSocket = {
    type: 'APPOINTMENT_SOCKET_DISCONNECT'
    chat: Models.ChatMetadata
}

export type AppointmentSubscribeToChannel = {
    type: 'APPOINTMENT_CHANNEL_SUBSCRIBE'
    chat: Models.ChatMetadata
    tab: PatientType
    transactionId: string
}

export type AppointmentUnsubscribeFromChannel = {
    type: 'APPOINTMENT_CHANNEL_UNSUBSCRIBE'
    chat: Models.ChatMetadata
}

export type AppointmentSetSocketConnectionStatus = {
    type: 'APPOINTMENT_SET_SOCKET_CONNECTION_STATUS'
    chat: Models.ChatMetadata
    isConnected: boolean
}

export function setBookingError(
    chat: Models.ChatMetadata,
    tab: PatientType,
    message: string | undefined,
): SetBookingError {
    return { type: 'SET_BOOKING_ERROR', chat, tab, message }
}

export function receiveAppointment(
    chat: Models.ChatMetadata,
    tab: PatientType,
    appointment: Models.Appointment,
): ReceiveAppointment {
    return {
        type: 'RECEIVE_APPOINTMENT',
        chat,
        tab,
        appointment,
    }
}

export function toggleDirectSchedulingModal(chat: Models.ChatMetadata, isOpened: boolean): ToggleDirectSchedulingModal {
    return {
        type: 'TOGGLE_DIRECT_SCHEDULING_MODAL',
        chat,
        isOpened,
    }
}

export function switchDirectSchedulingTab(chat: Models.ChatMetadata, tab: PatientType): SwitchDirectSchedulingTab {
    return {
        type: 'SWITCH_DIRECT_SCHEDULING_TAB',
        chat,
        tab,
    }
}

export function updateFormFields(
    chat: Models.ChatMetadata,
    tab: PatientType,
    formElements: NewPatientFormElements | ExistingPatientFormElements,
): UpdateFormFields {
    return {
        type: 'UPDATE_FORM_FIELDS',
        chat,
        tab,
        formElements,
    }
}

export function setAppointmentBookingStep(
    chat: Models.ChatMetadata,
    tab: PatientType,
    bookingStep: BookingStep,
): SetAppointmentBookingStep {
    return {
        type: 'SET_APPOINTMENT_BOOKING_STEP',
        chat,
        tab,
        bookingStep,
    }
}

export function setPending(chat: Models.ChatMetadata, tab: PatientType, isPending: boolean): SetPending {
    return {
        type: 'SET_BOOKING_PENDING',
        chat,
        tab,
        isPending,
    }
}

export function setAppointmentDatetime(
    chat: Models.ChatMetadata,
    tab: PatientType,
    datetime?: moment.Moment,
): SetAppointmentDatetime {
    return {
        type: 'SET_APPOINTMENT_DATETIME',
        chat,
        tab,
        datetime,
    }
}

export function resetForm(
    chat: Models.ChatMetadata,
    isAdditionalPatientBooking: boolean = false,
): ResetAppointmentForm {
    return {
        type: 'RESET_APPOINTMENT_FORM',
        chat,
        isAdditionalPatientBooking,
    }
}

export function selectAppointmentDay(
    chat: Models.ChatMetadata,
    tab: PatientType,
    day: moment.Moment,
): SelectAppointmentDay {
    return {
        type: 'SELECT_APPOINTMENT_DAY',
        chat,
        tab,
        day,
    }
}

export function setAppointmentPickerError(
    chat: Models.ChatMetadata,
    tab: PatientType,
    errorMessage?: string,
): SetAppointmentPickerError {
    return {
        type: 'SET_APPOINTMENT_PICKER_ERROR',
        chat,
        tab,
        errorMessage,
    }
}

export function updateSearchFormFields(
    chat: Models.ChatMetadata,
    formElements: SearchPatientFormElements,
): UpdateSearchFormFields {
    return {
        type: 'UPDATE_SEARCH_FORM_FIELDS',
        chat,
        formElements,
    }
}

export function receiveExistingPatients(
    chat: Models.ChatMetadata,
    patients: Models.Patient[],
): ReceiveExistingPatients {
    return {
        type: 'RECEIVE_EXISTING_PATIENTS',
        chat,
        patients,
    }
}

export function setSearchExistingPatientsPending(
    chat: Models.ChatMetadata,
    isPending: boolean,
): SetSearchExistingPatientsPending {
    return {
        type: 'SET_SEARCH_EXISTING_PATIENTS_PENDING',
        chat,
        isPending,
    }
}

export function selectExistingPatient(chat: Models.ChatMetadata, patient: Models.Patient): SelectExistingPatient {
    return {
        type: 'SELECT_EXISTING_PATIENT',
        chat,
        patient,
    }
}

export function resetSearchResults(chat: Models.ChatMetadata): ResetSearchResults {
    return {
        type: 'RESET_SEARCH_RESULTS',
        chat,
    }
}

export function connectToAppointmentSocket(chat: Models.ChatMetadata): ConnectToAppointmentSocket {
    return {
        type: 'APPOINTMENT_SOCKET_CONNECT',
        chat,
    }
}

export function disconnectFromAppointmentSocket(chat: Models.ChatMetadata): DisconnectFromAppointmentSocket {
    return {
        type: 'APPOINTMENT_SOCKET_DISCONNECT',
        chat,
    }
}

export function appointmentSubscribeToChannel(
    chat: Models.ChatMetadata,
    tab: PatientType,
    transactionId: string,
): AppointmentSubscribeToChannel {
    return {
        type: 'APPOINTMENT_CHANNEL_SUBSCRIBE',
        chat,
        tab,
        transactionId,
    }
}

export function appointmentUnsubscribeFromChannel(chat: Models.ChatMetadata): AppointmentUnsubscribeFromChannel {
    return {
        type: 'APPOINTMENT_CHANNEL_UNSUBSCRIBE',
        chat,
    }
}

export function appointmentSetSocketConnectionStatus(
    chat: Models.ChatMetadata,
    isConnected: boolean,
): AppointmentSetSocketConnectionStatus {
    return {
        type: 'APPOINTMENT_SET_SOCKET_CONNECTION_STATUS',
        chat,
        isConnected,
    }
}

export function createAppointment(
    chat: Models.ChatMetadata,
    tab: PatientType,
    location: Models.PracticeLocation,
    procedure: Models.Procedure,
    appointmentBookingPayload: NewPatientAppointmentBooking | ExistingPatientAppointmentBooking,
): any {
    return async (dispatch: any) => {
        try {
            const appointment = await Api.Patients.postAppointment(location, procedure, appointmentBookingPayload)
            await dispatch(receiveAppointment(chat, tab, appointment))
            return appointment
        } catch (err) {
            let errorMessage = 'Error'
            if (err?.status === 'error' && err?.statusCode === 422) {
                errorMessage = err?.errors[0]?.message
            }
            if (err?.status === 500) {
                errorMessage = err?.message
            }
            if (err?.statusCode === 400) {
                errorMessage = err?.errors[0]?.details || err?.errors[0]?.message
            }
            dispatch(setPending(chat, tab, false))
            dispatch(setBookingError(chat, tab, errorMessage))
            dispatch(setAppointmentBookingStep(chat, tab, BookingStep.ERROR))

            return err
        }
    }
}

export function searchExistingPatients(
    chat: Models.ChatMetadata,
    tab: PatientType,
    practice: Models.Practice,
    patientSearchCriteria: PatientSearchCriteria,
): any {
    return async (dispatch: any) => {
        try {
            await dispatch(setSearchExistingPatientsPending(chat, true))
            let patients: Models.Patient[]
            patients = await Api.Patients.getPatients(practice, patientSearchCriteria)
            await dispatch(receiveExistingPatients(chat, patients))
            await dispatch(setSearchExistingPatientsPending(chat, false))
            await dispatch(
                setAppointmentBookingStep(
                    chat,
                    tab,
                    patients.length > 0 ? BookingStep.SEARCH_RESULTS : BookingStep.SEARCH_NO_RESULTS,
                ),
            )
            return patients
        } catch (err) {
            return err
        }
    }
}
