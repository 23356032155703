import Api from '../../../../Api'
import { addLocationPaymentsTerminal, removeLocationPaymentsTerminal } from '../../actions'
import { setErrorMessage, setSuccessMessage } from '../actions'
import { TerminalModalState } from '../reducer'

import { AddTerminalRadio } from './TerminalForm'

export enum TerminalModalActionTypes {
    OPEN_TERMINAL_MODAL = '@LOCATIONS_TAB_TERMINAL_MODAL/OPEN_TERMINAL_MODAL',
    CLOSE_TERMINAL_MODAL = '@LOCATIONS_TAB_TERMINAL_MODAL/CLOSE_TERMINAL_MODAL',
    UPDATE_TERMINAL_MODAL_DATA = '@LOCATIONS_TAB_TERMINAL_MODAL/UPDATE_TERMINAL_MODAL_DATA',
}

export type OpenTerminalModal = {
    type: TerminalModalActionTypes.OPEN_TERMINAL_MODAL
    locationId: Models.PracticeLocation['id']
    practiceId: string
    terminalId: Models.LocationPaymentsTerminalInfo['id']
    nickname: Models.LocationPaymentsTerminalInfo['nickname']
    readerSerial: Models.LocationPaymentsTerminalInfo['readerSerial']
    pairingCode: Models.LocationPaymentsTerminalInfo['pairingCode']
    s700Code: Models.LocationPaymentsTerminalInfo['s700Code']
    uuid: Models.LocationPaymentsTerminalInfo['uuid']
    addTerminalRadio: AddTerminalRadio
    isView: boolean
}

export type CloseTerminalModal = {
    type: TerminalModalActionTypes.CLOSE_TERMINAL_MODAL
    practiceId: string
}

export type UpdateTerminalModalData = {
    type: TerminalModalActionTypes.UPDATE_TERMINAL_MODAL_DATA
    data: Partial<TerminalModalState>
    practiceId: string
}

export function openTerminalModal(
    locationId: Models.PracticeLocation['id'],
    practiceId: string,
    terminalId: Models.LocationPaymentsTerminalInfo['id'],
    nickname: Models.LocationPaymentsTerminalInfo['nickname'],
    readerSerial: Models.LocationPaymentsTerminalInfo['readerSerial'],
    pairingCode: Models.LocationPaymentsTerminalInfo['pairingCode'],
    s700Code: Models.LocationPaymentsTerminalInfo['s700Code'],
    uuid: Models.LocationPaymentsTerminalInfo['uuid'],
    addTerminalRadio: AddTerminalRadio,
    isView: boolean,
): OpenTerminalModal {
    return {
        type: TerminalModalActionTypes.OPEN_TERMINAL_MODAL,
        locationId,
        practiceId,
        terminalId,
        nickname,
        readerSerial,
        pairingCode,
        s700Code,
        uuid,
        addTerminalRadio,
        isView,
    }
}

export function updateTerminalModalData(
    data: Partial<TerminalModalState>,
    practiceId: string,
): UpdateTerminalModalData {
    return {
        type: TerminalModalActionTypes.UPDATE_TERMINAL_MODAL_DATA,
        data,
        practiceId,
    }
}

export function deleteTerminal(practiceId: string, locationId: string, terminalId: string, nickname: string): any {
    return async (dispatch: any) => {
        try {
            dispatch(
                updateTerminalModalData(
                    {
                        errorMessage: '',
                        isLoading: true,
                    },
                    practiceId,
                ),
            )
            await Api.Payments.deleteTerminal(practiceId, locationId, terminalId)
            dispatch(removeLocationPaymentsTerminal(practiceId, locationId, terminalId))
            dispatch(closeTerminalModal(practiceId))
            dispatch(setSuccessMessage(`Terminal "${nickname}" deleted successfully.`, practiceId))
        } catch (err) {
            dispatch(
                updateTerminalModalData(
                    {
                        errorMessage: err.message || 'Terminal deletion failed.',
                        isLoading: false,
                    },
                    practiceId,
                ),
            )
            dispatch(setErrorMessage(err.message || 'Terminal deletion failed.', practiceId))
        }
    }
}

export function createTerminal(
    practiceId: string,
    locationId: string,
    terminalData: Models.LocationPaymentsTerminalInfo,
    stripeLocationId: string | undefined,
) {
    return async (dispatch: any) => {
        try {
            dispatch(
                updateTerminalModalData(
                    {
                        errorMessage: '',
                        isLoading: true,
                    },
                    practiceId,
                ),
            )
            const newTerminal = { ...terminalData }
            let createdTerminal
            if (!terminalData.pairingCode && !terminalData.s700Code) {
                createdTerminal = await Api.Payments.createTerminal(practiceId, locationId, terminalData)
            } else {
                createdTerminal = await Api.Payments.createStripeTerminal(
                    practiceId,
                    locationId,
                    terminalData,
                    stripeLocationId,
                )
            }
            newTerminal.id = createdTerminal.data.terminal.terminalDevice.terminalId
            dispatch(addLocationPaymentsTerminal(practiceId, locationId, newTerminal))
            dispatch(closeTerminalModal(practiceId))
            dispatch(setSuccessMessage(`Terminal "${newTerminal.nickname}" created successfully.`, practiceId))
        } catch (err) {
            dispatch(
                updateTerminalModalData(
                    {
                        errorMessage: err.message || 'Failed to create new terminal.',
                        isLoading: false,
                    },
                    practiceId,
                ),
            )
            dispatch(setErrorMessage(err.message || 'Failed to create new terminal.', practiceId))
        }
    }
}

export function closeTerminalModal(practiceId: string): CloseTerminalModal {
    return {
        type: TerminalModalActionTypes.CLOSE_TERMINAL_MODAL,
        practiceId,
    }
}
