import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import classNames from 'classnames'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { ChatStatus } from '../../../models/ChatStatus'
import { AvailableHours } from '../../../models/Connect'
import { ChatCenterTab, ManageDirectScheduling } from '../../../models/enums'
import { useAsync } from '../../../util/useAsync'
import { fetchConnectSurveyList } from '../../amplify/connect/actions'
import { fetchPracticeLocations } from '../../practices/actions'
import { useResponsiveClasses } from '../../shared/custom-hooks'
import { setSelectedChatTab } from '../actions'
import useMessaging, { OnSendMessageParams } from '../chat-tiles-container-hooks/useMessaging'
import ConnectBookingForm from '../connect/ConnectBookingForm'
import { toggleDirectSchedulingModal } from '../direct-scheduling/actions'
import DirectScheduling from '../direct-scheduling/DirectScheduling'
import SurveyComponentV2 from '../survey/SurveyComponentV2'

import './ChatSurveyAndDirectScheduling.sass'

const moduleName = 'chat-survey-and-ds'

export type ChatSurveyAndDirectSchedulingProps = {
    selectedChat: Models.ChatCenterSelectedChat
    webCode: Models.WebCode
    isConnectAvailable: boolean
}

type Props = ChatSurveyAndDirectSchedulingProps

type TabItemProps = {
    tabType: ChatCenterTab
    icon: string
    children: string
    disabled?: boolean
}

export const checkConnectAvailability = (
    connectSurveyId: string | undefined,
    availableHours: AvailableHours[] | undefined,
    connectSurveyTimezone: string | undefined,
) => {
    if (!connectSurveyId || !availableHours || !connectSurveyTimezone) {
        return false
    }

    const timeToSetter = (time: string) => {
        const timeParts = time.split(':')
        return {
            hour: parseInt(timeParts[0], 10),
            minute: parseInt(timeParts[1], 10),
        }
    }

    const now = moment().tz(connectSurveyTimezone)
    let dayOfWeek = now.day() // Returns current day of week where Sun is 0 and Sat is 6
    dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1 // Making Mon to be 0 and Sun to be 6
    const todayAvailability = availableHours.find(day => day.dayOfWeek === dayOfWeek)
    if (!todayAvailability?.available) {
        return false
    }
    const startTime = now.clone().set(timeToSetter(todayAvailability.startTime))
    const endTime = now.clone().set(timeToSetter(todayAvailability.endTime))
    return now.isBetween(startTime, endTime, 'minute')
}

const ChatSurveyAndDirectScheduling = ({ selectedChat, webCode, isConnectAvailable }: Props) => {
    const selectedChatId = selectedChat.id
    const dispatch = useDispatch()
    const { onSendMessage } = useMessaging()
    const selectedChatTab = useSelector((state: RootState) => state.chat.selectedChatTabs[selectedChat.id])
    const [tab, setTab] = useState<ChatCenterTab>(selectedChatTab || ChatCenterTab.Survey)
    const practice = useSelector((state: RootState) => state.practices.practices[selectedChat.practice.id])
    const account = useSelector((state: RootState) => (state.app.self && state.app.self.account) as AdminAccount)
    const practiceLocationsByWebCode = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationsByWebCode?.[webCode?.id],
    )
    const connectSurvey = useSelector(
        (state: RootState) =>
            state?.connectSurveys?.connectSurveys?.[selectedChat.practice.id]?.find(
                connectWebCode => connectWebCode.id === webCode?.id,
            )?.connectSurvey,
    )

    const webCodeLocationIds = practiceLocationsByWebCode?.map(location => location.practice_location_id)

    const webCodePracticeLocationsHaveDirectScheduling = practice?.locations.some(location => {
        return (
            webCodeLocationIds?.includes(location.id) &&
            location.products?.find(product => product.product.value === 'direct_scheduling' && product.active === true)
        )
    })

    const practiceHasDirectSchedulingProduct = practice?.products.some(
        product => product.value === 'direct_scheduling' && product.active === true,
    )

    const hasDirectSchedulingProduct =
        webCodeLocationIds?.length > 0
            ? webCodePracticeLocationsHaveDirectScheduling
            : practiceHasDirectSchedulingProduct

    const isSchedulingEnabled =
        account.hasAccess(ManageDirectScheduling) &&
        hasDirectSchedulingProduct &&
        selectedChat.status.value !== ChatStatus.PatientClosed.value

    const { run: runFetchLocations, status: statusLocations } = useAsync()

    useEffect(() => {
        if (!connectSurvey && practice) {
            dispatch(fetchConnectSurveyList(practice))
        }
    }, [connectSurvey, dispatch, practice])

    useEffect(() => {
        if (practice) {
            runFetchLocations(dispatch(fetchPracticeLocations(practice.id)))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, runFetchLocations])

    useEffect(() => {
        dispatch(setSelectedChatTab(selectedChatId, tab))
    }, [selectedChatId, tab, dispatch])

    const { ref: containerRef } = useResponsiveClasses({
        breakpoints: {
            width: {
                [`${moduleName}--small`]: 0,
                [`${moduleName}--default`]: 280,
            },
        },
    })

    const sendMessage = (sendMessageParams: OnSendMessageParams) => {
        const { message, chatMetadata, messageMetadata } = sendMessageParams
        onSendMessage({ channel: selectedChat.channelName, message, chatMetadata, messageMetadata })
    }

    const setAnotherTab = (nextTab: ChatCenterTab) => () => {
        if (nextTab !== tab) {
            setTab(nextTab)
        }
    }

    useEffect(() => {
        if (isSchedulingEnabled) {
            setTab(ChatCenterTab.Scheduling)
        }
    }, [isSchedulingEnabled])

    useEffect(() => {
        if (isSchedulingEnabled && webCode) {
            dispatch(toggleDirectSchedulingModal(selectedChat, tab === ChatCenterTab.Scheduling))
        }
    }, [dispatch, isSchedulingEnabled, selectedChat, tab, webCode])

    const TabItem = ({ tabType, icon, children, disabled }: TabItemProps) => (
        <button
            disabled={Boolean(disabled)}
            className={classNames(
                `${moduleName}__tab`,
                tab === tabType && `${moduleName}__tab--selected`,
                disabled && `${moduleName}__tab--disabled`,
            )}
            onClick={setAnotherTab(tabType)}
        >
            <div className={`${moduleName}__tab-label`}>
                <span className={`${moduleName}__icon-wrapper`}>
                    <i className={`material-icons ${moduleName}__custom-icon`}>{icon}</i>
                </span>
                <span className={`${moduleName}__tab-name`}>{children}</span>
            </div>
        </button>
    )

    if (statusLocations === 'pending') {
        return (
            <div className={`${moduleName}__loading`}>
                <CircularProgress size={70} color="primary" variant="indeterminate" />
            </div>
        )
    }

    return (
        <div className={moduleName} ref={containerRef as React.RefObject<HTMLDivElement>}>
            <div className={`${moduleName}__tabs`}>
                <TabItem tabType={ChatCenterTab.Survey} icon="playlist_add_check">
                    Survey
                </TabItem>
                {isSchedulingEnabled && (
                    <TabItem tabType={ChatCenterTab.Scheduling} icon="today">
                        Schedule
                    </TabItem>
                )}
                {webCode?.connect?.status?.value === 'enabled' && (
                    <TabItem tabType={ChatCenterTab.Connect} icon="computer" disabled={!isConnectAvailable}>
                        Connect
                    </TabItem>
                )}
            </div>
            {tab === ChatCenterTab.Survey && (
                <div className={`${moduleName}__survey`}>
                    <SurveyComponentV2 webCodeId={webCode?.id} practiceId={selectedChat?.practice?.id} />
                </div>
            )}
            {tab === ChatCenterTab.Scheduling && (
                <div className={`${moduleName}__schedule`}>
                    <DirectScheduling chat={selectedChat} open={true} onSendMessage={sendMessage} webCode={webCode} />
                </div>
            )}
            {tab === ChatCenterTab.Connect && (
                <div className={`${moduleName}__connect`}>
                    <ConnectBookingForm chat={selectedChat} webCode={webCode} onSendMessage={sendMessage} />
                </div>
            )}
        </div>
    )
}

export default ChatSurveyAndDirectScheduling
