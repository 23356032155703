import { useState } from 'react'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { useAppDispatch } from 'util/useAppDispatch'
import { loadInvoice, savePaymentStatus } from './actions'
import { convertNumCentsToDollars } from 'util/formatCurrency'
import { mapOldStatusToNewStatusPossiblities, mapTransactionStatusToCopy } from './ChangePaymentStatusModal'

import './AdminToolsShared.sass'
import { transactionIdLabel } from './utils'

interface Props {
    onClose: () => void
    practiceId: string
}

const DEFAULT_ERROR_MESSAGE = ''

const CancelInvoiceModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [transactionId, setTransactionId] = useState('')
    const [screenIndex, setScreenIndex] = useState(0)
    const [isLoadingInvoiceData, setIsLoadingInvoiceData] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        transactionId: string
        currentStatus: string
        amount: number
    }>({
        transactionId: '',
        currentStatus: '',
        amount: 0,
    })
    const [isCancelingInvoice, setIsCancelingInvoice] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE)

    const goBackScreen = () => {
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadInvoice = async () => {
        if (isLoadingInvoiceData) {
            return
        }

        setIsLoadingInvoiceData(true)
        try {
            const invoiceData = await dispatch(loadInvoice(props.practiceId, transactionId))
            setLoadedData({
                transactionId: invoiceData.transactionId,
                currentStatus: invoiceData.status,
                amount: invoiceData.amount,
            })
            goForwardScreen()
            if (mapOldStatusToNewStatusPossiblities[invoiceData.status].indexOf('cancelled') === -1) {
                setErrorMessage(
                    `Invoice with status "${mapTransactionStatusToCopy(invoiceData.status)}" cannot be canceled.`,
                )
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsLoadingInvoiceData(false)
    }

    const onSubmitCancelInvoice = async () => {
        if (isCancelingInvoice) {
            return
        }
        setIsCancelingInvoice(true)
        try {
            const statusData = await dispatch(
                savePaymentStatus(loadedData.transactionId, loadedData.currentStatus, 'cancelled'),
            )
            if (statusData === true) {
                goForwardScreen()
                setSuccessMessage(`Invoice ${loadedData.transactionId} status successfully canceled.`)
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsCancelingInvoice(false)
    }

    return (
        <DefaultModal
            title="Cancel Sent Invoice"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row admin-tools-row--narrow">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={transactionIdLabel}
                                    inputType="text"
                                    value={transactionId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingInvoiceData,
                        disabled: transactionId === '',
                        onClick: onLoadInvoice,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                You are about to cancel this sent invoice. This action cannot be undone. Please confirm
                                the cancellation below.
                            </p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Invoice ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.transactionId}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Invoice Amount:</div>
                                    <div className="admin-tools-details-value">
                                        {convertNumCentsToDollars(loadedData.amount, true)}
                                    </div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Invoice Status:</div>{' '}
                                    <div className="admin-tools-details-value admin-tools-details-value--uppercase">
                                        {mapTransactionStatusToCopy(loadedData.currentStatus)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isCancelingInvoice,
                        disabled: errorMessage !== DEFAULT_ERROR_MESSAGE,
                        onClick: onSubmitCancelInvoice,
                        text: 'Cancel Invoice',
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default CancelInvoiceModal
