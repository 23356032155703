import React from 'react'
import Grid from '@mui/material/Grid'

import { PracticeLocationReschedulingConfiguration } from '../../../models/v2/Practice'

import CustomCheckbox from './CustomCheckbox'

const moduleName = 'cancel-reschedule-settings-locations-list'

type Props = {
    location: ApiV2.Practice.PracticeLocationFull
    locationSetting: PracticeLocationReschedulingConfiguration | undefined
    onUpdateSettings: (
        key: string,
        value: string | number | boolean,
        locationSetting: PracticeLocationReschedulingConfiguration | undefined,
        locationId: string,
    ) => void
    useGlobalSettingsChecked: boolean
    hasGlobalSettings: boolean
}

const ApplyGlobalSettingsCheckbox = ({
    useGlobalSettingsChecked,
    hasGlobalSettings,
    onUpdateSettings,
    locationSetting,
    location,
}: Props) => {
    return (
        <Grid
            item={true}
            xs={12}
            sm={3}
            md={2}
            lg={1}
            className={`${moduleName}__table-item details ${moduleName}__checkbox`}
            style={{ width: '15%' }}
        >
            <CustomCheckbox
                className={`${moduleName}__apply-global-settings-checkbox`}
                disabled={!hasGlobalSettings}
                checked={useGlobalSettingsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                        onUpdateSettings('use_global_settings', true, locationSetting, location.id)
                    } else {
                        onUpdateSettings('use_global_settings', false, locationSetting, location.id)
                    }
                }}
                label={
                    <>
                        Apply
                        <br /> Global Settings
                    </>
                }
            />
        </Grid>
    )
}

export default ApplyGlobalSettingsCheckbox
