import { mapWebCodeLocationsToModel, mapWebCodeLocationToModel } from '../mappers/self-scheduling/locations'
import { mapWebCodeToModel } from '../mappers/self-scheduling/webcodes'
import ApiService from '../service'

export default class SelfSchedulingService extends ApiService {
    readonly ModulePath = 'api/admin/self-scheduling/v1'
    private readonly PAGE_SIZE = 50

    async listWebCodesByPractice(practiceId: string): Promise<Models.SelfSchedulingWebCode[]> {
        const { data: webCodes } = await this.fetch(`web-codes/list/${practiceId}`)
        return webCodes.map(mapWebCodeToModel)
    }

    async getWebCodeById(id: string): Promise<Models.SelfSchedulingWebCode> {
        const { data: webCode } = await this.fetch(`web-codes/${id}`)
        return mapWebCodeToModel(webCode)
    }

    async createWebCode(createWebCode: Api.SelfScheduling.WebCodeCreate): Promise<Models.SelfSchedulingWebCode> {
        const { data: webCode } = await this.fetch(`web-codes`, {
            method: 'POST',
            body: JSON.stringify(createWebCode),
        })
        return mapWebCodeToModel(webCode)
    }

    async updateWebCode(updateWebCode: Api.SelfScheduling.WebCodeUpdate): Promise<Models.SelfSchedulingWebCode> {
        const { data: webCode } = await this.fetch(`web-codes/${updateWebCode.id}`, {
            method: 'PUT',
            body: JSON.stringify(updateWebCode),
        })
        return mapWebCodeToModel(webCode)
    }

    async searchLocationsByName(
        practiceId: string,
        webCodeId: string,
        searchTerms: Api.SelfScheduling.WebCodeLocationsSearchTerms = {},
    ): Promise<Api.StructuredResponse<Models.SelfSchedulingWebCodeLocation[]>> {
        const { limit = this.PAGE_SIZE, page, searchKey = '' } = searchTerms
        const { data: locations, paginationInfo } = (await this.fetch(
            `web-codes/${webCodeId}/location/search`,
            {},
            {
                practiceId,
                limit,
                page,
                searchKey,
            },
        )) as Api.StructuredResponse<Api.SelfScheduling.WebCodeLocation[]>

        return {
            paginationInfo,
            data: mapWebCodeLocationsToModel(locations),
        }
    }

    async updateLocation(
        updateLocation: Api.SelfScheduling.WebCodeLocationUpdate,
    ): Promise<Models.SelfSchedulingWebCodeLocation> {
        const { data: location } = await this.fetch(
            `web-codes/${updateLocation.webCodeId}/locations/${updateLocation.locationId}`,
            {
                method: 'PUT',
                body: JSON.stringify(updateLocation),
            },
        )
        return mapWebCodeLocationToModel(location)
    }

    async toggleEnableAllLocations(
        locationToggleEnableAll: Api.SelfScheduling.WebCodeLocationToggleEnableAll,
    ): Promise<void> {
        await this.fetch(`web-codes/${locationToggleEnableAll.webCodeId}/location/toggle-enable-all`, {
            method: 'POST',
            body: JSON.stringify({
                practiceId: locationToggleEnableAll.practiceId,
                enabled: locationToggleEnableAll.enabled,
            }),
        })
    }

    async countEnabledLocations(webCodeId: string): Promise<number> {
        const {
            data: { numberOfEnabledLocations },
        } = await this.fetch(`web-codes/${webCodeId}/location/count-enabled-locations`)

        return Number(numberOfEnabledLocations)
    }
}
