import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import _ from 'lodash'

import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { CustomFieldType } from '../../../models/enums'
import { receiveFormValueChange } from '../../../modules/referrals/referral-form/v2actions'
import CustomField from '../../../modules/shared/custom-fields/CustomField'
import * as htmlSpecialChars from '../../../util/htmlSpecialChars'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { filterOutGoogleQueryParams } from '../../chat-center/utils'
import { ReferralFormSections } from '../shared/enums'

import './ReferralTranscript.sass'

export type Props = {
    failedSave: boolean
    account?: AdminAccount
    patient_data?: ModelsV2.Referrals.ReferralPatient
}

const TAWKTO_SMILE_SPECIALIST_REGEX = /^\[\d\d:\d\d] (\w*) has joined the conversation/
const PATIENT_REGEX = /^[\s]*\[\d\d?:\d\d[ A-Za-z\s]{0,10}] ([\w.\-'’—íñéáóú@]*\s?[\w.\-'’—íñéáóú@]*\s?[\w.\-'’—íñéáóú@]*\s?[\w.\-'’—íñéáóú@]*):/
const PATIENT_AS_IP_ADDRESS_REGEX = /^[\s]*\[\d\d?:\d\d[a-zA-Z\s]{0,10}] (?:[0-9]{1,3}\.){3}[0-9]{1,3}:/
const IP_ADDRESS_REGEX = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}/
const TIME_REGEX = /^[\s]*\[\d\d?:\d\d[a-zA-Z\s]{0,10}]/

const ReferralTranscript = (props: Props) => {
    const { account, failedSave, patient_data } = props
    const dispatch = useAppDispatch()

    const scheduling_appointment_id = patient_data?.scheduling_appointment_id
    const referral = useSelector((state: RootState) => state.v2.referrals.referralForm.referral.value)
    const schedulingAppointment = useSelector(
        (state: RootState) =>
            scheduling_appointment_id && state.v2.referrals.schedulingAppointments[scheduling_appointment_id],
    ) as ModelsV2.Referrals.SchedulingAppointment
    const isUnsucessfull = schedulingAppointment?.scheduling_appointment_status?.value === 'sync_unsuccessful'
    const transcript =
        (referral &&
            referral.transcript &&
            htmlSpecialChars.decode(referral.transcript.replace('Direct Scheduling', 'Self-Scheduling'))) ||
        ''

    const [transcriptInEditMode, setTranscriptInEditMode] = useState(false)

    const onChange = (field: string, value: string | undefined) => {
        dispatch(receiveFormValueChange(ReferralFormSections.REFERRAL, field, value))
    }

    const handleOpenEditChatTranscript = () => {
        setTranscriptInEditMode(true)
    }

    const removeSystemMessages = (transcriptArr: string[]) => {
        const withoutSystemMessages = transcriptArr.filter(line => !line.includes('] System: ') && line !== '')

        return withoutSystemMessages.filter(line => line.includes('] ') && line.startsWith('['))
    }

    const getSmileSpecialistAndPatient = () => {
        const splittedTranscript = (referral && referral.transcript && referral.transcript.split('\n')) || ['']
        const splitTranscript = removeSystemMessages(splittedTranscript)
        let patientName = ''
        let smileSpecialistName = ''
        let smileSpecialist2Name = ''

        splitTranscript.forEach(line => {
            if (line.match(PATIENT_REGEX) || line.match(PATIENT_AS_IP_ADDRESS_REGEX)) {
                const nameString = line.split(':')[1].split('] ')[1]

                if (nameString.split(' ').length !== 1 || nameString.match(IP_ADDRESS_REGEX)) {
                    patientName = nameString
                } else {
                    if (smileSpecialistName === '') {
                        smileSpecialistName = nameString
                    } else {
                        smileSpecialist2Name = nameString
                    }
                }
            }
        })

        const tawktoSmileSpecialistLine =
            splittedTranscript && splittedTranscript.find(line => line.includes('has joined the conversation'))
        const tawktoSmileSpecialistMatch =
            tawktoSmileSpecialistLine && tawktoSmileSpecialistLine.match(TAWKTO_SMILE_SPECIALIST_REGEX)

        if (tawktoSmileSpecialistMatch && tawktoSmileSpecialistMatch[1]) {
            smileSpecialistName = tawktoSmileSpecialistMatch && `${tawktoSmileSpecialistMatch[1]} :`
        }

        return { smileSpecialist: smileSpecialistName, smileSpecialist2: smileSpecialist2Name, patient: patientName }
    }

    const { smileSpecialist, smileSpecialist2, patient } = getSmileSpecialistAndPatient()

    const isSpecialist = (text: string) => {
        if (referral.is_test && text.indexOf('Vyne Trellis: ') === 0) {
            return true
        }

        if (text.includes('] System: ') && smileSpecialist !== '') {
            return false
        }

        return text.includes(`${smileSpecialist}: `) || text.includes(`${smileSpecialist2}: `)
    }
    const isPatient = (text: string) => {
        if (referral.is_test && text.indexOf('Vyne Trellis: ') === 0) {
            return false
        }
        if (referral.is_test) {
            return text.split(':')[0].split(' ').length !== 1
        }

        return (
            !(
                text.includes('has joined the conversation') ||
                text.includes('(System Message)') ||
                text.includes('Visitor navigated to') ||
                text.includes('System: Chatter ') ||
                text.includes('System: I see ') ||
                text.includes('System: This chat has been closed') ||
                text.includes('System: Patient ')
            ) && text.includes(`${patient}: `)
        )
    }

    const getPossibleMessageType = (text: string, previousLineType: number) => {
        if (!text.match(TIME_REGEX) && previousLineType !== 0) {
            return previousLineType
        }

        return 0
    }

    const hasEditTranscriptPermission = (() => {
        // If there is no transcript yet, anybody can write it.
        if (!referral?.transcript) {
            return true
        }
        return account && account.permissions.find(permission => permission.type === 'can_edit_transcripts')
    })()

    const renderTranscript = () => {
        let filteredTranscript = transcript.split('\n')
        let previousLineType = 0
        if (isUnsucessfull) {
            filteredTranscript = filteredTranscript.filter(
                l => l !== 'This patient has scheduled their appointment through Self-Scheduling.',
            )
        }

        return (
            <div className="text">
                {filteredTranscript.map((line: string, i: number) => {
                    const lineText = line.includes('Patient Started chat on') ? filterOutGoogleQueryParams(line) : line

                    const probablyPatientLine = getPossibleMessageType(line, previousLineType) === 1
                    const probablySpecialistLine = getPossibleMessageType(line, previousLineType) === 2

                    const isPatientLine = isPatient(line) || probablyPatientLine
                    const isSpecialistLine = (isSpecialist(line) && !isPatientLine) || probablySpecialistLine

                    previousLineType = 0
                    if (isPatientLine) {
                        previousLineType = 1
                    }
                    if (isSpecialistLine) {
                        previousLineType = 2
                    }
                    return (
                        <p
                            key={i}
                            className={classNames({
                                'transcript--patient': isPatientLine,
                                'transcript--chatter': isSpecialistLine,
                            })}
                        >
                            {lineText.replace(
                                'This patient has scheduled their appointment through Self-Scheduling.',
                                'This patient has scheduled their appointment through the Self-Scheduling widget (Online Scheduling).',
                            )}
                        </p>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="transcript">
            <span className="transcript-label">
                <strong>Transcript*</strong>
                {hasEditTranscriptPermission && !transcriptInEditMode && (
                    <i onClick={handleOpenEditChatTranscript} className="material-icons transcript-edit-icon">
                        edit
                    </i>
                )}
            </span>
            {hasEditTranscriptPermission && transcriptInEditMode && (
                <CustomField
                    customFieldType={CustomFieldType.TEXTAREA}
                    placeholder="Paste chat transcript here:"
                    required={true}
                    rows={30}
                    error={failedSave && _.isEmpty(transcript)}
                    value={transcript}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const value = event.target.value
                        onChange('transcript', value)
                    }}
                />
            )}
            {(!hasEditTranscriptPermission || !transcriptInEditMode) && renderTranscript()}
        </div>
    )
}

export default ReferralTranscript
