import ApiService from '../../ApiV2/service'

export enum Dashboards {
    AMPLIFY = 'amplify',
    DIRECT_SCHEDULING = 'direct-scheduling',
    INVITE = 'invite',
}

export default class DataService extends ApiService {
    async getDashboardUrl(practiceId: string, dashboard: Dashboards = Dashboards.AMPLIFY) {
        const {
            data: { url },
        } = (await this.fetch(
            `metabase-data/${dashboard}`,
            {
                method: 'GET',
            },
            { practiceId: practiceId },
        )) as Api.StructuredResponse<{ url: string }>

        return url
    }
}
