import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { TableClassKey } from '@mui/material/Table'
import { TableCellClassKey } from '@mui/material/TableCell'
import sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

import { SurveyActivityLog } from '../../../../../models/v2/PracticeSurvey'
import EnhancedTableHead from '../../../../shared/enhanced-table-head/EnhancedTableHead'
import { fetchSurveyActivityLogs } from '../v2actions'

import './SurveyActivityLogList.sass'

interface Activity {
    id: string
    created: string
    name: string | null
    type: string
    details: string
}

type Order = 'asc' | 'desc'

type Props = {
    location: Models.PracticeLocation
    timezone?: string | undefined
}

const ACTIVITY_DATE_FORMAT = 'MM/DD/YYYY h:mm A (z)'

const moduleName = 'survey-activity-log-list'

type TableClassOverrides = {
    readonly [key in TableClassKey]?: string
}

type TableCellClassOverrides = {
    readonly [key in TableCellClassKey]?: string
}

const tableClassOverrides: TableClassOverrides = {
    root: `${moduleName}__table`,
}

const tableCellClassOverrides: TableCellClassOverrides = {
    root: `${moduleName}__table-cell`,
}

const columnLabels = [
    { id: 'created', label: 'Date', sortable: true },
    { id: 'name', label: 'Name' },
    { id: 'details', label: 'Details' },
]

const SurveyActivityLogList = (props: Props) => {
    const {
        surveyActivityLogs,
        paginationInfo,
        currentPage,
    }: {
        surveyActivityLogs: SurveyActivityLog[]
        paginationInfo: Models.PaginationInfo
        currentPage: number
    } = useSelector((state: any) => ({
        surveyActivityLogs:
            props.location.survey?.id && state.v2.practiceSurveys.activityLogsData[props.location.survey.id]
                ? state.v2.practiceSurveys.activityLogsData[props.location.survey.id]?.activityLogs
                : [],
        paginationInfo:
            props.location.survey?.id && state.v2.practiceSurveys.activityLogsData[props.location.survey.id]
                ? state.v2.practiceSurveys.activityLogsData[props.location.survey.id]?.paginationInfo
                : {},
        currentPage:
            props.location.survey?.id && state.v2.practiceSurveys.activityLogsData[props.location.survey.id]
                ? state.v2.practiceSurveys.activityLogsData[props.location.survey.id]?.currentPage
                : 1,
    }))

    const surveyId = props.location?.survey?.id || ''
    const logsFetched = surveyActivityLogs

    const timezone = props.timezone ?? moment.tz.guess()

    const [currentScrollPosition, setCurrentScrollPositions] = useState<number>(0)
    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState('')

    const dispatch = useDispatch()

    const currentPageRef = React.useRef(currentPage || 1)
    const allPagesRef = React.useRef(paginationInfo?.allPages || 0)
    const infiniteScrollDiv = useRef<HTMLDivElement>()

    useEffect(() => {
        const infiniteScrollDivCurrent = infiniteScrollDiv.current

        const loadMore = async () => {
            if (currentPageRef.current && currentPageRef.current < allPagesRef.current) {
                const searchTerms = {
                    page: currentPageRef.current + 1,
                    order,
                }
                await dispatch(fetchSurveyActivityLogs(surveyId, searchTerms))
            }
        }

        const onScroll = async (): Promise<any> => {
            if (infiniteScrollDivCurrent) {
                let th = infiniteScrollDivCurrent.scrollTop + infiniteScrollDivCurrent.clientHeight
                let c = infiniteScrollDivCurrent.scrollHeight

                if (c - th <= 1) {
                    if (infiniteScrollDivCurrent.scrollTop !== 0) {
                        setCurrentScrollPositions(infiniteScrollDivCurrent.scrollTop)
                        await loadMore()
                    }
                }
            }
        }

        if (infiniteScrollDivCurrent && logsFetched) {
            infiniteScrollDivCurrent.addEventListener('scroll', onScroll)
            infiniteScrollDivCurrent.scrollTop = currentScrollPosition
        }

        return () => {
            if (infiniteScrollDivCurrent) {
                infiniteScrollDivCurrent.removeEventListener('scroll', onScroll, false)
            }
        }
    }, [currentScrollPosition, surveyId, logsFetched, order, dispatch])

    useEffect(() => {
        const searchTerms = {
            page: 1,
            order,
        }
        dispatch(fetchSurveyActivityLogs(surveyId, searchTerms))
    }, [order, dispatch, surveyId])

    const setCurrentPageState = (data: number) => {
        currentPageRef.current = data
    }

    const setAllPagesState = (data: number) => {
        allPagesRef.current = data
    }

    useEffect(() => {
        setCurrentPageState(currentPage || 1)
        setAllPagesState(paginationInfo.allPages || 1)
    }, [currentPage, paginationInfo])

    const formatName = (firstName: string, lastName: string) => {
        if (!firstName && !lastName) {
            return 'Simplifeye'
        }
        if (!firstName) {
            return `${lastName[0]}.`
        }
        if (!lastName) {
            return firstName
        }

        return `${firstName} ${lastName}`
    }

    const getActivities = () => {
        const activityLogs = surveyActivityLogs
            ? surveyActivityLogs.map(
                  ({
                      id,
                      created,
                      createdBy,
                      type,
                      details,
                  }: {
                      id: string
                      created: Date
                      createdBy: any
                      type: string
                      details: string
                  }) => {
                      return {
                          id,
                          created: moment(created)
                              .utc()
                              .format(),
                          name: formatName(createdBy.firstName, createdBy.lastName),
                          type,
                          details,
                      }
                  },
              )
            : []

        return sortBy(activityLogs, 'created')
    }

    const surveyActivityLogsScroll = surveyActivityLogs.length > 6

    const handleRequestSort = (property: string) => {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }

    const sortActivites = (activities: Activity[], order: Order, orderBy: string) => {
        const sorted = order === 'desc' ? sortBy(activities, orderBy).reverse() : sortBy(activities, orderBy)

        return sorted
    }

    const getScrollBarWidth = () => {
        if (infiniteScrollDiv.current) {
            return infiniteScrollDiv.current.offsetWidth - infiniteScrollDiv.current.clientWidth
        }
        return 0
    }

    if (!surveyActivityLogs) {
        return <div>Loading...</div>
    }

    return (
        <Grid container={true} spacing={0} className={`${moduleName}`}>
            <Grid item={true} xs={12}>
                <div className={`${moduleName}__title`}>Activity Log</div>
            </Grid>
            <Grid item={true} xs={12}>
                <Table classes={tableClassOverrides}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        columnLabels={columnLabels}
                    />
                    <TableBody
                        ref={infiniteScrollDiv as React.RefObject<any>}
                        style={{ marginRight: getScrollBarWidth() }}
                        className={`${moduleName}__table-body`}
                    >
                        {sortActivites(getActivities(), order, orderBy).map(activity => (
                            <TableRow id={activity.id} key={activity.id} className={`${moduleName}__table-row`}>
                                <TableCell
                                    classes={tableCellClassOverrides}
                                    className={`${moduleName}__table-cell ${moduleName}__table-cell1  ${moduleName}__no-wrap-cell ${
                                        surveyActivityLogsScroll ? ` ${moduleName}__table-cell-with-scroll` : ``
                                    }`}
                                >
                                    {activity.created &&
                                        moment(activity.created)
                                            .tz(timezone)
                                            .format(ACTIVITY_DATE_FORMAT)}
                                </TableCell>
                                <TableCell
                                    classes={tableCellClassOverrides}
                                    className={`${moduleName}__middle-table-cell ${moduleName}__no-wrap-cell ${
                                        surveyActivityLogsScroll ? ` ${moduleName}__middle-with-scroll` : ``
                                    }`}
                                >
                                    {activity.name}
                                </TableCell>
                                <TableCell
                                    classes={tableCellClassOverrides}
                                    className={`${moduleName}__last-table-cell ${moduleName}__no-wrap-cell ${
                                        surveyActivityLogsScroll ? ` ${moduleName}__last-with-scroll` : ``
                                    }`}
                                >
                                    {activity.details}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default SurveyActivityLogList
