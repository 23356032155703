import * as React from 'react'
import classNames from 'classnames'

import './DexVoiceHealth.sass'

export type DexVoiceHealthProps = {
    agents: Array<Models.DexisAgent>
}

export type DexVoiceHealthDispatch = {}

export type DexVoiceHealthState = {}

type Props = DexVoiceHealthProps & DexVoiceHealthDispatch

class DexVoiceHealth extends React.PureComponent<Props, DexVoiceHealthState> {
    render() {
        const { agents } = this.props
        const healthyAgents = agents.filter(agent => !agent.failing)
        const unhealthyAgents = agents.filter(agent => agent.failing)

        return (
            <div className={classNames('dexvoice-agents-health')}>
                <div className="agent-count">{agents.length} Agents</div>
                {agents.length > 0 && (
                    <div className="graphs">
                        <div className="healthy">
                            <div
                                className="bar"
                                style={{ width: `${4 + (100 * healthyAgents.length) / agents.length}px` }}
                            />
                            <div className="label">Healthy</div>
                            <div className="count">{healthyAgents.length}</div>
                        </div>
                        <div className="unhealthy">
                            <div
                                className="bar"
                                style={{ width: `${4 + (100 * unhealthyAgents.length) / agents.length}px` }}
                            />
                            <div className="label">Unhealthy</div>
                            <div className="count">{unhealthyAgents.length}</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default DexVoiceHealth
