import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import TimeSelector from './TimeSelector'

interface Props {
    hour?: number
    minute?: number
    period?: Period
    onChange: (time: moment.Moment) => void
    onCancel: () => void
    hideContents: () => void
    preventProp?: (event: any) => void
}

export type Period = 'AM' | 'PM'

const moduleName = 'time-container'

const min = { hour: 0, minute: 0 }
const max = { hour: 11, minute: 55 }
const reduce = { hour: 1, minute: 5 }
const add = { hour: 1, minute: 5 }
const switchPeriod = { hour: 12 }

const TimeContainer = (props: Props) => {
    const [hour, setHour] = useState<number>(props.hour || 0)
    const [minute, setMinute] = useState<number>(props.minute || 0)
    const [period, setPeriod] = useState<Period>(props.period || 'AM')

    const { onChange, onCancel, hideContents } = props

    const handleHourUp = () => {
        const mappedValue = hour === switchPeriod.hour ? min.hour : hour
        let newHour = Math.min(mappedValue + add.hour, max.hour)
        if (hour === max.hour) {
            newHour = max.hour + add.hour
            setPeriod(togglePeriod(period))
        }

        if (hour === max.hour + add.hour) {
            newHour = min.hour + add.hour
        }

        setHour(newHour)
    }

    const handleHourDown = () => {
        const mappedValue = hour === min.hour + add.hour ? switchPeriod.hour + add.hour : hour
        let newHour = Math.max(mappedValue - reduce.hour, min.hour)

        if (hour === max.hour + add.hour) {
            newHour = max.hour
            setPeriod(togglePeriod(period))
        }

        if (hour === min.hour + add.hour) {
            newHour = max.hour + add.hour
        }

        setHour(newHour)
    }

    const handleMinuteUp = () => {
        let newMinute = Math.min(minute + add.minute, max.minute)

        if (minute === max.minute) {
            newMinute = min.minute
            let newHour = Math.min(hour + add.hour, max.hour)

            if (hour === max.hour) {
                newHour = max.hour + add.hour
                setPeriod(togglePeriod(period))
            }

            if (hour === max.hour + add.hour) {
                newHour = min.hour + add.hour
            }

            setHour(newHour)
        }

        setMinute(newMinute)
    }

    const handleMinuteDown = () => {
        let newMinute = Math.max(minute - reduce.minute, min.minute)

        if (minute === min.minute) {
            newMinute = max.minute
            let newHour = Math.max(hour - reduce.hour, min.hour)

            if (hour === min.hour + add.hour) {
                newHour = max.hour + add.hour
            }

            if (hour === max.hour + add.hour) {
                newHour = max.hour
                setPeriod(togglePeriod(period))
            }

            setHour(newHour)
        }

        setMinute(newMinute)
    }

    const handleCancel = () => {
        onCancel()
        hideContents()
    }

    const togglePeriod = (period: Period) => {
        return period === 'AM' ? 'PM' : 'AM'
    }

    useEffect(() => {
        const time = moment(`${hour}:${minute} ${period}`, 'h:mm A')
        onChange(time)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hour, minute, period])

    return (
        <div className={moduleName} onClick={props.preventProp}>
            <div className={`${moduleName}__time-div`}>
                <TimeSelector onUp={handleHourUp} onDown={handleHourDown} value={hour.toString()} />
                <div className={`${moduleName}__colon`}>:</div>
                <TimeSelector
                    onUp={handleMinuteUp}
                    onDown={handleMinuteDown}
                    value={minute < 10 ? `0${minute.toString()}` : minute.toString()}
                />
                <div className={`${moduleName}__am-pm`}>
                    <div
                        className={classnames(`${moduleName}__am-pm__button`, {
                            [`${moduleName}__am-pm--selected`]: period === 'AM',
                        })}
                        onClick={() => setPeriod('AM')}
                    >
                        AM
                    </div>
                    <div
                        className={classnames(`${moduleName}__am-pm__button`, {
                            [`${moduleName}__am-pm--selected`]: period === 'PM',
                        })}
                        onClick={() => setPeriod('PM')}
                    >
                        PM
                    </div>
                </div>
            </div>
            <div className={`${moduleName}__buttons-div`}>
                <div className={`${moduleName}__buttons-div__button-left`} onClick={handleCancel}>
                    CANCEL
                </div>
            </div>
        </div>
    )
}

export default TimeContainer
