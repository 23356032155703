import ApiV2 from '../../../ApiV2'
import { AppThunk } from '../../../appStore'
import {
    ConnectedPracticeLocation,
    PracticeLocationReschedulingConfiguration,
    PracticeReschedulingConfiguration,
} from '../../../models/v2/Practice'

export enum CancelRescheduleSettingsActionTypes {
    SEARCH_PRACTICE_LOCATIONS_REQUEST = '@V2CancelRescheduleSettings/SEARCH_PRACTICE_LOCATIONS_REQUEST',
    RECEIVE_PRACTICE_LOCATIONS = '@V2CancelRescheduleSettings/RECEIVE_PRACTICE_LOCATIONS',
    SET_DATE_FROM = '@V2CancelRescheduleSettings/SET_DATE_FROM',
    SET_DATE_TO = '@V2CancelRescheduleSettings/SET_DATE_TO',
    SEARCH_RESCHEDULING_CONFIGURATION_REQUEST = '@V2CancelRescheduleSettings/SEARCH_RESCHEDULING_CONFIGURATION_REQUEST',
    RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTINGS = '@V2CancelRescheduleSettings/RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTINGS',
    RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTING = '@V2CancelRescheduleSettings/RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTING',
    RECEIVE_PRACTICE_SETTINGS = '@V2CancelRescheduleSettings/RECEIVE_PRACTICE_SETTINGS',
    RECEIVE_CONNECTED_PRACTICE_LOCATIONS = '@V2CancelRescheduleSettings/RECEIVE_CONNECTED_PRACTICE_LOCATIONS',
}

export type ReceivePracticeLocations = {
    type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATIONS
    practiceId: string
    practiceLocations: ApiV2.Practice.PracticeLocationFull[]
    pagination: Models.PaginationInfo | undefined
}

export type SetDateFrom = {
    type: CancelRescheduleSettingsActionTypes.SET_DATE_FROM
    practiceId: string
    value: string
    isValid: boolean
    errorMessage: string
}

export type SetDateTo = {
    type: CancelRescheduleSettingsActionTypes.SET_DATE_TO
    practiceId: string
    value: string
    isValid: boolean
    errorMessage: string
}

export type SearchPracticeLocationReschedulingConfigurationRequest = {
    type: CancelRescheduleSettingsActionTypes.SEARCH_RESCHEDULING_CONFIGURATION_REQUEST
    practiceId: string
    page: number
    loading: boolean
}

export type ReceivePracticeLocationCancelRescheduleSettings = {
    type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTINGS
    practiceId: string
    settings: PracticeLocationReschedulingConfiguration[]
}

export type ReceivePracticeLocationCancelRescheduleSetting = {
    type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTING
    practiceId: string
    practiceLocationId: string
    setting: PracticeLocationReschedulingConfiguration[]
    isCreate: boolean
}

export type ReceivePracticeCancelRescheduleSettings = {
    type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_SETTINGS
    practiceId: string
    settings: PracticeReschedulingConfiguration
}

export type ReceiveConnectedPracticeLocations = {
    type: CancelRescheduleSettingsActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS
    practiceId: string
    connectedLocations: ConnectedPracticeLocation[]
}

export type SearchPracticeLocationsRequest = {
    type: CancelRescheduleSettingsActionTypes.SEARCH_PRACTICE_LOCATIONS_REQUEST
    practiceId: string
    page: number
    loading: boolean
}

export function receivePracticeLocations(
    practiceId: string,
    practiceLocations: ApiV2.Practice.PracticeLocationFull[],
    pagination: Models.PaginationInfo | undefined,
): ReceivePracticeLocations {
    return {
        type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATIONS,
        practiceId,
        practiceLocations,
        pagination,
    }
}

export function setDateFrom(
    practiceId: string,
    value: string,
    isValid: boolean,
    errorMessage: string = '',
): SetDateFrom {
    return {
        type: CancelRescheduleSettingsActionTypes.SET_DATE_FROM,
        practiceId,
        value,
        isValid,
        errorMessage,
    }
}

export function setDateTo(practiceId: string, value: string, isValid: boolean, errorMessage: string = ''): SetDateTo {
    return {
        type: CancelRescheduleSettingsActionTypes.SET_DATE_TO,
        practiceId,
        value,
        isValid,
        errorMessage,
    }
}

export function searchPracticeLocationReschedulingConfigurationRequest(
    practiceId: string,
    page: number,
    loading: boolean,
): SearchPracticeLocationReschedulingConfigurationRequest {
    return {
        type: CancelRescheduleSettingsActionTypes.SEARCH_RESCHEDULING_CONFIGURATION_REQUEST,
        practiceId,
        page,
        loading,
    }
}

export function receivePracticeLocationCancelRescheduleSettings(
    practiceId: string,
    settings: PracticeLocationReschedulingConfiguration[],
): ReceivePracticeLocationCancelRescheduleSettings {
    return {
        type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTINGS,
        practiceId,
        settings,
    }
}

export function receivePracticeLocationCancelRescheduleSetting(
    practiceId: string,
    practiceLocationId: string,
    setting: PracticeLocationReschedulingConfiguration[],
    isCreate: boolean = false,
): ReceivePracticeLocationCancelRescheduleSetting {
    return {
        type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_LOCATION_CANCEL_RESCHEDULE_SETTING,
        practiceId,
        practiceLocationId,
        setting,
        isCreate,
    }
}

export function receivePracticeCancelRescheduleSettings(
    practiceId: string,
    settings: PracticeReschedulingConfiguration,
): ReceivePracticeCancelRescheduleSettings {
    return {
        type: CancelRescheduleSettingsActionTypes.RECEIVE_PRACTICE_SETTINGS,
        practiceId,
        settings,
    }
}

export function receiveConnectedPracticeLocations(
    practiceId: string,
    connectedLocations: ConnectedPracticeLocation[],
): ReceiveConnectedPracticeLocations {
    return {
        type: CancelRescheduleSettingsActionTypes.RECEIVE_CONNECTED_PRACTICE_LOCATIONS,
        practiceId,
        connectedLocations,
    }
}

export function searchPracticeLocationsRequest(
    practiceId: string,
    page: number,
    loading: boolean,
): SearchPracticeLocationsRequest {
    return {
        type: CancelRescheduleSettingsActionTypes.SEARCH_PRACTICE_LOCATIONS_REQUEST,
        practiceId,
        page,
        loading,
    }
}

export function fetchPracticeLocations(practiceId: string, query: string, page: number): any {
    return async (dispatch: any) => {
        await dispatch(searchPracticeLocationsRequest(practiceId, page, true))
        const { data: locations, paginationInfo } = await ApiV2.Practice.getPracticeLocations(practiceId, {
            query,
            page,
            limit: 10,
        })
        await dispatch(receivePracticeLocations(practiceId, locations, paginationInfo))
        return locations
    }
}

// export function fetchPracticeLocationReschedulingConfiguration(
//     practiceId: string,
//     page: number,
// ): AppThunk<Promise<PracticeLocationReschedulingConfiguration[]>> {
//     return async (dispatch: any) => {
//         await dispatch(searchPracticeLocationReschedulingConfigurationRequest(practiceId, page, true))
//         const { data: settings, paginationInfo } = await ApiV2.Practice.getPracticeLocationReschedulingConfiguration(
//             practiceId,
//             page,
//         )
//         await dispatch(receiveCancelRescheduleSettings(practiceId, settings, paginationInfo))
//         return settings
//     }
// }

export function fetchPracticeLocationReschedulingConfiguration(practiceId: string, page: number = 1) {
    return async (dispatch: any) => {
        const settings = await ApiV2.Practice.getPracticeLocationReschedulingConfiguration(practiceId, page)
        await dispatch(receivePracticeLocationCancelRescheduleSettings(practiceId, settings))
        return settings
    }
}

export function updatePracticeLocationReschedulingConfiguration(
    practiceId: string,
    practiceLocationId: string,
    configuration: ModelsV2.Practice.PracticeLocationReschedulingConfiguration[],
    isCreate: boolean = false,
): AppThunk<Promise<PracticeLocationReschedulingConfiguration[]>> {
    return async (dispatch: any) => {
        const settings = await ApiV2.Practice.putPracticeLocationReschedulingConfiguration(practiceId, configuration)
        await dispatch(
            receivePracticeLocationCancelRescheduleSetting(practiceId, practiceLocationId, settings, isCreate),
        )
        return settings
    }
}

export function fetchConnectedPracticeLocations(
    practiceId: string,
    page: number = 1,
): AppThunk<Promise<ConnectedPracticeLocation[]>> {
    return async (dispatch: any) => {
        const connectedLocations = await ApiV2.Practice.getAllConnectedPracticeLocations(practiceId, page)
        await dispatch(receiveConnectedPracticeLocations(practiceId, connectedLocations))
        return connectedLocations
    }
}

export function fetchPracticeReschedulingConfiguration(
    practiceId: string,
): AppThunk<Promise<PracticeReschedulingConfiguration>> {
    return async (dispatch: any) => {
        const settings = await ApiV2.Practice.getPracticeReschedulingConfiguration(practiceId)
        await dispatch(receivePracticeCancelRescheduleSettings(practiceId, settings))
        return settings
    }
}

export function createPracticeReschedulingConfiguration(
    practiceId: string,
    configuration: PracticeReschedulingConfiguration,
): any {
    return async (dispatch: any) => {
        const settings = await ApiV2.Practice.putPracticeReschedulingConfiguration(practiceId, configuration)
        await dispatch(receivePracticeCancelRescheduleSettings(practiceId, settings))
        return settings
    }
}
