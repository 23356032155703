import React, { ChangeEvent } from 'react'
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material'
import classNames from 'classnames'

import CopyText from '../../../shared/CopyText'
import { terminalColors } from '../constants'

import { validateTerminal } from './utils'

import './TerminalForm.sass'

export type AddTerminalRadio = 'useWisePosE' | 'useSmile' | 'useStripeS700'

interface Props {
    isView: boolean
    isOpen: boolean
    isLoading: boolean
    terminalNickname: string
    addTerminalMode: AddTerminalRadio
    readerSerial: string
    wisePairingCode: string | undefined
    s700RegistrationCode: string | undefined
    stripeLocationId: string | undefined
    terminalUUID: string | undefined
    onClose: () => void
    onSubmit: () => void
    onDeleteTerminal: () => void
    onTerminalColorChange: (nickname: string) => void
    onTerminalModeChange: (mode: AddTerminalRadio) => void
    onTerminalReaderSerialChange: (serialNo: string) => void
    onTerminalReaderPairingCodeChange: (pairingCode: string) => void
    onTerminalReaderS700CodeChange: (s700Code: string) => void
    onTerminalUUIDChange: (uuid: string) => void
}

const TerminalForm = ({
    isView,
    isOpen,
    isLoading,
    terminalNickname,
    addTerminalMode,
    readerSerial,
    wisePairingCode,
    s700RegistrationCode,
    stripeLocationId,
    terminalUUID,
    onTerminalColorChange,
    onTerminalModeChange,
    onTerminalReaderSerialChange,
    onTerminalReaderPairingCodeChange,
    onTerminalReaderS700CodeChange,
    onTerminalUUIDChange,
    onClose,
    onSubmit,
    onDeleteTerminal,
}: Props) => {
    const handleTerminalColorChange = (event: SelectChangeEvent<string>) => {
        onTerminalColorChange(`${event.target.value.slice(0, 1).toUpperCase()}${event.target.value.slice(1)}`)
    }

    const handleTerminalModeChange = (_: unknown, value: AddTerminalRadio) => {
        onTerminalModeChange(value)
    }

    const handleTerminalSerialChange = (event: ChangeEvent<HTMLInputElement>) => {
        onTerminalReaderSerialChange(event.target.value)
    }

    const handlewisePairingCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        onTerminalReaderPairingCodeChange(event.target.value)
    }

    const handleS700CodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        onTerminalReaderS700CodeChange(event.target.value)
    }

    const handleTerminalUUIDChange = (event: ChangeEvent<HTMLInputElement>) => {
        onTerminalUUIDChange(event.target.value)
    }

    const renderTerminalContent = () => {
        if (!isOpen) return null

        if (isView) {
            return (
                <>
                    <Button className="delete-button" onClick={onDeleteTerminal} disabled={isLoading}>
                        Delete
                    </Button>
                    <div className="payments-terminal-bottom">
                        <div>
                            <div className="address-heading">Reader Serial Number</div>
                            {readerSerial}
                            <CopyText text={readerSerial} />
                        </div>
                        {wisePairingCode ? (
                            <div>
                                <div className="address-heading">Tablet Mac Address</div>
                                {wisePairingCode}
                                <CopyText text={wisePairingCode} />
                            </div>
                        ) : (
                            <div>
                                <div className="address-heading">Case-Sensitive UUID</div>
                                {terminalUUID}
                                <CopyText text={terminalUUID ? terminalUUID : ''} />
                            </div>
                        )}
                    </div>
                </>
            )
        }

        const isTerminalValid = validateTerminal(
            terminalNickname,
            readerSerial,
            wisePairingCode,
            s700RegistrationCode,
            terminalUUID,
        )

        return (
            <div>
                <div className="payments-terminal-data-entry-row">
                    <div>
                        <div className="address-heading terminal-color-heading">Terminal Color</div>
                        <FormControl className="terminal-color-field" variant="standard">
                            <InputLabel htmlFor="terminal-color-dropdown">Terminal Color</InputLabel>
                            <Select
                                className="terminal-color-select"
                                value={terminalNickname.toLowerCase()}
                                onChange={handleTerminalColorChange}
                                inputProps={{
                                    name: 'color',
                                    id: 'terminal-color-dropdown',
                                }}
                                variant="standard"
                            >
                                {Object.keys(terminalColors).map(colorName => (
                                    <MenuItem value={colorName} key={colorName}>
                                        <div className="terminal-color-item-wrapper">
                                            <span
                                                className="terminal-color-display"
                                                style={{
                                                    backgroundColor: terminalColors[colorName],
                                                }}
                                            ></span>
                                            <span className="terminal-color-name">{colorName}</span>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <RadioGroup value={addTerminalMode} onChange={handleTerminalModeChange}>
                            <FormControlLabel
                                value={'useSmile' as AddTerminalRadio}
                                control={<Radio />}
                                label="Smile Terminal"
                                className="terminal-radio"
                            />
                            <FormControlLabel
                                value={'useWisePosE' as AddTerminalRadio}
                                control={<Radio />}
                                label={`WisePOS E${!stripeLocationId ? ' (requires Stripe Location Id)' : ''}`}
                                className="terminal-radio"
                                disabled={!stripeLocationId}
                            />
                            <FormControlLabel
                                value={'useStripeS700' as AddTerminalRadio}
                                control={<Radio />}
                                label={`Stripe S700`}
                                className="terminal-radio"
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div className="payments-terminal-data-entry-row">
                    {addTerminalMode === 'useSmile' && (
                        <>
                            <div>
                                <div className="address-heading">Reader Serial Number</div>
                                <TextField
                                    required
                                    className="field terminal-reader-serial"
                                    value={readerSerial || ''}
                                    placeholder="Typically starts with CHB20"
                                    onChange={handleTerminalSerialChange}
                                />
                            </div>
                            <div>
                                <div className="address-heading">Case-Sensitive UUID</div>
                                <TextField
                                    className="field terminal-setup-id"
                                    value={terminalUUID || ''}
                                    placeholder="XXXX-XXXX-XXXX-XXXX"
                                    inputProps={{ maxLength: 16 }}
                                    onChange={handleTerminalUUIDChange}
                                />
                            </div>
                        </>
                    )}
                    {addTerminalMode === 'useWisePosE' && (
                        <div>
                            <div className="address-heading">Pairing Code</div>
                            <TextField
                                className="field terminal-setup-id"
                                value={wisePairingCode || ''}
                                inputProps={{ maxLength: 35 }}
                                onChange={handlewisePairingCodeChange}
                            />
                        </div>
                    )}
                    {addTerminalMode === 'useStripeS700' && (
                        <div className="s700-registration-wrapper">
                            <div className="address-heading">Registration Code</div>
                            <TextField
                                className="field terminal-setup-id"
                                value={s700RegistrationCode || ''}
                                inputProps={{ maxLength: 35 }}
                                onChange={handleS700CodeChange}
                            />
                        </div>
                    )}
                </div>
                <button
                    className={classNames('update-button', {
                        'can-save': isTerminalValid && !isLoading,
                    })}
                    onClick={onSubmit}
                    disabled={!isTerminalValid && !isLoading}
                >
                    Add Terminal
                </button>
            </div>
        )
    }

    return (
        <Modal
            open={isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose()
                }
            }}
            disableEscapeKeyDown
        >
            <div className="location-modal terminal-modal">
                <div>
                    <i className="material-icons close-modal" onClick={onClose}>
                        close
                    </i>
                    <div className="terminal-modal-wrapper">
                        <h1 className="name-header terminal-header">{!isView ? 'Add Terminal' : terminalNickname}</h1>
                        {renderTerminalContent()}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export type TerminalFormProps = Props
export default TerminalForm
