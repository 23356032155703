export function mapRSVPConnectedLocationToModel(
    connectedLocation: Api.RSVP.ConnectedLocation,
): Models.RSVPConnectedLocation {
    return {
        id: connectedLocation.id,
        location: {
            ...connectedLocation.location,
            userGroups: connectedLocation.location.user_groups,
        },
    }
}

export function mapRSVPConnectedLocationsToModel(
    connectedLocations: Api.RSVP.ConnectedLocation[],
): Models.RSVPConnectedLocation[] {
    return connectedLocations.map(mapRSVPConnectedLocationToModel)
}

export function mapRSVPConnectedLocationToApi(
    connectedLocation: Models.RSVPConnectedLocation,
): Api.RSVP.ConnectedLocation {
    return {
        id: connectedLocation.id,
        location: {
            ...connectedLocation.location,
            user_groups: connectedLocation.location.userGroups,
        },
    }
}

export function mapRSVPConnectedLocationsToApi(
    connectedLocations: Models.RSVPConnectedLocation[],
): Api.RSVP.ConnectedLocation[] {
    return connectedLocations.map(mapRSVPConnectedLocationToApi)
}
