export function mapWebCodeLocationToModel(location: Api.Amplify.WebCodeLocation): Models.AmplifyWebCodeLocation {
    return {
        name: location.name,
        amplifyWebCodeId: location.amplify_web_code_id,
        practiceLocationId: location.practice_location_id,
        enabled: location.enabled,
        allLocationsEnabled: location.all_locations_enabled,
    }
}

export function mapWebCodeLocationToApi(location: Models.AmplifyWebCodeLocation): Api.Amplify.WebCodeLocation {
    return {
        name: location.name,
        amplify_web_code_id: location.amplifyWebCodeId,
        practice_location_id: location.practiceLocationId,
        enabled: location.enabled,
        all_locations_enabled: location.allLocationsEnabled,
    }
}

export function mapWebCodeLocationsToApi(locations: Models.AmplifyWebCodeLocation[]): Api.Amplify.WebCodeLocation[] {
    if (!Array.isArray(locations)) {
        return []
    }
    return locations.map((location: Models.AmplifyWebCodeLocation) => mapWebCodeLocationToApi(location))
}

export function mapWebCodeLocationsToModel(locations: Api.Amplify.WebCodeLocation[]): Models.AmplifyWebCodeLocation[] {
    if (!Array.isArray(locations)) {
        return []
    }
    return locations.map((location: Api.Amplify.WebCodeLocation) => mapWebCodeLocationToModel(location))
}

export function mapFullPracticeLocation(location: ApiV2.Practice.PracticeLocationFull): Api.PracticeLocation {
    return {
        id: location.id,
        practice_id: location.practice_id,
        address_id: location.address.id,
        name: location.name,
        external_system_id: null,
        description: location.description,
        active: location.active,
        agent_id: null,
        address: location.address,
        phone_number: location.phone_number,
        timezone: location.timezone,
        practice_specialty_type: location.practice_specialty_type,
        survey: location.survey,
        yext_enabled: location.yext_enabled,
        is_vyne_created: location.is_vyne_created || false,
        facility_id: location?.facility_id || '',
        is_vyne_controlled: location?.is_vyne_controlled,
        products: location.products,
    }
}

export function mapFullPracticeLocationWithProducts(
    location: ApiV2.Practice.PracticeLocationFull,
    products: Models.Product[],
): Api.PracticeLocation {
    return {
        id: location.id,
        practice_id: location.practice_id,
        address_id: location.address.id,
        name: location.name,
        external_system_id: null,
        description: location.description,
        active: location.active,
        agent_id: null,
        address: location.address,
        phone_number: location.phone_number,
        timezone: location.timezone,
        practice_specialty_type: location.practice_specialty_type,
        survey: location.survey,
        yext_enabled: location.yext_enabled,
        is_vyne_created: location.is_vyne_created || false,
        facility_id: location?.facility_id || '',
        is_vyne_controlled: true,
        products: products.map((product: Models.Product) => ({
            practice_location_id: location.id,
            product_id: product.id,
            active: product.active,
            product: {
                id: product.id,
                value: product.value,
                display_name: product.displayName,
                default_paid_modules: [],
            },
        })),
    }
}

export function mapFullPracticeLocations(locations: ApiV2.Practice.PracticeLocationFull[]): Api.PracticeLocation[] {
    if (!Array.isArray(locations)) {
        return []
    }
    return locations.map((location: ApiV2.Practice.PracticeLocationFull) => mapFullPracticeLocation(location))
}

export function mapFullPracticeLocationsWithProducts(
    locations: ApiV2.Practice.PracticeLocationFull[],
    practiceProducts: Models.Product[],
): Api.PracticeLocation[] {
    if (!Array.isArray(locations)) {
        return []
    }
    return locations.map((location: ApiV2.Practice.PracticeLocationFull) =>
        mapFullPracticeLocationWithProducts(location, practiceProducts),
    )
}
