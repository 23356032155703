import { connect } from 'react-redux'

import { RootState } from '../../appReducer'
import AdminAccount from '../../models/AdminAccount'

import {
    checkPatientOnlineStatus,
    fetchAuthKey,
    fetchClaimedChats,
    fetchLatestChats,
    fetchNewerChatMsgs,
    handleEvent,
    handleMessage,
    patientRejoinedChat,
    setMessagePreview,
    setNetworkState,
    setPatientOnlineStatus,
} from './actions'
import PubNubEventManager, { PubNubEventManagerProps } from './PubNubEventManager'

const mapStateToProps = (state: RootState): PubNubEventManagerProps => ({
    account: (state.app.self && state.app.self.account) as AdminAccount, // component won't be mounted unless logged in
    authKey: state.chat.authKey,
    claimedChats: state.chat.claimedChats,
    selectedChats: state.chat.selectedChats,
    conversations: state.chat.conversations,
})

const PubNubEventManagerContainer = connect(mapStateToProps, {
    fetchAuthKey,
    fetchLatestChats,
    fetchClaimedChats,
    handleEvent,
    handleMessage,
    setMessagePreview,
    fetchNewerChatMsgs,
    setNetworkState,
    setPatientOnlineStatus,
    checkPatientOnlineStatus,
    patientRejoinedChat,
})(PubNubEventManager as any)

export default PubNubEventManagerContainer
