import iassign from 'immutable-assign'

import { AddPendingUpload, RemovePendingUpload, SavedFilePartUpload } from './actions'

export type UploadsState = {
    pendingUploads: { [key: string]: Models.Upload }
}

type UploadsAction = AddPendingUpload | SavedFilePartUpload | RemovePendingUpload

const initialState: UploadsState = {
    pendingUploads: {},
}

export function reducer(state: UploadsState = initialState, action: UploadsAction) {
    switch (action.type) {
        case 'ADD_PENDING_UPLOAD': {
            return iassign(state, next => {
                next.pendingUploads[action.upload.id] = action.upload
                return next
            })
        }
        case 'SAVED_FILE_PART_UPLOAD': {
            return iassign(
                state,
                n => n.pendingUploads[action.upload.id],
                next => {
                    next.parts_completed = next.parts_completed + 1
                    return next
                },
            )
        }
        case 'REMOVE_PENDING_UPLOAD': {
            return iassign(
                state,
                n => n.pendingUploads,
                next => {
                    delete next[action.uploadId]
                    return next
                },
            )
        }
        default:
            return state
    }
}
