import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import classNames from 'classnames'
import uniqBy from 'lodash/uniqBy'
import { updateLocationMilestone } from 'modules/practices/actions'
import { SimplifeyeLogoIconSmall, VyneLogoIconSmall } from 'modules/shared/svgIcons'

import { RootState } from '../../../../../appReducer'
import { useAppDispatch } from '../../../../../util/useAppDispatch'
import CopyText from '../../../../shared/CopyText'
import GreenSwitch from '../../../../shared/green-switch/GreenSwitch'
import SyncSchedBtn from '../../SyncSchedBtn'

import LocationCardPayment, { LocationCardPaymentProps } from './LocationCardPayment'
import Loading from 'react-loading'

interface Props {
    location: Models.PracticeLocation
    practiceId: Models.Practice['id']
    hasPayments: boolean
    isPaymentsCardExpanded: boolean
    isPaymentsCardLoading: boolean
    isLocationEditable: boolean
    canManageSurvey: boolean
    hasDirectScheduling: boolean
    onTogglePaymentsExpand: LocationCardPaymentProps['onTogglePaymentsExpand']
    onOpenTerminalModal: LocationCardPaymentProps['onOpenTerminalModal']
    onOpenStripeAccountIdModal: LocationCardPaymentProps['onOpenStripeAccountIdModal']
    onOpenPaymentsDescriptorModal: LocationCardPaymentProps['onOpenPaymentsDescriptorModal']
    onOpenPaymentsRatesModal: LocationCardPaymentProps['onOpenPaymentsRatesModal']
    onSyncLocationSchedulingData: (location: Models.PracticeLocation) => void
    onEditSurvey: (locationId: Models.PracticeLocation) => void
    onEditLocation: (locationId: Models.PracticeLocation) => void
    onOpenLocationProductsModal: (locationId: Models.PracticeLocation['id']) => void
}

const LocationCard = ({
    location,
    practiceId,
    hasPayments,
    isPaymentsCardExpanded,
    isPaymentsCardLoading,
    isLocationEditable,
    canManageSurvey,
    hasDirectScheduling,
    onTogglePaymentsExpand,
    onOpenTerminalModal,
    onOpenStripeAccountIdModal,
    onOpenPaymentsDescriptorModal,
    onOpenPaymentsRatesModal,
    onSyncLocationSchedulingData,
    onEditSurvey,
    onEditLocation,
    onOpenLocationProductsModal,
}: Props) => {
    const dispatch = useAppDispatch()
    const locationHasSurveyWarning = Boolean(location.survey?.status === 'not_completed')
    const locationHasSyncButton = location.jarvisEnabled && location.schedulingDetails

    const [loadingEdit, setLoadingEdit] = useState(false)

    const { stripeIDstatus, paymentsRatesStatus, allFieldsCompleted } = useSelector((state: RootState) => {
        let i = state.practices.practices[practiceId].locations.findIndex(stateLoc => stateLoc.id === location.id)
        return {
            stripeIDstatus: state.practices.practices[practiceId].locations[i].stripeID,
            paymentsRatesStatus: state.practices.practices[practiceId].locations[i].paymentsRates,
            allFieldsCompleted: state.practices.practices[practiceId].locations[i].allFieldsCompleted,
        }
    })

    const locationProductsRenamed = location.products?.map(product => ({
        ...product,
        product: {
            ...product.product,
            display_name: product.product.display_name
                .replace('Amplify', 'Live Webchat')
                .replace('Direct Scheduling', 'Online Scheduling')
                .replace('Payments', 'Simplifeye Payments'),
        },
    }))

    const locationProductsSorted = locationProductsRenamed?.sort((a, b) =>
        a.product?.display_name?.localeCompare(b.product?.display_name),
    )

    const isInactive = locationProductsSorted?.length === 0 || locationProductsSorted?.every(product => !product.active)

    const renderVyneLocationProducts = (): JSX.Element => {
        if (location.isVyneControlled && location.facilityId && location.vyneProducts === undefined) {
            return <CircularProgress size={20} color="primary" variant="indeterminate" />
        }
        const vyneProducts =
            location.vyneProducts?.map(product => ({
                product: {
                    id: product,
                    value: product,
                    display_name: product.replaceAll('\n', ''),
                },
                active: true,
            })) || []

        const vyneLocationProductsSorted = locationProductsSorted?.filter(
            product =>
                ['payments', 'direct_scheduling', 'amplify', 'chairfill'].includes(product.product?.value) &&
                product?.active,
        )

        const locationProductsSimp = vyneLocationProductsSorted || []

        const allProducts = [...locationProductsSimp, ...vyneProducts]

        const allProductsSorted = uniqBy(
            allProducts.sort((a, b) => a.product?.display_name?.localeCompare(b.product?.display_name)),
            'product.display_name',
        )

        return (
            <div>
                {allProductsSorted?.map(product => (
                    <div
                        key={product.product.id}
                        className={classNames('locations-tab__product', {
                            'locations-tab--product-inactive': !product.active,
                            'locations-tab--not-active': true,
                        })}
                    >
                        {product.product?.display_name}
                    </div>
                ))}
            </div>
        )
    }

    const generateActionButtonClass = (): string =>
        `buttons-wrapper-${locationHasSyncButton && canManageSurvey ? 'two-rows' : 'one-row'}${
            locationHasSurveyWarning ? `-warning` : ``
        }`

    const handleEditSurvey = (): void => onEditSurvey(location)

    const handleEditLocation = async () => {
        setLoadingEdit(true)
        await onEditLocation(location)
        setLoadingEdit(false)
    }

    const isChecked = useSelector((state: RootState) => {
        let i = state.practices.practices[practiceId].locations.findIndex(stateLoc => stateLoc.id === location.id)
        return state.practices.practices[practiceId].locations[i].isTrainingComplete
    })

    const handleTrainingSwitch = async () => {
        let willBeChecked = true
        if (isChecked) {
            willBeChecked = false
        }
        await dispatch(updateLocationMilestone(practiceId, location.id, willBeChecked))
    }

    const handleSyncLocationSchedulingData = (): void => {
        if (location.address && hasDirectScheduling) {
            onSyncLocationSchedulingData(location)
        }
    }

    const handleOpenLocationsProductModal = (): void => {
        onOpenLocationProductsModal(location.id)
    }

    return (
        <Card className={classNames('card', { 'inactive-card': isInactive })}>
            <div className="header">
                {location.name}
                <div className="vyne-controlled-icon">
                    {location.isVyneControlled ? <VyneLogoIconSmall /> : <SimplifeyeLogoIconSmall />}
                </div>
            </div>
            <CardContent className="address">
                {location.address && (
                    <div className="locations-tab__address-wrapper">
                        <div className="label">Address</div>
                        <div>{location.address.street}</div>
                        <div>{location.address.unit}</div>
                        <div>{`${location.address.city}, ${location.address.state} ${location.address.zip}`}</div>
                    </div>
                )}

                <span className="locations-tab__copy-id id">
                    Location ID: <CopyText text={location.id}>{location.id}</CopyText>
                </span>

                <div className="locations-tab__facility-id">
                    {location.facilityId ? (
                        <span className="locations-tab__copy-facility-id id">
                            Facility ID: <CopyText text={location.facilityId}>{location.facilityId}</CopyText>
                        </span>
                    ) : (
                        <span>Facility ID: N/A</span>
                    )}
                </div>
                <div
                    className={classNames('locations-tab__products', {
                        'locations-tab--not-clickable': location.isVyneControlled,
                    })}
                    onClick={location.isVyneControlled ? undefined : handleOpenLocationsProductModal}
                >
                    {locationProductsSorted?.length ? (
                        <div>
                            {location.isVyneControlled
                                ? renderVyneLocationProducts()
                                : locationProductsSorted?.map(product => (
                                      <div
                                          key={product.product.id}
                                          className={classNames('locations-tab__product', {
                                              'locations-tab--product-inactive': !product.active,
                                          })}
                                      >
                                          {product.product?.display_name}
                                      </div>
                                  ))}
                        </div>
                    ) : (
                        <div>
                            <div
                                className={classNames('locations-tab__product', {
                                    'locations-tab--not-active': location.isVyneControlled,
                                })}
                            >
                                {location.isVyneControlled ? `No Products` : `Add Products`}
                            </div>
                        </div>
                    )}
                </div>

                {hasPayments && (
                    <LocationCardPayment
                        practiceId={practiceId}
                        locationId={location.id}
                        locationName={location.name}
                        paymentsInfo={location.paymentsInfo}
                        isPaymentsCardLoading={isPaymentsCardLoading}
                        isPaymentsCardExpanded={isPaymentsCardExpanded}
                        stripeConnectedAccountId={location.stripeConnectedAccountId}
                        stripeLocationId={location.stripeLocationId}
                        statementDescriptor={location.statementDescriptor}
                        onOpenPaymentsRatesModal={onOpenPaymentsRatesModal}
                        onOpenStripeAccountIdModal={onOpenStripeAccountIdModal}
                        onOpenPaymentsDescriptorModal={onOpenPaymentsDescriptorModal}
                        onTogglePaymentsExpand={onTogglePaymentsExpand}
                        onOpenTerminalModal={onOpenTerminalModal}
                        allFieldsCompleted={allFieldsCompleted}
                        stripeIDStatus={stripeIDstatus}
                        paymentsRatesStatus={paymentsRatesStatus}
                    />
                )}
            </CardContent>
            <CardActions>
                <div className={generateActionButtonClass()}>
                    <div className="buttons">
                        <Button
                            className="button"
                            size="small"
                            disabled={!isLocationEditable}
                            onClick={handleTrainingSwitch}
                        >
                            <div>
                                <div>
                                    <GreenSwitch
                                        className="locations-tab__training-slider"
                                        checked={isChecked ?? false}
                                    />
                                </div>
                                <div>Training</div>
                            </div>
                        </Button>

                        {locationHasSyncButton && (
                            <SyncSchedBtn
                                syncLocationSchedulingData={handleSyncLocationSchedulingData}
                                locationId={location.id}
                            />
                        )}
                        {canManageSurvey && location.survey?.id && (
                            <Button className="button" size="small" onClick={handleEditSurvey}>
                                {locationHasSurveyWarning ? (
                                    <div className="locations-tab__survey-error-container">
                                        <div>
                                            <i className="material-icons locations-tab__survey-error-icon">error</i>
                                        </div>
                                        <div>Survey</div>
                                    </div>
                                ) : (
                                    'Survey'
                                )}
                            </Button>
                        )}

                        <Button
                            className="button"
                            size="small"
                            onClick={handleEditLocation}
                            disabled={!isLocationEditable}
                        >
                            Edit
                            {loadingEdit && (
                                <div className="locations-tab__small-loader">
                                    <CircularProgress size={10} />
                                </div>
                            )}
                        </Button>
                    </div>
                </div>
            </CardActions>
        </Card>
    )
}

export default LocationCard
