import * as React from 'react'

import DownwardLightning from './downward-lightning'
import LeftSocket from './left-socket'
import RightSocket from './right-socket'
import UpwardLightning from './upward-lightning'

import './DisconnectInterface.sass'

export default function DisconnectInterface() {
    return (
        <div className="disconnect-interface-container">
            <div className="disconnect-interface-message-container">
                <i className="material-icons disconnect-interface-warning-icon">warning</i>
                <p className="disconnect-interface-error-message">Network connection has been interrupted.</p>
                <p>Please wait a few moments until your connection is restored.</p>
            </div>
            <div className="disconnect-interface-images">
                <div className="disconnect-interface-left-socket">
                    <LeftSocket />
                </div>
                <div className="disconnect-interface-lightnings">
                    <div className="disconnect-interface-up-lightning">
                        <UpwardLightning />
                    </div>
                    <div className="disconnect-interface-down-lightning">
                        <DownwardLightning />
                    </div>
                </div>
                <div className="disconnect-interface-right-socket">
                    <RightSocket />
                </div>
            </div>
        </div>
    )
}
