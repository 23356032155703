import { connect } from 'react-redux'

import { setRedirect } from '../../appActions'
import { RootState } from '../../appReducer'

import { fetchAgents } from './actions'
import DexVoice, { DexVoiceProps } from './DexVoice'

// TODO #CONTPROPS# figure out a better way
type DexVoiceContainerProps = {
    practice: Models.Practice
}

const mapStateToProps = (state: RootState, props: DexVoiceContainerProps): DexVoiceProps => ({
    practice: props.practice,
    agents: state.dexVoice.agents[props.practice.id],
})

const DexVoiceContainer = connect(mapStateToProps, {
    fetchAgents,
    setRedirect,
})(DexVoice)

export default DexVoiceContainer
