import Api from '../../../Api'
import {
    ConnectBooking,
    ConnectBookingFormElements,
    ConnectPaymentDetails,
    ConnectPaymentStatus,
    PatientType,
} from '../../../models/Connect'

export type InitConnect = {
    type: '@CONNECT/INIT'
    chat: Models.ChatMetadata
    isInsuranceRequired: boolean
    patientType: PatientType
    isPaymentAvailable: boolean
}

export type UpdateConnect = {
    type: '@CONNECT/UPDATE'
    chat: Models.ChatMetadata
    isInsuranceRequired: boolean
    patientType: PatientType
    isPolicyHolder: boolean
}

export type UpdateFormFields = {
    type: '@CONNECT/UPDATE_FORM_FIELDS'
    chat: Models.ChatMetadata
    formElements: ConnectBookingFormElements
}

export type SetTeleConnectBookingPending = {
    type: '@CONNECT/BOOKING_PENDING'
    chat: Models.ChatMetadata
}

export type ReceiveTeleConnectBooking = {
    type: '@CONNECT/BOOKED'
    chat: Models.ChatMetadata
    booked: boolean
    message?: string
    status?: string
}

export type ShareTeleConnect = {
    type: '@CONNECT/SHARED'
    chat: Models.ChatMetadata
}

export type ValidateForm = {
    type: '@CONNECT/VALIDATE_FORM'
    chat: Models.ChatMetadata
}

export type UpdateConnectPaymentStatus = {
    type: '@CONNECT/UPDATE_CONNECT_PAYMENT_STATUS'
    chat: Models.ChatMetadata
    connectPaymentStatus: ConnectPaymentStatus
}

export type ReceivePaymentDetails = {
    type: '@CONNECT/RECEIVE_PAYMENT_DETAILS'
    chat: Models.ChatMetadata
    paymentDetails: ConnectPaymentDetails
}

export type ReceiveTelehealthSession = {
    type: '@CONNECT/RECEIVE_TELEHEALTH_SESSION_ID'
    chat: Models.ChatMetadata
    telehealthSessionId: string
}

export function initConnect(
    chat: Models.ChatMetadata,
    isInsuranceRequired: boolean,
    patientType: PatientType,
    isPaymentAvailable: boolean,
): InitConnect {
    return {
        type: '@CONNECT/INIT',
        chat,
        isInsuranceRequired,
        patientType,
        isPaymentAvailable,
    }
}

export function updateConnect(
    chat: Models.ChatMetadata,
    isInsuranceRequired: boolean,
    patientType: PatientType,
    isPolicyHolder: boolean,
): UpdateConnect {
    return {
        type: '@CONNECT/UPDATE',
        chat,
        isInsuranceRequired,
        patientType,
        isPolicyHolder,
    }
}

export function updateFormFields(
    chat: Models.ChatMetadata,
    formElements: ConnectBookingFormElements,
): UpdateFormFields {
    return {
        type: '@CONNECT/UPDATE_FORM_FIELDS',
        chat,
        formElements,
    }
}

export function setTeleConnectBookingPending(chat: Models.ChatMetadata): SetTeleConnectBookingPending {
    return {
        type: '@CONNECT/BOOKING_PENDING',
        chat,
    }
}

export function receiveTeleConnectBooking(
    chat: Models.ChatMetadata,
    booked: boolean,
    message?: string,
    status?: string,
): ReceiveTeleConnectBooking {
    return {
        type: '@CONNECT/BOOKED',
        chat,
        booked,
        message,
        status,
    }
}

export function shareTeleConnect(chat: Models.ChatMetadata): ShareTeleConnect {
    return {
        type: '@CONNECT/SHARED',
        chat,
    }
}

export function updateConnectPaymentStatus(
    chat: Models.ChatMetadata,
    connectPaymentStatus: ConnectPaymentStatus,
): UpdateConnectPaymentStatus {
    return {
        type: '@CONNECT/UPDATE_CONNECT_PAYMENT_STATUS',
        chat,
        connectPaymentStatus,
    }
}

export function receivePaymentDetails(
    chat: Models.ChatMetadata,
    paymentDetails: ConnectPaymentDetails,
): ReceivePaymentDetails {
    return {
        type: '@CONNECT/RECEIVE_PAYMENT_DETAILS',
        chat,
        paymentDetails,
    }
}

export function receiveTelehealthSession(
    chat: Models.ChatMetadata,
    telehealthSessionId: string,
): ReceiveTelehealthSession {
    return {
        type: '@CONNECT/RECEIVE_TELEHEALTH_SESSION_ID',
        chat,
        telehealthSessionId,
    }
}

export function bookTeleConnect(
    practice: Models.Practice,
    webCode: Models.WebCode,
    connectBooking: ConnectBooking,
): any {
    return async (dispatch: any) => {
        await dispatch(setTeleConnectBookingPending(connectBooking.chat))

        try {
            const { booked } = await Api.Patients.postBookTeleConnect(practice, webCode, connectBooking)
            await dispatch(receiveTeleConnectBooking(connectBooking.chat, booked))
            return booked
        } catch (err) {
            await dispatch(receiveTeleConnectBooking(connectBooking.chat, false, err.message, err.status))
            return err
        }
    }
}

export function createPatient(chat: Models.ChatMetadata, connectPaymentDetails: Models.ChatConnectPaymentDetails): any {
    return async (dispatch: any) => {
        const paymentDetails = await Api.Payments.postCreatePracticePrimaryPatient(
            chat.practice.id,
            connectPaymentDetails,
        )

        if (paymentDetails.patient?.payerId && paymentDetails.patient?.patientId) {
            const details = {
                payerId: paymentDetails.patient.payerId,
                patientId: paymentDetails.patient.patientId,
                firstName: paymentDetails.patient.firstName,
            }
            await dispatch(receivePaymentDetails(chat, details))
            return details
        } else {
            return {}
        }
    }
}

export function getPaymentClientSecret(payerId: string): any {
    return async () => {
        return await Api.Payments.postPaymentSetup(payerId)
    }
}

export function postPatientConsentToPaymentTerms(practiceId: string, payerId: string): any {
    return async () => {
        return await Api.Payments.postPayerApproval(practiceId, payerId)
    }
}

type ConnectAppointmentURLs = {
    url: string
    practiceUrl: string
}
export function createTelehealthSession(chat: Models.ChatMetadata, { url, practiceUrl }: ConnectAppointmentURLs): any {
    return async (dispatch: any) => {
        const { id } = await Api.Connect.postTelehealthSession(url, practiceUrl)
        await dispatch(receiveTelehealthSession(chat, id))
    }
}
