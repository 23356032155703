import { formatPhoneNumber as formatPhoneNumberNational, isValidPhoneNumber } from 'react-phone-number-input'
import countries from 'countries-list'
import validator from 'validator'

import { mobilePhoneLocale } from './constants'

export const formatPhoneNumberWithRoundBrackets = (phoneCountryCodePrefix: string, phoneNumber: string) => {
    return phoneNumber.replace(phoneCountryCodePrefix, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}

export const formatPhoneNumberNtl = (phoneNumberString: string) => {
    const isValid = isValidPhoneNumber(phoneNumberString)
    return isValid ? formatPhoneNumberNational(phoneNumberString) : phoneNumberString
}

export const phoneNumberToInternational = (value: string, locale: validator.MobilePhoneLocale) => {
    if (['en-US', 'en-CA'].includes(locale) && !value.startsWith('+1')) {
        return `+${value.replaceAll('-', '')}`
    }
    return value
}

export const getMobilePhoneLocaleFromCountryCode = (countryCodeIsoAlpha2: string): validator.MobilePhoneLocale => {
    const locale = mobilePhoneLocale.find(locale => {
        return locale.split('-')[1] === countryCodeIsoAlpha2
    })

    return locale || 'en-US'
}

export const isPhoneNumberValidE164 = (phoneNumber: string): boolean => {
    const E164regEx = /^\+[1-9]\d{10,14}$/
    return E164regEx.test(phoneNumber)
}

export const isPhoneNumberValidLandline = (phoneNumber: string, countryCode: string): boolean => {
    return isValidPhoneNumber(phoneNumber, countryCode)
}

export const validatePhoneNumber = (value: string, countryCode: string) => {
    if (!isPhoneNumberValidE164(value)) {
        return false
    }

    const locale = getMobilePhoneLocaleFromCountryCode(countryCode)
    const international = phoneNumberToInternational(value, locale)

    if (validator.isMobilePhone(international, locale)) {
        return true
    }

    return isPhoneNumberValidLandline(international, countryCode)
}

export const formatUSPhoneNumbers = (phoneNumber: string) => {
    const phoneCountryCode = countries.countries['US']?.phone
    const phoneCountryCodePrefix = `+${phoneCountryCode}`

    if (
        phoneCountryCode &&
        phoneNumber?.startsWith(phoneCountryCodePrefix) &&
        phoneNumber?.length > phoneCountryCodePrefix?.length
    ) {
        return formatPhoneNumberWithRoundBrackets(phoneCountryCodePrefix, phoneNumber)
    }

    return phoneNumber
}
