import * as React from 'react'

import WidgetCustomColors from './WidgetCustomColors'
import WidgetIconPlacement from './WidgetIconPlacement'
import WidgetStyle from './WidgetStyle'

import './WebCodeCustomizationWidget.sass'

type Props = {
    customization: Api.WebCodeCustomization
    resetColors: (e: React.MouseEvent<HTMLElement>) => void
    updateCustomColor: (groupKey: string, colorKey: string, newColor: string) => void
    updateIconPlacement: (iconPlacement: Api.widgetIconPosition) => void
    updateWidgetStyle: (widgetStyle: Api.widgetStyle) => void
}

type State = {}

export default class WebCodeCustomizationWidget extends React.Component<Props, State> {
    render() {
        const { customization, resetColors, updateCustomColor, updateIconPlacement, updateWidgetStyle } = this.props
        const widgetCustomization = customization.widget

        return (
            <div className="customize-widget">
                <WidgetCustomColors
                    customization={customization}
                    resetColors={resetColors}
                    updateCustomColor={updateCustomColor}
                />
                <div className="customize-widget-2">
                    <WidgetIconPlacement
                        customValue={widgetCustomization && widgetCustomization.icon_position}
                        updateIconPlacement={updateIconPlacement}
                    />
                    <WidgetStyle
                        customValue={widgetCustomization && widgetCustomization.widget_style}
                        updateWidgetStyle={updateWidgetStyle}
                    />
                </div>
            </div>
        )
    }
}
