import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import { PracticePatientSort } from '../../../models/v2/Practice'
import InfoMessage from '../../shared/info-message/info-message'
import { fetchPracticeLocations } from '../actions'

import PatientDetails from './patient-details/PatientDetails'
import PatientSearchBar from './patient-search-bar/PatientSearchBar'
import PatientsTable from './patients-table/PatientsTable'
import ProductionValueSection from './production-value-section/ProductionValue'
import { searchPracticePatients } from './v2actions'

import './PatientsTab.sass'

export type Props = {
    practice: Models.Practice
}

const PatientsTab = ({ practice }: Props) => {
    const selectedPatient = useSelector(
        (state: RootState) => state.v2.practicePatients.patientFinancialData?.[practice.id]?.selectedPatient || null,
    )

    const practiceLocations = useSelector(
        (state: RootState) => state.practices.practices?.[practice.id]?.locations || [],
    )

    const practiceLocationNames = practiceLocations.reduce((locations, loc) => {
        return {
            ...locations,
            [loc['id']]: loc.name,
        }
    }, {})

    const practiceId = practice.id

    const allRows = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.pagination?.allRows || 0,
    )

    const query = useSelector((state: RootState) => state.v2.practicePatients.patients?.[practice.id]?.query || '')
    const page = useSelector((state: RootState) => state.v2.practicePatients.patients?.[practice.id]?.page || 1)
    const sort = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practice.id]?.sort || 'last_visit',
    )
    const loading = useSelector(
        (state: RootState) => state.v2.practicePatients.patients?.[practiceId]?.loading || false,
    )
    const order = useSelector((state: RootState) => state.v2.practicePatients.patients?.[practice.id]?.order || 'desc')

    const [patientQuery, setPatientQuery] = useState<string>(query)
    const [searchPage, setSearchPage] = useState<number>(page)
    const [searchSort, setSearchSort] = useState<PracticePatientSort | undefined>(sort)
    const [searchOrder, setSearchOrder] = useState<'asc' | 'desc' | undefined>(order)
    const [hasSearchBar, setHasSearchBar] = useState(false)
    const [firstListLoadDone, setFirstListLoadDone] = useState(false)

    const noRecordsFound = !loading && query === '' && allRows === 0

    const dispatch = useDispatch()

    const searchForPracticePatients = useCallback(
        (
            practiceId: string,
            searchKey: string,
            searchPage: number,
            sort: PracticePatientSort | undefined,
            order: 'asc' | 'desc' | undefined,
        ) => {
            const searchTerms: ModelsV2.Practice.PracticePatientSearchTerms = { query: searchKey, page: searchPage }
            if (sort) {
                searchTerms.sort = sort
                searchTerms.order = order
            }
            dispatch(searchPracticePatients(practiceId, searchTerms))
        },
        [dispatch],
    )

    const throttledSearchPracticePatients = useMemo(() => throttle(searchForPracticePatients, 300), [
        searchForPracticePatients,
    ])

    useEffect(() => {
        dispatch(fetchPracticeLocations(practiceId))
    }, [practiceId, dispatch])

    useEffect(() => {
        throttledSearchPracticePatients(practiceId, patientQuery.trim(), searchPage, searchSort, searchOrder)
    }, [practiceId, patientQuery, searchPage, searchSort, searchOrder, throttledSearchPracticePatients])

    useEffect(() => {
        if ((allRows > 10 || query !== '') && !firstListLoadDone) {
            setHasSearchBar(true)
            setFirstListLoadDone(true)
        }
    }, [allRows, firstListLoadDone, query])

    if (selectedPatient) {
        return (
            <section className="patients-tab">
                <PatientDetails
                    practiceId={practice.id}
                    practiceLocationNames={practiceLocationNames}
                    numOfPracticeLocations={practiceLocations.length}
                />
            </section>
        )
    }

    return (
        <section className="patients-tab">
            <ProductionValueSection practiceId={practiceId} />
            {hasSearchBar && (
                <PatientSearchBar
                    practiceId={practiceId}
                    setPatientQuery={setPatientQuery}
                    setSearchPage={setSearchPage}
                    patientQuery={patientQuery}
                />
            )}
            <PatientsTable
                practiceId={practiceId}
                practiceLocationNames={practiceLocationNames}
                numOfPracticeLocations={practiceLocations.length}
                searchSort={searchSort}
                setSearchSort={setSearchSort}
                searchOrder={searchOrder}
                setSearchOrder={setSearchOrder}
                searchPage={searchPage}
                setSearchPage={setSearchPage}
            />
            {noRecordsFound && <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>}
        </section>
    )
}

export default PatientsTab
