import Api from '../../../../Api'
import { RootState } from '../../../../appReducer'
import { updateAllLocationsStripeAccountId, updateSingleLocationStripeAccountId } from '../../actions'
import { setErrorMessage, setSuccessMessage } from '../actions'
import { StripeAccountIdModalState } from '../reducer'

export enum StripeAccountIdModalActionTypes {
    OPEN_STRIPE_ACCOUNT_ID_MODAL = '@LOCATIONS_TAB_STRIPE_ACCOUNT_ID_MODAL/OPEN_STRIPE_ACCOUNT_ID_MODAL',
    CLOSE_STRIPE_ACCOUNT_ID_MODAL = '@LOCATIONS_TAB_STRIPE_ACCOUNT_ID_MODAL/CLOSE_STRIPE_ACCOUNT_ID_MODAL',
    UPDATE_STRIPE_ACCOUNT_ID_MODAL_DATA = '@LOCATIONS_TAB_STRIPE_ACCOUNT_ID_MODAL/UPDATE_STRIPE_ACCOUNT_ID_MODAL_DATA',
}

export type OpenStripeAccountIdModal = {
    type: StripeAccountIdModalActionTypes.OPEN_STRIPE_ACCOUNT_ID_MODAL
    locationId: Models.PracticeLocation['id']
    locationName: Models.PracticeLocation['name']
    practiceId: string
    stripeAccountId: string
    applyToAll: boolean
}

export type CloseStripeAccountIdModal = {
    type: StripeAccountIdModalActionTypes.CLOSE_STRIPE_ACCOUNT_ID_MODAL
    practiceId: string
}

export type UpdateStripeAccountIdModalData = {
    type: StripeAccountIdModalActionTypes.UPDATE_STRIPE_ACCOUNT_ID_MODAL_DATA
    data: Partial<StripeAccountIdModalState>
    practiceId: string
}

export function openStripeAccountIdModal(
    locationId: Models.PracticeLocation['id'],
    locationName: Models.PracticeLocation['name'],
    practiceId: string,
    stripeAccountId: string,
    applyToAll: boolean,
): OpenStripeAccountIdModal {
    return {
        type: StripeAccountIdModalActionTypes.OPEN_STRIPE_ACCOUNT_ID_MODAL,
        locationId,
        locationName,
        practiceId,
        stripeAccountId,
        applyToAll,
    }
}

export function closeStripeAccountIdModal(practiceId: string): CloseStripeAccountIdModal {
    return {
        type: StripeAccountIdModalActionTypes.CLOSE_STRIPE_ACCOUNT_ID_MODAL,
        practiceId,
    }
}

export function updateStripeAccountIdModalData(
    data: Partial<StripeAccountIdModalState>,
    practiceId: string,
): UpdateStripeAccountIdModalData {
    return {
        type: StripeAccountIdModalActionTypes.UPDATE_STRIPE_ACCOUNT_ID_MODAL_DATA,
        data,
        practiceId,
    }
}

export function updateStripeAccountIdSingleLocation(
    practiceId: string,
    locationId: string,
    stripeAccountId: string,
): any {
    return async (dispatch: any, getState: () => RootState) => {
        const { locationName } = getState().practiceLocations[practiceId].modals.stripeAccountId
        try {
            await Api.Payments.updateStripeAccountIdSingleLocation(practiceId, locationId, stripeAccountId)
            dispatch(closeStripeAccountIdModal(practiceId))
            dispatch(updateSingleLocationStripeAccountId(practiceId, locationId, stripeAccountId))
            dispatch(setSuccessMessage(`Stripe Account ID for "${locationName}" updated successfully.`, practiceId))
        } catch (err) {
            dispatch(
                updateStripeAccountIdModalData(
                    {
                        errorMessage: `Error saving Stripe Account ID for "${locationName}" location.`,
                        isLoading: false,
                    },
                    practiceId,
                ),
            )
            dispatch(setErrorMessage(`Error saving Stripe Account ID for "${locationName}" location.`, practiceId))
        }
    }
}

export function updateStripeAccountIdAllLocations(practiceId: string, stripeAccountId: string): any {
    return async (dispatch: any) => {
        try {
            await Api.Payments.updateStripeAccountIdAllLocations(practiceId, stripeAccountId)
            dispatch(closeStripeAccountIdModal(practiceId))
            dispatch(updateAllLocationsStripeAccountId(practiceId, stripeAccountId))
            dispatch(setSuccessMessage('Stripe Account ID for all locations updated successfully.', practiceId))
        } catch (err) {
            dispatch(
                updateStripeAccountIdModalData(
                    {
                        errorMessage: 'Error saving Stripe Account ID for all locations.',
                        isLoading: false,
                    },
                    practiceId,
                ),
            )
            dispatch(setErrorMessage(`Error saving Stripe Account ID for all locations.`, practiceId))
        }
    }
}
