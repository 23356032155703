export const CANCELLATIONS_VALUES = [
    { value: 'disable', display: 'Do not allow' },
    { value: 'allow', display: 'Direct Cancellation' },
    { value: 'request', display: 'Cancellation Request' },
]

export const RESCHEDULING_VALUES = [
    { value: 'disable', display: 'Do not allow' },
    { value: 'allow', display: 'Direct Rescheduling' },
    { value: 'request', display: 'Rescheduling Request' },
]

export const CONCURRENCY_NUM_ARRAY = Array.from(Array(8).keys()).map(num => num + 1)

export const DAYS_PRIOR_VALUES = CONCURRENCY_NUM_ARRAY.map(num => ({
    value: num.toString(),
    display: `${num} day${num === 1 ? `` : `s`} prior`,
}))
