import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import Radio, { RadioClassKey } from '@mui/material/Radio'
import classNames from 'classnames'

import { savePractice } from '../../modules/practices/actions'

import './ManageProducts.sass'

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

export type ManageSalesforceTypeProps = {
    practice: Models.Practice
    onClose: () => void
}

export type ManageSalesforceTypeDispatch = {}

export type ManageSalesforceTypeState = {
    salesforceType: 'vyne' | 'simplifeye'
    isVyneSalesforceDirty: boolean
    isDirty: boolean
}

type Props = ManageSalesforceTypeProps & ManageSalesforceTypeDispatch

const ManageSalesforceType = (props: Props) => {
    const [salesforceType, setSalesforceType] = useState<'vyne' | 'simplifeye'>(
        props.practice.isVyneCreated ? 'vyne' : props.practice.salesforceType || 'simplifeye',
    )

    const [isVyneSalesforceDirty, setIsVyneSalesforceDirty] = useState<boolean>(false)
    const [isSavingSalesforce, setIsSavingSalesforce] = useState<boolean>(false)

    const dispatch = useDispatch()

    const onSelectSalesforceType = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSalesforceType(e.target.value as 'vyne' | 'simplifeye')
        setIsVyneSalesforceDirty(true)
    }

    const onSaveSalesforce = async () => {
        if (isVyneSalesforceDirty) {
            setIsSavingSalesforce(true)
            await dispatch(savePractice(props.practice, { salesforce_type: salesforceType }))
            setIsSavingSalesforce(false)
        }
        props.onClose()
        return
    }

    const radioClassOverrides: RadioClassOverrides = {
        root: `manage-products__radio-root`,
    }

    return (
        <div className="manage-products">
            <div className="form">
                <div className="title">Salesforce Type</div>
                <div className={`manage-products__salesforce-type`}>
                    <div className={`manage-products__radio-wrapper`}>
                        <Radio
                            checked={salesforceType === 'vyne'}
                            onChange={onSelectSalesforceType}
                            name="radio-vyne-salesforce"
                            value="vyne"
                            id="vyne-salesforce-type"
                            classes={radioClassOverrides}
                            disabled={props.practice.isVyneCreated || isSavingSalesforce}
                        />
                        <div className={`manage-products__wrapper`}>
                            <label htmlFor="vyne-salesforce-type">
                                <span className={`manage-products__text`}>Vyne Salesforce</span>
                            </label>
                        </div>
                    </div>
                    <div className={`manage-products__radio-wrapper`}>
                        <Radio
                            checked={salesforceType === 'simplifeye'}
                            onChange={onSelectSalesforceType}
                            name="radio-simplifeye-salesforce"
                            value="simplifeye"
                            id="simplifeye-salesforce-type"
                            classes={radioClassOverrides}
                            disabled={props.practice.isVyneCreated || isSavingSalesforce}
                        />
                        <div className={`manage-products__wrapper`}>
                            <label htmlFor="simplifeye-salesforce-type">
                                <span className={`manage-products__text`}>Simplifeye Salesforce</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className={classNames('bottom-save-button', {
                    'can-save': isVyneSalesforceDirty && !isSavingSalesforce,
                })}
                disabled={!isVyneSalesforceDirty || isSavingSalesforce}
                onClick={onSaveSalesforce}
            >
                Save Changes
            </button>
            {Boolean(isSavingSalesforce) && (
                <div className="circular-progress-loader">
                    <CircularProgress className="circular-progress-spinner" size={70} variant="indeterminate" />
                </div>
            )}
        </div>
    )
}

export default ManageSalesforceType
