import * as React from 'react'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'

import { formatDateOfBirth } from './shared'

import './SearchResults.sass'

export type SearchResultsProps = {
    patients: Models.Patient[]
    selectedPatient?: Models.Patient
}

export type SearchResultsDispatch = {
    onSelectPatient: (patient: Models.Patient) => void
    onResetSearchResults: () => void
    onConfirmPatient: () => void
}

type Props = SearchResultsProps & SearchResultsDispatch

const moduleName = 'scheduling-search-patients-results'

const SearchResults = (props: Props) => {
    const handleSelectPatient = (patient: Models.Patient) => () => {
        props.onSelectPatient(patient)
    }

    const renderDetail = (label: string, value: string) => (
        <div className={`${moduleName}__detail`}>
            <label>{label}</label>
            <div>{value}</div>
        </div>
    )

    const { patients, selectedPatient } = props

    return (
        <div className={moduleName}>
            <p>
                Search returned {`${patients.length}`} result{patients.length > 1 ? 's' : ''}
            </p>
            <div className={`${moduleName}__cards`}>
                {patients.map(patient => {
                    const isSelectedPatient = selectedPatient && patient.id === selectedPatient.id

                    return (
                        <div
                            key={patient.id}
                            className={`
                                    ${moduleName}__patient
                                    ${isSelectedPatient ? `${moduleName}__patient--selected` : ''}
                                `}
                            onClick={handleSelectPatient(patient)}
                        >
                            <div className={`${moduleName}__checkbox`}>
                                {isSelectedPatient && <Icon>check_circle</Icon>}
                                {!isSelectedPatient && <span className={`${moduleName}__unchecked`} />}
                            </div>
                            <div className={`${moduleName}__patient-data`}>
                                <div className={`${moduleName}__name`}>
                                    {patient.firstName} {patient.lastName}
                                </div>
                                <div className={`${moduleName}__details`}>
                                    {patient?.mobilePhone && renderDetail('Mobile Phone', patient.mobilePhone)}
                                    {renderDetail('Date of Birth', formatDateOfBirth(patient.birthDate))}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={`${moduleName}__footer`}>
                <Button className={`${moduleName}__back`} onClick={props.onResetSearchResults}>
                    Back
                </Button>
                <Button className={`${moduleName}__next`} disabled={!selectedPatient} onClick={props.onConfirmPatient}>
                    Next
                </Button>
            </div>
        </div>
    )
}

export default SearchResults
