import React from 'react'
import { Icon } from '@mui/material'

import './success-message.sass'

type Props = {
    isShown: boolean
    children: any
}

const Confirmation = ({ children, isShown }: Props) => {
    return (
        <div className={`success-message success-message--${isShown ? 'shown' : 'hidden'}`}>
            <Icon>check_circle</Icon>
            <span>{children}</span>
        </div>
    )
}

export default Confirmation
