import _ from 'lodash'

import ApiV2 from '../../../ApiV2'
import { AppDispatch, AppThunk } from '../../../appStore'
import { asyncForEach } from '../../../util/asyncForEach'
import { dateStringToIso } from '../../../util/formatDate'
import { ReferralFormSections } from '../shared/enums'
import {
    createPatient,
    deletePatientMany,
    handleRequestError,
    updateAttachments,
    updatePatient,
    updateReferral,
} from '../v2actions'

// action types
export type ResetReferralForm = {
    type: 'RESET_REFERRAL_FORM'
}
export type ReceiveFormRemoveAdditionalPatient = {
    type: 'RECEIVE_FORM_REMOVE_ADDITIONAL_PATIENT'
    patientId: string
}
export type ReceiveFormEditAdditionalPatient = {
    type: 'RECEIVE_FORM_EDIT_ADDITIONAL_PATIENT'
    patientId: string
    field: string
}
export type ReceiveFormEditAdditionalPatientFinished = {
    type: 'RECEIVE_FORM_EDIT_ADDITIONAL_PATIENT_FINISHED'
    patientId: string
    uiIndex: number
}
export type ReceivePrimaryPatientUpdateFinished = {
    type: 'RECEIVE_FORM_EDIT_PRIMARY_PATIENT_FINISHED'
    patientId: string
}

export type ReceiveFormDeleteAdditionalPatientsFinished = {
    type: 'RECEIVE_FORM_DELETE_ADDITIONAL_PATIENTS_FINISHED'
    deletedPatients: ModelsV2.Referrals.ReferralPatient[]
}
export type ReceiveFormCreateAdditionalPatientsFinished = {
    type: 'RECEIVE_FORM_CREATE_ADDITIONAL_PATIENT_FINISHED'
    patientId: string
    uiIndex: number
}
export type ReceiveFormValueChange = {
    type: 'RECEIVE_FORM_VALUE_CHANGE'
    section: ReferralFormSections
    field: string
    value: string | boolean | undefined | null
}

export type ReceiveFormSectionMetaData = {
    type: 'RECEIVE_FORM_SECTION_METADATA'
    section: ReferralFormSections
    meta: {
        dirtyFields?: string[]
        errors?: ModelsV2.Errors.ErrorRecord[]
    }
}

// actions
export function removeAdditionalPatient(patientId: string): ReceiveFormRemoveAdditionalPatient {
    return {
        type: 'RECEIVE_FORM_REMOVE_ADDITIONAL_PATIENT',
        patientId,
    }
}
export function editAdditionalPatient(patientId: string, field: string): ReceiveFormEditAdditionalPatient {
    return {
        type: 'RECEIVE_FORM_EDIT_ADDITIONAL_PATIENT',
        patientId,
        field,
    }
}
export function additionalPatientDeleteFinished(
    deletedPatients: ModelsV2.Referrals.ReferralPatient[],
): ReceiveFormDeleteAdditionalPatientsFinished {
    return {
        type: 'RECEIVE_FORM_DELETE_ADDITIONAL_PATIENTS_FINISHED',
        deletedPatients,
    }
}
export function additionalPatientCreateFinished(
    patientId: string,
    uiIndex: number,
): ReceiveFormCreateAdditionalPatientsFinished {
    return {
        type: 'RECEIVE_FORM_CREATE_ADDITIONAL_PATIENT_FINISHED',
        patientId,
        uiIndex,
    }
}
export function additionalPatientEditFinished(
    patientId: string,
    uiIndex: number,
): ReceiveFormEditAdditionalPatientFinished {
    return {
        type: 'RECEIVE_FORM_EDIT_ADDITIONAL_PATIENT_FINISHED',
        patientId,
        uiIndex,
    }
}
export function receivePrimaryPatientUpdateFinished(patientId: string): ReceivePrimaryPatientUpdateFinished {
    return {
        type: 'RECEIVE_FORM_EDIT_PRIMARY_PATIENT_FINISHED',
        patientId,
    }
}

export function resetReferralForm(): ResetReferralForm {
    return {
        type: 'RESET_REFERRAL_FORM',
    }
}

export function receiveFormValueChange(
    section: ReferralFormSections,
    field: string,
    value: string | boolean | undefined | null,
): ReceiveFormValueChange {
    return {
        type: 'RECEIVE_FORM_VALUE_CHANGE',
        section,
        field,
        value,
    }
}

export function receiveFormSectionMetaData(
    section: ReferralFormSections,
    meta: {
        dirtyFields?: string[]
        errors?: ModelsV2.Errors.ErrorRecord[]
    },
): ReceiveFormSectionMetaData {
    return {
        type: 'RECEIVE_FORM_SECTION_METADATA',
        section,
        meta,
    }
}

// api actions
export function saveUpdateReferral(referralFormData: ModelsV2.Referrals.ReferralForm): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const { value: referral } = referralFormData.referral
        let updateReferralStatusException = false
        return await Promise.all([
            dispatch(updateReferralReferralForm(referralFormData.referral)),

            (() => {
                /* in case the referral is created but primarry patient is not 
                 try to create the patient again and not update it */
                if (referralFormData.primaryPatient.value.id) {
                    return dispatch(updatePrimaryPatientForm(referralFormData.primaryPatient))
                } else if (referral.id) {
                    updateReferralStatusException = true
                    return dispatch(createPrimaryPatientForm(referral.id, referralFormData.primaryPatient))
                }
                return null
            })(),

            dispatch(updateAdditionaPatientsForm(referral, referralFormData.otherPatients)),
            dispatch(updateAttachmentsForm(referral, referralFormData.attachments)),
        ]).then(responses => {
            const allStatus = responses.every(responseStatus => {
                return responseStatus !== null && responseStatus !== false
            })

            // used in manual referral create when pripary patients fails but referral created is succeess
            // form than trys to do an update so we need to change referral status
            if (updateReferralStatusException && referral.id) {
                dispatch(
                    updateReferral(referral.id, {
                        amplify_status: {
                            value: 'complete',
                        },
                    }),
                )
            }

            return allStatus
        })
    }
}
export function saveNewManualReferral(
    isTest: boolean,
    referralFormData: ModelsV2.Referrals.ReferralForm,
): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const referral = await dispatch(saveReferralReferralForm(isTest, referralFormData.referral)).then(response => {
            if (response !== null) {
                // Add referral id to the form data so UPDATE can be called in case user will hit save again instead of CREATE again
                dispatch(receiveFormValueChange(ReferralFormSections.REFERRAL, 'id', response.id))
                dispatch(
                    receiveFormSectionMetaData(ReferralFormSections.REFERRAL, {
                        dirtyFields: [],
                    }),
                )
            }
            return response
        })

        // submit all other data parts with new referral id
        if (referral !== null && referral.id) {
            return await Promise.all([
                dispatch(createPrimaryPatientForm(referral.id, referralFormData.primaryPatient)),
                dispatch(updateAdditionaPatientsForm(referral, referralFormData.otherPatients)),
                dispatch(updateAttachmentsForm(referral, referralFormData.attachments)),
            ]).then(responses => {
                return responses.every(responseStatus => {
                    return responseStatus !== null && responseStatus !== false
                })
            })
        }

        return false
    }
}
export function createNewReferral(
    referralData: ModelsV2.Referrals.ReferralV2ManualCreate,
): AppThunk<Promise<ModelsV2.Referrals.ReferralV2 | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await ApiV2.Referrals.postManualReferral(referralData)
            return response
        } catch (e) {
            handleRequestError(dispatch, e, ReferralFormSections.REFERRAL)
            return null
        }
    }
}

export function createNewTestReferral(
    referralData: ModelsV2.Referrals.ReferralV2ManualCreate,
): AppThunk<Promise<ModelsV2.Referrals.ReferralV2 | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await ApiV2.Referrals.postTestReferral(referralData)
            return response
        } catch (e) {
            handleRequestError(dispatch, e, ReferralFormSections.REFERRAL)
            return null
        }
    }
}

// form section submit functions
function updateReferralReferralForm(
    referralFormData: ModelsV2.Referrals.ReferralReferralForm,
): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const { value: referral, dirtyFields: referralDirtyFields } = referralFormData

        if (referralDirtyFields.length > 0 && referral.id) {
            const modifiedReferralFields = _.pick(referral, referralDirtyFields) as Partial<
                ModelsV2.Referrals.ReferralV2
            >

            const referralSuccess = await dispatch(updateReferral(referral.id, modifiedReferralFields)).then(
                response => {
                    if (response !== null) {
                        dispatch(
                            receiveFormSectionMetaData(ReferralFormSections.REFERRAL, {
                                dirtyFields: [],
                            }),
                        )
                    }
                    return response
                },
            )
            return referralSuccess !== null
        }

        return true
    }
}
function createPrimaryPatientForm(
    referralId: string,
    patient: ModelsV2.Referrals.ReferralPrimaryPatientForm,
): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const { value: primaryPatient, dirtyFields: primaryPatientDirtyFields } = patient

        if (primaryPatientDirtyFields.length > 0 && referralId) {
            const primaryPatientInfo = {
                ...primaryPatient,
                date_of_birth: dateStringToIso(primaryPatient.date_of_birth as string),
                policy_holder_date_of_birth: dateStringToIso(primaryPatient.policy_holder_date_of_birth as string),
            }

            const submitdata = {
                ..._.pick(primaryPatientInfo, primaryPatientDirtyFields),
                is_primary: true,
            }
            return await dispatch(createPatient(referralId, true, submitdata)).then(primaryPatientUpdated => {
                if (primaryPatientUpdated !== null && Boolean(primaryPatientUpdated.id)) {
                    dispatch(
                        receiveFormValueChange(ReferralFormSections.PRIMARY_PATIENT, 'id', primaryPatientUpdated.id),
                    )
                    dispatch(receivePrimaryPatientUpdateFinished(primaryPatientUpdated.id))

                    return true
                }
                return false
            })
        }
        return true
    }
}
function updatePrimaryPatientForm(patient: ModelsV2.Referrals.ReferralPrimaryPatientForm): AppThunk<Promise<boolean>> {
    return async (dispatch: AppDispatch) => {
        const { value: primaryPatient, dirtyFields: primaryPatientDirtyFields } = patient
        if (primaryPatientDirtyFields.length > 0 && primaryPatient.id) {
            const primaryPatientInfo = {
                ...primaryPatient,
                is_primary: true,
                date_of_birth: dateStringToIso(primaryPatient.date_of_birth as string),
                policy_holder_date_of_birth: dateStringToIso(primaryPatient.policy_holder_date_of_birth as string),
            }
            return await dispatch(
                updatePatient(primaryPatient.id, true, _.pick(primaryPatientInfo, primaryPatientDirtyFields)),
            ).then(primaryPatientUpdated => {
                if (primaryPatientUpdated !== null && Boolean(primaryPatientUpdated.id)) {
                    dispatch(receivePrimaryPatientUpdateFinished(primaryPatientUpdated.id))
                    return true
                }
                return false
            })
        }
        return true
    }
}
function updateAdditionaPatientsForm(
    referral: Partial<ModelsV2.Referrals.ReferralV2>,
    patients: ModelsV2.Referrals.ReferralOtherPatientsForm,
): AppThunk<Promise<boolean>> {
    const referralId = referral.id
    const { value: otherPatients } = patients
    const newPatients = otherPatients.filter(patient => patient.id === '')
    const editedPatients = patients.editedPatients
    const removedPatients = patients.removedPatients.filter(patient => patient !== '')

    return async (dispatch: AppDispatch) => {
        const patientsRequests = []

        // create new patients
        if (newPatients.length > 0 && referralId) {
            await asyncForEach(otherPatients, async (patient, index) => {
                if (patient.id === '') {
                    const request = await dispatch(
                        createPatient(
                            referralId,
                            false,
                            {
                                is_primary: false,
                                first_name: patient.first_name,
                                last_name: patient.last_name,
                                ...(!_.isEmpty(patient.reason) && { reason: patient.reason }),
                                ...(!_.isEmpty(patient.date_of_birth) && {
                                    date_of_birth: dateStringToIso(patient.date_of_birth as string),
                                }),
                            },
                            index,
                        ),
                    ).then(async addedPatient => {
                        if (addedPatient !== null && Boolean(addedPatient.id)) {
                            await dispatch(additionalPatientCreateFinished(addedPatient.id, index))
                            return true
                        }
                        return false
                    })
                    patientsRequests.push(request)
                }
            })
        }

        // edit patients
        if (editedPatients.length > 0 && referralId) {
            await asyncForEach(otherPatients, async (patient, index) => {
                const editedPatientLog = editedPatients.find(editedPatient => editedPatient.patientId === patient.id)

                if (patient.id && editedPatientLog) {
                    const updateData = {
                        ...patient,
                        date_of_birth: dateStringToIso(patient.date_of_birth as string),
                        is_primary: false,
                    }
                    const request = await dispatch(
                        updatePatient(patient.id, false, _.pick(updateData, editedPatientLog.dirtyFields), index),
                    ).then(async updatedPatient => {
                        if (updatedPatient !== null && Boolean(updatedPatient.id)) {
                            await dispatch(additionalPatientEditFinished(updatedPatient.id, index))
                            return true
                        }
                        return false
                    })
                    patientsRequests.push(request)
                }
            })
        }

        // remove patients
        if (removedPatients.length > 0 && referralId) {
            const request = await dispatch(deletePatientMany(referralId, removedPatients)).then(
                async deletedPatient => {
                    if (deletedPatient !== null && deletedPatient.length > 0) {
                        await dispatch(additionalPatientDeleteFinished(deletedPatient))
                        return true
                    }
                    return false
                },
            )
            patientsRequests.push(request)
        }

        if (patientsRequests.length > 0) {
            return await Promise.all(patientsRequests).then(responses => {
                return responses.every(responseSuccess => responseSuccess === true)
            })
        } else {
            return true
        }
    }
}
function updateAttachmentsForm(
    referral: Partial<ModelsV2.Referrals.ReferralV2>,
    attachments: ModelsV2.Referrals.ReferralAttachmentsForm,
): AppThunk<Promise<boolean | null>> {
    return async (dispatch: AppDispatch) => {
        if (referral.id) {
            return await dispatch(
                updateAttachments(
                    referral.id,
                    Object.keys(attachments.removedAttachments.value),
                    Object.keys(attachments.addedAttachments.value),
                ),
            )
        }
        return null
    }
}
function saveReferralReferralForm(
    isTest: boolean,
    referralFormData: ModelsV2.Referrals.ReferralReferralForm,
): AppThunk<Promise<ModelsV2.Referrals.ReferralV2 | null>> {
    return async (dispatch: AppDispatch) => {
        const { value: referral } = referralFormData

        const referralFields = {
            inbound_handler_id: referral.inbound_handler_id,
            location_id: referral.location_id,
            transcript: referral.transcript,
        } as ModelsV2.Referrals.ReferralV2ManualCreate

        if (isTest) {
            const referralSuccess = await dispatch(createNewTestReferral(referralFields))
            return referralSuccess
        } else {
            const referralSuccess = await dispatch(createNewReferral(referralFields))
            return referralSuccess
        }
    }
}
