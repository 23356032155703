import { useEffect, useRef } from 'react'
import Checkbox from '@mui/material/Checkbox'

type Props = {
    checked: boolean
    label: any
    disabled?: boolean
    className?: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AllowSidebookingCheckbox = ({ className, label, disabled, checked, onChange }: Props) => {
    const checkboxRef = useRef<HTMLButtonElement>(null)
    const labelRef = useRef<HTMLLabelElement>(null)

    useEffect(() => {
        const checkboxRefCurrent = checkboxRef.current

        const handleSetCheckboxHovered = () => {
            labelRef.current?.classList.add('custom-multiselect__label-hovered')
        }

        checkboxRefCurrent?.addEventListener('mouseover', handleSetCheckboxHovered)

        return () => {
            checkboxRefCurrent?.removeEventListener('mouseover', handleSetCheckboxHovered)
        }
    }, [])

    useEffect(() => {
        const checkboxRefCurrent = checkboxRef.current

        const handleRemoveCheckboxHovered = () => {
            labelRef.current?.classList.remove('custom-multiselect__label-hovered')
        }

        checkboxRefCurrent?.addEventListener('mouseout', handleRemoveCheckboxHovered)

        return () => {
            checkboxRefCurrent?.removeEventListener('mouseout', handleRemoveCheckboxHovered)
        }
    }, [])

    return (
        <>
            <label ref={labelRef} className="custom-multiselect__label">
                {label}
            </label>
            <Checkbox
                className={className}
                ref={checkboxRef}
                color="primary"
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />
        </>
    )
}

export default AllowSidebookingCheckbox
