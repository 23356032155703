import { connect } from 'react-redux'

import { RootState } from '../../../../appReducer'
import { fetchPaymentProcessingData, savePaymentProcessingData } from '../../actions'

import LocationPaymentsRates, { LocationPaymentsRatesProps } from './LocationPaymentsRates'

const mapStateToProps = (state: RootState, props: any) => ({
    practice: props.practice,
    // practiceFeeData: state.payments.practiceFees[props.practice.id],
})

export default connect(mapStateToProps, { fetchPaymentProcessingData, savePaymentProcessingData })(
    LocationPaymentsRates,
)
