import * as React from 'react'
import { ColorResult } from 'react-color'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'

import ColorPicker from './ColorPicker'

import './ColorPickerButton.sass'

export type ColorPickerButtonProps = {
    color: string
    label: string
    id: string
    updateCustomColor: (newColor: string) => void
}

export type ColorPickerButtonState = {
    customColor?: string
    isShowingColorPicker: boolean
}

export default class ColorPickerButton extends React.Component<ColorPickerButtonProps, ColorPickerButtonState> {
    constructor(props: ColorPickerButtonProps) {
        super(props)

        this.state = {
            isShowingColorPicker: false,
        }
    }

    onToggleShowColorPicker = () => {
        this.setState(prevState => ({
            isShowingColorPicker: !prevState.isShowingColorPicker,
            customColor: undefined,
        }))
    }

    onChangeSwatchColor = (colorResult: ColorResult) => {
        this.setState({
            customColor: colorResult.hex,
        })
    }

    onUpdateColor = () => {
        if (this.state.customColor && this.state.customColor !== this.props.color) {
            this.props.updateCustomColor(this.state.customColor)
        }

        this.onToggleShowColorPicker()
    }

    render() {
        const { color, label, id } = this.props
        const { customColor, isShowingColorPicker } = this.state
        const displayColor = customColor || color
        const customStyle = {
            backgroundColor: displayColor,
        }

        return (
            <div className={classNames({ 'show-picker': isShowingColorPicker })}>
                <div className="color-picker-button-wrapper">
                    <button className="color-picker-button" id={id} onClick={this.onToggleShowColorPicker}>
                        <div className="current-color" style={customStyle} />
                        <div>
                            <Icon className="arrow-drop-down">arrow_drop_down</Icon>
                        </div>
                    </button>
                    <label className="color-picker-label" htmlFor={id}>
                        {label}
                    </label>
                    {isShowingColorPicker && (
                        <ColorPicker
                            color={displayColor}
                            onChange={() => {
                                return false
                            }}
                            onChangeComplete={this.onChangeSwatchColor}
                            cancelColorPicker={this.onToggleShowColorPicker}
                            updateColor={this.onUpdateColor}
                        />
                    )}
                </div>
            </div>
        )
    }
}
