import {
    ExistingPatientAppointmentBooking,
    NewPatientAppointmentBooking,
    PatientSearchCriteria,
} from '../../models/BookingAppointment'
import { ConnectBooking } from '../../models/Connect'
import { mapAppointment, mapPatients, mapReverseBookingDetails } from '../mappers'
import ApiService from '../service'

export default class PatientsService extends ApiService {
    readonly ModulePath = 'api'

    async postAppointment(
        location: Models.PracticeLocation,
        procedure: Models.Procedure,
        appointment: NewPatientAppointmentBooking | ExistingPatientAppointmentBooking,
    ) {
        const { data } = await this.fetch(
            `admin/scheduling/v1/location/${location.id}/procedure/${procedure.id}/appointment`,
            {
                method: 'POST',
                body: JSON.stringify(appointment),
            },
        )
        return mapAppointment(data)
    }

    async getPatients(practice: Models.Practice, searchCriteria: PatientSearchCriteria) {
        const { data } = await this.fetch(
            `admin/scheduling/v1/practice/${practice.id}/patient`,
            {},
            {
                first_name: searchCriteria.firstName,
                last_name: searchCriteria.lastName,
                birth_date: searchCriteria.dateOfBirth,
                location_id: searchCriteria.locationId,
            },
        )
        return mapPatients(data)
    }

    async postBookTeleConnect(practice: Models.Practice, webCode: Models.WebCode, connectBooking: ConnectBooking) {
        const {
            data: { booked },
        } = await this.fetch(`admin/amplify/v1/chats/${connectBooking.chat.id}/connect/book`, {
            method: 'POST',
            body: JSON.stringify(mapReverseBookingDetails(practice, webCode, connectBooking)),
        })
        return { booked }
    }
}
