import { LocationInformation } from '../../../models/PracticeSurvey'
import { ValidatorPreset } from '../../../modules/practices/locations/edit-survey/shared/validator'

export const mapLocationInformation = (
    locationInformation: ApiV2.Survey.LocationInformation,
    location: Models.PracticeLocation,
): LocationInformation => {
    return {
        isValid: true,
        address: {
            id: locationInformation.id,
            type: locationInformation.type,
            unit: {
                isRequired: false,
                isValid: true,
                value: locationInformation.unit ?? '',
            },
            street: {
                isRequired: true,
                isValid: true,
                value: locationInformation.street ?? '',
            },
            street2: {
                isRequired: false,
                isValid: true,
                value: locationInformation.street2 ?? '',
            },
            city: {
                isRequired: true,
                isValid: true,
                value: locationInformation.city ?? '',
            },
            state: {
                isRequired: true,
                isValid: true,
                value: locationInformation.state ?? '',
            },
            zip: {
                isRequired: true,
                isValid: true,
                value: locationInformation.zip ?? '',
            },
            country_code_iso_alpha_2: {
                isRequired: true,
                isValid: true,
                value: locationInformation.country_code_iso_alpha_2 ?? '',
            },
            lat: {
                isRequired: true,
                isValid: true,
                value: locationInformation.lat ? `${locationInformation.lat}` : '',
                validators: [ValidatorPreset.LATITUDE],
            },
            lng: {
                isRequired: true,
                isValid: true,
                value: locationInformation.lng ? `${locationInformation.lng}` : '',
                validators: [ValidatorPreset.LONGITUDE],
            },
            timezone: {
                isRequired: true,
                isValid: true,
                value: location.timezone ?? '',
            },
        },
    }
}
