import { PatientDraft } from '../../models/BookingAppointment'

export interface ApiPatient extends PatientDraft {
    id: string
    hasAppointmentsInLast7Days?: boolean
    last_visit_date?: string
}

export function mapPatient(patient: ApiPatient): Models.Patient {
    return {
        id: patient.id,
        firstName: patient.first_name,
        lastName: patient.last_name,
        birthDate: patient.birth_date,
        middieInitial: patient.middie_initial,
        mobilePhone: patient.mobile_phone,
        emailAddress: patient.email_address,
        gender: patient.gender,
        homePhone: patient.home_phone,
    }
}

export function mapPatients(patients: ApiPatient[]): Models.Patient[] {
    if (!Array.isArray(patients)) {
        return []
    }
    return patients.map((patient: ApiPatient) => mapPatient(patient))
}
