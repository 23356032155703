import React from 'react'
import Grid from '@mui/material/Grid'

import { PracticeLocationReschedulingConfiguration } from '../../../models/v2/Practice'

import CustomCheckbox from './CustomCheckbox'

const moduleName = 'cancel-reschedule-settings-locations-list'

type Props = {
    location: ApiV2.Practice.PracticeLocationFull
    locationSetting: PracticeLocationReschedulingConfiguration | undefined
    onUpdateSettings: (
        key: string,
        value: string | number | boolean,
        locationSetting: PracticeLocationReschedulingConfiguration | undefined,
        locationId: string,
    ) => void
    useGlobalSettingsChecked: boolean
    practiceSettingsConcurrencyNum: number
    hasGlobalSettings: boolean
}

const AllowSidebookingCheckbox = ({
    useGlobalSettingsChecked,
    hasGlobalSettings,
    practiceSettingsConcurrencyNum,
    onUpdateSettings,
    locationSetting,
    location,
}: Props) => {
    return (
        <Grid
            item={true}
            xs={4}
            sm={3}
            md={2}
            lg={1}
            className={`${moduleName}__table-item details ${moduleName}__checkbox`}
        >
            <CustomCheckbox
                disabled={useGlobalSettingsChecked}
                checked={
                    locationSetting?.concurrency_num === undefined || useGlobalSettingsChecked
                        ? hasGlobalSettings
                            ? practiceSettingsConcurrencyNum === 2
                            : false
                        : locationSetting?.concurrency_num === 2
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                        onUpdateSettings('concurrency_num', 2, locationSetting, location.id)
                    } else {
                        onUpdateSettings('concurrency_num', 1, locationSetting, location.id)
                    }
                }}
                label={
                    <>
                        {' '}
                        Allow <br />
                        Sidebooking
                    </>
                }
            />
        </Grid>
    )
}

export default AllowSidebookingCheckbox
