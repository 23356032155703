import { isValidPhoneNumber } from 'react-phone-number-input'
import validator from 'validator'

import { isDobDate } from './isDateValid'

interface ValidatorOptions {
    errorMessage?: string
    warningMessage?: string
    countryCode?: string
}

interface LengthValidatorOptions extends ValidatorOptions {
    min?: number
    max?: number
}

export interface ValidatorObj {
    errorMessage: string
    warningMessage?: string
    type(value: string | string[]): boolean
    warningType?: (value: string | string[]) => boolean
}

export interface FormElement {
    value?: string | string[]
    validators?: Array<ValidatorObj>
    firstErrorMessage?: string
    isValid: boolean
    isDirty: boolean
    isOptional?: boolean
    isPossiblyInvalid?: boolean
}

export interface FormFieldElement extends FormElement {
    value: string
}

export interface FormSelectElement extends FormElement {
    value: string[]
}

export interface FormCheckBoxElement extends FormElement {
    value: string
    label: string
    checked: boolean
}

export const isRequired = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) => validator.isLength(value, { min: 1, max: undefined }),
    errorMessage: (options && options.errorMessage) || 'This field is required.',
})

export const isNotEmpty = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string[]) => value && value.length > 0,
    errorMessage: (options && options.errorMessage) || 'At least one item is required.',
})

export const isEmail = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) => validator.isEmail(value),
    errorMessage: (options && options.errorMessage) || 'Email address is not valid.',
})

export const isBirthDate = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) => isDobDate(value),
    errorMessage: (options && options.errorMessage) || 'Invalid date value',
})

export const isBasicPhoneNumber = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) => value.replace(/\D/g, '').length === 11,
    errorMessage: (options && options.errorMessage) || 'Invalid phone number',
})

export const isBasicPhoneNumberWithWarning = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) =>
        Boolean(options?.countryCode && options?.countryCode !== 'US')
            ? isValidPhoneNumber(value, options?.countryCode || 'US')
            : value.replace(/\D/g, '').length === 11,
    errorMessage: (options && options.errorMessage) || 'Invalid phone number',
    warningType: (value: string) => {
        return isValidPhoneNumber(value, options?.countryCode || 'US')
    },
    warningMessage: (options && options.warningMessage) || 'Phone # might be invalid',
})

export const isUSPhoneNumber = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) => {
        return isValidPhoneNumber(value, 'US')
    },
    errorMessage: (options && options.errorMessage) || 'Invalid phone number',
})

export const isValidPhoneNumberCheck = (options?: ValidatorOptions): ValidatorObj => ({
    type: (value: string) => {
        return isValidPhoneNumber(value, options?.countryCode || 'US')
    },
    errorMessage: (options && options.errorMessage) || 'Invalid phone number',
})

export const isURL = (options?: ValidatorOptions & validator.IsURLOptions): ValidatorObj => ({
    type: (value: string) => validator.isURL(value, options),
    errorMessage: (options && options.errorMessage) || 'URL is not valid.',
})

export const isCoordinate = (options?: LengthValidatorOptions): ValidatorObj => {
    return {
        type: (value: string) => {
            const coordinate = parseFloat(value)
            if (
                isNaN(coordinate) ||
                coordinate < (options?.min ?? -Infinity) ||
                coordinate > (options?.max ?? Infinity)
            ) {
                return false
            }
            return true
        },
        errorMessage: (options && options.errorMessage) || 'Coordinate is not valid.',
    }
}

export const isValidLength = (options?: LengthValidatorOptions): ValidatorObj => ({
    type: (value: string) => validator.isLength(value, { min: options?.min ?? 0, max: options?.max ?? 32 }),
    errorMessage: (options && options.errorMessage) || 'Length is not valid.',
})

export function validate<T>(formElements: T) {
    const runValidators = (elm: FormElement) => {
        if (Array.isArray(elm)) {
            elm.forEach(el => {
                if (el.validators) {
                    runValidators(el)
                }
                Object.keys(el).forEach(formElement => {
                    const e = el[formElement]
                    runValidators(e)
                })
            })
        } else {
            if (!elm || !elm.validators) {
                return
            }
            if ((elm.isOptional && !elm.value) || !elm.validators.length) {
                elm.firstErrorMessage = undefined
                elm.isValid = true
            } else {
                const errorMessages = elm.validators.reduce((list: string[], v: ValidatorObj) => {
                    if (elm.value === undefined || (elm.value !== undefined && !v.type(elm.value))) {
                        return [...list, v.errorMessage]
                    }
                    return list
                }, [])

                if (errorMessages.length) {
                    elm.firstErrorMessage = errorMessages[0]
                    elm.isValid = false
                } else {
                    elm.firstErrorMessage = undefined
                    elm.isValid = true
                }

                const warningMessages = elm.validators.reduce((list: string[], v: ValidatorObj) => {
                    if (
                        elm.value === undefined ||
                        (elm.value !== undefined && v.warningType && !v.warningType(elm.value))
                    ) {
                        return [...list, v.warningMessage]
                    }
                    return list
                }, [])

                if (warningMessages.length) {
                    elm.isPossiblyInvalid = false
                } else {
                    elm.isPossiblyInvalid = true
                }
            }
        }
    }

    Object.keys(formElements).forEach(formElement => {
        const elm = formElements[formElement]
        runValidators(elm)
    })

    const flattenFormElements: Array<FormElement> = []
    Object.keys(formElements).forEach((formElement: string) => {
        const elm = formElements[formElement]
        if (Array.isArray(elm)) {
            elm.forEach(item => {
                if (item.isValid !== undefined) {
                    flattenFormElements.push(item)
                }
                Object.keys(item).forEach(key => {
                    const nestedFormElement = item[key]
                    if (nestedFormElement?.isValid !== undefined) {
                        flattenFormElements.push(nestedFormElement)
                    }
                })
            })
        } else {
            flattenFormElements.push(elm)
        }
    })

    const isFormValid = flattenFormElements.every(elm => elm.isValid === true)
    return isFormValid
}

export const setFieldRequired = (
    formElement: FormFieldElement,
    isFieldRequired: boolean = true,
    validators: ValidatorObj[] = [],
) => {
    formElement.isOptional = !isFieldRequired
    formElement.validators = validators

    if (isFieldRequired) {
        formElement.validators.push(isRequired())
    }
    return formElement
}
