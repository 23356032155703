import React from 'react'
import MomentUtils from '@date-io/moment'
import { createTheme } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import Grid, { GridSpacing } from '@mui/material/Grid'

import { mainBlue } from '../../../shared/styles/colors'

import MaskedInlineDatepicker from './MaskedInlineDatepicker'

import './CustomDateRange.sass'

export const calendarTheme = createTheme({
    overrides: {
        MuiInputAdornment: {
            positionEnd: {
                marginLeft: '0',
            },
        },
        MuiIconButton: {
            root: {
                color: '#303030',
                marginRight: '1px',
                padding: '7px',
            },
        },
        MuiInputBase: {
            root: {
                backgroundColor: '#fff',
                border: '1px #cfdae9 solid',
                borderRadius: '6px',
                color: '#303030',
                marginRight: '20px',
                width: '155px',
                '&$focused': {
                    borderColor: mainBlue,
                },
            },
            input: {
                padding: '10.5px 11px',
            },
        },
        MuiInputLabel: {
            outlined: {
                color: '#737373 !important',
                fontSize: '12px !important',
                marginBottom: '6px',
                position: 'static',
                transform: 'none !important',
            },
        },
        MuiOutlinedInput: {
            adornedEnd: {
                paddingRight: '0',
            },
            input: {
                padding: '10.5px 11px',
            },
            notchedOutline: {
                border: '0',
                display: 'none',
            },
        },
    },
})
interface DateData {
    value: string
    isValid: boolean
    errorMessage?: string
}

interface DateRangeProps {
    labelFrom?: string | React.ReactElement<any>
    labelTo?: string | React.ReactElement<any>
    spacing?: GridSpacing
    disablePast?: boolean
    disableFuture?: boolean
    disabled?: boolean
    dateFrom: DateData
    dateTo: DateData
    onChangeDateFrom: (dateFrom: any) => void
    onChangeDateTo: (dateTo: any) => void
}

export const DATE_FORMAT = 'MM/DD/YYYY'
export const DATE_MASK = '99/99/9999'

export const moduleName = 'custom-date-range'

const CustomDateRange = (props: DateRangeProps) => {
    const {
        labelFrom,
        labelTo,
        dateFrom,
        dateTo,
        onChangeDateFrom,
        onChangeDateTo,
        disablePast = false,
        disabled = false,
        spacing = 2,
    } = props

    const handleDateChange = (type: 'from' | 'to') => (dateObj: any) => {
        if (type === 'from') {
            onChangeDateFrom(dateObj)
        }
        if (type === 'to') {
            onChangeDateTo(dateObj)
        }
    }

    return (
        <div className={`${moduleName}`}>
            <Grid container={true} className={`${moduleName}__wrapper`}>
                <Grid container={true} className={`${moduleName}__grid-row`} spacing={spacing}>
                    <Grid item={true} xs={6} className={`${moduleName}__date-picker`}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={calendarTheme}>
                                <MaskedInlineDatepicker
                                    keyboard={true}
                                    dateMask={DATE_MASK}
                                    dateFormat={DATE_FORMAT}
                                    error={!dateFrom.isValid}
                                    label={labelFrom}
                                    placeholder="From"
                                    errorMessage={dateFrom.errorMessage}
                                    value={dateFrom.value}
                                    onInputChange={handleDateChange('from')}
                                    onChange={handleDateChange('from')}
                                    disablePast={disablePast}
                                    disabled={disabled}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item={true} xs={6} className={`${moduleName}__date-picker`}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <ThemeProvider theme={calendarTheme}>
                                <MaskedInlineDatepicker
                                    keyboard={true}
                                    dateMask={DATE_MASK}
                                    dateFormat={DATE_FORMAT}
                                    error={!dateTo.isValid}
                                    label={labelTo || ''}
                                    placeholder="To"
                                    errorMessage={dateTo.errorMessage}
                                    value={dateTo.value}
                                    onInputChange={handleDateChange('to')}
                                    onChange={handleDateChange('to')}
                                    disablePast={disablePast}
                                    disabled={disabled}
                                />
                            </ThemeProvider>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default CustomDateRange
