export const validateTerminal = (
    nickname: Models.LocationPaymentsTerminalInfo['nickname'],
    readerSerial: Models.LocationPaymentsTerminalInfo['readerSerial'],
    pairingCode: Models.LocationPaymentsTerminalInfo['pairingCode'],
    s700Code: Models.LocationPaymentsTerminalInfo['s700Code'],
    uuid: Models.LocationPaymentsTerminalInfo['uuid'],
): boolean => {
    let isValid = true

    const pairingCodeRegex = /[a-zA-Z0-9]{1,15}-[a-zA-Z0-9]{1,15}-[a-zA-Z0-9]{1,15}/g
    const uuidRegex = /[a-zA-Z0-9]{16,32}/g

    isValid = nickname !== '' && (((!!pairingCode || readerSerial !== '') && (!!pairingCode || !!uuid)) || !!s700Code)

    if (!!pairingCode && !pairingCode.match(pairingCodeRegex)) {
        isValid = false
    }

    if (!!uuid && !uuid.match(uuidRegex)) {
        isValid = false
    }

    return isValid
}
