import { HeartbeatProvider } from '../../models/v2/Heartbeat'
import { HeartbeatPracticeLocationProvider } from '../../models/v2/Practice'
import ApiService from '../service'

export default class HeartbeatService extends ApiService {
    async getHeartbeatDownloadMonthlyCheckupCsv(year: number, month: number) {
        return this.fetch(
            `integrations/heartbeat/monthly-checkup?year=${year}&month=${month}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )
    }

    async getHeartbeatProviders() {
        const data = (await this.fetch(
            `integrations/heartbeat/providers`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<HeartbeatProvider[]>

        return data
    }

    async getIntegrationsPracticeLocationProviders(practiceId: string) {
        const practiceLocationProviders = (await this.fetch(
            `integrations/heartbeat/practice/${practiceId}/location-providers`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ApiV2.Heartbeat.HeartbeatPracticeLocationProvider[]>

        return practiceLocationProviders
    }

    async postAgentLocationProviders(externalLocationId: number, providerId: string, payload: any) {
        const practiceLocationProviders = (await this.fetch(
            `integrations/heartbeat/agent-location/${externalLocationId}/providers/${providerId}/register`,
            {
                method: 'POST',
                body: JSON.stringify(payload),
            },
            undefined,
            undefined,
            false,
        )) as ApiV2.StructuredResponse<HeartbeatPracticeLocationProvider[]>

        return practiceLocationProviders
    }

    async patchAgentLocationProviders(externalLocationId: number, providerId: string, payload: any) {
        const practiceLocationProviders = (await this.fetch(
            `integrations/heartbeat/agent-location/${externalLocationId}/providers/${providerId}`,
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
            },
            undefined,
            undefined,
            false,
        )) as ApiV2.StructuredResponse<HeartbeatPracticeLocationProvider[]>

        return practiceLocationProviders
    }

    async generatePandLZip(name: string) {
        const data = await this.fetch(
            `integrations/heartbeat/reports/${name}/generate`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )

        return data
    }
}
