import ApiService from '../service'

const API_PAYMENTS_HOST = process.env.REACT_APP_API_PAYMENTS_HOST

export default class PaymentsService extends ApiService {
    readonly ModulePath = 'api'

    async loadPayProcessingData(
        practiceId: string,
        locationId: string,
    ): Promise<Api.PaymentsResponse<Api.PaymentProcessingResponse>> {
        const structuredResponse = await this.fetch(
            `v1/practices/${practiceId}/locations/${locationId}/practiceLocationFee`,
            {},
            {},
            API_PAYMENTS_HOST,
        )

        if (
            (structuredResponse.errors && structuredResponse.errors.length > 0) ||
            (structuredResponse.data.error && structuredResponse.data.error.statusCode >= 400)
        ) {
            throw structuredResponse
        }

        return structuredResponse
    }

    async postLocationPayProcessingData(
        practiceId: string,
        locationId: string,
        merchantFlatFee: number,
        merchantProcessingRate: number,
        terminalFlatFee: number,
        terminalProcessingRate: number,
        debitFlatFee: number,
        debitProcessingRate: number,
        insuranceFlatFee: number,
        insuranceProcessingRate: number,
        achFlatFee: number,
        achProcessingRate: number,
        membershipPlanProcessingRate: number,
        monthlyPlatformFee: number,
        monthlyTerminalFee: number,
        prepaidProcessingRate: number,
        prepaidFlatFee: number,
        accountType: Models.PaymentProcessingType,
    ): Promise<Api.PaymentsResponse<Api.PaymentProcessingUpdateResponse>> {
        const structuredResponse = await this.fetch(
            `v1/practices/${practiceId}/locations/${locationId}/practiceLocationFee`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    accountType,
                    flatFee: merchantFlatFee,
                    processingRate: merchantProcessingRate,
                    terminalFlatFee,
                    terminalProcessingRate,
                    debitFlatFee,
                    debitProcessingRate,
                    insuranceFlatFee,
                    insuranceProcessingRate,
                    achFlatFee,
                    achProcessingRate,
                    membershipPlanProcessingRate,
                    monthlyPlatformFee,
                    monthlyTerminalFee,
                    prepaidProcessingRate,
                    prepaidFlatFee,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )

        return structuredResponse
    }

    async postPracticePayProcessingData(
        practiceId: string,
        merchantFlatFee: number,
        merchantProcessingRate: number,
        terminalFlatFee: number,
        terminalProcessingRate: number,
        debitFlatFee: number,
        debitProcessingRate: number,
        insuranceFlatFee: number,
        insuranceProcessingRate: number,
        achFlatFee: number,
        achProcessingRate: number,
        membershipPlanProcessingRate: number,
        monthlyPlatformFee: number,
        monthlyTerminalFee: number,
        prepaidProcessingRate: number,
        prepaidFlatFee: number,
        type: Models.PaymentProcessingType,
    ): Promise<Api.PaymentsResponse<Api.PaymentProcessingUpdateResponse>> {
        const structuredResponse = await this.fetch(
            `v1/practices/${practiceId}/practiceLocationFee`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    accountType: type,
                    flatFee: merchantFlatFee,
                    processingRate: merchantProcessingRate,
                    terminalFlatFee,
                    terminalProcessingRate,
                    debitFlatFee,
                    debitProcessingRate,
                    insuranceFlatFee,
                    insuranceProcessingRate,
                    achFlatFee,
                    achProcessingRate,
                    membershipPlanProcessingRate,
                    monthlyPlatformFee,
                    monthlyTerminalFee,
                    prepaidProcessingRate,
                    prepaidFlatFee,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )

        return structuredResponse
    }

    async getLocationPaymentInfo(
        practiceId: string,
        locationId: string,
    ): Promise<Api.PaymentsResponse<Api.LocationPaymentsInfo>> {
        const structuredResponse = await this.fetch(
            `v1/terminals`,
            { method: 'GET' },
            {
                practiceId,
                locationId,
            },
            API_PAYMENTS_HOST,
        )

        return structuredResponse
    }

    async createTerminal(
        practiceId: string,
        locationId: string,
        terminalData: Models.LocationPaymentsTerminalInfo,
    ): Promise<Api.PaymentsResponse<Api.CreateLocationTerminal>> {
        const response = await this.fetch(
            `v1/terminals/createTerminal`,
            {
                method: 'POST',
                body: JSON.stringify({
                    // androidMacAddress: terminalData.pairingCode,
                    uuid: terminalData.uuid,
                    stripeCardReaderSerialNumber: terminalData.readerSerial,
                    terminalNickname: terminalData.nickname,
                    backgroundColor: terminalData.backgroundColor,
                    practiceId,
                    locationId,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )

        return response
    }

    async createStripeTerminal(
        practiceId: string,
        locationId: string,
        terminalData: Models.LocationPaymentsTerminalInfo,
        stripeLocationId?: string,
    ): Promise<Api.PaymentsResponse<Api.CreateLocationTerminal>> {
        const extraTerminalData: {
            registrationCode?: string
            stripeLocationId?: string
            deviceType: 's700' | 'wisepose'
        } = {
            deviceType: !!terminalData.s700Code ? 's700' : 'wisepose',
        }

        if (!!terminalData.s700Code) {
            extraTerminalData.registrationCode = terminalData.s700Code
        } else {
            extraTerminalData.stripeLocationId = stripeLocationId
            extraTerminalData.registrationCode = terminalData.pairingCode
        }

        const response = await this.fetch(
            `v1/terminals/createStripeTerminal`,
            {
                method: 'POST',
                body: JSON.stringify({
                    practiceId,
                    locationId,
                    backgroundColor: terminalData.backgroundColor,
                    terminalNickname: terminalData.nickname,
                    ...extraTerminalData,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )

        return response
    }

    async deleteTerminal(practiceId: string, locationId: string, terminalId: string) {
        const response = await this.fetch(
            `v1/terminals/${terminalId}`,
            {
                method: 'DELETE',
            },
            {
                locationId,
                practiceId,
            },
            API_PAYMENTS_HOST,
        )

        return response
    }

    async postCreatePracticePrimaryPatient(
        practiceId: string,
        connectPaymentDetails: Models.ChatConnectPaymentDetails,
    ): Promise<Api.ChatConnectPaymentDetails> {
        try {
            const { data } = await this.fetch(
                `v1/practices/${practiceId}/patients/primary`,
                {
                    method: 'POST',
                    body: JSON.stringify(connectPaymentDetails),
                },
                {},
                API_PAYMENTS_HOST,
                false,
                true,
            )
            return data
        } catch (error) {
            return error?.data ?? {}
        }
    }

    async postPaymentSetup(payerId: string): Promise<string> {
        const {
            data: { clientSecret },
        } = await this.fetch(
            'v1/payments/setup',
            {
                method: 'POST',
                body: JSON.stringify({
                    payerId,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
        return clientSecret
    }

    async postPayerApproval(practiceId: string, payerId: string): Promise<boolean> {
        try {
            await this.fetch(
                `v1/practices/${practiceId}/payers/${payerId}/practicePayerApproval`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        connectApproval: true,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
            return true
        } catch (err) {
            return false
        }
    }

    async fetchLogoBgColor(practiceId: string): Promise<Api.PaymentsResponse<Api.FetchLogoBgColor>> {
        return await this.fetch(
            `v1/practices/${practiceId}/practiceLogoBackgroundColorCode`,
            {
                method: 'GET',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async updateLogoBgColor(practiceId: string, bgColor: Models.LogoBgColorOptions): Promise<any> {
        return await this.fetch(
            `v1/practices/${practiceId}/practiceLogoBackgroundColorCode`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    logoBackgroundColorCode: bgColor,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async fetchLocationStripeAccountId(
        practiceId: string,
        locationId: string,
    ): Promise<Api.PaymentsResponse<Api.LocationStripeAccountId>> {
        return await this.fetch(
            `v1/practices/${practiceId}/locations/${locationId}/stripeConnectedAccount`,
            {
                method: 'GET',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async updateStripeAccountIdAllLocations(practiceId: string, stripeAccountId: string) {
        return await this.fetch(
            `v1/practices/${practiceId}/stripeConnectedAccount`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    stripeConnectedAccountId: stripeAccountId,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async updateStripeAccountIdSingleLocation(practiceId: string, locationId: string, stripeAccountId: string) {
        return await this.fetch(
            `v1/practices/${practiceId}/locations/${locationId}/stripeConnectedAccount`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    stripeConnectedAccountId: stripeAccountId,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async getMonthlyStatementAccountsAndYears(
        practiceId: string,
    ): Promise<Api.PaymentsResponse<Api.PaymentMonthlyStatementAccountsAndYearsResponse>> {
        return this.fetch(
            `v1/practices/${practiceId}/stripeConnectedAccountWithMonths`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async getMonthlyStatementMonths(
        connectedAccountId: string,
        year: string,
        practiceId: string,
    ): Promise<Api.PaymentsResponse<Api.PaymentMonthlyStatementMonthsResponse>> {
        return this.fetch(
            `v1/practices/${practiceId}/monthlyRollup/accountId/${connectedAccountId}/year/${year}`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async downloadMonthlyStatement(
        connectedAccountId: string,
        statementDate: string,
        practiceId: string,
    ): Promise<any> {
        return this.fetch(
            `v1/practices/${practiceId}/connectedAccountId/${connectedAccountId}/monthlyStatement?statementStartDate=${statementDate}`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async getLocationLogo(
        practiceId: string,
        locationId: string,
    ): Promise<Api.PaymentsResponse<Api.PaymentLocationLogoResponse>> {
        return this.fetch(
            `v1/practiceLocation/practiceId/${practiceId}/locationId/${locationId}/logo`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {},
            API_PAYMENTS_HOST,
        )
        // GET localhost:7004
        // PUT localhost:7004/api/v1/practiceLocation/practiceId/:practiceId/locationId/:locationId/logo
    }

    async updateLocationLogo(practiceId: string, locationId: string, fileUploadId: string) {
        return this.fetch(
            `v1/practiceLocation/upload/practiceId/${practiceId}/locationId/${locationId}/logo`,
            {
                method: 'PUT',
                mode: 'cors',
                body: JSON.stringify({
                    fileUploadId,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async deleteLocationLogo(practiceId: string, locationId: string) {
        return this.fetch(
            `v1/practiceLocation/delete/practiceId/${practiceId}/locationId/${locationId}/logo`,
            {
                method: 'DELETE',
                mode: 'cors',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async updateLocationLogoBgColor(practiceId: string, locationId: string, bgColorHex: string) {
        return this.fetch(
            `v1/practiceLocation/background/practiceId/${practiceId}/locationId/${locationId}/logoBackgroundColorCode`,
            {
                method: 'PUT',
                mode: 'cors',
                body: JSON.stringify({
                    logoBackgroundColorCode: bgColorHex,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async getSdrAndAe(
        practiceId: string,
    ): Promise<Api.PaymentsResponse<{ practiceId: string; ae: string; sdr: string }>> {
        return this.fetch(
            `v1/practiceAeSdrRel/practiceId/${practiceId}`,
            {
                method: 'GET',
                mode: 'cors',
            },
            {},
            API_PAYMENTS_HOST,
        )
    }

    async updateSdrAndAe(practiceId: string, sdr: string, ae: string) {
        try {
            return await this.fetch(
                `v1/practiceAeSdrRel`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        practiceId,
                        sdr,
                        ae,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async getPaymentsWritebackEnabled(
        locationId: string,
    ): Promise<Api.PaymentsResponse<Api.GetPaymentsWritebackEnabledResponse>> {
        try {
            return await this.fetch(
                `v1/practices/locations/${locationId}/payments/settings`,
                {
                    method: 'GET',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async savePaymentsAllowWriteback(locationId: string, allowWriteback: boolean, accountId: string) {
        try {
            return await this.fetch(
                `v1/practices/locations/payments/settings`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        locationId,
                        enableWriteback: allowWriteback,
                        updatedBy: accountId,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async getLocationPaymentsSettings(
        locationId: string,
    ): Promise<Api.PaymentsResponse<Api.GetLocationPaymentsSettingsResponse>> {
        try {
            return await this.fetch(
                `v1/practices/locations/${locationId}/payments/settings`,
                {
                    method: 'GET',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async updateLocationPaymentsSettings(
        accountId: string,
        toUpdate: { practiceId?: string; locationId?: string }, // must be one of these 2
        newSettings: {
            enableWriteback?: boolean
            statementDescriptor?: string
        },
    ) {
        try {
            return await this.fetch(
                `v1/practices/locations/payments/settings`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        // add property for allow all locations, and maybe only send practice id when true
                        // practiceId,
                        ...toUpdate,
                        ...newSettings,
                        updatedBy: accountId,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadSwapTransactionData(
        firstName: string,
        lastName: string,
        dob: string,
        transactionId: string,
    ): Promise<Api.PaymentsResponse<Api.LoadSwapTransactionDataResponse>> {
        try {
            return await this.fetch(
                `v1/admins/transactions/swap`,
                {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({
                        // add property for allow all locations, and maybe only send practice id when true
                        // practiceId,
                        firstname: firstName,
                        lastname: lastName,
                        dob,
                        transactionId,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async saveSwapTransaction(
        transactionId: string,
        patientId: string,
        payerId: string,
    ): Promise<Api.PaymentsResponse<{}>> {
        try {
            return await this.fetch(
                `v1/admins/transactions/swap`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        // add property for allow all locations, and maybe only send practice id when true
                        // practiceId,
                        transactionId,
                        patientId,
                        payerId,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadPaymentStatus(transactionId: string): Promise<Api.PaymentsResponse<Api.LoadPaymentStatusResponse>> {
        try {
            return await this.fetch(
                `v1/admins/transactions/${transactionId}/statuses`,
                {
                    method: 'GET',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async savePaymentStatus(
        transactionId: string,
        currentStatus: string,
        newStatus: string,
    ): Promise<Api.PaymentsResponse<{}>> {
        try {
            return await this.fetch(
                `v1/admins/transactions/${transactionId}/statuses`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        // add property for allow all locations, and maybe only send practice id when true
                        // practiceId,
                        currentStatus,
                        newStatus,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadEmailData(
        practiceId: string,
        firstName: string,
        lastName: string,
        currentEmail?: string,
        currentPhone?: string,
    ): Promise<Api.PaymentsResponse<Api.LoadEmailDataResponse>> {
        try {
            return await this.fetch(
                `v1/admins/portalUsers/search`,
                {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({
                        practiceId,
                        firstName,
                        lastName,
                        email: currentEmail,
                        phoneNumber: currentPhone,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async saveNewEmailOrPhone(
        portalUserId: string,
        payerId: string,
        email?: string,
        phoneNumber?: string,
    ): Promise<Api.PaymentsResponse<{}>> {
        try {
            return await this.fetch(
                `v1/admins/portalUsers/${portalUserId}`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        // add property for allow all locations, and maybe only send practice id when true
                        // practiceId,
                        payerId,
                        email,
                        phoneNumber,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadInvoice(
        practiceId: string,
        transactionId: string,
    ): Promise<Api.PaymentsResponse<Api.LoadTransactionDataResponse>> {
        try {
            return await this.fetch(
                `v1/practices/${practiceId}/transactions/transactionId/${transactionId}`,
                {
                    method: 'GET',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadPlanData(paymentPlanId: string): Promise<Api.PaymentsResponse<Api.LoadPlanDataResponse>> {
        try {
            return await this.fetch(
                `v1/paymentPlan/${paymentPlanId}/balance`,
                {
                    method: 'GET',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async savePlanChargeDate(paymentPlanId: string, scheduledDate: string): Promise<Api.PaymentsResponse<{}>> {
        try {
            return await this.fetch(
                `v1/admins/paymentPlan/${paymentPlanId}/chargeEvents`,
                {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        // add property for allow all locations, and maybe only send practice id when true
                        // practiceId,
                        scheduledDate,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async getPayerData(
        practiceId: string,
        firstName: string,
        lastName: string,
        // dob: string,
        // email: string,
        phoneNumber: string,
    ): Promise<Api.PaymentsResponse<Api.GetPayerDataResponse>> {
        try {
            return await this.fetch(
                `v1/admins/payers/paymentMethods`,
                {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify({
                        practiceId,
                        firstname: firstName,
                        lastname: lastName,
                        phone: phoneNumber,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadPlanChargeEventsByPlanId(
        paymentPlanId: string,
    ): Promise<Api.PaymentsResponse<Api.LoadPlanChargeEventsResponse>> {
        try {
            return await this.fetch(
                `v1/admins/paymentPlan/${paymentPlanId}/chargeEvents`,
                {
                    method: 'GET',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async loadPlanChargeEventsByDownPaymentId(downPaymentChargeEventId: string) {}

    async loadPaymentPlanByChargeEventId(
        downPaymentChargeEventId: string,
    ): Promise<Api.PaymentsResponse<Api.LoadPaymentPlanByChargeEventResponse>> {
        try {
            return await this.fetch(
                `v1/admins/transactions/${downPaymentChargeEventId}/payment-plans`,
                {
                    method: 'GET',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async cancelPaymentPlan(paymentPlanId: string): Promise<Api.PaymentsResponse<{}>> {
        try {
            return await this.fetch(
                `v1/admins/payment-plans/${paymentPlanId}`,
                {
                    method: 'PUT',
                    mode: 'cors',
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }

    async removePayerPaymentMethods(paymentMethodsIds: string[]): Promise<{}> {
        try {
            return await this.fetch(
                `v1/admins/payers/paymentMethods`,
                {
                    method: 'DELETE',
                    mode: 'cors',
                    body: JSON.stringify({
                        paymentMethods: paymentMethodsIds,
                    }),
                },
                {},
                API_PAYMENTS_HOST,
            )
        } catch (err) {
            throw err
        }
    }
}
