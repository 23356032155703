import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from '@mui/material'

import { AvailableHours, DayOfWeek } from '../../../models/Connect'

import { daysOfTheWeek, defaultEndTime, defaultStartTime } from './constants'
import EditableAvailableHours, { HourUpdate } from './EditableAvailableHours'

import './AvailableHoursForm.sass'

export type AvailableHoursFormProps = {
    isEditForm: boolean
    hasConnectSurvey: boolean
    availableHours?: AvailableHours[]
    saveAvailableHours: (availableHours: AvailableHours[]) => void
    updateAvailableHours: (update: AvailableHours, availableHours: AvailableHours[]) => void
}

export type AvailableHoursFormDispatch = {}

type Props = AvailableHoursFormProps & AvailableHoursFormDispatch

const moduleName = 'available-hours-form'

const AvailableHoursForm = (props: Props) => {
    const initialState: AvailableHours[] = daysOfTheWeek.map(day => {
        return {
            dayOfWeek: day.index as DayOfWeek,
            startTime: defaultStartTime,
            endTime: defaultEndTime,
            available: true,
        }
    })

    const [availableHrs, setAvailableHrs] = useState<Array<AvailableHours> | undefined>(
        props.hasConnectSurvey ? props.availableHours : initialState,
    )
    const [initialAvailableHrs, setInitialAvailableHrs] = useState(false)

    useEffect(() => {
        if (props.availableHours && props.hasConnectSurvey && !initialAvailableHrs) {
            setAvailableHrs(props.availableHours)
            setInitialAvailableHrs(true)
        }
    }, [initialAvailableHrs, props.availableHours, props.hasConnectSurvey])

    const updateHourData = (startTime: string, endTime: string, dayIndex: DayOfWeek, available: boolean) => {
        if (availableHrs) {
            const index = availableHrs.findIndex(h => h.dayOfWeek === dayIndex)
            const hours: AvailableHours[] = Object.assign(availableHrs)
            if (hours[index]) {
                hours[index].startTime = startTime
                hours[index].endTime = endTime
                hours[index].available = available
            } else {
                hours[index] = {
                    startTime,
                    endTime,
                    dayOfWeek: dayIndex,
                    available,
                }
            }
            setAvailableHrs(hours)

            return hours
        }
        return undefined
    }

    const handleOnSave = ({ startTime, endTime, dayIndex, available }: HourUpdate) => {
        const hours = updateHourData(startTime, endTime, dayIndex, available)
        if (hours) {
            const update = hours.filter(h => h.dayOfWeek === dayIndex)
            props.updateAvailableHours(update[0], hours)
        }
    }

    const handleOnChange = ({ startTime, endTime, dayIndex, available }: HourUpdate) => {
        const hours = updateHourData(startTime, endTime, dayIndex, available)
        if (hours) {
            props.saveAvailableHours(hours)
        }
    }

    return (
        <Fragment>
            <Grid container={true} spacing={0} className={moduleName}>
                <Grid item={true} xs={12}>
                    <div className={`${moduleName}__header`}>
                        <div className={`${moduleName}__header-cell`}> </div>
                        <div className={`${moduleName}__header-cell`}>Day</div>
                        <div className={`${moduleName}__header-cell`}>Open</div>
                        <div className={`${moduleName}__header-cell`}>Close</div>
                        <div className={`${moduleName}__header-cell`}></div>
                    </div>
                    {availableHrs &&
                        daysOfTheWeek.map(day => {
                            const hours = availableHrs.find(hours => hours.dayOfWeek === day.index)

                            return (
                                <div className={`${moduleName}__container`} key={day.name}>
                                    <EditableAvailableHours
                                        dayName={day.name}
                                        dayIndex={day.index as DayOfWeek}
                                        startTime={hours?.startTime || ''}
                                        endTime={hours?.endTime || ''}
                                        onChange={handleOnChange}
                                        onSave={handleOnSave}
                                        editButtons={props.isEditForm}
                                        available={Boolean(hours?.available)}
                                    />
                                </div>
                            )
                        })}
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default AvailableHoursForm
