import { CircularProgress, Icon, Modal } from '@mui/material'

import './DefaultModal.sass'
import classNames from 'classnames'

interface Props {
    errorMessage?: string
    successMessage?: string
    isLoading?: boolean
    onClose: () => void
    screenIndex: number
    screens: Array<{
        content: JSX.Element
        leftButton?: {
            onClick: () => void
            disabled?: boolean
            text: string
        }
        mainButton: {
            disabled?: boolean
            isLoading?: boolean
            onClick: () => void
            text: string
        }
    }>
    title?: string
}

const DefaultModal = (props: Props) => {
    return (
        <Modal open={true} onClose={props.onClose}>
            <div className="vyne-modal-wrapper">
                <div className="vyne-modal-title">
                    <h2>{props.title || ''}</h2>
                    <button className="close-modal" onClick={props.onClose}>
                        <Icon>close</Icon>
                    </button>
                </div>
                {!!props.successMessage && (
                    <div className="vyne-modal-success">
                        <Icon className="info-payment-icon">check_circle</Icon>
                        <span>{props.successMessage}</span>
                    </div>
                )}
                {!!props.errorMessage && (
                    <div className="vyne-modal-error">
                        <Icon className="info-payment-icon">close</Icon>
                        <span>{props.errorMessage}</span>
                    </div>
                )}
                <div className="vyne-modal-content">{props.screens[props.screenIndex].content}</div>
                <div className="vyne-modal-buttons">
                    {!!props.screens[props.screenIndex].leftButton &&
                        (() => {
                            const btn = props.screens[props.screenIndex].leftButton!

                            return (
                                <button className="vyne-modal-left-btn" onClick={btn.onClick} disabled={btn.disabled}>
                                    {btn.text}
                                </button>
                            )
                        })()}
                    {(() => {
                        const btn = props.screens[props.screenIndex].mainButton

                        return (
                            <button
                                className={classNames('vyne-modal-main-btn', {
                                    'vyne-modal-main-btn--loading': btn.isLoading,
                                })}
                                onClick={btn.onClick}
                                disabled={btn.disabled}
                            >
                                <span className="vyne-modal-main-btn-text">{btn.text}</span>
                                {btn.isLoading && (
                                    <CircularProgress className="vyne-modal-main-btn-loading" size={21} />
                                )}
                            </button>
                        )
                    })()}
                </div>
            </div>
        </Modal>
    )
}

export default DefaultModal
