import React from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import './NewMessageIndicator.sass'

const moduleName = 'new-message-indicator'

type NewMessageIndicatorProps = {
    numberOfMessages: number
    onClick: () => void
}

const NewMessageIndicator = ({ numberOfMessages, onClick }: NewMessageIndicatorProps) => {
    const handleOnClick = () => {
        onClick()
    }
    return (
        <CSSTransition
            in={numberOfMessages > 0}
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={{ enter: 500, exit: 500 }}
            classNames={classNames(`${moduleName}`)}
        >
            <div className={`${moduleName}`}>
                <div className={`${moduleName}__box`} onClick={handleOnClick}>
                    <i className={`${moduleName}__arrow-downward-icon`}>arrow_downward</i>
                    <div className={`${moduleName}__text`}>
                        {numberOfMessages} new message{numberOfMessages > 1 && 's'}
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

export default NewMessageIndicator
