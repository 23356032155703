import * as React from 'react'

import { filterOutGoogleQueryParams } from './utils'

import './SystemMessage.sass'

interface Props {
    message: Models.Message
}

const SystemMessage = (props: Props) => {
    const { text } = props.message

    const messageText = text.includes('Patient Started chat on') ? filterOutGoogleQueryParams(text) : text

    return (
        <div className="actions-container">
            <div className="line-before"></div>
            <div className="message system-message">{messageText}</div>
            <div className="line-after"></div>
        </div>
    )
}

export default SystemMessage
