import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { HistoryResponse } from 'pubnub'

import { RootState } from '../../../appReducer'
import PubNubService from '../../../services/PubNubService'
import { newObjectId } from '../../../util/objectId'

export interface OnSendMessageParams {
    channel: string
    message: string
    chatMetadata: Models.ChatMetadata
    messageMetadata?: Models.MessageMetadata
    messageId?: string
}
interface useMessagingReturn {
    onSendMessage: (onSendMessageParams: OnSendMessageParams) => void
}

const useMessaging = (): useMessagingReturn => {
    const conversations = useSelector((state: RootState) => state.chat.conversations)
    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)

    const [missedMessageIdArr, setMissedMessages] = useState<{ [channel: string]: string[] }>({})

    const sendMessage = useCallback(
        (
            channel: string,
            message: string,
            chatMetadata: Models.ChatMetadata,
            messageMetadata?: Models.MessageMetadata,
            messageId?: string,
        ) => {
            const conversation = conversations[channel]
            const messages = conversation?.messages
            const receivedWebchatCount = messages.filter(msg => !msg.is_patient && msg.type === 'text').length
            const newMessageId = messageId ?? newObjectId(32)

            PubNubService.send(channel, {
                id: newMessageId,
                text: message,
                is_patient: false,
                sender_id: PubNubService.getChatterId(),
                type: 'text',
                claimee_name: chatMetadata?.claimee?.firstName,
                sender_name: account?.firstName,
                received_count: receivedWebchatCount,
                meta_data: messageMetadata,
            })
        },
        [account, conversations],
    )

    /*
        compares chatter sent message count to what patient/widget tells us it has received.
        if count is off, we pull pubnub history, and find message ids that aren't in pubnub,
        and resend them
    */
    const verifyMessageCount = useCallback(
        (channel: string, chatMetadata: Models.ChatMetadata) => {
            const conversation = conversations[channel]
            const messages = conversation?.messages
            const sortedMessages = _.sortBy([...messages], m => m.timetoken)
            const missedChannelMessages = missedMessageIdArr[channel] || []
            const ssConvo =
                sortedMessages && sortedMessages.filter(message => !message.is_patient && message.type === 'text')

            let ssMessageCount: number = (ssConvo?.length || 0) + missedChannelMessages.length
            let webchatSSMessageCount: number = 0
            const messageReverse: Models.Message[] = Object.assign([], sortedMessages).reverse()
            const convoLastIndex = messageReverse.findIndex(message => message.is_patient)

            if (convoLastIndex !== -1) {
                let countRecentChatterMsgs = 0
                for (let i = 0; i < messageReverse.length; i++) {
                    if (messageReverse[i].type === 'text' && !messageReverse[i].is_patient) {
                        countRecentChatterMsgs++
                    } else if (messageReverse[i].is_patient) {
                        break
                    }
                }
                const fromWidgetReceivedCount =
                    sortedMessages[sortedMessages.length - 1 - convoLastIndex].received_count || 0
                webchatSSMessageCount = fromWidgetReceivedCount + countRecentChatterMsgs
            } else {
                webchatSSMessageCount = ssMessageCount
            }

            if (ssMessageCount !== webchatSSMessageCount && sortedMessages?.length < 50) {
                PubNubService.getChatHistory(channel).then((chatHistory: HistoryResponse<Services.PubNub.Message>) => {
                    const ourChatterMessages = ssConvo || []
                    const pubNubChatterIds = chatHistory.messages
                        .filter(message => !message.entry.is_patient && message.entry.type === 'text')
                        .map(message => message.entry.id)
                        .concat(missedChannelMessages)

                    const missingMessageArr: Array<Models.Message> = []
                    ourChatterMessages.forEach(ourMessage => {
                        const ourId = ourMessage.id
                        // if message not in pubnub's history, and we haven't already resent this message,
                        // keep track of id for later use
                        if (pubNubChatterIds.indexOf(ourId) === -1) {
                            missingMessageArr.push(ourMessage)
                        }
                    })

                    let messageCounter = 0

                    const resendMissingMessage = () => {
                        while (messageCounter < missingMessageArr.length) {
                            setMissedMessages({
                                ...missedMessageIdArr,
                                [channel]: [...missedChannelMessages, missingMessageArr[messageCounter].id],
                            })
                            sendMessage(channel, missingMessageArr[messageCounter].text, chatMetadata)
                            messageCounter++
                        }
                    }

                    resendMissingMessage()
                })
            }
        },
        [missedMessageIdArr, conversations, sendMessage],
    )

    const onSendMessage = useCallback(
        (onSendMessageParams: OnSendMessageParams) => {
            const { channel, message, chatMetadata, messageMetadata, messageId } = onSendMessageParams

            verifyMessageCount(channel, chatMetadata)
            sendMessage(channel, message, chatMetadata, messageMetadata, messageId)
        },
        [sendMessage, verifyMessageCount],
    )

    return { onSendMessage }
}

export default useMessaging
