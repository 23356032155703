import iassign from 'immutable-assign'
import _ from 'lodash'

import { Practice } from '../../models/v2/Practice'

import {
    PracticeActionTypes,
    ReceiveArchivedChats,
    ReceiveHeartbeatPracticeLocationProviders,
    ReceiveLocationsByWebCode,
    ReceivePractice,
    ReceivePracticeAccount,
    ReceivePracticeAccountLocations,
    ReceivePracticeLocationAccounts,
    ReceivePracticeLocations,
    ReceivePracticeLocationsPaginated,
    ReceivePracticeLocationSubscribers,
    ReceivePracticeLocationsV2,
    ReceivePracticeStaffList,
    ReceiveUserPhoneNumberBlackList,
    ResetSearchPracticeStaff,
    SearchPracticeLocationAccountsRequest,
    SearchPracticeLocationSubscribersRequest,
    SearchPracticeStaffRequest,
    SearchPracticeStaffSuccess,
    SetNotificationsSelectedLocationId,
    SetPractice,
    SetResetLocationState,
    SetResetPracticeLocationState,
    UpdatePendingReferralOnChat,
} from './v2actions'

export type StaffDataState = {
    query: string
    list: ModelsV2.Practice.PracticeAccount[]
    page?: number
    pagination: Models.PaginationInfo | null
    sort?: ModelsV2.Practice.StaffSort
    order?: ModelsV2.Practice.StaffSortOrdering
    loading: boolean
}

export type PracticeLocationAccountsDataState = {
    list: ModelsV2.Practice.PracticeSubscriberFromStaff[]
    pagination: Models.PaginationInfo | null
    loading: boolean
    page?: number
}

export type PracticeLocationSubscribersDataState = {
    list: ModelsV2.Practice.PracticeLocationSubscriber[]
    pagination: Models.PaginationInfo | null
    loading: boolean
    page?: number
}

export type PracticesState = {
    practices: { [key: string]: Practice }
    practicesV2: { [key: string]: ModelsV2.Practice.PracticeV2 }
    practiceLocations: { [key: string]: ModelsV2.Practice.PracticeLocationsV2[] }
    practiceStaff: { [key: string]: StaffDataState }
    practiceLocationsByWebCode: { [key: string]: ModelsV2.Practice.LocationByWebCode[] }
    archivedChats: ModelsV2.Practice.ChatMetadata[] | undefined
    archivedChatsPaginationInfo?: Models.PaginationInfo
    practiceLocationAccounts: { [key: string]: PracticeLocationAccountsDataState }
    practiceLocationSubscriber: { [key: string]: PracticeLocationSubscribersDataState }
    notificationsSelectedLocationId: { [key: string]: string }
}

const initialState = (): PracticesState => ({
    practices: {},
    practicesV2: {},
    practiceLocations: {},
    practiceStaff: {},
    practiceLocationsByWebCode: {},
    archivedChats: undefined,
    archivedChatsPaginationInfo: {
        allPages: 1,
        allRows: 0,
    },
    practiceLocationAccounts: {},
    practiceLocationSubscriber: {},
    notificationsSelectedLocationId: {},
})

export const initialStaffDataState = (): StaffDataState => ({
    page: 1,
    query: '',
    loading: false,
    list: [],
    pagination: null,
    sort: 'last_name',
})

export const initialPracticeLocationSubscribersDataState = (): PracticeLocationSubscribersDataState => ({
    page: 1,
    loading: false,
    list: [],
    pagination: null,
})

export const initialPracticeLocationAccountsDataState = (): PracticeLocationAccountsDataState => ({
    page: 1,
    loading: false,
    list: [],
    pagination: null,
})

type PracticesAction =
    | SetPractice
    | ReceivePractice
    | SearchPracticeLocationAccountsRequest
    | ReceivePracticeLocationAccounts
    | ReceivePracticeLocations
    | SearchPracticeLocationSubscribersRequest
    | ReceivePracticeLocationSubscribers
    | ReceivePracticeLocationsV2
    | ReceivePracticeLocationsPaginated
    | ReceiveHeartbeatPracticeLocationProviders
    | SetResetPracticeLocationState
    | ReceiveLocationsByWebCode
    | ReceivePracticeStaffList
    | ResetSearchPracticeStaff
    | SearchPracticeStaffRequest
    | SearchPracticeStaffSuccess
    | SetResetLocationState
    | ReceiveArchivedChats
    | UpdatePendingReferralOnChat
    | ReceivePracticeAccount
    | ReceiveUserPhoneNumberBlackList
    | ReceivePracticeAccountLocations
    | SetNotificationsSelectedLocationId

export function reducer(state: PracticesState = initialState(), action: PracticesAction): PracticesState {
    switch (action.type) {
        // TO-DO-V2 rewrite this to use V2 practice
        case PracticeActionTypes.SET_PRACTICE: {
            return iassign(
                state,
                next => next.practices,
                nextPractice => {
                    nextPractice[action.practice.id] = action.practice
                    return nextPractice
                },
            )
        }

        // TO-DO-V2 rewrite this to use V2 locations
        case PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS: {
            return iassign(
                state,
                next => next.practices[action.practice.id],
                nextPractice => {
                    if (nextPractice) {
                        nextPractice.currentLocationPage = action.searchTerms?.page
                        nextPractice.locationPaginationInfo = action.paginationInfo

                        if (!nextPractice.locations) {
                            nextPractice.locations = action.locations
                        } else {
                            nextPractice.locations = _.unionBy(nextPractice.locations, action.locations, 'id')
                        }
                    }
                    return nextPractice
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS_PAGINATED: {
            return iassign(
                state,
                next => next.practices[action.practice.id],
                nextPractice => {
                    if (nextPractice) {
                        nextPractice.locations = action.locations
                        nextPractice.currentLocationPage = action.searchTerms?.page
                        nextPractice.locationQuery = action.searchTerms?.query
                        nextPractice.locationPaginationInfo = action.paginationInfo || { allPages: 1, allRows: 1 }
                    }
                    return nextPractice
                },
            )
        }

        case PracticeActionTypes.UPDATE_PENDING_REFERRAL_ON_CHAT: {
            // a patch so "send to referral" button can be disabled if referral on chat already exists

            return iassign(
                state,
                next => next.archivedChats,
                next => {
                    if (next) {
                        next = next.map(chat => {
                            if (chat.id === action.chatId) {
                                return {
                                    ...chat,
                                    amplify_referral: {
                                        updated: new Date(),
                                    },
                                }
                            }
                            return chat
                        })
                    }

                    return next
                },
            )
        }

        case PracticeActionTypes.RECEIVE_ARCHIVED_CHATS: {
            return iassign(state, next => {
                next.archivedChats = action.chats
                next.archivedChatsPaginationInfo = action.paginationInfo

                return next
            })
        }

        case PracticeActionTypes.SET_RESET_PRACTICE_LOCATIONS_STATE: {
            return iassign(
                state,
                next => next.practices[action.practiceId],
                nextPractice => {
                    if (nextPractice) {
                        nextPractice.currentLocationPage = 0
                        nextPractice.locations = []
                        nextPractice.locationProviders = []
                    }
                    return nextPractice
                },
            )
        }
        case PracticeActionTypes.SET_RESET_LOCATIONS_STATE: {
            return iassign(
                state,
                next => next.practiceLocations[action.practiceId],
                nextPractice => {
                    if (nextPractice) {
                        nextPractice = []
                    }
                    return nextPractice
                },
            )
        }

        case PracticeActionTypes.RECEIVE_HEARTBEAT_PRACTICE_LOCATION_PROVIDERS: {
            return iassign(
                state,
                next => next.practices[action.practice.id],
                nextPractice => {
                    if (nextPractice) {
                        nextPractice.locationProviders = action.locationProviders
                    }
                    return nextPractice
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE: {
            return iassign(
                state,
                next => next.practicesV2[action.practiceId],
                nextPractice => {
                    nextPractice = action.practice
                    return nextPractice
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS_V2: {
            return iassign(
                state,
                next => next.practiceLocations[action.practiceId],
                nextLocations => {
                    if (nextLocations) {
                        nextLocations = [...nextLocations, ...action.locations]
                        return nextLocations
                    }
                    nextLocations = action.locations
                    return nextLocations
                },
            )
        }

        case PracticeActionTypes.RECEIVE_LOCATIONS_BY_WEBCODE: {
            return iassign(
                state,
                next => next.practiceLocationsByWebCode,
                nextLocations => {
                    nextLocations[action.webCodeId] = action.locations
                    return nextLocations
                },
            )
        }

        case PracticeActionTypes.SEARCH_PRACTICE_STAFF_REQUEST: {
            return iassign(
                state,
                next => next.practiceStaff,
                nextPracticeStaff => {
                    if (!nextPracticeStaff[action.practiceId]) {
                        nextPracticeStaff[action.practiceId] = initialStaffDataState()
                    }

                    nextPracticeStaff[action.practiceId].query = action.searchTerms.query
                    nextPracticeStaff[action.practiceId].page = action.searchTerms.page || 1
                    nextPracticeStaff[action.practiceId].sort = action.searchTerms.sort
                    nextPracticeStaff[action.practiceId].order = action.searchTerms.order
                    nextPracticeStaff[action.practiceId].loading = action.loading
                    return nextPracticeStaff
                },
            )
        }
        case PracticeActionTypes.SEARCH_PRACTICE_STAFF_SUCCESS: {
            return iassign(
                state,
                next => next.practiceStaff,
                nextPracticeStaff => {
                    if (!nextPracticeStaff[action.practiceId]) {
                        nextPracticeStaff[action.practiceId] = initialStaffDataState()
                    }
                    nextPracticeStaff[action.practiceId].list = action.staff
                    nextPracticeStaff[action.practiceId].loading = false
                    nextPracticeStaff[action.practiceId].pagination = action.pagination || { allPages: 1, allRows: 1 }
                    return nextPracticeStaff
                },
            )
        }
        case PracticeActionTypes.RESET_SEARCH_PRACTICE_STAFF: {
            return iassign(
                state,
                next => next.practiceStaff,
                nextPracticeStaff => {
                    if (nextPracticeStaff[action.practiceId]) {
                        nextPracticeStaff[action.practiceId] = initialStaffDataState()
                    }

                    return nextPracticeStaff
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE_ACCOUNT: {
            return iassign(
                state,
                next => next.practiceStaff[action.practiceId].list,
                nextPracticeStaffList => {
                    const accountIndex = nextPracticeStaffList.findIndex(user => user.id === action.account.id)
                    if (accountIndex !== -1) {
                        nextPracticeStaffList[accountIndex] = action.account
                    }

                    return nextPracticeStaffList
                },
            )
        }

        case PracticeActionTypes.RECEIVE_USER_PHONE_NUMBER_BLACK_LIST: {
            return iassign(
                state,
                next => next.practiceStaff[action.practiceId].list,
                nextPracticeStaffList => {
                    const accountIndex = nextPracticeStaffList.findIndex(user => user.id === action.accountId)
                    if (accountIndex !== -1) {
                        nextPracticeStaffList[accountIndex].phone_number_black_list = action.phoneNumberBlackList
                    }

                    return nextPracticeStaffList
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE_ACCOUNT_LOCATIONS: {
            return iassign(
                state,
                next => next.practiceStaff[action.practiceId].list,
                nextPracticeStaffList => {
                    const accountIndex = nextPracticeStaffList.findIndex(user => user.id === action.accountId)
                    if (accountIndex !== -1) {
                        nextPracticeStaffList[accountIndex].practice_locations = action.accountPracticeLocations
                    }

                    return nextPracticeStaffList
                },
            )
        }

        case PracticeActionTypes.SEARCH_PRACTICE_LOCATION_ACCOUNTS_REQUEST: {
            return iassign(
                state,
                next => next.practiceLocationAccounts[action.locationId],
                nextPracticeLocationAccounts => {
                    if (!nextPracticeLocationAccounts) {
                        nextPracticeLocationAccounts = initialPracticeLocationAccountsDataState()
                    }
                    nextPracticeLocationAccounts.page = action.page || 1
                    nextPracticeLocationAccounts.loading = action.loading
                    return nextPracticeLocationAccounts
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE_LOCATION_ACCOUNTS: {
            return iassign(
                state,
                next => next.practiceLocationAccounts[action.locationId],
                nextPracticeLocationAccounts => {
                    if (!nextPracticeLocationAccounts) {
                        nextPracticeLocationAccounts = initialPracticeLocationAccountsDataState()
                    }
                    nextPracticeLocationAccounts.list = action.practiceLocationAccounts
                    nextPracticeLocationAccounts.loading = false
                    nextPracticeLocationAccounts.pagination = action.pagination || { allPages: 1, allRows: 1 }

                    return nextPracticeLocationAccounts
                },
            )
        }

        case PracticeActionTypes.SEARCH_PRACTICE_LOCATION_SUBSCRIBERS_REQUEST: {
            return iassign(
                state,
                next => next.practiceLocationSubscriber[action.searchTerms.locationId],
                nextPracticeLocationSubscribers => {
                    if (!nextPracticeLocationSubscribers) {
                        nextPracticeLocationSubscribers = initialPracticeLocationSubscribersDataState()
                    }
                    nextPracticeLocationSubscribers.page = action.searchTerms.page || 1
                    nextPracticeLocationSubscribers.loading = action.loading
                    return nextPracticeLocationSubscribers
                },
            )
        }

        case PracticeActionTypes.RECEIVE_PRACTICE_LOCATION_SUBSCRIBERS: {
            return iassign(
                state,
                next => next.practiceLocationSubscriber[action.locationId],
                nextPracticeLocationSubscribers => {
                    if (!nextPracticeLocationSubscribers) {
                        nextPracticeLocationSubscribers = initialPracticeLocationSubscribersDataState()
                    }
                    nextPracticeLocationSubscribers.list = action.practiceLocationSubscribers
                    nextPracticeLocationSubscribers.loading = false

                    return nextPracticeLocationSubscribers
                },
            )
        }

        case PracticeActionTypes.SET_NOTIFICATIONS_SELECTED_LOCATION_ID: {
            return iassign(
                state,
                next => next.notificationsSelectedLocationId[action.practiceId],
                () => action.locationId,
            )
        }

        default:
            return state
    }
}
