import React from 'react'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableHead } from '@mui/material'
import moment from 'moment'

import { OperationDay } from '../../../../../models/PracticeSurvey'
import { Cell, HeaderCell, HeaderRow } from '../shared/Table'

import { RENDER_TIME_FORMAT, SHADOW_TIME_FORMAT, weekDays } from './const'

import './OperatingHoursView.sass'

interface Props {
    location: Models.PracticeLocation
}

const OperatingHoursView = ({ location }: Props) => {
    const { hours }: { hours: OperationDay[] } = useSelector((state: any) => ({
        hours: state.practiceSurvey[location.id]?.operatingHours?.days ?? [],
    }))

    return (
        <div className="survey-section survey-section--view survey-operation-hours">
            <h4 className="survey-section__header">Hours of Operation</h4>

            <div className="survey-section__wrapper">
                <Table className="survey-operation-hours__table" aria-labelledby="Hours of Operation">
                    <TableHead>
                        <HeaderRow>
                            <HeaderCell className="survey-operation-hours__day">Day</HeaderCell>
                            <HeaderCell className="survey-operation-hours__open">Open</HeaderCell>
                            <HeaderCell className="survey-operation-hours__close">Close</HeaderCell>
                        </HeaderRow>
                    </TableHead>
                    <TableBody>
                        {hours.map(day => (
                            <HeaderRow key={day.dayOfWeek}>
                                <Cell align={'left'}>{weekDays[day.dayOfWeek - 1]}</Cell>
                                <Cell align={'left'}>
                                    {day.enabled
                                        ? day.open
                                            ? moment(day.open, SHADOW_TIME_FORMAT).format(RENDER_TIME_FORMAT)
                                            : '—'
                                        : `CLOSED`}
                                </Cell>
                                <Cell align={'left'}>
                                    {day.enabled
                                        ? day.open
                                            ? moment(day.close, SHADOW_TIME_FORMAT).format(RENDER_TIME_FORMAT)
                                            : '—'
                                        : ``}
                                </Cell>
                            </HeaderRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default OperatingHoursView
