import { useState } from 'react'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { useAppDispatch } from 'util/useAppDispatch'
import { loadPlanChargeEvents, savePaymentStatus } from './actions'
import { convertNumCentsToDollars } from 'util/formatCurrency'
import { mapOldStatusToNewStatusPossiblities, mapTransactionStatusToCopy } from './ChangePaymentStatusModal'

import './AdminToolsShared.sass'
import { transactionIdLabel } from './utils'
import { RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { dateFormat } from 'modules/referrals/referral-form/ReferralForm'
import moment from 'moment'

interface Props {
    onClose: () => void
    practiceId: string
}

const DEFAULT_ERROR_MESSAGE = ''

const RemoveChargeFromPlanModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [paymentPlanId, setPaymentPlanId] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [screenIndex, setScreenIndex] = useState(0)

    const [selectedChargeEventId, setSelectedChargeEventId] = useState('')
    const [isLoadingPlanChargeEvents, setIsLoadingPlanChargeEvents] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        paymentPlanId: string
        chargeEvents: Api.LoadPlanChargeEventsResponse
    }>({
        paymentPlanId: '',
        chargeEvents: [],
    })
    const [isRemovingChargeEvent, setIsRemovingChargeEvent] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState(DEFAULT_ERROR_MESSAGE)

    const goBackScreen = () => {
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage(DEFAULT_ERROR_MESSAGE)
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadPlanChargeEvents = async () => {
        if (isLoadingPlanChargeEvents) {
            return
        }

        setIsLoadingPlanChargeEvents(true)
        try {
            const chargeEventData = await dispatch(loadPlanChargeEvents(paymentPlanId, transactionId))
            const allowedStatuses = ['scheduled', 'unpaid', 'failed']
            const finalChargeEvents = chargeEventData.chargeEvents.filter(
                ch => allowedStatuses.indexOf(ch.chargeEventStatus) > -1,
            )
            setLoadedData({
                paymentPlanId: chargeEventData.planId,
                chargeEvents: finalChargeEvents,
            })
            if (finalChargeEvents.length > 0) {
                goForwardScreen()
            } else {
                setErrorMessage(`No charge events for this plan can be canceled.`)
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsLoadingPlanChargeEvents(false)
    }

    const onSubmitRemoveChargeEvent = async () => {
        if (isRemovingChargeEvent) {
            return
        }
        setIsRemovingChargeEvent(true)
        try {
            const chargeEvent = loadedData.chargeEvents.find(
                chargeEvent => chargeEvent.chargeEventId === selectedChargeEventId,
            )
            if (!chargeEvent) {
                setErrorMessage('No charge selected.')
                return
            }

            const statusData = await dispatch(
                savePaymentStatus(chargeEvent.chargeEventId, chargeEvent.chargeEventStatus, 'cancelled'),
            )

            if (statusData === true) {
                goForwardScreen()
                setSuccessMessage(`Payment plan ${loadedData.paymentPlanId} has been successfully canceled.`)
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsRemovingChargeEvent(false)
    }

    return (
        <DefaultModal
            title="Remove a charge from Payment Plan"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={'Payment Plan ID'}
                                    inputType="text"
                                    value={paymentPlanId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPaymentPlanId(e.target.value)
                                    }
                                />
                                <span className="admin-tools-or-text">Or</span>
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={transactionIdLabel}
                                    inputType="text"
                                    value={transactionId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingPlanChargeEvents,
                        disabled: paymentPlanId === '' && transactionId === '',
                        onClick: onLoadPlanChargeEvents,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                Please select which charge you would like to remove from Payment Plan {paymentPlanId}:
                            </p>
                            <div>
                                <RadioGroup
                                    value={selectedChargeEventId}
                                    onChange={(e: any, value: string) => {
                                        setSelectedChargeEventId(value)
                                    }}
                                >
                                    <ul className="admin-tools-radio-checkbox-list">
                                        {loadedData.chargeEvents.map((chargeEvent, index) => {
                                            return (
                                                <li>
                                                    <FormControlLabel
                                                        className="active-patient-form-control"
                                                        key={index}
                                                        value={chargeEvent.chargeEventId}
                                                        control={
                                                            <Radio
                                                                className={`active-patient-radio-checkbox ${
                                                                    selectedChargeEventId === chargeEvent.chargeEventId
                                                                        ? 'active-patient-radio-checkbox--selected'
                                                                        : ''
                                                                }`}
                                                            />
                                                        }
                                                        label={
                                                            <span className="active-patient-radio-checkbox-label">
                                                                {moment(chargeEvent.scheduledTsUTC).format(
                                                                    'MM/DD/YYYY',
                                                                )}{' '}
                                                                -{' '}
                                                                {convertNumCentsToDollars(
                                                                    chargeEvent.totalAmount,
                                                                    true,
                                                                )}
                                                            </span>
                                                        }
                                                    />
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </RadioGroup>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isRemovingChargeEvent,
                        disabled: selectedChargeEventId === '',
                        onClick: goForwardScreen,
                        text: 'Review Changes',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                You are about to remove a charge from Payment Plan {paymentPlanId}. Please confirm your
                                changes. This action cannot be undone.
                            </p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div>
                                        {(() => {
                                            const chargeEvent = loadedData.chargeEvents.find(
                                                chargeEvent => chargeEvent.chargeEventId === selectedChargeEventId,
                                            )
                                            if (!chargeEvent) {
                                                return null
                                            }
                                            return (
                                                <div>
                                                    <div className="admin-tools-details-large-bold">
                                                        {moment(chargeEvent.scheduledTsUTC).format('MM/DD/YYYY')} -{' '}
                                                        {convertNumCentsToDollars(chargeEvent.totalAmount, true)}
                                                    </div>
                                                </div>
                                            )
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isRemovingChargeEvent,
                        onClick: onSubmitRemoveChargeEvent,
                        text: 'Submit',
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default RemoveChargeFromPlanModal
