import moment from 'moment-timezone'

import { mapPractice } from '../../modules/practices/reducer'
import { formatDateOfBirth } from '../../modules/shared/dateUtils'
import ApiService from '../service'

export default class PendingReferralsService extends ApiService {
    readonly ModulePath: string = 'api/admin/amplify/v1/referrals/dashboard'

    static mapPendingReferral(pendingReferral: Api.Amplify.PendingReferral): Models.PendingReferral {
        const patient_data = pendingReferral.patient_data
        const timezone = 'America/New_York'

        return {
            id: pendingReferral.id,
            created: pendingReferral.created,
            updated: pendingReferral.updated,
            transcript: pendingReferral.transcript,
            attachments: pendingReferral.attachments,
            pending_referral_status: pendingReferral.pending_referral_status,
            flag: pendingReferral.flag,
            claimee: pendingReferral.claimee && {
                id: pendingReferral.claimee.id,
                firstName: pendingReferral.claimee.first_name,
                lastName: pendingReferral.claimee.last_name,
            },
            referralWriter: pendingReferral.referral_writer && {
                id: pendingReferral.referral_writer.id,
                firstName: pendingReferral.referral_writer.first_name,
                lastName: pendingReferral.referral_writer.last_name,
            },
            chat: pendingReferral.chat_data && {
                surveyId: pendingReferral.chat_data.survey_id,
                practiceSpecialty: pendingReferral.chat_data.practice_specialty,
                webCodeKey: pendingReferral.chat_data.web_code_key,
            },
            inboundHandlerId: pendingReferral.inbound_handler_id,
            location: pendingReferral.selected_practice_location,
            date:
                pendingReferral.chat_data &&
                pendingReferral.chat_data.created &&
                moment(new Date(pendingReferral.chat_data.created))
                    .tz(timezone)
                    .format('MM/DD/YYYY'),
            time:
                pendingReferral.chat_data &&
                pendingReferral.chat_data.created &&
                moment(new Date(pendingReferral.chat_data.created))
                    .tz(timezone)
                    .format('h:mm A z'),
            patientName: pendingReferral.patient_name,
            amplify_referral_patients: [],
            patientData: patient_data && {
                firstName: patient_data.first_name,
                lastName: patient_data.last_name,
                dateOfBirth: patient_data.date_of_birth && formatDateOfBirth(patient_data.date_of_birth),
                email: patient_data.email,
                desiredAppointmentTime: patient_data.desired_appointment_time,
                insurance: patient_data.insurance,
                inboundSource: patient_data.inbound_source,
                phoneNumber: patient_data.phone_number,
                reason: patient_data.reason,
                notes: patient_data.notes,
                existingPatient: patient_data.existing_patient,
                providerName: patient_data.provider_name,
                operatoryName: patient_data.operatory_name,
                procedure: patient_data.procedure,
                otherPatients: patient_data.other_patients && patient_data.other_patients.map(this.mapOtherPatients),
                patient_is_policy_holder: patient_data.patient_is_policy_holder,
                insurance_group: patient_data.insurance_group,
                insurance_member_id_ssn: patient_data.insurance_member_id_ssn,
                insurance_phone: patient_data.insurance_phone,
                policy_holder_first_name: patient_data.policy_holder_first_name,
                policy_holder_last_name: patient_data.policy_holder_last_name,
                policy_holder_date_of_birth: patient_data.policy_holder_date_of_birth,
                scheduling_appointment_id: patient_data.scheduling_appointment_id,
            },
            archived: false,
            isTest: false,
            referralCreatorId: '',
            practice: pendingReferral.practice && mapPractice(pendingReferral.practice),
            createdReferralId: pendingReferral.created_referral_id,
            directScheduled: pendingReferral.direct_scheduled,
        }
    }

    static mapOtherPatients(patient: Api.Amplify.AdditionalPatientData): Models.AdditionalPatientData {
        return {
            patientName: patient.patient_name,
            firstName: patient.first_name,
            lastName: patient.last_name,
            dateOfBirth: patient.date_of_birth,
            reason: patient.reason,
            operatoryName: patient.operatory_name,
            procedure: patient.procedure,
            providerName: patient.provider_name,
            appointmentTime: patient.appointment_time,
            schedulingAppointmentId: patient.scheduling_appointment_id,
        }
    }
}
