import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'

import { RootState } from '../../../appReducer'
import { WebCode } from '../../../models/v2/Referrals'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchChatAlertList } from '../../amplify/actions'
import InfoMessage from '../../shared/info-message/info-message'

import './ChatCenterAlertsList.sass'

export type Props = {
    practiceId: string
    webCode?: WebCode
}

export type ChatCenterAlertsListDispatch = {
    fetchChatAlertList: typeof fetchChatAlertList
}

const moduleName = 'chat-alerts-list'

const ChatCenterAlertsList = ({ webCode, practiceId }: Props) => {
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(fetchChatAlertList(practiceId))
    }, [dispatch, practiceId])

    const alerts = useSelector((state: RootState) => state.amplify.alerts[practiceId])

    if (!alerts) {
        return (
            <div className="chat-alerts-loading">
                <CircularProgress size={70} color="primary" variant="indeterminate" />
            </div>
        )
    }

    const filteredAlerts = webCode?.id
        ? alerts.filter(alert => alert.websiteIds.indexOf(String(webCode?.id)) >= 0)
        : alerts

    return (
        <div className={`${moduleName}`}>
            {filteredAlerts.length > 0 ? (
                filteredAlerts.map(alert => (
                    <div key={alert.id} className={`${moduleName}__alert`}>
                        <i className="material-icons icon">notifications</i>
                        <ReactMarkdown className={`${moduleName}__alert-text`} linkTarget="_blank">
                            {alert.alert}
                        </ReactMarkdown>
                    </div>
                ))
            ) : (
                <div className={`${moduleName}__no-records`}>
                    <InfoMessage isShown={true}>{' No records found for selected criteria'}</InfoMessage>
                </div>
            )}
        </div>
    )
}

export default ChatCenterAlertsList
