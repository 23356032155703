import React from 'react'

import { QuestionOption, SurveySectionQuestion } from '../../../../../../models/PracticeSurvey'
import { formatDate } from '../../shared/utils'

import './SelectMulti.sass'

interface Props {
    question: SurveySectionQuestion
}

const moduleName = 'question-select-multi'

const SelectMultiViewDefault = ({ question: { question_text, variants, answer_schema } }: Props) => {
    const options = answer_schema?.options || []
    const other_option = answer_schema?.other_option
    const extraClasses = variants?.map(variant => `${moduleName}--${variant}`).join(' ') ?? ''

    return (
        <div className={`${moduleName} ${moduleName}--view ${extraClasses}`}>
            <div aria-label={question_text} className={`${moduleName}__options`}>
                {options
                    .filter((option: QuestionOption) => option.selected)
                    .map((option: QuestionOption) => {
                        const optionLabel = option.metadata?.date
                            ? `${option.display_name} - ${formatDate(option.metadata?.date)}`
                            : option.display_name

                        return (
                            <div key={option.id} className={`${moduleName}__checkbox`}>
                                • {optionLabel}
                            </div>
                        )
                    })}
                {other_option?.selected && (
                    <div className={`${moduleName}__checkbox`}>
                        • {other_option.input_field?.value ?? other_option.display_name}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SelectMultiViewDefault
