import React from 'react'
import Icon from '@mui/material/Icon'

import './error-message.sass'

export type ErrorMessageProps = {
    children: any
    type?: 'error' | 'warning'
}

const moduleName = 'general-error-message'

const ErrorMessage = ({ type, children }: ErrorMessageProps) => {
    return (
        <div className={`${moduleName}`}>
            <div className={`${moduleName}__wrapper ${type && `${moduleName}__wrapper--${type}`}`}>
                <Icon>warning</Icon>
                <div className={`${moduleName}__message`}>{children}</div>
            </div>
        </div>
    )
}

export default ErrorMessage
