import {
    HeartbeatPracticeIntegrationsAgent,
    IMappedAgentsWithLocations,
    IntegrationsSoftware,
} from '../../../models/v2/Integrations'
import { PracticeLocationsV2 } from '../../../models/v2/Practice'

export const mergeAgentLocations = (agents: HeartbeatPracticeIntegrationsAgent[], locations: PracticeLocationsV2[]) => {
    return agents.map((agent: IMappedAgentsWithLocations) => {
        agent.agent_module.map(aModule => {
            if (!aModule.locations) aModule.locations = []
            return locations.map(location => {
                const found = location.heartbeat_agent_module_location.find(
                    locModule => locModule.heartbeat_agent_module_id === aModule.id,
                )
                const alreadyIn = aModule.locations?.find(
                    loc => loc.external_location_id === found?.external_location_id,
                )

                if (found && !alreadyIn) aModule.locations?.push({ ...found, name: location.name, id: location.id })
                return location
            })
        })
        return agent
    })
}

export const getSoftwareNameById = (id: number, software: IntegrationsSoftware[]) => {
    const found = software?.find(soft => soft.id === id)
    return found ? found.moduleTypeName : 'UNKNOWN'
}
