import React from 'react'
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import map from 'lodash/map'

import { CustomFieldType } from '../../../../../models/enums'
import { OtherOption, QuestionOption, SurveySection, SurveySectionQuestion } from '../../../../../models/PracticeSurvey'
import CustomField from '../../../../shared/custom-fields/CustomField'

import ProceduresTabs from './ProceduresTabs'
import SpecialtyTab from './SpecialtyTab'
import { formatSpecialtyName, groupProceduresBySpecialty } from './utils'

import './ProceduresEdit.sass'

type ProceduresEditProps = {
    survey: SurveySection
    location: Models.PracticeLocation
    question: SurveySectionQuestion
    onChange: (options: QuestionOption[], other_option?: OtherOption) => void
}

const moduleName = 'survey-procedures-edit'

const ProceduresEdit = (props: ProceduresEditProps) => {
    const { question } = props
    const other_option = question.answer_schema?.other_option

    const handleChangeOption = (optionId: string) => {
        const options = question.answer_schema?.options || []
        const nextOptions = options.map((option: QuestionOption) => {
            if (optionId === option.id) {
                return {
                    ...option,
                    selected: !option.selected,
                }
            }
            return option
        })

        props.onChange(nextOptions, other_option)
    }

    const handleChangeOtherOption = () => {
        const options = question.answer_schema?.options || []
        if (other_option) {
            const nextOther = { ...other_option }
            nextOther.selected = !nextOther.selected
            props.onChange(options, nextOther)
        }
    }

    const handleChangeOtherInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const options = question.answer_schema?.options || []
        if (other_option) {
            const nextOther = { ...other_option }
            nextOther.input_field.value = event.target.value
            props.onChange(options, nextOther)
        }
    }

    const hasError = question.isDirty && !question.isValid

    const renderQuestions = () => {
        const proceduresBySpecialty = groupProceduresBySpecialty(props.question)
        return (
            <Grid item={true} xs={12}>
                <ProceduresTabs onChange={handleChangeOption} edit={true}>
                    {map(proceduresBySpecialty, (options: QuestionOption[], specialty) => {
                        return (
                            <SpecialtyTab name={formatSpecialtyName(specialty)} key={specialty}>
                                {options}
                            </SpecialtyTab>
                        )
                    })}
                </ProceduresTabs>
                {other_option && (
                    <div className={`${moduleName}__other-option`}>
                        <Grid container={true}>
                            <Grid item={true} key={other_option.id} md={2} sm={3} xs={6}>
                                <div
                                    className={classNames(
                                        `${moduleName}__other-option-tile  ${moduleName}--clickable`,
                                        {
                                            [`${moduleName}--selected`]: other_option.selected,
                                        },
                                    )}
                                    onClick={() => handleChangeOtherOption()}
                                >
                                    {other_option.display_name}
                                </div>
                            </Grid>
                        </Grid>
                        {other_option?.selected && other_option?.input_field && (
                            <div className={`${moduleName}__other-option-input-wrapper`}>
                                <div className={`${moduleName}__other-option-input-label`}>OTHER (please specify)</div>
                                <div className={`${moduleName}__other-option-input`}>
                                    <CustomField
                                        customFieldType={CustomFieldType.INPUT}
                                        value={other_option.input_field.value ?? ''}
                                        required={question.isRequired}
                                        error={hasError}
                                        onChange={handleChangeOtherInput}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Grid>
        )
    }

    return (
        <div className="survey-section survey-section--view survey-procedures-section">
            <div className="survey-section__wrapper">
                <Grid container={true} className={`${moduleName}__grid-row`} spacing={2}>
                    {renderQuestions()}
                </Grid>
            </div>
            {hasError && <div className="question-select-one__error">{question.errorMessage}</div>}
        </div>
    )
}

export default ProceduresEdit
