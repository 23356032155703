import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import moment from 'moment'

import Api from '../../../Api'
import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { BookingMap, BookingStep } from '../../../models/BookingAppointment'
import { ConnectPaymentStatus } from '../../../models/Connect'
import { PatientType } from '../../../models/enums'
import AlertModal from '../../shared/alert-modal/AlertModal'
import ConfirmModal from '../../shared/confirm-modal/ConfirmModal'
import InlineEditableText from '../../shared/InlineEditableText'
import { closePatientNameRequiredAlert, fetchAmplifyChatMetadataSchedulingAppointments } from '../actions'
import { APP_ERROR_MESSAGE_TIMEOUT } from '../direct-scheduling/shared'
import EndChatModal from '../EndChatModal'

import BanUserModal from './BanUserModal'

import './ChatTileHeader.sass'

const moduleName = 'chat-tile-header'

export type Props = {
    isBanned?: boolean
    isVisible?: boolean
    chat: Models.ChatCenterSelectedChat
    account: AdminAccount
    bookings: BookingMap
    isPatientOnline: boolean
    webCode: Models.WebCode
    onEditName: (name: string) => Promise<any>
    onCloseChat: (close: Models.ChatClose) => Promise<any>
    onLeaveChat: (chatId: string) => void
}

enum ConfirmEndChatState {
    NONE,
    ALERTS,
    DIRECT_SCHEDULING,
    CONNECT,
}

const ChatTileHeader = ({
    chat,
    isBanned,
    account,
    bookings,
    isVisible,
    isPatientOnline,
    webCode,
    onLeaveChat,
    onCloseChat,
    onEditName,
}: Props) => {
    const anchorEl = useRef<HTMLElement>()
    const menuButton = useRef<HTMLDivElement>(null)
    const [showLocation, setShowLocation] = useState<boolean>(false)
    const [isMenuOpened, setMenuOpen] = useState<boolean>(false)
    const [isEndChatModalOpened, setEndChatModalOpened] = useState<boolean>(false)
    const [isBanUserModalOpened, setBanUserModalOpened] = useState<boolean>(false)
    const [ignoreUnmarkedTasks, setIgnoreUnmarkedTasks] = useState<boolean>(false)
    const [selectedChatChatterCountry, setSelectedChatChatterCountry] = useState<string>('')
    const [confirmEndChatState, setConfirmEndChatState] = useState<ConfirmEndChatState>(ConfirmEndChatState.NONE)

    const connectBooking = useSelector((state: RootState) => state.bookings?.connect[chat.id] ?? undefined)
    const directSchedulingBooking = useSelector(
        (state: RootState) => state.bookings?.directScheduling?.[chat.id] ?? undefined,
    )
    const tasks = useSelector((state: RootState) => state.amplify.tasks[chat.id])
    const showPatientNameRequiredAlert = useSelector(
        (state: RootState) => state.chat.showPatientNameRequiredAlert[chat.id],
    )

    const filteredTasks = useMemo(() => tasks?.filter(task => task.websiteIds?.includes(String(webCode?.id))), [
        tasks,
        webCode,
    ])

    const dispatch = useDispatch()

    const toggleMenu = () => setMenuOpen(!isMenuOpened)

    useEffect(() => {
        if (ignoreUnmarkedTasks === true) {
            openEndChatModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ignoreUnmarkedTasks])

    const openEndChatModal = () => {
        const doesConnectBookingNeedConfirmation =
            connectBooking &&
            (connectBooking.paymentStatus === ConnectPaymentStatus.Verified || connectBooking.isBooked) &&
            !connectBooking.isShared

        const directSchedulingStep = directSchedulingBooking
            ? directSchedulingBooking.selectedTab === PatientType.NEW_PATIENT
                ? directSchedulingBooking.newPatient.bookingStep
                : directSchedulingBooking.existingPatient.bookingStep
            : BookingStep.NONE

        const hasTimeoutError = Boolean(
            bookings?.[chat.id]?.existingPatient?.errorMessage === APP_ERROR_MESSAGE_TIMEOUT ||
                bookings?.[chat.id]?.newPatient?.errorMessage === APP_ERROR_MESSAGE_TIMEOUT,
        )

        const allATasksAreMarked = filteredTasks.every(task => task.marked)
        const warnForUnmarkedTasks = !allATasksAreMarked && ignoreUnmarkedTasks === false

        if (warnForUnmarkedTasks) {
            setConfirmEndChatState(ConfirmEndChatState.ALERTS)
        } else if (doesConnectBookingNeedConfirmation) {
            setConfirmEndChatState(ConfirmEndChatState.CONNECT)
        } else if (
            directSchedulingStep === BookingStep.BOOKED ||
            (directSchedulingStep !== BookingStep.SHARED && hasTimeoutError)
        ) {
            setConfirmEndChatState(ConfirmEndChatState.DIRECT_SCHEDULING)
        } else {
            dispatch(fetchAmplifyChatMetadataSchedulingAppointments(chat.id))
            setEndChatModalOpened(true)
            setIgnoreUnmarkedTasks(false)
        }
        setMenuOpen(false)
    }

    const closeEndChatModal = () => setEndChatModalOpened(false)

    const openBanUserModal = () => {
        getCountryCode()
        setBanUserModalOpened(true)
        setMenuOpen(false)
    }

    const closeBanUserModal = () => setBanUserModalOpened(false)

    const closeBanUserModalAndEndChat = (reason: Models.ChatClose) => {
        handleCloseChat(reason)
        onLeaveChat(chat.id)
        closeBanUserModal()
    }

    const getCountryCode = async () => {
        const { countryCodeAlpha2 } = await Api.App.getCountryCodeFromIp(chat.ip)
        setSelectedChatChatterCountry(countryCodeAlpha2)
    }

    const handleLeaveChat = () => {
        onLeaveChat(chat.id)
        setMenuOpen(false)
    }

    const handleConfirmCloseChat = () => {
        setConfirmEndChatState(ConfirmEndChatState.NONE)
        setEndChatModalOpened(true)
    }

    const handleIgnoreUncheckedAlerts = () => {
        setIgnoreUnmarkedTasks(true)
        setMenuOpen(false)
        handleCloseEndChatModal()
    }

    const handleCloseEndChatModal = () => setConfirmEndChatState(ConfirmEndChatState.NONE)

    const handleCloseChat = async (close: Models.ChatClose) => {
        await onCloseChat(close)
        handleLeaveChat()
    }

    const handleClosePatientNameNotEnteredModal = () => {
        dispatch(closePatientNameRequiredAlert(chat.id))
    }

    const renderLocationInfo = () => {
        const location = chat.meta && chat.meta.location
        const timeZone = location && location.time_zone
        const missingData = '<Not Found>'

        if (!location) {
            return <Typography className={`${moduleName}__location-info-empty`}>No location data found.</Typography>
        }

        return (
            <div className={`${moduleName}__location-list`}>
                <div className={`${moduleName}__location-item`}>
                    <Typography className={`${moduleName}__location-item-key`}>IP Address:</Typography>
                    <Typography className={`${moduleName}__location-item-value`}>
                        {location.ip || missingData}
                    </Typography>
                </div>
                <div className={`${moduleName}__location-item`}>
                    <Typography className={`${moduleName}__location-item-key`}>Country:</Typography>
                    <Typography className={`${moduleName}__location-item-value`}>
                        {location.country_name || missingData}
                    </Typography>
                </div>
                <div className={`${moduleName}__location-item`}>
                    <Typography className={`${moduleName}__location-item-key`}>Region/State:</Typography>
                    <Typography className={`${moduleName}__location-item-value`}>
                        {location.region_name || missingData}
                    </Typography>
                </div>
                <div className={`${moduleName}__location-item`}>
                    <Typography className={`${moduleName}__location-item-key`}>City:</Typography>
                    <Typography className={`${moduleName}__location-item-value`}>
                        {location.city || missingData}
                    </Typography>
                </div>
                <div className={`${moduleName}__location-item`}>
                    <Typography className={`${moduleName}__location-item-key`}>Zip Code:</Typography>
                    <Typography className={`${moduleName}__location-item-value`}>
                        {location.zip || missingData}
                    </Typography>
                </div>
                <div className={`${moduleName}__location-item`}>
                    <Typography className={`${moduleName}__location-item-key`}>Time:</Typography>
                    <Typography className={`${moduleName}__location-item-value`}>
                        {timeZone?.id
                            ? `${moment()
                                  .tz(timeZone.id)
                                  .format('LT z')}`
                            : missingData}
                    </Typography>
                </div>
            </div>
        )
    }

    const bannedBy = chat.claimee && `${chat.claimee.firstName} ${chat.claimee.lastName}`

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__header-left`}>
                <div className={`${moduleName}__header-line`}>
                    <InlineEditableText
                        className={`${moduleName}__patient-name`}
                        text={chat.patientName || chat.ip}
                        editable={true}
                        isIconButtonPrimary={false}
                        onSave={onEditName}
                    />
                    <div className={`${moduleName}__info-wrapper`}>
                        {isVisible && (
                            <Tooltip placement="bottom" title="Location Info">
                                <IconButton
                                    size="large"
                                    style={{ padding: 0 }}
                                    ref={anchorEl as React.RefObject<any>}
                                    onClick={() => setShowLocation(!showLocation)}
                                >
                                    <i className="material-icons icon">info</i>
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                    <div className={`${moduleName}__online-status-wrapper`}>
                        <i
                            className={`${moduleName}__online-status material-icons ${moduleName}--${
                                isPatientOnline ? `online` : `offline`
                            }`}
                        >
                            circle
                        </i>
                    </div>
                </div>
                <div className={`${moduleName}__header-line`}>
                    <div className={`${moduleName}__practice`}>{chat.practice.name}</div>
                </div>
                <Popover
                    open={showLocation}
                    anchorEl={anchorEl.current}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    onClose={() => setShowLocation(false)}
                >
                    <div className="location-info">{renderLocationInfo()}</div>
                </Popover>
            </div>
            <div className={`${moduleName}__menu`} ref={menuButton}>
                <IconButton
                    size="large"
                    className={`${moduleName}__menu-button`}
                    ref={menuButton as React.RefObject<any>}
                    onClick={toggleMenu}
                >
                    <i className="material-icons icon">menu</i>
                </IconButton>
                <Popover
                    open={isMenuOpened}
                    anchorEl={menuButton.current as HTMLElement}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={toggleMenu}
                >
                    <div className={`${moduleName}__chat-menu`}>
                        {!isBanned && (
                            <div
                                className={`${moduleName}__footer-button ${moduleName}__footer-button--ban`}
                                onClick={openBanUserModal}
                            >
                                <div>Ban User</div>
                                <i className={`material-icons ${moduleName}__icon-button`}>block</i>
                            </div>
                        )}
                        {chat.claimee?.id === account.id ? (
                            <Button
                                variant="text"
                                onClick={openEndChatModal}
                                color="secondary"
                                className={`${moduleName}__end-button`}
                            >
                                End Chat
                            </Button>
                        ) : (
                            <Button
                                variant="text"
                                onClick={handleLeaveChat}
                                color="primary"
                                className={`${moduleName}__leave-button`}
                            >
                                Leave Chat
                            </Button>
                        )}
                    </div>
                </Popover>

                <ConfirmModal
                    title={`You Have Unchecked Alerts`}
                    subtitle="Please check all alerts before you attempt to close out this chat."
                    discardText="RETURN TO CHAT"
                    confirmText="IGNORE"
                    open={confirmEndChatState === ConfirmEndChatState.ALERTS}
                    onClose={handleCloseEndChatModal}
                    onConfirm={handleIgnoreUncheckedAlerts}
                    onDiscard={handleCloseEndChatModal}
                />

                <ConfirmModal
                    title={`Booked appointment not shared`}
                    subtitle="You haven't shared the appointment information with the patient. Are you sure you want to end
                    this chat?"
                    confirmText="END CHAT"
                    open={confirmEndChatState === ConfirmEndChatState.DIRECT_SCHEDULING}
                    onClose={handleCloseEndChatModal}
                    onConfirm={handleConfirmCloseChat}
                    onDiscard={handleCloseEndChatModal}
                />

                <ConfirmModal
                    title={`"Doxy Link not Shared`}
                    subtitle="You haven't shared the appointment information link with the patient. Are you sure you want to
                    end this chat?"
                    confirmText="END CHAT"
                    open={confirmEndChatState === ConfirmEndChatState.CONNECT}
                    onClose={handleCloseEndChatModal}
                    onConfirm={handleConfirmCloseChat}
                    onDiscard={handleCloseEndChatModal}
                />

                <AlertModal
                    title={`Cannot close chat`}
                    subtitle={`Chat cannot be closed as "Successful" unless a patient name is entered. Please return to chat and enter patient name`}
                    confirmText="BACK TO CHAT"
                    open={showPatientNameRequiredAlert}
                    onClose={handleClosePatientNameNotEnteredModal}
                    onConfirm={handleClosePatientNameNotEnteredModal}
                />

                <EndChatModal
                    isModalOpen={isEndChatModalOpened}
                    chat={chat}
                    bookings={bookings}
                    closeModal={closeEndChatModal}
                    onCloseChat={handleCloseChat}
                />
                <BanUserModal
                    isModalOpen={isBanUserModalOpened}
                    date={moment().format('MM/DD/YYYY')}
                    bannedBy={bannedBy}
                    chat={chat}
                    webCode={webCode}
                    country={selectedChatChatterCountry}
                    closeBanUserModal={closeBanUserModal}
                    closeBanUserModalAndEndChat={closeBanUserModalAndEndChat}
                />
            </div>
        </div>
    )
}

export default ChatTileHeader
