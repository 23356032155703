import {
    FILE_BACKOFF_DURATION_SCALE,
    FILE_BACKOFF_MAX_WAIT_DURATION,
    FILE_BACKOFF_WAIT_DEFAULT,
    FILE_CHUNK_SIZE,
    FILE_MAX_RETRIES,
    FILE_PARALLEL_PARTS,
    FILE_RETRY_FOREVER_DEFAULT,
} from '../Constants'

// This is used by the actions to recursively decide what part to send, how large, and what to do in failure
// a state machine to track how many parts are left for uploading, and to manage the number of failures
// to accept before cancelling an upload
class PendingUpload {
    id: string
    numberOfParts: number
    partsRemaining: number
    chunkSize: number
    parallelism: number
    retryForever: boolean
    maxRetries: number
    initialBackoffWait: number
    backoffMaxWait: number
    backoffScale: number
    currentRetries: number
    currentBackoffWait: number
    uploadingParts: any
    failedParts: any
    cancelled: boolean

    constructor(upload: Api.Uploads.Upload) {
        this.id = upload.id
        this.numberOfParts = upload.number_of_parts
        this.partsRemaining = upload.number_of_parts

        // settings for part uploads
        this.chunkSize = upload.chunk_size || FILE_CHUNK_SIZE
        this.parallelism = upload.parallelism || FILE_PARALLEL_PARTS
        this.retryForever = upload.retry_forever || FILE_RETRY_FOREVER_DEFAULT
        this.maxRetries = upload.max_retries || FILE_MAX_RETRIES
        this.initialBackoffWait = Math.max(upload.initial_backoff_wait || FILE_BACKOFF_WAIT_DEFAULT, 1)
        this.backoffMaxWait = upload.backoff_max_wait || FILE_BACKOFF_MAX_WAIT_DURATION
        this.backoffScale = Math.max(upload.backoff_scale || FILE_BACKOFF_DURATION_SCALE, 1)

        this.currentRetries = 0
        this.currentBackoffWait = upload.initial_backoff_wait
        this.uploadingParts = {}
        this.failedParts = {}

        this.cancelled = false
    }

    get hasPartsRemaining() {
        return this.partsRemaining > 0
    }

    get hasPartsUploading() {
        return Object.keys(this.uploadingParts).length > 0
    }

    canUploadPart(partNumber: number) {
        return partNumber <= this.numberOfParts
    }

    fileSliceBounds(partNumber: number) {
        const start = (partNumber - 1) * this.chunkSize
        const end = partNumber * this.chunkSize
        return { start, end }
    }

    markStarted(partNumber: number) {
        if (!this.uploadingParts[partNumber]) {
            this.uploadingParts[partNumber] = true
            this.partsRemaining -= 1
        }
    }

    markDone(partNumber: number) {
        delete this.uploadingParts[partNumber]
        delete this.failedParts[partNumber]

        // when nothing else has failed, or all failures are resolved, reset retries
        if (Object.keys(this.failedParts).length === 0) {
            this.currentBackoffWait = this.initialBackoffWait
            this.currentRetries = 0
        }
    }

    markFailed(partNumber: number) {
        this.failedParts[partNumber] = true
        this.currentRetries += 1

        // only start backing off on the second retry
        if (this.currentRetries < 2) {
            return
        }

        this.currentBackoffWait = Math.min(this.backoffMaxWait, this.currentBackoffWait * this.backoffScale)
    }

    get attemptRetry() {
        return this.retryForever || this.currentRetries < this.maxRetries
    }
}

export default PendingUpload
