import React from 'react'

import { CustomFieldType } from '../../../models/enums'
import InfoMessage from '../info-message/info-message'

import CustomField from './CustomField'

import './SearchBar.sass'

interface Props {
    value: string
    onClear: () => void
    onChange?: ((event: React.ChangeEvent<Element>) => void) | undefined
    isMessageShown?: boolean
    noResultsMessage?: string
    placeholder?: string
}

export const SearchBar = ({ value, onChange, onClear, isMessageShown, placeholder, noResultsMessage }: Props) => {
    return (
        <>
            <CustomField
                customFieldType={CustomFieldType.INPUT}
                inputType="text"
                placeholder={placeholder || 'Search'}
                rightAdornment="search"
                value={value}
                onChange={onChange}
                onClear={onClear}
            />

            {isMessageShown && (
                <div className="info-message-wrapper">
                    <InfoMessage isShown={true}>
                        {noResultsMessage || ' No records found for selected criteria'}
                    </InfoMessage>
                </div>
            )}
        </>
    )
}
