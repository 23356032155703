import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'

import { RootState } from '../../../appReducer'
import { PracticeLocationReschedulingConfiguration } from '../../../models/v2/Practice'
import { fetchPracticeLocationsSchedulingDataWithAgentModule } from '../../../modules/practices/actions'
import CustomMultiselectField from '../../../modules/shared/custom-fields/CustomMultiselectField'
import InfoMessage from '../../shared/info-message/info-message'
import Paginator from '../../shared/Paginator'

import AllowSidebookingCheckbox from './AllowSidebookingCheckbox'
import ApplyGlobalSettingsCheckbox from './ApplyGlobalSettingsCheckbox'
import { CANCELLATIONS_VALUES, CONCURRENCY_NUM_ARRAY, DAYS_PRIOR_VALUES, RESCHEDULING_VALUES } from './constants'
import { updatePracticeLocationReschedulingConfiguration } from './v2actions'

import './CancelRescheduleSettingsLocationsList.sass'

export type CancelRescheduleSettingsLocationsListProps = {
    practiceId: string
    isPending: boolean
    searchPage: number
    setSearchPage: (page: number) => void
}

const moduleName = 'cancel-reschedule-settings-locations-list'

type Props = CancelRescheduleSettingsLocationsListProps

const CancelRescheduleSettingsLocationsList = ({ practiceId, searchPage, setSearchPage }: Props) => {
    const loading = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceLocationsData?.[practiceId]?.loading || false,
    )

    const list = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceLocationsData?.[practiceId]?.list,
    )
    const pagination = useSelector(
        (state: RootState) =>
            state.v2.reschedulingConfiguration.practiceLocationsData?.[practiceId]?.pagination || null,
    )

    const connectedPracticeLocations = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.connectedPracticeLocations?.[practiceId] || [],
    )

    const settings = useSelector((state: RootState) => state.v2.reschedulingConfiguration.settings?.[practiceId] || [])

    const practiceLocationsAgentModules = useSelector(
        (state: RootState) => state.practices.practiceLocationsAgentModules[practiceId] || [],
    )

    const practiceSettingsPracticeId = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceSettingsData?.[practiceId]?.practice_id,
    )
    const practiceSettingsCancelAppointment = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceSettingsData?.[practiceId]?.cancel_appointment,
    )
    const practiceSettingsRescheduleAppointment = useSelector(
        (state: RootState) =>
            state.v2.reschedulingConfiguration.practiceSettingsData?.[practiceId]?.reschedule_appointment,
    )
    const practiceSettingsConcurrencyNum = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceSettingsData?.[practiceId]?.concurrency_num,
    )
    const practiceSettingsDaysPrior = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.practiceSettingsData?.[practiceId]?.days_prior,
    )

    const hasGlobalSettings = Boolean(practiceSettingsPracticeId)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPracticeLocationsSchedulingDataWithAgentModule(practiceId))
    }, [dispatch, practiceId])

    const onUpdateSettings = (
        field: string,
        value: string | number | boolean,
        settings: PracticeLocationReschedulingConfiguration | undefined,
        locationId: string,
    ) => {
        if (settings?.practice_location_id) {
            const updatedSettings = {
                ...settings,
                practice_location_id: locationId,
                [field]: value,
            }
            dispatch(updatePracticeLocationReschedulingConfiguration(practiceId, locationId, [updatedSettings], false))
        } else {
            const createdSettings =
                field === 'use_global_settings' && value === false && hasGlobalSettings
                    ? {
                          ...{
                              practice_location_id: locationId,
                              cancel_appointment: practiceSettingsCancelAppointment,
                              reschedule_appointment: practiceSettingsRescheduleAppointment,
                              days_prior: practiceSettingsDaysPrior,
                              concurrency_num: practiceSettingsConcurrencyNum,
                              use_global_settings: false,
                          },
                      }
                    : {
                          ...{
                              practice_location_id: locationId,
                              cancel_appointment: 'allow',
                              reschedule_appointment: 'allow',
                              days_prior: 1,
                              concurrency_num: 1,
                              use_global_settings: false,
                          },
                          [field]: value,
                      }
            dispatch(updatePracticeLocationReschedulingConfiguration(practiceId, locationId, [createdSettings], true))
        }
    }

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    const renderRows = () => {
        return (
            <>
                {Boolean(list && list.length > 0) ? (
                    list?.map(location => {
                        const locationSetting = settings.find(s => s.practice_location_id === location.id)
                        const connectedLocation = connectedPracticeLocations?.find(l => l.id === location?.id)
                        const useGlobalSettingsChecked =
                            locationSetting?.use_global_settings === undefined
                                ? hasGlobalSettings
                                : locationSetting?.use_global_settings
                        return (
                            <Fragment key={location.id}>
                                <Grid
                                    item={true}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={4}
                                    className={`${moduleName}__table-item ${moduleName}__table-item-first`}
                                >
                                    {location.name}
                                </Grid>
                                {connectedLocation ? (
                                    <>
                                        <ApplyGlobalSettingsCheckbox
                                            useGlobalSettingsChecked={useGlobalSettingsChecked}
                                            hasGlobalSettings={hasGlobalSettings}
                                            onUpdateSettings={onUpdateSettings}
                                            location={location}
                                            locationSetting={locationSetting}
                                        />

                                        <Grid
                                            item={true}
                                            xs={6}
                                            sm={3}
                                            md={3}
                                            lg={2}
                                            className={`${moduleName}__table-item details ${moduleName}__size18`}
                                        >
                                            <div className={`${moduleName}__role-picker`}>
                                                <CustomMultiselectField
                                                    items={CANCELLATIONS_VALUES}
                                                    maxSelected={1}
                                                    disabled={useGlobalSettingsChecked}
                                                    selectedItems={[
                                                        locationSetting?.cancel_appointment === undefined ||
                                                        useGlobalSettingsChecked
                                                            ? hasGlobalSettings
                                                                ? practiceSettingsCancelAppointment
                                                                : ''
                                                            : locationSetting?.cancel_appointment,
                                                    ]}
                                                    keyProperty="value"
                                                    displayProperty="display"
                                                    placeholder=""
                                                    label="Cancellations"
                                                    onSelectElement={(values: string[]) => {
                                                        onUpdateSettings(
                                                            'cancel_appointment',
                                                            values[0],
                                                            locationSetting,
                                                            location.id,
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item={true}
                                            xs={6}
                                            sm={3}
                                            md={3}
                                            lg={2}
                                            className={`${moduleName}__table-item details ${moduleName}__size18`}
                                        >
                                            <div className={`${moduleName}__role-picker`}>
                                                <CustomMultiselectField
                                                    items={RESCHEDULING_VALUES}
                                                    disabled={useGlobalSettingsChecked}
                                                    maxSelected={1}
                                                    selectedItems={[
                                                        locationSetting?.reschedule_appointment === undefined ||
                                                        useGlobalSettingsChecked
                                                            ? hasGlobalSettings
                                                                ? practiceSettingsRescheduleAppointment
                                                                : ''
                                                            : locationSetting?.reschedule_appointment,
                                                    ]}
                                                    keyProperty="value"
                                                    displayProperty="display"
                                                    placeholder=""
                                                    label="Reschedules"
                                                    onSelectElement={(values: string[]) => {
                                                        onUpdateSettings(
                                                            'reschedule_appointment',
                                                            values[0],
                                                            locationSetting,
                                                            location.id,
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid
                                            item={true}
                                            className={`${moduleName}__table-item details  ${moduleName}__size13`}
                                            xs={6}
                                            sm={3}
                                            md={3}
                                            lg={2}
                                        >
                                            <div className={`${moduleName}__role-picker`}>
                                                <CustomMultiselectField
                                                    items={DAYS_PRIOR_VALUES}
                                                    disabled={useGlobalSettingsChecked}
                                                    maxSelected={1}
                                                    selectedItems={[
                                                        locationSetting?.days_prior === undefined ||
                                                        useGlobalSettingsChecked
                                                            ? hasGlobalSettings
                                                                ? practiceSettingsDaysPrior.toString()
                                                                : ''
                                                            : locationSetting?.days_prior.toString(),
                                                    ]}
                                                    keyProperty="value"
                                                    displayProperty="display"
                                                    placeholder=""
                                                    label="Allow Changes"
                                                    onSelectElement={(values: string[]) => {
                                                        onUpdateSettings(
                                                            'days_prior',
                                                            Number(values[0]),
                                                            locationSetting,
                                                            location.id,
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <>
                                            {[1, 5].includes(
                                                practiceLocationsAgentModules.find(
                                                    p => location.id === p.practice_location_id,
                                                )?.heartbeat_agent_module?.type_id || 0,
                                            ) ? (
                                                <AllowSidebookingCheckbox
                                                    useGlobalSettingsChecked={useGlobalSettingsChecked}
                                                    hasGlobalSettings={hasGlobalSettings}
                                                    practiceSettingsConcurrencyNum={practiceSettingsConcurrencyNum}
                                                    onUpdateSettings={onUpdateSettings}
                                                    locationSetting={locationSetting}
                                                    location={location}
                                                />
                                            ) : (
                                                <Grid
                                                    item={true}
                                                    className={`${moduleName}__table-item`}
                                                    sm={3}
                                                    md={3}
                                                    lg={1}
                                                >
                                                    <div className={`${moduleName}__sidebooking-picker`}>
                                                        <CustomMultiselectField
                                                            items={CONCURRENCY_NUM_ARRAY.map(value => ({
                                                                value: value.toString(),
                                                                display: `${value}`,
                                                            }))}
                                                            disabled={useGlobalSettingsChecked}
                                                            maxSelected={1}
                                                            selectedItems={[
                                                                locationSetting?.concurrency_num === undefined ||
                                                                useGlobalSettingsChecked
                                                                    ? hasGlobalSettings
                                                                        ? practiceSettingsConcurrencyNum.toString()
                                                                        : ''
                                                                    : locationSetting?.concurrency_num.toString(),
                                                            ]}
                                                            keyProperty="value"
                                                            displayProperty="display"
                                                            placeholder=""
                                                            label="Sidebooking"
                                                            onSelectElement={(values: string[]) => {
                                                                onUpdateSettings(
                                                                    'concurrency_num',
                                                                    Number(values[0]),
                                                                    locationSetting,
                                                                    location.id,
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        </>
                                    </>
                                ) : (
                                    <Grid
                                        item={true}
                                        key={location.id}
                                        className={`${moduleName}__table-item ${moduleName}__info-msg`}
                                        sm={12}
                                        md={12}
                                        lg={8}
                                    >
                                        <InfoMessage isShown={true}>
                                            Practice Location not connected to an Agent.
                                        </InfoMessage>
                                    </Grid>
                                )}
                            </Fragment>
                        )
                    })
                ) : (
                    <div />
                )}
            </>
        )
    }

    return (
        <div className={`${moduleName}`}>
            <Grid className={`${moduleName}__container`} container={true}>
                {renderRows()}
            </Grid>
            {typeof list === 'undefined' && (
                <div className={`no-records`}>
                    <InfoMessage isShown={true}>
                        Please select a location, user(s), date range and click on “GET LOGS” to view configuration
                        logs.
                    </InfoMessage>
                </div>
            )}

            {Boolean(pagination) && pagination?.allRows && pagination?.allRows > 5 ? (
                <div className={`${moduleName}__paginator-wrapper`}>
                    <Paginator currentPage={searchPage} paginationInfo={pagination} selectPage={handlePageChange} />
                </div>
            ) : null}
            {loading && (
                <div className={`${moduleName}__circular-progress-loader-body`}>
                    <CircularProgress
                        className={`${moduleName}__circular-progress-spinner`}
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
        </div>
    )
}

export default CancelRescheduleSettingsLocationsList
