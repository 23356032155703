import { error } from 'react-notification-system-redux'
import download from 'downloadjs'

import ApiV2 from '../../ApiV2'
import { AppDispatch, AppThunk } from '../../appStore'
import { ReviewPendingReferralStatus, UnclaimedPendingReferralStatus } from '../../models/enums'
import { updatePendingReferralOnChat } from '../../modules/practices/v2actions'
import NotificationService from '../../services/NotificationService'

import * as CONST from './shared/constants'
import { ReferralFormSections } from './shared/enums'

export type ReceiveErrors = {
    type: 'RECEIVE_ERRORS'
    section: ReferralFormSections
    errors: ModelsV2.Errors.ErrorRecord[]
    uiIndex?: number
}

export type ReceiveUpdateReferralDashboardCard = {
    type: 'RECEIVE_UPDATE_REFERRAL_DASHBOARD_CARD'
    referralCard: ModelsV2.Referrals.ReferralsDashboardCard
}

export type ReceiveConnectAppointments = {
    type: 'RECEIVE_CONNECT_APPOINTMENTS'
    referralId: string
    connectAppointments: ModelsV2.Referrals.ConnectAppointments[]
}

export type ReceiveReferralAttachments = {
    type: 'RECEIVE_REFERRAL_ATTACHMENTS'
    referralId: string
    attachments: ModelsV2.Referrals.ReferralAttachment[]
}

export type ReceiveReferralPatientFinancialData = {
    type: 'RECEIVE_REFERRAL_PATIENTS_FINANCIAL_DATA'
    patientId: string
    patientsFinancialData: ModelsV2.Referrals.PatientsFinancialData
}

export type ReceiveSchedulingAppointment = {
    type: 'RECEIVE_REFERRAL_SCHEDULING_APPOINTMENT'
    scheduling_appointment_id: string
    schedulingAppointment: ModelsV2.Referrals.SchedulingAppointment
}

export type ReceiveReferralActivityLog = {
    type: 'RECEIVE_REFERRAL_ACTIVITY_LOG'
    referralId: string
    referralActivityLog: ModelsV2.Referrals.ActivityLog[]
}

export type ReceiveReferral = {
    type: 'RECEIVE_REFERRAL'
    referral: ModelsV2.Referrals.ReferralV2
}

export type SetNumberOfReferralsInReviewState = {
    type: 'SET_NUMBER_OF_REFERRALS_IN_REVIEW_STATE'
    numberOfReferralsInReview: number
}

export type ReceiveReferralsDashboard = {
    type: 'RECEIVE_REFERRALS_DASHBOARD'
    status: ModelsV2.Referrals.StatusValue
    referrals: ModelsV2.Referrals.ReferralsDashboardCard[]
    page: number
    pagination_info: ApiV2.PaginationInfo
}

export type ReceiveFormReferralData = {
    type: 'RECEIVE_FORM_REFERRAL_DATA'
    data: ModelsV2.Referrals.ReferralV2
}

export type ReceiveFormPrimaryPatientData = {
    type: 'RECEIVE_FORM_PRIMARY_PATIENT_DATA'
    data: ModelsV2.Referrals.ReferralPatient
}
export type ReceiveFormOtherPatientsData = {
    type: 'RECEIVE_FORM_OTHER_PATIENTS_DATA'
    data: ModelsV2.Referrals.ReferralPatient[]
}
export type ReceiveFormAtachments = {
    type: 'RECEIVE_FORM_ATTACHMENTS'
    attachments: {
        allAttachments?: ModelsV2.Referrals.ReferralFormAttachments
        addedAttachments?: ModelsV2.Referrals.ReferralFormAttachments
        removedAttachments?: ModelsV2.Referrals.ReferralFormAttachments
    }
}
export type SetDeleteReferralCard = {
    type: 'SET_DELETE_REFERRAL_CARD'
    referralId: string
}

export function deleteReferralCard(referralId: string): SetDeleteReferralCard {
    return {
        type: 'SET_DELETE_REFERRAL_CARD',
        referralId,
    }
}
export function updateReferralDashboardCard(
    referralCard: ModelsV2.Referrals.ReferralsDashboardCard,
): ReceiveUpdateReferralDashboardCard {
    return {
        type: 'RECEIVE_UPDATE_REFERRAL_DASHBOARD_CARD',
        referralCard,
    }
}
export function receiveFormReferralData(data: ModelsV2.Referrals.ReferralV2): ReceiveFormReferralData {
    return {
        type: 'RECEIVE_FORM_REFERRAL_DATA',
        data,
    }
}

export function receiveFormPrimaryPatientData(data: ModelsV2.Referrals.ReferralPatient): ReceiveFormPrimaryPatientData {
    return {
        type: 'RECEIVE_FORM_PRIMARY_PATIENT_DATA',
        data,
    }
}

export function receiveFormOtherPatientData(data: ModelsV2.Referrals.ReferralPatient[]): ReceiveFormOtherPatientsData {
    return {
        type: 'RECEIVE_FORM_OTHER_PATIENTS_DATA',
        data,
    }
}

export function receiveFormAttachments(attachments: {
    allAttachments?: ModelsV2.Referrals.ReferralFormAttachments
    addedAttachments?: ModelsV2.Referrals.ReferralFormAttachments
    removedAttachments?: ModelsV2.Referrals.ReferralFormAttachments
}): ReceiveFormAtachments {
    return {
        type: 'RECEIVE_FORM_ATTACHMENTS',
        attachments,
    }
}

export function receiveReferralsDashboard(
    page: number,
    status: ModelsV2.Referrals.StatusValue,
    referrals: ModelsV2.Referrals.ReferralsDashboardCard[],
    pagination_info: ApiV2.PaginationInfo,
): ReceiveReferralsDashboard {
    return {
        type: 'RECEIVE_REFERRALS_DASHBOARD',
        page,
        status,
        referrals,
        pagination_info,
    }
}
export function receiveReferralAttachments(
    referralId: string,
    attachments: ModelsV2.Referrals.ReferralAttachment[],
): ReceiveReferralAttachments {
    return {
        type: 'RECEIVE_REFERRAL_ATTACHMENTS',
        referralId,
        attachments,
    }
}

export function receiveConnectAppointments(
    referralId: string,
    connectAppointments: ModelsV2.Referrals.ConnectAppointments[],
): ReceiveConnectAppointments {
    return {
        type: 'RECEIVE_CONNECT_APPOINTMENTS',
        referralId,
        connectAppointments,
    }
}

export function receiveErrors(
    section: ReferralFormSections,
    errors: ModelsV2.Errors.ErrorRecord[],
    uiIndex?: number,
): ReceiveErrors {
    return {
        type: 'RECEIVE_ERRORS',
        section,
        errors,
        uiIndex,
    }
}

export function receiveReferralPatientsFinancialData(
    patientId: string,
    patientsFinancialData: ModelsV2.Referrals.PatientsFinancialData,
): ReceiveReferralPatientFinancialData {
    return {
        type: 'RECEIVE_REFERRAL_PATIENTS_FINANCIAL_DATA',
        patientId,
        patientsFinancialData,
    }
}

export function receiveSchedulingAppointment(
    scheduling_appointment_id: string,
    schedulingAppointment: ModelsV2.Referrals.SchedulingAppointment,
): ReceiveSchedulingAppointment {
    return {
        type: 'RECEIVE_REFERRAL_SCHEDULING_APPOINTMENT',
        scheduling_appointment_id,
        schedulingAppointment,
    }
}

export function receiveReferralActivtyLog(
    referralId: string,
    referralActivityLog: ModelsV2.Referrals.ActivityLog[],
): ReceiveReferralActivityLog {
    return {
        type: 'RECEIVE_REFERRAL_ACTIVITY_LOG',
        referralId,
        referralActivityLog,
    }
}

export function receiveReferral(referral: ModelsV2.Referrals.ReferralV2): ReceiveReferral {
    return {
        type: 'RECEIVE_REFERRAL',
        referral,
    }
}

export function setNumberOfReferralsInReviewState(
    numberOfReferralsInReview: number,
): SetNumberOfReferralsInReviewState {
    return {
        type: 'SET_NUMBER_OF_REFERRALS_IN_REVIEW_STATE',
        numberOfReferralsInReview,
    }
}

export function createPendingReferral(chatId: string): AppThunk<Promise<void>> {
    return async (dispatch: any) => {
        try {
            await ApiV2.Referrals.postChatReferral(chatId)
            await dispatch(updatePendingReferralOnChat(chatId))
        } catch (e) {
            throw e
        }
    }
}

//V2

export function fetchReferralsDashboard(
    query: ModelsV2.Referrals.ReferralDashboardQueryProperties,
): AppThunk<Promise<ModelsV2.Referrals.ReferralsDashboardCard[] | null>> {
    return async (dispatch: AppDispatch) => {
        if (!query.page) {
            query.page = CONST.REFERRAL_DASHBOARD_COLUMN_DEFAULT_PAGE
        }
        if (!query.limit) {
            query.limit = CONST.REFERRAL_DASHBOARD_COLUMN_DEFAULT_PAGE_SIZE
        }
        if (!query.sort) {
            query.sort = CONST.REFERRAL_DASHBOARD_COLUMN_DEFAULT_SORT_PROPERTY
        }
        if (!query.order) {
            query.order = CONST.REFERRAL_DASHBOARD_COLUMN_DEFAULT_SORT_DIRECTION
        }

        const status = query.filter.status
        const page = query.page

        try {
            const response = await ApiV2.Referrals.getReferralsDashboard(query)
            const referralsList = response.data
            const paginanation_info = response.metadata?.pagination_info || ({} as ApiV2.PaginationInfo)
            dispatch(receiveReferralsDashboard(page, status, referralsList, paginanation_info))

            sendPushNotifications(referralsList)

            return referralsList
        } catch (e) {
            globalThis['store'].dispatch(
                error({
                    message: `Error fetching "${status}" referrals.`,
                    position: 'tr',
                    autoDismiss: 7,
                }),
            )
            return null
        }
    }
}

export function resetReferralsDashboardColumn(status: ModelsV2.Referrals.StatusValue): AppThunk<Promise<null>> {
    return async (dispatch: AppDispatch) => {
        await dispatch(
            receiveReferralsDashboard(CONST.REFERRAL_DASHBOARD_COLUMN_DEFAULT_PAGE, status, [], {
                allPages: 0,
                allRows: 0,
            }),
        )
        return null
    }
}

export function updateReferral(
    referralId: string,
    referralData: Partial<ModelsV2.Referrals.ReferralV2>,
): AppThunk<Promise<ModelsV2.Referrals.ReferralV2 | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await ApiV2.Referrals.putReferral(referralId, referralData)
            return response
        } catch (e) {
            handleRequestError(dispatch, e, ReferralFormSections.REFERRAL)
            return null
        }
    }
}

export function updateAttachments(
    referralId: string,
    removedAttachmentIds: string[] | undefined,
    addedAttachmentsIds: string[] | undefined,
): AppThunk<Promise<boolean | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            if (removedAttachmentIds && removedAttachmentIds.length > 0) {
                await ApiV2.Referrals.deleteAttachments(referralId, removedAttachmentIds)
                dispatch(
                    receiveFormAttachments({
                        removedAttachments: {} as ModelsV2.Referrals.ReferralFormAttachments,
                    }),
                )
            }
            if (addedAttachmentsIds && addedAttachmentsIds.length > 0) {
                await ApiV2.Referrals.postAttachments(referralId, addedAttachmentsIds)
                dispatch(
                    receiveFormAttachments({
                        addedAttachments: {} as ModelsV2.Referrals.ReferralFormAttachments,
                    }),
                )
            }
            return true
        } catch (e) {
            handleRequestError(dispatch, e, ReferralFormSections.ATTACHMENTS)
            return null
        }
    }
}

export function createPatient(
    referralId: string,
    isPrimary: boolean,
    patientData: Partial<ModelsV2.Referrals.ReferralPatient>,
    uiIndex?: number,
): AppThunk<Promise<ModelsV2.Referrals.ReferralPatient | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const data = await ApiV2.Referrals.postPatient(referralId, patientData)
            return data
        } catch (e) {
            const section = isPrimary ? ReferralFormSections.PRIMARY_PATIENT : ReferralFormSections.OTHER_PATIENTS
            handleRequestError(dispatch, e, section, uiIndex)
            return null
        }
    }
}

export function updatePatient(
    patientId: string,
    isPrimary: boolean,
    patientData: Partial<ModelsV2.Referrals.ReferralPatient>,
    uiIndex?: number,
): AppThunk<Promise<ModelsV2.Referrals.ReferralPatient | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const data = await ApiV2.Referrals.putPatient(patientId, patientData)
            return data
        } catch (e) {
            const section = isPrimary ? ReferralFormSections.PRIMARY_PATIENT : ReferralFormSections.OTHER_PATIENTS
            handleRequestError(dispatch, e, section, uiIndex)
            return null
        }
    }
}

export function deletePatientMany(
    referralId: string,
    patientIds: string[],
): AppThunk<Promise<ModelsV2.Referrals.ReferralPatient[] | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const data = await ApiV2.Referrals.deletePatientsById(referralId, patientIds)
            return data
        } catch (e) {
            handleRequestError(dispatch, e, ReferralFormSections.OTHER_PATIENTS)
            return null
        }
    }
}

export function fetchReferralV2(referralId: string): AppThunk<Promise<ModelsV2.Referrals.ReferralV2 | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const referral = await ApiV2.Referrals.getReferral(referralId)
            dispatch(receiveReferral(referral))
            return referral
        } catch (e) {
            handleRequestError(dispatch, e)
            return null
        }
    }
}

export function fetchReferralAttachments(
    referralId: string,
): AppThunk<Promise<ModelsV2.Referrals.ReferralAttachment[] | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const attachments = await ApiV2.Referrals.getReferralAttachments(referralId)
            await dispatch(receiveReferralAttachments(referralId, attachments))
            return attachments
        } catch (e) {
            handleRequestError(dispatch, e)
            return null
        }
    }
}

export function fetchConnectAppointments(
    referralId: string,
): AppThunk<Promise<ModelsV2.Referrals.ConnectAppointments[] | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const connectAppointments = await ApiV2.Referrals.getConnectAppointments(referralId)
            await dispatch(receiveConnectAppointments(referralId, connectAppointments))
            return connectAppointments
        } catch (e) {
            handleRequestError(dispatch, e)
            return null
        }
    }
}

export function fetchSchedulingAppointments(
    scheduling_appointment_id: string,
): AppThunk<Promise<ModelsV2.Referrals.SchedulingAppointment | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const schedulingAppointment = await ApiV2.Referrals.getSchedulingAppointment(scheduling_appointment_id)
            await dispatch(receiveSchedulingAppointment(scheduling_appointment_id, schedulingAppointment))
            return schedulingAppointment
        } catch (e) {
            throw e
        }
    }
}

export function fetchReferralActivityLog(
    referralId: string,
): AppThunk<Promise<ModelsV2.Referrals.ActivityLog[] | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const referralActivityLog = await ApiV2.Referrals.getReferralActivityLog(referralId)
            await dispatch(receiveReferralActivtyLog(referralId, referralActivityLog))
            return referralActivityLog
        } catch (e) {
            handleRequestError(dispatch, e)
            return null
        }
    }
}

export function fetchReferralFinancialData(
    patientId: string,
): AppThunk<Promise<ModelsV2.Referrals.PatientsFinancialData | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const financialData = await ApiV2.Referrals.getReferralFinancialData(patientId)

            await dispatch(receiveReferralPatientsFinancialData(patientId, financialData))
            return financialData
        } catch (e) {
            handleRequestError(dispatch, e)
            return null
        }
    }
}

export function downloadReferral(referralId: string): AppThunk<Promise<string | null>> {
    return async (dispatch: AppDispatch) => {
        try {
            const { blob, filename } = await ApiV2.Referrals.downloadReferral(referralId)
            download(blob, filename, 'application/pdf')
            return filename
        } catch (e) {
            handleRequestError(dispatch, e)
            return null
        }
    }
}

export function fetchNumberOfReferralsInReview() {
    return async (dispatch: any) => {
        const numberOfReferralsInReview = await dispatch(fetchReferralsDashboard({ filter: { status: 'review' } }))
        if (numberOfReferralsInReview !== null) {
            await dispatch(setNumberOfReferralsInReviewState(numberOfReferralsInReview.length))
        }
    }
}

export function sendPushNotifications(referrals: ModelsV2.Referrals.ReferralsDashboardCard[]) {
    const notifications = {}
    const dateNowTimestamp: number = new Date().getTime()

    referrals.forEach(referral => {
        if (
            [UnclaimedPendingReferralStatus.id, ReviewPendingReferralStatus.id].includes(referral.amplify_status.id) &&
            dateNowTimestamp - new Date(referral.updated).getTime() < 5000
        ) {
            notifications[referral.amplify_status.id] = true
        }
    })

    if (notifications[UnclaimedPendingReferralStatus.id]) {
        NotificationService.notify('New referral')
    }

    if (notifications[ReviewPendingReferralStatus.id]) {
        NotificationService.notify('Referral in Review')
    }
}

export function handleRequestError(
    dispatch: AppDispatch,
    e: ModelsV2.Errors.ResponseError,
    section?: ReferralFormSections,
    uiIndex?: number,
) {
    if (e.statusCode === 400 && section) {
        dispatch(receiveErrors(section, e.errors, uiIndex))
    }
    if (e.errors) {
        globalThis['store'].dispatch(
            error({
                message: e.errors.map((e: ModelsV2.Errors.ErrorRecord) => e.details).join('; '),
                position: 'tr',
                autoDismiss: 20,
            }),
        )
    } else if (e.message) {
        let msg = e.message
        if (section) {
            msg = `${section}: ${msg}`
        }
        globalThis['store'].dispatch(
            error({
                message: msg,
                position: 'tr',
                autoDismiss: 20,
            }),
        )
    }
}
