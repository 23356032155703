import PendingUpload from '../../models/PendingUpload'
import ApiService from '../service'

const API_UPLOAD_HOST = process.env.REACT_APP_API_UPLOAD_HOST

export default class UploadsService extends ApiService {
    readonly ModulePath: string = 'api/messaging/v1'

    constructor(token: string) {
        super(token)
        this.host = API_UPLOAD_HOST
    }

    async postFinishFileUpload(pendingUpload: PendingUpload | Models.Upload) {
        const { data: upload } = (await this.fetch(
            `uploads/${pendingUpload.id}/finish`,
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    product: 'amplify',
                }),
            },
            {
                structured: true,
            },
        )) as Api.StructuredResponse<Api.Uploads.Status>
        return upload
    }

    async postCancelFileUpload(upload: PendingUpload | Models.Upload) {
        const { data: uploadStatus } = (await this.fetch(
            `uploads/${upload.id}/cancel`,
            {
                method: 'POST',
                mode: 'cors',
            },
            {
                structured: true,
            },
        )) as Api.StructuredResponse<Api.Uploads.Status>

        return uploadStatus
    }

    async postFileUploadPart(pendingUpload: PendingUpload, part: any, partNumber: number) {
        const body = new FormData()
        body.append('file_part', part)

        const { data: upload } = (await this.fetch(
            `uploads/${pendingUpload.id}/part/${partNumber}`,
            {
                method: 'POST',
                mode: 'cors',
                headers: new Headers(),
                body,
            },
            {
                structured: true,
            },
        )) as Api.StructuredResponse<Api.Uploads.UploadPart>

        return upload
    }

    async postStartFileUpload(file: File, product: 'amplify' = 'amplify') {
        const { data: upload } = (await this.fetch(
            'uploads',
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    file: {
                        filename: file.name,
                        filesize: file.size,
                        type: file.type,
                    },
                    product,
                }),
            },
            {
                structured: true,
            },
        )) as Api.StructuredResponse<Api.Uploads.Upload>

        return upload
    }
}
