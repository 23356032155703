import * as React from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import iassign from 'immutable-assign'

import CustomPhoneInput from '../../shared/custom-fields/CustomPhoneInput'
import { FormFieldElement, validate } from '../../shared/form-validator/validator'

import './StaffFormNotificationPhoneNumberModal.sass'

interface StaffFormNotificationPhoneNumberModalProps {
    value?: string
}

export type StaffFormNotificationPhoneNumberModalDispatch = {
    onClose: () => void
    onSavePhoneNumber: (phoneNumber: string) => void
    validatePhoneNumber: (phoneNumber: string) => Promise<{ isValid: boolean; message: string }>
}

interface FormElements {
    phoneNumber: FormFieldElement
}

export type StaffFormNotificationPhoneNumberModalState = {
    formElements: FormElements
    isFormValid: boolean
    isFormDirty: boolean
    showFormErrors: boolean
    errorMessage: string
}

type Props = StaffFormNotificationPhoneNumberModalProps & StaffFormNotificationPhoneNumberModalDispatch

class StaffFormNotificationPhoneNumberModal extends React.Component<Props, StaffFormNotificationPhoneNumberModalState> {
    constructor(props: Props) {
        super(props)

        this.state = {
            formElements: {
                phoneNumber: {
                    value: this.props.value || '',
                    validators: [
                        {
                            type: (value: string) => {
                                const pn = isValidPhoneNumber(value, 'US')
                                return pn
                            },
                            errorMessage: 'Invalid phone number',
                        },
                    ],
                    isValid: false,
                    isDirty: false,
                },
            },
            isFormValid: false,
            isFormDirty: false,
            showFormErrors: false,
            errorMessage: '',
        }
    }

    onClose = () => {
        this.props.onClose()
    }

    onSave = () => {
        this.setState(
            iassign(this.state, (next: StaffFormNotificationPhoneNumberModalState) => {
                next.showFormErrors = true
                return next
            }),
            async () => {
                const phoneNumber = this.state.formElements.phoneNumber.value

                if (this.state.isFormValid) {
                    const { isValid, message } = await this.props.validatePhoneNumber(phoneNumber)

                    if (!isValid) {
                        this.setState({ errorMessage: message })
                        return
                    }

                    this.props.onSavePhoneNumber(phoneNumber)
                    this.onClose()
                    return
                }
            },
        )
    }

    onUpdate = (modifier: (changes: FormElements) => void) => {
        return this.setState(
            iassign(this.state, (next: StaffFormNotificationPhoneNumberModalState) => {
                modifier(next.formElements)
                next.isFormDirty = true
                next.errorMessage = ''
                return next
            }),
            this.validate,
        )
    }

    validate() {
        this.setState(
            iassign(this.state, (next: StaffFormNotificationPhoneNumberModalState) => {
                next.isFormValid = validate(this.state.formElements)
                return next
            }),
        )
    }

    render() {
        const { showFormErrors, formElements, errorMessage } = this.state

        return (
            <div>
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            this.onClose()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents staff-form-notification-phone-number-modal">
                        <div className="close-modal-button" onClick={this.onClose}>
                            <i className="material-icons">close</i>
                        </div>
                        <div className="title">{this.props.value ? 'Edit Phone Number' : 'Add Mobile Phone #'}</div>
                        <div className="instruction">Please enter user’s mobile phone number.</div>

                        <div className="phone-number-field">
                            <CustomPhoneInput
                                error={showFormErrors && formElements.phoneNumber.firstErrorMessage}
                                errorMessage={formElements.phoneNumber.firstErrorMessage}
                                value={formElements.phoneNumber.value}
                                label="Mobile Phone*"
                                country={'US'}
                                onChange={value =>
                                    this.onUpdate(({ phoneNumber: u }) => {
                                        u.value = value
                                        u.isDirty = true
                                    })
                                }
                            />
                        </div>

                        {errorMessage && (
                            <div className="error-message__container">
                                <p className="error-message">{errorMessage}</p>
                            </div>
                        )}

                        <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                            <Grid item={true}>
                                <Button color="primary" variant="outlined" onClick={this.onClose} className="primary">
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item={true}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.onSave}
                                    disabled={!this.state.isFormDirty}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default StaffFormNotificationPhoneNumberModal
