import React, { useCallback, useEffect, useRef } from 'react'

import NotificationService from '../../../services/NotificationService'

import ChatCard from './ChatCard'

import './UnclaimedChats.sass'

const SOUND_FREQUENCY = 1000

export type UnclaimedChatsProps = {
    unclaimed: string[]
    chats: { [key: string]: Models.ChatMetadata }
    claimChat: (chat: Models.ChatMetadata) => void
}

const UnclaimedChats = ({ unclaimed, chats, claimChat }: UnclaimedChatsProps) => {
    const notificationInterval = useRef<number | undefined>()

    const startSound = useCallback(() => {
        if (!notificationInterval.current) {
            notificationInterval.current = window.setInterval(NotificationService.playSound, SOUND_FREQUENCY)
        }
    }, [])

    const stopSound = useCallback(() => {
        clearInterval(notificationInterval.current)
        notificationInterval.current = undefined
    }, [])

    useEffect(() => {
        if (unclaimed?.length) {
            startSound()
        }

        return stopSound
    }, [unclaimed, startSound, stopSound])

    const sorted = unclaimed?.sort((a: string, b: string) => chats[a].created.getTime() - chats[b].created.getTime())

    return (
        <div className="unclaimed-chats">
            {sorted?.map((chatId: string) => (
                <ChatCard key={chatId} chat={chats[chatId]} onButton={claimChat} />
            ))}
        </div>
    )
}

export default UnclaimedChats
