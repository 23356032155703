import { useState } from 'react'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { useAppDispatch } from 'util/useAppDispatch'
import { loadPaymentStatus, savePaymentStatus } from './actions'
import CustomMultiselectField from 'modules/shared/custom-fields/CustomMultiselectField'

import './AdminToolsShared.sass'
import { transactionIdLabel } from './utils'

interface Props {
    onClose: () => void
}

export const mapOldStatusToNewStatusPossiblities = {
    '': [],
    paid: ['failed', 'cancelled'],
    unpaid: ['cancelled'],
    scheduled: ['failed', 'cancelled'],
    failed: ['unpaid', 'cancelled', 'scheduled'],
    refunded: ['unpaid', 'paid'],
    partially_refunded: ['unpaid', 'cancelled', 'paid'],
    cancelled: ['unpaid', 'paid'],
}

export const mapTransactionStatusToCopy = (status: string) => {
    const statusMap = {
        aborted: 'Aborted',
        cancelled: 'Canceled',
        card_expiring: 'Card Expiring',
        disputed: 'Disputed',
        failed: 'Failed',
        lack_of_funds: 'Lack of Funds',
        paid: 'Paid',
        partially_refunded: 'Partially Refunded',
        pending: 'Pending',
        practice_cancelled: 'Canceled',
        refunded: 'Refunded',
        scheduled: 'Scheduled',
        stripe_pending: 'Processing',
        unpaid: 'Unpaid',
    }
    return statusMap[status]
}

const SwapTransactionsModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [screenIndex, setScreenIndex] = useState(0)
    const [transactionId, setTransactionId] = useState('')
    const [isLoadingTransactionStatus, setIsLoadingTransactionStatus] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        transactionId: string
        currentStatus: string
    }>({
        transactionId: '',
        currentStatus: '',
    })
    const [selectedStatus, setSelectedStatus] = useState('')
    const [isSavingStatus, setIsSavingStatus] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const goBackScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadPaymentStatus = async () => {
        if (isLoadingTransactionStatus) {
            return
        }
        setIsLoadingTransactionStatus(true)
        try {
            const statusData = await dispatch(loadPaymentStatus(transactionId))
            setLoadedData({
                transactionId: statusData.chargeEventId,
                currentStatus: statusData.chargeEventStatus,
            })
            goForwardScreen()

            if (mapOldStatusToNewStatusPossiblities[statusData.chargeEventStatus] === undefined) {
                setErrorMessage(
                    `Only transactions with these statuses can be changed: ${Object.keys(
                        mapOldStatusToNewStatusPossiblities,
                    )
                        .filter(k => k !== '')
                        .reduce((prev, curr, index, arr) => {
                            if (index === 0) {
                                return mapTransactionStatusToCopy(curr)
                            } else if (index < arr.length - 1) {
                                return `${prev}, ${mapTransactionStatusToCopy(curr)}`
                            } else {
                                return `${prev}, and ${mapTransactionStatusToCopy(curr)}`
                            }
                        }, '')}.`,
                )
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsLoadingTransactionStatus(false)
    }

    const onSaveStatus = async () => {
        if (isSavingStatus) {
            return
        }
        setIsSavingStatus(true)
        try {
            const statusData = await dispatch(
                savePaymentStatus(loadedData.transactionId, loadedData.currentStatus, selectedStatus),
            )
            if (statusData === true) {
                goForwardScreen()
                setSuccessMessage(
                    `Transaction ${transactionId} status was successfully updated to ${mapTransactionStatusToCopy(
                        selectedStatus,
                    ).toUpperCase()}.`,
                )
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsSavingStatus(false)
    }

    return (
        <DefaultModal
            title="Change Status of a Payment"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row admin-tools-row--narrow">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={transactionIdLabel}
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={transactionId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingTransactionStatus,
                        disabled: transactionId === '',
                        onClick: onLoadPaymentStatus,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Charge Event/Transaction ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.transactionId}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Current Status:</div>{' '}
                                    <div className="admin-tools-details-value admin-tools-details-value--uppercase">
                                        {mapTransactionStatusToCopy(loadedData.currentStatus)}
                                    </div>
                                </div>
                            </div>
                            {mapOldStatusToNewStatusPossiblities[loadedData.currentStatus] !== undefined && (
                                <div className="admin-tools-select-wrapper">
                                    <CustomMultiselectField
                                        items={mapOldStatusToNewStatusPossiblities[
                                            loadedData.currentStatus
                                        ].map((s: string) => ({ display: mapTransactionStatusToCopy(s), value: s }))}
                                        selectedItems={[selectedStatus]}
                                        maxSelected={1}
                                        keyProperty="value"
                                        displayProperty="display"
                                        label="Change status to:"
                                        placeholder="Select Status"
                                        onSelectElement={(values: string[]) => {
                                            setSelectedStatus(values[0])
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        disabled: selectedStatus === '',
                        onClick: goForwardScreen,
                        text: 'Review Changes',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                You are about to change the status of this payment. Please confirm the following
                                changes:
                            </p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">{transactionIdLabel}:</div>
                                    <div className="admin-tools-details-value">{loadedData.transactionId}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Current Status:</div>
                                    <div className="admin-tools-details-value admin-tools-details-value--uppercase">
                                        {mapTransactionStatusToCopy(loadedData.currentStatus)}
                                    </div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Change Status to:</div>
                                    <div className="admin-tools-details-value admin-tools-details-value--uppercase">
                                        {mapTransactionStatusToCopy(selectedStatus)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isSavingStatus,
                        onClick: onSaveStatus,
                        text: 'Submit',
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default SwapTransactionsModal
