import * as React from 'react'
import * as PubNub from 'pubnub'
import { CATEGORIES, StatusEvent } from 'pubnub'

import AdminAccount from '../../models/AdminAccount'
import PubNubService from '../../services/PubNubService'
import { newObjectId } from '../../util/objectId'

import {
    checkPatientOnlineStatus,
    fetchAuthKey,
    fetchClaimedChats,
    fetchLatestChats,
    fetchNewerChatMsgs,
    handleEvent,
    handleMessage,
    patientRejoinedChat,
    setMessagePreview,
    setNetworkState,
    setPatientOnlineStatus,
} from './actions'

const { REACT_APP_SMILE_SPECIALIST_CHANNEL } = process.env

export type PubNubEventManagerProps = {
    account: AdminAccount
    authKey: string | null
    claimedChats?: { [channel: string]: Models.ChatMetadata }
    selectedChats: Models.ChatMetadata[] | null
    conversations: { [channel: string]: Models.Chat }
}

export type PubNubEventManagerDispatch = {
    fetchAuthKey: typeof fetchAuthKey
    fetchLatestChats: typeof fetchLatestChats
    fetchClaimedChats: typeof fetchClaimedChats
    handleEvent: typeof handleEvent
    handleMessage: typeof handleMessage
    setMessagePreview: typeof setMessagePreview
    fetchNewerChatMsgs: typeof fetchNewerChatMsgs
    setNetworkState: typeof setNetworkState
    patientRejoinedChat: typeof patientRejoinedChat
    setPatientOnlineStatus: typeof setPatientOnlineStatus
    checkPatientOnlineStatus: typeof checkPatientOnlineStatus
}

export type PubNubEventManagerState = {}

type Props = PubNubEventManagerProps & PubNubEventManagerDispatch

function isEvent(
    message: PubNub.MessageEvent<Services.PubNub.MessageEvent>,
): message is PubNub.MessageEvent<Services.PubNub.Event> {
    return message.channel === REACT_APP_SMILE_SPECIALIST_CHANNEL
}

class PubNubEventManager extends React.Component<Props, PubNubEventManagerState> {
    listener: PubNub.ListenerParameters<Services.PubNub.MessageEvent, Services.PubNub.Presence>

    constructor(props: Props) {
        super(props)

        this.listener = {
            message: this.onMessage,
            presence: this.onPresence,
            status: this.onStatus,
        }
    }

    componentDidMount() {
        this.props.fetchAuthKey()
        PubNubService.setChatterId(this.props.account.id)
        this.props.fetchLatestChats()
        PubNubService.addListener(this.listener)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.authKey && prevProps.authKey !== this.props.authKey) {
            PubNubService.setAuthKey(this.props.authKey)
            PubNubService.subscribe([REACT_APP_SMILE_SPECIALIST_CHANNEL as string], false)
            this.props.fetchClaimedChats()
        }
    }

    componentWillUnmount() {
        PubNubService.unsubscribeAll()
        PubNubService.removeListener(this.listener)
    }

    onPresence = (presence: PubNub.PresenceEvent<Services.PubNub.Presence>) => {
        if (presence && presence.state && presence.state.preview != null) {
            this.props.setMessagePreview(presence.channel, presence.state.preview)
        }

        if (presence?.action === 'leave') {
            this.checkIfPatientLeftChat(presence)
        }

        if (presence?.action === 'join') {
            this.checkIfPatientOrChatterRejoinedChat(presence)
        }
    }

    checkIfPatientLeftChat = (presence: PubNub.PresenceEvent<Services.PubNub.Presence>) => {
        const channelName = presence.channel
        const { selectedChats } = this.props
        const chat = selectedChats?.find(chat => chat.channelName === channelName)
        // const currentUserIsClaimee = this.props.account.id === chat?.claimee?.id

        const isPatient = chat?.meta?.pubnub_user_id === presence.uuid

        if (chat && isPatient) {
            this.props.setPatientOnlineStatus(chat, false)
            // PubNubService.send(channelName, {
            //     id: newObjectId(32),
            //     text: 'Patient left chat.',
            //     is_patient: false,
            //     sender_id: PubNubService.getChatterId(),
            //     type: 'system:patient-left-chat',
            // })
        }
    }

    checkIfPatientOrChatterRejoinedChat = (presence: PubNub.PresenceEvent<Services.PubNub.Presence>) => {
        const channelName = presence.channel
        const { selectedChats } = this.props
        const chat = selectedChats?.find(chat => chat.channelName === channelName)
        const currentUserIsClaimee = this.props.account.id === chat?.claimee?.id
        const isPatient = chat?.meta?.pubnub_user_id === presence.uuid

        if (chat && isPatient) {
            this.props.setPatientOnlineStatus(chat, true)
        }
        if (chat && currentUserIsClaimee && isPatient) {
            this.props.patientRejoinedChat(chat)

            PubNubService.send(channelName, {
                id: newObjectId(32),
                text: 'Patient rejoined chat.',
                is_patient: false,
                sender_id: PubNubService.getChatterId(),
                type: 'system:patient-rejoined-chat',
            })
        }

        if (chat && !isPatient) {
            this.props.checkPatientOnlineStatus(chat)
        }
        if (chat && currentUserIsClaimee && !isPatient) {
            this.props.patientRejoinedChat(chat)

            PubNubService.send(channelName, {
                id: newObjectId(32),
                text: 'Chatter rejoined chat.',
                is_patient: false,
                sender_id: PubNubService.getChatterId(),
                type: 'system:chatter-rejoined-chat',
            })
        }
    }

    onMessage = (message: PubNub.MessageEvent<Services.PubNub.MessageEvent>) => {
        const { claimedChats } = this.props
        const chat = claimedChats && claimedChats[message.channel]

        return isEvent(message)
            ? this.props.handleEvent(message)
            : this.props.handleMessage(message as PubNub.MessageEvent<Services.PubNub.Message>, chat)
    }

    onStatus = (statusEvent: StatusEvent) => {
        const { selectedChats, conversations } = this.props

        if (statusEvent.category === CATEGORIES.PNNetworkUpCategory && selectedChats) {
            selectedChats.forEach(selectedChat => {
                this.props.fetchNewerChatMsgs(selectedChat.channelName, conversations[selectedChat.channelName])
                const whisperChannelName = `whisper_${selectedChat.channelName}`
                this.props.fetchNewerChatMsgs(whisperChannelName, conversations[whisperChannelName])
                PubNubService.subscribe([selectedChat.channelName, `whisper_${selectedChat.channelName}`])
            })
        }

        if (statusEvent.category === CATEGORIES.PNNetworkUpCategory) {
            PubNubService.subscribe([REACT_APP_SMILE_SPECIALIST_CHANNEL as string], false)
            this.props.fetchLatestChats()
            this.props.setNetworkState(true)
        }

        if (statusEvent.category === CATEGORIES.PNNetworkDownCategory) {
            this.props.setNetworkState(false)
        }
    }

    render() {
        return <div />
    }
}

export default PubNubEventManager
