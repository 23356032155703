import { PracticeSurvey, SurveyDoctorRecord, SurveySection } from '../../models/PracticeSurvey'
import { mapSurveyActivityLogs } from '../mappers/survey/surveyActivityLogs'
import { mapDoctors } from '../mappers/survey/surveyDoctors'
import { mapHours } from '../mappers/survey/surveyHours'
import { mapLocationInformation } from '../mappers/survey/surveyLocationInformation'
import { mapSurveys } from '../mappers/survey/surveys'
import ApiService from '../service'

export default class SurveyService extends ApiService {
    private readonly PAGE_SIZE = 25

    async getLocationSurveyDoctors(locationId: string) {
        const { data: doctors } = (await this.fetch(`practice-locations/${locationId}/doctors`, {
            method: 'GET',
        })) as Api.StructuredResponse<ApiV2.Survey.Doctor[]>

        return await mapDoctors(doctors)
    }

    postLocationSurveyDoctor(locationId: string, doctor: SurveyDoctorRecord) {
        return this.fetch(
            `practice-locations/${locationId}/doctors`,
            {
                method: 'POST',
                body: JSON.stringify({
                    first_name: doctor.firstName.value,
                    last_name: doctor.lastName.value,
                    suffix: doctor.suffix.value,
                }),
            },
            undefined,
            undefined,
            true,
        )
    }

    putLocationSurveyDoctor(locationId: string, doctor: SurveyDoctorRecord) {
        return this.fetch(
            `practice-locations/${locationId}/doctors/${doctor.id.value}`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    first_name: doctor.firstName.value,
                    last_name: doctor.lastName.value,
                    suffix: doctor.suffix.value,
                }),
            },
            undefined,
            undefined,
            true,
        )
    }

    async deleteLocationSurveyDoctor(locationId: string, doctor: SurveyDoctorRecord) {
        await this.fetch(`practice-locations/${locationId}/doctors/${doctor.id.value}`, {
            method: 'DELETE',
        })
    }

    async saveLocationSurveyDoctors(locationId: string, survey: PracticeSurvey) {
        return Promise.allSettled(
            survey.doctors.list.map(doctor =>
                doctor.id.value
                    ? this.putLocationSurveyDoctor(locationId, doctor)
                    : this.postLocationSurveyDoctor(locationId, doctor),
            ),
        )
    }

    async getLocationSurveyOperatingHours(locationId: string) {
        const { data: hours } = (await this.fetch(`practice-locations/${locationId}/operating-hours`, {
            method: 'GET',
        })) as Api.StructuredResponse<ApiV2.Survey.OperatingHours[]>

        return await mapHours(hours)
    }

    async saveLocationSurveyOperatingHours(locationId: string, survey: PracticeSurvey) {
        await this.fetch(
            `practice-locations/${locationId}/operating-hours/upsert-many`,
            {
                method: 'POST',
                body: JSON.stringify({
                    practice_location_ids: survey.operatingHours.practiceLocationIds,
                    hours: survey.operatingHours.days.map(day => ({
                        day_of_week: day.dayOfWeek,
                        open_at: day.open,
                        close_at: day.close,
                        enabled: day.enabled,
                    })),
                }),
            },
            undefined,
            undefined,
            true,
        )
    }

    async postLocationSurveySection(locationId: string, surveyId: string, section: SurveySection) {
        return await this.fetch(
            `practice-locations/${locationId}/surveys/${surveyId}/sections/${section.id}/update-answers`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    answers: section.questions.map(question => ({
                        question_id: question.id,
                        answer_schema: question.answer_schema,
                        ...(section.practiceLocationIds &&
                            !question?.variants?.includes('dont_send_location_ids') && {
                                practice_location_ids: section.practiceLocationIds,
                            }),
                    })),
                }),
            },
            undefined,
            undefined,
            true,
        )
    }

    async getLocationSurveyLocationInformation(location: Models.PracticeLocation, addressId: string) {
        const { data: locationInfiormation } = (await this.fetch(
            `practice-locations/${location.id}/addresses/${addressId}`,
            {
                method: 'GET',
            },
        )) as Api.StructuredResponse<ApiV2.Survey.LocationInformation>

        return mapLocationInformation(locationInfiormation, location)
    }

    async getLocationSurveys(locationId: string, surveyId: string) {
        const { data: surveyData } = (await this.fetch(`practice-locations/${locationId}/surveys/${surveyId}`, {
            method: 'GET',
        })) as Api.StructuredResponse<ApiV2.Survey.SurveyData>

        return mapSurveys(surveyData.survey_layout.sections, surveyData.survey_layout.name)
    }

    async getSurveyActivityLogs(surveyId: string, searchTerms: ApiV2.Survey.ActivityLogsSearchTerms) {
        const { limit = this.PAGE_SIZE, page, sort = 'created', order = 'desc' } = searchTerms
        const { data: activityLogsData, metadata } = (await this.fetch(
            `surveys/${surveyId}/survey-logs?limit=${limit}&page=${page}&include[]=account&sort=${sort}&order=${order}`,
            {
                method: 'GET',
            },
        )) as ApiV2.StructuredResponse<ApiV2.Survey.SurveyActivityLog[]>

        return { paginationInfo: metadata?.pagination_info, data: mapSurveyActivityLogs(activityLogsData) }
    }
}
