import React, { useEffect, useMemo, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

type Size = {
    width?: number
    height?: number
}

type Breakpoints = {
    width?: { [key: string]: number }
    height?: { [key: string]: number }
}

type Params = {
    ref?: React.RefObject<HTMLElement | Element>
    onResize?: (size: Size) => void
    breakpoints?: Breakpoints
}

const defaultBreakpoints = {
    width: {
        XS: 0,
        SM: 384,
        MD: 576,
        LG: 768,
        XL: 960,
    },
}

export const useResponsiveClasses = ({ ref, breakpoints, onResize }: Params = { breakpoints: defaultBreakpoints }) => {
    const defaultRef = useRef<HTMLElement | Element>(null)
    const referral = ref || defaultRef
    const [size, setSize] = useState<Size>({
        width: undefined,
        height: undefined,
    })
    const [responsiveClassName, setResponsiveClassName] = useState<string>('')

    // Using a ref to track the previous width / height to avoid unnecessary renders
    const previous = useRef<Size>({
        width: undefined,
        height: undefined,
    })

    useEffect(() => {
        if (typeof referral !== 'object' || referral === null || !(referral.current instanceof Element)) {
            return
        }

        const element = referral.current
        const resizeObserver = new ResizeObserver(entries => {
            if (!Array.isArray(entries)) {
                return
            }

            // Since we only observe the one element, we don't need to loop over the
            // array
            if (!entries.length) {
                return
            }

            const entry = entries[0]
            const newWidth = Math.round(entry.contentRect.width)
            const newHeight = Math.round(entry.contentRect.height)

            if (previous.current.width !== newWidth || previous.current.height !== newHeight) {
                const newSize = { width: newWidth, height: newHeight }
                const customClassNames = Object.keys(breakpoints?.width ?? {})

                if (customClassNames.length) {
                    const customClassName = customClassNames.reduce((className, breakpointClassName) => {
                        if (!breakpoints?.width) {
                            return className
                        }
                        var minWidth = breakpoints.width[breakpointClassName]
                        if (entry.contentRect.width >= minWidth) {
                            return breakpointClassName
                        }
                        return className
                    }, '')

                    customClassNames.forEach(className => {
                        if (className === customClassName) {
                            entry.target.classList.add(className)
                            return
                        }
                        entry.target.classList.remove(className)
                    })

                    setResponsiveClassName(customClassName)
                }

                if (onResize) {
                    onResize(newSize)
                } else {
                    previous.current.width = newWidth
                    previous.current.height = newHeight
                    setSize(newSize)
                }
            }
        })

        resizeObserver.observe(element)

        return () => resizeObserver.unobserve(element)
    }, [referral, onResize, breakpoints])

    return useMemo(() => ({ ref: referral, responsiveClassName, width: size.width, height: size.height }), [
        responsiveClassName,
        referral,
        size.height,
        size.width,
    ])
}
