import { Dashboards } from '../../ApiV2/services/DataService'
import AdminAccount from '../../models/AdminAccount'
import MetabaseData from '../amplify/data/MetabaseData'
import InfoMessage from '../shared/info-message/info-message'

interface Props {
    practice: Models.Practice
    account: AdminAccount
}

export type ChairfillViews = 'main' | 'referrals'

import ChairfillViewsList from './ChairfillViewsList'

const ChairfillTab = ({ practice, account }: Props) => {
    return (
        <div>
            <ChairfillViewsList practice={practice} account={account} />
            {process.env.REACT_APP_ENV === 'production' ? (
                <MetabaseData practice={practice} source={Dashboards.INVITE} />
            ) : (
                <InfoMessage isShown={true}>Metabase is only available in production.</InfoMessage>
            )}
        </div>
    )
}

export default ChairfillTab
