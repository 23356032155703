import * as React from 'react'

import SelfSchedulingAdditionalComments from './SelfSchedulingAdditionalComments'
import SelfSchedulingAdditionalFeaturesAndFieldsSwitch from './SelfSchedulingAdditionalFeaturesAndFieldsSwitch'
import SelfSchedulingEnableCustomConfirmationPage from './SelfSchedulingEnableCustomConfirmationPage'
import SelfSchedulingInsurance from './SelfSchedulingInsurance'
import SelfSchedulingOpenURLInRadioGroup from './SelfSchedulingOpenURLInRadioGroup'

import './SelfSchedulingAdditionalFeaturesAndFieldsOptions.sass'

type Props = {
    additionalFeaturesAndFields: Models.SelfSchedulingAdditionalFeaturesAndFields
    updateInsurance: (insurance: Models.SelfSchedulingInsurance) => void
    updateAdditionalComments: (additionalComments: Models.SelfSchedulingAdditionalComments) => void
    enableMaps: (enabled: boolean) => void
    enableProvider: (enabled: boolean) => void
    enableCustomConfirmationPage: (enabled: boolean) => void
    updateOpenURLIn: (openURLIn: Models.SelfSchedulingOpenURLIn) => void
    updateCustomConfirmationPageURL: (url: string) => void
}

const moduleName = 'self-scheduling-additional-features-and-fields-options'

function SelfSchedulingAdditionalFeaturesAndFieldsOptions(props: Props) {
    const {
        additionalFeaturesAndFields,
        updateInsurance,
        updateAdditionalComments,
        enableMaps,
        enableProvider,
        enableCustomConfirmationPage,
        updateOpenURLIn,
        updateCustomConfirmationPageURL,
    } = props

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__insurance-and-add-info`}>
                <div className={`${moduleName}__left`}>
                    <SelfSchedulingInsurance
                        insurance={additionalFeaturesAndFields.insurance}
                        updateInsurance={updateInsurance}
                    />
                    <SelfSchedulingAdditionalComments
                        additionalComments={additionalFeaturesAndFields.additionalComments}
                        updateAdditionalComments={updateAdditionalComments}
                    />
                </div>
                <div className={`${moduleName}__right`}>
                    <SelfSchedulingAdditionalFeaturesAndFieldsSwitch
                        checked={additionalFeaturesAndFields.enabledMaps}
                        title="Enable Maps"
                        enableSwitch={enableMaps}
                    />
                    <SelfSchedulingAdditionalFeaturesAndFieldsSwitch
                        checked={additionalFeaturesAndFields.enabledProviderSelection}
                        title="Enable Provider Selection"
                        enableSwitch={enableProvider}
                    />
                </div>
            </div>
            <div className={`${moduleName}__enable-custom-confirmation-page`}>
                <div className={`${moduleName}__bottom`}>
                    <SelfSchedulingEnableCustomConfirmationPage
                        checked={Boolean(additionalFeaturesAndFields.customRedirect?.enabled)}
                        url={additionalFeaturesAndFields.customRedirect?.url || ''}
                        title="Enable Custom Confirmation Page"
                        updateCustomConfirmationPageURL={updateCustomConfirmationPageURL}
                        enableSwitch={enableCustomConfirmationPage}
                    />
                    <SelfSchedulingOpenURLInRadioGroup
                        enabled={Boolean(additionalFeaturesAndFields.customRedirect?.enabled)}
                        openURLIn={additionalFeaturesAndFields.customRedirect?.target}
                        updateOpenURLIn={updateOpenURLIn}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingAdditionalFeaturesAndFieldsOptions
