const permissionListData: Array<Api.Permission> = [
    {
        id: 2,
        type: 'customer',
        display_name: 'Simplifeye Customer',
        is_admin: false,
        is_public: false,
    },
    {
        id: 3,
        type: 'agent_installer',
        display_name: 'Simplifeye Agent Installer',
        is_admin: false,
        is_public: false,
    },
    {
        id: 4,
        type: 'call_list',
        display_name: 'Call List',
        is_admin: false,
        is_public: false,
    },
    {
        id: 5,
        type: 'all_patients',
        display_name: 'All Patients',
        is_admin: false,
        is_public: false,
    },
    {
        id: 7,
        type: 'metrics_access',
        display_name: 'Metrics Access',
        is_admin: false,
        is_public: false,
    },
    {
        id: 8,
        type: 'practice_admin',
        display_name: 'Practice Admin',
        is_admin: false,
        is_public: false,
    },
]

export default permissionListData
