import React from 'react'
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import map from 'lodash/map'

import { QuestionOption, SurveySectionQuestion } from '../../../../../models/PracticeSurvey'

import ProceduresTabs from './ProceduresTabs'
import SpecialtyTab from './SpecialtyTab'
import { groupProceduresBySpecialty } from './utils'
import { formatSpecialtyName } from './utils'

import './ProceduresEdit.sass'

type ProceduresViewProps = {
    question: SurveySectionQuestion
}

const moduleName = 'survey-procedures-edit'

const ProceduresView = (props: ProceduresViewProps) => {
    const { question } = props
    const other_option = question.answer_schema?.other_option

    const renderQuestions = () => {
        const proceduresBySpecialty = groupProceduresBySpecialty(question)
        return (
            <Grid item={true} xs={12}>
                <ProceduresTabs edit={false}>
                    {map(proceduresBySpecialty, (options: QuestionOption[], specialty) => {
                        return (
                            <SpecialtyTab name={formatSpecialtyName(specialty)} key={specialty}>
                                {options}
                            </SpecialtyTab>
                        )
                    })}
                </ProceduresTabs>
                {other_option && (
                    <div className={`${moduleName}__other-option`}>
                        <Grid container={true}>
                            <Grid item={true} key={other_option.id} md={2} sm={3} xs={6}>
                                <div
                                    className={classNames(`${moduleName}__other-option-tile`, {
                                        [`${moduleName}--selected`]: other_option.selected,
                                    })}
                                >
                                    {other_option.display_name}
                                </div>
                            </Grid>
                            {other_option?.selected && other_option?.input_field && (
                                <Grid item={true}>
                                    <div className={`${moduleName}__other-option-value`}>
                                        {other_option.input_field.value ?? ''}
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                )}
            </Grid>
        )
    }

    return (
        <div className="survey-section survey-section--view survey-procedures-section">
            <div className="survey-section__wrapper">
                <Grid container={true} className={`${moduleName}__grid-row`} spacing={2}>
                    {renderQuestions()}
                </Grid>
            </div>
        </div>
    )
}

export default ProceduresView
