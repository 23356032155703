import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import iassign from 'immutable-assign'
import moment from 'moment'

import 'react-app-polyfill/stable'
import 'url-polyfill'
// adds namespaces to project TODO TSC research
import './Api/interfaces'
import './ApiV2/interfaces'
import './models'
import './services/interfaces'
iassign.freeze = false

import App from './App'
import { fetchFeatureFlags, fetchSelf, receiveToken } from './appActions'
import store from './appStore'
import { unregister } from './registerServiceWorker'
import SessionManager from './SessionManager'

Object.assign(window, { store })

if (SessionManager.isLoggedIn) {
    store.dispatch(fetchSelf())
    store.dispatch(fetchFeatureFlags())
}

if (SessionManager.session) {
    store.dispatch(receiveToken(SessionManager.session))
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT_NAME,
    release: process.env.REACT_APP_SHORT_SHA1,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: process.env.REACT_APP_ENVIRONMENT_NAME === 'prod' ? 0.25 : 1.0,
    enabled: process.env.REACT_APP_SENTRY_LOGGING === 'true',
    ignoreErrors: [
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'ResizeObserver loop limit exceeded',
    ],
})

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root') as HTMLElement,
)
unregister()

const refreshAt = moment()
    .add(1, 'day')
    .hours(6)
    .minutes(0)
    .seconds(0)
const refreshIn = refreshAt.toDate().getTime() - new Date().getTime()
setTimeout(() => {
    window.location.reload()
}, refreshIn)

console.info('Will reload at', refreshAt.toString(), `[${refreshIn}]`) // tslint:disable-line
