import * as React from 'react'
import { useHistory } from 'react-router-dom'
import Icon from '@mui/material/Icon'

import { LOCAL_STORAGE_KEY_CHATS_ON_SCREEN } from '../../Constants'
import { useAppDispatch } from '../../util/useAppDispatch'
import { setChatsOnScreen, turnChatsPage } from '../chat-center/actions'

import './NavChatColumnsController.sass'

const MAX_CHATS_ON_SCREEN = 3

export type ChatColumnsControllerProps = {
    numChatsOnScreen: number
    paginator: Models.ChatCenterPaginator
}

type Props = ChatColumnsControllerProps

const moduleName = 'nav-chat-columns-controller'

const NavChatColumnsController = (props: Props) => {
    const { numChatsOnScreen: columns, paginator } = props
    const dispatch = useAppDispatch()

    const columnNumbers = Array.apply(null, Array(MAX_CHATS_ON_SCREEN)).map((value: number, index: number) => index + 1)

    let history = useHistory()

    const handleSelectChatsOnScreen = (chatsOnScreen: number) => () => {
        dispatch(setChatsOnScreen(chatsOnScreen))
        window.localStorage.setItem(LOCAL_STORAGE_KEY_CHATS_ON_SCREEN, chatsOnScreen.toString())
        history.push('/chat-center')
    }

    const prevPage = () => {
        dispatch(turnChatsPage({ direction: 'prev', nextEnabled: true }))
    }

    const nextPage = () => {
        dispatch(turnChatsPage({ direction: 'next', prevEnabled: true }))
    }

    const isPrevDisabled = !paginator.prevEnabled || columns === 1
    const isNextDisabled = !paginator.nextEnabled || columns === 1

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__columns`}>
                {columnNumbers.map((chatsOnScreen: number) => (
                    <button
                        key={chatsOnScreen}
                        className={`${moduleName}__button`}
                        disabled={columns === chatsOnScreen}
                        onClick={handleSelectChatsOnScreen(chatsOnScreen)}
                    >
                        {chatsOnScreen}
                    </button>
                ))}
            </div>
            <div className={`${moduleName}__nav`}>
                <div className={`${moduleName}__left`}>
                    <button
                        disabled={isPrevDisabled}
                        className={`${moduleName}__nav-button ${moduleName}__nav-button--placeholder`}
                        onClick={prevPage}
                    >
                        <Icon>arrow_back_ios</Icon>
                    </button>
                </div>
                <div className={`${moduleName}__right`}>
                    <button
                        disabled={isNextDisabled}
                        className={`${moduleName}__nav-button ${moduleName}__nav-button--placeholder`}
                        onClick={nextPage}
                    >
                        <Icon>arrow_forward_ios</Icon>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NavChatColumnsController
