import * as React from 'react'

import SelfSchedulingButtonColors from './SelfSchedulingButtonColors'
import SchedulingButtonSize from './SelfSchedulingButtonSize'
import SchedulingButtonStyle from './SelfSchedulingButtonStyle'

import './SelfSchedulingButtonCustomizationOptions.sass'

type Props = {
    schedulingButton: Models.SelfSchedulingButton
    resetColors: (e: React.MouseEvent<HTMLElement>) => void
    updateCustomColor: (colorKey: Models.SelfSchedulingButtonColorTypes, colorValue: string) => void
    updateSize: (size: Models.SelfSchedulingButtonSize) => void
    updateStyle: (style: Models.SelfSchedulingButtonStyle) => void
}

const moduleName = 'self-scheduling-button-customization-options'

function SelfSchedulingButtonCustomizationOptions(props: Props) {
    const { schedulingButton, resetColors, updateCustomColor, updateSize, updateStyle } = props

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__left`}>
                <SelfSchedulingButtonColors
                    colors={schedulingButton?.colors}
                    resetColors={resetColors}
                    updateCustomColor={updateCustomColor}
                />
            </div>
            <div className={`${moduleName}__right`}>
                <SchedulingButtonStyle style={schedulingButton?.style} updateStyle={updateStyle} />
                <SchedulingButtonSize size={schedulingButton?.size} updateSize={updateSize} />
            </div>
        </div>
    )
}

export default SelfSchedulingButtonCustomizationOptions
