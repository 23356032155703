import * as React from 'react'

export default class WidgetPlacementSVGIcon extends React.PureComponent {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 50 30">
                <g fill="none" fillRule="evenodd">
                    <path fill="#F0EFED" stroke="#979797" d="M.5.5h49v29H.5z" />
                    <path fill="#448AFF" d="M36 16h12v12H36z" />
                </g>
            </svg>
        )
    }
}
