import {
    HeartbeatPracticeAgentModule,
    HeartbeatPracticeIntegrationsAgent,
    IntegrationsAgent,
    IntegrationsAgentsSearchTerms,
    IntegrationsSoftware,
} from '../../models/v2/Integrations'
import { PracticeLocationsV2 } from '../../models/v2/Practice'
import ApiService from '../service'

export default class IntegrationsService extends ApiService {
    async getAgent(agentId: number) {
        const { data: IntegrationAgent } = (await this.fetch(
            `integrations/agents/${agentId}`,
        )) as Api.StructuredResponse<IntegrationsAgent>

        return IntegrationAgent
    }

    async loadIntegrationSoftwares() {
        const { data: softwares } = (await this.fetch('integrations/softwares')) as ApiV2.StructuredResponse<
            IntegrationsSoftware[]
        >

        return softwares
    }

    async loadAgentsByPracticeId(practiceId: string) {
        const { data: heartbeatAgents } = (await this.fetch(
            `practices/${practiceId}/integrations-agents?include[]=agent_module`,
        )) as ApiV2.StructuredResponse<HeartbeatPracticeIntegrationsAgent[]>

        return heartbeatAgents
    }

    async postIntegrationsAgent(practiceId: string, name: string) {
        const { data } = (await this.fetch(`practices/${practiceId}/integrations-agents`, {
            method: 'POST',
            body: JSON.stringify({
                name,
            }),
        })) as ApiV2.StructuredResponse<HeartbeatPracticeIntegrationsAgent>
        return data
    }

    async postIntegrationsAgentModule(agentId: string, softwareId: number) {
        const { data } = (await this.fetch(`integrations-agents/${agentId}/agent-modules`, {
            method: 'POST',
            body: JSON.stringify({
                software_id: softwareId,
            }),
        })) as ApiV2.StructuredResponse<HeartbeatPracticeAgentModule>
        return data
    }

    async putHeartbeatAgentsUpdate(agentId: string, name: string) {
        const { data } = (await this.fetch(`integrations-agents/${agentId}`, {
            method: 'PUT',
            body: JSON.stringify({
                name,
            }),
        })) as ApiV2.StructuredResponse<HeartbeatPracticeIntegrationsAgent>
        return data
    }

    async loadPracticeLocations(practiceId: string, page: number) {
        const locations = (await this.fetch(
            `practices/${practiceId}/practice-locations?include[]=heartbeat_agent_module_location&page=${page}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<PracticeLocationsV2[]>

        return locations
    }

    async loadAgentsByPracticeIdPaginated(practiceId: string, searchTerms: IntegrationsAgentsSearchTerms) {
        const { query = '', limit = 8, page = 1 } = searchTerms
        const { data: agents, metadata } = (await this.fetch(
            `practices/${practiceId}/integrations-agents`,
            {},
            {
                search: query,
                limit,
                page,
            },
            undefined,
            undefined,
            ['agent_module'],
        )) as ApiV2.StructuredResponse<HeartbeatPracticeIntegrationsAgent[]>

        return { paginationInfo: metadata?.pagination_info, data: agents }
    }
}
