import * as React from 'react'
import Button from '@mui/material/Button'
import classNames from 'classnames'

import { setRedirect } from '../../appActions'

import './DexVoiceCommands.sass'

export type DexVoiceCommandsProps = {
    readonly practice: Models.Practice
    readonly commands: Array<Models.DexisAgentCommand>
}

export type DexVoiceCommandsDispatch = {
    readonly setRedirect: typeof setRedirect
}

export type DexVoiceCommandsState = {}

type Props = DexVoiceCommandsProps & DexVoiceCommandsDispatch

class DexVoiceCommands extends React.PureComponent<Props, DexVoiceCommandsState> {
    render() {
        const { practice, commands } = this.props

        return (
            <div className={classNames('dexvoice-commands')}>
                <div className="title">Commands</div>
                <div className="range">Last 7 days</div>
                <div className="count">{commands.length}</div>
                <div className="details">
                    <Button
                        className="link"
                        onClick={() => this.props.setRedirect(`/diagnostics/${practice.id}/dexvoice`)}
                    >
                        Details
                    </Button>
                </div>
            </div>
        )
    }
}

export default DexVoiceCommands
