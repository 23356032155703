import iassign from 'immutable-assign'

// import { FetchPaymentProcessingData } from './actions'

export type PaymentsState = {
    practiceFees: Models.DisplayPracticeFee[]
}

type PaymentActions = any

const initialState = (): PaymentsState => ({
    practiceFees: [],
})

export function reducer(state: PaymentsState = initialState(), action: PaymentActions): PaymentsState {
    switch (action.type) {
        case 'RECEIVE_PAYMENT_PROCESSOR_DATA': {
            return iassign(
                state,
                next => next.practiceFees[action.practice.id],
                nextPracticeFee => {
                    nextPracticeFee = action.processingData.displayPracticeFee
                    return nextPracticeFee
                },
            )
        }
        default:
            return state
    }
}
