import * as React from 'react'
import autosize from 'autosize'
import { oneLine } from 'common-tags'

import { CustomFieldType } from '../../../models/enums'
import CustomTooltip from '../custom-tooltip/CustomTooltip'

import './CustomField.sass'

type EventProps = {
    onClick?: (event: React.MouseEvent) => void
    onSelect?: (event: React.SyntheticEvent) => void
    onChange?: (event: React.ChangeEvent) => void
    onKeyDown?: (event: React.KeyboardEvent) => void
    onKeyUp?: (event: React.KeyboardEvent) => void
    onBlur?: (event: React.FocusEvent) => void
}
type FieldProps = {
    customFieldType: CustomFieldType
    value: string | number
    required?: boolean
    inputType?: string
    placeholder?: string
    label?: string
    helperText?: string
    rows?: number
    error?: boolean | string
    errorMessage?: string
    errorMessageInTooltip?: boolean
    showErrorText?: boolean
    disabled?: boolean
    leftAdornment?: string
    rightAdornment?: string
    autoFocus?: boolean
    autoSize?: boolean
    readonly?: boolean
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>
    autosizeOnUpdate?: boolean
    onClear?: () => void
}
type Props = EventProps & FieldProps

type State = {
    active: boolean
}

type ReferenceType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null

const LeftAdornment = (props: Pick<FieldProps, 'leftAdornment'>) => (
    <div className="custom-field__adornment custom-field__adornment--left">
        <i className="material-icons custom-field__adornment-icon">{props.leftAdornment}</i>
    </div>
)

const RightAdornment = (props: Pick<FieldProps, 'rightAdornment'>) => (
    <div className="custom-field__adornment custom-field__adornment--right">
        <i className="material-icons custom-field__adornment-icon">{props.rightAdornment}</i>
    </div>
)

export default class CustomField extends React.PureComponent<Props, State> {
    customField: ReferenceType
    uniqId: string

    constructor(props: Props) {
        super(props)

        this.state = {
            active: false,
        }

        const randString = Array.from({ length: 10 }, () =>
            String.fromCharCode(65 + Math.floor(Math.random() * 26)),
        ).join('')
        this.uniqId = randString + Date.now()
    }

    componentDidMount() {
        const { autoFocus } = this.props

        if (autoFocus && this.customField) {
            this.customField.focus()
        }

        if (this.isGrowingTextarea()) {
            autosize(document.querySelectorAll(`#${this.uniqId}`))
        }
    }

    componentDidUpdate() {
        const { value, autosizeOnUpdate } = this.props

        if (this.isGrowingTextarea() && value === '') {
            autosize.update(document.querySelectorAll(`#${this.uniqId}`))
        }

        if (autosizeOnUpdate && this.isGrowingTextarea()) {
            autosize.update(document.querySelectorAll(`#${this.uniqId}`))
        }
    }

    setElementReference = (ref: ReferenceType) => {
        this.customField = ref
    }

    isGrowingTextarea = () => {
        const { autoSize, customFieldType } = this.props
        return autoSize && customFieldType === CustomFieldType.TEXTAREA
    }

    onFocus = () => {
        this.setState({
            active: true,
        })
    }

    onBlur = (event: React.FocusEvent) => {
        this.setState({
            active: false,
        })
        if (this.props.onBlur !== undefined) {
            this.props.onBlur(event)
        }
    }

    renderCustomField = () => {
        const { label, helperText, error, errorMessage, showErrorText, disabled, value } = this.props
        const { active } = this.state

        return (
            <div
                className={oneLine`
                    custom-field
                    ${error ? 'custom-field--error' : ''}
                    ${disabled ? 'custom-field--disabled' : ''}
                    ${active ? 'custom-field--active' : ''}
                `}
            >
                {label && (
                    <label htmlFor={this.uniqId} className="custom-field__label">
                        {label}
                    </label>
                )}
                {this.renderCorrectCustomField(this.uniqId)}
                {this.props.onClear && value && (
                    <i className="material-icons clear-search" onClick={this.props.onClear}>
                        close
                    </i>
                )}
                {error && errorMessage && showErrorText ? (
                    <span className="custom-field__helper-text">{errorMessage}</span>
                ) : (
                    helperText && <span className="custom-field__helper-text">{helperText}</span>
                )}
            </div>
        )
    }

    renderTooltip = () => {
        const { error, errorMessage, errorMessageInTooltip } = this.props

        if (error && errorMessage && errorMessageInTooltip) {
            return (
                <CustomTooltip text={errorMessage} position="top-right">
                    <i className="material-icons custom__input-error-icon">error</i>
                </CustomTooltip>
            )
        }

        if (error) {
            return <i className="material-icons custom__input-error-icon">error</i>
        }
        return ''
    }

    renderCorrectCustomField = (uniqId: string) => {
        const {
            customFieldType,
            required,
            inputType,
            value,
            placeholder,
            rows,
            disabled,
            children,
            onChange,
            onKeyDown,
            onKeyUp,
            onClick,
            readonly,
            inputProps,
        } = this.props
        const commonProps = {
            id: uniqId,
            disabled,
            value,
            placeholder,
            required,
            onClick: (event: React.MouseEvent) => onClick && onClick(event),
            onChange: (event: React.ChangeEvent) => onChange && onChange(event),
            onKeyDown: (event: React.KeyboardEvent) => onKeyDown && onKeyDown(event),
            onKeyUp: (event: React.KeyboardEvent) => onKeyUp && onKeyUp(event),
        }
        const onSelect = (event: React.SyntheticEvent) => this.props.onSelect && this.props.onSelect(event)

        switch (customFieldType) {
            case CustomFieldType.INPUT:
                return (
                    <React.Fragment>
                        <input
                            {...commonProps}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            ref={this.setElementReference}
                            type={inputType}
                            className="custom__input"
                            {...inputProps}
                        />
                        {this.renderTooltip()}
                    </React.Fragment>
                )
            case CustomFieldType.TEXTAREA:
                return (
                    <React.Fragment>
                        <textarea
                            {...commonProps}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            ref={this.setElementReference}
                            className="custom__textarea"
                            readOnly={readonly}
                            rows={rows}
                        />
                        {this.renderTooltip()}
                    </React.Fragment>
                )
            case CustomFieldType.SELECT:
                return (
                    <React.Fragment>
                        <select
                            {...commonProps}
                            className="custom__select"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSelect={onSelect}
                            ref={this.setElementReference}
                        >
                            {children}
                        </select>
                        <i className="material-icons custom__dropdown-arrow">arrow_drop_down</i>
                    </React.Fragment>
                )
            default:
                return null
        }
    }

    render() {
        const { leftAdornment, rightAdornment } = this.props

        return (
            <div
                className={oneLine`
                    custom-field__container
                    ${leftAdornment ? 'with-adornment--left' : ''}
                    ${rightAdornment ? 'with-adornment--right' : ''}
                `}
            >
                {leftAdornment && <LeftAdornment leftAdornment={leftAdornment} />}
                {this.renderCustomField()}

                {rightAdornment && <RightAdornment rightAdornment={rightAdornment} />}
            </div>
        )
    }
}
