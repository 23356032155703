import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Icon } from '@mui/material'
import CustomToggle from 'modules/shared/custom-fields/CustomToggle'
import FileDrop from 'modules/uploads/FileDrop'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { imageData } from '../../../util/imageValidation'
import CustomTooltip from '../../shared/custom-tooltip/CustomTooltip'
import { getLocationLogo } from '../actions'
import { mapBgHexToColors } from '../logo/PracticeLogoModal'

import './LocationsLogo.sass'

type BgColors = 'dark' | 'neutral' | 'light'

interface Props {
    practiceId: string
    locationId: string
    logo: {
        bgColor: string | null
        data: any | null
        shouldDelete: boolean
    }
    updateLogoData: (
        updatedLogo: Partial<{
            bgColor: BgColors | null
            data: any | null
            shouldDelete: boolean
        }>,
    ) => void
}

const MAX_LOGO_SIZE = 200 * 1024
const MAX_LOGO_WIDTH = 500
const MAX_LOGO_HEIGHT = 200
// const PROGRESS_INTERVAL = 60

type AppDispatch = ThunkDispatch<any, any, AnyAction>

// loads colors and image here. When changes are made they are propagated to parent
// Saving occurs in parent. Split like this because saving has to occur in parent,
// but too much in there to handle loading of defaults of this component as well
export default function LocationsLogo(props: Props) {
    const dispatch: AppDispatch = useDispatch()
    const [selected, setSelected] = useState<BgColors | null>(null)
    const [logoUrl, setLogoUrl] = useState<string | null>(null)
    const [logoError, setLogoError] = useState('')
    // const [image]
    // const [loadedSelected, setLoadedSelected] = useState<BgColors | null>(null)

    useEffect(() => {
        const loadLogoAndBgcolors = async () => {
            if (!props.locationId) return
            const result = await dispatch(getLocationLogo(props.practiceId, props.locationId))
            setLogoUrl(result.data ? result.data.url : null)
            setSelected(result.data ? mapBgHexToColors[String(result.data.backgroundColorCode)] : null)
            // GET localhost:7004/api/v1/practiceLocation/practiceId/:practiceId/locationId/:locationId/logo
            // PUT localhost:7004/api/v1/practiceLocation/practiceId/:practiceId/locationId/:locationId/logo
            // {
            //     "fileUploadId": ""
            // }
            // GET api/v1/practices/:practiceId/practiceLogoBackgroundColorCode
            // PUT api/v1/practices/:practiceId/practiceLogoBackgroundColorCode
            // {
            //   logoBackgroundColorCode: string
            // }
        }
        loadLogoAndBgcolors()
    }, [dispatch, props.practiceId, props.locationId])

    const onLogoUpload = async (file: File) => {
        setLogoError('')
        if (!file || file.size > MAX_LOGO_SIZE) {
            setLogoError(`Image exceeds max logo size of ${MAX_LOGO_SIZE / 1000}kb.`)
            return
        }

        const image = await imageData(file)

        const isImageValid = Boolean(image) && image.width <= MAX_LOGO_WIDTH && image.height <= MAX_LOGO_HEIGHT

        if (!isImageValid) {
            setLogoError(
                `Image must have a width less than or equal to ${MAX_LOGO_WIDTH}px and height less than or equal to ${MAX_LOGO_HEIGHT}px.`,
            )
            return
        }

        props.updateLogoData({ data: file })
    }

    const handleSetBgColor = (bgColor: BgColors | null) => {
        setSelected(bgColor)
        props.updateLogoData({ bgColor })
    }

    const handleDelete = () => {
        // if new logo has been added, reset
        if (props.logo.data !== null) {
            props.updateLogoData({ data: null })
        } else if (logoUrl) {
            // otherwise delete the existing logo (if there is one)
            handleSetBgColor(null)
            setLogoUrl(null)
            props.updateLogoData({
                shouldDelete: true,
            })
        }
    }

    return (
        <div>
            <div className="location-logo-heading">Logo</div>
            <div className="location-logo">
                <div className="location-logo-wrapper">
                    <label>Background Color</label>
                    <CustomToggle
                        options={[
                            {
                                text: 'Dark',
                                onClick: () => {
                                    handleSetBgColor('dark')
                                },
                                selected: selected === 'dark',
                            },
                            {
                                text: 'Neutral',
                                onClick: () => {
                                    handleSetBgColor('neutral')
                                },
                                selected: selected === 'neutral',
                            },
                            {
                                text: 'Light',
                                onClick: () => {
                                    handleSetBgColor('light')
                                },
                                selected: selected === 'light',
                            },
                        ]}
                    />
                    <div className="location-logo-display">
                        <div className={`location-logo-bg-top location-logo-bg-top--${selected}`}>
                            {(props.logo.data?.preview || logoUrl) && (
                                <img height="200" src={props.logo.data?.preview || logoUrl} alt="Practice Logo" />
                            )}
                            {!(props.logo.data?.preview || logoUrl) && <h2>LOGO</h2>}
                        </div>
                    </div>
                </div>
                <div className="location-logo-info-wrapper">
                    <span className="location-logo-info-text">
                        {logoUrl
                            ? `This logo will appear on location's patient’s payment portal and all email
                                    communications sent via Vyne Trellis.`
                            : `Upload a logo to appear on location's patient's payment portal and all email
                                    communications sent via Vyne Trellis.`}
                        <p className="location-logo-tooltip">
                            <CustomTooltip
                                position="top-left"
                                text={`Please upload .jpg, .jpeg or .png files, and no larger than 500x200px and ${MAX_LOGO_SIZE /
                                    1024}kb.`}
                            >
                                <Icon style={{ left: '2px', position: 'absolute', top: '-2px', fontSize: '21px' }}>
                                    info
                                </Icon>
                            </CustomTooltip>
                        </p>
                    </span>

                    {logoError && <div className="logo-error">{logoError}</div>}
                    <div className="location-logo-buttons">
                        <button>
                            <FileDrop
                                onFileDrop={(file: File) => onLogoUpload(file)}
                                disableClick={false}
                                accept="image/jpeg, image/png, image/jpg"
                                // ref={(ref: any) => (dropzoneRef = ref)}
                                className={`practice-logo__filedrop
                                ${logoUrl && 'practice-logo__filedrop--image'}`}
                                hover={null}
                            >
                                <div className="practice-logo__placeholder">
                                    <>
                                        <Icon>image</Icon> <span>Upload</span>
                                    </>
                                </div>
                            </FileDrop>
                        </button>
                        {logoUrl && (
                            <button onClick={handleDelete}>
                                <Icon>delete</Icon>
                                <span> Delete</span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
