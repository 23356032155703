import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'

import { RootState } from '../../../../../../appReducer'
import CustomMultiselectField from '../../../../../shared/custom-fields/CustomMultiselectField'

export type Props = {
    onSelectElement: (values: string[]) => void
    practiceId: string
    selectedItems: string[]
    excludeIds?: string[]
}

const LocationMultiselect = ({ onSelectElement, practiceId, selectedItems = [], excludeIds = [] }: Props) => {
    const { practiceLocations }: { practiceLocations: Models.PracticeLocation[] } = useSelector((state: RootState) => ({
        practiceLocations: state.practices.practices[practiceId].locations,
    }))

    const locations = useMemo(
        () =>
            orderBy(
                practiceLocations.filter(location => !excludeIds.includes(location.id)),
                'name',
                'asc',
            ),
        [practiceLocations, excludeIds],
    )

    return (
        <CustomMultiselectField
            items={locations}
            selectAllLabel={'ALL LOCATIONS'}
            selectedItems={selectedItems}
            keyProperty={'id'}
            search={true}
            displayProperty={'name'}
            placeholder={'Apply this to other locations...'}
            onSelectElement={onSelectElement}
            searchPlaceholder="Search for Location"
        ></CustomMultiselectField>
    )
}

export default LocationMultiselect
