import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    Button,
    CircularProgress,
    Modal,
    Snackbar,
    SnackbarContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material'
import { RootState } from 'appReducer'
import _ from 'lodash'
import moment from 'moment'

import Api from '../../Api'
import { convertDoubleStruckToRegular } from '../../modules/shared/unicodeCharsUtils'
import { useAppDispatch } from '../../util/useAppDispatch'
import { fetchWebCodes } from '../amplify/actions'
import { fetchArchivedChats } from '../practices/v2actions'
import CopyText from '../shared/CopyText'
import CustomMultiselectField from '../shared/custom-fields/CustomMultiselectField'
import { SearchBar } from '../shared/custom-fields/SearchBar'
import InfoMessage from '../shared/info-message/info-message'
import Paginator from '../shared/Paginator'
import { ArrowDropDown } from '../shared/svgIcons'

import ChatTranscript from './ChatTranscript'
import { PracticeDiagnosticsParams } from './PracticeDiagnostics'

import './ChatCenterArchive.sass'

const pickerDaysRanges = [
    { id: 'all', name: 'All Time' },
    { id: '0', name: 'Today' },
    { id: '7', name: 'Last 7 Days' },
    { id: '30', name: 'Last 30 Days' },
    { id: '90', name: 'Last 90 Days' },
]

const ChatCenterArchive = (props: PracticeDiagnosticsParams) => {
    const practiceId = props.match.params.practiceId

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const practice = useSelector((state: RootState) => state.practices.practices[practiceId])
    const chats = useSelector((state: RootState) => state.v2.practices.archivedChats)
    const webCodes = useSelector((state: RootState) => state.amplify.webCodes[practiceId])
    const paginationInfo = useSelector((state: RootState) => state.v2.practices.archivedChatsPaginationInfo)

    const dispatch = useAppDispatch()

    const throttledFetchArchivedChats = useCallback(
        _.throttle((practice: Models.Practice, searchTerms: ApiV2.Practice.ChatArchiveSearchTerms) => {
            dispatch(fetchArchivedChats(practice.id, searchTerms))
        }, 750),
        [],
    )

    const [showSnackbar, setShowSnackbar] = useState(false)
    const [selectedChatId, setSelectedChatId] = useState<string | undefined>()
    const [transcript, setTranscript] = useState<string | undefined>()
    const [days, setDays] = useState<number | 'all'>('all')
    const [searchTerms, setSearchTerms] = useState<ApiV2.Practice.ChatArchiveSearchTerms>({
        page: 1,
        limit: 25,
        sort: 'created',
        order: 'desc',
        search: '',
    })

    const selectedChatMetadata = chats ? chats.find(chat => chat.id === selectedChatId) : undefined

    useEffect(() => {
        throttledFetchArchivedChats(practice, searchTerms)
    }, [practice, searchTerms, throttledFetchArchivedChats])

    useEffect(() => {
        dispatch(fetchWebCodes(practice.id))
    }, [dispatch, practice])

    const onCreatedPendingReferral = () => {
        setShowSnackbar(true)
    }

    const onCloseSnackbar = () => {
        setShowSnackbar(false)
    }

    const onSelectChat = async (chatId: string) => {
        const transcriptData = await Api.Amplify.loadArchivedChatTranscript(practice, chatId)
        setSelectedChatId(chatId)
        setTranscript(transcriptData.transcript)
    }

    const getSortingDirectionOfProperty = (sortByProperty: ApiV2.Practice.ChatArchiveSort) => {
        if (sortByProperty === searchTerms.sort) {
            return searchTerms.order === 'desc' ? 'asc' : 'desc'
        }

        return 'desc'
    }
    const sortByProperty = (sort: ApiV2.Practice.ChatArchiveSort) => {
        const order = getSortingDirectionOfProperty(sort)
        setSearchTermsState({ sort, order })
    }
    const selectDays = (values: string[]) => {
        const value = values[0]
        const days = value === 'all' ? value : parseInt(value, 10)
        setDays(days)
        setSearchTermsState({
            start_date: (value !== 'all'
                ? moment()
                      .startOf('day')
                      .subtract(value, 'days')
                      .toISOString()
                : undefined) as string,
        })
    }

    const selectWebCodeUrl = (values: string[]) => {
        const value = values[0]
        const web_code_url = value !== 'all' ? value : undefined
        setSearchTermsState({ web_code_url })
    }

    const selectPage = (page: number) => {
        setSearchTermsState({ page })
    }

    const searchByKeyword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target && e.target.value
        setSearchTermsState({ search, page: 1 })
    }

    const clearSearch = () => {
        const search = ''
        setSearchTermsState({ search, page: 1 })
    }

    const setSearchTermsState = (updatedSearchTerms: Partial<ApiV2.Practice.ChatArchiveSearchTerms>) => {
        const newSearchTerms = {
            ...searchTerms,
            ...updatedSearchTerms,
        }
        setSearchTerms(newSearchTerms)
    }
    const onCloseChatTranscript = () => {
        setSelectedChatId(undefined)
        setTranscript(undefined)
    }

    if (!account || !webCodes || chats === undefined) {
        return (
            <div className="circular-progress-loader">
                <CircularProgress size={70} color="primary" variant="indeterminate" />
            </div>
        )
    }

    const sortedWebCodes = webCodes
        .map(wc => wc.url)
        .sort()
        .map(swc => ({ id: swc, url: swc }))
    const pickerWebCodes = [{ id: 'all', url: 'All Websites' }, ...sortedWebCodes]

    return (
        <div className="chat-center-archive">
            <div className="filter-select">
                <div className="chat-center-archive-select-website">
                    <CustomMultiselectField
                        items={pickerWebCodes}
                        maxSelected={1}
                        selectedItems={[
                            searchTerms.web_code_url ? decodeURIComponent(searchTerms.web_code_url as string) : 'all',
                        ]}
                        keyProperty="id"
                        displayProperty="url"
                        placeholder="Select website"
                        onSelectElement={selectWebCodeUrl}
                        search={sortedWebCodes?.length > 8}
                        searchPlaceholder="Search Website"
                    />
                </div>
                <div className="chat-center-archive-select-days">
                    <CustomMultiselectField
                        items={pickerDaysRanges}
                        maxSelected={1}
                        selectedItems={[days.toString()]}
                        keyProperty="id"
                        displayProperty="name"
                        placeholder="Select time"
                        onSelectElement={selectDays}
                    />
                </div>
                <div className="chat-center-archive-search-box">
                    <SearchBar
                        value={decodeURIComponent(searchTerms.search as string)}
                        onChange={searchByKeyword}
                        onClear={clearSearch}
                        placeholder="Search by Patient Name or Chat ID"
                    />
                </div>
            </div>
            <div className="statistics-subheader">
                <div className="statistic referral-count">
                    <div className="label">Chats</div>
                    <div className="count">{paginationInfo && paginationInfo.allRows}</div>
                </div>
            </div>
            {chats?.length === 0 ? (
                <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>
            ) : (
                <Table className="archive-table">
                    <TableHead>
                        <TableRow className="table-row">
                            <TableCell className="table-head-cell id-cell">ID</TableCell>
                            <TableCell className="table-head-cell">
                                <TableSortLabel
                                    active={searchTerms.sort === 'patient_name'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('patient_name')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Patient Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-head-cell">
                                <TableSortLabel
                                    active={searchTerms.sort === 'ip'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('ip')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Patient’s IP Address
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-head-cell">
                                <TableSortLabel
                                    active={searchTerms.sort === 'created'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('created')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Time
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-head-cell">
                                <TableSortLabel
                                    active={searchTerms.sort === 'account.last_name'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('account.last_name')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Chatter
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-head-cell">
                                <TableSortLabel
                                    active={searchTerms.sort === 'amplify_web_code.url'}
                                    direction={searchTerms.order}
                                    onClick={() => {
                                        sortByProperty('amplify_web_code.url')
                                    }}
                                    IconComponent={ArrowDropDown}
                                >
                                    Website
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className="table-head-cell" />
                        </TableRow>
                    </TableHead>
                    <TableBody className="archived-amplify-chat-list">
                        {chats?.map((chat: ModelsV2.Practice.ChatMetadata) => (
                            <TableRow className="archived-amplify-chat" key={chat.id}>
                                <TableCell className="chat-id">
                                    <CopyText text={`${chat.id} (${chat.channel_name})`}>ID</CopyText>
                                </TableCell>
                                <TableCell>
                                    {
                                        <span className="patient-name">
                                            {(chat.patient_name && convertDoubleStruckToRegular(chat.patient_name)) ||
                                                chat.ip}
                                        </span>
                                    }
                                </TableCell>
                                <TableCell>{chat.ip}</TableCell>
                                <TableCell>{moment(chat.created).format('M/D/YYYY h:mm a')}</TableCell>
                                <TableCell>
                                    {chat.account && chat.account.first_name} {chat.account && chat.account.last_name}
                                </TableCell>
                                <TableCell>{chat.amplify_web_code.url}</TableCell>
                                <TableCell>
                                    <Button className="select-chat-transcript" onClick={() => onSelectChat(chat.id)}>
                                        Chat Transcript
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            {selectedChatMetadata && (
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseChatTranscript()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents chat-center-archive-modal">
                        <div className="close-modal-button" onClick={onCloseChatTranscript}>
                            <i className="material-icons">close</i>
                        </div>
                        <ChatTranscript
                            practice={practice}
                            selectedChatMetadata={selectedChatMetadata}
                            onCreate={onCreatedPendingReferral}
                            transcript={transcript || ''}
                        />
                    </div>
                </Modal>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showSnackbar}
                autoHideDuration={2500}
                onClose={onCloseSnackbar}
            >
                <SnackbarContent className="snackbar" message={<span>Pending Referral created!</span>} />
            </Snackbar>
            <div className="paginator-wrapper">
                <Paginator
                    currentPage={searchTerms.page as number}
                    paginationInfo={paginationInfo}
                    selectPage={selectPage}
                />
            </div>
        </div>
    )
}

export default ChatCenterArchive
