import iassign from 'immutable-assign'

import {
    PatientFinancialDataRequest,
    PatientFinancialDataSelectPatient,
    PatientFinancialDataSuccess,
    PracticePatientsActionTypes,
    PracticePatientsFinancialDataRequest,
    PracticePatientsFinancialDataSuccess,
    ResetSearchPracticePatients,
    SearchPracticePatientsRequest,
    SearchPracticePatientsSuccess,
} from './v2actions'

export type PracticePatientsAction =
    | PatientFinancialDataRequest
    | PatientFinancialDataSuccess
    | PracticePatientsFinancialDataRequest
    | PracticePatientsFinancialDataSuccess
    | SearchPracticePatientsRequest
    | SearchPracticePatientsSuccess
    | PatientFinancialDataSelectPatient
    | ResetSearchPracticePatients

export type PatientFinancialDataState = {
    selectedPatient: any
    data: ModelsV2.Referrals.PatientsFinancialData | null
    loading: boolean
}

export type PracticePatientsFinancialDataState = {
    data: ModelsV2.Practice.PracticePatientsFinancialData | null
    loading: boolean
}

export type PatientsDataState = {
    query: string
    page: number
    sort?: ModelsV2.Practice.PracticePatientSort
    order?: 'asc' | 'desc'
    loading: boolean
    list: ModelsV2.Practice.PracticePatientData[]
    pagination: Models.PaginationInfo | null
}

export type PracticePatientsState = {
    patientFinancialData: { [key: string]: PatientFinancialDataState }
    practicePatientsFinancialData: { [key: string]: PracticePatientsFinancialDataState }
    patients: { [key: string]: PatientsDataState }
}

export const initialState = (): PracticePatientsState => ({
    patientFinancialData: {},
    practicePatientsFinancialData: {},
    patients: {},
})

export const initialPatientFinancialDataState = (): PatientFinancialDataState => ({
    data: null,
    loading: true,
    selectedPatient: null,
})

export const initialPracticePatientsFinancialDataState = (): PracticePatientsFinancialDataState => ({
    data: null,
    loading: true,
})

export const initialPatientsDataState = (): PatientsDataState => ({
    page: 1,
    query: '',
    loading: false,
    list: [],
    pagination: null,
    sort: 'last_visit',
    order: 'desc',
})

export function reducer(
    state: PracticePatientsState = initialState(),
    action: PracticePatientsAction,
): PracticePatientsState {
    switch (action.type) {
        case PracticePatientsActionTypes.GET_PATIENT_FINANCIAL_DATA_REQUEST: {
            return iassign(
                state,
                next => next.patientFinancialData,
                nextPatientData => {
                    if (!nextPatientData[action.practiceId]) {
                        nextPatientData[action.practiceId] = initialPatientFinancialDataState()
                    }
                    nextPatientData[action.practiceId].data = null
                    nextPatientData[action.practiceId].loading = true
                    return nextPatientData
                },
            )
        }
        case PracticePatientsActionTypes.GET_PATIENT_FINANCIAL_DATA_SUCCESS: {
            return iassign(
                state,
                next => next.patientFinancialData,
                nextPatientData => {
                    if (!nextPatientData[action.practiceId]) {
                        nextPatientData[action.practiceId] = initialPatientFinancialDataState()
                    }
                    nextPatientData[action.practiceId].data = action.patientFinancialData
                    nextPatientData[action.practiceId].loading = false
                    return nextPatientData
                },
            )
        }
        case PracticePatientsActionTypes.GET_PRACTICE_PATIENTS_FINANCIAL_DATA_REQUEST: {
            return iassign(
                state,
                next => next.practicePatientsFinancialData,
                nextPracticePatientsFinancialData => {
                    if (!nextPracticePatientsFinancialData[action.practiceId]) {
                        nextPracticePatientsFinancialData[
                            action.practiceId
                        ] = initialPracticePatientsFinancialDataState()
                    }
                    nextPracticePatientsFinancialData[action.practiceId].data = null
                    nextPracticePatientsFinancialData[action.practiceId].loading = true
                    return nextPracticePatientsFinancialData
                },
            )
        }
        case PracticePatientsActionTypes.GET_PRACTICE_PATIENTS_FINANCIAL_DATA_SUCCESS: {
            return iassign(
                state,
                next => next.practicePatientsFinancialData,
                nextPracticePatientsFinancialData => {
                    if (!nextPracticePatientsFinancialData[action.practiceId]) {
                        nextPracticePatientsFinancialData[
                            action.practiceId
                        ] = initialPracticePatientsFinancialDataState()
                    }
                    nextPracticePatientsFinancialData[action.practiceId].data = action.practiceProductionValue
                    nextPracticePatientsFinancialData[action.practiceId].loading = false
                    return nextPracticePatientsFinancialData
                },
            )
        }
        case PracticePatientsActionTypes.PATIENT_FINANCIAL_DATA_SELECT_PATIENT: {
            return iassign(
                state,
                next => next.patientFinancialData,
                nextPatientData => {
                    if (!nextPatientData[action.practiceId]) {
                        nextPatientData[action.practiceId] = initialPatientFinancialDataState()
                    }
                    nextPatientData[action.practiceId].selectedPatient = action.patient
                    nextPatientData[action.practiceId].data = null
                    nextPatientData[action.practiceId].loading = true
                    return nextPatientData
                },
            )
        }
        case PracticePatientsActionTypes.SEARCH_PRACTICE_PATIENTS_REQUEST: {
            return iassign(
                state,
                next => next.patients,
                nextPatients => {
                    if (!nextPatients[action.practiceId]) {
                        nextPatients[action.practiceId] = initialPatientsDataState()
                    }

                    nextPatients[action.practiceId].query = action.searchTerms.query
                    nextPatients[action.practiceId].page = action.searchTerms.page || 1
                    nextPatients[action.practiceId].sort = action.searchTerms.sort
                    nextPatients[action.practiceId].order = action.searchTerms.order
                    nextPatients[action.practiceId].loading = true
                    return nextPatients
                },
            )
        }
        case PracticePatientsActionTypes.SEARCH_PRACTICE_PATIENTS_SUCCESS: {
            return iassign(
                state,
                next => next.patients,
                nextPatients => {
                    if (!nextPatients[action.practiceId]) {
                        nextPatients[action.practiceId] = initialPatientsDataState()
                    }
                    nextPatients[action.practiceId].list = action.patients
                    nextPatients[action.practiceId].loading = false
                    nextPatients[action.practiceId].pagination = action.pagination || { allPages: 1, allRows: 1 }
                    return nextPatients
                },
            )
        }
        case PracticePatientsActionTypes.RESET_SEARCH_PRACTICE_PATIENTS: {
            const state1 = iassign(
                state,
                next => next.patients,
                nextPatients => {
                    if (nextPatients[action.practiceId]) {
                        nextPatients[action.practiceId] = initialPatientsDataState()
                    }

                    return nextPatients
                },
            )

            const state2 = iassign(
                state1,
                next => next.patientFinancialData,
                nextPatients => {
                    if (nextPatients[action.practiceId]) {
                        nextPatients[action.practiceId] = initialPatientFinancialDataState()
                    }

                    return nextPatients
                },
            )

            return iassign(
                state2,
                next => next.practicePatientsFinancialData,
                nextPracticePatientsFinancialData => {
                    if (nextPracticePatientsFinancialData[action.practiceId]) {
                        nextPracticePatientsFinancialData[
                            action.practiceId
                        ] = initialPracticePatientsFinancialDataState()
                    }

                    return nextPracticePatientsFinancialData
                },
            )
        }
        default:
            return state
    }
}
