import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import copy from 'copy-to-clipboard'

import './CopyText.sass'

export type CopyTextProps = {
    text: string
    id?: string
    style?: React.CSSProperties
    tooltip?: boolean | undefined
    tooltipText?: string
}

export type CopyTextState = {
    showCopied: boolean
}

class CopyText extends React.Component<CopyTextProps, CopyTextState> {
    constructor(props: CopyTextProps) {
        super(props)
        this.state = { showCopied: false }
    }

    get text() {
        if (this.props.id) {
            return `${this.props.text} (\`${this.props.id}\`)`
        }

        return this.props.text
    }

    onCopy = () => {
        copy(this.text)
        this.setState({ showCopied: true })
        setTimeout(() => this.setState({ showCopied: false }), 3000)
    }

    get copySpan() {
        return (
            <span className="copy-text-inner-span" onClick={this.onCopy}>
                {this.props.children}
                <i className="material-icons custom-icon">content_copy</i>
            </span>
        )
    }

    render() {
        if (this.props.tooltip === false) {
            return this.copySpan
        }

        const tooltipText = this.props.tooltipText || this.props.text

        return (
            <Tooltip
                style={this.props.style}
                className="copy-text"
                title={this.state.showCopied ? 'Copied!' : tooltipText}
                placement="bottom"
            >
                {this.copySpan}
            </Tooltip>
        )
    }
}

export default CopyText
