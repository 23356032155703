import _ from 'lodash'

import { ProductTypes } from '../../../../models/enums'

import { LocationDataModel, LocationFormDataModel } from './hooks/useLocationForm'
import { paymentInformationOptions } from './LocationPaymentInformationForm'

const FACILITY_ID_REGEX = /^[A-Z]{2}\d{6}$/

export const validateTimezone = (
    timezone: LocationFormDataModel['timezone'],
    timezoneListForCountry: LocationFormDataModel['timezoneListForCountry'],
    isEdit: boolean | undefined = false,
) => {
    if (!timezone.value) return false

    if (isEdit && !timezone.isTouched) return true

    return Boolean(
        timezone.isValid &&
            timezone.value &&
            timezoneListForCountry.value &&
            timezoneListForCountry.value.includes(timezone.value),
    )
}

export const validateLatLng = (
    lat: LocationFormDataModel['lat'],
    lng: LocationFormDataModel['lng'],
    isEdit: boolean | undefined = false,
) => {
    if (isEdit && !lat.isTouched && !lng.isTouched) return true

    return Boolean(lat.value && lng.value && lat.isValid && lng.isValid)
}

export const validateFacilityId = (facilityId: string | undefined, hasEditFacilityIdPermission: boolean) => {
    const facilityIdValue = facilityId || ''
    if (!hasEditFacilityIdPermission) return true

    if (facilityIdValue.length > 0) {
        return FACILITY_ID_REGEX.test(facilityIdValue)
    }
    return true
}

export const validateAddress = (
    name: LocationFormDataModel['name'],
    street: LocationFormDataModel['street'],
    city: LocationFormDataModel['city'],
    state: LocationFormDataModel['state'],
    countryCodeIsoAlpha2: LocationFormDataModel['countryCodeIsoAlpha2'],
) => {
    const isUSCountry = countryCodeIsoAlpha2?.value === 'US'
    return _.every([
        !_.isEmpty(name.value),
        !_.isEmpty(street.value),
        !_.isEmpty(city.value),
        !(isUSCountry ? _.isEmpty(state.value) : false),
    ])
}

export const validatePayment = (
    hasPayments: boolean,
    hasStripeAccount: boolean,
    paymentInformationStatus: LocationFormDataModel['paymentInformationStatus'],
    paymentAmount: LocationFormDataModel['paymentAmount'],
) => {
    if (hasPayments && hasStripeAccount && paymentInformationStatus?.value) {
        const never = paymentInformationOptions.find(opt => opt.name === 'Never')
        return (
            (paymentInformationStatus?.value === never?.value && !Boolean(paymentAmount?.value)) ||
            Number(paymentAmount?.value || 0) >= 1
        )
    }
    return true
}

export const validateForm = (
    formValues: LocationFormDataModel,
    hasPayments: boolean,
    hasStripeAccount: boolean,
    isEdit: boolean | undefined = false,
    hasEditFacilityIdPermission: boolean | undefined = false,
    isLoading: boolean | undefined = false,
): boolean => {
    const isInvalid = Object.values(formValues).some(field => !field?.isValid)

    if (isInvalid || isLoading) return false

    if (!validateLatLng(formValues.lat, formValues.lng, isEdit)) return false

    if (formValues.zip.value && !formValues.zip.isValid) return false

    if (formValues.practiceSpecialtyType?.value && !formValues.practiceSpecialtyType?.isValid) return false

    if (
        !validateAddress(
            formValues.name,
            formValues.street,
            formValues.city,
            formValues.state,
            formValues.countryCodeIsoAlpha2,
        )
    ) {
        return false
    }

    if (!formValues.phone.value || !formValues.phone.isValid) return false

    if (!validateFacilityId(formValues.facilityId?.value, hasEditFacilityIdPermission)) return false

    if (!validateTimezone(formValues.timezone, formValues.timezoneListForCountry, isEdit)) return false

    if (
        !validatePayment(hasPayments, hasStripeAccount, formValues.paymentInformationStatus, formValues.paymentAmount)
    ) {
        return false
    }

    return true
}

export const createUpdatesRecord = (data: Partial<LocationDataModel>): Api.UpdateLocation => ({
    name: data.name,
    timezone: data.timezone,
    address: {
        street: data.street,
        unit: data.unit,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country_code_iso_alpha_2: data.countryCodeIsoAlpha2,
        lat: parseFloat(data.lat ?? ''),
        lng: parseFloat(data.lng ?? ''),
    },
    phone_number: data.phone,
    jarvis_enabled: data.jarvisEnabled,
    practice_specialty_type: data.practiceSpecialtyType,
    facility_id: data.facilityId,
})

export const createAddRecord = (data: Partial<LocationDataModel>): ApiV2.Practice.CreatePracticeLocation => ({
    name: data.name,
    address: {
        street: data.street,
        unit: data.unit,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country_code_iso_alpha_2: data.countryCodeIsoAlpha2,
        lat: parseFloat(data.lat ?? ''),
        lng: parseFloat(data.lng ?? ''),
    },
    phone_number: data.phone,
    timezone: data.timezone,
    practice_specialty_type: { value: data.practiceSpecialtyType || 'dental' },
    description: data.description || '',
    facility_id: data.facilityId,
    products:
        data.products?.map(product => {
            const productId = ProductTypes.find(p => p.value === product)?.id || 0
            return {
                product_id: productId,
                active: true,
            }
        }) || [],
})

export const generateSchedulingData = (data: Partial<LocationDataModel>, hasDirectSchedulingFields: boolean) => {
    const updatesScheduling: Models.LocationScheduling | undefined = hasDirectSchedulingFields
        ? {
              software: data.software as string,
              version: data.version as string,
              timezone: data.timezone as string,
              ipAddress: data.ipAddress as string,
          }
        : undefined
    return updatesScheduling
}

export const connectPaymentInfoData = (
    paymentInformationStatus?: string,
    paymentAmount?: string,
    hasStripeAccount?: boolean,
) => {
    const updatesConnectPaymentInfo: Api.UpdateLocationConnectPaymentInfo | undefined =
        hasStripeAccount && paymentInformationStatus
            ? {
                  payment_information_status_id: Number(paymentInformationStatus),
                  amount: paymentAmount ? paymentAmount : null,
              }
            : undefined
    return updatesConnectPaymentInfo
}

export const createConnectPaymentInfoData = (
    practiceLocationId: string,
    paymentInformationStatus?: string,
    paymentAmount?: string,
) => {
    const createConnectPaymentInfo: Api.CreateLocationConnectPaymentInfo = {
        practice_location_id: practiceLocationId,
        payment_information_status_id: Number(paymentInformationStatus),
        amount: paymentAmount as string,
    }

    return createConnectPaymentInfo
}
