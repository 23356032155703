import * as React from 'react'
import Button from '@mui/material/Button'

import './TestReferralTypeSelector.sass'

export type TestReferralTypeSelectorProps = {
    onSelectedTestReferralType: (type: Models.PracticeSpecialtyValue) => void
    practiceName: string
}

type Props = TestReferralTypeSelectorProps

const TestReferralTypeSelector = (props: Props) => {
    const { practiceName, onSelectedTestReferralType } = props

    return (
        <div className="test-referral-type-selector">
            <div className="practice-title">
                <div className="title">{practiceName}</div>
            </div>
            <div className="test-referral-type-selector__inner-container">
                <div className="title">What type of request would you like to send?</div>
                <div className="btn-container">
                    <Button
                        className="test-referral-option-btn"
                        onClick={() => onSelectedTestReferralType('dental')}
                        variant="outlined"
                    >
                        <div className="test-referral-option-btn__inner">
                            <div className="img-container">
                                <img src="https://assets.simplifeye.co/pst/svg/icon-tooth.svg" alt="icon of a tooth" />
                            </div>
                            <span className="test-referral-option-label">Dentistry</span>
                        </div>
                    </Button>
                    <Button
                        className="test-referral-option-btn"
                        onClick={() => onSelectedTestReferralType('optometry')}
                        variant="outlined"
                    >
                        <div className="test-referral-option-btn__inner">
                            <div className="img-container">
                                <img src="https://assets.simplifeye.co/pst/svg/icon-eye.svg" alt="icon of an eye" />
                            </div>
                            <span className="test-referral-option-label">Optometry</span>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default TestReferralTypeSelector
