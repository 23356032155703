import { UpdateConnectSurvey } from '../../models/Connect'
import { mapConnectSurvey, mapConnectWebCodes, mapReverseUpdateConnectSurvey } from '../mappers/connect'
import ApiService from '../service'

export default class ConnectService extends ApiService {
    readonly ModulePath = 'api/admin/connect/v1'

    async postCreateConnectSurvey(createConnectSurvey: Api.Connect.CreateConnectSurvey) {
        const { data } = (await this.fetch(`survey`, {
            method: 'POST',
            body: JSON.stringify(createConnectSurvey),
        })) as Api.StructuredResponse<Api.Connect.ConnectSurvey>

        return mapConnectSurvey(data)
    }

    async putUpdateConnectSurvey(surveyId: string, updates: UpdateConnectSurvey) {
        const { data: updatedConnectSurvey } = (await this.fetch(`survey/${surveyId}`, {
            method: 'PUT',
            body: JSON.stringify(mapReverseUpdateConnectSurvey(updates)),
        })) as Api.StructuredResponse<Api.Connect.ConnectSurvey>

        return mapConnectSurvey(updatedConnectSurvey)
    }

    async listConnectSurveys(practice: Models.Practice) {
        const { data: connectWebCodes } = (await this.fetch(`survey/list/${practice.id}`)) as Api.StructuredResponse<
            Array<Api.Connect.ConnectWebCode>
        >

        return mapConnectWebCodes(connectWebCodes)
    }

    async postTelehealthSession(url: string, practiceUrl: string) {
        const { data } = (await this.fetch('url', {
            method: 'POST',
            body: JSON.stringify({
                url,
                practice_url: practiceUrl,
            }),
        })) as Api.StructuredResponse<{ id: string }>

        return data
    }
}
