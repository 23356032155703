import React from 'react'

import './LinkExpired.sass'

const { REACT_APP_ASSETS_URL } = process.env

function LinkExpired() {
    return (
        <div className="link-expired-container">
            <div className="link-expired">
                <img src={`${REACT_APP_ASSETS_URL}/pst/expired.svg`} alt="Expired link" />
                <h1>This link has expired</h1>
                <p>It looks like your password reset link has expired. You can request a new one from your manager.</p>
            </div>
        </div>
    )
}

export default LinkExpired
