import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, LinearProgress } from '@mui/material'
import { RootState } from 'appReducer'
import classNames from 'classnames'
import filesize from 'filesize'
import _ from 'lodash'

import ErrorMessage from '../../../modules/shared/error-message/error-message'
import { cancelPendingUpload, startFileUpload } from '../../../modules/uploads/actions'
import FileDrop from '../../../modules/uploads/FileDrop'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { useAsync } from '../../../util/useAsync'
import { fetchReferralAttachments, receiveFormAttachments } from '../v2actions'

import './ReferralAttachment.sass'

export type Props = {
    referralId?: string
}

const ReferralAttachment = (props: Props) => {
    const dispatch = useAppDispatch()
    const [fileDropRef, setFileDropRef] = useState<any>() // TO-DO-V2 - everthing is any in <FileDrop
    const { referralId } = props
    const pendingUploads = useSelector((state: RootState) => state.uploads.pendingUploads)

    const { run: fetchAsyncReferralAttachments, status: statusFetchAsyncReferralAttachments } = useAsync()

    const referralAttachmentsState =
        useSelector((state: RootState) => referralId && state.v2.referrals.referralAttachments[referralId]) || undefined
    const attachments = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.attachments.allAttachments.value,
    )
    const addedAttachments = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.attachments.addedAttachments.value,
    )
    const removedAttachments = useSelector(
        (state: RootState) => state.v2.referrals.referralForm.attachments.removedAttachments.value,
    )

    useEffect(() => {
        if (referralId) {
            fetchAsyncReferralAttachments(dispatch(fetchReferralAttachments(referralId)))
        }
    }, [dispatch, fetchAsyncReferralAttachments, referralId])

    useEffect(() => {
        if (referralAttachmentsState?.length) {
            const attachmentsData = {}
            referralAttachmentsState.forEach(attachment => {
                attachmentsData[attachment.id] = attachment
            })
            dispatch(
                receiveFormAttachments({
                    allAttachments: attachmentsData,
                }),
            )
        } else {
            dispatch(
                receiveFormAttachments({
                    allAttachments: {},
                }),
            )
        }
    }, [referralAttachmentsState, dispatch])

    const setAttachments = (allAttachments: ModelsV2.Referrals.ReferralFormAttachments) => {
        dispatch(
            receiveFormAttachments({
                allAttachments: allAttachments,
            }),
        )
    }
    const setAddedAttachments = (addedAttachments: ModelsV2.Referrals.ReferralFormAttachments) => {
        dispatch(
            receiveFormAttachments({
                addedAttachments: addedAttachments,
            }),
        )
    }
    const setRemovedAttachments = (removedAttachments: ModelsV2.Referrals.ReferralFormAttachments) => {
        dispatch(
            receiveFormAttachments({
                removedAttachments: removedAttachments,
            }),
        )
    }
    const renderAttachment = (attachment: Models.Upload | ModelsV2.Referrals.ReferralAttachment) => {
        const removed = removedAttachments && removedAttachments[attachment.id] != null
        const pending = pendingUploads[attachment.id]

        const pendingProgress = pending && Math.round((100 * pending.parts_completed) / pending.number_of_parts)

        return (
            <div className="file-upload" key={attachment.id}>
                <div className={classNames('info', { pending: Boolean(pending), removed })}>
                    <span className="file-upload-name">
                        <i className="material-icons">fiber_manual_record</i> {attachment.filename}
                    </span>
                    <span>{attachment.size && filesize(attachment.size)}</span>
                    <i onClick={() => removeAttachment(attachment)} className="material-icons">
                        close
                    </i>
                </div>
                {pending && <LinearProgress variant="determinate" value={pendingProgress} />}
            </div>
        )
    }
    const onRemoveAttachment = (attachment: Models.Upload | ModelsV2.Referrals.ReferralAttachment) => {
        if (referralId) {
            if (attachments && attachments[attachment.id]) {
                setAttachments(_.omit(attachments, [attachment.id]))
            }
            if (!removedAttachments) {
                setRemovedAttachments({})
            }

            if (referralAttachmentsState?.find(attach => attach.id === attachment.id)) {
                setRemovedAttachments({
                    ...removedAttachments,
                    [attachment.id]: attachment,
                })
            } else {
                setAddedAttachments(_.omit(addedAttachments, [attachment.id]))
            }
        } else {
            attachments && setAttachments(_.omit(attachments, [attachment.id]))
        }
    }
    const onFinishUpload = (upload: Models.Upload) => {
        if (attachments == null) {
            setAttachments({})
        }
        if (addedAttachments == null) {
            setAddedAttachments({})
        }

        setAddedAttachments({
            ...addedAttachments,
            [upload.id]: upload,
        })
        setAttachments({
            ...attachments,
            [upload.id]: upload,
        })
    }
    const removeAttachment = (attachment: Models.Upload | ModelsV2.Referrals.ReferralAttachment) => {
        onRemoveAttachment(attachment)
        if (pendingUploads[attachment.id]) {
            onCancelPendingUpload(pendingUploads[attachment.id])
        }
    }
    const onFileDrop = (file: File) => {
        dispatch(startFileUpload(file, onFinishUpload))
    }
    const onCancelPendingUpload = (upload: Models.Upload) => {
        dispatch(cancelPendingUpload(upload))
    }

    if (statusFetchAsyncReferralAttachments === 'rejected') {
        return <ErrorMessage>Problem with loading referral attachments</ErrorMessage>
    }

    return (
        <Grid container={true} spacing={5}>
            <Grid item={true} md={4} xs={12}>
                <div className="attachments-header">
                    <p className="attachments-title">Attachments</p>
                </div>
                <FileDrop
                    onFileDrop={onFileDrop}
                    ref={(ref: any) => {
                        setFileDropRef(ref)
                    }}
                >
                    <div className="uploads-container">
                        <div className="uploads">{attachments && _.map(attachments, renderAttachment)}</div>
                        <i className="material-icons attach-file">attach_file</i> Drop files to attach, or
                        <span className="browse-uploads" onClick={() => fileDropRef.dropzoneRef.open()}>
                            {' '}
                            browse.
                        </span>
                    </div>
                </FileDrop>
            </Grid>
        </Grid>
    )
}

export default ReferralAttachment
