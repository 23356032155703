import * as _ from 'lodash'
export interface ApiSelfSchedulingWebCode {
    id: string
    created: Date
    updated: Date
    key: string
    practice_id: string
    account_id: string
    url: string
    embed_code: string
    active: boolean
    customization: Api.SelfScheduling.WebCodeCustomization
    google_analytics_id?: string
}

export function mapWebCodeToModel(webCode: ApiSelfSchedulingWebCode): Models.SelfSchedulingWebCode {
    return {
        id: webCode.id,
        created: webCode.created && new Date(webCode.created),
        updated: webCode.updated && new Date(webCode.updated),
        key: webCode.key,
        practiceId: webCode.practice_id,
        accountId: webCode.account_id,
        url: webCode.url,
        embedCode: webCode.embed_code,
        active: webCode.active,
        customization: mapWebCodeCustomizationToModel(webCode.customization),
        googleAnalyticsId: webCode.google_analytics_id,
    }
}

export function mapWebCodeCustomizationToModel(
    customization: Api.SelfScheduling.WebCodeCustomization,
): Models.SelfSchedulingWebCodeCustomization {
    return {
        schedulingButton: customization?.scheduling_button,
        schedulingModal: customization?.scheduling_modal && mapSchedulingModalToModel(customization.scheduling_modal),
        schedulingAdditionalFeaturesAndFields:
            customization?.scheduling_additional_features_and_fields &&
            mapSchedulingAdditionalFeaturesAndFieldsToModel(customization.scheduling_additional_features_and_fields),
    }
}

export function mapSchedulingModalToModel(
    schedulingModal: Api.SelfScheduling.SchedulingModal,
): Models.SelfSchedulingModal {
    return {
        colors: mapSchedulingModalColorsToModel(schedulingModal.colors),
        backgroundColorStyle: schedulingModal.background_color_style,
    }
}

export function mapSchedulingModalColorsToModel(
    colors: Api.SelfScheduling.SchedulingModalColors,
): Models.SelfSchedulingModalColors {
    return {
        backgroundColor1: colors.background_color_1,
        backgroundColor2: colors.background_color_2,
        text: colors.text,
    }
}

export function mapSchedulingAdditionalFeaturesAndFieldsToModel(
    additionalFeaturesAndFields: Api.SelfScheduling.SchedulingAdditionalFeaturesAndFields,
): Models.SelfSchedulingAdditionalFeaturesAndFields {
    return {
        insurance: additionalFeaturesAndFields.insurance,
        additionalComments: additionalFeaturesAndFields.additional_comments,
        enabledMaps: additionalFeaturesAndFields.enabled_maps,
        enabledProviderSelection: additionalFeaturesAndFields.enabled_provider_selection,
        customRedirect: additionalFeaturesAndFields.custom_redirect,
    }
}

export function mapSchedulingModalColorTypeToApi(
    type: Models.SelfSchedulingModalColorTypes,
): Api.SelfScheduling.SchedulingModalColorTypes {
    return _.snakeCase(type) as Api.SelfScheduling.SchedulingModalColorTypes
}

export function mapSchedulingModalColorsToApi(
    colors: Models.SelfSchedulingModalColors,
): Api.SelfScheduling.SchedulingModalColors {
    return {
        background_color_1: colors.backgroundColor1,
        background_color_2: colors.backgroundColor2,
        text: colors.text,
    }
}

export function mapSchedulingModalToApi(
    schedulingModal: Models.SelfSchedulingModal,
): Api.SelfScheduling.SchedulingModal {
    return {
        colors: mapSchedulingModalColorsToApi(schedulingModal.colors),
        background_color_style: schedulingModal.backgroundColorStyle,
    }
}

export function mapSchedulingAdditionalFeaturesAndFieldsToApi(
    additionalFeaturesAndFields: Models.SelfSchedulingAdditionalFeaturesAndFields,
): Api.SelfScheduling.SchedulingAdditionalFeaturesAndFields {
    return {
        insurance: additionalFeaturesAndFields.insurance,
        additional_comments: additionalFeaturesAndFields.additionalComments,
        enabled_maps: additionalFeaturesAndFields.enabledMaps,
        enabled_provider_selection: additionalFeaturesAndFields.enabledProviderSelection,
        custom_redirect: additionalFeaturesAndFields.customRedirect,
    }
}

export function mapWebCodeCustomizationToApi(
    customization: Models.SelfSchedulingWebCodeCustomization,
): Api.SelfScheduling.WebCodeCustomization {
    return {
        scheduling_button: customization.schedulingButton,
        scheduling_modal: mapSchedulingModalToApi(customization.schedulingModal),
        scheduling_additional_features_and_fields: mapSchedulingAdditionalFeaturesAndFieldsToApi(
            customization.schedulingAdditionalFeaturesAndFields,
        ),
    }
}
