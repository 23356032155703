import React, { useState } from 'react'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import _ from 'lodash'

import { mapWebCodeCustomizationToApi } from '../../../../Api/mappers/self-scheduling/webcodes'
import AdminAccount from '../../../../models/AdminAccount'
import SelfSchedulingModel from '../../../../models/SelfScheduling'

import SelfSchedulingAdditionalFeaturesAndFieldsOptions from './SelfSchedulingAdditionalFeaturesAndFieldsOptions'
import SelfSchedulingButtonCustomizationOptions from './SelfSchedulingButtonCustomizationOptions'
import SelfSchedulingModalCustomizationOptions from './SelfSchedulingModalCustomizationOptions'
import SelfSchedulingWebCodeLocationsList from './SelfSchedulingWebCodeLocationsList'
import { CustomizationObject } from './types'

import './SelfSchedulingWebCodeCustomizationForm.sass'

export type SelfSchedulingWebCodeCustomizationFormProps = {
    account: AdminAccount
    practice: Models.Practice
    webCode: Models.SelfSchedulingWebCode
    modalRef: React.RefObject<any>
    onSaveWebCode: (updates: Api.SelfScheduling.WebCodeUpdate) => Promise<void>
}

const moduleName = 'self-scheduling-web-code-customization-form'

function SelfSchedulingWebCodeCustomizationForm(props: SelfSchedulingWebCodeCustomizationFormProps) {
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isPracticeLocationsOpen, setIsPracticeLocationsOpen] = useState<boolean>(false)
    const [isButtonCustomizationOpen, setIsButtonCustomizationOpen] = useState<boolean>(false)
    const [isModalCustomizationOpen, setIsModalCustomizationOpen] = useState<boolean>(false)
    const [isAdditionalFeaturesAndFieldsOpen, setIsAdditionalFeaturesAndFieldsOpen] = useState<boolean>(false)
    const [customization, setCustomization] = useState<Models.SelfSchedulingWebCodeCustomization>(
        _.cloneDeep(props.webCode.customization),
    )
    const { webCode, practice, modalRef } = props
    const isSaveButtonDisabled = !isPracticeLocationsOpen && !isDirty

    function onSave() {
        const { webCode } = props
        props.onSaveWebCode({ id: webCode.id, customization: mapWebCodeCustomizationToApi(customization) })
    }

    function onToggleButtonCustomizationOpen() {
        if (!isButtonCustomizationOpen) {
            setIsPracticeLocationsOpen(false)
        }
        setIsButtonCustomizationOpen(!isButtonCustomizationOpen)
    }

    function onToggleModalCustomizationOpen() {
        if (!isModalCustomizationOpen) {
            setIsPracticeLocationsOpen(false)
        }
        setIsModalCustomizationOpen(!isModalCustomizationOpen)
    }

    function onTogglePracticeLocationsOpen() {
        if (!isPracticeLocationsOpen) {
            setIsButtonCustomizationOpen(false)
            setIsModalCustomizationOpen(false)
        }
        setIsPracticeLocationsOpen(!isPracticeLocationsOpen)
    }

    function onToggleAdditionalFeaturesAndFieldsOpen() {
        if (!isAdditionalFeaturesAndFieldsOpen) {
            setIsPracticeLocationsOpen(false)
        }
        setIsAdditionalFeaturesAndFieldsOpen(!isAdditionalFeaturesAndFieldsOpen)
    }

    function onUpdateCustomColor(
        colorKey: Api.SelfScheduling.SchedulingButtonColorTypes | Models.SelfSchedulingModalColorTypes,
        colorValue: CustomizationObject,
        customizationObject: string = 'schedulingButton',
    ) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization[customizationObject].colors,
            nextColors => {
                nextColors[colorKey] = colorValue
                return nextColors
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onUpdateSize(size: Api.SelfScheduling.SchedulingButtonSize) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingButton.size,
            () => size,
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onUpdateStyle(
        style: Api.SelfScheduling.SchedulingButtonStyle | Api.SelfScheduling.SchedulingModalBackgroundColorStyle,
        customizationObject: string = 'schedulingButton',
    ) {
        const styleProp = customizationObject === 'schedulingButton' ? 'style' : 'backgroundColorStyle'
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization[customizationObject][styleProp],
            () => style,
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function resetColors(
        e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
        customizationObject: string = 'schedulingButton',
    ) {
        e.preventDefault()
        const defaultColors = customizationObject === 'schedulingButton' ? 'defaultButtonColors' : 'defaultModalColors'

        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization[customizationObject].colors,
            () => SelfSchedulingModel[defaultColors],
        )

        setCustomization(newCustomization)
        setIsDirty(customization[customizationObject]?.colors ? true : isDirty)
    }

    function onUpdateInsurance(insurance: Models.SelfSchedulingInsurance) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields.insurance,
            () => {
                return insurance
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onUpdateAdditionalComments(additionalComments: Models.SelfSchedulingAdditionalComments) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields.additionalComments,
            () => {
                return additionalComments
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onUpdateOpenURLIn(openURLIn: Models.SelfSchedulingOpenURLIn) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields,
            nextSchedulingAdditionalFeaturesAndFields => {
                if (!nextSchedulingAdditionalFeaturesAndFields.customRedirect) {
                    nextSchedulingAdditionalFeaturesAndFields['customRedirect'] = {
                        enabled: true,
                        url: '',
                        target: openURLIn,
                    }
                } else {
                    nextSchedulingAdditionalFeaturesAndFields.customRedirect.target = openURLIn
                }
                return nextSchedulingAdditionalFeaturesAndFields
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onUpdateCustomConfirmationPageURL(url: string) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields,
            nextSchedulingAdditionalFeaturesAndFields => {
                if (!nextSchedulingAdditionalFeaturesAndFields.customRedirect) {
                    nextSchedulingAdditionalFeaturesAndFields['customRedirect'] = {
                        enabled: true,
                        url: url,
                        target: 'same_window',
                    }
                } else {
                    nextSchedulingAdditionalFeaturesAndFields.customRedirect.url = url
                }
                return nextSchedulingAdditionalFeaturesAndFields
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onEnableMaps(enabled: boolean) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields.enabledMaps,
            () => {
                return enabled
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onEnableProvider(enabled: boolean) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields.enabledProviderSelection,
            () => {
                return enabled
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    function onEnableCustomConfirmationPage(enabled: boolean) {
        const newCustomization = iassign(
            customization,
            nextCustomization => nextCustomization.schedulingAdditionalFeaturesAndFields,
            nextSchedulingAdditionalFeaturesAndFields => {
                if (!nextSchedulingAdditionalFeaturesAndFields.customRedirect) {
                    nextSchedulingAdditionalFeaturesAndFields['customRedirect'] = {
                        enabled: enabled,
                        url: '',
                        target: 'same_window',
                    }
                } else {
                    nextSchedulingAdditionalFeaturesAndFields.customRedirect.enabled = enabled
                }
                return nextSchedulingAdditionalFeaturesAndFields
            },
        )

        setCustomization(newCustomization)
        setIsDirty(true)
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__container`}>
                <div className={`${moduleName}__header`}>
                    <div className={`${moduleName}__header-name`}>{props.practice.name}</div>
                    <div className={`${moduleName}__header-url`}>{props.webCode.url}</div>
                </div>
                <div className={`${moduleName}__title`}>
                    <span>Customization Options For Self-Scheduling Widget</span>
                </div>
                <div className={`${moduleName}__options`}>
                    <div className={`${moduleName}__table-action`} onClick={onToggleButtonCustomizationOpen}>
                        <div
                            className={classNames(`${moduleName}__option`, {
                                [`${moduleName}__option--open`]: Boolean(isButtonCustomizationOpen),
                            })}
                        >
                            <i className={`material-icons ${moduleName}__option-add-icon`}>add</i>
                            <i className={`material-icons ${moduleName}__option-close-icon`}>close</i>
                            Customize Self-Scheduling Button
                        </div>
                    </div>
                    {isButtonCustomizationOpen && (
                        <SelfSchedulingButtonCustomizationOptions
                            schedulingButton={customization.schedulingButton}
                            resetColors={resetColors}
                            updateCustomColor={onUpdateCustomColor}
                            updateSize={onUpdateSize}
                            updateStyle={onUpdateStyle}
                        />
                    )}
                </div>
                <div className={`${moduleName}__options`}>
                    <div className={`${moduleName}__table-action`} onClick={onToggleModalCustomizationOpen}>
                        <div
                            className={classNames(`${moduleName}__option`, {
                                [`${moduleName}__option--open`]: Boolean(isModalCustomizationOpen),
                            })}
                        >
                            <i className={`material-icons ${moduleName}__option-add-icon`}>add</i>
                            <i className={`material-icons ${moduleName}__option-close-icon`}>close</i>
                            Customize Self-Scheduling Modal
                        </div>
                    </div>
                    {isModalCustomizationOpen && (
                        <SelfSchedulingModalCustomizationOptions
                            schedulingModal={customization.schedulingModal}
                            resetColors={resetColors}
                            updateBackgroundStyle={onUpdateStyle}
                            updateCustomColor={onUpdateCustomColor}
                        />
                    )}
                </div>
                <div className={`${moduleName}__options`}>
                    <div className={`${moduleName}__table-action`} onClick={onTogglePracticeLocationsOpen}>
                        <div
                            className={classNames(`${moduleName}__option`, {
                                [`${moduleName}__option--open`]: Boolean(isPracticeLocationsOpen),
                            })}
                        >
                            <i className={`material-icons ${moduleName}__option-add-icon`}>add</i>
                            <i className={`material-icons ${moduleName}__option-close-icon`}>close</i>
                            Practice Locations
                        </div>
                    </div>
                    {isPracticeLocationsOpen && (
                        <SelfSchedulingWebCodeLocationsList webCode={webCode} practice={practice} modalRef={modalRef} />
                    )}
                </div>
                <div className={`${moduleName}__options`}>
                    <div className={`${moduleName}__table-action`} onClick={onToggleAdditionalFeaturesAndFieldsOpen}>
                        <div
                            className={classNames(`${moduleName}__option`, {
                                [`${moduleName}__option--open`]: Boolean(isAdditionalFeaturesAndFieldsOpen),
                            })}
                        >
                            <i className={`material-icons ${moduleName}__option-add-icon`}>add</i>
                            <i className={`material-icons ${moduleName}__option-close-icon`}>close</i>
                            Additional Features and Fields
                        </div>
                    </div>
                    {isAdditionalFeaturesAndFieldsOpen && (
                        <SelfSchedulingAdditionalFeaturesAndFieldsOptions
                            additionalFeaturesAndFields={customization.schedulingAdditionalFeaturesAndFields}
                            updateInsurance={onUpdateInsurance}
                            updateAdditionalComments={onUpdateAdditionalComments}
                            updateOpenURLIn={onUpdateOpenURLIn}
                            enableMaps={onEnableMaps}
                            enableProvider={onEnableProvider}
                            enableCustomConfirmationPage={onEnableCustomConfirmationPage}
                            updateCustomConfirmationPageURL={onUpdateCustomConfirmationPageURL}
                        />
                    )}
                </div>
            </div>

            <button
                className={classNames('webcode-button', { 'can-save': !isSaveButtonDisabled })}
                onClick={onSave}
                disabled={isSaveButtonDisabled}
            >
                {isPracticeLocationsOpen ? `close` : `save`}
            </button>
        </div>
    )
}

export default SelfSchedulingWebCodeCustomizationForm
