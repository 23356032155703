import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import throttle from 'lodash/throttle'
import moment from 'moment'

import { setRedirect } from '../../../appActions'
import { RootState } from '../../../appReducer'
import { fetchPractice } from '../../practices/actions'
import ConfigLogsPracticeHeader from '../../shared/ConfigLogsPracticeHeader'
import { DATE_FORMAT } from '../../shared/custom-fields/custom-date-range/CustomDateRange'
import { usePrevious } from '../../shared/custom-hooks'

import ConfigLogsFilters from './ConfigLogsFilters'
import ConfigLogsList from './ConfigLogsList'
import { fetchSchedulingConfigurationLogs } from './v2actions'

import './ConfigLogs.sass'

export type ConfigLogsRouteParams = RouteComponentProps<{
    practiceId: string
}>

type Props = ConfigLogsRouteParams

const moduleName = 'config-logs'

const ConfigLogs = (props: Props) => {
    const practiceId = props.match.params.practiceId

    const [csvError, setCsvError] = useState(false)

    const page = useSelector((state: RootState) => state.v2.configLogs.configLogsData?.[practiceId]?.page || 1)
    const selectedLocationId = useSelector((state: RootState) => state.v2.configLogs?.selectedLocationId?.[practiceId])
    const selectedUserIds = useSelector((state: RootState) => state.v2.configLogs?.selectedUserIds?.[practiceId] || [])
    const dateFromValue = useSelector((state: RootState) => state.v2.configLogs?.dateFrom?.[practiceId]?.value)
    const dateToValue = useSelector((state: RootState) => state.v2.configLogs?.dateTo?.[practiceId]?.value)

    const [loading, setLoading] = useState(false)
    const [searchPage, setSearchPage] = useState<number>(page)

    const practice = useSelector((state: RootState) => state.practices.practices[practiceId])

    const practiceFetched = practice?.id

    const prevPage = usePrevious(searchPage) || null

    const dispatch = useDispatch()

    useEffect(() => {
        if (!practiceFetched) {
            dispatch(fetchPractice(practiceId))
        }
    }, [practiceFetched, practiceId, dispatch])

    const onReturnToPractices = () => {
        dispatch(setRedirect(`/practices`))
    }

    const searchForConfigLogs = useCallback(
        (searchPage: number) => {
            if (selectedLocationId) {
                const searchTerms = {
                    page: searchPage,
                    user_ids: selectedUserIds,
                    start_date: moment(dateFromValue, DATE_FORMAT)
                        .startOf('day')
                        .toISOString(),
                    end_date: moment(dateToValue, DATE_FORMAT)
                        .endOf('day')
                        .toISOString(),
                }

                dispatch(fetchSchedulingConfigurationLogs(selectedLocationId, searchTerms))
            }
        },
        [dispatch, selectedLocationId, dateFromValue, dateToValue, selectedUserIds],
    )

    const throttledSearchConfigLogs = useMemo(() => throttle(searchForConfigLogs, 300), [searchForConfigLogs])

    useEffect(() => {
        if (prevPage !== searchPage) {
            setCsvError(false)
            throttledSearchConfigLogs(searchPage)
        }
    }, [searchPage, prevPage, throttledSearchConfigLogs])

    if (!practice) {
        return <div>Loading...</div>
    }

    return (
        <div className={moduleName}>
            <div className="detached-button" onClick={onReturnToPractices}>
                <i className="material-icons">arrow_back</i>
            </div>
            <ConfigLogsPracticeHeader practice={practice} />
            <div className={`${moduleName}__title`}>Configuration Logs</div>
            <div className={`${moduleName}__subtitle-wrapper`}>
                <div className={`${moduleName}__subtitle`}>View configuration logs.</div>
            </div>
            <ConfigLogsFilters practiceId={practiceId} setCsvError={setCsvError} setLoading={setLoading} />
            <ConfigLogsList
                practiceId={practice.id}
                setCsvError={setCsvError}
                csvError={csvError}
                setSearchPage={setSearchPage}
                searchPage={searchPage}
                isPending={loading}
            />
        </div>
    )
}

export default ConfigLogs
