import * as React from 'react'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'

import { FILE_MAX_SIZE } from '../../Constants'

import './FileDrop.sass'

type Props = {
    className?: string
    style?: any
    hoverElementStyle?: any
    hover?: any
    children: any
    onFileDrop: (file: File) => any
    ref?: any
    accept?: any
    disableClick?: boolean
}

export type FileDropState = {
    hovering: boolean
}

class FileDrop extends React.Component<Props, FileDropState> {
    dropzoneRef?: any

    static get defaultProps() {
        return {
            className: '',
            style: {},
            hoverElementStyle: {},
            hover: <div>Drop files here</div>,
            disableClick: true,
            accept: '',
        }
    }

    constructor(props: Props) {
        super(props)
        this.state = { hovering: false }
    }

    onStartHover() {
        this.setState({ hovering: true })
    }

    onEndHover() {
        this.setState({ hovering: false })
    }

    onDrop(acceptedFiles: File[], rejectedFiles: File[]) {
        const [acceptedFile] = acceptedFiles
        const [rejectedFile] = rejectedFiles
        this.setState({ hovering: false })

        if (acceptedFile) {
            this.props.onFileDrop(acceptedFile)
        } else if (rejectedFile) {
            // TODO better errors
            window.alert('Max file size is 1GB.')
        }
    }

    render() {
        const { className, children, hover, style, hoverElementStyle, accept, disableClick } = this.props

        return (
            <Dropzone
                className={classNames(className, 'file-upload-dropzone')}
                style={style}
                ref={ref => {
                    this.dropzoneRef = ref
                }}
                onDragEnter={() => this.onStartHover()}
                onDragOver={() => this.onStartHover()}
                onDragLeave={() => this.onEndHover()}
                onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                maxSize={FILE_MAX_SIZE}
                multiple={false}
                accept={accept}
                disableClick={disableClick}
            >
                {this.state.hovering && (
                    <div style={hoverElementStyle} className="file-upload-drop-hover-element">
                        {hover}
                    </div>
                )}
                {children}
            </Dropzone>
        )
    }
}

export default FileDrop
