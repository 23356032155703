import * as React from 'react'
import Circle from 'react-circle'
import Button, { ButtonClassKey } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'

import ConfirmModal from '../../../modules/shared/confirm-modal/ConfirmModal'
import { imageData } from '../../../util/imageValidation'
import CustomTooltip from '../../shared/custom-tooltip/CustomTooltip'
import { cancelPendingUpload, startFileUpload } from '../../uploads/actions'
import FileDrop from '../../uploads/FileDrop'
import {
    confirmUploadedLogo,
    deletePracticeLogo,
    fetchPracticeLogo,
    fetchPracticeLogoBgColor,
    updateLogoBgColor,
} from '../actions'

import './PracticeLogoModal.sass'

const MAX_LOGO_SIZE = 200 * 1024
const MAX_LOGO_WIDTH = 500
const MAX_LOGO_HEIGHT = 200
const PROGRESS_INTERVAL = 60

type TerminalBgColorTypes = 'dark' | 'neutral' | 'light'
type TerminalBgTypes = '' | TerminalBgColorTypes

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const buttonClassOverrides: ButtonClassOverrides = {
    outlined: `practice-logo__cancel-btn`,
    contained: `practice-logo__yes-btn`,
}

export type PracticeLogoModalState = {
    pending?: boolean
    imagePreview?: string
    upload?: null | Models.Upload
    mockProgress: number
    stage: 'NONE' | 'INITIAL' | 'UPLOADING' | 'PREVIEW' | 'ERROR' | 'MESSAGE' | 'DELETE'
    showModal: boolean
    selectedBgColor: TerminalBgTypes
    isSavingLogoBg: boolean
    hasUpdatedLogoBg: boolean
    errorMessageLogoBg: string
    isFetchingLogo: boolean
}

export type PracticeLogoModalProps = {
    practice: Models.Practice
    pendingUploads: Array<Models.Upload>
}

export type PracticeLogoModalDispatch = {
    fetchPracticeLogo: typeof fetchPracticeLogo
    startFileUpload: typeof startFileUpload
    confirmUploadedLogo: typeof confirmUploadedLogo
    deletePracticeLogo: typeof deletePracticeLogo
    cancelPendingUpload: typeof cancelPendingUpload
    fetchPracticeLogoBgColor: typeof fetchPracticeLogoBgColor
    updateLogoBgColor: typeof updateLogoBgColor
}

type Props = PracticeLogoModalProps & PracticeLogoModalDispatch

export const mapBgColorsToHex: {
    [key in TerminalBgColorTypes]: Models.LogoBgColorOptions
} = {
    dark: '#303030',
    neutral: '#666666',
    light: '#FFFFFF',
}

const logoText = {
    set: `This logo will appear on your patient’s payment portal and all email communications sent
    via Vyne Trellis.`,
    notSet: `Upload a logo to appear on patient’s payment portal and all email communications sent via Vyne Trellis.`,
}

export const mapBgHexToColors: {
    [key in Models.LogoBgColorOptions | 'null']: TerminalBgColorTypes | null
} = {
    null: null,
    '': null,
    '#303030': 'dark',
    '#666666': 'neutral',
    '#FFFFFF': 'light',
}

class PracticeLogoModal extends React.Component<Props, PracticeLogoModalState> {
    dropzoneRef: any
    progressInterval: any

    constructor(props: Props) {
        super(props)
        this.state = {
            pending: false,
            imagePreview: '',
            stage: 'NONE',
            mockProgress: 0,
            showModal: false,
            selectedBgColor: '',
            isSavingLogoBg: false,
            hasUpdatedLogoBg: false,
            errorMessageLogoBg: '',
            isFetchingLogo: true,
        }
    }

    async UNSAFE_componentWillMount() {
        await this.props.fetchPracticeLogo(this.props.practice)
        this.setState({
            stage: 'INITIAL',
            isFetchingLogo: false,
        })
        try {
            const bgColor = await this.props.fetchPracticeLogoBgColor(this.props.practice.id)
            let colorType: TerminalBgTypes =
                (bgColor && this.convertHexToBgColor((bgColor as unknown) as Models.LogoBgColorOptions)) || ''

            this.setState({
                selectedBgColor: colorType,
            })
        } catch (err) {}
    }

    componentWillUnmount() {
        this.clearInterval()
    }

    onChoseAnotherFile() {
        if (this.dropzoneRef) {
            this.dropzoneRef.dropzoneRef.open()
        }
    }

    convertHexToBgColor = (hex: Models.LogoBgColorOptions): TerminalBgTypes | null => {
        const bgKeys = Object.keys(mapBgColorsToHex)
        for (let i = 0; i < bgKeys.length; i++) {
            const bgC = bgKeys[i]
            if (mapBgColorsToHex[bgC] === hex) {
                return bgC as TerminalBgTypes
            }
        }
        return null
    }

    async onConfirm() {
        if (this.state.upload) {
            await this.props.confirmUploadedLogo(this.props.practice, this.state.upload)
            this.setState({
                stage: 'MESSAGE',
            })
        }
    }

    async onClose() {
        const { upload } = this.state
        const { pendingUploads } = this.props

        this.setState({
            stage: 'INITIAL',
            upload: null,
            showModal: false,
        })

        const pendingUpload = upload && pendingUploads[upload.id]

        if (pendingUpload) {
            this.props.cancelPendingUpload(pendingUpload)
        }
    }

    clearInterval() {
        if (Boolean(this.progressInterval)) {
            clearInterval(this.progressInterval)
        }
    }

    async onLogoUpload(file: File) {
        if (!file || file.size > MAX_LOGO_SIZE) {
            this.setState({
                stage: 'ERROR',
            })
            return
        }

        const image = await imageData(file)

        const isImageValid = Boolean(image) && image.width <= MAX_LOGO_WIDTH && image.height <= MAX_LOGO_HEIGHT

        if (!isImageValid) {
            this.setState({
                stage: 'ERROR',
            })
            return
        }

        this.clearInterval()

        this.setState(
            {
                imagePreview: image.preview,
                stage: 'UPLOADING',
                mockProgress: 0,
            },
            () => {
                this.props.startFileUpload(file, upload =>
                    this.setState({
                        upload,
                    }),
                )
                this.progressInterval = setInterval(() => {
                    this.setState({
                        mockProgress: Math.min(100, this.state.mockProgress + 1),
                    })
                }, PROGRESS_INTERVAL)
            },
        )
    }

    openModal = () => {
        this.setState({
            showModal: true,
        })
    }

    onFinishUpload() {
        this.setState({
            stage: 'PREVIEW',
            mockProgress: 0,
        })
        this.clearInterval()
    }

    onDeleteLogo() {
        this.setState({
            stage: 'DELETE',
        })
    }

    async onConfirmDelete() {
        this.setState({
            pending: true,
        })
        await this.props.deletePracticeLogo(this.props.practice)
        this.setState({
            pending: false,
        })
        this.onClose()
    }

    get practiceLogoUrl(): undefined | string {
        const { practice } = this.props
        if (practice && practice.logo && practice.logo && practice.logo.url) {
            return practice.logo.url
        }
        return undefined
    }

    renderInitial(visible: boolean) {
        const { practice } = this.props
        const logoUrl = this.practiceLogoUrl

        return (
            <div className={`practice-logo__current-logo ${!visible && 'practice-logo__current-logo--hidden'}`}>
                <div className="practice-logo__header">{practice.name} Logo</div>
                <div className="practice-logo-info-text">
                    {!!logoUrl ? logoText.set : logoText.notSet}
                    <p className="practice-logo-tooltip">
                        <CustomTooltip
                            position="bottom-right"
                            text={`Please upload .jpg, .jpeg or .png files, and no larger than 500x200px and ${MAX_LOGO_SIZE /
                                1024}kb.`}
                        >
                            <Icon className="tooltip-icon">info</Icon>
                        </CustomTooltip>
                    </p>
                </div>

                <FileDrop
                    onFileDrop={(file: File) => this.onLogoUpload(file)}
                    disableClick={false}
                    accept="image/jpeg, image/png, image/jpg"
                    ref={(ref: any) => (this.dropzoneRef = ref)}
                    className={`practice-logo__filedrop
                        ${logoUrl && 'practice-logo__filedrop--image'}`}
                    hover={null}
                >
                    <div className="practice-logo__placeholder">
                        {logoUrl ? (
                            <img src={logoUrl} className={'practice-logo__image'} alt={`${practice.name} Logo`} />
                        ) : (
                            <div className="practice-logo__placeholder-action">
                                <Icon>image</Icon> <span>UPLOAD LOGO</span>
                            </div>
                        )}
                    </div>
                </FileDrop>

                {logoUrl && (
                    <div className={`practice-logo__logo-actions`}>
                        <Button
                            variant="contained"
                            type="reset"
                            classes={buttonClassOverrides}
                            onClick={() => this.onDeleteLogo()}
                        >
                            DELETE LOGO
                        </Button>
                        <Button
                            variant="outlined"
                            classes={buttonClassOverrides}
                            onClick={() => this.onChoseAnotherFile()}
                        >
                            UPLOAD NEW LOGO
                        </Button>
                    </div>
                )}
            </div>
        )
    }

    renderMessage() {
        return (
            <Grid item={true} xs={12}>
                <div className="practice-logo__message-header">
                    <Icon className="practice-logo__message-header-icon practice-logo__message-header-icon--success">
                        check_circle
                    </Icon>
                    <span className="practice-logo__message-header-title">This logo was successfully saved.</span>
                </div>
                <Grid
                    item={true}
                    xs={12}
                    className="practice-logo__button-container  practice-logo__button-container--message"
                >
                    <Button onClick={() => this.onClose()} className="practice-logo__button">
                        BACK TO PRACTICES
                    </Button>
                </Grid>
            </Grid>
        )
    }

    renderDelete() {
        const { pending } = this.state
        return (
            <ConfirmModal
                title="Are you sure you want to delete this logo?"
                subtitle=""
                discardText="CANCEL"
                confirmText="DELETE LOGO"
                open
                onClose={() =>
                    this.setState({
                        stage: 'INITIAL',
                    })
                }
                onDiscard={() =>
                    this.setState({
                        stage: 'INITIAL',
                    })
                }
                onConfirm={() => {
                    !pending && this.onConfirmDelete()
                }}
            />
        )
    }

    renderUploading(progress: number) {
        return (
            <Grid item={true} xs={12}>
                <div className="practice-logo__modal-title">
                    <Typography variant="h1" gutterBottom={true} className="practice-logo__modal-caption">
                        Uploading...
                    </Typography>
                </div>
                <div className="practice-logo__progress">
                    <Circle
                        progress={progress}
                        animate={true}
                        responsive={false}
                        lineWidth="24"
                        size="170"
                        progressColor="#7ed321"
                        bgColor="#f5f5f5"
                        textColor="#4f4f4f"
                        textStyle={{
                            fontSize: '80px',
                        }}
                        roundedStroke={true}
                        showPercentage={true}
                        showPercentageSymbol={true}
                    />
                </div>
            </Grid>
        )
    }

    renderPreview() {
        const { imagePreview, pending } = this.state

        return (
            <React.Fragment>
                <Grid item={true} xs={12} className="practice-logo__uploded-image">
                    <Typography gutterBottom={true}>
                        <img src={imagePreview} alt="Practice Logo Preview" />
                    </Typography>
                </Grid>

                <Grid item={true} xs={12} className="practice-logo__button-container">
                    <Button onClick={() => this.onChoseAnotherFile()} className="practice-logo__button">
                        UPLOAD NEW FILE
                    </Button>
                    <Button
                        disabled={pending}
                        onClick={async () => {
                            this.setState({ pending: true })
                            await this.onConfirm()
                            this.setState({ pending: false })
                        }}
                        className="practice-logo__save-button"
                    >
                        SAVE THIS LOGO
                        {pending && <CircularProgress size={24} className={'practice-logo__button-spinner'} />}
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }

    renderError() {
        return (
            <Grid item={true} xs={12}>
                <div className="practice-logo__message-header">
                    <Icon className="practice-logo__message-header-icon practice-logo__message-header-icon--error">
                        info
                    </Icon>
                    <span className="practice-logo__message-header-title">This file could not be uploaded.</span>
                    <span className="practice-logo__message-header-subtitle">
                        Please upload .jpg, .jpeg or .png files, and no larger than 500x200px and {MAX_LOGO_SIZE / 1024}
                        kb.
                    </span>
                </div>
                <Grid
                    item={true}
                    xs={12}
                    className="practice-logo__button-container practice-logo__button-container--message"
                >
                    <Button onClick={() => this.onChoseAnotherFile()} className="practice-logo__button">
                        UPLOAD NEW FILE
                    </Button>
                </Grid>
            </Grid>
        )
    }

    renderSpinner() {
        return (
            <div className="practice-logo__await">
                <CircularProgress size={50} className={'practice-logo__await-spinner'} />
            </div>
        )
    }

    renderModalContent() {
        const { stage, upload } = this.state
        const { pendingUploads } = this.props

        switch (stage) {
            case 'INITIAL': {
                return
            }
            case 'UPLOADING': {
                if (!upload) {
                    return this.renderUploading(0)
                }

                const pendingUpload = pendingUploads[upload.id]

                if (pendingUpload && pendingUpload.number_of_parts === pendingUpload.parts_completed) {
                    this.onFinishUpload()
                }

                const pendingProgress = pendingUpload
                    ? Math.round((100 * pendingUpload.parts_completed) / pendingUpload.number_of_parts)
                    : 0

                return this.renderUploading(pendingProgress || this.state.mockProgress)
            }
            case 'PREVIEW': {
                return this.renderPreview()
            }
            case 'ERROR': {
                return this.renderError()
            }
            case 'MESSAGE': {
                return this.renderMessage()
            }
            case 'DELETE': {
                return this.renderDelete()
            }
            default: {
                return this.renderSpinner()
            }
        }
    }

    saveLogoBgChanges = async () => {
        if (this.state.selectedBgColor === '' || this.state.isSavingLogoBg) {
            return
        }
        this.setState({
            isSavingLogoBg: true,
            hasUpdatedLogoBg: false,
            errorMessageLogoBg: '',
        })
        try {
            await this.props.updateLogoBgColor(this.props.practice.id, mapBgColorsToHex[this.state.selectedBgColor])
            this.setState({
                hasUpdatedLogoBg: true,
            })
        } catch (err) {
            this.setState({
                errorMessageLogoBg: 'Error saving logo background color.',
            })
        }
        this.setState({
            isSavingLogoBg: false,
        })
    }

    render() {
        const logoUrl = this.practiceLogoUrl
        const isLogoSet = !!logoUrl
        const isSaveLogoButtonDisabled =
            this.state.selectedBgColor === '' ||
            this.convertHexToBgColor((this.props.practice.logo?.bgColor as unknown) as Models.LogoBgColorOptions) ===
                this.state.selectedBgColor

        if (this.state.isFetchingLogo) {
            return (
                <div className="practice-logo-tab">
                    <div className="practice-logo-spinner">
                        <div className="practice-logo-spinner__overlay">
                            <CircularProgress className="practice-logo-spinner__circural-bar" size={50} />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <React.Fragment>
                <Grid container={true} spacing={0} className="practice-logo-preview">
                    <div className="practice-logo-tab">
                        {this.state.hasUpdatedLogoBg && (
                            <div className="practice-logo-updated-successfully">
                                <Icon className="info-payment-icon">check_circle</Icon>
                                <span>Updates to logo background have been saved.</span>
                            </div>
                        )}
                        {this.state.errorMessageLogoBg !== '' && (
                            <div className="practice-logo-error">
                                <Icon className="info-payment-icon">close</Icon>
                                <span>{this.state.errorMessageLogoBg}</span>
                            </div>
                        )}
                        <div className="practice-logo-section">
                            <h2 className="practice-logo-header">Practice Logo</h2>
                            {isLogoSet && <img height="200" src={logoUrl} alt="Practice Logo" />}
                            <span className="practice-logo-info-text">
                                {isLogoSet ? logoText.set : logoText.notSet}
                                <p className="practice-logo-tooltip">
                                    <CustomTooltip
                                        position="top-left"
                                        text={`Please upload .jpg, .jpeg or .png files, and no larger than 500x200px and ${MAX_LOGO_SIZE /
                                            1024}kb.`}
                                    >
                                        <Icon className="tooltip-icon">info</Icon>
                                    </CustomTooltip>
                                </p>
                            </span>

                            <div>
                                <Button className="practice-logo-option" onClick={this.openModal}>
                                    {isLogoSet ? 'Update' : 'Add'} Logo
                                </Button>
                            </div>
                        </div>
                        {isLogoSet && (
                            <div className="practice-logo-section">
                                <h3>Background Color for your logo:</h3>
                                <p className="practice-logo-bg-text">
                                    Please select a background theme that will provide enough contrast to make your logo
                                    clear and visible. Logo will appear larger than this in the portal, emails, and
                                    terminal.
                                </p>
                                <div className="practice-logo-bg-wrapper">
                                    <div className="practice-logo-bg-item">
                                        <div className="practice-logo-bg-top practice-logo-bg-top-dark">
                                            {isLogoSet && <img height="200" src={logoUrl} alt="Practice Logo" />}
                                            {!isLogoSet && <h2>LOGO</h2>}
                                        </div>
                                        <Button
                                            className={classNames('practice-logo-option', {
                                                'practice-logo-option--selected': this.state.selectedBgColor === 'dark',
                                            })}
                                            onClick={() =>
                                                this.setState({
                                                    selectedBgColor: 'dark',
                                                    hasUpdatedLogoBg: false,
                                                })
                                            }
                                        >
                                            Dark
                                        </Button>
                                    </div>
                                    <div className="practice-logo-bg-item">
                                        <div className="practice-logo-bg-top practice-logo-bg-top-neutral">
                                            {isLogoSet && <img height="200" src={logoUrl} alt="Practice Logo" />}
                                            {!isLogoSet && <h2>LOGO</h2>}
                                        </div>
                                        <Button
                                            className={classNames('practice-logo-option', {
                                                'practice-logo-option--selected':
                                                    this.state.selectedBgColor === 'neutral',
                                            })}
                                            onClick={() =>
                                                this.setState({
                                                    selectedBgColor: 'neutral',
                                                    hasUpdatedLogoBg: false,
                                                })
                                            }
                                        >
                                            Neutral
                                        </Button>
                                    </div>
                                    <div className="practice-logo-bg-item">
                                        <div className="practice-logo-bg-top practice-logo-bg-top-light">
                                            {isLogoSet && <img height="200" src={logoUrl} alt="Practice Logo" />}
                                            {!isLogoSet && <h2>LOGO</h2>}
                                        </div>
                                        <Button
                                            className={classNames('practice-logo-option', {
                                                'practice-logo-option--selected':
                                                    this.state.selectedBgColor === 'light',
                                            })}
                                            onClick={() =>
                                                this.setState({
                                                    selectedBgColor: 'light',
                                                    hasUpdatedLogoBg: false,
                                                })
                                            }
                                        >
                                            Light
                                        </Button>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={`save-logo-bg-button ${
                                            isSaveLogoButtonDisabled ? `save-logo-bg-button--disabled` : ``
                                        }`}
                                        disabled={isSaveLogoButtonDisabled}
                                        onClick={this.saveLogoBgChanges}
                                    >
                                        {!this.state.isSavingLogoBg && 'Save Changes'}
                                        {this.state.isSavingLogoBg && <CircularProgress size={30} />}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Grid>
                <Modal
                    open={this.state.showModal}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            this.onClose()
                        }
                    }}
                >
                    <div className="practice-logo-wrapper">
                        <Paper
                            className={`practice-logo ${
                                ['ERROR', 'MESSAGE'].includes(this.state.stage)
                                    ? 'practice-logo--message'
                                    : 'practice-logo--default'
                            }`}
                        >
                            <Grid container={true} spacing={0} className="practice-logo__modal-container">
                                <div className="practice-logo__close-button">
                                    <IconButton
                                        size="large"
                                        aria-label="Close"
                                        className="practice-logo__close-button-icon"
                                        onClick={() => this.onClose()}
                                    >
                                        <Icon>clear</Icon>
                                    </IconButton>
                                </div>

                                {this.renderInitial(this.state.stage === 'INITIAL')}
                                {this.renderModalContent()}
                            </Grid>
                        </Paper>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

export default PracticeLogoModal
