import * as React from 'react'

import WidgetButtonStyle from './WidgetButtonStyle'

import './WebCodeCustomizationWidgetButton.sass'

type Props = {
    customization: Api.WebCodeCustomization
    updateWidgetButtonStyle: (widgetButtonStyle: Api.widgetButtonStyle) => void
}

const moduleName = 'customize-widget-button'

const WebCodeCustomizationWidgetButton = (props: Props) => {
    const { customization, updateWidgetButtonStyle } = props
    const widgetButtonCustomization = customization.widget_button

    return (
        <div className={moduleName}>
            <WidgetButtonStyle
                customValue={widgetButtonCustomization && widgetButtonCustomization.widget_button_style}
                updateWidgetButtonStyle={updateWidgetButtonStyle}
            />
        </div>
    )
}

export default WebCodeCustomizationWidgetButton
