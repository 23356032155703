import ApiService from '../service'

export default class FeatureFlagsService extends ApiService {
    async getFeatureFlags(): Promise<ApiV2.StructuredResponse<ApiV2.FeatureFlags.FeatureFlags>> {
        const structuredResponse = (await this.fetch(`feature-flags`)) as ApiV2.StructuredResponse<
            ApiV2.FeatureFlags.FeatureFlags
        >

        return structuredResponse
    }
}
