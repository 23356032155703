import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import './ChatterAlertExpiryDateNotification.sass'

type ChatterAlertUpdateNotificationProps = {
    show: boolean | undefined
}

type Props = ChatterAlertUpdateNotificationProps

const moduleName = 'chatter-alert-expiry-date-notification'

class ChatterAlertUpdateNotification extends React.PureComponent<Props> {
    render() {
        const { show } = this.props

        return (
            <CSSTransition
                in={show}
                mountOnEnter={true}
                unmountOnExit={true}
                timeout={3000}
                classNames={classNames(`${moduleName}__transition-container`)}
            >
                <div>
                    <div className={classNames(`${moduleName}__container`)}>
                        <i className={classNames('material-icons', `${moduleName}__check-mark-icon`)}>check_circle</i>
                        <div>Updates to your chatter alert has been saved.</div>
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

export default ChatterAlertUpdateNotification
