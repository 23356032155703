import { useState } from 'react'
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { dateFormat } from 'modules/referrals/referral-form/ReferralForm'
import CustomDatepicker from 'modules/shared/custom-fields/CustomInlineDatepicker'
import { useAppDispatch } from 'util/useAppDispatch'
import { formatDobForApi } from 'modules/shared/dateUtils'
import {
    loadEmailOrPhoneData,
    getPayerData,
    saveNewEmailOrPhone,
    saveSwapTransaction,
    removePayerPaymentMethods,
} from './actions'
import CustomMultiselectField from 'modules/shared/custom-fields/CustomMultiselectField'

import './AdminToolsShared.sass'
import { FormControlLabel } from '@mui/material'

interface Props {
    onClose: () => void
    practiceId: string
}

const emailRegex = /.+\@.+\..+/

type PmArray = Array<{
    id: string
    brand: string
    last4: string
}>

const RemovePaymentMethod = (props: Props) => {
    const dispatch = useAppDispatch()

    const [screenIndex, setScreenIndex] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState('')
    const [currentEmail, setTheCurrentEmail] = useState('')
    const [currentPhone, setTheCurrentPhone] = useState('')

    const [removePmList, setRemovePmList] = useState<PmArray>([])
    const [isLoadingPaymentMethods, setisLoadingPaymentMethods] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        practiceId: string
        payerId: string
        name: string
        phone: string
        pmList: PmArray
    }>({
        practiceId: '',
        payerId: '',
        name: '',
        phone: '',
        pmList: [],
    })
    const [newEmail, setTheNewEmail] = useState('')
    const [isRemovingPms, setisRemovingPms] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const goBackScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadPaymentMethods = async () => {
        if (isLoadingPaymentMethods) {
            return
        }

        setisLoadingPaymentMethods(true)
        try {
            const payerData = await dispatch(getPayerData(props.practiceId, firstName, lastName, currentPhone))
            if (payerData.length === 0) {
                setErrorMessage('No matching payer found.')
                return
            }
            if (payerData[0].payer_id === null) {
                setErrorMessage(`The matching payer's id is blank, which is not allowed.`)
                return
            }
            setLoadedData({
                practiceId: props.practiceId,
                payerId: payerData[0].payer_id as string,
                name: `${firstName} ${lastName}`,
                phone: currentPhone,
                pmList: payerData.map(pm => ({
                    id: pm.token_id,
                    brand: pm.card_type,
                    last4: pm.last_4,
                })),
            })
            goForwardScreen()
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setisLoadingPaymentMethods(false)
    }

    const onRemovePaymentMethods = async () => {
        if (isRemovingPms) {
            return
        }
        setisRemovingPms(true)
        try {
            const removedData = await dispatch(removePayerPaymentMethods(removePmList.map(pm => pm.id)))
            goForwardScreen()
            setSuccessMessage(`Payment methods successfully removed.`)
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setisRemovingPms(false)
    }

    return (
        <DefaultModal
            title="Remove Payment Method"
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row admin-tools-row--narrow">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="First Name"
                                    inputType="text"
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div className="admin-tools-row admin-tools-row--narrow">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Last Name"
                                    inputType="text"
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="admin-tools-row admin-tools-row--narrow">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Phone #"
                                    inputType="text"
                                    value={currentPhone}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTheCurrentPhone(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingPaymentMethods,
                        disabled:
                            firstName === '' ||
                            lastName === '' ||
                            // dob === '' ||
                            // currentEmail === '' ||
                            currentPhone === '',
                        onClick: onLoadPaymentMethods,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <div className="admin-tools-details">
                                <p>
                                    Please select which Payment Method{loadedData.pmList.length === 1 ? '' : 's'} you
                                    would like to remove
                                </p>
                            </div>
                            <ul className="admin-tools-radio-checkbox-list">
                                {loadedData.pmList.map(pm => {
                                    const isChecked = removePmList.findIndex(pm2 => pm2.id === pm.id) > -1
                                    return (
                                        <li>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        className={`active-patient-radio-checkbox`}
                                                        onChange={(_1, checked) => {
                                                            if (checked) {
                                                                setRemovePmList([
                                                                    ...removePmList,
                                                                    {
                                                                        id: pm.id,
                                                                        brand: pm.brand,
                                                                        last4: pm.last4,
                                                                    },
                                                                ])
                                                            } else {
                                                                setRemovePmList(
                                                                    removePmList.filter(pm2 => pm2.id !== pm.id),
                                                                )
                                                            }
                                                        }}
                                                        checked={isChecked}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <span className="active-patient-radio-checkbox-label">
                                                        {pm.brand} ***** {pm.last4}
                                                    </span>
                                                }
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isRemovingPms,
                        disabled: removePmList.length === 0,
                        onClick: goForwardScreen,
                        text: 'Review Changes',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                You are about to remove the following payment method(s). This action cannot be undone.
                                Please confirm the removal(s) below.
                            </p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Patient Name:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {loadedData.name}
                                    </div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label admin-tools-details-label--wide">
                                        Remove payment method{removePmList.length === 1 ? '' : 's'}:
                                    </div>
                                    <div className="admin-tools-details-value admin-tools-details-value--wide">
                                        {removePmList.map(pm => {
                                            return (
                                                <div className="admin-tools-card-list admin-tools-capitalize">
                                                    {pm.brand} - {pm.last4}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isRemovingPms,
                        onClick: onRemovePaymentMethods,
                        text: `Confirm Removal${removePmList.length === 1 ? '' : 's'}`,
                    },
                },
                {
                    content: <div className="admin-tools-content admin-tools-content--loaded"></div>,
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default RemovePaymentMethod
