import React from 'react'

import { ArrowDropDown, ArrowDropUp } from '../../../shared/svgIcons'

export interface Props {
    onUp: () => void
    onDown: () => void
    value: string
}

const moduleName = 'time-input'

const TimeSelector = ({ onUp, onDown, value }: Props) => {
    return (
        <div className={moduleName}>
            <div className={`${moduleName}__text`}>{value}</div>
            <div>
                <div className={`${moduleName}__up`}>
                    <ArrowDropUp
                        className={`${moduleName}__icon ${moduleName}--up`}
                        onClick={onUp}
                        viewBox="0 -7 24 24"
                        size="24"
                    />
                </div>
                <div className={`${moduleName}__down`}>
                    <ArrowDropDown
                        className={`${moduleName}__icon time-input--down`}
                        onClick={onDown}
                        viewBox="0 7 24 24"
                        size="24"
                    />
                </div>
            </div>
        </div>
    )
}

TimeSelector.defaultProps = {
    value: '0',
}

export default TimeSelector
