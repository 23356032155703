import React from 'react'

import SelfSchedulingModel from '../../../../models/SelfScheduling'
import ColorPickerButton from '../../../shared/ColorPickerButton'

import './SelfSchedulingButtonColors.sass'

export type SelfSchedulingButtonColorsProps = {
    colors: Models.SelfSchedulingButtonColors
    resetColors: (e: React.MouseEvent<HTMLElement>) => void
    updateCustomColor: (colorKey: Api.SelfScheduling.SchedulingButtonColorTypes, colorValue: string) => void
}

const moduleName = 'self-scheduling-button-colors'

function SelfSchedulingButtonColors(props: SelfSchedulingButtonColorsProps) {
    const { resetColors, updateCustomColor } = props

    const colors = {
        ...SelfSchedulingModel.defaultButtonColors,
        ...props.colors,
    }

    function updateColor(type: Models.SelfSchedulingButtonColorTypes) {
        return (color: string) => {
            updateCustomColor(type, color)
        }
    }

    return (
        <div>
            <h3 className="webcode-section-title">Colors:</h3>
            <ColorPickerButton
                id="background-color"
                key="background-color"
                color={colors.background}
                label="Background"
                updateCustomColor={updateColor('background')}
            />
            <ColorPickerButton
                id="stroke-color"
                key="stroke-color"
                color={colors.stroke}
                label="Stroke"
                updateCustomColor={updateColor('stroke')}
            />
            <ColorPickerButton
                id="text-color"
                key="text-color"
                color={colors.text}
                label="Text"
                updateCustomColor={updateColor('text')}
            />
            <a onClick={resetColors} className={`${moduleName}__reset-colors`} href="/#">
                Reset colors to default
            </a>
        </div>
    )
}

export default SelfSchedulingButtonColors
