import download from 'downloadjs'

import Api from '../../Api'

export type ReceiveAgents = {
    type: 'RECEIVE_PRACTICE_DEXVOICE_AGENTS'
    practice: Models.Practice
    agents: Array<Api.DexisAgent>
}

export type ReceiveCommands = {
    type: 'RECEIVE_PRACTICE_DEXVOICE_AGENTS_COMMANDS'
    practice: Models.Practice
    commands: Array<Api.DexisAgentCommand>
}

export type ReceiveErrors = {
    type: 'RECEIVE_PRACTICE_DEXVOICE_AGENTS_ERRORS'
    practice: Models.Practice
    errors: Array<Api.DexisAgentError>
}

export type ReceiveLicenses = {
    type: 'RECEIVE_PRACTICE_DEXVOICE_LICENSES'
    practice: Models.Practice
    licenses: Array<Api.DexisLicense>
}

export function receivePracticeAgents(practice: Models.Practice, agents: Array<Api.DexisAgent>): ReceiveAgents {
    return {
        type: 'RECEIVE_PRACTICE_DEXVOICE_AGENTS',
        practice,
        agents,
    }
}

export function receivePracticeAgentsCommands(
    practice: Models.Practice,
    commands: Array<Api.DexisAgentCommand>,
): ReceiveCommands {
    return {
        type: 'RECEIVE_PRACTICE_DEXVOICE_AGENTS_COMMANDS',
        practice,
        commands,
    }
}

export function receivePracticeAgentsErrors(
    practice: Models.Practice,
    errors: Array<Api.DexisAgentError>,
): ReceiveErrors {
    return {
        type: 'RECEIVE_PRACTICE_DEXVOICE_AGENTS_ERRORS',
        practice,
        errors,
    }
}

export function receivePracticeLicenses(practice: Models.Practice, licenses: Array<Api.DexisLicense>): ReceiveLicenses {
    return {
        type: 'RECEIVE_PRACTICE_DEXVOICE_LICENSES',
        practice,
        licenses,
    }
}

export function fetchPracticeAgentsCommands(practice: Models.Practice, start: Date, end: Date): any {
    return async (dispatch: any) => {
        const commands = await Api.Dexis.listCommands(practice, start, end)
        await dispatch(receivePracticeAgentsCommands(practice, commands))
    }
}

export function fetchPracticeAgentsErrors(practice: Models.Practice, start: Date, end: Date): any {
    return async (dispatch: any) => {
        const errors = await Api.Dexis.listErrors(practice, start, end)
        await dispatch(receivePracticeAgentsErrors(practice, errors))
    }
}

export function fetchAgents(practice: Models.Practice, start?: Date, end?: Date): any {
    return async (dispatch: any) => {
        const agents = await Api.Dexis.list(practice)
        await dispatch(receivePracticeAgents(practice, agents))

        if (start && end) {
            await Promise.all([
                dispatch(fetchPracticeAgentsCommands(practice, start, end)),
                dispatch(fetchPracticeAgentsErrors(practice, start, end)),
            ])
        }
    }
}

export function fetchDexVoiceLicenses(practice: Models.Practice): any {
    return async (dispatch: any) => {
        const licenses = await Api.Dexis.listLicenseAgreements(practice)
        await dispatch(receivePracticeLicenses(practice, licenses))
    }
}

export function downloadLicense(practice: Models.Practice, license: Models.DexisLicense): any {
    return async (dispatch: any) => {
        const { blob, filename } = await Api.DexVoiceDownload.downloadLicense(practice, license.id)
        download(blob, filename || `${practice.name} - License Agreement.pdf`, 'application/pdf')
    }
}
