import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import './AdminSuccessBox.sass'

export type AdminSuccessBoxType = 'resetPassword' | 'unlockPassword'

export type AdminConfrimationBoxProps = {
    type?: AdminSuccessBoxType
    name?: string
    username?: string
}

export type AdminConfrimationBoxDispatch = {
    closeFn: () => void
}

export interface AdminSuccessBoxParams {
    type: AdminSuccessBoxType
}

type Props = AdminConfrimationBoxProps & AdminConfrimationBoxDispatch

const AdminSuccessBox = ({ name, username, type, closeFn }: Props) => {
    const buildText = () => {
        switch (type) {
            case 'resetPassword':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons green">check_circle</i>
                            </Grid>
                            <Grid className="text">”A password reset link has been sent to {username}”</Grid>
                        </Grid>
                    </>
                )
            case 'unlockPassword':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons green">check_circle</i>
                            </Grid>
                            <Grid className="text">The {name}'s account has been unlocked.</Grid>
                        </Grid>
                    </>
                )

            default:
                return null
        }
    }

    return (
        <div className="confirmation-container">
            <div className="confirmation-inner-container">
                {buildText()}
                <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                    <Grid item={true}>
                        <Button variant="outlined" onClick={closeFn}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default AdminSuccessBox
