import iassign from 'immutable-assign'
import _ from 'lodash'

import { SetRedirect } from '../../appActions'
import { ReceiveAdmin, ReceiveAdminPermissions, ReceiveAdmins } from '../admin/actions'
import { CreatePractice, ReceivePractice, ReceivePractices } from '../practices/actions'

type SearchableBlobs = {
    [key: string]: {
        id: string
        suggestion: string
        blob: string
    }
}

type NavigationLists = {
    admins: SearchList
    diagnostics: SearchList
    practices: SearchList
    chatCenter: SearchList
    referrals: SearchList
    heartbeat: SearchList
    yext: SearchList
}

export type SelectedList = keyof NavigationLists

export type SearchList = {
    list: Array<string>
    listName: SelectedList
    rawBlobs: SearchableBlobs
}

export type NavigationState = NavigationLists & {
    selectedList: SelectedList
}

function filterList(state: SearchList): Array<string> {
    return _.map(state.rawBlobs, p => p.id)
}

type NavigationAction =
    | SetRedirect
    | CreatePractice
    | ReceivePractice
    | ReceivePractices
    | ReceiveAdmin
    | ReceiveAdmins
    | ReceiveAdminPermissions

const getSelectedList = (pathname: string): SelectedList => {
    if (pathname.startsWith('/admins')) {
        return 'admins'
    }

    if (pathname.startsWith('/diagnostics')) {
        return 'diagnostics'
    }

    if (pathname.startsWith('/practice')) {
        return 'practices'
    }

    if (pathname.startsWith('/chat-center')) {
        return 'chatCenter'
    }

    if (pathname.startsWith('/referrals')) {
        return 'referrals'
    }

    if (pathname.startsWith('/heartbeat')) {
        return 'heartbeat'
    }

    return 'practices'
}

const initList = (name: SelectedList): SearchList => ({
    list: [],
    listName: name,
    rawBlobs: {},
})

const initialState = (): NavigationState => ({
    admins: initList('admins'),
    diagnostics: initList('diagnostics'),
    practices: initList('practices'),
    chatCenter: initList('chatCenter'),
    referrals: initList('referrals'),
    heartbeat: initList('heartbeat'),
    yext: initList('yext'),
    selectedList: getSelectedList(window.location.pathname),
})

export function reducer(state: NavigationState = initialState(), action: NavigationAction) {
    switch (action.type) {
        case 'SET_REDIRECT': {
            if (action.path === '') {
                return state
            }
            return iassign(state, next => {
                next.selectedList = getSelectedList(action.path)
                return next
            })
        }
        case 'RECEIVE_PRACTICES': {
            return iassign(
                state,
                next => next.practices,
                next => {
                    next.rawBlobs = _.mapValues(action.practices, (p: any) => ({
                        id: p.id,
                        suggestion: p.name,
                        blob: JSON.stringify(p).toLowerCase(),
                    })) as any

                    next.list = filterList(next)
                    return next
                },
            )
        }
        case 'RECEIVE_PRACTICE': {
            return iassign(
                state,
                next => next.practices,
                next => {
                    next.rawBlobs[action.practice.id] = {
                        id: action.practice.id,
                        suggestion: action.practice.name,
                        blob: JSON.stringify(action.practice).toLowerCase(),
                    }
                    return next
                },
            )
        }
        case 'CREATE_PRACTICE': {
            return iassign(
                state,
                next => next.practices,
                next => {
                    next.list.unshift(action.practice.id)
                    return next
                },
            )
        }
        case 'RECEIVE_ADMINS': {
            return iassign(
                state,
                next => next.admins,
                next => {
                    next.rawBlobs = _.mapValues(action.admins, (a: any) => ({
                        id: a.id,
                        suggestion: a.username,
                        blob: JSON.stringify(a).toLowerCase(),
                    })) as any

                    next.list = filterList(next)
                    return next
                },
            )
        }
        case 'RECEIVE_ADMIN': {
            return iassign(
                state,
                next => next.admins,
                next => {
                    next.rawBlobs[action.admin.id] = {
                        id: action.admin.id,
                        suggestion: action.admin.username,
                        blob: JSON.stringify(action.admin).toLowerCase(),
                    }
                    return next
                },
            )
        }
        default:
            return state
    }
}
