import * as React from 'react'
import Switch, { SwitchClassKey } from '@mui/material/Switch'

import './EnableProviderSelectionToggle.sass'

export type EnableProviderSelectionToggleProps = {
    enabled?: boolean
    updateEnableProviderSelection: (enabled: boolean) => void
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const moduleName = 'enable-provider-selection-toggle'

const EnableProviderSelectionToggle = (props: EnableProviderSelectionToggleProps) => {
    const updateEnableProviderSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target

        props.updateEnableProviderSelection(checked)
    }

    const { enabled } = props
    const value = enabled || false
    const SwitchClassOverrides: SwitchClassOverrides = {
        root: `${moduleName}__switch-root`,
    }

    return (
        <div className={moduleName}>
            <div className={`${moduleName}__title`}>Enable Provider Selection:</div>
            <div className={`${moduleName}__switch-wrapper`}>
                <Switch
                    checked={value}
                    onChange={updateEnableProviderSelection}
                    name="switch-enable-provider-selection"
                    value="switch"
                    color="primary"
                    classes={SwitchClassOverrides}
                />
            </div>
        </div>
    )
}

export default EnableProviderSelectionToggle
