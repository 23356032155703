import { mapLocationConnectPaymentInfo } from '../../Api/mappers/connect'
import { mapProcedures, mapRestoredAccount } from '../mappers'
import ApiService from '../service'

const API_PAYMENTS_HOST = process.env.REACT_APP_API_PAYMENTS_HOST

export default class PracticesService extends ApiService {
    readonly ModulePath = 'api'
    private readonly PAGE_SIZE = 20

    async get(practiceId: string) {
        const practices = (await this.fetch(
            `1/admin/practices/${practiceId}`,
            {},
            {
                includeStaff: 'false',
            },
        )) as Api.Practice
        return practices
    }

    async list(searchTerms: Api.Amplify.PracticesSearchTerms = {}): Promise<Api.StructuredResponse<Api.Practice[]>> {
        const { limit = this.PAGE_SIZE, page = 1, searchKey, startDate } = searchTerms
        const structuredResponse = (await this.fetch(
            'admin/practices/v1/practices',
            {},
            {
                limit,
                page,
                searchKey,
                startDate,
            },
        )) as Api.StructuredResponse<Api.Practice[]>

        return structuredResponse
    }

    async postCreate(practice: Api.CreatePractice) {
        const created = (await this.fetch(`1/registration/practice`, {
            method: 'POST',
            body: JSON.stringify(practice),
        })) as Api.Practice
        return created
    }

    async putSave(practice: Models.Practice, updates: Api.UpdatePractice) {
        const saved = (await this.fetch(`1/admin/practices/${practice.id}`, {
            method: 'PUT',
            body: JSON.stringify({ updates }),
        })) as Api.Practice
        return saved
    }

    async putSaveProduct(practice: Models.Practice, productsData: Api.SaveProductsData) {
        const products = (await this.fetch(`1/admin/practices/${practice.id}/products`, {
            method: 'PUT',
            body: JSON.stringify(productsData),
        })) as Array<Api.Product>
        return products
    }

    async postResendInvitation(invitation: Api.InviteResend) {
        await this.fetch(`practice/v1/invites/resend`, {
            method: 'POST',
            body: JSON.stringify({ ...invitation }),
        })
    }

    async postCreateAccount(newAccount: Api.AccountCreate) {
        const { data } = await this.fetch(`practice/v1/users`, {
            method: 'POST',
            body: JSON.stringify({ ...newAccount }),
        })

        return data as Api.Account
    }

    async putSaveAccount(account: ModelsV2.Practice.PracticeStaffData, updates: Api.AccountUpdate) {
        const { data } = await this.fetch(`practice/v1/users/${account.id}`, {
            method: 'PUT',
            body: JSON.stringify({ ...updates }),
        })

        return data as Api.Account
    }

    async postRestoreAccount(account: ModelsV2.Practice.PracticeStaffData) {
        const { data } = await this.fetch(`practice/v1/users/restore`, {
            method: 'POST',
            body: JSON.stringify({ accountId: account.id }),
        })

        return mapRestoredAccount(data) as Api.Account
    }

    async postResetPassword(resetData: Api.ResetPassword) {
        await this.fetch(`practice/v1/users/password-reset`, {
            method: 'POST',
            body: JSON.stringify({ ...resetData }),
        })
    }

    // async listSchedulingLocations(practice: Models.Practice) {
    //     const { data } = await this.fetch(`admin/scheduling/v1/practice/${practice.id}/locations`)
    //     return data
    // }

    async listPracticeLocationProcedures(locationId: string, practice: Models.Practice) {
        const { data } = await this.fetch(`admin/scheduling/v1/location/${locationId}/procedure`)
        return mapProcedures(data, practice.id)
    }

    async listProcedureProviders(locationId: string, practice: Models.Practice, procedure: Models.Procedure) {
        const { data } = await this.fetch(
            `admin/scheduling/v1/location/${locationId}/procedure/${procedure.id}/provider`,
        )
        return data
    }

    async putSaveLocation(
        practice: Models.Practice,
        location: Models.PracticeLocation,
        updates: Api.UpdateLocation,
    ): Promise<Api.PracticeLocation> {
        const updated = await this.fetch(`1/admin/practices/${practice.id}/locations/${location.id}`, {
            method: 'PUT',
            body: JSON.stringify({ updates }),
        })
        return updated
    }

    async putDeleteLocation(practice: Models.Practice, location: Models.PracticeLocation) {
        const deleted = await this.fetch(`1/admin/practices/${practice.id}/locations/${location.id}`, {
            method: 'DELETE',
        })
        return deleted
    }

    async listProcedureAvailability(location: Models.PracticeLocation, procedure: Models.Procedure, date: string) {
        const { data } = await this.fetch(
            `admin/scheduling/v1/location/${location.id}/procedure/${procedure.id}/date/${date}/availability`,
        )
        return data
    }

    async listAvailability(
        location: Models.PracticeLocation,
        procedure: Models.Procedure,
        startDate: string,
        endDate: string,
        providerId?: string,
    ) {
        const { data } = await this.fetch(
            `admin/scheduling/v1/location/${location.id}/procedure/${procedure.id}/availability`,
            {},
            {
                from: startDate,
                to: endDate,
                providerId,
            },
        )
        return data
    }

    async getPracticeLogo(practice: Models.Practice) {
        const logo = (await this.fetch(`admin/practices/v1/${practice.id}/logo`)) as Api.StructuredResponse<
            Api.PracticeLogo
        >
        return logo
    }

    async setPracticeLogo(practice: Models.Practice, upload: Models.Upload) {
        const logo = (await this.fetch(`admin/practices/v1/${practice.id}/logo`, {
            method: 'POST',
            body: JSON.stringify({
                fileUploadId: upload.id,
            }),
        })) as Api.StructuredResponse<Api.PracticeLogo>
        return logo
    }

    async putDeleteLogo(practice: Models.Practice) {
        const deleted = await this.fetch(`admin/practices/v1/${practice.id}/logo`, {
            method: 'DELETE',
        })
        return deleted
    }

    async postImpersonateUser(employeeId: string) {
        await this.fetch('1/login/impersonation', {
            method: 'POST',
            body: JSON.stringify({ userId: employeeId }),
        })

        return `${process.env.REACT_APP_DASHBOARD_HOST}/login`
    }

    async getSearchByEmail(email: string) {
        const practices = (await this.fetch(
            '1/admin/practices/search/email',
            {},
            {
                q: encodeURIComponent(email),
            },
        )) as string[]
        return practices
    }

    async listPracticeAgents(practice: Models.Practice) {
        const agents = (await this.fetch(`1/admin/agents`, {}, { practiceId: practice.id })) as Array<Api.PracticeAgent>
        return agents
    }

    async listUnboundPracticeAgents() {
        const agents = (await this.fetch(`1/admin/agents`, {}, { active: true, unbound: true })) as Array<
            Api.PracticeAgent
        >
        return agents
    }

    async unbindAgent(agent: Models.PracticeAgent) {
        const unboundAgent = (await this.fetch(`admin/agents/${agent.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                practiceId: null,
                locationId: null,
                sendData: false,
            }),
        })) as Api.PracticeAgent
        return unboundAgent
    }

    async savePracticeAgent(agent: string | Models.PracticeAgent, update: Api.PracticeAgentUpdate) {
        const agentId = typeof agent === 'string' ? agent : agent.id
        const updatedAgent = (await this.fetch(`admin/agents/${agentId}`, {
            method: 'PUT',
            body: JSON.stringify(update),
        })) as Api.PracticeAgent
        return updatedAgent
    }

    async getAgentVitals(practice: Models.Practice) {
        const vitals = (await this.fetch(`1/admin/practices/${practice.id}/vitals`)) as Api.PracticeFlowVitals
        return vitals
    }

    async listAvailablePracticeSpecialties(): Promise<Api.StructuredResponse<Api.PracticeSpecialty[]>> {
        const specilaties = await this.fetch(`admin/practices/v1/practiceSpecialties`)
        return specilaties
    }

    async listAvailablePracticeUserRoles(): Promise<Api.StructuredResponse<Api.PracticeUserRole[]>> {
        const roles = await this.fetch(`practice/v1/users/roles`)
        return roles
    }

    async hasEmailNotificationSubscribers(
        practice: Models.Practice,
    ): Promise<Api.PracticeHasEmailNotificationSubscribers> {
        const hasEmailNotificationSubscribers = await this.fetch(
            `admin/practices/v1/${practice.id}/hasEmailNotificationSubscribers`,
        )
        return hasEmailNotificationSubscribers
    }

    async getLocationSchedulingData(location: Models.PracticeLocation): Promise<Api.SchedulingPracticeLocation> {
        const { data } = await this.silentFetch({
            endpoint: `admin/scheduling/v1/location/${location.id}`,
        })
        return data
    }

    async getLocationConnectPaymentInfoData(
        locationId: string,
    ): Promise<Models.LocationConnectPaymentInfo | undefined> {
        const { data } = await this.silentFetch({
            endpoint: `admin/connect/v1/payment-information/${locationId}`,
        })
        return data ? mapLocationConnectPaymentInfo(data) : undefined
    }

    async syncLocationSchedulingData(location: Models.PracticeLocation): Promise<void> {
        await this.fetch(`admin/scheduling/v1/location/${location.id}/jarvis/location/sync`)
    }

    async saveLocationScheduling(
        location: Models.PracticeLocation,
        updatesScheduling: Api.OptionalLocationScheduling,
    ): Promise<Api.LocationScheduling> {
        const { data } = await this.fetch(`admin/scheduling/v1/location/${location.id}/jarvis/location`, {
            method: 'PUT',
            body: updatesScheduling ? JSON.stringify(updatesScheduling) : null,
        })
        return data
    }

    async postCreateLocationConnectPaymentInfo(
        locationId: string,
        createConnectPaymentInfo: Api.CreateLocationConnectPaymentInfo,
    ): Promise<Models.LocationConnectPaymentInfo> {
        const { data } = await this.fetch(`admin/connect/v1/payment-information`, {
            method: 'POST',
            body: createConnectPaymentInfo ? JSON.stringify(createConnectPaymentInfo) : null,
        })
        return mapLocationConnectPaymentInfo(data)
    }

    async putSaveLocationConnectPaymentInfo(
        locationId: string,
        updatesConnectPaymentInfo: Api.UpdateLocationConnectPaymentInfo,
    ): Promise<Models.LocationConnectPaymentInfo> {
        const { data } = await this.fetch(`admin/connect/v1/payment-information/${locationId}`, {
            method: 'PUT',
            body: updatesConnectPaymentInfo ? JSON.stringify(updatesConnectPaymentInfo) : null,
        })
        return mapLocationConnectPaymentInfo(data)
    }

    async validatePhoneNumber(phoneNumber: string): Promise<{ isValid: boolean; message: string }> {
        const {
            data: { isValid, message },
        } = await this.fetch(`practice/v1/users/validate-phone-number/${phoneNumber}`)
        return { isValid, message }
    }

    async loadPhoneNumberBlackList(accountId: string): Promise<Api.StructuredResponse<string[]>> {
        return await this.fetch(`practice/v1/users/${accountId}/phone-number-black-list`)
    }

    async getMilestoneData(practiceId: string, locationId: string): Promise<Models.LocationMilestoneData> {
        const location = await this.fetch(
            `v1/practices/${practiceId}/locations/${locationId}/milestones`,
            {
                method: 'GET',
            },
            {},
            API_PAYMENTS_HOST,
        )
        return location.data
    }

    async getAllPaymentsStatusData(practiceId: string): Promise<Api.CompletionStatusResponse> {
        const locations = await this.fetch(
            `v1/practices/${practiceId}/locations/milestones`,
            {
                method: 'GET',
            },
            {},
            API_PAYMENTS_HOST,
        )
        return locations?.data || []
    }

    async putTrainingComplete(
        practiceId: string,
        locationId: string,
        isComplete: boolean,
    ): Promise<Models.LocationMilestoneData> {
        const location = await this.fetch(
            `v1/practices/${practiceId}/locations/${locationId}/milestones`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    trainingComplete: isComplete,
                    firstPaymentComplete: false,
                }),
            },
            {},
            API_PAYMENTS_HOST,
        )
        return location.data
    }
}
