import moment from 'moment'

export const FILE_MAX_SIZE = 1024 * 1024 * 1024
export const FILE_CHUNK_SIZE = 5 * 1024 * 1024
export const FILE_PARALLEL_PARTS = 3
export const FILE_RETRY_FOREVER_DEFAULT = false
export const FILE_MAX_RETRIES = 10
export const FILE_BACKOFF_WAIT_DEFAULT = moment.duration(5, 'seconds').asMilliseconds()
export const FILE_BACKOFF_MAX_WAIT_DURATION = moment.duration(1, 'minutes').asMilliseconds()
export const FILE_BACKOFF_DURATION_SCALE = 2 // double time between backoffs

export const LOCAL_STORAGE_KEY_CHATS_ON_SCREEN = '@chat-center/chats-on-screen'

export const Constants = {
    FILE_MAX_SIZE,
    FILE_CHUNK_SIZE,
    FILE_PARALLEL_PARTS,
    FILE_RETRY_FOREVER_DEFAULT,
    FILE_MAX_RETRIES,
    FILE_BACKOFF_WAIT_DEFAULT,
    FILE_BACKOFF_MAX_WAIT_DURATION,
    FILE_BACKOFF_DURATION_SCALE,
    LOCAL_STORAGE_KEY_CHATS_ON_SCREEN,
}

export default Constants
