import React from 'react'

import { SurveySectionQuestion } from '../../../../../../models/PracticeSurvey'
import ProceduresView from '../../procedures/ProceduresView'

import './SelectMulti.sass'

interface Props {
    question: SurveySectionQuestion
}

const SelectMultiViewTabs = ({ question }: Props) => {
    return <ProceduresView question={question} />
}

export default SelectMultiViewTabs
