import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import {
    surveyFormatConnectHours,
    surveyFormatDoctors,
    surveyFormatLocationInformation,
    surveyFormatOperatingHours,
    surveyFormatSurveySections,
} from 'ApiV2/mappers/survey/surveyUnmap'
import Fuse from 'fuse.js'
import _ from 'lodash'
import { fetchPracticeWithLocations } from 'modules/practices/actions'
import { fetchLocationsByWebcode } from 'modules/practices/v2actions'
import CustomMultiselectField from 'modules/shared/custom-fields/CustomMultiselectField'

import { RootState } from '../../../appReducer'
import { CustomFieldType } from '../../../models/enums'
import { moveDefaultLocationToTop } from '../../../modules/shared/sortUtils'
import { useAsync } from '../../../util/useAsync'
import { fetchAmplifyWebcodeConnectSurvey, fetchWebCodes } from '../../amplify/actions'
import * as actions from '../../practices/locations/edit-survey/actions'
import ButtonGroupSelector from '../../shared/custom-fields/ButtonGroupSelector'
import CustomField from '../../shared/custom-fields/CustomField'
import { usePrevious } from '../../shared/custom-hooks'

import Alert from './Alert'
import SurveyMonkeyComponent from './SurveyMonkeyComponent'
import SurveyResponse from './SurveyResponse'

import './SurveyComponentV2.sass'

export type SurveyComponentProps = {
    practiceId: string
    webCodeId: string
    isReferralModal?: boolean
    locationId?: string
    referralStatus?: string
}
type surveyType = 'simplifeye' | 'surveyMonkey'
const FUSE_OPTIONS = {
    threshold: 0.6,
    maxPatternLength: 24,
    minMatchCharLength: 1,
    keys: ['question'],
}

const moduleName = 'survey-component'

const SurveyComponentV2 = ({
    practiceId,
    webCodeId: propsWebCodeId,
    isReferralModal,
    locationId,
    referralStatus,
}: SurveyComponentProps) => {
    const [loading, setLoading] = useState(true)
    const [surveySwitch, setSurveySwitch] = useState<surveyType>('simplifeye')
    const [surveySearchQuery, setSurveySearchQuery] = useState('')
    const [selectedLocation, setSelectedLocation] = useState<Models.PracticeLocation>()

    // survey metrics
    const [locationsWithCompletedSurveys, setLocationsWithCompletedSurveys] = useState<Models.PracticeLocation[]>([])
    const [hasSurveyMonkeySurvey, setHasSurveyMonkey] = useState(false)
    const [hasSimplifeyeSurvey, setHasSimplifeyeSurvey] = useState(false)
    const [allLocationsHaveCompletedSimplifeyeSurvey, setAllLocationsHaveCompletedSimplifeyeSurvey] = useState(false)

    const dispatch = useDispatch()

    const { run: runPractice, status: statusRunPractice } = useAsync()
    const { run: runWebCodes, status: statusRunWebCodes } = useAsync()
    const { run: runWebCodeLocations, status: statusRunWebCodeLocations } = useAsync()
    const { run: runDoctors, status: statusDoctors } = useAsync()
    const { run: runHours, status: statusHours } = useAsync()
    const { run: runLocationInformation, status: statusLocationInformation } = useAsync()
    const { run: runSurveys, status: statusSurveys } = useAsync()
    const { run: runAmplifyWebcodeConnectSurvey, status: statusAmplifyWebcodeConnectSurvey } = useAsync()

    const practiceV1 = useSelector((state: RootState) => state.practices?.practices[practiceId])
    const practiceLocationsLength = practiceV1?.locations?.length
    const webCodesList = useSelector((state: RootState) => state.amplify.webCodes[practiceId])
    const webCodeByLocationId = webCodesList?.find(
        w =>
            w.practiceLocationIds?.find(l => l.practiceLocationId === locationId && l.enabled)?.practiceLocationId ===
            locationId,
    )

    const webCodeId = propsWebCodeId ? propsWebCodeId : webCodeByLocationId?.id || ''
    const practiceLocationsByWebCode = useSelector(
        (state: RootState) => state.v2.practices.practiceLocationsByWebCode[webCodeId],
    )

    const webCode = webCodesList?.find(webCode => webCode.id === webCodeId)
    const locationSurveys = useSelector((state: RootState) => state.practiceSurvey)
    const locationSurvey = selectedLocation && locationSurveys[selectedLocation.id]
    const connectSurveyId = webCode?.connectSurveyId

    const prevPracticeV1 = usePrevious(practiceV1) || null

    // resource: If there is webCode present uses webcode linked locations, in oposite uses just practice locations
    // returns: list of enabled location with completed survey
    const enabledLocationsWithSurveys = useCallback(() => {
        const enabledPracticeLocationsIdsByWebCode = practiceLocationsByWebCode
            ?.filter(loc => loc.enabled)
            ?.map(loc => loc.practice_location_id)

        // completed referrals list all practice locations - not only linked webcode locations
        if (
            (Boolean(referralStatus) &&
                ['new', 'scheduled', 'follow_up', 'unsuccessful'].includes(referralStatus || '')) ||
            !Boolean(webCodeId)
        ) {
            const locations = practiceV1?.locations?.filter(loc => loc.survey?.status === 'completed')
            return _.sortBy(locations, location => location.name.toLowerCase())
        }

        if (webCodeId) {
            const locations = practiceV1?.locations?.filter(
                loc => enabledPracticeLocationsIdsByWebCode?.includes(loc.id) && loc.survey?.status === 'completed',
            )
            return _.sortBy(locations, location => location.name.toLowerCase())
        }

        return []
    }, [practiceLocationsByWebCode, practiceV1, referralStatus, webCodeId])

    const onLocationChange = (value: string[]) => {
        const locationId = value[0]
        const selectedLocation = practiceV1.locations.find(location => location.id === locationId)
        if (selectedLocation) {
            setSelectedLocation(selectedLocation)
        }
    }

    const setLocationsData = useCallback(() => {
        const locationsWithSurveys = enabledLocationsWithSurveys()
        setLocationsWithCompletedSurveys(locationsWithSurveys)
        setHasSimplifeyeSurvey(locationsWithSurveys.length > 0)

        if (locationsWithSurveys?.length === 1 && !selectedLocation?.id) {
            setSelectedLocation(locationsWithSurveys[0])
        }

        if (webCodeId) {
            const hasSurveyMonkey = Boolean(webCode?.surveyMonkeyResponseId)
            setHasSurveyMonkey(hasSurveyMonkey)

            const allWebCodeEnabledLocations = practiceLocationsByWebCode?.filter(loc => loc.enabled)
            if (allWebCodeEnabledLocations?.length > 0) {
                setAllLocationsHaveCompletedSimplifeyeSurvey(
                    locationsWithSurveys.length === allWebCodeEnabledLocations.length,
                )
            } else {
                setAllLocationsHaveCompletedSimplifeyeSurvey(false)
            }

            // show only surveyMonkey survey by defauly only when there is 0 simplifeye surveys finished
            if (hasSurveyMonkey && locationsWithSurveys.length < 1) {
                setSurveySwitch('surveyMonkey')
            }
        } else {
            setAllLocationsHaveCompletedSimplifeyeSurvey(locationsWithSurveys.length === practiceLocationsLength)
            setHasSurveyMonkey(false) // no web-code no survey monkey
            setSurveySwitch('simplifeye')
        }
    }, [enabledLocationsWithSurveys, practiceLocationsByWebCode, practiceLocationsLength, webCode, webCodeId])

    useEffect(() => {
        if (practiceId) {
            runPractice(dispatch(fetchPracticeWithLocations(practiceId)))
        }
    }, [dispatch, practiceId, runPractice])

    useEffect(() => {
        if (webCodeId) {
            runWebCodeLocations(dispatch(fetchLocationsByWebcode(webCodeId)))
        }
    }, [dispatch, runWebCodeLocations, webCodeId])

    useEffect(() => {
        if (practiceV1 && prevPracticeV1?.id !== practiceV1.id) {
            runWebCodes(dispatch(fetchWebCodes(practiceV1.id)))
        }
    }, [dispatch, practiceV1, prevPracticeV1, runWebCodes])

    useEffect(() => {
        if (locationId) {
            const locationWithSimplifeyeSurvey = locationsWithCompletedSurveys.find(loc => loc.id === locationId)
            if (locationWithSimplifeyeSurvey) {
                setSurveySwitch('simplifeye')
                setSelectedLocation(locationWithSimplifeyeSurvey)
                setHasSimplifeyeSurvey(true)
                setHasSurveyMonkey(false)
            } else if (Boolean(webCode?.surveyMonkeyResponseId)) {
                setSurveySwitch('surveyMonkey')
                setHasSimplifeyeSurvey(false)
                setHasSurveyMonkey(true)
            } else {
                setSurveySwitch('simplifeye')
                setSelectedLocation({} as Models.PracticeLocation)
                setHasSimplifeyeSurvey(false)
                setHasSurveyMonkey(false)
            }
        }
    }, [locationId, locationsWithCompletedSurveys, webCode])

    useEffect(() => {
        if (
            Boolean(webCodeId) &&
            [statusRunPractice, statusRunWebCodes, statusRunWebCodeLocations].every(status => status === 'resolved')
        ) {
            setLoading(false)
            setLocationsData()
        }

        if (!Boolean(webCodeId) && statusRunPractice === 'resolved') {
            setLoading(false)
            setLocationsData()
        }
    }, [statusRunPractice, statusRunWebCodes, statusRunWebCodeLocations, webCodeId, setLocationsData])

    // Load  simplifeye survey
    useEffect(() => {
        if (selectedLocation && selectedLocation?.survey?.id) {
            dispatch(actions.setLocationState(selectedLocation.id, 'pending'))
            const promises = [
                runDoctors(dispatch(actions.fetchLocationSurveyDoctors(selectedLocation.id))),
                runLocationInformation(
                    dispatch(
                        actions.fetchLocationSurveyLocationInformation(selectedLocation, selectedLocation.addressId),
                    ),
                ),
                runHours(dispatch(actions.fetchLocationSurveyHours(selectedLocation.id))),
                runSurveys(dispatch(actions.fetchLocationSurveys(selectedLocation.id, selectedLocation.survey.id))),
            ]
            if (practiceId && connectSurveyId && webCodeId) {
                promises.push(
                    runAmplifyWebcodeConnectSurvey(
                        dispatch(fetchAmplifyWebcodeConnectSurvey(practiceId, webCodeId, connectSurveyId)),
                    ),
                )
            }
            Promise.all(promises)
                .then(() => {
                    dispatch(actions.setLocationState(selectedLocation.id, 'success'))
                })
                .catch(() => {
                    dispatch(actions.setLocationState(selectedLocation.id, 'error'))
                })
        }
    }, [
        runAmplifyWebcodeConnectSurvey,
        runDoctors,
        runHours,
        runLocationInformation,
        runSurveys,
        dispatch,
        selectedLocation,
        practiceId,
        connectSurveyId,
        webCodeId,
    ])

    const renderSurveyMonkeySurveyResults = () => {
        return (
            <SurveyMonkeyComponent
                wrapped={isReferralModal}
                surveyId={webCode?.surveyMonkeyResponseId}
                practiceSpecialtyValue={webCode?.practiceSpecialityType?.value}
                webCodeKey={webCode?.key}
                webCodeConnectStatusValue={webCode?.connectSurveyId} // TO-DO double-check this
            />
        )
    }
    const renderSimplifeyeSurveyResults = () => {
        if (!hasSimplifeyeSurvey) {
            return (
                <Alert message="No survey data has been collected for this practice. Please report to your manager to investigate." />
            )
        }

        if (!Boolean(selectedLocation)) {
            return (
                <p className={`${moduleName}__message-red`}>
                    <i className="material-icons">error</i>Please select a location to load a survey
                </p>
            )
        }

        if (
            [
                statusDoctors,
                statusHours,
                statusLocationInformation,
                statusSurveys,
                statusAmplifyWebcodeConnectSurvey,
            ].includes('pending')
        ) {
            return (
                <div className={`${moduleName}__loading`}>
                    <CircularProgress size={70} color="primary" variant="indeterminate" />
                </div>
            )
        }

        if (
            [
                statusDoctors,
                statusHours,
                statusLocationInformation,
                statusSurveys,
                statusAmplifyWebcodeConnectSurvey,
            ].includes('rejected')
        ) {
            return <Alert message={'Something went wrong while loading the survey. Please refresh the page.'} />
        }

        if (
            locationSurvey &&
            locationSurvey?.doctors &&
            locationSurvey?.locationInformation &&
            locationSurvey?.operatingHours?.days
        ) {
            const survey = [
                ...surveyFormatConnectHours(
                    webCode?.connect?.status.value,
                    webCode?.connectSurvey?.connectAvailableHours,
                    webCode?.connectSurvey?.timezone,
                ),
                ...surveyFormatDoctors(locationSurvey?.doctors?.list),
                ...surveyFormatLocationInformation(locationSurvey?.locationInformation),
                ...surveyFormatOperatingHours(locationSurvey?.operatingHours?.days),
                ...surveyFormatSurveySections(locationSurvey?.surveys),
            ]

            const fuse = new Fuse(survey, FUSE_OPTIONS)
            const results = surveySearchQuery && fuse ? fuse.search(surveySearchQuery) : survey

            return results.map((surveyResponse: Models.SurveyResponse, index: number) => {
                return (
                    <Grid item={true} key={surveyResponse.id} className={`${moduleName}__item`}>
                        <SurveyResponse response={surveyResponse} darkerCard={index % 2 === 0} />
                    </Grid>
                )
            })
        }

        return null
    }
    const renderSurveyBody = () => {
        if (surveySwitch === 'simplifeye') {
            return renderSimplifeyeSurveyResults()
        }
        return renderSurveyMonkeySurveyResults()
    }

    const surveySwitchVisible =
        hasSurveyMonkeySurvey && hasSimplifeyeSurvey && !allLocationsHaveCompletedSimplifeyeSurvey

    const renderSurveySwitch = () => {
        let switchVisible = (() => {
            if (isReferralModal) {
                return Boolean(locationId)
            } else {
                return surveySwitchVisible
            }
        })()

        if (switchVisible) {
            return (
                <Grid item>
                    <ButtonGroupSelector
                        items={[
                            {
                                key: 'surveyMonkey',
                                value: 'SurveyMonkey',
                            },
                            {
                                key: 'simplifeye',
                                value: 'simplifeye',
                            },
                        ]}
                        value={surveySwitch}
                        className={`${moduleName}__survey-switch`}
                        keyProperty="key"
                        valueProperty="value"
                        disabled={isReferralModal}
                        onSelect={(value: string) => {
                            setSurveySwitch(value as surveyType)
                        }}
                    />
                </Grid>
            )
        }

        return <></>
    }

    const renderLocationsDropdown = () => {
        const sortedLocationItems = locationsWithCompletedSurveys.sort(moveDefaultLocationToTop)
        // not visible only when surveyMonkey is present
        if (surveySwitch === 'simplifeye' || surveySwitchVisible) {
            return (
                <Grid item className={`${moduleName}__locations-dropdown`}>
                    <CustomMultiselectField
                        items={sortedLocationItems}
                        maxSelected={1}
                        selectedItems={[selectedLocation?.id || '']}
                        search={locationsWithCompletedSurveys.length > 4}
                        disableDropDown={Boolean(selectedLocation?.id && locationsWithCompletedSurveys.length === 1)}
                        keyProperty={'id'}
                        displayProperty={'name'}
                        placeholder={'Select Location'}
                        searchPlaceholder="Search Location"
                        onSelectElement={onLocationChange}
                        isLoading={false}
                        disabled={surveySwitch === 'surveyMonkey'}
                    ></CustomMultiselectField>
                </Grid>
            )
        }
        return <></>
    }

    const renderHeader = () => {
        return (
            <div className={`${moduleName}__survey-header`}>
                <Grid container direction="row" className={`${moduleName}__survey-switch-container`}>
                    {renderSurveySwitch()}
                    {!isReferralModal && renderLocationsDropdown()}
                </Grid>
                {surveySwitch === 'simplifeye' && (
                    <div className={`${moduleName}__header`}>
                        <div className={`${moduleName}__search-bar`}>
                            <CustomField
                                customFieldType={CustomFieldType.INPUT}
                                inputType="text"
                                placeholder="Search"
                                rightAdornment="search"
                                value={surveySearchQuery || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const searchValue = e.target && e.target.value
                                    setSurveySearchQuery(searchValue)
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        )
    }
    if (loading) {
        return (
            <div className={`${moduleName}__loading`}>
                <CircularProgress size={70} color="primary" variant="indeterminate" />
            </div>
        )
    }

    return (
        <div className={`${moduleName}`}>
            {renderHeader()}
            <div className={`${moduleName}__content`}>{renderSurveyBody()}</div>
        </div>
    )
}

export default SurveyComponentV2
