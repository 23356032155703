import React, { Fragment } from 'react'

import UnclaimedChats from './UnclaimedChats'

import './UnclaimedChatsList.sass'

export type UnclaimedChatsListProps = {
    chats: { [id: string]: Models.ChatMetadata }
    unclaimed: string[]
    claimChat: (chat: Models.ChatMetadata) => void
}

const moduleName = 'unclaimed-chats-list'

const UnclaimedChatsList = ({ chats, unclaimed, claimChat }: UnclaimedChatsListProps) => {
    const onClaimChat = (chat: Models.ChatMetadata) => {
        claimChat(chat)
    }

    return (
        <Fragment>
            <div className={`${moduleName}__section-title`}>
                <span>Unclaimed</span>
                <span className={`${moduleName}__section-title-counter`}>{unclaimed?.length ?? 0}</span>
            </div>
            <UnclaimedChats unclaimed={unclaimed} chats={chats} claimChat={onClaimChat} />
        </Fragment>
    )
}

export default UnclaimedChatsList
