import round from 'lodash/round'

export function formatCurrency(amount: number, shouldFormatAmount: boolean = true, customDollarSign?: string) {
    if (amount === null || amount === undefined) {
        amount = 0
    }

    let stringAmt = ''
    const dollarSign = shouldFormatAmount ? (customDollarSign !== undefined ? customDollarSign : '$') : ''
    let roundedAmt = round(amount, 2)

    if (Number.isInteger(roundedAmt)) {
        stringAmt = `${roundedAmt}.00`
    } else if (Number.isInteger(roundedAmt * 10)) {
        stringAmt = `${roundedAmt}0`
    } else {
        stringAmt = `${roundedAmt}`
    }

    const finalAmt = shouldFormatAmount ? stringAmt.replace(/\d(?=(\d{3})+\.)/g, '$&,') : stringAmt

    return `${dollarSign}${finalAmt}`
}

export function convertNumCentsToDollars(
    amount: number | undefined,
    shouldFormatAmount: boolean = false,
    customDollarSign?: string,
) {
    if (typeof amount === 'undefined' || isNaN(amount)) {
        return '...'
    }
    return formatCurrency(amount / 100, shouldFormatAmount, customDollarSign)
}

export function convertStringDollarsToCents(amount: string) {
    const parsedAmount = parseFloat(amount)
    const finalAmount = isNaN(parsedAmount) ? 0 : parsedAmount
    return round(finalAmount * 100, 0)
}

export function formatCurrencyString(amount: string, customDollarSign?: string) {
    if (!amount) {
        return '0'
    } else {
        return `${convertNumCentsToDollars(convertStringDollarsToCents(amount), true, customDollarSign)}`
    }
}
