import { useCallback, useLayoutEffect, useRef } from 'react'

function useSafeDispatch(dispatch: any) {
    const mounted = useRef<boolean>(false)

    useLayoutEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    return useCallback((...args) => (mounted.current ? dispatch(...args) : void 0), [dispatch])
}

export { useSafeDispatch }
