import { Enum } from './enums'

export interface ChatStatusEnum extends Enum<Api.ChatStatusValue> {}
type ChatStatusProperty = 'Unclaimed' | 'Claimed' | 'Idle' | 'Closed' | 'PatientClosed' | 'Inactive'

export const ChatStatus: { [status in ChatStatusProperty]: ChatStatusEnum } = {
    Unclaimed: {
        id: 1,
        value: 'unclaimed',
        display: 'Unclaimed',
    },
    Claimed: {
        id: 2,
        value: 'claimed',
        display: 'Claimed',
    },
    Idle: {
        id: 3,
        value: 'idle',
        display: 'Idle',
    },
    Closed: {
        id: 4,
        value: 'closed',
        display: 'Closed',
    },
    PatientClosed: {
        id: 5,
        value: 'patient_closed',
        display: 'Patient Closed',
    },
    Inactive: {
        id: 6,
        value: 'inactive',
        display: 'Inactive',
    },
}
