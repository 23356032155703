import { RootState } from 'appReducer'

import Api from '../../../Api'
import { receiveLocationStatementDescriptor } from '../actions'

export enum LocationsTabActionTypes {
    SET_SELECTED_LOCATION = '@LOCATIONS_TAB/SET_SELECTED_LOCATION',
    SET_ERROR_MESSAGE = '@LOCATIONS_TAB/SET_ERROR_MESSAGE',
    SET_SUCCESS_MESSAGE = '@LOCATIONS_TAB/SET_SUCCESS_MESSAGE',
    RESET_STATE = '@LOCATIONS_TAB/RESET_STATE',
    OPEN_EDIT_SURVEY_MODAL = '@LOCATIONS_TAB/OPEN_EDIT_SURVEY_MODAL',
    CLOSE_EDIT_SURVEY_MODAL = '@LOCATIONS_TAB/CLOSE_EDIT_SURVEY_MODAL',
    SET_LOCATION_LIST_META = '@LOCATIONS_TAB/SET_LOCATION_LIST_META',
}

export type SetSelectedLocation = {
    type: LocationsTabActionTypes.SET_SELECTED_LOCATION
    location: Models.PracticeLocation
}

export type SetErrorMessage = {
    type: LocationsTabActionTypes.SET_ERROR_MESSAGE
    message: string
    practiceId: string
}

export type SetSuccessMessage = {
    type: LocationsTabActionTypes.SET_SUCCESS_MESSAGE
    message: string
    practiceId: string
}

export type OpenEditSurveyModal = {
    type: LocationsTabActionTypes.OPEN_EDIT_SURVEY_MODAL
    location: Models.PracticeLocation
}

export type CloseEditSurveyModal = {
    type: LocationsTabActionTypes.CLOSE_EDIT_SURVEY_MODAL
    practiceId: string
}

export type SetLocationListMeta = {
    type: LocationsTabActionTypes.SET_LOCATION_LIST_META
    practiceId: string
    metadata: Partial<{
        search: string
        page: number
        allPages: number
        allRows: number
    }>
}

export type ResetState = {
    type: LocationsTabActionTypes.RESET_STATE
    practiceId: string
}

export function setSelectedLocation(location: Models.PracticeLocation): SetSelectedLocation {
    return {
        type: LocationsTabActionTypes.SET_SELECTED_LOCATION,
        location,
    }
}

export function setErrorMessage(message: string, practiceId: string): SetErrorMessage {
    return {
        type: LocationsTabActionTypes.SET_ERROR_MESSAGE,
        message,
        practiceId,
    }
}

export function setSuccessMessage(message: string, practiceId: string): SetSuccessMessage {
    return {
        type: LocationsTabActionTypes.SET_SUCCESS_MESSAGE,
        message,
        practiceId,
    }
}

export function reset(practiceId: string): ResetState {
    return {
        type: LocationsTabActionTypes.RESET_STATE,
        practiceId,
    }
}

export function openEditSurveyModal(location: Models.PracticeLocation): OpenEditSurveyModal {
    return {
        type: LocationsTabActionTypes.OPEN_EDIT_SURVEY_MODAL,
        location,
    }
}

export function closeEditSurveyModal(practiceId: string): CloseEditSurveyModal {
    return {
        type: LocationsTabActionTypes.CLOSE_EDIT_SURVEY_MODAL,
        practiceId,
    }
}

export function setLocationListMeta(
    practiceId: string,
    metadata: Partial<{
        search: string
        page: number
        allPages: number
        allRows: number
    }>,
): SetLocationListMeta {
    return {
        type: LocationsTabActionTypes.SET_LOCATION_LIST_META,
        metadata,
        practiceId,
    }
}

export const getLocationPaymentsSettings = (practiceId: string, locationId: string) => {
    return async (dispatch: any, getState: () => RootState) => {
        // const accountId = getState().app.self?.account.id || 'none'
        const paymentsSettings = await Api.Payments.getLocationPaymentsSettings(locationId)
        dispatch(receiveLocationStatementDescriptor(practiceId, locationId, paymentsSettings.data.statementDescriptor))
        // await dispatch(receiveAccount(practice, paymentsSettings))

        return paymentsSettings
    }
}
