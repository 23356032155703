import _ from 'lodash'

import ApiService from '../service'

export default class ReferralsService extends ApiService {
    async getReferral(referralId: string) {
        const { data: referral } = (await this.fetch(
            `referrals/${referralId}?include[]=amplify_referral_patient&include[]=amplify_status&include[]=amplify_web_code`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ReferralV2>

        return referral
    }

    async getReferralAttachments(referralId: string) {
        const { data: attachments } = (await this.fetch(
            `referrals/${referralId}/attachments`,
            {
                method: 'GET',
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ReferralAttachment[]>

        return attachments
    }

    async getReferralFinancialData(patientId: string) {
        const { data: finance } = (await this.fetch(
            `referral-patients/${patientId}/financial-data`,
            {
                method: 'GET',
            },
            {},
            undefined,
            true,
        )) as { data: ModelsV2.Referrals.PatientsFinancialData }

        return finance
    }

    async getReferralActivityLog(referralId: string) {
        const { data: activityLog } = (await this.fetch(
            `referrals/${referralId}/activity-logs`,
            {
                method: 'GET',
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ActivityLog[]>

        return activityLog
    }

    async getConnectAppointments(referralId: string) {
        const { data: connectAppointments } = (await this.fetch(
            `referrals/${referralId}/connect-appointments`,
            {
                method: 'GET',
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ConnectAppointments[]>

        return connectAppointments
    }

    async getSchedulingAppointment(schedulingAppointmentId: string) {
        const { data: schedulingAppointments } = (await this.fetch(
            `scheduling-appointments/${schedulingAppointmentId}?include[]=scheduling_procedure&include[]=scheduling_operatory&include[]=scheduling_provider&include[]=scheduling_appointment_status`,
            {
                method: 'GET',
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.SchedulingAppointment>
        return schedulingAppointments
    }

    async downloadReferral(referralId: string) {
        const data = await this.fetch(`referrals/${referralId}/download.pdf`)
        return data
    }

    async postAttachments(referralId: string, file_upload_ids: string[]) {
        const { data: postAttachments } = await this.fetch(
            `referrals/${referralId}/attachments/create-many`,
            {
                method: 'POST',
                body: JSON.stringify({
                    file_upload_ids,
                }),
            },
            {},
            undefined,
            true,
        )

        return postAttachments
    }

    async putPatient(patientId: string, patientData: Partial<ModelsV2.Referrals.ReferralPatient>) {
        const { data } = await this.fetch(
            `referral-patients/${patientId}`,
            {
                method: 'PUT',
                body: JSON.stringify(patientData),
            },
            {},
            undefined,
            true,
        )

        return data
    }

    async postPatient(referralId: string, patientData: Partial<ModelsV2.Referrals.ReferralPatient>) {
        const { data } = await this.fetch(
            `referrals/${referralId}/referral-patients`,
            {
                method: 'POST',
                body: JSON.stringify(patientData),
            },
            {},
            undefined,
            true,
        )

        return data
    }

    async deletePatientsById(referralId: string, amplify_referral_patient_ids: string[]) {
        const { data } = await this.fetch(
            `referrals/${referralId}/referral-patients/delete-many`,
            {
                method: 'DELETE',
                body: JSON.stringify({
                    amplify_referral_patient_ids,
                }),
            },
            {},
            undefined,
            true,
        )

        return data
    }

    async deleteAttachments(referralId: string, file_upload_ids: string[]) {
        const { data: deleteAttachments } = await this.fetch(
            `referrals/${referralId}/attachments/delete-many`,
            {
                method: 'DELETE',
                body: JSON.stringify({
                    file_upload_ids,
                }),
            },
            {},
            undefined,
            true,
        )

        return deleteAttachments
    }

    async getReferralsDashboard(query: ModelsV2.Referrals.ReferralDashboardQueryProperties) {
        const filters = _.omit(query, ['filter'])
        filters['filter[status]'] = query.filter.status

        const referrals = (await this.fetch(
            `referrals/dashboard`,
            {
                method: 'GET',
            },
            {
                ...filters,
            },
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ReferralsDashboardCard[]>

        return referrals
    }

    async putReferral(referralId: string, referralData: Partial<ModelsV2.Referrals.ReferralV2>) {
        const { data } = await this.fetch(
            `referrals/${referralId}`,
            {
                method: 'PUT',
                body: JSON.stringify(referralData),
            },
            {},
            undefined,
            true,
        )

        return data
    }

    async postManualReferral(referralData: ModelsV2.Referrals.ReferralV2ManualCreate) {
        const { data } = (await this.fetch(
            `referrals/create-manually-created-referral`,
            {
                method: 'POST',
                body: JSON.stringify(referralData),
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ReferralV2>

        return data
    }

    async postTestReferral(referralData: ModelsV2.Referrals.ReferralV2ManualCreate) {
        const { data } = (await this.fetch(
            `referrals/create-test-referral`,
            {
                method: 'POST',
                body: JSON.stringify(referralData),
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ReferralV2>

        return data
    }

    async postChatReferral(amplify_chat_metadata_id: string) {
        const { data } = (await this.fetch(
            `referrals/create-chat-referral`,
            {
                method: 'POST',
                body: JSON.stringify({
                    amplify_chat_metadata_id,
                }),
            },
            {},
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Referrals.ReferralV2>

        return data
    }
}
