import React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import SelfSchedulingModel from '../../../../models/SelfScheduling'

import './SelfSchedulingOpenURLInRadioGroup.sass'

export type SelfSchedulingOpenURLInRadioGroupProps = {
    openURLIn: Models.SelfSchedulingOpenURLIn
    enabled: boolean
    updateOpenURLIn: (openURLIn: Models.SelfSchedulingOpenURLIn) => void
}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

const radioClassOverrides: RadioClassOverrides = {
    root: 'radio-root',
}

const moduleName = 'self-scheduling-open-url-in-radio-group'

const SelfSchedulingOpenURLInRadioGroup = (props: SelfSchedulingOpenURLInRadioGroupProps) => {
    const { openURLIn = SelfSchedulingModel.defaultOpenURLIn } = props

    const updateOpenURLIn = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.updateOpenURLIn(e.target.value as Models.SelfSchedulingOpenURLIn)
    }

    return (
        <div className={`${moduleName} ${!props.enabled ? `${moduleName}--disabled-form` : ``}`}>
            <div className={`${moduleName}__title`}>Open URL in:</div>
            <div className={`${moduleName}__radio-buttons-wrapper`}>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="update-open-url-in-same-tab"
                        color="primary"
                        checked={openURLIn === 'same_window'}
                        onChange={updateOpenURLIn}
                        name="radio-widget-style"
                        value="same_window"
                        classes={radioClassOverrides}
                        disabled={!props.enabled}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="update-open-url-in-same-tab">
                            <span className={`${moduleName}__label-text`}>Same Tab</span>
                        </label>
                    </div>
                </div>
                <div className={`${moduleName}__option-wrapper`}>
                    <Radio
                        id="update-open-url-in-new-tab"
                        color="primary"
                        checked={openURLIn === 'new_tab'}
                        onChange={updateOpenURLIn}
                        name="radio-widget-style"
                        value="new_tab"
                        classes={radioClassOverrides}
                        disabled={!props.enabled}
                    />
                    <div className={`${moduleName}__label-wrapper`}>
                        <label htmlFor="update-open-url-in-new-tab">
                            <span className={`${moduleName}__label-text`}>New Tab</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelfSchedulingOpenURLInRadioGroup
