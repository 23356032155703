import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import throttle from 'lodash/throttle'

import { RootState } from '../../../appReducer'
import { SearchBar } from '../../shared/custom-fields/SearchBar'

interface Props {
    practiceId: string
    integrationsQuery: string
    setIntegrationsQuery: (value: string) => void
    setSearchPage: (value: number) => void
}

const IntegrationsSearchBar = ({ practiceId, integrationsQuery, setIntegrationsQuery, setSearchPage }: Props) => {
    const list = useSelector((state: RootState) => state.v2.integrations.heartbeat[practiceId]?.agents || [])
    const loading = useSelector((state: RootState) => state.v2.integrations.loadingAgents[practiceId] || false)

    const handleSearch = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setIntegrationsQuery(value)
        setSearchPage(1)
    }

    const handleClearSearch = () => {
        setIntegrationsQuery('')
        setSearchPage(1)
    }

    const throttledHandleSearch = throttle(handleSearch, 1000, {
        trailing: false,
    })

    return (
        <SearchBar
            value={integrationsQuery}
            onClear={handleClearSearch}
            onChange={throttledHandleSearch}
            placeholder="Search by Agent Name or Location Name"
            isMessageShown={integrationsQuery.length > 0 && !loading && list.length === 0}
        />
    )
}

export type IntegrationsSearchBarProps = Props
export default IntegrationsSearchBar
