import { useEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }: { children: any }) => {
    const mount = document.getElementById('sf-portal-root')
    const el = document.createElement('div')

    const effectCallback = (): any => {
        mount?.appendChild(el)
        return () => mount?.removeChild(el)
    }
    useEffect(effectCallback, [el, mount])

    return createPortal(children, el)
}

export default Portal
