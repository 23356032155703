/* tslint:disable:max-line-length */
const ALTERNATIVE_VERTICAL_QUESTION_LIST: Array<Models.DefaultSurveyQuestion> = [
    {
        id: 'connectHours',
        question: '<b>Connect hours:</b>',
        htmlClass: 'big',
    },
    {
        id: '635862767',
        question: 'What is/are the name of your business(s)?',
        htmlClass: 'big',
    },
    {
        id: '635862768',
        question: 'What is/are the name(s) of the staff members(s)?',
        htmlClass: 'small',
    },
    {
        id: '635862769',
        question: 'Who (or what business) is in charge of your website?',
        htmlClass: 'small',
    },
    {
        id: '635862771',
        question: `What is the web developer's phone number?`,
        htmlClass: 'small',
    },
    {
        id: '635862770',
        question: `What is the web developer's email address?`,
        htmlClass: 'small',
    },
    {
        id: '635862772',
        question: 'What is/are the business’s website address(es)?',
        htmlClass: 'small',
    },
    {
        id: '635862773',
        question: 'What are the addresses of ALL business locations?',
        htmlClass: 'small',
    },
    {
        id: '635862774',
        question: 'What are the phone numbers of ALL business locations?',
        htmlClass: 'small',
    },
    {
        id: '635862785',
        question: 'What are your customer’s most frequently asked questions and how do you typically respond?',
        htmlClass: 'small',
    },
    {
        id: '635862775',
        question: 'At what email address(es) would you like to receive Amplify referrals?',
        htmlClass: 'big',
    },
    {
        id: '635862778',
        question: 'What is your fax number?',
        htmlClass: 'small',
    },
    {
        id: '635862779',
        question:
            'What are your hours of operation? If you have more than one office, please specify what days and hours you are available at each location.',
        htmlClass: 'small',
    },
    {
        id: '635862780',
        question: 'Please select the major holidays that the office closes for:',
        htmlClass: 'small',
    },
    {
        id: '635862781',
        question: 'Do you have a complimentary consultation?',
        htmlClass: 'small',
    },
    {
        id: '635862795',
        question: 'What is included in the complimentary consultation?',
        htmlClass: 'small',
    },
    {
        id: '635862796',
        question: 'What services/products does your business have?',
        htmlClass: 'small',
    },
    {
        id: '635862789',
        question: 'What can we say to draw patients to your amazing business?',
        htmlClass: 'small',
    },
    {
        id: '635862791',
        question: 'Is there anything else we should know about your business?',
        htmlClass: 'small',
    },
].map(surveyItem => ({
    // Create raw (lowercase, no formatting) version for search
    questionRaw: surveyItem.question.toLowerCase().replace(/<b>|<\/b>/i, ''),
    ...surveyItem,
}))

export default ALTERNATIVE_VERTICAL_QUESTION_LIST
