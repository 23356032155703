import iassign from 'immutable-assign'

import { SetSelectedPractice } from '../../practices/actions'

import {
    CloseLocationAddEditModal,
    LocationAddEditModalActionTypes,
    OpenLocationAddEditModal,
    UpdateLocationAddEditData,
} from './location-forms/actions'
import { ClosePaymentRatesModal, OpenPaymentRatesModal, PaymentRatesModalActionTypes } from './payment-rates/actions'
import {
    CloseStatementDescriptorModal,
    OpenStatementDescriptorModal,
    StatementDescriptorModalActionTypes,
    UpdateStatementDescriptorModalData,
} from './statement-descriptor/actions'
import {
    CloseStripeAccountIdModal,
    OpenStripeAccountIdModal,
    StripeAccountIdModalActionTypes,
    UpdateStripeAccountIdModalData,
} from './stripe-account-id/actions'
import {
    CloseTerminalModal,
    OpenTerminalModal,
    TerminalModalActionTypes,
    UpdateTerminalModalData,
} from './terminal-form/actions'
import { AddTerminalRadio } from './terminal-form/TerminalForm'
import {
    CloseEditSurveyModal,
    LocationsTabActionTypes,
    OpenEditSurveyModal,
    ResetState,
    SetErrorMessage,
    SetLocationListMeta,
    SetSelectedLocation,
    SetSuccessMessage,
} from './actions'

type PracticeLocationsTabAction =
    | SetSelectedLocation
    | SetErrorMessage
    | SetSuccessMessage
    | OpenLocationAddEditModal
    | CloseLocationAddEditModal
    | OpenPaymentRatesModal
    | ClosePaymentRatesModal
    | OpenStatementDescriptorModal
    | UpdateStatementDescriptorModalData
    | CloseStatementDescriptorModal
    | OpenStripeAccountIdModal
    | CloseStripeAccountIdModal
    | OpenEditSurveyModal
    | CloseEditSurveyModal
    | OpenTerminalModal
    | CloseTerminalModal
    | ResetState
    | UpdateStripeAccountIdModalData
    | UpdateTerminalModalData
    | UpdateLocationAddEditData
    | SetSelectedPractice
    | SetLocationListMeta

export interface LocationAddEditModalState {
    location: Models.PracticeLocation | null
    isEdit: boolean
    isOpen: boolean
    isLoading: boolean
    showDeleteConfirmation: boolean
    errorMessage: string
}

export interface PaymentRatesModalState {
    locationId: Models.PracticeLocation['id']
    locationName: Models.PracticeLocation['name']
    isOpen: boolean
    isLoading: boolean
    errorMessage: string
}

export interface StripeAccountIdModalState {
    locationId: Models.PracticeLocation['id']
    locationName: Models.PracticeLocation['name']
    stripeAccountId: Models.PracticeLocation['stripeConnectedAccountId']
    applyToAll: boolean
    isOpen: boolean
    isLoading: boolean
    errorMessage: string
}

export interface StatementDescriptorModalState {
    locationId: Models.PracticeLocation['id']
    statementDescriptor: Models.PracticeLocation['statementDescriptor']
    applyToAll: boolean
    isOpen: boolean
    isLoading: boolean
    errorMessage: string
}

export interface EditSurveyModalState {
    location: Models.PracticeLocation | null
    isOpen: boolean
    isLoading: boolean
    errorMessage: string
}

export interface TerminalModalState {
    locationId: Models.PracticeLocation['id']
    terminalId: Models.LocationPaymentsTerminalInfo['id']
    nickname: Models.LocationPaymentsTerminalInfo['nickname']
    readerSerial: Models.LocationPaymentsTerminalInfo['readerSerial']
    pairingCode: Models.LocationPaymentsTerminalInfo['pairingCode']
    s700Code: Models.LocationPaymentsTerminalInfo['s700Code']
    uuid: Models.LocationPaymentsTerminalInfo['uuid']
    addTerminalRadio: AddTerminalRadio
    isView: boolean
    isLoading: boolean
    isOpen: boolean
    errorMessage: string
}

export interface PracticeLocationsTabState {
    errorMessage: string
    successMessage: string
    selectedLocation: Models.PracticeLocation | null
    modals: {
        locationAddEdit: LocationAddEditModalState
        paymentRates: PaymentRatesModalState
        stripeAccountId: StripeAccountIdModalState
        statementDescriptor: StatementDescriptorModalState
        editSurvey: EditSurveyModalState
        terminal: TerminalModalState
    }
    metadata: {
        search: string
        page: number
        allPages: number
        allRows: number
    }
}

export interface PracticeLocationsState {
    [key: string]: PracticeLocationsTabState
}

export const initLocationAddEditState = (): LocationAddEditModalState => ({
    location: null,
    isEdit: false,
    isOpen: false,
    isLoading: false,
    showDeleteConfirmation: false,
    errorMessage: '',
})

export const initPaymentsRateState = (): PaymentRatesModalState => ({
    locationId: '',
    locationName: '',
    isOpen: false,
    isLoading: false,
    errorMessage: '',
})

export const initStripeAccountIdState = (): StripeAccountIdModalState => ({
    locationId: '',
    locationName: '',
    stripeAccountId: '',
    applyToAll: false,
    isOpen: false,
    isLoading: false,
    errorMessage: '',
})

export const initStatementDescriptorState = (): StatementDescriptorModalState => ({
    locationId: '',
    statementDescriptor: '',
    applyToAll: false,
    isOpen: false,
    isLoading: false,
    errorMessage: '',
})

export const initEditSurveyState = (): EditSurveyModalState => ({
    location: null,
    isOpen: false,
    isLoading: false,
    errorMessage: '',
})

export const initTerminalState = (): TerminalModalState => ({
    locationId: '',
    terminalId: '',
    isView: false,
    isLoading: false,
    isOpen: false,
    nickname: '',
    readerSerial: '',
    pairingCode: undefined,
    s700Code: undefined,
    uuid: undefined,
    addTerminalRadio: 'useSmile',
    errorMessage: '',
})

const initPracticeLocationsTabState = (): PracticeLocationsTabState => ({
    errorMessage: '',
    successMessage: '',
    selectedLocation: null,
    modals: {
        locationAddEdit: { ...initLocationAddEditState() },
        paymentRates: { ...initPaymentsRateState() },
        stripeAccountId: { ...initStripeAccountIdState() },
        statementDescriptor: { ...initStatementDescriptorState() },
        editSurvey: { ...initEditSurveyState() },
        terminal: { ...initTerminalState() },
    },
    metadata: {
        search: '',
        page: 1,
        allPages: 1,
        allRows: 0,
    },
})

function practiceLocationsTabReducer(
    state: { [key: string]: PracticeLocationsTabState } = {},
    action: PracticeLocationsTabAction,
): { [key: string]: PracticeLocationsTabState } {
    switch (action.type) {
        case LocationsTabActionTypes.SET_SELECTED_LOCATION: {
            return iassign(
                state,
                next => next[action.location.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.selectedLocation = action.location
                    return nextPracticeLocation
                },
            )
        }
        case LocationsTabActionTypes.SET_ERROR_MESSAGE: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.errorMessage = action.message
                    nextPracticeLocation.successMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case LocationsTabActionTypes.SET_SUCCESS_MESSAGE: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.successMessage = action.message
                    nextPracticeLocation.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case LocationAddEditModalActionTypes.OPEN_LOCATION_ADD_EDIT_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.locationAddEdit.location = action.location
                    nextPracticeLocation.modals.locationAddEdit.isOpen = true
                    nextPracticeLocation.modals.locationAddEdit.isEdit = action.isEdit
                    nextPracticeLocation.modals.locationAddEdit.isLoading = false
                    nextPracticeLocation.modals.locationAddEdit.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case LocationAddEditModalActionTypes.CLOSE_LOCATION_ADD_EDIT_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.locationAddEdit = { ...initLocationAddEditState() }
                    return nextPracticeLocation
                },
            )
        }
        case LocationAddEditModalActionTypes.UPDATE_LOCATION_ADD_EDIT_MODAL_DATA: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.locationAddEdit = {
                        ...state[action.practiceId].modals.locationAddEdit,
                        ...action.data,
                    }
                    return nextPracticeLocation
                },
            )
        }
        case PaymentRatesModalActionTypes.OPEN_PAYMENT_RATES_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.paymentRates.locationId = action.locationId
                    nextPracticeLocation.modals.paymentRates.locationName = action.locationName
                    nextPracticeLocation.modals.paymentRates.isOpen = true
                    nextPracticeLocation.modals.paymentRates.isLoading = false
                    nextPracticeLocation.modals.paymentRates.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case PaymentRatesModalActionTypes.CLOSE_PAYMENT_RATES_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.paymentRates = { ...initPaymentsRateState() }
                    return nextPracticeLocation
                },
            )
        }
        case StatementDescriptorModalActionTypes.OPEN_STATEMENT_DESCRIPTOR_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.statementDescriptor.locationId = action.locationId
                    nextPracticeLocation.modals.statementDescriptor.statementDescriptor = action.statementDescriptor
                    nextPracticeLocation.modals.statementDescriptor.isOpen = true
                    nextPracticeLocation.modals.statementDescriptor.isLoading = false
                    nextPracticeLocation.modals.statementDescriptor.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case StatementDescriptorModalActionTypes.UPDATE_STATEMENT_DESCRIPTOR_MODAL_DATA: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.statementDescriptor = {
                        ...state[action.practiceId].modals.statementDescriptor,
                        ...action.data,
                    }
                    return nextPracticeLocation
                },
            )
        }
        case StatementDescriptorModalActionTypes.CLOSE_STATEMENT_DESCRIPTOR_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.statementDescriptor = { ...initStatementDescriptorState() }
                    return nextPracticeLocation
                },
            )
        }
        case StripeAccountIdModalActionTypes.OPEN_STRIPE_ACCOUNT_ID_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.stripeAccountId.locationId = action.locationId
                    nextPracticeLocation.modals.stripeAccountId.locationName = action.locationName
                    nextPracticeLocation.modals.stripeAccountId.stripeAccountId = action.stripeAccountId
                    nextPracticeLocation.modals.stripeAccountId.isOpen = true
                    nextPracticeLocation.modals.stripeAccountId.isLoading = false
                    nextPracticeLocation.modals.stripeAccountId.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case StripeAccountIdModalActionTypes.UPDATE_STRIPE_ACCOUNT_ID_MODAL_DATA: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.stripeAccountId = {
                        ...state[action.practiceId].modals.stripeAccountId,
                        ...action.data,
                    }
                    return nextPracticeLocation
                },
            )
        }
        case StripeAccountIdModalActionTypes.CLOSE_STRIPE_ACCOUNT_ID_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.stripeAccountId = { ...initStripeAccountIdState() }
                    return nextPracticeLocation
                },
            )
        }
        case LocationsTabActionTypes.OPEN_EDIT_SURVEY_MODAL: {
            return iassign(
                state,
                next => next[action.location.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.editSurvey.location = action.location
                    nextPracticeLocation.modals.editSurvey.isOpen = true
                    nextPracticeLocation.modals.editSurvey.isLoading = false
                    nextPracticeLocation.modals.editSurvey.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case LocationsTabActionTypes.CLOSE_EDIT_SURVEY_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.editSurvey = { ...initEditSurveyState() }
                    return nextPracticeLocation
                },
            )
        }
        case LocationsTabActionTypes.SET_LOCATION_LIST_META: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.metadata = { ...nextPracticeLocation.metadata, ...action.metadata }
                    return nextPracticeLocation
                },
            )
        }
        case TerminalModalActionTypes.OPEN_TERMINAL_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.terminal.locationId = action.locationId
                    nextPracticeLocation.modals.terminal.terminalId = action.terminalId
                    nextPracticeLocation.modals.terminal.nickname = action.nickname
                    nextPracticeLocation.modals.terminal.readerSerial = action.readerSerial
                    nextPracticeLocation.modals.terminal.pairingCode = action.pairingCode
                    nextPracticeLocation.modals.terminal.s700Code = action.s700Code
                    nextPracticeLocation.modals.terminal.uuid = action.uuid
                    nextPracticeLocation.modals.terminal.addTerminalRadio = action.addTerminalRadio
                    nextPracticeLocation.modals.terminal.isView = action.isView
                    nextPracticeLocation.modals.terminal.isOpen = true
                    nextPracticeLocation.modals.terminal.isLoading = false
                    nextPracticeLocation.modals.terminal.errorMessage = ''
                    return nextPracticeLocation
                },
            )
        }
        case TerminalModalActionTypes.UPDATE_TERMINAL_MODAL_DATA: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.terminal = {
                        ...state[action.practiceId].modals.terminal,
                        ...action.data,
                    }
                    return nextPracticeLocation
                },
            )
        }
        case TerminalModalActionTypes.CLOSE_TERMINAL_MODAL: {
            return iassign(
                state,
                next => next[action.practiceId],
                nextPracticeLocation => {
                    nextPracticeLocation.modals.terminal = { ...initTerminalState() }
                    return nextPracticeLocation
                },
            )
        }
        case LocationsTabActionTypes.RESET_STATE: {
            return iassign(
                state,
                next => next[action.practiceId],
                () => ({ ...initPracticeLocationsTabState() }),
            )
        }

        case 'SET_SELECTED_PRACTICE': {
            return iassign(
                state,
                next => next[action.selectedPractice.id],
                () => ({ ...initPracticeLocationsTabState() }),
            )
        }
        default:
            return state
    }
}

export default practiceLocationsTabReducer
