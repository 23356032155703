import React, { useCallback, useEffect, useRef, useState } from 'react'
import Icon from '@mui/material/Icon'

import './CustomTooltip.sass'

type Position = 'top' | 'bottom' | 'top-right' | 'bottom-right' | 'bottom-left' | 'top-left'
interface TooltipProps {
    text: React.ReactNode
    type?: 'click' | 'hover'
    timeout?: number
    children?: React.ReactNode
    overlay?: boolean
    parentRect?: any
    style?: object
    position?: Position
}

export const moduleName = 'custom-tooltip'

const CustomTooltip = (props: TooltipProps) => {
    const timerRef = useRef<any>(null)
    const { text, type = 'click', children, overlay = true, parentRect } = props
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [parentX, setParentX] = useState<number>(0)
    const [parentY, setParentY] = useState<number>(0)
    const [position, setPosition] = useState<Position>(props.position || 'top')
    const textLength = text?.toString().length || 0

    const popoverRef = useRef<HTMLDivElement>()
    const childrenRef = useRef<HTMLDivElement>()

    const checkIfClickedInside = (event: MouseEvent, element: HTMLDivElement) => {
        return element?.contains(event.target as HTMLElement) || element === event.target
    }

    useEffect(() => {
        if (!overlay && parentRect) {
            setParentX(parentRect.left)
            setParentY(parentRect.top)
        }
    }, [overlay, parentRect])

    const closeOnClickOutOfPopover = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            const isInsidePopover = checkIfClickedInside(event, popoverRef.current as HTMLDivElement)
            const isInsideChildrenElements = checkIfClickedInside(event, childrenRef.current as HTMLDivElement)
            if (!isInsidePopover && !isInsideChildrenElements) {
                setIsOpen(false)
            }
        },
        [setIsOpen],
    )

    const toggleTooltip = (open: boolean) => (e: any) => {
        if (!overlay) {
            const positionX = parentX + 100
            const positionY = parentY + textLength * 2

            if (e.clientX >= positionX && e.clientY <= positionY) {
                setPosition('bottom-left')
            }
            if (e.clientX <= positionX && e.clientY <= positionY) {
                setPosition('bottom-right')
            }
            if (e.clientX <= positionX && e.clientY >= positionY) {
                setPosition('top-right')
            }
            if (e.clientX >= positionX && e.clientY >= positionY) {
                setPosition('top-left')
            }
        }
        setIsOpen(open)
    }

    useEffect(() => {
        if (type === 'click') {
            if (isOpen) {
                document.addEventListener('click', closeOnClickOutOfPopover, true)
                return
            }
            document.removeEventListener('click', closeOnClickOutOfPopover, true)
        }
    }, [type, isOpen, closeOnClickOutOfPopover])

    const toggleTooltipTimeouted = (isOpen: boolean) => (e: any) => {
        if (props.timeout && isOpen) {
            timerRef.current = setTimeout(() => toggleTooltip(isOpen)(e), props.timeout)
        } else {
            clearTimeout(timerRef.current)
            toggleTooltip(isOpen)(e)
        }
    }

    const renderChildren = () => {
        if (type === 'hover') {
            return (
                <div
                    className={`${moduleName}__children`}
                    onMouseEnter={toggleTooltipTimeouted(true)}
                    onMouseLeave={toggleTooltipTimeouted(false)}
                >
                    {children}
                </div>
            )
        }

        return (
            <div
                className={`${moduleName}__children`}
                ref={childrenRef as React.RefObject<any>}
                onClick={toggleTooltip(true)}
            >
                {children}
            </div>
        )
    }

    const style = props.style || {}

    return (
        <div className={`${moduleName}`}>
            {isOpen && (
                <div
                    className={`${moduleName}__popover ${moduleName}__popover--${position}`}
                    style={style}
                    ref={popoverRef as React.RefObject<any>}
                >
                    {type === 'click' && (
                        <Icon onClick={toggleTooltip(false)} className={`${moduleName}__popover-close-icon`}>
                            close
                        </Icon>
                    )}
                    {text}
                </div>
            )}
            {renderChildren()}
        </div>
    )
}

export default CustomTooltip
