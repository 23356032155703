import * as React from 'react'
import Card, { CardClassKey } from '@mui/material/Card'
import CardContent, { CardContentClassKey } from '@mui/material/CardContent'
import Tab, { TabClassKey } from '@mui/material/Tab'
import Tabs, { TabsClassKey } from '@mui/material/Tabs'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import intersection from 'lodash/intersection'
import DirectSchedulingTab from 'modules/direct-scheduling/DirectSchedulingTab'

import AdminAccount from '../../models/AdminAccount'
import {
    AccessHeartbeatConfiguration,
    AccessPatientsTab,
    AccessPaymentsTab,
    Amplify,
    Chairfill,
    DexVoice,
    DirectScheduling,
    Flow,
    Heartbeat,
    ManageAllPractices,
    ManageAmplify,
    ManageDexVoice,
    ManageDirectScheduling,
    ManageFlow,
    ManageIntegrations,
    ManagePayments,
    ProductType,
    QuickviewTab,
    Reviews,
    SimplifeyePayments,
} from '../../models/enums'
import AmplifyTab from '../amplify/AmplifyTab'
import ChairfillTab from '../chairfill/ChairfillTab'
import DexVoiceContainer from '../dexvoice/DexVoiceContainer'
import PaymentsTabContainer from '../payments/PaymentsTabContainer'
import PracticeHeader from '../shared/PracticeHeader'

import FlowTabContainer from './flow/FlowTabContainer'
import HeartbeatTab from './heartbeat/HeartbeatTab'
import { Integrations } from './integrations/Integrations'
import LocationsTab from './locations/LocationsTab'
import PracticeLogoContainer from './logo/PracticeLogoContainer'
import PatientsTab from './patients/PatientsTab'
import { resetSearchPracticePatients } from './patients/v2actions'
import StaffTab from './staff/StaffTab'
import { savePractice, saveSelectedPractice } from './actions'

import './PracticeQuickview.sass'

export type PracticeQuickviewProps = {
    practice: Models.Practice
    account: AdminAccount
    practiceSearchTerms: Models.SearchTerms
    canEdit?: boolean
    selectedTab?: QuickviewTab
    saveSelectedPractice: typeof saveSelectedPractice
    resetSearchPracticePatients: typeof resetSearchPracticePatients
}

export type PracticeQuickviewDispatch = {
    savePractice: typeof savePractice
}

export type PracticeQuickviewState = {
    selected?: QuickviewTab
}

type Props = PracticeQuickviewProps & PracticeQuickviewDispatch

type CardContentClassOverrides = {
    readonly [key in CardContentClassKey]?: string
}

type CardClassOverrides = {
    readonly [key in CardClassKey]?: string
}

type TabsClassOverrides = {
    readonly [key in TabsClassKey]?: string
}

type TabClassOverrides = {
    readonly [key in TabClassKey]?: string
}

class PracticeQuickview extends React.Component<Props, PracticeQuickviewState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            selected: props.selectedTab,
        }
    }

    hasPermission(permissions?: Models.Permission[]) {
        if (permissions) {
            return permissions.some(this.props.account.hasAccess.bind(this.props.account))
        }
        return true
    }

    hasProductTab(productTypes: ProductType | ProductType[], permissions?: Models.Permission[]) {
        const tabs = Array.isArray(productTypes) ? productTypes.map(p => p.value) : [productTypes.value]
        const products = this.props.practice.products.map(p => p.value)
        const hasProduct = intersection(products, tabs).length > 0
        return hasProduct && this.hasPermission(permissions)
    }
    onSwitchTab(tabname: QuickviewTab) {
        this.setState(
            iassign(this.state, (next: PracticeQuickviewState) => {
                next.selected = tabname
                return next
            }),
        )
    }
    onSelectTab(tabname: QuickviewTab, practiceId: string) {
        this.onSwitchTab(tabname)
        if (tabname === QuickviewTab.Patients) {
            this.props.resetSearchPracticePatients(practiceId)
        }
        this.props.saveSelectedPractice({ id: practiceId, selectedTab: tabname })
    }

    render() {
        const { practice, canEdit, account } = this.props
        const { selected } = this.state

        const cardContentClassOverrides: CardContentClassOverrides = {
            root: 'card-content-root',
        }

        const cardClassOverrides: CardClassOverrides = {
            root: 'card-root',
        }

        const tabsClassOverrides: TabsClassOverrides = {
            flexContainer: 'tabs-container',
            scroller: 'tabs-scroller',
            indicator: 'tabs-indicator',
        }

        const tabClassOverrides: TabClassOverrides = {
            wrapped: 'tab-wrapper',
            labelIcon: 'tab-icon',
            iconWrapper: 'tab-icon-wrapper',
        }

        const tabLabelClass = 'label tab-label-container'

        const hasPayments = this.hasProductTab(SimplifeyePayments, [ManagePayments, ManageAllPractices])
        const hasHeartbeat = this.hasProductTab(Heartbeat, [AccessHeartbeatConfiguration])

        return (
            <div className={classNames('practice-quickview')} id={`practice-quickview-${practice.id}`}>
                <Card className={classNames('card')} classes={cardClassOverrides}>
                    <CardContent classes={cardContentClassOverrides}>
                        <PracticeHeader canEdit={canEdit} practice={practice} />
                    </CardContent>
                    <Tabs
                        className={classNames('tab-bar')}
                        classes={tabsClassOverrides}
                        onChange={(_e, value) => this.onSelectTab(value, practice.id)}
                        value={this.state.selected || false}
                        indicatorColor="primary"
                        textColor="inherit"
                    >
                        {this.hasProductTab(Flow, [ManageFlow, ManageAllPractices]) &&
                            practice.products.some(p => p.value === Flow.value && p.active) && (
                                <Tab
                                    className={classNames('tab', {
                                        selected: this.state.selected === QuickviewTab.Flow,
                                    })}
                                    label={<span className={tabLabelClass}>Flow</span>}
                                    value={QuickviewTab.Flow}
                                    classes={tabClassOverrides}
                                />
                            )}
                        {this.hasProductTab(DexVoice, [ManageDexVoice, ManageAllPractices]) &&
                            practice.products.some(p => p.value === DexVoice.value && p.active) && (
                                <Tab
                                    className={classNames('tab', {
                                        selected: this.state.selected === QuickviewTab.DexVoice,
                                    })}
                                    label={<span className={tabLabelClass}>DexVoice</span>}
                                    value={QuickviewTab.DexVoice}
                                    classes={tabClassOverrides}
                                />
                            )}
                        {this.hasProductTab(Amplify, [ManageAmplify, ManageAllPractices]) && (
                            <Tab
                                className={classNames(
                                    'tab',
                                    {
                                        inactive: !practice.products.some(p => p.value === Amplify.value && p.active),
                                    },
                                    { selected: this.state.selected === QuickviewTab.Amplify },
                                )}
                                label={<span className={tabLabelClass}>Live Webchat</span>}
                                value={QuickviewTab.Amplify}
                                classes={tabClassOverrides}
                            />
                        )}
                        {this.hasProductTab(DirectScheduling, [ManageDirectScheduling, ManageAllPractices]) && (
                            <Tab
                                className={classNames(
                                    'tab',
                                    {
                                        inactive: !practice.products.some(
                                            p => p.value === DirectScheduling.value && p.active,
                                        ),
                                    },
                                    { selected: this.state.selected === QuickviewTab.DirectScheduling },
                                )}
                                label={<span className={tabLabelClass}>Online Scheduling</span>}
                                value={QuickviewTab.DirectScheduling}
                                classes={tabClassOverrides}
                            />
                        )}
                        {this.hasPermission([AccessPaymentsTab]) && hasPayments && (
                            <Tab
                                className={classNames(
                                    'tab',
                                    {
                                        inactive: !practice.products.some(
                                            p => p.value === SimplifeyePayments.value && p.active,
                                        ),
                                    },
                                    { selected: this.state.selected === QuickviewTab.Payments },
                                )}
                                label={<span className={tabLabelClass}>Simplifeye Payments</span>}
                                value={QuickviewTab.Payments}
                                classes={tabClassOverrides}
                            />
                        )}
                        {this.hasProductTab(Chairfill) && (
                            <Tab
                                className={classNames(
                                    'tab',
                                    {
                                        inactive: !practice.products.some(p => p.value === Chairfill.value && p.active),
                                    },
                                    { selected: this.state.selected === QuickviewTab.Chairfill },
                                )}
                                label={<span className={tabLabelClass}>Campaigns</span>}
                                value={QuickviewTab.Chairfill}
                                classes={tabClassOverrides}
                            />
                        )}
                        {this.hasPermission([AccessPatientsTab]) && (
                            <Tab
                                className={classNames('tab', {
                                    selected: this.state.selected === QuickviewTab.Patients,
                                })}
                                label={<span className="label">Patients</span>}
                                value={QuickviewTab.Patients}
                                classes={tabClassOverrides}
                            />
                        )}
                        <Tab
                            className={classNames('tab', { selected: this.state.selected === QuickviewTab.Staff })}
                            label={<span className={tabLabelClass}>Staff</span>}
                            value={QuickviewTab.Staff}
                            icon={<i className="material-icons">group</i>}
                            classes={tabClassOverrides}
                        />
                        <Tab
                            className={classNames('tab', { selected: this.state.selected === QuickviewTab.Locations })}
                            label={<span className={tabLabelClass}>Locations</span>}
                            value={QuickviewTab.Locations}
                            icon={<i className="material-icons">place</i>}
                            classes={tabClassOverrides}
                        />
                        <Tab
                            className={classNames('tab', { selected: this.state.selected === QuickviewTab.Logo })}
                            label={<span className={tabLabelClass}>Logo</span>}
                            value={QuickviewTab.Logo}
                            icon={<i className="material-icons">image</i>}
                            classes={tabClassOverrides}
                        />
                        {this.hasProductTab(
                            [Heartbeat, Chairfill, DirectScheduling, SimplifeyePayments, Reviews],
                            [
                                ManageIntegrations,
                                ManageAmplify,
                                ManagePayments,
                                ManageDirectScheduling,
                                ManageAllPractices,
                            ],
                        ) && (
                            <Tab
                                className={classNames(
                                    'tab',
                                    {
                                        inactive: !practice.products.some(
                                            p =>
                                                [
                                                    Heartbeat.value,
                                                    Chairfill.value,
                                                    DirectScheduling.value,
                                                    SimplifeyePayments.value,
                                                    Reviews.value,
                                                ].includes(p.value) && p.active,
                                        ),
                                    },
                                    {
                                        selected: this.state.selected === QuickviewTab.Integrations,
                                    },
                                )}
                                label={<span className={tabLabelClass}>Integrations</span>}
                                value={QuickviewTab.Integrations}
                                classes={tabClassOverrides}
                            />
                        )}
                        {hasHeartbeat && (
                            <Tab
                                className={classNames(
                                    'tab',
                                    {
                                        inactive: !practice.products.some(p => p.value === Heartbeat.value && p.active),
                                    },
                                    { selected: this.state.selected === QuickviewTab.Heartbeat },
                                )}
                                label={<span className={tabLabelClass}>Heartbeat</span>}
                                value={QuickviewTab.Heartbeat}
                                icon={<i className="material-icons">favorite</i>}
                                classes={tabClassOverrides}
                            />
                        )}
                    </Tabs>
                    {selected === QuickviewTab.Flow && (
                        <CardContent>
                            <FlowTabContainer practice={practice} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.DexVoice && (
                        <CardContent>
                            <DexVoiceContainer practice={practice} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Amplify && (
                        <CardContent classes={cardContentClassOverrides}>
                            <AmplifyTab practice={practice} account={account} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.DirectScheduling && (
                        <CardContent classes={cardContentClassOverrides}>
                            <DirectSchedulingTab practice={practice} account={account} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Payments && (
                        <CardContent>
                            <PaymentsTabContainer practice={practice} account={account} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Staff && (
                        <CardContent>
                            <StaffTab practice={practice} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Locations && (
                        <CardContent>
                            <LocationsTab
                                practice={practice}
                                hasPayments={hasPayments}
                                practiceSearchTerms={this.props.practiceSearchTerms}
                            />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Logo && (
                        <CardContent>
                            <PracticeLogoContainer practice={practice} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Integrations && (
                        <CardContent style={{ padding: '8px' }}>
                            <Integrations practice={practice} account={account} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Heartbeat && (
                        <CardContent>
                            <HeartbeatTab practice={practice} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Patients && (
                        <CardContent>
                            <PatientsTab practice={practice} />
                        </CardContent>
                    )}
                    {selected === QuickviewTab.Chairfill && (
                        <CardContent>
                            <ChairfillTab practice={practice} account={account} />
                        </CardContent>
                    )}
                </Card>
            </div>
        )
    }
}

export default PracticeQuickview
