import * as React from 'react'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

import { CustomFieldType } from '../../models/enums'
import CustomField from '../shared/custom-fields/CustomField'

import './WebCodeCustomizationWelcome.sass'

type Props = {
    cancelNewMessage: () => void
    customization: Api.WebCodeCustomization
    deleteMessage: (index: number) => void
    newMessage?: string
    saveNewMessage: () => void
    updateNewMessage: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type State = {}

export default class WebCodeCustomizationWelcome extends React.Component<Props, State> {
    render() {
        const {
            cancelNewMessage,
            customization,
            deleteMessage,
            newMessage,
            saveNewMessage,
            updateNewMessage,
        } = this.props

        const newMessageValue = newMessage || ''

        return (
            <div className="welcome-messages-table">
                <Table>
                    <TableBody>
                        {customization.welcome_messages &&
                            customization.welcome_messages.map((wm, index) => (
                                <TableRow key={index.toString()}>
                                    <TableCell className="welcome-messages-table-button-meesage">{wm}</TableCell>
                                    <TableCell>
                                        <Button
                                            className="welcome-messages-table-button"
                                            variant="text"
                                            onClick={() => deleteMessage(index)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow>
                            <TableCell className="message">
                                <CustomField
                                    customFieldType={CustomFieldType.TEXTAREA}
                                    autoFocus={true}
                                    autoSize={true}
                                    value={newMessageValue}
                                    rows={1}
                                    onChange={updateNewMessage}
                                />
                            </TableCell>
                            <TableCell>
                                <div className="welcome-messages-table-buttons">
                                    <Button
                                        className="welcome-messages-table-button-add add"
                                        variant="text"
                                        disabled={!newMessage}
                                        onClick={saveNewMessage}
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        className=" welcome-messages-table-button cancel"
                                        variant="text"
                                        onClick={cancelNewMessage}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        )
    }
}
