import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@mui/material'
import { SimplifeyePayments } from 'models/enums'

import { RootState } from '../../../../appReducer'
import { useAppDispatch } from '../../../../util/useAppDispatch'

import { LocationDataModel, LocationFormDataModel } from './hooks/useLocationForm'
import { closeLocationAddEditModal, createLocation, updateLocation, updateLocationAddEditData } from './actions'
import LocationForm from './LocationForm'

interface Props {
    practice: Models.Practice
    hasEhrIntegration: boolean
    hasPayments: boolean
}

const LocationFormModal = ({ practice, hasEhrIntegration, hasPayments }: Props) => {
    const dispatch = useAppDispatch()
    const { location, isEdit, isOpen, isLoading } = useSelector(
        (state: RootState) => state.practiceLocations[practice.id].modals.locationAddEdit,
    )
    const isPaymentProductActivated = useMemo(
        () => practice.products.find(product => product.value === SimplifeyePayments.value)?.active === true,
        [practice.products],
    )

    const handleCloseModal = () => {
        if (isLoading) return

        dispatch(closeLocationAddEditModal(practice.id))
    }

    const handleOpenDeleteLocationModal = (): void => {
        if (isLoading || !location) return

        dispatch(updateLocationAddEditData({ showDeleteConfirmation: true }, practice.id))
    }

    const handleSubmit = async (
        formValues: LocationFormDataModel,
        hasEhrIntegrationFields: boolean,
        hasDirectSchedulingFields: boolean,
    ) => {
        if (isLoading) return

        let locationUpdateData: Partial<LocationDataModel> = {}
        Object.entries(formValues).forEach(([fieldKey, fieldValue]) => {
            locationUpdateData[fieldKey] = fieldValue?.value
        })
        const isLogoTouched = Boolean(formValues?.logo?.isTouched)
        const hasStripeAccount = Boolean(location?.stripeConnectedAccountId)

        if (isEdit && location) {
            dispatch(
                updateLocation(
                    practice,
                    locationUpdateData,
                    location,
                    hasDirectSchedulingFields,
                    hasStripeAccount,
                    isLogoTouched,
                ),
            )
        } else {
            const newLocationId = await dispatch(
                createLocation(practice, locationUpdateData, hasDirectSchedulingFields, false, isLogoTouched),
            )
        }
    }

    if (!isOpen) {
        return null
    }

    return (
        <Modal
            open={isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleCloseModal()
                }
            }}
            disableEscapeKeyDown
        >
            <LocationForm
                practiceId={practice.id}
                practiceName={practice.name}
                locationData={location}
                isLoading={isLoading}
                isEdit={isEdit}
                hasEhrIntegration={hasEhrIntegration}
                hasPayments={hasPayments}
                onClose={handleCloseModal}
                onDeleteLocation={handleOpenDeleteLocationModal}
                onSubmit={handleSubmit}
            />
        </Modal>
    )
}

export default LocationFormModal
