import * as React from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import _ from 'lodash'

import { MicroMD, Vision } from '../../../models/enums'
import CopyText from '../../shared/CopyText'
import { unbindPracticeAgent, updatePracticeAgent } from '../actions'

export type AgentProps = {
    practice: Models.Practice
    agent: Models.PracticeAgent
}

export type AgentDispatch = {
    updatePracticeAgent: typeof updatePracticeAgent
    unbindPracticeAgent: typeof unbindPracticeAgent
}

export type AgentState = {
    futureDays: number | ''
    editing: boolean
}

type Props = AgentProps & AgentDispatch

import './Agent.sass'

class Agent extends React.Component<Props, AgentState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            futureDays: props.agent.futureDays,
            editing: false,
        }
    }

    onChangeLocation = (locationId: string) => {
        if (this.props.agent.locationId === locationId) {
            return
        }
        const location = this.props.practice.locations.find(l => l.id === locationId)
        if (this.confirmChange('Location', location ? location.name : locationId)) {
            this.props.updatePracticeAgent(this.props.practice, this.props.agent, { locationId })
        }
    }

    onChangeSendData = (sendData: boolean) => {
        if (this.confirmChange('Send data', `${sendData}`)) {
            this.props.updatePracticeAgent(this.props.practice, this.props.agent, { sendData })
        }
    }

    onChangeFutureDays = (days: string) => {
        const futureDays = parseInt(days, 10)
        if (isNaN(futureDays)) {
            this.setState({ futureDays: '' })
        }
        if (futureDays < 0) {
            return
        }
        this.setState({ futureDays })
    }

    confirmChange(field: string, val: string | number) {
        return window.confirm(`Are you sure you want to change '${field}' to '${val}'?`)
    }

    onUnbindAgent = (agent: Models.PracticeAgent) => {
        if (window.confirm(`Are you sure you want to unbind '${agent.name}'`)) {
            this.props.unbindPracticeAgent(this.props.practice, agent)
        }
    }

    onCheckKeyUp = (event: any) => {
        if (event.key === 'Escape') {
            event.target.blur()
            this.setState({ futureDays: this.props.agent.futureDays, editing: false })
        }
        if (event.key === 'Enter') {
            this.onPromptSave(event.target.value)
            event.target.blur()
        }
    }

    onPromptSave = (days: string) => {
        const futureDays = parseInt(days, 10)
        if (isNaN(futureDays)) {
            this.setState({ futureDays: this.props.agent.futureDays, editing: false })
            return
        }
        if (this.confirmChange('Future Days', futureDays)) {
            this.props
                .updatePracticeAgent(this.props.practice, this.props.agent, { futureDays })
                .then(() => this.setState({ editing: false }))
            return
        }
        this.setState({ futureDays: this.props.agent.futureDays, editing: false })
    }

    onMakeEditable = () => {
        this.setState({ editing: true })
    }
    render() {
        const { agent, practice } = this.props
        const { editing } = this.state

        return (
            <TableRow className="agent">
                <TableCell className="cell id">
                    <div>
                        <CopyText tooltip={true} text={agent.id} />
                    </div>
                </TableCell>
                <TableCell className="cell name">{agent.name}</TableCell>
                <TableCell className="cell">{agent.createdAt.toLocaleDateString()}</TableCell>
                <TableCell className="cell">{agent.updatedAt.toLocaleDateString()}</TableCell>
                <TableCell className="cell location">
                    <Select
                        value={agent.locationId || ''}
                        fullWidth={true}
                        onChange={event => this.onChangeLocation(event.target.value as string)}
                        disabled={
                            practice.integrationType.name === MicroMD.name ||
                            practice.integrationType.name === Vision.name
                        }
                        variant="standard"
                    >
                        {_.sortBy(practice.locations, l => l.name.toLowerCase()).map(location => (
                            <MenuItem key={location.id} value={location.id}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell className="cell">
                    <div className="data">
                        <Switch
                            color="primary"
                            checked={agent.sendData}
                            onChange={event => this.onChangeSendData(event.target.checked)}
                        />
                    </div>
                </TableCell>
                <TableCell className="cell">
                    <div className="future-days">
                        <TextField
                            className="input"
                            type="number"
                            value={this.state.futureDays}
                            onChange={event => this.onChangeFutureDays(event.target.value)}
                            onClick={this.onMakeEditable}
                            onKeyUp={this.onCheckKeyUp}
                        />
                        {editing && (
                            <span onClick={() => this.onPromptSave(this.state.futureDays.toString())}>
                                <i className="material-icons icon">check</i>
                            </span>
                        )}
                        {editing && (
                            <span onClick={() => this.onPromptSave('')}>
                                <i className="material-icons icon">close</i>
                            </span>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <Button className="button" onClick={() => this.onUnbindAgent(agent)}>
                        Remove
                    </Button>
                </TableCell>
            </TableRow>
        )
    }
}

export default Agent
