import iassign from 'immutable-assign'
import { createTransform, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { ConnectBooking, ConnectBookingFormElements, ConnectPaymentStatus, PatientType } from '../../../models/Connect'
import { setFieldRequired } from '../../shared/form-validator/validator'
import { isBasicPhoneNumber, isBirthDate, isEmail, validate } from '../../shared/form-validator/validator'

import {
    InitConnect,
    ReceivePaymentDetails,
    ReceiveTeleConnectBooking,
    ReceiveTelehealthSession,
    SetTeleConnectBookingPending,
    ShareTeleConnect,
    UpdateConnect,
    UpdateConnectPaymentStatus,
    UpdateFormFields,
} from './actions'

export type ConnectState = { [key: string]: ConnectBooking }

type ConnectAction =
    | InitConnect
    | UpdateConnect
    | UpdateFormFields
    | ReceiveTeleConnectBooking
    | SetTeleConnectBookingPending
    | ShareTeleConnect
    | UpdateConnectPaymentStatus
    | ReceivePaymentDetails
    | ReceiveTelehealthSession

const initialState: ConnectState = {}
const PERSIST_WRITE_THROTTLE = 1500

const initialConnect = (
    chat: Models.ChatMetadata,
    isInsuranceRequired: boolean,
    patientType: PatientType,
    isPaymentAvailable: boolean,
): ConnectBooking => {
    const isPolicyHolder = true
    return applyValidators({
        chat,
        patientType,
        isInsuranceRequired,
        isPolicyHolder,
        isPaymentAvailable,
        paymentStatus: ConnectPaymentStatus.NotSubmitted,
        formElements: {
            firstName: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            lastName: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            dateOfBirth: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            mobilePhone: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            email: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            practiceLocation: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            doxyURLIndex: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            insurance: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            insurancePhone: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            insuranceGroup: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            insuranceMemberId: {
                value: '',
                isValid: false,
                isDirty: false,
            },
            policyHolderFirstName: {
                value: '',
                isValid: true,
                isDirty: false,
            },
            policyHolderLastName: {
                value: '',
                isValid: true,
                isDirty: false,
            },
            policyHolderDateOfBirth: {
                value: '',
                isValid: true,
                isDirty: false,
            },
        },
        isFormValid: false,
        isFormDirty: false,
        isPending: false,
        isBooked: false,
        isShared: false,
        hasError: false,
    })
}

const applyValidators = (state: ConnectBooking): ConnectBooking => {
    const nextConnect = { ...state }
    setFieldRequired(nextConnect.formElements.firstName)
    setFieldRequired(nextConnect.formElements.lastName)
    setFieldRequired(nextConnect.formElements.dateOfBirth, true, [isBirthDate()])
    setFieldRequired(nextConnect.formElements.mobilePhone, true, [isBasicPhoneNumber()])
    setFieldRequired(nextConnect.formElements.email, nextConnect.isPaymentAvailable, [isEmail()])
    setFieldRequired(nextConnect.formElements.practiceLocation)
    setFieldRequired(nextConnect.formElements.doxyURLIndex)

    setFieldRequired(nextConnect.formElements.insurance, nextConnect.isInsuranceRequired)
    setFieldRequired(nextConnect.formElements.insurancePhone, nextConnect.isInsuranceRequired)
    setFieldRequired(nextConnect.formElements.insuranceGroup, nextConnect.isInsuranceRequired)
    setFieldRequired(nextConnect.formElements.insuranceMemberId, nextConnect.isInsuranceRequired)

    const isNotPolicyHolder = !nextConnect.isPolicyHolder && nextConnect.isInsuranceRequired

    setFieldRequired(nextConnect.formElements.policyHolderFirstName, isNotPolicyHolder)
    setFieldRequired(nextConnect.formElements.policyHolderLastName, isNotPolicyHolder)
    setFieldRequired(nextConnect.formElements.policyHolderDateOfBirth, isNotPolicyHolder, [isBirthDate()])

    if (nextConnect.isFormDirty) {
        nextConnect.isFormValid = validate(nextConnect.formElements)
    }

    return nextConnect
}

export function reducer(state: ConnectState = initialState, action: ConnectAction) {
    switch (action.type) {
        case '@CONNECT/INIT': {
            return iassign(
                state,
                next => next,
                nextConnects => {
                    if (!nextConnects[action.chat.id]) {
                        nextConnects[action.chat.id] = initialConnect(
                            action.chat,
                            action.isInsuranceRequired,
                            action.patientType,
                            action.isPaymentAvailable,
                        )
                    }
                    return nextConnects
                },
            )
        }
        case '@CONNECT/UPDATE': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.patientType = action.patientType
                    nextConnect.isInsuranceRequired = action.isInsuranceRequired
                    nextConnect.isPolicyHolder = action.isPolicyHolder
                    return applyValidators(nextConnect)
                },
            )
        }
        case '@CONNECT/UPDATE_FORM_FIELDS': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id]
                },
                nextForm => {
                    nextForm.formElements = action.formElements
                    nextForm.isFormDirty = true
                    nextForm.isFormValid = validate(nextForm.formElements)
                    return nextForm
                },
            )
        }
        case '@CONNECT/UPDATE_CONNECT_PAYMENT_STATUS': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.paymentStatus = action.connectPaymentStatus
                    return nextConnect
                },
            )
        }
        case '@CONNECT/BOOKING_PENDING': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.isBooked = false
                    nextConnect.hasError = false
                    nextConnect.isPending = true
                    return nextConnect
                },
            )
        }
        case '@CONNECT/BOOKED': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.isPending = false
                    nextConnect.isBooked = Boolean(action.booked)
                    nextConnect.hasError = action.status === 'error'
                    nextConnect.errorMessage = action.message || undefined
                    return nextConnect
                },
            )
        }
        case '@CONNECT/SHARED': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.isShared = true
                    return nextConnect
                },
            )
        }
        case '@CONNECT/RECEIVE_PAYMENT_DETAILS': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.paymentPatientId = action.paymentDetails.patientId
                    nextConnect.paymentPayerId = action.paymentDetails.payerId
                    return nextConnect
                },
            )
        }
        case '@CONNECT/RECEIVE_TELEHEALTH_SESSION_ID': {
            return iassign(
                state,
                next => next[action.chat.id],
                nextConnect => {
                    nextConnect.connectTelehealthSessionId = action.telehealthSessionId
                    return nextConnect
                },
            )
        }
        default:
            return state
    }
}

// Removing validators functions on saving to the storage
// and restoring them on reading from storage
const transformReducer = createTransform(
    (inboundState: ConnectBooking): ConnectBooking => {
        if (!Boolean(inboundState.formElements)) {
            return inboundState
        }
        const transformedReducer = {
            ...inboundState,
            formElements: Object.keys(inboundState.formElements).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: {
                        ...inboundState.formElements[key],
                        validators: [],
                    },
                }),
                {},
            ) as ConnectBookingFormElements,
        }
        return transformedReducer
    },
    (outboundState: ConnectBooking): ConnectBooking => {
        if (!Boolean(outboundState.formElements)) {
            return outboundState
        }
        const transformedReducer = applyValidators(outboundState)
        return transformedReducer
    },
)

export const persistConfig = {
    key: '@booking/connect',
    transforms: [transformReducer],
    storage,
    throttle: PERSIST_WRITE_THROTTLE,
    blacklist: ['isShared', 'isPending'],
}

export const persistedReducer = persistReducer(persistConfig, reducer)
