import React, { useState } from 'react'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel, { FormControlLabelClassKey } from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'

import { RootState } from '../../appReducer'
import { BookingMap } from '../../models/BookingAppointment'
import { mainBlue } from '../shared/styles/colors'

import { APP_ERROR_MESSAGE_TIMEOUT } from './direct-scheduling/shared'

import './EndChatModal.sass'

const moduleName = 'end-chat-modal'

const CLOSE_REASONS_1 = [
    { value: 'estimate', display: 'Patient wants an estimate' },
    { value: 'second_opinion', display: 'Patient wants a second opinion' },
    { value: 'insurance', display: 'Patient has insurance inquiry' },
    { value: 'test', display: 'Test' },
    { value: 'other', display: 'Other' },
]

const CLOSE_REASONS_2 = [
    { value: 'appointment', display: 'Current patient appointment' },
    { value: 'emergency_appointment', display: 'Current patient emergency appointment' },
    { value: 'new_appointment', display: 'New patient appointment' },
    { value: 'new_emergency_appointment', display: 'New patient emergency appointment' },
]

interface CloseReason {
    value: Models.ChatReasonValue
    display: string
}

interface StatusButtonProps {
    value: 'successful' | 'unsuccessful'
    selected: boolean
    label: string
    icon: string
    iconVariant?: 'outlined'
    disabled?: boolean
    onSelect: Models.VoidFunction
}

const StatusButton: React.SFC<StatusButtonProps> = ({
    value,
    selected,
    label,
    icon,
    iconVariant,
    onSelect,
    disabled,
}) => (
    <Button
        color="primary"
        variant="outlined"
        className={classNames('status-button', {
            [`status-button--${value}`]: selected,
            'status-button--default': !selected,
            'status-button--disabled': disabled,
        })}
        onClick={onSelect}
        disabled={disabled}
    >
        <div className="status-button__content">
            <Icon className={`status-button__icon ${iconVariant && `material-icons-${iconVariant}`}`}>{icon}</Icon>
            <span className="status-button__label">{label}</span>
        </div>
    </Button>
)

type EndChatModalProps = {
    isModalOpen: boolean
    chat: Models.ChatCenterSelectedChat
    bookings: BookingMap
    closeModal: () => void
    onCloseChat: (close: Models.ChatClose) => void
}

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    root: `${moduleName}__form-control-root`,
    label: `${moduleName}__form-control-label`,
}

const EndChatModal = ({ isModalOpen, closeModal, chat, bookings, onCloseChat }: EndChatModalProps) => {
    const [isCopied, setCopied] = useState<boolean>(false)
    const [successful, setSuccessful] = useState<'successful' | 'unsuccessful'>()
    const [reasons, setReason] = useState<Models.ChatReasonValue[]>([])
    const [message, setMessage] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const hasTimeoutError = Boolean(
        bookings?.[chat.id]?.existingPatient?.errorMessage === APP_ERROR_MESSAGE_TIMEOUT ||
            bookings?.[chat.id]?.newPatient?.errorMessage === APP_ERROR_MESSAGE_TIMEOUT,
    )
    const schedulingAppointments = useSelector((state: RootState) => state.chat.chats[chat.id]?.schedulingAppointments)
    const isAppointmentAlreadyBooked =
        bookings?.[chat.id]?.appointment || (schedulingAppointments && schedulingAppointments.length > 0)

    const connectBooking = useSelector((state: RootState) => state.bookings.connect)

    const template = `${chat.patientName || chat.ip} - ${chat.practice.name}`

    const isBookingConnected = connectBooking[chat.id]?.isBooked

    const canSave = successful && reasons.length > 0 && (message || !reasons.find(r => r === 'other'))

    const copyTemplate = () => {
        copy(template)
        setCopied(true)
    }

    const onSelectReason = (reason: Models.ChatReasonValue, checked: boolean) => {
        if (checked) {
            setReason([...reasons, reason])
        } else {
            setReason(reasons.filter(r => r !== reason))
            setMessage(reason === 'other' ? undefined : message)
        }
    }

    const onOtherTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const message = event.target && event.target.value
        setMessage(message)
    }

    const closeChat = () => {
        setLoading(true)
        onCloseChat({ reasons, message, success: successful === 'successful' })
    }

    const renderReason = (reason: CloseReason) => (
        <FormControlLabel
            key={reason.value}
            classes={formControlLabelClassOverrides}
            control={
                <Checkbox
                    key={reason.value}
                    value={reason.display}
                    color="primary"
                    className={`${moduleName}__reason-checkbox`}
                    onChange={(e: any, checked: any) => onSelectReason(reason.value, checked)}
                    checked={Boolean(reasons.find(r => r === reason.value))}
                    disabled={!successful}
                />
            }
            label={reason.display}
        />
    )

    return (
        <Modal open={isModalOpen} onClose={closeModal} disableEscapeKeyDown={loading}>
            <div className={moduleName}>
                <Paper className={`${moduleName}__modal`}>
                    {loading && (
                        <div className={`${moduleName}__loading-overlay`}>
                            <ReactLoading type="spin" color={mainBlue} height={96} width={96} />
                        </div>
                    )}

                    <div className={`${moduleName}__modal-header`}>
                        <IconButton size="large" onClick={closeModal}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>

                    <div className={`${moduleName}__template-info`}>
                        <h3>Are you sure you want to close this chat?</h3>
                        <p>
                            Please note: If the patient messages in after the chat is closed, it will begin a new chat.
                        </p>
                    </div>
                    <div className={`${moduleName}__template-container`}>
                        <Paper className={`${moduleName}__template`}>
                            <div className={`${moduleName}__trello-card`}>
                                <pre>{template}</pre>
                                <IconButton
                                    size="large"
                                    className={`${moduleName}__${isCopied ? 'trello-copied' : 'trello-copy'}`}
                                    onClick={copyTemplate}
                                >
                                    <Icon>content_copy</Icon>
                                </IconButton>
                            </div>
                        </Paper>
                    </div>

                    <div className={`${moduleName}__modal-content`}>
                        <h2 className={`${moduleName}__modal-actions-header`}>
                            Please choose a status for this request:
                        </h2>
                        <div className={`${moduleName}__modal-actions`}>
                            <StatusButton
                                value="successful"
                                selected={successful === 'successful'}
                                label="Successful"
                                icon="check_circle"
                                onSelect={() => setSuccessful('successful')}
                            />
                            <StatusButton
                                value="unsuccessful"
                                selected={successful === 'unsuccessful'}
                                label="Unsuccessful"
                                icon="sentiment_very_dissatisfied"
                                iconVariant="outlined"
                                disabled={Boolean(isAppointmentAlreadyBooked) || isBookingConnected || hasTimeoutError}
                                onSelect={() => setSuccessful('unsuccessful')}
                            />
                        </div>
                        <div className={`${moduleName}__close-reasons`}>
                            <span className={classNames(`${moduleName}__reason-text`, { disabled: !successful })}>
                                Please select reason for patient chat:
                            </span>
                            <div className={`${moduleName}__reason-container`}>
                                <div className={`${moduleName}__modal-col`}>{CLOSE_REASONS_1.map(renderReason)}</div>
                                <div className={`${moduleName}__modal-col`}>{CLOSE_REASONS_2.map(renderReason)}</div>
                            </div>

                            {reasons.find(r => r === 'other') && (
                                <TextField
                                    className={`${moduleName}__other-text`}
                                    multiline={true}
                                    fullWidth={true}
                                    label="Please describe the patient's reason for referral"
                                    onChange={onOtherTextChange}
                                    value={message}
                                    variant="standard"
                                />
                            )}
                        </div>

                        <div className={`${moduleName}__close-chat`}>
                            <Button
                                className={`${moduleName}__close-chat-button ${moduleName}__close-chat-button--primary`}
                                variant="outlined"
                                onClick={closeModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={`${moduleName}__close-chat-button ${
                                    !canSave ? `${moduleName}__close-chat-button--disabled` : ``
                                }`}
                                disabled={!canSave}
                                variant="contained"
                                onClick={closeChat}
                            >
                                Close Chat
                            </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        </Modal>
    )
}

export default EndChatModal
