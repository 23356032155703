import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import './StaffSuccessBox.sass'

export type StaffSuccessBoxType =
    | 'resendInvitation'
    | 'deleteAccount'
    | 'resetPassword'
    | 'deactivateAccount'
    | 'reactivateAccount'
    | 'restoreAccount'
    | 'deleteEmail'
    | 'deletePhoneNumber'
    | 'impersonateUser'

export type StaffConfrimationBoxProps = {
    type?: StaffSuccessBoxType
    employee?: ModelsV2.Practice.PracticeStaffData
}

export type StaffConfrimationBoxDispatch = {
    closeFn: () => void
}

export interface StaffSuccessBoxParams {
    type: StaffSuccessBoxType
}

type Props = StaffConfrimationBoxProps & StaffConfrimationBoxDispatch

const StaffSuccessBox = ({ employee, type, closeFn }: Props) => {
    const buildText = () => {
        const employeeName = `${employee?.first_name} ${employee?.last_name}`
        switch (type) {
            case 'resendInvitation':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons green">email</i>
                            </Grid>
                            <Grid item={true} className="text">
                                Invitation has been sent to {employeeName}.
                            </Grid>
                        </Grid>
                    </>
                )
            case 'impersonateUser':
                return (
                    <Grid
                        container={true}
                        spacing={2}
                        className="title"
                        wrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item={true}>
                            <i className="material-icons red">warning</i>
                        </Grid>
                        <Grid item={true} className="text">
                            User will be logged out of their account and you will be logged in as them.
                        </Grid>
                    </Grid>
                )
            case 'deleteAccount':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">remove_circle</i>
                            </Grid>
                            <Grid item={true} className="text">
                                Account has been deleted.
                            </Grid>
                        </Grid>
                    </>
                )
            case 'deactivateAccount':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">flash_off</i>
                            </Grid>
                            <Grid item={true} className="text">
                                Account has been deactivated.
                            </Grid>
                        </Grid>
                    </>
                )
            case 'reactivateAccount':
                return (
                    <Grid
                        container={true}
                        spacing={2}
                        className="title"
                        wrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item={true}>
                            <i className="material-icons green">flash_on</i>
                        </Grid>
                        <Grid item={true} className="text">
                            Account has been reactivated.
                        </Grid>
                    </Grid>
                )
            case 'restoreAccount':
                return (
                    <Grid
                        container={true}
                        spacing={2}
                        className="title"
                        wrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Grid item={true}>
                            <i className="material-icons green">cached</i>
                        </Grid>
                        <Grid item={true} className="text">
                            Account has been restored.
                        </Grid>
                    </Grid>
                )
            case 'resetPassword':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons green">check_circle</i>
                            </Grid>
                            <Grid className="text">”A password reset link has been sent to {employee?.username}”</Grid>
                        </Grid>
                    </>
                )
            case 'deleteEmail':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">email</i>
                            </Grid>
                        </Grid>
                        <div>”An email has been deleted”</div>
                    </>
                )
            case 'deletePhoneNumber':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">warning</i>
                            </Grid>
                        </Grid>
                        <div>Phone number has been deleted.</div>
                    </>
                )
            default:
                return null
        }
    }

    return (
        <div className="confirmation-container">
            <div className="confirmation-inner-container">
                {buildText()}
                <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                    <Grid item={true}>
                        <Button variant="outlined" onClick={closeFn}>
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default StaffSuccessBox
