import * as React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import classNames from 'classnames'

import { setRedirect as setRedirectFn } from '../../appActions'
import { AccountTypes } from '../../models/enums'
import { fetchPractice, savePractice } from '../practices/actions'
import PracticeHeader from '../shared/PracticeHeader'

import AmplifyManagementContainer from './AmplifyManagementContainer'
import ChatCenterArchive from './ChatCenterArchive'
import DexVoiceDiagnosticsContainer from './DexVoiceDiagnosticsContainer'

import './PracticeDiagnostics.sass'

export type PracticeDiagnosticsProps = {
    readonly account?: Models.Account
    readonly practice?: Models.Practice
}

export type PracticeDiagnosticsDispatch = {
    readonly fetchPractice: typeof fetchPractice
    readonly setRedirect: typeof setRedirectFn
    readonly savePractice: typeof savePractice
}

export type PracticeDiagnosticsState = {}

// TODO probably should be moved into some routes module/namespace/section
export type PracticeDiagnosticsParams = RouteComponentProps<{
    practiceId: string
}>

type Props = PracticeDiagnosticsProps & PracticeDiagnosticsDispatch & PracticeDiagnosticsParams

class PracticeDiagnostics extends React.PureComponent<Props, PracticeDiagnosticsState> {
    UNSAFE_componentWillMount() {
        this.props.fetchPractice(this.props.match.params.practiceId)
    }

    onReturnToPractices = () => {
        this.props.setRedirect(`/practices`)
    }

    render() {
        const { practice, match, account } = this.props

        if (!practice || !account) {
            return (
                <div className="circular-progress-loader">
                    <CircularProgress size={70} color="primary" variant="indeterminate" />
                </div>
            )
        }

        const canEdit =
            account &&
            (account.type.id === AccountTypes.SimplifeyeStaff ||
                account.type.id === AccountTypes.SuperAdmin ||
                account.type.id === AccountTypes.PattersonStaff)

        return (
            <div className={classNames('practice-diagnostics')}>
                <div className="detached-button" onClick={this.onReturnToPractices}>
                    <i className="material-icons">arrow_back</i>
                </div>
                <PracticeHeader canEdit={canEdit || false} practice={practice} />
                <Route path={`${match.path}/amplify`} component={AmplifyManagementContainer} />
                <Route path={`${match.path}/dexvoice`} component={DexVoiceDiagnosticsContainer} />
                <Route path={`${match.path}/chat-center`} component={ChatCenterArchive} />
            </div>
        )
    }
}

export default PracticeDiagnostics
