import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox, FormControlLabel, Grid, Switch } from '@mui/material'
import { FormControlLabelClassKey } from '@mui/material/FormControlLabel'
import { SwitchClassKey } from '@mui/material/Switch'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import { CustomFieldType } from '../../../models/enums'
import { receiveFormSectionMetaData, receiveFormValueChange } from '../../../modules/referrals/referral-form/v2actions'
import { fetchSchedulingAppointments } from '../../../modules/referrals/v2actions'
import CustomField from '../../../modules/shared/custom-fields/CustomField'
import CustomPhoneInput from '../../../modules/shared/custom-fields/CustomPhoneInput'
import { formatDateOfBirth } from '../../../modules/shared/dateUtils'
import ErrorMessage from '../../../modules/shared/error-message/error-message'
import { useAppDispatch } from '../../../util/useAppDispatch'
import MaskedInlineDatepicker from '../../shared/custom-fields/MaskedInlineDatepicker'
import { isDobDate } from '../../shared/form-validator/isDateValid'
import { ReferralFormSections } from '../shared/enums'

import './ReferralPatientInfo.sass'

const DATE_MASK = '99/99/9999'
const TELE_APPOINTMENT = 'TELE-APPOINTMENT'
const TEST_FIELDS = [
    'date_of_birth',
    'desired_appointment_time',
    'email',
    'first_name',
    'inbound_source',
    'insurance_provider',
    'last_name',
    'phone_number',
    'reason',
    'is_primary',
]

export type Props = {
    referral?: ModelsV2.Referrals.ReferralV2
    practiceLocations?: ModelsV2.Practice.PracticeLocationsV2[]
    patient_data?: ModelsV2.Referrals.ReferralPatient
    testReferral: boolean | undefined
    failedSave: boolean
    dateFormat: string
}

const moduleName = 'referral-patient-info'

type FormControlLabelClassOverrides = {
    readonly [key in FormControlLabelClassKey]?: string
}

const formControlLabelClassOverrides: FormControlLabelClassOverrides = {
    label: `${moduleName}__form-control-label`,
}

type SwitchClassOverrides = {
    readonly [key in SwitchClassKey]?: string
}

const switchClassOverrides: SwitchClassOverrides = {
    root: `${moduleName}__switch-base`,
}

const appointmentTime = (time?: string, timezone?: string) => {
    if (time === TELE_APPOINTMENT) {
        return TELE_APPOINTMENT
    }
    if (!time) {
        return ''
    }
    if (!timezone) {
        return time
    }

    return moment.tz(new Date(time), timezone).format('MM/DD/YYYY h:mm A (z)')
}

const ReferralPatientInfo = (props: Props) => {
    const [isPhoneNumberChanged, setPhoneNumberChanged] = useState(false)

    const { failedSave, referral, dateFormat, practiceLocations, patient_data, testReferral } = props
    const phone_number = patient_data?.phone_number
    const first_name = patient_data?.first_name
    const last_name = patient_data?.last_name
    const scheduling_appointment_id = patient_data?.scheduling_appointment_id
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (testReferral) {
            dispatch(
                receiveFormSectionMetaData(ReferralFormSections.PRIMARY_PATIENT, {
                    dirtyFields: TEST_FIELDS,
                }),
            )
        }
    }, [dispatch, testReferral])

    useEffect(() => {
        if (scheduling_appointment_id) {
            dispatch(fetchSchedulingAppointments(scheduling_appointment_id))
        }
    }, [dispatch, scheduling_appointment_id])

    const schedulingAppointment = useSelector(
        (state: RootState) =>
            scheduling_appointment_id && state.v2.referrals.schedulingAppointments[scheduling_appointment_id],
    ) as ModelsV2.Referrals.SchedulingAppointment

    const onChangeValue = (field: string, value: string | boolean | null) => {
        dispatch(receiveFormValueChange(ReferralFormSections.PRIMARY_PATIENT, field, value))
    }

    const appointmentInSyncStatus = schedulingAppointment?.scheduling_appointment_status?.value
    useEffect(() => {
        const interval = setInterval(() => {
            if (appointmentInSyncStatus === 'sync_pending' && scheduling_appointment_id) {
                dispatch(fetchSchedulingAppointments(scheduling_appointment_id))
            }
        }, 20000)
        return () => clearInterval(interval)
    }, [dispatch, appointmentInSyncStatus, scheduling_appointment_id])

    const practiceLocationTimezone =
        referral?.location_id && practiceLocations?.find(location => location.id === referral?.location_id)?.timezone

    const fieldErrors = useSelector((state: RootState) => state.v2.referrals.referralForm?.primaryPatient.errors)

    const errorMsg = (fieldName: string): string => {
        if (!failedSave) {
            return ''
        }
        const err = fieldErrors?.find(err => {
            return err.metadata?.propertyName === fieldName
        })
        if (err) {
            return err.details
        }
        return ''
    }
    const hasError = (field: string): boolean => {
        return errorMsg(field) !== ''
    }

    const showDirectScheduled = referral?.direct_scheduled && schedulingAppointment
    const dob = patient_data?.date_of_birth ? formatDateOfBirth(patient_data?.date_of_birth) : ''
    const policyDob = patient_data?.policy_holder_date_of_birth
        ? formatDateOfBirth(patient_data?.policy_holder_date_of_birth)
        : ''
    return (
        <div className={moduleName}>
            <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                    <div className={`${moduleName}__header`}>
                        <p className={`${moduleName}__header-title`}>Patient Info</p>
                        <FormControlLabel
                            label={<p className={`${moduleName}__header-checkbox-label`}>Existing patient?</p>}
                            control={
                                <Checkbox
                                    color="default"
                                    className={`${moduleName}__header-checkbox-icon`}
                                    checked={patient_data?.existing_patient || false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = event.target.checked
                                        onChangeValue('existing_patient', value)
                                    }}
                                />
                            }
                        />
                    </div>
                </Grid>
                {schedulingAppointment?.scheduling_appointment_status?.value === 'sync_pending' && (
                    <Grid item={true} xs={12} className={`${moduleName}__field-message`}>
                        <ErrorMessage>This appointment has pending to be synced to your PMS.</ErrorMessage>
                    </Grid>
                )}
                {schedulingAppointment?.scheduling_appointment_status?.value === 'sync_unsuccessful' && (
                    <Grid item={true} xs={12} className={`${moduleName}__field-message`}>
                        <ErrorMessage>
                            This appointment has not been scheduled yet. It will be added to the PMS next time we are
                            able to connect to the PMS.
                        </ErrorMessage>
                    </Grid>
                )}
                <Grid container={true} spacing={0}>
                    <Grid item={true} className={`${moduleName}__field`} xs={12} sm={6}>
                        <CustomField
                            customFieldType={CustomFieldType.INPUT}
                            label="First Name*"
                            required={true}
                            error={hasError('first_name')}
                            errorMessage={errorMsg('first_name')}
                            errorMessageInTooltip={true}
                            value={first_name || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value
                                onChangeValue('first_name', value)
                            }}
                        />
                    </Grid>
                    <Grid item={true} className={`${moduleName}__field`} xs={12} sm={6}>
                        <CustomField
                            customFieldType={CustomFieldType.INPUT}
                            label="Last Name*"
                            required={true}
                            error={hasError('last_name')}
                            errorMessage={errorMsg('last_name')}
                            errorMessageInTooltip={true}
                            value={last_name || ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const value = e.target.value
                                onChangeValue('last_name', value)
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} spacing={0}>
                    <Grid item={true} xl={6} lg={12} md={12} sm={12} xs={12} className={`${moduleName}__field`}>
                        <div className={`${moduleName}__datepicker-wrapper-patient-dob`}>
                            <MaskedInlineDatepicker
                                dateMask={DATE_MASK}
                                dateFormat={dateFormat}
                                label="Patient DOB"
                                value={dob}
                                error={(Boolean(dob) && !isDobDate(dob)) || hasError('date_of_birth')}
                                errorMessage={'Invalid Date' || errorMsg('date_of_birth')}
                                onInputChange={(targetValue: string | boolean | null) => {
                                    onChangeValue('date_of_birth', targetValue)
                                }}
                                onChange={(value: string | boolean | null) => {
                                    onChangeValue('date_of_birth', value)
                                }}
                                validator={isDobDate}
                            />
                        </div>
                    </Grid>
                    <Grid item={true} xl={6} lg={12} md={12} sm={12} xs={12} className={`${moduleName}__field`}>
                        <CustomPhoneInput
                            label="Patient Phone #"
                            value={phone_number}
                            country="US"
                            error={hasError('phone_number')}
                            errorMessage={errorMsg('phone_number')}
                            onChange={(value: string) => {
                                if (!isPhoneNumberChanged) {
                                    setPhoneNumberChanged(true)
                                }
                                onChangeValue('phone_number', value === undefined ? null : value || null)
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} className={`${moduleName}__field`}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Patient Email"
                        inputType="email"
                        value={patient_data?.email || ''}
                        error={hasError('email')}
                        errorMessage={errorMsg('email')}
                        errorMessageInTooltip={true}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value || null
                            onChangeValue('email', value)
                        }}
                    />
                </Grid>

                <Grid item={true} xs={12} className={`${moduleName}__field`}>
                    <CustomField
                        customFieldType={CustomFieldType.TEXTAREA}
                        label="Chat Reason*"
                        rows={1}
                        required={true}
                        autoSize={true}
                        error={hasError('reason')}
                        errorMessage={errorMsg('reason')}
                        errorMessageInTooltip={true}
                        value={patient_data?.reason || ''}
                        autosizeOnUpdate={true}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                            const value = event.target.value
                            onChangeValue('reason', value)
                        }}
                    />
                </Grid>

                {showDirectScheduled && (
                    <>
                        <Grid item={true} xs={6} className={`${moduleName}__field-static`}>
                            <div className={`${moduleName}__field-label`}>Appointment Date and Time</div>
                            <div className={`${moduleName}__field-text`}>
                                {appointmentTime(patient_data?.desired_appointment_time, practiceLocationTimezone)}
                            </div>
                        </Grid>

                        <Grid item={true} xs={6} className={`${moduleName}__field-static`}>
                            <div className={`${moduleName}__field-label`}>Operatory Name</div>
                            <div className={`${moduleName}__field-text`}>
                                {schedulingAppointment?.scheduling_operatory?.name ||
                                    schedulingAppointment?.scheduling_operatory?.num}
                            </div>
                        </Grid>
                        <Grid item={true} xs={6} className={`${moduleName}__field-static`}>
                            <div className={`${moduleName}__field-label`}>Provider Name</div>
                            <div className={`${moduleName}__field-text`}>
                                {`${schedulingAppointment?.scheduling_provider?.first_name} ${schedulingAppointment?.scheduling_provider?.last_name}`}
                            </div>
                        </Grid>
                        <Grid item={true} xs={6} className={`${moduleName}__field-static`}>
                            <div className={`${moduleName}__field-label`}>Procedure</div>
                            <div className={`${moduleName}__field-text`}>
                                {schedulingAppointment?.scheduling_procedure?.name}
                            </div>
                        </Grid>
                    </>
                )}

                {!referral?.direct_scheduled && (
                    <Grid item={true} xs={12} className={`${moduleName}__field`}>
                        <CustomField
                            customFieldType={CustomFieldType.INPUT}
                            label="Desired Visit Date"
                            inputType="text"
                            error={hasError('desired_appointment_time')}
                            errorMessage={errorMsg('desired_appointment_time')}
                            value={patient_data?.desired_appointment_time || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value = event.target.value
                                onChangeValue('desired_appointment_time', value)
                            }}
                        />
                    </Grid>
                )}

                <Grid item={true} xs={12}>
                    <h3 className={`${moduleName}__section-title`}>
                        <span className={`${moduleName}__title`}>Insurance</span>
                        <FormControlLabel
                            control={
                                <Switch
                                    color="primary"
                                    checked={patient_data?.patient_is_policy_holder ?? true}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        const checked = event.target.checked
                                        onChangeValue('patient_is_policy_holder', checked)
                                    }}
                                    classes={switchClassOverrides}
                                />
                            }
                            label="Patient is policyholder"
                            classes={formControlLabelClassOverrides}
                        />
                    </h3>
                </Grid>

                <Grid item={true} lg={6} xs={12} className={`${moduleName}__field`}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Insurance Provider"
                        value={patient_data?.insurance_provider || ''}
                        error={hasError('insurance_provider')}
                        errorMessage={errorMsg('insurance_provider')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            onChangeValue('insurance_provider', value)
                        }}
                    />
                </Grid>

                <Grid item={true} lg={6} xs={12} className={`${moduleName}__field`}>
                    <CustomPhoneInput
                        label="Insurance Phone #"
                        value={patient_data?.insurance_phone || ''}
                        country="US"
                        error={hasError('insurance_phone')}
                        errorMessage={errorMsg('insurance_phone')}
                        onChange={(value: string) => {
                            onChangeValue('insurance_phone', value || '')
                        }}
                    />
                </Grid>

                <Grid item={true} lg={6} xs={12} className={`${moduleName}__field`}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Insurance Group #"
                        value={patient_data?.insurance_group || ''}
                        error={hasError('insurance_group')}
                        errorMessage={errorMsg('insurance_group')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            onChangeValue('insurance_group', value)
                        }}
                    />
                </Grid>

                <Grid item={true} lg={6} xs={12} className={`${moduleName}__field`}>
                    <CustomField
                        customFieldType={CustomFieldType.INPUT}
                        label="Insurance Member ID/SSN"
                        error={hasError('insurance_member_id_ssn')}
                        errorMessage={errorMsg('insurance_member_id_ssn')}
                        value={patient_data?.insurance_member_id_ssn || ''}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event.target.value
                            onChangeValue('insurance_member_id_ssn', value)
                        }}
                    />
                </Grid>

                {!patient_data?.patient_is_policy_holder && (
                    <React.Fragment>
                        <Grid item={true} lg={6} xs={12} className={`${moduleName}__field`}>
                            <CustomField
                                customFieldType={CustomFieldType.INPUT}
                                label="Policyholder First Name"
                                error={hasError('policy_holder_first_name')}
                                errorMessage={errorMsg('policy_holder_first_name')}
                                value={patient_data?.policy_holder_first_name || ''}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value
                                    onChangeValue('policy_holder_first_name', value)
                                }}
                            />
                        </Grid>

                        <Grid item={true} lg={6} xs={12} className={`${moduleName}__field`}>
                            <CustomField
                                customFieldType={CustomFieldType.INPUT}
                                label="Policyholder Last Name"
                                error={hasError('policy_holder_last_name')}
                                errorMessage={errorMsg('policy_holder_last_name')}
                                value={patient_data?.policy_holder_last_name || ''}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = event.target.value
                                    onChangeValue('policy_holder_last_name', value)
                                }}
                            />
                        </Grid>

                        <Grid item={true} lg={6} sm={10} xs={12} className={`${moduleName}__field`}>
                            <div className={`${moduleName}__datepicker-wrapper`}>
                                <MaskedInlineDatepicker
                                    dateMask={DATE_MASK}
                                    dateFormat={dateFormat}
                                    label="Policyholder DOB"
                                    error={
                                        (Boolean(policyDob) && !isDobDate(policyDob)) ||
                                        hasError('policy_holder_date_of_birth')
                                    }
                                    errorMessage={'Invalid Date' || errorMsg('policy_holder_date_of_birth')}
                                    value={policyDob}
                                    onInputChange={(targetValue: string | boolean | null) => {
                                        onChangeValue('policy_holder_date_of_birth', targetValue)
                                    }}
                                    onChange={(value: string | boolean | null) => {
                                        onChangeValue('policy_holder_date_of_birth', value)
                                    }}
                                    validator={isDobDate}
                                />
                            </div>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </div>
    )
}

export default ReferralPatientInfo
