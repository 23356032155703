import * as React from 'react'
import classNames from 'classnames'
import moment from 'moment'

import CopyText from '../shared/CopyText'

import './DexVoiceDiagnosticsCommand.sass'

export type DexVoiceDiagnosticsCommandProps = {
    command: Models.DexisAgentCommand
}

export type DexVoiceDiagnosticsCommandDispatch = {}

export type DexVoiceDiagnosticsCommandState = {}

type Props = DexVoiceDiagnosticsCommandProps & DexVoiceDiagnosticsCommandDispatch

function buildRow(label: React.ReactNode, value: React.ReactNode) {
    return (
        <tr className="command-data-item">
            <td className="label">{label}</td>
            <td className="divider">:</td>
            <td className="value">{value}</td>
        </tr>
    )
}

class DexVoiceDiagnosticsCommand extends React.PureComponent<Props, DexVoiceDiagnosticsCommandState> {
    render() {
        const { command } = this.props

        return (
            <div className={classNames('dexvoice-command')}>
                <div className="status-badge">
                    {command.success ? (
                        <i className="fas fa-check success" />
                    ) : (
                        <i className="far fa-times-circle error" />
                    )}
                </div>
                <table className="table">
                    <tbody>
                        {buildRow('Issued', moment(command.created).format('YYYY-MM-DD, hh:mm:ss'))}
                        {buildRow('Command ID', <CopyText text={command.id}>{command.id} &nbsp;</CopyText>)}
                        {command.parameters && buildRow('Action', command.parameters.action)}
                        {command.parameters && buildRow('Image Type', command.parameters.imageType)}
                        {command.parameters && buildRow('Tooth', command.parameters.tooth)}
                        {command.parameters &&
                            command.parameters.bitewing &&
                            buildRow('Bitewing', command.parameters.bitewing)}
                        {command.details && buildRow('Exit Code', command.details.exitCode)}
                    </tbody>
                </table>
                <div className="device" title={command.device_id}>
                    <CopyText text={command.device_id}>Copy Full Device ID &nbsp;</CopyText>
                </div>
            </div>
        )
    }
}

export default DexVoiceDiagnosticsCommand
