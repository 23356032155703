import React from 'react'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableHead } from '@mui/material'

import { SurveyDoctorRecord } from '../../../../../models/PracticeSurvey'
import { Cell, HeaderCell, HeaderRow } from '../shared/Table'

import './Doctors.sass'

interface Props {
    location: Models.PracticeLocation
}

const DoctorsView = ({ location }: Props) => {
    const { doctors }: { doctors: SurveyDoctorRecord[] } = useSelector((state: any) => ({
        doctors: state.practiceSurvey[location.id]?.doctors?.list ?? [],
    }))

    return (
        <div className="survey-section survey-section--view survey-doctors">
            <h4 className="survey-section__header">Doctors</h4>

            <div className="survey-section__wrapper">
                <Table className="survey-doctors__table" aria-labelledby="Doctors">
                    <TableHead>
                        <HeaderRow>
                            <HeaderCell className="survey-doctors__first-name">First Name</HeaderCell>
                            <HeaderCell className="survey-doctors__last-name">Last Name</HeaderCell>
                            <HeaderCell>Suffix</HeaderCell>
                        </HeaderRow>
                    </TableHead>
                    <TableBody>
                        {doctors.map((doctor, index) => (
                            <HeaderRow key={`id_doctors_${index}}`}>
                                <Cell align={'left'}>{doctor.firstName.value}</Cell>
                                <Cell align={'left'}>{doctor.lastName.value}</Cell>
                                <Cell align={'left'}>{doctor.suffix.value}</Cell>
                            </HeaderRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default DoctorsView
