import React from 'react'

import DoctorEdit from './doctors/DoctorsEdit'
import LocationInformationEdit from './location-information/LocationInformationEdit'
import OperatingHoursEdit from './operating-hours/OperatingHoursEdit'
import SurveyRenderedEdit from './survey-rendered/SurveyRenderedEdit'

interface Props {
    location: Models.PracticeLocation
}

const SurveyEdit = ({ location }: Props) => {
    return (
        <div>
            <h2 className="survey-modal__main-title">Practice Info</h2>
            <DoctorEdit location={location} />
            <LocationInformationEdit location={location} />
            <OperatingHoursEdit location={location} />
            <SurveyRenderedEdit location={location} />
        </div>
    )
}

export default SurveyEdit
