import { Dispatch } from 'redux'

import Api from '../../../Api'
import ApiV2 from '../../../ApiV2'
import { ConnectSurveyFormElements, ConnectWebCode } from '../../../models/Connect'
import { ConnectSurvey } from '../../../models/Connect'

export type SetConnectSurveyError = {
    type: 'SET_CONNECT_SURVEY_ERROR'
    errorMessage?: string
}

export type ReceiveConnectSurvey = {
    type: 'RECEIVE_CONNECT_SURVEY'
    practice: Models.Practice
    webCodeId: string
    survey: any
}

export type ReceiveConnectSurveyStatus = {
    type: 'RECEIVE_CONNECT_SURVEY_STATUS'
    practiceId: string
    webCodeId: string
    survey: ConnectSurvey
}

export type UpdateFormFields = {
    type: 'UPDATE_FORM_FIELDS'
    practice: Models.Practice
    webCodeId: string
    formElements: ConnectSurveyFormElements
}

export function setConnectSurveyError(errorMessage?: string): SetConnectSurveyError {
    return {
        type: 'SET_CONNECT_SURVEY_ERROR',
        errorMessage,
    }
}

export function receiveConnectSurvey(survey: any, practice: Models.Practice, webCodeId: string): ReceiveConnectSurvey {
    return {
        type: 'RECEIVE_CONNECT_SURVEY',
        practice,
        webCodeId,
        survey,
    }
}

export function receiveConnectSurveyStatus(
    survey: ConnectSurvey,
    practiceId: string,
    webCodeId: string,
): ReceiveConnectSurveyStatus {
    return {
        type: 'RECEIVE_CONNECT_SURVEY_STATUS',
        practiceId,
        webCodeId,
        survey,
    }
}

export function updateFormFields(
    practice: Models.Practice,
    webCodeId: string,
    formElements: ConnectSurveyFormElements,
): UpdateFormFields {
    return {
        type: 'UPDATE_FORM_FIELDS',
        practice,
        webCodeId,
        formElements,
    }
}

export type ReceiveConnectSurveysList = {
    type: 'RECEIVE_CONNECT_SURVEYS_LIST'
    practice: Models.Practice
    connectWebCodes: ConnectWebCode[]
}

export function receiveConnectSurveysList(
    practice: Models.Practice,
    connectWebCodes: ConnectWebCode[],
): ReceiveConnectSurveysList {
    return {
        type: 'RECEIVE_CONNECT_SURVEYS_LIST',
        practice,
        connectWebCodes,
    }
}

export function createConnectSurvey(
    createConnectSurvey: Api.Connect.CreateConnectSurvey,
    practice: Models.Practice,
    webCodeId: string,
): any {
    return async (dispatch: any) => {
        try {
            const connectSurvey = await Api.Connect.postCreateConnectSurvey(createConnectSurvey)
            await dispatch(receiveConnectSurvey(connectSurvey, practice, webCodeId))
            return connectSurvey
        } catch (err) {
            dispatch(setConnectSurveyError(err.message))
            return err
        }
    }
}

export function fetchConnectSurveyList(practice: Models.Practice): any {
    return async (dispatch: Dispatch<ReceiveConnectSurveysList>) => {
        try {
            const connectWebCodes = await Api.Connect.listConnectSurveys(practice)
            await dispatch(receiveConnectSurveysList(practice, connectWebCodes))
            return connectWebCodes
        } catch (e) {
            return e
        }
    }
}

export function fetchPaginatedConnectSurveyList(
    practice: Models.Practice,
    params: { search: string; page: number; sort?: string },
): any {
    return async (dispatch: Dispatch<ReceiveConnectSurveysList | any>) => {
        try {
            const response = await ApiV2.Connect.getConnectSurveys(practice.id, params)
            await dispatch(receiveConnectSurveysList(practice, response.data))
            response.data.forEach((connectWebCode: ConnectWebCode) => {
                if (connectWebCode.connectSurveyId) {
                    dispatch(
                        fetchConnectSurveyStatusById(practice.id, connectWebCode.id, connectWebCode.connectSurveyId),
                    )
                }
            })
            return response
        } catch (e) {
            return e
        }
    }
}

export function fetchConnectSurvey(practice: Models.Practice, amplifyWebcodeId: string, connectSurveyId: string) {
    return async (dispatch: any) => {
        try {
            const surveyData = await ApiV2.Connect.getConnectSurveyById(amplifyWebcodeId, connectSurveyId)
            await dispatch(receiveConnectSurvey(surveyData, practice, amplifyWebcodeId))
        } catch (e) {
            return e
        }
    }
}

export function fetchConnectSurveyStatusById(practiceId: string, amplifyWebcodeId: string, connectSurveyId: string) {
    return async (dispatch: any) => {
        try {
            const surveyData = await ApiV2.Connect.getConnectSurveyStatusById(amplifyWebcodeId, connectSurveyId)
            await dispatch(receiveConnectSurveyStatus(surveyData, practiceId, amplifyWebcodeId))
        } catch (e) {
            return e
        }
    }
}

export function saveConnectSurvey(
    id: string,
    updateConnectSurvey: Api.Connect.UpdateConnectSurvey,
    practice: Models.Practice,
    webCodeId: string,
): any {
    return async (dispatch: Dispatch<ReceiveConnectSurvey | ReceiveConnectSurveyStatus>) => {
        try {
            const connectSurvey = await Api.Connect.putUpdateConnectSurvey(id, updateConnectSurvey)
            await dispatch(receiveConnectSurvey(connectSurvey, practice, webCodeId))
            await dispatch(receiveConnectSurveyStatus(connectSurvey, practice.id, webCodeId))
            return connectSurvey
        } catch (e) {
            return e
        }
    }
}
