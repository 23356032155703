import Api from '../../Api'
import { RootState } from '../../appReducer'
import PendingUpload from '../../models/PendingUpload'

export type AddPendingUpload = {
    type: 'ADD_PENDING_UPLOAD'
    upload: Models.Upload
}

export type SavedFilePartUpload = {
    type: 'SAVED_FILE_PART_UPLOAD'
    upload: PendingUpload
    partNumber: number
}

export type RemovePendingUpload = {
    type: 'REMOVE_PENDING_UPLOAD'
    uploadId: string
}

export function addPendingUpload(upload: Models.Upload): AddPendingUpload {
    return {
        type: 'ADD_PENDING_UPLOAD',
        upload,
    }
}

export function savedFilePartUpload(upload: PendingUpload, partNumber: number): SavedFilePartUpload {
    return {
        type: 'SAVED_FILE_PART_UPLOAD',
        upload,
        partNumber,
    }
}

export function removePendingUpload(uploadId: string): RemovePendingUpload {
    return {
        type: 'REMOVE_PENDING_UPLOAD',
        uploadId,
    }
}

export function finishPendingUpload(pendingUpload: PendingUpload | Models.Upload): any {
    return async (dispatch: any) => {
        try {
            const upload = await Api.Uploads.postFinishFileUpload(pendingUpload)
            await dispatch(removePendingUpload(pendingUpload.id))
            return upload
        } catch (e) {
            await dispatch(cancelPendingUpload(pendingUpload))
            throw e
        }
    }
}

export function cancelPendingUpload(upload: PendingUpload | Models.Upload): any {
    return async (dispatch: any) => {
        try {
            const cancel = await Api.Uploads.postCancelFileUpload(upload)
            await dispatch(removePendingUpload(upload.id))
            return cancel
        } catch (e) {
            await dispatch(removePendingUpload(upload.id))
            throw e
        }
    }
}

// recursively queues up another part if there are more left on the pendingUpload
export function uploadFilePart(pendingUpload: PendingUpload, file: File, partNumber: number): any {
    if (!pendingUpload.canUploadPart(partNumber)) {
        return () => Promise.resolve()
    }

    const { start, end } = pendingUpload.fileSliceBounds(partNumber)
    const part = file.slice(start, end)

    pendingUpload.markStarted(partNumber)

    return async (dispatch: (a: any) => any, getState: () => RootState) => {
        try {
            const response = await Api.Uploads.postFileUploadPart(pendingUpload, part, partNumber)
            pendingUpload.markDone(partNumber)
            dispatch(savedFilePartUpload(pendingUpload, partNumber))

            if (pendingUpload.hasPartsRemaining) {
                const nextPart = response.part_number + pendingUpload.parallelism
                await dispatch(uploadFilePart(pendingUpload, file, nextPart))
            } else if (!pendingUpload.hasPartsUploading) {
                await dispatch(finishPendingUpload(pendingUpload))
            }
        } catch (err) {
            const cancelledByUser = !getState().uploads.pendingUploads[pendingUpload.id]
            if (pendingUpload.cancelled || cancelledByUser) {
                return
            }

            // try again, stopping at the max try, and waiting some variable number of milliseconds
            if (!pendingUpload.attemptRetry) {
                pendingUpload.cancelled = true
                dispatch(cancelPendingUpload(pendingUpload))
                throw err
            }

            pendingUpload.markFailed(partNumber)
            setTimeout(
                async () => await dispatch(uploadFilePart(pendingUpload, file, partNumber)),
                pendingUpload.currentBackoffWait,
            )
        }
    }
}

export function startFileUpload(
    file: File,
    onComplete: (upload: Models.Upload) => any,
    onCompleteFinal?: (upload: Models.Upload) => any,
): any {
    return async (dispatch: any) => {
        const upload = await Api.Uploads.postStartFileUpload(file, 'amplify')
        const fileUpload = {
            ...upload,
            filename: file.name,
            size: file.size,
            parts_completed: 0,
        }
        await dispatch(addPendingUpload(fileUpload))
        onComplete(fileUpload)

        // the object used in the recursive stack, and in the state itself are/must be different
        const pendingUpload = new PendingUpload(upload)

        const initialParts = Math.min(pendingUpload.numberOfParts, pendingUpload.parallelism)
        for (let i = 0; i < initialParts; i += 1) {
            await dispatch(uploadFilePart(pendingUpload, file, i + 1))
        }

        if (onCompleteFinal) {
            onCompleteFinal(fileUpload)
        }
    }
}
