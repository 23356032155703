import * as React from 'react'
import Radio, { RadioClassKey } from '@mui/material/Radio'

import './WidgetStyle.sass'

export type WidgetStyleProps = {
    customValue?: Api.widgetStyle | boolean
    updateWidgetStyle: (widgetStyle: Api.widgetStyle) => void
}

export type WidgetStyleState = {}

type RadioClassOverrides = {
    readonly [key in RadioClassKey]?: string
}

export default class WidgetStyle extends React.Component<WidgetStyleProps, WidgetStyleState> {
    updateWidgetStyle = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateWidgetStyle(e.target.value as Api.widgetStyle)
    }

    render() {
        const { customValue } = this.props
        const radioValue = customValue || 'expanded'
        const radioClassOverrides: RadioClassOverrides = {
            root: 'radio-root',
        }

        return (
            <div className="widget-style">
                <h3>Style:</h3>
                <div className="radio-wrapper">
                    <Radio
                        checked={radioValue === 'expanded'}
                        onChange={this.updateWidgetStyle}
                        name="radio-widget-style"
                        value="expanded"
                        id="widget-style-expanded"
                        classes={radioClassOverrides}
                    />
                    <div className="widget-style-wrapper">
                        <label htmlFor="widget-style-expanded">
                            <span className="widget-style-text">Expanded</span>
                        </label>
                    </div>
                </div>
                <div className="radio-wrapper">
                    <Radio
                        checked={radioValue === 'condensed'}
                        onChange={this.updateWidgetStyle}
                        name="radio-widget-style"
                        value="condensed"
                        id="widget-style-condensed"
                        classes={radioClassOverrides}
                    />
                    <div className="widget-style-wrapper">
                        <label htmlFor="widget-style-condensed">
                            <span className="widget-style-text">Condensed</span>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}
