import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import classNames from 'classnames'
import moment from 'moment-timezone'

import { RootState } from '../../../appReducer'
import { DATE_FORMAT } from '../../shared/custom-fields/custom-date-range/CustomDateRange'
import ErrorMessage from '../../shared/error-message/error-message'
import InfoMessage from '../../shared/info-message/info-message'
import Paginator from '../../shared/Paginator'

import { dateFromInitial, dateToInitial } from './ConfigLogsFilters'
import { fetchSchedulingConfigurationLogsCsv } from './v2actions'

import './ConfigLogsList.sass'

export type SchedulerProps = {
    practiceId: string
    isPending: boolean
    searchPage: number
    csvError: boolean
    setCsvError: (error: boolean) => void
    setSearchPage: (page: number) => void
}

function formatDateTime(date: string): string {
    const timezone = moment.tz.guess() || 'America/New_York'
    return moment(date)
        .tz(timezone)
        .format('MM/DD/YYYY h:mm A z')
}

type Props = SchedulerProps

const ConfigLogsList = ({ practiceId, searchPage, csvError, setCsvError, setSearchPage }: Props) => {
    const selectedLocationId = useSelector((state: RootState) => state.v2.configLogs?.selectedLocationId?.[practiceId])
    const list = useSelector((state: RootState) => state.v2.configLogs.configLogsData?.[selectedLocationId]?.list)
    const pagination = useSelector(
        (state: RootState) => state.v2.configLogs.configLogsData?.[selectedLocationId]?.pagination || null,
    )
    const loading = useSelector(
        (state: RootState) => state.v2.configLogs.configLogsData?.[selectedLocationId]?.loading || false,
    )

    const selectedUsers = useSelector((state: RootState) => state.v2.configLogs.selectedUserIds?.[practiceId] || [])
    const dateFrom = useSelector((state: RootState) => state.v2.configLogs.dateFrom?.[practiceId] || dateFromInitial)
    const dateTo = useSelector((state: RootState) => state.v2.configLogs.dateTo?.[practiceId] || dateToInitial)
    const downloadStatus = useSelector(
        (state: RootState) => state.v2.configLogs.csvDownloadStatus?.[selectedLocationId] || 'idle',
    )

    useEffect(() => {
        if (downloadStatus === 'error') {
            setCsvError(true)
        } else {
            setCsvError(false)
        }
    }, [downloadStatus, setCsvError])

    const csvDownloadInProgress = downloadStatus === 'downloading'

    const csvButtonDisabled = csvDownloadInProgress || !selectedLocationId

    const dispatch = useDispatch()

    const downloadCsv = () => {
        setCsvError(false)
        dispatch(
            fetchSchedulingConfigurationLogsCsv(selectedLocationId, {
                user_ids: selectedUsers,
                start_date: moment(dateFrom.value, DATE_FORMAT)
                    .startOf('day')
                    .toISOString(),
                end_date: moment(dateTo.value, DATE_FORMAT)
                    .endOf('day')
                    .toISOString(),
            }),
        )
    }

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    const renderRows = () => {
        return (
            <>
                {csvError && (
                    <TableRow className="error-list-row">
                        <TableCell className="table-item created" style={{ width: '25%' }}></TableCell>
                        <TableCell className="table-item created" style={{ width: '25%' }}></TableCell>
                        <TableCell className="table-item created" style={{ width: '50%' }}></TableCell>
                    </TableRow>
                )}
                {Boolean(list && list.length > 0) ? (
                    list?.map(log => (
                        <TableRow className="list-row">
                            <TableCell className="table-item created" style={{ width: '25%' }}>
                                {formatDateTime(log.created)}
                            </TableCell>
                            <TableCell
                                className="table-item name"
                                style={{ width: '25%' }}
                            >{`${log.account.first_name} ${log.account.last_name}`}</TableCell>
                            <TableCell className="table-item details" style={{ width: '50%' }}>
                                {log.message}
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <div />
                )}
            </>
        )
    }

    return (
        <div className="config-logs-list">
            {csvError && (
                <div className="csv-error-message">
                    <ErrorMessage type="error">
                        Unable to create and/or download CSV file. Please try again.
                    </ErrorMessage>
                </div>
            )}
            <Table>
                <TableHead>
                    <TableRow className="table-row">
                        <TableCell className="table-item table-header-item" style={{ width: '25%' }}>
                            Date
                        </TableCell>
                        <TableCell className="table-item table-header-item" style={{ width: '25%' }}>
                            Name
                        </TableCell>
                        <TableCell className="table-item table-header-item" style={{ width: '50%' }}>
                            <div className={`table-header-item-details`}>
                                Details
                                <div className={`download-csv`}>
                                    <div
                                        className={classNames('csv-download-button', {
                                            [`csv-download-button-disabled`]: csvButtonDisabled,
                                        })}
                                        onClick={csvButtonDisabled ? () => {} : downloadCsv}
                                    >
                                        {csvDownloadInProgress ? (
                                            `downloading csv...`
                                        ) : (
                                            <div className={`csv-button-export`}>
                                                Export to CSV{' '}
                                                <i className="material-icons download-icon">file_download</i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderRows()}</TableBody>
            </Table>
            {typeof list === 'undefined' && (
                <div className={`no-records`}>
                    <InfoMessage isShown={true}>
                        Please select a location, user(s), date range and click on “GET LOGS” to view configuration
                        logs.
                    </InfoMessage>
                </div>
            )}
            {list?.length === 0 && (
                <div className={`no-records`}>
                    <InfoMessage isShown={true}>
                        No results found matching those criteria. Please revise and try again.
                    </InfoMessage>
                </div>
            )}

            {Boolean(pagination) && pagination?.allRows && pagination?.allRows > 5 ? (
                <div className="config-logs-paginator-wrapper">
                    <Paginator currentPage={searchPage} paginationInfo={pagination} selectPage={handlePageChange} />
                </div>
            ) : null}
            {loading && (
                <div className="circular-progress-loader-body">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            )}
        </div>
    )
}

export default ConfigLogsList
