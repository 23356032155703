import * as React from 'react'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import _ from 'lodash'

import { NO_RESPONSE_PLACEHOLDER } from '../reducer'

type Props = {
    response: Models.SurveyResponse
    darkerCard: boolean
}

const moduleName = 'survey-component'

const SurveyResponse = ({ response, darkerCard }: Props) => {
    const { id, question, answers } = response

    const createMarkup = (inText: string) => {
        return { __html: inText }
    }

    const formatResponse = () => {
        return answers.join('\n').replace(';', '\n')
    }

    const copyResponse = () => {
        const { answers } = response
        copy(answers.join('\n').replace(';', '\n'))
    }

    const formattedResponse = formatResponse().trim()
    const isCopyButtonDisabled = formattedResponse === '' || formattedResponse === NO_RESPONSE_PLACEHOLDER

    return (
        <div className={classNames(`${moduleName}__qa-card`, { 'survey-component__qa-card--darker': darkerCard })}>
            <div className={`${moduleName}__qa-content`}>
                <strong dangerouslySetInnerHTML={createMarkup(question)} />
                <ul>
                    {_.flatMap(answers, ans => ans.split(';')).map((answer, index) => (
                        <li key={`${id}-${index}`}>{answer}</li>
                    ))}
                </ul>
            </div>
            <div
                className={classNames(`${moduleName}__copy-button`, {
                    [`${moduleName}__copy-button--disabled`]: isCopyButtonDisabled,
                })}
                onClick={copyResponse}
            >
                <i className={`material-icons ${moduleName}__custom-icon`}>content_copy</i>
            </div>
        </div>
    )
}

export default SurveyResponse
