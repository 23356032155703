import React, { useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppDispatch } from 'util/useAppDispatch'

import { getPaymentsWritebackEnabled } from './actions'

import './Integrations.sass'

const moduleName = 'integrations'

interface Props {
    locationId: string
    checked: boolean | undefined
    onChangeWriteback: (locationId: string, allowWriteback: boolean) => void
    onUpdateWriteback: (locationId: string, allowWriteback: boolean) => void
}

const AllowWriteback = (props: Props) => {
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const loadData = async () => {
            const isEnabled = await dispatch(getPaymentsWritebackEnabled(props.locationId))
            if (typeof isEnabled === 'boolean') {
                props.onChangeWriteback(props.locationId, isEnabled)
            }
            setIsLoading(false)
        }
        loadData()
    }, [])

    return (
        <div className={`${moduleName}__allow-writeback`}>
            <label>Allow writeback</label>
            {isLoading && <CircularProgress className={`${moduleName}__allow-writeback-loading`} size={20} />}
            {!isLoading && (
                <Switch
                    checked={props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        props.onChangeWriteback(props.locationId, checked)
                        props.onUpdateWriteback(props.locationId, checked)
                    }}
                />
            )}
        </div>
    )
}

export default AllowWriteback
