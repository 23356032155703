import { SurveyActivityLog, SurveyActivityLogCreator } from '../../../models/v2/PracticeSurvey'

export const mapCreatedBy = (account: ApiV2.Survey.SurveyActivityLogAccount): SurveyActivityLogCreator => {
    return {
        id: account.id,
        firstName: account.first_name,
        lastName: account.last_name,
    }
}

export const mapSurveyActivityLogs = (activityLogs: ApiV2.Survey.SurveyActivityLog[]): SurveyActivityLog[] =>
    activityLogs.map(activityLog => ({
        ...activityLog,
        surveyId: activityLog.survey_id,
        createdBy: mapCreatedBy(activityLog.account),
        details: activityLog.details,
    }))
