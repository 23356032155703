import Api from '../../../../Api'
import ApiV2 from '../../../../ApiV2'
import {
    DayOfWeek,
    LocationInformation,
    OperationDay,
    PracticeSurvey,
    PracticeSurveyState,
    SurveyDoctorRecord,
    SurveyErrorDetails,
    SurveyFormField,
    SurveySection,
    SurveySectionQuestion,
} from '../../../../models/PracticeSurvey'

export const SURVEY_SET_LOCATION_STATE = '@SURVEY/SET_LOCATION_STATE'
export const SURVEY_SET_EDITING = '@SURVEY/SET_EDITING'
export const SURVEY_SET_SECTION_IS_VALID = '@SURVEY/SET_SECTION_IS_VALID'
export const SURVEY_SET_SECTION_LOCATION_IDS = '@SURVEY/SET_SECTION_LOCATION_IDS'
export const SURVEY_SET_DOCTORS = '@SURVEY/SET_DOCTORS'
export const SURVEY_SET_SURVEYS = '@SURVEY/SET_SURVEYS'
export const SURVEY_SET_SURVEY_QUESTION = '@SURVEY/SET_SURVEYS_QUESTION'
export const SURVEY_SET_OPERATING_HOURS = '@SURVEY/SET_OPERATING_HOURS'
export const SURVEY_SET_OPERATING_HOUR = '@SURVEY/SET_OPERATING_HOUR'
export const SURVEY_SET_OPERATING_HOUR_LOCATION_IDS = '@SURVEY/SET_OPERATING_HOUR_LOCATION_IDS'
export const SURVEY_SET_ADDITIONAL_HOURS_INFORMATION_LOCATION_IDS =
    '@SURVEY/SET_ADDITIONAL_HOURS_INFORMATION_LOCATION_IDS'
export const SURVEY_SET_LOCATION_INFORMATION = '@SURVEY/SET_LOCATION_INFORMATION'
export const SURVEY_UPDATE_LOCATION_INFORMATION = '@SURVEY/UPDATE_LOCATION_INFORMATION'
export const SURVEY_ERROR_DETAILS = '@SURVEY/ERROR_DETAILS'
export const SURVEY_CLEAR_ERROR_DETAILS = '@SURVEY/CLEAR_ERROR_DETAILS'
export const SURVEY_PATCH_DOCTORS = '@SURVEY/PATCH_DOCTORS'

export type SetLocationSurveyDoctors = {
    type: typeof SURVEY_SET_DOCTORS
    locationId: string
    doctors: SurveyDoctorRecord[]
}

export type SetLocationSurveyOperatingHours = {
    type: typeof SURVEY_SET_OPERATING_HOURS
    locationId: string
    hours: OperationDay[]
}

export type SetOperatingHour = {
    type: typeof SURVEY_SET_OPERATING_HOUR
    locationId: string
    dayOfWeek: DayOfWeek
    patch: Partial<OperationDay>
}

export type SetOperatingHourLocationIds = {
    type: typeof SURVEY_SET_OPERATING_HOUR_LOCATION_IDS
    locationId: string
    practiceLocationIds: string[]
}

export type SetAdditionalHoursInformationLocationIds = {
    type: typeof SURVEY_SET_ADDITIONAL_HOURS_INFORMATION_LOCATION_IDS
    locationId: string
    practiceLocationIds: string[]
}

export type SetLocationSurveyLocationInformation = {
    type: typeof SURVEY_SET_LOCATION_INFORMATION
    locationId: string
    locationInformation: LocationInformation
}

export type SetEditing = {
    type: typeof SURVEY_SET_EDITING
    locationId: string
    isEditing: boolean
}

export type SetSectionIsValid = {
    type: typeof SURVEY_SET_SECTION_IS_VALID
    locationId: string
    section: string
    isValid: boolean
}

export type SetSectionPracticeLocationIds = {
    type: typeof SURVEY_SET_SECTION_LOCATION_IDS
    locationId: string
    survey: SurveySection
    practiceLocationIds: string[]
}

export type SetLocationState = {
    type: typeof SURVEY_SET_LOCATION_STATE
    locationId: string
    state: PracticeSurveyState
}

export type SetLocationSurveys = {
    type: '@SURVEY/SET_SURVEYS'
    locationId: string
    surveys: SurveySection[]
}

export type SetSurveyQuestion = {
    type: '@SURVEY/SET_SURVEYS_QUESTION'
    locationId: string
    survey: SurveySection
    question: SurveySectionQuestion
}

export type UpdateLocationInformation = {
    type: '@SURVEY/UPDATE_LOCATION_INFORMATION'
    locationId: string
    fieldName: string
    nextLocationInformationField: SurveyFormField
}

export type SetSurveyErrors = {
    type: typeof SURVEY_ERROR_DETAILS
    locationId: string
    errorDetails: SurveyErrorDetails
}

export type ClearSurveyErrors = {
    type: typeof SURVEY_CLEAR_ERROR_DETAILS
    locationId: string
}

export function receiveLocationSurveyDoctors(
    locationId: string,
    doctors: SurveyDoctorRecord[],
): SetLocationSurveyDoctors {
    return {
        type: '@SURVEY/SET_DOCTORS',
        locationId,
        doctors,
    }
}

// operating hours
export function setOperatingHour(
    locationId: string,
    dayOfWeek: DayOfWeek,
    patch: Partial<OperationDay>,
): SetOperatingHour {
    return {
        type: '@SURVEY/SET_OPERATING_HOUR',
        locationId,
        dayOfWeek,
        patch,
    }
}

export function setOperatingHourLocationIds(
    locationId: string,
    practiceLocationIds: string[],
): SetOperatingHourLocationIds {
    return {
        type: '@SURVEY/SET_OPERATING_HOUR_LOCATION_IDS',
        locationId,
        practiceLocationIds,
    }
}

export function receiveLocationSurveyOperatingHours(
    locationId: string,
    hours: OperationDay[],
): SetLocationSurveyOperatingHours {
    return {
        type: '@SURVEY/SET_OPERATING_HOURS',
        locationId,
        hours,
    }
}

export type PatchDoctors = {
    type: typeof SURVEY_PATCH_DOCTORS
    locationId: string
    doctorsIds: Array<string | undefined>
}

export function receiveLocationSurveyLocationInformation(
    locationId: string,
    locationInformation: LocationInformation,
): SetLocationSurveyLocationInformation {
    return {
        type: SURVEY_SET_LOCATION_INFORMATION,
        locationId,
        locationInformation,
    }
}

export function receiveLocationSurveys(locationId: string, surveys: SurveySection[]): SetLocationSurveys {
    return {
        type: SURVEY_SET_SURVEYS,
        locationId,
        surveys,
    }
}

export function updateSurveyQuestion(
    locationId: string,
    survey: SurveySection,
    question: SurveySectionQuestion,
): SetSurveyQuestion {
    return {
        type: SURVEY_SET_SURVEY_QUESTION,
        locationId,
        survey,
        question,
    }
}

export function setLocationState(locationId: string, state: PracticeSurveyState): SetLocationState {
    return {
        type: SURVEY_SET_LOCATION_STATE,
        locationId,
        state,
    }
}

export function setEditing(locationId: string, isEditing: boolean): SetEditing {
    return {
        type: SURVEY_SET_EDITING,
        locationId,
        isEditing,
    }
}

export function setSectionIsValid(locationId: string, section: string, isValid: boolean): SetSectionIsValid {
    return {
        type: SURVEY_SET_SECTION_IS_VALID,
        locationId,
        section,
        isValid,
    }
}

export function setSectionPracticeLocationIds(
    locationId: string,
    survey: SurveySection,
    practiceLocationIds: string[],
): SetSectionPracticeLocationIds {
    return {
        type: SURVEY_SET_SECTION_LOCATION_IDS,
        locationId,
        survey,
        practiceLocationIds,
    }
}

export function setAdditionalHoursInformationLocationIds(
    locationId: string,
    practiceLocationIds: string[],
): SetAdditionalHoursInformationLocationIds {
    return {
        type: '@SURVEY/SET_ADDITIONAL_HOURS_INFORMATION_LOCATION_IDS',
        locationId,
        practiceLocationIds,
    }
}

export function updateLocationInformation(
    locationId: string,
    fieldName: string,
    nextLocationInformationField: SurveyFormField,
): UpdateLocationInformation {
    return {
        type: SURVEY_UPDATE_LOCATION_INFORMATION,
        locationId,
        fieldName,
        nextLocationInformationField,
    }
}

export function setSurveyErrors(locationId: string, errorDetails: SurveyErrorDetails): SetSurveyErrors {
    return {
        type: SURVEY_ERROR_DETAILS,
        locationId,
        errorDetails,
    }
}

export function clearSurveyErrors(locationId: string): ClearSurveyErrors {
    return {
        type: SURVEY_CLEAR_ERROR_DETAILS,
        locationId,
    }
}

export function updateDoctorsSection(locationId: string, doctors: SurveyDoctorRecord[]): any {
    return async (dispatch: any) => {
        return dispatch(receiveLocationSurveyDoctors(locationId, doctors))
    }
}

export function updateOperatingHour(locationId: string, dayOfWeek: DayOfWeek, patch: Partial<OperationDay>): any {
    return async (dispatch: any) => {
        return dispatch(setOperatingHour(locationId, dayOfWeek, patch))
    }
}

export function updateOperatingHourLocationIds(locationId: string, practiceLocationIds: string[]): any {
    return async (dispatch: any) => {
        dispatch(setAdditionalHoursInformationLocationIds(locationId, practiceLocationIds))
        return dispatch(setOperatingHourLocationIds(locationId, practiceLocationIds))
    }
}

export function patchDoctors(locationId: string, doctorsIds: Array<string | undefined>): PatchDoctors {
    return {
        type: '@SURVEY/PATCH_DOCTORS',
        locationId,
        doctorsIds,
    }
}

export function saveLocationUpdates(
    practice: Models.Practice,
    location: Models.PracticeLocation,
    survey: PracticeSurvey,
): any {
    return async (dispatch: any) => {
        dispatch(setLocationState(location.id, 'pending'))

        const allErrors = {}

        ApiV2.Survey.saveLocationSurveyDoctors(location.id, survey).then(doctorsResponse => {
            doctorsResponse.forEach(response => {
                if (response.status === 'rejected') {
                    allErrors['doctors'] = {
                        details: response.reason.errors[0].details,
                        errorCode: response.reason.errors[0].errorCode,
                    }
                }
            })

            dispatch(
                patchDoctors(
                    location.id,
                    doctorsResponse.map(doctor => (doctor.status === 'fulfilled' ? doctor.value?.data?.id : undefined)),
                ),
            )
        })

        try {
            dispatch(setLocationState(location.id, 'pending'))
            const locationAddress = survey.locationInformation?.address
            const locationUpdates: Api.UpdateLocation = {
                address: {
                    street: locationAddress?.street.value,
                    city: locationAddress?.city.value,
                    state: locationAddress?.state.value,
                    zip: locationAddress?.zip.value,
                    unit: locationAddress?.unit.value,
                    lat: Number(locationAddress?.lat.value),
                    lng: Number(locationAddress?.lng.value),
                    country_code_iso_alpha_2: locationAddress?.country_code_iso_alpha_2.value,
                },
                timezone: locationAddress?.timezone.value,
            }
            await Api.Practices.putSaveLocation(practice, location, locationUpdates)
        } catch (err) {
            err.errors.forEach((errorDetails: any) => {
                allErrors['locationInformation'] = {
                    details: errorDetails.message,
                    errorCode: errorDetails.code,
                }
            })
        }

        try {
            await ApiV2.Survey.saveLocationSurveyOperatingHours(location.id, survey)
        } catch (err) {
            err.errors.forEach((errorDetails: any) => {
                allErrors[errorDetails.metadata?.question_id || 'operatingHours'] = {
                    details: errorDetails.details,
                    errorCode: errorDetails.errorCode,
                }
            })
        }

        const setErrors = (errors: any) => {
            if (errors) {
                dispatch(setSurveyErrors(location.id, allErrors))
            } else {
                dispatch(clearSurveyErrors(location.id))
            }
        }

        const postNextSection = (sectionIndex: number) => {
            const section = survey.surveys[sectionIndex]
            ApiV2.Survey.postLocationSurveySection(location.id, location.survey?.id ?? '', section)
                .then(() => {
                    sectionIndex++
                    if (sectionIndex < survey.surveys.length) {
                        postNextSection(sectionIndex)
                    } else {
                        setErrors(allErrors)
                        dispatch(setLocationState(location.id, 'submitted'))
                        dispatch(setEditing(location.id, false))
                    }
                })
                .catch(err => {
                    sectionIndex++

                    err.errors.forEach((errorDetails: any) => {
                        allErrors[errorDetails.metadata?.question_id] = {
                            details: errorDetails.details,
                            errorCode: errorDetails.errorCode,
                        }
                    })
                    if (sectionIndex < survey.surveys.length) {
                        postNextSection(sectionIndex)
                    } else {
                        setErrors(allErrors)
                        dispatch(setLocationState(location.id, 'submitted'))
                    }
                })
        }
        postNextSection(0)
    }
}

export function deleteDoctor(locationId: string, doctor: SurveyDoctorRecord): any {
    return async () => {
        return await ApiV2.Survey.deleteLocationSurveyDoctor(locationId, doctor)
    }
}

export function fetchLocationSurveyDoctors(locationId: string): any {
    return async (dispatch: any) => {
        const doctors = await ApiV2.Survey.getLocationSurveyDoctors(locationId)
        return await dispatch(
            receiveLocationSurveyDoctors(
                locationId,
                doctors.sort((a, b) => a.lastName?.value?.localeCompare(b.lastName?.value)),
            ),
        )
    }
}

export function fetchLocationSurveys(locationId: string, surveyId: string): any {
    return async (dispatch: any) => {
        const surveys = await ApiV2.Survey.getLocationSurveys(locationId, surveyId)
        return await dispatch(receiveLocationSurveys(locationId, surveys as SurveySection[]))
    }
}

export function fetchLocationSurveyHours(locationId: string): any {
    return async (dispatch: any) => {
        const hours = await ApiV2.Survey.getLocationSurveyOperatingHours(locationId)
        return await dispatch(receiveLocationSurveyOperatingHours(locationId, hours))
    }
}

export function fetchLocationSurveyLocationInformation(location: Models.PracticeLocation, addressId: string): any {
    return async (dispatch: any) => {
        const locationInformation = await ApiV2.Survey.getLocationSurveyLocationInformation(location, addressId)
        return await dispatch(receiveLocationSurveyLocationInformation(location.id, locationInformation))
    }
}
