import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'
import moment from 'moment'

import { RootState } from '../../appReducer'
import { AccountTypes } from '../../models/enums'
import { savePractice } from '../practices/actions'

import CopyText from './CopyText'
import InlineEditableText from './InlineEditableText'
import ManageSalesforceType from './ManageSalesforceType'
import { SimplifeyeLogoIconSmall, VyneLogoIconSmall } from './svgIcons'

import './AvailabilityPracticeHeader.sass'

export type AvailabilityPracticeHeaderProps = {
    practice: Models.Practice
    className?: string
}

type Props = AvailabilityPracticeHeaderProps

const AvailabilityPracticeHeader = (props: Props) => {
    const { practice, className } = props

    const [showSettingsModal, setShowSettingsModal] = useState(false)

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)

    const isVyneCreated = practice.isVyneCreated
    const isVyneSalesForce = practice.salesforceType === 'vyne'

    const canEdit =
        account && (account.type.id === AccountTypes.SimplifeyeStaff || account.type.id === AccountTypes.SuperAdmin)

    const dispatch = useDispatch()

    const onSavePracticeName = (name: string) => {
        return dispatch(savePractice(props.practice, { name }))
    }

    const onCloseModal = () => {
        setShowSettingsModal(false)
    }

    const onShowModal = () => {
        setShowSettingsModal(true)
    }

    if (!account) {
        return <div>Loading...</div>
    }

    const isInactive = practice.products.every(p => !p.active) || !practice.active

    return (
        <div className={classNames('availability-practice-header', className)}>
            <div className="info">
                <div className="title">
                    <InlineEditableText
                        className="title"
                        textClassName="practice-header__text"
                        editable={canEdit ?? true}
                        text={practice.name}
                        onSave={onSavePracticeName}
                    />
                </div>
                <div className="db-id">
                    Practice ID:&nbsp;
                    <CopyText text={practice.id}>{practice.id}</CopyText>
                </div>
                <div className="created">Registered On: {moment(practice.created).format('MM/DD/YYYY')}</div>
            </div>
            <div className="status">
                <div className="company-logo-icon" onClick={onShowModal}>
                    {isVyneCreated || isVyneSalesForce ? <VyneLogoIconSmall /> : <SimplifeyeLogoIconSmall />}
                </div>
                <div className={classNames('dot', { inactive: isInactive })} />
                <div className="label">{isInactive ? 'Disabled' : 'Active'} Account</div>
            </div>
            {showSettingsModal && (
                <Modal
                    className="pst-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseModal()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents manage-products-modal">
                        <div className="close-modal-button" onClick={onCloseModal}>
                            <i className="material-icons">close</i>
                        </div>
                        <ManageSalesforceType practice={practice} onClose={onCloseModal} />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default AvailabilityPracticeHeader
