import Api from '../../../../Api'
import ApiV2 from '../../../../ApiV2'
import { RootState } from '../../../../appReducer'
import { startFileUpload } from '../../../uploads/actions'
import {
    createLocationConnectPaymentInfo,
    deleteLocationLogo,
    deletePracticeLocation,
    fetchLocationsSchedulingData,
    fetchPracticeLocationsPaginated,
    receiveCreatedPracticeLocation,
    receivePracticeLocation,
    saveLocationConnectPaymentInfo,
    saveLocationScheduling,
    updateLocationLogoBgColor,
    updateLocationLogoUploadId,
} from '../../actions'
import { mapBgColorsToHex } from '../../logo/PracticeLogoModal'
import { setErrorMessage, setSuccessMessage } from '../actions'
import { LocationAddEditModalState } from '../reducer'

import { LocationDataModel } from './hooks/useLocationForm'
import {
    connectPaymentInfoData,
    createAddRecord,
    createConnectPaymentInfoData,
    createUpdatesRecord,
    generateSchedulingData,
} from './utils'

export enum LocationAddEditModalActionTypes {
    OPEN_LOCATION_ADD_EDIT_MODAL = '@LOCATIONS_TAB/OPEN_LOCATION_ADD_EDIT_MODAL',
    CLOSE_LOCATION_ADD_EDIT_MODAL = '@LOCATIONS_TAB/CLOSE_LOCATION_ADD_EDIT_MODAL',
    UPDATE_LOCATION_ADD_EDIT_MODAL_DATA = '@LOCATIONS_TAB/UPDATE_LOCATION_ADD_EDIT_MODAL_DATA',
}

export type OpenLocationAddEditModal = {
    type: LocationAddEditModalActionTypes.OPEN_LOCATION_ADD_EDIT_MODAL
    location: Models.PracticeLocation | null
    practiceId: string
    isEdit: boolean
}

export type CloseLocationAddEditModal = {
    type: LocationAddEditModalActionTypes.CLOSE_LOCATION_ADD_EDIT_MODAL
    practiceId: string
}

export type UpdateLocationAddEditData = {
    type: LocationAddEditModalActionTypes.UPDATE_LOCATION_ADD_EDIT_MODAL_DATA
    data: Partial<LocationAddEditModalState>
    practiceId: string
}

export function openLocationAddEditModal(
    location: Models.PracticeLocation | null,
    practiceId: string,
): OpenLocationAddEditModal {
    return {
        type: LocationAddEditModalActionTypes.OPEN_LOCATION_ADD_EDIT_MODAL,
        location,
        practiceId,
        isEdit: Boolean(location),
    }
}

export function updateLocationAddEditData(
    data: Partial<LocationAddEditModalState>,
    practiceId: string,
): UpdateLocationAddEditData {
    return {
        type: LocationAddEditModalActionTypes.UPDATE_LOCATION_ADD_EDIT_MODAL_DATA,
        data,
        practiceId,
    }
}

export function deleteLocation(practice: Models.Practice, location: Models.PracticeLocation): any {
    return async (dispatch: any) => {
        try {
            dispatch(setSuccessMessage(``, practice.id))
            dispatch(
                updateLocationAddEditData(
                    {
                        isLoading: true,
                        errorMessage: '',
                    },
                    practice.id,
                ),
            )
            const deleted = await Api.Practices.putDeleteLocation(practice, location)
            dispatch(closeLocationAddEditModal(practice.id))
            dispatch(setSuccessMessage(`Location "${location.name}" deleted successfully.`, practice.id))
            dispatch(deletePracticeLocation(practice, deleted))
        } catch (err) {
            dispatch(
                updateLocationAddEditData(
                    {
                        isLoading: false,
                        errorMessage: err.message || `Error while deleting location "${location.name}"!`,
                        showDeleteConfirmation: false,
                    },
                    practice.id,
                ),
            )
        }
    }
}

export function updateLocationLogo(
    logo: {
        bgColor: string | null
        data: any
        shouldDelete: boolean
    },
    practiceId: string,
    locationId: string,
): any {
    return async (dispatch: any) => {
        if (logo.shouldDelete) {
            await dispatch(deleteLocationLogo(practiceId, locationId))
        } else {
            if (logo.data !== null && logo.data !== undefined) {
                await dispatch(
                    startFileUpload(
                        logo.data,
                        () => {},
                        async upload => {
                            await dispatch(updateLocationLogoUploadId(practiceId, locationId, upload.id))
                        },
                    ),
                )
            }
            if (logo.bgColor !== null) {
                await dispatch(
                    updateLocationLogoBgColor(practiceId, locationId, mapBgColorsToHex[logo.bgColor || 'neutral']),
                )
            }
        }
    }
}

export function updateConnectPaymentInfo(
    practice: Models.Practice,
    formData: Partial<LocationDataModel>,
    location: Models.PracticeLocation,
    hasStripeAccount: boolean,
): any {
    return async (dispatch: any) => {
        const locationConnectPaymentInfoData = connectPaymentInfoData(
            formData.paymentInformationStatus,
            formData.paymentAmount,
            hasStripeAccount,
        )
        if (!locationConnectPaymentInfoData) return

        if (location?.connectPaymentInfo) {
            await dispatch(saveLocationConnectPaymentInfo(practice.id, location.id, locationConnectPaymentInfoData))
        } else {
            const locationCreateConnectPaymentInfoData = createConnectPaymentInfoData(
                location.id,
                formData.paymentInformationStatus,
                formData.paymentAmount,
            )
            await dispatch(
                createLocationConnectPaymentInfo(practice.id, location.id, locationCreateConnectPaymentInfoData),
            )
        }
    }
}

export function updateSchedulingInfo(
    practice: Models.Practice,
    formData: Partial<LocationDataModel>,
    location: Models.PracticeLocation,
    hasDirectSchedulingFields: boolean,
): any {
    return async (dispatch: any) => {
        const locationSchedulingData =
            location.schedulingDetails || generateSchedulingData(formData, hasDirectSchedulingFields)
        if (locationSchedulingData) {
            await dispatch(
                saveLocationScheduling(practice, location, {
                    software: locationSchedulingData.software ?? '',
                    version: locationSchedulingData.version ?? '',
                    timezone: locationSchedulingData.timezone ?? '',
                    ip_address: locationSchedulingData.ipAddress ?? '',
                }),
            )
        }
    }
}

export function updateLocation(
    practice: Models.Practice,
    formData: Partial<LocationDataModel>,
    location: Models.PracticeLocation,
    hasDirectSchedulingFields: boolean,
    hasStripeAccount: boolean,
    shouldUpdateLogo: boolean,
): any {
    return async (dispatch: any) => {
        dispatch(updateLocationAddEditData({ isLoading: true, errorMessage: '' }, practice.id))
        dispatch(setErrorMessage('', practice.id))
        dispatch(setSuccessMessage('', practice.id))
        const updatedLocation = createUpdatesRecord(formData)
        ApiV2.Practice.putUpdatePracticeLocation(practice.id, location.id, updatedLocation)
            .then(async updated => {
                dispatch(receivePracticeLocation(practice, updated))
                if (formData.jarvisEnabled) {
                    await dispatch(updateSchedulingInfo(practice, formData, location, hasDirectSchedulingFields))
                }
                await dispatch(updateConnectPaymentInfo(practice, formData, location, hasStripeAccount))
                if (formData.logo && shouldUpdateLogo) {
                    await dispatch(updateLocationLogo(formData.logo, practice.id, location.id))
                }
                await dispatch(closeLocationAddEditModalAndUpdateList(practice))
                await dispatch(setSuccessMessage(`Location "${location.name}" updated successfully.`, practice.id))
            })
            .catch(err => {
                dispatch(
                    updateLocationAddEditData(
                        {
                            errorMessage: err.message || 'An error occured while trying to update the location!',
                        },
                        practice.id,
                    ),
                )
                dispatch(
                    setErrorMessage(
                        err.message || 'An error occured while trying to update the location!',
                        practice.id,
                    ),
                )
            })
            .finally(() => {
                dispatch(updateLocationAddEditData({ isLoading: false }, practice.id))
            })
    }
}

export function createConnectPaymentInfo(
    practice: Models.Practice,
    formData: Partial<LocationDataModel>,
    createdLocation: Models.PracticeLocation,
    hasStripeAccount: boolean,
): any {
    return async (dispatch: any) => {
        const locationConnectPaymentInfoData = connectPaymentInfoData(
            formData.paymentInformationStatus,
            formData.paymentAmount,
            hasStripeAccount,
        )
        if (locationConnectPaymentInfoData) {
            const locationCreateConnectPaymentInfoData = createConnectPaymentInfoData(
                createdLocation.id,
                formData.paymentInformationStatus,
                formData.paymentAmount,
            )
            await dispatch(
                createLocationConnectPaymentInfo(practice.id, createdLocation.id, locationCreateConnectPaymentInfoData),
            )
        }
    }
}

export function createSchedulingInfo(
    practice: Models.Practice,
    formData: Partial<LocationDataModel>,
    createdLocation: Models.PracticeLocation,
    hasDirectSchedulingFields: boolean,
): any {
    return async (dispatch: any) => {
        const locationSchedulingData = generateSchedulingData(formData, hasDirectSchedulingFields)
        if (locationSchedulingData) {
            await dispatch(saveLocationScheduling(practice, createdLocation, locationSchedulingData))
        }
    }
}

export function createLocation(
    practice: Models.Practice,
    formData: Partial<LocationDataModel>,
    hasDirectSchedulingFields: boolean,
    hasStripeAccount: boolean,
    shouldUpdateLogo: boolean,
): any {
    return async (dispatch: any) => {
        dispatch(updateLocationAddEditData({ isLoading: true, errorMessage: '' }, practice.id))
        dispatch(setErrorMessage('', practice.id))
        dispatch(setSuccessMessage('', practice.id))
        const newLocation = createAddRecord(formData)
        let newLocationId: string | null = null // used to enable payments settings if practice has payments enabled
        return ApiV2.Practice.postCreatePracticeLocation(practice.id, newLocation)
            .then(async createdLocationResponse => {
                dispatch(receiveCreatedPracticeLocation(practice, createdLocationResponse.data))
                const createdLocation = createdLocationResponse.data

                if (createdLocation?.id) {
                    newLocationId = createdLocation.id
                    if (formData.logo && shouldUpdateLogo) {
                        await dispatch(updateLocationLogo(formData.logo, practice.id, createdLocation.id))
                    }

                    if (formData.jarvisEnabled) {
                        await dispatch(
                            createSchedulingInfo(
                                practice,
                                formData,
                                (createdLocation as unknown) as Models.PracticeLocation,
                                hasDirectSchedulingFields,
                            ),
                        )
                    }

                    await dispatch(
                        createConnectPaymentInfo(
                            practice,
                            formData,
                            (createdLocation as unknown) as Models.PracticeLocation,
                            hasStripeAccount,
                        ),
                    )
                }
                await dispatch(closeLocationAddEditModalAndUpdateList(practice))
                await dispatch(setSuccessMessage(`Location "${formData.name}" created successfully.`, practice.id))
            })
            .catch(err => {
                let errMessage = err.message

                if (err.errors) {
                    errMessage = err.errors.map((e: any) => e.message || e.details).join('; ')
                }

                dispatch(
                    updateLocationAddEditData(
                        {
                            errorMessage: errMessage || 'An error occured while trying to create new location!',
                        },
                        practice.id,
                    ),
                )
                dispatch(
                    setErrorMessage(errMessage || 'An error occured while trying to create new location!', practice.id),
                )
            })
            .finally(() => {
                dispatch(updateLocationAddEditData({ isLoading: false }, practice.id))
                return newLocationId
            })
    }
}

export function closeLocationAddEditModalAndUpdateList(practice: Models.Practice): any {
    return async (dispatch: any, getState: () => RootState) => {
        const search = getState().practiceLocations[practice.id]?.metadata.search || ''
        const page = getState().practiceLocations[practice.id]?.metadata.page || 1
        await dispatch(closeLocationAddEditModal(practice.id))
        await dispatch(fetchPracticeLocationsPaginated(practice, { search, page }))
        await dispatch(fetchLocationsSchedulingData(practice.id))
    }
}

export function closeLocationAddEditModal(practiceId: string): CloseLocationAddEditModal {
    return {
        type: LocationAddEditModalActionTypes.CLOSE_LOCATION_ADD_EDIT_MODAL,
        practiceId,
    }
}
