import { connect } from 'react-redux'

import { RootState } from '../../appReducer'
import { fetchAdmins } from '../admin/actions'
import { fetchReferralList } from '../amplify/actions'

import AmplifyManagement, { AmplifyManagementProps } from './AmplifyManagement'
import { PracticeDiagnosticsParams } from './PracticeDiagnostics'

const mapStateToProps = (state: RootState, props: PracticeDiagnosticsParams): AmplifyManagementProps => ({
    practice: state.practices.practices[props.match.params.practiceId],
    referrals: state.amplify.referrals[props.match.params.practiceId],
    admins: state.admin.admins,
    paginationInfo: state.amplify.referralsPaginationInfo,
})

const AmplifyManagementContainer = connect(mapStateToProps, { fetchReferralList, fetchAdmins })(AmplifyManagement)

export default AmplifyManagementContainer
