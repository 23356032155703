const defaultButtonColors: Models.SelfSchedulingButtonColors = {
    background: '#174a8e',
    stroke: '#ffffff',
    text: '#ffffff',
}

const defaultModalColors: Models.SelfSchedulingModalColors = {
    backgroundColor1: '#91c73d',
    backgroundColor2: '#174a8e',
    text: '#ffffff',
}

const defaultButtonSize: Models.SelfSchedulingButtonSize = 'large'
const defaultButtonStyle: Models.SelfSchedulingButtonStyle = 'default_position'

const defaultModalBackgroundColorStyle: Models.SelfSchedulingModalBackgroundColorStyle = 'gradient'

const defaultInsurance = 'none'
const defaultAdditionalComments = 'none'
const defaultEnableMaps = false

const defaultOpenURLIn: Models.SelfSchedulingOpenURLIn = 'same_window'

export default {
    defaultButtonColors,
    defaultModalColors,
    defaultButtonSize,
    defaultButtonStyle,
    defaultModalBackgroundColorStyle,
    defaultInsurance,
    defaultAdditionalComments,
    defaultEnableMaps,
    defaultOpenURLIn,
}
