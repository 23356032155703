import { IntegrationsLocationPair } from '../../models/v2/Integrations'
import { mapHeartbeatAgentLocations } from '../mappers/heartbeat'
import ApiService from '../service'

export default class HeartbeatService extends ApiService {
    readonly ModulePath = 'api/admin/heartbeat/v1'

    async deleteHeartbeatAgent(practiceId: string, agentId: string) {
        await this.fetch(`practice/${practiceId}/agent/${agentId}`, {
            method: 'DELETE',
        })
    }

    async saveLocationPairing(practiceId: string, agentId: string, pairs: IntegrationsLocationPair[]) {
        const { data } = await this.silentFetch({
            endpoint: `practice/${practiceId}/agent/${agentId}/location`,
            requestOptions: {
                method: 'PUT',
                body: JSON.stringify(pairs),
            },
        })
        return data
    }

    async getRSVPEnabledLocations(practiceId: string) {
        const { data: rsvpEnabledLocations } = (await this.fetch(
            `practice/${practiceId}/locations`,
            {},
            {
                chairfillEnabled: true,
            },
        )) as Api.StructuredResponse<Api.Integrations.HeartbeatAgentLocation[]>

        return mapHeartbeatAgentLocations(rsvpEnabledLocations)
    }
}
