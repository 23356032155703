import download from 'downloadjs'
import moment from 'moment'

import Api from '../../Api'

export const getMonthlyStatementAccountsAndYears = (practiceId: string) => {
    return async (dispatch: any): Promise<Api.PaymentMonthlyStatementAccountsAndYearsResponse> => {
        try {
            const monthlyAccountData = await Api.Payments.getMonthlyStatementAccountsAndYears(practiceId)

            return monthlyAccountData.data
        } catch (err) {
            throw err
        }
    }
}

export const getMonthlyStatementMonths = (connectedAccountId: string, year: string, practiceId: string) => {
    return async (dispatch: any): Promise<Api.PaymentMonthlyStatementMonthsResponse> => {
        try {
            const monthsListData = await Api.Payments.getMonthlyStatementMonths(connectedAccountId, year, practiceId)

            return monthsListData.data
        } catch (err) {
            throw err
        }
    }
}

export const downloadMonthlyStatement = (
    connectedAccountId: string,
    statementDate: string,
    locationNames: string[],
    practiceName: string,
    practiceId: string,
) => {
    return async (dispatch: any): Promise<any> => {
        try {
            const { blob, filename } = await Api.Payments.downloadMonthlyStatement(
                connectedAccountId,
                statementDate,
                practiceId,
            )

            practiceName = practiceName.replace(/[^a-zA-Z -]/g, '').slice(0, 10)

            const locationName =
                locationNames.length === 1 ? locationNames[0].slice(0, 10) : `${locationNames.length} locations`

            download(
                blob,
                filename || `${practiceName}-${locationName}-${moment(statementDate).format('MMM YYYY')}.pdf`,
                'application/pdf',
            )
            return
        } catch (err) {
            throw err
        }
    }
}

export const getSdrAndAe = (practiceId: string) => {
    return async (dispatch: any) => {
        try {
            const { data } = await Api.Payments.getSdrAndAe(practiceId)
            return data
        } catch (err) {
            throw err
        }
    }
}

export const updateSdrAndAeAction = (practiceId: string, sdr: string, ae: string) => {
    return async (dispatch: any) => {
        try {
            const { data } = await Api.Payments.updateSdrAndAe(practiceId, sdr, ae)
            return data
        } catch (err) {
            throw err
        }
    }
}
