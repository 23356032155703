import { useLayoutEffect, useState } from 'react'

export const useHeaderDimensions = (myRef: any) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

    useLayoutEffect(() => {
        const getDimensions = () => ({
            width: myRef?.current?.offsetWidth,
            height: myRef?.current?.offsetHeight,
        })

        const handleResize = () => {
            setDimensions(getDimensions())
        }

        if (myRef.current) {
            setTimeout(() => setDimensions(getDimensions()), 100)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [myRef])

    return dimensions
}
