import Button from '@mui/material/Button'
import { Moment } from 'moment'

import './AppointmentPicker.sass'

export type Props = {
    patientName: string
    location: Models.PracticeLocation
    procedure: Models.Procedure
    selectedDate: Moment
    isFormValid: boolean
    timeslotError: boolean
    displayDays: number
    selectedDatetime?: Moment
    errorMessage?: string
    onMakeChanges: () => void
    onBookAppointment: () => Promise<void>
}

export type AppointmentPickerState = {
    startDate: Moment
    endDate: Moment
    minDate: Moment
    isLoading: boolean
}

const moduleName = 'scheduling-appointment-picker'

const AppointmentPickerPreviewOverlay = ({
    patientName,
    selectedDate,
    selectedDatetime,
    displayDays,
    procedure,
    location,
    isFormValid,
    timeslotError,
    onMakeChanges,
    onBookAppointment,
}: Props) => {
    return (
        <div className={`${moduleName}__preview-appointment-overlay`}>
            <div className={`${moduleName}__preview-appointment`}>
                <i className={`material-icons ${moduleName}__info-icon`}>info</i>

                <div className={`${moduleName}__preview-appointment-header`}>
                    You are about to book the following appointment:
                </div>
                <div className={`${moduleName}__preview-appointment-sub-header`}>
                    Please confirm the following details:
                </div>

                <div className={`${moduleName}__preview-appointment-body`}>
                    <div className={`${moduleName}__preview-appointment-body-item`}>
                        Patient Name: <span className={`${moduleName}--bold`}>{patientName}</span>
                    </div>
                    <div className={`${moduleName}__preview-appointment-body-item`}>
                        Location: <span className={`${moduleName}--bold`}>{location.name}</span>
                    </div>
                    <div className={`${moduleName}__preview-appointment-body-item`}>
                        Procedure: <span className={`${moduleName}--bold`}>{procedure.name}</span>
                    </div>
                    <div className={`${moduleName}__preview-appointment-body-item`}>
                        Date/Time:{' '}
                        <span className={`${moduleName}--bold`}>
                            {selectedDatetime
                                ?.tz(location.timezone || 'America/New_York')
                                .format('MMMM D, YYYY [at] h:mm A z')}
                        </span>
                    </div>
                </div>
            </div>

            <div
                className={`${
                    displayDays !== 3
                        ? `${moduleName}__preview-appointment-action-buttons`
                        : `${moduleName}__preview-appointment-action-buttons-narrow`
                }`}
            >
                <div className={`${moduleName}__submit`}>
                    <Button
                        className={`${
                            displayDays !== 3
                                ? `${moduleName}__make-changes-button`
                                : `${moduleName}__make-changes-button-narrow`
                        }`}
                        onClick={onMakeChanges}
                    >
                        MAKE CHANGES
                    </Button>
                </div>
                <div className={`${moduleName}__submit`}>
                    <Button
                        disabled={Boolean(!selectedDatetime || !isFormValid || timeslotError)}
                        className={`${
                            displayDays !== 3 ? `${moduleName}__submit-button` : `${moduleName}__submit-button-narrow`
                        }`}
                        onClick={onBookAppointment}
                    >
                        SCHEDULE APPOINTMENT
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AppointmentPickerPreviewOverlay
