import * as React from 'react'
import { Button } from '@mui/material'

import './ReferralStatusModal.sass'

type Props = {
    patientName: string
    fromStatus: string
    toStatus: string
    onCloseSuccessModal: () => void
}

const ReferralStatusModal = (props: Props) => {
    const { patientName, fromStatus, toStatus, onCloseSuccessModal } = props
    const referralDisplayStatus = {
        in_progress: 'In Progress',
        review: 'In Review',
        complete: 'Complete',
    }
    return (
        <React.Fragment>
            <div className="referral-status-modal-header">
                <p className="referral-status-modal-title">Request: {patientName}</p>
            </div>
            <div className="referral-status-modal-body">
                <div className="message-container">
                    <div className="message-container-heading">
                        <i className="material-icons success">check_circle</i>
                        <h1>{patientName} Request Submitted</h1>
                    </div>
                    <p className="message-information">
                        This request has been moved from "{referralDisplayStatus[fromStatus]}" to "
                        {referralDisplayStatus[toStatus]}"
                    </p>
                </div>
                <div className="button-container">
                    <Button variant="contained" color="primary" className="primary" onClick={onCloseSuccessModal}>
                        View Requests Center
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReferralStatusModal
