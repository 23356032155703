import React, { useEffect, useState } from 'react'

import './CustomProgressBar.sass'

const moduleName = 'custom-progress-bar'

type Props = {
    scrollToTheBottom: () => void
}

const CustomProgressBar = ({ scrollToTheBottom }: Props) => {
    const [percentage, setPercentage] = useState<number>(0)

    useEffect(() => {
        scrollToTheBottom()
    }, [scrollToTheBottom])

    useEffect(() => {
        const updateProgress = () => setPercentage(percentage + 10)
        if (percentage < 100) {
            setTimeout(updateProgress, 20)
        }
    }, [percentage])

    return <div className={`${moduleName}__filler`} style={{ width: `${percentage}%` }} />
}

export default CustomProgressBar
