import moment from 'moment'

export const formatDate = (date: string) => {
    const formatType1 = 'YYYY-MM-DD'
    const formatType2 = 'ddd, MM/DD/YYYY'

    const isFormatType1 = date.includes('-')

    if (isFormatType1) {
        return moment(date, formatType1).format(formatType2)
    }
    return date
}
