import { SurveySection, SurveySectionQuestion } from '../../../../../models/PracticeSurvey'

const isQuestionValueSelected = (surveys: SurveySection[], questionString: string): boolean => {
    let isSelected = false
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [s, questionIdentifier, questionValue] = questionString.split('_')

    surveys.forEach(survey => {
        survey.questions.forEach(question => {
            const surveyQuestionIdentifier = `${survey.name}${question.order_no}`
            if (questionIdentifier === surveyQuestionIdentifier && question.answer_schema?.options) {
                const availableOptions = [
                    ...question.answer_schema.options,
                    question.answer_schema.other_option,
                ].filter(Boolean)

                isSelected = availableOptions.some(option => {
                    const removeSpacesDisplayName = option?.display_name.replace(/\s/g, '')
                    return (
                        removeSpacesDisplayName?.toUpperCase() === questionValue.toUpperCase() &&
                        option?.selected === true
                    )
                })
            } else if (questionIdentifier === surveyQuestionIdentifier && question.question_type === 'input_field') {
                if (question?.answer_schema?.value) {
                    isSelected = question.answer_schema.value.length > 0 && question.isValid
                }
            }
        })
    })
    return isSelected
}

export const isQuestionVisible = (
    surveys: SurveySection[],
    question: SurveySectionQuestion,
    isUSLocation: boolean = true,
) => {
    if (question.metadata?.ifUSAddress?.includes('AtLeastOneSelected') && !isUSLocation) {
        return false
    }

    if (!question.variants) {
        return true
    }
    const showOnVariant = question.variants.filter(v => v.includes('show-on'))

    if (showOnVariant.length) {
        let visible = true
        showOnVariant.some(show => {
            visible = isQuestionValueSelected(surveys, show)
            return isQuestionValueSelected(surveys, show)
        })
        return visible
    }
    return true
}

export const isQuestionRequired = (
    surveys: SurveySection[],
    question: SurveySectionQuestion,
    isUSLocation: boolean = true,
) => {
    let required = question.isRequired

    if (question.metadata?.ifUSAddress?.includes('AtLeastOneSelected') && isUSLocation) {
        return true
    }

    if (!question.variants) {
        return required
    }
    const requiredOnVariant = question.variants.filter(v => v.includes('required-on'))

    if (requiredOnVariant.length) {
        requiredOnVariant.some(req => {
            required = isQuestionValueSelected(surveys, req)
            return isQuestionValueSelected(surveys, req)
        })
    }

    return required
}

export const getExcludeLocationIdsBySpecialty = ({
    location,
    practiceLocations,
    survey,
    question,
}: {
    location: Models.PracticeLocation
    practiceLocations: Models.PracticeLocation[]
    survey: SurveySection
    question?: SurveySectionQuestion
}): string[] => {
    const locationPracticeSpecialtyType = location.practiceSpecialtyType?.value || ''
    let excludeLocationIds: string[] = []
    if (survey.showPracticeLocationsSelect || question?.showPracticeLocationsSelect) {
        if (['brand-info', 'procedures'].some(el => survey.name.includes(el))) {
            const excludeLocations = practiceLocations.filter(loc => {
                return loc.practiceSpecialtyType?.value !== locationPracticeSpecialtyType
            })
            excludeLocationIds = excludeLocations.map(loc => loc.id)
        }
    }

    excludeLocationIds.push(location.id)

    return excludeLocationIds
}

export const clearQuestionValues = (question: SurveySectionQuestion) => {
    if (
        ['select_multi_with_other', 'select_one_with_other'].includes(question.question_type) &&
        question.answer_schema?.options &&
        question.answer_schema?.other_option
    ) {
        return {
            ...question,
            answer_schema: {
                ...question.answer_schema,
                options: question.answer_schema.options.map(opt => ({
                    ...opt,
                    selected: false,
                })),
                other_option: {
                    ...question.answer_schema.other_option,
                    input_field: { value: '' },
                    selected: false,
                },
            },
        }
    }

    if (question.question_type === 'select_one') {
        return {
            ...question,
            answer_schema: {
                ...question.answer_schema,
                options: question.answer_schema?.options?.map(opt => ({
                    ...opt,
                    selected: false,
                })),
            },
        }
    }

    if (question.question_type === 'input_field') {
        return {
            ...question,
            answer_schema: {
                ...question.answer_schema,
                value: '',
            },
        }
    }

    return question
}
