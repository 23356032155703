import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import moment from 'moment'

import { AmplifyWebcodeConnectSurvey, AvailableHours } from '../../../models/AmplifyWebCodeConnectSurvey'
import {
    AmplifyWebCodeSurveySection,
    SurveySectionQuestion,
    SurveySectionValidationType,
} from '../../../models/AmplifyWebCodeSurveys'
import { ConnectSurvey } from '../../../models/Connect'
import { ValidatorPreset } from '../../../modules/practices/locations/edit-survey/shared/validator'

const extraSurveyData = {
    'amplify-websites': {
        'add-website': {
            isRequired: true,
        },
    },
}

const extraQuestionsData = {
    'add-website1': {
        size: { xs: 12, sm: 12, md: 6 },
    },
    'add-website2': {
        variants: ['phone-number'],
        size: { xs: 12, sm: 12, md: 6 },
    },
    'add-website3': {
        size: { xs: 12, sm: 12, md: 6 },
    },
}
const required = ['IsNotEmpty', 'AtLeastOneSelected', 'ArrayNotEmpty']
export const mapValidator = (validation_rule: SurveySectionValidationType): ValidatorPreset => {
    switch (validation_rule) {
        case 'IsEmail':
            return ValidatorPreset.EMAIL_ADDRESS
        case 'IsPhoneNumber':
            return ValidatorPreset.PHONE_NUMBER
        case 'IsString':
            return ValidatorPreset.STRING
        case 'IsURL':
            return ValidatorPreset.URL
        default:
            return ValidatorPreset.NAME
    }
}

export const mapQuestionIsRequred = (validationRules: SurveySectionValidationType[]) => {
    return validationRules.some(r => required.includes(r))
}

export const mapSurveyQuestion = (sectionName: string) => (
    question: ApiV2.AmplifyWebCodeSurvey.SurveyQuestion,
): SurveySectionQuestion => {
    return {
        ...omit(question, ['required']),
        isRequired: mapQuestionIsRequred(question.validation_rules ?? []),
        isValid: true,
        isDirty: false,
        error: false,
        errorMessage: '',
        order: question.order_no,
        validators: question.validation_rules?.filter(rule => !required.includes(rule)).map(mapValidator) ?? [],
        ...(extraQuestionsData[`${sectionName}${question.order_no}`] ?? {}),
    }
}

export const mapSurvey = (specialty: string) => (
    survey: ApiV2.AmplifyWebCodeSurvey.SurveySection,
): AmplifyWebCodeSurveySection => {
    return {
        id: survey.id,
        name: survey.name,
        display_name: survey.display_name,
        questions: orderBy(survey.questions.map(mapSurveyQuestion(survey.name)), 'order'),
        order: survey.order_no,
        ...(extraSurveyData[specialty][survey.name] ?? {}),
    }
}

export const mapAmpliftyWebcodeSurveys = (
    surveys: ApiV2.AmplifyWebCodeSurvey.SurveySection[],
    specialty: string,
): AmplifyWebCodeSurveySection[] => {
    return surveys.map(mapSurvey(specialty)).sort((a, b) => a.order ?? 0 - (b.order ?? 0))
}

export function mapAvailableHours(available_hours: Array<Api.Connect.AvailableHours>): AvailableHours[] {
    if (!Array.isArray(available_hours)) {
        return []
    }
    return available_hours
        .sort((a, b) => a.day_of_week - b.day_of_week)
        .map((available_hour: Api.Connect.AvailableHours, index: number) => {
            return {
                available: available_hour.available,
                startTime: available_hour.start_time,
                endTime: available_hour.end_time,
                dayOfWeek: available_hour.day_of_week,
            }
        })
}

export function mapAvailableHoursV2(available_hours: Array<Api.Connect.AvailableHours>): AvailableHours[] {
    if (!Array.isArray(available_hours)) {
        return []
    }
    return available_hours
        .sort((a, b) => a.day_of_week - b.day_of_week)
        .map((available_hour: Api.Connect.AvailableHours, index: number) => {
            return {
                available: available_hour.available,
                startTime: moment(available_hour.start_time, 'HH:mm').format('HH:mm:ss'),
                endTime: moment(available_hour.end_time, 'HH:mm').format('HH:mm:ss'),
                dayOfWeek: available_hour.day_of_week,
            }
        })
}

export const mapAmplifyWebcodeConnectSurveys = (
    connectSurvey: ApiV2.Connect.ConnectSurvey,
): AmplifyWebcodeConnectSurvey => {
    return {
        id: connectSurvey.id,
        statusId: connectSurvey.status_id,
        doxyUrls: connectSurvey.doxy_urls,
        patientTypeId: connectSurvey.patient_type_id,
        insuranceInformationStatusId: connectSurvey.insurance_information_status_id,
        emails: connectSurvey.emails,
        phoneNumber: connectSurvey.phone_number,
        timezone: connectSurvey.timezone,
        connectAvailableHours: mapAvailableHours(connectSurvey.connect_available_hours),
    }
}

export const mapAmplifyWebcodeConnectSurveysV2 = (connectSurvey: ApiV2.Connect.ConnectSurveyFullV2): ConnectSurvey => {
    return {
        id: connectSurvey.id,
        status: connectSurvey.connect_status,
        doxy_urls: connectSurvey.doxy_urls,
        patientType: connectSurvey.connect_patient_type,
        insuranceInformationStatus: connectSurvey.connect_insurance_information_status,
        emails: connectSurvey.emails,
        phoneNumber: connectSurvey.phone_number,
        timeZone: connectSurvey.timezone,
        availableHours: mapAvailableHours(connectSurvey.connect_available_hours),
    }
}
