import { Button, Card, CardActions, CardContent, Switch } from '@mui/material'
import classNames from 'classnames'

import { Amplify } from '../../models/enums'
import CopyText from '../../modules/shared/CopyText'

type Props = {
    webCode: Models.WebCode
    practice: Models.Practice
    onToggleActive: (webCode: Models.WebCode) => void
    onEditWebCode: (webCode: Models.WebCode) => void
    onCustomizeWebCode: (webCode: Models.WebCode) => void
}

function WebCodeCard(props: Props) {
    const { webCode, practice, onToggleActive, onCustomizeWebCode, onEditWebCode } = props

    const amplifyProductDisabled = !practice.products.some(p => p.value === Amplify.value && p.active)

    return (
        <Card
            className={classNames(`code-card`, {
                [`code-card--code-inactive`]: !webCode.active,
            })}
            key={webCode.id}
        >
            <CardContent>
                <div className="code-card__wrapper">
                    <div className="code-card__header">
                        <div className={classNames('url', { 'active-code': webCode.active })}>{webCode.url}</div>
                    </div>

                    <div className="code-card__container">
                        <div className="code-card__heading">
                            <div className="code-card__amp-webcode">
                                Webchat Web Code
                                <div className="code-card__text">
                                    <CopyText text={webCode.amplifyScript} />
                                </div>
                            </div>
                            {amplifyProductDisabled ? (
                                <Switch className="switch" disabled={true} color="primary" checked={false} />
                            ) : (
                                <Switch
                                    className="switch"
                                    color="primary"
                                    checked={webCode.active}
                                    onClick={() => onToggleActive(webCode)}
                                />
                            )}
                        </div>
                    </div>

                    <div className="code-card__container">
                        <div className="code-card__web-code-heading">Web Code Key</div>
                        <div className={`code-card__web-code-key`}>
                            {webCode.key}
                            <CopyText text={webCode.key} />
                        </div>
                    </div>
                </div>
            </CardContent>
            <CardActions className={`code-card__actions`}>
                <div className={`code-card__buttons code-card__buttons-space-between`}>
                    <Button className="button" size="small" onClick={() => onCustomizeWebCode(webCode)}>
                        Customize
                    </Button>
                    <Button className="button" size="small" onClick={() => onEditWebCode(webCode)}>
                        Edit
                    </Button>
                </div>
            </CardActions>
        </Card>
    )
}

export default WebCodeCard
