import iassign from 'immutable-assign'
import moment from 'moment'

import { Booking, BookingMap, BookingStep } from '../../../models/BookingAppointment'
import { PatientType } from '../../../models/enums'
import {
    isBasicPhoneNumberWithWarning,
    isBirthDate,
    isEmail,
    isNotEmpty,
    isRequired,
    validate,
} from '../../shared/form-validator/validator'

import {
    AppointmentSetSocketConnectionStatus,
    AppointmentSubscribeToChannel,
    AppointmentUnsubscribeFromChannel,
    ReceiveAppointment,
    ReceiveExistingPatients,
    ResetAppointmentForm,
    ResetSearchResults,
    SelectAppointmentDay,
    SelectExistingPatient,
    SetAppointmentBookingStep,
    SetAppointmentDatetime,
    SetAppointmentPickerError,
    SetBookingError,
    SetPending,
    SetSearchExistingPatientsPending,
    SwitchDirectSchedulingTab,
    ToggleDirectSchedulingModal,
    UpdateFormFields,
    UpdateSearchFormFields,
} from './actions'

const ERROR_CANNOT_CONNECT = 'We couldn’t connect to the practice calendar.'
const tomorrow = moment().add(1, 'days')

export type DirectSchedulingState = BookingMap

type DirectSchedulingAction =
    | ReceiveAppointment
    | ToggleDirectSchedulingModal
    | SwitchDirectSchedulingTab
    | UpdateFormFields
    | SetAppointmentBookingStep
    | SetPending
    | SetAppointmentDatetime
    | ResetAppointmentForm
    | SelectAppointmentDay
    | SetAppointmentPickerError
    | SetBookingError
    | UpdateSearchFormFields
    | SetSearchExistingPatientsPending
    | ReceiveExistingPatients
    | SelectExistingPatient
    | ResetSearchResults
    | AppointmentSetSocketConnectionStatus
    | AppointmentSubscribeToChannel
    | AppointmentUnsubscribeFromChannel

const initialState: DirectSchedulingState = {}

const initialBooking = (chat: Models.ChatMetadata): Booking => {
    return {
        open: false,
        chat,
        selectedTab: PatientType.NEW_PATIENT,
        isAdditionalPatientBooking: false,
        socketConnected: false,
        searchPatients: {
            formElements: {
                firstName: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                lastName: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                dateOfBirth: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                location: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
            },
            isFormValid: false,
            isFormDirty: false,
            isPending: false,
            patients: [],
        },
        existingPatient: {
            formElements: {
                mobilePhone: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                email: {
                    value: '',
                    validators: [isEmail()],
                    isValid: false,
                    isDirty: false,
                },
                procedure: {
                    value: '',
                    validators: [isNotEmpty()],
                    isValid: false,
                    isDirty: false,
                },
                location: {
                    value: '',
                    validators: [isNotEmpty()],
                    isValid: false,
                    isDirty: false,
                },
                provider: {
                    value: '',
                    validators: [],
                    isValid: true,
                    isDirty: false,
                },
            },
            isFormValid: false,
            isFormDirty: false,
            isPending: false,
            bookingStep: BookingStep.SEARCH,
            appointmentPicker: {
                selectedDate: tomorrow,
            },
        },
        newPatient: {
            formElements: {
                firstName: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                lastName: {
                    value: '',
                    validators: [isRequired()],
                    isValid: false,
                    isDirty: false,
                },
                dateOfBirth: {
                    value: '',
                    validators: [isRequired(), isBirthDate()],
                    isValid: false,
                    isDirty: false,
                },
                mobilePhone: {
                    value: '',
                    validators: [isBasicPhoneNumberWithWarning()],
                    isValid: false,
                    isDirty: false,
                },
                gender: {
                    value: '',
                    validators: [],
                    isValid: true,
                    isDirty: false,
                },
                email: {
                    value: '',
                    validators: [isEmail()],
                    isValid: false,
                    isDirty: false,
                },
                procedure: {
                    value: '',
                    validators: [isNotEmpty()],
                    isValid: false,
                    isDirty: false,
                },
                location: {
                    value: '',
                    validators: [isNotEmpty()],
                    isValid: false,
                    isDirty: false,
                },
                provider: {
                    value: '',
                    validators: [],
                    isValid: true,
                    isDirty: false,
                },
            },
            isFormValid: false,
            isFormDirty: false,
            isPending: false,
            bookingStep: BookingStep.NONE,
            appointmentPicker: {
                selectedDate: tomorrow,
            },
        },
    }
}

export function reducer(state: DirectSchedulingState = initialState, action: DirectSchedulingAction) {
    switch (action.type) {
        case 'TOGGLE_DIRECT_SCHEDULING_MODAL': {
            return iassign(
                state,
                next => next,
                nextBookings => {
                    if (!nextBookings[action.chat.id]) {
                        nextBookings[action.chat.id] = initialBooking(action.chat)
                    }
                    nextBookings[action.chat.id].open = action.isOpened
                    return nextBookings
                },
            )
        }
        case 'SWITCH_DIRECT_SCHEDULING_TAB': {
            return iassign(
                state,
                next => next[action.chat.id].selectedTab,
                () => action.tab,
            )
        }
        case 'UPDATE_FORM_FIELDS': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id][action.tab]
                },
                nextForm => {
                    nextForm.formElements = action.formElements
                    nextForm.isFormDirty = true
                    nextForm.isFormValid = validate(nextForm.formElements)
                    return nextForm
                },
            )
        }
        case 'SET_APPOINTMENT_BOOKING_STEP': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id][action.tab]
                },
                nextTab => {
                    if (nextTab.bookingStep === BookingStep.SHARED && action.bookingStep === BookingStep.BOOKED) {
                        return nextTab
                    }
                    nextTab.bookingStep = action.bookingStep

                    return nextTab
                },
            )
        }
        case 'SET_BOOKING_PENDING': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id][action.tab]
                },
                nextTab => {
                    nextTab.isPending = action.isPending
                    return nextTab
                },
            )
        }
        case 'SELECT_APPOINTMENT_DAY': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id][action.tab].appointmentPicker
                },
                nextAppointmentPicker => {
                    nextAppointmentPicker.selectedDate = action.day
                    nextAppointmentPicker.selectedDatetime = undefined
                    return nextAppointmentPicker
                },
            )
        }
        case 'SET_APPOINTMENT_DATETIME': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id][action.tab].appointmentPicker
                },
                nextAppointmentPicker => {
                    nextAppointmentPicker.selectedDatetime = action.datetime
                    return nextAppointmentPicker
                },
            )
        }
        case 'RECEIVE_APPOINTMENT': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id]
                },
                nextBooking => {
                    if (action.appointment) {
                        nextBooking.appointment = action.appointment
                        nextBooking[action.tab].errorMessage = undefined
                    } else {
                        nextBooking.appointment = undefined
                        nextBooking[action.tab].errorMessage = ERROR_CANNOT_CONNECT
                    }

                    return nextBooking
                },
            )
        }

        case 'SET_BOOKING_ERROR': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id]
                },
                nextBooking => {
                    nextBooking[action.tab].errorMessage = action.message

                    return nextBooking
                },
            )
        }
        case 'RESET_APPOINTMENT_FORM': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id]
                },
                nextChat => {
                    const locationValue = (nextChat.selectedTab === PatientType.NEW_PATIENT
                        ? nextChat.newPatient
                        : nextChat.existingPatient
                    ).formElements.location.value
                    nextChat = initialBooking(action.chat)
                    if (action.isAdditionalPatientBooking) {
                        ;[
                            nextChat.newPatient.formElements,
                            nextChat.existingPatient.formElements,
                            nextChat.searchPatients.formElements,
                        ].forEach(form => {
                            form.location.value = locationValue
                            form.location.isDirty = true
                            form.location.isValid = true
                        })
                        nextChat.isAdditionalPatientBooking = action.isAdditionalPatientBooking
                    }
                    return nextChat
                },
            )
        }
        case 'SET_APPOINTMENT_PICKER_ERROR': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id][action.tab].appointmentPicker
                },
                nextAppointmentPicker => {
                    nextAppointmentPicker.errorMessage = action.errorMessage
                    return nextAppointmentPicker
                },
            )
        }
        case 'UPDATE_SEARCH_FORM_FIELDS': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id].searchPatients
                },
                nextForm => {
                    nextForm.formElements = action.formElements
                    nextForm.isFormDirty = true
                    nextForm.isFormValid = validate(nextForm.formElements)
                    return nextForm
                },
            )
        }
        case 'SET_SEARCH_EXISTING_PATIENTS_PENDING': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id].searchPatients
                },
                nextForm => {
                    nextForm.isPending = action.isPending
                    nextForm.selectedPatient = undefined
                    return nextForm
                },
            )
        }
        case 'RECEIVE_EXISTING_PATIENTS': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id].searchPatients
                },
                nextForm => {
                    nextForm.patients = action.patients
                    nextForm.isFormDirty = false
                    return nextForm
                },
            )
        }
        case 'SELECT_EXISTING_PATIENT': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id]
                },
                nextBooking => {
                    nextBooking.searchPatients.selectedPatient = action.patient
                    nextBooking.existingPatient.formElements.email.value = action.patient.emailAddress
                    nextBooking.existingPatient.formElements.mobilePhone.value = action.patient.mobilePhone
                    return nextBooking
                },
            )
        }
        case 'RESET_SEARCH_RESULTS': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id]
                },
                next => {
                    next.searchPatients.selectedPatient = undefined
                    next.searchPatients.patients = []
                    next.searchPatients.isFormDirty = true
                    next.existingPatient.bookingStep = BookingStep.SEARCH
                    return next
                },
            )
        }

        case 'APPOINTMENT_CHANNEL_SUBSCRIBE': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id].channelId
                },
                () => action.transactionId,
            )
        }

        case 'APPOINTMENT_CHANNEL_UNSUBSCRIBE': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id].channelId
                },
                () => '',
            )
        }

        case 'APPOINTMENT_SET_SOCKET_CONNECTION_STATUS': {
            return iassign(
                state,
                next => {
                    return next[action.chat.id].socketConnected
                },
                () => action.isConnected,
            )
        }
        default:
            return state
    }
}
