import * as React from 'react'
import { useSelector } from 'react-redux'
import Button, { ButtonClassKey } from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'
import { DebouncedFunc } from 'lodash'
import moment from 'moment'

import { RootState } from '../../../appReducer'

import './ChatCard.sass'

type Props = {
    chat: Models.ChatMetadata
    onButton?: (chat: Models.ChatMetadata) => void
    onSelect?: (chatId: string) => void
    selected?: boolean
    throttledPlayIncomingMessageSound?: DebouncedFunc<() => void>
}

type ButtonClassOverrides = {
    readonly [key in ButtonClassKey]?: string
}

const ChatCard = (props: Props) => {
    const { chat, onButton, onSelect, selected, throttledPlayIncomingMessageSound } = props

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const conversation = useSelector((state: RootState) => state.chat.conversations[chat.channelName])

    const chatLocation = chat.meta && chat.meta.location
    const chatFlag = chatLocation && chatLocation.location && chatLocation.location.country_flag
    const isChatIdle = chat.status.value === 'idle'
    const isChatInactive = chat.status.value === 'inactive'

    const userMessages = conversation?.messages.filter(message => !message?.type.includes('system:'))
    const lastUserMessage = userMessages && userMessages[userMessages.length - 1]
    const unread = lastUserMessage?.is_patient && chat.claimee?.id === account?.id

    const renderButton = () => {
        const buttonClassOverrides: ButtonClassOverrides = {
            root: 'claim-button-root',
        }

        return (
            <div className="claim-chat">
                <Button
                    classes={buttonClassOverrides}
                    fullWidth={true}
                    variant="text"
                    onClick={() => (onButton ? onButton(chat) : null)}
                    size="small"
                    color="primary"
                >
                    Claim
                </Button>
            </div>
        )
    }

    // The sound is played only on the chats that are not currently selected
    if (unread && !selected && !conversation?.preview && throttledPlayIncomingMessageSound) {
        throttledPlayIncomingMessageSound()
    }

    return (
        <div
            className={classNames('chat-card', { unclaimed: !Boolean(chat.claimee) })}
            onClick={() => (onSelect ? onSelect(chat.id) : null)}
        >
            <div className="chat-info-wrapper">
                {chatFlag && (
                    <div className="chat-flag">
                        <img className="patient-flag" src={chatFlag} alt="" />
                    </div>
                )}
                <div className="chat-info">
                    <div className="patient">
                        <div className="patient-name">{chat.patientName || chat.ip}</div>
                        <div className="notification-icons">
                            {unread && <Icon className="unread-notification card-icon">notifications</Icon>}
                            {isChatIdle && <i className="material-icons card-icon alarm-icon-idle">alarm</i>}
                            {isChatInactive && <i className="material-icons card-icon alarm-icon-inactive">alarm</i>}
                        </div>
                    </div>
                    <div className="practice">{chat.practice.name}</div>
                    <div className="created">Created: {moment(chat.created).fromNow()}</div>
                    {chat.claimee && (
                        <div className="chat-claimee">
                            <Icon className="record-voice-over">record_voice_over</Icon>
                            {chat.claimee && chat.claimee.firstName}
                        </div>
                    )}
                </div>
            </div>
            {!chat.claimee && renderButton()}
        </div>
    )
}

export default ChatCard
