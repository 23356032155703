import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import moment from 'moment'

import { mainBlue } from '../shared/styles/colors'

import './ReferralCard.sass'

type Props = {
    statusValue: Models.PendingReferralStatusValue
    referral: ModelsV2.Referrals.ReferralsDashboardCard
    openReferral: (referral: ModelsV2.Referrals.ReferralsDashboardCard) => void
    claimReferral?: (referral: ModelsV2.Referrals.ReferralsDashboardCard) => void
}
type ButtonProps = {
    classes: any
    disabled: boolean
    onClick: (event: React.MouseEvent<HTMLElement>) => void
}
type ReferralCondition = 'unclaimed' | 'claimed' | 'unattended' | 'finished'

const buttonStyles = {
    root: {
        borderColor: mainBlue,
    },
}

const ClaimButtonWithStyles = withStyles(buttonStyles)((props: ButtonProps) => (
    <Button
        className="referral-claim-button"
        classes={props.classes}
        variant="outlined"
        color="primary"
        onClick={props.onClick}
        disabled={props.disabled}
    >
        Claim
    </Button>
))

const ReferralCard = (props: Props) => {
    const { statusValue, referral, claimReferral } = props

    const {
        patient_first_name,
        patient_last_name,
        practice,
        referral_writer_first_name,
        referral_writer_last_name,
        chat_agent_first_name,
        chat_agent_last_name,
        isUnattended,
        updated,
    } = referral
    const patientName = `${patient_first_name} ${patient_last_name}`
    const [referralNotUpdateForMoreThan10Minutes, setReferralNotUpdateForMoreThan10Minutes] = useState(false)

    const referralIsUnattended = isUnattended || referralNotUpdateForMoreThan10Minutes

    const isClaimButtonClicked = useRef(false)

    useEffect(() => {
        const isReferralUnattended = () => {
            const now = moment()
            const tenMinutesAfterLastUpdate = moment(referral.updated).add(10, 'minutes')
            const isUnattended =
                !['complete', 'new', 'scheduled', 'follow_up'].includes(referral.amplify_status.value) &&
                moment(tenMinutesAfterLastUpdate).isBefore(now)

            setReferralNotUpdateForMoreThan10Minutes(isUnattended)
        }

        const interval = setInterval(() => {
            isReferralUnattended()
        }, 10000)

        return () => clearInterval(interval)
    }, [referral.updated, referral.amplify_status.value])

    const getReferralCondition = (): ReferralCondition => {
        if (statusValue === 'complete') {
            return 'finished'
        } else if (referralIsUnattended) {
            return 'unattended'
        } else if (statusValue === 'unclaimed') {
            return 'unclaimed'
        } else {
            return 'claimed'
        }
    }
    const openReferral = () => {
        if (statusValue !== 'unclaimed') {
            props.openReferral(props.referral)
        }
    }
    const onClaimReferral = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()

        if (statusValue === 'unclaimed' && claimReferral && !isClaimButtonClicked.current) {
            isClaimButtonClicked.current = true
            setTimeout(() => {
                isClaimButtonClicked.current = false
            }, 2000)
            claimReferral(referral)
        }
    }
    const formatFullName = (firstName: string, lastName: string) => {
        let fullName = ''

        if (firstName) {
            fullName += firstName + ' '
        }

        if (lastName) {
            fullName += lastName.slice(0, 1) + '.'
        }

        return fullName
    }

    const condition = getReferralCondition()
    const conditionClassNameSuffix =
        statusValue === 'unclaimed' && condition === 'unattended' ? 'unattended--unclaimed' : condition
    const timezone = moment.tz.guess()
    const isFlaggedForReview = statusValue === 'review'
    const referralWriterName = formatFullName(referral_writer_first_name, referral_writer_last_name)
    const claimeeName = formatFullName(chat_agent_first_name, chat_agent_last_name)

    return (
        <div className="referral-card" onClick={openReferral}>
            <div className={`referral-card-header referral-card-header--${conditionClassNameSuffix}`}>
                {referralIsUnattended && condition !== 'finished' && <i className="material-icons unattended">error</i>}
                {statusValue !== 'unclaimed' ? (
                    <div className="referral-card-writter-chatter">
                        <div>{referralWriterName}</div>
                        <div>{claimeeName}</div>
                    </div>
                ) : (
                    <p>UNCLAIMED</p>
                )}
            </div>
            <div className="referral-card-body">
                <div>
                    <p className="referral-card-body__patient-name">
                        <strong>{patientName}</strong>
                    </p>
                    <p>{practice.name}</p>
                    {statusValue === 'complete' && (
                        <p>
                            Last updated:{' '}
                            {moment(updated)
                                .tz(timezone)
                                .format('MM/DD/YYYY h:mm A z')}
                        </p>
                    )}
                </div>
                {statusValue === 'unclaimed' && (
                    <ClaimButtonWithStyles onClick={onClaimReferral} disabled={Boolean(isClaimButtonClicked.current)} />
                )}
                {isFlaggedForReview && <i className="material-icons referral-flagged-for-review">flag</i>}
            </div>
        </div>
    )
}

export default ReferralCard
