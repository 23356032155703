/* tslint:disable:max-line-length */
const OPTOMETRY_QUESTION_LIST: Array<Models.DefaultSurveyQuestion> = [
    {
        id: 'connectHours',
        question: '<b>Connect hours:</b>',
        htmlClass: 'big',
    },
    {
        id: '195548092',
        question: 'What is/are the name(s) of your <b>practice(s)?</b>',
        htmlClass: 'big',
    },
    {
        id: '195548093',
        question: '<b>Doctor(s):</b>',
        htmlClass: 'small',
    },
    {
        id: '195548098',
        question: '<b>Address(es):</b>',
        htmlClass: 'small',
    },
    {
        id: '195548099',
        question: '<b>Phone</b> number(s):',
        htmlClass: 'small',
    },
    {
        id: '195548101',
        question: '<b>Emergency</b> number:',
        htmlClass: 'small',
    },
    {
        id: '195548102',
        question: 'Do you allow <b>non-existing patients</b> to contact your emergency number?',
        htmlClass: 'small',
    },
    {
        id: '195548103',
        question: '<b>Fax</b> number?',
        htmlClass: 'small',
    },
    {
        id: '195548104',
        question: '<b>Hours</b> of operation?',
        htmlClass: 'small',
    },
    {
        id: '195548105',
        question: 'What <b>holidays</b> does the office close for?',
        htmlClass: 'small',
    },
    {
        id: '195548108',
        question: 'What <b>payment options</b> does your practice offer?',
        htmlClass: 'big',
    },
    {
        id: '195548110',
        question: 'Do you accept <b>Medicaid</b>?',
        htmlClass: 'small',
    },
    {
        id: '195548109',
        question: 'State sponsored <b>Medicaid</b>:',
        htmlClass: 'small',
    },
    {
        id: '195548111',
        question: '<b>Insurances</b> accepted:',
        htmlClass: 'small',
    },
    {
        id: '195548112',
        question: "If you do not accept a patient's specific <b>insurance</b>, how would you like us to reply to them?",
        htmlClass: 'small',
    },
    {
        id: '277391266',
        question: 'What <b>treatments</b> does your practice provide?',
        htmlClass: 'big',
    },
    {
        id: '195548106',
        question: 'Do you have a <b>complimentary consultation</b>?',
        htmlClass: 'small',
    },
    {
        id: '277390015',
        question: 'What is <b>included in the consultation</b>?',
        htmlClass: 'small',
    },
    {
        id: '195548113',
        question: 'What <b>age range</b> of patients do you accept?',
        htmlClass: 'small',
    },
    {
        id: '195548094',
        question: 'Who (or what business) is in charge of your <b>website</b>?',
        htmlClass: 'big',
    },
    {
        id: '195548095',
        question: "<b>Web developer's email address:</b>",
        htmlClass: 'small',
    },
    {
        id: '195548096',
        question: "<b>Web developer's phone number:</b>",
        htmlClass: 'small',
    },
    {
        id: '195548097',
        question: 'What is/are the practice website <b>URL</b> / address(es)?',
        htmlClass: 'small',
    },
    {
        id: '195548100',
        question: 'At what email address(es) would you like to receive Amplify <b>referrals</b>?',
        htmlClass: 'small',
    },
    {
        id: '195548117',
        question:
            'For the purpose of composing customized scripting, <b>what can we say about your doctor(s), your team, or your practice</b>?',
        htmlClass: 'big',
    },
    {
        id: '195548116',
        question: 'What can we say to <b>draw patients</b> to your practice?',
        htmlClass: 'small',
    },
    {
        id: '195548115',
        question: 'What <b>differentiates</b> your practice from your competitors?',
        htmlClass: 'small',
    },
    {
        id: '195548118',
        question: 'Is there <b>anything else</b> we should know about your practice?',
        htmlClass: 'small',
    },
    {
        id: '195548121',
        question: 'Please provide the office email address that patients may use.',
        htmlClass: 'small',
    },
    {
        id: '277393059',
        question: 'What brand(s) of glasses do you carry? Ex. Ray-Ban, Cole Haan, Gucci',
        htmlClass: 'small',
    },
    {
        id: '277393602',
        question: 'What brand(s) of lenses do you carry? Ex. Nikon, Crizal, Transitions etc.',
        htmlClass: 'small',
    },
    {
        id: '277394018',
        question: 'What brand(s) of contact lenses do you carry? Ex. Acuvue, Dailies, etc',
        htmlClass: 'small',
    },
    {
        id: '195548120',
        question: 'Please provide the link to your online Patient Forms.',
        htmlClass: 'small',
    },
].map(surveyItem => ({
    // Create raw (lowercase, no formatting) version for search
    questionRaw: surveyItem.question.toLowerCase().replace(/<b>|<\/b>/i, ''),
    ...surveyItem,
}))

export default OPTOMETRY_QUESTION_LIST
