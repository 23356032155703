import React, { Fragment } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import _ from 'lodash'

import { LocationInformation, SurveySection, SurveySectionQuestion } from '../../../../../models/PracticeSurvey'
import { formatPhoneNumberNtl } from '../../../../shared/phone-number-formatter/formatPhoneNumber'

import SelectMultiView from './select-multi/SelectMultiView'
import { isQuestionVisible } from './utils'

import './SurveyRendered.sass'

type Props = {
    location: Models.PracticeLocation
}

const moduleName = 'survey-surveys'

const SurveyRenderedView = ({ location }: Props) => {
    const {
        surveys,
        locationInformation,
    }: { surveys: SurveySection[]; locationInformation: LocationInformation } = useSelector((state: any) => ({
        surveys: state.practiceSurvey[location.id]?.surveys ?? [],
        locationInformation: state.practiceSurvey[location.id]?.locationInformation ?? {},
    }))

    const isUSLocation = Boolean(locationInformation.address?.country_code_iso_alpha_2.value === 'US')

    const renderOneWithAnotherAnswer = (question: SurveySectionQuestion) => {
        const displayValue = question.answer_schema?.other_option?.input_field?.value
        const displayName = question.answer_schema?.other_option?.display_name

        if (displayValue) {
            if (displayName?.toLowerCase() === 'only for') {
                return `${_.capitalize(displayName)}: ${displayValue}`
            }

            return displayValue
        }

        if (displayName) {
            return displayName
        }

        return '-'
    }

    const renderQuestion = (question: SurveySectionQuestion) => {
        switch (question.question_type) {
            case 'input_field':
            case 'input_field_multi_values':
                const valueArray = Array.isArray(question.answer_schema?.value)
                    ? question.answer_schema?.value
                    : [question.answer_schema?.value]

                const isPhoneNumberFieldType = question.variants?.includes('phone-number')

                return (
                    <Fragment>
                        {valueArray &&
                            valueArray.map((value: string) => {
                                let formatedValue = value
                                if (isPhoneNumberFieldType && isValidPhoneNumber(value)) {
                                    formatedValue = formatPhoneNumberNtl(value)
                                }
                                return (
                                    <div className={`survey-question__value`} key={value}>
                                        {formatedValue ?? '-'}
                                    </div>
                                )
                            })}
                    </Fragment>
                )
            case 'select_one':
            case 'select_one_with_other':
                const options = question.answer_schema?.options?.filter(option => option.selected) ?? []
                return (
                    <Fragment>
                        {options.map(option => (
                            <div key={option.id} className={`survey-question__value`}>
                                {option.display_name}
                            </div>
                        ))}
                        {question.answer_schema?.other_option?.selected && (
                            <div className={`survey-question__value`}>{renderOneWithAnotherAnswer(question)}</div>
                        )}
                    </Fragment>
                )
            case 'select_multiple':
            case 'select_multi_with_other':
                return <SelectMultiView question={question} />
            default:
                return <></>
        }
    }

    return (
        <div className={`${moduleName}`}>
            {surveys.map(survey => {
                return (
                    <div key={survey.id} className="survey-section survey-section--edit survey-surveys">
                        {survey.sectionHeader !== 'none' && (
                            <h4
                                className={`${
                                    survey.sectionHeader === 'big'
                                        ? `${moduleName}__section-header--big ${moduleName}__header-underline`
                                        : `${moduleName}__section-header`
                                }`}
                            >
                                {survey.display_name}
                            </h4>
                        )}

                        <div className="survey-section__wrapper">
                            <Grid container spacing={4}>
                                {survey.questions.map((question, index) => {
                                    if (!isQuestionVisible(surveys, question, isUSLocation)) {
                                        return <Fragment key={`id_${question.id}_${index}`}></Fragment>
                                    }
                                    return (
                                        <Grid item key={`id_${question.id}_${index}`} {...question.size}>
                                            <div
                                                id={question.id}
                                                className={`survey-question survey-question--${question.question_type}`}
                                            >
                                                <div
                                                    className={
                                                        survey.firstLabelSize === 'big' && index === 0
                                                            ? 'survey-question__label survey-question__label--big'
                                                            : 'survey-question__label'
                                                    }
                                                >
                                                    {question.question_text}
                                                </div>
                                                <div
                                                    className={`survey-question__fields survey-question--${question.question_type}`}
                                                >
                                                    {renderQuestion(question)}
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SurveyRenderedView
