import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'

import { SurveySection, SurveySectionQuestion, SurveySectionValidationType } from '../../../models/PracticeSurvey'
import { ValidatorPreset } from '../../../modules/practices/locations/edit-survey/shared/validator'

const extraSurveyData = {
    'alternative-verticals': {
        'additional-hours-info': {
            sectionHeader: 'none',
            order: 10,
            isRequired: false,
            firstLabelSize: 'big',
        },
        'major-holidays': {
            order: 20,
            isRequired: false,
            sectionHeader: 'none',
            firstLabelSize: 'big',
        },
        'alternative-verticals-contact-information': {
            order: 30,
            isRequired: true,
        },
        'alternative-verticals-procedures': {
            sectionHeader: 'big',
            order: 40,
            isRequired: true,
        },
        'alternative-verticals-payment-options': {
            sectionHeader: 'big',
            order: 50,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
        'alternative-verticals-brand-info': {
            sectionHeader: 'big',
            order: 60,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
    },
    dental: {
        'additional-hours-info': {
            sectionHeader: 'none',
            order: 10,
            isRequired: false,
            firstLabelSize: 'big',
        },
        'major-holidays': {
            order: 20,
            isRequired: false,
            sectionHeader: 'none',
            firstLabelSize: 'big',
        },
        'dental-contact-information': {
            order: 30,
            isRequired: true,
        },
        'dental-procedures': {
            sectionHeader: 'big',
            order: 40,
            isRequired: true,
        },
        'dental-payment-options': {
            sectionHeader: 'big',
            order: 50,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
        'dental-brand-info': {
            sectionHeader: 'big',
            order: 60,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
    },
    optometry: {
        'additional-hours-info': {
            sectionHeader: 'none',
            order: 10,
            isRequired: false,
            firstLabelSize: 'big',
        },
        'major-holidays': {
            order: 20,
            isRequired: false,
            sectionHeader: 'none',
            firstLabelSize: 'big',
        },
        'optometry-contact-information': {
            order: 30,
            isRequired: true,
        },
        'optometry-procedures': {
            sectionHeader: 'big',
            order: 40,
            isRequired: true,
        },
        'optometry-payment-options': {
            sectionHeader: 'big',
            order: 50,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
        'optometry-brand-info': {
            sectionHeader: 'big',
            order: 60,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
    },
    medical: {
        'additional-hours-info': {
            sectionHeader: 'none',
            order: 10,
            isRequired: false,
            firstLabelSize: 'big',
        },
        'major-holidays': {
            order: 20,
            isRequired: false,
            sectionHeader: 'none',
            firstLabelSize: 'big',
        },
        'medical-contact-information': {
            order: 30,
            isRequired: true,
        },
        'medical-procedures': {
            sectionHeader: 'big',
            order: 40,
            isRequired: true,
        },
        'medical-payment-options': {
            sectionHeader: 'big',
            order: 50,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
        'medical-brand-info': {
            sectionHeader: 'big',
            order: 60,
            isRequired: true,
            showPracticeLocationsSelect: true,
        },
    },
}

// Hardcoded sizes and orders for the questions
const extraQuestionsData = {
    // contact information
    'dental-contact-information1': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Phone #',
        show_empty_field: true,
        variants: ['phone-number'],
    },
    'dental-contact-information2': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Fax #',
        show_empty_field: true,
        variants: ['phone-number'],
    },
    'dental-contact-information3': {
        size: { xs: 12, sm: 6, md: 2 },
        variants: ['padding-top-bottom', 'phone-number'],
        show_empty_field: true,
    },
    'dental-contact-information4': {
        size: { xs: 12, sm: 6, md: 4 },
        variants: ['row', 'capitalize_first', 'show-on_dental-contact-information3_InputValid', 'hide-optional'],
    },
    'dental-contact-information5': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['half'],
        add_label: '+ Add another email',
        show_empty_field: true,
    },
    'dental-contact-information6': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third'],
    },
    'dental-contact-information7': {
        size: { xs: 12, sm: 12, md: 12, lg: 7 },
        component_type: 'dropdown',
        variants: ['half', 'inline-with-other'],
        other_label: 'EMR Software Name',
    },
    'dental-contact-information8': {
        size: { xs: 12, sm: 12, md: 12, lg: 7 },
        component_type: 'dropdown',
        variants: ['half', 'inline-with-other'],
        other_label: 'Payment processor',
    },
    // optometry-contact-information
    'optometry-contact-information1': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Phone #',
        variants: ['phone-number'],
        show_empty_field: true,
    },
    'optometry-contact-information2': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Fax #',
        variants: ['phone-number'],
        show_empty_field: true,
    },
    'optometry-contact-information3': {
        size: { xs: 12, sm: 6, md: 2 },
        variants: ['padding-top-bottom', 'phone-number'],
        show_empty_field: true,
    },
    'optometry-contact-information4': {
        size: { xs: 12, sm: 6, md: 4 },
        variants: ['row', 'capitalize_first', 'show-on_optometry-contact-information3_InputValid', 'hide-optional'],
    },
    'optometry-contact-information5': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['half'],
        add_label: '+ Add another email',
        show_empty_field: true,
    },
    'optometry-contact-information6': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third'],
    },
    'optometry-contact-information7': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'dropdown',
        variants: ['third', 'inline-with-other'],
        other_label: 'Payment processor',
    },
    // medical-contact-information
    'medical-contact-information1': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Phone #',
        variants: ['phone-number'],
        show_empty_field: true,
    },
    'medical-contact-information2': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Fax #',
        variants: ['phone-number'],
        show_empty_field: true,
    },
    'medical-contact-information3': {
        size: { xs: 12, sm: 6, md: 2 },
        variants: ['padding-top-bottom', 'phone-number'],
    },
    'medical-contact-information4': {
        size: { xs: 12, sm: 6, md: 4 },
        variants: ['row', 'capitalize_first', 'show-on_medical-contact-information3_InputValid', 'hide-optional'],
        show_empty_field: true,
    },
    'medical-contact-information5': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['half'],
        add_label: '+ Add another email',
        show_empty_field: true,
    },
    'medical-contact-information6': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third'],
    },
    'medical-contact-information7': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'dropdown',
        variants: ['third', 'inline-with-other'],
        other_label: 'Payment processor',
    },
    // alternative-verticals-contact-information
    'alternative-verticals-contact-information1': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Phone #',
        variants: ['phone-number'],
        show_empty_field: true,
    },
    'alternative-verticals-contact-information2': {
        size: { xs: 12, sm: 6, md: 3 },
        add_label: '+ Add another Fax #',
        variants: ['phone-number'],
        show_empty_field: true,
    },
    'alternative-verticals-contact-information3': {
        size: { xs: 12, sm: 6, md: 2 },
        add_label: '+ Add another Phone #',
        variants: ['padding-top-bottom', 'phone-number'],
        show_empty_field: true,
    },
    'alternative-verticals-contact-information4': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['half', 'capitalize_first'],
        add_label: '+ Add another email',
        show_empty_field: true,
    },
    'alternative-verticals-contact-information5': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'dropdown',
        variants: ['third', 'inline-with-other'],
        other_label: 'Payment processor',
    },
    // additional-hours-info
    'additional-hours-info1': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'text-area',
        component_size: 1,
        variants: ['auto-size'],
    },
    // major-holidays
    'major-holidays1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['toggle', 'toggle-all', 'columns-2'],
    },
    'major-holidays2': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    // procedures
    // procedures-dental
    'dental-procedures1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third', 'dont_send_location_ids', 'capitalize_first'],
        component_type: 'atom-toggle',
    },
    'dental-procedures2': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: [
            'half',
            'dont_send_location_ids',
            'show-on_dental-procedures1_Yes',
            'required-on_dental-procedures1_Yes',
        ],
        component_type: 'text-area',
    },
    'dental-procedures3': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['sixth', 'dont_send_location_ids'],
    },
    'dental-procedures4': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'tabs',
        showPracticeLocationsSelect: true,
    },
    // procedures-optometry
    'optometry-procedures1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third', 'dont_send_location_ids', 'capitalize_first'],
        component_type: 'atom-toggle',
    },
    'optometry-procedures2': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: [
            'half',
            'dont_send_location_ids',
            'show-on_optometry-procedures1_Yes',
            'required-on_optometry-procedures1_Yes',
            'show-on_optometry-procedures1_OnlyFor',
            'required-on_optometry-procedures1_OnlyFor',
        ],
        component_type: 'text-area',
    },
    'optometry-procedures3': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['sixth', 'dont_send_location_ids'],
    },
    'optometry-procedures4': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'text-area',
        component_size: 6,
        showPracticeLocationsSelect: true,
    },
    'optometry-procedures5': {
        size: { xs: 12, sm: 4, md: 4 },
        component_type: 'text-area',
        variants: ['dont_send_location_ids'],
        component_size: 6,
    },
    'optometry-procedures6': {
        size: { xs: 12, sm: 4, md: 4 },
        component_type: 'text-area',
        variants: ['dont_send_location_ids'],
        component_size: 6,
    },
    'optometry-procedures7': {
        size: { xs: 12, sm: 4, md: 4 },
        component_type: 'text-area',
        variants: ['dont_send_location_ids'],
        component_size: 6,
    },
    // procedures-alternative-verticals
    'alternative-verticals-procedures1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third', 'capitalize_first'],
        component_type: 'atom-toggle',
        other_component_type: 'text-area',
    },
    'alternative-verticals-procedures2': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: [
            'half',
            'show-on_alternative-verticals-procedures1_Yes',
            'required-on_alternative-verticals-procedures1_Yes',
            'show-on_alternative-verticals-procedures1_OnlyFor',
            'required-on_alternative-verticals-procedures1_OnlyFor',
        ],
        component_type: 'text-area',
    },
    'alternative-verticals-procedures3': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'text-area',
        component_size: 5,
    },
    // medical-procedures
    'medical-procedures1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['third', 'dont_send_location_ids', 'capitalize_first'],
        component_type: 'atom-toggle',
    },
    'medical-procedures2': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: [
            'half',
            'dont_send_location_ids',
            'show-on_medical-procedures1_Yes',
            'required-on_medical-procedures1_Yes',
            'show-on_medical-procedures1_OnlyFor',
            'required-on_medical-procedures1_OnlyFor',
        ],
        component_type: 'text-area',
    },
    'medical-procedures3': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['sixth', 'dont_send_location_ids'],
    },
    'medical-procedures4': {
        size: { xs: 12, sm: 12, md: 12 },
        component_type: 'text-area',
        component_size: 6,
        showPracticeLocationsSelect: true,
    },
    // payment-options
    // dental-payment-options
    'dental-payment-options1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['columns-3'],
    },
    'dental-payment-options2': {
        size: { xs: 12, sm: 12, md: 6 },
    },
    'dental-payment-options3': {
        order: 4,
        size: { xs: 12, sm: 12, md: 6 },
        add_label: 'Select state-sponsored Medicaid',
        component_type: 'dropdown',
    },
    'dental-payment-options4': {
        order: 3,
        size: { xs: 12, sm: 12, md: 6 },
    },
    'dental-payment-options5': {
        size: { xs: 12, sm: 12, md: 6 },
        add_label: 'Select state-sponsored CHIP',
        component_type: 'dropdown',
    },
    'dental-payment-options6': {
        size: { xs: 12, sm: 12, md: 6 },
        section_sub_heading: 'Insurance',
        component_type: 'text-area',
        component_size: 6,
    },
    'dental-payment-options7': {
        size: { xs: 12, sm: 12, md: 6 },
        component_type: 'text-area',
        component_size: 6,
    },
    'dental-payment-options8': {
        size: { xs: 12, sm: 12, md: 6 },
        component_type: 'text-area',
        component_size: 6,
    },
    // optometry-payment-options
    'optometry-payment-options1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['columns-3'],
    },
    'optometry-payment-options2': {
        size: { xs: 12, sm: 12, md: 6 },
    },
    'optometry-payment-options3': {
        order: 4,
        size: { xs: 12, sm: 12, md: 6 },
        add_label: 'Select state-sponsored Medicaid',
        component_type: 'dropdown',
    },
    'optometry-payment-options4': {
        order: 3,
        size: { xs: 12, sm: 12, md: 6 },
    },
    'optometry-payment-options5': {
        size: { xs: 12, sm: 12, md: 6 },
        add_label: 'Select state-sponsored CHIP',
        component_type: 'dropdown',
    },
    'optometry-payment-options6': {
        size: { xs: 12, sm: 12, md: 6 },
        section_sub_heading: 'Insurance',
        component_type: 'text-area',
        component_size: 6,
    },
    'optometry-payment-options7': {
        size: { xs: 12, sm: 12, md: 6 },
        component_type: 'text-area',
        component_size: 6,
    },
    'optometry-payment-options8': {
        size: { xs: 12, sm: 12, md: 6 },
        component_type: 'text-area',
        component_size: 6,
    },
    // medical-payment-options
    'medical-payment-options1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['columns-3'],
    },
    'medical-payment-options2': {
        size: { xs: 12, sm: 12, md: 6 },
    },
    'medical-payment-options3': {
        order: 4,
        size: { xs: 12, sm: 12, md: 6 },
        add_label: 'Select state-sponsored Medicaid',
        component_type: 'dropdown',
    },
    'medical-payment-options4': {
        order: 3,
        size: { xs: 12, sm: 12, md: 6 },
    },
    'medical-payment-options5': {
        size: { xs: 12, sm: 12, md: 6 },
        add_label: 'Select state-sponsored CHIP',
        component_type: 'dropdown',
    },
    'medical-payment-options6': {
        size: { xs: 12, sm: 12, md: 6 },
        section_sub_heading: 'Insurance',
        component_type: 'text-area',
        component_size: 6,
    },
    'medical-payment-options7': {
        size: { xs: 12, sm: 12, md: 6 },
        component_type: 'text-area',
        component_size: 6,
    },
    'medical-payment-options8': {
        size: { xs: 12, sm: 12, md: 6 },
        component_type: 'text-area',
        component_size: 6,
    },
    // alternative-verticals-payment-options
    'alternative-verticals-payment-options1': {
        size: { xs: 12, sm: 12, md: 12 },
        variants: ['columns-3'],
    },
    // brand info
    // dental-brand-info
    'dental-brand-info1': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'dental-brand-info2': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'dental-brand-info3': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'dental-brand-info4': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    // optometry-brand-info
    'optometry-brand-info1': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'optometry-brand-info2': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'optometry-brand-info3': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'optometry-brand-info4': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    // medical-brand-info
    'medical-brand-info1': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'medical-brand-info2': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'medical-brand-info3': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'medical-brand-info4': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    // alternative-verticals-brand-info
    'alternative-verticals-brand-info1': {
        size: { xs: 12, sm: 12, md: 12 },
    },
    'alternative-verticals-brand-info2': {
        size: { xs: 12, sm: 12, md: 12 },
    },
}
const required = ['IsNotEmpty', 'AtLeastOneSelected', 'ArrayNotEmpty']
export const mapValidator = (validation_rule: SurveySectionValidationType): ValidatorPreset => {
    switch (validation_rule) {
        case 'IsEmail':
            return ValidatorPreset.EMAIL_ADDRESS
        case 'IsPhoneNumber':
            return ValidatorPreset.PHONE_NUMBER
        case 'IsURL':
            return ValidatorPreset.URL
        case 'IsString':
            return ValidatorPreset.STRING
        default:
            return ValidatorPreset.NAME
    }
}

export const mapQuestionIsRequired = (validationRules: SurveySectionValidationType[]) => {
    return validationRules.some(r => required.includes(r))
}

export const mapSurveyQuestion = (sectionName: string) => (
    question: ApiV2.Survey.SurveyQuestion,
): SurveySectionQuestion => {
    return {
        ...omit(question, ['required']),
        isRequired: mapQuestionIsRequired(question.validation_rules ?? []),
        isValid: true,
        isDirty: false,
        error: false,
        errorMessage: '',
        order: question.order_no,
        validators: question.validation_rules?.filter(rule => !required.includes(rule)).map(mapValidator) ?? [],
        ...(extraQuestionsData[`${sectionName}${question.order_no}`] ?? {}),
    }
}

export const mapSurvey = (specialty: string) => (survey: ApiV2.Survey.SurveySection): SurveySection => {
    return {
        id: survey.id,
        name: survey.name,
        display_name: survey.display_name,
        questions: orderBy(survey.questions.map(mapSurveyQuestion(survey.name)), 'order'),
        order: survey.order_no,
        ...(extraSurveyData[specialty][survey.name] ?? {}),
    }
}

export const mapSurveys = (surveys: ApiV2.Survey.SurveySection[], specialty: string): SurveySection[] => {
    return surveys.map(mapSurvey(specialty)).sort((a, b) => a.order ?? 0 - (b.order ?? 0))
}
