import { useState } from 'react'
import moment from 'moment'

import { CustomFieldType } from 'models/enums'
import CustomField from 'modules/shared/custom-fields/CustomField'
import DefaultModal from './DefaultModal'
import { dateFormat } from 'modules/referrals/referral-form/ReferralForm'
import CustomDatepicker from 'modules/shared/custom-fields/CustomInlineDatepicker'
import { useAppDispatch } from 'util/useAppDispatch'
import { formatDobForApi } from 'modules/shared/dateUtils'
import { loadSwapTransactionData, saveSwapTransaction } from './actions'
import CustomMultiselectField from 'modules/shared/custom-fields/CustomMultiselectField'
import { convertNumCentsToDollars } from 'util/formatCurrency'

import './AdminToolsShared.sass'
import { transactionIdLabel } from './utils'

interface Props {
    onClose: () => void
}

const SwapTransactionsModal = (props: Props) => {
    const dispatch = useAppDispatch()

    const [screenIndex, setScreenIndex] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState('')
    const [transactionId, setTransactionId] = useState('')
    const [isLoadingSwapData, setIsLoadingSwapData] = useState(false)
    const [loadedData, setLoadedData] = useState<{
        transactionId: string
        patientId: string
        patientName: string
        amount: number
        payers: Api.LoadSwapTransactionDataResponse['payers']
    }>({
        transactionId: '',
        patientId: '',
        patientName: '',
        amount: 0,
        payers: [],
    })
    const [selectedPayerId, setSelectedPayerId] = useState('')
    const [isSavingSwap, setIsSavingSwap] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const goBackScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex - 1)
    }

    const goForwardScreen = () => {
        setErrorMessage('')
        setScreenIndex(currentScreenIndex => currentScreenIndex + 1)
    }

    const onLoadSwapData = async () => {
        if (isLoadingSwapData) {
            return
        }
        setIsLoadingSwapData(true)
        try {
            const swapData = await dispatch(
                loadSwapTransactionData(firstName, lastName, formatDobForApi(dob), transactionId),
            )
            setLoadedData({
                transactionId: swapData.chargeEventId,
                patientId: swapData.patient.patientId,
                patientName: `${swapData.patient.firstname} ${swapData.patient.lastname}`,
                amount: swapData.amount,
                payers: swapData.payers,
            })
            goForwardScreen()
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsLoadingSwapData(false)
    }

    const onSaveSwap = async () => {
        if (isSavingSwap) {
            return
        }
        setIsSavingSwap(true)
        try {
            const swapData = await dispatch(saveSwapTransaction(transactionId, loadedData.patientId, selectedPayerId))
            if (swapData === true) {
                goForwardScreen()
                setSuccessMessage(`Transaction was successfully swapped to ${loadedData.patientName}.`)
            }
        } catch (err) {
            if (err.errors && !!err.errors[0] && err.errors[0].message) {
                setErrorMessage(err.errors[0].message)
            } else {
                setErrorMessage('Server error.')
            }
        }
        setIsSavingSwap(false)
    }

    return (
        <DefaultModal
            title="Swap Transaction to..."
            onClose={props.onClose}
            screenIndex={screenIndex}
            successMessage={successMessage}
            errorMessage={errorMessage}
            screens={[
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--input">
                            <div className="admin-tools-row">
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="First Name"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                                />
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label="Last Name"
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                                />
                            </div>
                            <div className="admin-tools-row">
                                <CustomDatepicker
                                    value={dob ? moment(dob, dateFormat).toDate() : null}
                                    label={'Date of Birth'}
                                    invalidLabel={dateFormat}
                                    placeholder={dateFormat}
                                    dateFormat={dateFormat}
                                    // error={error}
                                    disableFuture={true}
                                    maxDateMessage="Future dates not supported"
                                    onChange={(value: moment.Moment) => {
                                        setDob(value?.format(dateFormat))
                                    }}
                                />
                                <CustomField
                                    customFieldType={CustomFieldType.INPUT}
                                    autoFocus={false}
                                    label={transactionIdLabel}
                                    // inputProps={{ step: '.01' }}
                                    inputType="text"
                                    value={transactionId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setTransactionId(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ),
                    mainButton: {
                        isLoading: isLoadingSwapData,
                        disabled: firstName === '' || lastName === '' || dob === '' || transactionId === '',
                        onClick: onLoadSwapData,
                        text: 'Next',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                You are about transfer this transaction to a different patient. Please confirm the
                                details below.
                            </p>
                            <div className="admin-tools-details">
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Transaction ID:</div>
                                    <div className="admin-tools-details-value">{loadedData.transactionId}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">New Patient Name:</div>
                                    <div className="admin-tools-details-value">{loadedData.patientName}</div>
                                </div>
                                <div className="admin-tools-details-row">
                                    <div className="admin-tools-details-label">Transaction Amount:</div>
                                    <div className="admin-tools-details-value">
                                        {convertNumCentsToDollars(loadedData.amount, true)}
                                    </div>
                                </div>
                            </div>
                            <div className="admin-tools-select-wrapper">
                                <CustomMultiselectField
                                    items={loadedData.payers.map(p => ({
                                        display: `${p.firstname} ${p.lastname}`,
                                        value: p.payerId,
                                    }))}
                                    selectedItems={[selectedPayerId]}
                                    maxSelected={1}
                                    keyProperty="value"
                                    displayProperty="display"
                                    placeholder="Select Guarantor"
                                    onSelectElement={(values: string[]) => {
                                        setSelectedPayerId(values[0])
                                    }}
                                />
                            </div>
                        </div>
                    ),
                    leftButton: {
                        onClick: goBackScreen,
                        text: 'Back',
                    },
                    mainButton: {
                        isLoading: isSavingSwap,
                        disabled: selectedPayerId === '',
                        onClick: onSaveSwap,
                        text: 'Swap Transaction',
                    },
                },
                {
                    content: (
                        <div className="admin-tools-content admin-tools-content--loaded">
                            <p className="admin-tools-text">
                                Please remove the payment method associated with the old patient.
                            </p>
                        </div>
                    ),
                    mainButton: {
                        isLoading: false,
                        disabled: false,
                        onClick: props.onClose,
                        text: 'Close',
                    },
                },
            ]}
        />
    )
}

export default SwapTransactionsModal
