import { ConnectedPracticeLocation, PracticeLocationsV2 } from '../../models/v2/Practice'
type LocationToSort =
    | Models.AmplifyWebCodeLocation
    | PracticeLocationsV2
    | {
          id: string
          name: string
          amplify_web_code_id: string
          practice_location_id: string
          enabled: boolean
          all_locations_enabled: boolean
      }
    | Models.PracticeLocation
    | ConnectedPracticeLocation
    | Models.SelfSchedulingWebCodeLocation

export const moveDefaultLocationToTop = (x: LocationToSort, y: LocationToSort) => {
    const locationName = 'default location'
    return x.name.toLocaleLowerCase() === locationName ? -1 : y.name.toLocaleLowerCase() === locationName ? 1 : 0
}

const sortLocationByName = (a: any, b: any) => a.name.localeCompare(b.name)

export const sortLocationByNameAndMoveDefaultLocationToTop = (locations: any[]) => {
    return locations.sort(sortLocationByName).sort(moveDefaultLocationToTop)
}
