import * as React from 'react'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import _ from 'lodash'

import Api from '../../Api'
import AdminAccount from '../../models/AdminAccount'

import WebCodeCustomizationBannedIPAddresses from './WebCodeCustomizationBannedIPAddresses'
import WebCodeCustomizationDirectScheduling from './WebCodeCustomizationDirectScheduling'
import WebCodeCustomizationWelcome from './WebCodeCustomizationWelcome'
import WebCodeCustomizationWidget from './WebCodeCustomizationWidget'
import WebCodeCustomizationWidgetButton from './WebCodeCustomizationWidgetButton'

import './WebCodeCustomizationForm.sass'

export type WebCodeCustomizationFormProps = {
    practice: Models.Practice
    webCode: Models.WebCode
    onSave: (webCode: Models.WebCode, updates: Api.WebCodeUpdate) => Promise<void>
    account: AdminAccount
}

export type WebCodeCustomizationFormState = {
    isCustomizationOpen: boolean
    isCustomizationButtonOpen: boolean
    isMessageOpen: boolean
    isBannedIPsOpen: boolean
    isDirectSchedulingOpen: boolean
    isDirty: boolean
    customization: Api.WebCodeCustomization
    newMessage?: string
    newIPAddress?: string
}

type Props = WebCodeCustomizationFormProps

export default class WebCodeCustomizationForm extends React.Component<Props, WebCodeCustomizationFormState> {
    constructor(props: Props) {
        super(props)

        this.state = {
            customization: {
                welcome_messages: props.webCode.customization.welcome_messages,
                widget: _.cloneDeep(props.webCode.customization.widget),
                widget_button: _.cloneDeep(props.webCode.customization.widget_button),
                banned_ip_addresses: _.cloneDeep(props.webCode.customization.banned_ip_addresses),
                direct_scheduling: _.cloneDeep(props.webCode.customization.direct_scheduling),
            },
            isCustomizationOpen: false,
            isCustomizationButtonOpen: false,
            isMessageOpen: false,
            isBannedIPsOpen: false,
            isDirectSchedulingOpen: false,
            isDirty: false,
        }
    }

    onUpdateMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newMessage: e.target.value })
    }

    onSave = () => {
        const { webCode } = this.props
        const { customization } = this.state
        this.props.onSave(webCode, { customization })
    }

    onCancelNewMessage = () => {
        this.setState({ newMessage: '' })
    }

    onDeleteMessage = (index: number) => {
        const newState = iassign(
            this.state,
            next => next.customization.welcome_messages,
            next => {
                if (!next) {
                    return next
                }
                next.splice(index, 1)
                return next
            },
        )

        if (newState.customization.welcome_messages && newState.customization.welcome_messages.length === 0) {
            newState.customization.welcome_messages = undefined
        }
        this.setState(newState)
        this.setState({ isDirty: true })
    }

    onSaveNewMessage = () => {
        const { newMessage } = this.state

        if (!newMessage) {
            return
        }

        const customization = iassign(
            this.state.customization,
            next => next.welcome_messages,
            next => {
                if (!next) {
                    return [newMessage]
                }

                next.push(newMessage)
                return next
            },
        )

        this.setState({ newMessage: undefined, isDirty: true, customization })
    }

    onUpdateIPAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9.*]+$/.test(e.target.value) || e.target.value === '') {
            this.setState({ newIPAddress: e.target.value })
        }
    }

    onCancelNewIPAddress = () => {
        this.setState({ newIPAddress: undefined })
    }

    onDeleteIPAddress = (index: number) => {
        const newState = iassign(
            this.state,
            next => next.customization.banned_ip_addresses,
            next => {
                if (!next) {
                    return next
                }
                next.splice(index, 1)
                return next
            },
        )

        if (newState.customization.banned_ip_addresses && newState.customization.banned_ip_addresses.length === 0) {
            newState.customization.banned_ip_addresses = undefined
        }
        this.setState(newState)
        this.setState({ isDirty: true })
    }

    onEditIPAddress = async (index: number, ipString: string, banReason: string | undefined) => {
        const { countryCodeAlpha2 } = await Api.App.getCountryCodeFromIp(ipString)

        const newState = iassign(
            this.state,
            next => next.customization.banned_ip_addresses,
            next => {
                if (!next) {
                    return next
                }
                next[index].ban_reason = banReason
                next[index].ip_address = ipString
                next[index].location = countryCodeAlpha2
                return next
            },
        )

        this.setState(newState)
        this.setState({ isDirty: true })
    }

    onSaveNewIPAddress = async () => {
        const { newIPAddress } = this.state
        const dateNow = new Date()

        if (!newIPAddress) {
            return
        }

        const { countryCodeAlpha2 } = await Api.App.getCountryCodeFromIp(newIPAddress)

        const customization = iassign(
            this.state.customization,
            next => next.banned_ip_addresses!,
            next => {
                if (!next) {
                    return [{ ip_address: newIPAddress }]
                }

                const { lastName, firstName } = this.props.account

                next.push({
                    ip_address: newIPAddress,
                    date: dateNow,
                    location: countryCodeAlpha2,
                    banned_by: `${firstName} ${lastName}`,
                })
                return next
            },
        )

        this.setState({ newIPAddress: undefined, isDirty: true, customization })
    }

    onToggleCustomizationOpen = () => {
        this.setState(prevState => ({
            isCustomizationOpen: !prevState.isCustomizationOpen,
        }))
    }

    onToggleCustomizationButtonOpen = () => {
        this.setState(prevState => ({
            isCustomizationButtonOpen: !prevState.isCustomizationButtonOpen,
        }))
    }

    onToggleMessageOpen = () => {
        this.setState(prevState => ({
            isMessageOpen: !prevState.isMessageOpen,
        }))
    }

    onToggleBannedIPsOpen = () => {
        this.setState(prevState => ({
            isBannedIPsOpen: !prevState.isBannedIPsOpen,
        }))
    }

    onToggleDirectSchedulingOpen = () => {
        this.setState(prevState => ({
            isDirectSchedulingOpen: !prevState.isDirectSchedulingOpen,
        }))
    }

    onUpdateCustomColor = (groupKey: string, colorKey: string, newColor: string) => {
        const customization = this.state.customization
        const widgetCustomization = customization.widget
        const widgetColors = widgetCustomization && widgetCustomization.colors
        let newCustomization

        if (!widgetColors) {
            newCustomization = {
                ...customization,
                widget: {
                    ...customization.widget,
                    colors: {
                        [`${groupKey}_${colorKey}`]: newColor,
                    },
                },
            }
        } else {
            newCustomization = iassign(
                customization,
                customizationObj => customizationObj.widget!.colors![`${groupKey}_${colorKey}`],
                next => newColor,
            )
        }

        this.setState({
            customization: newCustomization,
            isDirty: true,
        })
    }

    onUpdateIconPlacement = (iconPlacement: Api.widgetIconPosition) => {
        const widgetCustomization = this.state.customization.widget
        const customization = Object.assign({}, this.state.customization, {
            widget: Object.assign({}, widgetCustomization, {
                icon_position: iconPlacement,
            }),
        })

        this.setState({
            customization,
            isDirty: true,
        })
    }

    onUpdateWidgetStyle = (widgetStyle: Api.widgetStyle) => {
        const widgetCustomization = this.state.customization.widget
        const customization = Object.assign({}, this.state.customization, {
            widget: Object.assign({}, widgetCustomization, {
                widget_style: widgetStyle,
            }),
        })

        this.setState({
            customization,
            isDirty: true,
        })
    }

    resetColors = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        this.setState(state => {
            const { customization } = this.state
            const isDirty = customization.widget && customization.widget.colors ? true : state.isDirty
            const newState = {
                customization: {
                    ...customization,
                },
                isDirty,
            }
            if (newState.customization.widget) {
                delete newState.customization.widget.colors
            }
            return newState
        })
    }

    onUpdateWidgetButtonStyle = (widgetButtonStyle: Api.widgetButtonStyle) => {
        const { customization } = this.state

        this.setState({
            customization: {
                ...customization,
                widget_button: { ...customization.widget_button, widget_button_style: widgetButtonStyle },
            },
            isDirty: true,
        })
    }

    onUpdateEnableProviderSelection = (enabled: boolean) => {
        const { customization } = this.state

        this.setState({
            customization: {
                ...customization,
                direct_scheduling: { ...customization.direct_scheduling, enable_provider_selection: enabled },
            },
            isDirty: true,
        })
    }

    render() {
        const {
            customization,
            isDirty,
            isCustomizationOpen,
            isCustomizationButtonOpen,
            isMessageOpen,
            isBannedIPsOpen,
            isDirectSchedulingOpen,
            newMessage,
            newIPAddress,
        } = this.state

        const isSaveButtonDisabled = !isDirty

        return (
            <div className="web-code-customization-wrapper">
                <div className="web-code-customization">
                    <div className="header">
                        <div className="name-header">{this.props.practice.name}</div>
                        <div className="url-header">{this.props.webCode.url}</div>
                    </div>
                    <div className="welcome-messages">
                        <div className="title">Customization Options For Webchat Widget</div>
                        <div className="table-action" onClick={this.onToggleMessageOpen}>
                            <div className={classNames('button', { open: isMessageOpen })}>
                                <i className="material-icons add-icon">add</i>
                                <i className="material-icons close-icon">close</i>
                                New Welcome Message
                            </div>
                        </div>
                        {isMessageOpen && (
                            <WebCodeCustomizationWelcome
                                cancelNewMessage={this.onCancelNewMessage}
                                customization={customization}
                                deleteMessage={this.onDeleteMessage}
                                newMessage={newMessage}
                                saveNewMessage={this.onSaveNewMessage}
                                updateNewMessage={this.onUpdateMessage}
                            />
                        )}
                        <div className="table-action" onClick={this.onToggleCustomizationOpen}>
                            <div className={classNames('button', { open: Boolean(isCustomizationOpen) })}>
                                <i className="material-icons add-icon">add</i>
                                <i className="material-icons close-icon">close</i>
                                Customize Webchat Widget
                            </div>
                        </div>
                        {isCustomizationOpen && (
                            <WebCodeCustomizationWidget
                                customization={customization}
                                resetColors={this.resetColors}
                                updateCustomColor={this.onUpdateCustomColor}
                                updateIconPlacement={this.onUpdateIconPlacement}
                                updateWidgetStyle={this.onUpdateWidgetStyle}
                            />
                        )}
                        <div className="table-action" onClick={this.onToggleCustomizationButtonOpen}>
                            <div className={classNames('button', { open: Boolean(isCustomizationButtonOpen) })}>
                                <i className="material-icons add-icon">add</i>
                                <i className="material-icons close-icon">close</i>
                                Customize Webchat Widget Button
                            </div>
                        </div>
                        {isCustomizationButtonOpen && (
                            <WebCodeCustomizationWidgetButton
                                customization={customization}
                                updateWidgetButtonStyle={this.onUpdateWidgetButtonStyle}
                            />
                        )}
                        <div className="table-action" onClick={this.onToggleBannedIPsOpen}>
                            <div className={classNames('button', { open: Boolean(isBannedIPsOpen) })}>
                                <i className="material-icons add-icon">add</i>
                                <i className="material-icons close-icon">close</i>
                                Banned Ip Addresses
                            </div>
                        </div>
                        {isBannedIPsOpen && (
                            <WebCodeCustomizationBannedIPAddresses
                                cancelNewIPAddress={this.onCancelNewIPAddress}
                                customization={customization}
                                deleteIPAddress={this.onDeleteIPAddress}
                                editIPAddress={this.onEditIPAddress}
                                newIPAddress={newIPAddress}
                                saveNewIPAddress={this.onSaveNewIPAddress}
                                updateNewIPAddress={this.onUpdateIPAddress}
                            />
                        )}
                        <div className="table-action" onClick={this.onToggleDirectSchedulingOpen}>
                            <div className={classNames('button', { open: Boolean(isDirectSchedulingOpen) })}>
                                <i className="material-icons add-icon">add</i>
                                <i className="material-icons close-icon">close</i>
                                Online Scheduling
                            </div>
                        </div>
                        {isDirectSchedulingOpen && (
                            <WebCodeCustomizationDirectScheduling
                                customization={customization}
                                updateEnableProviderSelection={this.onUpdateEnableProviderSelection}
                            />
                        )}
                    </div>
                </div>
                <button
                    className={classNames('webcode-button', {
                        'can-save': !isSaveButtonDisabled,
                    })}
                    onClick={this.onSave}
                    disabled={isSaveButtonDisabled}
                >
                    Save
                </button>
            </div>
        )
    }
}
