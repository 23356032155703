export function convertDoubleStruckToRegular(input: string) {
    // A dictionary that maps each Unicode mathematical double-struck alphanumeric symbol
    // to its corresponding regular character.
    const replacements = {
        // Unicode mathematical double-struck capital letters
        '\uD835\uDD38': 'A',
        '\uD835\uDD39': 'B',
        ℂ: 'C',
        '\uD835\uDD3B': 'D',
        '\uD835\uDD3C': 'E',
        '\uD835\uDD3D': 'F',
        '\uD835\uDD3E': 'G',
        ℍ: 'H',
        '\uD835\uDD40': 'I',
        '\uD835\uDD41': 'J',
        '\uD835\uDD42': 'K',
        '\uD835\uDD43': 'L',
        '\uD835\uDD44': 'M',
        ℕ: 'N',
        '\uD835\uDD46': 'O',
        ℙ: 'P',
        ℚ: 'Q',
        ℝ: 'R',
        '\uD835\uDD4A': 'S',
        '\uD835\uDD4B': 'T',
        '\uD835\uDD4C': 'U',
        '\uD835\uDD4D': 'V',
        '\uD835\uDD4E': 'W',
        '\uD835\uDD4F': 'X',
        '\uD835\uDD50': 'Y',
        ℤ: 'Z',
        // Unicode mathematical double-struck small letters
        '\uD835\uDD52': 'a',
        '\uD835\uDD53': 'b',
        '\uD835\uDD54': 'c',
        '\uD835\uDD55': 'd',
        '\uD835\uDD56': 'e',
        '\uD835\uDD57': 'f',
        '\uD835\uDD58': 'g',
        '\uD835\uDD59': 'h',
        '\uD835\uDD5A': 'i',
        '\uD835\uDD5B': 'j',
        '\uD835\uDD5C': 'k',
        '\uD835\uDD5D': 'l',
        '\uD835\uDD5E': 'm',
        '\uD835\uDD5F': 'n',
        '\uD835\uDD60': 'o',
        '\uD835\uDD61': 'p',
        '\uD835\uDD62': 'q',
        '\uD835\uDD63': 'r',
        '\uD835\uDD64': 's',
        '\uD835\uDD65': 't',
        '\uD835\uDD66': 'u',
        '\uD835\uDD67': 'v',
        '\uD835\uDD68': 'w',
        '\uD835\uDD69': 'x',
        '\uD835\uDD6A': 'y',
        '\uD835\uDD6B': 'z',
        // Unicode mathematical double-struck digits
        '\uD835\uDFD8': '0',
        '\uD835\uDFD9': '1',
        '\uD835\uDFDA': '2',
        '\uD835\uDFDB': '3',
        '\uD835\uDFDC': '4',
        '\uD835\uDFDD': '5',
        '\uD835\uDFDE': '6',
        '\uD835\uDFDF': '7',
        '\uD835\uDFE0': '8',
        '\uD835\uDFE1': '9',
    }

    let output = ''
    for (const char of input) {
        output += replacements[char] || char
    }
    return output
}

export function cleanZalgoText(text: string) {
    // Define the Zalgo character ranges to be removed
    const zalgoRanges = [
        '\u0300-\u036f', // Combining Diacritical Marks
        '\u0483-\u0489', // Combining Cyrillic
        '\u0591-\u05bd', // Hebrew accents
        '\u05bf', // Hebrew punctuation
        '\u05c1-\u05c2', // Hebrew punctuation
        '\u05c4-\u05c7', // Hebrew punctuation
        '\u0600-\u06ff', // Arabic
        '\u07c0-\u07fa', // N'Ko
        '\u0800-\u08ff', // Samaritan
        '\u0900-\u0cff', // Devanagari
        '\u0d00-\u0d7f', // Malayalam
        '\u0e00-\u0e7f', // Thai
        '\u0e80-\u0eff', // Lao
        '\u10a0-\u10ff', // Georgian
        '\u1100-\u11ff', // Hangul Jamo
        '\u1200-\u137f', // Ethiopic
        '\u1380-\u139f', // Ethiopic Supplement
        '\u13a0-\u13ff', // Cherokee
        '\u1ab0-\u1aff', // Combining Diacritical Marks Extended
        '\u1dc0-\u1dff', // Combining Diacritical Marks Supplement
        '\u1e00-\u1eff', // Latin Extended Additional
        '\u1f00-\u1fff', // Greek Extended
        // '\u2000-\u206f', // General Punctuation
        '\u2070-\u209f', // Superscripts and Subscripts
        '\u20d0-\u20ff', // Combining Diacritical Marks for Symbols
        '\u2100-\u214f', // Letterlike Symbols
        '\u2150-\u218f', // Number Forms
        '\u2190-\u21ff', // Arrows
        '\u2200-\u22ff', // Mathematical Operators
        '\u2300-\u23ff', // Miscellaneous Technical
        '\u2400-\u243f', // Control Pictures
        '\u2440-\u245f', // Optical Character Recognition
        '\u2460-\u24ff', // Enclosed Alphanumerics
        '\u2500-\u257f', // Box Drawing
        '\u2580-\u259f', // Block Elements
        '\u25a0-\u25ff', // Geometric Shapes
        '\u2600-\u26ff', // Miscellaneous Symbols
        '\u2700-\u27bf', // Dingbats
        '\u27c0-\u27ef', // Miscellaneous Mathematical Symbols-A
        '\u27f0-\u27ff', // Supplemental Arrows-A
        '\u2800-\u28ff', // Braille Patterns
        '\u2900-\u297f', // Supplemental Arrows-B
        '\u2980-\u29ff', // Miscellaneous Mathematical Symbols-B
        '\u2a00-\u2aff', // Supplemental Mathematical Operators
        '\u2b00-\u2bff', // Miscellaneous Symbols and Arrows
        '\u2c60-\u2c7f', // Latin Extended-C
        '\u2c80-\u2cff', // Coptic
        '\u2d00-\u2d2f', // Georgian Supplement
        '\u2d30-\u2d7f', // Tifinagh
        '\u2d80-\u2ddf', // Ethiopic Extended
        '\u2de0-\u2dff', // Cyrillic Extended-A
        '\u2e00-\u2e7f', // Supplemental Punctuation
        '\u2e80-\u2eff', // CJK Radicals Supplement
        '\u2f00-\u2fdf', // Kangxi Radicals
        '\u2ff0-\u2fff', // Ideographic Description Characters
        '\u3000-\u303f', // CJK Symbols and Punctuation
        '\u3040-\u309f', // Hiragana
        '\u30a0-\u30ff', // Katakana
        '\u3100-\u312f', // Bopomofo
        '\u3130-\u318f', // Hangul Compatibility Jamo
        '\u3190-\u319f', // Kanbun
        '\u31a0-\u31bf', // Bopomofo Extended
        '\u31c0-\u31ef', // CJK Strokes
        '\u31f0-\u31ff', // Katakana Phonetic Extensions
        '\u3200-\u32ff', // Enclosed CJK Letters and Months
        '\u3300-\u33ff', // CJK Compatibility
        '\u3400-\u4dbf', // CJK Unified Ideographs Extension A
        '\u4dc0-\u4dff', // Yijing Hexagram Symbols
        '\u4e00-\u9fff', // CJK Unified Ideographs
        '\ua000-\ua48f', // Yi Syllables
        '\ua490-\ua4cf', // Yi Radicals
        '\ua4d0-\ua4ff', // Lisu
        '\ua500-\ua63f', // Vai
        '\ua640-\ua69f', // Cyrillic Extended-B
        '\ua6a0-\ua6ff', // Bamum
        '\ua700-\ua71f', // Modifier Tone Letters
        '\ua720-\ua7ff', // Latin Extended-D
        '\ua800-\ua82f', // Syloti Nagri
        '\ua830-\ua83f', // Common Indic Number Forms
        '\ua840-\ua87f', // Phags-pa
        '\ua880-\ua8df', // Saurashtra
        '\ua8e0-\ua8ff', // Devanagari Extended
        '\ua900-\ua92f', // Kayah Li
        '\ua930-\ua95f', // Rejang
        '\ua960-\ua97f', // Hangul Jamo Extended-A
        '\ua980-\ua9df', // Javanese
        '\ua9e0-\ua9ff', // Myanmar Extended-B
        '\uaa00-\uaa5f', // Cham
        '\uaa60-\uaa7f', // Myanmar Extended-A
        '\uaa80-\uaaff', // Tai Viet
        '\uab00-\uab2f', // Ethiopic Extended-A
        '\uab30-\uab6f', // Latin Extended-E
        '\uab70-\uabef', // Cherokee Supplement
        '\uabf0-\uabff', // Meetei Mayek Extensions
        '\uac00-\ud7af', // Hangul Syllables
        '\ud7b0-\ud7ff', // Hangul Jamo Extended-B
        '\ue000-\uf8ff', // Private Use Area
        '\uf900-\ufaff', // CJK Compatibility Ideographs
        '\ufb00-\ufb4f', // Alphabetic Presentation Forms
        '\ufb50-\ufb7f', // Arabic Presentation Forms-A
        '\ufb80-\ufbbf', // Arabic Presentation Forms-B
        '\ufbc0-\ufbff', // Arabic Presentation Forms-C
        '\ufc00-\ufc4f', // Arabic Presentation Forms-D
        '\ufc50-\ufc7f', // Arabic Presentation Forms-E
        '\ufc80-\ufcff', // Arabic Presentation Forms-F
        '\ufd00-\ufdff', // Arabic Presentation Forms-G
        '\ufe00-\ufe0f', // Variation Selectors
        '\ufe10-\ufe1f', // Vertical Forms
        '\ufe20-\ufe2f', // Combining Half Marks
        '\ufe30-\ufe4f', // CJK Compatibility Forms
        '\ufe50-\ufe6f', // Small Form Variants
        '\ufe70-\ufeff', // Arabic Presentation Forms
        '\uff00-\uffef', // Halfwidth and Fullwidth Forms
        '\ufff0-\uffff', // Specials
    ]

    // Create a regular expression pattern from the ranges
    const regexPattern = new RegExp(`[${zalgoRanges.join('')}]`, 'g')

    // Remove Zalgo characters from the text
    const cleanedText = text.replace(regexPattern, '')

    return cleanedText
}
